import PutAway from '../view'
import EntireTray from '../view/EntireTray'
import Batches from '../view/Batches'
import TrayDetail from '../view/TrayDetail'

let route = [
  {
    path: '/put-away',
    name: 'PutAway',
    component: PutAway,
  },
  {
    path: '/put-away-load/entire-tray',
    name: 'EntireTray',
    component: EntireTray,
  },
  {
    path: '/put-away-load/batches',
    name: 'Batches',
    component: Batches,
  },
  {
    path: '/put-away-load/tray-detail',
    name: 'TrayDetail',
    component: TrayDetail,
  },
]

export default route
