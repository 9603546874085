var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [_c("ips-header", { attrs: { title: _vm.$t("title.outSundry") } })],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["cidCode"]
                ? _vm.hideControl["cidCode"]
                : true,
              expression:
                "customData['cidCode'] ? hideControl['cidCode']: true",
            },
          ],
          ref: "ref0",
          attrs: {
            label: _vm.$t("label.tray"),
            title: _vm.$t("label.tray"),
            params: "cidCode",
            url: _vm.winvBaseUrl + "app-miscellaneous-out/cid",
            "pre-parse-url": _vm.urlData["cidCode"],
            custom: _vm.customData["cidCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            enter: function ($event) {
              return _vm.skipNext(10)
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(0)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(0, "lov")
            },
            choose: function ($event) {
              return _vm.handleChoose("cidCode", $event)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.cidCode,
            callback: function ($$v) {
              _vm.cidCode = $$v
            },
            expression: "cidCode",
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["locationCode"]
                ? _vm.hideControl["locationCode"]
                : true,
              expression:
                "customData['locationCode'] ? hideControl['locationCode']: true",
            },
          ],
          ref: "ref10",
          attrs: {
            label: _vm.$t("label.countLocation"),
            title: [
              _vm.$t("label.countLocation"),
              _vm.$t("label.locationName"),
              _vm.$t("label.whareaName"),
            ],
            required: "",
            params: ["locationCode", "locationName", "whareaName"],
            display: "locationCode",
            "url-params": { enabledFlag: 1, id: _vm.cidLocationId },
            url: _vm.wmdmBaseUrl + "wms-locations",
            "pre-parse-url": _vm.urlData["locationCode"],
            custom: _vm.customData["locationCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(10)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(10, "lov")
            },
            choose: function ($event) {
              return _vm.handleChoose("locationCode", $event)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.locationCode,
            callback: function ($$v) {
              _vm.locationCode = $$v
            },
            expression: "locationCode",
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.cidId
                ? false
                : _vm.customData["sku"]
                ? _vm.hideControl["sku"]
                : true,
              expression:
                " cidId ? false : customData['sku'] ? hideControl['sku'] : true",
            },
          ],
          ref: "ref20",
          attrs: {
            label: _vm.$t("label.sku"),
            required: "",
            title: [
              _vm.$t("label.sku"),
              _vm.$t("label.goodsName"),
              _vm.$t("label.ownerName"),
            ],
            params: ["sku", "goodsName", "ownerName"],
            display: "sku",
            url: _vm.winvBaseUrl + "app-miscellaneous-out/goods",
            "url-params": {
              orgId: _vm.orgId,
              orgCode: _vm.orgCode,
              ownerId: _vm.ownerId,
              ownerCode: _vm.ownerCode,
              locationId: _vm.locationId,
            },
            "pre-parse-url": _vm.urlData["sku"],
            custom: _vm.customData["sku"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            choose: function ($event) {
              return _vm.handleChoose("sku", $event)
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(20)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(20, "lov")
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.sku,
            callback: function ($$v) {
              _vm.sku = $$v
            },
            expression: "sku",
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isbatch
                ? _vm.customData["batchCode"]
                  ? _vm.hideControl["batchCode"]
                  : _vm.isbatch
                : false,
              expression:
                "isbatch ? (customData['batchCode'] ? hideControl['batchCode'] : isbatch) : false",
            },
          ],
          ref: "ref30",
          attrs: {
            label: _vm.$t("label.batch"),
            title: _vm.$t("label.batch"),
            required: "",
            params: "batchCode",
            display: "batchCode",
            url: _vm.winvBaseUrl + "app-miscellaneous-out/batch",
            "url-params": {
              goodsId: _vm.goodsId,
              sku: _vm.sku,
              ownerId: _vm.ownerId,
              ownerCode: _vm.ownerCode,
              locationId: _vm.locationId,
              locationCode: _vm.locationCode,
            },
            "pre-parse-url": _vm.urlData["batchCode"],
            custom: _vm.customData["batchCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            choose: function ($event) {
              return _vm.handleChoose("batchCode", $event)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(30)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(30, "lov")
            },
            enter: function ($event) {
              return _vm.skipNext(40)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.batchCode,
            callback: function ($$v) {
              _vm.batchCode = $$v
            },
            expression: "batchCode",
          },
        }),
        _vm.goodsId
          ? _c(
              "div",
              [
                _c("ips-page-card", {
                  attrs: {
                    "react-field": ["ownerName", "uomName"],
                    item: _vm.goodsLov,
                    "footer-info": { goodsName: _vm.goodsLov.goodsName },
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c("hips-row", [
          ["INV_CTRL", "OUT_CTRL", "SEMI_INV_CTRL"].includes(_vm.serialType)
            ? _c(
                "div",
                { staticStyle: { "margin-top": "16px" } },
                [
                  _c(
                    "hips-col",
                    { attrs: { span: "14" } },
                    [
                      _c("ips-input-number", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.customData["transferQty"]
                              ? _vm.hideControl["transferQty"]
                              : true,
                            expression:
                              "customData['transferQty'] ? hideControl['transferQty'] : true",
                          },
                        ],
                        ref: "ref40",
                        attrs: {
                          required: "",
                          label: _vm.$t("label.quantity"),
                          "thousands-formatter": "",
                          "not-auto-fill": "",
                          custom: _vm.customData["transferQty"],
                          "source-parse-field": _vm.sourceParseField,
                          "enable-serial": _vm.serialType,
                          min: null,
                          precision: 0,
                        },
                        on: {
                          setSourceField: function ($event) {
                            return _vm.saveSourceField($event)
                          },
                          valuedByFoucs: function ($event) {
                            return _vm.valuedByFoucs($event)
                          },
                          enter: _vm.chooseSkipNext,
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.handleCommonClick(40)
                          },
                        },
                        model: {
                          value: _vm.transferQty,
                          callback: function ($$v) {
                            _vm.transferQty = $$v
                          },
                          expression: "transferQty",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "hips-col",
                    { attrs: { span: "10" } },
                    [
                      _c(
                        "hips-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.cidId
                                ? false
                                : _vm.showSeriesDetail
                                ? _vm.customData["showSeriesDetail"]
                                  ? _vm.hideControl["showSeriesDetail"]
                                  : _vm.showSeriesDetail
                                : false,
                              expression:
                                "cidId ? false : showSeriesDetail ? (customData['showSeriesDetail'] ? hideControl['showSeriesDetail'] : showSeriesDetail) : false",
                            },
                          ],
                          ref: "refSerail",
                          staticStyle: {
                            width: "93%",
                            "margin-left": "10px",
                            color: "#FFFFFF",
                            height: "36px",
                            "line-height": "36px",
                          },
                          attrs: {
                            type: "primary",
                            size: "large",
                            disabled: !_vm.transferQty,
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.goToSerialDetail.apply(null, arguments)
                            },
                            enter: function ($event) {
                              return _vm.skipNext(40)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.serialNumberList.length > 0
                                  ? _vm.$t("button.seriesDetail") +
                                      "(" +
                                      _vm.serialNumberList.length +
                                      ")"
                                  : _vm.$t("button.seriesDetail")
                              ) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
        _c("hips-row", [
          (_vm.cidId ? true : false)
            ? _c(
                "div",
                { staticStyle: { "margin-top": "16px" } },
                [
                  _c(
                    "hips-col",
                    { attrs: { span: "14" } },
                    [
                      _c("ips-input-number", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.customData["transferQty"]
                              ? _vm.hideControl["transferQty"]
                              : true,
                            expression:
                              "customData['transferQty'] ? hideControl['transferQty'] : true",
                          },
                        ],
                        ref: "ref40",
                        attrs: {
                          required: "",
                          disabled: true,
                          label: _vm.$t("label.quantity"),
                          "thousands-formatter": "",
                          "not-auto-fill": "",
                          custom: _vm.customData["transferQty"],
                          "source-parse-field": _vm.sourceParseField,
                          min: null,
                        },
                        on: {
                          setSourceField: function ($event) {
                            return _vm.saveSourceField($event)
                          },
                          valuedByFoucs: function ($event) {
                            return _vm.valuedByFoucs($event)
                          },
                          enter: function ($event) {
                            return _vm.skipNext("inputRef")
                          },
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.handleCommonClick(40)
                          },
                        },
                        model: {
                          value: _vm.transferQty,
                          callback: function ($$v) {
                            _vm.transferQty = $$v
                          },
                          expression: "transferQty",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "hips-col",
                    { attrs: { span: "10" } },
                    [
                      _c(
                        "hips-button",
                        {
                          ref: "inputRef",
                          staticStyle: {
                            width: "93%",
                            "margin-left": "10px",
                            color: "#FFFFFF",
                            height: "36px",
                            "line-height": "36px",
                          },
                          attrs: { type: "primary", size: "large" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.goToCidTransferDetail.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("button.selectTrayDetail")) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
        _c("hips-row", [
          (
            _vm.cidId
              ? false
              : !["INV_CTRL", "OUT_CTRL", "SEMI_INV_CTRL"].includes(
                  _vm.serialType
                )
          )
            ? _c(
                "div",
                { staticStyle: { "margin-top": "16px" } },
                [
                  _c(
                    "hips-col",
                    { attrs: { span: "24" } },
                    [
                      _c("ips-input-number", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.customData["transferQty"]
                              ? _vm.hideControl["transferQty"]
                              : true,
                            expression:
                              "customData['transferQty'] ? hideControl['transferQty'] : true",
                          },
                        ],
                        ref: "ref40",
                        attrs: {
                          required: "",
                          label: _vm.$t("label.quantity"),
                          "thousands-formatter": "",
                          custom: _vm.customData["transferQty"],
                          "source-parse-field": _vm.sourceParseField,
                          "enable-serial": _vm.serialType,
                          "not-auto-fill": "",
                          min: null,
                        },
                        on: {
                          enter: function ($event) {
                            return _vm.skipNext(50)
                          },
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.handleCommonClick(40)
                          },
                          setSourceField: function ($event) {
                            return _vm.saveSourceField($event)
                          },
                          valuedByFoucs: function ($event) {
                            return _vm.valuedByFoucs($event)
                          },
                        },
                        model: {
                          value: _vm.transferQty,
                          callback: function ($$v) {
                            _vm.transferQty = $$v
                          },
                          expression: "transferQty",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
        _c(
          "div",
          { staticClass: "card_num_subtrans" },
          [
            _c("hips-row", [
              _c(
                "div",
                { staticClass: "row-num" },
                [
                  _c(
                    "hips-col",
                    {
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        height: "100%",
                      },
                      attrs: { span: "12" },
                    },
                    [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("label.availableQty")) +
                            ":\n            "
                        ),
                      ]),
                      _c("div", { staticClass: "value" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.qty) +
                            "\n            "
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "hips-col",
                    {
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        height: "100%",
                      },
                      attrs: { span: "12" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "title",
                          staticStyle: { display: "inline-block" },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("label.currentUseQty")) +
                              ":\n            "
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "value",
                          staticStyle: { display: "inline-block" },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.validQty) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["reason"]
                ? _vm.hideControl["reason"]
                : true,
              expression: "customData['reason'] ? hideControl['reason']: true",
            },
          ],
          ref: "ref50",
          attrs: {
            label: _vm.$t("label.sundryReason"),
            title: _vm.$t("label.sundryReason"),
            params: ["meaning"],
            url: _vm.wmdmBaseUrl + "wms-common/lov-data-query",
            "url-params": { lovCode: "WINV.INV_MOVE_MISC_REASON" },
            "pre-parse-url": _vm.urlData["reason"],
            "is-page-content": false,
            custom: _vm.customData["reason"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(50)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(50, "lov")
            },
            enter: function ($event) {
              return _vm.skipNext("submitRef")
            },
            choose: function ($event) {
              return _vm.handleChoose("reason", $event)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.reason,
            callback: function ($$v) {
              _vm.reason = $$v
            },
            expression: "reason",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-footer" },
      [
        _c(
          "hips-button",
          {
            ref: "submitRef",
            attrs: { size: "large" },
            on: { click: _vm.handleButtonSubmit },
          },
          [
            _c("svg-icon", {
              attrs: { slot: "icon", "icon-class": "success" },
              slot: "icon",
            }),
            _vm._v("\n      " + _vm._s(_vm.$t("button.confirm")) + "\n    "),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }