<template>
  <hips-view>
    <ips-header
      class="ips-header-height"
      :title="$t('title.ipsNewSerial')"
      :is-index="false"
      @beforeBack="returnLastRouter"
    />
    <div class="ips-container">
      <hips-row
        style="margin-bottom: 16px"
        v-if="detailIdTmpList ? !detailIdTmpList : true"
      >
        <hips-col span="18">
          <ips-field
            v-model="serialNum"
            is-only
            :is-index="false"
            :label="$t('label.serialNumber')"
            ref="ref0"
            @enter="checkSerialNum"
            @click.stop="handleCommonClick(0)"
          />
        </hips-col>
        <hips-col
          span="6"
          style="text-align: right"
        >
          <hips-button
            size="large"
            @click.stop="checkSerialNum"
            ref="refSerail"
            class="ips-new-serial-button-style"
          >
            {{ $t('button.serialEntry') }}
          </hips-button>
        </hips-col>
      </hips-row>
      <div
        class="center_area"
        v-show="batchSerialNum"
      >
        <div class="entryQty-box">
          {{ $t('label.alreadyEntry') }}: &nbsp;{{ totalSelectedNum }}
        </div>
      </div>
      <div
        class="num-boxs"
        v-show="!batchSerialNum"
      >
        <div class="ips-new-serial-detail-block-one">
          <div class="receive-front">
            {{ receiveQty || 0 }}
          </div>
          <div class="front">
            {{ $t('label.shouldEntry') }}
          </div>
        </div>
        <div class="ips-new-serial-detail-block-two">
          <div class="receive-front">
            {{ totalSelectedNum }}
          </div>
          <div class="front">
            {{ $t('label.alreadyEntry') }}
          </div>
        </div>
        <div class="ips-new-serial-detail-block-three">
          <div class="receive-front">
            {{ receiveQty-totalSelectedNum }}
          </div>
          <div class="front">
            {{ $t('label.stayEntry') }}
          </div>
        </div>
      </div>
      <div class="ips-table">
        <table>
          <tr style="background-color: #eee">
            <th
              class="ips-table-thead"
              v-for="(item, index) in tableTitle"
              :key="index"
              scope="col"
            >
              {{ item.name }}
            </th>
          </tr>
          <tr
            v-for="(item, index) in tableData"
            :key="index"
            :ref="`tr${index}`"
          >
            <td>{{ item.serialNumber }}</td>
            <td style="position:relative">
              {{ item.qualityStatusName }}
              <!-- <div
                @click.stop="deleteTableData(index)"
                class="delIcon"
              >
                <svg-icon slot="icon" icon-class="delete" />
              </div> -->
            </td>
            <td>
              {{ item.serialQty || item.qty }}
            </td>
            <td>
              <div
                class="delLineIcon"
                @click.stop="deleteTableData(item)"
              >
                <svg-icon
                  slot="icon"
                  icon-class="delete"
                />
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div
      class="ips-footer"
      style="position: fixed; bottom: 0px"
    >
      <hips-button
        v-if="!detailIdTmpList"
        class="ips-new-serial-button-clear-style"
        size="large"
        ref="checkRef"
        @click="selectAll"
      >
        {{ $t('button.clear') }}
      </hips-button>
      <hips-button
        class="ips-new-serial-button-confirm-style"
        size="large"
        ref="submitRef"
        @click="handleButtonSubmit"
      >
        {{ $t('button.confirm') }}
      </hips-button>
    </div>
  </hips-view>

</template>

<script>
import { Switch, Row, Col, } from "@hips/vue-ui";
import { checkSerialInbound } from "../api";
import _ from 'lodash'
export default {
  name: 'IpsNewSerial',
  components: {
    [Switch.name]: Switch,
    [Row.name]: Row,
    [Col.name]: Col,
  },
  data() {
    return {
      inputFieldList: [{ type: 'input', required: false, ref: 0, bind: 'serialNum' }],
      totalSelectedNum: 0,
      serialNum: '',
      tableData: [],
      tableTitle: [],
      oldTableData: [],
      isClear: false,
      receiveQty: '',
      pendingQty: '',
      detailIdTmpList: null,
      batchSerialNum: false,
      callback: null,
      isSuccessSubmit: true,
    };
  },
  mounted() {
    this.tableTitle = [
      {
        name: this.$t('label.serialNumber'), // 序列号
      },
      {
        name: this.$t('label.qualityStatus'), // 质量状态
      },
      {
        name: this.$t('label.quantity'), // 数量
      },
      {
        name: this.$t('label.operation'), // 操作
      },
    ];

    // 默认展示输入框
    const { task, pendingQty, receiveQty, detailIdTmpList, batchSerialNum, serialQtyNum, callback, definedInboundSerialReceiptList, detailId, billDetail, showInputBox, submitUrl } = this.$route.params;
    this.receiveQty = receiveQty;
    this.detailId = detailId;
    this.callback = callback;
    this.billDetail = billDetail;
    this.showInputBox = showInputBox;
    this.submitUrl = submitUrl;
    if (serialQtyNum) {
      this.receiveQty = serialQtyNum;
      this.totalSelectedNum = serialQtyNum;
    }
    if (batchSerialNum) {
      this.batchSerialNum = true;
    }
    // if(pendingQty === receiveQty){
    this.tableData = [...task];
    if (task.length > 0) this.totalSelectedNum = task.length;
    this.oldTableData = [...task];
    this.totalSelectedNum = task.length;
    this.isClear = false;
    // }
    this.pendingQty = pendingQty;
    this.detailIdTmpList = detailIdTmpList;
    this.definedInboundSerialReceiptList = definedInboundSerialReceiptList;
    // fromUnLoading || fromPutaway
    // 不可编辑的情况
    if (detailIdTmpList) {
      this.tableData = task;
    }
    this.skipNext(0);
  },
  activated() {
    this.inputFieldList = [{ type: 'input', required: false, ref: 0, bind: 'serialNum' }];

  },
  methods: {

    /**
     * 录入序列号
     */
    async checkSerialNum() {
      if (this.serialNum) {
        this.$hint.showLoading();
        const { data, serialUrl, callback } = this.$route.params;
        let newData = {
          ...data,
          serialNumber: this.serialNum,
        };
        console.log(serialUrl, data);
        const url = `${this.winvBaseUrl}` + `${serialUrl}`;
        const res = await this.$http.post(url, newData);
        let isRepeat = false;
        try {
          if (res) {
            if (this.tableData && this.tableData.length > 0) {
              this.tableData.forEach((item) => {
                if (item.serialNumber === this.serialNum) { // res[0].serialNumber
                  isRepeat = true
                }
              });
              // const onConfirm=()=>{
              //   const newTableData =this.tableData.filter((item)=>item.serialNumber !== this.serialNum)
              //   this.tableData = newTableData;
              //   this.totalSelectedNum-=1;
              //   this.serialNum = '';
              // };
              // const onCancel = ()=> {
              //   this.serialNum = '';
              // };
              if (isRepeat) {
                this.$hint.showFailed(this.$i18n.t('message.repeatSerial'));
                this.serialNum = '';
                // this.$hint.showAlert(this.$i18n.t('message.deleteSerial'), onConfirm, onCancel);
              } else {
                if (this.receiveQty === this.totalSelectedNum) {
                  this.$hint.showFailed(this.$i18n.t('message.showCheckQty'));
                  this.$hint.hideLoading();
                  return undefined;
                }
                if (callback) {
                  callback.call(this, this.serialNum, res.serialId);
                } else {
                  this.tableData.push(res[0]);
                }
                this.totalSelectedNum += 1;
                this.serialNum = '';
              }
            } else {
              if (this.receiveQty === this.totalSelectedNum) {
                this.$hint.showFailed(this.$i18n.t('message.showCheckQty'));
                return
              }
              if (callback) {
                callback.call(this, this.serialNum, res.serialId);
              } else {
                this.tableData.push(res[0]);
              }
              this.totalSelectedNum += 1;
              this.serialNum = '';
            }
            this.$hint.hideLoading();
          }
        } catch (e) {
          this.$hint.hideLoading();
        }
      }
    },

    /**
     * 全删
     */
    selectAll() {
      const onConfirm = () => {
        this.isClear = true;
        this.task = [];
        this.tableData = [];
        this.totalSelectedNum = 0;
        this.serialNum = '';
      };
      const onCancel = () => {
        this.serialNum = '';
        this.isClear = false;
      };
      this.$hint.showAlert(this.$i18n.t('message.allDeleteSerial'), onConfirm, onCancel);
    },

    deleteTableData(record) {
      const onConfirm = () => {
        const newTableData = this.tableData.filter((item) => item.serialNumber !== record.serialNumber)
        this.tableData = newTableData;
        this.totalSelectedNum -= 1;
        // this.serialNum = '';
      };
      const onCancel = () => {
        // this.serialNum = '';
      };

      this.$hint.showAlert(this.$i18n.t('message.confirmDeletion'), onConfirm, onCancel);
    },

    // 确认序列号明细
    async handleButtonSubmit() {
      if (this.submitUrl) {
        this.handleSerialSubmit();
      }

      const serialNumberList = []
      this.tableData.forEach((item) => {
        serialNumberList.push(item.serialNumber);
      });
      const submitSerialData = {
        detailId: this.detailId,
        serialNumberList,
      };
      if (this.detailId) {
        const res = await checkSerialInbound(submitSerialData);
        if (res && res.failed) {
          this.$hint.showFailed(res.message, true);
          return;
        }
      }
      if (this.receiveQty - this.totalSelectedNum !== 0) {
        const onConfirm = () => {
          sessionStorage.setItem('inboundSerialReceiptList', JSON.stringify(this.tableData));
          sessionStorage.setItem('serialReceiveQty', JSON.stringify(this.tableData.length));
          this.$router.backPage();
        };
        const onCancel = () => {
          this.skipNext(0);
        };
        this.isSuccessSubmit = false;
        this.$hint.showAlert(this.$i18n.t('message.checkSerialQty'), onConfirm, onCancel);
        return ({ isSuccess: false })
      }
      this.isSuccessSubmit = true;
      sessionStorage.setItem('inboundSerialReceiptList', JSON.stringify(this.tableData));
      sessionStorage.setItem('serialReceiveQty', JSON.stringify(this.tableData.length));
      this.$router.backPage();
    },

    // 确认序列号明细 - 任务执行
    async handleSerialSubmit() {
      let serialNumberDtoList = [];
      this.tableData.map((item) => {
        serialNumberDtoList.push(item);
      });
      if (serialNumberDtoList.length) {
        // fromUnLoading, fromPutaway, fromReceiveAdjust
        // 不展示输入框的情况 ，纯展示，不输入
        if (!this.showInputBox) {
          sessionStorage.setItem('totalSerialNum', JSON.stringify(this.totalSelectedNum));
          sessionStorage.setItem('serialNumberDtoList', JSON.stringify(serialNumberDtoList));
          this.$store.commit('pushKeepAlives', 'IpsSerial'); // 返回卸货页面时 缓存当前页面
          this.$router.backPage();
        } else {
          try {
            const url = `${this.winvBaseUrl}${this.submitUrl}`;
            this.$hint.showLoading();
            this.handleSubmitStatus();
            // const url = `${this.winvBaseUrl}app-outbound-load/confirm-serial-sernumber`;
            const data = {
              ...this.billDetail,
              serialNumberDtoList,
            };
            const res = await this.$http.post(url, data);
            sessionStorage.setItem('totalSerialNum', JSON.stringify(this.totalSelectedNum));
            sessionStorage.setItem('serialNumberDtoList', JSON.stringify(res));
            this.$router.backPage();
          } finally {
            this.$hint.hideLoading();
            this.handleSubmitStatus();
          }
        }
      } else {
        this.$hint.showFailed(this.$t('message.mustChooseSerial'), true);
      }
    },

    async returnLastRouter() {
      const { receiveQty, toSerialOldList } = this.$route.params;
      if (this.isClear && this.tableData.length === 0) {
        sessionStorage.setItem('inboundSerialReceiptList', null);
        sessionStorage.setItem('serialReceiveQty', JSON.stringify(receiveQty));
        this.$router.backPage();
      } else if (!_.isEqual(this.oldTableData, this.tableData)) {
        const onConfirm = () => {
          sessionStorage.setItem('inboundSerialReceiptList', JSON.stringify(this.oldTableData));
          sessionStorage.setItem('serialReceiveQty', JSON.stringify(receiveQty));
          this.$router.backPage();
        };
        const onCancel = () => {
          this.skipNext(0);
        };
        this.isSuccessSubmit = false;
        this.$hint.showAlert(this.$i18n.t('message.checkBackPath'), onConfirm, onCancel);
        return ({ isSuccess: false })
      } else if (_.isEqual(toSerialOldList, this.tableData) && this.pendingQty === this.oldTableData.length) {
        sessionStorage.setItem('inboundSerialReceiptList', null);
        sessionStorage.setItem('serialReceiveQty', JSON.stringify(this.receiveQty));
        this.$router.backPage();
      } else {
        sessionStorage.setItem('inboundSerialReceiptList', JSON.stringify(this.tableData));
        sessionStorage.setItem('serialReceiveQty', JSON.stringify(receiveQty));
        this.$router.backPage();
      }
    },
  }
};
</script>

<style lang="stylus" scoped>
@import '~@/style/var.styl';

.ips-header-height {
  height: 50px;
}

.ips-table {
  margin-top: 16px;
  width: 100%;

  .ips-table-thead {
    width: 180px;
    height: 26px;
    background: rgba(255, 255, 255, 1);
    border-radius: 12px 0px 0px 0px;
    line-height: 26px;
    text-align: left;
    font-weight: bold;
    font-size: 14px;
    padding-left: 12px;
  }

  table {
    width: 100%;

    th, td {
      padding: 5px 2px;
      border: 1px solid #D9D9D9;
      text-align: center;
      white-space: nowrap;
      font-size: 14px;
      color: rgba(89, 89, 89, 1);
    }

    .back-green {
      background-color: #cbdeb8;
    }
  }
}

.num-boxs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.center_area {
  line-height: 36px;
  margin: 5px 0;

  .entryQty-box {
    width: 110px;
    line-height: 30px;
    background: rgba(230, 247, 255, 1);
    border-radius: 6px;
    border: 1px solid rgba(145, 213, 255, 1);
    text-align: center;
  }
}

.ips-new-serial-detail-block-one {
  width: 110px;
  height: 77px;
  background: rgba(230, 247, 255, 1);
  border-radius: 6px;
  border: 2px solid rgba(145, 213, 255, 1);
  text-align: center;

  .receive-front {
    line-height: 65px;
    font-size: 24px;
    font-weight: bold;
    color: rgba(24, 144, 255, 1);
  }

  .front {
    margin-top: -20px;
    font-size: 14px;
    color: rgba(24, 144, 255, 1);
  }
}

.ips-new-serial-detail-block-two {
  width: 110px;
  height: 77px;
  background: rgba(246, 255, 237, 1);
  border-radius: 6px;
  border: 2px solid rgba(183, 235, 143, 1);
  text-align: center;

  .receive-front {
    line-height: 65px;
    font-size: 24px;
    font-weight: bold;
    color: rgba(82, 196, 26, 1);
  }

  .front {
    margin-top: -20px;
    font-size: 14px;
    color: rgba(82, 196, 26, 1);
  }
}

.ips-new-serial-detail-block-three {
  width: 110px;
  height: 77px;
  background: rgba(255, 244, 230, 1);
  border-radius: 6px;
  border: 2px solid rgba(255, 211, 130, 1);
  text-align: center;

  .receive-front {
    line-height: 65px;
    font-size: 24px;
    font-weight: bold;
    color: rgba(255, 143, 7, 1);
  }

  .front {
    margin-top: -20px;
    font-size: 14px;
    color: rgba(255, 143, 7, 1);
  }
}

.delIcon {
  position: absolute;
  z-index: 999;
  right: 10px;
  top: 5px;
}

.delLineIcon {
  color: rgba(255, 64, 49, 1);
}

.ips-new-serial-button-style {
  margin-left: 16px;
  background-color: var(--primaryColor);
  color: #FFFFFF;
  font-size: 14px;
  height: 36px;
  line-height: 36px;
}

.ips-new-serial-button-clear-style {
  border-radius: 6px;
  border: 2px solid rgba(255, 64, 49, 1);
  width: 170px;
  height: 44px;
  line-height: 44px;
  font-size: 20px;
  color: rgba(255, 64, 49, 1);
  background-color: #fff;
  margin-right: 0;
}

.ips-new-serial-button-confirm-style {
  margin-left: 16px;
  background-color: var(--primaryColor);
  color: #FFFFFF;
  font-size: 20px;
  border-radius: 6px;
  font-size: 20px;
}
</style>
