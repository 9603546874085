import WarehouseChoose from '../views/warehouse-choose'

let route = [
  {
    path: '/warehouse-choose',
    name: 'WarehouseChoose',
    component: WarehouseChoose,
  },
]

export default route