import { render, staticRenderFns } from "./ipsSerial.vue?vue&type=template&id=139149d2&scoped=true&"
import script from "./ipsSerial.vue?vue&type=script&lang=js&"
export * from "./ipsSerial.vue?vue&type=script&lang=js&"
import style0 from "./ipsSerial.vue?vue&type=style&index=0&id=139149d2&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "139149d2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/app/wms-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('139149d2')) {
      api.createRecord('139149d2', component.options)
    } else {
      api.reload('139149d2', component.options)
    }
    module.hot.accept("./ipsSerial.vue?vue&type=template&id=139149d2&scoped=true&", function () {
      api.rerender('139149d2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/public/views/ipsSerial.vue"
export default component.exports