import request from '@/request';
import mixin  from '@/mixin/mixin';

// 获取托盘详情信息数据
export function queryCidStockDetail(data) {
  let url = `${mixin.data().winvBaseUrl}app-inv/query-cid-stock-detail`;
  return request.get(url, data);
}

// 获取托盘信息
export function queryCid(data) {
  let url = `${mixin.data().winvBaseUrl}app-inv/query-cid`;
  return request.get(url, data);
}

