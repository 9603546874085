<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.searchTray')" />
    </div>
    <div class="ips-container">
      <!-- 托盘 -->
      <ips-lov
        :label="$t('label.tray')"
        :title="$t('label.tray')"
        required
        v-model="cidCode"
        v-show="customData['cidCode'] ? hideControl['cidCode']: true"
        params="cidCode"
        :url="`${wmdmBaseUrl}containers`"
        :pre-parse-url="urlData['cidCode']"
        :custom="customData['cidCode']"
        :source-parse-field="sourceParseField"
        ref="ref0"
        is-only
        @enter="skipNext('submitRef')"
        @down="skipNext('submitRef')"
        @click.stop="handleCommonClick(0)"
        @clickIcon="handleCommonClick(0, 'lov')"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
    </div>
    <div class="ips-footer">
      <hips-button size="large" ref="submitRef" @click.stop="handleCommonSubmit">
        <svg-icon slot="icon" icon-class="success" />
        {{ $t('button.confirm') }} 
      </hips-button>
    </div>
  </hips-view>
</template>

<script>
  import { storage } from '@/utils';
  export default {
    name: 'ContanierSearch',
    data() {
      return {
        cidCode: '', // 托盘
        urlData:{},
        customData: {}, // 个性化配置信息列表
        hideControl: {}, // 个性化配置显示隐藏控制
        sourceParseField: {}, // 界面中所有的来源解析字段
      };
    },
    methods: {
      /**
       *@functionName: saveSourceField
       *@description: 保存来源解析字段并赋值Enter
       *@params1: fieldObj[Object] 来源解析字段对象,
       */
      saveSourceField(fieldObj) {
        this.saveSourceParseField(this, fieldObj);
      },
      // 字段聚焦时赋值操作
      async valuedByFoucs(options={}) {
        const { custom ={} , currentValue } = options;
        await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
      },
      async handleButtonSubmit() {
        const param = {
          name: 'ContanierData',
          params: {
            cidCode: this.cidCode,
          },
        };
        await this.clearSourceFields(this, true);
        this.$router.pushPage(param);
      },
    },
    created() {
      const { customObj, hideControl } = this.handleCustomData(storage.get('customData'));
      this.customData = customObj;
      this.hideControl = hideControl;
      // console.log('托盘查询customData', Object.assign({}, this.customData));
    },
    mounted() {
      this.inputFieldList = [{ type: 'lov', required: true, ref: 0, bind: 'cidCode', field: 'cidCode' }];
      this.urlData = this.handleData('LPN_QUERY');
      this.skipNext(0);
    },
  };
</script>

<style lang="stylus" scoped></style>
