var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: {
            title:
              _vm.type === "location"
                ? _vm.$t("title.locationInventory")
                : _vm.$t("title.batchInventory"),
            "is-index": false,
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "ips-container stockDetail-container" }, [
      _vm.type === "location"
        ? _c(
            "div",
            _vm._l(_vm.data, function (item, index) {
              return _c("div", { key: index, staticClass: "list-item" }, [
                _c(
                  "div",
                  { staticClass: "stockDetail-container__item" },
                  [
                    _c("ips-value-cell", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.customData["skuDetail"]
                            ? _vm.hideControl["skuDetail"]
                            : true,
                          expression:
                            "customData['skuDetail'] ? hideControl['skuDetail'] : true",
                        },
                      ],
                      attrs: { title: _vm.$t("label.sku"), value: item.sku },
                    }),
                    _c("ips-value-cell", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.customData["goodsName"]
                            ? _vm.hideControl["goodsName"]
                            : true,
                          expression:
                            "customData['goodsName'] ? hideControl['goodsName'] : true",
                        },
                      ],
                      attrs: {
                        title: _vm.$t("label.goodsName"),
                        value: item.goodsName,
                      },
                    }),
                    _c("ips-value-cell", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.customData["uomName"]
                            ? _vm.hideControl["uomName"]
                            : true,
                          expression:
                            "customData['uomName'] ? hideControl['uomName'] : true",
                        },
                      ],
                      attrs: {
                        title: _vm.$t("label.unit"),
                        value: item.uomName,
                      },
                    }),
                    _c("ips-value-cell", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.customData["batchCodeDetail"]
                            ? _vm.hideControl["batchCodeDetail"]
                            : true,
                          expression:
                            "customData['batchCodeDetail'] ? hideControl['batchCodeDetail'] : true",
                        },
                      ],
                      attrs: {
                        title: _vm.$t("label.batch"),
                        value: item.batchCode,
                      },
                    }),
                    _c("ips-value-cell", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.customData["whareaNameDetail"]
                            ? _vm.hideControl["whareaNameDetail"]
                            : true,
                          expression:
                            "customData['whareaNameDetail'] ? hideControl['whareaNameDetail'] : true",
                        },
                      ],
                      attrs: {
                        title: _vm.$t("label.whareaCode"),
                        value: item.whareaName,
                      },
                    }),
                    _c("ips-value-cell", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.customData["locationNameDetail"]
                            ? _vm.hideControl["locationNameDetail"]
                            : true,
                          expression:
                            "customData['locationNameDetail'] ? hideControl['locationNameDetail'] : true",
                        },
                      ],
                      attrs: {
                        title: _vm.$t("label.countLocation"),
                        value: item.locationName,
                      },
                    }),
                    _c("ips-value-cell", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.customData["qty"]
                            ? _vm.hideControl["qty"]
                            : true,
                          expression:
                            "customData['qty'] ? hideControl['qty'] : true",
                        },
                      ],
                      attrs: {
                        title: _vm.$t("label.availableQty"),
                        value: item.qty,
                      },
                    }),
                    _c("ips-value-cell", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.customData["validQty"]
                            ? _vm.hideControl["validQty"]
                            : true,
                          expression:
                            "customData['validQty'] ? hideControl['validQty'] : true",
                        },
                      ],
                      attrs: {
                        title: _vm.$t("label.useQty"),
                        value: item.validQty,
                      },
                    }),
                  ],
                  1
                ),
              ])
            }),
            0
          )
        : _c(
            "div",
            _vm._l(_vm.data, function (item, index) {
              return _c("div", { key: index, staticClass: "list-item" }, [
                _c(
                  "div",
                  { staticClass: "stockDetail-container__item" },
                  [
                    _c("ips-value-cell", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.customData["skuDetail"]
                            ? _vm.hideControl["skuDetail"]
                            : true,
                          expression:
                            "customData['skuDetail'] ? hideControl['skuDetail'] : true",
                        },
                      ],
                      attrs: { title: _vm.$t("label.sku"), value: item.sku },
                    }),
                    _c("ips-value-cell", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.customData["goodsName"]
                            ? _vm.hideControl["goodsName"]
                            : true,
                          expression:
                            "customData['goodsName'] ? hideControl['goodsName'] : true",
                        },
                      ],
                      attrs: {
                        title: _vm.$t("label.goodsName"),
                        value: item.goodsName,
                      },
                    }),
                    _c("ips-value-cell", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.customData["uomName"]
                            ? _vm.hideControl["uomName"]
                            : true,
                          expression:
                            "customData['uomName'] ? hideControl['uomName'] : true",
                        },
                      ],
                      attrs: {
                        title: _vm.$t("label.unit"),
                        value: item.uomName,
                      },
                    }),
                    _c("ips-value-cell", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.customData["batchCodeDetail"]
                            ? _vm.hideControl["batchCodeDetail"]
                            : true,
                          expression:
                            "customData['batchCodeDetail'] ? hideControl['batchCodeDetail'] : true",
                        },
                      ],
                      attrs: {
                        title: _vm.$t("label.batch"),
                        value: item.batchCode,
                      },
                    }),
                    _c("ips-value-cell", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.customData["qty"]
                            ? _vm.hideControl["qty"]
                            : true,
                          expression:
                            "customData['qty'] ? hideControl['qty'] : true",
                        },
                      ],
                      attrs: {
                        title: _vm.$t("label.availableQty"),
                        value: item.qty,
                      },
                    }),
                    _c("ips-value-cell", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.customData["validQty"]
                            ? _vm.hideControl["validQty"]
                            : true,
                          expression:
                            "customData['validQty'] ? hideControl['validQty'] : true",
                        },
                      ],
                      attrs: {
                        title: _vm.$t("label.useQty"),
                        value: item.validQty,
                      },
                    }),
                  ],
                  1
                ),
              ])
            }),
            0
          ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }