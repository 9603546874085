<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.skuCode') + `-${sku}`" :is-index="false" />
    </div>
    <div class="ips-container">
      <div class="ips-table">
        <table>
          <tr style="background-color: #eee">
            <th v-for="(item, index) in tableTitle" :key="index" scope="col">
              {{ item.name }}
            </th>
          </tr>
          <tr v-for="(item, index) in goodsDate" :key="index">
            <td v-if="isBatch">
              {{ item.batchCode }}
            </td>
            <td v-if="sourceType !== 'INV' && enableSerial || sourceType === 'INV' && isInvCtrl">
              <a style="color: blue"
                 @click="goSerialNumberDetail(item.sku, item.goodsId, item.billCode, item.billId, item.batchCode, item.batchId)"
              >{{ $t('title.serialNumber') }}</a>
            </td>
            <td>{{ item.uomCode }}</td>
            <td>{{ item.qtyScan }}</td>
          </tr>
        </table>
      </div>
    </div>
  </hips-view> 
</template>

<script>
import { queryGoodsDetail, goSerialNumberDetail } from '../api'
export default {
  name: 'GoodsDetail',
  data () {
    return {
      sku: '',
      goodsId: '',
      loadCidId: '',
      loadCidCode: '',
      goodsDate: [],
      serialDate: [],
      sourceType: '',
      isInvCtrl: '',
    }
  },
  methods: {
    // 获取物料详情
    async getGoodsDetail(loadCidId, loadCidCode, sku, goodsId, billId, billCode){
      try{
        this.$hint.showLoading();
        let param = {
          params: {
            sku,
            goodsId,
            loadCidId,
            loadCidCode,
            billId,
            billCode,
          }
        };
      this.goodsDate = await queryGoodsDetail(param);
      } finally {
        this.$hint.hideLoading();
      }
    },
    // 获取序列号详情
    async goSerialNumberDetail(sku, goodsId, billCode, billId, batchCode, batchId){
      try{
        this.$hint.showLoading();
        let param = {
          params: {
            sku,
            goodsId,
            billId,
            billCode,
            batchCode,
            batchId,
            loadCidId: this.loadCidId,
            loadCidCode: this.loadCidCode,
          }
        };
        this.serialDate = await goSerialNumberDetail(param);
        const routeParam = {
          name: 'SerialDetail',
          params: {
            serialDate: this.serialDate
          },
        };
        this.$router.pushPage(routeParam);
      } finally {
        this.$hint.hideLoading();
      }
    }
  },
  created() {
    const {loadCidId, loadCidCode, sku, goodsId, billId, billCode, enableSerial, isBatch, sourceType, isInvCtrl} =
      this.$route.params;
    this.loadCidId = loadCidId;
    this.loadCidCode = loadCidCode;
    this.sku = sku;
    this.goodsId = goodsId;
    this.enableSerial = enableSerial;
    this.isBatch = isBatch;
    this.sourceType = sourceType;
    this.isInvCtrl = isInvCtrl;
    this.getGoodsDetail(loadCidId, loadCidCode, sku, goodsId, billId, billCode );
    if(this.sourceType === 'INV'){
      if(isBatch && isInvCtrl){
        this.tableTitle = [
          {// 批次
            name: this.$t('label.batch')
          },
          {// 序列号
            name: this.$t('label.serialNumber')
          },
          {// 单位
            name: this.$t('label.unit')
          },
          {// 数量
            name: this.$t('label.quantity')
          }
        ];
      }else if(!isBatch && isInvCtrl){
        this.tableTitle = [
          {// 序列号
            name: this.$t('label.serialNumber')
          },
          {// 单位
            name: this.$t('label.unit')
          },
          {// 数量
            name: this.$t('label.quantity')
          }
        ];
      }else if(isBatch && !isInvCtrl){
        this.tableTitle = [
          {// 批次
            name: this.$t('label.batch')
          },
          {// 单位
            name: this.$t('label.unit')
          },
          {// 数量
            name: this.$t('label.quantity')
          }
        ];
      }
    }else {
      if(isBatch && enableSerial){
        this.tableTitle = [
          {// 批次
            name: this.$t('label.batch')
          },
          {// 序列号
            name: this.$t('label.serialNumber')
          },
          {// 单位
            name: this.$t('label.unit')
          },
          {// 数量
            name: this.$t('label.quantity')
          }
        ];
      }else if(!isBatch && enableSerial){
        this.tableTitle = [
          {// 序列号
            name: this.$t('label.serialNumber')
          },
          {// 单位
            name: this.$t('label.unit')
          },
          {// 数量
            name: this.$t('label.quantity')
          }
        ];
      }else if(isBatch && !enableSerial){
        this.tableTitle = [
          {// 批次
            name: this.$t('label.batch')
          },
          {// 单位
            name: this.$t('label.unit')
          },
          {// 数量
            name: this.$t('label.quantity')
          }
        ];
      }
    }


  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'SerialDetail') {
      this.$store.commit('pushKeepAlives', 'GoodsDetail');
    } else if(to.name === 'CidDetail') {
      this.$store.commit('deleteKeepAlives', 'GoodsDetail');
    }
    this.$nextTick(() => {
      next();
    });
  },
}
</script>

<style lang="stylus" scoped>
 @import '~@/style/var.styl'
.ips-table {
  width 100%
  overflow-x auto
  table {
    width 100%
  }
  th, td {
    padding:8px 10px
    border 1px solid #ccc
    text-align center
    white-space nowrap
  }
}

</style>





