import request from '@/request';
import mixin from '@/mixin/mixin';
import { filterNullValueObject } from '@/utils/common'

// 波次单数量展示
export function queryTaskSummary(data) {
  let url = `${mixin.data().winvBaseUrl}app-wave-picking/query-task-summary`;
  const params = filterNullValueObject(data)
  return request.get(url, params);
}

// 波次单数量展示
export function operatingNodeIntercept(data) {
  let url = `${mixin.data().winvBaseUrl}winv-outbound-interceptions/operating-node-intercept`;
  const newData = filterNullValueObject(data)
  return request.post(url, newData);
}

// 波次单校验
export function validateWave(data) {
  let url = `${mixin.data().winvBaseUrl}app-wave-picking/validate-wave`;
  const newData = filterNullValueObject(data)
  return request.post(url, newData);
}

// 波次单拣货小车校验
export function validatePickingCart(data) {
  let url = `${mixin.data().winvBaseUrl}app-wave-picking/validate-picking-cart`;
  const newData = filterNullValueObject(data)
  return request.post(url, newData);
}

// 清空任务
export function clearTask(data) {
  let url = `${mixin.data().winvBaseUrl}app-wave-picking/clear-task`;
  const newData = filterNullValueObject(data)
  return request.post(url, newData);
}

// 确认
export function fetchProcessTask(data) {
  let url = `${mixin.data().winvBaseUrl}app-wave-picking/fetch-process-task`;
  const params = filterNullValueObject(data)
  return request.get(url, params);
}

// 验证物料
export function validateSku(data) {
  let url = `${mixin.data().winvBaseUrl}app-wave-picking/validate-sku`;
  const newData = filterNullValueObject(data)
  return request.post(url, newData);
}

// 验证条码
export function validateCode(data) {
  let url = `${mixin.data().winvBaseUrl}app-wave-picking/validate-code`;
  const newData = filterNullValueObject(data)
  return request.post(url, newData);
}

// 删除已验证条码
export function deleteValidateCode(data) {
  let url = `${mixin.data().winvBaseUrl}app-wave-picking/delete-code`;
  const newData = filterNullValueObject(data)
  return request.post(url, newData);
}

// 扫描明细
export function queryScanDetail(data) {
  let url = `${mixin.data().winvBaseUrl}app-wave-picking/query-scan-detail`;
  const params = filterNullValueObject(data)
  return request.get(url, params);
}

// 获取现有量
export function querySummaryQty(data) {
  let url = `${mixin.data().winvBaseUrl}app-wave-picking/query-summary-qty`;
  const params = filterNullValueObject(data)
  return request.get(url, params);
}

// 获取现有量
export function validateQty(data) {
  let url = `${mixin.data().winvBaseUrl}app-wave-picking/validate-qty`;
  const newData = filterNullValueObject(data)
  return request.post(url, newData);
}

// 跳过任务
export function skipTask(data) {
  let url = `${mixin.data().winvBaseUrl}app-wave-picking/skip-task`;
  const newData = filterNullValueObject(data)
  return request.post(url, newData);
}

// 清空当前扫描任务
export function clearCurrentScan(data) {
  let url = `${mixin.data().winvBaseUrl}app-wave-picking/clear-current-scan`;
  const newData = filterNullValueObject(data)
  return request.post(url, newData);
}

// 拣货确认
export function loadAndUnload(data) {
  let url = `${mixin.data().winvBaseUrl}app-wave-picking/load-and-unload`;
  const newData = filterNullValueObject(data)
  return request.post(url, newData);
}
