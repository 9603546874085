var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ips-input ips-input-common" }, [
    _c("div", { class: _vm.validateClass }, [
      _c(
        "div",
        {
          class: [
            "ips-input-wrapper",
            "ips-input-has-label",
            "ips-input-has-border",
            {
              "ips-input-has-value": _vm.nativeInputValue,
              "ips-input-focused": _vm.focused,
              "ips-has-append": _vm.$slots.append,
              "is-disabled": _vm.disabled,
              "disabled-background": _vm.disBackground,
              "is-required": _vm.required,
              "back-required": _vm.backRequired,
              "nolabel-required": _vm.nolabelRequired,
            },
          ],
        },
        [
          _c(
            "input",
            _vm._b(
              {
                ref: "input",
                staticClass: "ips-input__inner",
                attrs: {
                  type: _vm.type,
                  disabled: _vm.disabled,
                  disBackground: _vm.disBackground,
                  readonly: _vm.readonly,
                },
                on: {
                  input: _vm.handleInput,
                  focus: _vm.handleFocus,
                  click: _vm.handleClick,
                  blur: _vm.handleBlur,
                  change: _vm.handleChange,
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleEnter.apply(null, arguments)
                  },
                  keydown: [
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "down", 40, $event.key, [
                          "Down",
                          "ArrowDown",
                        ])
                      ) {
                        return null
                      }
                      return _vm.handleDown.apply(null, arguments)
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
                      ) {
                        return null
                      }
                      return _vm.handleTab.apply(null, arguments)
                    },
                  ],
                },
              },
              "input",
              _vm.$attrs,
              false
            )
          ),
          _vm.label
            ? _c("div", { staticClass: "ips-input-label-wrapper" }, [
                _c("div", { staticClass: "ips-input-label" }, [
                  _vm._v("\n          " + _vm._s(_vm.label) + "\n        "),
                ]),
              ])
            : _vm._e(),
          _vm.$slots.append
            ? _c(
                "div",
                { staticClass: "ips-input-group__append" },
                [_vm._t("append")],
                2
              )
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.validateClass === "has-error" ||
                    _vm.validateClass === "has-nodata",
                  expression:
                    "validateClass === 'has-error' || validateClass === 'has-nodata'",
                },
              ],
              staticClass: "ips-error-suffix",
            },
            [
              _c("svg-icon", { attrs: { "icon-class": "fail" } }),
              _c("div", { staticClass: "ips-toolTip" }, [
                _c("div", { staticClass: "ips-toolTip__message" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.errorMsg || _vm.$t("message.mustEnter")) +
                      "\n          "
                  ),
                ]),
                _c("div", { staticClass: "popper__arrow" }),
              ]),
            ],
            1
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }