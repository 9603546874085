<template>
  <div class="ipsPicture">
    <hips-row>
      <hips-col 
        :span="6" 
        class="position-r"
        v-for="(item,index) in imageList"
        :key="index"
      >
        <img 
          class="img-item"
          :src="item.src"
          @click="previewImg(index)"
          alt="No Picture"
        >
        <img 
          class="img-del" 
          src="@assets/del.png"
          @click="deleteImg(index)"
          alt="No Picture"
        >
      </hips-col>
      <hips-col :span="6">
        <img 
          class="img-item" 
          src="@assets/add.png"
          @click="choiceMethods"
          alt="No Picture"
        >
      </hips-col>
    </hips-row>
    <hips-actionsheet 
      v-model="showAction"
      :buttons="buttons" 
    />
  </div>  
</template>

<script>
import { Row, Col, Actionsheet } from '@hips/vue-ui';
export default {
  name:'IpsPicture',
  data() {
    return {
      showAction:false,
      buttons: [
        {
        text: '拍照',
        closeAfterClick: true,
        handler: this.takePhoto
        }, {
          text: '从相册中选择',
          closeAfterClick: true,
          handler: this.openAlbum
        }
      ],
      imageList: [],//显示图片数组
    }
  },
  props: {

  },
  components:{
    [Row.name]: Row,
    [Col.name]: Col,
    [Actionsheet.name]:Actionsheet,
  },
  methods: {
    //选择图片获取方式
    choiceMethods() {
      this.showAction = true;
    },
    //拍照
    async takePhoto(){
      try {
        const img = await this.$bridge.takePhoto();  
        this.addImg([ img ]);
      }catch(e) {
        console.log(e)
      }finally{
        this.showAction = false;
        this.$hint.hideLoading();
      }
    },
    //打开相册
    async openAlbum() {
      try{
        const imgs = await this.$bridge.photoAlbum();
        this.addImg(imgs);
      }catch(e) {
        console.log(e)
      }finally{
        this.showAction = false;
        this.$hint.hideLoading();
      }  
    },
    //添加图片
    addImg(imgList) {
      for(let item of imgList) {
        let img = {
          src: item,
          msrc: item,
        };
        this.imageList.push(img);
      }
      this.$emit('changeImg', this.imageList);
    },
    //删除照片
    deleteImg(index) {
      this.imageList.splice(index,1);
      this.$emit('changeImg', this.imageList);
    },
    //预览图片
    previewImg(index){
      this.$hips.previewer.show({
        imageSet: this.imageList,
        options: {
          index: index,
        },
      });
    },
  },


}
</script>

<style lang="stylus">
 @import '~@/style/var.styl'
.ipsPicture {
  .position-r {
    position relative;
  }
  .img-item {
    width 100%;
    height 80px;
  }
  .img-del {
    position absolute;
    right -2px;
    top -2px;
    width 20px;
    
  }
}

</style>

