<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.workManagement')" />
    </div>
    <div class="ips-container">
      <div class="tabs">
        <div
          @click="activeName = 'pendingTasks'"
          :class="activeName === 'pendingTasks' ? 'tab active' : 'tab'"
        >
          {{ $t('button.taskToBeProcessed') }}({{ pendingTasks.length }})
        </div>
        <div
          @click="activeName = 'processedTasks'"
          :class="activeName === 'processedTasks' ? 'tab active' : 'tab'"
        >
          {{ $t('button.haveToDealWith') }}({{ processedTasks.length }})
        </div>
        <div
          @click="showInput = !showInput"
          class="tab g-flex-center"
        >
          <hips-icon
            name="menu"
            :size="20"
          /> {{ $t('button.more') }}
        </div>
      </div>
      <div
        v-if="showInput"
        class="filter-inputs"
      >
        <hips-input
          v-model="taskTypeName"
          @input="filterData"
          :label="$t('label.jobType')"
          :placeholder="`${$t('label.jobType')}`"
          type="text"
          label-position="left"
        />
        <!-- <hips-input
          v-model="billTypeName"
          @input="filterData"
          :label="$t('label.billType')"
          :placeholder="`${$t('label.billType')}`"
          type="text"
          label-position="left"
        /> -->
      </div>
      <div class="date-box g-flex">
        <hips-icon
          name="alert-route"
          :size="20"
        />
        <div
          style="flex: 1"
          class="g-flex-center"
        >
          <div class="g-flex-between">
            <div
              @click="showPopupStartDate = true"
              class="start-date"
            >
              {{ startDate }}
            </div>
            <div style="margin: 0 20px">
              至
            </div>
            <div
              @click="showPopupEndDate = true"
              class="end-date"
            >
              {{ endDate }}
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="dataList.length > 0"
        class="task-list"
      >
        <div
          :class="item.taskType"
          @click="goCompleteTask(item)"
          v-for="item in dataList"
          :key="item.id"
          class="task-item"
        >
          <div class="top-box g-flex-between">
            <div class="g-flex-center">
              <div class="tag">
                {{ item.taskTypeName.slice(0, 2) }}
              </div>
              <span v-if="item.taskType === 'PUT_AWAY'"> {{ item.cidCode }}</span>
              <span v-if="item.taskType === 'OUT_WAVE'"> {{ item.waveBillCode }}</span>
              <span v-if="item.taskType === 'OUT_BILL'"> {{ item.billCode }}</span>
              <span v-if="item.taskType === 'TRANSFER'"> {{ item.billCode }}</span>
            </div>
            <div>{{ item.billType }}</div>
          </div>
          <div class="mid-box g-flex-between">
            <div class="left">
              <div class="cell">
                <div v-if="item.taskType === 'PUT_AWAY'">
                  {{ $t('label.deliveryCode') }}：{{ item.billCode }}
                </div>
                <div v-if="item.billTypeName">
                  {{ $t('label.billType') }} ：{{ item.billTypeName }}
                </div>
                <div>{{ $t('label.estimatedDeliveryDate') }}：{{ item.creationDate }}</div>
                <div>{{ $t('label.planTheNumberOf') }}：{{ item.qty }}</div>
              </div>
            </div>
            <div
              v-if="activeName === 'pendingTasks'"
              class="right"
            >
              <hips-icon name="arrow" />
            </div>
          </div>
          <div class="fotter-box g-flex-between">
            <div>
              <div
                v-if="item.leftFooter"
                class="type-box"
              >
                {{ item.leftFooter }}
              </div>
            </div>
            <div>{{ item.rightFooter }}</div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="content-empty"
      >
        <span> — {{ $t('message.findNullNormal') }} — </span>
      </div>
    </div>
    <hips-popup
      v-model="showPopupStartDate"
      position="bottom"
    >
      <hips-datetime-picker
        format="yyyy-MM-dd"
        title="选择开始时间"
        :default-selected-value="startDate"
        @confirm="handleConfirm1"
        @cancel="hidePop"
      />
    </hips-popup>
    <hips-popup
      v-model="showPopupEndDate"
      position="bottom"
    >
      <hips-datetime-picker
        format="yyyy-MM-dd"
        title="选择结束时间"
        :default-selected-value="endDate"
        @confirm="handleConfirm2"
        @cancel="hidePop"
      />
    </hips-popup>

    <!-- <hips-popup
      v-model="showTaskPopup"
      position="bottom"
    >
      <hips-picker
        :data="TaskColumns"
        value-key="name"
        :title="$t('title.switchLanguage')"
        @confirm="handleConfirm($event, 'lan')"
      />
    </hips-popup> -->
  </hips-view>
</template>

<script>
import { Icon } from '@hips/vue-ui';
import { Input } from '@hips/vue-ui';
import { storage } from '@/utils';
import { fetchProcessedTasks, fetchPendingTasks, waveOrderLov, dealWithCid, queryProcessTask, checkCidId, fetchProcessTask } from '../api/index';
import { getYearMonth } from './../utils/index';
import { Popup, DatetimePicker } from '@hips/vue-ui';
let allTaskData = {
  processedTasks: [],
  pendingTasks: [],
};
export default {
  name: 'WorkManagement',
  components: {
    [Input.name]: Input,
    [Icon.name]: Icon,
    [Popup.name]: Popup,
    [DatetimePicker.name]: DatetimePicker,
  },
  data() {
    let startDate = getYearMonth();
    let endDate = getYearMonth();
    return {
      showInput: false,
      taskTypeName: '',
      billTypeName: '',
      startDate,
      endDate,
      activeName: 'pendingTasks',
      processedTasks: [],
      pendingTasks: [],
      showPopupStartDate: false,
      showPopupEndDate: false,
      // showTaskPopup: false,
    };
  },
  computed: {
    dataList: function () {
      return this[this.activeName];
    },
  },
  async mounted() {
    this.getData();
    storage.remove('waveOrderItem');
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'taskExecutionWave' || to.name === 'EntireTray' || to.name === 'ExecutionConfirm') {
      this.$store.commit('pushKeepAlives', 'WorkManagement');
    } else {
      this.$store.commit('deleteKeepAlives', 'WorkManagement');
    }
    setTimeout(() => {
      next();
    }, 100);
  },
  methods: {
    // 完成任务
    goCompleteTask(item) {
      if (item.taskType === 'OUT_WAVE') {
        waveOrderLov({
          params: {
            waveBillCode: item.waveBillCode,
          },
        }).then((res) => {
          if (res && res.content && res.content.length === 1) {
            storage.set('waveOrderItem', res.content[0]);
            let param = {
              name: 'taskExecutionWave',
              query: {
                waveBillId: item.waveBillId,
              },
            };
            this.$router.pushPage(param);
          }
        });
      } else if (item.taskType === 'PUT_AWAY') {
        checkCidId({
          params: {
            cidId: item.cidId,
          },
        }).then(() => {
          const cidData = {
            params: {
              cidId: item.cidId,
              cidCode: item.cidCode,
              isSpiltFlag: false,
            },
          };
          dealWithCid(cidData).then((res) => {
            const length = res.length;
            if (res && length > 0) {
              const param = {
                name: 'EntireTray',
                params: {
                  cidCode: item.cidCode,
                  parentCidCode: res[0].parentCidCode,
                  parentCidId: res[0].parentCidId,
                  warehouseId: res[0].warehouseId,
                  sourceParseField: {},
                  customData: {},
                  hideControl: {},
                },
              };
              sessionStorage.setItem('_putawayLoad', JSON.stringify(res));
              this.$router.pushPage(param);
            }
          });
        });
      } else if (item.taskType === 'OUT_BILL') {
        const { whareaCode, whareaId, sku, goodsId, taskCode, id, billCode, billId, billTypeId, billType } = item;
        let param = {
          params: {
            billCode,
            billId,
            billTypeId,
            billType,
            whareaCode,
            whareaId,
            sku,
            goodsId,
            taskCode,
            id,
          },
        };
        queryProcessTask(param).then((res) => {
          const routerParam = {
            name: 'ExecutionConfirm',
            params: {
              billDetail: res,
              sourceParseField: {},
              customData: {},
              hideControl: {},
            },
          };
          this.$router.pushPage(routerParam);
        });
      } else if (item.taskType === 'TRANSFER') {
        console.log(item)
        const { billId, billCode } = item;
        this.$hint.showLoading();
        fetchProcessTask({
          billCode,
          billId,
          sourceType: item.taskType
        }).then((res) => {
          if (res) {
            storage.set('billDetail', res);
            let param = {
              name: 'taskExecutionWave2',
              query: {
                billId,
                billCode,
                sourceType: item.taskType
              },
            };
            this.$router.pushPage(param);
          }
        });
        this.$hint.hideLoading();
      }
    },

    filterData() {
      if (this.taskTypeName || this.billTypeName) {
        if (allTaskData[this.activeName].length > 0) {
          this[this.activeName] = allTaskData[this.activeName].filter((item) => {
            console.log(this.taskTypeName);
            console.log(this.billTypeName);
            if (this.taskTypeName && this.billTypeName) {
              return item.taskTypeName.indexOf(this.taskTypeName) > -1 && item.billTypeName.indexOf(this.billTypeName) > -1;
            } else if (this.taskTypeName && !this.billTypeName) {
              return item.taskTypeName.indexOf(this.taskTypeName) > -1;
            } else if (!this.taskTypeName && this.billTypeName) {
              return item.billTypeName.indexOf(this.billTypeName) > -1;
            }
          });
        }
      } else {
        this[this.activeName] = allTaskData[this.activeName];
      }
    },
    hidePop() {
      this.showPopupStartDate = false;
      this.showPopupEndDate = false;
    },
    handleConfirm1(value) {
      this.startDate = value;
      this.getData();
      this.hidePop();
    },
    handleConfirm2(value) {
      this.endDate = value;
      this.getData();
      this.hidePop();
    },
    async getData() {
      if (this.startDate > this.endDate) {
        this.$hint.showFailed('起始时间不能大于终止时间', true);
        return;
      }
      let data = {
        params: {
          startDate: this.startDate + ' 00:00:00',
          endDate: this.endDate + ' 23:59:59',
        },
      };
      this.processedTasks = allTaskData['processedTasks'] = await fetchProcessedTasks(data);
      this.pendingTasks = allTaskData['pendingTasks'] = await fetchPendingTasks(data);
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~@/style/var.styl';

.ips-container {
  padding-top: 0px;
  font-size: 12px;

  .content-empty {
    text-align: center;
    padding-top: 30px;
  }

  .tabs {
    display: flex;
    justify-content: space-between;

    .tab {
      flex: 1;
      text-align: center;
      color: #4a4a4a;
      border: 1px solid #d1d2d2;
      margin-right: 5px;
      height: 40px;
      line-height: 40px;
      border-radius: 5px;
      vertical-align: middle;

      &:last-child {
        margin: 0;
      }

      &.active {
        background: #7fc0ed;
      }
    }
  }

  .filter-inputs {
    padding-top: 10px;
  }

  .date-box {
    height: 32px;
    line-height: 32px;
    border: 1px solid #e0e2e5;
    background: #eef1f6;
    border-radius: 5px;
    margin-top: 10px;
    align-items: center;
    padding-left: 10px;
  }

  .task-list {
    .task-item {
      border-radius: 5px;
      border: 1px solid #eff2f6;
      box-shadow: -5px 1px 2px #fa5e49;
      margin-top: 10px;

      &.OUT_WAVE {
        box-shadow: -5px 1px 2px #fa5e49;

        .top-box {
          .tag {
            background: #fa5e49;
          }
        }

        .fotter-box {
          .type-box {
            background: #fa5e49;
          }
        }
      }

      &.OUT_BILL {
        box-shadow: -5px 1px 2px #7fc0ed;

        .top-box {
          .tag {
            background: #7fc0ed;
          }
        }

        .fotter-box {
          .type-box {
            background: #7fc0ed;
          }
        }
      }

      &.PUT_AWAY {
        box-shadow: -5px 1px 2px #eeaa49;

        .top-box {
          .tag {
            background: #eeaa49;
          }
        }

        .fotter-box {
          .type-box {
            background: #eeaa49;
          }
        }
      }

      .top-box {
        height: 34px;
        align-items: center;
        padding: 0 10px;
        border-bottom: 1px solid #eff2f6;

        .tag {
          height: 26px;
          line-height: 26px;
          color: white;
          padding: 0 20px;
          border-radius: 15px;
          background: #fa5e49;
          margin-right: 10px;
        }
      }

      .mid-box {
        line-height: 24px;
        padding: 0 10px;
        border-bottom: 1px solid #eff2f6;
      }

      .fotter-box {
        height: 40px;
        padding: 0 10px;

        .type-box {
          height: 24px;
          line-height: 24px;
          color: white;
          padding: 0 20px;
          border-radius: 5px;
          background: #fa5e49;
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
