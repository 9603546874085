import BatchMarge from '../views/batch-marge'
import BatchMargeConfirm from '../views/batch-marge-confirm'
import ImportedDetail from '../views/imported-detail'

let route = [
  {
    path: '/batch-marge',
    name: 'BatchMarge',
    component: BatchMarge,
  },
  {
    path: '/batch-marge-confirm',
    name: 'BatchMargeConfirm',
    component: BatchMargeConfirm,
  },
  {
    path: '/imported-detail',
    name: 'ImportedDetail',
    component: ImportedDetail,
  },
];

export default route
