var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: {
            title: _vm.$t("title.cidDetail") + "" + _vm.loadCidCode,
            "is-index": false,
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container container" },
      [
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["defaultType"]
                ? _vm.hideControl["defaultType"]
                : true,
              expression:
                "customData['defaultType'] ? hideControl['defaultType']: true",
            },
          ],
          ref: "ref0",
          attrs: {
            label: _vm.$t("label.queryType"),
            title: _vm.$t("label.queryType"),
            params: "meaning",
            url: _vm.wmdmBaseUrl + "wms-common/lov-data-query",
            "url-params": { lovCode: "WINV.UNLOAD_QUERY_LEVEL" },
            "pre-parse-url": _vm.urlData["defaultType"],
            "is-page-content": false,
            custom: _vm.customData["defaultType"],
            "source-parse-field": _vm.sourceParseField,
            readonly: true,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(0)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(0, "lov")
            },
            choose: function ($event) {
              return _vm.handleChoose("type", $event)
            },
            enter: function ($event) {
              return _vm.skipNext(10)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.defaultType,
            callback: function ($$v) {
              _vm.defaultType = $$v
            },
            expression: "defaultType",
          },
        }),
        _c(
          "div",
          { staticStyle: { "margin-top": "16px" } },
          [
            _c(
              "hips-row",
              [
                _c(
                  "hips-col",
                  { attrs: { span: "19" } },
                  [
                    _c("ips-field", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.customData["queryValue"]
                            ? _vm.hideControl["queryValue"]
                            : true,
                          expression:
                            "customData['queryValue'] ? hideControl['queryValue']: true",
                        },
                      ],
                      ref: "ref10",
                      attrs: {
                        label: _vm.$t("label.queryConditions"),
                        params: "queryValue",
                        display: "queryValue",
                        "pre-parse-url": _vm.urlData["queryValue"],
                        custom: _vm.customData["queryValue"],
                        "source-parse-field": _vm.sourceParseField,
                      },
                      on: {
                        enter: function ($event) {
                          return _vm.skipNext("submitRef")
                        },
                        setSourceField: function ($event) {
                          return _vm.saveSourceField($event)
                        },
                        valuedByFoucs: function ($event) {
                          return _vm.valuedByFoucs($event)
                        },
                      },
                      model: {
                        value: _vm.queryValue,
                        callback: function ($$v) {
                          _vm.queryValue = $$v
                        },
                        expression: "queryValue",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "hips-col",
                  {
                    staticStyle: { "text-align": "right" },
                    attrs: { span: "5" },
                  },
                  [
                    _c(
                      "hips-button",
                      {
                        attrs: { type: "warning" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.queryTask.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("button.search")) +
                            "\n          "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm.taskList.length > 0
          ? _c(
              "div",
              _vm._l(_vm.taskList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    ref: "tr" + index,
                    refInFor: true,
                    staticClass: "ips-unloading-card-style",
                    class: [{ "card-active": _vm.idList.includes(item.id) }],
                    on: {
                      click: function ($event) {
                        return _vm.handleClickCard(item.id)
                      },
                    },
                  },
                  [
                    _c(
                      "hips-row",
                      [
                        _c("hips-col", { attrs: { span: "24" } }, [
                          _c(
                            "span",
                            { staticClass: "coloumns-fieldname-left" },
                            [_vm._v(_vm._s(_vm.$t("label.skuCode")))]
                          ),
                          _c(
                            "span",
                            { staticClass: "unloading-card-coloumns-center" },
                            [_vm._v(":")]
                          ),
                          _c("span", { staticClass: "coloumns-fieldvalue" }, [
                            _vm._v(_vm._s(item.sku)),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "hips-row",
                      [
                        _c(
                          "hips-col",
                          {
                            staticStyle: { "margin-top": "12px" },
                            attrs: { span: "24" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "coloumns-fieldname-left" },
                              [_vm._v(_vm._s(_vm.$t("label.goodsName")))]
                            ),
                            _c(
                              "span",
                              { staticClass: "unloading-card-coloumns-center" },
                              [_vm._v(":")]
                            ),
                            _c("span", { staticClass: "coloumns-fieldvalue" }, [
                              _vm._v(_vm._s(item.goodsName)),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "hips-row",
                      [
                        _c(
                          "hips-col",
                          {
                            staticStyle: { "margin-top": "12px" },
                            attrs: { span: "24" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "coloumns-fieldname-left" },
                              [_vm._v(_vm._s(_vm.$t("label.batch")))]
                            ),
                            _c(
                              "span",
                              { staticClass: "unloading-card-coloumns-center" },
                              [_vm._v(":")]
                            ),
                            _c("span", { staticClass: "coloumns-fieldvalue" }, [
                              _vm._v(_vm._s(item.batchCode)),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "hips-row",
                      [
                        _c(
                          "hips-col",
                          {
                            staticStyle: { "margin-top": "12px" },
                            attrs: { span: "24" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "coloumns-fieldname-left" },
                              [_vm._v(_vm._s(_vm.$t("label.deliveryCode")))]
                            ),
                            _c(
                              "span",
                              { staticClass: "unloading-card-coloumns-center" },
                              [_vm._v(":")]
                            ),
                            _c("span", { staticClass: "coloumns-fieldvalue" }, [
                              _vm._v(_vm._s(item.billCode)),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "hips-row",
                      [
                        _c(
                          "hips-col",
                          {
                            staticStyle: { "margin-top": "12px" },
                            attrs: { span: "11" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "coloumns-fieldname-left" },
                              [_vm._v(_vm._s(_vm.$t("label.quantity")))]
                            ),
                            _c(
                              "span",
                              { staticClass: "unloading-card-coloumns-center" },
                              [_vm._v(":")]
                            ),
                            _c(
                              "span",
                              {
                                ref: "num" + index,
                                refInFor: true,
                                staticClass: "coloumns-fieldvalue",
                                staticStyle: {
                                  "font-weight": "bold",
                                  "z-index": "9999",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.modifiNum(index, item)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item.qtyScan))]
                            ),
                          ]
                        ),
                        _c(
                          "hips-col",
                          {
                            staticStyle: { "margin-top": "12px" },
                            attrs: { span: "13" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "coloumns-fieldname-right" },
                              [_vm._v(_vm._s(_vm.$t("label.targetLocation")))]
                            ),
                            _c(
                              "span",
                              { staticClass: "unloading-card-coloumns-center" },
                              [_vm._v(":")]
                            ),
                            _c("span", { staticClass: "coloumns-fieldvalue" }, [
                              _vm._v(_vm._s(item.toLocationCode)),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "hips-row",
                      [
                        _c(
                          "hips-col",
                          {
                            staticStyle: { "margin-top": "12px" },
                            attrs: { span: "11" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "coloumns-fieldname-left" },
                              [_vm._v(_vm._s(_vm.$t("label.unit")))]
                            ),
                            _c(
                              "span",
                              { staticClass: "unloading-card-coloumns-center" },
                              [_vm._v(":")]
                            ),
                            _c("span", { staticClass: "coloumns-fieldvalue" }, [
                              _vm._v(_vm._s(item.uomName)),
                            ]),
                          ]
                        ),
                        _c(
                          "hips-col",
                          {
                            staticStyle: { "margin-top": "12px" },
                            attrs: { span: "13" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "coloumns-fieldname-right" },
                              [_vm._v(_vm._s(_vm.$t("label.ownerCode")))]
                            ),
                            _c(
                              "span",
                              { staticClass: "unloading-card-coloumns-center" },
                              [_vm._v(":")]
                            ),
                            _c("span", { staticClass: "coloumns-fieldvalue" }, [
                              _vm._v(_vm._s(item.ownerCode)),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e(),
        _c(
          "hips-popup",
          {
            model: {
              value: _vm.modifyFlag,
              callback: function ($$v) {
                _vm.modifyFlag = $$v
              },
              expression: "modifyFlag",
            },
          },
          [
            _c(
              "div",
              { staticClass: "modifyModel" },
              [
                _c("h1", [_vm._v(_vm._s(_vm.$t("label.splitUnloading")))]),
                _c("ips-field", {
                  attrs: { label: _vm.$t("label.totalTaskNum"), disabled: "" },
                  model: {
                    value: _vm.qtyPlan,
                    callback: function ($$v) {
                      _vm.qtyPlan = $$v
                    },
                    expression: "qtyPlan",
                  },
                }),
                _c("ips-input-number", {
                  ref: "modifyRef",
                  attrs: {
                    label: _vm.$t("label.dischargeNum"),
                    min: null,
                    "more-than-zero": "",
                    "not-auto-fill": "",
                  },
                  on: {
                    enter: function ($event) {
                      return _vm.skipNext("popupConfirmRef")
                    },
                    down: function ($event) {
                      return _vm.skipNext("popupConfirmRef")
                    },
                  },
                  model: {
                    value: _vm.qtyScan,
                    callback: function ($$v) {
                      _vm.qtyScan = $$v
                    },
                    expression: "qtyScan",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "ips-popup-footer" },
              [
                _c("hips-button", { on: { click: _vm.modifyCancel } }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("button.cancel")) +
                      "\n        "
                  ),
                ]),
                _c(
                  "hips-button",
                  {
                    ref: "popupConfirmRef",
                    staticStyle: { color: "#108EE9" },
                    on: { click: _vm.modifyConfirm },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("button.confirm")) +
                        "\n        "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
        _c(
          "hips-popup",
          {
            attrs: { "overlay-on-click-close": false, "z-index": 1 },
            model: {
              value: _vm.unloadingConfirmFlag,
              callback: function ($$v) {
                _vm.unloadingConfirmFlag = $$v
              },
              expression: "unloadingConfirmFlag",
            },
          },
          [
            _c(
              "div",
              { staticClass: "modifyModel" },
              [
                _c("h1", [_vm._v(_vm._s(_vm.$t("label.confirmDischarge")))]),
                _c("ips-lov", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.allowLocationReplace === "1"
                          ? _vm.customData["toLocationCode"]
                            ? _vm.hideControl["toLocationCode"]
                            : _vm.allowLocationReplace === "1"
                          : false,
                      expression:
                        "allowLocationReplace==='1' ? (customData['toLocationCode'] ? hideControl['toLocationCode'] : allowLocationReplace==='1') : false",
                    },
                  ],
                  ref: "ref20",
                  attrs: {
                    label: _vm.$t("label.targetLocation"),
                    title: _vm.$t("label.targetLocation"),
                    required: "",
                    params: "toLocationCode",
                    url:
                      _vm.winvBaseUrl +
                      "app-outbound-unload/query-to-locations",
                    "url-params": {
                      loadCidCode: _vm.loadCidCode,
                      loadCidId: _vm.loadCidId,
                    },
                    "pre-parse-url": _vm.urlData["toLocationCode"],
                    custom: _vm.customData["toLocationCode"],
                    "source-parse-field": _vm.sourceParseField,
                    "z-index": "9999",
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleCommonClick(20)
                    },
                    clickIcon: function ($event) {
                      return _vm.handleCommonClick(20, "lov")
                    },
                    choose: function ($event) {
                      return _vm.handleChoose("location", $event)
                    },
                    enter: _vm.handleLocationEnter,
                    setSourceField: function ($event) {
                      return _vm.saveSourceField($event)
                    },
                    valuedByFoucs: function ($event) {
                      return _vm.valuedByFoucs($event)
                    },
                  },
                  model: {
                    value: _vm.toLocationCode,
                    callback: function ($$v) {
                      _vm.toLocationCode = $$v
                    },
                    expression: "toLocationCode",
                  },
                }),
                _vm.allowLocationReplace === "0"
                  ? _c(
                      "hips-row",
                      { staticStyle: { "margin-bottom": "12px" } },
                      [
                        _c("hips-col", { attrs: { span: "7" } }, [
                          _c(
                            "span",
                            { staticClass: "coloumns-fieldname-left" },
                            [_vm._v(_vm._s(_vm.$t("label.targetLocation")))]
                          ),
                        ]),
                        _c("hips-col", { attrs: { span: "17" } }, [
                          _c("span", { staticClass: "popup-column-right" }, [
                            _vm._v(_vm._s(_vm.toLocationCode)),
                          ]),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "hips-row",
                  { staticStyle: { "margin-bottom": "12px" } },
                  [
                    _c("hips-col", { attrs: { span: "7" } }, [
                      _c("span", { staticClass: "coloumns-fieldname-left" }, [
                        _vm._v(_vm._s(_vm.$t("label.locationName"))),
                      ]),
                    ]),
                    _c("hips-col", { attrs: { span: "17" } }, [
                      _c("span", { staticClass: "popup-column-right" }, [
                        _vm._v(_vm._s(_vm.locationInfo.toLocationName)),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "hips-row",
                  { staticStyle: { "margin-bottom": "12px" } },
                  [
                    _c("hips-col", { attrs: { span: "7" } }, [
                      _c("span", { staticClass: "coloumns-fieldname-left" }, [
                        _vm._v(_vm._s(_vm.$t("label.targetWharea"))),
                      ]),
                    ]),
                    _c("hips-col", { attrs: { span: "17" } }, [
                      _c("span", { staticClass: "popup-column-right" }, [
                        _vm._v(_vm._s(_vm.locationInfo.toWhareaCode)),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "hips-row",
                  [
                    _c("hips-col", { attrs: { span: "7" } }, [
                      _c("span", { staticClass: "coloumns-fieldname-left" }, [
                        _vm._v(_vm._s(_vm.$t("label.whareaName"))),
                      ]),
                    ]),
                    _c("hips-col", { attrs: { span: "17" } }, [
                      _c("span", { staticClass: "popup-column-right" }, [
                        _vm._v(_vm._s(_vm.locationInfo.toWhareaName)),
                      ]),
                    ]),
                  ],
                  1
                ),
                _vm.showTargetTray
                  ? _c("ips-lov", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.customData["toCidCode"]
                            ? _vm.hideControl["toCidCode"]
                            : true,
                          expression:
                            "customData['toCidCode'] ? hideControl['toCidCode']: true",
                        },
                      ],
                      ref: "ref30",
                      attrs: {
                        label: _vm.$t("label.targetTray"),
                        title: _vm.$t("label.targetTray"),
                        "z-index": "9999",
                        params: "toCidCode",
                        url:
                          _vm.winvBaseUrl +
                          "app-outbound-unload/query-to-containers",
                        "url-params": {
                          toLocationCode: _vm.toLocationCode,
                          toLocationId: _vm.toLocationId,
                          allProcessFlag: _vm.allProcessFlag,
                          loadCidId: _vm.loadCidId,
                          loadCidCode: _vm.loadCidCode,
                        },
                        "pre-parse-url": _vm.urlData["toCidCode"],
                        custom: _vm.customData["toCidCode"],
                        "source-parse-field": _vm.sourceParseField,
                      },
                      on: {
                        blur: _vm.toCidBlur,
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.handleCommonClick(30)
                        },
                        clickIcon: function ($event) {
                          return _vm.handleCommonClick(30, "lov")
                        },
                        choose: function ($event) {
                          return _vm.handleChoose("cid", $event)
                        },
                        enter: function ($event) {
                          _vm.isReplace
                            ? _vm.skipNext(40)
                            : _vm.skipNext("popupConfirmRef")
                        },
                        setSourceField: function ($event) {
                          return _vm.saveSourceField($event)
                        },
                        valuedByFoucs: function ($event) {
                          return _vm.valuedByFoucs($event)
                        },
                      },
                      model: {
                        value: _vm.toCidCode,
                        callback: function ($$v) {
                          _vm.toCidCode = $$v
                        },
                        expression: "toCidCode",
                      },
                    })
                  : _vm._e(),
                (
                  _vm.isReplace
                    ? _vm.customData["unloadReplaceReasonCode"]
                      ? _vm.hideControl["unloadReplaceReasonCode"]
                      : _vm.isReplace
                    : false
                )
                  ? _c("ips-lov", {
                      ref: "ref40",
                      attrs: {
                        label: _vm.$t("label.replaceReason"),
                        title: _vm.$t("label.replaceReason"),
                        params: "meaning",
                        "is-page-content": false,
                        required: _vm.isReplace,
                        url: _vm.wmdmBaseUrl + "wms-common/lov-data-query",
                        "url-params": { lovCode: "WINV.OUTBOUND_LOAD_REASON" },
                        "pre-parse-url": _vm.urlData["unloadReplaceReasonCode"],
                        custom: _vm.customData["unloadReplaceReasonCode"],
                        "source-parse-field": _vm.sourceParseField,
                        "z-index": "9999",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.handleCommonClick(40)
                        },
                        clickIcon: function ($event) {
                          return _vm.handleCommonClick(40, "lov")
                        },
                        choose: function ($event) {
                          return _vm.handleChoose("replace", $event)
                        },
                        enter: function ($event) {
                          return _vm.skipNext("popupConfirmRef")
                        },
                        setSourceField: function ($event) {
                          return _vm.saveSourceField($event)
                        },
                        valuedByFoucs: function ($event) {
                          return _vm.valuedByFoucs($event)
                        },
                      },
                      model: {
                        value: _vm.unloadReplaceReasonCode,
                        callback: function ($$v) {
                          _vm.unloadReplaceReasonCode = $$v
                        },
                        expression: "unloadReplaceReasonCode",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "ips-popup-footer" },
              [
                _c("hips-button", { on: { click: _vm.unloadingCancel } }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("button.cancel")) +
                      "\n        "
                  ),
                ]),
                _c(
                  "hips-button",
                  {
                    ref: "popupConfirmRef",
                    staticStyle: { color: "#108EE9" },
                    on: { click: _vm.unloadingConfirm },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("button.confirm")))]
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-footer footer" },
      [
        _c("hips-checkbox", {
          attrs: { label: _vm.$t("button.allCheck") },
          model: {
            value: _vm.isAllChange,
            callback: function ($$v) {
              _vm.isAllChange = $$v
            },
            expression: "isAllChange",
          },
        }),
        _c(
          "hips-button",
          {
            ref: "submitRef",
            staticStyle: {
              "background-color": "var(--primaryColor)",
              color: "#FFFFFF",
              "border-radius": "4px",
            },
            attrs: { size: "large" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonSubmit.apply(null, arguments)
              },
            },
          },
          [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("button.submitUnloading")) + "\n    "
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }