import request from '@/request';
import mixin  from '@/mixin/mixin';

// 校验托盘
export function checkCidId(data) {
  let url = `${mixin.data().winvBaseUrl}app-inbound-putaway/check-outermoster-container`;
  return request.get(url, data);
}

// 处理托盘查询任务
export function dealWithCid(data) {
  let url = `${mixin.data().winvBaseUrl}app-inbound-putaway/whole-cid-assign`;
  return request.get(url, data)
}

// 确认整托上架明细 托盘校验
export function confirmCidDetail(data) {
  let url = `${mixin.data().winvBaseUrl}app-inbound-putaway/check-container-pack-object`;
  return request.get(url, data);
}

// 确认整托上架明细
export function confirmDetail(data) {
  let url = `${mixin.data().winvBaseUrl}app-inbound-putaway/whole-cid-inbound`;
  return request.post(url, data);
}

// 确认分托上架明细
export function confirmBatchesDetail(data) {
  let url = `${mixin.data().winvBaseUrl}app-inbound-putaway/task-putaway`;
  return request.post(url, data);
}

// 查询托盘明细
export function queryTrayDetail(data) {
  const url = `${mixin.data().winvBaseUrl}app-inbound-putaway/task-info`;
  return request.get(url, data);
}

// 查询所有序列号
export function querySerialList(data) {
  const url = `${mixin.data().winvBaseUrl}inbound-serial-receipts`;
  return request.get(url, data);
}

// 分托-确认托盘校验
export function checkCidCode(data) {
  const url = `${mixin.data().winvBaseUrl}app-inbound-putaway/task-cid-code-check`;
  return request.get(url, data);
}

// 分托-确认父托盘校验
export function checkParentCidCode(data) {
  const url = `${mixin.data().winvBaseUrl}app-inbound-putaway/task-parent-cid-code-check`;
  return request.get(url, data);
}
