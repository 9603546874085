<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header @beforeBack="beforeBack" :is-index="false" :title="$t('title.taskQuery')" />
    </div>
    <div class="ips-container">
      <!-- 拣货库区 -->
      <ips-lov
        :label="$t('label.pickingReservoir')"
        v-model="inputData.whareaCode"
        :title="$t('label.pickingReservoir')"
        params="whareaCode"
        display="whareaCode"
        :url="`${winvBaseUrl}app-wave-picking/wharea-lov`"
        :url-params="{ pageId: menuId }"
        :pre-parse-url="urlData['whareaCode']"
        ref="ref10"
        :custom="customData['whareaCode']"
        :source-parse-field="sourceParseField"
        v-show="customData['whareaCode'] ? hideControl['whareaCode'] : true"
        @click.stop="handleCommonClick(10)"
        @clickIcon="handleCommonClick(10, 'lov')"
        @enter="skipNext(20)"
        @down="skipNext(20)"
        @choose="handleChooseWhareaCode($event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 波次单号 -->
      <ips-lov
        :label="$t('label.waveNum')"
        :title="$t('label.waveNum')"
        v-model="inputData.waveNum"
        v-show="customData['waveNum'] ? hideControl['waveNum'] : true"
        params="waveBillCode"
        display="waveBillCode"
        :url-params="{ whareaId }"
        :url="`${winvBaseUrl}app-wave-picking/wave-order-lov`"
        :pre-parse-url="urlData['waveNum']"
        :custom="customData['waveNum']"
        :source-parse-field="sourceParseField"
        ref="ref20"
        is-control
        required
        @click.stop="handleCommonClick(20)"
        @clickIcon="handleCommonClick(20, 'lov')"
        @choose="handleChooseWaveNum($event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />


      <!-- 拣货小车 -->
      <ips-lov
        :label="$t('label.wavePickingCart')"
        :title="$t('title.wavePickingCart')"
        v-model="inputData.pickingCartCode"
        v-show="showPickingCart"
        params="pickingCartCode"
        display="pickingCartCode"
        :url-params="{ warehouseId }"
        :url="`${winvBaseUrl}picking-carts`"
        :pre-parse-url="urlData['pickingCartCode']"
        :custom="customData['pickingCartCode']"
        :source-parse-field="sourceParseField"
        ref="ref30"
        is-control
        required
        @click.stop="handleCommonClick(30)"
        @clickIcon="handleCommonClick(30, 'lov')"
        @choose="handleChoosePickingCart($event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />

      <!-- 物料 -->
      <ips-lov
        :label="$t('label.sku')"
        v-model="inputData.sku"
        :title="$t('label.sku')"
        params="sku"
        display="sku"
        :url="`${winvBaseUrl}app-wave-picking/sku-lov`"
        :url-params="{ sku: inputData.sku, waveBillId, whareaId, orgId, ownerId, waveLineType, }"
        :pre-parse-url="urlData['sku']"
        ref="ref40"
        :custom="customData['sku']"
        :source-parse-field="sourceParseField"
        @setSourceField="saveSourceField($event)"
        v-show="customData['sku'] ? hideControl['sku'] : true"
        @click.stop="handleCommonClick(40)"
        @clickIcon="handleCommonClick(40, 'lov')"
        @choose="handleChooseSku($event)"
        @enter="skipNext('submitRef')"
        @down="skipNext('submitRef')"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 订单总数 -->
      <ips-value-cell
        :title="$t('label.totalNumberOfOrders')"
        :value="resInfo.orderCount || 0"
      />
      <!-- 物料种类数-->
      <ips-value-cell
        :title="$t('label.numberOfMaterialTypes')"
        :value="resInfo.skuCount || 0"
      />
      <!-- 波次单物料总数-->
      <ips-value-cell
        :title="$t('label.waveOrderTotalNumberOfMaterials')"
        :value="resInfo.planQty || 0"
      />
      <!-- 已拣物料总数 -->
      <ips-value-cell
        :title="$t('label.totalNumberOfMaterialsCollected')"
        :value="resInfo.pickedQty || 0"
      />
      <!-- 未拣货数量 -->
      <ips-value-cell
        :title="$t('label.unpickedQuantity')"
        :value="resInfo.unPickedQty || 0"
      />
      <!-- 备注说明-->
      <ips-value-cell
        :title="$t('label.remark')"
        :value="resInfo.remark || '无'"
      />
      <div class="ips-footer">
        <!--提交 @click="handleSub"-->
        <hips-button
          size="large"
          ref="submitRef"
          @click.stop="handleCommonSubmit"
        >
          {{ $t('button.confirm') }}
        </hips-button>
      </div>
    </div>
  </hips-view>
</template>

<script>
import { storage } from '@/utils';
// eslint-disable-next-line no-unused-vars
import { queryTaskSummary, validateWave, validatePickingCart, clearTask } from '../api/index';
export default {
  name: 'WavePicking',
  data() {
    return {
      // 库位ID
      whareaId: '',
      // 仓库ID
      warehouseId: '',
      // 数量展示对象
      resInfo: {},
      // 入库单id
      waveBillId: '',
      waveLineType: '',
      // 界面上的输入框数据
      inputData: {
        // 拣货库区
        whareaCode: '',
        // 波次单号
        waveNum: '',
        // 物料
        sku: '',
      },
      orgId: '',
      ownerId: '',
      inputFieldList: [
        { type: 'lov', required: false, ref: 10, bind: 'inputData.whareaCode', field: 'whareaCode' },
        { type: 'lov', required: true, ref: 20, bind: 'inputData.waveNum', field: 'waveNum' },
        { type: 'lov', required: false, ref: 30, bind: 'inputData.sku', field: 'sku' },
      ],
      menuId: storage.get('menuId'),
      urlData: {},
      customData: {}, // 个性化配置信息列表
      hideControl: {}, // 非必输字段的显隐控制
      sourceParseField: {}, // 界面中所有的来源解析字段
    };
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['pageFunctionParams'],
  computed: {
    // 是否展示拣货小车
    // 功能参数 BIND_PICKING_CART = 1 则显示，否则不显示
    showPickingCart(ctx) {
      return ctx.pageFunctionParams ? ctx.pageFunctionParams['BIND_PICKING_CART'] === '1' : false
    }
  },
  watch: {
    '$route': {
      handler(to) {
        // 不是来自task详情页面，就清除task任务
        if (to && to.params.fromTaskPage !== 1) {
          clearTask({})
        }
      },
      immediate: true
    }
  },
  async created() {
    this.$hint.hideLoading();
    const { hideControl, customObj } = this.handleCustomData(storage.get('customData') || []);
    this.customData = customObj;
    this.hideControl = hideControl;
    storage.remove('waveOrderItem');
  },
  mounted() {
    // 测试数据
    // 波次单号 WAVE202204220002
    // 物料： QY001
    // 序列号：041319~042920
    // 物料：QYPC_001
    // 批次：2022033001
    this.urlData = this.handleData('WAVE_PICK_QUERY');
    this.skipNext(10);
  },
  methods: {
    // 保存来源解析字段
    async saveSourceField(fieldObj) {
      this.saveSourceParseField(this, fieldObj);
    },
    // 字段聚焦时赋值操作
    async valuedByFoucs(options = {}) {
      const { custom = {}, currentValue } = options;
      await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
    },
    beforeBack() {
      // 返回首页
      this.$router.push('/applications');
      return Promise.resolve();
    },
    // 下个页面再去处理
    handleButtonSubmit() {
      const { boundSortingPosFlag} = this.resInfo
      let param = {
        name: 'taskExecutionWave',
        query: {
          waveBillId: this.waveBillId,
          goodsId: this.goodsId,
          whareaId: this.whareaId,
          waveLineType: this.waveLineType,
        },
      };

      if (this.showPickingCart) {
        Object.assign(param.query, {
          pickingCartId: this.pickingCartId,
          boundSortingPosFlag,
          bindPickingCart: this.showPickingCart ? 1 : 0
        })
      }
      this.$router.pushPage(param);
    },
    // 处理物料
    handleChooseSku(obj) {
      this.goodsId = obj.goodsId;
    },
    // 拣货库区
    handleChooseWhareaCode(obj) {
      this.whareaId = obj.whareaId;
    },
    // 处理选择波次单号
    handleChooseWaveNum(obj) {
      this.waveBillId = obj.waveBillId;
      this.orgId = obj.orgId;
      this.ownerId = obj.ownerId;
      this.warehouseId = obj.warehouseId;
      this.waveLineType = obj.waveLineType;
      const data = {
        params: {
          waveBillId: obj.waveBillId,
          waveLineType: this.waveLineType,
        },
      };


      // if (this.showPickingCart) {
      //   Object.assign(data.params, {
      //     waveLineType: 'RELATED_LINE'
      //   })
      // }
      storage.set('waveOrderItem', obj);
      validateWave({
        ...obj,
        waveLineType: this.waveLineType,
      }).then((res) => {
        if (res?.failed) {
          this.$hint.showFailed(res);
          this.skipNext(20);
        } else {
          queryTaskSummary(data).then((res) => {
            this.resInfo = res;
            if (this.showPickingCart) {
              this.skipNext('30');
            } else {
              this.skipNext('40');
            }
          });
        }
      })
    },

    // 处理选择拣货小车
    handleChoosePickingCart(obj) {
      const {id: pickingCartId, pickingCartCode, status: pickingCartStatus, latticeNumber, tenantId} = obj || {}
      this.pickingCartId = pickingCartId;
      validatePickingCart({
        tenantId,
        waveBillId: this.waveBillId,
        pickingCartId,
        pickingCartCode,
        pickingCartStatus,
        latticeNumber,
        waveLineType: this.waveLineType,
      }).then((res) => {
        if (res?.failed) {
          this.$hint.showFailed(res);
          this.skipNext(30);
        } else {
          Object.assign(this.resInfo, {
            boundSortingPosFlag: res.boundSortingPosFlag
          })
          this.skipNext('40');
        }
      })
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~@/style/var.styl';

.ips-container >>> .hips-cell__title {
  flex: 2 !important;
}
</style>
