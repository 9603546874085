var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: { title: _vm.$t("title.outboundShipment") },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _c("ips-lov", {
          ref: "ref20",
          attrs: {
            label: _vm.$t("label.outboundOrderR"),
            title: _vm.$t("label.outboundOrderR"),
            params: "billCode",
            display: "billCode",
            url: _vm.winvBaseUrl + "app-outbound-ship/query-shippable-order",
            "pre-parse-url": _vm.urlData["billCode"],
            "is-only": "",
          },
          on: {
            enter: function ($event) {
              return _vm.skipNext("submitRef")
            },
            down: function ($event) {
              return _vm.skipNext("submitRef")
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(20)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(20, "lov")
            },
            choose: function ($event) {
              return _vm.handleChoose("billCode", $event)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.inputData.billCode,
            callback: function ($$v) {
              _vm.$set(_vm.inputData, "billCode", $$v)
            },
            expression: "inputData.billCode",
          },
        }),
        _vm.orderData.waveBillCode || _vm.orderData.billCode
          ? _c(
              "div",
              { staticClass: "out_box" },
              [
                _vm.orderData.waveBillCode
                  ? _c("ips-value-cell", {
                      attrs: {
                        title: "波次单号",
                        "image-flag": "",
                        value: _vm.orderData.waveBillCode,
                      },
                    })
                  : _vm._e(),
                _vm.orderData.billCode
                  ? _c("ips-value-cell", {
                      attrs: {
                        title: _vm.$t("label.outboundOrderR"),
                        "image-flag": "",
                        value: _vm.orderData.billCode,
                      },
                    })
                  : _vm._e(),
                _c("ips-value-cell", {
                  attrs: {
                    title: _vm.$t("label.totalFreightVolume"),
                    "image-flag": "",
                    value: _vm.orderData.billPickQty,
                  },
                }),
                _c("ips-value-cell", {
                  attrs: {
                    title: _vm.$t("label.processedCcapacity"),
                    "image-flag": "",
                    value: _vm.orderData.billShipQty,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-footer" },
      [
        _c(
          "hips-button",
          {
            ref: "submitRef",
            staticClass: "ips-btn-normal",
            attrs: { size: "large", type: "warning" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonSubmit.apply(null, arguments)
              },
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleCommonSubmit.apply(null, arguments)
              },
              keypress: function ($event) {
                $event.preventDefault()
              },
            },
          },
          [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("button.batchshipment")) + "\n    "
            ),
          ]
        ),
        !_vm.orderData.forbidSelectTaskFlag
          ? _c(
              "hips-button",
              {
                staticClass: "ips-btn-normal",
                attrs: { size: "large", type: "warning" },
                on: { click: _vm.goDetail },
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("button.detailedShipping")) +
                    "\n    "
                ),
              ]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }