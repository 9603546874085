var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "hips-view",
    { attrs: { "header-fixed": true } },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("ips-header", {
            attrs: { title: _vm.$t("title.secondarySorting") + "-播种式" },
            on: { onChecked: _vm.onChecked },
          }),
        ],
        1
      ),
      _vm.normalScanning
        ? _c(
            "div",
            { staticClass: "ips-container" },
            [
              _c("ips-lov", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.customData["waveNum"]
                      ? _vm.hideControl["waveNum"]
                      : true,
                    expression:
                      "customData['waveNum'] ? hideControl['waveNum'] : true",
                  },
                ],
                ref: "ref0",
                attrs: {
                  label: _vm.$t("label.waveNum"),
                  title: _vm.$t("label.waveNum"),
                  params: "waveBillCode",
                  display: "waveBillCode",
                  url:
                    _vm.winvBaseUrl + "app-sorting-seeding/query-wave-orders",
                  "is-control": "",
                  required: "",
                  "pre-parse-url": _vm.urlData["waveNum"],
                  custom: _vm.customData["waveNum"],
                  "source-parse-field": _vm.sourceParseField,
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleCommonClick(0)
                  },
                  clickIcon: function ($event) {
                    return _vm.handleCommonClick(0, "lov")
                  },
                  enter: function ($event) {
                    return _vm.skipNext(10)
                  },
                  down: function ($event) {
                    return _vm.skipNext(10)
                  },
                  choose: function ($event) {
                    return _vm.handleChoose("waveNum", $event)
                  },
                  setSourceField: function ($event) {
                    return _vm.saveSourceField($event)
                  },
                  valuedByFoucs: function ($event) {
                    return _vm.valuedByFoucs($event)
                  },
                },
                model: {
                  value: _vm.waveNum,
                  callback: function ($$v) {
                    _vm.waveNum = $$v
                  },
                  expression: "waveNum",
                },
              }),
              _c("ips-field", {
                ref: "ref10",
                attrs: {
                  label: _vm.$t("label.sku"),
                  required: "",
                  "pre-parse-url": _vm.urlData["scanSku"],
                  custom: _vm.customData["scanSku"],
                  "source-parse-field": _vm.sourceParseField,
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleCommonClick(10)
                  },
                  down: function () {
                    return _vm.handleBlur("scanSku", _vm.scanSku)
                  },
                  enter: function () {
                    return _vm.handleBlur("scanSku", _vm.scanSku)
                  },
                  setSourceField: function ($event) {
                    return _vm.saveSourceField($event)
                  },
                  valuedByFoucs: function ($event) {
                    return _vm.valuedByFoucs($event)
                  },
                },
                model: {
                  value: _vm.scanSku,
                  callback: function ($$v) {
                    _vm.scanSku = $$v
                  },
                  expression: "scanSku",
                },
              }),
              _c("ips-field", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.scanType,
                    expression: "scanType",
                  },
                ],
                ref: "ref20",
                attrs: {
                  label:
                    _vm.scanType === "BATCH"
                      ? _vm.$t("label.batchCodeNew")
                      : _vm.$t("label.serialNumber"),
                  required: "",
                  "pre-parse-url": _vm.urlData["barCode"],
                  custom: _vm.customData["barCode"],
                  "source-parse-field": _vm.sourceParseField,
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleCommonClick(20)
                  },
                  down: function () {
                    return _vm.handleBlur("barCode", _vm.barCode)
                  },
                  enter: function () {
                    return _vm.handleBlur("barCode", _vm.barCode)
                  },
                  setSourceField: function ($event) {
                    return _vm.saveSourceField($event)
                  },
                  valuedByFoucs: function ($event) {
                    return _vm.valuedByFoucs($event)
                  },
                },
                model: {
                  value: _vm.barCode,
                  callback: function ($$v) {
                    _vm.barCode = $$v
                  },
                  expression: "barCode",
                },
              }),
              _c("div", { staticClass: "sow-location" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("message.recommendedSeedingPosition")) +
                    "\n      "
                ),
                _c("div", { staticClass: "num-box" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.orderInformation.sortingPosition) +
                      "\n      "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "recommended-number" }, [
                _c("div", { staticClass: "left" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("message.recommendedNumber")) +
                      "\n      "
                  ),
                ]),
                _c("div", { staticClass: "right" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.orderInformation.suggestQty) +
                      "\n      "
                  ),
                ]),
              ]),
              _c("ips-input-number", {
                ref: "ref30",
                staticStyle: { margin: "0" },
                attrs: {
                  required: "",
                  disabled: !_vm.currentNumCanEdit,
                  label: _vm.$t("label.quantity"),
                  "pre-parse-url": _vm.urlData["quantity"],
                  custom: _vm.customData["quantity"],
                  "source-parse-field": _vm.sourceParseField,
                  min: null,
                  "more-than-zero": true,
                  "not-auto-fill": "",
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleCommonClick(30)
                  },
                  enter: _vm.checkQty,
                  down: _vm.checkQty,
                  setSourceField: function ($event) {
                    return _vm.saveSourceField($event)
                  },
                  valuedByFoucs: function ($event) {
                    return _vm.valuedByFoucs($event)
                  },
                },
                model: {
                  value: _vm.quantity,
                  callback: function ($$v) {
                    _vm.quantity = $$v
                  },
                  expression: "quantity",
                },
              }),
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "hips-button",
                    {
                      ref: "submitRef",
                      staticClass: "ips-btn-normal",
                      attrs: { size: "large" },
                      on: { click: _vm.showSwitchTenantFun },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("message.scanTheDetail")) +
                          "\n      "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("ips-value-cell", {
                attrs: {
                  title: _vm.$t("label.outboundOrderR"),
                  value: _vm.orderInformation.billCode,
                },
              }),
              _c("ips-value-cell", {
                attrs: {
                  title: _vm.$t("label.documentsNote"),
                  value: _vm.orderInformation.remark || "无",
                },
              }),
              _c("ips-value-cell", {
                attrs: {
                  title: _vm.$t("label.packageNo"),
                  value: _vm.orderInformation.packCode,
                },
              }),
              _c(
                "div",
                { staticClass: "count_block_box" },
                [
                  _c("ips-number-count", {
                    staticClass: "count-scannedQuantity",
                    attrs: {
                      count: _vm.orderInformation.scanQty || 0,
                      "text-field": "scannedQuantity",
                    },
                  }),
                  _c("ips-number-count", {
                    staticClass: "count-totalScans",
                    attrs: {
                      count: _vm.orderInformation.sortedQty || 0,
                      "text-field": "totalScans",
                    },
                  }),
                  _c("ips-number-count", {
                    staticClass: "count-totalNumber",
                    attrs: {
                      count: _vm.orderInformation.planQty || 0,
                      "text-field": "totalNumber",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "ips-container" },
            [
              _c("ips-lov", {
                ref: "ref0",
                attrs: {
                  label: _vm.$t("label.waveNum"),
                  title: _vm.$t("label.waveNum"),
                  params: "waveBillCode",
                  display: "waveBillCode",
                  disabled: "",
                  url:
                    _vm.winvBaseUrl + "app-sorting-seeding/query-wave-orders",
                  "is-control": "",
                  required: "",
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleCommonClick(0)
                  },
                  clickIcon: function ($event) {
                    return _vm.handleCommonClick(0, "lov")
                  },
                  enter: function ($event) {
                    return _vm.skipNext(10)
                  },
                  down: function ($event) {
                    return _vm.skipNext(10)
                  },
                  choose: function ($event) {
                    return _vm.handleChoose("waveNum", $event)
                  },
                },
                model: {
                  value: _vm.waveNum,
                  callback: function ($$v) {
                    _vm.waveNum = $$v
                  },
                  expression: "waveNum",
                },
              }),
              _c("ips-field", {
                ref: "ref10",
                attrs: { label: _vm.$t("label.sku"), required: "" },
                on: {
                  down: function () {
                    return _vm.handleBlur("scanSku", _vm.scanSku)
                  },
                  enter: function () {
                    return _vm.handleBlur("scanSku", _vm.scanSku)
                  },
                },
                model: {
                  value: _vm.scanSku,
                  callback: function ($$v) {
                    _vm.scanSku = $$v
                  },
                  expression: "scanSku",
                },
              }),
              _c("ips-field", {
                ref: "ref20",
                attrs: { label: _vm.$t("label.barCode"), required: "" },
                on: {
                  down: function () {
                    return _vm.handleBlur("barCode", _vm.barCode)
                  },
                  enter: function () {
                    return _vm.handleBlur("barCode", _vm.barCode)
                  },
                },
                model: {
                  value: _vm.barCode,
                  callback: function ($$v) {
                    _vm.barCode = $$v
                  },
                  expression: "barCode",
                },
              }),
              _vm.notNormalScanningItem.sortingPosition
                ? _c("div", { staticClass: "sow-location" }, [
                    _vm._v("\n      播种位\n      "),
                    _c("div", { staticClass: "num-box" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.notNormalScanningItem.sortingPosition) +
                          "\n      "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _c("ips-input-number", {
                ref: "ref30",
                attrs: {
                  required: "",
                  label: _vm.$t("label.quantity"),
                  min: null,
                  "more-than-zero": true,
                  "not-auto-fill": "",
                },
                on: { enter: _vm.checkQty, down: _vm.checkQty },
                model: {
                  value: _vm.quantity,
                  callback: function ($$v) {
                    _vm.quantity = $$v
                  },
                  expression: "quantity",
                },
              }),
            ],
            1
          ),
      _c(
        "div",
        { staticClass: "ips-footer" },
        [
          _c(
            "hips-button",
            {
              staticClass: "ips-btn-normal",
              attrs: { size: "large" },
              on: { click: _vm.handleSub },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("button.sortConfirm")) + "\n    "
              ),
            ]
          ),
          _c(
            "hips-button",
            {
              staticClass: "ips-btn-normal",
              attrs: { size: "large" },
              on: { click: _vm.endBoxChange },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("button.inCasesOf")) + "\n    ")]
          ),
        ],
        1
      ),
      _c(
        "hips-popup",
        {
          attrs: { position: "bottom" },
          model: {
            value: _vm.showSwitchTenantPopup,
            callback: function ($$v) {
              _vm.showSwitchTenantPopup = $$v
            },
            expression: "showSwitchTenantPopup",
          },
        },
        [
          _c("div", { staticClass: "popup-title" }, [
            _c("div", { staticClass: "left", on: { click: _vm.cleanPage } }, [
              _vm._v(
                "\n        " + _vm._s(_vm.$t("button.clear")) + "\n      "
              ),
            ]),
            _c("div", { staticClass: "center" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("message.scanTheDetail")) +
                  "\n      "
              ),
            ]),
            _c(
              "div",
              { staticClass: "right", on: { click: _vm.deleteIsSelectItem } },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("button.delete")) + "\n      "
                ),
              ]
            ),
          ]),
          _vm.orderInformation.waveSortingScanDTOS
            ? _c(
                "div",
                {
                  staticClass: "ips-table",
                  staticStyle: {
                    "overflow-y": "scroll",
                    "padding-bottom": "30px",
                  },
                },
                [
                  _c(
                    "table",
                    [
                      _c(
                        "tr",
                        { staticStyle: { "background-color": "#eee" } },
                        _vm._l(_vm.tableTitle, function (item, index) {
                          return _c(
                            "th",
                            { key: index, attrs: { scope: "col" } },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(item.name) +
                                  "\n          "
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                      _vm._l(
                        _vm.orderInformation.waveSortingScanDTOS,
                        function (item, index) {
                          return _c(
                            "tr",
                            {
                              key: index,
                              ref: "tr" + index,
                              refInFor: true,
                              style: item.isSelect ? "background: #ccc;" : "",
                              on: {
                                click: function ($event) {
                                  return _vm.selectItem(index)
                                },
                              },
                            },
                            [
                              _c("td", [_vm._v(_vm._s(item.sortingPosition))]),
                              _c("td", [_vm._v(_vm._s(item.sku))]),
                              _c("td", [_vm._v(_vm._s(item.serialNumber))]),
                              _c("td", [_vm._v(_vm._s(item.batchCode))]),
                              _c("td", [_vm._v(_vm._s(item.qty))]),
                              _c("td", [_vm._v(_vm._s(item.packCode))]),
                            ]
                          )
                        }
                      ),
                    ],
                    2
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }