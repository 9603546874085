var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: { title: _vm.$t("title.outboundDelivery") },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _vm.deliveryMode
          ? _c(
              "hips-radio-group",
              {
                attrs: { horizontal: "" },
                model: {
                  value: _vm.deliveryMode,
                  callback: function ($$v) {
                    _vm.deliveryMode = $$v
                  },
                  expression: "deliveryMode",
                },
              },
              [
                _c(
                  "hips-radio",
                  {
                    key: "ORDER",
                    attrs: { option: "ORDER", position: "right" },
                  },
                  [_vm._v("\n        出库单交接\n      ")]
                ),
                _c(
                  "hips-radio",
                  { key: "WAVE", attrs: { option: "WAVE", position: "right" } },
                  [_vm._v("\n        波次单交接\n      ")]
                ),
              ],
              1
            )
          : _vm._e(),
        _c("ips-input", {
          ref: "ref0",
          attrs: {
            label: _vm.$t("label.packageNo"),
            custom: _vm.customData["packCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            enter: _vm.checkPackCodeBlur,
            down: _vm.checkPackCodeBlur,
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(0)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.inputData.packCode,
            callback: function ($$v) {
              _vm.$set(_vm.inputData, "packCode", $$v)
            },
            expression: "inputData.packCode",
          },
        }),
        _c("ips-value-cell", {
          attrs: {
            title: _vm.$t("label.carrier"),
            "image-flag": "",
            value: _vm.orderData.carrierName,
          },
        }),
        _vm.deliveryMode === "WAVE"
          ? _c("ips-value-cell", {
              attrs: {
                title: _vm.$t("label.waveNum"),
                "image-flag": "",
                value: _vm.orderData.waveBillCode,
              },
            })
          : _vm._e(),
        _vm.deliveryMode === "ORDER"
          ? _c("ips-value-cell", {
              attrs: {
                title: _vm.$t("label.outboundOrderR"),
                "image-flag": "",
                value: _vm.orderData.billCode,
              },
            })
          : _vm._e(),
        _c("ips-value-cell", {
          attrs: {
            title: "已录箱数 ",
            "image-flag": "",
            value: _vm.orderData.scannedCount,
          },
        }),
        _c("ips-value-cell", {
          attrs: {
            title: "已交接数",
            "image-flag": "",
            value: _vm.orderData.deliveredCount,
          },
        }),
        _c("ips-value-cell", {
          attrs: {
            title: "未交接数",
            "image-flag": "",
            value: _vm.orderData.unDeliveredCount,
          },
        }),
        _c("ips-value-cell", {
          attrs: {
            title: "未复核数",
            "image-flag": "",
            value: _vm.orderData.unReviewedCount,
          },
        }),
        _c(
          "div",
          { staticClass: "ips-footer" },
          [
            _c(
              "hips-button",
              {
                ref: "submitRef",
                attrs: { size: "large" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleCommonSubmit.apply(null, arguments)
                  },
                },
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("button.confirm")) + "\n      "
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }