<template>
  <hips-view>
    <div class="mine-container">
      <div class="header-box g-flex">
        <div class="left-img">
          <img src="./resources/default-header.png" alt="">
        </div>
        <div class="right-info">
          <div class="top-role">
            {{ realName }}
          </div>
          <div class="tag-box g-flex">
            <div class="tag-item">
              角色 {{ roleName }}
            </div>
            <div class="tag-item">
              租户 {{ tenantName }}
            </div>
          </div>
        </div>
      </div>
      <div class="top-box-warp">
        <div class="top-box">
          <div class="item" @click="selectLag">
            <div class="icon">
              <img src="./resources/new-switch-yuyan.png" alt="" srcset="">
            </div>
            <div class="text">
              {{ $t('title.switchLanguage') }}
            </div>
            <div class="arrow-right">
              <img src="./resources/arrow-right.png" alt="">
            </div>
          </div>
          <div class="item" @click="selectTenant">
            <div class="icon">
              <img src="./resources/new-switch-zuhu.png" alt="" srcset="">
            </div>
            <div class="text">
              {{ $t('title.switchTenants') }}
            </div>
            <div class="arrow-right">
              <img src="./resources/arrow-right.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="logo">
        <img src="./resources/logo.png" alt="" srcset="">
      </div>
      <!-- 退出登录 -->
      <div class="submit-box">
        <div class="submit-btn" @click="logout">
          {{ $t('button.logout') }}
        </div>
      </div>
    </div>

    <!-- 切换语言 -->
    <hips-popup v-model="showSwitchLanPopup" position="bottom">
      <hips-picker :data="langsColumns" value-key="name" :title="$t('title.switchLanguage')" @confirm="handleConfirm($event, 'lan')" />
    </hips-popup>
    <!-- 切换租户 -->
    <hips-popup v-model="showSwitchTenantPopup" position="bottom">
      <hips-picker
        :data="tenantsColumns"
        value-key="tenantName"
        :title="$t('title.switchTenants')"
        @confirm="handleConfirm($event, 'tenant')"
      />
    </hips-popup>
  </hips-view>
</template>

<script>
import { storage } from '@/utils';
import { appLogout } from '@/request';
// eslint-disable-next-line no-unused-vars
import { getLovCodeList, setLovCode, setTenantId, getSelfTenants } from './api';
import { getUsersSelf } from '@/public/api';
import { Popup, Picker } from '@hips/vue-ui';
export default {
  name: 'Mine',
  components: {
    [Popup.name]: Popup,
    [Picker.name]: Picker,
  },
  data() {
    return {
      // 切换租户
      showSwitchTenantPopup: false,
      // 切换language
      showSwitchLanPopup: false,
      tenantsColumns: [],
      langsColumns: [],
      checked: this.$storage.get('language') === 'en',
      tenantName: this.$storage.get('tenantName'),
      realName: this.$storage.get('realName'),
      roleName: this.$storage.get('currentRoleName'),
    };
  },
  mounted() {},
  methods: {
    // 切换租户
    selectTenant() {
      getSelfTenants().then((res) => {
        this.showSwitchTenantPopup = true;
        this.tenantsColumns = res;
      });
    },
    // 确认选择语言
    handleConfirm(values, type) {
      if (type === 'lan') {
        setLovCode({
          language: `${values[0].value.code}`,
        }).then(() => {
          this.showSwitchLanPopup = false;
          this.getUsersSelfFun();
        });
      } else if (type === 'tenant') {
        const selectItem = this.tenantsColumns[values[0].index] || {};
        setTenantId({
          tenantId: `${selectItem.tenantId}`,
        }).then(() => {
          this.showSwitchTenantPopup = false;
          this.getUsersSelfFun();
        });
      }
    },
    // 获取用户信息更新之后
    getUsersSelfFun() {
      getUsersSelf().then((res) => {
        this.$storage.set('tenantId', res.tenantId);
        this.$storage.set('tenantName', res.tenantName);
        this.$storage.set('realName', res.realName);
        this.$storage.set('currentRoleName', res.currentRoleName);
        this.$storage.set('language', res.language);

        Object.assign(this.$data, {
          realName: res.realName,
          tenantName: res.tenantName,
          roleName: res.currentRoleName,
        })
        this.$i18n.locale = res.language === 'zh_CN' ? 'zh' : 'en';
      });
    },
    // 选择语言
    selectLag() {
      // 获取可以切换的语言
      getLovCodeList().then((res) => {
        this.showSwitchLanPopup = true;
        this.langsColumns = res;
      });
    },
    // 切换语言
    switchLanguage(val) {
      let language = val ? 'en' : 'zh_CN';
      this.$storage.set('language', language);
      this.$hint.showLoading();
      // 重新渲染一下页面 简单做
      this.$router.go(0);
    },
    // 注销登录的方法
    logout() {
      appLogout({
        accessToken: this.$storage.get('user-token'),
      }).then((res) => {
        console.log(res);
      });
      storage.clear();
      this.$router.pushPage('login');
    },
  },
};
</script>

<style lang="stylus" scoped>
 @import '~@/style/var.styl';
.mine-container {
    background: #F6F7FA;
    height: 100vh;
    .header-box {
        height: 160px;
        box-sizing: border-box;
        background-color: #ffbe37;
        padding: 70px 10px 10px 10px;

        .left-img {
            img {
                width: 50px;
                border: 2px solid white;
                border-radius: 50%;
            }
        }

        .right-info {
            padding-left: 20px;
            padding-top: 4px;

            .top-role {
                font-size: 18px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #222222;
            }

            .tag-box {
                padding-top: 10px;

                .tag-item {
                    height: 21px;
                    background: #222222;
                    border-radius: 10px;
                    color: white;
                    font-size: 11px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                    line-height: 21px;
                    padding: 0 10px;
                    margin-right: 5px;
                }
            }
        }
    }
}
.top-box-warp{
    padding: 0 10px;
    .top-box:after{
        content: '';
        display: inline-block;
        width:1px;
        height:16px;
        background:#EAEAEA;
        position: absolute;
        left:50%;
        top:50%;
        margin-top: -8px;
    }
    .top-box {
        display: flex;
        background: white;
        margin-bottom: 10px;
        border: 5px;
        position: relative;
        top: -25px;
        border-radius: 5px;

        .item {
            flex: 1;
            display: flex;
            justify-content: center;
            height: 50px;
            line-height: 50px;
            .icon {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 5px;
                img {
                    width: 25px;
                }
            }
            .text {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #222222;
            }
            .arrow-right{
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 20px;
                img {
                    width: 15px;
                }
            }
        }
    }
}

.logo {
    padding-top: 60px;
    text-align: center;
    opacity: 0.3;
    img {
        width: 116px;
    }
}

.submit-box {
    display: flex;
    justify-content: center;

    .submit-btn {
        width: 131px;
        height: 38px;
        background: #FFBE37;
        border-radius: 20px;
        text-align: center;
        line-height: 38px;
        color: #222222;
        font-size: 13px;
        letter-spacing: 1px;
        margin-top: 40px;
    }
}
</style>
