export default {
  home: 'Home',
  application: 'Application',
  statistics: 'Statistics',
  mine: 'Mine',
  title: {
    labelPrint:'labelPrint',
    isShowPrintTag: 'isShowPrintTag',
    workManagement: 'Work management',
    analyse: 'analyse',
    allreceive: 'allreceive',
    warehouseManagement: 'warehouseManagement',
    expressReceipts: 'expressReceipts',
    switchTenants: 'switchTenants',
    switchLanguage: 'switchLanguage',
    outboundShipment: 'Outbound-Shipment',
    isShowEn: 'Is Show English',
    accept: 'Receive',
    acceptConfirm: 'Receipt confirm',
    acceptDetail: 'Order Receipt Detail',
    associatedSite: 'Map Sort Loc',
    batchInventory: 'Batch Stock',
    batchQuery: 'Batch Query',
    batchConversion: 'Batch Conversion',
    batchDecomposition: 'Batch Decomposition',
    belongWarehouse: 'Warehouse',
    belongWharea: 'Wharea',
    areaType: 'Area Type',
    locationType: 'Location Type',
    serialNumber: 'Serial Detail',
    bulkStockTransfer: 'Goods Transfer',
    inSundry: 'In sundry',
    outSundry: 'Out sundry',
    sundryReason: 'Reason',
    cancelPallet: 'Unpack',
    pallet: 'Pallet',
    cidDetail: 'LPN Detail',
    count: 'Stock Count',
    countDetail: 'Stock Count Details',
    directInBound: 'Direct INBOUND',
    quickInBound: 'Quick INBOUND',
    directOut: 'Direct OUBOUND',
    outboundDelivery: 'Outbound Delivery',
    revokeDelivery: 'Revoke Delivery',
    inventoryCheck: 'Stock Inquiry',
    interceptTally: 'Intercept Tally',
    importedDetail: 'Imported Detail',
    warehouseStock: 'Warehouse Stock',
    whareaStock: 'Wharea Stock',
    locationStock: 'Location Stock',
    detailStock: 'Detail Stock',
    queryCodeNotNull: 'Query criteria cannot be empty',
    subinventoryTransfer: 'Sub Pallet Transfer',
    inventoryTransfer: 'Full Pallet Transfer',
    ipsBatch: 'Create Batch',
    ipsSerial: 'Serial Num Entry',
    locationInventory: 'Location Stock',
    identification: 'location identification',
    palletsTask: 'Put Away Task',
    rackingTasks: 'Put Away Details',
    ReceivingAdjustment: 'Receipt Adjust',
    searchTray: 'LPN',
    shelvesLoad: 'Put Away',
    skuCode: 'Goods Code',
    taskQuery: 'Inquiry Task',
    trayDetail: 'Tray Details',
    taskDetail: 'Task Details',
    trayDetailShow: 'LPN Details',
    trayInfoShow: 'LPN info',
    unloading: 'Drop',
    taskExecution: 'Transact Task',
    singleProcessing: 'Work Order Execute',
    secondarySorting: 'Secondary Sorting',
    waveNum: 'Wave Order',
    wavePickingCart: 'Picking Cart',
    nodata: 'No Data',
    directlyShelves: 'Directly Shelves',
    receiveDetail: 'Receive Detail',
    ipsNewSerial: 'Serial Num Detail',
    detail: 'Detail',
    acceptCidDetail: 'Cid Detail',
    loading: 'Load',
    receiveAdjustment: 'Receive Adjustment',
    multiLpnPack: 'Multi layer palletizing / revocation',
    code: 'Code',
    name: 'Name',
    batchMarge: 'Batch Marge',
    cancelPick: 'Cancel Pick',
    warehouseCode: 'Warehouse Code',
    warehouseName: 'Warehouse Name',
    batchList: 'Batch List',
    skuPropertyUpdate: 'Material property update',
    roundsTransform: 'Material Transform',
    serialChange: 'Serial Transform',
  },
  label: {
    locationTran: 'location transform',
    transQty: 'transform qty',
    sourceSku: 'sourceSku',
    fromWhareaName: 'fromWhareaName',
    fromWhareaCode: 'fromWhareaCode',
    accumulateQty: 'AccumulateQty',
    packageQty: 'packageQty',
    casesNum: 'casesNum',
    tailBoxNum: 'tailBoxNum',
    thisInventoryQty: 'inventoryQty',
    haveInventoryQty: 'haveInventoryQty',
    finalCountQty: 'finalCountQty',
    printer:'printer',
    printMould:'printMould',
    operation: "operation",
    expressBackCode: 'expressBackCode',
    expressBackTypeName: 'expressBackTypeName',
    province: 'province',
    city: 'city',
    pieceQty: 'pieceQty',
    productCode: 'productCode',
    expressQty: 'expressQty',
    jobType: 'jobType',
    planTheNumberOf: 'planTheNumberOf',
    estimatedDeliveryDate: 'est-Delivery',
    lotOrBoxCode: 'Lot/box code',
    serialNumberOrBoxCode: 'Serial number/box code',
    boxCode: 'Box code',
    nameOfTheMaterial: 'nameOfTheMaterial',
    remark: 'remark',
    unpickedQuantity: 'unpickedQuantity',
    totalNumberOfMaterialsCollected: 'totalNumberMaterialsCollected',
    waveOrderTotalNumberOfMaterials: 'waveTotalNumberMaterials',
    numberOfMaterialTypes: 'Number of material types',
    totalNumberOfOrders: 'totalNumberOfOrders',
    pickingReservoir: 'pickingReservoir',
    batchCodeNew: 'batchCode',
    scanningValue: 'scanningValue',
    noQuantityChecked: 'quantityChecked',
    quantityChecked: 'quantityChecked',
    orderNumberNew: 'The order number',
    packagingMaterials: 'packagingMaterials',
    courierNumber: 'Package No. / tracking No',
    checkTheDimensionOrderNumber: 'Check the dimension order number',
    packageNo: 'Pack-no',
    documentsNote: 'Doc-note',
    totalNumber: 'totalNumber',
    totalScans: 'totalScans',
    scannedQuantity: 'scannedQuantity',
    barCode: 'bar-code',
    batchconfirmation: 'batchconfirmation',
    processedCcapacity: 'processedCcapacity',
    roleName: 'Role Name',
    tenantName: 'Tenant Name',
    userName: 'User Name',
    password: 'Password',
    quantityToBeTreated: 'Quantity-To-Be-Treated',
    processedCapacity: 'Processed-Capacity',
    totalFreightVolume: 'Total-Volume',
    availableQty: 'On-Hand',
    enteredQty: 'EnteredQty',
    batch: 'Batch',
    batchControl: 'Batch Control',
    batchSerial: 'Batch',
    battcStatus: 'Batch Status',
    billCode: 'Inbound Order',
    billId: 'Order Num',
    billType: 'Order Type',
    batchQuery: 'Batch',
    batchSerialDetail: 'Batch',
    belongOrg: 'Org Name',
    canOutQty: 'Available Qty',
    confirmBatchCode: 'Confirm Batch',
    confirmDischarge: 'Confirm Drop',
    confirmLocation: 'Confirm Location',
    confirmNum: 'Confirm Qty',
    confirmSku: 'Confirm Goods',
    confirmWharea: 'Confirm Area',
    conformTray: 'Confirm LPN',
    confirmParentTray: 'Confirm Parent LPN',
    countLocation: 'Location',
    countLocationCode: 'Location Code',
    countPrecisionMeaning: 'Stock Count Level',
    countType: 'Stock Count Way',
    countQty: 'Stock Count Qty',
    countTypeMeaning: 'Stock Count Type',
    customer: 'Customer',
    deliveryCode: 'Order Num',
    dischargeNum: 'Drop Qty',
    detail: 'Detail',
    demandQty: 'DemandQty',
    unfold: 'Unfold',
    exceptionReason: 'Exception Reason',
    expireDate: 'Effective Date',
    fixReason: 'Revised Reason',
    instructions: 'You can enter the following classification codes to search in turn:',
    goodsName: 'Goods Name',
    goodsStatus: 'Goods Status',
    category: 'Category Name',
    GRN: 'Inbound Order',
    billInfo: 'In Order-Line Num',
    inQty: 'Received Qty',
    inventoryCode: 'Order Num',
    inventoryQty: 'Inbound Qty',
    lineNum: 'Line Num',
    detailLineNum: 'Line Num',
    receiveWarehouse: 'Receive Warehouse',
    location: 'Receive Location',
    locationName: 'Location Name',
    locationUseQty: 'Location Available Qty',
    nextCheckDate: 'Next Inspection Date',
    outQty: 'Outbound Qty',
    owner: 'Owner',
    pendingInQty: 'Remaining Qty',
    pickQty: 'Picking Qty',
    pigeonhole: 'Pigeonhole',
    planQty: 'Required Qty',
    planSumQty: 'Required Sum Qty',
    plantQty: 'LPN Qty',
    produceDate: 'Production Date',
    putAwayTray: 'Put Away LPN',
    putAwayParentTray: 'Put Away Parent LPN',
    qtyPlan: 'Picking Qty',
    qtyWillPlan: 'Pending Quantity',
    quantity: 'Qty',
    queryConditions: 'Inquiry Filed',
    queryType: 'Inquiry Type',
    receiveNum: 'Receiving Qty',
    receivedQty: 'Received Qty',
    receiveQty: 'Receive Qty',
    receiveSumQty: 'Received Sum Qty',
    receivingTray: 'Receiving LPN',
    replaceReason: 'Replace Reason',
    resourceNum: 'Source Order Num',
    revisionNumber: 'Revised Number',
    serialNumber: 'Serial Num',
    serialControl: 'Serial Control',
    sourceSerialNumber: 'fromSerialNumber',
    targetSerialNumber: 'toSerialNumber',
    shelvesNum: 'Put Away Qty',
    sku: 'Goods',
    skuCode: 'Goods Code',
    sourceLocation: 'Source Location',
    sourceTray: 'Source LPN',
    sourceWharea: 'Source Area',
    sourceOfWharea: 'Source Area',
    sourceBatch: 'Source Batch',
    splitUnloading: 'Split Drop',
    split: 'Split',
    startOfLocation: 'Source Location',
    startOfTray: 'Source LPN',
    startOfParentTray: 'Source Parent LPN',
    status: 'Status',
    storageWarehouse: 'Inbound Warehouse',
    storageLocation: 'Inbound Location',
    storageTray: 'Inbound LPN',
    stockQuery: 'Inquiry Filed',
    suggestedBatch: 'Suggested Batch',
    suggestedWhareaCode: 'Suggested WhareaCode',
    suggestedLocation: 'Suggested Location',
    suggestedCidCode: 'Suggested CidCode',
    targetBatch: 'Destination Batch',
    targetLocation: 'Destination Location',
    targetTray: 'Destination Container',
    targetParentTray: 'Destination Parent Container',
    transferFrom: 'Transfer From',
    transferTo: 'Transfer To',
    targetWharea: 'Destination Area',
    taskCode: 'Order Num',
    taskNum: 'Task Qty',
    taskNumber: 'Task Num',
    theScanQty: 'Txn Qty',
    tips: 'Tips',
    toBeEnterQty: 'To Be Enter Qty',
    toCidCode: 'Transfer LPN',
    totalTaskNum: 'Task Sum Qty',
    transferNumber: 'Transfer Qty',
    tray: 'LPN',
    parentTray: 'Parent LPN',
    trayName: 'LPN Name',
    trayStatus: 'LPN Status',
    trayAvailability: 'LPN Available On-Hand',
    trayCurrentQty: 'LPN On-Hand',
    trayState: 'LPN Status',
    unit: 'UOM',
    unReceiveQty: 'Pending Qty',
    unReceiveSumQty: 'Pending Sum Qty',
    useQty: 'Available Qty',
    carrier: 'Carrier',
    vendor: 'Supplier',
    vendorCode: 'Supplier Code',
    vendorNumber: 'Supplier Batch Num',
    warehouse: 'Warehouse',
    whareaCode: 'Area',
    whareaName: 'Area Name',
    workStation: 'Work Num',
    batchCode: 'Batch',
    note: 'Remark',
    org: 'Organization',
    orgName: 'Organization Name',
    ownerName: 'Owner Name',
    ownerCode: 'Owner',
    qualityStatus: 'Quality Status',
    supplierCode: 'Supplier Code',
    totalNum: 'Total Qty',
    fromWarehouse: 'Source Warehouse',
    toWarehouse: 'Destination Warehouse',
    waveNum: 'Wave Order',
    wavePickingCart: 'Picking Cart',
    itemCode: 'Item Code',
    outboundOrder: 'Outbound Order - Line Order',
    outboundOrderR: 'OutboundOrder',
    sowLocation: 'Sow Location',
    pickNum: 'Pick Number',
    waitSortNum: 'Wait Sort Number',
    processQtyUpdate: 'Process Quantity Update ',
    processingNum: 'Work Order',
    linenumSku: 'Line number/Goods',
    processingType: 'Work Type',
    assemblyArea: 'Assemble Wharea',
    assemblyLocation: 'Assemble Location',
    parentSku: 'Parent Goods',
    currentUseQty: 'Available Qty',
    toBeProcessed: 'To Be Executed Qty',
    processingQty: 'Executed Qty',
    childSku: 'Child Of Goods',
    shelvesCid: 'Container',
    shelvesParentCid: 'Parent Container',
    shelvesBillNum: 'Source Number',
    shelvesInboundNum: 'Inbound Number',
    shelvesReceiveNum: 'Receive Number',
    shelvesWarehouse: 'Warehouse',
    shelvesReceiveQty: 'Receive Quantity',
    shelvesVendor: 'vendor',
    shelvesCustomer: 'Customer',
    shelvesBatch: 'Batch',
    shelvesQuantity: 'shelvesQuantity',
    shelvesLineNum: 'Line Number',
    shelvesUom: 'Uom',
    shelvesDetail: 'Shelves Detail',
    shelvesPendingQty: 'To Process Quantity',
    inboundNum: 'Inbound Quantity',
    inboundCid: 'Inbound Container',
    recommandLoca: 'Recommand Location',
    receiveCid: 'Receive Container',
    shouldEntry: 'Should Entry Quantity',
    alreadyEntry: 'Already Entry Quantity',
    stayEntry: 'Stay Entry Quantity',
    receiptNumber: 'Receipt Number',
    unreceiveQty: 'Not receiveQty',
    uomName: 'Uom',
    uom: 'Uom',
    orderLine: 'Line Number',
    locationCode: 'Location',
    orderNumber: 'Order Number',
    amount: 'Amount',
    defaultWarehouse: 'Default Warehouse',
    chooseWarehouse: 'Choose Warehouse',
    cidMsg: 'Cid message',
    reason: 'Adjustment Reason',
    receiveAdjustQty: 'Receive Adjustment Quantity',
    reserveQty: 'Reserve Quantity',
    oldReceiveQty: 'Original Received Quantity',
    multiPack: 'Palletizing',
    multiPackCancel: 'Revoke Palletizing',
    parentCid: 'Parent Container',
    childCid: 'Child Container',
    sourceMessage: 'Source Message',
    executionMessage: 'Execution Message',
    sourceTypeName: 'Source Type Name',
    warehouseName: 'Warehouse Name',
    cidCode: 'Cid Code',
    validQty: 'validQty',
    qty: 'qty',
    parentCidCode: 'Parent shelves cid',
    inboundParentCid: 'Inbound parent cid',
    adjust: 'Adjust',
    cancelReceipt: 'Cancel receipt',
    measurementStandard: 'Measurement standard',
    qtyInBox: 'Quantity in the box',
    weight: 'Weighth',
    length: 'Length',
    width: 'Width',
    height: 'Height',
    weightBox: 'Weighth(box)',
    lengthBox: 'Length(box)',
    widthBox: 'Width(box)',
    heightBox: 'Height(box)',
    weightUnit: 'Unit of weighth',
    lengthUnit: 'Unit of length',
    widthUnit: 'Unit of width',
    heightUnit: 'Unit of height',
  },
  button: {
    configuration: 'configuration',
    more: 'more',
    haveToDealWith: 'Processed',
    taskToBeProcessed: 'noProcessed',
    changeTheLocation: 'changeTheLocation',
    confirmThePicking: 'confirmThePicking',
    checkTheDetails: 'checkTheDetails',
    closeBtn: 'closeBtn',
    inCasesOf: 'inCasesOf',
    skuItemNo: 'skuItemNo',
    shipConfirm: 'shipConfirm',
    detailedShipping: 'Detailed shipping',
    batchshipment: 'Batch Shipment',
    login: 'Login',
    logout: 'Logout',
    back: 'Back',
    batches: 'Split LPN',
    batchInventory: 'Batch Stock',
    cancel: 'Cancel',
    save: 'Save',
    cidDetail: 'LPN Detail',
    confirm: 'Confirm',
    confirmRevoke: 'Unload',
    conversion: 'Conversion',
    decomposition: 'Decomposition',
    conversionConfirm: 'Conversion Confirm',
    decompositionConfirm: 'Decomposition Confirm',
    edit: 'Edit',
    delete: 'Delete',
    next: 'Next',
    importedDetail: 'Imported Detail',
    detail: 'Detail',
    entireTray: 'Full Pallet',
    locationInventory: 'Location Stock',
    overTask: 'Close Task',
    pickAndUnload: 'Pick And Drop',
    pickConfirm: 'Pick Confirm',
    search: 'Inquiry',
    query: 'Query',
    serialNumEntry: 'Serial Num Entry',
    serialDetail: 'Serial Detail',
    prePackCidDetail: 'Prepackaged pallet details',
    skipTask: 'Skip Task',
    submit: 'Submit',
    submitUnloading: 'Drop',
    switchTask: 'Switch Task',
    taskDetail: 'Task Details',
    theDetail: 'Detail',
    confirmSNDetail: 'Confirm SN Detail',
    sortConfirm: 'Confirm Sort',
    associatedConfirm: 'Confirm Associated',
    processComplete: 'Work Completed',
    receiveDetail: 'Receive Detail',
    serialNumber: 'Serial Num',
    serialEntry: 'Entry',
    clear: 'Clear',
    allCheck: 'All Check',
    uom: 'Uom',
    orderLine: 'Order Line',
    selectTrayDetail: 'Select Tray Detail',
    locationCode: 'Location Code',
    seriesDetail: 'Series Detail',
    loadingCidCode: 'Loading tray',
    waitLoading: 'To be loaded',
    alreadyLoading: 'installed',
    loadingMessage: 'load message',
    buttonPack: 'Palletizing',
    cancellation: 'Bulk cargo cancellation',
    allCancellation: 'Cancellation of whole care',
    allBreakUp: 'One key to break up',
    marge: 'Marge',
    margeConfirm: 'Marge Confirm',
    backTop: 'Back Top',
    cancelReceipt: 'Cancel receipt',
  },
  message: {
    analyseItemEave: 'Check by wave order number',
    analyseItemOrder: 'Check by order number',
    analyseItemTracking: 'Check according to the tracking number',
    scanTheDetail: 'Scan the detail',
    recommendedNumber: 'Recommended number',
    recommendedSeedingPosition: 'Recommended seeding position',
    checkBatchCode: 'Batch not exist, whether to create',
    checkBatchSame: 'Please confirm that suggested batch is consistent with confirmed batch',
    checkRequired: 'Please complete required field ',
    checkSkuSame: 'Please confirm that goods is consistent with confirmed goods',
    checkTask: 'The destination location of selected task is not unique and system does not support location replacement. Please check',
    checkTray: 'LPN does not exist, whether to create the LPN?',
    checkTrayNotAll: 'There is reservation in LPN which cannot full pallet transfer',
    chooseOneAtLeast: 'At least one task needs to be selected',
    cidNotNull: 'Put away LPN can not be null',
    countBillCheck: 'Order not exist or wrong status, please check',
    countGoodsCheck: 'Goods in this location has been counted, whether re-count',
    countLocationCkeck: 'Location is not in the warehouse of order, please check',
    countPlantsCkeck: 'LPN already exists in other location, please check',
    createPlantSuccess: 'Create LPN successfully',
    enterAtLeastOne: 'Please enter inquiry filed',
    findNull: 'Can not find{label}',
    findNullNormal: 'Can not find data, please check and re-enter',
    findNullNormalWithTask: 'Cid no record of direct material receipt, and no despatching is allowed',
    inputSuccess: 'Enter successfully, please continue',
    lastDataTips: 'This is final line of data',
    limitDischargeQty: 'Drop Qty could not exceed total Qty of the task',
    notFoundTask: 'No processable task was found',
    operationSuccess: 'Operation successfully',
    operationFailed: 'Operation Failed',
    outBillCheck: 'Order not exist or wrong status, please check',
    qtyInPlant: 'On-hand is in LPN, please enter LPN',
    qtyMustBeGreaterThanZero: 'Qty must be more than or equal 0',
    allQtyMustBeGreaterThanZero: 'Total transfer qty must be more than 0',
    qtyMustBeSmallerThanValidQty: 'Transfer quantity cannot be greater than available quantity',
    qtyMustBeSmallerThanValidFromQty: 'The recorded quantity plus the current entered quantity is greater than the source quantity',
    FromQtyNotEqualEntered: 'Total entered quantity is not equal to source quantity, cannot confirm the split',
    FromQtyHaveBeenEntered: 'All source quantities have been entered. You do not need to click next to confirm the split',
    transferQtyMustEqualValidQty: 'Serial number quantity is not equal to transfer quantity',
    transferQtyNotZero: 'The number of summary operations cannot be 0',
    qtyOverrunReEnterSerial: 'Transfer quantity cannot be greater than available quantity. Please re-enter the serial Num',
    reEnter: 'Inconsistent with the task, please re-enter',
    serialAllSuccess: 'All serial Num entered successfully',
    serialRepeat: 'The serial Num is repeated. Please re-enter',
    skipTaskSuccess: 'Skip task successfully',
    startCidNotNull: 'Source LPN can not be null',
    taskSubmitSuccess: 'Complete task',
    toAndFromLocationNotSame: 'Source location must be different from destination location',
    transferSuccess: 'Transfer successfully',
    unknownError: 'Unknown error has occurred. Please contact the administrator',
    unloadingSuccess: 'Drop successfully',
    sortingSuccess: 'Sorting Success',
    wareSuccess: 'Inbound successfully',
    pullOnLoading: 'More',
    noMoreData: 'No more data',
    refreshSuccess: 'Refresh success',
    noData: 'No data',
    mustChooseSerial: 'Select SN',
    batchCreatedSuccess: 'Batch created success',
    selectReplaceReason: 'Please select the replacement reason',
    dataDuplication: 'Duplicate data, please enter again',
    mustEnter: 'Required',
    notAllowDirectPick: 'Order parameter does not allow direct picking',
    waveOrderNull: 'The order number of the wave can not query the material',
    waveOrderNumCheck: 'The number of executions must not be greater than the number to be sorted',
    waveOrderMsgChoose: 'Please select the documents before sorting',
    waveOrderNumCheckMin: 'The number of executions must be greater than 0',
    quantityMin: 'Quantity must be greater than 0',
    workQtyWarnOne: 'Executed Qty must be greater than 0',
    workQtyWarnTwo: 'Executed Qty must be less than or equal to Remaining Executed Qty',
    lackMaterial:
      'The available quantity of material {label} in the assembly location is insufficient, so this' + ' operation cannot be performed',
    checkSerialQty: 'The number to be recorded and the number to be recorded are not the same, sure you want to' + ' leave',
    repeatSerial: 'The serial number already exists',
    deleteSerial: 'The serial number already exists. Delete will be performed. Confirm to execute',
    allDeleteSerial: 'Are you sure you want to clear the currently entered serial number',
    checkImpQty: 'Current quantity to be recorded{label}and recorded quantity{labelTwo}Inconsistent, please modify',
    receivedNumberHaved:
      'The receiving record already exists in the receiving number. Do you want to continue adding the receiving record under the receiving number',
    unSerialMsg: 'The stock in quantity cannot be greater than the quantity to be processed',
    unBatchSerialMsg: 'Inbound qty can not be greater than the batch quantity to be processed by ',
    showCheckQty: 'The quantity to be recorded is equal to the recorded quantity. Please enter after deleting',
    checkInboudReceiveQty: 'The stock in quantity cannot be greater than the demand quantity',
    checkCidSerialNum:
      'The receiving pallet is not fully stored in the warehouse, and it is not allowed to use the receiving tray for direct shelf mounting',
    checkDefinedInboundSerialReceiptList:
      'The remaining receive quantity is equal to quantity of pre-defined serial numbers, please enter the pre-defined serial number',
    checkAlreadyHasAcceptRecord:
      'The receive number already exists. Do you want to continue adding receive record under this receive number',
    autoCreatingReceiptNumber: 'Automatically generating receive number...',
    receiptNumberSuccess: 'Receive number generated successfully',
    confirmDeletion: 'Whether to confirm deletion?',
    checkAlreadyHasAcceptRecordInTray:
      'The pallet has a receiving record under other receiving numbers, whether to allow the same pallet to receive mixed receiving numbers',
    checkBackPath:
      'There is unsaved data in the current page. If it is returned, it will not be saved automatically. Are you sure to return？',
    countQtyCheck: 'Count quantity cannot be equal to 0',
    checkParentCid: 'This pallet already exists on the current parent pallet and cannot be palletized again',
    sortOnlyOne: 'The sorting quantity of materials controlled by serial number can only be 1',
    checkSerialBatch: 'The current serial number has been entered in other source batches Cannot be entered' + ' repeatedly',
    limitScanQty: 'Drop Qty must be greater than 0.',
    noAutoEnter: 'Automatically skipping is not allowed',
    loading: 'In the load...',
    qtyNeedLessAvailable: 'Input quantity should be less than or equal to the amount available',
    checkWarehouseIdNotSame: 'The selected location does not belong to the warehouse specified in the order line, please re select.',
    checkQtyScanWithSerialNumber: 'The total number of entered serial numbers must be equal to the number of scans',
    thisIsParentTran: 'The input tray is the parent tray. Please note that all the items will be issued!',
    showCheckReceiveQty:
      'The number of serial number input is less than the number of scanning, and the number of outer layers will be automatically replaced when clicking OK',
    restAutoCancel: 'The remaining number of tasks is automatically cancelled',
    restOfTask: 'Number of remaining tasks',
    autoCancelStart: 'Will automatically cancelled',
    requiredField: 'Required field',
    retrievalValueField: 'Default value retrieval failed',
    checkAppConfig: 'Please check the App personalization configuration',
    // checkParentWithCidCode: 'The warehouse location of the parent pallet and the warehouse location of the incoming pallet are inconsistent with the filled warehouse location',
    checkParentWithCidCodeStr1: 'The storage location of the parent pallet',
    checkParentWithCidCodeStr2: 'With the storage location of the inbound pallet',
    checkParentWithCidCodeStr3: 'Not same',
    scanBatchIsNotEqualBatch: 'Scan batch is not consistent with the batch specified in the order',
    outQtyNeedLessThanValidQty: 'Outbound quantity must be less than or equal to inventory availability',
    judgeCidReceiveNumber: 'The cid is stored in other receiving lines and cannot enter into the rectifier processing',
    cidNoData: 'Tray no data',
    parameterInformationIncomplete: 'The parameter information is incomplete'
  },
  exception: {
    noData: 'No Data',
  }
};
