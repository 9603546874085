var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "page_box",
        { page_box_task_comfirm: _vm.pageBoxTaskComfirmClass },
      ],
    },
    [
      _c(
        "div",
        { staticClass: "page_head_title" },
        _vm._l(_vm.titleInfo, function (val, index) {
          return _c("span", { key: index, staticClass: "title_span" }, [
            _vm._v("\n      " + _vm._s(val) + "\n    "),
          ])
        }),
        0
      ),
      _c("div", { staticClass: "page_head_content" }, [
        _vm.reactField.length > 0
          ? _c(
              "div",
              _vm._l(_vm.reactField, function (key, index) {
                return _c("div", { key: index, staticClass: "content" }, [
                  _vm._v("\n        " + _vm._s(_vm.$t("label." + key)) + ": "),
                  _c("span", { staticClass: "content_span_value" }, [
                    _vm._v(_vm._s(_vm.contentInfo[key])),
                  ]),
                ])
              }),
              0
            )
          : _vm._e(),
        _vm.reactField.length === 0
          ? _c(
              "div",
              _vm._l(_vm.contentInfo, function (val, key, index) {
                return _c("div", { key: index, staticClass: "content" }, [
                  _vm._v("\n        " + _vm._s(_vm.$t("label." + key)) + ": "),
                  _c("span", { staticClass: "content_span_value" }, [
                    _vm._v(_vm._s(val)),
                  ]),
                ])
              }),
              0
            )
          : _vm._e(),
        _vm.footerMessage
          ? _c(
              "div",
              {
                staticClass: "content message-content",
                attrs: { slot: "right" },
                slot: "right",
              },
              [_vm._v("\n      " + _vm._s(_vm.footerMessage) + "\n    ")]
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }