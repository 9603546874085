<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.rackingTasks')" :is-index="false" />
    </div>
    <div class="ips-container">
      <!-- 上架托盘 -->
      <ips-value-cell
        :title="$t('label.putAwayTray')"
        :value="formData.cidCode"
        v-show="customData['cidCode'] ? hideControl['cidCode'] : true"
      />
      <!-- 上架父托盘 -->
      <ips-value-cell
        :title="$t('label.putAwayParentTray')"
        :value="formData.parentCidCode"
        v-show="!requireParentCidCode ? false : customData['parentCidCode'] ? hideControl['parentCidCode'] : formData.parentCidCode"
      />
      <!-- 物料 -->
      <ips-value-cell :title="$t('label.sku')" :value="formData.skuShow" v-show="customData['skuShow'] ? hideControl['skuShow'] : true" />
      <!-- 品名 -->
      <ips-value-cell
        :title="$t('label.goodsName')"
        :value="formData.goodsName"
        v-show="customData['goodsName'] ? hideControl['goodsName'] : true"
      />
      <!-- 单位 -->
      <ips-value-cell :title="$t('label.unit')" :value="formData.uomName" v-show="customData['uomName'] ? hideControl['uomName'] : true" />
      <!-- 确认物料 -->
      <ips-field
        :label="$t('label.confirmSku')"
        v-model="formData.confirmSku"
        v-show="customData['confirmSku'] ? hideControl['confirmSku'] : true"
        required
        params="confirmSku"
        display="confirmSku"
        :pre-parse-url="urlData['confirmSku']"
        :custom="customData['confirmSku']"
        :source-parse-field="sourceParseField"
        ref="ref0"
        @blur="() => handleSkipBlur('ref0', 'sku', formData.confirmSku)"
        @enter="() => handleSkip('ref0')"
        @click.stop="handleCommonClick(0)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 建议批次 -->
      <ips-value-cell
        :title="$t('label.suggestedBatch')"
        :value="formData.batchCodeShow"
        v-show="isbatch ? (customData['batchCodeShow'] ? hideControl['batchCodeShow'] : isbatch) : false"
      />
      <!-- 确认批次 -->
      <ips-field
        :label="$t('label.confirmBatchCode')"
        v-model="formData.confirmBatchCode"
        v-show="customData['confirmBatchCode'] ? hideControl['confirmBatchCode'] : true"
        required
        params="confirmBatchCode"
        display="confirmBatchCode"
        :pre-parse-url="urlData['confirmBatchCode']"
        :custom="customData['confirmBatchCode']"
        :source-parse-field="sourceParseField"
        ref="ref10"
        v-if="isbatch"
        @blur="() => handleSkipBlur('ref10', 'batchCode', formData.confirmBatchCode)"
        @enter="() => handleSkip('ref10')"
        @click.stop="handleCommonClick(10)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 确认托盘 -->
      <ips-field
        :label="$t('label.conformTray')"
        v-model="formData.toCidCodeByBatches"
        v-show="customData['confirmBatchCode'] ? hideControl['confirmBatchCode'] : true"
        params="toCidCode"
        display="toCidCode"
        :pre-parse-url="urlData['toCidCodeByBatches']"
        :custom="customData['toCidCodeByBatches']"
        :source-parse-field="sourceParseField"
        ref="ref20"
        @blur="handleCodeBlur"
        @enter="handleCodeSkip"
        @down="handleCodeSkip"
        @click.stop="handleCommonClick(20)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 确认父托盘 -->
      <ips-field
        :label="$t('label.confirmParentTray')"
        v-model="formData.toParentCidCode"
        v-show="!requireParentCidCode ? false : customData['toParentCidCode'] ? hideControl['toParentCidCode'] : true"
        params="toParentCidCode"
        :pre-parse-url="urlData['toParentCidCode']"
        :custom="customData['toParentCidCode']"
        :source-parse-field="sourceParseField"
        :disabled="!formData.toCidCodeByBatches || disableParentCid"
        ref="ref25"
        @blur="handleParentCodeBlur"
        @click.stop="handleCommonClick(25)"
        @enter="handleSkip('ref25')"
        @down="handleSkip('ref25')"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 建议库位 -->
      <ips-value-cell
        :title="$t('label.suggestedLocation')"
        :value="formData.toLocationCodeShow"
        v-show="customData['toLocationCodeShow'] ? hideControl['toLocationCodeShow'] : true"
      />
      <!-- 确认库位 -->
      <ips-lov
        :label="$t('label.confirmLocation')"
        :title="$t('label.confirmLocation')"
        required
        :disabled="disabledFlag"
        v-model="toLocationCodeByBatches"
        v-show="customData['toLocationCodeByBatches'] ? hideControl['toLocationCodeByBatches'] : true"
        params="locationCode"
        :url="`${wmdmBaseUrl}wms-locations/lov`"
        :url-params="{ warehouseId, locationType: 'T_STOCK' }"
        :pre-parse-url="urlData['toLocationCodeByBatches']"
        :custom="customData['toLocationCodeByBatches']"
        :source-parse-field="sourceParseField"
        ref="ref30"
        @click.stop="handleCommonClick(30)"
        @clickIcon="handleCommonClick(30, 'lov')"
        @enter="handleSkip('ref30')"
        @down="handleSkip('ref30')"
        @choose="chooseConfirmLocation"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 任务数量 -->
      <ips-value-cell
        :title="$t('label.taskNum')"
        :value="formData.qtyPlan"
        v-show="customData['qtyPlan'] ? hideControl['qtyPlan'] : true"
      />
      <!-- 序列号录入 -->
      <div class="input-button" v-if="showSerialFlag">
        <hips-button @click="goToSerial" ref="inputRef" :style="{ ...inputBtnStyle }">
          {{ $t('button.serialNumEntry') }}
        </hips-button>
      </div>
      <!-- 确认数量 -->
      <ips-input-number
        :label="$t('label.confirmNum')"
        v-model="formData.qtyScan"
        v-show="customData['qtyScan'] ? hideControl['qtyScan'] : true"
        :min="null"
        :more-than-zero="true"
        not-auto-fill
        required
        :custom="customData['qtyScan']"
        :source-parse-field="sourceParseField"
        ref="ref40"
        :max="formData.qtyPlan"
        @click.stop="handleCommonClick(40)"
        :disabled="enableSerialFlag || disQtyScan"
        @enter="skipNext('submitRef')"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
    </div>
    <div class="ips-footer">
      <hips-button size="large" @click.stop="handleCommonSubmit" ref="submitRef">
        <svg-icon slot="icon" icon-class="success" />
        {{ $t('button.confirm') }}
      </hips-button>
      <hips-button v-show="customData['switchTask'] ? hideControl['switchTask'] : true" size="large" @click="switchTask">
        {{ $t('button.switchTask') }}
      </hips-button>
    </div>
  </hips-view>
</template>

<script>
import { confirmBatchesDetail, checkCidCode, querySerialList, checkParentCidCode } from '../api';
export default {
  name: 'Batches',
  data() {
    return {
      isbatch: 0, //是否启用批次管理
      enableSerialFlag: false, //是否启用序列号管理  同时控制确认数量是否可编辑
      showSerialFlag: false, //是否显示序列号
      allTaskList: [], //当前托盘下所有任务列表
      currentTaskData: [], //当前任务数据
      detailId: '',
      inboundSerialReceiptList: [], //输入成功序列号返回数据集合
      toLocationCodeByBatches: '', //确认库位
      warehouseId: '', // 库位id
      formData: {
        confirmSku: '', //确认物料
        confirmBatchCode: '', //确认批次
        toCidCodeByBatches: '', //确认托盘
        toParentCidCode: '', //确认父托盘
        cidCode: '', // 上架托盘
        parentCidCode: '', // 上架父托盘
        goodsName: '', //品名
        uomName: '', //单位
        batchCodeShow: '', //建议批次
        toLocationCodeShow: '', //建议库位
        qtyPlan: undefined, //任务数量
        qtyScan: undefined, //确认数量
        serialNumber: '', //序列号
      },
      locationValuedByParent: false,
      skuPass: false,
      batchCodePass: false,
      serialPass: true,
      confirmDisabled: false,
      disabledFlag: false,
      disableParentCid: false, // 父托盘禁用
      disQtyScan: false,
      urlData: {},
      customData: {}, // 个性化配置信息列表
      hideControl: {}, // 个性化配置显示隐藏控制
      sourceParseField: {}, // 界面中所有的来源解析字段
      requireParentCidCode: 0, // 页面功能参数控制是否启用父托盘
    };
  },
  computed: {
    isAllow() {
      const { toLocationCodeByBatches } = this;
      const { confirmSku, confirmBatchCode, qtyScan } = this.formData;
      let batchFlag = this.isbatch ? !!confirmBatchCode : true;
      let qtyFlag = this.showSerialFlag ? true : qtyScan;
      return !!confirmSku && batchFlag && !!toLocationCodeByBatches && !!qtyFlag;
    },
  },
  methods: {
    async goToSerial() {
      const data = {
        params: {
          cidCode: this.$route.params.cidCode,
          detailId: this.detailId,
          status: '2-RECEIVED',
          serialNumber: '',
          size: 1000,
        },
      };
      const res = await querySerialList(data);
      const param = {
        name: 'inputSerial',
        params: {
          fromPutaway: true,
          showInputBox: false,
          task: res.content || [],
        },
      };
      this.$router.pushPage(param);
    },
    /**
     *@functionName: saveSourceField
     *@description: 保存来源解析字段并赋值Enter
     *@params1: fieldObj[Object] 来源解析字段对象,
     fieldCode[String] 来源解析字段
     parseValue[String] 二维码解析值
     subFields[Array]] 关联子字段
     */
    saveSourceField(fieldObj) {
      this.saveSourceParseField(this, fieldObj);
    },
    // 字段聚焦时赋值操作
    async valuedByFoucs(options = {}) {
      const { custom = {}, currentValue } = options;
      await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
    },
    /**
     * 确认托盘失焦事件
     */
    async handleCodeBlur() {
      if (this.enableSerialFlag) {
        this.showSerialFlag = !(this.formData.toCidCodeByBatches === this.formData.cidCode && this.allTaskList.length === 1);
      }
      if (this.formData.toCidCodeByBatches) {
        this.$hint.showLoading();
        const data = {
          params: {
            cidCode: this.formData.toCidCodeByBatches, //当前填写的确认托盘
            fromCidCode: this.formData.cidCode, //上架托盘
            fromParentCidCode: this.formData.parentCidCode,
            fromParentCidId: this.formData.parentCidId,
            locationId: this.formData.toLocationId,
            locationCode: this.toLocationCodeByBatches, //确认库位
            warehouseId: this.formData.warehouseId,
          },
        };
        try {
          const res = await checkCidCode(data);
          const { parentCidCode } = res;
          if (res.locationCode && res.locationId) {
            // 判断是否与当前存在的父托盘库位一致，不一致就报错
            if (this.formData.toParentCidCode && this.toParentLocationIdByBatches && this.toParentLocationIdByBatches !== res.locationId) {
              this.$hint.showFailed(this.$i18n.t('message.checkParentWithCidCode'));
              return;
            }
            this.toLocationCodeByBatches = res.locationCode;
            this.toLocationIdByBatches = res.locationId;
            this.formData.toLocationId = res.locationId;
            this.disabledFlag = true;
            this.handleSkip('ref20');
          } else {
            this.skipNext(25);
          }
          if (parentCidCode) {
            this.formData.toParentCidCode = parentCidCode;
            this.toParentCidCode = parentCidCode;
            // 输入托盘不等于上架托盘
            if (this.formData.cidCode !== this.formData.toCidCodeByBatches) {
              this.$nextTick(() => {
                this.disableParentCid = true;
                console.log('*******disableParentCid', this.disableParentCid);
              });
            }
          }
          if (this.formData.toCidCodeByBatches === this.formData.cidCode && this.allTaskList.length === 1) {
            this.disQtyScan = true;
            this.formData.qtyScan = this.formData.qtyPlan;
          }
        } catch (e) {
          this.skipNext(20);
        } finally {
          this.$hint.hideLoading();
        }
      }
    },
    /**
     * 确认托盘Enter事件
     */
    handleCodeSkip() {
      if (this.formData.toCidCodeByBatches) {
        this.requireParentCidCode ? this.skipNext(25) : this.skipNext(30);
      } else {
        if (this.disabledFlag) {
          this.handleSkip('ref30');
        } else {
          this.skipNext(30);
        }
      }
    },

    /**
     * 确认父托盘失焦事件
     */
    handleParentCodeBlur() {
      if (this.formData.toParentCidCode) {
        this.$hint.showLoading();
        const data = {
          params: {
            parentCidCode: this.formData.toParentCidCode, //当前填写的确认父托盘
            fromParentCidCode: this.formData.parentCidCode, //上架父托盘
          },
        };
        checkParentCidCode(data)
          .then((res) => {
            if (res.locationCode && res.locationId) {
              this.toParentLocationIdByBatches = res.locationId;
              if (this.toLocationIdByBatches) {
                // 判断是否与当前的确认库位一致，不一致就报错
                if (this.toLocationIdByBatches !== res.locationId) {
                  this.$hint.showFailed(`
                   ${this.$i18n.t('message.checkParentWithCidCodeStr1')}
                   ${res.locationCode}
                   ${this.$i18n.t('message.checkParentWithCidCodeStr2')}
                   ${this.toLocationCodeByBatches}
                   ${this.$i18n.t('message.checkParentWithCidCodeStr3')}
                `);
                  this.formData.toParentCidCode = null;
                  this.skipNext(25);
                  return;
                }
              } else {
                // 若当前的确认库位没有值，就附上带出来的值
                this.toLocationCodeByBatches = res.locationCode;
                this.formData.toLocationId = res.locationId;
                this.locationValuedByParent = true; // 库位值确定是由父托盘带出
              }
              this.disabledFlag = true;
              this.handleSkip('ref30');
            } else {
              this.toParentLocationIdByBatches = null;
              this.skipNext(30);
              this.disabledFlag = false;
            }
          })
          .catch(() => {
            this.skipNext(25);
          })
          .finally(() => {
            this.$hint.hideLoading();
          });
      }
    },

    /**
     * Blur事件
     * @param {String} flag
     */
    async handleSkipBlur(ref, flag, value) {
      // console.log("***********************");
      let breakOut = false;
      const changeObj = {
        sku: this.$i18n.t('message.checkSkuSame'),
        batchCode: this.$i18n.t('message.checkBatchSame'),
      };
      const refObj = this.$refs[`${ref}`];
      switch (ref) {
        case 'ref0':
          if (value && value !== this.formData[`${flag}Show`]) {
            this.$hint.showFailed(changeObj[flag], false);
            breakOut = true;
            refObj.isError = true;
            refObj.validateClass = 'has-error';
            refObj.errorMsg = this.$i18n.t('message.checkSkuSame');
          } else {
            refObj.isError = false;
            refObj.validateClass = false;
            refObj.errorMsg = '';
          }
          break;
        case 'ref10':
          if (value && value !== this.formData[`${flag}Show`]) {
            this.$hint.showFailed(changeObj[flag], true);
            breakOut = true;
            refObj.isError = true;
            refObj.validateClass = 'has-error';
            refObj.errorMsg = this.$i18n.t('message.checkBatchSame');
          } else {
            refObj.isError = false;
            refObj.validateClass = false;
            refObj.errorMsg = '';
          }
          break;
        default:
          return null;
      }
      return breakOut;
    },
    /**
     * Enter事件
     * @param {String} flag
     */
    handleSkip(ref) {
      // const changeObj = {
      //   sku: this.$i18n.t('message.checkSkuSame'),
      //   batchCode: this.$i18n.t('message.checkBatchSame'),
      // };
      switch (ref) {
        case 'ref0':
          // if (value && value === this.formData[`${flag}Show`]) {
          if (this.isbatch === 1) {
            this.$nextTick(() => {
              this.skipNext(10);
            });
          } else {
            this.skipNext(20);
          }
          // }else {
          //   this.$hint.showFailed(changeObj[flag], true);
          //   this.skipNext(0);
          // }
          break;
        case 'ref10':
          // if (value && value === this.formData[`${flag}Show`]) {
          this.skipNext(20);
          // }else {
          //   this.$hint.showFailed(changeObj[flag], true);
          //   this.$nextTick(() => {
          //     this.skipNext(10);
          //   });
          // }
          break;
        case 'ref20':
          this.toParentCidCode ? this.skipNext(40) : this.skipNext(25);
          break;
        case 'ref25':
          this.disabledFlag ? this.skipNext(40) : this.skipNext(30);
          break;
        case 'ref30':
          if (this.enableSerialFlag) {
            if (this.showSerialFlag) {
              this.$nextTick(() => {
                this.skipNext('inputRef');
              });
            } else {
              this.skipNext('submitRef');
            }
          } else {
            if (this.disQtyScan) {
              this.skipNext('submitRef');
            } else {
              this.skipNext(40);
            }
          }
          break;
        // case 'ref40':
        //   if(!this.serialPass) {
        //     this.$nextTick(() => {
        //       this.skipNext('inputRef');
        //     });
        //   }else {
        //     this.skipNext('submitRef');
        //   }
        //   break;
        default:
          return null;
      }
    },

    /**
     * 确认物料/确认批次 Change事件
     * @param {String} flag
     * @param {String} value
     */
    // changeData(flag, value) {
    //   const changeObj = {
    //     sku: this.$i18n.t('message.checkSkuSame'),
    //     batchCode: this.$i18n.t('message.checkBatchSame'),
    //   };
    //   if (value && value === this.formData[`${flag}Show`]) {
    //     this[[`${flag}Pass`]] = true;
    //   }else {
    //     this.$hint.showFailed(changeObj[flag], true);
    //     this[[`${flag}Pass`]] = false;
    //   }
    // },

    /**
     * 确认库位选中事件
     * @param {Object} item
     */
    chooseConfirmLocation(item) {
      this.formData.toLocationId = item.locationId;
    },

    /**
     * 序列号选中事件
     * @param {Object} chooseData
     */
    // chooseSerial(chooseData) {
    //   const { qtyPlan } = this.formData;
    //   if (chooseData) {
    //     this.formData.serialNumber = '';
    //     this.$refs.ref40.clearValue();
    //     const index = this.inboundSerialReceiptList.findIndex((item) => {
    //       return item.serialNumber === chooseData.serialNumber;
    //     });
    //     if (index === -1) {
    //       this.inboundSerialReceiptList.push(chooseData);
    //       this.serialPass = this.inboundSerialReceiptList.length === this.serialList.length;
    //       if(this.serialPass) {
    //         this.$hint.showSuccess(this.$i18n.t('message.serialAllSuccess'));
    //       }else {
    //         this.$hint.showSuccess(this.$i18n.t('message.inputSuccess'))
    //       }
    //     } else {
    //       this.$hint.showFailed(this.$i18n.t('message.serialRepeat'), true);
    //       this.serialPass = false;
    //     }
    //     this.formData.qtyScan = this.inboundSerialReceiptList.length || qtyPlan;
    //   }
    // },

    handleButtonSubmit() {
      if (!this.isAllow) {
        this.$hint.showFailed(this.$i18n.t('message.checkRequired'));
        return;
      }
      if (!this.confirmDisabled) {
        this.confirmDisabled = true;
        if (this.formData.toCidCodeByBatches === '' || this.formData.toCidCodeByBatches === undefined) {
          this.currentTaskData.toCidCodeByBatches = '';
          this.currentTaskData.toCidId = '';
        }
        this.$hint.showLoading();
        this.handleSubmitStatus();
        const data = {
          ...this.currentTaskData,
          ...this.formData,
          // cidCode: this.formData.cidCodeByBatches,
          toCidCode: this.formData.toCidCodeByBatches,
          toParentCidCode: this.formData.toParentCidCode,
          // qtyPlan: this.formData.qtyPlanByBatches,
          toLocationCode: this.toLocationCodeByBatches,
          serialNumber: this.showSerialFlag ? this.formData.serialNumber : '',
          confirmBatchCode: this.isbatch ? this.formData.confirmBatchCode : '',
          inboundSerialReceiptList: this.inboundSerialReceiptList || [],
        };
        confirmBatchesDetail(data)
          .then((res) => {
            if (res && !res.failed) {
              this.confirmDisabled = false;
              this.switchTask();
            }
          })
          .catch(() => {
            this.confirmDisabled = false;
          })
          .finally(() => {
            this.$hint.hideLoading();
            this.handleSubmitStatus();
          });
      }
    },

    initData() {
      this.disabledFlag = false;
      this.skuPass = false;
      this.batchCodePass = false;
      this.serialPass = true;
      this.confirmDisabled = false;
      this.disabledFlag = false;
      this.disQtyScan = false;
    },

    /**
     * 切换任务
     */
    async switchTask() {
      let _this = this;
      this.$hint.showLoading();
      this.initData();
      const index = this.allTaskList.findIndex((item) => {
        return this.currentTaskData.id === item.id;
      });
      if (index < this.allTaskList.length - 1) {
        this.currentTaskData = this.allTaskList[index + 1];
        this.formData = {};
        this.formData.cidCode = this.$route.params.cidCode;
        this.formData.parentCidCode = this.$route.params.parentCidCode;
        this.inboundSerialReceiptList = [];
        this.$store.commit('deleteKeepAlives', 'IpsSerial');
        await this.clearSourceFields(this, true);
        this.dealData(this.currentTaskData);
        this.skipNext(0);
        await setTimeout(function () {
          _this.$hint.hideLoading();
        }, 200);
      } else {
        this.$hint.showFailed(this.$i18n.t('message.lastDataTips'), true);
        setTimeout(function () {
          _this.$hint.hideLoading();
        }, 200);
        this.$router.backPage();
      }
    },
    getDataFromSession() {
      const { sourceParseField, customData, hideControl } = this.$route.params;
      this.sourceParseField = sourceParseField;
      this.customData = customData;
      this.hideControl = hideControl;
      let sessionData = sessionStorage.getItem('_putawayLoad');
      if (sessionData) {
        this.allTaskList = JSON.parse(sessionData);
        this.currentTaskData = this.allTaskList[0] || {};
        this.dealData(this.currentTaskData);
        sessionStorage.removeItem('_putawayLoad');
      }
    },
    dealData(data) {
      this.isbatch = data.isbatch || 0;
      const site = [this.SERIAL_INV_CTRL, this.SERIAL_IN_CTRL, this.SERIAL_SEMI_INV_CTRL];
      if (site.includes(data.serialType)) {
        this.enableSerialFlag = this.showSerialFlag = true;
        this.formData.qtyScan = data.qtyPlan;
      }
      this.detailId = data.detailId;
      this.toLocationCodeByBatches = data.toLocationCode; //确认库位
      this.formData.toLocationId = data.toLocationId;
      this.formData.skuShow = data.sku;
      this.formData.goodsName = data.goodsName;
      this.formData.uomName = data.uomName;
      this.formData.batchCodeShow = data.batchCode;
      this.formData.toLocationCodeShow = data.toLocationCode; //建议库位
      this.formData.qtyPlan = data.qtyPlan;
      this.formData.warehouseId = data.warehouseId;
    },

    /**
     * 查询所有序列号
     */
    // async getSerialList() {
    //   const data = {
    //     params: {
    //       receiptId: this.detailId,
    //       status: '2-RECEIVED',
    //       serialNumber: '',
    //       size: 1000,
    //     }
    //   }
    //   const res = await querySerialList(data);
    //   this.serialList = res.content || [];
    // }
  },
  async mounted() {
    await this.getDataFromSession();
    this.inputFieldList = [
      { type: 'text', bind: 'formData.cidCode', field: 'cidCode' },
      { type: 'text', bind: 'formData.parentCidCode', field: 'parentCidCode' },
      { type: 'text', bind: 'formData.skuShow', field: 'skuShow' },
      { type: 'text', bind: 'formData.goodsName', field: 'goodsName' },
      { type: 'text', bind: 'formData.uomName', field: 'uomName' },
      { type: 'input', required: true, ref: 0, bind: 'formData.confirmSku', field: 'confirmSku' },
      { type: 'text', bind: 'formData.batchCodeShow', field: 'batchCodeShow' },
      { type: 'input', required: true, ref: 10, bind: 'formData.confirmBatchCode', field: 'confirmBatchCode', isHide: !this.isbatch },
      { type: 'input', required: false, ref: 20, bind: 'formData.toCidCodeByBatches', field: 'toCidCodeByBatches' },
      { type: 'input', required: false, ref: 25, bind: 'formData.toParentCidCode', field: 'toParentCidCode' },
      { type: 'text', bind: 'formData.qtyPlan', field: 'qtyPlan' },
      { type: 'lov', required: true, ref: 30, bind: 'toLocationCodeByBatches', field: 'toLocationCodeByBatches' },
      { type: 'number', required: true, ref: 40, bind: 'formData.qtyScan', field: 'qtyScan' },
    ];
    this.urlData = this.handleData('SPLIT_PALLET_CONFIRM');
    // 获取页面功能参数
    const { PARENT_CID_ENABLE } = await this.fetchPageFunctionConfig({
      functionCode: 'PUT_AWAY',
      deviceCode: 'PDA',
      paramCode: 'PARENT_CID_ENABLE', // 是否启用父托盘
    });
    this.requireParentCidCode = Number(PARENT_CID_ENABLE);
    this.skipNext(0);
    this.formData.cidCode = this.$route.params.cidCode;
    this.formData.parentCidCode = this.$route.params.parentCidCode;
    this.formData.parentCidId = this.$route.params.parentCidId;
    this.warehouseId = this.$route.params.warehouseId;
    // if(this.enableSerialFlag) {
    //   this.getSerialList();
    // }
  },
  watch: {
    'formData.toCidCodeByBatches': function (val) {
      if (!val) {
        this.formData.toParentCidCode = undefined;
        this.toLocationCodeByBatches = null;
        this.disableParentCid = false;
        this.disabledFlag = false;
      }
    },
    'formData.toParentCidCode': function (val) {
      if (!val) {
        if (this.locationValuedByParent) {
          this.toLocationCodeByBatches = null;
          this.toParentLocationIdByBatches = null;
          this.disabledFlag = false;
        }
      }
    },
  },
  activated() {
    // 从序列号明细页返回
    const selectedSerialNum = JSON.parse(sessionStorage.getItem('totalSerialNum'));
    this.formData.qtyScan = selectedSerialNum || this.formData.qtyPlan;
    this.formData = Object.assign({}, this.formData);
    this.inboundSerialReceiptList = JSON.parse(sessionStorage.getItem('serialNumberDtoList'));
    sessionStorage.removeItem('totalSerialNum');
    sessionStorage.removeItem('serialNumberDtoList');
  },

  beforeRouteLeave(to, from, next) {
    if (to.name === 'inputSerial') {
      this.$store.commit('pushKeepAlives', 'Batches');
    } else {
      this.$store.commit('deleteKeepAlives', 'Batches');
      this.$store.commit('deleteKeepAlives', 'IpsSerial');
    }
    this.$nextTick(() => {
      next();
    });
  },
};
</script>

<style lang="stylus" scoped>
 @import '~@/style/var.styl'
.input-button {
  margin 5px 0 10px 0
  .hips-button--primary {
    height 35px
    line-height 20px
  }
}
</style>
