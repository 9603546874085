var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { className: "text-a" } },
    [
      _c("ips-header", { attrs: { title: "测试a" } }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.input.a,
            expression: "input.a",
          },
        ],
        attrs: { type: "text", placeholder: "输入框1" },
        domProps: { value: _vm.input.a },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.input, "a", $event.target.value)
          },
        },
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.input.b,
            expression: "input.b",
          },
        ],
        attrs: { type: "text", placeholder: "输入框2" },
        domProps: { value: _vm.input.b },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.input, "b", $event.target.value)
          },
        },
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.input.c,
            expression: "input.c",
          },
        ],
        attrs: { type: "text", placeholder: "输入框3" },
        domProps: { value: _vm.input.c },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.input, "c", $event.target.value)
          },
        },
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.input.d,
            expression: "input.d",
          },
        ],
        attrs: { type: "text", placeholder: "输入框4" },
        domProps: { value: _vm.input.d },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.input, "d", $event.target.value)
          },
        },
      }),
      _c("input", { attrs: { type: "text", placeholder: "请输入" } }),
      _c("button", { on: { click: _vm.go } }, [_vm._v("\n    前往b页面\n  ")]),
      _c(
        "button",
        {
          on: {
            click: function ($event) {
              return _vm.click("success")
            },
          },
        },
        [_vm._v("\n    显示成功消息\n  ")]
      ),
      _c("br"),
      _c(
        "button",
        {
          on: {
            click: function ($event) {
              return _vm.click("failed")
            },
          },
        },
        [_vm._v("\n    显示失败消息\n  ")]
      ),
      _c("br"),
      _c(
        "button",
        {
          on: {
            click: function ($event) {
              return _vm.click("alert")
            },
          },
        },
        [_vm._v("\n    显示alert框\n  ")]
      ),
      _c("br"),
      _c(
        "button",
        {
          on: {
            click: function ($event) {
              return _vm.click("64")
            },
          },
        },
        [_vm._v("\n    图片上传\n  ")]
      ),
      _c("br"),
      _c(
        "button",
        {
          on: {
            click: function ($event) {
              return _vm.click("goApp")
            },
          },
        },
        [_vm._v("\n    前往其他子应用\n  ")]
      ),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("ips-picture", { on: { changeImg: _vm.changeImg } }),
      _c("ips-date-picker"),
      _c("input", { attrs: { type: "text", placeholder: "请输入测试数据" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }