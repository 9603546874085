/**
 * @hips/plugin-vue-request
 * Library version is 0.2.16
 * Build for hips plugin vue request
 */

 import axios from 'axios';

 let accessToken;
 
 function createHttp(options) {
   axios.defaults.timeout = options.httpTimeout || 5000;
   const refreshTokenPromise = options.refreshToken;
   const successFlag = options.successFlag;
   const noAuth = axios.create();
   const authed = axios.create();
   const origin = axios.create();
   origin.defaults.baseURL = authed.defaults.baseURL = options.httpBaseUrl;
   noAuth.defaults.baseURL = options.noAuthHttpBaseUrl || options.httpBaseUrl;
   authed.interceptors.request.use(function (request) {
     if (options.httpHeader) {
       let httpHeader;
 
       try {
         httpHeader = JSON.parse(options.httpHeader);
       } catch (error) {
         httpHeader = options.httpHeader;
       }
 
       const requestHeader = Object.assign({}, httpHeader || {});
       Object.keys(requestHeader).map(function (item) {
         request.headers[item] = requestHeader[item];
       });
     }
 
     if (accessToken) {
       request.headers['Authorization'] = accessToken;
     }
 
     return request;
   });
   authed.interceptors.response.use(
     function (response) {
       // 只将请求请求结果的data字段返回
       let result;
 
       try {
         result = JSON.parse(response.data);
       } catch (e) {
         result = response.data;
       }
 
       if (typeof successFlag === 'string') {
         if (result['' + successFlag] === true) {
           return Promise.resolve(result);
         }
 
         return Promise.reject(result);
       } else if (typeof successFlag === 'object') {
         const flagKey = Object.keys(successFlag)[0];
         const flagVal = successFlag[flagKey];
 
         if (result['' + flagKey] === flagVal) {
           return Promise.resolve(result);
         }
 
         return Promise.reject(result);
       }
 
       return Promise.resolve(result);
     },
     function (error) {
       if (error) {
         const status = error.response.status;
         const config = error.config;
         console.log('config = ', config);
 
         if (status === 401 && refreshTokenPromise) {
           // 当接口返回401时重新刷新token
           console.log('401');
           return refreshTokenPromise().then(function () {
             return authed.request(config);
           });
         }
 
         return Promise.reject(error);
       }
     }
   );
   noAuth.interceptors.request.use(function (request) {
     if (options.httpHeader) {
       let httpHeader;
 
       try {
         httpHeader = JSON.parse(options.httpHeader);
       } catch (error) {
         httpHeader = options.httpHeader;
       }
 
       const requestHeader = Object.assign({}, httpHeader || {});
       Object.keys(requestHeader).map(function (item) {
         request.headers[item] = requestHeader[item];
       });
     }
 
     return request;
   });
   noAuth.interceptors.response.use(
     function (response) {
       // 只将请求请求结果的data字段返回
       let result;
 
       try {
         result = JSON.parse(response.data);
       } catch (e) {
         result = response.data;
       }
 
       if (typeof successFlag === 'string') {
         if (result['' + successFlag] === true) {
           return Promise.resolve(result);
         }
 
         return Promise.reject(result);
       } else if (typeof successFlag === 'object') {
         const flagKey = Object.keys(successFlag)[0];
         const flagVal = successFlag[flagKey];
 
         if (result['' + flagKey] === flagVal) {
           return Promise.resolve(result);
         }
 
         return Promise.reject(result);
       }
 
       return Promise.resolve(result);
     },
     function (error) {
       if (error) {
         return Promise.reject(error);
       }
     }
   );
   return {
     origin,
     noAuth,
     authed,
   };
 }
 
 function setToken(token) {
   accessToken = token;
 }
 
 function getToken() {
   return accessToken;
 }
 
 let http;
 let noAuthHttp;
 let originHttp;
 
 function install(Vue, options) {
   if (install.installed) {
     return;
   }
 
   install.installed = true;
   const allHttp = createHttp(options);
 
   if (!Vue.prototype.$hips) {
     Vue.prototype.$hips = {};
   }
 
   (Vue.prototype.$hips.http = allHttp.authed),
     (Vue.prototype.$hips.noAuthHttp = allHttp.noAuth),
     (Vue.prototype.$hips.originHttp = allHttp.origin),
     (Vue.prototype.$hips.accessToken = {
       set: setToken,
       get: getToken,
     }),
     (http = allHttp.authed);
   noAuthHttp = allHttp.noAuth;
   originHttp = allHttp.origin;
 }
 
 const plugin = {
   install,
 };
 
 export default plugin;
 export { http, noAuthHttp, originHttp };
 