var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: { title: _vm.$t("title.trayInfoShow"), "is-index": false },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _c("ips-value-cell", {
          attrs: { title: _vm.$t("label.tray"), value: _vm.cidCode },
        }),
        _c("ips-value-cell", {
          attrs: {
            title: _vm.$t("label.countLocation"),
            value: _vm.lineData.locationCode,
          },
        }),
        _c("ips-value-cell", {
          attrs: {
            title: _vm.$t("label.trayState"),
            value: _vm.lineData.cidStatusName,
          },
        }),
        _c("ips-value-cell", {
          attrs: {
            title: _vm.$t("label.trayCurrentQty"),
            value: _vm.lineData.qty,
          },
        }),
        _c("ips-value-cell", {
          attrs: {
            title: _vm.$t("label.trayAvailability"),
            value: _vm.lineData.validQty,
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-footer" },
      [
        _c(
          "hips-button",
          {
            ref: "submitRef",
            attrs: { size: "large" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonSubmit.apply(null, arguments)
              },
            },
          },
          [
            _c("svg-icon", {
              attrs: { slot: "icon", "icon-class": "success" },
              slot: "icon",
            }),
            _vm._v("\n      " + _vm._s(_vm.$t("button.theDetail")) + "\n    "),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }