import request from '@/request';
import mixin  from '@/mixin/mixin';

// 现有量、可用量查询
export function queryStockQtyAll(data) {
  let url = `${mixin.data().winvBaseUrl}app-stock-trans/query-stock-qty-all`;
  return request.get(url, data);
}

// 整库转移
export function wholeCidStockTransfer(data) {
  let url = `${mixin.data().winvBaseUrl}app-stock-trans/whole-cid-stock-transfer`;
  return request.post(url, data)
}

// 托盘详情查询
export function queryCidStockDetail(data) {
  let url = `${mixin.data().winvBaseUrl}app-inv/query-cid-stock-detail`;
  return request.get(url, data);
}