// import Login from '../views/login'
import BatchQuery from '../views/batch-query'
import BatchQueryDetail from '../views/batch-query-detail'

let route = [
  {
    path: '/batch-query',
    name: 'BatchQuery',
    component: BatchQuery,
  },
  {
    path: '/batch-query-detail',
    name: 'BatchQueryDetail',
    component: BatchQueryDetail,
  },
]

export default route