var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: {
            title: _vm.$t("title.batchDecomposition"),
            "is-index": false,
          },
          on: { beforeBack: _vm.goBack },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["toBatchCode"]
                ? _vm.hideControl["toBatchCode"]
                : true,
              expression:
                "customData['toBatchCode'] ? hideControl['toBatchCode']: true",
            },
          ],
          ref: "ref0",
          attrs: {
            label: _vm.$t("label.targetBatch"),
            title: _vm.$t("label.targetBatch"),
            required: "",
            params: "batchCode",
            url: _vm.wmdmBaseUrl + "batchs/to-batch-modify-lov",
            "url-params": { enabledFlag: 1, goodsId: _vm.fromData.goodsId },
            custom: _vm.customData["toBatchCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            enter: function ($event) {
              return _vm.skipNext(10)
            },
            down: function ($event) {
              return _vm.skipNext(10)
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(0)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(0, "lov")
            },
            choose: function ($event) {
              return _vm.handleChoose("toBatchCode", $event)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.toBatchCode,
            callback: function ($$v) {
              _vm.toBatchCode = $$v
            },
            expression: "toBatchCode",
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["toSku"] ? _vm.hideControl["toSku"] : true,
              expression: "customData['toSku'] ? hideControl['toSku']: true",
            },
          ],
          ref: "ref10",
          attrs: {
            label: _vm.$t("label.sku"),
            title: _vm.$t("label.sku"),
            required: "",
            disabled: "",
            params: "sku",
            custom: _vm.customData["toSku"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.toSku,
            callback: function ($$v) {
              _vm.toSku = $$v
            },
            expression: "toSku",
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["toCidCode"]
                ? _vm.hideControl["toCidCode"]
                : true,
              expression:
                "customData['toCidCode'] ? hideControl['toCidCode']: true",
            },
          ],
          ref: "ref20",
          attrs: {
            label: _vm.$t("label.targetTray"),
            title: _vm.$t("label.targetTray"),
            params: "cidCode",
            url: _vm.wmdmBaseUrl + "containers/to-cid-lov",
            custom: _vm.customData["toCidCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            enter: function ($event) {
              return _vm.skipNext(30)
            },
            down: function ($event) {
              return _vm.skipNext(30)
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(20)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(20, "lov")
            },
            choose: function ($event) {
              return _vm.handleChoose("toCidCode", $event)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.toCidCode,
            callback: function ($$v) {
              _vm.toCidCode = $$v
            },
            expression: "toCidCode",
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["toLocationCode"]
                ? _vm.hideControl["toLocationCode"]
                : true,
              expression:
                "customData['toLocationCode'] ? hideControl['toLocationCode']: true",
            },
          ],
          ref: "ref30",
          attrs: {
            label: _vm.$t("label.targetLocation"),
            title: _vm.$t("label.targetLocation"),
            required: "",
            disabled: _vm.cidStatus === "IN_STOCK",
            params: "locationCode",
            url: _vm.wmdmBaseUrl + "wms-locations",
            custom: _vm.customData["toLocationCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(30)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(30, "lov")
            },
            choose: function ($event) {
              return _vm.handleChoose("toLocationCode", $event)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.toLocationCode,
            callback: function ($$v) {
              _vm.toLocationCode = $$v
            },
            expression: "toLocationCode",
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["toWhareaCode"]
                ? _vm.hideControl["toWhareaCode"]
                : true,
              expression:
                "customData['toWhareaCode'] ? hideControl['toWhareaCode']: true",
            },
          ],
          ref: "ref40",
          attrs: {
            label: _vm.$t("label.targetWharea"),
            title: _vm.$t("label.targetWharea"),
            required: "",
            disabled: "",
            params: "whareaCode",
            custom: _vm.customData["toWhareaCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.toWhareaCode,
            callback: function ($$v) {
              _vm.toWhareaCode = $$v
            },
            expression: "toWhareaCode",
          },
        }),
        _c("ips-input-number", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.enableSerial
                ? _vm.customData["decompositionQty"]
                  ? _vm.hideControl["decompositionQty"]
                  : !_vm.enableSerial
                : false,
              expression:
                "!enableSerial ? (customData['decompositionQty'] ? hideControl['decompositionQty'] : !enableSerial) : false",
            },
          ],
          ref: "ref50",
          attrs: {
            label: _vm.$t("label.amount"),
            required: "",
            custom: _vm.customData["decompositionQty"],
            "source-parse-field": _vm.sourceParseField,
            "not-auto-fill": "",
            min: null,
            "more-than-zero": true,
            "enable-serial": _vm.serialType,
          },
          on: {
            enter: _vm.handleNextEnter,
            down: _vm.handleNextEnter,
            tab: _vm.handleNextEnter,
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.decompositionQty,
            callback: function ($$v) {
              _vm.decompositionQty = $$v
            },
            expression: "decompositionQty",
          },
        }),
        _vm.enableSerial
          ? _c("hips-row", [
              _c(
                "div",
                { staticClass: "hip-row" },
                [
                  _c(
                    "hips-col",
                    { attrs: { span: "21" } },
                    [
                      _c("ips-input-number", {
                        ref: "ref50",
                        attrs: {
                          label: _vm.$t("label.amount"),
                          required: "",
                          disabled: "",
                          "enable-serial": _vm.serialType,
                          custom: _vm.customData["decompositionQty"],
                          "source-parse-field": _vm.sourceParseField,
                          "not-auto-fill": "",
                          min: null,
                          "more-than-zero": true,
                        },
                        on: {
                          enter: function ($event) {
                            return _vm.skipNext("refSerail")
                          },
                          down: function ($event) {
                            return _vm.skipNext("refSerail")
                          },
                          tab: function ($event) {
                            return _vm.skipNext("refSerail")
                          },
                          setSourceField: function ($event) {
                            return _vm.saveSourceField($event)
                          },
                          valuedByFoucs: function ($event) {
                            return _vm.valuedByFoucs($event)
                          },
                        },
                        model: {
                          value: _vm.decompositionQty,
                          callback: function ($$v) {
                            _vm.decompositionQty = $$v
                          },
                          expression: "decompositionQty",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "hips-col",
                    { attrs: { span: "8" } },
                    [
                      _c(
                        "hips-button",
                        {
                          ref: "refSerail",
                          staticClass: "button-detail",
                          attrs: { size: "large" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.goToSerialDetail.apply(null, arguments)
                            },
                            enter: _vm.goToSerialDetail,
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("button.serialNumber")) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "count_block_box" },
          [
            _c("ips-number-count", {
              staticClass: "count-demandQty",
              attrs: { count: _vm.demandQty, "text-field": "demandQty" },
            }),
            _c("ips-number-count", {
              staticClass: "count-enteredQty",
              attrs: { count: _vm.enteredQty, "text-field": "enteredQty" },
            }),
            _c("ips-number-count", {
              staticClass: "count-toBeEnterQty",
              attrs: { count: _vm.toBeEnterQty, "text-field": "toBeEnterQty" },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _vm.enteredQty > 0
      ? _c(
          "div",
          { staticClass: "footer-button" },
          [
            _c(
              "hips-button",
              {
                ref: "nextRef",
                staticClass: "next",
                attrs: { size: "large" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleNext.apply(null, arguments)
                  },
                  enter: _vm.handleNext,
                },
              },
              [_vm._v(_vm._s(_vm.$t("button.next")) + "\n    ")]
            ),
            _c(
              "hips-button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.customData["importedDetail"]
                      ? _vm.hideControl["importedDetail"]
                      : true,
                    expression:
                      "customData['importedDetail'] ? hideControl['importedDetail']: true",
                  },
                ],
                staticClass: "importedDetail",
                attrs: { size: "large" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleImportedDetail.apply(null, arguments)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("button.importedDetail")) + "\n    ")]
            ),
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "ips-footer" },
      [
        _c(
          "hips-button",
          {
            ref: "submitRef",
            attrs: { size: "large" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonSubmit.apply(null, arguments)
              },
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleCommonSubmit.apply(null, arguments)
              },
              keypress: function ($event) {
                $event.preventDefault()
              },
            },
          },
          [
            _c("svg-icon", {
              attrs: { slot: "icon", "icon-class": "success" },
              slot: "icon",
            }),
            _vm._v(
              _vm._s(
                _vm.enteredQty > 0
                  ? _vm.$t("button.decompositionConfirm")
                  : _vm.$t("button.next")
              ) + "\n    "
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }