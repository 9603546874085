<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.trayDetail')" :is-index="false" />
    </div>
    <div class="ips-container">
      <!--库位-物料卡片-->
      <div v-if="prePackData.length > 0">
        <div class="ips-card__content" ref="ips-content" tabindex="-1">
          <hips-scroll
            ref="scroll"
            :scroll-options="scrollOption"
            :data="[prePackData]"
            :scroll-events="['scroll']"
            @pulling-down="loadRefresh"
            @pulling-up="loadMore"
            @scroll="scroll"
          >
            <ul class="list-wrapper">
              <div
                class="card_countLocation"
                v-for="(item,index) in prePackData"
                :key="index"
              >
                <div class="row">
                  <div class="title">
                    {{ $t('label.tray') }}:
                  </div>
                  <div class="value">
                    {{ item.cidCode }}
                  </div>
                </div>
                <div class="row">
                  <div class="row-50">
                    <div class="title">
                      {{ $t('label.sku') }}:
                    </div>
                    <div class="value">
                      {{ item.sku }}
                    </div>
                  </div>
                  <div class="row-50">
                    <div class="title">
                      {{ $t('label.batch') }}:
                    </div>
                    <div class="value">
                      {{ item.batchCode }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="row-50">
                    <div class="title">
                      {{ $t('label.lineNum') }}:
                    </div>
                    <div class="value">
                      {{ item.detailLineNum }}
                    </div>
                  </div>
                  <div class="row-50">
                    <div class="title">
                      {{ $t('label.unit') }}:
                    </div>
                    <div class="value">
                      {{ item.uomName }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="title">
                    {{ $t('label.goodsName') }}:
                  </div>
                  <div class="value">
                    {{ item.goodsName || '--' }}
                  </div>
                </div>
                <div class="row" v-if="!['INV_CTRL', 'IN_CTRL', 'SEMI_INV_CTRL'].includes(item.serialType)">
                  <div class="title">
                    {{ $t('label.quantity') }}:
                  </div>
                  <div class="value">
                    {{ item.packQty || '--' }}
                  </div>
                </div>
                <div class="row" v-if="['INV_CTRL', 'IN_CTRL', 'SEMI_INV_CTRL'].includes(item.serialType)">
                  <div class="row-50">
                    <div class="title">
                      {{ $t('label.quantity') }}:
                    </div>
                    <div class="value">
                      {{ item.packQty || '--' }}
                    </div>
                  </div>
                  <div class="row-50">
                    <!-- 序列号明细 -->
                    <hips-button
                      type="warning"
                      size="large"
                      @click.stop="goToSerialDetail(item)"
                      style="margin-left: 3px; color: #FFFFFF; font-size: 14px; height: 30px; line-height: 30px; width: 150px"
                    >
                      {{ $t('button.seriesDetail') }}
                    </hips-button>
                  </div>
                </div>
              </div>
              <li
                v-show="prePackData.length > 20"
                class="scroll-item hips-thinline--bottom"
                style="color: var(--primaryColor); text-align: center"
                @click="scrollToTop"
              >
                {{ $t('button.backTop') }}
              </li>
            </ul>
          </hips-scroll>
        </div>
      </div>
    </div>
  </hips-view>
</template>

<script>
  import { queryPrePackCidDetail } from '../api';
  import { Row, Col, Icon } from '@hips/vue-ui';
  export default {
    name: 'ReceiveParentCidDetail',
    components:{
      [Row.name]: Row,
      [Col.name]: Col,
      [Icon.name]: Icon,
    },
    data() {
      return {
        prePackData: [],
        inboundSerialReceiptList: [],
        scrollY: -1, // y 滚动距离
        page: 0,
        size: 10,
        scrollOption: {
          pullDownRefresh: {
            threshold: 60, // 触发 pullingDown 的距离
            stop: 40, // pullingDown 正在刷新 hold 时的距离
            txt: this.$t('message.refreshSuccess'),
          },
          pullUpLoad: {
            txt: {
              // more: '上拉加载',
              more: this.$t('message.pullOnLoading'),
              // noMore: '暂无更多数据',
              noMore: this.$t('message.noMoreData'),
            },
          },
        },
      };
    },
    methods: {
      // 获取params传来的参
      async getParamsData(page=0){
        try{
          // this.$hint.showLoading();
          const { status, parentCidCode, billId } = this.$route.params;
          let data = {
            params: {
              status,
              parentCidCode,
              billId,
              page,
              size: this.size,
            },
          };
          const dataList = await queryPrePackCidDetail(data);
          if(page===0){
            this.prePackData = dataList.content;
          } else {
            if(dataList && dataList.content.length > 0){
              this.prePackData.push(...dataList.content);
            }
          }
        } finally {
          // this.$hint.hideLoading();
        }
      },

      scroll(pos) {
        this.scrollY = pos.y
      },

      scrollToTop () {
        this.$refs.scroll.scrollTo(0, 0);
      },

      // 加载刷新数据
      async loadRefresh () {
        await this.getParamsData();
        this.page = 1;
      },

      // 加载更多数据
      async loadMore () {
        const page = this.page + 1;
        await this.getParamsData(page);
        // 判断是否已达最后一页
        if (this.prePackData.length < this.size) {
          // 如果没有新数据，不用强制更新滚轮，注意下面的 forceUpdate 方法需要包裹在 nextTick 中
          this.$nextTick(() => {
            this.$refs.scroll.forceUpdate();
          })
        } else {
          this.page++;
        }
      },

      /**
      * 前往序列号明细界面
      */
      async goToSerialDetail(item){
        let data = {
          billId: this.billId,
        };
        let serialUrl ='app-inbound/serial-check';
        // const _this = this;
        const param = {
          name: 'serialComponent',
          params: {
            data,
            serialUrl,
            receiveQty: item.packQty,
            detailIdTmpList: true,
            task: item.inboundSerialReceiptList,
            callback: function(serialNum) {
              const item = {
                serialNumber: serialNum,
                qualityStatusName: '0-合格',
                qualityStatus: '0-ACCEPTED',
                serialQty: 1,
              };

              this.tableData.push(item);
              if(this.definedInboundSerialReceiptList.includes(serialNum)) {
                this.definedInboundSerialReceiptList.splice(this.definedInboundSerialReceiptList.findIndex((serial) => serial === serialNum), 1)
              }
            }
          },

        };
        this.$router.pushPage(param);
      },
    },
    mounted() {
      this.getParamsData(this.page);
    },
    beforeRouteLeave(to, from, next) {
      if (to.name === 'serialComponent') {
        this.$store.commit('pushKeepAlives', 'ReceiveParentCidDetail');
      }
      this.$nextTick(() => {
        next();
      });
    },
  };
</script>

<style lang="stylus" scoped>
 @import '~@/style/var.styl'
  .ips-container{
    height: 900px;
    padding-top 0!important;
      .scroll-list{
        background: white;
        text-align: center;
      }
      .scroll-item{
        line-height: 44px;
        height: 44px;
      }
    .ips-card__content {
      height: auto;
      max-height: 264vw !important;
    }
    .ips-card__content .hips-scroll {
      max-height: 264vw !important;
    }
    .ips-card__content .list-wrapper .scroll-item .content-item--3 {
      word-wrap: break-word !important;
    }
    .menu-row{
      display flex
      margin 12px 32px 12px 12px;
      .menu-title{
        width:80px;
        height:16px;
        font-size:12px;
        /*font-family:PingFangSC-Regular,PingFang SC;*/
        font-weight:200;
        color:#8C8C8C;
        line-height:16px;
      }
      .menu-value{
        width:261px;
        height:16px;
        font-size:12px;
        /*font-family:PingFangSC-Regular,PingFang SC;*/
        font-weight:initial;
        color:#000000;
        line-height:16px;
        overflow: hidden;      /*溢出隐藏*/
        white-space: nowrap;	/*规定文本不进行换行*/
        text-overflow: ellipsis;	/*当对象内文本溢出时显示省略标记（...）*/
      }
    }
    .card_countLocation{
      padding: 0.4vw 2.7vw;
      border: 1px solid #d9d9d9;
      margin 10px 0;
      padding  0 12px
      height: 170px;
      border-radius: 1.6vw;
      color: #000;
      box-shadow: 0 0 2.133vw #d9d9d9;
      font-size: 14px;
      .row{
          display flex
          padding  7px 0
          .row-50{
              width 50%
              display flex
              .title{
                  width:56px;
                  height:16px;
                  font-size:14px;
                  font-family:PingFangSC-Regular,PingFang SC;
                  font-weight:200;
                  color:#595959;
                  line-height:18px;
              }
              .value{
                  width:110px;
                  height:18px;
                  font-size:14px;
                  font-family:PingFangSC-Medium,PingFang SC;
                  font-weight:initial;
                  color:#000000;
                  line-height:18px;
                  overflow: hidden;      /*溢出隐藏*/
                  white-space: nowrap;	/*规定文本不进行换行*/
                  text-overflow: ellipsis;	/*当对象内文本溢出时显示省略标记（...）*/
              }
              .label{
                  width:40px;
                  height:18px;
                  font-size:14px;
                  font-family:PingFangSC-Medium,PingFang SC;
                  font-weight:initial;
                  color:#000000;
                  line-height:18px;
              }
              .label-number{
                  width:40px;
                  height:18px;
                  font-size:14px;
                  font-family:PingFangSC-Medium,PingFang SC;
                  font-weight:initial;
                  color:#9254DE;
                  line-height:18px;
              }
          }
          .title{
              width:56px;
              height:16px;
              font-size:14px;
              font-family:PingFangSC-Regular,PingFang SC;
              font-weight:200;
              color:#595959;
              line-height:18px;
          }
          .value{
              width:260px;
              height:18px;
              font-size:14px;
              font-family:PingFangSC-Medium,PingFang SC;
              font-weight:initial;
              color:#000000;
              line-height:18px;
              overflow: hidden;      /*溢出隐藏*/
              white-space: nowrap;	/*规定文本不进行换行*/
              text-overflow: ellipsis;	/*当对象内文本溢出时显示省略标记（...）*/
          }
        }
      }
    }
</style>
