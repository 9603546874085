var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "hips-view",
    { attrs: { "header-fixed": true } },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [_c("ips-header", { attrs: { title: _vm.$t("title.analyse") } })],
        1
      ),
      _c(
        "div",
        { staticClass: "ips-container" },
        [
          _vm.matchType === "PACK"
            ? _c("ips-field", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.customData["courierNumber"]
                      ? _vm.hideControl["courierNumber"]
                      : true,
                    expression:
                      "customData['courierNumber'] ? hideControl['courierNumber'] : true",
                  },
                ],
                ref: "ref0",
                attrs: {
                  label: _vm.$t("label.courierNumber"),
                  required: "",
                  custom: _vm.customData["courierNumber"],
                  "source-parse-field": _vm.sourceParseField,
                },
                on: {
                  setSourceField: function ($event) {
                    return _vm.saveSourceField($event)
                  },
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleCommonClick(0)
                  },
                  down: _vm.handleCourierNumber,
                  enter: _vm.handleCourierNumber,
                },
                model: {
                  value: _vm.courierNumber,
                  callback: function ($$v) {
                    _vm.courierNumber = $$v
                  },
                  expression: "courierNumber",
                },
              })
            : _vm._e(),
          _vm.matchType === "BILL"
            ? _c("ips-lov", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.customData["outboundOrderR"]
                      ? _vm.hideControl["outboundOrderR"]
                      : true,
                    expression:
                      "customData['outboundOrderR'] ? hideControl['outboundOrderR'] : true",
                  },
                ],
                ref: "ref0",
                attrs: {
                  label: _vm.$t("label.outboundOrderR"),
                  title: _vm.$t("label.outboundOrderR"),
                  params: "billCode",
                  display: "billCode",
                  url: _vm.winvBaseUrl + "app-review/query-bill-orders",
                  "is-control": "",
                  required: "",
                  custom: _vm.customData["outboundOrderR"],
                  "source-parse-field": _vm.sourceParseField,
                },
                on: {
                  setSourceField: function ($event) {
                    return _vm.saveSourceField($event)
                  },
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleCommonClick(0)
                  },
                  clickIcon: function ($event) {
                    return _vm.handleCommonClick(0, "lov")
                  },
                  enter: function ($event) {
                    return _vm.skipNext(10)
                  },
                  down: function ($event) {
                    return _vm.skipNext(10)
                  },
                  choose: function ($event) {
                    return _vm.handleChooseOutboundOrderR($event)
                  },
                },
                model: {
                  value: _vm.outboundOrderR,
                  callback: function ($$v) {
                    _vm.outboundOrderR = $$v
                  },
                  expression: "outboundOrderR",
                },
              })
            : _vm._e(),
          _vm.matchType === "WAVE"
            ? _c("ips-lov", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.customData["waveBillCode"]
                      ? _vm.hideControl["waveBillCode"]
                      : true,
                    expression:
                      "customData['waveBillCode'] ? hideControl['waveBillCode'] : true",
                  },
                ],
                ref: "ref0",
                attrs: {
                  label: _vm.$t("label.waveNum"),
                  title: _vm.$t("label.waveNum"),
                  params: "waveBillCode",
                  display: "waveBillCode",
                  url: _vm.winvBaseUrl + "app-review/query-wave-orders",
                  "is-control": "",
                  required: "",
                  custom: _vm.customData["waveBillCode"],
                  "source-parse-field": _vm.sourceParseField,
                },
                on: {
                  setSourceField: function ($event) {
                    return _vm.saveSourceField($event)
                  },
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleCommonClick(0)
                  },
                  clickIcon: function ($event) {
                    return _vm.handleCommonClick(0, "lov")
                  },
                  enter: function ($event) {
                    return _vm.skipNext(10)
                  },
                  down: function ($event) {
                    return _vm.skipNext(10)
                  },
                  choose: function ($event) {
                    return _vm.handleChooseWaveNum($event)
                  },
                },
                model: {
                  value: _vm.waveNum,
                  callback: function ($$v) {
                    _vm.waveNum = $$v
                  },
                  expression: "waveNum",
                },
              })
            : _vm._e(),
          _vm.matchType === "WAVE"
            ? _c("ips-field", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.customData["scanSku"]
                      ? _vm.hideControl["scanSku"]
                      : true,
                    expression:
                      "customData['scanSku'] ? hideControl['scanSku'] : true",
                  },
                ],
                ref: "ref10",
                attrs: {
                  label: _vm.$t("label.sku"),
                  required: "",
                  params: "scanSku",
                  display: "scanSku",
                  custom: _vm.customData["scanSku"],
                  "pre-parse-url": _vm.urlData["scanSku"],
                  "source-parse-field": _vm.sourceParseField,
                },
                on: {
                  setSourceField: function ($event) {
                    return _vm.saveSourceField($event)
                  },
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleCommonClick(10)
                  },
                  down: _vm.handleScanSku,
                  enter: _vm.handleScanSku,
                },
                model: {
                  value: _vm.scanSku,
                  callback: function ($$v) {
                    _vm.scanSku = $$v
                  },
                  expression: "scanSku",
                },
              })
            : _vm._e(),
          _vm.matchType !== "WAVE"
            ? _c("ips-lov", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.customData["scanSku"]
                      ? _vm.hideControl["scanSku"]
                      : true,
                    expression:
                      "customData['scanSku'] ? hideControl['scanSku'] : true",
                  },
                ],
                ref: "ref10",
                attrs: {
                  label: _vm.$t("label.sku"),
                  title: [_vm.$t("label.sku"), _vm.$t("label.goodsName")],
                  params: ["sku", "goodsName"],
                  display: "sku",
                  url: _vm.winvBaseUrl + "cux-app-review/sku-lov",
                  "url-params": { billId: _vm.resInfo.billId },
                  "is-control": "",
                  required: "",
                  custom: _vm.customData["scanSku"],
                  "source-parse-field": _vm.sourceParseField,
                },
                on: {
                  setSourceField: function ($event) {
                    return _vm.saveSourceField($event)
                  },
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleCommonClick(10)
                  },
                  clickIcon: function ($event) {
                    return _vm.handleCommonClick(10, "lov")
                  },
                  enter: _vm.handleScanSku,
                  down: _vm.handleScanSku,
                  choose: function ($event) {
                    return _vm.handleChooseSku($event)
                  },
                },
                model: {
                  value: _vm.scanSku,
                  callback: function ($$v) {
                    _vm.scanSku = $$v
                  },
                  expression: "scanSku",
                },
              })
            : _vm._e(),
          _vm.scanType === "BATCH" || _vm.scanType === "SERIAL"
            ? _c("ips-field", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.customData["barCode"]
                      ? _vm.hideControl["barCode"]
                      : true,
                    expression:
                      "customData['barCode'] ? hideControl['barCode'] : true",
                  },
                ],
                ref: "ref20",
                attrs: {
                  label:
                    _vm.scanType === "BATCH"
                      ? _vm.$t("label.batch")
                      : _vm.$t("label.serialNumber"),
                  required: "",
                  custom: _vm.customData["barCode"],
                  "source-parse-field": _vm.sourceParseField,
                },
                on: {
                  setSourceField: function ($event) {
                    return _vm.saveSourceField($event)
                  },
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleCommonClick(20)
                  },
                  down: _vm.handleBarCode,
                  enter: _vm.handleBarCode,
                },
                model: {
                  value: _vm.barCode,
                  callback: function ($$v) {
                    _vm.barCode = $$v
                  },
                  expression: "barCode",
                },
              })
            : _vm._e(),
          _c("ips-input-number", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.customData["quantity"]
                  ? _vm.hideControl["quantity"]
                  : true,
                expression:
                  "customData['quantity'] ? hideControl['quantity'] : true",
              },
            ],
            ref: "ref30",
            attrs: {
              required: "",
              label: _vm.$t("label.quantity"),
              disabled: _vm.scanType === "SERIAL",
              custom: _vm.customData["quantity"],
              "source-parse-field": _vm.sourceParseField,
              min: null,
              "more-than-zero": true,
              "not-auto-fill": "",
            },
            on: {
              setSourceField: function ($event) {
                return _vm.saveSourceField($event)
              },
              enter: _vm.handleQuantity,
              down: _vm.handleQuantity,
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(30)
              },
            },
            model: {
              value: _vm.quantity,
              callback: function ($$v) {
                _vm.quantity = $$v
              },
              expression: "quantity",
            },
          }),
          _vm.matchType !== "WAVE"
            ? _c("ips-field", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.customData["packagingMaterials"]
                      ? _vm.hideControl["packagingMaterials"]
                      : true,
                    expression:
                      "customData['packagingMaterials'] ? hideControl['packagingMaterials'] : true",
                  },
                ],
                ref: "ref40",
                attrs: {
                  label: _vm.$t("label.packagingMaterials"),
                  custom: _vm.customData["packagingMaterials"],
                  "source-parse-field": _vm.sourceParseField,
                },
                on: {
                  setSourceField: function ($event) {
                    return _vm.saveSourceField($event)
                  },
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleCommonClick(40)
                  },
                  down: _vm.handlePackagingMaterials,
                  enter: _vm.handlePackagingMaterials,
                },
                model: {
                  value: _vm.packagingMaterials,
                  callback: function ($$v) {
                    _vm.packagingMaterials = $$v
                  },
                  expression: "packagingMaterials",
                },
              })
            : _vm._e(),
          _c(
            "div",
            { staticStyle: { display: "flex" } },
            [
              _c(
                "hips-button",
                {
                  staticClass: "ips-btn-normal",
                  attrs: { size: "large" },
                  on: { click: _vm.showSwitchTenantFun },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("message.scanTheDetail")) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
          _c("ips-value-cell", {
            attrs: {
              title: _vm.$t("label.orderNumberNew"),
              value: _vm.resInfo.billQty || 0,
            },
          }),
          _c("ips-value-cell", {
            attrs: {
              title: _vm.$t("label.quantityChecked"),
              value: _vm.resInfo.billReviewedQty || 0,
            },
          }),
          _c("ips-value-cell", {
            attrs: {
              title: _vm.$t("label.noQuantityChecked"),
              value: _vm.resInfo.billNotReviewedQty || 0,
            },
          }),
          _c(
            "div",
            { staticClass: "ips-footer", staticStyle: { bottom: "60px" } },
            [
              _vm.matchType !== "WAVE"
                ? _c(
                    "hips-button",
                    {
                      ref: "endBoxChangeBtn",
                      attrs: { size: "large" },
                      on: { click: _vm.endBoxChange },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("button.inCasesOf")) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "hips-button",
                {
                  ref: "goDetailAnalyseBtn",
                  attrs: { size: "large" },
                  on: { click: _vm.goDetailAnalyse },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("button.checkTheDetails")) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "ips-footer" },
            [
              _c(
                "hips-button",
                {
                  ref: "submitRef",
                  attrs: { size: "large" },
                  on: { click: _vm.handleSub },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("button.submit")) + "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "hips-popup",
        {
          attrs: { position: "bottom" },
          model: {
            value: _vm.showSwitchTenantPopup,
            callback: function ($$v) {
              _vm.showSwitchTenantPopup = $$v
            },
            expression: "showSwitchTenantPopup",
          },
        },
        [
          _c("div", { staticClass: "popup-title" }, [
            _c("div", { staticClass: "left", on: { click: _vm.cleanPage } }, [
              _vm._v(
                "\n        " + _vm._s(_vm.$t("button.clear")) + "\n      "
              ),
            ]),
            _c("div", { staticClass: "center" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("message.scanTheDetail")) +
                  "\n      "
              ),
            ]),
            _c(
              "div",
              {
                staticClass: "right",
                on: {
                  click: function ($event) {
                    _vm.showSwitchTenantPopup = false
                  },
                },
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("button.closeBtn")) + "\n      "
                ),
              ]
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "ips-table",
              staticStyle: { "overflow-y": "scroll" },
            },
            [
              _c(
                "table",
                [
                  _c(
                    "tr",
                    { staticStyle: { "background-color": "#eee" } },
                    _vm._l(_vm.tableTitle, function (item, index) {
                      return _c("th", { key: index, attrs: { scope: "col" } }, [
                        _vm._v(
                          "\n            " + _vm._s(item.name) + "\n          "
                        ),
                      ])
                    }),
                    0
                  ),
                  _vm._l(_vm.taskList, function (item, index) {
                    return _c(
                      "tr",
                      { key: index, ref: "tr" + index, refInFor: true },
                      [
                        _c("td", [_vm._v(_vm._s(item.sku))]),
                        _c("td", [_vm._v(_vm._s(item.code))]),
                        _c("td", [_vm._v(_vm._s(item.serialNumber))]),
                        _c("td", [_vm._v(_vm._s(item.batchCode))]),
                        _c("td", [_vm._v(_vm._s(item.qty))]),
                      ]
                    )
                  }),
                ],
                2
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }