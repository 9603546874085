var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "hips-view",
    { attrs: { "header-fixed": true } },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("ips-header", {
            attrs: { title: _vm.$t("title.countDetail"), "is-index": false },
          }),
          !_vm.showSerialBtn
            ? _c(
                "div",
                {
                  staticClass:
                    "hips-nav-bar__buttons hips-nav-bar__buttons--right",
                },
                [
                  _c(
                    "hips-button",
                    {
                      staticClass: "header-right",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.handleShowPopup.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("button.configuration")) +
                          "\n      "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "hips-popup",
        {
          on: { "click-overlay": _vm.onPopupClose },
          model: {
            value: _vm.showPopup,
            callback: function ($$v) {
              _vm.showPopup = $$v
            },
            expression: "showPopup",
          },
        },
        [
          _c(
            "div",
            { staticClass: "popup" },
            [
              _c("hips-switch-cell", {
                attrs: { title: _vm.$t("label.accumulateQty") },
                on: { change: _vm.switchTag },
                model: {
                  value: _vm.accumulateQty,
                  callback: function ($$v) {
                    _vm.accumulateQty = $$v
                  },
                  expression: "accumulateQty",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "ips-container" },
        [
          _c("ips-lov", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.customData["location"]
                  ? _vm.hideControl["location"]
                  : true,
                expression:
                  "customData['location'] ? hideControl['location'] : true",
              },
            ],
            ref: "ref0",
            attrs: {
              label: _vm.$t("label.countLocation"),
              title: [
                _vm.$t("label.countLocationCode"),
                _vm.$t("label.locationName"),
                _vm.$t("label.whareaName"),
              ],
              required: "",
              params: ["locationCode", "locationName", "whareaName"],
              display: "locationCode",
              url: _vm.wmdmBaseUrl + "wms-locations/app",
              "url-params": { warehouseId: _vm.warehouseId },
              "pre-parse-url": _vm.urlData["locationCode"],
              custom: _vm.customData["location"],
              "source-parse-field": _vm.sourceParseField,
              "error-msg": this.$i18n.t("message.countLocationCkeck"),
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(0)
              },
              enter: function ($event) {
                return _vm.skipJudge(10, _vm.hidePlant)
              },
              down: function ($event) {
                return _vm.skipJudge(10, _vm.hidePlant)
              },
              choose: function ($event) {
                return _vm.handleChoose("location", $event)
              },
              clickIcon: function ($event) {
                return _vm.handleCommonClick(0, "lov")
              },
              setSourceField: function ($event) {
                return _vm.saveSourceField($event)
              },
              valuedByFoucs: function ($event) {
                return _vm.valuedByFoucs($event)
              },
            },
            model: {
              value: _vm.inputData.location,
              callback: function ($$v) {
                _vm.$set(_vm.inputData, "location", $$v)
              },
              expression: "inputData.location",
            },
          }),
          !_vm.hidePlant
            ? _c("ips-field", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.customData["plants"]
                      ? _vm.hideControl["plants"]
                      : true,
                    expression:
                      "customData['plants'] ? hideControl['plants'] : true",
                  },
                ],
                ref: "ref10",
                attrs: {
                  label: _vm.$t("label.tray"),
                  params: "plants",
                  display: "plants",
                  "pre-parse-url": _vm.urlData["plants"],
                  custom: _vm.customData["plants"],
                  "source-parse-field": _vm.sourceParseField,
                },
                on: {
                  enter: function ($event) {
                    return _vm.skipNext(20)
                  },
                  setSourceField: function ($event) {
                    return _vm.saveSourceField($event)
                  },
                  valuedByFoucs: function ($event) {
                    return _vm.valuedByFoucs($event)
                  },
                },
                model: {
                  value: _vm.inputData.plants,
                  callback: function ($$v) {
                    _vm.$set(_vm.inputData, "plants", $$v)
                  },
                  expression: "inputData.plants",
                },
              })
            : _vm._e(),
          _c("ips-lov", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.customData["sku"] ? _vm.hideControl["sku"] : true,
                expression: "customData['sku'] ? hideControl['sku'] : true",
              },
            ],
            ref: "ref20",
            attrs: {
              label: _vm.$t("label.sku"),
              title: [_vm.$t("label.sku"), _vm.$t("label.goodsName")],
              required: "",
              params: ["sku", "goodsName"],
              display: "sku",
              url: _vm.wmdmBaseUrl + "goods",
              "url-params": Object.assign(
                {},
                { orgId: _vm.orgId, ownerId: _vm.ownerId, enabledFlag: 1 },
                _vm.paramsObj
              ),
              "pre-parse-url": _vm.urlData["sku"],
              custom: _vm.customData["sku"],
              "source-parse-field": _vm.sourceParseField,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(20)
              },
              enter: function ($event) {
                return _vm.skipJudge(30, _vm.hideBatch)
              },
              down: function ($event) {
                return _vm.skipJudge(30, _vm.hideBatch)
              },
              choose: function ($event) {
                return _vm.handleChoose("sku", $event)
              },
              clickIcon: function ($event) {
                return _vm.handleCommonClick(20, "lov")
              },
              setSourceField: function ($event) {
                return _vm.saveSourceField($event)
              },
              valuedByFoucs: function ($event) {
                return _vm.valuedByFoucs($event)
              },
            },
            model: {
              value: _vm.inputData.sku,
              callback: function ($$v) {
                _vm.$set(_vm.inputData, "sku", $$v)
              },
              expression: "inputData.sku",
            },
          }),
          _vm.showSerialBtn
            ? _c(
                "hips-row",
                [
                  _c("ips-value-cell", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.customData["snapshotQty"]
                          ? _vm.hideControl["snapshotQty"]
                          : true,
                        expression:
                          "customData['snapshotQty'] ? hideControl['snapshotQty'] : true",
                      },
                    ],
                    attrs: {
                      title: _vm.$t("label.snapshotQty"),
                      value: _vm.inputData.snapshotQty,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("ips-value-cell", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.customData["goods"]
                  ? _vm.hideControl["goods"]
                  : true,
                expression: "customData['goods'] ? hideControl['goods'] : true",
              },
            ],
            attrs: {
              title: _vm.$t("label.goodsName"),
              value: _vm.inputData.goods,
            },
          }),
          _vm.showSerialBtn
            ? _c(
                "hips-row",
                [
                  _c("ips-value-cell", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.customData["uom"]
                          ? _vm.hideControl["uom"]
                          : true,
                        expression:
                          "customData['uom'] ? hideControl['uom'] : true",
                      },
                    ],
                    attrs: {
                      title: _vm.$t("label.unit"),
                      value: _vm.inputData.uom,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          !_vm.hideBatch
            ? _c("ips-field", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.customData["batch"]
                      ? _vm.hideControl["batch"]
                      : true,
                    expression:
                      "customData['batch'] ? hideControl['batch'] : true",
                  },
                ],
                ref: "ref30",
                attrs: {
                  label: _vm.$t("label.batch"),
                  required: "",
                  params: "batchCode",
                  display: "batchCode",
                  "pre-parse-url": _vm.urlData["batchCode"],
                  custom: _vm.customData["batch"],
                  "source-parse-field": _vm.sourceParseField,
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleCommonClick(30)
                  },
                  enter: function ($event) {
                    return _vm.handleBatchEnter()
                  },
                  setSourceField: function ($event) {
                    return _vm.saveSourceField($event)
                  },
                  valuedByFoucs: function ($event) {
                    return _vm.valuedByFoucs($event)
                  },
                },
                model: {
                  value: _vm.inputData.batch,
                  callback: function ($$v) {
                    _vm.$set(_vm.inputData, "batch", $$v)
                  },
                  expression: "inputData.batch",
                },
              })
            : _vm._e(),
          _vm.showSerialBtn
            ? _c("serialInputComp", {
                attrs: { params: _vm.serialCompParams },
                on: { serialNumberList: _vm.changeSerialNumberList },
                model: {
                  value: _vm.inputData.qty,
                  callback: function ($$v) {
                    _vm.$set(_vm.inputData, "qty", $$v)
                  },
                  expression: "inputData.qty",
                },
              })
            : _vm._e(),
          _c("ips-input-number", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.showSerialBtn
                  ? _vm.customData["packageQty"]
                    ? _vm.hideControl["packageQty"]
                    : true
                  : false,
                expression:
                  "!showSerialBtn ? (customData['packageQty'] ? hideControl['packageQty'] : true) : false",
              },
            ],
            ref: "ref50",
            attrs: {
              label: _vm.$t("label.packageQty"),
              min: null,
              required: "",
              "not-auto-fill": "",
              custom: _vm.customData["packageQty"],
              "source-parse-field": _vm.sourceParseField,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(50)
              },
              enter: function ($event) {
                return _vm.calInventoryQty(60)
              },
              down: function ($event) {
                return _vm.skipNext(60)
              },
              setSourceField: function ($event) {
                return _vm.saveSourceField($event)
              },
              valuedByFoucs: function ($event) {
                return _vm.valuedByFoucs($event)
              },
            },
            model: {
              value: _vm.inputData.packageQty,
              callback: function ($$v) {
                _vm.$set(_vm.inputData, "packageQty", $$v)
              },
              expression: "inputData.packageQty",
            },
          }),
          _c("ips-input-number", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.showSerialBtn
                  ? _vm.customData["casesNum"]
                    ? _vm.hideControl["casesNum"]
                    : true
                  : false,
                expression:
                  "!showSerialBtn ? (customData['casesNum'] ? hideControl['casesNum'] : true) : false",
              },
            ],
            ref: "ref60",
            attrs: {
              label: _vm.$t("label.casesNum"),
              min: null,
              "more-than-zero": true,
              "not-auto-fill": "",
              custom: _vm.customData["casesNum"],
              "source-parse-field": _vm.sourceParseField,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(60)
              },
              enter: function ($event) {
                return _vm.calInventoryQty(70)
              },
              down: function ($event) {
                return _vm.skipNext(70)
              },
              setSourceField: function ($event) {
                return _vm.saveSourceField($event)
              },
              valuedByFoucs: function ($event) {
                return _vm.valuedByFoucs($event)
              },
            },
            model: {
              value: _vm.inputData.casesNum,
              callback: function ($$v) {
                _vm.$set(_vm.inputData, "casesNum", $$v)
              },
              expression: "inputData.casesNum",
            },
          }),
          _c("ips-input-number", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.showSerialBtn
                  ? _vm.customData["tailBoxNum"]
                    ? _vm.hideControl["tailBoxNum"]
                    : true
                  : false,
                expression:
                  "!showSerialBtn ? (customData['tailBoxNum'] ? hideControl['tailBoxNum'] : true) : false",
              },
            ],
            ref: "ref70",
            attrs: {
              label: _vm.$t("label.tailBoxNum"),
              min: null,
              "more-than-zero": true,
              "not-auto-fill": "",
              custom: _vm.customData["tailBoxNum"],
              "source-parse-field": _vm.sourceParseField,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(70)
              },
              enter: function ($event) {
                return _vm.calInventoryQty(80)
              },
              down: function ($event) {
                return _vm.skipNext(80)
              },
              setSourceField: function ($event) {
                return _vm.saveSourceField($event)
              },
              valuedByFoucs: function ($event) {
                return _vm.valuedByFoucs($event)
              },
            },
            model: {
              value: _vm.inputData.tailBoxNum,
              callback: function ($$v) {
                _vm.$set(_vm.inputData, "tailBoxNum", $$v)
              },
              expression: "inputData.tailBoxNum",
            },
          }),
          _c("ips-input-number", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.showSerialBtn
                  ? _vm.customData["thisInventoryQty"]
                    ? _vm.hideControl["thisInventoryQty"]
                    : true
                  : false,
                expression:
                  "!showSerialBtn ? (customData['thisInventoryQty'] ? hideControl['thisInventoryQty'] : true) : false",
              },
            ],
            ref: "ref80",
            attrs: {
              label: _vm.$t("label.thisInventoryQty"),
              min: null,
              required: "",
              "more-than-zero": true,
              "not-auto-fill": "",
              custom: _vm.customData["thisInventoryQty"],
              "source-parse-field": _vm.sourceParseField,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(80)
              },
              enter: function ($event) {
                return _vm.calFinalCountQty("submitRef")
              },
              down: function ($event) {
                return _vm.skipNext("submitRef")
              },
              setSourceField: function ($event) {
                return _vm.saveSourceField($event)
              },
              valuedByFoucs: function ($event) {
                return _vm.valuedByFoucs($event)
              },
            },
            model: {
              value: _vm.inputData.thisInventoryQty,
              callback: function ($$v) {
                _vm.$set(_vm.inputData, "thisInventoryQty", $$v)
              },
              expression: "inputData.thisInventoryQty",
            },
          }),
          _c("ips-value-cell", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.showSerialBtn
                  ? _vm.customData["haveInventoryQty"]
                    ? _vm.hideControl["haveInventoryQty"]
                    : true
                  : false,
                expression:
                  "!showSerialBtn ? (customData['haveInventoryQty'] ? hideControl['haveInventoryQty'] : true) : false",
              },
            ],
            attrs: {
              title: _vm.$t("label.haveInventoryQty"),
              value: _vm.inputData.haveInventoryQty,
            },
          }),
          _c("ips-value-cell", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.showSerialBtn
                  ? _vm.customData["finalCountQty"]
                    ? _vm.hideControl["finalCountQty"]
                    : true
                  : false,
                expression:
                  "!showSerialBtn ? (customData['finalCountQty'] ? hideControl['finalCountQty'] : true) : false",
              },
            ],
            attrs: {
              title: _vm.$t("label.finalCountQty"),
              value: _vm.inputData.finalCountQty,
            },
          }),
          _c("ips-value-cell", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.showSerialBtn
                  ? _vm.showSnapshot
                    ? _vm.customData["snapshotQty"]
                      ? _vm.hideControl["snapshotQty"]
                      : true
                    : false
                  : false,
                expression:
                  "!showSerialBtn ? (showSnapshot ? (customData['snapshotQty'] ? hideControl['snapshotQty'] : true) : false) : false",
              },
            ],
            attrs: {
              title: _vm.$t("label.snapshotQty"),
              value: _vm.inputData.snapshotQty,
            },
          }),
          _vm.showSerialBtn
            ? _c("hips-row", [
                _c(
                  "div",
                  { staticStyle: { "margin-top": "16px" } },
                  [
                    _c(
                      "hips-col",
                      { attrs: { span: "24" } },
                      [
                        _c("ips-input-number", {
                          ref: "ref40",
                          attrs: {
                            required: "",
                            min: null,
                            label: _vm.$t("label.countQty"),
                            "more-than-zero": true,
                            custom: _vm.customData["qty"],
                            "source-parse-field": _vm.sourceParseField,
                            "enable-serial": _vm.serialType,
                            disabled: _vm.showSerialBtn,
                            "not-auto-fill": "",
                          },
                          on: {
                            setSourceField: function ($event) {
                              return _vm.saveSourceField($event)
                            },
                            valuedByFoucs: function ($event) {
                              return _vm.valuedByFoucs($event)
                            },
                          },
                          model: {
                            value: _vm.inputData.qty,
                            callback: function ($$v) {
                              _vm.$set(_vm.inputData, "qty", $$v)
                            },
                            expression: "inputData.qty",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "ips-footer" },
            [
              _c(
                "hips-button",
                {
                  ref: "submitRef",
                  attrs: { size: "large" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleButtonSubmit.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("svg-icon", {
                    attrs: { slot: "icon", "icon-class": "success" },
                    slot: "icon",
                  }),
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("button.confirm")) + "\n      "
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }