<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <!-- 批次分解 -->
      <ips-header :title="$t('title.batchDecomposition')" :is-index="true" />
    </div>
    <div class="ips-container">
      <!-- 原始批次 -->
      <ips-lov
        :label="$t('label.sourceBatch')"
        :title="$t('label.batchCode')"
        v-model="fromBatchCode"
        v-show="customData['fromBatchCode'] ? hideControl['fromBatchCode']: true"
        required
        params="batchCode"
        :url="`${wmdmBaseUrl}batchs/batch-code-lov`"
        :url-params="{ 'isbatchSplit': 1, 'enabledFlag': 1 }"
        :custom="customData['fromBatchCode']"
        :source-parse-field="sourceParseField"
        ref="ref0"
        @enter="skipNext(10)"
        @down="skipNext(10)"
        @click.stop="handleCommonClick(0)"
        @clickIcon="handleCommonClick(0, 'lov')"
        @choose="handleChoose('fromBatchCode', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!--物料-->
      <ips-lov
        :label="$t('label.sku')"
        :title="$t('label.sku')"
        v-model="fromSku"
        v-show="customData['fromSku'] ? hideControl['fromSku']: true"
        required
        params="sku"
        :url="`${wmdmBaseUrl}batchs/batch-modify-lov`"
        :url-params="{ 'batchCode': fromBatchCode, 'isbatchSplit': 1 }"
        :custom="customData['fromSku']"
        :source-parse-field="sourceParseField"
        ref="ref10"
        @click.stop="handleCommonClick(10)"
        @clickIcon="handleCommonClick(10, 'lov')"
        @choose="handleChoose('fromSku', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 来源托盘 -->
      <ips-lov
        :label="$t('label.sourceTray')"
        :title="$t('label.sourceTray')"
        v-model="fromCidCode"
        v-show="customData['fromCidCode'] ? hideControl['fromCidCode']: true"
        params="cidCode"
        :url="`${winvBaseUrl}app-batch-modify/original-cid-lov`"
        :url-params="fromCidParam"
        :custom="customData['fromCidCode']"
        :source-parse-field="sourceParseField"
        ref="ref20"
        @click.stop="handleCommonClick(20)"
        @clickIcon="handleCommonClick(20, 'lov')"
        @enter="skipNext(30)"
        @down="skipNext(30)"
        @choose="handleChoose('fromCidCode', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 来源库位 -->
      <ips-lov
        :label="$t('label.sourceLocation')"
        :title="$t('label.sourceLocation')"
        v-model="fromLocationCode"
        v-show="customData['fromLocationCode'] ? hideControl['fromLocationCode']: true"
        required
        params="locationCode"
        :url="`${winvBaseUrl}app-batch-modify/original-location-lov`"
        :url-params="fromLocParam"
        :custom="customData['fromLocationCode']"
        :source-parse-field="sourceParseField"
        ref="ref30"
        @click.stop="handleCommonClick(30)"
        @clickIcon="handleCommonClick(30, 'lov')"
        @enter="handleLocEnter"
        @down="handleLocEnter"
        @choose="handleChoose('fromLocationCode', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 来源库区 -->
      <ips-lov
        :label="$t('label.sourceWharea')"
        :title="$t('label.sourceWharea')"
        v-model="fromWhareaCode"
        v-show="customData['fromWhareaCode'] ? hideControl['fromWhareaCode']: true"
        required
        disabled
        :custom="customData['fromWhareaCode']"
        :source-parse-field="sourceParseField"
        ref="ref40"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />

      <!-- 转移数量 -->
      <ips-input-number
        :label="$t('label.amount')"
        v-model="transferQty"
        v-show="!enableSerial ? (customData['transferQty'] ? hideControl['transferQty'] : !enableSerial) : false"
        required
        :custom="customData['transferQty']"
        :source-parse-field="sourceParseField"
        ref="ref50"
        not-auto-fill
        :min="null"
        :more-than-zero="true"
        :enable-serial="serialType"
        @blur="checkNumBlur"
        @enter="skipNext('submitRef')"
        @down="skipNext('submitRef')"
        @tab="skipNext('submitRef')"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <hips-row v-if="enableSerial">
        <div class="hip-row">
          <hips-col span="21">
            <ips-input-number
              :label="$t('label.amount')"
              v-model="transferQty"
              required
              disabled
              :enable-serial="serialType"
              :custom="customData['transferQty']"
              :source-parse-field="sourceParseField"
              ref="ref50"
              not-auto-fill
              :min="null"
              :more-than-zero="true"
              @enter="skipNext('refSerail')"
              @down="skipNext('refSerail')"
              @tab="skipNext('refSerail')"
              @setSourceField="saveSourceField($event)"
              @valuedByFoucs="valuedByFoucs($event)"
            />
          </hips-col>
          <hips-col span="8">
            <hips-button
              size="large"
              @click.stop="goToSerialDetail"
              @enter="goToSerialDetail"
              ref="refSerail"
              class="button-detail"
            >
              {{ $t('button.serialNumber') }}
            </hips-button>
          </hips-col>
        </div>
      </hips-row>

      <div class="count_block_box">
        <!-- 现有量 -->
        <ips-number-count
          class="count-availableQty"
          :count="availableQty"
          :required="!enableSerial"
          :disabled="enableSerial"
          text-field="availableQty"
        />
        <!-- 可用量 -->
        <ips-number-count
          class="count-useQty"
          :count="useQty"
          text-field="useQty"
        />
        <!-- 保留量 -->
        <ips-number-count
          class="count-reserveQty"
          :count="reserveQty"
          text-field="reserveQty"
        />
      </div>
    </div>
    <div class="ips-footer">
      <hips-button
        size="large"
        @click.stop="handleCommonSubmit"
        @keyup.enter.native="handleCommonSubmit"
        @keypress.native.prevent
        ref="submitRef"
      >
        <svg-icon
          slot="icon"
          icon-class="success"
        />{{ $t('button.decomposition') }}
      </hips-button>
    </div>
  </hips-view>
</template>

<script>
  import {Col, Row} from '@hips/vue-ui';
  import { storage } from '@/utils';
  import { checkBatch } from '../api';
  export default {
    name: 'BatchSplit',
    components: {
      [Col.name]: Col,
      [Row.name]: Row,
    },
    data() {
      return {
        fromSerialFlag: false, // 从序列号组件跳转过来
        enableSerial: '',
        serialType: '',
        fromBatchId: '',
        fromBatchCode: '',
        goodsId: '',
        fromSku: '',
        skuInfo: {}, // 批次数据
        fromCidId: '',
        fromCidCode: '',
        fromLocationId: '',
        fromLocationCode: '',
        fromWhareaId: '',
        fromWhareaCode: '',
        warehouseId: '', // 仓库id
        fromCidParam: {}, // 来源托盘参数
        fromLocParam: {}, // 来源托盘参数
        transferQty: '', // 转换数量
        availableQty: '', // 现有量
        useQty: '', // 可用量
        reserveQty: '', // 保留量
        serialNumberList: [], // 序列号数据
        // urlData:{},
        customData: {}, // 个性化配置信息列表
        hideControl: {}, // 个性化配置显示隐藏控制
        sourceParseField: {}, // 界面中所有的来源解析字段
      };
    },
    methods: {
      /**
       *@functionName: saveSourceField
       *@description: 保存来源解析字段并赋值Enter
       *@params1: fieldObj[Object] 来源解析字段对象,
       */
      saveSourceField(fieldObj) {
        this.saveSourceParseField(this, fieldObj);
      },
      // 字段聚焦时赋值操作
      async valuedByFoucs(options={}) {
        const { custom ={} , currentValue } = options;
        await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
      },
      /**
       * handleChoose lov选中触发
       * @param type
       * @param obj 选中对象
       */
      handleChoose(type, obj) {
        const site = [this.SERIAL_INV_CTRL, this.SERIAL_SEMI_INV_CTRL];
        switch (type) {
          case 'fromBatchCode':
            this.fromBatchCode = obj.batchCode;
            break;
          case 'fromSku':
            // 先清空
            this.fromBatchId = '';
            this.serialType = '';
            this.fromSku = '';
            this.goodsId = '';
            this.fromCidId = '';
            this.fromCidCode = '';
            this.fromLocationId = '';
            this.fromLocationCode = '';
            this.fromWhareaId = '';
            this.fromWhareaCode = '';
            this.warehouseId = '';
            this.fromCidParam = {};
            this.fromLocParam = {};
            this.serialNumberList = [];
            this.$storage.set('serialNumberList', []);
            this.transferQty = '';
            this.availableQty = ''; // 现有量
            this.useQty = ''; // 可用量
            this.reserveQty = ''; // 保留量
            // 再赋值
            this.skuInfo = obj;
            this.fromBatchId = obj.id;
            this.fromSku = obj.sku;
            this.goodsId = obj.goodsId;
            this.serialType = obj.serialType;
            this.enableSerial = site.includes(obj.serialType);
            this.fromCidParam = {
              batchId: this.fromBatchId,
              goodsId: obj.goodsId
            };
            this.fromLocParam = {
              batchId: this.fromBatchId,
              goodsId: this.goodsId,
            };
            this.handleSkuBlur();
            break;
          case 'fromCidCode':
            this.fromCidId = obj.cidId;
            this.fromCidCode = obj.cidCode;
            this.fromLocationId = obj.locationId;
            this.fromLocationCode = obj.locationCode;
            this.fromWhareaId = obj.whareaId;
            this.fromWhareaCode = obj.whareaCode;
            this.warehouseId = obj.warehouseId;
            this.availableQty = obj.qty; // 现有量
            this.useQty = obj.validQty; // 可用量
            this.reserveQty = obj.reserveQty; // 保留量
            this.fromLocParam = {
              batchId: this.fromBatchId,
              goodsId: this.goodsId,
              cidId: obj.cidId
            };
            break;
          case 'fromLocationCode':
            this.fromLocationId = obj.locationId;
            this.fromLocationCode = obj.locationCode;
            this.fromWhareaId = obj.whareaId;
            this.fromWhareaCode = obj.whareaCode;
            this.warehouseId = obj.warehouseId;
            this.availableQty = obj.qty; // 现有量
            this.useQty = obj.validQty; // 可用量
            this.reserveQty = obj.reserveQty; // 保留量
            break;
          default:
            return null;
        }
      },
      /**
       * 物料lov失焦
       */
      async handleSkuBlur(){
        try {
          let data = {
            batchId: this.skuInfo.id,
            goodsId: this.skuInfo.goodsId
          };
          const res = await checkBatch(data);
          if(res && res.failed){
            this.skipNext(10);
          }else if(!res) {
            this.skipNext(20);
          }else {
            if(res.cidCode){
              this.fromCidId = res.cidId;
              this.fromCidCode = res.cidCode;
            }
            this.fromLocationId = res.locationId;
            this.fromLocationCode = res.locationCode;
            this.fromWhareaId = res.whareaId;
            this.fromWhareaCode = res.whareaCode;
            this.warehouseId = res.warehouseId;
            this.availableQty = res.qty; // 现有量
            this.useQty = res.validQty; // 可用量
            this.reserveQty = res.reserveQty; // 保留量
            if(this.enableSerial){
              this.skipNext('refSerail');
            } else {
              this.skipNext(50);
            }
          }
        }catch(e) {
          this.skipNext(10);
          console.log('错误：' + e);
        }
      },

      /**
       * 库位输入框失焦
       */
      handleLocEnter(){
        if(this.enableSerial){
          this.skipNext('refSerail');
        } else {
          this.skipNext(50);
        }
      },
      /**
       * 数字输入框失焦
       */
      checkNumBlur(){
        if(this.transferQty < 0){
          this.$hint.showFailed(this.$i18n.t('message.qtyMustBeGreaterThanZero'), true);
          this.skipNext(50);
        }
        if(this.transferQty > this.useQty){
          // this.$hint.showFailed('转移数量不能大于可用量');
          this.$hint.showFailed(this.$i18n.t('message.qtyMustBeSmallerThanValidQty'), true);
          this.skipNext(50);
        }
      },
      /**
       * 序列号录入
       */
      async goToSerialDetail(){
        if(!this.fromBatchId || !this.fromLocationId){
          this.$hint.showFailed(this.$i18n.t('message.checkRequired'), true);
          return
        }
        let data = {
          serialType: this.serialType,
          batchId: this.fromBatchId,
          goodsId: this.goodsId,
          cidId: this.fromCidId,
          locationId: this.fromLocationId,
          warehouseId: this.warehouseId,
        };
        const serialUrl = 'app-batch-modify/check-serial';
        const param = {
          name: 'batchSerialInput',
          params: {
            data,
            serialUrl,
            useQty: this.useQty,
            serialNumberList: this.serialNumberList || [],
          },
        };
        this.fromSerialFlag = true;
        this.$router.pushPage(param);
      },

      async handleButtonSubmit() {
        if(this.transferQty < 0){
          this.$hint.showFailed(this.$i18n.t('message.qtyMustBeGreaterThanZero'), true);
          this.skipNext(50);
          return;
        }
        if(this.transferQty > this.useQty){
          // this.$hint.showFailed('转移数量不能大于可用量');
          this.$hint.showFailed(this.$i18n.t('message.qtyMustBeSmallerThanValidQty'), true);
          this.skipNext(50);
          return;
        }
        let param = {
          name: 'BatchSplitConfirm',
          params: {
            source: 'batchDecomposition',
            fromData:{
              enableSerial: this.enableSerial,
              skuInfo: this.skuInfo,
              fromSku: this.fromSku,
              goodsId: this.goodsId,
              sku: this.skuInfo.sku,
              ownerName: this.skuInfo.ownerName,
              ownerId: this.skuInfo.ownerId,
              orgName: this.skuInfo.orgName,
              orgId: this.skuInfo.orgId,
              serialType: this.serialType,
              fromBatchId: this.fromBatchId,
              fromBatchCode: this.fromBatchCode,
              fromCidId: this.fromCidId,
              fromCidCode: this.fromCidCode,
              fromLocationId: this.fromLocationId,
              fromLocationCode: this.fromLocationCode,
              fromWhareaId: this.fromWhareaId,
              fromWhareaCode: this.fromWhareaCode,
              qty: this.transferQty,
              availableQty: this.availableQty,
              useQty: this.useQty,
              reserveQty: this.reserveQty,
              fromSerialNumberList: this.serialNumberList,
            },
            sourceParseField: {...this.sourceParseField},
            customData: this.customData,
            hideControl: this.hideControl,
          },
        };
        this.$router.pushPage(param);
      },

    },
    created() {
      const { customObj, hideControl } = this.handleCustomData(storage.get('customData'));
      this.customData = customObj;
      this.hideControl = hideControl;
      // console.log('批次分解customData', Object.assign({}, this.customData));
    },
    mounted() {
      this.fromSerialFlag = false;
      this.inputFieldList = [
        { type: 'lov', required: true, ref: 0, bind: 'fromBatchCode', field: 'fromBatchCode' },
        { type: 'lov', required: true, ref: 10, bind: 'fromSku', field: 'fromSku' },
        { type: 'lov', required: false, ref: 20, bind: 'fromCidCode', field: 'fromCidCode' },
        { type: 'lov', required: true, ref: 30, bind: 'fromLocationCode', field: 'fromLocationCode' },
        { type: 'lov', required: true, ref: 40, bind: 'fromWhareaCode', field: 'fromWhareaCode' },
        { type: 'number', required: true, ref: 50, bind: 'transferQty', field: 'transferQty' },
      ];
      this.skipNext(0);
    },
    activated() {
      const { deleteData, source, serialStockProcessDtoList } = this.$route.params;
      if(this.fromSerialFlag){
        this.fromSerialFlag = false;
        this.serialNumberList = this.$storage.get('serialNumberList') || [];
        this.transferQty = this.serialNumberList.length;
        this.skipNext('submitRef');
      }
      if(source === 'batchDecompositionConfirm' && !deleteData){
        this.$storage.set('serialNumberList', serialStockProcessDtoList);
      }
      if(deleteData){
        this.enableSerial = '';
        this.fromBatchId = '';
        this.fromBatchCode = '';
        this.fromSku = '';
        this.goodsId = '';
        this.serialType = '';
        this.fromCidId = '';
        this.fromCidCode = '';
        this.fromLocationId = '';
        this.fromLocationCode = '';
        this.fromWhareaId = '';
        this.fromWhareaCode = '';
        this.warehouseId = '';
        this.fromCidParam = {};
        this.fromLocParam = {};
        this.serialNumberList = [];
        this.$storage.set('serialNumberList', []);
        this.transferQty = '';
        this.availableQty = ''; // 现有量
        this.useQty = ''; // 可用量
        this.reserveQty = ''; // 保留量
        this.skipNext(0);
      }
    },
    beforeRouteLeave(to, from, next) {
      if (to.name === 'BatchSplitConfirm' || to.name === 'batchSerialInput') {
        this.$store.commit('pushKeepAlives', 'BatchSplit');
      } else {
        this.$store.commit('deleteKeepAlives', 'BatchSplit');
      }
      next();
    },
    // 从二级界面返回主界面, 清空来源解析字段
    beforeRouteEnter(to, from, next) {
      next((vm) => {
        const _this = vm;
        if(from.name === 'BatchSplitConfirm') {
          _this.clearSourceFields(_this);
        }
      });
    },
    watch:{
      'fromBatchCode': function(val, oldval) {
        if(oldval !== '' && val !== oldval){
          this.serialType = '';
          this.fromSku = '';
          this.goodsId = '';
          this.fromCidId = '';
          this.fromCidCode = '';
          this.fromLocationId = '';
          this.fromLocationCode = '';
          this.fromWhareaId = '';
          this.fromWhareaCode = '';
          this.warehouseId = '';
          this.fromCidParam = {};
          this.fromLocParam = {};
          this.serialNumberList = [];
          this.$storage.set('serialNumberList', []);
          this.transferQty = '';
          this.availableQty = ''; // 现有量
          this.useQty = ''; // 可用量
          this.reserveQty = ''; // 保留量
        }
      },
      'fromSku': function(val, oldval) {
        if(oldval !== '' && val === ''){
          this.fromBatchId = '';
          this.serialType = '';
          this.goodsId = '';
          this.fromCidId = '';
          this.fromCidCode = '';
          this.fromLocationId = '';
          this.fromLocationCode = '';
          this.fromWhareaId = '';
          this.fromWhareaCode = '';
          this.warehouseId = '';
          this.fromCidParam = {};
          this.fromLocParam = {};
          this.serialNumberList = [];
          this.$storage.set('serialNumberList', []);
          this.transferQty = '';
          this.availableQty = ''; // 现有量
          this.useQty = ''; // 可用量
          this.reserveQty = ''; // 保留量
        }
      },
      'fromCidCode': function(val, oldval) {
        if(oldval !== '' && val !== oldval){
          this.fromCidId = '';
          this.fromLocationId = '';
          this.fromLocationCode = '';
          this.fromWhareaId = '';
          this.fromWhareaCode = '';
          this.warehouseId = '';
          this.fromLocParam = {
            batchId: this.fromBatchId,
            goodsId: this.goodsId,
          };
          this.serialNumberList = [];
          this.$storage.set('serialNumberList', []);
          this.transferQty = '';
          this.availableQty = ''; // 现有量
          this.useQty = ''; // 可用量
          this.reserveQty = ''; // 保留量
        }
      },
      'fromLocationCode': function(val, oldval) {
        if(oldval !== '' && val === ''){
          this.fromLocationId = '';
          this.fromWhareaId = '';
          this.fromWhareaCode = '';
          this.warehouseId = '';
          this.serialNumberList = [];
          this.$storage.set('serialNumberList', []);
          this.transferQty = '';
          this.availableQty = ''; // 现有量
          this.useQty = ''; // 可用量
          this.reserveQty = ''; // 保留量
        }
      },
    }
  };
</script>

<style lang="stylus" scoped>
 @import '~@/style/var.styl'
  .count_block_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0;
  }
  .count-availableQty{
    background-color: #91D5FF
    border-color: var(--primaryColor)
    color: var(--primaryColor)
  }
  .count-useQty{
    background-color: #B7EB8F
    border-color: #52C41A
    color: #52C41A
  }
  .count-reserveQty{
    background-color: #FFD382
    border-color: #FF8F07
    color: #FF8F07
  }
  .hip-row{
    display flex
    padding  12px 0 0 0
    .button-detail{
      margin-left: 15px;
      margin-right: 5px;
      padding 0 5px;
      background-color: var(--primaryColor);
      color: #FFFFFF;
      border: none;
      font-size: 14px;
      height: 36px;
      line-height: 36px;
    }
  }
</style>
