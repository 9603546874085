<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="type === 'location' ? $t('title.locationInventory') : $t('title.batchInventory') " :is-index="false" />
    </div>
    <div class="ips-container stockDetail-container">
      <!-- 库位查询 -->
      <div v-if="type === 'location'">
        <div
          v-for="(item,index) in data"
          :key="index"
          class="list-item"
        >
          <div class="stockDetail-container__item">
            <!-- 物料 -->
            <ips-value-cell
              :title="$t('label.sku')"
              v-show="customData['skuDetail'] ? hideControl['skuDetail'] : true"
              :value="item.sku"
            />
            <!-- 品名 -->
            <ips-value-cell
              :title="$t('label.goodsName')"
              v-show="customData['goodsName'] ? hideControl['goodsName'] : true"
              :value="item.goodsName"
            />
            <!-- 单位 -->
            <ips-value-cell
              :title="$t('label.unit')"
              v-show="customData['uomName'] ? hideControl['uomName'] : true"
              :value="item.uomName"
            />
            <!-- 批次 -->
            <ips-value-cell
              :title="$t('label.batch')"
              v-show="customData['batchCodeDetail'] ? hideControl['batchCodeDetail'] : true"
              :value="item.batchCode"
            />
            <!-- 库区 -->
            <ips-value-cell
              :title="$t('label.whareaCode')"
              v-show="customData['whareaNameDetail'] ? hideControl['whareaNameDetail'] : true"
              :value="item.whareaName"
            />
            <!-- 库位 -->
            <ips-value-cell
              :title="$t('label.countLocation')"
              v-show="customData['locationNameDetail'] ? hideControl['locationNameDetail'] : true"
              :value="item.locationName"
            />
            <!-- 现有量 -->
            <ips-value-cell
              :title="$t('label.availableQty')"
              v-show="customData['qty'] ? hideControl['qty'] : true"
              :value="item.qty"
            />
            <!-- 可用量 -->
            <ips-value-cell
              :title="$t('label.useQty')"
              v-show="customData['validQty'] ? hideControl['validQty'] : true"
              :value="item.validQty"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <div
          v-for="(item,index) in data"
          :key="index"
          class="list-item"
        >
          <div class="stockDetail-container__item">
            <!--物料-->
            <ips-value-cell
              :title="$t('label.sku')"
              v-show="customData['skuDetail'] ? hideControl['skuDetail'] : true"
              :value="item.sku"
            />
            <!-- 品名 -->
            <ips-value-cell
              :title="$t('label.goodsName')"
              v-show="customData['goodsName'] ? hideControl['goodsName'] : true"
              :value="item.goodsName"
            />
            <!-- 单位 -->
            <ips-value-cell
              :title="$t('label.unit')"
              v-show="customData['uomName'] ? hideControl['uomName'] : true"
              :value="item.uomName"
            />
            <!-- 批次 -->
            <ips-value-cell
              :title="$t('label.batch')"
              v-show="customData['batchCodeDetail'] ? hideControl['batchCodeDetail'] : true"
              :value="item.batchCode"
            />
            <!-- 现有量 -->
            <ips-value-cell
              :title="$t('label.availableQty')"
              v-show="customData['qty'] ? hideControl['qty'] : true"
              :value="item.qty"
            />
            <!-- 可用量 -->
            <ips-value-cell
              :title="$t('label.useQty')"
              v-show="customData['validQty'] ? hideControl['validQty'] : true"
              :value="item.validQty"
            />
          </div>
        </div>
      </div>
    </div>
  </hips-view>
</template>

<script>
export default {
  name: 'StockDetail',
  data() {
    return {
      inputFieldList: [
        { type: 'text', bind: 'skuDetail', field: 'skuDetail' },
        { type: 'text', bind: 'goodsName', field: 'goodsName' },
        { type: 'text', bind: 'uomName', field: 'uomName' },
        { type: 'text',  bind: 'batchCodeDetail', field: 'batchCodeDetail' },
        { type: 'text',  bind: 'whareaNameDetail', field: 'whareaNameDetail' },
        { type: 'text',  bind: 'locationNameDetail', field: 'locationNameDetail' },
        { type: 'text', bind: 'qty', field: 'qty' },
        { type: 'text', bind: 'validQty', field: 'validQty' },
      ],
      data: [], //查询参数
      type: 'location', //查询类型
      customData: {}, // 个性化配置信息列表
      hideControl: {}, // 个性化配置显示隐藏控制
    };
  },
  created() {
    this.init();
  },
  methods: {
    /**
     * 初始化
     */
    async init() {
      try {
        const { customData, hideControl } = this.$route.params;
        this.customData = customData;
        this.hideControl = hideControl;
        if (this.$route.params.data) {
          this.data = this.$route.params.data;
          this.type = this.$route.params.type;
        }
      } catch (error) {
        this.$hint.showFailed(this.$i18n.t('message.unknownError'), true);
      } finally {
        this.$hint.hideLoading();
      }
    },
    //返回
    goBack() {
      this.$router.backPage();
    }
  }
};
</script>

<style lang="stylus" scoped>
 @import '~@/style/var.styl'
  .stockDetail-container {
    padding-left: 0;
    padding-right: 0;
    &__item {
      padding 10px 10px 0 10px
    }
    .list-item:not(:last-child) {
      border-bottom 10px solid #eee
    }
  }
</style>
