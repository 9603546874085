<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.inventoryCheck')" :is-index="false" />
    </div>
    <!-- 库区、库位、物料、批次、序列号、托盘 -->
    <hips-row class="ips-search">
      <hips-col span="20">
        <ips-field
          :label="stockQueryField"
          required
          v-model="queryCode"
          params="queryCode"
          display="queryCode"
          v-show="customData['queryCode'] ? hideControl['queryCode']: true"
          :pre-parse-params="{queryTag: queryTag}"
          :pre-parse-url="urlData['queryCode']"
          :pre-parse-title="preParseTitle"
          :pre-params="preParams"
          :custom="customData['queryCode']"
          :source-parse-field="sourceParseField"
          ref="ref0"
          @enter="handleButtonQuery"
          @setSourceField="saveSourceField($event)"
          @valuedByFoucs="valuedByFoucs($event)"
        />
      </hips-col>
      <hips-col span="4" class="ips-row-left">
        <hips-button
          type="warning"
          @click.stop="handleButtonQuery"
          @keyup.enter.native="handleButtonQuery"
          @keypress.native.prevent
          ref="selectRef"
        >
          {{ $t('button.query') }}
        </hips-button>
      </hips-col>
    </hips-row>
    <div class="ips-container">
      <!--库区卡片-->
      <div
        v-if="queryTag === 'WHAREA_TAG' && stockData.length>=1"
      >
        <div
          class="card_wharea"
          v-for="(item,index) in stockData"
          :key="index"
          :ref="`ref${index*10+10}`"
          @click="toWhareaSkuDetail(item)"
        >
          <div class="row">
            <div class="title">
              {{ $t('label.warehouse') }}:
            </div>
            <div class="label">
              {{ item.warehouseName }}
            </div>
          </div>
          <div class="row">
            <div class="title">
              {{ $t('label.whareaCode') }}:
            </div>
            <div class="label">
              {{ item.whareaCode }}
            </div>
          </div>
        </div>
      </div>
      <!--库位卡片-->
      <div
        v-if="queryTag === 'LOCATION_TAG' && stockData.length>=1"
      >
        <div
          class="card_countLocation"
          v-for="(item,index) in stockData"
          :key="index"
          :ref="`ref${index*10+10}`"
          @click="toLocationSkuDetail(item)"
        >
          <div class="row">
            <div class="title">
              {{ $t('label.warehouse') }}:
            </div>
            <div class="label">
              {{ item.warehouseName }}
            </div>
          </div>
          <div class="row">
            <div class="title">
              {{ $t('label.whareaCode') }}:
            </div>
            <div class="label">
              {{ item.whareaCode }}
            </div>
          </div>
          <div class="row">
            <div class="title">
              {{ $t('label.countLocation') }}:
            </div>
            <div class="label">
              {{ item.locationCode }}
            </div>
          </div>
        </div>

      </div>
      <!--物料卡片-->
      <div
        v-if="queryTag === 'GOODS_TAG' && stockData.length>=1"
      >
        <div
          class="card_countLocation"
          v-for="(item,index) in stockData"
          :key="index"
          :ref="`ref${index*10+10}`"
          @click="toSkuWarehouseDetail(item)"
        >
          <div class="row">
            <div class="title">
              {{ $t('label.org') }}:
            </div>
            <div class="label">
              {{ item.orgName }}
            </div>
          </div>
          <div class="row">
            <div class="title">
              {{ $t('label.ownerCode') }}:
            </div>
            <div class="label">
              {{ item.ownerName }}
            </div>
          </div>
          <div class="row">
            <div class="title">
              {{ $t('label.sku') }}:
            </div>
            <div class="label">
              {{ item.goodsName }}
            </div>
          </div>
        </div>

      </div>
      <!--序列号卡片-->
      <div
        v-if="queryTag === 'SERIAL_NUMBER_TAG' && stockData.length>=1"
      >
        <div
          class="card_wharea"
          v-for="(item,index) in stockData"
          :key="index"
          :ref="`ref${index*10+10}`"
        >
          <div class="row">
            <div class="title">
              {{ $t('label.org') }}:
            </div>
            <div class="label">
              {{ item.orgName }}
            </div>
          </div>
          <div class="row">
            <div class="title">
              {{ $t('label.serialNumber') }}:
            </div>
            <div class="label">
              {{ item.serialNumber }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </hips-view>
</template>

<script>
  import { storage } from '@/utils';
  import { queryStockDetail, getStock } from '../api';
  import { Row, Col } from '@hips/vue-ui';
  export default {
    name: 'StockQueryDetail',
    components:{
      [Row.name]: Row,
      [Col.name]: Col,
    },
    data() {
      return {
        queryCode: '', // 库区、库位、物料、批次、序列号、托盘
        queryTag: '',
        stockData: '', // 查询出的数据
        stockQueryField: '', // 默认数据
        routeParam: '', // 跳转参数
        urlData:{},
        customData: {}, // 个性化配置信息列表
        hideControl: {}, // 个性化配置显示隐藏控制
        sourceParseField: {}, // 界面中所有的来源解析字段
        preParseTitle: undefined,
        preParams: undefined,
      };
    },
    methods: {
      /**
       *@functionName: saveSourceField
       *@description: 保存来源解析字段并赋值Enter
       *@params1: fieldObj[Object] 来源解析字段对象,
       */
      saveSourceField(fieldObj) {
        this.saveSourceParseField(this, fieldObj);
      },
      // 字段聚焦时赋值操作
      async valuedByFoucs(options={}) {
        const { custom ={} , currentValue } = options;
        await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
      },
      // params传参
      async getParamsData(){
        const { flag, sourceParseField, customData, hideControl } = this.$route.params;
        this.queryTag = flag;
        this.sourceParseField = sourceParseField;
        this.customData = customData;
        this.hideControl = hideControl;
        switch (flag) {
          case 'WHAREA_TAG':
            this.stockQueryField = this.$t('label.whareaCode');
            this.urlData = this.handleData('STOCK_QUERY_WHAREA');
            break;
          case 'LOCATION_TAG':
            this.stockQueryField = this.$t('label.countLocation');
            this.urlData = this.handleData('STOCK_QUERY_LOCATION');
            break;
          case 'GOODS_TAG':
            this.stockQueryField = this.$t('label.sku');
            this.preParseTitle = [this.$t('label.sku'),this.$t('title.name'), this.$t('label.ownerCode')]
            this.preParams = ['mainValue','mainName','ownerCode']
            this.urlData = this.handleData('STOCK_QUERY_GOODS');
            break;
          case 'BATCH_TAG':
            this.stockQueryField = this.$t('label.batch');
            this.urlData = this.handleData('STOCK_QUERY_BATCH');
            break;
          case 'SERIAL_NUMBER_TAG':
            this.stockQueryField = this.$t('label.serialNumber');
            this.urlData = this.handleData('STOCK_QUERY_SERIAL');
            break;
          case 'CONTAINER_TAG':
            this.stockQueryField = this.$t('label.tray');
            this.urlData = this.handleData('STOCK_QUERY_CONTAINER');
            break;
          default:
            return null;
        }
      },
      // 查询
      async handleButtonQuery(){
        let _this = this;
        if (!_this.queryCode) {
          _this.$hint.showFailed(_this.$i18n.t('message.queryCodeNotNull'), true);
          return;
        }
        const data = {
          params: {
            queryTag: _this.queryTag,
            queryCode: _this.queryCode,
          }
        };
        _this.$hint.showLoading();
        queryStockDetail(data, this.urlData.queryCode).then((res)=>{
          if (this.urlData.queryCode) {
            queryStockDetail(data, null).then((resp)=>{
              _this.getStockParams(_this.queryTag, resp);
            })
          } else {
             _this.getStockParams(_this.queryTag, res);
          }
        }).finally(()=>{
          this.$hint.hideLoading();
        })
      },
      // 获取跳转参数
      async getStockParams(flag, queryData){
        try {
          const data = {
            params: {
              queryTag: flag,
              pageId: storage.get('menuId'),
            }
          };
          const res = await getStock(data);
          this.routeParam = res;
          this.routePage(flag, queryData);
        }catch (e) {
          console.log(e);
        }
      },
      // 跳转
      routePage(flag, res){
        let _this = this;
        switch (flag) {
          case 'WHAREA_TAG':
            _this.stockData = res.contentDTO.whareaList;
            if(_this.stockData.length === 1){
              _this.toWhareaSkuDetail(_this.stockData[0]);
            }
            break;
          case 'LOCATION_TAG':
            _this.stockData = res.contentDTO.locationList;
            if(_this.stockData.length === 1){
              _this.toLocationSkuDetail(_this.stockData[0]);
            }
            break;
          case 'GOODS_TAG':
            _this.stockData = res.contentDTO.goodsList;
            if(_this.stockData.length === 1){
              _this.toSkuWarehouseDetail(_this.stockData[0]);
            }
            break;
          case 'BATCH_TAG':
            _this.stockData = res.contentDTO.batchList;
            if(_this.stockData.length > 0){
              _this.toBatchWarehouseDetail(_this.stockData);
            }
            break;
          case 'SERIAL_NUMBER_TAG':
            _this.stockData = res.contentDTO.serialNumberList;
            if(_this.stockData.length === 1){
              _this.toSerialDetail(_this.stockData[0]);
            }
            break;
          case 'CONTAINER_TAG':
            _this.stockData = res.contentDTO.containerList;
            if(_this.stockData.length === 1){
              _this.toContainerDetail(_this.stockData[0]);
            }
            break;
          default:
            return null;
        }
        if(_this.stockData.length === 1){
          console.log("跳转")
        }
      },
      //跳转至库区-物料页面
      toWhareaSkuDetail(item){
        let param;
        if(this.routeParam === 'SUM_STOCK'){
          param = {
            name: 'WhareaSku',
            params: {
              item: item,
            },
          };
        }else {
          param = {
            name: 'WhareaDetail',
            params: {
              item: item,
              routeParam: this.routeParam,
            },
          };
        }
        this.$router.pushPage(param);
      },
      //跳转至库位-物料页面
      toLocationSkuDetail(item){
        let param;
        if(this.routeParam === 'SUM_STOCK'){
          param = {
            name: 'LocationSku',
            params: {
              item: item,
            },
          };
        }else {
          param = {
            name: 'LocationDetail',
            params: {
              item: item,
              routeParam: this.routeParam,
            },
          };
        }
        this.$router.pushPage(param);
      },
      //跳转至物料-仓库页面
      toSkuWarehouseDetail(item){
        let param;
        if(this.routeParam === 'SUM_STOCK'){
          param = {
            name: 'SkuWarehouse',
            params: {
              item: item,
            },
          };
        }else {
          param = {
            name: 'SkuDetail',
            params: {
              item: item,
              routeParam: this.routeParam,
            },
          };
        }
        this.$router.pushPage(param);
      },
      //跳转至批次-仓库页面
      toBatchWarehouseDetail(item){
        let param;
        if(this.routeParam === 'SUM_STOCK'){
          param = {
            name: 'BatchWarehouse',
            params: {
              item: item,
            },
          };
        }else {
          param = {
            name: 'BatchDetail',
            params: {
              item: item,
              routeParam: this.routeParam,
            },
          };
        }
        this.$router.pushPage(param);
      },
      //跳转至序列号-明细页面
      toSerialDetail(item){
        let param = {
          name: 'SerialDetail',
          params: {
            item: item,
          },
        };
        this.$router.pushPage(param);
      },
      //跳转至托盘-明细页面
      toContainerDetail(item){
        let param = {
          name: 'ContainerDetail',
          params: {
            item: item,
          },
        };
        this.$router.pushPage(param);
      },
    },
    async mounted() {
      await this.getParamsData();
      this.inputFieldList = [
        { type: 'input', required: true, ref: 0, bind: 'queryCode', field: 'queryCode' },
      ];
      this.skipNext(0);
    },
    activated() {
      this.skipNext(0);
    },
    deactivated(){
      this.queryCode = '';
      this.stockData = '';
    },
    beforeRouteLeave(to, from, next) {
      if (to.name !== 'StockQuery') {
        this.$store.commit('pushKeepAlives', 'StockQueryDetail');
      } else {
        this.$store.commit('deleteKeepAlives', 'StockQueryDetail');
      }
     this.$nextTick(() => {
        next();
      })
    },
  };
</script>

<style lang="stylus" scoped>
 @import '~@/style/var.styl'
  .ips-search{
    display flex
    padding: 4.27vw 2vw 0 2vw;
    .ips-row-left{
      float right
      text-align right
    }
  }
  .ips-container{
    padding: 0 0 16vw!important;
    .ips-row{
      width: 66%;
      margin: 24px 50px;
      .ips-col{
        text-align center
        a{
          color var(--primaryColor)
        }
      }
    }
    .card_wharea{
      margin-top: 4.267vw;
      padding: 0.4vw 2.7vw;
      color: #000;
      box-shadow: 0 0 2.133vw #d9d9d9;
      border: none;
      font-size: 14px;
      height:70px;
      .row{
        display flex
        padding  2.7vw 0 0 0
        .title{
          width:63px;
          height:18px;
          font-size:14px;
          font-family:PingFangSC-Regular,PingFang SC;
          font-weight:200;
          color:#8C8C8C;
          line-height:18px;
        }
        .label{
          width:289px;
          height:18px;
          font-size:14px;
          font-family:PingFangSC-Medium,PingFang SC;
          font-weight:initial;
          color:#000000;
          line-height:18px;
          overflow: hidden;      /*溢出隐藏*/
          white-space: nowrap;	/*规定文本不进行换行*/
          text-overflow: ellipsis;	/*当对象内文本溢出时显示省略标记（...）*/
        }
      }
    }
    .card_countLocation{
      margin-top: 4.267vw;
      padding: 0.4vw 2.7vw;
      color: #000;
      box-shadow: 0 0 2.133vw #d9d9d9;
      border: none;
      font-size: 14px;
      height:95px;
      .row{
        display flex
        padding  2.7vw 0 0 0
        .title{
          width:42px;
          height:18px;
          font-size:14px;
          font-family:PingFangSC-Regular,PingFang SC;
          font-weight:200;
          color:#8C8C8C;
          line-height:18px;
        }
        .label{
          width:289px;
          height:18px;
          font-size:14px;
          font-family:PingFangSC-Medium,PingFang SC;
          font-weight:initial;
          color:#000000;
          line-height:18px;
          overflow: hidden;      /*溢出隐藏*/
          white-space: nowrap;	/*规定文本不进行换行*/
          text-overflow: ellipsis;	/*当对象内文本溢出时显示省略标记（...）*/
        }
      }
    }
  }
</style>
