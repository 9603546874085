<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.subinventoryTransfer')" />
    </div>
    <!-- 物料、序列号、托盘 -->
    <hips-row class="ips-search">
      <hips-col span="20">
        <ips-field
          :label="$t('label.stockQuery')"
          required
          v-show="customData['queryCode'] ? hideControl['queryCode']: true"
          v-model="queryCode"
          :custom="customData['queryCode']"
          :source-parse-field="sourceParseField"
          ref="ref0"
          @enter="handleButtonQuery"
          @setSourceField="saveSourceField($event)"
          @valuedByFoucs="valuedByFoucs($event)"
        />
      </hips-col>
      <hips-col span="4" class="ips-row-left">
        <hips-button
          type="warning"
          @click.stop="handleButtonQuery"
          @keyup.enter.native="handleButtonQuery"
          @keypress.native.prevent
          ref="submitRef"
        >
          {{ $t('button.query') }}
        </hips-button>
      </hips-col>
    </hips-row>
    <div class="ips-container">
      <div v-if="stockData.length === 0 && transferType === 'ALL'">
        <span class="instructions">
          {{ $t('label.instructions') }}
        </span>
        <hips-row class="ips-row">
          <hips-col class="ips-col" span="7">
            <a
              @enter="toQueryDetail('GOODS_TAG')"
              @click.stop="toQueryDetail('GOODS_TAG')"
            >{{ $t('label.sku') }}</a>
          </hips-col>
          <hips-col class="ips-col" span="1">
            |
          </hips-col>
          <hips-col class="ips-col" span="7">
            <a
              @enter="toQueryDetail('SERIAL_NUMBER_TAG')"
              @click.stop="toQueryDetail('SERIAL_NUMBER_TAG')"
            >{{ $t('label.serialNumber') }}</a>
          </hips-col>
          <hips-col class="ips-col" span="1">
            |
          </hips-col>
          <hips-col class="ips-col" span="7">
            <a
              @enter="toQueryDetail('CONTAINER_TAG')"
              @click.stop="toQueryDetail('CONTAINER_TAG')"
            >{{ $t('label.tray') }}</a>
          </hips-col>
        </hips-row>
      </div>
      <!--物料卡片-->
      <div
        v-if="objTag === 'GOODS_TAG' && stockData.length>=1"
      >
        <div
          class="card_countLocation"
          v-for="(item,index) in stockData"
          :key="index"
          :ref="`ref${index*10+10}`"
          @click="toSkuTransfer(item)"
        >
          <div class="row">
            <div class="title">
              {{ $t('label.org') }}:
            </div>
            <div class="label">
              {{ item.orgName }}
            </div>
          </div>
          <div class="row">
            <div class="title">
              {{ $t('label.ownerCode') }}:
            </div>
            <div class="label">
              {{ item.ownerName }}
            </div>
          </div>
          <div class="row">
            <div class="title">
              {{ $t('label.sku') }}:
            </div>
            <div class="label">
              {{ item.goodsName }}
            </div>
          </div>
        </div>

      </div>
      <!--序列号卡片-->
      <div
        v-if="objTag === 'SERIAL_NUMBER_TAG' && stockData.length>=1"
      >
        <div
          class="card_wharea"
          v-for="(item,index) in stockData"
          :key="index"
          :ref="`ref${index*10+10}`"
          @click="toSerialDetail(item)"
        >
          <div class="row">
            <div class="title">
              {{ $t('label.org') }}:
            </div>
            <div class="label">
              {{ item.orgName }}
            </div>
          </div>
          <div class="row">
            <div class="title">
              {{ $t('label.serialNumber') }}:
            </div>
            <div class="label">
              {{ item.serialNumber }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </hips-view>
</template>

<script>
  import { getSubinventory, getStockTransferType } from '../api';
  import { storage } from '@/utils';
  import { Row, Col } from '@hips/vue-ui';
  export default {
    name: 'SubinventoryQuery',
    components:{
      [Row.name]: Row,
      [Col.name]: Col,
    },
    data() {
      return {
        queryCode: '', // 物料、序列号、托盘
        queryTag: '', // 查询类型
        stockData: '', // 查询出的数据
        objTag: '', // 查询结果的类型
        transferType: '', // 页面功能参数
        customData: {}, // 个性化配置信息列表
        hideControl: {}, // 个性化配置显示隐藏控制
        sourceParseField: {}, // 界面中所有的来源解析字段
      };
    },
    methods: {
      async stockTransferType(){
        try {
          this.$hint.showLoading();
          const data = {
            params: {
              pageId: storage.get('menuId'),
            }
          };
          const res = await getStockTransferType(data);
          this.transferType = res;
        }finally {
          this.$hint.hideLoading();
        }
      },
      // 查询
      async handleButtonQuery(){
        let _this = this;
        if (!_this.queryCode) {
          _this.$hint.showFailed(_this.$i18n.t('message.queryCodeNotNull'), true);
          return;
        }
        const data = {
          params: {
            queryTag: _this.queryTag,
            queryCode: _this.queryCode,
            transferType: _this.transferType,
          }
        };
        _this.$hint.showLoading();
        getSubinventory(data).then((res)=>{
          _this.objTag = res.objTag;
          _this.routePage(res.objTag, res);
        }).finally(()=>{
          this.$hint.hideLoading();
        })
      },
      /**
       *@functionName: saveSourceField
       *@description: 保存来源解析字段并赋值Enter
       *@params1: fieldObj[Object] 来源解析字段对象,
       fieldCode[String] 来源解析字段
       parseValue[String] 二维码解析值
       subFields[Array]] 关联子字段
       */
      saveSourceField(fieldObj) {
        this.saveSourceParseField(this, fieldObj);
      },
      /**
       * 字段聚焦时赋值操作
       */
      async valuedByFoucs(options={}) {
        const { custom ={} , currentValue } = options;
        await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
      },
      // 跳转
      routePage(flag, res){
        let _this = this;
        switch (flag) {
          case 'GOODS_TAG':
            _this.stockData = res.contentDTO.goodsList;
            if(_this.stockData.length === 1){
              _this.toSkuTransfer(_this.stockData[0]);
            }
            break;
          case 'SERIAL_NUMBER_TAG':
            _this.stockData = res.contentDTO.serialNumberList;
            if(_this.stockData.length === 1){
              _this.toSerialDetail(_this.stockData[0]);
            }
            break;
          case 'CONTAINER_TAG':
            _this.stockData = res.contentDTO.containerList;
            if(_this.stockData.length === 1){
              _this.toContainerDetail(_this.stockData[0]);
            }
            break;
          default:
            return null;
        }
        if(_this.stockData.length === 1){
          console.log("跳转")
        }
      },
      // 跳转详细查询
      toQueryDetail(flag){
        let param = {
          name: 'SubinventoryQueryDetail',
          params: {
            flag: flag,
          },
        };
        this.$router.pushPage(param);
      },
      //跳转至物料页面
      toSkuTransfer(item){
        let param = {
            name: 'SkuTransfer',
            params: {
              item: item,
              transferType: this.transferType,
              sourceParseField: {...this.sourceParseField},
              customData: this.customData,
              hideControl: this.hideControl,
            },
          };
        this.$router.pushPage(param);
      },
      //跳转至序列号页面
      toSerialDetail(item){
        let param = {
          name: 'SerialTransfer',
          params: {
            item: item,
            sourceParseField: {...this.sourceParseField},
            customData: this.customData,
            hideControl: this.hideControl,
          },
        };
        this.$router.pushPage(param);
      },
      //跳转至托盘页面
      toContainerDetail(item){
        let param = {
          name: 'CidTransfer',
          params: {
            item: item,
            transferType: this.transferType,
            sourceParseField: {...this.sourceParseField},
            customData: this.customData,
            hideControl: this.hideControl,
          },
        };
        this.$router.pushPage(param);
      },
    },
    created() {
      const { customObj, hideControl } = this.handleCustomData(storage.get('customData'));
      this.customData = customObj;
      this.hideControl = hideControl;
      // console.log('子库存转移customData', Object.assign({}, this.customData));
    },
    async mounted() {
      this.inputFieldList = [
        { type: 'input', required: true, ref: 0, bind: 'queryCode', field: 'queryCode' },
      ];
      this.skipNext(0);
      this.stockTransferType();
    },
    activated() {
      this.skipNext(0);
    },
  };
</script>

<style lang="stylus" scoped>
 @import '~@/style/var.styl'
  .ips-search{
    display flex
    padding: 4.27vw 2vw 0 2vw;
    .ips-row-left{
      float right
      text-align right
    }
  }
    .ips-container{
        padding: 0 0 16vw!important;
        .instructions{
            display: block;
            margin: 15px 0 15px 0;
            padding-left 2vw
            font-size 14px;
            color #8C8C8C
        }
        .ips-row{
            width: 66%;
            margin: 24px 50px;
            .ips-col{
                text-align center
                font-size 14px;
                a{
                    color var(--primaryColor)
                }
            }
        }
        .card_wharea{
            margin 10px 0;
            height:76px;
            color: #000;
            box-shadow: 0 0 2.133vw #d9d9d9;
            border: none;
            font-size: 14px;
            .row{
                display flex
                padding  14px 12px 0 12px
                .title{
                    width:63px;
                    height:18px;
                    font-size:14px;
                    font-family:PingFangSC-Regular,PingFang SC;
                    font-weight:200;
                    color:#8C8C8C;
                    line-height:18px;
                }
                .label{
                    width:289px;
                    height:18px;
                    font-size:14px;
                    font-family:PingFangSC-Medium,PingFang SC;
                    font-weight:initial;
                    color:#000000;
                    line-height:18px;
                }
            }
        }
        .card_countLocation{
            margin 10px 0;
            height:114px;
            color: #000;
            box-shadow: 0 0 2.133vw #d9d9d9;
            border: none;
            font-size: 14px;
            .row{
                display flex
                padding  14px 12px 0 12px
                .title{
                    width:42px;
                    height:18px;
                    font-size:14px;
                    font-family:PingFangSC-Regular,PingFang SC;
                    font-weight:200;
                    color:#8C8C8C;
                    line-height:18px;
                }
                .label{
                    width:289px;
                    height:18px;
                    font-size:14px;
                    font-family:PingFangSC-Medium,PingFang SC;
                    font-weight:initial;
                    color:#000000;
                    line-height:18px;
                }
            }
        }
    }
</style>