<template>
  <div id="app">
    <!-- <div style="position: absolute; width:200px; height:200px; bottom:80px; left:0;z-index:1000; background:white; padding:5px;border:1px solid #333;">
      测试缓存模板代码：
      {{ keepAlives }}
    </div> -->
    <transition :name="routerTransition">
      <keep-alive :include="keepAlives">
        <router-view />
      </keep-alive>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import { setDocumentTitle } from '@/utils';

// import { noAuthHttp } from '@/request';

export default {
  name: 'App',
  data() {
    return {
      routerTransition: 'router-slide-left',
    };
  },
  computed: {
    keepAlives() {
      return this.$store.getters.getkeepAlives;
    },
  },
  watch: {
    $route(to, from) {
      setDocumentTitle(to.meta.title);
      this.routerTransition = from.meta.routerTransition;
    },
  },
  mounted() {
    //取子应用资源路径值
    // let route = this.$router;
    let search = window.location.search;
    if (search !== '') {
      let t = search.split('?')[1].split('&');
      let searchObj = {};
      t.map((item) => {
        searchObj[item.split('=')[0]] = item.split('=')[1];
      });
      this.setLocationSearch(searchObj);
    }
    //初始化
    console.log('app.vue');
    this.init();
  },
  methods: {
    ...mapGetters(['getRouterHistory']),
    ...mapMutations(['setRouterHistory', 'setLocationSearch']),

    init() {
      // 初始化tip显示时间
      this.$storage.set('closeTime', 2000);
      // 阻止输入框失焦事件
      document.addEventListener('mousedown', (e) => {
        const flag = e.target.dataset && e.target.dataset.blur;
        console.log('监听事件mousedown');
        if (e.target.tagName !== 'INPUT' && (e.target.tagName !== 'BUTTON' || flag)) {
          e.preventDefault();
        }
      });
    },
  },
};
</script>
<style lang="stylus">
@import 'style/theme.styl';
@import 'style/common.styl';
</style>
