<template>
  <div class="container">
    <div class="top">
      <div class="left">
        {{ $t('label.defaultWarehouse') }}
      </div>
      <div class="right" @click="openPopupModal">
        <svg-icon slot="icon" icon-class="add" />
        {{ $t('label.chooseWarehouse') }}
      </div>
    </div>
    <div class="content">
      <img v-if="showImg" src="@/public/assets/pic-no-data.png" alt="'NO Data'">
      <ul v-if="showContent" class="warehouse_area">
        <li v-for="(item, index) in selectedWarehouseList" :key="index">
          <span>{{ item.warehouseCode }}</span>
          <span>{{ item.warehouseName }}</span>
          <span ref="icon-del" class="icon-del" @click="handleDelete(item)">
            <svg-icon slot="icon" icon-class="delete_sm" />
          </span>
        </li>
      </ul>
    </div>
    <hips-popup v-model="showPopup" position="bottom" :overlay="true">
      <div class="popup_title">
        <span class="title_item" @click="handleCancel">{{ $t('button.cancel') }}</span>
        <div class="title_item">
          {{ $t('label.chooseWarehouse') }}
        </div>
        <span class="title_item" @click="handleSave">{{ $t('button.save') }}</span>
      </div>
      <div class="popup_content">
        <hips-scroll
          ref="scroll"
          :scroll-options="scrollOption"
          :data="[allWarehouseList]"
          @pulling-down="loadRefresh"
          @pulling-up="loadMore"
        >
          <hips-checkbox-group v-model="selectedOptions" :checkbox-border="true">
            <hips-checkbox v-for="(item, index) in allWarehouseList" :key="index" :option="item.warehouseId.toString()" position="right">
              <div class="warehouse_item">
                <span>{{ item.warehouseCode }}</span>
                <span>{{ item.warehouseName }}</span>
              </div>
            </hips-checkbox>
          </hips-checkbox-group>
        </hips-scroll>
      </div>
    </hips-popup>
  </div>
</template>

<script>
export default {
    name:'WarehouseChoose',
    data(){
      return {
        showImg: false,
        showContent: false,
        showPopup: false,
        allWarehouseList: [],  //全部仓库（注意会受分页影响而变化 10  20  30 ）
        selectedOptions: [],  // 选中的option组合 option为字符
        selectedWarehouseList: [],  // 选中的仓库数组
        page: 0,
        pageSize: 10,
        allData: [],
      }
    },
    created() {
      this.scrollOption = {
        pullDownRefresh: {
          threshold: 60, // 触发 pullingDown 的距离
          stop: 40, // pullingDown 正在刷新 hold 时的距离
          txt: this.$t('message.refreshSuccess'),
        },
        pullUpLoad: {
          txt: {
            more: this.$t('message.pullOnLoading'),
            noMore: this.$t('message.noMoreData'),
          },
        },
      };
      this.getSelectedWareList();
      this.getAllWareList();
      this.getAllData();
    },
    methods: {
       /** 
       * 一次性获取所有仓库
       */
      async getAllData() {
        const url = `${this.wmdmBaseUrl}warehouses/permission-warehouse-list`;
        const res = await this.$http.get(url, {params: {size:1000}});
        this.allData = res.content; 
      },

      /** 
       * 获取当前分页所有可选仓库
       */
      async getAllWareList() {
        try {
          this.$hint.showLoading();
          const url = `${this.wmdmBaseUrl}warehouses/permission-warehouse-list`;
          const res = await this.$http.get(url, {params: {size:10}});
          this.allWarehouseList = res.content; 
        }finally {
          this.$hint.hideLoading();
        }
      },

      /** 
       *查询已选中仓库信息
      */
    async getSelectedWareList() {
        try {
          this.$hint.showLoading();
          const url = `${this.wmdmBaseUrl}warehouses/query-user-warehourse-permissions`;
          const res = await this.$http.get(url, {});
          this.showImg = !res || res.warehouseList.length === 0;
          this.showContent = res && res.warehouseList.length > 0;
          this.selectedWarehouseList = res.warehouseList;
        }finally {
          this.$hint.hideLoading();
        }
      },

      openPopupModal() {
        this.$bridge.fullScreen();
        this.showPopup = !this.showPopup;
        this.selectedOptions = this.selectedWarehouseList.map((item)=>{
          return item.warehouseId.toString(); // 多选框的option只支持字符
        })
      },

        // 加载刷新数据
      async loadRefresh () {
        const url = `${this.wmdmBaseUrl}warehouses/permission-warehouse-list`;
        const data = await this.$http.get(url, {params: {size:10}});
        // 初始化数据
        this.allWarehouseList = data.content;
        this.page = 0;
      },

        // 加载更多数据
      async loadMore () {
        const page = this.page + 1;
        const url = `${this.wmdmBaseUrl}warehouses/permission-warehouse-list`;
        const params = {
          page,
          size: 10,
        }
        const data = await this.$http.get(url, {params});
        this.allWarehouseList.push(...data.content);
        // 判断是否已达最后一页
        if (data.content.length < this.pageSize) {
          // 如果没有新数据，不用强制更新滚轮，注意下面的 forceUpdate 方法需要包裹在 nextTick 中
          this.$nextTick(() => {
            this.$refs.scroll.forceUpdate()
          })
        } else {
          this.page++;
        }
      },

      handleCancel() {
        this.selectedOptions = [];
        this.showPopup = false;
      },

      /**
       * 删除选中仓库
       */
      async handleDelete(delItem) {
        const newSelectedId = [];
        this.selectedWarehouseList.forEach((item)=>{
          if(item.warehouseId !== delItem.warehouseId) {
            newSelectedId.push(item.warehouseId);
          }
        })
        const url = `${this.wmdmBaseUrl}warehouses/save-user-warehourse-permissions`;
        const res = await this.$http.post(url,newSelectedId);  // 返回选中的ID数组
        this.selectedWarehouseList = this.allData.filter((item)=>{
          return res.includes(item.warehouseId);
        })
        this.selectedOptions = this.selectedWarehouseList.map((item)=>{
          return item.warehouseId.toString(); // 多选框的option只支持字符
        })
      },

      /**
       * 保存
       */
      async handleSave() {
        try {
          this.$hint.showLoading()
          const selectedIdList = [];
          // console.log('selectedOptions',this.selectedOptions)
          // console.log('allWarehouseList', this.allWarehouseList)
          this.selectedOptions.forEach((item)=>{
            selectedIdList.push(Number(item));
          })
          // console.log('IdList',selectedIdList)
          const url = `${this.wmdmBaseUrl}warehouses/save-user-warehourse-permissions`;
          const res = await this.$http.post(url,selectedIdList);  // 返回选中的ID数组
  
          this.selectedWarehouseList = this.allData.filter((item)=>{
            return res.includes(item.warehouseId);
          })
      
          this.showPopup = false;
          this.showImg = this.selectedWarehouseList.length === 0;
          this.showContent = this.selectedWarehouseList.length > 0;
        }catch {
          this.showImg = true;
        }finally {
          this.$hint.hideLoading();
        }
      }
    }
}

</script>
<style lang="stylus" scoped>
 @import '~@/style/var.styl'
.container {
  height 100%
  margin 5px 20px 20px 20px
  position relative
  .top {
    border-bottom 1px solid #eee
    padding 10px 0
    overflow hidden
    .left {
      float left
      font-size 14px
      font-weight bold
    }
    .right {
      float right
      color var(--primaryColor)
      font-size 14px
    }
  }
  .content {
    width 100%
    text-align center
    overflow hidden
    img {
      width 60%
      margin-top 30px
    }
    .warehouse_area {
      margin-top 10px
      overflow hidden
      /*width 100%*/
      li {
        display inline
        float left
        /*max-width: 100% !important;*/
        /*white-space nowrap!important*/
        font-size 12px
        line-height 26px
        background-color #E8F7FF
        color var(--primaryColor)
        border 1px solid var(--primaryColor)
        padding 0 10px
        border-radius 15px
        margin 0 10px 10px 0
        text-align left 
        span:first-child {
          margin-right 8px
        }
        span{
          white-space normal
        }
        .icon-del {
          padding-left 5px
          color var(--primaryColor)
        }
      }
    }
  }
  
}

.hips-popup--bottom {
    height 90%
    overflow-y: hidden;
    .popup_title {
      width 100%
      display flex
      box-sizing border-box
      align-items center
      padding 10px
      .title_item {
          flex 1
          &:first-child {
              color var(--primaryColor)
          }
          &:nth-child(2) {
              text-align center
          }
          &:last-child {
              text-align right 
              color var(--primaryColor)
          }
      }  
    }
    .popup_content {
      box-sizing border-box
      align-items center
      padding 10px
      height calc(100vh - 40px) // 这里是外层滚轮父容器的指定高度
      .warehouse_item {
        width 80%
        display flex
        margin-left 20px
        span {
          flex 1
          &:last-child {
            overflow hidden
            white-space nowrap
            text-overflow ellipsis
          }
        }
      }
    }
}
</style>