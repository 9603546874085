var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card_box" }, [
    Object.keys(_vm.titleInfo).length > 0
      ? _c(
          "div",
          { staticClass: "card_title" },
          _vm._l(_vm.titleInfo, function (val, index) {
            return _c("span", { key: index, staticClass: "title_span" }, [
              _vm._v("\n      " + _vm._s(val) + "\n    "),
            ])
          }),
          0
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "card_content" },
      [
        _vm._l(_vm.reactField, function (field, index) {
          return _c("div", { key: index, staticClass: "content" }, [
            _c("span", { staticClass: "card_content_span" }, [
              _vm._v(
                _vm._s(
                  _vm.reactFieldNameKeys.includes(field)
                    ? _vm.$t("label." + _vm.reactFieldName[field])
                    : _vm.$t("label." + field)
                ) + ":"
              ),
            ]),
            _vm.linkField.includes(field)
              ? _c(
                  "span",
                  {
                    staticClass: "card_content_span_value value_link",
                    on: {
                      click: function ($event) {
                        return _vm.linkFieldEvent(_vm.item)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.item[field] || "无"))]
                )
              : _c("span", { staticClass: "card_content_span_value" }, [
                  _vm._v(
                    _vm._s(
                      _vm.item[field] === 0
                        ? 0
                        : _vm.item[field]
                        ? _vm.item[field]
                        : _vm.$t("message.noData")
                    )
                  ),
                ]),
          ])
        }),
        _vm._t("default"),
      ],
      2
    ),
    Object.keys(_vm.footerInfo).length > 0
      ? _c(
          "div",
          { staticClass: "card_footer" },
          _vm._l(_vm.footerInfo, function (val, index) {
            return _c("span", { key: index, staticClass: "title_span" }, [
              _vm._v("\n      " + _vm._s(val) + "\n    "),
            ])
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }