<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.roundsTransform')" />
      <div class="hips-nav-bar__buttons hips-nav-bar__buttons--right">
        <hips-button class="header-right" @click.stop="handleShowPopup">
          {{ $t('button.configuration') }}
        </hips-button>
      </div>
    </div>
    <hips-popup v-model="showPopup" @click-overlay="onPopupClose">
      <div class="popup">
        <hips-switch-cell @change="switchTag" v-model="processId" :title="$t('label.locationTran')" />
      </div>
    </hips-popup>
    <div class="ips-container">
      <!-- 来源库区 -->
      <ips-lov
        :label="$t('label.sourceWharea')"
        :title="[$t('label.fromWhareaCode'), $t('label.fromWhareaName')]"
        v-model="inputData.fromWharea"
        v-show="customData['fromWharea'] ? hideControl['fromWharea'] : true"
        :params="['whareaCode', 'whareaName']"
        display="whareaName"
        required
        :url="`${wmdmBaseUrl}whareas/accessible-list`"
        :pre-parse-url="urlData['fromWharea']"
        :custom="customData['fromWharea']"
        :source-parse-field="sourceParseField"
        ref="ref0"
        @click.stop="handleCommonClick(0)"
        @enter="skipNext(10)"
        @down="skipNext(10)"
        :error-msg="this.$i18n.t('message.countLocationCkeck')"
        @choose="handleChoose('fromWharea', $event)"
        @clickIcon="handleCommonClick(0, 'lov')"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 来源库位 -->
      <ips-lov
        :label="$t('label.sourceLocation')"
        :title="[$t('label.countLocationCode'), $t('label.locationName')]"
        v-model="inputData.fromLocation"
        v-show="customData['fromLocation'] ? hideControl['fromLocation'] : true"
        :params="['locationCode', 'locationName']"
        display="locationCode"
        required
        :url="`${wmdmBaseUrl}pda-goods-change/from-stock-location`"
        :url-params="{ whareaId }"
        :pre-parse-url="urlData['fromLocation']"
        :custom="customData['fromLocation']"
        :source-parse-field="sourceParseField"
        ref="ref10"
        @click.stop="handleCommonClick(10)"
        @enter="skipNext(20)"
        @down="skipNext(20)"
        @choose="handleChoose('fromLocation', $event)"
        @clickIcon="handleCommonClick(10, 'lov')"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 来源物料 -->
      <ips-lov
        :label="$t('label.sourceSku')"
        :title="$t('label.skuCode')"
        v-model="inputData.fromSku"
        v-show="customData['fromSku'] ? hideControl['fromSku'] : true"
        params="sku"
        display="sku"
        required
        :url="`${wmdmBaseUrl}pda-goods-change/from-goods`"
        :url-params="{ whareaId, locationId: fromLocationId }"
        :pre-parse-url="urlData['fromSku']"
        :custom="customData['fromSku']"
        :source-parse-field="sourceParseField"
        ref="ref20"
        @click.stop="handleCommonClick(20)"
        @enter="showbatch ? skipNext(30) : skipNext(40)"
        @down="showbatch ? skipNext(30) : skipNext(40)"
        :error-msg="this.$i18n.t('message.countLocationCkeck')"
        @choose="handleChoose('fromSku', $event)"
        @clickIcon="handleCommonClick(20, 'lov')"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 来源批次 -->
      <ips-lov
        :label="$t('label.sourceBatchs')"
        :title="$t('label.batchCodeNew')"
        v-model="inputData.fromBatchCode"
        v-show="showbatch"
        params="batchCode"
        display="batchCode"
        :required="showbatch"
        :url="`${wmdmBaseUrl}pda-goods-change/from-batch`"
        :url-params="{ whareaId, locationId: fromLocationId, goodsId: fromGoodsId }"
        :pre-parse-url="urlData['fromBatchCode']"
        :custom="customData['fromBatchCode']"
        :source-parse-field="sourceParseField"
        ref="ref30"
        @click.stop="handleCommonClick(30)"
        @enter="skipNext(40)"
        @down="skipNext(40)"
        :error-msg="$i18n.t('message.countLocationCkeck')"
        @choose="handleChoose('fromBatchCode', $event)"
        @clickIcon="handleCommonClick(30, 'lov')"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 品名 -->
      <ips-value-cell
        :title="$t('label.goodsName')"
        :value="goodsName"
        v-show="customData['goodsName'] ? hideControl['goodsName'] : true"
      />
      <!-- 现有量 -->
      <ips-value-cell :title="$t('label.availableQty')" :value="haveQty" v-show="customData['haveQty'] ? hideControl['haveQty'] : true" />
      <!-- 可用量 -->
      <ips-value-cell
        :title="$t('label.useQty')"
        :value="availableQty"
        v-show="customData['availableQty'] ? hideControl['availableQty'] : true"
      />
      <!-- 目标库位 -->
      <ips-lov
        :label="$t('label.targetLocation')"
        :title="[$t('label.countLocationCode'), $t('label.locationName')]"
        v-model="inputData.toLocation"
        v-show="customData['toLocation'] ? hideControl['toLocation'] : true"
        :params="['locationCode', 'locationName']"
        display="locationCode"
        required
        :url="`${wmdmBaseUrl}wms-locations`"
        :url-params="{ enabledFlag: 1, warehouseId: fromWarehouseId }"
        :pre-parse-url="urlData['toLocation']"
        :custom="customData['toLocation']"
        :source-parse-field="sourceParseField"
        ref="ref40"
        @click.stop="handleCommonClick(40)"
        @enter="skipNext(50)"
        @down="skipNext(50)"
        :error-msg="this.$i18n.t('message.countLocationCkeck')"
        @choose="handleChoose('toLocation', $event)"
        @clickIcon="handleCommonClick(40, 'lov')"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 目标物料 -->
      <ips-lov
        :label="$t('label.toSku')"
        :title="$t('label.skuCode')"
        v-model="inputData.toSkuName"
        v-show="customData['toSkuName'] ? hideControl['toSkuName'] : true"
        params="sku"
        display="sku"
        required
        :url="`${wmdmBaseUrl}pda-goods-change/to-goods`"
        :url-params="{ fromGoodsId }"
        :pre-parse-url="urlData['toSkuName']"
        :custom="customData['toSkuName']"
        :source-parse-field="sourceParseField"
        ref="ref50"
        @click.stop="handleCommonClick(50)"
        @enter="skipJudge()"
        @down="skipJudge()"
        :error-msg="this.$i18n.t('message.countLocationCkeck')"
        @choose="handleChoose('toSkuName', $event)"
        @clickIcon="handleCommonClick(50, 'lov')"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 目标批次 -->
      <ips-input
        :label="$t('label.targetBatch')"
        v-model="inputData.toBatch"
        v-show="showBatch()"
        :custom="customData['toBatch']"
        :source-parse-field="sourceParseField"
        :required="showBatch()"
        :pre-parse-url="urlData['toBatch']"
        ref="ref60"
        @click.stop="handleCommonClick(60)"
        @enter="skipNext(70)"
        @down="skipNext(70)"
        not-auto-fill
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 品名 -->
      <ips-value-cell
        :title="$t('label.goodsName')"
        :value="toGoodsName"
        v-show="customData['toGoodsName'] ? hideControl['toGoodsName'] : true"
      />
      <!-- 转换数量 -->
      <ips-input
        :label="$t('label.transQty')"
        v-model="inputData.qty"
        ref="ref70"
        :disabled="processId"
        :pre-parse-url="urlData['qty']"
        not-auto-fill
        :min="null"
        v-show="customData['qty'] ? hideControl['qty'] : true"
        :custom="customData['qty']"
        :source-parse-field="sourceParseField"
        @blur="transQtyBlur"
        @enter="skipNext('submitRef')"
        @down="skipNext('submitRef')"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
    </div>
    <div class="ips-footer">
      <hips-button size="large" @click.stop="handleCommonSubmit" ref="submitRef">
        <svg-icon slot="icon" icon-class="success" />
        {{ $t('button.confirm') }}
      </hips-button>
    </div>
  </hips-view>
</template>

<script>
import { storage } from '@/utils';
import { getQty, submit } from '../api';

export default {
  name: 'RoundsTransform',
  data() {
    return {
      showPopup: false,
      inputData: {
        fromWharea: '',
        fromLocation: '',
        fromSku: '',
        fromBatchCode: '',
        toLocation: '',
        toSkuName: '',
        toBatch: '',
        qty: null,
      },
      urlData: {},
      hideControl: {}, // 个性化配置显示隐藏控制
      sourceParseField: {}, // 界面中所有的来源解析字段
      customData: {}, // 个性化配置信息
      isbatch: 0,
      showbatch: false,
      haveQty: null,
      availableQty: undefined,
      processId: true, // 整库位转换
      serialType: '',
      fromWhareaCode: '',
      fromWhareaId: null,
      whareaId: null,
      fromLocationCode: '',
      fromLocationId: null,
      toLocationCode: '',
      toLocationId: null,
      toWhareaId: null,
      toWhareaCode: '',
      fromGoodsId: null,
      goodsName: '',
      fromBatchId: null,
      toSku: '',
      toGoodsId: null,
      toGoodsName: '',
      fromWarehouseId: null,
      fromWarehouseCode: '',
      toWarehouseId: null,
      toWarehouseCode: '',
    };
  },
  created() {
    const { customObj, hideControl } = this.handleCustomData(storage.get('customData'));
    this.customData = customObj;
    this.hideControl = hideControl;
  },
  async mounted() {
    this.inputFieldList = [
      { type: 'lov', required: true, ref: 0, bind: 'inputData.fromWharea', field: 'fromWharea' },
      { type: 'lov', required: true, ref: 10, bind: 'inputData.fromLocation', field: 'fromLocation' },
      { type: 'lov', required: true, ref: 20, bind: 'inputData.fromSku', field: 'fromSku' },
      { type: 'text', bind: 'goodsName', field: 'goodsName' },
      { type: 'text', bind: 'haveQty', field: 'haveQty' },
      { type: 'text', bind: 'useQty', field: 'useQty' },
      { type: 'lov', ref: 30, bind: 'inputData.fromBatchCode', field: 'fromBatchCode' },
      { type: 'lov', required: true, ref: 40, bind: 'inputData.toLocation', field: 'toLocation' },
      { type: 'lov', ref: 50, bind: 'inputData.toSkuName', field: 'toSkuName' },
      { type: 'input', ref: 60, bind: 'inputData.toBatch', field: 'toBatch' },
      { type: 'input', ref: 70, bind: 'inputData.qty', field: 'qty' },
      { type: 'text', bind: 'toGoodsName', field: 'toGoodsName' },
    ];
    this.urlData = this.handleData('ROUNDS_TRANSFORM_QUERY');
    this.skipNext(0);
  },
  methods: {
    /**
     *@functionName: saveSourceField
     *@description: 保存来源解析字段并赋值Enter
     *@params1: fieldObj[Object] 来源解析字段对象,
     fieldCode[String] 来源解析字段
     parseValue[String] 二维码解析值
     subFields[Array]] 关联子字段
     */
    saveSourceField(fieldObj) {
      this.saveSourceParseField(this, fieldObj);
    },
    /**
     * 字段聚焦时赋值操作
     */
    async valuedByFoucs(options = {}) {
      const { custom = {}, currentValue } = options;
      await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
    },
    // 点击配置按钮
    handleShowPopup() {
      const { processId, customData, hideControl, urlData, inputFieldList } = this;
      Object.assign(this.$data, this.$options.data(), {
        processId,
        customData,
        hideControl,
        urlData,
        inputFieldList
      });
      this.showPopup = true;
    },
    // 关闭配置界面
    onPopupClose() {
      this.skipNext(0);
    },
    // 整库位转换
    switchTag(val) {
      this.processId = val;
    },
    // 判断批次是否显示
    showBatch() {
      if (!this.processId && this.isbatch && this.serialType === 'UNCTRL') {
        return this.customData['fromBatchCode'] ? this.hideControl['fromBatchCode'] : true;
      }
      return false;
    },
    skipJudge() {
      if (this.showBatch()) {
        this.skipNext(60);
      } else if (this.processId) {
        this.skipNext('submitRef');
      } else {
        this.skipNext(70);
      }
    },

    /**
     * lov选择框，选择数据
     * @param {String} type - 定位lov
     * @param {object} obj - 选中行的数据
     */
    handleChoose(type, obj) {
      switch (type) {
        case 'fromWharea':
          this.fromWhareaCode = obj.whareaCode;
          this.fromWhareaId = obj.whareaId;
          this.whareaId = obj.whareaId;
          this.fromWarehouseId = obj.warehouseId;
          this.fromWarehouseCode = obj.warehouseCode;
          break;
        case 'fromLocation':
          this.fromLocationCode = obj.locationCode;
          this.fromLocationId = obj.locationId;
          break;
        case 'fromSku':
          this.fromGoodsId = obj.goodsId;
          this.goodsName = obj.goodsName; // 品名
          this.isbatch = obj.isbatch;
          this.getQty(0);
          break;
        case 'fromBatchCode':
          this.fromBatchId = obj.batchId;
          this.getQty(1);
          break;
        case 'toLocation':
          this.toLocationCode = obj.locationCode;
          this.toLocationId = obj.locationId;
          this.toWhareaId = obj.whareaId;
          this.toWhareaCode = obj.whareaCode;
          this.toWarehouseId = obj.warehouseId;
          this.toWarehouseCode = obj.warehouseCode;
          break;
        case 'toSkuName':
          this.toSku = obj.sku;
          this.toGoodsId = obj.goodsId;
          this.toGoodsName = obj.goodsName;
          break;
        default:
          return null;
      }
    },

    async getQty(isBatch) {
      const { whareaId, fromLocationId, fromGoodsId, fromBatchId } = this;
      const query = {
        params: {
          whareaId,
          locationId: fromLocationId,
          goodsId: fromGoodsId,
          isBatch,
        },
      };
      if (isBatch) {
        query.params.batchId = fromBatchId;
      }
      const res = await getQty(query);
      this.haveQty = res.content[0]?.qty;
      this.availableQty = res.content[0]?.qty - res.content[0]?.reserveQty;
      this.serialType = res.content[0]?.serialType;
      if (!this.processId) {
        this.inputData = Object.assign({}, this.inputData, { qty: this.availableQty });
      }
      if (!isBatch) {
        this.showbatch = this.showBatch();
        this.$nextTick(() => {
          if (this.showbatch) {
            this.skipNext(30);
          } else {
            this.skipNext(40);
          }
        });
      }
    },

    // 校验转换数量
    transQtyBlur() {
      if (!this.processId) {
        if (this.qty < 0 || this.qty > this.availableQty) {
          if (this.qty < 0) {
            this.$hint.showFailed(this.$i18n.t('message.qtyMustBeGreaterThanZero'), true);
          } else {
            this.$hint.showFailed(this.$i18n.t('message.qtyMustBeSmallerThanValidQty'), true);
          }
          this.skipNext('submitRef');
        }
      }
    },

    /**
     * 确认按钮
     */
    async handleButtonSubmit() {
      try {
        const { fromWharea, fromLocation, fromSku, fromBatchCode, toLocation, toSkuName, toBatch } = this.inputData;
        // 必输项未输时提示
        if (this.showbatch) {
          if (!fromBatchCode || !toBatch) {
            return this.$hint.showFailed(this.$i18n.t('message.parameterInformationIncomplete'), true);
          }
        }
        if (!fromWharea || !fromLocation || !fromSku || !toLocation || !toSkuName) {
          return this.$hint.showFailed(this.$i18n.t('message.parameterInformationIncomplete'), true);
        }
        this.$hint.showLoading();
        const data = [
          {
            fromWhareaId: this.fromWhareaId,
            fromWhareaCode: this.fromWhareaCode,
            fromLocationId: this.fromLocationId,
            fromLocationCode: this.fromLocationCode,
            fromGoodsId: this.fromGoodsId,
            fromSku: this.inputData.fromSku,
            toLocationId: this.toLocationId,
            toLocationCode: this.toLocationCode,
            toWhareaId: this.toWhareaId,
            toWhareaCode: this.toWhareaCode,
            toGoodsId: this.toGoodsId,
            toSku: this.toSku,
            qty: this.inputData.qty,
            processId: this.processId ? 1 : 0,
            fromWarehouseId: this.fromWarehouseId,
            fromWarehouseCode: this.fromWarehouseCode,
            toWarehouseId: this.toWarehouseId,
            toWarehouseCode: this.toWarehouseCode,
          },
        ];
        if (this.showbatch) {
          data[0].fromBatchCode = this.inputData.fromBatchCode;
          data[0].fromBatchId = this.fromBatchId;
          data[0].toBatchCode = this.inputData.toBatch;
        }

        const res = await submit(data);
        if (res && !res.failed) {
          const fromWharea = this.inputData.fromWharea;
          const { fromWhareaCode, fromWhareaId, whareaId, fromWarehouseId, fromWarehouseCode, processId } = this;
          Object.assign(this.$data, this.$options.data(), {
            fromWhareaCode,
            fromWhareaId,
            whareaId,
            fromWarehouseId,
            fromWarehouseCode,
            processId,
            inputData: { fromWharea },
          });
          this.$hint.showSuccess();
          this.skipNext(10);
        } else {
          this.$hint.showFailed(res.message);
        }
      } catch (e) {
        console.log('错误：' + e);
      } finally {
        this.$hint.hideLoading();
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.header-right {
  padding: 0 10px;
  background-color: #eee;
  color: #88c0f0
}
.popup {
  width: 50 vw;
}

.content-cell {
  color:#666;
}

.content-card {
    flex: 1;
    border-radius: 1.6vw;
    width: 96%;
    color: #000;
    font-size: 3.467vw;
    box-shadow: 0 0 2.133vw #d9d9d9;
    overflow: hidden;
    padding: 5px;
    margin-bottom: 6px;
}

.cell {
  height: 30px;
  line-height: 30px;
  padding: 0 5px;
}
</style>
