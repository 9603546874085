import Vue from 'vue';
import Vuex from 'vuex';

let modules = {};
if (process.env.VUE_APP_TARGET === 'all') {
  modules = {
    workFlow: require('@/modules/receive/store').default,
  };
} else {
  let ary = process.env.VUE_APP_TARGET.split('-');
  let moduleName = '';
  ary.map((item, index) => {
    if (index > 0) {
      item = item.replace(item.charAt(0), item.charAt(0).toUpperCase());
    }
    moduleName += item;
  });
  modules[moduleName] = require(`@/modules/${process.env.VUE_APP_TARGET}/store`).default;
}

let keepAlives = ['InventorTransfer', 'Unstacking', 'ContanierData', 'DerectInbound', 'texta', 'EntireTray', 'DirectOutbound'];

Vue.use(Vuex);

let store = new Vuex.Store({
  modules,
  state: {
    routerHistory: [],
    userInfo: void 0,
    allSystemUser: void 0,
    locationSearch: void 0,
    keepAlives,
  },
  getters: {
    getRouterHistory: (state) => {
      return state.routerHistory;
    },
    getUserInfo: (state) => {
      return state.userInfo;
    },
    getAllSystemUser: (state) => {
      return state.allSystemUser;
    },
    getLocationSearch: (state) => {
      return state.locationSearch;
    },
    getkeepAlives: (state) => {
      return state.keepAlives;
    },
  },

  mutations: {
    setRouterHistory: (state, value) => {
      state.routerHistory = value;
    },
    setUserInfo: (state, value) => {
      state.userInfo = value;
    },
    setAllSystemUser: (state, value) => {
      state.allSystemUser = value;
    },
    setLocationSearch: (state, value) => {
      state.locationSearch = value;
    },
    setKeepAlives: (state, value) => {
      state.keepAlives = value;
    },
    pushKeepAlives: (state, value) => {
      const index = state.keepAlives.indexOf(value);
      if (index === -1) {
        state.keepAlives.push(value);
      }
    },
    deleteKeepAlives: (state, value) => {
      const index = state.keepAlives.indexOf(value);
      if (index > -1) {
        state.keepAlives.splice(index, 1);
      }
    },
  },
});

export default store;
