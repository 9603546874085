var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: { title: _vm.$t("title.interceptTally"), "is-index": true },
          on: {
            beforeBack: function ($event) {
              return _vm.goBack()
            },
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _c("ips-field", {
          ref: "ref0",
          attrs: {
            required: "",
            label: _vm.$t("label.expressBackCode"),
            params: "expressNo",
            display: "expressNo",
            "pre-parse-url": _vm.urlData["expressNo"],
            custom: _vm.customData["expressNo"],
          },
          on: {
            blur: function ($event) {
              return _vm.validatePack()
            },
            click: function ($event) {
              return _vm.handleCommonClick(0)
            },
            enter: function ($event) {
              return _vm.skipNext("submitRef")
            },
            down: function ($event) {
              return _vm.skipNext("submitRef")
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.inputData.expressNo,
            callback: function ($$v) {
              _vm.$set(_vm.inputData, "expressNo", $$v)
            },
            expression: "inputData.expressNo",
          },
        }),
        _c("ips-field", {
          ref: "ref10",
          attrs: {
            disabled: "",
            label: _vm.$t("label.deliveryCode"),
            params: "billCode",
            display: "billCode",
            "pre-parse-url": _vm.urlData["billCode"],
            custom: _vm.customData["billCode"],
          },
          model: {
            value: _vm.inputData.billCode,
            callback: function ($$v) {
              _vm.$set(_vm.inputData, "billCode", $$v)
            },
            expression: "inputData.billCode",
          },
        }),
        _c(
          "div",
          { staticClass: "ips-table", staticStyle: { "overflow-y": "scroll" } },
          [
            _c(
              "table",
              [
                _c(
                  "tr",
                  { staticStyle: { "background-color": "#eee" } },
                  _vm._l(_vm.tableColumns, function (item, index) {
                    return _c("th", { key: index, attrs: { scope: "col" } }, [
                      _vm._v(
                        "\n            " + _vm._s(item.title) + "\n          "
                      ),
                    ])
                  }),
                  0
                ),
                _vm._l(_vm.interceptTallyDetails, function (item, index) {
                  return _c(
                    "tr",
                    { key: item.id, ref: "tr" + index, refInFor: true },
                    _vm._l(_vm.tableColumns, function (col) {
                      return _c("td", { key: col.dataIndex }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(item[col.dataIndex]) +
                            "\n          "
                        ),
                      ])
                    }),
                    0
                  )
                }),
              ],
              2
            ),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-footer" },
      [
        _c(
          "hips-button",
          {
            ref: "submitRef",
            class: [
              "ips-btn-normal",
              {
                "ips-btn-no-value": !_vm.inputData.billCode,
              },
            ],
            attrs: { size: "large" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonSubmit.apply(null, arguments)
              },
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleCommonSubmit.apply(null, arguments)
              },
              keypress: function ($event) {
                $event.preventDefault()
              },
            },
          },
          [
            _c("svg-icon", {
              attrs: { slot: "icon", "icon-class": "success" },
              slot: "icon",
            }),
            _vm._v("\n      " + _vm._s(_vm.$t("button.submit")) + "\n    "),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }