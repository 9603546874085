var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "hips-view",
    { attrs: { "header-fixed": true } },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("ips-header", {
            attrs: { title: _vm.$t("title.workManagement") },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "ips-container" }, [
        _c("div", { staticClass: "tabs" }, [
          _c(
            "div",
            {
              class: _vm.activeName === "pendingTasks" ? "tab active" : "tab",
              on: {
                click: function ($event) {
                  _vm.activeName = "pendingTasks"
                },
              },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("button.taskToBeProcessed")) +
                  "(" +
                  _vm._s(_vm.pendingTasks.length) +
                  ")\n      "
              ),
            ]
          ),
          _c(
            "div",
            {
              class: _vm.activeName === "processedTasks" ? "tab active" : "tab",
              on: {
                click: function ($event) {
                  _vm.activeName = "processedTasks"
                },
              },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("button.haveToDealWith")) +
                  "(" +
                  _vm._s(_vm.processedTasks.length) +
                  ")\n      "
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "tab g-flex-center",
              on: {
                click: function ($event) {
                  _vm.showInput = !_vm.showInput
                },
              },
            },
            [
              _c("hips-icon", { attrs: { name: "menu", size: 20 } }),
              _vm._v(" " + _vm._s(_vm.$t("button.more")) + "\n      "),
            ],
            1
          ),
        ]),
        _vm.showInput
          ? _c(
              "div",
              { staticClass: "filter-inputs" },
              [
                _c("hips-input", {
                  attrs: {
                    label: _vm.$t("label.jobType"),
                    placeholder: "" + _vm.$t("label.jobType"),
                    type: "text",
                    "label-position": "left",
                  },
                  on: { input: _vm.filterData },
                  model: {
                    value: _vm.taskTypeName,
                    callback: function ($$v) {
                      _vm.taskTypeName = $$v
                    },
                    expression: "taskTypeName",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "date-box g-flex" },
          [
            _c("hips-icon", { attrs: { name: "alert-route", size: 20 } }),
            _c(
              "div",
              { staticClass: "g-flex-center", staticStyle: { flex: "1" } },
              [
                _c("div", { staticClass: "g-flex-between" }, [
                  _c(
                    "div",
                    {
                      staticClass: "start-date",
                      on: {
                        click: function ($event) {
                          _vm.showPopupStartDate = true
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.startDate) +
                          "\n          "
                      ),
                    ]
                  ),
                  _c("div", { staticStyle: { margin: "0 20px" } }, [
                    _vm._v("\n            至\n          "),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "end-date",
                      on: {
                        click: function ($event) {
                          _vm.showPopupEndDate = true
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " + _vm._s(_vm.endDate) + "\n          "
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ],
          1
        ),
        _vm.dataList.length > 0
          ? _c(
              "div",
              { staticClass: "task-list" },
              _vm._l(_vm.dataList, function (item) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    staticClass: "task-item",
                    class: item.taskType,
                    on: {
                      click: function ($event) {
                        return _vm.goCompleteTask(item)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "top-box g-flex-between" }, [
                      _c("div", { staticClass: "g-flex-center" }, [
                        _c("div", { staticClass: "tag" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.taskTypeName.slice(0, 2)) +
                              "\n            "
                          ),
                        ]),
                        item.taskType === "PUT_AWAY"
                          ? _c("span", [_vm._v(" " + _vm._s(item.cidCode))])
                          : _vm._e(),
                        item.taskType === "OUT_WAVE"
                          ? _c("span", [
                              _vm._v(" " + _vm._s(item.waveBillCode)),
                            ])
                          : _vm._e(),
                        item.taskType === "OUT_BILL"
                          ? _c("span", [_vm._v(" " + _vm._s(item.billCode))])
                          : _vm._e(),
                        item.taskType === "TRANSFER"
                          ? _c("span", [_vm._v(" " + _vm._s(item.billCode))])
                          : _vm._e(),
                      ]),
                      _c("div", [_vm._v(_vm._s(item.billType))]),
                    ]),
                    _c("div", { staticClass: "mid-box g-flex-between" }, [
                      _c("div", { staticClass: "left" }, [
                        _c("div", { staticClass: "cell" }, [
                          item.taskType === "PUT_AWAY"
                            ? _c("div", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("label.deliveryCode")) +
                                    "：" +
                                    _vm._s(item.billCode) +
                                    "\n              "
                                ),
                              ])
                            : _vm._e(),
                          item.billTypeName
                            ? _c("div", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("label.billType")) +
                                    " ：" +
                                    _vm._s(item.billTypeName) +
                                    "\n              "
                                ),
                              ])
                            : _vm._e(),
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.$t("label.estimatedDeliveryDate")) +
                                "：" +
                                _vm._s(item.creationDate)
                            ),
                          ]),
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.$t("label.planTheNumberOf")) +
                                "：" +
                                _vm._s(item.qty)
                            ),
                          ]),
                        ]),
                      ]),
                      _vm.activeName === "pendingTasks"
                        ? _c(
                            "div",
                            { staticClass: "right" },
                            [_c("hips-icon", { attrs: { name: "arrow" } })],
                            1
                          )
                        : _vm._e(),
                    ]),
                    _c("div", { staticClass: "fotter-box g-flex-between" }, [
                      _c("div", [
                        item.leftFooter
                          ? _c("div", { staticClass: "type-box" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(item.leftFooter) +
                                  "\n            "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _c("div", [_vm._v(_vm._s(item.rightFooter))]),
                    ]),
                  ]
                )
              }),
              0
            )
          : _c("div", { staticClass: "content-empty" }, [
              _c("span", [
                _vm._v(
                  " — " + _vm._s(_vm.$t("message.findNullNormal")) + " — "
                ),
              ]),
            ]),
      ]),
      _c(
        "hips-popup",
        {
          attrs: { position: "bottom" },
          model: {
            value: _vm.showPopupStartDate,
            callback: function ($$v) {
              _vm.showPopupStartDate = $$v
            },
            expression: "showPopupStartDate",
          },
        },
        [
          _c("hips-datetime-picker", {
            attrs: {
              format: "yyyy-MM-dd",
              title: "选择开始时间",
              "default-selected-value": _vm.startDate,
            },
            on: { confirm: _vm.handleConfirm1, cancel: _vm.hidePop },
          }),
        ],
        1
      ),
      _c(
        "hips-popup",
        {
          attrs: { position: "bottom" },
          model: {
            value: _vm.showPopupEndDate,
            callback: function ($$v) {
              _vm.showPopupEndDate = $$v
            },
            expression: "showPopupEndDate",
          },
        },
        [
          _c("hips-datetime-picker", {
            attrs: {
              format: "yyyy-MM-dd",
              title: "选择结束时间",
              "default-selected-value": _vm.endDate,
            },
            on: { confirm: _vm.handleConfirm2, cancel: _vm.hidePop },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }