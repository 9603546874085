var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: { "is-index": false, title: _vm.$t("title.taskQuery") },
          on: { beforeBack: _vm.beforeBack },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["whareaCode"]
                ? _vm.hideControl["whareaCode"]
                : true,
              expression:
                "customData['whareaCode'] ? hideControl['whareaCode'] : true",
            },
          ],
          ref: "ref10",
          attrs: {
            label: _vm.$t("label.pickingReservoir"),
            title: _vm.$t("label.pickingReservoir"),
            params: "whareaCode",
            display: "whareaCode",
            url: _vm.winvBaseUrl + "app-wave-picking/wharea-lov",
            "url-params": { pageId: _vm.menuId },
            "pre-parse-url": _vm.urlData["whareaCode"],
            custom: _vm.customData["whareaCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(10)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(10, "lov")
            },
            enter: function ($event) {
              return _vm.skipNext(20)
            },
            down: function ($event) {
              return _vm.skipNext(20)
            },
            choose: function ($event) {
              return _vm.handleChooseWhareaCode($event)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.inputData.whareaCode,
            callback: function ($$v) {
              _vm.$set(_vm.inputData, "whareaCode", $$v)
            },
            expression: "inputData.whareaCode",
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["waveNum"]
                ? _vm.hideControl["waveNum"]
                : true,
              expression:
                "customData['waveNum'] ? hideControl['waveNum'] : true",
            },
          ],
          ref: "ref20",
          attrs: {
            label: _vm.$t("label.waveNum"),
            title: _vm.$t("label.waveNum"),
            params: "waveBillCode",
            display: "waveBillCode",
            "url-params": { whareaId: _vm.whareaId },
            url: _vm.winvBaseUrl + "app-wave-picking/wave-order-lov",
            "pre-parse-url": _vm.urlData["waveNum"],
            custom: _vm.customData["waveNum"],
            "source-parse-field": _vm.sourceParseField,
            "is-control": "",
            required: "",
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(20)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(20, "lov")
            },
            choose: function ($event) {
              return _vm.handleChooseWaveNum($event)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.inputData.waveNum,
            callback: function ($$v) {
              _vm.$set(_vm.inputData, "waveNum", $$v)
            },
            expression: "inputData.waveNum",
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showPickingCart,
              expression: "showPickingCart",
            },
          ],
          ref: "ref30",
          attrs: {
            label: _vm.$t("label.wavePickingCart"),
            title: _vm.$t("title.wavePickingCart"),
            params: "pickingCartCode",
            display: "pickingCartCode",
            "url-params": { warehouseId: _vm.warehouseId },
            url: _vm.winvBaseUrl + "picking-carts",
            "pre-parse-url": _vm.urlData["pickingCartCode"],
            custom: _vm.customData["pickingCartCode"],
            "source-parse-field": _vm.sourceParseField,
            "is-control": "",
            required: "",
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(30)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(30, "lov")
            },
            choose: function ($event) {
              return _vm.handleChoosePickingCart($event)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.inputData.pickingCartCode,
            callback: function ($$v) {
              _vm.$set(_vm.inputData, "pickingCartCode", $$v)
            },
            expression: "inputData.pickingCartCode",
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["sku"] ? _vm.hideControl["sku"] : true,
              expression: "customData['sku'] ? hideControl['sku'] : true",
            },
          ],
          ref: "ref40",
          attrs: {
            label: _vm.$t("label.sku"),
            title: _vm.$t("label.sku"),
            params: "sku",
            display: "sku",
            url: _vm.winvBaseUrl + "app-wave-picking/sku-lov",
            "url-params": {
              sku: _vm.inputData.sku,
              waveBillId: _vm.waveBillId,
              whareaId: _vm.whareaId,
              orgId: _vm.orgId,
              ownerId: _vm.ownerId,
              waveLineType: _vm.waveLineType,
            },
            "pre-parse-url": _vm.urlData["sku"],
            custom: _vm.customData["sku"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(40)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(40, "lov")
            },
            choose: function ($event) {
              return _vm.handleChooseSku($event)
            },
            enter: function ($event) {
              return _vm.skipNext("submitRef")
            },
            down: function ($event) {
              return _vm.skipNext("submitRef")
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.inputData.sku,
            callback: function ($$v) {
              _vm.$set(_vm.inputData, "sku", $$v)
            },
            expression: "inputData.sku",
          },
        }),
        _c("ips-value-cell", {
          attrs: {
            title: _vm.$t("label.totalNumberOfOrders"),
            value: _vm.resInfo.orderCount || 0,
          },
        }),
        _c("ips-value-cell", {
          attrs: {
            title: _vm.$t("label.numberOfMaterialTypes"),
            value: _vm.resInfo.skuCount || 0,
          },
        }),
        _c("ips-value-cell", {
          attrs: {
            title: _vm.$t("label.waveOrderTotalNumberOfMaterials"),
            value: _vm.resInfo.planQty || 0,
          },
        }),
        _c("ips-value-cell", {
          attrs: {
            title: _vm.$t("label.totalNumberOfMaterialsCollected"),
            value: _vm.resInfo.pickedQty || 0,
          },
        }),
        _c("ips-value-cell", {
          attrs: {
            title: _vm.$t("label.unpickedQuantity"),
            value: _vm.resInfo.unPickedQty || 0,
          },
        }),
        _c("ips-value-cell", {
          attrs: {
            title: _vm.$t("label.remark"),
            value: _vm.resInfo.remark || "无",
          },
        }),
        _c(
          "div",
          { staticClass: "ips-footer" },
          [
            _c(
              "hips-button",
              {
                ref: "submitRef",
                attrs: { size: "large" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleCommonSubmit.apply(null, arguments)
                  },
                },
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("button.confirm")) + "\n      "
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }