<template>
  <div class="ips-date-picker">
    <ips-field
      :label="label"
      @focus="openPicker"
      @click="handleClick"
      :readonly="true"
      v-model="dateValue"
    >
      <span 
        slot="append" 
        v-show="dateValue" 
        @click="clear"
      >
        <svg-icon icon-class="clear" />
      </span>
    </ips-field>
    <hips-popup 
      v-model="show" 
      position="bottom"
    >
      <hips-datetime-picker
        :format="format"
        title="选择时间"
        @confirm="confirm"
        @cancel="cancel"
      />
    </hips-popup>
  </div>
</template>

<script>
import { DatetimePicker } from "@hips/vue-ui";
export default {
  name: "DatePicker",
  components: {
    [DatetimePicker.name]: DatetimePicker
  },
  data() {
    return {
      show: false,
      dateValue: '',
    };
  },
  props: {
    format: {
      type: String,
      default: 'yyyy-MM-dd',
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
  },
  watch: {
    dateValue: function(newV) {
      this.$emit('input',newV);
    },
    value: function(newV) {
      this.dateValue = newV;
    }
  },
  methods: {
    openPicker() {
      this.show = true;
    },
    confirm(value) {
      this.dateValue = value;
      this.show = false;
    },
    cancel() {
      this.show = false;
    },
    clear() {
      this.dateValue = "";
    },
    handleClick() {
      this.$emit('click');
    }
  }
};
</script>

<style lang="stylus" scoped>
 @import '~@/style/var.styl'
</style>
