import hint from './hint'
let bridge = {
  getBaseInfo,
  scan,
  closeWebView,
  fullScreen,
  photoAlbum,
  takePhoto,
  imgToBase64,
  goApplication,
  logout,
}

//获取基本信息
function getBaseInfo() {
  return new Promise((resolve, reject) => {
    console.log('获取基本信息')
    //调用成功返回
    window.onSuccess = function (message) {
      const object = JSON.parse(message);
      console.log('获取基本信息', object);
      resolve(object);
      //object.appVersion 应用版本号
      //object.baseUrl 基地址
      //object.token 用户token
      //object.userId 用户id
      //object.loginAccount 登录使用的账号
      //object.employeeId 员工id
      //object.employeeName 员工姓名
      //object.employeeNum 员工工号
      //object.tenantId 租户id
      //object.tenantName 租户名称
      //object.pushExtra 当从消息中心进入子应用时获取接口的额外参数
      //object.Accept-hips-Language 当前app的语言环境 字段包含en为英语，包含zh为汉语
      //object.argsExtra 子应用间跳转的额外参数
      //object.bannerSplashExtra banner或splash跳转的额外参数
    }

    //调用失败返回
    window.onError = function (message) {
      console.log('获取基本信息失败', message);
      reject(message);
    }
    const postParam = {
      className: "BaseBridge",
      function: "getBaseInfo",
      successCallBack: "onSuccess",
      failCallBack: "onError"
    }
    // eslint-disable-next-line no-undef
    HandBridge.postMessage(JSON.stringify(postParam));
  });
}

//扫码调用
function scan() {
  return new Promise((resolve, reject) => {
    window.onScanSuccess = function (message) {
      console.log('扫码成功,', message);
      resolve(message.result)
    }
    window.onScanError = function (message) {
      console.log('扫码失败：', message);
      reject(message);
    }

    const postParam = {
      className: "ScanBridge",
      function: "scanner",
      successCallBack: "onScanSuccess",
      failCallBack: "onScanError"
    }
    if (process.env.VUE_APP_TARGET === 'all') {
      resolve('4655');
    } else {
      console.log('开始调用扫码');
      // eslint-disable-next-line no-undef
      HandBridge.postMessage(JSON.stringify(postParam));
    }
  })
}
//关闭子应用
function closeWebView() {
  //成功回调
  window.onCloseSuccess = function (message) {
    console.log(message)
  }
  //失败回调
  window.onCloseError = function (message) {
    console.log(message)
  }
  const postParam = {
    className: "BaseBridge",
    function: "closeWebView",
    successCallBack: "onCloseSuccess",
    failCallBack: "onCloseError"
  }
  // eslint-disable-next-line no-undef
  HandBridge.postMessage(JSON.stringify(postParam));
}

//调用方式
function fullScreen(){
  //成功回调
  window.onSuccess = function(message){
    alert(message);
  }
  //失败回调
  window.onError = function(message){
    alert(message);
  }
  let options = {
    "fullScreen": true,//true：设置全屏显示，false：设置区域显示
    "coverNavBarFlag": 0//仅当fullScreen为true时有效 1:隐藏原生导航栏， 0 显示原生导航栏
  }
  let postParam = {
    className:"WebViewBridge",
    params:options,
    function:"fullScreen",
    successCallBack:"onSuccess",
    failCallBack:"onError"
  }
  // eslint-disable-next-line no-undef
  HandBridge.postMessage(JSON.stringify(postParam));
}

//图片压缩
function imgToBase64(imgPath) {
  console.log('开始转64')
  return new Promise((resolve, reject) => {
    //成功回调
    window.onBase64Success = function (message) {
      console.log('转64成功')
      let result = JSON.parse(message);
      console.log('data:image/png;base64,'+result.data)
      resolve('data:image/png;base64,'+result.data);
    }

    //失败回调
    window.onBase64Error = function (message) {
      console.log('转64失败')
      console.log(message)
      
      reject(message);
    }

    let options = {
      "filePath": imgPath,
      "quality": 50, //取值范围0-100  100时图片不压缩
    }
    const postParam = {
      className: "FileBridge",
      function: "image2Base64",
      params: options,
      successCallBack: "onBase64Success",
      failCallBack: "onBase64Error"
    }
    // eslint-disable-next-line no-undef
    HandBridge.postMessage(JSON.stringify(postParam));
  });
}
//相册
function photoAlbum() {
  return new Promise((resolve, reject) => {
    //成功回调
    window.onAlbumSuccess = async function (res) {
      //message：图片地址
      hint.showLoading();
      let result = JSON.parse(res);
      let baseImg = [];
      if (!(result instanceof Array)) {
        result = [result];
      }
      console.log('图片地址：',result);
      for (let img of result) {
        let base64 = await imgToBase64(img);
        baseImg.push(base64);
      }
      console.log('baseImg', baseImg)
      hint.hideLoading();
      resolve(baseImg);
    }
    //失败回调
    window.onAlbumError = function (message) {
      reject(message);
    }
    //调用方式
    const options = {
      maxImages: 5 //仅对Android有效，如果不传该参数调用的是图片单选插件返回的为图片地址的字符串，
      // 如果参数值为大于等于1的整数则调用图片多选插件，返回数组
    }
    const postParam = {
      className: "CameraBridge",
      function: "getPicture",
      params: options,
      successCallBack: "onAlbumSuccess",
      failCallBack: "onAlbumError"
    }
    // eslint-disable-next-line no-undef
    HandBridge.postMessage(JSON.stringify(postParam));
  });
}
//拍照
function takePhoto() {
  return new Promise((resolve, reject) => {
    //成功回调
    window.onCameraSuccess = function (message) {
      hint.showLoading();
      //message：图片地址
      console.log(message);
      imgToBase64(message).then(function (data) {
        hint.hideLoading();
          console.log('拍照成功')
        console.log(data)
        resolve(data);
      }, function (err) {
        console.log(err)
        reject(err);
      });
    }
    //失败回调
    window.onCameraError = function (message) {
      reject(message);
    }
    const options = {
      "allowEdit": false, //拍完照后是否允许编辑
    };
    const postParam = {
      className: "CameraBridge",
      function: "takePicture",
      params: options,
      successCallBack: "onCameraSuccess",
      failCallBack: "onCameraError"
    }
    // eslint-disable-next-line no-undef
    HandBridge.postMessage(JSON.stringify(postParam));
  });
}

//跳转到其他子应用
function goApplication(appId, args) {
  return new Promise((resolve, reject) => {
    //调用成功返回
    window.onSuccess = function () {
    }

    //调用失败返回
    window.onError = function (message) {
      reject(message);
    }
    const options = {
      menuId: appId, 
      args,//用户自定义参数json格式,该值在被跳转到的子应用BaseBridge.getBaseInfo中获得，字段为argsExtra
    }
    const postParam = {
      className:"BaseBridge",
      function:"startApplication",
      params:options,
      successCallBack:"onSuccess",
      failCallBack:"onError"
    }
    // eslint-disable-next-line no-undef
    HandBridge.postMessage(JSON.stringify(postParam));
  });
}

function logout() {
  let postParam = {
    className: "LoginBridge",
    function: "logout",
    successCallBack: "onSuccess",
    failCallBack: "onError",
  }
  // eslint-disable-next-line no-undef
  HandBridge.postMessage(JSON.stringify(postParam));
}


export default bridge