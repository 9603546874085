var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-a" },
    [
      _c("ips-header", { attrs: { title: "测试b", "is-index": false } }),
      _c("input", {
        directives: [
          { name: "model", rawName: "v-model", value: _vm.a, expression: "a" },
        ],
        attrs: { type: "text", placeholder: "输入框1" },
        domProps: { value: _vm.a },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.a = $event.target.value
          },
        },
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.input.b,
            expression: "input.b",
          },
        ],
        attrs: { type: "text", placeholder: "输入框2" },
        domProps: { value: _vm.input.b },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.input, "b", $event.target.value)
          },
        },
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.input.c,
            expression: "input.c",
          },
        ],
        attrs: { type: "text", placeholder: "输入框3" },
        domProps: { value: _vm.input.c },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.input, "c", $event.target.value)
          },
        },
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.input.d,
            expression: "input.d",
          },
        ],
        attrs: { type: "text", placeholder: "输入框4" },
        domProps: { value: _vm.input.d },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.input, "d", $event.target.value)
          },
        },
      }),
      _c("button", { on: { click: _vm.go } }, [_vm._v("\n    前往c页面\n  ")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }