const SPEAK_CONFIG = {
  // 声音的音量，区间范围是0到1，默认是1。
  volume: '1',
  // 设置播放语速，范围：0.1 - 10之间    正常为1倍播放
  rate: 1,
  // 表示说话的音高，数值，范围从0（最小）到2（最大）。默认值为1。
  pitch: '1',
  // 使用的语言，字符串， 例如："zh-cn"
  lang: 'zh-cn',
};

const ENABLE_SPEECH_SYNTHESIS = 0; // 0 禁用  1启用

const globalConfig = () => {
  return {
    baseUrl: '',
    SPEAK_CONFIG,
    ENABLE_SPEECH_SYNTHESIS,
  };
};

module.exports = globalConfig();
