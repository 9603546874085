var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home" },
    [
      _c("keep-alive", [_c("router-view")], 1),
      _c(
        "div",
        { staticClass: "tab-bar-box g-flex-between" },
        _vm._l(_vm.tabs, function (item, index) {
          return _c(
            "div",
            {
              key: item.name,
              staticClass: "tab-item",
              on: {
                click: function ($event) {
                  return _vm.handleTabClick(item, index)
                },
              },
            },
            [
              _c("div", [
                _c("img", {
                  staticClass: "img-box",
                  attrs: {
                    src:
                      index === _vm.active ? item.activeIcon : item.normalIcon,
                    alt: "",
                    srcset: "",
                  },
                }),
              ]),
              _c(
                "div",
                {
                  staticClass: "tab-text",
                  class: index === _vm.active ? "active" : "",
                },
                [_vm._v("\n        " + _vm._s(item.name) + "\n      ")]
              ),
            ]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }