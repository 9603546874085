var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: { title: _vm.$t("title.acceptCidDetail"), "is-index": false },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _c("ips-page-head", {
          attrs: {
            "title-info": { cidCode: _vm.cidCode },
            "content-info": { amount: _vm.receiveQty },
          },
        }),
        _c(
          "hips-scroll",
          _vm._l(_vm.lineData, function (item, index) {
            return _c(
              "ips-page-card",
              {
                key: index,
                attrs: {
                  "react-field": ["sku", "amount", "uomName", "batchCode"],
                  "link-field": ["batchCode"],
                  "link-field-event": function () {
                    return _vm.goToCreateBatch(index, item)
                  },
                  item: Object.assign({}, item, {
                    unReceiveQty: item.planQty - item.receiveQty,
                    amount: item.packQty,
                  }),
                },
              },
              [
                _c("div", { staticClass: "content-line" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("label.orderNumber")) + ":"),
                  ]),
                  _c("span", { staticClass: "card_content_span_value" }, [
                    _vm._v(_vm._s(item.billCode)),
                  ]),
                ]),
                _c("ips-message-tip", { attrs: { message: item.goodsName } }),
              ],
              1
            )
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }