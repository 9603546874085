import wavePicking from '../../wave-picking/views/wave-picking';
import PickingBoundSort from '../views/picking-bound-sort'
import digitalPickingWrapper from '../views/digital-picking'

let route = [
  {
    path: '/digital-picking',
    name: 'DigitalPicking',
    component: digitalPickingWrapper(wavePicking, {'BIND_PICKING_CART': '1'}),
  },
  {
    path: '/picking-bound-sort',
    name: 'PickingBoundSort',
    component: PickingBoundSort,
  },
];

export default route;
