<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.inventoryCheck')" />
    </div>
    <div class="ips-container">
      <!--物料-->
      <ips-lov :label="$t('label.sku')"
               v-model="inputData.sku"
               :title="$t('label.sku')"
               params="sku"
               :url="`${wmdmBaseUrl}goods`"
               ref="ref0"
               @click.stop="handleCommonClick(0)"
               @clickIcon="handleCommonClick(0, 'lov')"
               @enter="skipNext(10)"
               @choose="handleChoose"
               :custom="customData['sku']"
               :source-parse-field="sourceParseField"
               v-show="customData['sku'] ? hideControl['sku'] : true"
               @setSourceField="saveSourceField($event)"
               @valuedByFoucs="valuedByFoucs($event)"
      />
      <!--批次-->
      <ips-lov :label="$t('label.batch')"
               v-model="inputData.batchCode"
               :title="$t('label.batch')"
               params="batchCode"
               :url="`${wmdmBaseUrl}batchs`"
               :url-params="{goodsId: inputData.goodsId}"
               :pre-parse-url="urlData['batchCode']"
               ref="ref10"
               @click.stop="handleCommonClick(10)"
               @clickIcon="handleCommonClick(10, 'lov')"
               @enter="skipNext(20)"
               :custom="customData['batchCode']"
               :source-parse-field="sourceParseField"
               v-show="customData['batchCode'] ? hideControl['batchCode'] : true"
               @setSourceField="saveSourceField($event)"
               @valuedByFoucs="valuedByFoucs($event)"
      />
      <!--库区-->
      <ips-lov :label="$t('label.whareaCode')"
               v-model="inputData.whareaCode"
               :title="$t('label.whareaCode')"
               params="whareaCode"
               :url="`${wmdmBaseUrl}whareas`"
               :pre-parse-url="urlData['whareaCode']"
               ref="ref20"
               @click.stop="handleCommonClick(20)"
               @clickIcon="handleCommonClick(20, 'lov')"
               @enter="skipNext(30)"
               :custom="customData['whareaCode']"
               :source-parse-field="sourceParseField"
               v-show="customData['whareaCode'] ? hideControl['whareaCode'] : true"
               @setSourceField="saveSourceField($event)"
               @valuedByFoucs="valuedByFoucs($event)"
      />
      <!--库位-->
      <ips-lov :label="$t('label.countLocation')"
               v-model="inputData.locationCode"
               :title="$t('label.countLocation')"
               params="locationCode"
               :url="`${wmdmBaseUrl}wms-locations`"
               :pre-parse-url="urlData['locationCode']"
               ref="ref30"
               @click.stop="handleCommonClick(30)"
               @clickIcon="handleCommonClick(30, 'lov')"
               @enter="skipNext('submitRef')"
               :custom="customData['locationCode']"
               :source-parse-field="sourceParseField"
               v-show="customData['locationCode'] ? hideControl['locationCode'] : true"
               @setSourceField="saveSourceField($event)"
               @valuedByFoucs="valuedByFoucs($event)"
      />
    </div>
    <div class="ips-footer">
      <hips-button
        ref="submitRef"
        size="large"
        @click="handleCommonSubmit('location')"
      >
        {{ $t('button.locationInventory') }}
      </hips-button>
      <hips-button
        size="large"
        @click="handleCommonSubmit('batch')"
      >
        {{ $t('button.batchInventory') }}
      </hips-button>
    </div>
  </hips-view>
</template>

<script>
  import { storage } from '@/utils';
  export default {
  name: "StockInquiry",
  data() {
    return {
      inputData: {
        sku: '',
        goodsId: '',
        batchCode: '',
        whareaCode: '',
        locationCode: '',
      },
      urlData:{},
      customData: {}, // 个性化配置信息
      hideControl: {}, // 非必输字段的显隐控制
      sourceParseField: {}, // 界面中所有的来源解析字段
    };
  },
  watch: {
    'inputData.sku': function(val) {
      if (!val) {
        this.inputData.goodsId = undefined
      }
    }
  },
  created() {
    const { hideControl, customObj } = this.handleCustomData(storage.get('customData'));
    this.customData = customObj;
    this.hideControl = hideControl;

  },
  async mounted() {
    this.inputFieldList = [
      { type: 'lov', required: false, ref: 0, bind: 'inputData.sku', field: 'sku' },
      { type: 'lov', required: false, ref: 10, bind: 'inputData.batchCode', field: 'batchCode' },
      { type: 'lov', required: false, ref: 20, bind: 'inputData.whareaCode', field: 'whareaCode' },
      { type: 'lov', required: false, ref: 30, bind: 'inputData.locationCode', field: 'locationCode' },
    ];
    this.urlData = this.handleData('STOCK_QUERY');
    this.skipNext(0);
  },
  methods: {
    handleChoose(item) {
      this.inputData.goodsId = item.goodsId;
    },
    // 保存来源解析字段
    async saveSourceField(fieldObj) {
      this.saveSourceParseField(this, fieldObj);
    },
    // 字段聚焦时赋值操作
    async valuedByFoucs(options={}) {
      const { custom ={} , currentValue } = options;
      await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
    },
    /**
     * 提交查询
     * @param {String} type - 判断是库位还是批次库存
     */
    async handleButtonSubmit(type) {
      try {
        const { sku, batchCode, locationCode, whareaCode, goodsId } = this.inputData;
        if (sku || batchCode || locationCode || whareaCode) {
          let url = type === 'location' ? `${this.winvBaseUrl}stocks/query-location-stock` : `${this.winvBaseUrl}stocks/query-batch-stock`;
          const param = {
            params: {
              goodsId,
              sku,
              batchCode,
              locationCode,
              whareaCode,
            }
          };
          this.$hint.showLoading();
          this.handleSubmitStatus();
          const data = await this.$http.get(url,param);
          this.handleSubmitStatus();
          const par = {
            name: 'StockDetail',
            params: {
              data,
              type,
              customData: this.customData,
              hideControl: this.hideControl,
            }
          };
          this.$router.pushPage(par);
          // 清除当前界面的二维码解析字段
          await this.clearSourceFields(this, true);
        } else {
          this.$hint.showFailed(this.$i18n.t('message.enterAtLeastOne'));
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.$hint.hideLoading();
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
</style>
