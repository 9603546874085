/* eslint-disable vue/no-v-html */
<template>
  <hips-nav-bar
    slot="header"
    :back-button="{
      showLeftArrow: true,
      backText: $t('button.back'),
    }"
    :title="title"
    @nav-bar-back-click="back()"
  >
    <div slot="right" v-if="trigger" @click="onGoDetail">
      {{ trigger }}
    </div>
    <div slot="right" v-if="routerKey === 'sorting-seeding'">
      <hips-switch :value="checked" @change="onInput" width="70" checked-text="正扫" unchecked-text="反扫" />
    </div>
    <!-- 以下写法只适合'关联播种位’ -->
    <div slot="center" class="tabs" v-if="renderData && pageType === ''">
      <p style="line-height: 25px; font-weight: bold">
        {{ renderData.title || renderData.billCode }}
      </p>
      <p style="line-height: 25px; font-weight: bold">
        {{ renderData.taskCode || renderData.billCode }}
      </p>
    </div>
    <!-- 以下写法只适合‘任务执行’ -->
    <div style="text-align: left; float: left" slot="center" class="tabs" v-if="renderData && pageType === 'taskExecution'">
      <p style="line-height: 25px; font-weight: bold">
        {{ renderData.title || renderData.billCode }}
      </p>
      <div style="line-height: 25px; font-weight: bold; font-size: 12px; float: left">
        {{ renderData.taskCode || renderData.billCode }}
        <p style="float: right; margin-left: 10px">
          <!-- <span>{{ renderData.startTaskNumber }}</span> / <span>{{ renderData.taskNumbers }}</span> -->
          <span>{{ renderData.taskNumbers }}</span>
        </p>
      </div>
    </div>
  </hips-nav-bar>
</template>

<script>
import { Switch } from '@hips/vue-ui';
export default {
  name: 'NavBar',
  data() {
    return {
      checked: true,
    };
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    trigger: {
      type: String,
      default: '',
    },
    isIndex: {
      type: Boolean,
      default: true,
    },
    onGoDetail: {
      type: Function,
      default: null,
    },
    handleTrigger: {
      type: Function,
      default: null,
    },
    renderData: {
      type: Object,
      default: () => {},
    },
    pageType: {
      type: String,
      default: '',
    },
    routerKey: {
      type: String,
      default: '',
    },
  },
  components: {
    [Switch.name]: Switch,
  },
  methods: {
    onInput(checked) {
      this.checked = checked;
      this.$emit('onChecked', checked);
    },
    back() {
      // if (!this.isIndex) {
      //   this.$router.backPage();
      // }else {
      if (this.$listeners.beforeBack) {
        this.$listeners
          .beforeBack()
          .then((res) => {
            if (this.isIndex) {
              this.$common.mainPageGoBack();
            } else {
              if (res && res.isSuccess) {
                // console.log('阻止')
              } else if (res && res.isToCidQuery) {
                // console.log('阻止')
              } else {
                // console.log('失败')
              }
            }
          })
          .catch(() => {
            console.log('error');
          });
      } else if (this.isIndex) {
        this.$common.mainPageGoBack();
      } else {
        this.$router.backPage();
      }
      // }
    },
  },
};
</script>

<style lang="stylus">
/* .hips-nav-bar__buttons--right {
  right: 5% !important;
  font-size: 14px;
  color: var(--primaryColor);
} */
</style>
