var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: { title: _vm.$t("title.shelvesDetail"), "is-index": false },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _c("div", { staticStyle: { padding: "0 12px", "font-size": "14px" } }, [
          _c("span", { staticStyle: { color: "#595959" } }, [
            _vm._v(_vm._s(_vm.$t("label.receiveCid")) + " :"),
          ]),
          _c(
            "span",
            { staticStyle: { "font-weight": "bold", color: "#262626" } },
            [_vm._v("\n        " + _vm._s(_vm.receiveCidCode) + "\n      ")]
          ),
        ]),
        _c(
          "div",
          { staticClass: "shelves-detail-multiple-card" },
          [
            _c(
              "div",
              { staticStyle: { "font-size": "14px", "font-weight": "bold" } },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("label.skuCode")) + "\n      "
                ),
              ]
            ),
            _c("hr", {
              staticStyle: {
                margin: "10px 0px",
                border: "0.5px solid #d8d8d8",
              },
            }),
            _vm._l(_vm.detailData, function (item, index) {
              return _c(
                "span",
                {
                  key: index,
                  staticClass: "shelves-detail-multiple-card-sku-style",
                },
                [_vm._v("\n        " + _vm._s(item.sku) + "\n      ")]
              )
            }),
          ],
          2
        ),
        _vm.serialType !== "UNCTRL" && _vm.serialType !== "OUT_CTRL"
          ? _c(
              "hips-row",
              { staticStyle: { "margin-top": "16px" } },
              [
                _c(
                  "hips-col",
                  { attrs: { span: "14" } },
                  [
                    _c("ips-input-number", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.customData["receiveQty"]
                            ? _vm.hideControl["receiveQty"]
                            : true,
                          expression:
                            "customData['receiveQty'] ? hideControl['receiveQty'] : true",
                        },
                      ],
                      ref: "ref10",
                      attrs: {
                        disabled: true,
                        label: _vm.$t("label.inboundNum"),
                        required: "",
                        custom: _vm.customData["receiveQty"],
                        "source-parse-field": _vm.sourceParseField,
                        "enable-serial": _vm.serialType,
                        min: null,
                        precision: 0,
                      },
                      on: {
                        enter: function ($event) {
                          return _vm.skipNext(20)
                        },
                        setSourceField: function ($event) {
                          return _vm.saveSourceField($event)
                        },
                        valuedByFoucs: function ($event) {
                          return _vm.valuedByFoucs($event)
                        },
                      },
                      model: {
                        value: _vm.receiveQty,
                        callback: function ($$v) {
                          _vm.receiveQty = $$v
                        },
                        expression: "receiveQty",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "hips-col",
                  { attrs: { span: "10" } },
                  [
                    _c(
                      "hips-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.customData["serialNumber"]
                              ? _vm.hideControl["serialNumber"]
                              : true,
                            expression:
                              "customData['serialNumber'] ? hideControl['serialNumber'] : true",
                          },
                        ],
                        ref: "refSerail",
                        staticClass: "serial-button-detail",
                        attrs: { size: "large" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.goToSerialDetail.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("button.serialNumber")) +
                            "(" +
                            _vm._s(_vm.serialQtyNum) +
                            ")\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.serialType === "UNCTRL" || _vm.serialType === "OUT_CTRL"
          ? _c(
              "div",
              { staticStyle: { "margin-top": "16px" } },
              [
                _c("ips-input-number", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.customData["receiveQty"]
                        ? _vm.hideControl["receiveQty"]
                        : true,
                      expression:
                        "customData['receiveQty'] ? hideControl['receiveQty'] : true",
                    },
                  ],
                  ref: "ref50",
                  attrs: {
                    disabled: true,
                    label: _vm.$t("label.inboundNum"),
                    required: "",
                    custom: _vm.customData["receiveQty"],
                    "source-parse-field": _vm.sourceParseField,
                    min: null,
                    "enable-serial": _vm.serialType,
                    precision: 0,
                  },
                  on: {
                    enter: _vm.checkImpQtyUnSerial,
                    down: _vm.checkImpQtyUnSerial,
                    blur: _vm.checkImpQtyUnSerial,
                    setSourceField: function ($event) {
                      return _vm.saveSourceField($event)
                    },
                    valuedByFoucs: function ($event) {
                      return _vm.valuedByFoucs($event)
                    },
                  },
                  model: {
                    value: _vm.receiveQty,
                    callback: function ($$v) {
                      _vm.receiveQty = $$v
                    },
                    expression: "receiveQty",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticStyle: { "margin-top": "16px" } },
          [
            _c("ips-lov", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.customData["locationCode"]
                    ? _vm.hideControl["locationCode"]
                    : true,
                  expression:
                    "customData['locationCode'] ? hideControl['locationCode'] : true",
                },
              ],
              ref: "ref20",
              attrs: {
                label: _vm.$t("label.storageLocation"),
                required: "",
                title: _vm.$t("label.storageLocation"),
                params: "locationCode",
                url: _vm.wmdmBaseUrl + "wms-locations",
                "url-params": {
                  warehouseId: _vm.warehouseId,
                  whareaId: _vm.whareaId,
                },
                custom: _vm.customData["locationCode"],
                "source-parse-field": _vm.sourceParseField,
              },
              on: {
                enter: _vm.checkToRouter,
                down: _vm.checkToRouter,
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleCommonClick(20)
                },
                clickIcon: function ($event) {
                  return _vm.handleCommonClick(20, "lov")
                },
                choose: function ($event) {
                  return _vm.handleChoose("storageLocation", $event)
                },
                setSourceField: function ($event) {
                  return _vm.saveSourceField($event)
                },
                valuedByFoucs: function ($event) {
                  return _vm.valuedByFoucs($event)
                },
              },
              model: {
                value: _vm.inputData.locationCode,
                callback: function ($$v) {
                  _vm.$set(_vm.inputData, "locationCode", $$v)
                },
                expression: "inputData.locationCode",
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "recommend-loaction" }, [
          _c("span", [_vm._v(_vm._s(_vm.$t("label.recommandLoca")) + " :")]),
          _c("span", [_vm._v(_vm._s(_vm.defaultLocation))]),
        ]),
        _vm.inputData.containerManageFlag === 1
          ? _c(
              "div",
              { staticStyle: { "margin-top": "16px" } },
              [
                _vm.inputData.containerManageFlag === 1
                  ? _c("ips-field", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.customData["inboundCidCode"]
                            ? _vm.hideControl["inboundCidCode"]
                            : true,
                          expression:
                            "customData['inboundCidCode'] ? hideControl['inboundCidCode'] : true",
                        },
                      ],
                      ref: "ref30",
                      attrs: {
                        disabled: _vm.inputData.containerManageFlag === 1,
                        label: _vm.$t("label.inboundCid"),
                        custom: _vm.customData["inboundCidCode"],
                        "source-parse-field": _vm.sourceParseField,
                      },
                      on: {
                        enter: function ($event) {
                          return _vm.skipNext("submitRef")
                        },
                        down: function ($event) {
                          return _vm.skipNext("submitRef")
                        },
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.handleCommonClick(_vm.submitRef)
                        },
                        blur: _vm.checkInboundCidBlur,
                        setSourceField: function ($event) {
                          return _vm.saveSourceField($event)
                        },
                        valuedByFoucs: function ($event) {
                          return _vm.valuedByFoucs($event)
                        },
                      },
                      model: {
                        value: _vm.inputData.inboundCidCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.inputData, "inboundCidCode", $$v)
                        },
                        expression: "inputData.inboundCidCode",
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-footer" },
      [
        _c(
          "hips-button",
          {
            ref: "submitRef",
            attrs: { size: "large" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.submitShelves.apply(null, arguments)
              },
            },
          },
          [
            _c("svg-icon", {
              attrs: { slot: "icon", "icon-class": "success" },
              slot: "icon",
            }),
            _vm._v("\n      " + _vm._s(_vm.$t("button.submit")) + "\n    "),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }