var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: { title: _vm.$t("title.receiveDetail"), "is-index": false },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container" },
      _vm._l(_vm.detailData, function (item, index) {
        return _c(
          "div",
          { key: index, staticClass: "receive-detail-card" },
          [
            _c("hips-row", [
              _c("div", { staticClass: "receive-detail-card-sku" }, [
                _vm._v("\n          " + _vm._s(item.sku) + "\n        "),
              ]),
            ]),
            _c("hips-row", [
              _c(
                "div",
                { staticClass: "receive-detail-card-second" },
                [
                  _c("hips-col", { attrs: { span: "12" } }, [
                    _c("span", { staticClass: "font-color" }, [
                      _vm._v(_vm._s(_vm.$t("label.shelvesCid"))),
                    ]),
                    _vm._v("：\n            "),
                    _c("span", { staticStyle: { "font-weight": "bold" } }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(item.cidCode) +
                          "\n            "
                      ),
                    ]),
                  ]),
                  _c("hips-col", { attrs: { span: "12" } }, [
                    _c("span", { staticClass: "font-color" }, [
                      _vm._v(_vm._s(_vm.$t("label.shelvesBatch"))),
                    ]),
                    _vm._v("：\n            "),
                    _c("span", { staticStyle: { "font-weight": "bold" } }, [
                      _vm._v(_vm._s(item.batchCode)),
                    ]),
                  ]),
                ],
                1
              ),
            ]),
            _c("hips-row", [
              _c(
                "div",
                { staticClass: "receive-detail-card-second" },
                [
                  _c("hips-col", { attrs: { span: "12" } }, [
                    _c("span", { staticClass: "font-color" }, [
                      _vm._v(_vm._s(_vm.$t("label.shelvesLineNum"))),
                    ]),
                    _vm._v("：\n            "),
                    _c("span", { staticStyle: { "font-weight": "bold" } }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(item.lineNum) +
                          "\n            "
                      ),
                    ]),
                  ]),
                  _c("hips-col", { attrs: { span: "12" } }, [
                    _c("span", { staticClass: "font-color" }, [
                      _vm._v(_vm._s(_vm.$t("label.shelvesUom"))),
                    ]),
                    _vm._v("：\n            "),
                    _c("span", { staticStyle: { "font-weight": "bold" } }, [
                      _vm._v(_vm._s(item.uomName)),
                    ]),
                  ]),
                ],
                1
              ),
            ]),
            _c("hips-row", [
              _c(
                "div",
                { staticClass: "receive-detail-card-second" },
                [
                  _c("hips-col", { attrs: { span: "12" } }, [
                    _c("span", { staticClass: "font-color" }, [
                      _vm._v(_vm._s(_vm.$t("label.shelvesQuantity"))),
                    ]),
                    _vm._v("：\n            "),
                    _c("span", { staticStyle: { "font-weight": "bold" } }, [
                      _vm._v(_vm._s(item.receiveQty)),
                    ]),
                  ]),
                ],
                1
              ),
            ]),
            _c("hips-row", [
              _c("div", { staticClass: "receive-detail-card-three" }, [
                _vm._v("\n          " + _vm._s(item.goodsName) + "\n        "),
              ]),
            ]),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }