import SKUPROPERTYUPDATE from '../view/skuPropertyUpdate.vue'


let route = [
  {
    path: '/sku-property-update',
    name: 'SKUPROPERTYUPDATE',
    component: SKUPROPERTYUPDATE,
  },
]

export default route