import request from '@/request';
import mixin  from '@/mixin/mixin';


// 查询所有序列号
export function querySerialList(data) {
  const url = `${mixin.data().winvBaseUrl}inbound-serial-receipts`;
  return request.get(url, data);
}

// 获取当前行下面的相应数据
export function queryReceiptInfo(data) {
  const url = `${mixin.data().winvBaseUrl}app-inbound-receive-adjust/query-receipt-info`;
  return request.get(url, data);
}

// 收货调整事件
export function receiveReduce(data) {
  let url = `${mixin.data().winvBaseUrl}app-inbound-receive-adjust/receive-reduce`;
  return request.post(url, data);
}

