var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: { title: _vm.$t("title.labelPrint") },
          on: { beforeBack: _vm.clearStorage },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["printerName"]
                ? _vm.hideControl["printerName"]
                : true,
              expression:
                "customData['printerName'] ? hideControl['printerName'] : true",
            },
          ],
          ref: "ref0",
          attrs: {
            label: _vm.$t("label.printer"),
            title: [_vm.$t("title.code"), _vm.$t("label.printer")],
            required: "",
            params: ["value", "meaning"],
            display: "value",
            custom: _vm.customData["printerName"],
            url: _vm.winvBaseUrl + "print-records/printer-lov",
            "url-params": { value: _vm.printer.printerCode },
            "is-page-content": false,
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(0)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(0, "lov")
            },
            enter: function ($event) {
              return _vm.skipNext(1)
            },
            down: function ($event) {
              return _vm.skipNext(1)
            },
            choose: function ($event) {
              return _vm.handleChoose("printer", $event)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.printer.printerName,
            callback: function ($$v) {
              _vm.$set(_vm.printer, "printerName", $$v)
            },
            expression: "printer.printerName",
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["mouldName"]
                ? _vm.hideControl["mouldName"]
                : true,
              expression:
                "customData['mouldName'] ? hideControl['mouldName'] : true",
            },
          ],
          ref: "ref1",
          attrs: {
            label: _vm.$t("label.printMould"),
            title: _vm.$t("label.printMould"),
            required: "",
            params: "name",
            "url-params": { value: "print" },
            custom: _vm.customData["mouldName"],
            url: _vm.winvBaseUrl + "print-records/template-lov",
            "is-page-content": false,
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(1)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(1, "lov")
            },
            choose: function ($event) {
              return _vm.handleChoose("mould", $event)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.printMould.mouldName,
            callback: function ($$v) {
              _vm.$set(_vm.printMould, "mouldName", $$v)
            },
            expression: "printMould.mouldName",
          },
        }),
        _vm._l(_vm.printParam.slice(0, -1), function (item, index) {
          return _c(
            "div",
            { key: index + 1 },
            [
              _vm.printParam.length - index > 1
                ? _c("ips-field", {
                    ref: "ref" + (index + 2),
                    refInFor: true,
                    staticStyle: { "margin-top": "15px" },
                    attrs: {
                      required: _vm.printParam[index].isRequired,
                      label: _vm.param[index].text,
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.handleCommonClick(index + 2)
                      },
                      enter: function ($event) {
                        return _vm.skipNext(index + 3)
                      },
                      down: function ($event) {
                        return _vm.skipNext(index + 3)
                      },
                    },
                    model: {
                      value: _vm.printParam[index].text,
                      callback: function ($$v) {
                        _vm.$set(_vm.printParam[index], "text", $$v)
                      },
                      expression: "printParam[index].text",
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        }),
        _vm._l(_vm.printParam.slice(-1), function (item, index) {
          return _c(
            "div",
            { key: index },
            [
              _c("ips-field", {
                ref: "ref" + (_vm.printParam.length + 1),
                refInFor: true,
                staticStyle: { "margin-top": "15px" },
                attrs: {
                  required:
                    _vm.printParam[_vm.printParam.length - 1].isRequired,
                  label: _vm.param[_vm.printParam.length - 1].text,
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleCommonClick(_vm.printParam.length + 1)
                  },
                  enter: function ($event) {
                    return _vm.skipNext("submitRef")
                  },
                  down: function ($event) {
                    return _vm.skipNext("submitRef")
                  },
                },
                model: {
                  value: _vm.printParam[_vm.printParam.length - 1].text,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.printParam[_vm.printParam.length - 1],
                      "text",
                      $$v
                    )
                  },
                  expression: "printParam[printParam.length - 1].text",
                },
              }),
            ],
            1
          )
        }),
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "ips-footer" },
      [
        _c(
          "hips-button",
          {
            ref: "submitRef",
            attrs: { size: "large" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonSubmit.apply(null, arguments)
              },
            },
          },
          [
            _c("svg-icon", {
              attrs: { slot: "icon", "icon-class": "success" },
              slot: "icon",
            }),
            _vm._v("\n      " + _vm._s(_vm.$t("button.confirm")) + "\n    "),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }