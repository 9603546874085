import OutboundDelivery from '../views/outbound-delivery.vue'
// import BatchDetail from '../views/batch-detail'


let route = [
  {
    path: '/outbound-delivery',
    name: 'OutboundDelivery',
    component: OutboundDelivery,
  },
]

export default route