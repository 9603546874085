var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "hips-view",
    [
      _c("ips-header", {
        attrs: { title: _vm.$t("title.ipsSerial"), "is-index": false },
      }),
      _c(
        "div",
        { staticClass: "ips-container" },
        [
          _c("ips-field", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showInputBox,
                expression: "showInputBox",
              },
            ],
            ref: "ref0",
            attrs: {
              "is-only": "",
              "is-index": false,
              label: _vm.$t("label.serialNumber"),
            },
            on: {
              enter: function ($event) {
                return _vm.skipNext("submitRef")
              },
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(0)
              },
              blur: _vm.checkSerialNum,
            },
            model: {
              value: _vm.serialNum,
              callback: function ($$v) {
                _vm.serialNum = $$v
              },
              expression: "serialNum",
            },
          }),
          _c(
            "div",
            { staticClass: "center_area" },
            [
              _c("div", { staticStyle: { float: "left" } }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("label.totalNum")) +
                    ":" +
                    _vm._s(_vm.totalSelectedNum) +
                    "\n      "
                ),
              ]),
              _c("hips-switch", {
                on: { change: _vm.selectAll },
                model: {
                  value: _vm.isSelectedAll,
                  callback: function ($$v) {
                    _vm.isSelectedAll = $$v
                  },
                  expression: "isSelectedAll",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "ips-table" }, [
            _c(
              "table",
              [
                _c(
                  "tr",
                  { staticStyle: { "background-color": "#eee" } },
                  _vm._l(_vm.tableTitle, function (item, index) {
                    return _c(
                      "th",
                      {
                        key: index,
                        staticClass: "ips-table-thead",
                        attrs: { scope: "col" },
                      },
                      [
                        _vm._v(
                          "\n            " + _vm._s(item.name) + "\n          "
                        ),
                      ]
                    )
                  }),
                  0
                ),
                _vm._l(_vm.tableData, function (item, index) {
                  return _c(
                    "tr",
                    {
                      key: index,
                      ref: "tr" + index,
                      refInFor: true,
                      class: [item.selectedFlag ? "back-green" : ""],
                      on: {
                        click: function ($event) {
                          return _vm.handleSelect(item, index)
                        },
                      },
                    },
                    [
                      _c("td", [_vm._v(_vm._s(item.serialNumber))]),
                      _c("td", { staticStyle: { position: "relative" } }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(item.qualityStatusName) +
                            "\n            "
                        ),
                        item.handInputFlag
                          ? _c(
                              "div",
                              {
                                staticClass: "delIcon",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.deleteTableData(index)
                                  },
                                },
                              },
                              [
                                _c("svg-icon", {
                                  attrs: {
                                    slot: "icon",
                                    "icon-class": "delete",
                                  },
                                  slot: "icon",
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]
                  )
                }),
              ],
              2
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "ips-footer" },
        [
          _c(
            "hips-button",
            {
              ref: "submitRef",
              attrs: { size: "large" },
              on: { click: _vm.handleButtonSubmit },
            },
            [
              _c("svg-icon", {
                attrs: { slot: "icon", "icon-class": "success" },
                slot: "icon",
              }),
              _vm._v(
                "\n      " + _vm._s(_vm.$t("button.confirmSNDetail")) + "\n    "
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }