<template>
  <div class="login-page">
    <!-- 头部banner容器 -->
    <div class="login-header-bg">
      <img style="padding-bottom: 20px" :src="logo" alt="">
      <div class="big-title">
        WMS仓储管理系统
      </div>
      <div class="small-title">
        仓库作业的精细化管理
      </div>
    </div>
    <form onsubmit="return false;">
      <!-- 中间输入框 -->
      <div class="from-box">
        <div class="input-item">
          <div class="input-label">
            <img src="./../resources/phone-icon.png" alt="">
            <!-- <hips-icon name="person" /> -->
          </div>
          <div class="input-box">
            <input ref="ref10" v-model="username" placeholder="请输入账号" type="text" @keyup.enter="skipPassword">
          </div>
        </div>
        <div class="input-item">
          <div class="input-label">
            <img src="./../resources/password-icon.png" alt="">
          </div>
          <div class="input-box">
            <input ref="ref20" v-model="password" placeholder="请输入密码" :type="showPassword ? 'text' : 'password'">
          </div>
          <div class="show-password-box" @click="showPassword = !showPassword">
            <img v-if="!showPassword" src="./../resources/hide-icon.png" alt="">
            <img v-else src="./../resources/show-icon.png" alt="">
          </div>
        </div>
      </div>
      <!-- 登录按钮 -->
      <div class="cache-password">
        <hips-checkbox label="记住密码" v-model="isCachePass" />
      </div>
      <div class="submit-box">
        <!-- :style="btnStyle"  -->
        <button type="submit" class="submit-btn" @click="loginFun">
          登 录
        </button>
      </div>
    </form>
  </div>
</template>
<script>
import { getToken } from '@/request';
import http from '@/request';
import { encryptPwd } from '@/utils/utils';
import { getUsersSelf, getLogo } from '@/public/api';
import { queryApplicationLocales } from '@/i18n/vue-i18n/api';
// 用户名密码： pook   pook123  getLogo
export default {
  name: 'Login',
  data() {
    return {
      showPassword: false,
      grantType: 'password',
      clientId: 'pook-app',
      clientSecret: 'Pook@App0!',
      username: '',
      password: '',
      logo: require('../resources/black-logo.png'),
      isCachePass: false
    };
  },
  computed: {
    btnStyle: function () {
      if (this.username && this.password) {
        return 'background:#FFBE37; color:white;';
      }
      return 'background:#E1E1E1;color: #999999;';
    },
  },
  created() {
    switch (navigator.language) {
      case 'zh-CN':
        this.$i18n.locale = 'zh';
        break;
      case 'en-US':
        this.$i18n.locale = 'en';
        break;
      default:
        this.$i18n.locale = 'en';
    }
  },
  mounted() {
    const cacheUpass = this.$storage.get('CACHE-UPASS')
    if (cacheUpass) {
      Object.assign(this.$data, {
        ...cacheUpass
      })
    }
    this.getLogo();
  },
  methods: {
    skipPassword() {
      this.$refs.ref20.focus();
    },
    // 获取登录logo
    async getLogo() {
      const res = await getLogo();
      if (res) {
        this.logo = res;
      } else {
        this.logo = require('../resources/logo.png');
      }
    },

    // 登录的方法
    loginFun() {
      if (!this.username || !this.password) {
        this.$hint.showFailed('请检查用户名或者密码');
        return;
      }
      this.$storage.clear();
      getToken({
        grantType: 'password',
        clientId: 'pook-app',
        clientSecret: 'Pook@App0!',
        username: this.username,
        password: encryptPwd(this.password),
      }).then((res) => {
        // 存入token
        this.$storage.set('user-token', res.access_token);
        if (this.isCachePass) {
          this.$storage.set('CACHE-UPASS', JSON.stringify({
            username: this.username,
            password: this.password,
            isCachePass: this.isCachePass
          }));
        } else {
          this.$storage.remove('CACHE-UPASS');
        }
        // 获取用户设置
        getUsersSelf().then(async (res) => {
          this.$storage.set('tenantId', res.tenantId);
          this.$storage.set('tenantName', res.tenantName);
          this.$storage.set('realName', res.realName);
          this.$storage.set('currentRoleName', res.currentRoleName);
          this.$storage.set('language', res.language);
          // 模拟数据
          const lang = res.language === 'zh_CN' ? 'zh' : 'en';
          const messages = await queryApplicationLocales(res.language);
          if (res) {
            this.$i18n.mergeLocaleMessage(lang, messages);
          }
          this.$i18n.locale = lang
          await this.getMsgCloseTime();

          this.$router.push('/home');
        });
      });
    },
    // 获取报错消息的关闭时间
    getMsgCloseTime: async function () {
      try {
        const url = `${process.env.VUE_APP_WMDM_URL}v1/${this.$storage.get('tenantId')}/wms-common/query-profile-value`;
        const data = {
          params: {
            paramCode: 'HWMS.PDA_MSG_CLOSE_TIME',
          },
        };
        const res = await http.get(url, data);
        this.$storage.set('closeTime', res.paramValue * 1000);
      } catch (e) {
        console.log(e.message);
        // hint.showWarn(e.message);
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
@import '~@/style/var.styl';

.login-page {
  height: 100vh;
  background-image: url('../resources/login-bg.png');
  background-size: cover;

  .cache-password {
    &::v-deep {
      .hips-checkbox__wrap {
        padding: 0 0 0 3.333vw;
      }
      .hips-checkbox__ui {
        border-radius: 4px;
        border-color: #d9d9d9;
      }

      .hips-checkbox__checked .hips-checkbox__ui {
        background-color: #ffbe37;
      }
    }
  }

  .submit-box {
    display: flex;
    justify-content: center;

    .submit-btn {
      width: 260px;
      height: 40px;
      background: #FFBE37;
      border-radius: 20px;
      text-align: center;
      line-height: 40px;
      color: #222222;
      font-size: 14px;
      letter-spacing: 1px;
      margin-top: 22px;
      border: 0 none;
    }
  }

  .login-header-bg {
    background-size: 100% 100%;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;

    img {
      width: 120px;
    }

    .big-title {
      font-size: 19px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #222222;
      line-height: 18px;
      letter-spacing: 1px;
    }

    .small-title {
      padding-top: 10px;
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 13px;
    }
  }

  .from-box {
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .input-item {
      display: flex;
      width: 260px;
      height: 40px;
      background: white;
      border-radius: 20px;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
      box-sizing: border-box;
      margin-bottom: 20px;

      .input-label {
        color: #9da0a3;

        img {
          width: 20px;
        }
      }

      .input-box {
        flex: 1;
        padding-left: 10px;

        input {
          width: 100%;
          height: 30px;
          font-size: 13px;
          background: transparentify;
        }

        input::-webkit-input-placeholder {
          color: #aab2bd;
          font-size: 13px;
        }
      }

      .show-password-box {
        padding-left: 10px;

        img {
          width: 15px;
        }
      }
    }
  }
}
</style>
