var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: { title: _vm.$t("title.receiveAdjustment") },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _c(
          "hips-tabs",
          {
            on: { click: _vm.changeTab },
            model: {
              value: _vm.active,
              callback: function ($$v) {
                _vm.active = $$v
              },
              expression: "active",
            },
          },
          [
            _c(
              "hips-tab",
              {
                class: [{ "active-tab": _vm.active === 0 }],
                attrs: { title: _vm.$t("label.adjust") },
              },
              [
                _c("ips-lov", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.customData["sourceBillCode"]
                        ? _vm.hideControl["sourceBillCode"]
                        : true,
                      expression:
                        "customData['sourceBillCode'] ? hideControl['sourceBillCode']: true",
                    },
                  ],
                  ref: "ref0",
                  attrs: {
                    label: _vm.$t("label.resourceNum"),
                    title: _vm.$t("label.resourceNum"),
                    params: "sourceBillCode",
                    display: "sourceBillCode",
                    url:
                      _vm.winvBaseUrl +
                      "app-inbound-receive-adjust/query-source-order",
                    "pre-parse-url": _vm.urlData["sourceBillCode"],
                    custom: _vm.customData["sourceBillCode"],
                    "source-parse-field": _vm.sourceParseField,
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleCommonClick(0)
                    },
                    clickIcon: function ($event) {
                      return _vm.handleCommonClick(0, "lov")
                    },
                    enter: function ($event) {
                      return _vm.skipNext(10)
                    },
                    down: function ($event) {
                      return _vm.skipNext(10)
                    },
                    choose: function ($event) {
                      return _vm.handleChoose("sourceBillCode", $event)
                    },
                    setSourceField: function ($event) {
                      return _vm.saveSourceField($event)
                    },
                    valuedByFoucs: function ($event) {
                      return _vm.valuedByFoucs($event)
                    },
                  },
                  model: {
                    value: _vm.inputData.sourceBillCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputData, "sourceBillCode", $$v)
                    },
                    expression: "inputData.sourceBillCode",
                  },
                }),
                _c("ips-lov", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.customData["billCode"]
                        ? _vm.hideControl["billCode"]
                        : true,
                      expression:
                        "customData['billCode'] ? hideControl['billCode']: true",
                    },
                  ],
                  ref: "ref10",
                  attrs: {
                    label: _vm.$t("label.billCode"),
                    title: _vm.$t("label.billCode"),
                    required: "",
                    params: "billCode",
                    display: "billCode",
                    url:
                      _vm.winvBaseUrl +
                      "app-inbound-receive-adjust/query-inbound-order",
                    "url-params": {
                      sourceBillCode: _vm.inputData.sourceBillCode,
                    },
                    "pre-parse-url": _vm.urlData["billCode"],
                    custom: _vm.customData["billCode"],
                    "source-parse-field": _vm.sourceParseField,
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleCommonClick(10)
                    },
                    clickIcon: function ($event) {
                      return _vm.handleCommonClick(10, "lov")
                    },
                    enter: function ($event) {
                      return _vm.skipNext(20)
                    },
                    down: function ($event) {
                      return _vm.skipNext(20)
                    },
                    choose: function ($event) {
                      return _vm.handleChoose("billCode", $event)
                    },
                    setSourceField: function ($event) {
                      return _vm.saveSourceField($event)
                    },
                    valuedByFoucs: function ($event) {
                      return _vm.valuedByFoucs($event)
                    },
                  },
                  model: {
                    value: _vm.inputData.billCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputData, "billCode", $$v)
                    },
                    expression: "inputData.billCode",
                  },
                }),
                _c("ips-lov", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.customData["receiptNumber"]
                        ? _vm.hideControl["receiptNumber"]
                        : true,
                      expression:
                        "customData['receiptNumber'] ? hideControl['receiptNumber']: true",
                    },
                  ],
                  ref: "ref20",
                  attrs: {
                    label: _vm.$t("label.shelvesReceiveNum"),
                    title: _vm.$t("label.shelvesReceiveNum"),
                    params: "receiptNumber",
                    display: "receiptNumber",
                    url:
                      _vm.winvBaseUrl +
                      "app-inbound-receive-adjust/query-receipt-number",
                    "url-params": {
                      sourceBillCode: _vm.inputData.sourceBillCode,
                      billId: _vm.billId,
                    },
                    "pre-parse-url": _vm.urlData["receiptNumber"],
                    custom: _vm.customData["receiptNumber"],
                    "source-parse-field": _vm.sourceParseField,
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleCommonClick(20)
                    },
                    clickIcon: function ($event) {
                      return _vm.handleCommonClick(20, "lov")
                    },
                    enter: function ($event) {
                      return _vm.skipNext(30)
                    },
                    down: function ($event) {
                      return _vm.skipNext(30)
                    },
                    choose: function ($event) {
                      return _vm.handleChoose("receiptNumber", $event)
                    },
                    setSourceField: function ($event) {
                      return _vm.saveSourceField($event)
                    },
                    valuedByFoucs: function ($event) {
                      return _vm.valuedByFoucs($event)
                    },
                  },
                  model: {
                    value: _vm.inputData.receiptNumber,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputData, "receiptNumber", $$v)
                    },
                    expression: "inputData.receiptNumber",
                  },
                }),
                _c("ips-lov", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.customData["cidCode"]
                        ? _vm.hideControl["cidCode"]
                        : true,
                      expression:
                        "customData['cidCode'] ? hideControl['cidCode']: true",
                    },
                  ],
                  ref: "ref30",
                  attrs: {
                    label: _vm.$t("label.receiveCid"),
                    title: _vm.$t("label.receiveCid"),
                    params: "cidCode",
                    url: _vm.winvBaseUrl + "app-inbound-receive-adjust/cid-lov",
                    "url-params": {
                      sourceBillCode: _vm.inputData.sourceBillCode,
                      billId: _vm.billId,
                      receiptNumber: _vm.inputData.receiptNumber,
                    },
                    "pre-parse-url": _vm.urlData["cidCode"],
                    custom: _vm.customData["cidCode"],
                    "source-parse-field": _vm.sourceParseField,
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleCommonClick(30)
                    },
                    clickIcon: function ($event) {
                      return _vm.handleCommonClick(30, "lov")
                    },
                    enter: function ($event) {
                      return _vm.skipNext(40)
                    },
                    down: function ($event) {
                      return _vm.skipNext(40)
                    },
                    choose: function ($event) {
                      return _vm.handleChoose("receiveCidCode", $event)
                    },
                    setSourceField: function ($event) {
                      return _vm.saveSourceField($event)
                    },
                    valuedByFoucs: function ($event) {
                      return _vm.valuedByFoucs($event)
                    },
                  },
                  model: {
                    value: _vm.inputData.cidCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputData, "cidCode", $$v)
                    },
                    expression: "inputData.cidCode",
                  },
                }),
                _c("ips-lov", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.customData["skuCode"]
                        ? _vm.hideControl["skuCode"]
                        : true,
                      expression:
                        "customData['skuCode'] ? hideControl['skuCode']: true",
                    },
                  ],
                  ref: "ref40",
                  attrs: {
                    label: _vm.$t("label.skuCode"),
                    title: _vm.$t("label.skuCode"),
                    required: "",
                    params: "sku",
                    display: "sku",
                    url:
                      _vm.winvBaseUrl + "app-inbound-receive-adjust/goods-lov",
                    "url-params": {
                      sourceBillCode: _vm.inputData.sourceBillCode,
                      billId: _vm.billId,
                      receiptNumber: _vm.inputData.receiptNumber,
                      cidId: _vm.cidId,
                    },
                    "pre-parse-url": _vm.urlData["skuCode"],
                    custom: _vm.customData["skuCode"],
                    "source-parse-field": _vm.sourceParseField,
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleCommonClick(40)
                    },
                    clickIcon: function ($event) {
                      return _vm.handleCommonClick(40, "lov")
                    },
                    enter: _vm.skuEnter,
                    down: _vm.skuEnter,
                    choose: function ($event) {
                      return _vm.handleChoose("skuCode", $event)
                    },
                    setSourceField: function ($event) {
                      return _vm.saveSourceField($event)
                    },
                    valuedByFoucs: function ($event) {
                      return _vm.valuedByFoucs($event)
                    },
                  },
                  model: {
                    value: _vm.inputData.skuCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputData, "skuCode", $$v)
                    },
                    expression: "inputData.skuCode",
                  },
                }),
                _vm.inputData.skuCode
                  ? _c(
                      "div",
                      [
                        _c(
                          "ips-page-card",
                          {
                            attrs: {
                              "react-field": ["detailLineNum", "uomName"],
                              item: _vm.skuInfo,
                            },
                          },
                          [
                            _c("ips-message-tip", {
                              attrs: { message: _vm.skuInfo.goodsName },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("hips-row", [
                  _vm.isbatch
                    ? _c(
                        "div",
                        [
                          _c(
                            "hips-col",
                            {
                              staticClass: "hips-col-class",
                              attrs: { span: "24" },
                            },
                            [
                              _c("ips-lov", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isbatch
                                      ? _vm.customData["batchCode"]
                                        ? _vm.hideControl["batchCode"]
                                        : _vm.isbatch
                                      : false,
                                    expression:
                                      "isbatch ? (customData['batchCode'] ? hideControl['batchCode'] : isbatch) : false",
                                  },
                                ],
                                ref: "ref50",
                                attrs: {
                                  label: _vm.$t("label.batch"),
                                  title: _vm.$t("label.batch"),
                                  required: "",
                                  params: "batchCode",
                                  url:
                                    _vm.winvBaseUrl +
                                    "app-inbound-receive-adjust/batch-lov",
                                  "url-params": {
                                    sourceBillCode:
                                      _vm.inputData.sourceBillCode,
                                    billId: _vm.billId,
                                    receiptNumber: _vm.inputData.receiptNumber,
                                    cidId: _vm.cidId,
                                    goodsId: _vm.goodsId,
                                    detailId: _vm.detailId,
                                  },
                                  "pre-parse-url": _vm.urlData["batchCode"],
                                  custom: _vm.customData["batchCode"],
                                  "source-parse-field": _vm.sourceParseField,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.handleCommonClick(50)
                                  },
                                  clickIcon: function ($event) {
                                    return _vm.handleCommonClick(50, "lov")
                                  },
                                  enter: function ($event) {
                                    return _vm.skipNext(60)
                                  },
                                  down: function ($event) {
                                    return _vm.skipNext(60)
                                  },
                                  choose: function ($event) {
                                    return _vm.handleChoose("batchCode", $event)
                                  },
                                  setSourceField: function ($event) {
                                    return _vm.saveSourceField($event)
                                  },
                                  valuedByFoucs: function ($event) {
                                    return _vm.valuedByFoucs($event)
                                  },
                                },
                                model: {
                                  value: _vm.inputData.batchCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.inputData, "batchCode", $$v)
                                  },
                                  expression: "inputData.batchCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _c("hips-row", [
                  (_vm.serialType === "INV_CTRL" ||
                    _vm.serialType === "IN_CTRL" ||
                    _vm.serialType === "SEMI_INV_CTRL") &&
                  _vm.inputData.skuCode
                    ? _c(
                        "div",
                        [
                          _c(
                            "hips-col",
                            {
                              staticClass: "hips-col-class",
                              attrs: { span: "14" },
                            },
                            [
                              _c("ips-input-number", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.customData["receiveQty"]
                                      ? _vm.hideControl["receiveQty"]
                                      : true,
                                    expression:
                                      "customData['receiveQty'] ? hideControl['receiveQty']: true",
                                  },
                                ],
                                ref: "ref60",
                                attrs: {
                                  label: _vm.$t("label.receiveAdjustQty"),
                                  required: "",
                                  custom: _vm.customData["receiveQty"],
                                  "source-parse-field": _vm.sourceParseField,
                                  "enable-serial": _vm.serialType,
                                  min: 0,
                                  precision: 0,
                                },
                                on: {
                                  enter: _vm.checkImpQty,
                                  setSourceField: function ($event) {
                                    return _vm.saveSourceField($event)
                                  },
                                  valuedByFoucs: function ($event) {
                                    return _vm.valuedByFoucs($event)
                                  },
                                },
                                model: {
                                  value: _vm.inputData.receiveQty,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.inputData, "receiveQty", $$v)
                                  },
                                  expression: "inputData.receiveQty",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "hips-col",
                            {
                              staticClass: "hips-col-class",
                              attrs: { span: "10" },
                            },
                            [
                              _c(
                                "hips-button",
                                {
                                  ref: "refSerail",
                                  staticClass: "serial-button-style",
                                  attrs: { size: "large" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.goToSerialDetail.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("button.serialNumber")) +
                                      " (" +
                                      _vm._s(
                                        _vm.isReturn
                                          ? _vm.serialReceiveQtyImp
                                          : _vm.showQty
                                      ) +
                                      ")\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _c("hips-row", [
                  _vm.serialType !== "INV_CTRL" &&
                  _vm.serialType !== "IN_CTRL" &&
                  _vm.serialType !== "SEMI_INV_CTRL" &&
                  _vm.inputData.skuCode
                    ? _c(
                        "div",
                        [
                          _c(
                            "hips-col",
                            {
                              staticClass: "hips-col-class",
                              attrs: { span: "24" },
                            },
                            [
                              _c("ips-input-number", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.customData["receiveQty"]
                                      ? _vm.hideControl["receiveQty"]
                                      : true,
                                    expression:
                                      "customData['receiveQty'] ? hideControl['receiveQty']: true",
                                  },
                                ],
                                ref: "ref60",
                                attrs: {
                                  label: _vm.$t("label.receiveAdjustQty"),
                                  required: "",
                                  custom: _vm.customData["receiveQty"],
                                  "source-parse-field": _vm.sourceParseField,
                                  "enable-serial": _vm.serialType,
                                  min: 0,
                                  "more-than-zero": true,
                                },
                                on: {
                                  enter: function ($event) {
                                    return _vm.skipNext(70)
                                  },
                                  setSourceField: function ($event) {
                                    return _vm.saveSourceField($event)
                                  },
                                  valuedByFoucs: function ($event) {
                                    return _vm.valuedByFoucs($event)
                                  },
                                },
                                model: {
                                  value: _vm.inputData.receiveQty,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.inputData, "receiveQty", $$v)
                                  },
                                  expression: "inputData.receiveQty",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _vm.inputData.skuCode
                  ? _c("div", { staticClass: "qtyBox" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("label.oldReceiveQty")) +
                            " :  " +
                            _vm._s(_vm.showQty)
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _c("ips-lov", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.customData["reason"]
                        ? _vm.hideControl["reason"]
                        : true,
                      expression:
                        "customData['reason'] ? hideControl['reason']: true",
                    },
                  ],
                  ref: "ref70",
                  attrs: {
                    label: _vm.$t("label.reason"),
                    title: _vm.$t("label.reason"),
                    required: "",
                    params: "meaning",
                    url: _vm.wmdmBaseUrl + "wms-common/lov-data-query",
                    "url-params": {
                      lovCode: "WINV.INBOUND_RECEIVE_ADJUST_REASON",
                    },
                    "pre-parse-url": _vm.urlData["reason"],
                    "is-page-content": false,
                    custom: _vm.customData["reason"],
                    "source-parse-field": _vm.sourceParseField,
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleCommonClick(70)
                    },
                    clickIcon: function ($event) {
                      return _vm.handleCommonClick(70, "lov")
                    },
                    enter: function ($event) {
                      return _vm.skipNext("submitRef")
                    },
                    down: function ($event) {
                      return _vm.skipNext("submitRef")
                    },
                    choose: function ($event) {
                      return _vm.handleChoose("reason", $event)
                    },
                    setSourceField: function ($event) {
                      return _vm.saveSourceField($event)
                    },
                    valuedByFoucs: function ($event) {
                      return _vm.valuedByFoucs($event)
                    },
                  },
                  model: {
                    value: _vm.inputData.reason,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputData, "reason", $$v)
                    },
                    expression: "inputData.reason",
                  },
                }),
              ],
              1
            ),
            _c(
              "hips-tab",
              {
                class: [{ "active-tab": _vm.active !== 0 }],
                attrs: { title: "" + _vm.$t("label.cancelReceipt") },
              },
              [
                _c("ips-lov", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.customData["cidCode1"]
                        ? _vm.hideControl["cidCode1"]
                        : true,
                      expression:
                        "customData['cidCode1'] ? hideControl['cidCode1']: true",
                    },
                  ],
                  ref: "ref100",
                  attrs: {
                    label: _vm.$t("label.receiveCid"),
                    title: _vm.$t("label.receiveCid"),
                    params: "cidCode",
                    required: "",
                    url: _vm.wmdmBaseUrl + "mdm-info/query-container-to",
                    "url-params": { cidStatus: "RECEIVING" },
                    "pre-parse-url": _vm.urlData["cidCode1"],
                    custom: _vm.customData["cidCode1"],
                    "source-parse-field": _vm.sourceParseField,
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleCommonClick(100)
                    },
                    clickIcon: function ($event) {
                      return _vm.handleCommonClick(100, "lov")
                    },
                    enter: _vm.handleSkipCid1,
                    down: function ($event) {
                      return _vm.skipNext(110)
                    },
                    choose: function ($event) {
                      return _vm.handleChoose("receiveCidCode1", $event)
                    },
                    setSourceField: function ($event) {
                      return _vm.saveSourceField($event)
                    },
                    valuedByFoucs: function ($event) {
                      return _vm.valuedByFoucs($event)
                    },
                  },
                  model: {
                    value: _vm.inputData.cidCode1,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputData, "cidCode1", $$v)
                    },
                    expression: "inputData.cidCode1",
                  },
                }),
                _c("ips-lov", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.customData["reason1"]
                        ? _vm.hideControl["reason1"]
                        : true,
                      expression:
                        "customData['reason1'] ? hideControl['reason1']: true",
                    },
                  ],
                  ref: "ref110",
                  attrs: {
                    label: _vm.$t("label.reason"),
                    title: _vm.$t("label.reason"),
                    required: "",
                    params: "meaning",
                    url: _vm.wmdmBaseUrl + "wms-common/lov-data-query",
                    "url-params": {
                      lovCode: "WINV.INBOUND_RECEIVE_ADJUST_REASON",
                    },
                    "pre-parse-url": _vm.urlData["reason1"],
                    "is-page-content": false,
                    custom: _vm.customData["reason1"],
                    "source-parse-field": _vm.sourceParseField,
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleCommonClick(110)
                    },
                    clickIcon: function ($event) {
                      return _vm.handleCommonClick(110, "lov")
                    },
                    enter: function ($event) {
                      return _vm.skipNext("inputRef")
                    },
                    down: function ($event) {
                      return _vm.skipNext("inputRef")
                    },
                    choose: function ($event) {
                      return _vm.handleChoose("reason1", $event)
                    },
                    setSourceField: function ($event) {
                      return _vm.saveSourceField($event)
                    },
                    valuedByFoucs: function ($event) {
                      return _vm.valuedByFoucs($event)
                    },
                  },
                  model: {
                    value: _vm.inputData.reason1,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputData, "reason1", $$v)
                    },
                    expression: "inputData.reason1",
                  },
                }),
                _vm.cidDataArray.length > 0
                  ? _c("div", [
                      _c(
                        "p",
                        {
                          staticStyle: {
                            "margin-top": "2.367vw",
                            color: "#595959",
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("title.receiveDetail")) +
                              "\n          "
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          ref: "ips-content",
                          staticClass: "ips-card__content",
                          attrs: { tabindex: "-1" },
                        },
                        [
                          _c(
                            "hips-scroll",
                            {
                              ref: "scroll",
                              attrs: {
                                "scroll-options": _vm.scrollOption,
                                data: [_vm.cidDataArray],
                                "scroll-events": ["scroll"],
                              },
                              on: {
                                "pulling-down": _vm.loadRefresh,
                                "pulling-up": _vm.loadMore,
                                scroll: _vm.scroll,
                              },
                            },
                            [
                              _c(
                                "ul",
                                { staticClass: "list-wrapper" },
                                [
                                  _vm._l(
                                    _vm.cidDataArray,
                                    function (item, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "card_countLocation",
                                        },
                                        [
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "title" },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.$t("label.tray")
                                                    ) +
                                                    ":\n                    "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "value" },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(item.cidCode) +
                                                    "\n                    "
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "row-50" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "title" },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          _vm.$t("label.sku")
                                                        ) +
                                                        ":\n                      "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "value" },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(item.sku) +
                                                        "\n                      "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "row-50" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "title" },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          _vm.$t("label.batch")
                                                        ) +
                                                        ":\n                      "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "value" },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(item.batchCode) +
                                                        "\n                      "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "row-50" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "title" },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "label.lineNum"
                                                          )
                                                        ) +
                                                        ":\n                      "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "value" },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          item.detailLineNum
                                                        ) +
                                                        "\n                      "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "row-50" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "title" },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          _vm.$t("label.unit")
                                                        ) +
                                                        ":\n                      "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "value" },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(item.uomName) +
                                                        "\n                      "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "title" },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.$t("label.goodsName")
                                                    ) +
                                                    ":\n                    "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "value" },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      item.goodsName || "--"
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]
                                            ),
                                          ]),
                                          ![
                                            "INV_CTRL",
                                            "IN_CTRL",
                                            "SEMI_INV_CTRL",
                                          ].includes(item.serialType)
                                            ? _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "title" },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "label.quantity"
                                                            )
                                                          ) +
                                                          ":\n                    "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "value" },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            item.receiveQty ||
                                                              "--"
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          [
                                            "INV_CTRL",
                                            "IN_CTRL",
                                            "SEMI_INV_CTRL",
                                          ].includes(item.serialType)
                                            ? _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "row-50" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "title",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "label.quantity"
                                                                )
                                                              ) +
                                                              ":\n                      "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "value",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                item.receiveQty ||
                                                                  "--"
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "row-50" },
                                                    [
                                                      _c(
                                                        "hips-button",
                                                        {
                                                          staticStyle: {
                                                            "margin-left":
                                                              "3px",
                                                            color: "#FFFFFF",
                                                            "font-size": "14px",
                                                            height: "30px",
                                                            "line-height":
                                                              "30px",
                                                            width: "150px",
                                                          },
                                                          attrs: {
                                                            type: "warning",
                                                            size: "large",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.goToSerialDetail1(
                                                                item
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "button.seriesDetail"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    }
                                  ),
                                  _c(
                                    "li",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.cidDataArray.length > 20,
                                          expression:
                                            "cidDataArray.length > 20",
                                        },
                                      ],
                                      staticClass:
                                        "scroll-item hips-thinline--bottom",
                                      staticStyle: {
                                        color: "var(--primaryColor)",
                                        "text-align": "center",
                                      },
                                      on: { click: _vm.scrollToTop },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.$t("button.backTop")) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                2
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "ips-footer", staticStyle: { position: "fix" } },
          [
            !_vm.active
              ? _c(
                  "hips-button",
                  {
                    ref: "submitRef",
                    attrs: { size: "large" },
                    on: {
                      enter: function ($event) {
                        return _vm.handleCommonSubmit("tab1")
                      },
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.handleCommonSubmit("tab1")
                      },
                    },
                  },
                  [
                    _c("svg-icon", {
                      attrs: { slot: "icon", "icon-class": "success" },
                      slot: "icon",
                    }),
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("button.confirm")) +
                        "\n      "
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.active
              ? _c(
                  "hips-button",
                  {
                    ref: "inputRef",
                    attrs: { size: "large" },
                    on: {
                      enter: function ($event) {
                        return _vm.handleCommonSubmit("tab2")
                      },
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.handleCommonSubmit("tab2")
                      },
                    },
                  },
                  [
                    _c("svg-icon", {
                      attrs: { slot: "icon", "icon-class": "success" },
                      slot: "icon",
                    }),
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("button.cancelReceipt")) +
                        "\n      "
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }