<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.cidDetail') + `${loadCidCode}`" :is-index="false" />
    </div>
    <div class="ips-container container">
      <!-- 查询类型 -->
      <ips-lov
        :label="$t('label.queryType')"
        :title="$t('label.queryType')"
        v-model="defaultType"
        v-show="customData['defaultType'] ? hideControl['defaultType']: true"
        params="meaning"
        :url="`${wmdmBaseUrl}wms-common/lov-data-query`"
        :url-params="{lovCode:'WINV.UNLOAD_QUERY_LEVEL'}"
        :pre-parse-url="urlData['defaultType']"
        :is-page-content="false"
        :custom="customData['defaultType']"
        :source-parse-field="sourceParseField"
        ref="ref0"
        @click.stop="handleCommonClick(0)"
        @clickIcon="handleCommonClick(0, 'lov')"
        :readonly="true"
        @choose="handleChoose('type', $event)"
        @enter="skipNext(10)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <div style="margin-top: 16px">
        <hips-row>
          <hips-col span="19">
            <ips-field
              :label="$t('label.queryConditions')"
              v-model="queryValue"
              v-show="customData['queryValue'] ? hideControl['queryValue']: true"
              params="queryValue"
              display="queryValue"
              :pre-parse-url="urlData['queryValue']"
              :custom="customData['queryValue']"
              :source-parse-field="sourceParseField"
              ref="ref10"
              @enter="skipNext('submitRef')"
              @setSourceField="saveSourceField($event)"
              @valuedByFoucs="valuedByFoucs($event)"
            />
          </hips-col>
          <hips-col span="5" style="text-align: right">
            <hips-button
              type="warning"
              @click.native="queryTask"
            >
              {{ $t('button.search') }}
            </hips-button>
          </hips-col>
        </hips-row>
      </div>
      <div v-if="taskList.length>0">
        <div
          v-for="(item, index) in taskList"
          :key="index"
          class="ips-unloading-card-style"
          @click="handleClickCard(item.id)"
          :class="[{'card-active': idList.includes(item.id)}]"
          :ref="`tr${index}`"
        >
          <hips-row>
            <hips-col span="24">
              <!-- 物料编码 -->
              <span class="coloumns-fieldname-left">{{ $t('label.skuCode') }}</span>
              <span class="unloading-card-coloumns-center">:</span>
              <span class="coloumns-fieldvalue">{{ item.sku }}</span>
            </hips-col>
          </hips-row>
          <hips-row>
            <hips-col span="24" style="margin-top: 12px;">
              <!-- 品名 -->
              <span class="coloumns-fieldname-left">{{ $t('label.goodsName') }}</span>
              <span class="unloading-card-coloumns-center">:</span>
              <span class="coloumns-fieldvalue">{{ item.goodsName }}</span>
            </hips-col>
          </hips-row>
          <hips-row>
            <hips-col span="24" style="margin-top: 12px">
              <!-- 批次 -->
              <span class="coloumns-fieldname-left">{{ $t('label.batch') }}</span>
              <span class="unloading-card-coloumns-center">:</span>
              <span class="coloumns-fieldvalue">{{ item.batchCode }}</span>
            </hips-col>
          </hips-row>
          <hips-row>
            <hips-col span="24" style="margin-top: 12px;">
              <!-- 单据编号 -->
              <span class="coloumns-fieldname-left">{{ $t('label.deliveryCode') }}</span>
              <span class="unloading-card-coloumns-center">:</span>
              <span class="coloumns-fieldvalue">{{ item.billCode }}</span>
            </hips-col>
          </hips-row>
          <hips-row>
            <hips-col span="11" style="margin-top: 12px">
              <!-- 数量 -->
              <span class="coloumns-fieldname-left">{{ $t('label.quantity') }}</span>
              <span class="unloading-card-coloumns-center">:</span>
              <span
                @click.stop="modifiNum(index, item)"
                class="coloumns-fieldvalue"
                style="font-weight: bold;z-index: 9999"
                :ref="`num${index}`"
              >{{ item.qtyScan }}</span>
            </hips-col>
            <hips-col span="13" style="margin-top: 12px">
              <!-- 目标库位 -->
              <span class="coloumns-fieldname-right">{{ $t('label.targetLocation') }}</span>
              <span class="unloading-card-coloumns-center">:</span>
              <span class="coloumns-fieldvalue">{{ item.toLocationCode }}</span>
            </hips-col>
          </hips-row>
          <hips-row>
            <hips-col span="11" style="margin-top: 12px">
              <!-- 单位 -->
              <span class="coloumns-fieldname-left">{{ $t('label.unit') }}</span>
              <span class="unloading-card-coloumns-center">:</span>
              <span class="coloumns-fieldvalue">{{ item.uomName }}</span>
            </hips-col>
            <hips-col span="13" style="margin-top: 12px">
              <!-- 货主 -->
              <span class="coloumns-fieldname-right">{{ $t('label.ownerCode') }}</span>
              <span class="unloading-card-coloumns-center">:</span>
              <span class="coloumns-fieldvalue">{{ item.ownerCode }}</span>
            </hips-col>
          </hips-row>
        </div>
      </div>

      <hips-popup v-model="modifyFlag">
        <div class="modifyModel">
          <!-- 拆分卸货 -->
          <h1>{{ $t('label.splitUnloading') }}</h1>
          <!-- 任务总数量 -->
          <ips-field
            v-model="qtyPlan"
            :label="$t('label.totalTaskNum')"
            disabled
          />
          <!-- 本次卸货数量 -->
          <ips-input-number
            v-model="qtyScan"
            :label="$t('label.dischargeNum')"
            ref="modifyRef"
            :min="null"
            more-than-zero
            not-auto-fill
            @enter="skipNext('popupConfirmRef')"
            @down="skipNext('popupConfirmRef')"
          />
        </div>
        <div class="ips-popup-footer">
          <hips-button
            @click="modifyCancel"
          >
            {{ $t('button.cancel') }}
          </hips-button>
          <hips-button
            style="color: #108EE9"
            ref="popupConfirmRef"
            @click="modifyConfirm"
          >
            {{ $t('button.confirm') }}
          </hips-button>
        </div>
      </hips-popup>

      <hips-popup
        v-model="unloadingConfirmFlag"
        :overlay-on-click-close="false"
        :z-index="1"
      >
        <div class="modifyModel">
          <!-- 卸货确认 -->
          <h1>{{ $t('label.confirmDischarge') }}</h1>
          <!-- 目标库位 -->
          <ips-lov
            :label="$t('label.targetLocation')"
            :title="$t('label.targetLocation')"
            required
            v-model="toLocationCode"
            v-show="allowLocationReplace==='1' ? (customData['toLocationCode'] ? hideControl['toLocationCode'] : allowLocationReplace==='1') : false"
            params="toLocationCode"
            :url="`${winvBaseUrl}app-outbound-unload/query-to-locations`"
            :url-params="{ loadCidCode, loadCidId }"
            :pre-parse-url="urlData['toLocationCode']"
            :custom="customData['toLocationCode']"
            :source-parse-field="sourceParseField"
            ref="ref20"
            @click.stop="handleCommonClick(20)"
            @clickIcon="handleCommonClick(20, 'lov')"
            @choose="handleChoose('location', $event)"
            @enter="handleLocationEnter"
            @setSourceField="saveSourceField($event)"
            @valuedByFoucs="valuedByFoucs($event)"
            z-index="9999"
          />
          <!-- 目标库位 -->
          <hips-row v-if="allowLocationReplace==='0'" style="margin-bottom: 12px">
            <hips-col span="7">
              <span class="coloumns-fieldname-left">{{ $t('label.targetLocation') }}</span>
            </hips-col>
            <hips-col span="17">
              <span class="popup-column-right">{{ toLocationCode }}</span>
            </hips-col>
          </hips-row>
          <!-- 库位名称 -->
          <hips-row style="margin-bottom: 12px">
            <hips-col span="7">
              <span class="coloumns-fieldname-left">{{ $t('label.locationName') }}</span>
            </hips-col>
            <hips-col span="17">
              <span class="popup-column-right">{{ locationInfo.toLocationName }}</span>
            </hips-col>
          </hips-row>
          <!-- 目标库区 -->
          <hips-row style="margin-bottom: 12px">
            <hips-col span="7">
              <span class="coloumns-fieldname-left">{{ $t('label.targetWharea') }}</span>
            </hips-col>
            <hips-col span="17">
              <span class="popup-column-right">{{ locationInfo.toWhareaCode }}</span>
            </hips-col>
          </hips-row>
          <!-- 库区名称 -->
          <hips-row>
            <hips-col span="7">
              <span class="coloumns-fieldname-left">{{ $t('label.whareaName') }}</span>
            </hips-col>
            <hips-col span="17">
              <span class="popup-column-right">{{ locationInfo.toWhareaName }}</span>
            </hips-col>
          </hips-row>
          <!-- 目标托盘 -->
          <ips-lov
            :label="$t('label.targetTray')"
            :title="$t('label.targetTray')"
            z-index="9999"
            v-model="toCidCode"
            v-show="customData['toCidCode'] ? hideControl['toCidCode']: true"
            params="toCidCode"
            :url="`${winvBaseUrl}app-outbound-unload/query-to-containers`"
            :url-params="{toLocationCode, toLocationId, allProcessFlag, loadCidId, loadCidCode }"
            :pre-parse-url="urlData['toCidCode']"
            :custom="customData['toCidCode']"
            :source-parse-field="sourceParseField"
            ref="ref30"
            v-if="showTargetTray"
            @blur="toCidBlur"
            @click.stop="handleCommonClick(30)"
            @clickIcon="handleCommonClick(30, 'lov')"
            @choose="handleChoose('cid', $event)"
            @enter="isReplace ? skipNext(40) : skipNext('popupConfirmRef')"
            @setSourceField="saveSourceField($event)"
            @valuedByFoucs="valuedByFoucs($event)"
          />
          <!-- 替换原因 -->
          <ips-lov
            :label="$t('label.replaceReason')"
            :title="$t('label.replaceReason')"
            v-model="unloadReplaceReasonCode"
            v-if="isReplace ? (customData['unloadReplaceReasonCode'] ? hideControl['unloadReplaceReasonCode'] : isReplace) : false"
            params="meaning"
            :is-page-content="false"
            :required="isReplace"
            :url="`${wmdmBaseUrl}wms-common/lov-data-query`"
            :url-params="{lovCode:'WINV.OUTBOUND_LOAD_REASON'}"
            :pre-parse-url="urlData['unloadReplaceReasonCode']"
            :custom="customData['unloadReplaceReasonCode']"
            :source-parse-field="sourceParseField"
            ref="ref40"
            @click.stop="handleCommonClick(40)"
            @clickIcon="handleCommonClick(40, 'lov')"
            @choose="handleChoose('replace', $event)"
            @enter="skipNext('popupConfirmRef')"
            @setSourceField="saveSourceField($event)"
            @valuedByFoucs="valuedByFoucs($event)"
            z-index="9999"
          />
        </div>
        <div class="ips-popup-footer">
          <hips-button
            @click="unloadingCancel"
          >
            {{ $t('button.cancel') }}
          </hips-button>
          <hips-button
            style="color: #108EE9"
            ref="popupConfirmRef"
            @click="unloadingConfirm"
          > {{ $t('button.confirm') }}</hips-button>
        </div>
      </hips-popup>
    </div>
    <div class="ips-footer footer">
      <hips-checkbox
        v-model="isAllChange"
        :label="$t('button.allCheck')"
      />
      <hips-button
        size="large"
        ref="submitRef"
        style="background-color: var(--primaryColor);color: #FFFFFF;border-radius: 4px"
        @click.stop="handleCommonSubmit"
      >
        {{ $t('button.submitUnloading') }}
      </hips-button>
    </div>
  </hips-view>
</template>

<script>
  import { Switch, Row, Col, Checkbox } from '@hips/vue-ui';
  import { queryUnloadTask, confirmUnloading, cancelUnloading, initPage, queryTaskList } from '../api'
  export default {
    name: 'UnloadingDetail',
    components:{
      [Switch.name]: Switch,
      [Row.name]: Row,
      [Col.name]: Col,
      [Col.Checkbox]: Checkbox,
    },
    data () {
      return {
        taskList: [],
        selectedData: [],
        isAllChange: false,
        isReadAll: false,
        idList: [],
        modifyFlag: false,
        unloadingConfirmFlag: false,
        queryValue: '',
        selected: true,
        qtyPlan: '',
        qtyScan: '',
        loadCidCode: '',
        loadCidId: '',
        allowLocationReplace: '',
        toLocationCode: '',
        toCidCode: '',
        isSelectedALL: true,
        isReplace: false,
        locationInfo: {
          toLocationName: '',
          toWhareaCode: '',
          toWhareaName: '',
        },
        unloadReplaceReasonCode: '',
        toLocationId: '',
        showTargetTray: false,
        qtyModified: false, //表格里数量是否改变过
        allProcessFlag: 1,
        urlData:{},
        customData: {}, // 个性化配置信息列表
        hideControl: {}, // 个性化配置显示隐藏控制
        sourceParseField: {}, // 界面中所有的来源解析字段
      }
    },
    watch: {
      'isAllChange': function(val) {
        if(val !==false){
          this.idList = [];
          this.taskList.forEach((v)=>{
            this.idList.push(v.id)
          })
        }else{
          if(this.idList && this.idList.length === this.taskList.length){
            this.idList=[];
          }

        }
      },
      selectedData(val) {
        const { qtyModified, taskList } = this;
        this.allProcessFlag = (val.length === taskList.length && !qtyModified) ? 1 : 0;
      },
      qtyModified(val) {
        const { selectedData, taskList } = this;
        this.allProcessFlag = (selectedData.length === taskList.length && !val) ? 1 : 0;
      }
    },
    methods: {
      /**
       *@functionName: saveSourceField
       *@description: 保存来源解析字段并赋值Enter
       *@params1: fieldObj[Object] 来源解析字段对象,
       */
      saveSourceField(fieldObj) {
        this.saveSourceParseField(this, fieldObj);
      },
      // 字段聚焦时赋值操作
      async valuedByFoucs(options={}) {
        const { custom ={} , currentValue } = options;
        await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
      },

      /**
     *  目标托盘失焦事件
     */
    toCidBlur() {
      // code清空的同时清除id
      if(!this.toCidCode) {
        this.toCidId = null;
      }
    },
      /**
       * 目标库位enter事件
       **/
      handleLocationEnter() {
        this.$nextTick(()=>{
          if(this.showTargetTray) {
            this.skipNext(30);
          }else{
            if(this.isReplace) {
              this.skipNext(40);
            }else {
              this.skipNext('popupConfirmRef');
            }
          }
        })
      },
      handleClickCard(id){
        let isReplace = false;  // 判断id是否存在与数组中
        if(this.idList && this.idList.length>0){
          this.idList.forEach((v, index)=>{
            if(v === id){
              isReplace = true;
              this.idList.splice(index,1);
            }
          });
          if(!isReplace){
            this.idList.push(id);
          }
        }else {
          this.idList.push(id);
        }
        if(this.idList && this.idList.length>0 && this.idList.length === this.taskList.length){
          this.isAllChange='全选';
        }else {
          this.isAllChange=false;
        }
      },

      // 卡片点击事件
      /**
       * lov选中事件
       * @param {String} type - 定位lov
       * @param {object} obj - 选中行的数据
       */
      handleChoose(type, obj) {
        switch (type) {
          case 'type':
            this.queryTypeCode = obj.value;
            break;
          case 'cid':
            this.toCidId = obj.toCidId;
            break;
          case 'location':
            this.locationInfo = {
              ...obj,
              toLocationName: obj.toLocationName,
              toWhareaCode: obj.toWhareaCode,
              toWhareaName: obj.toWhareaName
            };
            this.toLocationId = obj.toLocationId;
            this.showTargetTray = obj.containerManageFlag !== 0 && obj.containerManageFlag !== null && obj.containerManageFlag !== '';
            this.locationReplace();
            break;
          case 'replace':
            this.unloadReplaceReason = obj.value;
            break;
          default:
            return null;
        }

      },
      // 获取上个页面传参
      async getDataFromParam() {
        this.fromIndex = false;
        const { loadCidCode, loadCidId, taskList, index, sourceParseField, customData, hideControl } = this.$route.params;
        if (loadCidCode) {
          this.loadCidCode = loadCidCode;
          this.loadCidId = loadCidId;
          this.taskList = taskList;
          this.selectedData = taskList;
          this.fromIndex = index;
          this.sourceParseField = sourceParseField;
          this.customData = customData;
          this.hideControl = hideControl;
        }
      },
      // 查询任务
      async queryTask(type) {
        try {
          const { loadCidCode, loadCidId } = this;
          this.$hint.showLoading();
          let params = {
            loadCidCode,
            loadCidId,
          };
          if(this.queryTypeCode===undefined){
            this.queryTypeCode = 'billCode'
          }
          params[this.queryTypeCode] = this.queryValue;
          this.taskList = await queryUnloadTask({ params });
          this.idList = [];
          for (const index in this.taskList) {
            this.taskList[index].selected = true;
            this.idList.push(this.taskList[index].id);
            if (type) {
              this.$refs[`num${index}`][0].className = 'font-skyblue';
            }
          }
          this.isAllChange = true;  // 默认全选
        } finally {
          this.$hint.hideLoading();
        }
      },



      /**
       * 点击数量进入录入序列号页面或显示拆分卸货弹窗
       * @param {object} index - 当前点击那一行的数据
       */
      modifiNum(index, item) {
        if(item.sourceType === 'INV'){
          this.currentIndex = index;
          if(item.serialType === 'INV_CTRL'){
            const param = {
              name: 'inputSerial',
              params: {
                fromUnLoading: true,
                showInputBox: false,
                task: this.taskList[index],
                currentIndex: index,
              },
            };
            this.$router.pushPage(param);
          }else {
            this.modifyFlag = true;
            const { qtyPlan, qtyScan } = this.taskList[index];
            this.qtyPlan = qtyPlan;
            this.qtyScan = qtyScan;
            this.modifyIndex = index;
          }
        } else {
          const site = [this.SERIAL_INV_CTRL, this.SERIAL_OUT_CTRL, this.SERIAL_SEMI_INV_CTRL];
          const enableSerial = site.includes(this.taskList[index].serialType);
          this.currentIndex = index;
          if(enableSerial) {
            const param = {
              name: 'inputSerial',
              params: {
                fromUnLoading: true,
                showInputBox: false,
                task: this.taskList[index],
                currentIndex: index,
              },
            };
            this.$router.pushPage(param);
          }else {
            this.modifyFlag = true;
            const { qtyPlan, qtyScan } = this.taskList[index];
            this.qtyPlan = qtyPlan;
            this.qtyScan = qtyScan;
            this.modifyIndex = index;
          }
        }
      },
      /**
       * 拆分卸货弹窗确认按钮
       */
      modifyConfirm() {
        const index = this.modifyIndex;
        if (this.qtyPlan < this.qtyScan) {
          this.$hint.showFailed(this.$i18n.t('message.limitDischargeQty'), true);
          return;
        }
        if(this.qtyScan <= 0) {
          this.$hint.showFailed(this.$i18n.t('message.limitScanQty'), true);
          return;
        }
        if (this.qtyScan !== this.qtyPlan) {
          this.$refs[`num${index}`][0].className = 'font-red';
          this.qtyModified = true;
        }else {
          this.$refs[`num${index}`][0].className = 'font-skyblue';
          this.qtyModified = false;
        }
        this.taskList[index].qtyScan = this.qtyScan;
        this.modifyFlag = false;
        this.skipNext(10);
      },
      /**
       * 拆分卸货弹窗取消按钮
       */
      modifyCancel() {
        this.modifyFlag = false;
        this.skipNext(10);
      },
      /**
       * 卸货确认弹窗确认事件
       */
      async unloadingConfirm() {
        try {
          const { toCidCode, toCidId, unloadReplaceReason, toLocationCode, toLocationId, selectedData } = this;
          this.$hint.showLoading();
          const param = {
            taskList: selectedData,
            toCidCode,
            toCidId,
            unloadReplaceReason,
            toLocationCode,
            toLocationId,
          };
          await confirmUnloading(param);
          await this.clearSourceFields(this, true);
          this.$hint.showSuccess(this.$i18n.t('message.unloadingSuccess'));
          this.$router.backPage();
        } finally {
          this.$hint.hideLoading();
        }
      },
      /**
       * 卸货确认弹窗取消按钮事件
       */
      async unloadingCancel() {
        try {
          this.$hint.showLoading();
          await cancelUnloading({});
          this.unloadingConfirmFlag = false;
        } finally {
          this.$hint.hideLoading();
        }
      },

      screenTasks() {
        this.selectedData = [];
        for ( let item of this.taskList) {
          this.idList.forEach((v)=>{
            if(v === item.id){
              if(item.selected) {
                this.selectedData.push(item);
              }
            }
          })
        }
      },
      /**
       * 提交卸货事件
       */
      async handleButtonSubmit() {
        this.screenTasks();
        try {
          const { selectedData, taskList  } = this;
          const allTaskList = taskList;  //获取表格所有初始化任务数据
          if (selectedData.length === 0) {
            this.$hint.showFailed(this.$i18n.t('message.chooseOneAtLeast'), true);
            return;
          }
          const { showUnloadConfirm, allowLocationReplace } = this.config;
          const onlyLocationFlag = this.haveOnlyLocation(selectedData);
          this.allowLocationReplace = allowLocationReplace;
          if (!onlyLocationFlag && allowLocationReplace === '0') {
            this.$hint.showFailed(this.$i18n.t('message.checkTask'), true);
            return;
          }
          this.$hint.showLoading();
          this.handleSubmitStatus();
          const data = {
            taskList: selectedData,
          };
          const showTaskList = await queryTaskList(data);
          if (showUnloadConfirm === '1') {
            const { loadCidCode, loadCidId ,locationInfo, defaultLocation, allowLocationReplace, qtyModified } = this;
            const { needReason } = this.config;
            const routerParam = {
              name: 'unloadingConfirm',
              params: {
                allTaskList,
                qtyModified,
                showTaskList,
                taskList: selectedData,
                loadCidCode,
                loadCidId,
                locationInfo,
                needReason,
                defaultLocation,
                allowLocationReplace,
                sourceParseField: {...this.sourceParseField},
                customData: this.customData,
                hideControl: this.hideControl,
              },
            };
            this.$router.pushPage(routerParam);
            this.qtyModified = false;
          } else {
            this.unloadingConfirmFlag = true;
            this.$nextTick( () => {
              if(allowLocationReplace==='1') {
                this.skipNext(20);
              }else if(this.showTargetTray) {
                this.skipNext(30);
              }else {
                this.skipNext(40);
              }
            })
          }
        } catch(e) {
          console.log('错误：' + e);
        } finally {
          this.$hint.hideLoading();
          this.handleSubmitStatus();
        }
      },
      /**
       * 页面初始化
       */
      async getConfig() {
        try {
          const { loadCidCode, loadCidId } = this;
          this.$hint.showLoading();
          const params = {
            loadCidCode,
            loadCidId,
          };
          this.config = await initPage({params});
        }finally {
          this.$hint.hideLoading();
        }
      },
      /**
       * 将数组中第一个对象设为目标库位
       * @param {object} dataList - 当前点击那一行的数据
       */
      haveOnlyLocation(dataList) {
        const firstLocation = dataList[0].toLocationCode;
        for (let item of dataList) {
          if (item.toLocationCode !== firstLocation) {
            this.toLocationCode = '';
            this.defaultLocation = null;
            this.locationInfo = {};
            this.toLocationId = '';
            return false;
          }
        }
        this.toLocationCode = firstLocation;
        this.defaultLocation = this.toLocationCode;
        this.locationInfo = dataList[0];
        this.toLocationId = this.locationInfo.toLocationId;
        return true;
      },
      locationReplace() {
        const { defaultLocation, toLocationCode } = this;
        const{ needReason } = this.config;
        if ((defaultLocation !== toLocationCode) && needReason==='1') {
          this.isReplace = true;
        }
      },
    },
    created() {
      this.tableTitle = [
        {
          name: this.$t('label.ownerCode'), // 货主
        },
        {
          name: this.$t('label.skuCode'), // 物料编码
        },
        {
          name: this.$t('label.quantity'), // 数量
        },
        {
          name: this.$t('label.unit'), // 单位
        },
        {
          name: this.$t('label.batch'), // 批次
        },
        {
          name: this.$t('label.targetLocation'), // 目标库位
        },
        {
          name: this.$t('label.deliveryCode'), // 单据编号
        }
      ];
      this.defaultType =  this.$t('label.deliveryCode');  // 单据编号
    },
    async mounted() {
      await this.getDataFromParam();
      this.inputFieldList = [
        { type: 'lov', required: false, ref: 0, bind: 'queryType', field: 'queryType' },
        { type: 'input', required: false, ref: 10, bind: 'queryValue', field: 'queryValue' },
        { type: 'lov', required: false, ref: 20, bind: 'toLocationCode', field: 'toLocationCode', isHide:!this.unloadingConfirmFlag },
        { type: 'lov', required: false, ref: 30, bind: 'toCidCode', field: 'toCidCode', isHide: !this.showTargetTray },
        { type: 'lov', required: false, ref: 40, bind: 'unloadReplaceReasonCode', field: 'unloadReplaceReasonCode', isHide:!this.isReplace },
      ];
      if (this.fromIndex) {
        this.isReplace = false;
        this.isSelectedALL = true;
        this.isAllChange = true;
        this.selectedData = [];
        for (const index in this.taskList) {
          this.$nextTick( () => {
            this.$refs[`num${index}`][0].className = 'font-skyblue';
          })
        }
      }
      this.urlData = this.handleData('DROP_DETAIL_QUERY');
      this.skipNext(10);
      this.getConfig();
    },
    activated() {
      // 从序列号明细页返回
      const index = this.currentIndex;
      const selectedSerialNum = JSON.parse(sessionStorage.getItem('totalSerialNum'));
      const serialNumberDtoList = JSON.parse(sessionStorage.getItem('serialNumberDtoList'));
      if(selectedSerialNum !== null) {
        this.taskList[index].qtyScan = selectedSerialNum;
        this.taskList[index].outboundSerialRecordList = serialNumberDtoList;
        if (this.taskList[index].qtyScan !== this.taskList[index].qtyPlan) {
          this.$refs[`num${index}`][0].className = 'font-red';
        }else {
          this.$refs[`num${index}`][0].className = 'font-skyblue';
        }
        sessionStorage.removeItem('totalSerialNum');
        sessionStorage.removeItem('serialNumberDtoList');
      }

      //部分卸货成功后返回此页面
      if(sessionStorage.getItem('refresh')) {
        sessionStorage.removeItem('refresh');
        this.queryTask(true);
        this.skipNext(10);
      }
    },
    beforeRouteLeave(to, from, next) {
      if (to.name === 'unloading') {
        this.$store.commit('deleteKeepAlives', 'UnloadingDetail');
        this.$store.commit('deleteKeepAlives', 'IpsSerial');
      }else if (to.name === 'inputSerial' || to.name === 'unloadingConfirm') {
        this.$store.commit('pushKeepAlives', 'UnloadingDetail');
      }
      this.$nextTick(() => {
        next();
      });
    }
  }
</script>

<style lang="stylus" scoped>
 @import '~@/style/var.styl'
  .ips-table {
    width 100%
    overflow-x auto
    table {
      width 100%
    }
    th, td {
      padding:5px 2px
      border 1px solid #ccc
      text-align center
      white-space nowrap
    }
  }
  .back-green {
    background-color #cbdeb8
  }
  .font-skyblue {
    margin-left: 5px;
    color: var(--primaryColor);
  }
  .font-red {
    color red
  }
  .modifyModel {
    width 70vw
    padding 0 10px
    h1 {
      font-size 18px
      text-align center
      margin 15px 0
    }
  }
  .ips-unloading-card-style{
    background: #fff;
    border-radius: 1.6vw;
    margin-top: 4.267vw;
    color: #000;
    font-size: 3.467vw;
    box-shadow: 0 0 2.133vw #d9d9d9;
    border 1px solid transparent;
    padding 12px
  }
  .coloumns-fieldname-left,.coloumns-fieldname-right{
    display inline-block;
    font-size:14px;
    font-weight:400;
    color:rgba(140,140,140,1);
    line-height:16px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .unloading-card-coloumns-center{
    display inline-block;
    margin-top -2px
  }
  .coloumns-fieldvalue{
    display inline-block;
    font-size:14px;
    color:rgba(0,0,0,0.85);
    line-height:16px;
    margin-left 5px;
  }

  .popup-column-right {
    display inline-block;
    width:80%;
    font-size:14px;
    font-weight:500;
    color:rgba(0,0,0,0.85);
    line-height:16px;
    margin-left 5px;
  }
  .card-active {
    background-color : #F2FBFF;
    border 1px solid #40A9FF;
  }
  .footer {
    position fixed
    bottom 0
    padding-top 10px
  }
  .container {
    padding-bottom 65px
  }
</style>
