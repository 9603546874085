var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "number_count_box",
      style: {
        border: "1px solid " + _vm.borderColor,
        background: _vm.backgroundColor,
      },
    },
    [
      _c("div", { staticClass: "context" }, [
        _c(
          "div",
          { staticClass: "number_count", style: { color: _vm.fontColor } },
          [_vm._v("\n      " + _vm._s(_vm.count) + "\n    ")]
        ),
        _c(
          "div",
          { staticClass: "number_title", style: { color: _vm.fontColor } },
          [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("label." + _vm.textField)) + "\n    "
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }