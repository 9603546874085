<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header
        @beforeBack="beforeBack"
        :is-index="false"
        :title="waveOrderItem.waveBillCode"
        :trigger="$t('button.skipTask')"
        :on-go-detail="handleSkipSubmit"
      />
    </div>
    <div class="ips-container">
      <!-- 物料编码 -->
      <!-- <ips-value-cell
        :title="$t('label.skuCode')"
        :value="taskInfo.sku || 0"
      /> -->
      <!-- 物料名称-->
      <!-- <ips-value-cell
        :title="$t('label.nameOfTheMaterial')"
        :value="taskInfo.goodsName || 0"
      /> -->
      <!-- 物料 -->
      <ips-value-cell :title="$t('label.sku')" :value="`${taskInfo.sku} | ${taskInfo.goodsName}` || 0" />
      <!-- 备注-->
      <ips-value-cell :title="$t('label.remark')" :value="taskInfo.remark || '无'" />
      <!-- 库区 -->
      <ips-value-cell :title="$t('label.whareaCode')" :value="taskInfo.whareaCode || '无'" />
      <!-- 库位 -->
      <ips-value-cell :title="$t('label.countLocation')" :value="taskInfo.locationCode || '无'" />
      <!-- 拣货库位 -->
      <ips-lov
        :label="$t('label.pickLocation')"
        :title="$t('label.pickLocation')"
        v-model="inputData.locationCode"
        v-show="customData['locationCode'] ? hideControl['locationCode'] : true"
        required
        params="locationCode"
        :url-params="{ whareaId: taskInfo.whareaId, goodsId: taskInfo.goodsId }"
        :url="`${wmdmBaseUrl}app-wave-picking/location-lov`"
        :pre-parse-url="urlData['locationCode']"
        :custom="customData['locationCode']"
        :source-parse-field="sourceParseField"
        :disabled="fieldLocationCodeDisabled"
        ref="ref10"
        @click.stop="handleCommonClick(10)"
        @clickIcon="handleCommonClick(10, 'lov')"
        @choose="handleChooseLocationCode($event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 物料 :pre-parse-params配置个性化参数-->
      <ips-field
        v-model="inputData.sku"
        :label="$t('label.sku')"
        ref="ref20"
        required
        params="sku"
        display="sku"
        :pre-parse-params="{ orgId: waveOrderItem.orgId, ownerId: waveOrderItem.ownerId }"
        @click.stop="handleCommonClick(20)"
        @down="handleScanSku"
        @enter="handleScanSku"
        @blur="handleScanSku"
        v-show="customData['sku'] ? hideControl['sku'] : true"
        :pre-parse-url="urlData['sku']"
        :custom="customData['sku']"
        :source-parse-field="sourceParseField"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 批次、序列号-->
      <ips-field
        v-model="inputData.code"
        v-if="fieldCodeIsShow"
        :label="fieldCodeLabel"
        params="code"
        display="code"
        :pre-parse-url="urlData['code']"
        :custom="customData['code']"
        ref="ref30"
        :required="fieldCodeRequired"
        @click.stop="handleCommonClick(30)"
        @down="handleBarCode"
        @enter="handleBarCode"
        @blur="handleBarCode"
      />
      <!-- 数量 -->
      <ips-input-number
        v-model="inputData.qty"
        required
        :label="$t('label.quantity')"
        :disabled="fieldQtyDisabled"
        :pre-parse-url="urlData['qty']"
        :custom="customData['qty']"
        ref="ref40"
        @enter="handleQuantity"
        @down="handleQuantity"
        @blur="handleQuantity"
        :min="null"
        @click.stop="handleCommonClick(40)"
        :more-than-zero="true"
        not-auto-fill
      />
      <div class="footer-btns">
        <!--确认拣货-->
        <hips-button
          size="large"
          ref="submitRef"
          :loading="submitFunLoading"
          @keyup.right.native="skipNext('nextRef')"
          @click.stop="submitFun"
        >
          {{ $t('button.confirmThePicking') }}
        </hips-button>
        <!--更换库位-->
        <hips-button
          size="large"
          ref="nextRef"
          class="change-location"
          @keyup.left.native="skipNext('submitRef')"
          @click="changeTheLocation"
        >
          {{ $t('button.changeTheLocation') }}
        </hips-button>
      </div>
      <div class="light-blue-box">
        <div class="line">
          <div class="left">
            需求数量：{{ taskInfo.qtyPlan }}
          </div>
          <div class="right">
            还需出库数量：{{ taskInfo.qtyPlan - totalQty }}
          </div>
        </div>
        <div class="line">
          <div class="left">
            现有量：{{ stockQty }}
          </div>
          <div class="right">
            扫描总数：{{ totalQty }}
          </div>
        </div>
      </div>
      <div style="display: flex">
        <hips-button size="large" class="ips-btn-normal" :loading="quantitySubmitLoading" @click="showSwitchTenantFun">
          {{ $t('message.scanTheDetail') }}
        </hips-button>
      </div>
    </div>
    <hips-popup v-model="showSwitchTenantPopup" @click-overlay="onTenantPopupClose" position="bottom">
      <div class="popup-title">
        <div class="left">
          <!-- {{ $t('button.clear') }} -->
        </div>
        <div class="center">
          {{ $t('message.scanTheDetail') }}
        </div>
        <div class="right" @click="onTenantPopupClose">
          {{ $t('button.closeBtn') }}
        </div>
      </div>
      <div class="ips-table" style="overflow-y: scroll">
        <table>
          <tr style="background-color: #eee">
            <th v-for="(item, index) in tableTitle" :key="index" scope="col">
              {{ item.name }}
            </th>
          </tr>
          <tr v-for="(item, index) in taskList" :key="index" :ref="`tr${index}`">
            <td>{{ item.sku }}</td>
            <td>{{ item.serialNumber }}</td>
            <td>{{ item.batchCode }}</td>
            <td>{{ item.qty }}</td>
            <td>
              <div class="delLineIcon" @click.stop="deleteTableData(item)">
                <svg-icon slot="icon" icon-class="delete" />
              </div>
            </td>
          </tr>
        </table>
      </div>
    </hips-popup>
  </hips-view>
</template>

<script>
import { storage } from '@/utils';
import {
  fetchProcessTask,
  clearTask,
  validateSku,
  validateCode,
  deleteValidateCode,
  queryScanDetail,
  querySummaryQty,
  validateQty,
  skipTask,
  clearCurrentScan,
  loadAndUnload,
} from '../api/index';
export default {
  name: 'TaskExecutionWave',
  data() {
    this.loadAndUnloadRes = undefined
    return {
      fieldLocationCodeDisabled: true,
      tableTitle: [],
      taskList: [],
      showSwitchTenantPopup: false,
      // 现有量
      stockQty: 0,
      // 数量是否可以使用
      fieldQtyDisabled: false,
      // 扫描总数
      totalQty: 0,
      // 条码字段显示标签
      fieldCodeLabel: '',
      // 条码字段知否显示
      fieldCodeIsShow: false,
      // 条码字段是否必输
      fieldCodeRequired: true,
      // 任务信息
      taskInfo: {},
      // 上个页面缓存
      waveOrderItem: {},
      // 请求路由信息
      queryInfo: {},
      // 界面上的输入框数据
      inputData: {
        // 拣货库区
        locationCode: '',
        // 物料
        sku: '',
        // 数量
        qty: '',
      },
      inputFieldList: [
        { type: 'lov', required: true, ref: 10, bind: 'inputData.locationCode', field: 'locationCode' },
        { type: 'input', required: true, ref: 20, bind: 'inputData.sku', field: 'sku' },
        { type: 'input', required: true, ref: 30, bind: 'inputData.code', field: 'code' },
        { type: 'input', required: true, ref: 40, bind: 'inputData.qty', field: 'qty' },
      ],
      menuId: storage.get('menuId'),
      urlData: {},
      customData: {}, // 个性化配置信息列表
      hideControl: {}, // 非必输字段的显隐控制
      sourceParseField: {}, // 界面中所有的来源解析字段

      quantitySubmitLoading: false,

      submitFunLoading: false,
    };
  },
  async created() {
    this.quantityPromise = undefined;
    this.scanSkuPromise = undefined;
    this.barCodePromise = undefined;

    const { hideControl, customObj } = this.handleCustomData(storage.get('customData') || []);
    this.customData = customObj || {};
    this.hideControl = hideControl || {};
    // console.log('按单收货customData', Object.assign({}, this.customData));
  },
  async mounted() {
    this.tableTitle = [
      {
        name: this.$t('label.skuCode'),
      },
      {
        name: this.$t('label.serialNumber'),
      },
      {
        name: this.$t('label.batchCode'),
      },
      {
        name: this.$t('label.shelvesQuantity'),
      },
      {
        name: this.$t('label.operation'), // 操作
      },
    ];
    this.waveOrderItem = storage.get('waveOrderItem');
    this.queryInfo = {...this.$route.query};
    let data = {
      params: {
        ...this.queryInfo,
      },
    };
    let res = await fetchProcessTask(data);
    // 修改 boundSortingPosFlag值
    Object.assign(this.queryInfo, { boundSortingPosFlag: res.boundSortingPosFlag })

    this.taskInfo = res;
    this.containerManageFlag = res.containerManageFlag;
    if (res.qtyPlan !== undefined) {
      this.inputData.qty = res.qtyPlan;
    }
    if (res.locationCode) {
      this.inputData.locationCode = res.locationCode;
      // this.skipNext(20);
    }
    this.skipNext(20);
    let res2 = await querySummaryQty({
      params: {
        scanLocationId: res.locationId,
        goodsId: res.goodsId,
      },
    });
    this.stockQty = res2.stockQty;
    this.urlData = this.handleData('WAVE_PICK_CONFIRM');
    // this.skipNext(10);
  },
  methods: {
    // 保存来源解析字段
    async saveSourceField(fieldObj) {
      this.saveSourceParseField(this, fieldObj);
    },
    // 字段聚焦时赋值操作
    async valuedByFoucs(options = {}) {
      const { custom = {}, currentValue } = options;
      await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
    },
    async beforeBack() {
      const { pickingCartId,
        waveBillId,
        waveLineType,
        boundSortingPosFlag} = this.queryInfo
      await clearTask({
        pickingCartId,
        waveBillId,
        waveLineType,
        boundSortingPosFlag
      });
      // eslint-disable-next-line eqeqeq
      this.backPage()
    },

    // 跳过任务
    async handleSkipSubmit() {
      try {
        this.$hint.showLoading();
        // 需要先清空数据
        this.inputData.locationCode = '';
        this.inputData.sku = '';
        this.inputData.qty = '';
        this.inputData.code = '';
        const res = await skipTask({
          tenantId: this.waveOrderItem.tenantId,
          orgId: this.waveOrderItem.orgId,
          ownerId: this.waveOrderItem.ownerId,
          waveBillId: this.waveOrderItem.waveBillId,
          waveBillCode: this.waveOrderItem.waveBillCode,
          allowExecuteQty: this.taskInfo.allowExecuteQty,
          whareaId: this.taskInfo.whareaId,
          locationId: this.taskInfo.locationId,
          locationCode: this.taskInfo.locationCode,
          qtyPlan: this.taskInfo.qtyPlan,
          scanLocationId: this.locationId || this.taskInfo.locationId,
          scanLocationCode: this.locationCode || this.taskInfo.locationCode,
          goodsId: this.taskInfo.goodsId,
          totalQty: this.totalQty,
          containerManageFlag: this.containerManageFlag,
          isbatch: this.isbatch,
          serialType: this.serialType,
          code: this.inputData.code,
        });
        this.$hint.showSuccess(this.$i18n.t('message.skipTaskSuccess'));
        this.taskInfo = res;
        if (res.locationCode) {
          this.inputData.locationCode = res.locationCode;
          this.skipNext(20);
        } else {
          this.backPage();
        }
        // 更新扫描总数
        let scanDetail = await queryScanDetail({});
        this.totalQty = scanDetail.length;
        let res2 = await querySummaryQty({
          params: {
            scanLocationId: res.locationId,
            goodsId: res.goodsId,
          },
        });
        this.stockQty = res2.stockQty;
      } catch (e) {
        console.log('错误: ' + e);
      } finally {
        this.$hint.hideLoading();
      }
    },
    // 查看扫描明细
    showSwitchTenantFun() {
      queryScanDetail({}).then((res) => {
        if (res.length > 0) {
          this.taskList = res;
          this.showSwitchTenantPopup = true;
        } else {
          console.log('no scan data');
        }
      });
    },

    onTenantPopupClose() {
      this.showSwitchTenantPopup = false;
      this.skipNext(20);
    },

    deleteTableData(record) {
      const onConfirm = () => {
        if (this.fieldCodeIsShow) {
          record.code = this.inputData.code;
        } else {
          record.code = this.inputData.sku;
        }
        deleteValidateCode(record).then(() => {
          const newTaskList = this.taskList.filter((item) => item.serialNumber !== record.serialNumber);
          this.taskList = newTaskList;
          this.totalQty -= record?.qty || 1;
        });
      };
      const onCancel = () => {
        //
      };
      this.$hint.showAlert(this.$i18n.t('message.confirmDeletion'), onConfirm, onCancel);
    },
    // 处理数量
    handleQuantity() {
      if (!this.inputData?.qty) {
        return;
      }

      if (!this.quantityPromise) {
        this.quantitySubmitLoading = true;
        this.quantityPromise = validateQty({
          tenantId: this.waveOrderItem.tenantId,
          waveBillId: this.waveOrderItem.waveBillId,
          orgId: this.waveOrderItem.orgId,
          ownerId: this.waveOrderItem.ownerId,
          allowExecuteQty: this.taskInfo.allowExecuteQty,
          whareaId: this.taskInfo.whareaId,
          locationId: this.taskInfo.locationId,
          locationCode: this.taskInfo.locationCode,
          scanLocationId: this.locationId || this.taskInfo.locationId,
          scanLocationCode: this.locationCode || this.taskInfo.locationCode,
          containerManageFlag: this.containerManageFlag,
          goodsId: this.taskInfo.goodsId,
          isbatch: this.isbatch,
          qtyPlan: this.taskInfo.qtyPlan,
          serialType: this.serialType,
          totalQty: this.totalQty,
          batchId: this.batchId,
          qty: this.inputData.qty,
          code: this.fieldCodeIsShow ? this.inputData.code : this.inputData.sku,
        })
          .then((res) => {
            this.totalQty = res.totalQty;
            this.inputData.qty = '';
            this.inputData.code = '';
            if (res.totalQty === this.taskInfo.qtyPlan) {
              this.skipNext('submitRef');
            } else {
              this.skipNext('30');
            }
          })
          .finally(() => {
            this.quantitySubmitLoading = false;
            this.quantityPromise = undefined;
          });
      }
    },
    // 条码
    handleBarCode() {
      if (!this.barCodePromise) {
        if (!this.inputData?.code) {
          if (
            !this.fieldCodeRequired &&
            this.containerManageFlag === 1 &&
            this.isbatch === 0 &&
            this.serialType !== 'INV_CTRL' &&
            this.serialType !== 'OUT_CTRL' &&
            this.serialType !== 'SEMI_INV_CTRL'
          ) {
            this.skipNext('40');
          }
          return;
        }
        this.barCodePromise = validateCode({
          tenantId: this.waveOrderItem.tenantId,
          orgId: this.waveOrderItem.orgId,
          ownerId: this.waveOrderItem.ownerId,
          waveBillId: this.waveOrderItem.waveBillId,
          waveBillCode: this.waveOrderItem.waveBillCode,
          allowExecuteQty: this.taskInfo.allowExecuteQty,
          whareaId: this.taskInfo.whareaId,
          locationId: this.taskInfo.locationId,
          locationCode: this.taskInfo.locationCode,
          qtyPlan: this.taskInfo.qtyPlan,
          scanLocationId: this.locationId || this.taskInfo.locationId,
          scanLocationCode: this.locationCode || this.taskInfo.locationCode,
          goodsId: this.taskInfo.goodsId,
          sku: this.taskInfo.sku,
          totalQty: this.totalQty,
          containerManageFlag: this.containerManageFlag,
          isbatch: this.isbatch,
          serialType: this.serialType,
          code: this.inputData.code,
        })
          .then((res) => {
            this.batchId = res.batchId;
            this.totalQty = res.totalQty;
            // 如果是批次
            if (res.scanType === 'BATCH') {
              this.skipNext('40');
            } else if (res.scanType === 'SERIAL' || res.scanType === 'LPN') {
              this.fieldQtyDisabled = true;
              this.inputData.code = '';
              if (res.qty || res.qty === 0) {
                this.inputData.qty = res.qty;
              }
              if (res.totalQty === this.taskInfo.qtyPlan) {
                this.skipNext('submitRef');
              }
            } else {
              this.skipNext('40');
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.barCodePromise = undefined;
          });
      }
    },
    // 更换库位
    async changeTheLocation() {
      try {
        this.$hint.showLoading();
        await clearCurrentScan({});
        this.inputData.locationCode = '';
        this.inputData.sku = '';
        this.inputData.code = '';
        this.inputData.qty = '';
        this.fieldLocationCodeDisabled = false;
        setTimeout(() => {
          this.skipNext(10);
        }, 0);
      } catch (e) {
        console.log('错误: ' + e);
      } finally {
        this.$hint.hideLoading();
      }
    },

    // 确认拣货
    async submitFun() {
      this.submitFunLoading = true
      try {
        const res = await loadAndUnload({
          tenantId: this.waveOrderItem.tenantId,
          orgId: this.waveOrderItem.orgId,
          ownerId: this.waveOrderItem.ownerId,
          waveBillId: this.waveOrderItem.waveBillId,
          waveBillCode: this.waveOrderItem.waveBillCode,
          pickingCartId: this.queryInfo.pickingCartId,
          boundSortingPosFlag: this.queryInfo.boundSortingPosFlag,
          billIds: this.queryInfo.billIds,
          waveLineType: this.queryInfo.waveLineType,
          allowExecuteQty: this.taskInfo.allowExecuteQty,
          whareaId: this.taskInfo.whareaId,
          locationId: this.taskInfo.locationId,
          locationCode: this.taskInfo.locationCode,
          qtyPlan: this.taskInfo.qtyPlan,
          scanLocationId: this.locationId || this.taskInfo.locationId,
          scanLocationCode: this.locationCode || this.taskInfo.locationCode,
          goodsId: this.taskInfo.goodsId,
          totalQty: this.totalQty,
          containerManageFlag: this.containerManageFlag,
          isbatch: this.isbatch,
          serialType: this.serialType,
          code: this.inputData.code,
        });
        // eslint-disable-next-line eqeqeq
        if (this.queryInfo.bindPickingCart == 1) {
          this.goPickingBoundSortPage(res);
        } else {
          this.processloadAndUnload(res);
        }
      } catch (error) {
        this.submitFunLoading = false;
      } finally {
        this.submitFunLoading = false;
      }
    },

    backPage() {
      if (this.queryInfo.bindPickingCart == 1) {
        this.$router.pushPage({
          name: 'DigitalPicking',
          params: { fromTaskPage: 1 }
        });
      } else {
        this.$router.pushPage({
          name: 'wavePicking',
          params: { fromTaskPage: 1 }
        });
        // this.$router.backPage();
      }
    },

    async processloadAndUnload(res) {
      this.$hint.showLoading();
      if (res && res.sku) {
        this.$hint.showSuccess(this.$i18n.t('message.operationSuccess'));
        this.inputData.locationCode = '';
        this.taskInfo = res;
        if (res.locationCode) {
          this.inputData.locationCode = res.locationCode;
          this.skipNext(20);
        }
        let res2 = await querySummaryQty({
          params: {
            scanLocationId: res.locationId,
            goodsId: res.goodsId,
          },
        });
        this.stockQty = res2.stockQty;
        this.totalQty = 0
        this.inputData.sku = '';
        this.inputData.code = '';
        this.inputData.qty = '';
        this.$hint.hideLoading();
      } else {
        this.$hint.showSuccess(this.$i18n.t('message.operationSuccess'));
        this.$hint.hideLoading();
        setTimeout(() => {
          // 跳到上一个页面
          this.$hint.hideLoading();
          this.backPage();
        }, 200);
      }
    },

    goPickingBoundSortPage(res) {
      this.loadAndUnloadRes = res;
      const { sortingPositionMap = {} } = res || {};
      const list = [];
      const sortKeys = Object.keys(sortingPositionMap).sort((a, b) => {
        const aIndex = Number(a.split('^')[0])
        const bIndex = Number(b.split('^')[0])
        return aIndex - bIndex
      })
      sortKeys.forEach((key) => {
        const element = sortingPositionMap[key];
        const code = key.split('^')[1]
          list.push({
            code,
            value: element || 0,
          });
      })
      // 跳转边拣边分详情页面
      this.$router.pushPage({name: 'PickingBoundSort', params: { taskPickingList: list}})
    },
    // 库位选择
    handleChooseLocationCode(obj) {
      this.containerManageFlag = obj.containerManageFlag;
      this.locationId = obj.locationId;
      this.skipNext('20');
    },
    // 处理扫描的物料
    handleScanSku() {
      if (!this.inputData?.sku) {
        return;
      }

      if (!this.scanSkuPromise) {
        // 检验是否和上面展示的物料一致
        if (this.inputData.sku !== this.taskInfo.sku) {
          this.$hint.showFailed(this.$i18n.t('message.checkSkuSame'), true);
          return;
        }

        this.scanSkuPromise = validateSku({
          tenantId: this.waveOrderItem.tenantId,
          orgId: this.waveOrderItem.orgId,
          ownerId: this.waveOrderItem.ownerId,
          sku: this.inputData.sku,
        })
          .then((res) => {
            this.serialType = res.serialType;
            this.isbatch = res.isbatch;
            this.fieldCodeRequired = true;
            if (res.serialType === 'INV_CTRL' || res.serialType === 'OUT_CTRL' || res.serialType === 'SEMI_INV_CTRL') {
              // 序列号
              this.fieldCodeIsShow = true;
              this.fieldQtyDisabled = true;
              if (this.containerManageFlag === 1) {
                this.inputData.qty = 1;
                this.fieldCodeLabel = this.$t('label.serialNumberOrBoxCode');
              } else {
                this.fieldCodeLabel = this.$t('label.serialNumber');
                this.inputData.qty = 1;
              }
              this.$nextTick(() => {
                this.skipNext(30);
              });
            } else if (res.isbatch === 1) {
              // 批次
              this.fieldCodeIsShow = true;
              this.fieldQtyDisabled = false;
              if (this.containerManageFlag === 1) {
                this.fieldCodeLabel = this.$t('label.lotOrBoxCode');
              } else {
                this.fieldCodeLabel = this.$t('label.batch');
              }
              this.$nextTick(() => {
                this.skipNext(30);
              });
            } else {
              // 不是批次也不是序列号
              this.fieldQtyDisabled = false;
              if (this.containerManageFlag === 1) {
                this.fieldCodeIsShow = true;
                this.fieldCodeRequired = false;
                this.fieldCodeLabel = this.$t('label.boxCode');
                this.$nextTick(() => {
                  this.skipNext(30);
                });
              } else {
                this.fieldCodeLabel = this.$t('label.barCode');
                this.$nextTick(() => {
                  this.skipNext(40);
                });
              }
            }
          })
          .finally(() => {
            this.scanSkuPromise = undefined;
          });
      }
    },
  },

  activated() {
    // eslint-disable-next-line eqeqeq
    if (this.queryInfo.bindPickingCart == 1) {
      const { params } = this.$route;
      if (params.action === 'submit') {
        this.processloadAndUnload(this.loadAndUnloadRes)
      }
    }
  },

  beforeRouteLeave(to, from, next) {
    // eslint-disable-next-line eqeqeq
    if (this.queryInfo.bindPickingCart == 1) {
      if (to.name === 'PickingBoundSort') {
        this.$store.commit('pushKeepAlives', 'TaskExecutionWave');
      } else {
        this.$store.commit('deleteKeepAlives', 'TaskExecutionWave');
      }
      setTimeout(() => {
        next();
      }, 0);
    } else {
      this.$store.commit('deleteKeepAlives', 'TaskExecutionWave');
      next()
    }
  },
};
</script>
<style lang="stylus" scoped>
@import '~@/style/var.styl';

.ips-btn-normal {
  flex: 1 !important;
  margin: 10px 0 !important;
}

.light-blue-box {
  padding: 10px;
  border-radius: 5px;
  background: #57B1ED;
  color: white;

  .line {
    display: flex;
    height: 25px;
    line-height: 25px;

    .left, .right {
      flex: 1;
    }
  }
}

.popup-title {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 0 20px;

  .left, .right {
    font-size: 12px;
    color: #999;
  }
}

.ips-table {
  margin-top: 16px;
  width: 100%;

  .ips-table-thead {
    width: 180px;
    height: 26px;
    background: rgba(255, 255, 255, 1);
    border-radius: 12px 0px 0px 0px;
    line-height: 26px;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    padding-left: 12px;
  }

  .delLineIcon {
    color: rgba(255, 64, 49, 1);
  }

  table {
    width: 100%;

    th, td {
      padding: 5px 2px;
      border: 1px solid #D9D9D9;
      text-align: center;
      white-space: nowrap;
      font-size: 14px;
      color: rgba(89, 89, 89, 1);

      &:first-child {
        width: 40px;
      }
    }

    .back-green {
      background-color: #cbdeb8;
    }
  }
}

.footer-btns {
  padding-top: 10px;
  background-color: $background-color-white;
  display: flex;
  width: 100%;

  button {
    flex: 1;
    border: none;
    border-radius: 2.133vw;
    margin: 0 2.667vw 3.2vw;
    font-size: 4.533vw;
    color: #FFF;
    background-color: #d9d9d9;
  }

  button:focus {
    background-color: var(--primaryColor) !important;
  }

  .change-location {
    background-color: #57B1ED !important;

    &:focus {
      background-color: #57B1ED !important;
    }
  }

  .svg-icon {
    margin-right: 5px;
    font-size: $fontsize-large;
  }
}
</style>
