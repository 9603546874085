<template>
  <hips-view>
    <ips-header :title="$t('title.ipsSerial')" :is-index="false" @beforeBack="saveVerify" />
    <div class="ips-container">
      <hips-row>
        <hips-col span="18">
          <ips-field
            v-model="serialNum"
            is-only
            :is-index="false"
            :label="$t('label.serialNumber')"
            ref="ref0"
            @click.stop="handleCommonClick(0)"
            @enter="checkSerialNum"
            @down="checkSerialNum"
          />
        </hips-col>
        <hips-col span="6">
          <hips-button size="large" @click.stop="checkSerialNum" ref="inputRef" class="input-button">{{
            $t('button.serialEntry')
          }}</hips-button>
        </hips-col>
      </hips-row>
      <div class="center_area">
        <div class="entryQty-box">
          {{ $t('label.alreadyEntry') }}: &nbsp;{{ totalSelectedNum }}
        </div>
      </div>
      <div class="ips-table">
        <table>
          <tr>
            <th class="ips-table-thead" v-for="(item, index) in tableTitle" :key="index" scope="col">
              {{ item.name }}
            </th>
          </tr>
          <tr v-for="(item, index) in tableData" :key="index">
            <td>{{ item.serialNumber }}</td>
            <td>{{ item.qualityStatusName }}</td>
            <td>{{ item.serialQty || item.qty }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="ips-footer" style="position: fixed; bottom: 0px">
      <hips-button class="ips-new-serial-button-clear-style" size="large" ref="checkRef" @click="clearAll">
        {{ $t('button.clear') }}
      </hips-button>
      <hips-button class="ips-new-serial-button-confirm-style" size="large" ref="submitRef" @click="handleButtonSubmit">
        {{ $t('button.confirm') }}
      </hips-button>
    </div>
  </hips-view>
</template>

<script>
import _ from 'lodash';
export default {
  name: 'StockInputSerial',
  data() {
    return {
      totalSelectedNum: 0, // 已录入序列号数
      serialNum: '',
      tableData: [],
      oldTableData: [],
      tableTitle: [],
      inputFieldList: [{ type: 'input', required: false, ref: 0, bind: 'serialNum' }],
    };
  },
  mounted() {
    this.tableTitle = [
      {
        name: this.$t('label.serialNumber'), // 序列号
      },
      {
        name: this.$t('label.qualityStatus'), // 质量状态
      },
      {
        name: this.$t('label.quantity'), // 数量
      },
    ];
    const { serialNumberList } = this.$route.params;
    if (serialNumberList && serialNumberList.length > 0) {
      this.tableData = [...serialNumberList];
      this.totalSelectedNum = serialNumberList.length;
      this.oldTableData = [...serialNumberList];
    }
    this.skipNext(0);
  },

  methods: {
    /**
     * 录入序列号
     */
    async checkSerialNum() {
      if (this.serialNum) {
        const index = this.tableData.findIndex((item) => {
          return item.serialNumber === this.serialNum;
        });
        const onConfirm = () => {
          const newTableData = this.tableData.filter((item) => item.serialNumber !== this.serialNum);
          this.tableData = newTableData;
          this.totalSelectedNum -= 1;
          this.serialNum = '';
        };
        const onCancel = () => {
          this.serialNum = '';
          this.skipNext(0);
        };
        // 若录入的序列号已存在 可进行删除操作
        if (index > -1) {
          this.$hint.showAlert(this.$i18n.t('message.deleteSerial'), onConfirm, onCancel);
        } else {
          try {
            this.$hint.showLoading();
            const url = `${this.winvBaseUrl}app-inv/serial-check`;
            const data = {
              params: {
                serialNumber: this.serialNum,
              },
            };
            const res = await this.$http.get(url, data);
            this.tableData.push(res);
            this.serialNum = '';
            this.totalSelectedNum++;
            this.skipNext(0);
          } catch {
            this.skipNext(0);
          } finally {
            this.$hint.hideLoading();
          }
        }
      }
    },

    handleButtonSubmit() {
      const serialNumberList = this.tableData;
      this.$storage.set('serialNumberList', JSON.stringify(serialNumberList));
      this.$router.backPage();
    },

    /**
     * 清楚所有
     */
    clearAll() {
      const onConfirm = () => {
        this.tableData = [];
        this.totalSelectedNum = 0;
        this.serialNum = '';
      };
      const onCancel = () => {
        this.serialNum = '';
      };
      this.$hint.showAlert(this.$i18n.t('message.allDeleteSerial'), onConfirm, onCancel);
    },

    async saveVerify() {
      // console.log('old', this.oldTableData);
      // console.log('new', this.tableData)
      if (!_.isEqual(this.oldTableData, this.tableData)) {
        const onConfirm = () => {
          this.$router.backPage();
        };
        const onCancel = () => {
          this.skipNext(0);
          return { isSuccess: false };
        };
        this.$hint.showAlert(this.$i18n.t('message.checkBackPath'), onConfirm, onCancel);
      } else {
        this.$router.backPage();
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
 @import '~@/style/var.styl'
.input-button {
  margin-left: 16px;
  background-color: var(--primaryColor);
  color: #FFFFFF;
  font-size: 14px;
  height: 36px;
  line-height: 36px;
}

.center_area {
  line-height: 36px;
  margin: 5px 0;

  .entryQty-box {
    width: 110px;
    line-height: 30px;
    background: rgba(230, 247, 255, 1);
    border-radius: 6px;
    border: 1px solid rgba(145, 213, 255, 1);
    text-align: center;
  }
}

.ips-table {
  width: 100%;

  .ips-table-thead {
    width: 180px;
    height: 26px;
    background: rgba(255, 255, 255, 1);
    border-radius: 12px 0px 0px 0px;
    line-height: 26px;
    text-align: left;
    font-weight: bold;
    font-size: 14px;
    padding-left: 12px;
  }

  table {
    width: 100%;

    th, td {
      padding: 5px 2px;
      border: 1px solid #ccc;
      text-align: center;
      white-space: nowrap;
      font-size: 14px;
      color: rgba(89, 89, 89, 1);
    }
  }
}

.ips-new-serial-button-clear-style{
  border-radius: 6px;
  border:2px solid rgba(255,64,49,1);
  width: 170px;
  font-size: 20px;
  box-sizing: border-box;
  color:rgba(255,64,49,1);
}
.ips-new-serial-button-confirm-style{
  margin-left: 16px;
  background-color: var(--primaryColor);
  color: #FFFFFF;
  font-size: 20px;
  border-radius: 6px;
}
</style>
