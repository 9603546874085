<template>
  <hips-view
    v-if="detailData"
    :header-fixed="true"
  >
    <!-- 头部信息 -->
    <div slot="header">
      <ips-header :title="$t('button.detailedShipping')" />
    </div>
    <div
      class="ips-container"
      style="padding-top: 2px;"
    >
      <div
        class="out_box"
        style="padding: 0; margin-top: 0;"
      >
        <!-- 出库单号 -->
        <ips-value-cell
          :title="$t('label.outboundOrderR')"
          image-flag
          :value="detailData.billCode || '无'"
        />
        <!-- 物料编码 -->
        <ips-value-cell
          :title="$t('label.skuCode')"
          image-flag
          :value="detailData.sku || '无'"
        />
        <!-- 货主 -->
        <ips-value-cell
          :title="$t('label.ownerCode')"
          image-flag
          :value="detailData.ownerName || '无'"
        />
        <!-- 来源仓库 -->
        <ips-value-cell
          :title="$t('label.fromWarehouse')"
          image-flag
          :value="detailData.ownerCode || '无'"
        />
        <!-- 来源库区 -->
        <ips-value-cell
          :title="$t('label.sourceWharea')"
          image-flag
          :value="detailData.fromWhareaCode || '无'"
        />
        <!-- 来源库位 -->
        <ips-value-cell
          :title="$t('label.sourceLocation')"
          image-flag
          :value="detailData.fromLocationCode || '无'"
        />
        <!-- 批次编码 -->
        <ips-value-cell
          :title="$t('label.batchCode')"
          image-flag
          :value="detailData.batchCode || '无'"
        />
        <!-- 序列号明细 -->
        <hips-button
          v-if="detailData.serialType === 'INV_CTRL' || detailData.serialType === 'OUT_CTRL' || detailData.serialType === 'SEMI_INV_CTRL'"
          size="large"
          @click.stop="goToSerialDetail"
          ref="refSerail"
          class="serial-button-detail serial-button-detail-pos"
        >
          {{ $t('button.seriesDetail') }}
        </hips-button>
      </div>
      <!-- 托盘 -->
      <ips-lov
        :label="$t('label.sourceTray')"
        :title="$t('label.sourceTray')"
        v-model="fromCidCode"
        v-show="detailData.containerManageFlag === 1"
        params="fromCidCode"
        :url="`${winvBaseUrl}app-outbound-ship/query-shippable-lpn`"
        :url-params="fromCidParam"
        :source-parse-field="sourceParseField"
        ref="ref0"
        @enter="skipNext(10)"
        @click.stop="handleCommonClick(0)"
        @clickIcon="handleCommonClick(0, 'lov')"
        @choose="handleChoose('fromCidCode', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 确认物料  confirmSku-->
      <ips-input
        v-model="confirmSku"
        ref="ref10"
        @enter="skipNext(20)"
        :label="$t('label.confirmSku')"
        type="text"
        label-position="left"
      />

      <!-- 批次确认 batchconfirmation -->
      <ips-input
        v-model="confirmBatchCode"
        ref="ref20"
        @enter="skipNext(30)"
        :label="$t('label.batchconfirmation')"
        type="text"
        label-position="left"
      />
      <div style="margin-top: 16px;">
        <hips-row>
          <hips-col span="14">
            <!-- 待处理量 -->
            <ips-input-number
              :label="$t('label.quantityToBeTreated')"
              v-model="planQty"
              required
              ref="ref30"
              not-auto-fill
              :min="null"
              :more-than-zero="true"
              @blur="checkNumBlur"
              @enter="skipNext('submitRef')"
              @down="skipNext('submitRef')"
              @tab="skipNext('submitRef')"
              @setSourceField="saveSourceField($event)"
              @valuedByFoucs="valuedByFoucs($event)"
            />
          </hips-col>
          <hips-col
            span="10"
            style="text-align: right;"
          >
            <!-- 序列号明细 -->
            <hips-button
              v-if="
                detailData.serialType === 'INV_CTRL' || detailData.serialType === 'OUT_CTRL' || detailData.serialType === 'SEMI_INV_CTRL'
              "
              size="large"
              @click.stop="goToSerialDetail2"
              ref="refSerail"
              class="serial-button-detail"
            >
              {{ $t('button.seriesDetail') }}({{ inboundSerialReceiptList.length }})
            </hips-button>
          </hips-col>
        </hips-row>
      </div>

      <div class="count_block_box">
        <!-- 现有量 -->
        <ips-number-count
          class="count-totalFreightVolume"
          :count="totalFreightVolume"
          text-field="totalFreightVolume"
        />
        <!-- 可用量 -->
        <ips-number-count
          class="count-processedCapacity"
          :count="processedCapacity"
          text-field="processedCapacity"
        />
        <!-- 已录量 -->
        <ips-number-count
          class="count-quantityToBeTreated"
          :count="quantityToBeTreated"
          text-field="quantityToBeTreated"
        />
      </div>
    </div>

    <div class="ips-footer">
      <!--撤销挑库-->
      <hips-button
        v-if="detailData.existNextFlag === 1"
        size="large"
        type="warning"
        @click="skipCurrentShippableDetailFun"
      >
        {{ $t('button.skipTask') }}
      </hips-button>
      <!--托盘详情-->
      <hips-button
        size="large"
        type="warning"
        ref="submitRef"
        class="ips-btn-normal"
        @click="handleCommonSubmit"
      >
        {{ $t('button.shipConfirm') }}
      </hips-button>
    </div>
  </hips-view>
</template>

<script>
import { fetchOneShippableDetail, batchDetailShipment, skipCurrentShippableDetail } from '../api';
import _ from 'lodash';
export default {
  name: 'ShipmentDetail',
  data() {
    return {
      detailData: null,
      confirmSku: '',
      confirmBatchCode: '',
      planQty: '',
      fromCidCode: '',
      sourceParseField: {},
      customData: {},
      fromCidParam: {}, // 来源托盘参数
      totalFreightVolume: 0,
      processedCapacity: 0,
      quantityToBeTreated: 0,
      inboundSerialReceiptList: [], // 选中的序列号
    };
  },
  activated() {
    const inboundSerialReceiptList = JSON.parse(sessionStorage.getItem('inboundSerialReceiptList'));
    this.inboundSerialReceiptList = inboundSerialReceiptList;
  },
  destroyed() {
    sessionStorage.removeItem('inboundSerialReceiptList');
  },
  mounted() {
    this.initPageData();
  },
  methods: {
    checkNumBlur() { },
    // 处理选择搜集数据
    handleChoose(type, obj) {
      console.log(111111111);
      console.log(type);
      console.log(obj)
      // 如果托盘有数字就去托盘的
      if (obj.planQty) {
        this.planQty = obj.planQty;
      }
      this.fromCidId = obj.fromCidId;
    },
    // 录入
    goToSerialDetail2() {
      // 检查的api
      let serialUrl = 'app-outbound-ship/check-serial';
      // 调用接口传入的参数
      let data = {
        billId: this.detailData.billId,
        billCode: this.detailData.billCode,
        ownerId: this.detailData.ownerId,
        ownerCode: this.detailData.ownerCode,
        ownerName: this.detailData.ownerName,
        warehouseId: this.detailData.warehouseId,
        warehouseCode: this.detailData.warehouseCode,
        fromWhareaId: this.detailData.fromWhareaId,
        fromWhareaCode: this.detailData.fromWhareaCode,
        fromLocationId: this.detailData.fromLocationId,
        fromLocationCode: this.detailData.fromLocationCode,
        goodsId: this.detailData.goodsId,
        sku: this.detailData.sku,
        goodsName: this.detailData.goodsName,
        serialType: this.detailData.serialType,
        batchId: this.detailData.batchId,
        batchCode: this.detailData.batchCode,
        serialNumber: this.detailData.serialNumber,
      };
      this.prepackagedData = this.shipSerialDtos;
      const param = {
        name: 'serialComponent',
        params: {
          serialUrl,
          data,
          task:
            this.inboundSerialReceiptList && this.inboundSerialReceiptList.length > 0
              ? this.inboundSerialReceiptList
              : this.prepackagedData[0]
                ? this.prepackagedData[0].inboundSerialReceiptList
                : [],
          detailId: this.detailId,

          pendingQty: this.pendingQty,

          receiveQty: this.planQty,
          serialType: this.detailData.serialType,
          detailIdTmpList: this.prepackagedData.length === 1,
          callback: function (serialNum) {
            const item = {
              serialNumber: serialNum,
              qualityStatusName: '0-合格',
              qualityStatus: '0-ACCEPTED',
              serialQty: 1,
            };
            this.tableData.push(item);
          },
        },
      };
      this.$router.pushPage(param);
    },
    // 跳转到序列号明细页面
    goToSerialDetail() {
      this.$storage.set('serialNumberDetailsData', this.shipSerialDtos);
      const param = {
        name: 'serialNumberDetails',
        query: {
          sku: this.confirmSku,
        },
      };
      this.$router.pushPage(param);
    },
    // 初始化页面数据
    initPageData() {
      let requestData = {
        params: {
          ...this.$route.query,
        },
      };
      // 获取明细
      fetchOneShippableDetail(requestData).then((res) => {
        console.log(res);
        this.detailData = res;
        this.confirmSku = res.sku;
        this.confirmBatchCode = res.batchCode;
        this.planQty = res.planQty;
        this.totalFreightVolume = res.billPickQty;
        this.processedCapacity = res.billShipQty;
        this.quantityToBeTreated = res.billPickQty - res.billShipQty;
        this.shipSerialDtos = res.shipSerialDtos;
        this.fromCidCode = res.fromCidCode;
        this.fromCidParam = {
          billId: res.billId,
          ownerId: res.ownerId,
          warehouseId: res.warehouseId,
          fromWhareaId: res.fromWhareaId,
          fromLocationId: res.fromLocationId,
          goodsId: res.goodsId,
          batchId: res.batchId,
        };
      });
    },
    // 跳过当前任务
    skipCurrentShippableDetailFun() {
      skipCurrentShippableDetail({
        ...this.$route.params,
        shipDetailIds: this.detailData.shipDetailIds,
      }).then((res) => {
        console.log(res);
        this.initPageData();
      });
    },
    // 点击发运
    async handleButtonSubmit() {
      // 只要有一个就可以批量发运
      if (this.detailData.waveBillId || this.detailData.billId) {
        try {
          this.$hint.showLoading();
          this.handleSubmitStatus();
          let parmasData = {
            billId: this.detailData.billId,
            billCode: this.detailData.billCode,
            ownerId: this.detailData.ownerId,
            ownerCode: this.detailData.ownerCode,
            warehouseId: this.detailData.warehouseId,
            warehouseCode: this.detailData.warehouseCode,
            fromWhareaId: this.detailData.fromWhareaId,
            fromWhareaCode: this.detailData.fromWhareaCode,
            fromLocationId: this.detailData.fromLocationId,
            fromLocationCode: this.detailData.fromLocationCode,
            fromCidId: this.fromCidId,
            fromCidCode: this.fromCidCode,
            goodsId: this.detailData.goodsId,
            sku: this.detailData.sku,
            serialType: this.detailData.serialType,
            batchId: this.detailData.batchId,
            batchCode: this.detailData.batchCode,
            planQty: this.detailData.planQty,
            confirmSku: this.confirmSku,
            confirmBatchCode: this.confirmBatchCode,
            processQty: this.planQty,
            confirmShipSerialDtos: _.map(this.inboundSerialReceiptList, 'serialNumber'), // 添加序列号
          };
          console.log(parmasData);
          let res = await batchDetailShipment(parmasData);
          console.log(res);
          if (res.billCode) {
            this.initPageData();
          } else {
            this.$router.backPage();
          }
        } catch (e) {
          console.log('错误: ' + e);
        } finally {
          this.$hint.hideLoading();
          this.handleSubmitStatus();
        }
      } else {
        this.$hips.toast({
          message: '批次单号或者是出库单号必须选择一项',
        });
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'serialComponent' || to.name === 'serialNumberDetails') {
      this.$store.commit('pushKeepAlives', 'ShipmentDetail');
    } else {
      this.$store.commit('deleteKeepAlives', 'ShipmentDetail');
    }
    setTimeout(() => {
      next();
    }, 0);
  },
};
</script>

<style lang="stylus" scoped>
 @import '~@/style/var.styl'
.ips-container {
  padding-top: 0;
}

.count-totalFreightVolume {
  background-color: #91D5FF;
  border-color: var(--primaryColor);
  color: var(--primaryColor);
}

.count-processedCapacity {
  background-color: #B7EB8F;
  border-color: #52C41A;
  color: #52C41A;
}

.count-quantityToBeTreated {
  background-color: #FFD382;
  border-color: #FF8F07;
  color: #FF8F07;
}

.out_box {
  border-radius: 1.6vw;
  margin-top: 4.267vw;
  width: 100%;
  padding: 2.133vw 0px;
  color: #000;
  font-size: 3.467vw;
  box-shadow: 0 0 2.133vw #d9d9d9;
  position: relative;

  .serial-button-detail-pos {
    position: absolute;
    bottom: 0;
    right: 10px;
  }
}
</style>
