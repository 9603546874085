import request from '@/request';
import mixin  from '@/mixin/mixin';

// 查询
export function queryStockDetail(data, queryURL) {
  let url;
  if(queryURL){
    url = queryURL;
  }else {
    url = `${mixin.data().wmdmBaseUrl}mdm-info/query-mdm-info`;
  }
  return request.get(url, data);
}

// // 查询URL
// export function queryPageURL(data) {
//   const url = `${mixin.data().winvBaseUrl}personal/query-field-info`;
//   return request.get(url, data);
// }

// 获取跳转参数
export function getStock(data) {
  const url = `${mixin.data().winvBaseUrl}stocks/query-page-param`;
  return request.get(url, data);
}

// 库区维度查询
export function queryWhareaSku(data) {
  const url = `${mixin.data().winvBaseUrl}stocks/query-stock-wharea`;
  return request.get(url, data);
}

// 库区明细查询
export function queryWhareaDetail(data) {
  const url = `${mixin.data().winvBaseUrl}stocks/query-stock-wharea-detail`;
  return request.get(url, data);
}

//直接跳转库区维度明细
export function queryWhareaDetailParam(data) {
  const url = `${mixin.data().winvBaseUrl}stocks/query-stock-wharea-detail-param`;
  return request.get(url, data);
}

// 库位维度查询
export function queryLocationSku(data) {
  const url = `${mixin.data().winvBaseUrl}stocks/query-stock-location`;
  return request.get(url, data);
}

// 库位明细查询
export function queryLocationDetail(data) {
  const url = `${mixin.data().winvBaseUrl}stocks/query-stock-location-detail`;
  return request.get(url, data);
}

//直接跳转库位维度明细
export function queryLocDetailParam(data) {
  const url = `${mixin.data().winvBaseUrl}stocks/query-stock-location-detail-param`;
  return request.get(url, data);
}

// 物料维度查询
export function querySkuWarehouse(data) {
  const url = `${mixin.data().winvBaseUrl}stocks/query-stock-goods`;
  return request.get(url, data);
}

// 物料明细查询
export function querySkuDetail(data) {
  const url = `${mixin.data().winvBaseUrl}stocks/query-stock-goods-detail`;
  return request.get(url, data);
}

//直接跳转物料维度明细
export function querySkuDetailParam(data) {
  const url = `${mixin.data().winvBaseUrl}stocks/query-stock-goods-detail-param`;
  return request.get(url, data);
}

// 批次维度查询
export function queryBatchWarehouse(data) {
  const url = `${mixin.data().winvBaseUrl}stocks/query-stock-batch`;
  return request.get(url, data);
}

// 批次维度查询
export function queryBatch(data) {
  const url = `${mixin.data().winvBaseUrl}stocks/query-stock-batch-code`;
  return request.get(url, data);
}

//直接跳转批次维度明细
export function queryBatchDetailParam(data) {
  const url = `${mixin.data().winvBaseUrl}stocks/query-stock-batch-detail-param`;
  return request.get(url, data);
}

// 批次明细查询
export function queryBatchDetail(data) {
  const url = `${mixin.data().winvBaseUrl}stocks/query-stock-batch-detail`;
  return request.get(url, data);
}

// 序列号明细查询
export function querySerialDetail(data) {
  const url = `${mixin.data().winvBaseUrl}stocks/query-stock-serial-detail`;
  return request.get(url, data);
}

// 托盘明细查询
export function queryContainerDetail(data) {
  const url = `${mixin.data().winvBaseUrl}stocks/query-stock-container-detail`;
  return request.get(url, data);
}


