<template>
  <div class="ips-input ips-input-common">
    <div :class="validateClass">
      <div
        :class="[
          'ips-input-wrapper',
          'ips-input-has-label',
          'ips-input-has-border',
          {
            'ips-input-has-value': nativeInputValue,
            'ips-input-focused': focused,
            'ips-has-append': $slots.append,
            'is-disabled': disabled,
            'disabled-background': disBackground,
            'is-required': required,
            'back-required': backRequired,
            'nolabel-required': nolabelRequired,
          },
        ]"
      >
        <input
          class="ips-input__inner"
          v-bind="$attrs"
          :type="type"
          :disabled="disabled"
          :disBackground="disBackground"
          :readonly="readonly"
          ref="input"
          @input="handleInput"
          @focus="handleFocus"
          @click="handleClick"
          @blur="handleBlur"
          @change="handleChange"
          @keyup.enter="handleEnter"
          @keydown.down="handleDown"
          @keydown.tab="handleTab"
        >
        <div class="ips-input-label-wrapper" v-if="label">
          <div class="ips-input-label">
            {{ label }}
          </div>
        </div>
        <div class="ips-input-group__append" v-if="$slots.append">
          <slot name="append" />
        </div>
        <div class="ips-error-suffix" v-show="validateClass === 'has-error'">
          <svg-icon icon-class="fail" />
          <div class="ips-toolTip">
            <div class="ips-toolTip__message">
              {{ errorMsg || $t('message.mustEnter') }}
            </div>
            <div class="popper__arrow" />
          </div>
        </div>
      </div>
    </div>
    <hips-popup
      v-model="showPreChekPop"
      class="pre-check-popup"
      @click-overlay="handleClickOverlay"
      :overlay-on-click-close="overlayOnClickClose"
    >
      <div class="ips-popup__title">
        <div class="title-item" v-for="(item, index) in [].concat(preParseTitle)" :key="index">
          {{ item }}
        </div>
      </div>
      <div class="ips-popup__content" ref="ips-parse-content" tabindex="-1">
        <hips-scroll ref="parseScroll" :scroll-events="['scroll']" :data="[preChekPopList]">
          <ul class="list-wrapper">
            <li
              v-for="(item, index) in preChekPopList"
              :key="index"
              class="scroll-item"
              ref="scrollItem"
              :class="['scroll-item', { active: index === activeListIndex }]"
              @click.stop="chooseParseOrder(item)"
            >
              <div
                :class="`content-item--${[].concat(preParams).length}`"
                v-for="(i, childIndex) in [].concat(preParams)"
                :key="childIndex"
              >
                {{ item[i] }}
              </div>
            </li>
          </ul>
        </hips-scroll>
      </div>
    </hips-popup>
  </div>
</template>
<script>
// 引入i18n
import i18n from '../../../i18n/vue-i18n';

export default {
  name: 'IpsField',
  componentName: 'IpsField',
  inheritAttrs: false,
  data() {
    return {
      focused: false,
      validateClass: 'has-normal',
      //preParams: ['mainValue', 'mainName'],
      activeListIndex: 0, // 默认是第一个为激活状态
      currentValue: '',
      showPreChekPop: false,
      popupDataList: [],
      preChekPopList: [],
      scrollY: -1, // y 滚动距离
    };
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    value: [String, Number],
    disabled: Boolean,
    disBackground: Boolean,
    required: Boolean,
    backRequired: Boolean,
    nolabelRequired: Boolean,
    readonly: Boolean,
    overlayOnClickClose: {
      // 以下都是pop的属性
      type: Boolean,
      default: true,
    },
    lov: Boolean,
    type: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      default: '',
    },
    // 预校验接口url - 校验输入（一物多码、多码拼接）
    preParseUrl: {
      type: String,
      default: '',
    },
    preParseParams: {
      type: Object,
      default: () => {},
    },
    preParseTitle: {
      // 与校验相关弹窗字段名称（展示)
      type: [String, Array],
      default: () => {
        const res = [i18n.t('title.code'), i18n.t('title.name')];
        return res;
      },
    },
    preParams: {
      type: Array,
      default: () => {
        const res = ['mainValue', 'mainName'];
        return res;
      },
    },
    // eslint-disable-next-line vue/require-default-prop
    params: [String, Array], // 绑定值（值）
    display: {
      type: String,
      default: '',
    },
    isError: {
      type: Boolean,
      default: false,
    },
    errorMsg: {
      type: String,
      // default: '必输'
      default: function () {
        return this.$t('message.mustEnter');
      },
    },
    // 是否已经被个性化过
    overed: {
      type: Boolean,
      default: false,
    },
    // 个性化配置信息
    custom: {
      type: Object,
      default: () => {},
    },
    // 所有的来源解析字段
    sourceParseField: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    // 如果不传display且params为一个时，那就取params为display
    getDisplay() {
      let params = [].concat(this.params);
      if (params.length === 1 && !this.display) {
        return params[0];
      }
      return this.display;
    },
    nativeInputValue() {
      return this.value === null || this.value === undefined ? '' : String(this.value);
    },
  },
  watch: {
    // native input value is set explicitly
    // do not use v-model / :value in template
    nativeInputValue(val) {
      if (this.required && val) {
        this.validateClass = 'has-success';
      }
      this.setNativeInputValue();
    },
    isError(val) {
      if (val) {
        this.validateClass = 'has-error';
      } else {
        this.validateClass = 'has-normal';
      }
    },
    showPreChekPop(val) {
      this.handleKeyDown(val, 'pre-parse');
      if (!val) {
        this.page = 0;
        this.$refs.parseScroll.scrollTo(0, 0);
      }
    },
    preChekPopList() {
      setTimeout(() => {
        this._calculateHeight();
      }, 20);
    },
  },
  mounted() {
    this.setNativeInputValue();
  },
  methods: {
    async focus() {
      const input = this.$refs.input;
      // const { fieldCode } = this.custom || {};
      // console.log('光标到', fieldCode);
      console.log('触发filed focus');

      input.focus();
      await this.$emit('valuedByFoucs', { custom: this.custom, currentValue: this.currentValue });
    },
    blur() {
      console.log('触发field blur');

      this.$refs.input.blur();
    },
    handleBlur(event) {
      this.focused = false;
      this.$emit('blur', event);
    },
    handleKeyDown(val, type) {
      if (!val) {
        this.activeListIndex = 0;
        this.scrollY = -1;
      }
      this.$nextTick(() => {
        let dom = this.$refs['ips-content'];
        this.actionFn = this._commonKeyDown.bind(this, type);
        if (type && type === 'pre-parse') {
          dom = this.$refs['ips-parse-content'];
        }
        if (val) {
          dom.focus();
          dom.addEventListener('keydown', this.actionFn);
        } else {
          dom.removeEventListener('keydown', this.actionFn);
          // this.activeListIndex = 0;
        }
      });
    },
    _commonKeyDown(type, e) {
      const popupData = this.preChekPopList;
      if (this.showPopup || this.showPreChekPop) {
        switch (e.keyCode) {
          case 9: // TAB
            e.preventDefault();
            // setTimeout(() => {
            //   this.chooseOrder(this.popupDataList[this.activeListIndex]);
            // }, 200)
            if (this.activeListIndex < popupData.length - 1) {
              this.activeListIndex++;
            }
            this.getSelfDistance('bottom', type);
            break;
          case 38: // 上
            e.preventDefault();
            this.getSelfDistance('top', type);
            if (this.activeListIndex > 0) {
              this.activeListIndex--;
            }
            break;
          case 40: // 下
            e.preventDefault();
            if (this.activeListIndex < popupData.length - 1) {
              this.activeListIndex++;
            }
            this.getSelfDistance('bottom', type);
            break;
          case 13: // 回车
            e.preventDefault();
            // 为解决enter事件有一段时间都会触发的情况
            setTimeout(() => {
              if (type && type === 'pre-parse') {
                this.chooseParseOrder(popupData[this.activeListIndex]);
              } else {
                this.chooseOrder(popupData[this.activeListIndex]);
              }
            }, 200);
            break;
          default:
            break;
        }
      }
    },
    handleClickOverlay() {
      this.focus();
      this.$refs['ips-parse-content'].removeEventListener('keydown', this.actionFn);
    },
    _calculateHeight() {
      this.listHeight = [];
      const list = this.$refs.scrollItem;
      let height = 0;
      this.listHeight.push(height);
      for (let i = 0; i < list.length; i++) {
        let item = list[i]; // 每个group
        height += item.clientHeight;
        this.listHeight.push(height);
      }
    },
    checkParseUrl(value) {
      // 校验是否存在preParseUrl与校验参数
      if (this.preParseUrl !== '' && value !== '' && value !== null) {
        this.preCheckData(value);
      } else {
        this.$emit('enter');
      }
    },
    handleDown(event) {
      let value = event.target.value.trim();
      if (this.required && this.$common.isEmpty(value)) {
        this.validateClass = 'has-error';
      }
      if (this.validateClass === 'has-error') {
        this.focus();
        return;
      }
      this.$emit('down');
    },
    handleEnter(event) {
      let value = event.target.value.trim();
      if (this.required && this.$common.isEmpty(value)) {
        this.validateClass = 'has-error';
      }
      if (this.validateClass === 'has-error') {
        this.focus();
        return;
      }
      const {
        resolveSourceFlag = false,
        fieldValueSourceType,
        fieldCode,
        resolveDelimiter,
        valueSourceIndex,
        subFields,
      } = this.custom || {};
      if (resolveSourceFlag && fieldValueSourceType === 'VALUE' && value && value.includes(resolveDelimiter)) {
        // 是来源解析字段
        const parseValue = value.split(resolveDelimiter);
        const valueNew = parseValue[Number(valueSourceIndex) - 1];
        value = valueNew;
        this.$emit('input', value);
        const fieldObj = { fieldCode, parseValue, subFields, value };
        this.$emit('setSourceField', fieldObj);
        this.$emit('enter');
        return;
      }
      this.checkParseUrl(value);
    },
    handleTab() {
      let value = event.target.value.trim();
      if ((!this.lov && this.required && value === '') || value === undefined || value === null) {
        this.validateClass = 'has-error';
      }
      if ((!this.lov && this.validateClass === 'has-error') || this.isError) {
        this.focus();
        return;
      }
      // 校验是否存在preParseUrl与校验参数
      if (this.preParseUrl !== '') {
        this.preCheckData(value);
      } else {
        this.$emit('tab');
      }
    },
    // 点击预校验popup弹出框中数据
    chooseParseOrder(item) {
      this.showPreChekPop = false;
      let value = item.mainValue;
      this.currentValue = value;
      this.$refs['ips-parse-content'].removeEventListener('keydown', this.actionFn);
      this.$emit('input', value);
      this.$emit('enter');
      this.$emit('tab');
    },
    // 键盘操作时判断距离
    getSelfDistance(type, kind) {
      const ipsContent = 'ips-parse-content';
      let parentDom = this.$refs[ipsContent];
      let activeDom = this.$refs.scrollItem[this.activeListIndex];
      const offsetTop = this.listHeight[this.activeListIndex];
      const listHeight = activeDom.clientHeight;
      const parentHeight = parentDom.offsetHeight;
      const num = Math.round(parentHeight / listHeight);
      const minusNum = this.activeListIndex - num;
      let firstIndex = minusNum <= 0 ? 0 : minusNum;
      let firstDom = this.$refs.scrollItem[firstIndex];
      const distance = Math.abs(offsetTop + this.scrollY);
      const scroll = 'parseScroll';

      if (!kind) {
        // 正常LOV框
        if (type === 'bottom' && Math.abs(parentHeight - distance) < listHeight * 2) {
          this.$refs[scroll].scrollToElement(activeDom, 500);
        }
        if (type === 'top' && distance <= listHeight && this.scrollY < -listHeight * 2) {
          this.$refs[scroll].scrollToElement(firstDom, 500);
        }
        const popupDataList = this.preChekPopList;
        if (type === 'bottom' && this.activeListIndex === popupDataList.length - 1) {
          this.loadMore();
        }
      } else {
        // 预校验Lov弹框
        if (type === 'bottom' && Math.abs(parentHeight - distance) < listHeight * 2) {
          this.$refs.parseScroll.scrollToElement(activeDom, 500);
        }
        if (type === 'top' && distance <= parentHeight) {
          this.$refs.parseScroll.scrollToElement(firstDom, 500);
        }
      }
    },
    async preCheckData(value) {
      const checkData = {
        // param: value,
        params: {
          [this.getDisplay]: value,
          ...this.preParseParams,
        },
      };
      try {
        const data = await this.$http.get(this.preParseUrl, checkData);

        if (data && data.length > 1) {
          this.showPreChekPop = true;
          this.preChekPopList = data;
        } else {
          const { mainValue } = data && data[0];
          if (mainValue) {
            // this.$refs.input.value = mainValue;
            this.$emit('input', mainValue);
            this.$emit('enter');
            this.$emit('tab');
          } else {
            this.$emit('enter');
            this.$emit('tab');
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.$hint.hideLoading();
      }
    },
    setNativeInputValue() {
      const input = this.$refs.input;
      if (!input) return;
      if (input.value === this.nativeInputValue) return;
      input.value = this.nativeInputValue;
    },
    handleFocus(event) {
      this.focused = true;
      // this.focus();
      this.$emit('focus', event);
    },
    handleClick(event) {
      this.$emit('click', event);
    },
    handleInput(event) {
      // not update v-model when composing
      if (event.target.composing) {
        return;
      }
      if (event.target.value === this.nativeInputValue) return;
      this.$emit('input', event.target.value.trim());
      // ensure native input value is controlled
      this.$nextTick(() => {
        if (this.required && !event.target.value) {
          this.validateClass = 'has-error';
        }
        // this.setNativeInputValue();
      });
    },
    handleChange(event) {
      this.$emit('change', event.target.value.trim());
    },
  },
};
</script>

<style lang="stylus">
 @import '~@/style/var.styl'
.pre-check-popup {
  height: auto;
  min-width: 70%;
}
.pre-check-popup .ips-popup_title {
  position: fixed;
  top: 0;
}
.pre-check-popup .ips-popup__content {
  height: auto;
  max-height: 64vw !important;
}
.pre-check-popup .ips-popup__content .hips-scroll {
  max-height: 64vw !important;
}
</style>
