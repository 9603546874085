import ExpressReceipts from '../views/express-receipts';

let route = [
  {
    path: '/express-receipts',
    name: 'ExpressReceipts',
    component: ExpressReceipts,
  },
];

export default route;
