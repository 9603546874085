<template>
  <hips-view :header-fixed="true" header-height="48">
    <div slot="header">
      <ips-header title="整托拣货" />
    </div>
    <!-- <ips-header title="整托拣货" /> -->
    <div class="ips-container">
      <!--为区分enter 与 点击元素框事件 用click代替focus，且其可以在最外部（例如 class为container的元素）绑定click事件-->
      <ips-field
        v-model="formData.billCode"
        label="测试"
        ref="ref0"
        autofocus
        @enter="skipNext(10)"
        @click.stop="handleCommonClick(0)"
      />
      <ips-field
        v-model="formData.cidCode"
        label="测111试"
        ref="ref10"
        @enter="skipNext(20)"
        @click.stop="handleCommonClick(10)"
      />
      <!-- 单据类型 -->
      <ips-value-cell :title="$t('label.billType')" image-flag :value="formData.billTypeName" />
      <!-- 供应商 -->
      <ips-value-cell :title="$t('label.vendor')" image-flag :value="formData.vendorName" />
      <!-- 客户 -->
      <ips-value-cell :title="$t('label.customer')" image-flag :value="formData.customerCode" />
      <ips-field
        v-model="formData.aaa"
        label="测222试"
        ref="ref20"
        @enter="skipNext(30)"
        @click.stop="handleCommonClick(20)"
      />
      <ips-field
        v-model="formData.bbb"
        label="测333试"
        ref="ref30"
        @enter="skipNext(40)"
        @click.stop="handleCommonClick(30)"
      />
      <ips-field
        v-model="formData.ccc"
        label="测444试"
        ref="ref40"
        @enter="skipNext(50)"
        @click.stop="handleCommonClick(40)"
      />
      <ips-field
        v-model="formData.ddd"
        label="测555试"
        ref="ref50"
        @enter="skipNext(60)"
        @click.stop="handleCommonClick(50)"
      />
      <ips-field
        v-model="formData.eee"
        label="测666试"
        ref="ref60"
        @enter="skipNext(70)"
        @click.stop="handleCommonClick(60)"
      />
      <ips-field
        v-model="formData.ggg"
        label="测777试"
        ref="ref70"
        @enter="skipNext(80)"
        @click.stop="handleCommonClick(70)"
      />
      <ips-field
        v-model="formData.ttt"
        label="测888试"
        ref="ref80"
        @enter="skipNext('submitRef')"
        @click.stop="handleCommonClick(80)"
      />
      <!-- <ips-input-number v-model="num1"
                        required
                        label="数字"
                        :precision="2"
                        :max="10000"
                        :min="10"
                        ref="ref20"
                        @blur="handleCommonBlur(2)"
                        @click.stop="handleCommonClick(20)"
                        @enter="skipNext(30)"
      />
      <ips-lov label="lov测试"
               v-model="lov1"
               :title="['仓库', '组织']"
               :params="['warehouseCode', 'locationCode']"
               display="billCode"
               :url="`${baseUrl}v1/1/inbound-receipt-records`"
               ref="ref30"
               @click.stop="handleCommonClick(30)"
               @enter="skipNext(40)"
               @blur="handleCommonBlur(3)"
               required
      />
      <ips-lov label="lov测试"
               v-model="lov2"
               :title="['仓库', '组织']"
               :params="['warehouseCode', 'locationCode']"
               display="billCode"
               :url="`${baseUrl}v1/1/inbound-receipt-records`"
               ref="ref40"
               @click.stop="handleCommonClick(40)"
               @enter="skipNext('submitRef')"
               @blur="handleCommonBlur(4)"
      />-->
    </div>
    <div class="ips-footer">
      <hips-button size="large" ref="submitRef" @click.stop="handleCommonSubmit">
        <svg-icon slot="icon" icon-class="success" />
        提交{{ error }}
      </hips-button>
    </div>
  </hips-view>
</template>

<script>
export default {
  name: 'Demo',
  data() {
    return {
      formData: {
        billCode: '',
        cidCode: '',
        locationCode: '',
        qtyPlan: null,
        toCidCode: '',
        toLocationCode: '',
      },
      error: false,
      num1: undefined,
      lov1: '',
      lov2: '',
      baseUrl: process.env.VUE_APP_WINV_URL,
      // inputFieldList: [
      //   {isLov: false, required: false, ref: 0, bind: 'formData.billCode'},
      //   {isLov: false, required: true, ref: 10 , bind: 'formData.cidCode'},
      //   {isLov: false, required: true, ref: 20,  bind: 'num1'},
      //   {isLov: true, required: true, ref: 30, bind: 'lov1'},
      //   {isLov: true, required: false, ref: 40 , bind: 'lov2'},
      // ],
      inputFieldList: [
        { type: 'input', required: true, ref: 0, bind: 'formData.billCode' },
        { type: 'input', required: true, ref: 10, bind: 'formData.billCode' },
        { type: 'input', required: true, ref: 20, bind: 'formData.billCode' },
        { type: 'input', required: true, ref: 30, bind: 'formData.billCode' },
        { type: 'input', required: true, ref: 40, bind: 'formData.billCode' },
        { type: 'input', required: true, ref: 50, bind: 'formData.billCode' },
        { type: 'input', required: true, ref: 60, bind: 'formData.billCode' },
        { type: 'input', required: true, ref: 70, bind: 'formData.billCode' },
        { type: 'input', required: true, ref: 80, bind: 'formData.billCode' },
        { type: 'input', required: true, ref: 90, bind: 'formData.billCode' },
        { type: 'input', required: true, ref: 100, bind: 'formData.billCode' },
      ],
    };
  },
  created() {
    window.addEventListener('resize', ()=>{
       if (document.activeElement.tagName === 'INPUT' || document.activeElement.tagName === 'TEXTAREA') {
        setTimeout(() => {
          document.activeElement.scrollIntoViewIfNeeded(true);
        }, 500);
      }
    });
  },
  methods: {
    scrollIntoView() {
      if (document.activeElement.tagName === 'INPUT' || document.activeElement.tagName === 'TEXTAREA') {
        setTimeout(() => {
          document.activeElement.scrollIntoViewIfNeeded(true);
        }, 500);
      }
    },
    handleButtonSubmit() {
      console.log('4444');
    },
    handleBack() {
      console.log('33333');
    },
  },
};
</script>

<style lang="stylus" scoped></style>

