<template>
  <div class="ips-lov ips-input-common">
    <ips-input
      ref="input"
      :value="currentValue"
      :disabled="disabled"
      :readonly="readonly"
      :required="required"
      :is-error="getErrorFlag"
      :error-msg="getErrorMsg"
      lov
      v-bind="$attrs"
      @blur="handleBlur"
      @focus="handleFocus"
      @click="handleClick"
      @input="handleInput"
      @change="handleInputChange"
      @enter="handleEnter"
      @down="handleDown"
      @tab="handleTab"
    >
      <div slot="append" @click.stop="handleIconClick">
        <hips-icon name="search" size="18px" />
      </div>
    </ips-input>
    <hips-popup
      v-model="showPopup"
      position="bottom"
      :overlay-on-click-close="overlayOnClickClose"
      :z-index="zIndex"
      :lock-scroll="lockScroll"
      :safe-area="safeArea"
      class="ips-popup"
      @click-overlay="handleClickOverlay"
    >
      <div class="ips-popup__title">
        <div class="title-item" v-for="(item, index) in getColumns.initList" :key="index">
          {{ item.title }}
        </div>
      </div>
      <div class="ips-popup__content" ref="ips-content" tabindex="-1">
        <hips-scroll
          ref="scroll"
          @scroll="scroll"
          :scroll-events="['scroll']"
          :data="[popupDataList]"
          :scroll-options="scrollOption"
          @pulling-down="loadRefresh"
          @pulling-up="loadMore"
        >
          <ul class="list-wrapper">
            <template v-for="(item, index) in popupDataList">
              <li
                :key="index"
                ref="scrollItem"
                :class="['scroll-item', { active: index === activeListIndex }]"
                @click.stop="chooseOrder(item)"
              >
                <div
                  :class="`content-item--${[].concat(getColumns.initList).length}`"
                  v-for="(col, colIndex) in getColumns.initList"
                  :key="colIndex"
                >
                  <a @click.stop="handleViewMoreInfo(item, col)" v-if="col.title === '详情'">{{ !item._show ? '更多' : '收起' }}</a>
                  <template v-else>
                    {{ item[col.dataIndex] }}
                  </template>
                </div>
              </li>
              <transition :key="index" name="fade">
                <div class="scroll-item__extra" v-if="item._show">
                  <ips-value-cell
                    v-for="(rest, rIndex) in getColumns.restList"
                    :key="rIndex"
                    :title="rest.title"
                    :value="item[rest.dataIndex]"
                  />
                </div>
              </transition>
            </template>
          </ul>
        </hips-scroll>
      </div>
    </hips-popup>

    <hips-popup
      v-model="showPreChekPop"
      class="pre-check-popup"
      @click-overlay="() => handleClickOverlay('pre-parse')"
      :overlay-on-click-close="overlayOnClickClose"
      :lock-scroll="lockScroll"
      :safe-area="safeArea"
    >
      <div class="ips-popup__title">
        <div class="title-item" v-for="(item, index) in [].concat(preParseTitle)" :key="index">
          {{ item }}
        </div>
      </div>
      <div class="ips-popup__content" ref="ips-parse-content" tabindex="-1">
        <hips-scroll ref="parseScroll" @scroll="scroll" :scroll-events="['scroll']" :data="[preChekPopList]">
          <ul class="list-wrapper">
            <li
              v-for="(item, index) in preChekPopList"
              :key="index"
              ref="scrollItem"
              :class="['scroll-item', { active: index === activeListIndex }]"
              @click.stop="chooseParseOrder(item)"
            >
              <div
                :class="`content-item--${[].concat(preParams).length}`"
                v-for="(i, childIndex) in [].concat(preParams)"
                :key="childIndex"
              >
                {{ item[i] }}
              </div>
            </li>
          </ul>
        </hips-scroll>
      </div>
    </hips-popup>
  </div>
</template>
<script>
import IpsInput from './Input/ipsInput';
import ipsValueCell from './ipsValueCell.vue';
import { Icon, Popup, Scroll } from '@hips/vue-ui';
// 引入i18n
import i18n from '../../i18n/vue-i18n';

export default {
  name: 'IpsLov',
  components: {
    IpsInput,
    ipsValueCell,
    [Icon.name]: Icon,
    [Popup.name]: Popup,
    [Scroll.name]: Scroll,
  },
  props: {
    value: {
      type: [Number, String],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: Boolean, // 必输
    readonly: Boolean, // 只读
    overlayOnClickClose: {
      // 以下都是pop的属性
      type: Boolean,
      default: true,
    },
    lockScroll: {
      type: Boolean,
      default: true,
    },
    safeArea: {
      type: Boolean,
      default: false,
    },
    probeType: {
      type: Number,
      default: 3,
    },
    // eslint-disable-next-line vue/require-default-prop
    zIndex: [Number, String],
    url: {
      type: String,
      default: null,
    }, // 接口地址
    urlParams: {
      type: Object,
      default: () => {},
    }, // 除绑定字段的其他url参数
    // eslint-disable-next-line vue/require-default-prop
    params: [String, Array], // 绑定值（值）
    // eslint-disable-next-line vue/require-default-prop
    title: [String, Array], // 名称（展示）
    // eslint-disable-next-line vue/require-default-prop
    maxColNum: [String, Number],
    preParseTitle: {
      // 与校验相关弹窗字段名称（展示)
      type: [String, Array],
      default: () => {
        const res = [i18n.t('title.code'), i18n.t('title.name')];
        // const res = ['code', 'name'];
        return res;
      },
    },
    display: {
      type: String,
      default: '',
    }, // 选中的值字段与外面的v-model相对应
    isPageContent: {
      // lov 返回的数据格式是否为分页的数据结构
      type: Boolean,
      default: true,
    },
    isError: {
      type: Boolean,
      default: false,
    }, // 外部传入的报错
    errorMsg: {
      type: String,
      default: '',
    }, // isError为true时，必须有这个
    isControl: Boolean, // 是否受控，选择数据之后属否需要额外的一些逻辑数据操作
    isOnly: Boolean, // 是否整个页面只有这一个输入框
    allowNoExist: {
      // 是否允许lov查询不出数据时，填写字段也可以存在
      type: Boolean,
      default: false,
    },
    // 预校验接口url - 校验输入（一物多码、多码拼接）
    preParseUrl: {
      type: String,
      default: '',
    },
    preParseParams: {
      type: Object,
      default: () => {},
    },
    // 69码转换弹框显示的字段名
    preParams: {
      type: Array,
      default: () => {
        const res = ['mainValue', 'mainName'];
        return res;
      },
    },
    // 是否已经被个性化过
    overed: {
      type: Boolean,
      default: false,
    },
    // 个性化配置信息
    custom: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: 'lov',
    },
    // 所有的来源解析字段
    sourceParseField: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.newCid++;
        // 为区分是输入的数据 还是 外部连带进来的数据，连带的数据validateLov 为true
        if (this.newCid !== this.cid && val) {
          this.validateLov = true;
          this.newCid = this.cid = 0;
        }
        this.currentValue = val;
        this.errorFlag = false;
        this.errorMessage = '';
      },
    },
    currentValue: {
      immediate: true,
      handler(value) {
        this.$emit('input', value);
      },
    },
    showPopup(val) {
      this.handleKeyDown(val);
      if (!val) {
        this.page = 0;
        this.$refs.scroll.scrollTo(0, 0);
      }
    },
    showPreChekPop(val) {
      this.handleKeyDown(val, 'pre-parse');
      if (!val) {
        this.page = 0;
        this.$refs.parseScroll.scrollTo(0, 0);
      }
    },
    popupDataList() {
      setTimeout(() => {
        this._calculateHeight();
      }, 20);
    },
    preChekPopList() {
      setTimeout(() => {
        this._calculateHeight();
      }, 20);
    },
  },
  computed: {
    // 如果不传display且params为一个时，那就取params为display
    getDisplay() {
      let params = [].concat(this.params);
      if (params.length === 1 && !this.display) {
        return params[0];
      }
      return this.display;
    },
    getErrorFlag() {
      return this.errorFlag || this.isError;
    },
    getErrorMsg() {
      return this.isError ? this.errorMsg : this.errorMessage;
    },

    getColumns(ctx) {
      const maxColNum = Number(ctx.maxColNum + '');
      const titles = [].concat(ctx.title);
      const params = [].concat(ctx.params);
      let initList = [];
      let restList = [];
      if (!Number.isNaN(maxColNum) && maxColNum) {
        const { show, hide } = (titles || []).reduce(
          (totalize, curr, index) => {
            if (maxColNum >= index + 1) {
              totalize.show.push({
                dataIndex: params[index],
                title: curr,
              });
            } else if (!totalize[maxColNum] && maxColNum === index) {
              totalize.show.push({
                // dataIndex: ctx.params[index],
                title: '详情',
              });

              totalize.hide.push({
                dataIndex: params[index],
                title: curr,
              });
            } else {
              totalize.hide.push({
                dataIndex: params[index],
                title: curr,
              });
            }

            return totalize;
          },
          { show: [], hide: [] }
        );
        initList = show;
        restList = hide;
      } else {
        initList = titles.map((item, i) => ({
          dataIndex: params[i],
          title: item,
        }));
      }
      return {
        initList,
        restList,
      };
    },
  },
  data() {
    return {
      showPopup: false,
      popupDataList: [],
      showPreChekPop: false,
      preChekPopList: [],
      // preParams: ['mainValue', 'mainName'],
      errorFlag: false, // 报错标志位
      errorMessage: '', // 报错信息
      currentValue: '',
      validateLov: false, // 确定lov是否是正确的标志
      activeListIndex: 0, // 默认是第一个为激活状态
      scrollY: -1, // y 滚动距离
      page: 0,
      size: 20,
    };
  },
  created() {
    // 无需data 初始化 变成响应式
    this.cid = 0;
    this.newCid = 0;
    this.listHeight = [];
    this.scrollOption = {
      pullDownRefresh: {
        threshold: 60, // 触发 pullingDown 的距离
        stop: 40, // pullingDown 正在刷新 hold 时的距离
        txt: this.$t('message.refreshSuccess'),
      },
      pullUpLoad: {
        txt: {
          // more: '上拉加载',
          more: this.$t('message.pullOnLoading'),
          // noMore: '暂无更多数据',
          noMore: this.$t('message.noMoreData'),
        },
      },
    };
    console.log(`${this.title}的custom配置信息为:${JSON.stringify(this.custom)}`);
  },
  mounted() {},
  methods: {
    async focus() {
      const input = this.$refs.input;
      const { fieldCode } = this.custom || {};
      console.log('光标到', fieldCode, this.$attrs.label);
      input.focus();
      this.custom && (await this.$emit('valuedByFoucs', { custom: this.custom, currentValue: this.currentValue }));
    },
    blur() {
      console.log('触发LOV blur');

      this.$refs.input.blur();
    },
    handleBlur(event) {
      this.$emit('blur', event);
    },
    async checkParseUrl(callback) {
      // 校验是否存在preParseUrl与校验参数
      if (this.preParseUrl !== '' && this.currentValue !== '' && this.currentValue !== null) {
        await this.preCheckData();
      } else {
        await this.getData();
      }
      if (callback) callback();
    },
    handleEnter() {
      console.log('触发lov enter 事件 外');
      const {
        resolveSourceFlag = false,
        fieldCode,
        resolveDelimiter,
        fieldValueSourceType,
        valueSourceIndex,
        subFields,
      } = this.custom || {};
      // 不必输并且没有值的情况下
      if (!this.required && (this.currentValue === '' || this.currentValue === null)) {
        this.$emit('enter');
      } else {
        if (resolveSourceFlag && fieldValueSourceType === 'VALUE' && this.currentValue && this.currentValue.includes(resolveDelimiter)) {
          // 是来源解析字段, 且字段值来源类型为二维码
          const parseValue = this.currentValue.split(resolveDelimiter);
          const value = parseValue[Number(valueSourceIndex) - 1];
          this.currentValue = value;
          this.$emit('input', this.currentValue);
          const fieldObj = { fieldCode, parseValue, subFields, value };
          // 来源解析字段自动enter添加getData回调函数
          const _this = this;
          const fn = () => {
            return new Promise(async (resolve) => {
              resolve(await this.checkParseUrl(() => (_this.overed = true)));
            });
          };
          fieldObj.callback = fn;
          this.$emit('setSourceField', fieldObj);
          return;
        }
        this.checkParseUrl();
      }
    },
    handleDown(event) {
      event.preventDefault();
      // 不必输并且没有值的情况下
      if (!this.required && (this.currentValue === '' || this.currentValue === null)) {
        this.$emit('down');
      } else {
        // 校验是否存在preParseUrl与校验参数
        if (this.preParseUrl !== '' && this.currentValue !== '' && this.currentValue !== null) {
          this.preCheckData();
        } else {
          this.getData();
          this.$emit('lovDown', event);
        }
      }
    },
    handleTab(event) {
      event.preventDefault();
      // 不必输并且没有值的情况下
      if (!this.required && (this.currentValue === '' || this.currentValue === null)) {
        this.$emit('tab');
      } else {
        // 校验是否存在preParseUrl与校验参数
        if (this.preParseUrl !== '' && this.currentValue !== '' && this.currentValue !== null) {
          this.preCheckData();
        } else {
          this.getData();
          this.$emit('lovTab', event);
        }
      }
    },
    handleFocus(event) {
      this.focused = true;
      this.$emit('focus', event);
    },
    handleClick(event) {
      this.$emit('click', event);
    },
    handleInputChange(value) {
      this.$emit('change', value);
    },
    handleInput(value) {
      this.validateLov = false;
      this.cid++;
      this.$emit('input', value);
    },
    handleIconClick() {
      // 需要光标控制，所以不能直接调用接口
      if (!this.disabled) {
        if (this.isOnly) {
          this.getData();
        }
        this.$emit('clickIcon');
      }
    },

    handleViewMoreInfo(record) {
      this.$set(record, '_show', !record._show);
    },
    clearValue() {
      this.currentValue = '';
    },
    handleKeyDown(val, type) {
      if (!val) {
        this.activeListIndex = 0;
        this.scrollY = -1;
      }
      this.$nextTick(() => {
        let dom = this.$refs['ips-content'];
        this.actionFn = this._commonKeyDown.bind(this, type);

        if (type && type === 'pre-parse') {
          dom = this.$refs['ips-parse-content'];
        }
        if (val) {
          dom.focus();
          dom.addEventListener('keydown', this.actionFn);
        } else {
          dom.removeEventListener('keydown', this.actionFn);
          // this.activeListIndex = 0;
        }
      });
    },
    _commonKeyDown(type, e) {
      const popupData = type && type === 'pre-parse' ? this.preChekPopList : this.popupDataList;
      if (this.showPopup || this.showPreChekPop) {
        switch (e.keyCode) {
          case 9: // TAB
            e.preventDefault();
            // setTimeout(() => {
            //   this.chooseOrder(this.popupDataList[this.activeListIndex]);
            // }, 200)
            if (this.activeListIndex < popupData.length - 1) {
              this.activeListIndex++;
            }
            this.getSelfDistance('bottom', type);
            break;
          case 38: // 上
            e.preventDefault();
            this.getSelfDistance('top', type);
            if (this.activeListIndex > 0) {
              this.activeListIndex--;
            }
            break;
          case 40: // 下
            e.preventDefault();
            if (this.activeListIndex < popupData.length - 1) {
              this.activeListIndex++;
            }
            // console.log('触发_commonKeyDown 下');
            this.getSelfDistance('bottom', type);
            break;
          case 13: // 回车
            e.preventDefault();
            // 为解决enter事件有一段时间都会触发的情况
            setTimeout(() => {
              if (type && type === 'pre-parse') {
                this.chooseParseOrder(popupData[this.activeListIndex]);
              } else {
                this.chooseOrder(popupData[this.activeListIndex]);
              }
            }, 200);
            break;
          default:
            break;
        }
      }
    },

    // 键盘操作时判断距离
    getSelfDistance(type, kind) {
      const ipsContent = kind && kind === 'pre-parse' ? 'ips-parse-content' : 'ips-content';
      let parentDom = this.$refs[ipsContent];
      let activeDom = this.$refs.scrollItem[this.activeListIndex];
      const offsetTop = this.listHeight[this.activeListIndex];
      const listHeight = activeDom.clientHeight;
      const parentHeight = parentDom.offsetHeight;
      const num = Math.round(parentHeight / listHeight);
      const minusNum = this.activeListIndex - num;
      let firstIndex = minusNum <= 0 ? 0 : minusNum;
      let firstDom = this.$refs.scrollItem[firstIndex];
      const distance = Math.abs(offsetTop + this.scrollY);
      // const scroll = kind && kind === 'pre-parse' ? 'parseScroll' : 'scroll';

      if (!kind) {
        // 正常LOV框
        if (type === 'bottom' && Math.abs(parentHeight - distance) < listHeight) {
          this.$refs['scroll'].scrollToElement(activeDom, 400);
        }
        if (type === 'top' && distance <= listHeight && this.scrollY < -listHeight) {
          this.$refs['scroll'].scrollToElement(firstDom, 400);
        }
        const popupDataList = kind && kind === 'pre-parse' ? this.preChekPopList : this.popupDataList;

        if (type === 'bottom' && this.activeListIndex === popupDataList.length - 1) {
          this.loadMore();
        }
      } else {
        // 预校验Lov弹框
        if (type === 'bottom' && Math.abs(parentHeight - distance) < listHeight) {
          this.$refs.parseScroll.scrollToElement(activeDom, 500);
        }
        if (type === 'top' && distance <= listHeight && this.scrollY < -listHeight) {
          this.$refs.parseScroll.scrollToElement(firstDom, 500);
        }
      }
    },
    scroll(pos) {
      this.scrollY = pos.y;
    },
    // 点击popup弹出框中数据
    async chooseOrder(item) {
      this.showPopup = false;
      let value = item[this.getDisplay];
      this.currentValue = value;
      this.$emit('input', value);
      await this.$emit('choose', item);
      this.$refs['ips-content'].removeEventListener('keydown', this.actionFn);
      if (!this.isControl) {
        this.validateLov = true;
        this.$emit('enter');
        // this.$emit('down');
        this.$emit('tab');
      }
    },
    // 点击预校验popup弹出框中数据
    chooseParseOrder(item) {
      this.showPreChekPop = false;
      let value = item.mainValue;
      this.currentValue = value;
      this.$refs['ips-parse-content'].removeEventListener('keydown', this.actionFn);
      this.getData(value);
    },

    handleClickOverlay(type) {
      this.focus();
      if (type && type === 'pre-parse') {
        this.$refs['ips-parse-content'].removeEventListener('keydown', this.actionFn);
      } else {
        this.$refs['ips-content'].removeEventListener('keydown', this.actionFn);
      }
    },

    _calculateHeight() {
      this.listHeight = [];
      const list = this.$refs.scrollItem;
      let height = 0;
      this.listHeight.push(height);
      for (let i = 0; i < list.length; i++) {
        let item = list[i]; // 每个group
        height += item.clientHeight;
        this.listHeight.push(height);
      }
    },

    // 接口测试数据
    async getEnterOrders(type, preCheckData) {
      const url = this.url;
      console.log('getEnterOrders OF  urlParams', this.urlParams);
      let data = {
        ...this.urlParams,
        size: this.size,
        page: this.page,
      };
      try {
        if (type === 'lov') this.$hint.showLoading();
        const dataList = await this.$http.get(url, {
          params: {
            ...data,
            [this.getDisplay]: preCheckData ? preCheckData : this.currentValue || data[this.getDisplay],
          },
        });
        this.$hint.hideLoading();
        return dataList;
      } catch (e) {
        console.log('LOV getEnterOrders 获取数据错误', e);
        this.$hint.hideLoading();
      } finally {
        this.$hint.hideLoading();
      }
    },

    async preCheckData() {
      const checkData = {
        // param: this.currentValue,
        params: {
          [this.getDisplay]: this.currentValue,
          ...this.urlParams,
          size: this.size,
          page: this.page,
        },
      };
      try {
        const data = await this.$http.get(this.preParseUrl, checkData);
        if (data && data.length > 1) {
          this.showPreChekPop = true;
          this.preChekPopList = data;
        } else {
          const { mainValue } = data && data[0];
          if (mainValue) {
            this.getData(mainValue);
          } else {
            this.errorFlag = true;
            this.errorMessage = this.$t('message.noData');
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.$hint.hideLoading();
      }
    },
    async getData(preCheckData) {
      if (this.showPopup) return;
      const data = await this.getEnterOrders('lov', preCheckData);
      const dataList = this.isPageContent ? data && data.content : data;
      if (!dataList) {
        this.errorFlag = true;
        this.errorMessage = this.$t('message.noData');
        this.validateLov = false;
        return;
      }
      if (Array.isArray(dataList) && dataList.length === 0) {
        if (this.allowNoExist && this.currentValue) {
          await this.$emit('choose', 'noExist');
          this.validateLov = true;
          this.$emit('enter');
          this.$emit('tab');
        } else {
          this.errorFlag = true;
          this.errorMessage = this.$t('message.noData');
        }
      } else if (dataList.length === 1) {
        this.currentValue = dataList[0][this.getDisplay];
        this.$emit('input', this.currentValue);
        this.$emit('choose', dataList[0]);

        if (!this.isControl) {
          this.validateLov = true;
          this.$emit('enter');
          this.$emit('tab');
        }
      } else if (dataList.length > 1) {
        this.showPopup = true;
        this.popupDataList = dataList;
      }
      return dataList;
    },
    async loadRefresh() {
      this.page = 0;
      const data = await this.getEnterOrders();
      this.popupDataList = this.isPageContent ? data && data.content : data;
    },
    async loadMore() {
      if (this.popupDataList.length < this.size) {
        this.$nextTick(() => {
          this.$refs.scroll.forceUpdate();
        });
        return;
      }
      this.page++;
      const data = await this.getEnterOrders();
      const dataList = this.isPageContent ? data && data.content : data;
      this.popupDataList.push(...dataList);
      if (dataList.length < this.size) {
        this.$nextTick(() => {
          this.$refs.scroll.forceUpdate();
        });
      }
    },
  },
};
</script>

<style lang="stylus">
@import '~@/style/var.styl';

.pre-check-popup {
  height: auto;
  min-width: 70%;
}

.pre-check-popup .ips-popup_title {
  position: fixed;
  top: 0;
}

.pre-check-popup .ips-popup__content {
  height: auto;
  max-height: 64vw !important;
}

.pre-check-popup .ips-popup__content .hips-scroll {
  max-height: 64vw !important;
}

.ips-popup__content .list-wrapper .scroll-item .content-item--3 {
  word-wrap: break-word !important;
}

.scroll-item__extra {
  padding-left 10%
}


.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
