var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: { title: _vm.$t("title.singleProcessing"), "is-index": true },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["billCode"]
                ? _vm.hideControl["billCode"]
                : true,
              expression:
                "customData['billCode'] ? hideControl['billCode']: true",
            },
          ],
          ref: "ref0",
          attrs: {
            label: _vm.$t("label.processingNum"),
            title: _vm.$t("label.processingNum"),
            required: "",
            params: "billCode",
            url:
              _vm.winvBaseUrl +
              "work-orders?statusList=PROCESSING&statusList=APPROVED",
            "pre-parse-url": _vm.urlData["billCode"],
            custom: _vm.customData["billCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            enter: function ($event) {
              return _vm.skipNext(10)
            },
            down: function ($event) {
              return _vm.skipNext(10)
            },
            choose: function ($event) {
              return _vm.handleChoose("billCode", $event)
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(0)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(0, "lov")
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.billCode,
            callback: function ($$v) {
              _vm.billCode = $$v
            },
            expression: "billCode",
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["sku"] ? _vm.hideControl["sku"] : true,
              expression: "customData['sku'] ? hideControl['sku']: true",
            },
          ],
          ref: "ref10",
          attrs: {
            label: _vm.$t("label.linenumSku"),
            title: [_vm.$t("label.lineNum"), _vm.$t("label.sku")],
            required: "",
            display: "sku",
            url: _vm.winvBaseUrl + "app-work-order/pda",
            "url-params": { billId: _vm.billId, workType: _vm.workType },
            "pre-parse-url": _vm.urlData["sku"],
            params: ["lineNum", "sku"],
            custom: _vm.customData["sku"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            enter: function ($event) {
              return _vm.skipNext("submitRef")
            },
            down: function ($event) {
              return _vm.skipNext("submitRef")
            },
            choose: function ($event) {
              return _vm.handleChoose("sku", $event)
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(10)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(10, "lov")
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.sku,
            callback: function ($$v) {
              _vm.sku = $$v
            },
            expression: "sku",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-footer" },
      [
        _c(
          "hips-button",
          {
            ref: "submitRef",
            attrs: { size: "large" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonSubmit.apply(null, arguments)
              },
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleCommonSubmit.apply(null, arguments)
              },
              keypress: function ($event) {
                $event.preventDefault()
              },
            },
          },
          [
            _c("svg-icon", {
              attrs: { slot: "icon", "icon-class": "success" },
              slot: "icon",
            }),
            _vm._v("\n      " + _vm._s(_vm.$t("button.confirm")) + "\n    "),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }