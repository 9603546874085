var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: { title: _vm.$t("title.trayDetail"), "is-index": false },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "ips-container" }, [
      _vm.prePackData.length > 0
        ? _c("div", [
            _c(
              "div",
              {
                ref: "ips-content",
                staticClass: "ips-card__content",
                attrs: { tabindex: "-1" },
              },
              [
                _c(
                  "hips-scroll",
                  {
                    ref: "scroll",
                    attrs: {
                      "scroll-options": _vm.scrollOption,
                      data: [_vm.prePackData],
                      "scroll-events": ["scroll"],
                    },
                    on: {
                      "pulling-down": _vm.loadRefresh,
                      "pulling-up": _vm.loadMore,
                      scroll: _vm.scroll,
                    },
                  },
                  [
                    _c(
                      "ul",
                      { staticClass: "list-wrapper" },
                      [
                        _vm._l(_vm.prePackData, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "card_countLocation" },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "title" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.$t("label.tray")) +
                                      ":\n                "
                                  ),
                                ]),
                                _c("div", { staticClass: "value" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(item.cidCode) +
                                      "\n                "
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "row-50" }, [
                                  _c("div", { staticClass: "title" }, [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(_vm.$t("label.sku")) +
                                        ":\n                  "
                                    ),
                                  ]),
                                  _c("div", { staticClass: "value" }, [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(item.sku) +
                                        "\n                  "
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "row-50" }, [
                                  _c("div", { staticClass: "title" }, [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(_vm.$t("label.batch")) +
                                        ":\n                  "
                                    ),
                                  ]),
                                  _c("div", { staticClass: "value" }, [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(item.batchCode) +
                                        "\n                  "
                                    ),
                                  ]),
                                ]),
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "row-50" }, [
                                  _c("div", { staticClass: "title" }, [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(_vm.$t("label.lineNum")) +
                                        ":\n                  "
                                    ),
                                  ]),
                                  _c("div", { staticClass: "value" }, [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(item.detailLineNum) +
                                        "\n                  "
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "row-50" }, [
                                  _c("div", { staticClass: "title" }, [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(_vm.$t("label.unit")) +
                                        ":\n                  "
                                    ),
                                  ]),
                                  _c("div", { staticClass: "value" }, [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(item.uomName) +
                                        "\n                  "
                                    ),
                                  ]),
                                ]),
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "title" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.$t("label.goodsName")) +
                                      ":\n                "
                                  ),
                                ]),
                                _c("div", { staticClass: "value" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(item.goodsName || "--") +
                                      "\n                "
                                  ),
                                ]),
                              ]),
                              ![
                                "INV_CTRL",
                                "IN_CTRL",
                                "SEMI_INV_CTRL",
                              ].includes(item.serialType)
                                ? _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "title" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.$t("label.quantity")) +
                                          ":\n                "
                                      ),
                                    ]),
                                    _c("div", { staticClass: "value" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(item.packQty || "--") +
                                          "\n                "
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              ["INV_CTRL", "IN_CTRL", "SEMI_INV_CTRL"].includes(
                                item.serialType
                              )
                                ? _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "row-50" }, [
                                      _c("div", { staticClass: "title" }, [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(_vm.$t("label.quantity")) +
                                            ":\n                  "
                                        ),
                                      ]),
                                      _c("div", { staticClass: "value" }, [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(item.packQty || "--") +
                                            "\n                  "
                                        ),
                                      ]),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "row-50" },
                                      [
                                        _c(
                                          "hips-button",
                                          {
                                            staticStyle: {
                                              "margin-left": "3px",
                                              color: "#FFFFFF",
                                              "font-size": "14px",
                                              height: "30px",
                                              "line-height": "30px",
                                              width: "150px",
                                            },
                                            attrs: {
                                              type: "warning",
                                              size: "large",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.goToSerialDetail(
                                                  item
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.$t("button.seriesDetail")
                                                ) +
                                                "\n                  "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          )
                        }),
                        _c(
                          "li",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.prePackData.length > 20,
                                expression: "prePackData.length > 20",
                              },
                            ],
                            staticClass: "scroll-item hips-thinline--bottom",
                            staticStyle: {
                              color: "var(--primaryColor)",
                              "text-align": "center",
                            },
                            on: { click: _vm.scrollToTop },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("button.backTop")) +
                                "\n            "
                            ),
                          ]
                        ),
                      ],
                      2
                    ),
                  ]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }