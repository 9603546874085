import inbound from '../views/inbound'
import DerectInboundReceive from '../views/inbound-receive'
import InboundDetail from '../views/inbound-detail'
import InboundCidDetail from '../views/inbound-cid-detail'
import ReceiveParentCidDetail from '../views/receive-parent-cid-detail'

let route = [{
    path: '/inbound',
    name: 'inbound',
    component: inbound,
  },
  {
    path:'/inbound-receive',
    name:'DerectInboundReceive',
    component: DerectInboundReceive,
  },
  {
    path:'/inbound-detail',
    name:'InboundDetail',
    component: InboundDetail,
  },
  {
    path:'/inbound-cid-detail',
    name:'InboundCidDetail',
    component: InboundCidDetail,
  },
  {
    path:'/receive-parent-cid-detail',
    name:'ReceiveParentCidDetail',
    component:ReceiveParentCidDetail,
  },
]

export default route