<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.trayDetailShow')" :is-index="false" />
    </div>
    <div class="ips-container">
      <div v-for="(item, index) in lineData" :key="index">
        <!-- 物料 -->
        <ips-value-cell :title="$t('label.sku')" :value="item.sku" />
        <!-- 品名 -->
        <ips-value-cell :title="$t('label.goodsName')" :value="item.goodsName" />
        <!-- 单位 -->
        <ips-value-cell :title="$t('label.unit')" :value="item.uomName" />
        <!-- 批次 -->
        <ips-value-cell :title="$t('label.batch')" :value="item.batchCode" />
        <!-- 现有量 -->
        <ips-value-cell :title="$t('label.availableQty')" :value="item.qty" />
        <!-- 可用量 -->
        <ips-value-cell :title="$t('label.useQty')" :value="item.validQty" />
      </div>
    </div>
  </hips-view>
</template>

<script>
  import {queryCidStockDetail} from '../api'
  export default {
  name: 'ContanierDetail',
  data() {
    return {
      lineData: [],
    };
  },
  methods: {
    getDataFromParams() {
      const { cidCode } = this.$route.params;
      this.cidCode = cidCode;
    },

    /**
    * getData 获取托盘信息数据
    */
    async getData() {
      try {
        this.$hint.showLoading();
        // let url = `${this.winvBaseUrl}app-inv/query-cid-stock-detail`;
        let data = {
          params: {
            cidCode: this.cidCode,
          },
        };
        // this.lineData = await this.$http.get(url, data);
        this.lineData = await queryCidStockDetail(data);
      }finally {
        this.$hint.hideLoading();
      }
    },
  },
  mounted() {
    this.getDataFromParams();
    this.getData();
  },
};
</script>

<style lang="stylus" scoped></style>
