import request from '@/request';
import mixin from '@/mixin/mixin';

// 获取快照数据
export function validateSourceSerial(data) {
  let url = `${mixin.data().winvBaseUrl}app-serial-change/validate-source-serial`;
  return request.post(url, data);
}

// 获取快照数据
export function validateTargetSerial(data) {
  let url = `${mixin.data().winvBaseUrl}app-serial-change/validate-target-serial`;
  return request.post(url, data);
}

// 获取快照数据
export function confirmSerialChange(data) {
  let url = `${mixin.data().winvBaseUrl}app-serial-change/confirm`;
  return request.post(url, data);
}
