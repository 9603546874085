import Vue from 'vue';
import Router from './router';
import publicRoutes from '../public/routes';

let components = [];
const requireUtils = require.context('../modules', true, /\/router\/index.js$/);
requireUtils.keys().forEach((path) => {
  const util = requireUtils(path);
  components.push(util);
});
let routes = [];
if (process.env.VUE_APP_TARGET === 'all') {
  const mainRoute = {
    path: '/home',
    name: 'home',
    component: require('../home/index').default,
    redirect: '/applications',
    children: [
      {
        path: '/applications',
        name: 'applications',
        component: require('../home/applications').default,
      },
      {
        path: '/statistics',
        name: 'statistics',
        component: require('../home/statistics').default,
      },
      {
        path: '/mine',
        name: 'mine',
        component: require('../home/mine').default,
      },
    ],
  };
  routes.push(mainRoute);
  components.forEach((item) => {
    routes.push(...item.default);
  });
} else {
  routes.push(...require(`@/modules/${process.env.VUE_APP_TARGET}/router`).default);
}
routes.push(...publicRoutes);

// ...

routes.unshift({
  path: '*',
  redirect: routes[0].path,
});

Vue.use(Router);
export default new Router({
  routes,
});
