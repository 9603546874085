<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <!-- 批次合并 -->
      <ips-header :title="$t('title.batchMarge')" :is-index="true" />
    </div>
    <div class="ips-container">
      <!-- 原始批次 -->
      <ips-lov
        :label="$t('label.sourceBatch')"
        :title="$t('label.batchCode')"
        v-model="fromBatchCode"
        v-show="customData['fromBatchCode'] ? hideControl['fromBatchCode']: true"
        required
        params="batchCode"
        :url="margeData.length > 0?`${wmdmBaseUrl}batchs/batch-modify-lov`:`${wmdmBaseUrl}batchs/batch-code-lov`"
        :url-params="{ 'isbatchMerge': 1, 'enabledFlag': 1, 'goodsId': disableGoodsId }"
        :custom="customData['fromBatchCode']"
        :source-parse-field="sourceParseField"
        ref="ref0"
        @enter="margeData.length > 0?skipNext(20):skipNext(10)"
        @down="margeData.length > 0?skipNext(20):skipNext(10)"
        @click.stop="handleCommonClick(0)"
        @clickIcon="handleCommonClick(0, 'lov')"
        @choose="handleChoose('fromBatchCode', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!--物料-->
      <ips-lov
        :label="$t('label.sku')"
        :title="$t('label.sku')"
        v-model="fromSku"
        v-show="customData['fromSku'] ? hideControl['fromSku']: true"
        required
        params="sku"
        :url="`${wmdmBaseUrl}batchs/batch-modify-lov`"
        :url-params="{ 'batchCode': fromBatchCode, 'isbatchMerge': 1 }"
        :disabled="margeData.length > 0"
        :custom="customData['fromSku']"
        :source-parse-field="sourceParseField"
        ref="ref10"
        @click.stop="handleCommonClick(10)"
        @clickIcon="handleCommonClick(10, 'lov')"
        @choose="handleChoose('fromSku', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 来源托盘 -->
      <ips-lov
        :label="$t('label.sourceTray')"
        :title="$t('label.sourceTray')"
        v-model="fromCidCode"
        v-show="customData['fromCidCode'] ? hideControl['fromCidCode']: true"
        params="cidCode"
        :url="`${winvBaseUrl}app-batch-modify/original-cid-lov`"
        :url-params="fromCidParam"
        :custom="customData['fromCidCode']"
        :source-parse-field="sourceParseField"
        ref="ref20"
        @click.stop="handleCommonClick(20)"
        @clickIcon="handleCommonClick(20, 'lov')"
        @enter="skipNext(30)"
        @down="skipNext(30)"
        @choose="handleChoose('fromCidCode', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 来源库位 -->
      <ips-lov
        :label="$t('label.sourceLocation')"
        :title="$t('label.sourceLocation')"
        v-model="fromLocationCode"
        v-show="customData['fromLocationCode'] ? hideControl['fromLocationCode']: true"
        required
        params="locationCode"
        :url="`${winvBaseUrl}app-batch-modify/original-location-lov`"
        :url-params="{
          'batchId': fromBatchId, 'goodsId': goodsId, 'cidId': fromCidId
        }"
        :custom="customData['fromLocationCode']"
        :source-parse-field="sourceParseField"
        ref="ref30"
        @click.stop="handleCommonClick(30)"
        @clickIcon="handleCommonClick(30, 'lov')"
        @enter="handleLocEnter"
        @down="handleLocEnter"
        @choose="handleChoose('fromLocationCode', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 来源库区 -->
      <ips-lov
        :label="$t('label.sourceWharea')"
        :title="$t('label.sourceWharea')"
        v-model="fromWhareaCode"
        v-show="customData['fromWhareaCode'] ? hideControl['fromWhareaCode']: true"
        required
        disabled
        :custom="customData['fromWhareaCode']"
        :source-parse-field="sourceParseField"
        ref="ref40"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />

      <!-- 转移数量 -->
      <ips-input-number
        :label="$t('label.amount')"
        v-model="transferQty"
        v-show="!enableSerial ? (customData['transferQty'] ? hideControl['transferQty'] : !enableSerial) : false"
        required
        :custom="customData['transferQty']"
        :source-parse-field="sourceParseField"
        ref="ref50"
        not-auto-fill
        :min="null"
        :enable-serial="serialType"
        :more-than-zero="true"
        @blur="checkNumBlur"
        @enter="margeData.length>0?skipNext('nextRef'):skipNext('submitRef')"
        @down="margeData.length>0?skipNext('nextRef'):skipNext('submitRef')"
        @tab="margeData.length>0?skipNext('nextRef'):skipNext('submitRef')"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <hips-row v-if="enableSerial">
        <div class="hip-row">
          <hips-col span="21">
            <ips-input-number
              :label="$t('label.amount')"
              v-model="transferQty"
              required
              disabled
              :enable-serial="serialType"
              :custom="customData['transferQty']"
              :source-parse-field="sourceParseField"
              ref="ref50"
              not-auto-fill
              :min="null"
              :more-than-zero="true"
              @enter="skipNext('refSerail')"
              @down="skipNext('refSerail')"
              @tab="skipNext('refSerail')"
              @setSourceField="saveSourceField($event)"
              @valuedByFoucs="valuedByFoucs($event)"
            />
          </hips-col>
          <hips-col span="8">
            <hips-button
              size="large"
              @click.stop="goToSerialDetail"
              @keyup.enter.native="goToSerialDetail"
              @keypress.native.prevent
              ref="refSerail"
              class="button-detail"
            >
              {{ $t('button.serialNumber') }}
            </hips-button>
          </hips-col>
        </div>
      </hips-row>

      <div class="count_block_box">
        <!-- 现有量 -->
        <ips-number-count
          class="count-availableQty"
          :count="availableQty"
          :required="!enableSerial"
          :disabled="enableSerial"
          text-field="availableQty"
        />
        <!-- 可用量 -->
        <ips-number-count
          class="count-useQty"
          :count="useQty"
          text-field="useQty"
        />
        <!-- 已录量 -->
        <ips-number-count
          class="count-enteredQty"
          :count="enteredQty"
          text-field="enteredQty"
        />
      </div>
    </div>
    <div class="footer-button" v-if="enteredQty > 0">
      <div class="footer-button-box">
        <hips-button
          class="next"
          size="large"
          @click.stop="handleNext"
          @keyup.enter.native="handleNext"
          @keypress.native.prevent
          ref="nextRef"
          :disabled="isDisabled"
        >{{ $t('button.next') }}
        </hips-button>
        <hips-button
          class="importedDetail"
          size="large"
          v-show="customData['importedDetail'] ? hideControl['importedDetail']: true"
          @click.stop="handleImportedDetail"
          @keyup.enter.native="handleImportedDetail"
          @keypress.native.prevent
        >{{ $t('button.importedDetail') }}
        </hips-button>
      </div>
    </div>
    <div class="ips-footer">
      <hips-button
        size="large"
        v-if="!enteredQty"
        @click.stop="handleNext"
        @keyup.enter.native="handleNext"
        @keypress.native.prevent
        ref="submitRef"
        :disabled="isDisabled"
      >
        {{ $t('button.next') }}
      </hips-button>
      <hips-button
        size="large"
        v-if="enteredQty > 0"
        @click.stop="submit"
        @keyup.enter.native="submit"
        @keypress.native.prevent
        ref="submitRef"
        :disabled="isDisabled"
      >
        {{ $t('button.marge') }}
      </hips-button>
    </div>
  </hips-view>
</template>

<script>
  import {Col, Row} from '@hips/vue-ui';
  import { checkBatch } from '../api';
  import uuidv4 from 'uuid/v4'
  export default {
    name: 'BatchMarge',
    components: {
      [Col.name]: Col,
      [Row.name]: Row,
    },
    data() {
      return {
        fromSerialFlag: false, // 从序列号组件跳转过来
        fromDetailFlag: false,
        enableSerial: '',
        serialType: '',
        fromBatchId: '',
        fromBatchCode: '',
        goodsId: '',
        fromSku: '',
        disableSku: '',
        disableGoodsId: '',
        disableSerialType: '',
        skuInfo: {}, // 批次数据
        fromCidId: '',
        fromCidCode: '',
        fromLocationId: '',
        fromLocationCode: '',
        fromWhareaId: '',
        fromWhareaCode: '',
        warehouseId: '', // 仓库id
        fromCidParam: {}, // 来源托盘参数
        fromLocParam: {}, // 来源托盘参数
        transferQty: '', // 转换数量
        availableQty: 0, // 现有量
        useQty: 0, // 可用量
        enteredQty: 0, // 保留量
        margeQty: 0, // 保留量
        serialNumberList: [], // 序列号数据
        margeData: [], // 合并数据
        isDisabled: false,
        isMargeDetailFrom: false,
        isDelete: false,
        isEdit: false,
        isChangeCid: false,
        // urlData:{},
        customData: {}, // 个性化配置信息列表
        hideControl: {}, // 个性化配置显示隐藏控制
        sourceParseField: {}, // 界面中所有的来源解析字段
      };
    },
    methods: {
      /**
       *@functionName: saveSourceField
       *@description: 保存来源解析字段并赋值Enter
       *@params1: fieldObj[Object] 来源解析字段对象,
       */
      saveSourceField(fieldObj) {
        this.saveSourceParseField(this, fieldObj);
      },
      // 字段聚焦时赋值操作
      async valuedByFoucs(options={}) {
        const { custom ={} , currentValue } = options;
        await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
      },
      /**
       * handleChoose lov选中触发
       * @param type
       * @param obj 选中对象
       */
      handleChoose(type, obj) {
        const site = [this.SERIAL_INV_CTRL, this.SERIAL_SEMI_INV_CTRL];
        switch (type) {
          case 'fromBatchCode':
            this.fromBatchCode = obj.batchCode;
            if(this.margeData.length>0){
              this.fromBatchId = obj.id;
              this.fromCidParam = {
                batchId: obj.id,
                goodsId: this.disableGoodsId
              };
              this.fromLocParam = {
                batchId: obj.id,
                cidId: this.fromCidId,
              };
            }
            break;
          case 'fromSku':
            // 先清空
            this.fromBatchId = '';
            this.serialType = '';
            this.fromSku = '';
            this.goodsId = '';
            this.fromCidId = '';
            this.fromCidCode = '';
            this.fromLocationId = '';
            this.fromLocationCode = '';
            this.fromWhareaId = '';
            this.fromWhareaCode = '';
            this.warehouseId = '';
            this.fromCidParam = {};
            this.fromLocParam = {};
            this.serialNumberList = [];
            this.$storage.set('serialNumberList', []);
            this.transferQty = '';
            this.availableQty = 0; // 现有量
            this.useQty = 0; // 可用量
            this.enteredQty = 0; // 保留量
            // 再赋值
            this.skuInfo = obj;
            this.fromBatchId = obj.id;
            this.fromSku = obj.sku;
            this.goodsId = obj.goodsId;
            this.disableSku = obj.sku;
            this.disableGoodsId = obj.goodsId;
            this.disableSerialType = obj.serialType;
            this.serialType = obj.serialType;
            this.enableSerial = site.includes(obj.serialType);
            this.fromCidParam = {
              batchId: this.fromBatchId,
              goodsId: obj.goodsId
            };
            this.fromLocParam = {
              batchId: this.fromBatchId,
              goodsId: this.goodsId,
              cidId: this.fromCidId,
            };
            this.handleSkuBlur();
            break;
          case 'fromCidCode':
            this.fromCidId = obj.cidId;
            this.fromCidCode = obj.cidCode;
            this.fromLocationId = obj.locationId;
            this.fromLocationCode = obj.locationCode;
            this.fromWhareaId = obj.whareaId;
            this.fromWhareaCode = obj.whareaCode;
            this.warehouseId = obj.warehouseId;
            this.availableQty = obj.qty; // 现有量
            this.useQty = obj.validQty; // 可用量
            this.enteredQty = this.margeData.length>0?this.margeQty:0; // 可用量
            this.isChangeCid = true;
            this.fromLocParam = {
              batchId: this.fromBatchId,
              goodsId: this.goodsId,
              cidId: obj.cidId
            };
            break;
          case 'fromLocationCode':
            this.fromLocationId = obj.locationId;
            this.fromLocationCode = obj.locationCode;
            this.fromWhareaId = obj.whareaId;
            this.fromWhareaCode = obj.whareaCode;
            this.warehouseId = obj.warehouseId;
            this.availableQty = obj.qty; // 现有量
            this.useQty = obj.validQty; // 可用量
            this.enteredQty = this.margeData.length>0?this.margeQty:0; // 可用量

            break;
          default:
            return null;
        }
      },
      /**
       * 下一个
       */
      // 下一步
      async handleNext(){
        if(this.transferQty < 0 || this.transferQty > this.useQty){
          return;
        }
        if(!this.fromBatchId || !this.fromLocationId || !this.transferQty){
          this.$hint.showFailed(this.$i18n.t('message.checkRequired'), true);
          return
        }
        if(this.enteredQty + this.transferQty > this.demandQty){
          this.$hint.showFailed(this.$i18n.t('message.qtyMustBeSmallerThanValidFromQty'), true);
          return
        }
        if(this.enteredQty + this.transferQty === this.demandQty){
          this.$hint.showFailed(this.$i18n.t('message.FromQtyHaveBeenEntered'), true);
          return
        }

        let data = {
          goodsId: this.goodsId,
          fromSku: this.fromSku,
          fromBatchId: this.fromBatchId,
          fromBatchCode: this.fromBatchCode,
          fromCidId: this.fromCidId,
          fromCidCode: this.fromCidCode,
          fromWhareaId: this.fromWhareaId,
          fromWhareaCode: this.fromWhareaCode,
          fromLocationId: this.fromLocationId,
          fromLocationCode: this.fromLocationCode,
          warehouseId: this.warehouseId,
          qty: this.transferQty,
          availableQty: this.availableQty,
          useQty: this.useQty,
          serialStockProcessDtoList: this.serialNumberList,
          uuid: uuidv4(),
        };
        if(this.editFlag){
          this.margeData = this.margeData.map((item) => item.uuid === this.editData.uuid ? data : item);
          this.margeQty = this.enteredQty + this.transferQty;
          this.enteredQty = this.enteredQty + this.transferQty;

        }else {
          this.margeData = this.margeData.concat(data);
          this.margeQty = this.enteredQty + this.transferQty;
          this.enteredQty = this.enteredQty + this.transferQty;

        }

        // 清空页面数据
        await this.clearSourceFields(this, true);
        this.fromBatchId = '';
        this.fromBatchCode = '';
        this.fromCidId = '';
        this.fromCidCode = '';
        this.fromLocationId = '';
        this.fromLocationCode = '';
        this.fromWhareaId= '';
        this.whareaCode= '';
        this.transferQty = '';
        this.serialNumberList = [];
        this.skipNext(0);
      },

      // 跳转详情界面
      handleImportedDetail(){
        if(this.editFlag && this.transferQty ===this.editData.qty){
          let data = {
            goodsId: this.editData.goodsId,
            fromSku: this.editData.fromSku,
            fromBatchId: this.editData.fromBatchId,
            fromBatchCode: this.editData.fromBatchCode,
            fromCidId: this.editData.fromCidId,
            fromCidCode: this.editData.fromCidCode,
            cidStatus: this.editData.cidStatus,
            fromWhareaId: this.editData.fromWhareaId,
            fromWhareaCode: this.editData.fromWhareaCode,
            fromLocationId: this.editData.fromLocationId,
            fromLocationCode: this.editData.fromLocationCode,
            warehouseId: this.editData.warehouseId,
            qty: this.editData.qty,
            availableQty: this.editData.availableQty,
            useQty: this.editData.useQty,
            serialStockProcessDtoList: this.serialNumberList,
            uuid: this.editData.uuid,
          };
          this.margeData = this.margeData.map((item) => item.uuid === this.editData.uuid ? data : item);
          this.margeQty = this.enteredQty + this.editData.qty;
          this.enteredQty = this.enteredQty + this.editData.qty;
        }
        let param = {
          name: 'ImportedDetail',
          params: {
            source: 'batchMarge',
            enteredQty: this.enteredQty,
            margeData: this.margeData,
            editFlag: this.editFlag,
            editQty: this.editData.qty,
          },
        };
        // this.fromBatchCode = '';
        this.fromDetailFlag = true;
        this.$router.pushPage(param);
      },

      /**
       * 物料lov失焦
       */
      async handleSkuBlur(){
        try {
          let data = {
            batchId: this.skuInfo.id,
            goodsId: this.skuInfo.goodsId
          };
          const res = await checkBatch(data);
          if(res && res.failed){
            this.skipNext(10);
          }else if(!res) {
            this.skipNext(20);
          }else {
            if(res.cidCode){
              this.fromCidId = res.cidId;
              this.fromCidCode = res.cidCode;
              this.fromLocParam = {
                batchId: this.skuInfo.id,
                goodsId: this.skuInfo.goodsId,
                cidId: res.cidId,
              };
            }
            this.fromLocationId = res.locationId;
            this.fromLocationCode = res.locationCode;
            this.fromWhareaId = res.whareaId;
            this.fromWhareaCode = res.whareaCode;
            this.warehouseId = res.warehouseId;
            this.availableQty = res.qty; // 现有量
            this.useQty = res.validQty; // 可用量
            // this.enteredQty = res.enteredQty; // 保留量
            this.skipNext(50);
          }
        }catch(e) {
          this.skipNext(10);
          console.log('错误：' + e);
        }
      },

      /**
       * 库位输入框失焦
       */
      handleLocEnter(){
        if(this.enableSerial){
          this.skipNext('refSerail');
        } else {
          this.skipNext(50);
        }
      },
      /**
       * 数字输入框失焦
       */
      checkNumBlur(){
        if(this.transferQty < 0 || this.transferQty > this.useQty){
          if(this.transferQty < 0){
            this.$hint.showFailed(this.$i18n.t('message.qtyMustBeGreaterThanZero'), true);
          }else {
            this.$hint.showFailed(this.$i18n.t('message.qtyMustBeSmallerThanValidQty'), true);
          }
          this.skipNext(50);
        }
      },
      /**
       * 序列号录入
       */
      async goToSerialDetail(){
        if(!this.fromBatchId || !this.fromLocationId){
          this.$hint.showFailed(this.$i18n.t('message.checkRequired'), true);
          return
        }
        let serialNumberDataList = [];
        this.margeData&&this.margeData.forEach((v)=>{
          serialNumberDataList.push(...v.serialStockProcessDtoList);
        });
        let data = {
          serialType: this.serialType,
          batchId: this.fromBatchId,
          goodsId: this.goodsId,
          cidId: this.fromCidId,
          locationId: this.fromLocationId,
          warehouseId: this.warehouseId,
        };
        const serialUrl = 'app-batch-modify/check-serial';
        const param = {
          name: 'batchSerialInput',
          params: {
            data,
            serialUrl,
            useQty: this.useQty,
            serialNumberList: this.isEdit?this.editData.serialStockProcessDtoList: this.serialNumberList,
            serialNumberDataList,
          },
        };
        this.fromSerialFlag = true;
        this.$router.pushPage(param);
      },

      async submit() {
        if(this.transferQty < 0 || this.transferQty > this.useQty){
          return;
        }
        if(this.margeData.length>0){
          if(!this.fromBatchId || !this.fromLocationId || !this.transferQty){
            this.$hint.showFailed(this.$i18n.t('message.checkRequired'), true);
            return
          }
          let margeData = [];
          let decData = {
            goodsId: this.disableGoodsId,
            fromSku: this.disableSku,
            serialType: this.disableSerialType,
            fromBatchId: this.fromBatchId,
            fromBatchCode: this.fromBatchCode,
            fromCidId: this.fromCidId,
            fromCidCode: this.fromCidCode,
            fromWhareaId: this.fromWhareaId,
            fromWhareaCode: this.fromWhareaCode,
            fromLocationId: this.fromLocationId,
            fromLocationCode: this.fromLocationCode,
            warehouseId: this.warehouseId,
            qty: this.transferQty,
            availableQty: this.availableQty,
            useQty: this.useQty,
            serialStockProcessDtoList: this.serialNumberList,
            uuid: uuidv4(),
          };
          if(this.editFlag){
            this.margeData = this.margeData.map((item) => item.uuid === this.editData.uuid ? decData : item);
            margeData = this.margeData;
            this.margeQty = this.enteredQty + this.transferQty;
            this.enteredQty = this.enteredQty + this.transferQty;
          }else {
            margeData = this.margeData.concat(decData);
            this.margeQty = this.enteredQty + this.transferQty;
            this.enteredQty = this.enteredQty + this.transferQty;
          }
          this.$storage.set('margeData', this.margeData);
          this.$storage.set('enteredQty', this.enteredQty);
          let param = {
            name: 'BatchMargeConfirm',
            params: {
              source: 'batchMarge',
              margeData: margeData,
              goodsId: this.disableGoodsId,
              sku: this.disableSku,
              skuInfo: this.skuInfo,
              enableSerial: this.enableSerial,
              enteredQty: this.enteredQty,
              sourceParseField: {...this.sourceParseField},
              customData: this.customData,
              hideControl: this.hideControl,
            },
          };
          this.fromBatchCode = '';
          await this.clearSourceFields(this, true);
          this.$router.pushPage(param);
        } else {
          this.handleNext();
        }
      },

    },
    created() {
      // 如果有自定义数据获取自定义数据
      if(this.$storage.get('customData')) {
        const { customObj, hideControl } = this.handleCustomData(this.$storage.get('customData'));
        this.customData = customObj;
        this.hideControl = hideControl;
      }
      // console.log('批次合并customData', Object.assign({}, this.customData));
    },
    mounted() {
      this.editFlag = false;
      this.editData = {};
      this.fromSerialFlag = false;
      this.fromDetailFlag = false;
      this.isEdit = false;
      this.inputFieldList = [
        { type: 'lov', required: true, ref: 0, bind: 'fromBatchCode', field: 'fromBatchCode' },
        { type: 'lov', required: true, ref: 10, bind: 'fromSku', field: 'fromSku' },
        { type: 'lov', required: false, ref: 20, bind: 'fromCidCode', field: 'fromCidCode' },
        { type: 'lov', required: true, ref: 30, bind: 'fromLocationCode', field: 'fromLocationCode' },
        { type: 'lov', required: true, ref: 40, bind: 'fromWhareaCode', field: 'fromWhareaCode' },
        { type: 'number', required: true, ref: 50, bind: 'transferQty', field: 'transferQty' },
      ];
      this.skipNext(0);
    },
    activated() {
      return
      // 之前的逻辑下注释掉
      // const {editFlag, editData, source, data, margeData, decompositionQty, isDelete, isEdit, serialData } = this.$route.params;
      // const { deleteData } = this.$route.params;
      // this.isChangeCid = false;
      // this.isDelete=isDelete;
      // this.isEdit=isEdit;
      // if(this.fromSerialFlag){
      //   this.fromSerialFlag = false;

      //   this.serialNumberList = this.$storage.get('serialNumberList') || [];
      //   this.transferQty = this.serialNumberList.length;
      //   this.skipNext('submitRef');
      // }
      // if(deleteData){
      //   this.margeData=[];
      //   this.enableSerial = '';
      //   this.fromBatchId = '';
      //   this.fromBatchCode = '';
      //   this.fromSku = '';
      //   this.goodsId = '';
      //   this.serialType = '';
      //   this.fromCidId = '';
      //   this.fromCidCode = '';
      //   this.fromLocationId = '';
      //   this.fromLocationCode = '';
      //   this.fromWhareaId = '';
      //   this.fromWhareaCode = '';
      //   this.warehouseId = '';
      //   this.fromCidParam = {};
      //   this.fromLocParam = {};
      //   this.serialNumberList = [];
      //   this.$storage.set('serialNumberList', []);
      //   this.transferQty = '';
      //   this.availableQty = 0; // 现有量
      //   this.useQty = 0; // 可用量
      //   this.enteredQty = 0; // 保留量
      //   this.skipNext(0);
      // }
      // this.isMargeDetailFrom = this.$storage.get('isToDetail');
      // this.$storage.remove('isToDetail');
      // if(this.fromDetailFlag){
      //   this.fromDetailFlag = false;
      //   this.margeData = this.$storage.get('margeData') || [];
      //   this.enteredQty = this.$storage.get('enteredQty') || 0;
      //   this.margeQty = this.$storage.get('enteredQty') || 0;
      //   if(editFlag){
      //     this.editFlag = true;
      //     this.editData = editData;
      //     this.fromSku= editData.fromSku;
      //     this.fromBatchId= editData.fromBatchId;
      //     this.fromBatchCode= editData.fromBatchCode;
      //     this.fromCidId= editData.fromCidId;
      //     this.fromCidCode= editData.fromCidCode;
      //     this.fromWhareaId= editData.fromWhareaId;
      //     this.fromWhareaCode= editData.fromWhareaCode;
      //     this.fromLocationId= editData.fromLocationId;
      //     this.fromLocationCode= editData.fromLocationCode;
      //     this.availableQty= editData.availableQty;
      //     this.warehouseId= editData.warehouseId;
      //     this.useQty=editData.useQty;
      //     this.fromCidParam = {
      //       batchId: editData.fromBatchId,
      //       goodsId: editData.goodsId,
      //     };

      //     this.fromLocParam = {
      //       batchId: editData.fromBatchId,
      //       goodsId: editData.goodsId,
      //       cidId: editData.fromCidId,
      //     };
      //     this.qty= editData.qty;
      //     this.transferQty= editData.qty;
      //     this.serialStockProcessDtoList= editData.serialStockProcessDtoList;
      //   }else if(!isDelete&&!editFlag&&this.margeData.length>1){
      //     this.enteredQty = this.enteredQty-this.transferQty;
      //   }
      //   this.skipNext('submitRef');
      // }
      // if(this.isMargeDetailFrom){
      //   this.isMargeDetailFrom=false;
      //   this.margeData = this.$storage.get('margeData') || [];
      //   this.enteredQty = this.$storage.get('enteredQty') || 0;
      //   this.margeQty = this.$storage.get('enteredQty') || 0;
      //   if(editFlag){
      //     this.editFlag = true;
      //     this.editData = editData;
      //     this.fromSku= editData.fromSku;
      //     this.fromBatchId= editData.fromBatchId;
      //     this.fromBatchCode= editData.fromBatchCode;
      //     this.fromCidId= editData.fromCidId;
      //     this.fromCidCode= editData.fromCidCode;
      //     this.fromWhareaId= editData.fromWhareaId;
      //     this.fromWhareaCode= editData.fromWhareaCode;
      //     this.fromLocationId= editData.fromLocationId;
      //     this.fromLocationCode= editData.fromLocationCode;
      //     this.availableQty= editData.availableQty;
      //     this.warehouseId= editData.warehouseId;
      //     this.useQty=editData.useQty;
      //     this.fromCidParam = {
      //       batchId: editData.fromBatchId,
      //       goodsId: editData.goodsId,
      //     };
      //     this.fromLocParam = {
      //       batchId: editData.fromBatchId,
      //       goodsId: editData.goodsId,
      //       cidId: editData.fromCidId,
      //     };
      //     this.qty= editData.qty;
      //     this.transferQty= editData.qty;
      //     this.serialStockProcessDtoList= editData.serialStockProcessDtoList;
      //   }
      //   this.skipNext('submitRef');
      // }
      // if(source==='batchMargeConfirm'){
      //   this.editFlag = true;
      //   this.editData = data;
      //   this.fromSku= data.fromSku;
      //   this.fromBatchId= data.fromBatchId;
      //   this.fromBatchCode= data.fromBatchCode;
      //   this.fromCidId= data.fromCidId;
      //   this.fromCidCode= data.fromCidCode;
      //   this.fromWhareaId= data.fromWhareaId;
      //   this.fromWhareaCode= data.fromWhareaCode;
      //   this.fromLocationId= data.fromLocationId;
      //   this.fromLocationCode= data.fromLocationCode;
      //   this.availableQty= data.availableQty;
      //   this.useQty=data.useQty;
      //   this.fromCidParam = {
      //     batchId: data.fromBatchId,
      //     goodsId: data.goodsId,
      //   };

      //   this.fromLocParam = {
      //     batchId: data.fromBatchId,
      //     goodsId: data.goodsId,
      //     cidId: data.fromCidId,
      //   };
      //   this.enteredQty = decompositionQty;

      //   this.margeQty = decompositionQty;
      //   this.qty= data.qty;
      //   this.transferQty= data.qty;
      //   this.margeData= margeData;
      //   this.serialStockProcessDtoList= data.serialStockProcessDtoList;
      //   this.serialNumberList= serialData;
      //   this.skipNext('submitRef');
      // }

    },
    beforeRouteLeave(to, from, next) {
      if (to.name === 'importedDetailMarge' || to.name === 'batchSerialInput' || to.name === 'BatchMargeConfirm' || to.name === 'ImportedDetail') {
        this.$store.commit('pushKeepAlives', 'BatchMarge');
      } else {
        this.$store.commit('deleteKeepAlives', 'BatchMarge');
      }
      setTimeout(() => {
           next();
      },100)
    },
    watch:{
      'fromBatchCode': function(val, oldval) {
        if(oldval !== '' && val === '' ){
          this.serialType = '';
          this.fromSku = '';
          this.goodsId = '';
          this.fromCidId = '';
          this.fromCidCode = '';
          this.fromLocationId = '';
          this.fromLocationCode = '';
          this.fromWhareaId = '';
          this.fromWhareaCode = '';
          this.warehouseId = '';
          this.fromCidParam = {};
          this.fromLocParam = {};
          this.serialNumberList = [];
          this.$storage.set('serialNumberList', []);
          this.transferQty = '';
          this.availableQty = 0; // 现有量
          this.useQty = 0; // 可用量
          // this.enteredQty = ''; // 保留量
          if(this.margeData.length>0){
            this.goodsId= this.disableGoodsId;
            this.fromSku= this.disableSku;
            this.enteredQty= this.margeQty;

            this.serialType= this.disableSerialType;
            this.fromLocParam = {
              batchId: this.fromBatchId,
              goodsId: this.disableGoodsId,
            };
          }
        }
      },
      'fromSku': function(val, oldval) {
        if(oldval !== '' && val === ''){
          this.fromBatchId = '';
          this.serialType = '';
          this.goodsId = '';
          this.disableGoodsId = '';
          this.disableSku = '';
          this.fromCidId = '';
          this.fromCidCode = '';
          this.fromLocationId = '';
          this.fromLocationCode = '';
          this.fromWhareaId = '';
          this.fromWhareaCode = '';
          this.warehouseId = '';
          this.fromCidParam = {};
          this.fromLocParam = {};
          this.serialNumberList = [];
          this.$storage.set('serialNumberList', []);
          this.transferQty = '';
          this.availableQty = 0; // 现有量
          this.useQty = 0; // 可用量
          this.enteredQty = 0; // 保留量
        }
      },
      'fromCidCode': function(val, oldval) {
        if(oldval !== '' && val === '' && this.isChangeCid){
          this.fromCidId = '';
          this.fromLocationId = '';
          this.fromLocationCode = '';
          this.fromWhareaId = '';
          this.fromWhareaCode = '';
          this.warehouseId = '';
          this.fromLocParam = {
            batchId: this.fromBatchId,
            goodsId: this.goodsId,
          };
          this.serialNumberList = [];
          this.$storage.set('serialNumberList', []);
          this.transferQty = '';
          this.availableQty = 0; // 现有量
          this.useQty = 0; // 可用量
        }
      },
      'fromLocationCode': function(val, oldval) {
        if(oldval !== '' && val  === ''){
          this.fromLocationId = '';
          this.fromWhareaId = '';
          this.fromWhareaCode = '';
          this.warehouseId = '';
          this.serialNumberList = [];
          this.$storage.set('serialNumberList', []);
          // this.transferQty = '';
          // this.availableQty = 0; // 现有量
          this.useQty = 0; // 可用量
        }
      },
    }
  };
</script>

<style lang="stylus" scoped>
 @import '~@/style/var.styl'
  .count_block_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0;
  }
  .count-availableQty{
    background-color: #91D5FF
    border-color: var(--primaryColor)
    color: var(--primaryColor)
  }
  .count-useQty{
    background-color: #B7EB8F
    border-color: #52C41A
    color: #52C41A
  }
  .count-enteredQty{
    background-color: #FFD382
    border-color: #FF8F07
    color: #FF8F07
  }
  .hip-row{
    display flex
    padding  12px 0 0 0
    .button-detail{
      margin-left: 15px;
      margin-right: 5px;
      padding 0 5px;
      background-color: var(--primaryColor);
      color: #FFFFFF;
      border: none;
      font-size: 14px;
      height: 36px;
      line-height: 36px;
    }
  }
  .footer-button{
    display: flex;
    position: fixed;
    bottom: 7.5%;
    left: 0;
    width: 100%
    text-align: center;
    justify-content: space-around;
    .footer-button-box{
      width: 100%
      margin: 0 2.667vw 3.2vw;
    }
    .next{
      background-color var(--primaryColor);
      color white;
      width 49%;
    }
    .importedDetail{
      background-color #f5a623;
      margin-left: 1.5%;
      color white;
      width 49%;
    }
  }
</style>
