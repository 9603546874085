import request from '../../../request';
import { storage } from '../../../utils';

export default function digitalPickingWrapper(WrappedComponent, initData) {
  return {
    name: 'DigitalPicking',
    data() {
      this.menuId = storage.get('menuId');
      this.winvBaseUrl = `${process.env.VUE_APP_WINV_URL}v1/${storage.get('tenantId')}/`;
      return {
        pageFunctionParams: {},
      };
    },
    created() {
      if (!initData) {
        this.queryPageFunction();
      } else {
        this.pageFunctionParams = initData || {};
      }
    },
    methods: {
      queryPageFunction() {
        const url = `${this.winvBaseUrl}wms-common/query-enabled-page-params`;
        request
          .get(url, {
            params: {
              deviceCode: 'PDA',
            },
          })
          .then((res) => {
            this.pageFunctionParams = res || {};
            return res;
          });
      },
    },
    render(h) {
      const { pageFunctionParams } = this;
      return h(WrappedComponent, { attrs: { pageFunctionParams } });
    },
  };
}
