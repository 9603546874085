<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.analyse')" />
    </div>
    <!-- 按波次单号复核  按订单号复核  按快递单号复核 -->
    <hips-row class="ips-search">
      <hips-col span="20">
        <ips-field
          :label="$t('label.checkTheDimensionOrderNumber')"
          v-show="customData['matchCode'] ? hideControl['matchCode'] : true"
          :custom="customData['matchCode']"
          :source-parse-field="sourceParseField"
          @setSourceField="saveSourceField($event)"
          required
          v-model="matchCode"
          ref="ref0"
          @enter="handleButtonQuery"
        />
      </hips-col>
      <hips-col span="4" class="ips-row-left">
        <hips-button
          type="warning"
          @click.stop="handleButtonQuery"
          @keyup.enter.native="handleButtonQuery"
          @keypress.native.prevent
          ref="selectRef"
        >
          {{ $t('button.query') }}
        </hips-button>
      </hips-col>
    </hips-row>
    <div class="ips-container">
      <span class="instructions">
        {{ $t('label.instructions') }}
      </span>
      <hips-row class="ips-row">
        <div class="link-item" @enter="selectSearchType('PACK')" @click.stop="selectSearchType('PACK')">
          {{ $t('message.analyseItemTracking') }}
        </div>
        <div class="link-item" @enter="selectSearchType('BILL')" @click.stop="selectSearchType('BILL')">
          {{ $t('message.analyseItemOrder') }}
        </div>
        <div class="link-item" @enter="selectSearchType('WAVE')" @click.stop="selectSearchType('WAVE')">
          {{ $t('message.analyseItemEave') }}
        </div>
      </hips-row>
    </div>
  </hips-view>
</template>

<script>
import { storage } from '@/utils';
import { autoMatchCode } from '../api';
export default {
  data() {
    return {
      matchCode: '',
      matchType: '',
      urlData: {},
      customData: {}, // 个性化配置信息列表
      hideControl: {}, // 非必输字段的显隐控制
      sourceParseField: {}, // 界面中所有的来源解析字段
    };
  },
  mounted() {
    const { hideControl, customObj } = this.handleCustomData(storage.get('customData') || []);
    this.customData = customObj;
    this.hideControl = hideControl;
    // this.$hint.showSuccess('一行数据');
    // this.$hint.showSuccess(this.$i18n.t('message.createPlantSuccess'));
  },
  methods: {
    // 搜索
    handleButtonQuery() {
      this.$hint.showLoading();
      let data = {
        params: {
          matchCode: this.matchCode,
        },
      };
      autoMatchCode(data)
        .then((res) => {
          this.matchType = res.matchType;
          this.jumpPageByType(res);
        })
        .finally(() => {
          this.$hint.hideLoading();
        });
    },
    // 根据matchType进行跳转
    jumpPageByType(res = {}) {
      let param = {
        name: 'PackAnalyse',
        query: {
          matchType: this.matchType,
          matchCode: this.matchCode,
        },
      };
      if (this.matchType === 'BILL') {
        param.query.billId = res.billId;
      } else if (this.matchType === 'WAVE') {
        param.query.waveBillId = res.waveBillId;
      }
      this.$router.pushPage(param);
    },
    // 选择搜索类型
    selectSearchType(type) {
      this.matchType = type;
      this.jumpPageByType();
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~@/style/var.styl';

.ips-search {
  display: flex;
  padding: 4.27vw 2vw 0 2vw;

  .ips-row-left {
    float: right;
    text-align: right;
  }
}

.ips-container {
  padding: 0 0 16vw !important;

  .instructions {
    display: block;
    margin: 15px 0 15px 0;
    padding-left: 2vw;
    font-size: 14px;
    color: #8C8C8C;
  }

  .ips-row {
    padding-left: 20px;

    .link-item {
      line-height: 30px;
      color: blue;
    }
  }
}
</style>
