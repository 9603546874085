import request from '@/request';
import mixin from '@/mixin/mixin';

//  获取已完成任务
export function fetchProcessedTasks(data) {
  let url = `${mixin.data().winvBaseUrl}app-task-manager/fetch-processed-tasks`;
  return request.get(url, data);
}
// 获取待处理任务
export function fetchPendingTasks(data) {
  let url = `${mixin.data().winvBaseUrl}app-task-manager/fetch-pending-tasks`;
  return request.get(url, data);
}

// 获取批次信息
export function waveOrderLov(data) {
  let url = `${mixin.data().winvBaseUrl}app-wave-picking/wave-order-lov`;
  return request.get(url, data);
}

// 处理托盘查询任务
export function dealWithCid(data) {
  let url = `${mixin.data().winvBaseUrl}app-inbound-putaway/whole-cid-assign`;
  return request.get(url, data);
}

// 确认  查询任务
export function queryProcessTask(data) {
  const url = `${mixin.data().winvBaseUrl}app-outbound-load/fetch-process-task`;
  return request.get(url, data);
}

// 校验托盘
export function checkCidId(data) {
  let url = `${mixin.data().winvBaseUrl}app-inbound-putaway/check-outermoster-container`;
  return request.get(url, data);
}

export function fetchProcessTask(data) {
  let url = `${mixin.data().winvBaseUrl}app-order-picking/fetch-process-task`;
  return request.get(url, data);
}
