<template>
  <div class="message_box">
    <div
      :class="[
        'message_text_normal',
        {
          'message_text_expand': expand,
        }]"
    >
      {{ message }}
    </div>
    <div class="control_tool" @click="changeExpandStatus">
      <span>{{ this.$i18n.t('label.unfold') }}</span>
      <div class="crl_switch">
        <span
          :class="[
            'switch_triangle',
            {
              'switch_triangle_expand': expand,
            }]"
        />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "IpsMessageTip",
    data() {
      return {
        expand: false,
      };
    },
    props: {
      message: {
        type: String,
        // eslint-disable-next-line vue/require-valid-default-prop
        default: '',
      },
    },
    methods: {
      changeExpandStatus () {
        this.expand = !this.expand;
      },
    },
  };
</script>

<style lang="stylus">
    @import '~@/style/var.styl'
    .message_box {
        font-size: 14px;
    }
    .message_text_normal {
        min-height: 35px;
        color: #595959;
        border-bottom: 1px solid #d9d9d9;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 35px;
    }
    .message_text_expand {
        height: auto;
        overflow-y: visible;
        white-space: normal;
        margin-top: 10px;
        padding-bottom: 5px;
        color: #595959;
        font-size: 15px;
    }
    .control_tool {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .control_tool span {
        color: var(--primaryColor);
        font-size: 14px;
    }
    .crl_switch {
        position: relative;
    }
    .switch_triangle {
        position: absolute;
        z-index: 2;
        width: 0;
        height: 0;
        line-height: 0;
        font-size: 0;
        border-width: 5px; /*如果想改变倒三角形的大小,就改变这里的像素值*/
        border-style: solid;
        border-color: #666 #fff #fff #fff; /*如果倒三角形的父元素背景不是白色,就把后面三个#fff改为对应的背景色*/
        top: -10px;
        right: 10px; /*如果倒三角形前面的字多于两个,就设置这里的像素值大些*/
    }
    .switch_triangle_expand {
        -moz-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
    }

</style>
