var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ips-input-number ips-input-common" },
    [
      _c(
        "ips-input",
        _vm._b(
          {
            ref: "input",
            attrs: {
              value: _vm.displayValue,
              disabled: _vm.disabled,
              readonly: _vm.readonly,
              required: _vm.required,
              max: _vm.max,
              min: _vm.min,
              name: _vm.name,
              label: _vm.label,
              autofocus: _vm.autofocus,
            },
            on: {
              blur: _vm.handleBlur,
              focus: _vm.handleFocus,
              input: _vm.handleInput,
              change: _vm.handleInputChange,
              down: _vm.handleDown,
              enter: _vm.handleEnter,
              click: _vm.handleClick,
            },
          },
          "ips-input",
          _vm.$attrs,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }