<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.outSundry')" />
    </div>
    <div class="ips-container">
      <!-- 托盘 -->
      <ips-lov
        :label="$t('label.tray')"
        :title="$t('label.tray')"
        v-model="cidCode"
        v-show="customData['cidCode'] ? hideControl['cidCode']: true"
        params="cidCode"
        :url="`${winvBaseUrl}app-miscellaneous-out/cid`"
        :pre-parse-url="urlData['cidCode']"
        :custom="customData['cidCode']"
        :source-parse-field="sourceParseField"
        ref="ref0"
        @enter="skipNext(10)"
        @click.stop="handleCommonClick(0)"
        @clickIcon="handleCommonClick(0, 'lov')"
        @choose="handleChoose('cidCode', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 库位 -->
      <ips-lov
        :label="$t('label.countLocation')"
        :title="[$t('label.countLocation'), $t('label.locationName'), $t('label.whareaName')]"
        v-model="locationCode"
        v-show="customData['locationCode'] ? hideControl['locationCode']: true"
        required
        :params="[ 'locationCode', 'locationName', 'whareaName']"
        display="locationCode"
        :url-params="{'enabledFlag': 1, 'id': cidLocationId }"
        :url="`${wmdmBaseUrl}wms-locations`"
        :pre-parse-url="urlData['locationCode']"
        :custom="customData['locationCode']"
        :source-parse-field="sourceParseField"
        ref="ref10"
        @click.stop="handleCommonClick(10)"
        @clickIcon="handleCommonClick(10, 'lov')"
        @choose="handleChoose('locationCode', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 物料 -->
      <ips-lov
        :label="$t('label.sku')"
        v-model="sku"
        required
        :title="[$t('label.sku'), $t('label.goodsName'), $t('label.ownerName')]"
        :params="[ 'sku','goodsName', 'ownerName']"
        display="sku"
        :url="`${winvBaseUrl}app-miscellaneous-out/goods`"
        :url-params="{ orgId, orgCode, ownerId, ownerCode, locationId }"
        :pre-parse-url="urlData['sku']"
        ref="ref20"
        :custom="customData['sku']"
        :source-parse-field="sourceParseField"
        @setSourceField="saveSourceField($event)"
        v-show=" cidId ? false : customData['sku'] ? hideControl['sku'] : true"
        @choose="handleChoose('sku', $event)"
        @click.stop="handleCommonClick(20)"
        @clickIcon="handleCommonClick(20, 'lov')"
        @valuedByFoucs="valuedByFoucs($event)"
      />

      <!-- 批次 -->
      <ips-lov
        :label="$t('label.batch')"
        :title="$t('label.batch')"
        required
        params="batchCode"
        v-model="batchCode"
        display="batchCode"
        :url="`${winvBaseUrl}app-miscellaneous-out/batch`"
        :url-params="{ goodsId, sku, ownerId, ownerCode, locationId, locationCode }"
        :pre-parse-url="urlData['batchCode']"
        ref="ref30"
        :custom="customData['batchCode']"
        :source-parse-field="sourceParseField"
        v-show="isbatch ? (customData['batchCode'] ? hideControl['batchCode'] : isbatch) : false"
        @choose="handleChoose('batchCode', $event)"
        @setSourceField="saveSourceField($event)"
        @click.stop="handleCommonClick(30)"
        @clickIcon="handleCommonClick(30, 'lov')"
        @enter="skipNext(40)"
        @valuedByFoucs="valuedByFoucs($event)"
      />

      <!-- 品名单位 -->
      <div v-if="goodsId">
        <ips-page-card
          :react-field="['ownerName', 'uomName']"
          :item="goodsLov"
          :footer-info="{goodsName: goodsLov.goodsName}"
        />
      </div>

      <hips-row>
        <div
          style="margin-top: 16px"
          v-if="['INV_CTRL', 'OUT_CTRL', 'SEMI_INV_CTRL'].includes(serialType)"
        >
          <hips-col span="14">
            <ips-input-number
              v-model="transferQty"
              required
              :label="$t('label.quantity')"
              ref="ref40"
              thousands-formatter
              not-auto-fill
              :custom="customData['transferQty']"
              :source-parse-field="sourceParseField"
              :enable-serial="serialType"
              v-show="customData['transferQty'] ? hideControl['transferQty'] : true"
              @setSourceField="saveSourceField($event)"
              @valuedByFoucs="valuedByFoucs($event)"
              @enter="chooseSkipNext"
              @click.stop="handleCommonClick(40)"
              :min="null"
              :precision="0"
            />
          </hips-col>
          <hips-col span="10">
            <!-- 序列号明细 -->
            <hips-button
              type="primary"
              size="large"
              :disabled="!transferQty"
              v-show="cidId ? false : showSeriesDetail ? (customData['showSeriesDetail'] ? hideControl['showSeriesDetail'] : showSeriesDetail) : false"
              @click.stop="goToSerialDetail"
              @enter="skipNext(40)"
              ref="refSerail"
              style="width: 93%; margin-left: 10px; color: #FFFFFF; font-size:
                          14px; height: 36px;line-height: 36px"
            >
              {{ serialNumberList.length > 0 ? (`${$t('button.seriesDetail')}(${serialNumberList.length})`) : ($t('button.seriesDetail')) }}
            </hips-button>
          </hips-col>
        </div>
      </hips-row>
      <hips-row>
        <div
          style="margin-top: 16px"
          v-if="cidId ? true : false"
        >
          <hips-col span="14">
            <ips-input-number
              v-model="transferQty"
              required
              :disabled="true"
              :label="$t('label.quantity')"
              ref="ref40"
              thousands-formatter
              not-auto-fill
              :custom="customData['transferQty']"
              :source-parse-field="sourceParseField"
              v-show="customData['transferQty'] ? hideControl['transferQty'] : true"
              @setSourceField="saveSourceField($event)"
              @valuedByFoucs="valuedByFoucs($event)"
              @enter="skipNext('inputRef')"
              @click.stop="handleCommonClick(40)"
              :min="null"
            />
          </hips-col>
          <!-- 托盘明细 -->
          <hips-col span="10">
            <hips-button
              type="primary"
              size="large"
              @click.stop="goToCidTransferDetail"
              ref="inputRef"
              style="width: 93%; margin-left: 10px; color: #FFFFFF; font-size:
                          14px; height: 36px;line-height: 36px"
            >
              {{ $t('button.selectTrayDetail') }}
            </hips-button>
          </hips-col>
        </div>
      </hips-row>
      <hips-row>
        <div
          style="margin-top: 16px"
          v-if="cidId ? false : !['INV_CTRL', 'OUT_CTRL', 'SEMI_INV_CTRL'].includes(serialType)"
        >
          <hips-col span="24">
            <ips-input-number
              v-model="transferQty"
              required
              :label="$t('label.quantity')"
              ref="ref40"
              thousands-formatter
              @enter="skipNext(50)"
              @click.stop="handleCommonClick(40)"
              :custom="customData['transferQty']"
              :source-parse-field="sourceParseField"
              :enable-serial="serialType"
              v-show="customData['transferQty'] ? hideControl['transferQty'] : true"
              @setSourceField="saveSourceField($event)"
              @valuedByFoucs="valuedByFoucs($event)"
              not-auto-fill
              :min="null"
            />
          </hips-col>
        </div>
      </hips-row>
      <!--现有量、可用量-->
      <div class="card_num_subtrans">
        <hips-row>
          <div class="row-num">
            <hips-col
              span="12"
              style="display: flex;align-items: center;height: 100%;"
            >
              <div
                class="title"
              >
                {{ $t('label.availableQty') }}:
              </div>
              <div
                class="value"
              >
                {{ qty }}
              </div>
            </hips-col>
            <hips-col
              span="12"
              style="display: flex;align-items: center;height: 100%;"
            >
              <div
                class="title"
                style="display: inline-block"
              >
                {{ $t('label.currentUseQty') }}:
              </div>
              <div
                class="value"
                style="display: inline-block;"
              >
                {{ validQty }}
              </div>
            </hips-col>
          </div>
        </hips-row>
      </div>
      <!--调整原因-->
      <ips-lov
        :label="$t('label.sundryReason')"
        :title="$t('label.sundryReason')"
        v-model="reason"
        v-show="customData['reason'] ? hideControl['reason']: true"
        :params="['meaning']"
        :url="`${wmdmBaseUrl}wms-common/lov-data-query`"
        :url-params="{lovCode:'WINV.INV_MOVE_MISC_REASON'}"
        :pre-parse-url="urlData['reason']"
        :is-page-content="false"
        :custom="customData['reason']"
        :source-parse-field="sourceParseField"
        ref="ref50"
        @click.stop="handleCommonClick(50)"
        @clickIcon="handleCommonClick(50, 'lov')"
        @enter="skipNext('submitRef')"
        @choose="handleChoose('reason', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
    </div>
    <div class="ips-footer">
      <hips-button
        size="large"
        ref="submitRef"
        @click="handleButtonSubmit"
      >
        <svg-icon
          slot="icon"
          icon-class="success"
        />
        {{ $t('button.confirm') }}
      </hips-button>
    </div>
  </hips-view>
</template>

<script>
import { checkGoodsQty, outSunderySubmit, getCidDetailData, getStockTransferType } from '../api';
import { Row, Col } from '@hips/vue-ui';
import { storage } from '@/utils';
export default {
  name: 'MiscellaneousOut',
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
  },
  data() {
    return {
      cidId: '',
      cidCode: '',
      cidData: {},
      cidLocationId: '',
      locationId: '',
      locationCode: '',
      locationType: '',
      warehouseId: '',
      warehouseCode: '',
      whareaId: '',
      whareaCode: '',
      orgId: '',
      orgCode: '',
      orgName: '',
      sku: '',
      goodsId: '',
      goodsLov: null,
      isbatch: '',
      ownerId: '',
      ownerCode: '',
      ownerName: '',
      serialType: '',
      uomId: '',
      uomCode: '',
      uomName: '',
      batchId: '',
      batchCode: '',
      qty: '',
      validQty: '',
      transferQty: '',
      reason: '',
      receiptId: '',
      urlData: {},
      receiveQty: '',
      cidDetailData: '',
      transferType: '',
      showSeriesDetail: false, // 显示序列号明细按钮
      customData: {}, // 个性化配置信息列表
      hideControl: {}, // 个性化配置显示隐藏控制
      sourceParseField: {}, // 界面中所有的来源解析字段
      serialNumberList: [], // 序列号明细list
    };
  },
  methods: {
    // 更据条件决定光标定位何处
    chooseSkipNext() {
      if (this.showSeriesDetail) {
        if (this.transferQty === this.serialNumberList.length) {
          this.skipNext(50);
        } else {
          this.skipNext('refSerail')
        }
      } else {
        this.skipNext(50);
      }
    },
    // 个性化配置
    // 保存来源解析字段
    async saveSourceField(fieldObj) {
      this.saveSourceParseField(this, fieldObj);
    },
    // 字段聚焦时赋值操作
    async valuedByFoucs(options = {}) {
      const { custom = {}, currentValue } = options;
      await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
    },

    handleLocatinSkip() {
      if (this.cidId) {
        this.skipNext('inputRef');
      } else {
        this.skipNext(20);
      }
    },


    async handleChoose(type, obj) {
      switch (type) {
        case 'locationCode':
          this.locationId = obj.locationId;
          this.warehouseId = obj.warehouseId;
          this.warehouseCode = obj.warehouseCode;
          this.locationType = obj.locationType;
          this.whareaId = obj.whareaId;
          this.whareaCode = obj.whareaCode;
          this.orgId = obj.orgId;
          this.orgCode = obj.orgCode;
          this.orgName = obj.orgName;
          this.handleLocatinSkip();
          break;
        case 'sku':
          this.goodsLov = obj;
          this.goodsId = obj.goodsId;
          this.ownerId = obj.ownerId;
          this.ownerCode = obj.ownerCode;
          this.ownerName = obj.ownerName;
          this.isbatch = obj.isbatch;
          this.serialType = obj.serialType;
          this.uomId = obj.uomId,
            this.uomCode = obj.uomCode,
            this.uomName = obj.uomName,
            this.receiptId = obj.receiptId,
            this.showSeriesDetail = ['INV_CTRL', 'OUT_CTRL', 'SEMI_INV_CTRL'].includes(obj.serialType),
            this.skuCheckAndGetQty();
          break;
        case 'reason':
          this.reasonValue = obj.value;
          this.reason = obj.meaning;
          break;
        case 'cidCode':
          this.cidId = obj.cidId;
          this.locationId = obj.locationId;
          this.cidLocationId = obj.locationId;
          this.locationCode = obj.locationCode;
          this.cidData = obj;
          await this.getCidData();
          break;
        case 'batchCode':
          this.batchId = obj.batchId;
          this.getGoodsQty();
          break;
        default:
          return null;
      }
    },
    async getCidData() {
      try {
        this.$hint.showLoading();
        const data = {
          params: {
            cidId: this.cidId,
            // cidCode: this.cidCode,
          }
        };
        this.getGoodsQty();
        const res = await getCidDetailData(data);
        if (res && res.content.length > 0) {
          this.cidDetailData = res.content;
        }

      } finally {
        this.$hint.hideLoading();
      }

    },

    async skuCheckAndGetQty() {
      this.getGoodsQty();
      if (this.isbatch === 1) {
        this.$nextTick(() => {
          this.skipNext(30);
        });
      } else {
        this.$nextTick(() => {
          this.skipNext(40);
        });
      }
    },

    async getGoodsQty() {
      let data;
      if (this.cidId) {
        data = {
          params: {
            cidId: this.cidId,
            // cidCode: this.cidCode,
            locationId: this.locationId,
            // locationCode: this.locationCode,
            warehouseId: this.warehouseId,
            // warehouseCode: this.warehouseCode,
            whareaId: this.whareaId,
            // whareaCode: this.whareaCode,
          }
        };
        const res = await checkGoodsQty(data);
        if (res && res.qty) {
          this.qty = res.qty;
          this.validQty = res.validQty;
          this.transferQty = res.validQty;
        } else {
          this.qty = 0;
          this.validQty = 0;
        }
      } else if (this.isbatch === 0) {
        data = {
          params: {
            goodsId: this.goodsId,
            locationId: this.locationId,
            ownerId: this.ownerId,
            warehouseId: this.warehouseId,
            whareaId: this.whareaId,
          }
        };
        const res = await checkGoodsQty(data);
        if (res && res.qty) {
          this.qty = res.qty;
          this.validQty = res.validQty;
        } else {
          this.qty = 0;
          this.validQty = 0;
        }
      } else if (this.batchId) {
        data = {
          params: {
            goodsId: this.goodsId,
            locationId: this.locationId,
            ownerId: this.ownerId,
            warehouseId: this.warehouseId,
            whareaId: this.whareaId,
            batchCode: this.batchCode,
            batchId: this.batchId,
          }
        };
        const res = await checkGoodsQty(data);
        if (res && res.qty) {
          this.qty = res.qty;
          this.validQty = res.validQty;
        } else {
          this.qty = 0;
          this.validQty = 0;
        }
      }
    },

    /**
     * go托盘转移详情
     * */
    async goToCidTransferDetail() {
      if (this.cidDetailData) {
        const param = {
          name: 'cidMiscellaneousOutDetail',
          params: {
            item: this.cidData,
            cidDetailData: this.cidDetailData,
            transferType: this.transferType,
          },
        };
        this.$router.pushPage(param);
      }
    },
    /**
    * 前往序列号明细界面
    */
    async goToSerialDetail() {
      let data = {
        cidCode: this.cidCode,
        locationId: this.locationId,
        locationCode: this.locationCode,
        sku: this.sku,
        goodsId: this.goodsId,
        batchCode: this.batchCode,
        serialType: this.serialType,
        whareaCode: this.whareaCode,
        whareaId: this.whareaId,
        warehouseCode: this.warehouseCode,
        warehouseId: this.warehouseId,
        orgId: this.orgId,
        orgCode: this.orgCode,
      };
      let serialUrl = 'app-miscellaneous-out/check-serial';
      const param = {
        name: 'serialComponent',
        params: {
          data,
          serialUrl,
          receiveQty: this.transferQty,
          // task: this.inboundSerialReceiptList,
          serialNumberList: this.serialNumberList, // 序列号信息
          callback: function (serialNumber, serialId) {
            const item = {
              serialId,
              serialNumber,
              serialQty: 1,
            };
            if (!item.qualityStatusName) {
              const serialDataShow = Object.assign({}, item, { qualityStatusName: '合格' });
              this.tableData.push(serialDataShow);
            } else {
              const serialDataShow = Object.assign({}, item);
              this.tableData.push(serialDataShow);
            }
          }
        },

      };
      this.$router.pushPage(param);
    },

    // 杂项出库提交
    async handleButtonSubmit() {
      try {
        if (this.transferQty) {
          this.$hint.showLoading();
          this.handleSubmitStatus();

          // 配置stockTransferDTOList
          let stockTransferDTOList = [];
          if (this.cidId) {
            this.cidDetailData.forEach((item) => {
              let tempObj = {};
              if (item.transferQty !== 0) { // 有数量才出库
                tempObj = {
                  serialStockProcessDtoList: item.serialStockProcessDtoList,
                  validQty: item.validQty,
                  qty: item.qty,
                  transferQty: item.transferQty,
                  batchCode: item.batchCode,
                  batchId: item.batchId,
                  sku: item.sku,
                  goodsId: item.goodsId,
                  locationCode: item.locationCode,
                  locationId: item.locationId,
                  parentCidCode: item.parentCidCode,
                  cidCode: item.cidCode,
                  cidId: item.cidId,
                  reason: this.reasonValue,
                  orgId: this.orgId,
                  ownerId: this.ownerId,
                  orgCode: this.orgCode,
                  whareaId: this.whareaId,
                  warehouseId: this.warehouseId,
                  uomId: this.uomId,
                  uomCode: this.uomCode,
                  ownerCode: this.ownerCode
                };
                if (tempObj.serialStockProcessDtoList && tempObj.serialStockProcessDtoList.length === 0) {
                  delete tempObj.serialStockProcessDtoList;
                }
                stockTransferDTOList.push(tempObj);
              }
            });
          } else {
            let tempObj = {
              locationCode: this.locationCode,
              locationId: this.locationId,
              goodsId: this.goodsId,
              sku: this.sku,
              batchCode: this.batchCode,
              batchId: this.batchId,
              transferQty: this.transferQty,
              qty: this.qty,
              serialStockProcessDtoList: this.serialNumberList,
              validQty: this.validQty,
              reason: this.reasonValue,
              orgId: this.orgId,
              ownerId: this.ownerId,
              orgCode: this.orgCode,
              whareaId: this.whareaId,
              warehouseId: this.warehouseId,
              uomId: this.uomId,
              uomCode: this.uomCode,
              ownerCode: this.ownerCode
            };
            if (tempObj.serialStockProcessDtoList && tempObj.serialStockProcessDtoList.length === 0) {
              delete tempObj.serialStockProcessDtoList;
            }
            stockTransferDTOList.push(tempObj);
          }

          // const postData = {
          //   paramIn: { stockTransferDTOList },
          //   // macroCode: "WMS_APP_MISCELLANEOUS_OUT",
          // };
          const res = await outSunderySubmit(stockTransferDTOList)
          if (res) {
            this.$hint.showSuccess(this.$i18n.t('message.operationSuccess'));
            this.init();
            // 清除当前界面的二维码解析字段
            await this.clearSourceFields(this, true);
          }
        } else {
          this.$hint.showFailed(this.$i18n.t('message.transferQtyNotZero'));
        }
      } finally {
        this.$hint.hideLoading();
        this.handleSubmitStatus();
      }
    },

    async stockTransferType() {
      try {
        this.$hint.showLoading();
        const data = {
          params: {
            pageId: storage.get('menuId'),
          }
        };
        const res = await getStockTransferType(data);
        this.transferType = res;
      } finally {
        this.$hint.hideLoading();
      }
    },

    init() {
      this.cidId = '';
      this.cidCode = '';
      this.cidData = {},
        this.locationId = '';
      this.cidLocationId = '',
        this.locationCode = '';
      this.locationType = '';
      this.warehouseId = '';
      this.warehouseCode = '';
      this.whareaId = '';
      this.whareaCode = '';
      this.orgId = '';
      this.orgCode = '';
      this.orgName = '';
      this.sku = '';
      this.goodsId = '';
      this.goodsLov = null;
      this.isbatch = '';
      this.ownerId = '';
      this.ownerCode = '';
      this.serialType = '';
      this.uomId = '';
      this.uomCode = '';
      this.uomName = '';
      this.batchId = '',
        this.batchCode = '';
      this.qty = '';
      this.validQty = '';
      this.transferQty = '';
      this.reason = '';
      this.reasonValue = '';
      this.receiptId = '';
      this.urlData = {};
      this.receiveQty = '';
      this.cidDetailData = '';
      this.showSeriesDetail = false;
      this.customData = {};
      this.hideControl = {};
      this.sourceParseField = {};
      this.serialNumberList = [];
      this.skipNext(0);
    },
  },
  async mounted() {
    this.inputFieldList = [
      { type: 'lov', required: false, ref: 0, bind: 'cidCode', field: 'cidCode' },
      { type: 'lov', required: true, ref: 10, bind: 'locationCode', field: 'locationCode' },
      { type: 'lov', required: false, ref: 20, bind: 'sku', field: 'sku' },
      { type: 'lov', required: true, ref: 30, bind: 'batchCode', field: 'batchCode' },
      { type: 'number', required: true, ref: 40, bind: 'transferQty', field: 'transferQty' },
      { type: 'lov', required: false, ref: 50, bind: 'reason', field: 'reason' },
    ];
    this.stockTransferType();
    this.skipNext(0);
  },
  async created() {
    const { hideControl, customObj } = this.handleCustomData(storage.get('customData'));
    this.customData = customObj;
    this.hideControl = hideControl;
    // console.log('按单收货customData', Object.assign({}, this.customData));
  },
  activated() {
    if (this.$route.params.route === 'cidMiscellaneousOutDetail') {
      this.cidDetailData = this.$route.params.cidDetailData;
      this.transferQty = this.$route.params.transferNumber;
      this.skipNext('inputRef');
    } else if (this.$route.params.route === 'serialComponent') {
      this.skipNext('refSerail');
    }

    const serialNumberList = JSON.parse(sessionStorage.getItem('inboundSerialReceiptList')) || [];
    this.serialNumberList = serialNumberList;
    this.transferQty = serialNumberList.length;
    sessionStorage.removeItem('inboundSerialReceiptList');
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'cidMiscellaneousOutDetail' || to.name === 'serialComponent') {
      this.$store.commit('pushKeepAlives', 'MiscellaneousOut');
    } else {
      this.$store.commit('deleteKeepAlives', 'MiscellaneousOut');
    }
    setTimeout(() => {
      next();
    }, 0);
  },
  // beforeRouteEnter(to, from, next) {
  //   next((vm) => {
  //     const _this = vm;
  //     if (from.name === 'serialComponent') {
  //       console.log(2222222)
  //       const serialNumberList = JSON.parse(sessionStorage.getItem('serialNumberList')) || [];
  //       _this.serialNumberList = serialNumberList;
  //       _this.transferQty = serialNumberList.length;
  //       console.log(_this.serialNumberList)


  //       // sessionStorage.removeItem('serialNumberList');
  //       // setTimeout(() => {
  //       //   _this.skipNext(40);
  //       // }, 300);
  //     }
  //   });
  // },
  watch: {
    'locationCode': function (val, oldval) {
      // if(oldval !== val && !this.cidCode && oldval && val){
      //   this.locationId = '';
      // }
      if (oldval !== '' && val === '' && !this.cidCode) {
        this.locationId = '';
        this.sku = '';
        this.goodsId = '';
        this.goodsLov = null;
        this.isbatch = '';
        this.ownerId = '';
        this.ownerCode = '';
        this.serialType = '';
        this.uomId = '';
        this.uomCode = '';
        this.uomName = '';
        this.batchId = '',
          this.batchCode = '';
        this.qty = '';
        this.validQty = '';
        this.transferQty = '';
        this.reason = '';
        this.reasonValue = '';
      }
    },
    'sku': function (val, oldval) {
      if (oldval !== '' && val === '') {
        this.batchId = '',
          this.batchCode = '';
        this.qty = '';
        this.validQty = '';
        this.transferQty = '';
        this.reason = '';
        this.reasonValue = '';
      }
    },
    'cidCode': function (val, oldval) {
      if (!val) {
        this.cidId = '';
      }
      if (oldval !== '' && val === '') {
        this.cidLocationId = '',
          this.locationCode = '';
        this.sku = '';
        this.goodsId = '';
        this.goodsLov = null;
        this.isbatch = '';
        this.ownerId = '';
        this.ownerCode = '';
        this.serialType = '';
        this.uomId = '';
        this.uomCode = '';
        this.uomName = '';
        this.batchId = '',
          this.batchCode = '';
        this.qty = '';
        this.validQty = '';
        this.transferQty = '';
        this.reason = '';
        this.reasonValue = '';
        this.receiptId = '';
        this.urlData = {};
        this.receiveQty = '';
        this.cidDetailData = '';
        this.showSeriesDetail = false;
        this.customData = {};
        this.hideControl = {};
        this.sourceParseField = {};
        this.serialNumberList = [];
      }
    },
  }
};
</script>

<style lang="stylus" scoped>
@import '~@/style/var.styl';

.ips-container {
  .card_num_subtrans {
    margin: 10px 0;
    padding: 0 12px;
    height: 50px;
    border-radius: 1.6vw;
    color: #000;
    box-shadow: 0 0 2.133vw #d9d9d9;
    border: none;
    font-size: 14px;
    background-color: #FFFFFF;

    .row {
      display: flex;

      /* padding 0 0 7px 0 */
      .title {
        height: 16px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 200;
        color: var(--primaryColor);
        line-height: 18px;
      }
    }

    .hips-row {
      display: flex;
      /* padding-left: 5px; */
      padding-top: 7px;

      .button-detail {
        margin-left: 5px;
        margin-right: 5px;
        padding: 0 5px;
        background-color: var(--primaryColor);
        color: #FFFFFF;
        border: none;
        font-size: 14px;
        height: 36px;
        line-height: 36px;
      }
    }

    .row-num {
      display: inline-block;
      align-items: center;
      margin-top: 3px;
      height: 25px;
      background: #E6F7FF;
      border-radius: 6px;
      width: 100%;
      border: 2px solid #91D5FF;
      
      .row-50 {
        width: 50%;
        /* display flex */
      }

      .hips-col {
        padding: 0 5px;
      }

      .title {
        // width: 100px;
        height: 16px;
        
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 200;
        color: #595959;
        line-height: 16px;
      }

      .value {
        margin-left: auto;
        height: 16px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: initial;
        color: #000000;
        line-height: 16px;
        overflow: hidden; /* 溢出隐藏 */
        white-space: nowrap; /* 规定文本不进行换行 */
        text-overflow: ellipsis; /* 当对象内文本溢出时显示省略标记（...） */
      }
    }
  }
}

.ips-btn-normal {
  border-radius: 8px !important;
  margin: 12px 10px 12px 10px;
  background-color: var(--primaryColor) !important;
  color: #ffffff !important;
  font-size: 17px !important;
}
</style>
