import request from '@/request';
import mixin  from '@/mixin/mixin';

// 查询托盘任务详情
export function queryUnloadTask(param) {
  const url = `${mixin.data().winvBaseUrl}app-outbound-unload/query-unload-tasks`;
  return request.get(url, param);
}

// 确认卸货
export function confirmUnloading(data) {
  const url = `${mixin.data().winvBaseUrl}app-outbound-unload/unload`;
  return request.post(url, data);
}

// 取消卸货
export function cancelUnloading(data) {
  const url = `${mixin.data().winvBaseUrl}app-outbound-unload/clear-task`;
  return request.post(url, data);
}

// 获取页面初始化数据
export function initPage(param) {
  const url = `${mixin.data().winvBaseUrl}app-outbound-unload/init-page`;
  return request.get(url, param);
}

export function queryTaskList(data) {
  const url = `${mixin.data().winvBaseUrl}app-outbound-unload/unload-tasks-process`;
  return request.post(url, data);
}

// 获取转运托盘Id
export function queryLoadCidId(param) {
  const url = `${mixin.data().winvBaseUrl}app-outbound-unload/query-unload-containers`;
  return request.get(url, param);
}







