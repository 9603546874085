var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: { title: _vm.$t("title.secondarySorting") },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["waveNum"]
                ? _vm.hideControl["waveNum"]
                : true,
              expression:
                "customData['waveNum'] ? hideControl['waveNum']: true",
            },
          ],
          ref: "ref0",
          attrs: {
            label: _vm.$t("label.waveNum"),
            title: _vm.$t("label.waveNum"),
            params: "waveBillCode",
            display: "waveBillCode",
            url: _vm.winvBaseUrl + "app-outbound-sorting/query-wave-orders",
            "pre-parse-url": _vm.urlData["waveNum"],
            custom: _vm.customData["waveNum"],
            "source-parse-field": _vm.sourceParseField,
            "is-control": "",
            required: "",
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(0)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(0, "lov")
            },
            enter: function ($event) {
              return _vm.skipNext(10)
            },
            down: function ($event) {
              return _vm.skipNext(10)
            },
            choose: function ($event) {
              return _vm.handleChoose("waveNum", $event)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.inputData.waveNum,
            callback: function ($$v) {
              _vm.$set(_vm.inputData, "waveNum", $$v)
            },
            expression: "inputData.waveNum",
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.inputData.showSerialFlag
                ? _vm.customData["serialNumber"]
                  ? _vm.hideControl["serialNumber"]
                  : _vm.inputData.showSerialFlag
                : false,
              expression:
                "inputData.showSerialFlag ? (customData['serialNumber'] ? hideControl['serialNumber'] : inputData.showSerialFlag) : false",
            },
          ],
          ref: "ref10",
          attrs: {
            label: _vm.$t("label.serialNumber"),
            title: _vm.$t("label.serialNumber"),
            required: _vm.isSerial,
            params: "serialNumber",
            url: _vm.winvBaseUrl + "app-outbound-sorting/query-serials",
            "url-params": { waveBillId: _vm.waveBillId },
            "pre-parse-url": _vm.urlData["serialNumber"],
            custom: _vm.customData["serialNumber"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(10)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(10, "lov")
            },
            enter: _vm.serialBlur,
            down: _vm.serialBlur,
            blur: _vm.serialBlur,
            choose: function ($event) {
              return _vm.handleChoose("serialNumber", $event)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.inputData.serialNumber,
            callback: function ($$v) {
              _vm.$set(_vm.inputData, "serialNumber", $$v)
            },
            expression: "inputData.serialNumber",
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["itemCode"]
                ? _vm.hideControl["itemCode"]
                : true,
              expression:
                "customData['itemCode'] ? hideControl['itemCode']: true",
            },
          ],
          ref: "ref20",
          attrs: {
            label: _vm.$t("label.itemCode"),
            title: _vm.$t("label.itemCode"),
            required: "",
            disabled: _vm.isDisabled,
            params: "sku",
            display: "sku",
            url: _vm.winvBaseUrl + "app-outbound-sorting/query-goods",
            "url-params": {
              waveBillId: _vm.waveBillId,
              waveBillTypeId: _vm.waveBillTypeId,
            },
            "pre-parse-url": _vm.urlData["itemCode"],
            custom: _vm.customData["itemCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(20)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(20, "lov")
            },
            enter: _vm.handleGoodsBlur,
            down: _vm.handleGoodsBlur,
            choose: function ($event) {
              return _vm.handleChoose("itemCode", $event)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.inputData.itemCode,
            callback: function ($$v) {
              _vm.$set(_vm.inputData, "itemCode", $$v)
            },
            expression: "inputData.itemCode",
          },
        }),
        _c("ips-input-number", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["quantity"]
                ? _vm.hideControl["quantity"]
                : true,
              expression:
                "customData['quantity'] ? hideControl['quantity']: true",
            },
          ],
          ref: "ref30",
          attrs: {
            label: _vm.$t("label.quantity"),
            disabled: _vm.isDisabled,
            required: "",
            min: null,
            "more-than-zero": true,
            "enable-serial": _vm.serialType,
            custom: _vm.customData["quantity"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            blur: _vm.handleNumberBlur,
            enter: function ($event) {
              _vm.inputData.isbatch === 0
                ? _vm.inputData.showSerialFlag
                  ? _vm.skipNext(10)
                  : _vm.skipNext("submitRef")
                : _vm.skipNext(40)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.inputData.quantity,
            callback: function ($$v) {
              _vm.$set(_vm.inputData, "quantity", $$v)
            },
            expression: "inputData.quantity",
          },
        }),
        _c("ips-value-cell", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["owner"] ? _vm.hideControl["owner"] : true,
              expression: "customData['owner'] ? hideControl['owner']: true",
            },
          ],
          attrs: {
            title: _vm.$t("label.ownerCode"),
            "image-flag": "",
            value: _vm.orderData.ownerName,
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.inputData.isbatch
                ? _vm.customData["batchCode"]
                  ? _vm.hideControl["batchCode"]
                  : _vm.inputData.isbatch
                : false,
              expression:
                "inputData.isbatch ? (customData['batchCode'] ? hideControl['batchCode'] : inputData.isbatch) : false",
            },
          ],
          ref: "ref40",
          attrs: {
            label: _vm.$t("label.batch"),
            title: _vm.$t("label.batch"),
            disabled: _vm.isDisabled,
            params: "batchCode",
            url: _vm.winvBaseUrl + "app-outbound-sorting/query-batchs",
            "url-params": { goodsId: _vm.inputData.goodsId },
            "pre-parse-url": _vm.urlData["batchCode"],
            custom: _vm.customData["batchCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(40)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(40, "lov")
            },
            enter: function ($event) {
              return _vm.skipNext("submitRef")
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.inputData.batchCode,
            callback: function ($$v) {
              _vm.$set(_vm.inputData, "batchCode", $$v)
            },
            expression: "inputData.batchCode",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-footer" },
      [
        _c(
          "hips-button",
          {
            ref: "submitRef",
            attrs: { size: "large" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonSubmit.apply(null, arguments)
              },
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleCommonSubmit.apply(null, arguments)
              },
              keypress: function ($event) {
                $event.preventDefault()
              },
            },
          },
          [
            _c("svg-icon", {
              attrs: { slot: "icon", "icon-class": "success" },
              slot: "icon",
            }),
            _vm._v("\n      " + _vm._s(_vm.$t("button.submit")) + "\n    "),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }