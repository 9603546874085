import BatchSplit from '../views/batch-split'
import BatchSplitConfirm from '../views/batch-split-confirm'
import ImportedDetailSplit from '../views/imported-detail'

let route = [
  {
    path: '/batch-split',
    name: 'BatchSplit',
    component: BatchSplit,
  },
  {
    path: '/batch-split-confirm',
    name: 'BatchSplitConfirm',
    component: BatchSplitConfirm,
  },
  {
    path: '/imported-detail-split',
    name: 'ImportedDetailSplit',
    component: ImportedDetailSplit,
  },
];

export default route