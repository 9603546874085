<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.bulkStockTransfer')" />
    </div>
    <div class="ips-container">
      <!-- 起始托盘 -->
      <ips-lov
        :label="$t('label.startOfTray')"
        :title="[$t('label.startOfTray'), $t('label.sku'), $t('label.batch')]"
        v-show="customData['fromCidCode'] ? hideControl['fromCidCode'] : true"
        v-model="fromCidCode"
        :params="['cidCode', 'sku', 'batchCode']"
        :url="`${winvBaseUrl}app-stock-trans/query-trans-container`"
        :pre-parse-url="urlData['fromCidCode']"
        display="cidCode"
        ref="ref0"
        :custom="customData['fromCidCode']"
        :source-parse-field="sourceParseField"
        @click.stop="handleCommonClick(0)"
        @clickIcon="handleCommonClick(0, 'lov')"
        @enter="handleCidEnter"
        @down="handleCidEnter"
        @tab="handleCidTab"
        @choose="handleChoose('fromCidCode', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 起始父托盘 -->
      <ips-field
        :label="$t('label.startOfParentTray')"
        :title="$t('label.startOfParentTray')"
        v-show="parentCidEnabled.paramValue==1? true:false"
        v-model="fromParentCidCode"
        :params="fromParentCidCode"
        disabled
        display="parentCidCode"
        ref="ref5"
        :custom="customData['fromParentCidCode']"
        :source-parse-field="sourceParseField"
      />
      <!-- 起始库位 -->
      <ips-lov
        :label="$t('label.startOfLocation')"
        :title="$t('label.startOfLocation')"
        v-show="customData['fromLocationCode'] ? hideControl['fromLocationCode'] : true"
        v-model="fromLocationCode"
        required
        params="locationCode"
        :url="`${winvBaseUrl}app-stock-trans/query-trans-location`"
        :url-params="locationParam"
        :pre-parse-url="urlData['fromLocationCode']"
        ref="ref10"
        :custom="customData['fromLocationCode']"
        :source-parse-field="sourceParseField"
        @click.stop="handleCommonClick(10)"
        @clickIcon="handleCommonClick(10, 'lov')"
        @enter="skipNext(20)"
        @down="skipNext(20)"
        @tab="skipNext(20)"
        @choose="handleChoose('fromLocationCode', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 物料 -->
      <ips-lov
        :label="$t('label.sku')"
        :title="[$t('label.sku'),$t('label.goodsName')]"
        v-show="customData['sku'] ? hideControl['sku'] : true"
        v-model="sku"
        required
        display="sku"
        :params="['sku', 'goodsName']"
        :url="`${winvBaseUrl}app-stock-trans/query-trans-sku`"
        :url-params="skuParam"
        :pre-parse-url="urlData['sku']"
        :pre-parse-title="[$t('label.sku'),$t('title.name'), $t('label.ownerCode')]"
        :pre-params="['mainValue','mainName','ownerCode']"
        ref="ref20"
        :custom="customData['sku']"
        :source-parse-field="sourceParseField"
        @click.stop="handleCommonClick(20)"
        @clickIcon="handleCommonClick(20, 'lov')"
        @enter="handleSkuEnter"
        @down="handleSkuEnter"
        @tab="handleSkuTab"
        @choose="handleChoose('sku', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 品名 -->
      <ips-value-cell
        :title="$t('label.goodsName')"
        :value="skuInfo.goodsName"
        v-show="customData['goodsName'] ? hideControl['goodsName'] : true"
      />
      <!-- 单位 -->
      <ips-value-cell
        :title="$t('label.unit')"
        :value="skuInfo.uomCode"
        v-show="customData['uomCode'] ? hideControl['uomCode'] : true"
      />
      <!-- 批次 -->
      <ips-lov
        :label="$t('label.batch')"
        v-model="batchCode"
        v-show="isbatch ? (customData['batchCode'] ? hideControl['batchCode'] : isbatch) : false"
        :required="isbatch"
        :title="$t('label.batch')"
        params="batchCode"
        :url="`${winvBaseUrl}app-stock-trans/query-trans-batch`"
        :url-params="batchParam"
        :pre-parse-url="urlData['batchCode']"
        ref="ref30"
        :custom="customData['batchCode']"
        :source-parse-field="sourceParseField"
        @click.stop="handleCommonClick(30)"
        @clickIcon="handleCommonClick(30, 'lov')"
        @enter="skipNext(40)"
        @down="skipNext(40)"
        @tab="skipNext(40)"
        @choose="handleChoose('batch', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 现有量 -->
      <ips-value-cell
        :title="$t('label.availableQty')"
        :value="qtyInfo.qty"
        v-show="customData['qty'] ? hideControl['qty'] : true"
      />
      <!-- 可用量 -->
      <ips-value-cell
        :title="$t('label.useQty')"
        :value="qtyInfo.validQty"
        v-show="customData['validQty'] ? hideControl['validQty'] : true"
      />
      <!-- 目标托盘 -->
      <!--<ips-field v-model="toCidCode" :label="$t('label.targetTray')" ref="ref40" @click.stop="handleCommonClick(40)" @enter="skipNext(50)"></ips-field>-->
      <ips-lov
        :label="$t('label.targetTray')"
        :title="$t('label.targetTray')"
        v-show="customData['toCidCode'] ? hideControl['toCidCode'] : true"
        v-model="toCidCode"
        params="cidCode"
        :url="`${winvBaseUrl}app-stock-trans/query-trans-to-container`"
        :url-params="toCidCodeParam"
        :pre-parse-url="urlData['toCidCode']"
        ref="ref40"
        :custom="customData['toCidCode']"
        :source-parse-field="sourceParseField"
        @click.stop="handleCommonClick(40)"
        @clickIcon="handleCommonClick(40, 'lov')"
        @enter="handleToCidEnter"
        @down="handleToCidEnter"
        @tab="handleToCidTab"
        @choose="handleChoose('toCidCode', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 目标父托盘 -->
      <ips-lov
        :label="$t('label.targetParentTray')"
        :title="$t('label.targetParentTray')"
        v-show="parentCidEnabled.paramValue==1? true:false"
        v-model="toParentCidCode"
        params="cidCode"
        :disabled="isHasParentCid"
        :url="`${winvBaseUrl}app-stock-trans/query-trans-to-parent-container`"
        :url-params="toCidCodeParam"
        :pre-parse-url="urlData['toParentCidCode']"
        ref="ref45"
        :custom="customData['toParentCidCode']"
        :source-parse-field="sourceParseField"
        @click.stop="handleCommonClick(45)"
        @clickIcon="handleCommonClick(45, 'lov')"
        @enter="handleToParentCidEnter"
        @down="handleToParentCidEnter"
        @tab="handleToParentCidTab"
        @choose="handleChoose('toParentCidCode', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 目标库位 -->
      <ips-lov
        :title="$t('label.targetLocation')"
        :label="$t('label.targetLocation')"
        v-show="customData['toLocationCode'] ? hideControl['toLocationCode'] : true"
        v-model="toLocationCode"
        required
        params="locationCode"
        :url="`${wmdmBaseUrl}wms-locations/lov`"
        :url-params="toLocationParam"
        :pre-parse-url="urlData['toLocationCode']"
        :disabled="isToCidCode"
        ref="ref50"
        :custom="customData['toLocationCode']"
        :source-parse-field="sourceParseField"
        @click.stop="handleCommonClick(50)"
        @clickIcon="handleCommonClick(50, 'lov')"
        @enter="handleLocationEnter"
        @down="handleLocationEnter"
        @tab="handleLocationTab"
        @choose="handleChoose('toLocationCode', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 序列号录入 -->
      <div
        class="input-button"
        v-if="enableSerial"
      >
        <hips-button
          @click="goToSerial"
          ref="inputRef"
          :style="{ ...inputBtnStyle }"
        >
          {{ $t('button.serialNumEntry') }}
        </hips-button>
      </div>
      <!-- 转移数量 -->
      <ips-input-number
        v-model="transferQty"
        v-show="customData['transferQty'] ? hideControl['transferQty'] : true"
        required
        :disabled="qtyScanDisabled"
        :enable-serial="skuInfo.serialType"
        :label="$t('label.transferNumber')"
        ref="ref60"
        :custom="customData['transferQty']"
        :source-parse-field="sourceParseField"
        @enter="skipNext('submitRef')"
        @down="skipNext('submitRef')"
        @tab="skipNext('submitRef')"
        @click.stop="handleCommonClick(60)"
        @blur="checkQtyBlur"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
        :min="null"
        :more-than-zero="true"
        not-auto-fill
      />
    </div>
    <div class="ips-footer">
      <hips-button
        size="large"
        ref="submitRef"
        @click.stop="handleCommonSubmit"
      >
        <svg-icon
          slot="icon"
          icon-class="success"
        />
        {{ $t('button.confirm') }}
      </hips-button>
    </div>
  </hips-view>
</template>

<script>
import { querySerialList, queryStockQty, stockTrans, toParentCidCheck, getparentCidEnabled } from '../api';
import { storage } from '@/utils';
export default {
  name: 'StockTransfer',
  data() {
    return {
      fromCidId: '', // 起始托盘ID
      fromCidCode: '', // 起始托盘
      fromParentCidId: '', // 起始父托盘ID
      fromParentCidCode: '', // 起始父托盘
      warehouseId: '',
      fromLocationId: '', // 起始库位
      fromLocationCode: '', // 起始库位
      goodsId: '', // 物料id
      sku: '', // 物料
      batchCode: '', // 批次
      batchId: '', //批次id
      transferQty: null, // 转移数量
      toCidId: '', // 目标托盘id
      toCidCode: '', // 目标托盘
      toParentCidId: '', // 目标父托盘id
      toParentCidCode: '', // 目标父托盘
      toLocationId: '', // 目标库位
      toLocationCode: '', // 目标库位
      locationInfo: {}, // 库位详细
      serialId: '', // 序列号id
      serialNumber: '', // 序列号
      serialStockProcessDtoList: [], // 序列号数组
      skuInfo: '', // 物料详细
      isbatch: false, // 是否启用批次控制
      enableSerial: false, //是否启用序列号管理
      qtyInfo: '', // 数量详细
      locationParam: {}, // 库位lov参数
      skuParam: {}, // 物料lov参数
      batchParam: {}, // 批次lov参数
      toCidCodeParam: {}, // 目标托盘参数
      toLocationParam: {}, // 目标库位参数
      // enableSerialParam: {}, // 序列号lov参数
      qtyScanDisabled: false,
      refreshFlag: false, //序列号查询的控制参数是否更新
      serialAllowSubmit: true, // 序列号录入是否符合提交要求
      isToCidCode: false, // 托盘带出来的库位不允许编辑
      isHasParentCid: true, //目标父托盘字段是否可以编辑
      urlData: {},
      customData: {}, // 个性化配置信息列表
      hideControl: {}, // 个性化配置显示隐藏控制
      sourceParseField: {}, // 界面中所有的来源解析字段
      parentCidEnabled: {}, //页面参数是否显示父托盘字段
      LocationIsParentLocation: false,//目标库位是父托盘带出数据

      crossWhareaId: undefined //允许跨库区库存转移 1：允许 0： 不允许
    };
  },
  methods: {
    /**
     * 序列号录入
     */
    async goToSerial() {
      const data = {
        params: {
          locationId: this.fromLocationId,
          locationCode: this.fromLocationCode,
          cidId: this.fromCidId,
          cidCode: this.fromCidCode,
          batchId: this.batchId,
          batchCode: this.batchCode,
          sku: this.sku,
          goodsId: this.goodsId,
          size: 1000,
        },
      };
      const res = await querySerialList(data);
      const param = {
        name: 'inputSerial',
        params: {
          showInputBox: false,
          task: res.content || [],
        },
      };
      if (this.refreshFlag) {
        this.$store.commit('deleteKeepAlives', 'IpsSerial');
      }
      this.$router.pushPage(param);
    },

    /**
     * lov选中事件
     * @param {String} type - 定位lov
     * @param {object} obj - 选中行的数据
     */
    handleChoose(type, obj) {
      const site = [this.SERIAL_INV_CTRL];
      switch (type) {
        case 'fromCidCode':
          this.skuInfo = obj;
          this.whareaId = obj.whareaId;
          this.fromCidId = obj.cidId;
          this.fromCidCode = obj.cidCode;
          this.fromParentCidId = obj.parentCidId;
          this.fromParentCidCode = obj.parentCidCode;
          this.warehouseId = obj.warehouseId;
          this.fromLocationId = obj.locationId;
          this.fromLocationCode = obj.locationCode;
          this.goodsId = obj.goodsId;
          this.sku = obj.sku;
          this.batchCode = obj.batchCode;
          this.batchId = obj.batchId;
          this.isbatch = obj.isbatch === 1 ? true : false;
          this.enableSerial = site.includes(obj.serialType);
          this.queryQty();
          this.locationParam = {
            cidId: this.fromCidId,
            cidCode: this.fromCidCode,
          };
          this.skuParam = {
            cidId: this.fromCidId,
            cidCode: this.fromCidCode,
            locationId: this.fromLocationId,
            locationCode: this.fromLocationCode,
            whareaId: this.whareaId,
            warehouseId: this.warehouseId,
          };
          this.batchParam = {
            cidId: this.fromCidId,
            cidCode: this.fromCidCode,
            locationId: this.fromLocationId,
            locationCode: this.fromLocationCode,
            sku: this.sku,
            goodsId: this.goodsId,
            whareaId: this.whareaId,
            warehouseId: this.warehouseId,
          };
          this.crossWhareaId = obj.crossWhareaTransferFlag === 0 ? obj.whareaId : undefined
          this.toLocationParam = {
            warehouseId: this.warehouseId,
            whareaId: this.crossWhareaId,
          };
          this.toCidCodeParam = {
            warehouseId: this.warehouseId,
            whareaId: this.crossWhareaId,
          };
          break;
        case 'fromLocationCode':
          this.fromLocationId = obj.locationId;
          this.fromLocationCode = obj.locationCode;
          this.whareaId = obj.whareaId;
          this.warehouseId = obj.warehouseId;
          this.locationInfo = obj;
          this.skuParam = {
            cidId: this.fromCidId,
            cidCode: this.fromCidCode,
            locationId: this.fromLocationId,
            locationCode: this.fromLocationCode,
            whareaId: this.whareaId,
            warehouseId: this.warehouseId,
          };
          this.crossWhareaId = obj.crossWhareaTransferFlag === 0 ? obj.whareaId : undefined;
          this.toLocationParam = {
            warehouseId: this.warehouseId,
            whareaId: this.crossWhareaId,
          };
          break;
        case 'sku':
          this.goodsId = obj.goodsId;
          this.sku = obj.sku;
          this.skuInfo = obj;
          this.isbatch = obj.isbatch === 1 ? true : false;
          this.enableSerial = site.includes(obj.serialType);
          this.queryQty();
          this.batchParam = {
            cidId: this.fromCidId,
            cidCode: this.fromCidCode,
            locationId: this.fromLocationId,
            locationCode: this.fromLocationCode,
            sku: this.sku,
            goodsId: this.goodsId,
            whareaId: this.whareaId,
            warehouseId: this.warehouseId,
          };
          break;
        case 'batch':
          this.batchCode = obj.batchCode;
          this.batchId = obj.batchId;
          this.queryQty();
          break;
        case 'toCidCode':
          this.toCidId = obj.cidId;
          this.toCidCode = obj.cidCode;
          this.isToCidCode = false;
          if (obj.locationCode && this.fromCidCode !== this.toCidCode) {
            this.toLocationId = obj.locationId;
            this.toLocationCode = obj.locationCode;
            this.isToCidCode = true;
            this.LocationIsParentLocation = false;
          }
          this.toParentCidId = obj.parentCidId;
          this.toParentCidCode = obj.parentCidCode;
          if (this.toCidCode !== undefined) {
            this.isHasParentCid = false;
          }
          break;
        case 'toParentCidCode':
          this.toParentCidId = obj.cidId;
          this.toParentCidCode = obj.cidCode;
          if (!this.toLocationCode && obj.locationId && this.toCidCode !== '' && this.fromParentCidCode !== obj.cidCode) {
            this.toLocationId = obj.locationId;
            this.toLocationCode = obj.locationCode;
            this.LocationIsParentLocation = true;
          }

          this.chekckToParentCidCode();
          break;
        case 'toLocationCode':
          this.toLocationId = obj.locationId;
          this.toLocationCode = obj.locationCode;
          break;
        default:
          return null;
      }
    },

    /**
     * 校验目标父托盘字段
     */
    async chekckToParentCidCode() {
      try {
        const data = {
          locationId: this.toLocationId,
          locationCode: this.toLocationCode,
          cidId: this.toCidId,
          cidCode: this.toCidCode,
          parentCidId: this.toParentCidId,
          parentCidCode: this.toParentCidCode,
        };
        const res = await toParentCidCheck(data);
        if (res && res.failed) {
          this.skipNext(45);
        } else {
          if (!this.toLocationCode && res.locationId && this.toCidCode !== '' && this.fromParentCidCode !== res.cidCode) {
            this.toLocationId = res.locationId;
            this.toLocationCode = res.locationCode;
            this.LocationIsParentLocation = true;
          }
        }
      } catch {
        this.skipNext(45);
      }
    },

    /**
     *@functionName: saveSourceField
     *@description: 保存来源解析字段并赋值Enter
     *@params1: fieldObj[Object] 来源解析字段对象,
               fieldCode[String] 来源解析字段
               parseValue[String] 二维码解析值
               subFields[Array]] 关联子字段
    */
    saveSourceField(fieldObj) {
      this.saveSourceParseField(this, fieldObj);
    },
    // 字段聚焦时赋值操作
    async valuedByFoucs(options = {}) {
      const { custom = {}, currentValue } = options;
      await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
    },
    /**
     * 起始托盘enter事件
     */
    handleCidEnter() {
      if (this.fromCidCode) {
        this.skipNext(40);
      } else {
        this.skipNext(10);
      }
    },
    /**
     * 起始托盘tab事件
     */
    handleCidTab() {
      if (this.fromCidCode) {
        this.skipNext(40);
      } else {
        this.skipNext(10);
      }
    },
    /**
     * 转移数量blur事件
     */
    checkQtyBlur() {
      const { transferQty } = this;

      if (transferQty > this.qtyInfo.validQty) {
        // this.$hint.showFailed('转移数量不能大于可用量');
        this.$hint.showFailed(this.$i18n.t('message.qtyMustBeSmallerThanValidQty'), true);
        this.skipNext(60);
      }
    },

    async parentCidEnabledShow() {
      try {
        this.$hint.showLoading();
        const data = {
          params: {
            pageId: storage.get('menuId'),
            device: "PDA",
            functionCode: "PDA_INV_INVENTORY_TRANSFER",
            paramCode: "PARENT_CID_ENABLE",
          }
        };
        const res = await getparentCidEnabled(data);
        this.parentCidEnabled = res;
      } finally {
        this.$hint.hideLoading();
      }
    },
    /**
     * 库存转移
     */
    async handleButtonSubmit() {
      if (this.serialAllowSubmit) {
        try {
          this.$hint.showLoading();
          this.handleSubmitStatus();
          // let url = `${this.winvBaseUrl}stock-trans`;
          let param = {
            batchId: this.batchId,
            batchCode: this.batchCode,
            fromLocationId: this.fromLocationId,
            fromLocationCode: this.fromLocationCode,
            toLocationId: this.toLocationId,
            toLocationCode: this.toLocationCode,
            toCidId: this.toCidId,
            toCidCode: this.toCidCode,
            toParentCidId: this.toParentCidId,
            toParentCidCode: this.toParentCidCode,
            fromCidId: this.fromCidId,
            fromCidCode: this.fromCidCode,
            goodsId: this.goodsId,
            sku: this.sku,
            transferQty: this.transferQty,
            validQty: this.qtyInfo.validQty,
            warehouseId: this.warehouseId,
            serialStockProcessDtoList: this.serialStockProcessDtoList,
          };
          // if(this.serialStockProcessDtoList.length !== 0){
          //   param.serialStockProcessDtoList = this.serialStockProcessDtoList;
          // }
          // await this.$http.post(url, param);
          await stockTrans(param);
          this.$hint.showSuccess();
          await this.clearSourceFields(this, true);
          this.dataInit();
          this.skipNext(0);
        } finally {
          this.$hint.hideLoading();
          this.handleSubmitStatus();
        }
      } else {
        this.$hint.showFailed(this.$i18n.t('message.qtyOverrunReEnterSerial'), true);
      }
    },
    /**
     * 计算现有量、可用量
     */
    async queryQty() {
      try {
        this.$hint.showLoading();
        // let url = `${this.winvBaseUrl}stock-trans/query-stock-qty`;
        let param = {
          params: {
            cidId: this.fromCidId,
            cidCode: this.fromCidCode,
            locationId: this.fromLocationId,
            locationCode: this.fromLocationCode,
            goodsId: this.skuInfo.goodsId,
            sku: this.sku,
            ownerCode: this.skuInfo.ownerCode,
            ownerId: this.skuInfo.ownerId,
            uomId: this.skuInfo.uomId,
            uomCode: this.skuInfo.uomCode,
            orgId: this.skuInfo.orgId,
            orgCode: this.skuInfo.orgCode,
            whareaId: this.whareaId,
            warehouseId: this.warehouseId,
            batchId: this.batchId,
            batchCode: this.batchCode,
          },
        };
        // this.qtyInfo = await this.$http.get(url, param);
        this.qtyInfo = await queryStockQty(param);
      } catch (e) {
        console.log('错误：' + e);
      } finally {
        this.$hint.hideLoading();
      }
    },
    dataInit() {
      this.whareaId = '';
      this.fromCidId = '';
      this.fromCidCode = '';
      this.fromParentCidId = '';
      this.fromParentCidCode = '';
      this.fromLocationId = '';
      this.fromLocationCode = '';
      this.sku = '';
      this.goodsId = '';
      this.batchId = '';
      this.batchCode = '';
      this.transferQty = '';
      this.toCidId = '';
      this.toCidCode = '';
      this.toParentCidId = '';
      this.toParentCidCode = '';
      this.toLocationId = '';
      this.toLocationCode = '';
      this.serialStockProcessDtoList = [];
      this.refreshFlag = false;
      this.skuInfo = {};
      this.isbatch = false;
      this.enableSerial = false;
      this.qtyInfo = {};
    },
    /**
     * 物料enter事件
     */
    handleSkuEnter() {
      if (this.isbatch) {
        this.$nextTick(() => {
          this.skipNext(30);
        });
      } else {
        this.skipNext(40);
      }
    },
    handleSkuTab() {
      if (this.isbatch) {
        this.$nextTick(() => {
          this.skipNext(30);
        });
      } else {
        this.skipNext(40);
      }
    },
    /**
     * 目标托盘enter事件
     */
    handleToCidEnter() {
      if (this.toCidCode) {
        if (this.toLocationId) {
          if (this.fromCidCode === this.toCidCode) {
            this.skipNext(50);
          } else if (this.enableSerial) {
            this.skipNext('inputRef');
          } else {
            if (this.parentCidEnabled.paramValue === '1') {
              this.skipNext(45);
            } else {
              this.skipNext(60);
            }
          }
        } else {
          if (this.parentCidEnabled.paramValue === '1') {
            this.skipNext(45);
          } else {
            this.skipNext(50);
          }
        }
      } else {
        this.skipNext(50);
      }
    },
    handleToCidTab() {
      if (this.toCidCode) {
        if (this.toLocationId) {
          if (this.fromCidCode === this.toCidCode) {
            this.skipNext(50);
          } else if (this.enableSerial) {
            this.skipNext('inputRef');
          } else {
            if (this.parentCidEnabled.paramValue === '1') {
              this.skipNext(45);
            } else {
              this.skipNext(60);
            }
          }
        } else {
          if (this.parentCidEnabled.paramValue === '1') {
            this.skipNext(45);
          } else {
            this.skipNext(50);
          }
        }
      } else {
        this.skipNext(50);
      }
    },
    /**
     * 目标父托盘enter事件
     */
    handleToParentCidEnter() {
      if (this.toLocationId) {
        if (this.fromCidCode === this.toCidCode) {
          this.skipNext('submitRef');
        } else if (this.enableSerial) {
          this.skipNext('inputRef');
        } else {
          this.skipNext(60);
        }
      } else {
        this.skipNext(50);
      }
    },
    handleToParentCidTab() {
      if (this.toLocationId) {
        if (this.fromCidCode === this.toCidCode) {
          this.skipNext('submitRef');
        } else if (this.enableSerial) {
          this.skipNext('inputRef');
        } else {
          this.skipNext(60);
        }
      } else {
        this.skipNext(50);
      }
    },
    /**
     * 目标库位enter事件
     */
    handleLocationEnter() {
      if (this.enableSerial) {
        this.$nextTick(() => {
          this.skipNext('inputRef');
        });
      } else {
        if (this.qtyScanDisabled) {
          this.skipNext('submitRef');
        } else {
          this.skipNext('60');
        }
      }
    },
    handleLocationTab() {
      if (this.enableSerial) {
        this.$nextTick(() => {
          this.skipNext('inputRef');
        });
      } else {
        if (this.qtyScanDisabled) {
          this.skipNext('submitRef');
        } else {
          this.skipNext('60');
        }
      }
    },
  },
  watch: {
    fromCidCode: function (val) {
      this.qtyScanDisabled = false;
      this.serialStockProcessDtoList = [];
      this.refreshFlag = true;
      this.transferQty = '';
      if (val === this.toCidCode && val !== '' && val !== null) {
        this.enableSerial = false;
        this.transferQty = this.qtyInfo.validQty;
        this.qtyScanDisabled = true;
      } else if (val === '') {
        this.skuInfo = '';
        this.whareaId = '';
        this.fromCidId = '';
        this.fromCidCode = '';
        this.fromParentCidId = null;
        this.fromParentCidCode = null;
        this.warehouseId = '';
        this.fromLocationId = '';
        this.fromLocationCode = '';
        this.goodsId = '';
        this.sku = '';
        this.batchCode = '';
        this.batchId = '';
        this.isbatch = false;
        this.enableSerial = false;
        this.qtyInfo = {};
        this.locationParam = {};
        this.skuParam = {};
        this.batchParam = {};
        // this.enableSerialParam = {};
        this.toLocationParam = {};
        this.toCidCodeParam = {};
      }
    },
    fromLocationCode: function () {
      this.serialStockProcessDtoList = [];
      this.refreshFlag = true;
      this.transferQty = '';
    },
    sku: function () {
      this.serialStockProcessDtoList = [];
      this.refreshFlag = true;
      this.transferQty = '';
    },
    batchCode: function () {
      const site = [this.SERIAL_INV_CTRL];
      this.serialStockProcessDtoList = [];
      this.refreshFlag = true;
      this.transferQty = '';
      this.enableSerial = site.includes(this.skuInfo.serialType);
      if (this.enableSerial) {
        this.qtyScanDisabled = true;
      }
    },
    toCidCode: function (val) {
      this.qtyScanDisabled = false;
      this.serialStockProcessDtoList = [];
      this.refreshFlag = true;
      this.transferQty = '';
      if (val === this.fromCidCode && val !== '' && val !== null) {
        this.enableSerial = false;
        this.transferQty = this.qtyInfo.validQty;
        this.qtyScanDisabled = true;
      } else if (val === '') {
        this.isToCidCode = false;
        const site = [this.SERIAL_INV_CTRL];
        this.toParentCidId = null;
        this.toParentCidCode = null;
        this.toCidCode = null;
        this.toCidId = null;
        this.isHasParentCid = true;
        this.toLocationId = null; // 目标库位
        this.toLocationCode = null; // 目标库位
        // this.enableSerialParam = {};
        this.enableSerial = site.includes(this.skuInfo.serialType);
        if (this.enableSerial) {
          this.qtyScanDisabled = true;
        }
      } else if (this.enableSerial) {
        this.transferQty = this.qtyInfo.validQty;
        this.qtyScanDisabled = true;
      }
    },
    toLocationCode: function () {
      const site = [this.SERIAL_INV_CTRL];
      this.serialStockProcessDtoList = [];
      this.refreshFlag = true;
      this.enableSerial = site.includes(this.skuInfo.serialType);
      if (this.enableSerial) {
        this.qtyScanDisabled = true;
      }
    },
    toParentCidCode: function (val, oldval) {
      if (oldval !== '' && val === '' && this.LocationIsParentLocation) {
        this.toLocationCode = null;
        this.toLocationId = null;
      }
      if (oldval !== '' && val === '') {
        this.toParentCidCode = null;
        this.toParentCidId = null;
      }
    }
  },
  created() {
    const { customObj, hideControl } = this.handleCustomData(storage.get('customData'));
    this.customData = customObj;
    this.hideControl = hideControl;
    // console.log('库存转移customData', Object.assign({}, this.customData));
  },
  async mounted() {
    this.inputFieldList = [
      { type: 'lov', required: false, ref: 0, bind: 'fromCidCode', field: 'fromCidCode' },
      { type: 'text', required: false, ref: 5, bind: 'fromParentCidCode', field: 'fromParentCidCode' },
      { type: 'lov', required: true, ref: 10, bind: 'fromLocationCode', field: 'fromLocationCode' },
      { type: 'lov', required: true, ref: 20, bind: 'sku', field: 'sku' },
      { type: 'text', bind: 'goodsName', field: 'goodsName' },
      { type: 'text', bind: 'uomCode', field: 'uomCode' },
      { type: 'lov', required: true, ref: 30, bind: 'batchCode', field: 'batchCode' },
      { type: 'text', bind: 'qty', field: 'qty' },
      { type: 'text', bind: 'validQty', field: 'validQty' },
      { type: 'lov', required: false, ref: 40, bind: 'toCidCode', field: 'toCidCode' },
      { type: 'lov', required: false, ref: 45, bind: 'toParentCidCode', field: 'toParentCidCode' },
      { type: 'lov', required: true, ref: 50, bind: 'toLocationCode', field: 'toLocationCode' },
      // { type: 'input', required: true, ref: 60, bind: 'serialNumber', isHide: !this.enableSerial },
      { type: 'number', required: true, ref: 60, bind: 'transferQty', field: 'transferQty' },
    ];
    this.urlData = this.handleData('STOCK_TRANSFER_CONFIRM');
    this.parentCidEnabledShow();
    this.skipNext(0);
  },

  activated() {
    // 从序列号明细页返回
    this.refreshFlag = false;
    const selectedSerialNum = JSON.parse(sessionStorage.getItem('totalSerialNum'));
    const serialNumberDtoList = JSON.parse(sessionStorage.getItem('serialNumberDtoList'));
    this.transferQty = selectedSerialNum;
    if (this.transferQty > this.qtyInfo.validQty) {
      this.$hint.showFailed(this.$i18n.t('message.qtyOverrunReEnterSerial'), true);
    }
    this.serialAllowSubmit = this.transferQty <= this.qtyInfo.validQty;
    this.serialStockProcessDtoList = serialNumberDtoList;
    sessionStorage.removeItem('totalSerialNum');
    sessionStorage.removeItem('serialNumberDtoList');
  },

  beforeRouteLeave(to, from, next) {
    if (to.name === 'inputSerial') {
      this.$store.commit('pushKeepAlives', 'StockTransfer');
    } else {
      this.$store.commit('deleteKeepAlives', 'StockTransfer');
      this.$store.commit('deleteKeepAlives', 'IpsSerial');
    }
    this.$nextTick(() => {
      next();
    });
  },
};
</script>

<style lang="stylus" scoped>
@import '~@/style/var.styl';

.input-button {
  margin: 5px 0 10px 0;

  .hips-button--primary {
    height: 35px;
    line-height: 20px;
  }
}
</style>

