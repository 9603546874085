<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.acceptDetail')" :is-index="false" />
    </div>
    <div class="ips-container">
      <ips-page-head :title-info="{billCode, billTypeName}" :content-info="billData" />

      <hips-scroll>
        <ips-page-card
          v-for="(item, index) in lineData"
          :key="index"
          :title-info="{sku: item.sku}"
          :react-field="['lineNum', 'uomName', 'planQty', 'receiveQty', 'unReceiveQty']"
          :item="{...item, unReceiveQty: item.planQty - item.receiveQty}"
        >
          <ips-message-tip :message="item.goodsName" />
        </ips-page-card>
      </hips-scroll>
    </div>
  </hips-view>
</template>

<script>
  import {checkQty, inboundOrderLines} from "../api";
  export default {
    name: 'AcceptanceDetail',
    data() {
      return {
        billId: '', //入库单id
        billCode: '', //入库单号
        billTypeName: '',
        billData: {
          // billId: this.billId,
          planQty: '',
          receivedQty: '',
          unreceiveQty: '',
        },
        lineData: [],
      };
    },

    methods: {
      /**
       * 获取数据
       */
      async getBillData() {
        try {
          this.$hint.showLoading();
          // let url = `${this.winvBaseUrl}inbound-orders`;
          // let data = {
          //   params: {
          //     id: this.billId,
          //   },
          // };
          // const res = await this.$http.get(url, data);
          const res = await checkQty(this.billId);
          const resData = res.content[0];
          const { planQty, receiveQty, billTypeName } = resData;
          this.billTypeName = billTypeName;
          this.billData.planQty = planQty;
          this.billData.receivedQty = receiveQty;
          this.billData.unreceiveQty = planQty - receiveQty;
          await this.getLineNum();
        } catch (e) {
          console.log(e);
        }finally {
          this.$hint.hideLoading();
        }
      },

      async getLineNum() {
        try {
          // let url = `${this.winvBaseUrl}inbound-order-lines`;
          let data = {
            params: {
              billId: this.billId,
            },
          };
          // const res = await this.$http.get(url, data);
          const res = await inboundOrderLines(data);
          this.lineData = res.content;
          // console.log('====lr===', this.lineData);
        } catch (e) {
          console.log(e);
        }
      },
      //返回
      goBack() {
        this.$router.backPage();
      },
    },
    mounted() {
      const {billId, billCode} = this.$route.params;
      this.billId = billId;
      this.billCode = billCode;
      this.getBillData();
    },
  };
</script>

<style lang="stylus" scoped>
 @import '~@/style/var.styl'
  .acceptance-detail-item {
    border-top: 2px solid #e8e8e8
  }
  .scroll_box {
    position: absolute;
    margin-top 108.97px
    height calc(100% - 109px);
    width: 96%
    padding-bottom: 10px
  }

  .card_box {
    margin 10px 5px 16px 5px !important 
  }
</style>
