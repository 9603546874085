import request from '@/request';
import mixin  from '@/mixin/mixin';

export function checkCid(data) {
    let url = `${mixin.data().winvBaseUrl}inbound-receipt-records/check-cid`;
    return request.post(url, data)
}

export function checkCidBlur(data) {
    let url = `${mixin.data().winvBaseUrl}app-inbound-receive/check-cid`;
    return request.post(url, data)
}

// 序列号失焦验证
export function serialReceive(data) {
  let url = `${mixin.data().winvBaseUrl}app-inbound/serial-receive`;
  return request.post(url, data)
}

// 提交
export function submitReceive(data) {
  let url = `${mixin.data().winvBaseUrl}app-inbound/receive`;
  return request.post(url, data)
}

export function queryReceiptInfo(data) {
  let url = `${mixin.data().winvBaseUrl}app-inbound-receive/query-receipt-info`;
  return request.get(url, data);
}

// 批次校验
export function checkBatchs(data) {
  let url = `${mixin.data().wmdmBaseUrl}batchs/check`;
  return request.get(url, data);
}

// 获取物料
export function queryInboundLines(data) {
  let url = `${mixin.data().winvBaseUrl}app-inbound/query-inbound-lines-info`;
  return request.get(url, data);
}

// 来源单号失去焦点校验
export function fromBillBlur(data) {
  let url = `${mixin.data().winvBaseUrl}inbound-orders`;
  return request.get(url, data)
}

// 判断所有行收货完成
export function checkQty(id) {
    let url = `${mixin.data().winvBaseUrl}inbound-orders?id=${id}`;
    return request.get(url)
}

// 获取行数据
export function inboundOrderLines(data) {
  let url = `${mixin.data().winvBaseUrl}inbound-order-lines`;
  return request.get(url, data);
}

// 获取页面功能参数
export function queryPageFunction(data) {
  let url = `${mixin.data().winvBaseUrl}wms-common/query-page-params`;
  return request.get(url, data);
}

// 检验托盘与接收号是否是1v1对应关系
export function checkCidCode(data) {
  let url = `${mixin.data().winvBaseUrl}app-inbound-receive/check-receipt-cid-code`
  return request.get(url, data);
}

// 检验托盘是否为空托盘
export function checkCidNull(data) {
  let url = `${mixin.data().winvBaseUrl}app-inbound-receive/check-cid-code`
  return request.get(url, data);
}

// 检验接收号是否存在
export function checkReceiptNum(data) {
  let url = `${mixin.data().winvBaseUrl}app-inbound-receive/check-receipt-number`
  return request.get(url, data);
}

// 自动生成接收号
export function autoCreateReceiptNum(data) {
  let url = `${mixin.data().winvBaseUrl}app-inbound-receive/get-receipt-number`
  return request.get(url, data);
}
// 查询库区字符串
export function queryWhareaStr(data) {
  let url = `${mixin.data().winvBaseUrl}inbound-order-lines/find-inbound-wharea-ids`;
  return request.get(url, data);
}

// 收货成功物料校验
export function successCheckItem(data) {
  let url = `${mixin.data().winvBaseUrl}app-quick-inbound/query-return-type-receive`;
  return request.get(url, data);
}

// 校验父托盘是否为空托盘或者接收中
export function checkParentCidCode(data) {
    let url = `${mixin.data().winvBaseUrl}app-inbound-receive/check-parent-container`;
    return request.post(url, data);
}

// 校验父托盘和子托盘的关系
export function checkParentCidAndCid(data) {
    let url = `${mixin.data().winvBaseUrl}app-inbound-receive/check-outermost-container`;
    return request.post(url, data);
}

// 查询预打包托盘详情
export function queryPrePackCidDetail(data) {
  let url = `${mixin.data().winvBaseUrl}inbound-receipt-records/query-prepackaged-receipt`
  return request.get(url, data);
}

