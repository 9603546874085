import StockQuery from '../views/stock-query'
import StockQueryDetail from '../views/stock-query-detail'
import WhareaSku from '../views/wharea-sku'
import WhareaLocation from '../views/wharea-location'
import WhareaDetail from '../views/wharea-detail'
import LocationSku from '../views/location-sku'
import LocationDetail from '../views/location-detail'
import SkuWarehouse from '../views/sku-warehouse'
import SkuWharea from '../views/sku-wharea'
import SkuLocation from '../views/sku-location'
import SkuDetail from '../views/sku-detail'
import BatchWarehouse from '../views/batch-warehouse'
import BatchWharea from '../views/batch-wharea'
import BatchLocation from '../views/batch-location'
import BatchDetail from '../views/batch-detail'
import SerialDetail from '../views/serial-detail'
import ContainerDetail from '../views/container-detail'


let route = [{
    path: '/new-stock-query',
    name: 'StockQuery',
    component: StockQuery,
  },
  {
    path: '/stock-queryDetail',
    name: 'StockQueryDetail',
    component: StockQueryDetail,
  },
  {
    path: '/wharea-sku',
    name: 'WhareaSku',
    component: WhareaSku,
  },
  {
    path: '/wharea-location',
    name: 'WhareaLocation',
    component: WhareaLocation,
  },
  {
    path: '/wharea-detail',
    name: 'WhareaDetail',
    component: WhareaDetail,
  },
  {
    path: '/location-sku',
    name: 'LocationSku',
    component: LocationSku,
  },
  {
    path: '/location-detail',
    name: 'LocationDetail',
    component: LocationDetail,
  },
  {
    path: '/sku-warehouse',
    name: 'SkuWarehouse',
    component: SkuWarehouse,
  },
  {
    path: '/sku-wharea',
    name: 'SkuWharea',
    component: SkuWharea,
  },
  {
    path: '/sku-location',
    name: 'SkuLocation',
    component: SkuLocation,
  },
  {
    path: '/sku-detail',
    name: 'SkuDetail',
    component: SkuDetail,
  },
  {
    path: '/batch-warehouse',
    name: 'BatchWarehouse',
    component: BatchWarehouse,
  },
  {
    path: '/batch-wharea',
    name: 'BatchWharea',
    component: BatchWharea,
  },
  {
    path: '/batch-location',
    name: 'BatchLocation',
    component: BatchLocation,
  },
  {
    path: '/batch-detail',
    name: 'BatchDetail',
    component: BatchDetail,
  },

  {
    path: '/serial-detail',
    name: 'SerialDetail',
    component: SerialDetail,
  },
  {
    path: '/container-detail',
    name: 'ContainerDetail',
    component: ContainerDetail,
  },
];

export default route