<template>
  <div class="no-data">
    <div class="no-data-content">
      <img :src="getIcon()" alt="No Data">
    </div>
    <div class="no-data-text">
      {{ $t('title.nodata') }}
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
function exception() {
  return {
    'no-data': {
      icon: 'no-data',
      text: this.$t('exception.noData')
    },
  }
}
export default{
  name: 'NoData',
  props: {
    type: {
      type: String,
      default: 'no-data'
    }
  },
  methods: {
    getIcon () {
      return require('../assets/no-data.png')
    }
  },
  data () {
    return {
      exceptionItem: {
        icon: 'no-data',
        text: this.$t('exception.noData')
      }
    }
  },
  mounted () {
    this.exceptionItem = exception.call(this)
  }
}
</script>
<style lang="stylus">
 @import '~@/style/var.styl'
  .no-data {
    padding-top: 50px;
  .no-data-content {
    width: 90px;
    height: 90px;
    margin-left: auto;
    margin-right: auto;
  img {
    width: 90px;
    height: 90px;
  }
  }
  .no-data-text {
    margin-top: 5px;
    font-size: 14px;
    color: #959595;
    text-align: center;
  }
  }
</style>
