var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: { title: _vm.$t("title.unloading") },
          on: { beforeBack: _vm.clearStorage },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["loadCidCode"]
                ? _vm.hideControl["loadCidCode"]
                : true,
              expression:
                "customData['loadCidCode'] ? hideControl['loadCidCode'] : true",
            },
          ],
          ref: "ref0",
          attrs: {
            label: _vm.$t("label.toCidCode"),
            title: _vm.$t("label.toCidCode"),
            required: "",
            params: "loadCidCode",
            url:
              _vm.winvBaseUrl + "app-outbound-unload/query-unload-containers",
            "pre-parse-url": _vm.urlData["loadCidCode"],
            custom: _vm.customData["loadCidCode"],
            "source-parse-field": _vm.sourceParseField,
            "is-only": "",
          },
          on: {
            choose: _vm.handleChoose,
            enter: function ($event) {
              return _vm.skipNext("submitRef")
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.loadCidCode,
            callback: function ($$v) {
              _vm.loadCidCode = $$v
            },
            expression: "loadCidCode",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-footer" },
      [
        _c(
          "hips-button",
          {
            ref: "submitRef",
            attrs: { size: "large" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonSubmit.apply(null, arguments)
              },
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleCommonSubmit.apply(null, arguments)
              },
              keypress: function ($event) {
                $event.preventDefault()
              },
            },
          },
          [
            _c("svg-icon", {
              attrs: { slot: "icon", "icon-class": "success" },
              slot: "icon",
            }),
            _vm._v(_vm._s(_vm.$t("button.confirm")) + "\n    "),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }