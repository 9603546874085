<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header
        :title="transferType === 'PACK' ? $t('title.pallet') : $t('title.subinventoryTransfer')"
        :is-index="false"
      />
    </div>
    <div class="header-row">
      <div class="header-title">
        {{ skuData.sku }}
      </div>
    </div>
    <div class="ips-container">
      <div class="menu-row">
        <div class="menu-title">
          {{ $t('label.goodsName') }}：
        </div>
        <div class="menu-value">
          {{ skuData.goodsName }}
        </div>
      </div>
      <div class="menu-row">
        <div class="menu-title">
          {{ $t('label.unit') }}：
        </div>
        <div class="menu-value">
          {{ skuData.uomName }}
        </div>
      </div>
      <div :class="isbatch ? 'card_batch_subtrans' : 'card_subtrans'">
        <div class="row">
          <div class="title">
            {{ $t('label.transferFrom') }}
          </div>
          <svg-icon
            slot="icon"
            icon-class="arrow"
          />
        </div>
        <!-- 起始托盘 -->
        <ips-lov
          :label="$t('label.startOfTray')"
          :title="$t('label.startOfTray')"
          v-show="customData['fromCidCodeBySku'] ? hideControl['fromCidCodeBySku']: true"
          v-model="fromCidCodeBySku"
          :disabled="transferType === 'PACK'"
          params="cidCode"
          :url="`${winvBaseUrl}new-stock-trans/query-container-from`"
          :url-params="cidParam"
          :pre-parse-url="urlData['fromCidCodeBySku']"
          :custom="customData['fromCidCodeBySku']"
          :source-parse-field="sourceParseField"
          ref="ref0"
          @click.stop="handleCommonClick(0)"
          @clickIcon="handleCommonClick(0, 'lov')"
          @enter="handleCidEnter"
          @down="handleCidEnter"
          @tab="handleCidTab"
          @choose="handleChoose('fromCidCodeBySku', $event)"
          @setSourceField="saveSourceField($event)"
          @valuedByFoucs="valuedByFoucs($event)"
        />
        <!-- 起始父托盘 -->
        <ips-field
          :label="$t('label.startOfParentTray')"
          :title="$t('label.startOfParentTray')"
          v-show="parentCidEnabled.paramValue==1? fromParentCidCodeBySku:false"
          v-model="fromParentCidCodeBySku"
          disabled="true"
          params="fromParentCidCodeBySku"
          :url="`${winvBaseUrl}new-stock-trans/query-container-from`"
          :custom="customData['fromParentCidCodeBySku']"
          :source-parse-field="sourceParseField"
        />
        <!-- 起始库位 -->
        <ips-lov
          :label="$t('label.startOfLocation')"
          :title="$t('label.startOfLocation')"
          v-model="fromLocationCodeBySku"
          v-show="customData['fromLocationCodeBySku'] ? hideControl['fromLocationCodeBySku']: true"
          required
          params="locationCode"
          :url="`${winvBaseUrl}new-stock-trans/query-location-serial`"
          :url-params="locationParam"
          :pre-parse-url="urlData['fromLocationCodeBySku']"
          :custom="customData['fromLocationCodeBySku']"
          :source-parse-field="sourceParseField"
          ref="ref10"
          @click.stop="handleCommonClick(10)"
          @clickIcon="handleCommonClick(10, 'lov')"
          @enter="handleLocEnter"
          @down="handleLocEnter"
          @tab="handleLocTab"
          @choose="handleChoose('fromLocationCodeBySku', $event)"
          @setSourceField="saveSourceField($event)"
          @valuedByFoucs="valuedByFoucs($event)"
        />
        <!-- 起始批次 -->
        <ips-lov
          :label="$t('label.batch')"
          v-model="batchCodeBySku"
          v-show="isbatch ? (customData['batchCodeBySku'] ? hideControl['batchCodeBySku'] : isbatch) : false"
          :required="isbatch"
          :title="$t('label.batch')"
          params="batchCode"
          :url="`${winvBaseUrl}new-stock-trans/query-batch-from`"
          :url-params="batchParam"
          :pre-parse-url="urlData['batchCodeBySku']"
          :custom="customData['batchCodeBySku']"
          :source-parse-field="sourceParseField"
          ref="ref20"
          @click.stop="handleCommonClick(20)"
          @clickIcon="handleCommonClick(20, 'lov')"
          @enter="handleBatchEnter"
          @tab="handleBatchTab"
          @choose="handleChoose('batch', $event)"
          @setSourceField="saveSourceField($event)"
          @valuedByFoucs="valuedByFoucs($event)"
        />
      </div>
      <div class="card_countLocation">
        <div class="row">
          <svg-icon
            slot="icon"
            icon-class="arrow"
          />
          <div class="title">
            {{ $t('label.transferTo') }}
          </div>
        </div>
        <!-- 目标托盘 -->
        <ips-lov
          :label="$t('label.targetTray')"
          :title="$t('label.targetTray')"
          v-model="toCidCodeBySku"
          v-show="customData['toCidCodeBySku'] ? hideControl['toCidCodeBySku'] : true"
          :required="transferType === 'PACK'"
          params="cidCode"
          :url="`${winvBaseUrl}new-stock-trans/query-container-to`"
          :url-params="toCidParam"
          :pre-parse-url="urlData['toCidCodeBySku']"
          :custom="customData['batchCodeBySku']"
          :source-parse-field="sourceParseField"
          ref="ref30"
          @click.stop="handleCommonClick(30)"
          @clickIcon="handleCommonClick(30, 'lov')"
          @enter="handleToCidEnter"
          @down="handleToCidEnter"
          @tab="handleToCidTab"
          @choose="handleChoose('toCidCodeBySku', $event)"
          @setSourceField="saveSourceField($event)"
          @valuedByFoucs="valuedByFoucs($event)"
        />
        <!-- 目标父托盘 -->
        <ips-lov
          :label="$t('label.targetParentTray')"
          :title="$t('label.targetParentTray')"
          v-model="toParentCidCodeBySku"
          v-show="parentCidEnabled.paramValue==1? true:false"
          :disabled="isHasParentCid"
          params="cidCode"
          :url="`${winvBaseUrl}new-stock-trans/query-trans-to-parent-container`"
          :url-params="toCidParam"
          :pre-parse-url="urlData['toParentCidCodeBySku']"
          :custom="customData['batchCodeBySku']"
          :source-parse-field="sourceParseField"
          ref="ref35"
          @click.stop="handleCommonClick(35)"
          @clickIcon="handleCommonClick(35, 'lov')"
          @enter="handleToParentCidEnter"
          @down="handleToParentCidEnter"
          @tab="handleToParentCidTab"
          @choose="handleChoose('toParentCidCodeBySku', $event)"
          @setSourceField="saveSourceField($event)"
          @valuedByFoucs="valuedByFoucs($event)"
        />
        <!-- 目标库位 -->
        <ips-lov
          :label="$t('label.targetLocation')"
          :title="$t('label.targetLocation')"
          v-model="toLocationCodeBySku"
          v-show="customData['toLocationCodeBySku'] ? hideControl['toLocationCodeBySku']: true"
          required
          params="locationCode"
          :url="`${winvBaseUrl}new-stock-trans/query-location-to`"
          :url-params="toLocationParam"
          :pre-parse-url="urlData['toLocationCodeBySku']"
          :custom="customData['toLocationCodeBySku']"
          :source-parse-field="sourceParseField"
          ref="ref40"
          @click.stop="handleCommonClick(40)"
          @clickIcon="handleCommonClick(40, 'lov')"
          @enter="skipNext(50)"
          @down="skipNext(50)"
          @tab="skipNext(50)"
          @choose="handleChoose('toLocationCodeBySku', $event)"
          @setSourceField="saveSourceField($event)"
          @valuedByFoucs="valuedByFoucs($event)"
        />
        <!-- 转移数量 -->
        <ips-input-number
          v-model="transferQtyBySku"
          v-show="!enableSerial ? (customData['transferQtyBySku'] ? hideControl['transferQtyBySku'] : !enableSerial) : false"
          required
          not-auto-fill
          :label="$t('label.transferNumber')"
          :custom="customData['transferQtyBySku']"
          :source-parse-field="sourceParseField"
          ref="ref50"
          :min="null"
          :more-than-zero="true"
          @enter="skipNext('submitRef')"
          @down="skipNext('submitRef')"
          @tab="skipNext('submitRef')"
          @click.stop="handleCommonClick(50)"
          @blur="checkQtyBlur"
          @setSourceField="saveSourceField($event)"
          @valuedByFoucs="valuedByFoucs($event)"
        />
        <hips-row v-if="enableSerial">
          <div class="hip-row">
            <hips-col span="24">
              <ips-input-number
                v-model="transferQtyBySku"
                v-show="customData['transferQtyBySku'] ? hideControl['transferQtyBySku']: true"
                required
                :label="$t('label.transferNumber')"
                :custom="customData['transferQtyBySku']"
                :source-parse-field="sourceParseField"
                ref="ref50"
                :min="null"
                :more-than-zero="true"
                @enter="skipSerail"
                @down="skipSerail"
                @tab="skipSerail"
                @blur="checkQtyBlur"
                @setSourceField="saveSourceField($event)"
                @valuedByFoucs="valuedByFoucs($event)"
              />
            </hips-col>
          </div>

          <serialComp
            ref="refSerail"
            v-model="serialReceiveQty"
            :params="serialCompParams"
            @inboundSerialReceiptList="changeInboundSerialReceiptList"
          />
        </hips-row>
        <!--现有量、可用量-->
        <div class="row-num">
          <div class="row-50">
            <div class="title">
              {{ $t('label.availableQty') }}:
            </div>
            <div class="value">
              {{ qty }}
            </div>
          </div>
          <div class="row-50">
            <div class="title">
              {{ $t('label.currentUseQty') }}:
            </div>
            <div class="value">
              {{ validQty }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ips-footer">
      <hips-button
        size="large"
        @click.stop="handleCommonSubmit"
        @enter="handleCommonSubmit"
        ref="submitRef"
        :class="['ips-btn-normal']"
      >
        <svg-icon
          slot="icon"
          icon-class="success"
        />
        {{ $t('button.confirm') }}
      </hips-button>
    </div>
  </hips-view>
</template>

<script>
import { skuStockTrans, getSkuNumber, toParentCidCheck, getparentCidEnabled } from '../api';
import { Row, Col } from '@hips/vue-ui';
import { storage } from '@/utils';
import IpsField from '../../../public/components/Input/ipsField.vue';
import serialComp from '@/public/views/ipsNewSerialComp'
export default {
  name: 'SkuTransfer',
  components: {
    IpsField,
    [Row.name]: Row,
    [Col.name]: Col,
    serialComp
  },
  data() {
    return {
      skuData: '', // params参数
      isbatch: '', //批次控制
      enableSerial: '',
      fromCidIdBySku: '', // 起始托盘ID
      fromParentCidCodeBySku: '', // 起始父托盘
      fromParentCidIdBySku: '', // 起始父托盘ID
      fromCidCodeBySku: '', // 起始托盘
      fromLocationIdBySku: '', // 起始库位
      fromLocationCodeBySku: '', // 起始库位
      batchCodeBySku: '', // 批次
      batchIdBySku: '', //批次id
      transferType: '',
      toCidIdBySku: '', // 目标托盘ID
      toCidCodeBySku: '', // 目标托盘
      toParentCidIdBySku: '', // 目标父托盘ID
      toParentCidCodeBySku: '', // 目标父托盘
      toLocationIdBySku: '', // 目标库位
      toLocationCodeBySku: '', // 目标库位
      transferQtyBySku: '', // 转移数量
      warehouseId: '', // 仓库id
      qty: '',
      validQty: '',
      serialReceiveQty: 0,
      serialReceiveQtyImp: 0,
      serialStockProcessDtoList: [], // 序列号数据
      cidParam: {}, // 托盘lov参数
      locationParam: {}, // 库位lov参数
      batchParam: {}, // 批次lov参数
      toCidParam: {}, // 目标托盘参数
      toLocationParam: {}, // 目标库位参数
      urlData: {},
      customData: {}, // 个性化配置信息列表
      hideControl: {}, // 个性化配置显示隐藏控制
      sourceParseField: {}, // 界面中所有的来源解析字段
      isHasParentCid: true, //目标父托盘字段是否可以编辑
      isToCidCode: false, //目标库位是否可以编辑
      parentCidEnabled: {}, //页面参数是否显示父托盘字段
      LocationIsParentLocation: false,//目标库位是父托盘带出数据
      crossWhareaId: undefined, //允许跨库区库存转移 1：允许 0： 不允许
      serialCompParams: {},
    };
  },
  methods: {
    skipSerail() {
      this.goToSerialDetail()
      this.$refs.refSerail.$refs['ref0'].focus()
    },
    changeInboundSerialReceiptList(list) {
      this.serialStockProcessDtoList = list
    },
    /**
     * 获取params传参
     */
    async getParamsData() {
      const { item, transferType, sourceParseField, customData, hideControl } = this.$route.params;
      this.skuData = item || this.savaRouteData.item;
      this.transferType = transferType;
      this.sourceParseField = sourceParseField;
      this.customData = customData;
      this.hideControl = hideControl;
      this.isbatch = item === undefined ? false : item.isbatch === 1 ? true : false;
      this.enableSerial = item === undefined ? false : item.serialType === this.SERIAL_INV_CTRL;
      this.cidParam = {
        sku: this.skuData.sku,
        goodsId: this.skuData.id,
      };
      this.batchParam = {
        cidId: this.fromCidIdBySku,
        cidCode: this.fromCidCodeBySku,
        locationId: this.fromLocationIdBySku,
        locationCode: this.fromLocationCodeBySku,
        sku: this.skuData.sku,
        goodsId: this.skuData.id,
      };
      try {
        this.$hint.showLoading();
        const data = {
          params: {
            sku: this.skuData.sku,
            goodsId: this.skuData.id,
          }
        };
        const res = await getSkuNumber(data);
        this.qty = res.content[0].qty;
        this.validQty = res.content[0].validQty;
      } finally {
        this.$hint.hideLoading();
      }
    },
    /**
     *@functionName: saveSourceField
     *@description: 保存来源解析字段并赋值Enter
     *@params1: fieldObj[Object] 来源解析字段对象,
     fieldCode[String] 来源解析字段
     parseValue[String] 二维码解析值
     subFields[Array]] 关联子字段
     */
    saveSourceField(fieldObj) {
      this.saveSourceParseField(this, fieldObj);
    },
    /**
     * 字段聚焦时赋值操作
     */
    async valuedByFoucs(options = {}) {
      const { custom = {}, currentValue } = options;
      await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
    },
    /**
     * lov选中事件
     * @param {String} type - 定位lov
     * @param {object} obj - 选中行的数据
     */
    handleChoose(type, obj) {
      switch (type) {
        case 'fromCidCodeBySku':
          this.fromCidIdBySku = obj.cidId;
          this.fromCidCodeBySku = obj.cidCode;
          this.fromParentCidIdBySku = obj.parentCidId;
          this.fromParentCidCodeBySku = obj.parentCidCode;
          this.fromLocationIdBySku = obj.locationId;
          this.fromLocationCodeBySku = obj.locationCode;
          this.batchCodeBySku = obj.batchCode;
          this.batchIdBySku = obj.batchId;
          this.qty = obj.qty;
          this.validQty = obj.validQty;
          this.warehouseId = obj.warehouseId;
          this.locationParam = {
            goodsId: this.skuData.id,
            sku: this.skuData.sku,
            cidId: obj.cidId,
          };
          this.batchParam = {
            cidId: this.fromCidIdBySku,
            cidCode: this.fromCidCodeBySku,
            locationId: this.fromLocationIdBySku,
            locationCode: this.fromLocationCodeBySku,
            sku: this.skuData.sku,
            goodsId: this.skuData.id,
          };
          this.crossWhareaId = obj.crossWhareaTransferFlag === 0 ? obj.whareaId : undefined;
          this.toCidParam = {
            warehouseId: this.warehouseId,
            whareaId: this.crossWhareaId,
          };
          this.toLocationParam = {
            warehouseId: this.warehouseId,
            whareaId: this.crossWhareaId,
          };
          break;
        case 'fromLocationCodeBySku':
          this.fromLocationIdBySku = obj.locationId;
          this.fromLocationCodeBySku = obj.locationCode;
          if (this.transferType === 'PACK') {
            this.toLocationIdBySku = obj.locationId;
            this.toLocationCodeBySku = obj.locationCode;
          }
          this.batchCodeBySku = obj.batchCode;
          this.batchIdBySku = obj.batchId;
          this.qty = obj.qty;
          this.validQty = obj.validQty;
          this.warehouseId = obj.warehouseId;
          this.batchParam = {
            cidId: this.fromCidIdBySku,
            cidCode: this.fromCidCodeBySku,
            locationId: this.fromLocationIdBySku,
            locationCode: this.fromLocationCodeBySku,
            sku: this.skuData.sku,
            goodsId: this.skuData.id,
          };
          this.crossWhareaId = obj.crossWhareaTransferFlag === 0 ? obj.whareaId : undefined;
          this.toCidParam = {
            warehouseId: this.warehouseId,
            whareaId: this.crossWhareaId,
          };
          this.toLocationParam = {
            warehouseId: this.warehouseId,
            whareaId: this.crossWhareaId,
          };
          break;
        case 'batch':
          this.batchCodeBySku = obj.batchCode;
          this.batchIdBySku = obj.batchId;
          this.qty = obj.qty;
          this.validQty = obj.validQty;
          break;
        case 'toCidCodeBySku':
          this.toCidIdBySku = obj.cidId;
          this.toCidCodeBySku = obj.cidCode;
          if (obj.cidStatus === 'IN_STOCK' && this.fromCidCodeBySku !== obj.cidCode) {
            this.toLocationParam = {
              cidId: obj.cidId,
              cidStatus: obj.cidStatus,
              warehouseId: this.warehouseId,
              whareaId: this.crossWhareaId,
            };
          } else {
            this.toLocationParam = {
              cidStatus: obj.cidStatus,
              warehouseId: this.warehouseId,
              whareaId: this.crossWhareaId,
            };
          }
          if (obj.locationId && this.fromCidCodeBySku !== obj.cidCode) {
            this.toLocationIdBySku = obj.locationId;
            this.toLocationCodeBySku = obj.locationCode;
          }
          this.toParentCidIdBySku = obj.parentCidId;
          this.toParentCidCodeBySku = obj.parentCidCode;
          if (this.toCidIdBySku !== undefined) {
            this.isHasParentCid = false;
          }
          break;
        case 'toParentCidCodeBySku':
          this.toParentCidIdBySku = obj.cidId;
          this.toParentCidCodeBySku = obj.cidCode;
          if (!this.toLocationCodeBySku && obj.locationId && this.toCidCodeBySku !== '' && this.fromParentCidCodeBySku !== obj.cidCode) {
            this.toLocationIdBySku = obj.locationId;
            this.toLocationCodeBySku = obj.locationCode;
            this.LocationIsParentLocation = true;
          }
          this.chekckToParentCidCode();
          break;
        case 'toLocationCodeBySku':
          this.toLocationIdBySku = obj.locationId;
          this.toLocationCodeBySku = obj.locationCode;
          break;
        default:
          return null;
      }
    },
    /**
    * 校验目标父托盘字段
    */
    async chekckToParentCidCode() {
      try {
        const data = {
          locationId: this.toLocationIdBySku,
          locationCode: this.toLocationCodeBySku,
          cidId: this.toCidIdBySku,
          cidCode: this.toCidCodeBySku,
          parentCidId: this.toParentCidIdBySku,
          parentCidCode: this.toParentCidCodeBySku,
        };
        const res = await toParentCidCheck(data);
        if (res && res.failed) {
          this.skipNext(35);
        } else {
          if (res.locationCode && !(this.fromParentCidCodeBySku === res.parentCidCode && this.fromCidCodeBySku === res.cidCode)) {
            this.toLocationIdBySku = res.locationId;
            this.toLocationCodeBySku = res.locationCode;
            this.isToCidCode = true;
          }
        }
      } catch (e) {
        this.skipNext(35);
      }
    },
    /**
     * 起始托盘enter事件
     */
    handleCidEnter() {
      if ((this.fromLocationIdBySku && this.batchIdBySku) || (this.fromLocationIdBySku && !this.batchIdBySku && !this.isbatch)) {
        this.skipNext(30)
      } else if (this.fromLocationIdBySku && !this.batchIdBySku && this.isbatch) {
        this.skipNext(20)
      } else {
        this.skipNext(10)
      }
    },
    /**
     * 起始托盘tab事件
     */
    handleCidTab() {
      if ((this.fromLocationIdBySku && this.batchIdBySku) || (this.fromLocationIdBySku && !this.batchIdBySku && !this.isbatch)) {
        this.skipNext(30)
      } else if (this.fromLocationIdBySku && !this.batchIdBySku && this.isbatch) {
        this.skipNext(20)
      } else {
        this.skipNext(10)
      }
    },
    monitorCid() {
      if (!this.fromCidCodeBySku) {
        this.fromParentCidCodeBySku = ''
      }
      if (!this.toCidCodeBySku) {
        this.toParentCidCodeBySku = '';
        this.toLocationCodeBySku = '';
      }
      if (!this.toParentCidCodeBySku && this.LocationIsParentLocation) {
        this.toLocationCodeBySku = '';
      }
    },
    /**
     * 起始库位enter事件
     */
    handleLocEnter() {
      if (this.batchIdBySku || (!this.batchIdBySku && !this.isbatch)) {
        if (this.transferType !== 'UNPACK') {
          this.skipNext(30)
        } else {
          this.skipNext(40)
        }
      } else if (!this.batchIdBySku && this.isbatch) {
        this.skipNext(20)
      }
    },
    /**
     * 起始库位tab事件
     */
    handleBatchEnter() {
      if (this.transferType !== 'UNPACK') {
        this.skipNext(30)
      } else {
        this.skipNext(40)
      }
    },
    /**
     * 起始库位tab事件
     */
    handleBatchTab() {
      if (this.transferType !== 'UNPACK') {
        this.skipNext(30)
      } else {
        this.skipNext(40)
      }
    },
    /**
     * 起始库位tab事件
     */
    handleLocTab() {
      if (this.batchIdBySku || (!this.batchIdBySku && !this.isbatch)) {
        if (this.transferType !== 'UNPACK') {
          this.skipNext(30)
        } else {
          this.skipNext(40)
        }
      } else if (!this.batchIdBySku && this.isbatch) {
        this.skipNext(20)
      }
    },
    /**
     * 目标托盘enter事件
     */
    handleToCidEnter() {
      if (this.isHasParentCid) {
        if (this.parentCidEnabled.paramValue !== '1') {
          this.skipNext(40);
        } else {
          this.skipNext(40);
        }
      } else {
        if (this.parentCidEnabled.paramValue !== '1') {
          this.skipNext(40);
        } else {
          this.skipNext(35);
        }
      }
    },
    handleToCidTab() {
      if (this.isHasParentCid) {
        if (this.parentCidEnabled.paramValue !== '1') {
          this.skipNext(50);
        } else {
          this.skipNext(40);
        }
      } else {
        if (this.parentCidEnabled.paramValue !== '1') {
          this.skipNext(40);
        } else {
          this.skipNext(35);
        }
      }
    },
    /**
     * 目标父托盘enter事件
     */
    handleToParentCidEnter() {
      if (this.toLocationIdBySku) {
        this.skipNext(50);
      } else {
        this.skipNext(40);
      }
    },
    handleToParentCidTab() {
      if (this.toLocationIdBySku) {
        this.skipNext(50);
      } else {
        this.skipNext(40);
      }
    },

    /**
     * 转移数量blur事件
     */
    checkQtyBlur() {
      const { transferQtyBySku } = this;
      if (transferQtyBySku > this.validQty) {
        // this.$hint.showFailed('转移数量不能大于可用量');
        this.$hint.showFailed(this.$i18n.t('message.qtyMustBeSmallerThanValidQty'), true);
        this.skipNext(50);
      }
    },

    async parentCidEnabledShow() {
      try {
        this.$hint.showLoading();
        const data = {
          params: {
            pageId: storage.get('menuId'),
            device: "PDA",
            functionCode: "PDA_INV_STOCK_TRANSFER",
            paramCode: "PARENT_CID_ENABLE",
          }
        };
        const res = await getparentCidEnabled(data);
        this.parentCidEnabled = res;
      } finally {
        this.$hint.hideLoading();
      }
    },

    /**
     * 子库存转移
     */
    async handleButtonSubmit() {
      if (!this.fromLocationCodeBySku || (this.isbatch && !this.batchCodeBySku) || (this.transferType === 'PACK' && !this.toCidCodeBySku) || !this.toLocationCodeBySku || !this.transferQtyBySku) {
        this.$hint.showFailed(this.$i18n.t('message.checkRequired'), true);
        return
      }
      try {
        this.$hint.showLoading();
        this.handleSubmitStatus();
        let param = {
          sku: this.skuData.sku,
          goodsId: this.skuData.id,
          fromCidId: this.fromCidIdBySku,
          fromCidCode: this.fromCidCodeBySku,
          fromLocationId: this.fromLocationIdBySku,
          fromLocationCode: this.fromLocationCodeBySku,
          batchId: this.batchIdBySku,
          batchCode: this.batchCodeBySku,
          toLocationId: this.toLocationIdBySku,
          toLocationCode: this.toLocationCodeBySku,
          toCidId: this.toCidIdBySku,
          toCidCode: this.toCidCodeBySku,
          toParentCidId: this.toParentCidIdBySku,
          toParentCidCode: this.toParentCidCodeBySku,
          transferQty: this.transferQtyBySku,
          qty: this.transferQtyBySku,
          serialStockProcessDtoList: this.serialStockProcessDtoList,
        };
        await skuStockTrans(param);
        this.$hint.showSuccess();
        this.dataInit();
        this.$refs['refSerail'].tableData = [];
        await this.clearSourceFields(this, true);
        this.skipNext(0);
      } finally {
        this.$hint.hideLoading();
        this.handleSubmitStatus();
      }
    },
    dataInit() {
      this.fromCidIdBySku = ''; // 起始托盘ID
      this.fromCidCodeBySku = ''; // 起始托盘
      this.fromParentCidIdBySku = ''; // 起始托盘ID
      this.fromParentCidCodeBySku = ''; // 起始托盘
      this.fromLocationIdBySku = ''; // 起始库位
      this.fromLocationCodeBySku = ''; // 起始库位
      this.batchCodeBySku = ''; // 批次
      this.batchIdBySku = ''; //批次id
      this.toCidIdBySku = ''; // 目标托盘ID
      this.toCidCodeBySku = ''; // 目标托盘
      this.toParentCidIdBySku = ''; // 目标托盘ID
      this.toParentCidCodeBySku = ''; // 目标托盘
      this.toLocationIdBySku = ''; // 目标库位
      this.toLocationCodeBySku = null; // 目标库位
      this.transferQtyBySku = ''; // 转移数量
      this.qty = '';
      this.validQty = '';
      this.serialReceiveQty = 0;
      this.serialReceiveQtyImp = 0;
      this.serialStockProcessDtoList = []; // 序列号数据
      this.isHasParentCid = true; //目标父托盘字段是否可以编辑
      this.isToCidCode = false; //目标库位是都可以编辑
      this.cidParam = {
        sku: this.skuData.sku,
        goodsId: this.skuData.id,
      };
      this.locationParam = {
        cidId: '',
        goodsId: this.skuData.id,
        sku: this.skuData.sku,
      };
      this.batchParam = {}; // 批次lov参数
      this.toLocationParam = {}; // 目标库位参数
    },
    /**
     * 序列号录入
     */
    async goToSerialDetail() {
      if (this.transferQtyBySku > 0) {
        let data = {
          cidId: this.fromCidIdBySku,
          cidCode: this.fromCidCodeBySku,
          goodsId: this.skuData.id,
          sku: this.skuData.sku,
          locationId: this.fromLocationIdBySku,
          locationCode: this.fromLocationCodeBySku,
          batchId: this.batchIdBySku,
          batchCode: this.batchCodeBySku,
        };
        // 保留上个界面的传值
        const { item, transferType, sourceParseField, customData, hideControl } = this.$route.params;

        let serialUrl = 'new-stock-trans/query-serial-list';
        const param = {
          name: 'serialComponent',
          params: {
            data,
            serialUrl,
            receiveQty: this.transferQtyBySku,
            serialType: this.skuData.serialType,
            task: this.serialStockProcessDtoList || [],
            // 上个界面的存值
            item, transferType, sourceParseField, customData, hideControl
          },
        };
        this.batchParam = {}; // 批次lov参数
        // this.$router.pushPage(param);
        this.serialCompParams = param.params
      }
    },

  },
  async mounted() {
    await this.getParamsData();
    this.inputFieldList = [
      { type: 'lov', required: false, ref: 0, bind: 'fromCidCodeBySku', field: 'fromCidCodeBySku' },
      { type: 'lov', required: true, ref: 10, bind: 'fromLocationCodeBySku', field: 'fromLocationCodeBySku' },
      { type: 'lov', required: true, ref: 20, bind: 'batchCodeBySku', field: 'batchCodeBySku' },
      { type: 'lov', required: this.transferType === 'PACK', ref: 30, bind: 'toCidCodeBySku', field: 'toCidCodeBySku' },
      { type: 'lov', required: false, ref: 35, bind: 'toParentCidCodeBySku', field: 'toParentCidCodeBySku' },
      { type: 'lov', required: true, ref: 40, bind: 'toLocationCodeBySku', field: 'toLocationCodeBySku' },
      { type: 'number', required: true, ref: 50, bind: 'transferQtyBySku', field: 'transferQtyBySku' },
    ];
    this.locationParam = {
      cidId: '',
      goodsId: this.skuData.id,
      sku: this.skuData.sku,
    };
    this.urlData = this.handleData('STOCK_SKU_CONFIRM');
    this.parentCidEnabledShow();
    if (this.transferType === 'PACK') {
      this.skipNext(10);
    } else {
      this.skipNext(0);
    }

  },
  activated() {
    const serialStockProcessDtoList = JSON.parse(sessionStorage.getItem('inboundSerialReceiptList'));
    this.serialStockProcessDtoList = serialStockProcessDtoList;
    const serialReceiveQty = JSON.parse(sessionStorage.getItem('serialReceiveQty'));
    this.serialReceiveQty = serialReceiveQty;
    this.serialReceiveQtyImp = serialReceiveQty;
    sessionStorage.removeItem('inboundSerialReceiptList');
    sessionStorage.removeItem('serialReceiveQty');
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'serialComponent') {
      const { item, transferType, sourceParseField, customData, hideControl } = this.$route.params;
      this.savaRouteData = { item, transferType, sourceParseField, customData, hideControl };
      this.$store.commit('pushKeepAlives', 'SkuTransfer');
    } else {
      this.$store.commit('deleteKeepAlives', 'SkuTransfer');
    }
    this.$nextTick(() => {
      next();
    });
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const _this = vm;
      if (from.name === 'serialComponent') {
        // 从序列号界面返回, 默认定位在数量字段
        _this.skipNext(50);
        _this.savaRouteData = {};
      }
    });
  },
  watch: {
    'fromCidCodeBySku': function (val, oldval) {
      if (oldval !== '' && val === null) {
        this.fromCidIdBySku = '';
        this.fromLocationIdBySku = '';
        this.fromLocationCodeBySku = '';
        this.locationParam = {
          cidId: '',
          goodsId: this.skuData.id,
          sku: this.skuData.sku,
        };
        this.batchParam = {};
        this.batchCodeBySku = '';
        this.batchIdBySku = '';
        this.qty = '';
        this.validQty = '';
        this.getParamsData();
      }
      if (!this.fromCidCodeBySku) {
        this.fromParentCidCodeBySku = ''
      }
    },
    'fromLocationCodeBySku': function (val, oldval) {
      if (oldval !== '' && val === null) {
        this.fromLocationIdBySku = '';
        this.batchParam = {};
        this.batchCodeBySku = '';
        this.batchIdBySku = '';
        this.qty = '';
        this.validQty = '';
        this.getParamsData();
      }
    },
    'toCidCodeBySku': function (val, oldval) {
      if (oldval !== '' && val === '') {
        this.toLocationParam = {
          warehouseId: this.warehouseId,
          whareaId: this.crossWhareaId,
        };
        this.isHasParentCid = true;
      }
      if (!this.toCidCodeBySku) {
        this.toParentCidCodeBySku = null;
        this.toLocationIdBySku = null;
        this.toLocationCodeBySku = null;
      }
    },
    'toParentCidCodeBySku': function (val, oldval) {
      if (oldval !== '' && val === '' && this.LocationIsParentLocation) {
        this.toLocationCodeBySku = null;
        this.toLocationIdBySku = null;
      }
      if (oldval !== '' && val === '') {
        this.toParentCidIdBySku = null;
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
@import '~@/style/var.styl';

.header-row {
  padding-top: 5px;
  display: flex;
  margin-bottom: 12px;

  .header-title {
    display: flex;
    align-items: center;
    height: 28px;
    background: #E6F7FF;
    color: var(--primaryColor);
    border-radius: 0px 32px 32px 0px;
    padding: 0 12px 0 8px;
    border-left: 4px solid var(--primaryColor);
  }
}

.ips-container {
  padding-top: 0 !important;

  .menu-row {
    display: flex;
    margin: 12px 32px 12px 12px;

    .menu-title {
      width: 40px;
      height: 16px;
      font-size: 12px;
      /* font-family:PingFangSC-Regular,PingFang SC; */
      font-weight: 200;
      color: #8C8C8C;
      line-height: 16px;
    }

    .menu-value {
      width: 261px;
      height: 16px;
      font-size: 12px;
      /* font-family:PingFangSC-Regular,PingFang SC; */
      font-weight: initial;
      color: #000000;
      line-height: 16px;
    }
  }

  .card_batch_subtrans {
    margin: 10px 0;
    padding: 12px 12px;
    height: flex;
    border-radius: 1.6vw;
    color: #000;
    box-shadow: 0 0 2.133vw #d9d9d9;
    border: none;
    font-size: 14px;
    background-color: #FFFFFF;

    .row {
      display: flex;
      padding: 0 0 0 0;

      .title {
        height: 16px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 200;
        color: var(--primaryColor);
        line-height: 18px;
      }
    }
  }

  .card_subtrans {
    margin: 10px 0;
    padding: 12px 12px;
    height: flex;
    border-radius: 1.6vw;
    color: #000;
    box-shadow: 0 0 2.133vw #d9d9d9;
    border: none;
    font-size: 14px;
    background-color: #FFFFFF;

    .row {
      display: flex;
      padding: 0 0 0 0;

      .title {
        height: 16px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: initial;
        color: var(--primaryColor);
        line-height: 18px;
      }
    }
  }

  .card_countLocation {
    margin: 10px 0;
    padding: 12px 12px;
    height: flex;
    border-radius: 1.6vw;
    color: #000;
    box-shadow: 0 0 2.133vw #d9d9d9;
    border: none;
    font-size: 14px;
    background-color: #FFFFFF;

    .hip-row {
      display: flex;
      padding: 10px 0 0 0;

      .button-detail {
        margin-left: 5px;
        margin-right: 5px;
        padding: 0 5px;
        background-color: var(--primaryColor);
        color: #FFFFFF;
        border: none;
        font-size: 14px;
        height: 36px;
        line-height: 36px;
      }
    }

    .row {
      display: flex;
      padding: 12px 0 0 0;

      .title {
        height: 16px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: initial;
        color: var(--primaryColor);
        line-height: 18px;
      }
    }

    .row-num {
      display: flex;
      align-items: center;
      margin-top: 5px;
      height: 25px;
      background: #E6F7FF;
      border-radius: 6px;
      border: 2px solid #91D5FF;

      .row-50 {
        width: 50%;
        display: flex;

        .title {
          width: 100px;
          height: 16px;
          padding-left: 5px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 200;
          color: #595959;
          line-height: 18px;
        }

        .value {
          width: 80px;
          height: 18px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: initial;
          color: #000000;
          line-height: 18px;
          overflow: hidden; /* 溢出隐藏 */
          white-space: nowrap; /* 规定文本不进行换行 */
          text-overflow: ellipsis; /* 当对象内文本溢出时显示省略标记（...） */
        }
      }
    }
  }
}

.ips-btn-normal {
  border-radius: 8px !important;
  margin: 12px 10px 12px 10px;
  background-color: var(--primaryColor) !important;
  color: #ffffff !important;
  font-size: 17px !important;
}
</style>
