// import Login from '../views/login'
import AssociatedSite from '../views/associated-site'
import AssociatedSiteDetail from '../views/associated-site-detail'

let route = [
  {
    path: '/sort-location-map',
    name: 'AssociatedSite',
    component: AssociatedSite,
  },
  {
    path:'/sort-location-map-detail',
    name:'AssociatedSiteDetail',
    component:AssociatedSiteDetail,
  },
]

export default route