<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.detailStock')" :is-index="false" />
    </div>
    <div class="header-row">
      <div class="header-title">
        {{ serialData.serialNumber }}
      </div>
      <div :class="statusFlag === '启用' ? 'header-status' : 'header-no-status'">
        {{ statusFlag }}
      </div>
    </div>
    <div class="ips-container">
      <div class="menu-row">
        <div class="menu-title">
          {{ $t('label.belongOrg') }}：
        </div>
        <div class="menu-value">
          {{ serialData.orgName }}
        </div>
      </div>
      <div class="menu-row">
        <div class="menu-title">
          {{ $t('label.ownerCode') }}：
        </div>
        <div class="menu-value">
          {{ serialData.ownerName }}
        </div>
      </div>
      <div class="menu-row">
        <div class="menu-title">
          {{ $t('label.sku') }}：
        </div>
        <div class="menu-value">
          {{ serialData.sku }}
        </div>
      </div>
      <div class="menu-row">
        <div class="menu-title">
          {{ $t('label.goodsName') }}：
        </div>
        <div class="menu-value">
          {{ serialData.goodsName }}
        </div>
      </div>
      <div class="menu-row">
        <div class="menu-title">
          {{ $t('label.status') }}：
        </div>
        <div class="menu-value">
          {{ serialData.statusName }}
        </div>
      </div>
      <div class="menu-row">
        <div class="menu-title">
          {{ $t('label.qualityStatus') }}：
        </div>
        <div class="menu-value">
          {{ serialData.qualityStatusName }}
        </div>
      </div>
      <ips-no-data v-if="serialNumberData.length<=0" />
      <!--库位-物料卡片-->
      <div v-if="serialNumberData.length > 0">
        <div
          class="card_countLocation"
          v-for="(item,index) in serialNumberData"
          :key="index"
        >
          <div class="row">
            <div class="title">
              {{ $t('label.warehouse') }}:
            </div>
            <div class="value">
              {{ item.warehouseName }}
            </div>
          </div>
          <div class="row">
            <div class="row-50">
              <div class="title">
                {{ $t('label.whareaCode') }}:
              </div>
              <div class="label">
                {{ item.whareaCode }}
              </div>
            </div>
            <div class="row-50">
              <div class="title">
                {{ $t('label.countLocation') }}:
              </div>
              <div class="value">
                {{ item.locationCode }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="row-50">
              <div class="title">
                {{ $t('label.sku') }}:
              </div>
              <div class="value">
                {{ item.sku }}
              </div>
            </div>
            <div class="row-50">
              <div class="title">
                {{ $t('label.unit') }}:
              </div>
              <div class="label">
                {{ item.uomName }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="row-50">
              <div class="title">
                {{ $t('label.tray') }}:
              </div>
              <div class="value">
                {{ item.cidCode || '--' }}
              </div>
            </div>
            <div class="row-50">
              <div class="title">
                {{ $t('label.batch') }}:
              </div>
              <div class="label">
                {{ item.batchCode || '--' }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="row-50">
              <div class="title">
                {{ $t('label.availableQty') }}:
              </div>
              <div class="label-number">
                {{ item.qty }}
              </div>
            </div>
            <div class="row-50">
              <div class="title">
                {{ $t('label.currentUseQty') }}:
              </div>
              <div class="label-number">
                {{ item.validQty }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="title">
              {{ $t('label.goodsName') }}:
            </div>
            <div class="value">
              {{ item.goodsName }}
            </div>
          </div>
        </div>

      </div>
    </div>
  </hips-view>
</template>

<script>
  import { querySerialDetail } from '../api';
  import { Row, Col, Icon } from '@hips/vue-ui';
  export default {
    name: 'SerialDetail',
    components:{
      [Row.name]: Row,
      [Col.name]: Col,
      [Icon.name]: Icon,
    },
    data() {
      return {
        serialData: {}, // params参数
        serialNumberData: [], // 序列号数据
        statusFlag: '', // 状态
      };
    },
    methods: {
      // 获取params传来的参
      getParamsData(){
        const { item = {} } = this.$route.params;
        this.serialData = item;
        this.statusFlag = item.enabledFlag === 1 ? '启用' : '未启用';
        let data = {
          params: {
            serialId: item.id,
            serialNumber: item.serialNumber,
          }
        };
        querySerialDetail(data).then((res)=>{
          res.forEach((item)=>{
            item.spanOverflow = true;
          });
          this.serialNumberData = res;
        })
          .catch((e) => {
            console.log('错误：'+ e )
          })
          .finally(()=>{
            this.$hint.hideLoading();
          });
      },

    },
    mounted() {
      this.getParamsData();
    },
  };
</script>

<style lang="stylus" scoped>
 @import '~@/style/var.styl'
    .header-row{
      padding-top 5px
      display flex;
      margin-bottom 12px;
      .header-title{
        display flex;
        align-items: center;
        height:28px;
        background:#E6F7FF;
        color var(--primaryColor);
        border-radius:0px 32px 32px 0px;
        padding 0 12px 0 8px;
        border-left:4px solid var(--primaryColor);
      }
      .header-status{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left 12px;
        padding 0 12px;
        height:28px;
        font-size 15px;
        background:#D6FFD7;
        color #19A633;
        border-radius:12px;
      }
      .header-no-status{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left 12px;
        padding 0 12px;
        height:28px;
        font-size 15px;
        background:#D9D9D9;
        color #8C8C8C;
        border-radius:12px;
      }
  }
    .ips-container{
        padding-top 0!important;
        .menu-row{
            display flex
            margin 12px 32px 12px 12px;
            .menu-title{
                width:70px;
                height:16px;
                font-size:12px;
                /*font-family:PingFangSC-Regular,PingFang SC;*/
                font-weight:200;
                color:#8C8C8C;
                line-height:16px;
            }
            .menu-value{
                width:261px;
                height:16px;
                font-size:12px;
                /*font-family:PingFangSC-Regular,PingFang SC;*/
                font-weight:initial;
                color:#000000;
                line-height:16px;
                overflow: hidden;      /*溢出隐藏*/
                white-space: nowrap;	/*规定文本不进行换行*/
                text-overflow: ellipsis;	/*当对象内文本溢出时显示省略标记（...）*/
            }
        }
        .card_countLocation{
            margin 10px 0;
            padding  0 12px
            height:195px;
            border-radius: 1.6vw;
            color: #000;
            box-shadow: 0 0 2.133vw #d9d9d9;
            border: none;
            font-size: 14px;
            .row{
              display flex
              padding  7px 0
              .row-50{
                  width 50%
                  display flex
                  .title{
                      width:56px;
                      height:16px;
                      font-size:14px;
                      font-family:PingFangSC-Regular,PingFang SC;
                      font-weight:200;
                      color:#595959;
                      line-height:18px;
                  }
                  .value{
                      width:110px;
                      height:18px;
                      font-size:14px;
                      font-family:PingFangSC-Medium,PingFang SC;
                      font-weight:initial;
                      color:#000000;
                      line-height:18px;
                      overflow: hidden;      /*溢出隐藏*/
                      white-space: nowrap;	/*规定文本不进行换行*/
                      text-overflow: ellipsis;	/*当对象内文本溢出时显示省略标记（...）*/
                  }
                  .label{
                      width:110px;
                      height:18px;
                      font-size:14px;
                      font-family:PingFangSC-Medium,PingFang SC;
                      font-weight:initial;
                      color:#000000;
                      line-height:18px;
                      overflow: hidden;      /*溢出隐藏*/
                      white-space: nowrap;	/*规定文本不进行换行*/
                      text-overflow: ellipsis;	/*当对象内文本溢出时显示省略标记（...）*/
                  }
                  .label-number{
                      width:40px;
                      height:18px;
                      font-size:14px;
                      font-family:PingFangSC-Medium,PingFang SC;
                      font-weight:initial;
                      color:#9254DE;
                      line-height:18px;
                  }
              }
              .title{
                width:56px;
                height:16px;
                font-size:14px;
                font-family:PingFangSC-Regular,PingFang SC;
                font-weight:200;
                color:#595959;
                line-height:18px;
              }
              .value{
                width:260px;
                height:18px;
                font-size:14px;
                font-family:PingFangSC-Medium,PingFang SC;
                font-weight:initial;
                color:#000000;
                line-height:18px;
                overflow: hidden;      /*溢出隐藏*/
                white-space: nowrap;	/*规定文本不进行换行*/
                text-overflow: ellipsis;	/*当对象内文本溢出时显示省略标记（...）*/
              }
            }
        }
    }
</style>
