<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <!-- 批次分解 -->
      <ips-header :title="$t('title.batchDecomposition')" :is-index="false" @beforeBack="goBack" />
    </div>
    <div class="ips-container">
      <!-- 目标批次 -->
      <ips-lov
        :label="$t('label.targetBatch')"
        :title="$t('label.targetBatch')"
        v-model="toBatchCode"
        v-show="customData['toBatchCode'] ? hideControl['toBatchCode']: true"
        required
        params="batchCode"
        :url="`${wmdmBaseUrl}batchs/to-batch-modify-lov`"
        :url-params="{ 'enabledFlag': 1, 'goodsId': fromData.goodsId }"
        :custom="customData['toBatchCode']"
        :source-parse-field="sourceParseField"
        ref="ref0"
        @enter="skipNext(10)"
        @down="skipNext(10)"
        @click.stop="handleCommonClick(0)"
        @clickIcon="handleCommonClick(0, 'lov')"
        @choose="handleChoose('toBatchCode', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!--物料-->
      <ips-lov
        :label="$t('label.sku')"
        :title="$t('label.sku')"
        v-model="toSku"
        v-show="customData['toSku'] ? hideControl['toSku']: true"
        required
        disabled
        params="sku"
        :custom="customData['toSku']"
        :source-parse-field="sourceParseField"
        ref="ref10"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 目标托盘 -->
      <ips-lov
        :label="$t('label.targetTray')"
        :title="$t('label.targetTray')"
        v-model="toCidCode"
        v-show="customData['toCidCode'] ? hideControl['toCidCode']: true"
        params="cidCode"
        :url="`${wmdmBaseUrl}containers/to-cid-lov`"
        :custom="customData['toCidCode']"
        :source-parse-field="sourceParseField"
        ref="ref20"
        @enter="skipNext(30)"
        @down="skipNext(30)"
        @click.stop="handleCommonClick(20)"
        @clickIcon="handleCommonClick(20, 'lov')"
        @choose="handleChoose('toCidCode', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 目标库位 -->
      <ips-lov
        :label="$t('label.targetLocation')"
        :title="$t('label.targetLocation')"
        v-model="toLocationCode"
        v-show="customData['toLocationCode'] ? hideControl['toLocationCode']: true"
        required
        :disabled="cidStatus === 'IN_STOCK'"
        params="locationCode"
        :url="`${wmdmBaseUrl}wms-locations`"
        :custom="customData['toLocationCode']"
        :source-parse-field="sourceParseField"
        ref="ref30"
        @click.stop="handleCommonClick(30)"
        @clickIcon="handleCommonClick(30, 'lov')"
        @choose="handleChoose('toLocationCode', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 目标库区 -->
      <ips-lov
        :label="$t('label.targetWharea')"
        :title="$t('label.targetWharea')"
        v-model="toWhareaCode"
        v-show="customData['toWhareaCode'] ? hideControl['toWhareaCode']: true"
        required
        disabled
        params="whareaCode"
        :custom="customData['toWhareaCode']"
        :source-parse-field="sourceParseField"
        ref="ref40"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />

      <!-- 分解数量 -->
      <ips-input-number
        :label="$t('label.amount')"
        v-model="decompositionQty"
        v-show="!enableSerial ? (customData['decompositionQty'] ? hideControl['decompositionQty'] : !enableSerial) : false"
        required
        :custom="customData['decompositionQty']"
        :source-parse-field="sourceParseField"
        ref="ref50"
        not-auto-fill
        :min="null"
        :more-than-zero="true"
        :enable-serial="serialType"
        @enter="handleNextEnter"
        @down="handleNextEnter"
        @tab="handleNextEnter"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <hips-row v-if="enableSerial">
        <div class="hip-row">
          <hips-col span="21">
            <ips-input-number
              :label="$t('label.amount')"
              v-model="decompositionQty"
              required
              disabled
              :enable-serial="serialType"
              :custom="customData['decompositionQty']"
              :source-parse-field="sourceParseField"
              ref="ref50"
              not-auto-fill
              :min="null"
              :more-than-zero="true"
              @enter="skipNext('refSerail')"
              @down="skipNext('refSerail')"
              @tab="skipNext('refSerail')"
              @setSourceField="saveSourceField($event)"
              @valuedByFoucs="valuedByFoucs($event)"
            />
          </hips-col>
          <hips-col span="8">
            <hips-button
              size="large"
              @click.stop="goToSerialDetail"
              @enter="goToSerialDetail"
              ref="refSerail"
              class="button-detail"
            >
              {{ $t('button.serialNumber') }}
            </hips-button>
          </hips-col>
        </div>
      </hips-row>

      <div class="count_block_box">
        <!-- 需求量 -->
        <ips-number-count
          class="count-demandQty"
          :count="demandQty"
          text-field="demandQty"
        />
        <!-- 已录量 -->
        <ips-number-count
          class="count-enteredQty"
          :count="enteredQty"
          text-field="enteredQty"
        />
        <!-- 待录量 -->
        <ips-number-count
          class="count-toBeEnterQty"
          :count="toBeEnterQty"
          text-field="toBeEnterQty"
        />
      </div>
    </div>
    <div class="footer-button" v-if="enteredQty > 0">
      <hips-button
        size="large"
        @click.stop="handleNext"
        @enter="handleNext"
        ref="nextRef"
        class="next"
      >{{ $t('button.next') }}
      </hips-button>
      <hips-button
        class="importedDetail"
        size="large"
        v-show="customData['importedDetail'] ? hideControl['importedDetail']: true"
        @click.stop="handleImportedDetail"
      >{{ $t('button.importedDetail') }}
      </hips-button>
    </div>
    <div class="ips-footer">
      <hips-button
        size="large"
        @click.stop="handleCommonSubmit"
        @keyup.enter.native="handleCommonSubmit"
        @keypress.native.prevent
        ref="submitRef"
      >
        <svg-icon
          slot="icon"
          icon-class="success"
        />{{ (enteredQty > 0)?$t('button.decompositionConfirm'): $t('button.next') }}
      </hips-button>
    </div>
  </hips-view>
</template>

<script>
  import {Col, Row} from '@hips/vue-ui';
  import uuidv4 from 'uuid/v4'
  import { checkToBatch, checkToCid, getValidQty, batchSplit, checkBatchCode } from '../api';
  export default {
    name: 'BatchSplitConfirm',
    components: {
      [Col.name]: Col,
      [Row.name]: Row,
    },
    data() {
      return {
        fromSerialFlag: false, // 从序列号组件跳转过来
        fromDetailFlag: false, // 从序列号组件跳转过来
        editFlag: false,
        editData: {}, // 编辑数据
        enableSerial: '',
        toBatchId: '',
        toBatchCode: '',
        toSku: '',
        toGoodsId: '',
        fromData: '',
        toCidId: '',
        toCidCode: '',
        cidStatus: '',
        toLocationId: '',
        toLocationCode: '',
        toWhareaId: '',
        toWhareaCode: '',
        warehouseId: '', // 仓库id
        toCidParam: {}, // 来源托盘参数
        toLocParam: {}, // 来源托盘参数
        decompositionQty: '', // 分解数量
        demandQty: '', // 需求量
        // toBeEnterQty: '', // 待录量
        enteredQty: '', // 已录量
        serialNumberList: [], // 序列号数据
        decompositionData: [], // 分解数据
        customData: {}, // 个性化配置信息列表
        hideControl: {}, // 个性化配置显示隐藏控制
        sourceParseField: {}, // 界面中所有的来源解析字段
      };
    },
    methods: {
      // 获取上页数据
      async getDataFromParam() {
        const { fromData, sourceParseField, customData, hideControl } = this.$route.params;
        this.fromData = fromData;
        this.serialType = fromData.serialType,
        this.toSku = fromData.fromSku;
        this.enableSerial = fromData.enableSerial;
        this.demandQty = fromData.qty;
        this.sourceParseField = sourceParseField;
        this.customData = customData;
        this.hideControl = hideControl;
        this.enteredQty = 0;
        this.fromSerialFlag = false;
        this.fromDetailFlag = false;
        this.editFlag = false;
        this.editData = {};
      },
      /**
       *@functionName: saveSourceField
       *@description: 保存来源解析字段并赋值Enter
       *@params1: fieldObj[Object] 来源解析字段对象,
       */
      saveSourceField(fieldObj) {
        this.saveSourceParseField(this, fieldObj);
      },
      // 字段聚焦时赋值操作
      async valuedByFoucs(options={}) {
        const { custom ={} , currentValue } = options;
        await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
      },
      /**
       * handleChoose lov选中触发
       * @param type
       * @param obj 选中对象
       */
      handleChoose(type, obj) {
        switch (type) {
          case 'toBatchCode':
            this.toBatchId = obj.id;
            this.toBatchCode = obj.batchCode;
            this.handleBatchBlur(obj.batchCode);
            break;
          case 'toCidCode':
            this.toCidId = obj.cidId;
            this.toCidCode = obj.cidCode;
            this.cidStatus = obj.cidStatus;
            if(obj.cidStatus === 'IN_STOCK'){
              this.handleCidBlur();
            }else {
              this.skipNext(30);
            }
            break;
          case 'toLocationCode':
            this.toLocationId = obj.locationId;
            this.toLocationCode = obj.locationCode;
            this.toWhareaId = obj.whareaId;
            this.toWhareaCode = obj.whareaCode;
            this.warehouseId = obj.warehouseId;
            this.handleLocBlur();
            break;
          default:
            return null;
        }
      },

      /**
       * 批次lov选中事件
       */
      async handleBatch(){
        try {
          let data = {
            params:{
              batchId: this.toBatchId
            }
          };
          const res = await checkToBatch(data);
          if(res) {
            this.skipNext(20);
          }else {
            this.skipNext(0);
          }
        }catch(e) {
          console.log('错误：' + e);
        }
      },
      /**
       * handleBatchBlur 批次失焦触发
       */
      async handleBatchBlur(batchCode) {
        const onConfirm = () => {
          const urlParam = {
            name: 'createBatch',
            params: {
              source: 'BatchSplitConfirm',
              skuInfo: {
                sku: this.fromData.sku,
                goodsId: this.fromData.goodsId,
                ownerName: this.fromData.ownerName,
                ownerId: this.fromData.ownerId,
                orgName: this.fromData.orgName,
                orgId: this.fromData.orgId,
              },
              batchCode: batchCode,
            },
          };
          this.$router.pushPage(urlParam);
        };
        const onCancel = () => {
          this.$nextTick(() => {
            this.skipNext(0);
          })
        };
        if (batchCode) {
          try {
            this.$hint.showLoading();
            this.handleSubmitStatus();
            const data = {
              params: {
                batchCode: batchCode,
                goodsId: this.fromData.goodsId,
              },
            };
            const res = await checkBatchCode(data);
            if (res && !res.batchId) {
              // 该批次不存在，是否创建批次？
              this.$hint.showAlert(this.$i18n.t('message.checkBatchCode'), onConfirm, onCancel);
            } else {
              this.handleBatch();
            }
          } catch {
            this.$nextTick(() => {
              this.skipNext(0);
            })
          } finally {
            this.$hint.hideLoading();
            this.handleSubmitStatus();
          }
        }
      },
      /**
       * 托盘lov失焦
       */
      async handleCidBlur(){
        try {
          let data = {
            params:{
              cidId: this.toCidId
            }
          };
          const res = await checkToCid(data);
          if(res) {
            this.toLocationId = res.locationId;
            this.toLocationCode = res.locationCode;
            this.toWhareaId = res.whareaId;
            this.toWhareaCode = res.whareaCode;
            this.warehouseId = res.warehouseId;
            if(this.enableSerial){
              this.skipNext('refSerail');
            } else {
              this.skipNext(50);
            }
          }else {
            this.skipNext(20);
          }
        }catch(e) {
          console.log('错误：' + e);
        }
      },
      /**
       * 库位lov失焦
       */
      async handleLocBlur(){
        try {
          let data = {
            params:{
              batchId: this.toBatchId,
              cidId: this.toCidId,
              locationId: this.toLocationId
            }
          };
          const res = await getValidQty(data);
          if(res) {
            if(this.enableSerial){
              this.skipNext('refSerail');
            } else {
              this.skipNext(50);
            }
          }else {
            this.skipNext(30);
          }
        }catch(e) {
          console.log('错误：' + e);
        }
      },
      /**
       * 数量enter、tab事件
       */
      handleNextEnter(){
        if(this.enteredQty > 0){
          this.skipNext('nextRef')
        }else {
          this.skipNext('submitRef')
        }
      },

      /**
       * 序列号录入
       */
      async goToSerialDetail(){
        if(!this.toBatchId || !this.toLocationId){
          this.$hint.showFailed(this.$i18n.t('message.checkRequired'), true);
          return
        }
        let usedStockSerialList = [];
        this.decompositionData.forEach((item)=>{
          usedStockSerialList = usedStockSerialList.concat(item.serialStockProcessDtoList);
        });
        let data = {
          originalStockSerialList: this.fromData.fromSerialNumberList || [],
          usedStockSerialList: usedStockSerialList,
        };
        const serialUrl = 'app-batch-modify/batch-split-check-serial';
        const param = {
          name: 'batchSerialInput',
          params: {
            data,
            serialUrl,
            serialNumberList: this.serialNumberList || [],
          },
        };
        this.fromSerialFlag = true;
        this.$router.pushPage(param);
      },

      // 下一步
      async handleNext(){
        if(!this.toBatchId || !this.toLocationId){
          this.$hint.showFailed(this.$i18n.t('message.checkRequired'), true);
          return
        }
        if(this.enteredQty + this.decompositionQty > this.demandQty){
          this.$hint.showFailed(this.$i18n.t('message.qtyMustBeSmallerThanValidFromQty'), true);
          return
        }
        if(this.enteredQty + this.decompositionQty === this.demandQty){
          this.$hint.showFailed(this.$i18n.t('message.FromQtyHaveBeenEntered'), true);
          return
        }
        if((this.decompositionQty > 0 && this.enableSerial) || !this.enableSerial){
          let data = {
            goodsId: this.fromData.goodsId,
            toSku: this.toSku,
            toBatchId: this.toBatchId,
            toBatchCode: this.toBatchCode,
            toCidId: this.toCidId,
            toCidCode: this.toCidCode,
            cidStatus: this.cidStatus,
            toWhareaId: this.toWhareaId,
            toWhareaCode: this.toWhareaCode,
            toLocationId: this.toLocationId,
            toLocationCode: this.toLocationCode,
            qty: this.decompositionQty,
            serialStockProcessDtoList: this.serialNumberList,
            uuid: uuidv4(),
          };
          if(this.editFlag){
            this.decompositionData = this.decompositionData.map((item) => item.uuid === this.editData.uuid ? data : item);
          }else {
            this.decompositionData = this.decompositionData.concat(data);
          }
          this.enteredQty = this.enteredQty + this.decompositionQty;
          // 清空页面数据
          this.editFlag = false;
          this.toBatchId = '';
          this.toBatchCode = '';
          this.toCidId = '';
          this.toCidCode = '';
          this.cidStatus = '';
          this.toLocationId = '';
          this.toLocationCode = '';
          this.toWhareaId= '';
          this.whareaCode= '';
          this.decompositionQty = '';
          this.serialNumberList = [];
          await this.clearSourceFields(this, true);
          this.skipNext(0);
        }else {
          this.$hint.showFailed(this.$i18n.t('message.qtyMustBeGreaterThanZero'), true);
        }

      },
      // 跳转详情界面
      handleImportedDetail(){
        this.fromDetailFlag = true;
        if(this.editFlag){
          let data = {
            goodsId: this.editData.goodsId,
            toSku: this.editData.toSku,
            toBatchId: this.editData.toBatchId,
            toBatchCode: this.editData.toBatchCode,
            toCidId: this.editData.toCidId,
            toCidCode: this.editData.toCidCode,
            cidStatus: this.editData.cidStatus,
            toWhareaId: this.editData.toWhareaId,
            toWhareaCode: this.editData.toWhareaCode,
            toLocationId: this.editData.toLocationId,
            toLocationCode: this.editData.toLocationCode,
            qty: this.editData.qty,
            serialStockProcessDtoList: this.serialNumberList,
            uuid: this.editData.uuid,
          };
          this.decompositionData = this.decompositionData.map((item) => item.uuid === this.editData.uuid ? data : item);
          this.enteredQty = this.enteredQty + this.editData.qty;
        }
        let param = {
          name: 'ImportedDetailSplit',
          params: {
            enteredQty: this.enteredQty,
            decompositionData: this.decompositionData,
            editFlag: this.editFlag,
            editQty: this.editData.qty,
          },
        };
        this.$router.pushPage(param);
      },
      // 分解
      async handleButtonSubmit() {
        if(this.enteredQty>0){
          if(this.enteredQty + this.decompositionQty !== this.demandQty){
            this.$hint.showFailed(this.$i18n.t('message.FromQtyNotEqualEntered'), true);
            return
          }
          if(!this.toBatchId || !this.toLocationId){
            this.$hint.showFailed(this.$i18n.t('message.checkRequired'), true);
            return
          }
          this.$hint.showLoading();
          this.handleSubmitStatus();
          let decompositionData = [];
          let decData = {
            goodsId: this.fromData.goodsId,
            toSku: this.toSku,
            toBatchId: this.toBatchId,
            toBatchCode: this.toBatchCode,
            toCidId: this.toCidId,
            toCidCode: this.toCidCode,
            cidStatus: this.cidStatus,
            toWhareaId: this.toWhareaId,
            toWhareaCode: this.toWhareaCode,
            toLocationId: this.toLocationId,
            toLocationCode: this.toLocationCode,
            qty: this.decompositionQty,
            serialStockProcessDtoList: this.serialNumberList,
            uuid: uuidv4(),
          };
          if(this.editFlag){
            this.decompositionData = this.decompositionData.map((item) => item.uuid === this.editData.uuid ? decData : item);
            decompositionData = this.decompositionData;
          }else {
            decompositionData = this.decompositionData.concat(decData);
          }
          let data = {
            fromSku: this.fromData.fromSku,
            goodsId: this.fromData.goodsId,
            sku: this.fromData.skuInfo.sku,
            ownerName: this.fromData.skuInfo.ownerName,
            ownerId: this.fromData.skuInfo.ownerId,
            orgName: this.fromData.skuInfo.orgName,
            orgId: this.fromData.skuInfo.orgId,
            serialType: this.fromData.serialType,
            fromBatchId: this.fromData.fromBatchId,
            fromBatchCode: this.fromData.fromBatchCode,
            fromCidId: this.fromData.fromCidId,
            fromCidCode: this.fromData.fromCidCode,
            fromLocationId: this.fromData.fromLocationId,
            fromLocationCode: this.fromData.fromLocationCode,
            fromWhareaId: this.fromData.fromWhareaId,
            fromWhareaCode: this.fromData.fromWhareaCode,
            qty: this.fromData.qty,
            availableQty: this.fromData.availableQty,
            useQty: this.fromData.useQty,
            reserveQty: this.fromData.reserveQty,
            serialStockProcessDtoList: this.fromData.fromSerialNumberList,
            appBatchModifyDTOList: decompositionData,
          };
          await batchSplit(data);
          await this.clearSourceFields(this, true);
          this.$hint.hideLoading();
          this.handleSubmitStatus();
          this.$hint.showSuccess();
          let param = {
            name: 'BatchSplit',
            params: {
              deleteData: true,
            },
          };
          this.$router.pushPage(param);
        } else {
          this.handleNext();
        }
      },
      async goBack(){
        this.$router.backPage();
        // 之前的逻辑
        // let param = {
        //   name: 'BatchSplit',
        //   params: {
        //     source: 'batchDecompositionConfirm',
        //     deleteData: false,
        //     serialStockProcessDtoList: this.fromData.fromSerialNumberList,
        //   },
        // };
        // this.$storage.set('serialNumberList', []);
        // this.$router.pushPage(param);
      },
    },
    async mounted() {
      await this.getDataFromParam();
      this.inputFieldList = [
        { type: 'lov', required: true, ref: 0, bind: 'toBatchCode', field: 'toBatchCode' },
        { type: 'lov', required: true, ref: 10, bind: 'toSku', field: 'toSku' },
        { type: 'lov', required: false, ref: 20, bind: 'toCidCode', field: 'toCidCode' },
        { type: 'lov', required: true, ref: 30, bind: 'toLocationCode', field: 'toLocationCode' },
        { type: 'lov', required: true, ref: 40, bind: 'toWhareaCode', field: 'toWhareaCode' },
        { type: 'number', required: true, ref: 50, bind: 'decompositionQty', field: 'decompositionQty' },
      ];
      // this.urlData = this.handleData('DIRECT_INB_CONFIRM');
      this.skipNext(0);
    },
    activated(){
      const {source, batchId, editFlag, editData} = this.$route.params;
      if(this.fromSerialFlag){
        this.fromSerialFlag = false;
        this.serialNumberList = this.$storage.get('serialNumberList') || [];
        const serialNumberSubmit = this.$storage.get('serialNumberSubmit');
        if(this.editFlag){
          this.decompositionData.forEach((item) => {
            if(item.uuid === this.editData.uuid){
              if(serialNumberSubmit){
                item.serialStockProcessDtoList = this.serialNumberList;
                // this.editData.serialStockProcessDtoList = this.serialNumberList;
                this.decompositionQty = this.serialNumberList.length;
              }else {
                item.serialStockProcessDtoList = this.editData.serialStockProcessDtoList;
                this.serialNumberList = this.editData.serialStockProcessDtoList;
                this.decompositionQty = this.editData.serialStockProcessDtoList.length;
              }
            }
          })
        }else {
          this.decompositionQty = this.serialNumberList.length;
        }
        this.$storage.set('serialNumberSubmit', false);
        this.skipNext('submitRef');
      }
      // 明细页面过来
      if(this.fromDetailFlag){
        this.fromDetailFlag = false;
        this.decompositionData = this.$storage.get('decompositionData') || [];
        this.enteredQty = this.$storage.get('enteredQty') || 0;
        if(editFlag){
            this.editFlag = true;
            this.editData = editData;
            this.toSku= editData.toSku;
            this.toBatchId= editData.toBatchId;
            this.toBatchCode= editData.toBatchCode;
            this.toCidId= editData.toCidId;
            this.toCidCode= editData.toCidCode;
            this.cidStatus = editData.cidStatus;
            this.toWhareaId= editData.toWhareaId;
            this.toWhareaCode= editData.toWhareaCode;
            this.toLocationId= editData.toLocationId;
            this.toLocationCode= editData.toLocationCode;
            this.decompositionQty= editData.qty;
            this.serialNumberList= editData.serialStockProcessDtoList;
        }
        this.skipNext('submitRef');
      }
      if(source === 'createBatch'){
        this.toBatchId = batchId;
        this.skipNext(20);
      }else if(source === 'batchDecomposition'){
        this.skipNext(0);
      } else {
        this.skipNext('submitRef');
      }
    },
    beforeRouteLeave(to, from, next) {
      if (to.name === 'batchSerialInput' || to.name === 'createBatch' || to.name === 'ImportedDetail' || to.name === 'ImportedDetailSplit') {
        this.$store.commit('pushKeepAlives', 'BatchSplitConfirm');
      } else {
        this.$store.commit('deleteKeepAlives', 'BatchSplitConfirm');
      }
      setTimeout(() => {
        next();
      },0)
    },
    computed:{
      toBeEnterQty:function(){
        return this.demandQty - this.enteredQty;
      },
    },
    watch:{
      'toBatchCode': function(val, oldval) {
        if(oldval !== '' && val === ''){
          this.toBatchId = '';
          this.toCidId = '';
          this.toCidCode = '';
          this.cidStatus = '';
          this.toLocationId = '';
          this.toLocationCode = '';
          this.toWhareaId = '';
          this.toWhareaCode = '';
          this.warehouseId = '';
        }
      },
      'toCidCode': function(val, oldval) {
        if(oldval !== '' && val !== oldval){
          this.toCidId = '';
          this.cidStatus = '';
          this.toLocationId = '';
          this.toLocationCode = '';
          this.toWhareaId = '';
          this.toWhareaCode = '';
          this.warehouseId = '';
        }
      },
      'toLocationCode': function(val, oldval) {
        if(oldval !== '' && val === ''){
          this.toLocationId = '';
          this.toWhareaId = '';
          this.toWhareaCode = '';
          this.warehouseId = '';
        }
      },
    }

  };
</script>

<style lang="stylus" scoped>
 @import '~@/style/var.styl'
    .count_block_box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 16px 0;
    }
    .count-demandQty{
        background-color: #91D5FF
        border-color: var(--primaryColor)
        color: var(--primaryColor)
    }
    .count-toBeEnterQty{
      background-color: #FFD382
      border-color: #FF8F07
      color: #FF8F07
    }
    .count-enteredQty{
      background-color: #B7EB8F
      border-color: #52C41A
      color: #52C41A

    }
    .hip-row{
      display flex
      padding  12px 0 0 0
      .button-detail{
        margin-left: 15px;
        margin-right: 5px;
        padding 0 5px;
        background-color: var(--primaryColor);
        color: #FFFFFF;
        border: none;
        font-size: 14px;
        height: 36px;
        line-height: 36px;
      }
    }
  .footer-button{
    display: flex;
    /*margin-top: 20px;*/
    margin 0 10px
    position: static;
    bottom:10px;
    .next{
      background-color var(--primaryColor)
      color white
      width 49%
    }
    .importedDetail{
      background-color #f5a623
      color white
      width 49%
      margin-left 10px
    }
  }
</style>
