import { isArray } from 'lodash';
import request from '@/request';
import { storage } from '../utils';
const BUTTON_SUBMIT_INDEX = 9999;
const BUTTON_INPUT_INDEX = 8888;
const BUTTON_SELECT_INDEX = 7777;
const BUTTON_POPUPCONFIRM_INDEX = 6666;
const BUTTON_SERIAL_INDEX = 5555;
const BUTTON_NEXT_INDEX = 4444;

export default {
  data() {
    return {
      winvBaseUrl: `${process.env.VUE_APP_WINV_URL}v1/${storage.get('tenantId')}/`,
      wmdmBaseUrl: `${process.env.VUE_APP_WMDM_URL}v1/${storage.get('tenantId')}/`,
      hpfmBaseUrl: `${process.env.VUE_APP_HPFM_URL}v1/${storage.get('tenantId')}/`,
      // wmdmBaseUrl:`wmdm-30193/v1/${storage.get('tenantId')}/`,   // 晨曦
      // winvBaseUrl:`winv-11313/v1/${storage.get('tenantId')}/`,   // 隼姐
      // wmdmBaseUrl:`wmdm-11313/v1/${storage.get('tenantId')}/`,   // 隼姐
      // wmdmBaseUrl:`wmdm-15099/v1/${storage.get('tenantId')}/`,   // 建秋
      // winvBaseUrl:`winv-15099/v1/${storage.get('tenantId')}/`,   // 建秋
      // winvBaseUrl:`winv-25903/v1/${storage.get('tenantId')}/`,   // 云峰
      // wmdmBaseUrl:`wmdm-25903/v1/${storage.get('tenantId')}/`,   // 云峰
      SERIAL_INV_CTRL: 'INV_CTRL',
      SERIAL_IN_CTRL: 'IN_CTRL',
      SERIAL_OUT_CTRL: 'OUT_CTRL',
      SERIAL_SEMI_INV_CTRL: 'SEMI_INV_CTRL',
      BUTTON_INPUT_INDEX,
      inputFieldList: [], // 这是页面上所有输入框属性列表（isLov, required, ref, bind, isHide ）页面注意需要覆盖
      // isLov 是否是lov
      // required 是否必输
      // ref 索引index 例如最近绑定ref=ref0 此ref就为0
      // bind v-modal绑定的值
      // isHide 是否是隐藏的， 默认为false
      activeIndex: 0,
    };
  },
  computed: {
    inputBtnStyle: function () {
      return this.activeIndex === this.BUTTON_INPUT_INDEX
        ? { color: '#fff', backgroundColor: '#0074df', height: '45px' }
        : { color: '#FFFFFF', backgroundColor: '#f5a623', height: '45px' };
    },
  },
  methods: {
    // 如果有其他情况，可以不用这个方法，自己将需要的代码拷出来写，例子：可能涉及到每个组件外部自定义的err报错
    async handleCommonClick(ref, type) {
      const isUpOptions = this.activeIndex > ref;
      console.log('currentRef', ref, 'activeIndex', this.activeIndex, '光标操作是否向上', isUpOptions);
      // 点击active元素的下方元素
      if (ref > this.activeIndex) {
        let flag = true;
        const activeObj = this.inputFieldList.find((i) => i.ref === this.activeIndex);
        if (activeObj && activeObj.type === 'lov') {
          const value = this.$common.parsePath(activeObj.bind)(this);
          if (activeObj.required || (!activeObj.required && value)) {
            // const selfRef = this.$refs[`ref${activeObj.ref}`];
            const selfRef = this.$refs[`ref${activeObj.ref}`][0] || this.$refs[`ref${activeObj.ref}`];
            let res;
            // 校验是否存在preParseUrl与校验参数
            if (selfRef.preParseUrl !== '' && selfRef.currentValue !== '' && selfRef.currentValue !== null) {
              res = await selfRef.preCheckData();
            } else {
              res = await selfRef.getData();
            }
            // const res = await selfRef.getData();
            if (!res || (res && res.failed) || (Array.isArray(res) && res.length === 0 && !selfRef.allowNoExist)) {
              // 接口报错
              this.$refs['ref' + this.activeIndex].focus();
            }
            return;
          }
        } else {
          flag = this.checkCommonFront(ref);
        }
        // console.log('common click', flag);
        if (!flag) {
          console.log('校验失败..');
          this.$refs['ref' + this.activeIndex].focus();
        } else {
          this.handleSelfClick(ref, type);
        }
      } else {
        this.handleSelfClick(ref, type, isUpOptions);
      }
    },

    async handleSelfClick(ref, type) {
      // hideableFlag
      if (type === 'lov') {
        // const selfRef = this.$refs['ref' + ref];
        const selfRef = this.$refs['ref' + ref][0] || this.$refs['ref' + ref];
        // if (selfRef.$refs.input.validateClass === 'has-error') return
        let res;

        // 校验是否存在preParseUrl与校验参数
        if (selfRef.preParseUrl !== '' && selfRef.currentValue !== '' && selfRef.currentValue !== null) {
          res = await selfRef.preCheckData();
        } else {
          res = await selfRef.getData();
        }
        this.activeIndex = ref;
        if (!res || (res && res.failed) || (Array.isArray(res) && res.length === 0 && !selfRef.allowNoExist)) {
          // 接口报错
          // this.$refs['ref' + ref].focus();
        }
      } else {
        this.activeIndex = ref;
      }
    },

    handleCommonSubmit(type) {
      let flag = this.checkCommonFront(BUTTON_SUBMIT_INDEX, 'submit');
      const activeObj = this.inputFieldList.find((i) => i.ref === this.activeIndex);
      // console.log('button submit');
      if (!flag) {
        if (this.activeIndex === BUTTON_SUBMIT_INDEX) return;
        if (this.activeIndex > 1000) return;
        this.$refs['ref' + this.activeIndex].focus();
        if (activeObj.type === 'lov' && !this.$refs['ref' + activeObj.ref].errorFlag) {
          const value = this.$common.parsePath(activeObj.bind)(this);
          if (activeObj.required || (!activeObj.required && value)) {
            // 校验是否存在preParseUrl与校验参数
            if (
              this.$refs[`ref${activeObj.ref}`].preParseUrl !== '' &&
              this.$refs[`ref${activeObj.ref}`].currentValue !== '' &&
              this.$refs[`ref${activeObj.ref}`].currentValue !== null
            ) {
              this.$refs[`ref${activeObj.ref}`].preCheckData();
            } else {
              this.$refs[`ref${activeObj.ref}`].getData();
            }
            // this.$refs[`ref${activeObj.ref}`].getData();
          }
        }
        return;
      }
      // 如果已经存在提交中状态, 提前中断submit的事件
      if (this.submitLoading) {
        console.log('监测到已经存在提交, 中断此次提交操作');
        return;
      }
      this.handleButtonSubmit(type);
    },
    handleButtonSubmit() {
      throw new Error('component must implement handleButtonSubmit method');
    },
    checkCommonFront(ref, type) {
      console.log(ref);
      const array = this.inputFieldList;
      const activeIndex = this.activeIndex;
      const focusIndex = ref;
      let validate = true;
      // 也需要校验active
      let start = array.findIndex((i) => i.ref === activeIndex);
      let end = type === 'submit' ? focusIndex : array.findIndex((i) => i.ref === focusIndex);
      const temArr = array.slice(start, end);
      for (let i = 0; i < temArr.length; i++) {
        let item = temArr[i];
        if (item.isHide) continue;
        let value = this.$common.parsePath(item.bind)(this);
        const refObj = this.$refs['ref' + item.ref];
        // isEmpty 考虑number 0
        // 校验必输错误，当然可能存在其他情况，自己重写即可
        if (item.required && this.$common.isEmpty(value)) {
          // refObj.$refs.input.validateClass = 'has-error';
          // refObj.$refs.input.isError = true;
          refObj.isError = true;
          refObj.validateClass = 'has-error';
          validate = false;
          break;
        }
        if (item.type === 'text') {
          console.log('TEXT校验跳过');
          // 默认lov不是受控的
        } else if (item.type === 'input') {
          if (refObj?.validateClass === 'has-error') {
            validate = false;
            break;
          } else if (item.required && this.$common.isEmpty(value)) {
            refObj.$refs.input.validateClass = 'has-error';
            refObj.$refs.input.isError = true;
            refObj.isError = true;
            refObj.validateClass = 'has-error';
            validate = false;
            break;
          }
          // 默认lov不是受控的
        } else {
          if (refObj.$refs.input.validateClass === 'has-error') {
            validate = false;
            break;
          } else if (item.required && item.type === 'number' && this.$common.isEmpty(value)) {
            refObj.$refs.input.validateClass = 'has-error';
            this.$hint.showFailed(this.$i18n.t('message.checkRequired'), true);
            validate = false;
            break;
          } else if (item.type === 'lov') {
            if (item.required && !refObj.validateLov) {
              this.$hint.showFailed(this.$i18n.t('message.checkRequired'), true);
              validate = false;
              break;
            } else if (!item.required && value && !refObj.validateLov) {
              validate = false;
              break;
            }
          }
        }
      }
      return validate;
    },
    async skipNext(reference) {
      console.log('skip =======>', reference);
      if (reference === null || reference === undefined || reference === '') return;
      if (reference === 'submitRef') {
        this.activeIndex = BUTTON_SUBMIT_INDEX;
        this.$refs.submitRef.$el.focus();
      } else if (reference === 'inputRef') {
        this.activeIndex = BUTTON_INPUT_INDEX;
        this.$refs.inputRef.$el.focus();
      } else if (reference === 'cancellation') {
        this.activeIndex = BUTTON_SUBMIT_INDEX;
        this.$refs.cancellation.$el.focus();
      } else if (reference === 'allCancellation') {
        this.activeIndex = BUTTON_SUBMIT_INDEX;
        this.$refs.allCancellation.$el.focus();
      } else if (reference === 'popupConfirmRef') {
        this.activeIndex = BUTTON_POPUPCONFIRM_INDEX;
        this.$refs.popupConfirmRef.$el.focus();
      } else if (reference === 'selectRef') {
        this.activeIndex = BUTTON_SELECT_INDEX;
        this.$refs.selectRef.$el.focus();
      } else if (reference === 'refSerail') {
        this.activeIndex = BUTTON_SERIAL_INDEX;
        this.$refs.refSerail.$el.focus();
      } else if (reference === 'nextRef') {
        this.activeIndex = BUTTON_NEXT_INDEX;
        this.$refs.nextRef.$el.focus();
      } else {
        let ref = Number(reference);
        const refElementObj = this.$refs['ref' + ref] || {};
        const { custom } = refElementObj;
        let parsedValue = undefined;
        // if(custom) {
        //   parsedValue = this.customDefaultValueConfig(this, custom, this.sourceParseField, currentValue);
        //   console.log('LR  ******* parsedValue', parsedValue);
        //   const { fieldCode, fieldName, hideableFlag } = custom;
        //   // 必输字段没有值, 中断并提示
        //   const breakOut = this.checkRequiredFiled({
        //     _this: this,
        //     fieldCode,
        //     fieldName,
        //     hideableFlag,
        //     parsedValue
        //   });
        //   if(breakOut) return;
        // }
        const currentValue = refElementObj.$refs.input.value;
        console.log('LR  ******* input currentValue', currentValue);
        this.activeIndex = ref;
        console.log(Array.isArray(this.$refs['ref' + ref]));
        if (Array.isArray(this.$refs['ref' + ref])) {
          if (!this.$refs['ref' + ref][0].custom) {
            this.$refs['ref' + ref][0] ? this.$refs['ref' + ref][0].focus() : this.skipNext('submitRef');
          } else if (
            this.$refs['ref' + ref][0].custom.hideableFlag ||
            (this.$refs['ref' + ref][0].value && this.$refs['ref' + ref][0].custom.autoEnterFlag && this.$refs['ref' + ref].type !== 'lov')
          ) {
            this.skipNext(ref + 1);
          } else if (
            this.$refs['ref' + ref][0].value &&
            this.$refs['ref' + ref][0].custom.autoEnterFlag &&
            this.$refs['ref' + ref].type === 'lov'
          ) {
            this.handleCommonClick(ref, this.$refs['ref' + ref].type);
          } else {
            this.$refs['ref' + ref][0] ? this.$refs['ref' + ref][0].focus() : this.skipNext('submitRef');
          }
        } else {
          if (custom) {
            const { fieldCode, hideableFlag } = custom;
            const inputFieldObj = this.inputFieldList.filter((inputField) => inputField.field === fieldCode)[0]; // 匹配非文本显示类型的
            const { required, type } = inputFieldObj;
            const refLists = Object.keys(this.$refs);
            if ((type !== 'lov' && !required && hideableFlag) || (type === 'lov' && this.$common.isEmpty(parsedValue) && hideableFlag)) {
              ref = ref + 1;
              while (!refLists.includes(`ref${ref}`)) {
                ref = ref + 1;
              }
            }
          }
          console.log('skipNext => ref: ', ref, 'focus()');
          this.$refs['ref' + ref].focus();
        }

        // 光标跳转控制滚动聚焦
        setTimeout(() => {
          // currentDistance为当前页面可视高度减去当前dom节点到可视高度上边沿的距离
          const currentDistance = document.documentElement.clientHeight - document.activeElement.getBoundingClientRect().top;
          // isEnterScrollIntoView为判断currentDistance与一个dom节点的高度加边距
          const isEnterScrollIntoView = currentDistance < document.activeElement.clientHeight + 15;
          if (
            (document.activeElement.tagName === 'INPUT' || document.activeElement.tagName === 'TEXTAREA') &&
            ref !== 0 &&
            isEnterScrollIntoView
          ) {
            window.setTimeout(function () {
              if ('scrollIntoView' in document.activeElement) {
                document.activeElement.scrollIntoView({ behavior: 'instant', block: 'end', inline: 'nearest' });
              } else {
                console.log('scrollIntoViewIfNeeded: ', '2222222');

                document.activeElement.scrollIntoViewIfNeeded();
              }
            }, 0);
          }
        }, 300);
      }
    },
    handleData(pageCode) {
      const urlData = this.$storage.get('urlData') || [];
      let preParseData = [];
      for (let item of urlData) {
        if (item.pageCode === pageCode) {
          preParseData = item.fields;
        }
      }
      let params = {};
      preParseData.forEach((item) => {
        // params[item.fieldCode] = item;
        if (item.serviceFrom === 'winv') {
          params[item.fieldCode] = `${this.winvBaseUrl}${item.url}`;
        } else if (item.serviceFrom === 'wmdm') {
          params[item.fieldCode] = `${this.wmdmBaseUrl}${item.url}`;
        }
      });
      this.preParsePrarms = params;
      return this.preParsePrarms;
    },
    /*
     *@functionName: handleCustomData
     *@description: 格式化子应用个性化配置信息
     *@params1: data[Array] 个性化配置信息列表
     *@return: customObj[Object] 个性化对象
     *@author: LiuRun
     *@date: 2020-12-18 16:46:05
     *@version: V1.0.0
     */
    handleCustomData(data = []) {
      let customObj = {};
      let hideControl = {};
      if (!isArray(data)) return;
      if (data.length >= 1) {
        data.forEach((item) => {
          const { fieldCode, hideableFlag } = item;
          customObj[fieldCode] = item;
          hideControl[fieldCode] = !hideableFlag;
        });
      }
      return { customObj, hideControl };
    },
    /*
     *@functionName: calculateFieldOnFocus
     *@description: 在字段聚焦时进行赋值计算
     *@params1: _this
     *@params2: custom 字段个性化配置信息
     *@params3: sourceParseField 来源解析字段
     *@params4: currentValue 字段当前值
     *@return: undefine
     *@author: LiuRun
     *@date: 2021-01-09 23:29:06
     *@version: V1.0.0
     */
    async calculateFieldOnFocus(_this, custom, sourceParseField, currentValue) {
      const {
        fieldCode,
        // fieldName,
        fieldValueSourceType,
        // hideableFlag,
        // autoEnterFlag,
      } = custom;
      let parsedValue = null;
      let staticThis = _this;

      parsedValue = await this.customDefaultValueConfig(_this, custom, sourceParseField, currentValue);
      console.log('光标聚焦在', fieldCode, '字段时(', fieldValueSourceType, ') 来源字段的值', parsedValue);

      // 必输字段没有值, 中断并提示
      //  const breakOut = this.checkRequiredFiled({
      //   _this,
      //   fieldCode,
      //   fieldName,
      //   hideableFlag,
      //   parsedValue
      //  });
      //  if(breakOut) return;

      await this.fieldEvaluation({
        _this: staticThis,
        custom,
        parsedValue,
        currentValue,
        fieldValueSourceType,
      });
    },
    /*
     *@functionName: getSourceFieldValue
     *@description: 获取来源字段的Value
     *@params1: _this
     *@params2: sourceField 来源字段
     *@return: parsedValue 来源字段的值
     *@author: LiuRun
     *@date: 2021-01-09
     *@version: V1.0.0
     */
    getSourceFieldValue(_this, sourceField) {
      // 来源字段信息
      const sourceInputFieldObj = _this.inputFieldList.filter((inputField) => inputField.field === sourceField)[0]; // 匹配非文本显示类型的
      const { bind: sourceFieldBind } = sourceInputFieldObj;
      const bindCode = sourceFieldBind.split('.');
      let keycode = '';
      if (bindCode.length > 1) {
        bindCode.forEach((code, i) => {
          keycode = code;
          if (i < bindCode.length - 1) _this = _this[code];
        });
      } else {
        keycode = bindCode;
      }
      return _this[keycode];
    },
    // 字段赋值
    async fieldEvaluation(options = {}) {
      console.log('开始fieldEvaluation赋值操作');
      const {
        // _this,
        custom = {},
        // parsedValue,
        currentValue,
        fieldValueSourceType,
      } = options;
      let { _this, parsedValue } = options;

      const { fieldCode, autoEnterFlag, hideableFlag } = custom;
      const inputFieldObj = _this.inputFieldList.filter((f) => f.field === fieldCode)[0];
      const staticThis = _this;
      if (inputFieldObj) {
        const { bind: currentFieldBind, type, ref, required } = inputFieldObj;
        const input = _this.$refs[`ref${ref}`];
        const currentBindCode = currentFieldBind.split('.');
        parsedValue = parsedValue && type === 'number' ? Number(parsedValue) : parsedValue;
        let currentKeycode = '';
        if (currentBindCode.length > 1) {
          currentBindCode.forEach((code, i) => {
            currentKeycode = code;
            if (i < currentBindCode.length - 1) _this = _this[code];
          });
        } else {
          currentKeycode = currentBindCode[0];
        }
        // 只有字段值类型为非“无”的才允许赋值操作
        if (fieldValueSourceType !== 'NONE' && !this.$common.isEmpty(parsedValue)) {
          _this[currentKeycode] = parsedValue;
          console.log(currentKeycode, _this[currentKeycode]);
        }
        // 字段赋值完成之后, 标注已overed状态
        // if(input) input.overed = true;

        // auto enter
        _this = staticThis;
        if (type === 'lov') {
          this.judgeAutoEnter(_this, custom, inputFieldObj, currentValue, parsedValue);
        } else {
          if (((!required || (required && parsedValue)) && autoEnterFlag) || hideableFlag) {
            // 非lov类型字段如果存在blur事件,优先触发校验逻辑
            if (input && input.$listeners.blur && this.isFunction(input.$listeners.blur)) {
              const breakOut = await input.$listeners.blur();
              // console.log('校验结果breakOut', breakOut);
              if (breakOut) return;
            }
            // 一些非必须的输入框存在enter事件, 判断光标的走向
            if (!required && input.$listeners.enter && this.isFunction(input.$listeners.enter)) {
              await input.$listeners.enter();
              return;
            }
            if (input && input.$refs.input) {
              input.$refs.input.focus();
            }
            const inputFieldLength = _this.inputFieldList.length;
            const maxIndex = _this.inputFieldList[inputFieldLength - 1].ref;

            let nextRef = isNaN(Number(ref)) ? ref : Number(ref) + 1;
            while (!_this.$refs[`ref${nextRef}`]) {
              if (nextRef > maxIndex) {
                nextRef = 'submitRef';
                break;
              }
              nextRef += 1;
            }

            if ((input && !input.isError) || !input) {
              _this.skipNext(nextRef > maxIndex ? 'submitRef' : nextRef);
            }
          }
        }
      }
    },
    // 判断自动Enter
    async judgeAutoEnter(_this, custom, inputFieldObj, currentValue, parsedValue) {
      // currentValue, parsedValue
      const { autoEnterFlag, hideableFlag, fieldName, resolveSourceFlag } = custom;
      // 隐藏的Lov也需要自动enter
      if (autoEnterFlag || hideableFlag) {
        console.log('走入判断自动enter', fieldName);
        // 如果是来源解析字段直接终止
        if (resolveSourceFlag) {
          console.log('来源解析字段终止自动enter');
          return;
        }
        const { ref, required } = inputFieldObj;
        const inputFieldLength = _this.inputFieldList.length;
        const maxIndex = _this.inputFieldList[inputFieldLength - 1].ref;
        let nextRef = isNaN(Number(ref)) ? ref : Number(ref) + 1;
        console.log(fieldName, '的ref', ref, 'nextRef', nextRef, 'maxIndex', maxIndex);

        while (!_this.$refs[`ref${nextRef}`]) {
          if (nextRef > maxIndex) {
            nextRef = 'submitRef';
            break;
          }
          nextRef += 1;
        }
        const input = _this.$refs[`ref${ref}`];

        if (input) {
          try {
            console.log('judgeAutoEnter ****** currentValueOFparsedValue', currentValue, parsedValue);
            const currentValue = currentValue || parsedValue;
            if (!(!required && _this.$common.isEmpty(currentValue))) {
              if (currentValue || parsedValue) {
                await setTimeout(() => _this.handleSelfClick(ref, input.type), 500);
              }
              return;
            }
            // 一些非必须的lov存在enter事件, 判断光标的走向
            if (input.$listeners.enter && this.isFunction(input.$listeners.enter)) {
              await input.$listeners.enter();
              return;
              // const refElementObj = this.$refs['ref' + ref] || {};
              // const currentValue = refElementObj.$refs.input.value;
            }
          } catch (err) {
            console.error(' lov enter error ******', err);
            return;
          }
        }
        !required && _this.skipNext(nextRef > maxIndex || nextRef === 'submitRef' ? 'submitRef' : nextRef);
      }
    },
    /*
     *@functionName: saveSourceParseField
     *@description: 保存来源解析字段并赋值Enter
     *@params1: fieldObj 字段对象信息,
     *@author: LiuRun
     *@date: 2020-12-21 09:15:37
     *@version: V1.0.0
     */
    async saveSourceParseField(_this, fieldObj) {
      const { fieldCode, parseValue, callback, value } = fieldObj;
      _this.sourceParseField[fieldCode] = parseValue;
      const inputField = _this.inputFieldList.filter((f) => f.field === fieldCode)[0];
      const { required, ref } = inputField;
      try {
        // 来源解析字段getData回调函数
        if ((required || value) && this.isFunction(callback)) await callback();
        if (!required && this.$common.isEmpty(value)) {
          // console.log('当非LOV型的二维码字段, 需要执行自动enter');
          let nextRef = isNaN(Number(ref)) ? ref : Number(ref) + 1;
          if (!this.$refs[`ref${nextRef}`]) {
            nextRef += 1;
          }
          const inputFieldLength = this.inputFieldList.length;
          const maxIndex = this.inputFieldList[inputFieldLength - 1].ref;
          nextRef = nextRef > maxIndex ? 'submitRef' : nextRef;
          // console.log('nextRef', nextRef);
          this.$nextTick(() => {
            this.skipNext(nextRef);
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 函数队列
    async queue(promiseArr) {
      let res = [];
      for (let fn of promiseArr) {
        const data = await fn();
        res.push(data);
      }
      return await res;
    },
    // 移除数组中特定元素
    remove(arr, val) {
      const index = arr.indexOf(val);
      if (index > -1) {
        return arr.splice(index, 1);
      }
      return arr;
    },
    // 是否为函数
    isFunction(fn) {
      return Object.prototype.toString.call(fn) === '[object Function]';
    },
    // 清除当前界面的二维码信息
    clearSourceFields(_this, directlyDelete) {
      if (directlyDelete) {
        this.sourceParseField = {};
        return;
      }
      // const staticThis = _this;
      const keys = Object.keys(_this.sourceParseField);
      if (keys.length > 0) {
        keys.forEach((fieldKey) => {
          this.clearSourceField(_this, fieldKey);
          // _this = staticThis;
        });
      }
      _this.sourceParseField = {};
    },
    clearSourceField(_this, fieldKey) {
      const inputField = _this.inputFieldList.filter((f) => f.field === fieldKey)[0];
      if (inputField) {
        const { bind: sourceFieldBind } = inputField;
        const bindCode = sourceFieldBind.split('.');
        let keycode = '';
        if (bindCode.length > 1) {
          let dynamicTarget = null;
          bindCode.forEach((code, i) => {
            keycode = code;
            if (i < bindCode.length - 1) dynamicTarget = _this[code];
          });
          dynamicTarget[keycode] = null;
        } else {
          keycode = bindCode[0];
          _this[keycode] = null;
        }
      }
    },
    // 控制确认提交状态
    handleSubmitStatus() {
      this.submitLoading = !this.submitLoading;
    },
    // 校验必输字段是否有默认值
    checkRequiredFiled(options = {}) {
      let breakOut = false;
      const { _this, fieldCode, fieldName, hideableFlag, parsedValue } = options;
      const inputFieldObj = _this.inputFieldList.filter((f) => f.field === fieldCode)[0];
      const { required } = inputFieldObj;
      const language = storage.get('language');
      console.log('language', language);
      if (required && hideableFlag && this.$common.isEmpty(parsedValue)) {
        this.$hint.showFailed(
          `${this.$i18n.t('message.requiredField')}${language === 'zh_CN' ? fieldName : fieldCode}${this.$i18n.t(
            'message.retrievalValueField'
          )}
            ${this.$i18n.t('message.checkAppConfig')}
          `,
          false // 是否自动关闭消息提示
        );
        breakOut = true;
      }
      return breakOut;
    },
    // 个性化默认值配置方式
    customDefaultValueConfig(_this, custom, sourceParseField, currentValue) {
      const {
        fieldCode,
        fieldValueSourceType,
        defaultValue,
        sourceField,
        valueField,
        valueSourceIndex,
        //  autoEnterFlag,
      } = custom;
      let parsedValue = null;

      // 判断值字段类型
      if (fieldValueSourceType === 'CHARACTER' && defaultValue) {
        // 固定值
        parsedValue = defaultValue;
      } else if (fieldValueSourceType === 'FIELD' && sourceField) {
        // 字段
        parsedValue = this.getSourceFieldValue(_this, sourceField);
      } else if (fieldValueSourceType === 'VALUE' && valueField && valueSourceIndex) {
        // 二维码
        if (fieldCode === valueField && this.$common.isEmpty(currentValue)) {
          return;
        }
        const parseValue = sourceParseField[valueField] ? sourceParseField[valueField] : [];
        parsedValue = parseValue[Number(valueSourceIndex) - 1];
      }
      console.log('解析值', parsedValue);
      return parsedValue;
    },
    // 获取页面功能参数
    fetchPageFunctionConfig(config) {
      let data = {
        params: { ...config },
      };
      const url = `${this.winvBaseUrl}wms-common/batch-query-page-params`;
      return request.get(url, data);
    },
  },
};
