import ReceiptAdjust from '../views/receipt-adjust.vue'


let route = [
  {
    path: '/receipt-adjust',
    name: 'ReceiptAdjust',
    component: ReceiptAdjust,
  },
]

export default route
