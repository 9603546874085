import MultiLpnPackUnpack from '../views/multi-lpn-pack-unpack.vue'


let route = [
  {
    path: '/multi-lpn-pack-unpack',
    name: 'MultiLpnPackUnpack',
    component: MultiLpnPackUnpack,
  },
]

export default route
