import request from '@/request';
import mixin  from '@/mixin/mixin';

// 撤销挑库
export function loadCancel(data) {
  const url = `${mixin.data().winvBaseUrl}app-outbound-load-cancel/load-cancel`;
  return request.post(url, data);
}

// 查询托盘详情
export function queryCidDetail(param) {
  const url = `${mixin.data().winvBaseUrl}app-outbound-load-cancel/query-container-details`;
  return request.get(url, param);
}

// 查询物料详情
export function queryGoodsDetail(param) {
  const url = `${mixin.data().winvBaseUrl}app-outbound-load-cancel/query-container-goods-details`;
  return request.get(url, param);
}

// 查询序列号详情
export function goSerialNumberDetail(param) {
  const url = `${mixin.data().winvBaseUrl}app-outbound-load-cancel/query-container-serial-details`;
  return request.get(url, param);
}





