import moreAnalyse from '../views/more-analyse';
import PackAnalyse from '../views/pack-analyse';
import DetailAnalyse from '../views/detail-analyse';
let route = [
  {
    path: '/analyse',
    name: 'moreAnalyse',
    component: moreAnalyse,
  },
  {
    path: '/pack-analyse',
    name: 'PackAnalyse',
    component: PackAnalyse,
  },
  {
    path: '/detail-analyse',
    name: 'DetailAnalyse',
    component: DetailAnalyse,
  },
];

export default route;
