var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: {
            title: _vm.$t("title.subinventoryTransfer"),
            "is-index": false,
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "header-row" }, [
      _c("div", { staticClass: "header-title" }, [
        _vm._v("\n      " + _vm._s(_vm.serialData.serialNumber) + "\n    "),
      ]),
      _c(
        "div",
        {
          class: {
            "header-0-status": _vm.serialData.status === "0-DEFINED",
            "header-1-status": _vm.serialData.status === "1-INSTOCK",
            "header-2-status": _vm.serialData.status === "2-SHIPPED",
          },
        },
        [_vm._v("\n      " + _vm._s(_vm.serialData.statusName) + "\n    ")]
      ),
    ]),
    _c("div", { staticClass: "ips-container" }, [
      _c("div", { staticClass: "menu-row" }, [
        _c("div", { staticClass: "menu-title" }, [
          _vm._v("\n        " + _vm._s(_vm.$t("label.sku")) + "：\n      "),
        ]),
        _c("div", { staticClass: "menu-value" }, [
          _vm._v("\n        " + _vm._s(_vm.serialData.sku) + "\n      "),
        ]),
      ]),
      _c("div", { staticClass: "menu-row" }, [
        _c("div", { staticClass: "menu-title" }, [
          _vm._v("\n        " + _vm._s(_vm.$t("label.unit")) + "：\n      "),
        ]),
        _c("div", { staticClass: "menu-value" }, [
          _vm._v("\n        " + _vm._s(_vm.serialData.uomName) + "\n      "),
        ]),
      ]),
      _c("div", { staticClass: "menu-row" }, [
        _c("div", { staticClass: "menu-title" }, [
          _vm._v(
            "\n        " + _vm._s(_vm.$t("label.goodsName")) + "：\n      "
          ),
        ]),
        _c("div", { staticClass: "menu-value" }, [
          _vm._v("\n        " + _vm._s(_vm.serialData.goodsName) + "\n      "),
        ]),
      ]),
      _c(
        "div",
        {
          class: _vm.isbatch ? "card_batch_subtrans" : "card_nobatch_subtrans",
        },
        [
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("div", { staticClass: "title" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("label.transferFrom")) +
                    "\n        "
                ),
              ]),
              _c("svg-icon", {
                attrs: { slot: "icon", "icon-class": "arrow" },
                slot: "icon",
              }),
            ],
            1
          ),
          _c("ips-lov", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.customData["fromCidCodeBySerial"]
                  ? _vm.hideControl["fromCidCodeBySerial"]
                  : true,
                expression:
                  "customData['fromCidCodeBySerial'] ? hideControl['fromCidCodeBySerial']: true",
              },
            ],
            ref: "ref0",
            attrs: {
              label: _vm.$t("label.startOfTray"),
              title: _vm.$t("label.startOfTray"),
              disabled: _vm.enableSerial,
              params: "cidCode",
              url:
                _vm.winvBaseUrl + "new-stock-trans/query-container-serial-lov",
              "url-params": _vm.cidParam,
              "pre-parse-url": _vm.urlData["fromCidCodeBySerial"],
              custom: _vm.customData["fromCidCodeBySerial"],
              "source-parse-field": _vm.sourceParseField,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(0)
              },
              clickIcon: function ($event) {
                return _vm.handleCommonClick(0, "lov")
              },
              enter: function ($event) {
                return _vm.skipNext(10)
              },
              down: function ($event) {
                return _vm.skipNext(10)
              },
              tab: function ($event) {
                return _vm.skipNext(10)
              },
              choose: function ($event) {
                return _vm.handleChoose("fromCidCodeBySerial", $event)
              },
              setSourceField: function ($event) {
                return _vm.saveSourceField($event)
              },
              valuedByFoucs: function ($event) {
                return _vm.valuedByFoucs($event)
              },
            },
            model: {
              value: _vm.fromCidCodeBySerial,
              callback: function ($$v) {
                _vm.fromCidCodeBySerial = $$v
              },
              expression: "fromCidCodeBySerial",
            },
          }),
          _c("ips-field", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.parentCidEnabled.paramValue == 1
                    ? _vm.fromParentCidCodeBySerial
                    : false,
                expression:
                  "parentCidEnabled.paramValue==1? fromParentCidCodeBySerial:false",
              },
            ],
            ref: "ref5",
            attrs: {
              label: _vm.$t("label.startOfParentTray"),
              title: _vm.$t("label.startOfParentTray"),
              params: _vm.fromParentCidCodeBySerial,
              disabled: "",
              display: "parentCidCode",
              custom: _vm.customData["fromParentCidCodeBySerial"],
              "source-parse-field": _vm.sourceParseField,
            },
            model: {
              value: _vm.fromParentCidCodeBySerial,
              callback: function ($$v) {
                _vm.fromParentCidCodeBySerial = $$v
              },
              expression: "fromParentCidCodeBySerial",
            },
          }),
          _c("ips-lov", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.customData["fromLocationCodeBySerial"]
                  ? _vm.hideControl["fromLocationCodeBySerial"]
                  : true,
                expression:
                  "customData['fromLocationCodeBySerial'] ? hideControl['fromLocationCodeBySerial']: true",
              },
            ],
            ref: "ref10",
            attrs: {
              label: _vm.$t("label.startOfLocation"),
              title: _vm.$t("label.startOfLocation"),
              disabled: _vm.enableSerial,
              required: "",
              params: "locationCode",
              url: _vm.winvBaseUrl + "new-stock-trans/query-location-serial",
              "url-params": _vm.locationParam,
              "pre-parse-url": _vm.urlData["fromLocationCodeBySerial"],
              custom: _vm.customData["fromLocationCodeBySerial"],
              "source-parse-field": _vm.sourceParseField,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(10)
              },
              clickIcon: function ($event) {
                return _vm.handleCommonClick(10, "lov")
              },
              enter: function ($event) {
                return _vm.skipNext(30)
              },
              down: function ($event) {
                return _vm.skipNext(30)
              },
              tab: function ($event) {
                return _vm.skipNext(30)
              },
              choose: function ($event) {
                return _vm.handleChoose("fromLocationCodeBySerial", $event)
              },
              setSourceField: function ($event) {
                return _vm.saveSourceField($event)
              },
              valuedByFoucs: function ($event) {
                return _vm.valuedByFoucs($event)
              },
            },
            model: {
              value: _vm.fromLocationCodeBySerial,
              callback: function ($$v) {
                _vm.fromLocationCodeBySerial = $$v
              },
              expression: "fromLocationCodeBySerial",
            },
          }),
          _c("ips-lov", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isbatch
                  ? _vm.customData["batchCodeBySerial"]
                    ? _vm.hideControl["batchCodeBySerial"]
                    : _vm.isbatch
                  : false,
                expression:
                  "isbatch ? (customData['batchCodeBySerial'] ? hideControl['batchCodeBySerial'] : isbatch) : false",
              },
            ],
            ref: "ref20",
            attrs: {
              label: _vm.$t("label.batch"),
              disabled: "",
              required: "",
              title: _vm.$t("label.batch"),
              params: "batchCode",
              url: _vm.winvBaseUrl + "new-stock-trans/query-batch-from",
              "pre-parse-url": _vm.urlData["batchCodeBySerial"],
              custom: _vm.customData["batchCodeBySerial"],
              "source-parse-field": _vm.sourceParseField,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(20)
              },
              clickIcon: function ($event) {
                return _vm.handleCommonClick(20, "lov")
              },
              choose: function ($event) {
                return _vm.handleChoose("batch", $event)
              },
              setSourceField: function ($event) {
                return _vm.saveSourceField($event)
              },
              valuedByFoucs: function ($event) {
                return _vm.valuedByFoucs($event)
              },
            },
            model: {
              value: _vm.batchCodeBySerial,
              callback: function ($$v) {
                _vm.batchCodeBySerial = $$v
              },
              expression: "batchCodeBySerial",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "card_countLocation" },
        [
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("svg-icon", {
                attrs: { slot: "icon", "icon-class": "arrow" },
                slot: "icon",
              }),
              _c("div", { staticClass: "title" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("label.transferTo")) +
                    "\n        "
                ),
              ]),
            ],
            1
          ),
          _c("ips-lov", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.customData["toCidCodeBySerial"]
                  ? _vm.hideControl["toCidCodeBySerial"]
                  : true,
                expression:
                  "customData['toCidCodeBySerial'] ? hideControl['toCidCodeBySerial']: true",
              },
            ],
            ref: "ref30",
            attrs: {
              label: _vm.$t("label.targetTray"),
              title: _vm.$t("label.targetTray"),
              params: "cidCode",
              url: _vm.winvBaseUrl + "new-stock-trans/query-container-to",
              "url-params": _vm.toCidParam,
              "pre-parse-url": _vm.urlData["toCidCodeBySerial"],
              custom: _vm.customData["toCidCodeBySerial"],
              "source-parse-field": _vm.sourceParseField,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(30)
              },
              clickIcon: function ($event) {
                return _vm.handleCommonClick(30, "lov")
              },
              enter: _vm.handleToCidEnter,
              down: _vm.handleToCidEnter,
              tab: _vm.handleToCidTab,
              choose: function ($event) {
                return _vm.handleChoose("toCidCodeBySerial", $event)
              },
              setSourceField: function ($event) {
                return _vm.saveSourceField($event)
              },
              valuedByFoucs: function ($event) {
                return _vm.valuedByFoucs($event)
              },
            },
            model: {
              value: _vm.toCidCodeBySerial,
              callback: function ($$v) {
                _vm.toCidCodeBySerial = $$v
              },
              expression: "toCidCodeBySerial",
            },
          }),
          _c("ips-lov", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.parentCidEnabled.paramValue == 1 ? true : false,
                expression: "parentCidEnabled.paramValue==1?true:false",
              },
            ],
            ref: "ref35",
            attrs: {
              label: _vm.$t("label.targetParentTray"),
              title: _vm.$t("label.targetParentTray"),
              required: _vm.transferType === "PACK",
              disabled: _vm.isHasParentCid,
              params: "cidCode",
              url:
                _vm.winvBaseUrl +
                "new-stock-trans/query-trans-to-parent-container",
              "url-params": _vm.toCidParam,
              "pre-parse-url": _vm.urlData["toParentCidCodeBySerial"],
              custom: _vm.customData["batchCodeBySerial"],
              "source-parse-field": _vm.sourceParseField,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(35)
              },
              clickIcon: function ($event) {
                return _vm.handleCommonClick(35, "lov")
              },
              enter: _vm.handleToParentCidEnter,
              down: _vm.handleToParentCidEnter,
              tab: _vm.handleToParentCidTab,
              choose: function ($event) {
                return _vm.handleChoose("toParentCidCodeBySerial", $event)
              },
              setSourceField: function ($event) {
                return _vm.saveSourceField($event)
              },
              valuedByFoucs: function ($event) {
                return _vm.valuedByFoucs($event)
              },
            },
            model: {
              value: _vm.toParentCidCodeBySerial,
              callback: function ($$v) {
                _vm.toParentCidCodeBySerial = $$v
              },
              expression: "toParentCidCodeBySerial",
            },
          }),
          _c("ips-lov", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.customData["toLocationCodeBySerial"]
                  ? _vm.hideControl["toLocationCodeBySerial"]
                  : true,
                expression:
                  "customData['toLocationCodeBySerial'] ? hideControl['toLocationCodeBySerial']: true",
              },
            ],
            ref: "ref40",
            attrs: {
              label: _vm.$t("label.targetLocation"),
              title: _vm.$t("label.targetLocation"),
              required: "",
              params: "locationCode",
              disabled: _vm.isToCidCode,
              url: _vm.winvBaseUrl + "new-stock-trans/query-location-to",
              "url-params": _vm.toLocationParam,
              "pre-parse-url": _vm.urlData["toLocationCodeBySerial"],
              custom: _vm.customData["toLocationCodeBySerial"],
              "source-parse-field": _vm.sourceParseField,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(40)
              },
              clickIcon: function ($event) {
                return _vm.handleCommonClick(40, "lov")
              },
              enter: function ($event) {
                return _vm.skipNext("submitRef")
              },
              down: function ($event) {
                return _vm.skipNext("submitRef")
              },
              tab: function ($event) {
                return _vm.skipNext("submitRef")
              },
              choose: function ($event) {
                return _vm.handleChoose("toLocationCodeBySerial", $event)
              },
              setSourceField: function ($event) {
                return _vm.saveSourceField($event)
              },
              valuedByFoucs: function ($event) {
                return _vm.valuedByFoucs($event)
              },
            },
            model: {
              value: _vm.toLocationCodeBySerial,
              callback: function ($$v) {
                _vm.toLocationCodeBySerial = $$v
              },
              expression: "toLocationCodeBySerial",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          class: _vm.enableSerial
            ? "card_num_subtrans_serial"
            : "card_num_subtrans",
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("label.transferNumber")) +
                  "\n        "
              ),
            ]),
          ]),
          _c("ips-input-number", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.customData["transferQtyBySerial"]
                  ? _vm.hideControl["transferQtyBySerial"]
                  : true,
                expression:
                  "customData['transferQtyBySerial'] ? hideControl['transferQtyBySerial']: true",
              },
            ],
            ref: "ref50",
            attrs: {
              disabled: "",
              required: "",
              "not-auto-fill": "",
              "more-than-zero": true,
              label: _vm.$t("label.transferNumber"),
              custom: _vm.customData["transferQtyBySerial"],
              "source-parse-field": _vm.sourceParseField,
            },
            on: {
              enter: function ($event) {
                return _vm.skipNext("submitRef")
              },
              down: function ($event) {
                return _vm.skipNext("submitRef")
              },
              tab: function ($event) {
                return _vm.skipNext("submitRef")
              },
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(60)
              },
              setSourceField: function ($event) {
                return _vm.saveSourceField($event)
              },
              valuedByFoucs: function ($event) {
                return _vm.valuedByFoucs($event)
              },
            },
            model: {
              value: _vm.transferQtyBySerial,
              callback: function ($$v) {
                _vm.transferQtyBySerial = $$v
              },
              expression: "transferQtyBySerial",
            },
          }),
          !_vm.enableSerial
            ? _c("div", { staticClass: "row-num" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("label.locationUseQty")) +
                      ":\n        "
                  ),
                ]),
                _c("div", { staticClass: "value" }, [
                  _vm._v(
                    "\n          " + _vm._s(_vm.locationValidQty) + "\n        "
                  ),
                ]),
              ])
            : _vm._e(),
          _c("div", { staticClass: "row-num" }, [
            _c("div", { staticClass: "row-50" }, [
              _c("div", { staticClass: "title" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("label.availableQty")) +
                    ":\n          "
                ),
              ]),
              _c("div", { staticClass: "value" }, [
                _vm._v("\n            " + _vm._s(_vm.qty) + "\n          "),
              ]),
            ]),
            _c("div", { staticClass: "row-50" }, [
              _c("div", { staticClass: "title" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("label.currentUseQty")) +
                    ":\n          "
                ),
              ]),
              _c("div", { staticClass: "value" }, [
                _vm._v(
                  "\n            " + _vm._s(_vm.validQty) + "\n          "
                ),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "ips-footer" },
      [
        _c(
          "hips-button",
          {
            ref: "submitRef",
            class: ["ips-btn-normal"],
            attrs: { size: "large" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonSubmit.apply(null, arguments)
              },
              enter: _vm.handleCommonSubmit,
            },
          },
          [
            _c("svg-icon", {
              attrs: { slot: "icon", "icon-class": "success" },
              slot: "icon",
            }),
            _vm._v("\n      " + _vm._s(_vm.$t("button.confirm")) + "\n    "),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }