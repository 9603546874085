<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.trayInfoShow')" :is-index="false" />
    </div>
    <div class="ips-container">
      <!-- 托盘 -->
      <ips-value-cell :title="$t('label.tray')" :value="cidCode" />
      <!-- 库位 -->
      <ips-value-cell :title="$t('label.countLocation')" :value="lineData.locationCode" />
      <!-- 托盘状态 -->
      <ips-value-cell :title="$t('label.trayState')" :value="lineData.cidStatusName" />
      <!-- 托盘现有量 -->
      <ips-value-cell :title="$t('label.trayCurrentQty')" :value="lineData.qty" />
      <!-- 托盘可用量 -->
      <ips-value-cell :title="$t('label.trayAvailability')" :value="lineData.validQty" />
    </div>
    <div class="ips-footer">
      <hips-button size="large" ref="submitRef" @click.stop="handleCommonSubmit">
        <svg-icon slot="icon" icon-class="success" />
        {{ $t('button.theDetail') }}
      </hips-button>
    </div>
  </hips-view>
</template>

<script>
  import {queryCid} from "../api";
  export default {
  name: 'ContanierData',
  data() {
    return {
      cidCode: '', //托盘编码
      lineData: {},
    };
  },
  methods: {
    getDataFromParams() {
      const { cidCode } = this.$route.params;
      if (cidCode) {
        this.cidCode = cidCode;
      }    
    },

    async getCidData() {
      try {
        this.$hint.showLoading();
        // let url = `${this.winvBaseUrl}app-inv/query-cid`;
        let data = {
          params: {
            cidCode: this.cidCode,
          },
        };
        // this.lineData = await this.$http.get(url, data);
        this.lineData = await queryCid(data);
      }finally {
        this.$hint.hideLoading();
      } 
    },

    /**
    * getData 前往明细界面
    */
    handleButtonSubmit() {
      const param = {
        name: 'ContanierDetail',
        params: {
          cidCode: this.cidCode,
        },
      };
      this.$router.pushPage(param);
    },
  },
  activated() {
    this.getDataFromParams();
    this.getCidData();
  }
};
</script>

<style lang="stylus" scoped></style>
