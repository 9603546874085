var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "hips-view",
    { attrs: { "header-fixed": true } },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("ips-header", {
            attrs: { title: _vm.$t("title.roundsTransform") },
          }),
          _c(
            "div",
            {
              staticClass: "hips-nav-bar__buttons hips-nav-bar__buttons--right",
            },
            [
              _c(
                "hips-button",
                {
                  staticClass: "header-right",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleShowPopup.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("button.configuration")) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "hips-popup",
        {
          on: { "click-overlay": _vm.onPopupClose },
          model: {
            value: _vm.showPopup,
            callback: function ($$v) {
              _vm.showPopup = $$v
            },
            expression: "showPopup",
          },
        },
        [
          _c(
            "div",
            { staticClass: "popup" },
            [
              _c("hips-switch-cell", {
                attrs: { title: _vm.$t("label.locationTran") },
                on: { change: _vm.switchTag },
                model: {
                  value: _vm.processId,
                  callback: function ($$v) {
                    _vm.processId = $$v
                  },
                  expression: "processId",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "ips-container" },
        [
          _c("ips-lov", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.customData["fromWharea"]
                  ? _vm.hideControl["fromWharea"]
                  : true,
                expression:
                  "customData['fromWharea'] ? hideControl['fromWharea'] : true",
              },
            ],
            ref: "ref0",
            attrs: {
              label: _vm.$t("label.sourceWharea"),
              title: [
                _vm.$t("label.fromWhareaCode"),
                _vm.$t("label.fromWhareaName"),
              ],
              params: ["whareaCode", "whareaName"],
              display: "whareaName",
              required: "",
              url: _vm.wmdmBaseUrl + "whareas/accessible-list",
              "pre-parse-url": _vm.urlData["fromWharea"],
              custom: _vm.customData["fromWharea"],
              "source-parse-field": _vm.sourceParseField,
              "error-msg": this.$i18n.t("message.countLocationCkeck"),
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(0)
              },
              enter: function ($event) {
                return _vm.skipNext(10)
              },
              down: function ($event) {
                return _vm.skipNext(10)
              },
              choose: function ($event) {
                return _vm.handleChoose("fromWharea", $event)
              },
              clickIcon: function ($event) {
                return _vm.handleCommonClick(0, "lov")
              },
              setSourceField: function ($event) {
                return _vm.saveSourceField($event)
              },
              valuedByFoucs: function ($event) {
                return _vm.valuedByFoucs($event)
              },
            },
            model: {
              value: _vm.inputData.fromWharea,
              callback: function ($$v) {
                _vm.$set(_vm.inputData, "fromWharea", $$v)
              },
              expression: "inputData.fromWharea",
            },
          }),
          _c("ips-lov", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.customData["fromLocation"]
                  ? _vm.hideControl["fromLocation"]
                  : true,
                expression:
                  "customData['fromLocation'] ? hideControl['fromLocation'] : true",
              },
            ],
            ref: "ref10",
            attrs: {
              label: _vm.$t("label.sourceLocation"),
              title: [
                _vm.$t("label.countLocationCode"),
                _vm.$t("label.locationName"),
              ],
              params: ["locationCode", "locationName"],
              display: "locationCode",
              required: "",
              url: _vm.wmdmBaseUrl + "pda-goods-change/from-stock-location",
              "url-params": { whareaId: _vm.whareaId },
              "pre-parse-url": _vm.urlData["fromLocation"],
              custom: _vm.customData["fromLocation"],
              "source-parse-field": _vm.sourceParseField,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(10)
              },
              enter: function ($event) {
                return _vm.skipNext(20)
              },
              down: function ($event) {
                return _vm.skipNext(20)
              },
              choose: function ($event) {
                return _vm.handleChoose("fromLocation", $event)
              },
              clickIcon: function ($event) {
                return _vm.handleCommonClick(10, "lov")
              },
              setSourceField: function ($event) {
                return _vm.saveSourceField($event)
              },
              valuedByFoucs: function ($event) {
                return _vm.valuedByFoucs($event)
              },
            },
            model: {
              value: _vm.inputData.fromLocation,
              callback: function ($$v) {
                _vm.$set(_vm.inputData, "fromLocation", $$v)
              },
              expression: "inputData.fromLocation",
            },
          }),
          _c("ips-lov", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.customData["fromSku"]
                  ? _vm.hideControl["fromSku"]
                  : true,
                expression:
                  "customData['fromSku'] ? hideControl['fromSku'] : true",
              },
            ],
            ref: "ref20",
            attrs: {
              label: _vm.$t("label.sourceSku"),
              title: _vm.$t("label.skuCode"),
              params: "sku",
              display: "sku",
              required: "",
              url: _vm.wmdmBaseUrl + "pda-goods-change/from-goods",
              "url-params": {
                whareaId: _vm.whareaId,
                locationId: _vm.fromLocationId,
              },
              "pre-parse-url": _vm.urlData["fromSku"],
              custom: _vm.customData["fromSku"],
              "source-parse-field": _vm.sourceParseField,
              "error-msg": this.$i18n.t("message.countLocationCkeck"),
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(20)
              },
              enter: function ($event) {
                _vm.showbatch ? _vm.skipNext(30) : _vm.skipNext(40)
              },
              down: function ($event) {
                _vm.showbatch ? _vm.skipNext(30) : _vm.skipNext(40)
              },
              choose: function ($event) {
                return _vm.handleChoose("fromSku", $event)
              },
              clickIcon: function ($event) {
                return _vm.handleCommonClick(20, "lov")
              },
              setSourceField: function ($event) {
                return _vm.saveSourceField($event)
              },
              valuedByFoucs: function ($event) {
                return _vm.valuedByFoucs($event)
              },
            },
            model: {
              value: _vm.inputData.fromSku,
              callback: function ($$v) {
                _vm.$set(_vm.inputData, "fromSku", $$v)
              },
              expression: "inputData.fromSku",
            },
          }),
          _c("ips-lov", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showbatch,
                expression: "showbatch",
              },
            ],
            ref: "ref30",
            attrs: {
              label: _vm.$t("label.sourceBatchs"),
              title: _vm.$t("label.batchCodeNew"),
              params: "batchCode",
              display: "batchCode",
              required: _vm.showbatch,
              url: _vm.wmdmBaseUrl + "pda-goods-change/from-batch",
              "url-params": {
                whareaId: _vm.whareaId,
                locationId: _vm.fromLocationId,
                goodsId: _vm.fromGoodsId,
              },
              "pre-parse-url": _vm.urlData["fromBatchCode"],
              custom: _vm.customData["fromBatchCode"],
              "source-parse-field": _vm.sourceParseField,
              "error-msg": _vm.$i18n.t("message.countLocationCkeck"),
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(30)
              },
              enter: function ($event) {
                return _vm.skipNext(40)
              },
              down: function ($event) {
                return _vm.skipNext(40)
              },
              choose: function ($event) {
                return _vm.handleChoose("fromBatchCode", $event)
              },
              clickIcon: function ($event) {
                return _vm.handleCommonClick(30, "lov")
              },
              setSourceField: function ($event) {
                return _vm.saveSourceField($event)
              },
              valuedByFoucs: function ($event) {
                return _vm.valuedByFoucs($event)
              },
            },
            model: {
              value: _vm.inputData.fromBatchCode,
              callback: function ($$v) {
                _vm.$set(_vm.inputData, "fromBatchCode", $$v)
              },
              expression: "inputData.fromBatchCode",
            },
          }),
          _c("ips-value-cell", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.customData["goodsName"]
                  ? _vm.hideControl["goodsName"]
                  : true,
                expression:
                  "customData['goodsName'] ? hideControl['goodsName'] : true",
              },
            ],
            attrs: { title: _vm.$t("label.goodsName"), value: _vm.goodsName },
          }),
          _c("ips-value-cell", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.customData["haveQty"]
                  ? _vm.hideControl["haveQty"]
                  : true,
                expression:
                  "customData['haveQty'] ? hideControl['haveQty'] : true",
              },
            ],
            attrs: { title: _vm.$t("label.availableQty"), value: _vm.haveQty },
          }),
          _c("ips-value-cell", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.customData["availableQty"]
                  ? _vm.hideControl["availableQty"]
                  : true,
                expression:
                  "customData['availableQty'] ? hideControl['availableQty'] : true",
              },
            ],
            attrs: { title: _vm.$t("label.useQty"), value: _vm.availableQty },
          }),
          _c("ips-lov", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.customData["toLocation"]
                  ? _vm.hideControl["toLocation"]
                  : true,
                expression:
                  "customData['toLocation'] ? hideControl['toLocation'] : true",
              },
            ],
            ref: "ref40",
            attrs: {
              label: _vm.$t("label.targetLocation"),
              title: [
                _vm.$t("label.countLocationCode"),
                _vm.$t("label.locationName"),
              ],
              params: ["locationCode", "locationName"],
              display: "locationCode",
              required: "",
              url: _vm.wmdmBaseUrl + "wms-locations",
              "url-params": {
                enabledFlag: 1,
                warehouseId: _vm.fromWarehouseId,
              },
              "pre-parse-url": _vm.urlData["toLocation"],
              custom: _vm.customData["toLocation"],
              "source-parse-field": _vm.sourceParseField,
              "error-msg": this.$i18n.t("message.countLocationCkeck"),
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(40)
              },
              enter: function ($event) {
                return _vm.skipNext(50)
              },
              down: function ($event) {
                return _vm.skipNext(50)
              },
              choose: function ($event) {
                return _vm.handleChoose("toLocation", $event)
              },
              clickIcon: function ($event) {
                return _vm.handleCommonClick(40, "lov")
              },
              setSourceField: function ($event) {
                return _vm.saveSourceField($event)
              },
              valuedByFoucs: function ($event) {
                return _vm.valuedByFoucs($event)
              },
            },
            model: {
              value: _vm.inputData.toLocation,
              callback: function ($$v) {
                _vm.$set(_vm.inputData, "toLocation", $$v)
              },
              expression: "inputData.toLocation",
            },
          }),
          _c("ips-lov", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.customData["toSkuName"]
                  ? _vm.hideControl["toSkuName"]
                  : true,
                expression:
                  "customData['toSkuName'] ? hideControl['toSkuName'] : true",
              },
            ],
            ref: "ref50",
            attrs: {
              label: _vm.$t("label.toSku"),
              title: _vm.$t("label.skuCode"),
              params: "sku",
              display: "sku",
              required: "",
              url: _vm.wmdmBaseUrl + "pda-goods-change/to-goods",
              "url-params": { fromGoodsId: _vm.fromGoodsId },
              "pre-parse-url": _vm.urlData["toSkuName"],
              custom: _vm.customData["toSkuName"],
              "source-parse-field": _vm.sourceParseField,
              "error-msg": this.$i18n.t("message.countLocationCkeck"),
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(50)
              },
              enter: function ($event) {
                return _vm.skipJudge()
              },
              down: function ($event) {
                return _vm.skipJudge()
              },
              choose: function ($event) {
                return _vm.handleChoose("toSkuName", $event)
              },
              clickIcon: function ($event) {
                return _vm.handleCommonClick(50, "lov")
              },
              setSourceField: function ($event) {
                return _vm.saveSourceField($event)
              },
              valuedByFoucs: function ($event) {
                return _vm.valuedByFoucs($event)
              },
            },
            model: {
              value: _vm.inputData.toSkuName,
              callback: function ($$v) {
                _vm.$set(_vm.inputData, "toSkuName", $$v)
              },
              expression: "inputData.toSkuName",
            },
          }),
          _c("ips-input", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showBatch(),
                expression: "showBatch()",
              },
            ],
            ref: "ref60",
            attrs: {
              label: _vm.$t("label.targetBatch"),
              custom: _vm.customData["toBatch"],
              "source-parse-field": _vm.sourceParseField,
              required: _vm.showBatch(),
              "pre-parse-url": _vm.urlData["toBatch"],
              "not-auto-fill": "",
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(60)
              },
              enter: function ($event) {
                return _vm.skipNext(70)
              },
              down: function ($event) {
                return _vm.skipNext(70)
              },
              setSourceField: function ($event) {
                return _vm.saveSourceField($event)
              },
              valuedByFoucs: function ($event) {
                return _vm.valuedByFoucs($event)
              },
            },
            model: {
              value: _vm.inputData.toBatch,
              callback: function ($$v) {
                _vm.$set(_vm.inputData, "toBatch", $$v)
              },
              expression: "inputData.toBatch",
            },
          }),
          _c("ips-value-cell", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.customData["toGoodsName"]
                  ? _vm.hideControl["toGoodsName"]
                  : true,
                expression:
                  "customData['toGoodsName'] ? hideControl['toGoodsName'] : true",
              },
            ],
            attrs: { title: _vm.$t("label.goodsName"), value: _vm.toGoodsName },
          }),
          _c("ips-input", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.customData["qty"] ? _vm.hideControl["qty"] : true,
                expression: "customData['qty'] ? hideControl['qty'] : true",
              },
            ],
            ref: "ref70",
            attrs: {
              label: _vm.$t("label.transQty"),
              disabled: _vm.processId,
              "pre-parse-url": _vm.urlData["qty"],
              "not-auto-fill": "",
              min: null,
              custom: _vm.customData["qty"],
              "source-parse-field": _vm.sourceParseField,
            },
            on: {
              blur: _vm.transQtyBlur,
              enter: function ($event) {
                return _vm.skipNext("submitRef")
              },
              down: function ($event) {
                return _vm.skipNext("submitRef")
              },
              setSourceField: function ($event) {
                return _vm.saveSourceField($event)
              },
              valuedByFoucs: function ($event) {
                return _vm.valuedByFoucs($event)
              },
            },
            model: {
              value: _vm.inputData.qty,
              callback: function ($$v) {
                _vm.$set(_vm.inputData, "qty", $$v)
              },
              expression: "inputData.qty",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "ips-footer" },
        [
          _c(
            "hips-button",
            {
              ref: "submitRef",
              attrs: { size: "large" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleCommonSubmit.apply(null, arguments)
                },
              },
            },
            [
              _c("svg-icon", {
                attrs: { slot: "icon", "icon-class": "success" },
                slot: "icon",
              }),
              _vm._v("\n      " + _vm._s(_vm.$t("button.confirm")) + "\n    "),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }