<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.ReceivingAdjustment')" />
    </div>
    <div class="ips-container">
      <!-- 收货托盘 -->
      <ips-lov
        :label="$t('label.receivingTray')"
        v-model="cidCode"
        required
        :title="$t('label.receivingTray')"
        params="cidCode"
        :url="`${winvBaseUrl}app-inbound-receive-adjust/cid-lov`"
        ref="ref0"
        @click.stop="handleCommonClick(0)"
        @clickIcon="handleCommonClick(0, 'lov')"
        @choose="handleChoose('cidCode', $event)"
      />
      <!-- 物料 -->
      <ips-lov
        :label="$t('label.sku')"
        v-model="sku"
        required
        :title="[$t('label.sku'), $t('label.billInfo')]"
        :params="['sku', 'billInfo']"
        :url="`${winvBaseUrl}app-inbound-receive-adjust/item-lov`"
        :url-params="skuParam"
        display="sku"
        ref="ref10"
        @click.stop="handleCommonClick(10)"
        @clickIcon="handleCommonClick(10, 'lov')"
        @enter="handleSkuEnter"
        @down="handleSkuEnter"
        @choose="handleChoose('sku', $event)"
      />
      <!-- 品名 -->
      <ips-value-cell :title="$t('label.goodsName')" :value="skuInfo.goodsName" />
      <!-- 单位 -->
      <ips-value-cell :title="$t('label.unit')" :value="skuInfo.uomName" />
      <!-- 入库单-行号 -->
      <ips-value-cell :title="$t('label.billInfo')" :value="skuInfo.billInfo" />
      <!-- 批次 -->
      <ips-lov
        :label="$t('label.batch')"
        v-show="isBatch"
        v-model="batchCode"
        required
        :title="$t('label.batch')"
        params="batchCode"
        :url="`${winvBaseUrl}app-inbound-receive-adjust/batch-lov`"
        :url-params="batchParam"
        ref="ref20"
        @click.stop="handleCommonClick(20)"
        @clickIcon="handleCommonClick(20, 'lov')"
        @enter="handleBatchEnter"
        @down="handleBatchEnter"
        @choose="handleChoose('batchCode', $event)"
      />
      <!-- 收货数量 -->
      <ips-value-cell :title="$t('label.receiveNum')" :value="skuInfo.receiveQty" />
      <!-- 序列号 -->
      <!-- <ips-lov
        :label="$t('label.serialNumber')"
        v-model="serialNumber"
        v-show="enableSerial"
        :required="enableSerial"
        :title="$t('label.serialNumber')"
        params="serialNumber"
        :url="`${winvBaseUrl}inbound-serial-receipts`"
        :url-params="enableSerialParam"
        ref="ref30"
        @click.stop="handleCommonClick(30)"
        @clickIcon="handleCommonClick(30, 'lov')"
        @enter="skipNext(40)"
        @choose="handleChoose('serialNumber', $event)"
      />-->
      <!-- 序列号录入 -->
      <div class="input-button" v-if="enableSerial">
        <hips-button
          @click="goToSerial"
          ref="inputRef"
          :style="{...inputBtnStyle}"
        >
          {{ $t('button.serialNumEntry') }}
        </hips-button>
      </div>
      <!-- 调整数量 -->
      <ips-input-number
        required
        v-model="changeQty"
        :disabled="qtyDisabled"
        :min="null"
        :more-than-zero="true"
        :label="$t('label.revisionNumber')"
        ref="ref40"
        :enable-serial="serialType"
        @enter="skipNext(50)"
        @down="skipNext(50)"
        @click.stop="handleCommonClick(40)"
        @blur="checkQtyBlur"
      />
      <!-- 调整原因 -->
      <ips-field
        required
        v-model="remark"
        :label="$t('label.fixReason')"
        ref="ref50"
        @enter="skipNext('submitRef')"
        @down="skipNext('submitRef')"
        @click.stop="handleCommonClick(50)"
      />
    </div>
    <div class="ips-footer">
      <hips-button size="large" @click.stop="handleCommonSubmit" ref="submitRef">
        <svg-icon slot="icon" icon-class="success" />
        {{ $t('button.submit') }}
      </hips-button>
    </div>
  </hips-view>
</template>

<script>
import { querySerialList, queryReceiptInfo, receiveReduce } from '../api';
export default {
  name: 'ReceivingAdjustment',
  data() {
    return {
      inputFieldList: [
        { type: 'lov', required: true, ref: 0, bind: 'cidCode' },
        { type: 'lov', required: true, ref: 10, bind: 'sku' },
        { type: 'lov', required: this.isBatch, ref: 20, bind: 'batchCode' },
        // { type: 'lov', required: true, ref: 30, bind: 'serialNumber' },
        { type: 'number', required: true, ref: 40, bind: 'changeQty' },
        { type: 'input', required: true, ref: 50, bind: 'remark' },
      ],
      cidId: '', // 收货托盘id
      cidCode: '', // 收货托盘
      goodsId: '', //物料id
      sku: '', //物料
      batchId: '', //批次id
      batchCode: '', //批次
      // serialId: '', // 序列号id
      serialNumber: '', // 序列号
      inboundSerialReceiptList: [], // 序列号数组
      isBatch: false, // 是否启用批次控制
      enableSerial: false, //是否启用序列号管理
      qtyDisabled: false, // 数量是否不可编辑
      submitRequest: {}, // 提交请求参数
      serialType: '',
      skuInfo: {
        goodsName: '',
        uomName: '',
        billInfo: '',
        receiveQty: '',
      }, // 物料明细
      skuParam: {}, // 物料请求参数
      batchParam: {}, // 批次lov参数
      // enableSerialParam: {}, // 序列号lov参数
      changeQty: '', // 修正数量
      remark: '', // 修正原因
    };
  },
  methods: {
    /**
     * 序列号录入
     */
    async goToSerial() {
      const data = {
        params: {
          receiptId: this.receiptId,
          status: '2-RECEIVED',
          serialNumber: '',
          size: 1000,
        },
      };
      const res = await querySerialList(data);
      const param = {
        name: 'inputSerial',
        params: {
          showInputBox: false,
          task: res.content || [],
        },
      };
      this.$router.pushPage(param);
    },

    /**
     * lov选中事件
     * @param {String} type - 定位lov
     * @param {object} obj - 选中行的数据
     */
    handleChoose(type, obj) {
      const site = [this.SERIAL_INV_CTRL, this.SERIAL_IN_CTRL, this.SERIAL_SEMI_INV_CTRL];
      switch (type) {
        case 'cidCode':
          this.cidId = obj.cidId;
          this.cidCode = obj.cidCode;
          this.skuParam = {
            cidId: this.cidId,
            cidCode: this.cidCode,
          };
          // this.skuInfo = obj;
          this.queryReceiptInfo(1);
          return;
        case 'sku':
          this.skuInfo = obj;
          this.goodsId = obj.goodsId;
          this.sku = obj.sku;
          this.serialType = obj.serialType;
          this.isBatch = obj.isbatch === 1 ? true : false;
          this.enableSerial = site.includes(obj.serialType);
          this.batchParam = {
            cidId: this.cidId,
            cidCode: this.cidCode,
            goodsId: this.goodsId,
            sku: this.sku,
          };
          this.queryReceiptInfo(2);
          return;
        case 'batchCode':
          this.batchId = obj.batchId;
          this.batchCode = obj.batchCode;
          this.queryReceiptInfo(3);
          // this.skuInfo = obj;
          return;
        // case 'serialNumber':
        //   this.serialId = obj.serialId;
        //   this.serialNumber = obj.serialNumber;
        //   var arr = obj;
        //   arr._status = 'delete';
        //   this.inboundSerialReceiptList = this.inboundSerialReceiptList.concat(arr);
        //   this.skipNext(50);
        //   return;
        default:
          return null;
      }
    },
    /**
     * 获取当前行下面的相应数据
     * @param ref 接口返回为空时，光标定位
     * @returns {Promise<void>}
     */
    async queryReceiptInfo(ref) {
      if (this.cidCode !== '') {
        try {
          this.$hint.showLoading();
          // const url = `${this.winvBaseUrl}app-inbound-receive-adjust/query-receipt-info`;
          let data = {
            params: {
              cidId: this.cidId,
              cidCode: this.cidCode,
              goodsId: this.goodsId,
              sku: this.sku,
              batchId: this.batchId,
              batchCode: this.batchCode,
            },
          };
          // const res = await this.$http.get(url, data);
          const res = await queryReceiptInfo(data);
          if (res !== '') {
            const site = [this.SERIAL_INV_CTRL, this.SERIAL_IN_CTRL, this.SERIAL_SEMI_INV_CTRL];
            this.submitRequest = res;
            this.skuInfo = res;
            this.goodsId = res.goodsId;
            this.sku = res.sku;
            this.batchId = res.batchId;
            this.batchCode = res.batchCode;
            this.isBatch = res.isbatch === 1 ? true : false;
            this.enableSerial = site.includes(res.serialType);
            this.receiptId = res.id;
            // this.enableSerialParam = {
            //     receiptId: res.id,
            //     status: '2-RECEIVED',
            // };
            if (this.enableSerial) {
              this.qtyDisabled = true;
              this.$nextTick(()=>{
                this.skipNext('inputRef');
              })
            } else {
              this.skipNext(40);
            }
            // this.$hint.showFailed(this.$i18n.t('message.qtyInPlant'), true);
          } else {
            if (ref === 1) {
              this.skipNext(10);
            } else if (ref === 2) {
              if (this.isBatch) {
                this.skipNext(20);
              } else if (!this.isBatch && this.enableSerial) {
                this.$nextTick(()=>{
                  this.skipNext('inputRef');
                })
              } else if (!this.isBatch && !this.enableSerial) {
                this.skipNext(40);
              }
            }
          }
        } finally {
          this.$hint.hideLoading();
        }
      }
    },
    /**
     * 调整数量失焦事件
     */
    checkQtyBlur() {
      const { changeQty } = this;
      if (changeQty === undefined || changeQty < 0) {
        this.$hint.showFailed(this.$i18n.t('message.qtyMustBeGreaterThanZero'), true);
        this.skipNext(40);
      }
      // else {
      //   this.skipNext(50);
      // }
    },
    /**
     * 收货调整事件
     */
    async handleButtonSubmit() {
      try {
        this.$hint.showLoading();
        this.handleSubmitStatus();
        // const url = `${this.winvBaseUrl}app-inbound-receive-adjust/receive-reduce`;
        let submitQty = '';
        if (this.enableSerial) {
          submitQty = this.submitRequest.receiveQty - this.inboundSerialReceiptList.length;
        } else {
          submitQty = this.changeQty;
        }
        const param = {
          ...this.submitRequest,
          inboundSerialReceiptList: this.inboundSerialReceiptList,
          receiveQty: submitQty,
          adjustReason: this.remark,
        };
        // await this.$http.post(url, param);
        await receiveReduce(param);
        this.dataInit();
        this.$hint.showSuccess();
      } catch (e) {
        console.log('错误：' + e);
      } finally {
        this.$hint.hideLoading();
        this.handleSubmitStatus();
      }
    },
    // handleCidCodeEnter() {
    //   if(this.enableSerial){
    //     this.skipNext(30);
    //   }else {
    //     this.skipNext(40);
    //   }
    // },
    /**
     * 物料enter事件
     */
    handleSkuEnter() {
      if (this.isBatch) {
        this.skipNext(20);
      } else if(this.enableSerial) {
        this.$nextTick(()=>{
          this.skipNext('inputRef');
        })
      }else if(!this.qtyDisabled) {
        this.skipNext(40)
      }else {
        this.skipNext(50);
      }
    },
    /**
     * 批次enter事件
     */
    handleBatchEnter() {
      if (this.enableSerial) {
        this.$nextTick(()=>{
          this.skipNext('inputRef');
        })
      } else {
        this.skipNext(40);
      }
    },
    /**
     * 清除页面数据
     */
    dataInit() {
      this.cidId = '';
      this.cidCode = '';
      this.sku = '';
      this.batchCode = '';
      this.serialNumber = '';
      this.inboundSerialReceiptList = [];
      this.isBatch = false;
      this.enableSerial = false;
      this.skuInfo = {
        goodsName: '',
        uomName: '',
        billInfo: '',
        receiveQty: '',
      }; // 物料明细
      this.skuParam = {}; // 物料请求参数
      this.batchParam = {}; // 批次lov参数
      // this.enableSerialParam = {}; // 序列号lov参数
      this.changeQty = '';
      this.remark = '';
      this.skipNext(0);
      this.$store.commit('deleteKeepAlives', 'IpsSerial');
    },
  },
  mounted() {
    this.$refs['ref0'].focus();
  },

  activated() {
    // 从序列号明细页返回
    const selectedSerialNum = JSON.parse(sessionStorage.getItem('totalSerialNum'));
    const serialNumberDtoList = JSON.parse(sessionStorage.getItem('serialNumberDtoList'));
    this.changeQty = selectedSerialNum;
    this.inboundSerialReceiptList = serialNumberDtoList.map((item) => {
      return {
        ...item,
        _status: 'delete',
      };
    });
    sessionStorage.removeItem('totalSerialNum');
    sessionStorage.removeItem('serialNumberDtoList');
  },

  beforeRouteLeave(to, from, next) {
    if (to.name === 'inputSerial') {
      this.$store.commit('pushKeepAlives', 'ReceivingAdjustment');
    } else {
      this.$store.commit('deleteKeepAlives', 'ReceivingAdjustment');
      this.$store.commit('deleteKeepAlives', 'IpsSerial');
    }
    next();
  },
};
</script>

<style lang="stylus" scoped>
 @import '~@/style/var.styl'
.input-button {
  margin: 5px 0 10px 0;

  .hips-button--primary {
    height: 35px;
    line-height: 20px;
  }
}
</style>
