<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <!-- 收货调整 -->
      <ips-header
        :title="$t('title.receiveAdjustment')"
      />
    </div>
    <div class="ips-container">
      <hips-tabs v-model="active" @click="changeTab">
        <hips-tab :title="$t('label.adjust')" :class="[{'active-tab': active===0}]">
          <!-- 来源单号 -->
          <ips-lov
            :label="$t('label.resourceNum')"
            :title="$t('label.resourceNum')"
            v-model="inputData.sourceBillCode"
            v-show="customData['sourceBillCode'] ? hideControl['sourceBillCode']: true"
            params="sourceBillCode"
            display="sourceBillCode"
            :url="`${winvBaseUrl}app-inbound-receive-adjust/query-source-order`"
            :pre-parse-url="urlData['sourceBillCode']"
            :custom="customData['sourceBillCode']"
            :source-parse-field="sourceParseField"
            ref="ref0"
            @click.stop="handleCommonClick(0)"
            @clickIcon="handleCommonClick(0, 'lov')"
            @enter="skipNext(10)"
            @down="skipNext(10)"
            @choose="handleChoose('sourceBillCode', $event)"
            @setSourceField="saveSourceField($event)"
            @valuedByFoucs="valuedByFoucs($event)"
          />
          <!-- 入库单号 -->
          <ips-lov
            :label="$t('label.billCode')"
            :title="$t('label.billCode')"
            required
            v-model="inputData.billCode"
            v-show="customData['billCode'] ? hideControl['billCode']: true"
            params="billCode"
            display="billCode"
            :url="`${winvBaseUrl}app-inbound-receive-adjust/query-inbound-order`"
            :url-params="{ 'sourceBillCode': inputData.sourceBillCode }"
            :pre-parse-url="urlData['billCode']"
            :custom="customData['billCode']"
            :source-parse-field="sourceParseField"
            ref="ref10"
            @click.stop="handleCommonClick(10)"
            @clickIcon="handleCommonClick(10, 'lov')"
            @enter="skipNext(20)"
            @down="skipNext(20)"
            @choose="handleChoose('billCode', $event)"
            @setSourceField="saveSourceField($event)"
            @valuedByFoucs="valuedByFoucs($event)"
          />
          <!-- 接收号 -->
          <ips-lov
            :label="$t('label.shelvesReceiveNum')"
            :title="$t('label.shelvesReceiveNum')"
            v-model="inputData.receiptNumber"
            v-show="customData['receiptNumber'] ? hideControl['receiptNumber']: true"
            params="receiptNumber"
            display="receiptNumber"
            :url="`${winvBaseUrl}app-inbound-receive-adjust/query-receipt-number`"
            :url-params="{ 'sourceBillCode': inputData.sourceBillCode,'billId': billId }"
            :pre-parse-url="urlData['receiptNumber']"
            :custom="customData['receiptNumber']"
            :source-parse-field="sourceParseField"
            ref="ref20"
            @click.stop="handleCommonClick(20)"
            @clickIcon="handleCommonClick(20, 'lov')"
            @enter="skipNext(30)"
            @down="skipNext(30)"
            @choose="handleChoose('receiptNumber', $event)"
            @setSourceField="saveSourceField($event)"
            @valuedByFoucs="valuedByFoucs($event)"
          />
          <!-- 接收托盘 -->
          <ips-lov
            :label="$t('label.receiveCid')"
            :title="$t('label.receiveCid')"
            v-model="inputData.cidCode"
            v-show="customData['cidCode'] ? hideControl['cidCode']: true"
            params="cidCode"
            :url="`${winvBaseUrl}app-inbound-receive-adjust/cid-lov`"
            :url-params="{ 'sourceBillCode': inputData.sourceBillCode,'billId': billId, 'receiptNumber': inputData.receiptNumber }"
            :pre-parse-url="urlData['cidCode']"
            :custom="customData['cidCode']"
            :source-parse-field="sourceParseField"
            ref="ref30"
            @click.stop="handleCommonClick(30)"
            @clickIcon="handleCommonClick(30, 'lov')"
            @enter="skipNext(40)"
            @down="skipNext(40)"
            @choose="handleChoose('receiveCidCode', $event)"
            @setSourceField="saveSourceField($event)"
            @valuedByFoucs="valuedByFoucs($event)"
          />
          <!-- 物料编码 -->
          <ips-lov
            :label="$t('label.skuCode')"
            :title="$t('label.skuCode')"
            required
            v-model="inputData.skuCode"
            v-show="customData['skuCode'] ? hideControl['skuCode']: true"
            params="sku"
            display="sku"
            :url="`${winvBaseUrl}app-inbound-receive-adjust/goods-lov`"
            :url-params="{ 'sourceBillCode': inputData.sourceBillCode,'billId': billId, 'receiptNumber':
              inputData.receiptNumber, 'cidId': cidId }"
            :pre-parse-url="urlData['skuCode']"
            :custom="customData['skuCode']"
            :source-parse-field="sourceParseField"
            ref="ref40"
            @click.stop="handleCommonClick(40)"
            @clickIcon="handleCommonClick(40, 'lov')"
            @enter="skuEnter"
            @down="skuEnter"
            @choose="handleChoose('skuCode', $event)"
            @setSourceField="saveSourceField($event)"
            @valuedByFoucs="valuedByFoucs($event)"
          />
          <!--物料信息卡片-->
          <div v-if="inputData.skuCode">
            <ips-page-card
              :react-field="['detailLineNum', 'uomName']"
              :item="skuInfo"
            >
              <ips-message-tip :message="skuInfo.goodsName" />
            </ips-page-card>
          </div>
          <!-- 批次 -->
          <hips-row>
            <div v-if="isbatch">
              <hips-col span="24" class="hips-col-class">
                <ips-lov
                  :label="$t('label.batch')"
                  :title="$t('label.batch')"
                  v-model="inputData.batchCode"
                  v-show="isbatch ? (customData['batchCode'] ? hideControl['batchCode'] : isbatch) : false"
                  required
                  params="batchCode"
                  :url="`${winvBaseUrl}app-inbound-receive-adjust/batch-lov`"
                  :url-params="{ 'sourceBillCode': inputData.sourceBillCode,'billId': billId, 'receiptNumber':
                    inputData.receiptNumber, 'cidId': cidId, 'goodsId': goodsId, 'detailId': detailId }"
                  :pre-parse-url="urlData['batchCode']"
                  :custom="customData['batchCode']"
                  :source-parse-field="sourceParseField"
                  ref="ref50"
                  @click.stop="handleCommonClick(50)"
                  @clickIcon="handleCommonClick(50, 'lov')"
                  @enter="skipNext(60)"
                  @down="skipNext(60)"
                  @choose="handleChoose('batchCode', $event)"
                  @setSourceField="saveSourceField($event)"
                  @valuedByFoucs="valuedByFoucs($event)"
                />
              </hips-col>
            </div>
          </hips-row>
          <!--调整数量-->
          <hips-row>
            <div v-if="(serialType ==='INV_CTRL'||serialType ==='IN_CTRL'|| serialType ==='SEMI_INV_CTRL') && inputData.skuCode">
              <hips-col span="14" class="hips-col-class">
                <ips-input-number
                  :label="$t('label.receiveAdjustQty')"
                  v-model="inputData.receiveQty"
                  v-show="customData['receiveQty'] ? hideControl['receiveQty']: true"
                  required
                  :custom="customData['receiveQty']"
                  :source-parse-field="sourceParseField"
                  :enable-serial="serialType"
                  ref="ref60"
                  :min="0"
                  :precision="0"
                  @enter="checkImpQty"
                  @setSourceField="saveSourceField($event)"
                  @valuedByFoucs="valuedByFoucs($event)"
                />
              </hips-col>
              <hips-col span="10" class="hips-col-class">
                <hips-button
                  size="large"
                  @click.stop="goToSerialDetail"
                  ref="refSerail"
                  class="serial-button-style"
                >
                  {{ $t('button.serialNumber') }} ({{ isReturn?serialReceiveQtyImp:showQty }})
                </hips-button>
              </hips-col>
            </div>
          </hips-row>
          <hips-row>
            <div v-if="(serialType !=='INV_CTRL'&& serialType !=='IN_CTRL' && serialType
              !=='SEMI_INV_CTRL')&& inputData.skuCode"
            >
              <hips-col span="24" class="hips-col-class">
                <ips-input-number
                  :label="$t('label.receiveAdjustQty')"
                  v-model="inputData.receiveQty"
                  v-show="customData['receiveQty'] ? hideControl['receiveQty']: true"
                  required
                  :custom="customData['receiveQty']"
                  :source-parse-field="sourceParseField"
                  :enable-serial="serialType"
                  ref="ref60"
                  :min="0"
                  :more-than-zero="true"
                  @enter="skipNext(70)"
                  @setSourceField="saveSourceField($event)"
                  @valuedByFoucs="valuedByFoucs($event)"
                />
              </hips-col>
            </div>
          </hips-row>
          <!--原接收数量-->
          <div class="qtyBox" v-if="inputData.skuCode">
            <span>{{ $t('label.oldReceiveQty') }} :&nbsp;&nbsp;{{ showQty }}</span>
          </div>
          <!--调整原因-->
          <ips-lov
            :label="$t('label.reason')"
            :title="$t('label.reason')"
            v-model="inputData.reason"
            v-show="customData['reason'] ? hideControl['reason']: true"
            required
            params="meaning"
            :url="`${wmdmBaseUrl}wms-common/lov-data-query`"
            :url-params="{lovCode:'WINV.INBOUND_RECEIVE_ADJUST_REASON'}"
            :pre-parse-url="urlData['reason']"
            :is-page-content="false"
            :custom="customData['reason']"
            :source-parse-field="sourceParseField"
            ref="ref70"
            @click.stop="handleCommonClick(70)"
            @clickIcon="handleCommonClick(70, 'lov')"
            @enter="skipNext('submitRef')"
            @down="skipNext('submitRef')"
            @choose="handleChoose('reason', $event)"
            @setSourceField="saveSourceField($event)"
            @valuedByFoucs="valuedByFoucs($event)"
          />
        </hips-tab>
        <hips-tab :title="`${$t('label.cancelReceipt')}`" :class="[{'active-tab': active!==0}]">
          <!-- 接收托盘 -->
          <ips-lov
            :label="$t('label.receiveCid')"
            :title="$t('label.receiveCid')"
            v-model="inputData.cidCode1"
            v-show="customData['cidCode1'] ? hideControl['cidCode1']: true"
            params="cidCode"
            required
            :url="`${wmdmBaseUrl}mdm-info/query-container-to`"
            :url-params="{ cidStatus: 'RECEIVING' }"
            :pre-parse-url="urlData['cidCode1']"
            :custom="customData['cidCode1']"
            :source-parse-field="sourceParseField"
            ref="ref100"
            @click.stop="handleCommonClick(100)"
            @clickIcon="handleCommonClick(100, 'lov')"
            @enter="handleSkipCid1"
            @down="skipNext(110)"
            @choose="handleChoose('receiveCidCode1', $event)"
            @setSourceField="saveSourceField($event)"
            @valuedByFoucs="valuedByFoucs($event)"
          />
          <!--调整原因-->
          <ips-lov
            :label="$t('label.reason')"
            :title="$t('label.reason')"
            v-model="inputData.reason1"
            v-show="customData['reason1'] ? hideControl['reason1']: true"
            required
            params="meaning"
            :url="`${wmdmBaseUrl}wms-common/lov-data-query`"
            :url-params="{lovCode:'WINV.INBOUND_RECEIVE_ADJUST_REASON'}"
            :pre-parse-url="urlData['reason1']"
            :is-page-content="false"
            :custom="customData['reason1']"
            :source-parse-field="sourceParseField"
            ref="ref110"
            @click.stop="handleCommonClick(110)"
            @clickIcon="handleCommonClick(110, 'lov')"
            @enter="skipNext('inputRef')"
            @down="skipNext('inputRef')"
            @choose="handleChoose('reason1', $event)"
            @setSourceField="saveSourceField($event)"
            @valuedByFoucs="valuedByFoucs($event)"
          />
          <div v-if="cidDataArray.length > 0">
            <p style="margin-top: 2.367vw; color: #595959">
              {{ $t('title.receiveDetail') }}
            </p>
            <div class="ips-card__content" ref="ips-content" tabindex="-1">
              <hips-scroll
                ref="scroll"
                :scroll-options="scrollOption"
                :data="[cidDataArray]"
                :scroll-events="['scroll']"
                @pulling-down="loadRefresh"
                @pulling-up="loadMore"
                @scroll="scroll"
              >
                <ul class="list-wrapper">
                  <div
                    class="card_countLocation"
                    v-for="(item,index) in cidDataArray"
                    :key="index"
                  >
                    <div class="row">
                      <div class="title">
                        {{ $t('label.tray') }}:
                      </div>
                      <div class="value">
                        {{ item.cidCode }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="row-50">
                        <div class="title">
                          {{ $t('label.sku') }}:
                        </div>
                        <div class="value">
                          {{ item.sku }}
                        </div>
                      </div>
                      <div class="row-50">
                        <div class="title">
                          {{ $t('label.batch') }}:
                        </div>
                        <div class="value">
                          {{ item.batchCode }}
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="row-50">
                        <div class="title">
                          {{ $t('label.lineNum') }}:
                        </div>
                        <div class="value">
                          {{ item.detailLineNum }}
                        </div>
                      </div>
                      <div class="row-50">
                        <div class="title">
                          {{ $t('label.unit') }}:
                        </div>
                        <div class="value">
                          {{ item.uomName }}
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="title">
                        {{ $t('label.goodsName') }}:
                      </div>
                      <div class="value">
                        {{ item.goodsName || '--' }}
                      </div>
                    </div>
                    <div class="row" v-if="!['INV_CTRL', 'IN_CTRL', 'SEMI_INV_CTRL'].includes(item.serialType)">
                      <div class="title">
                        {{ $t('label.quantity') }}:
                      </div>
                      <div class="value">
                        {{ item.receiveQty || '--' }}
                      </div>
                    </div>
                    <div class="row" v-if="['INV_CTRL', 'IN_CTRL', 'SEMI_INV_CTRL'].includes(item.serialType)">
                      <div class="row-50">
                        <div class="title">
                          {{ $t('label.quantity') }}:
                        </div>
                        <div class="value">
                          {{ item.receiveQty || '--' }}
                        </div>
                      </div>
                      <div class="row-50">
                        <!-- 序列号明细 -->
                        <hips-button
                          type="warning"
                          size="large"
                          @click.stop="goToSerialDetail1(item)"
                          style="margin-left: 3px; color: #FFFFFF; font-size: 14px; height: 30px; line-height: 30px; width: 150px"
                        >
                          {{ $t('button.seriesDetail') }}
                        </hips-button>
                      </div>
                    </div>
                  </div>
                  <li
                    v-show="cidDataArray.length > 20"
                    class="scroll-item hips-thinline--bottom"
                    style="color: var(--primaryColor); text-align: center"
                    @click="scrollToTop"
                  >
                    {{ $t('button.backTop') }}
                  </li>
                </ul>
              </hips-scroll>
            </div>
          </div>
        </hips-tab>
      </hips-tabs>
      <div class="ips-footer" style="position: fix">
        <hips-button
          v-if="!active"
          size="large"
          ref="submitRef"
          @enter="handleCommonSubmit('tab1')"
          @click.stop="handleCommonSubmit('tab1')"
        >
          <svg-icon slot="icon" icon-class="success" />
          {{ $t('button.confirm') }}
        </hips-button>
        <hips-button
          v-if="active "
          size="large"
          ref="inputRef"
          @enter="handleCommonSubmit('tab2')"
          @click.stop="handleCommonSubmit('tab2')"
        >
          <svg-icon slot="icon" icon-class="success" />
          {{ $t('button.cancelReceipt') }}
        </hips-button>
      </div>
    </div>
  </hips-view>
</template>

<script>
  import { Cell, Row, Col, Tabs, Tab, Search, Scroll  } from "@hips/vue-ui";
  import { handleSaveData, handleQuerySerial, getCidDetail, cancelReceiptData } from '../api'
  import { storage } from '@/utils';
  export default {
  name: "ReceiptAdjust",
  data() {
    return {
      active: 0,
      isbatch: '',
      sourceBillId: '',
      billId: '',
      cidId: '',
      cidId1: '',
      serialType: '',
      goodsId: '',
      detailId: '',
      batchId: '',
      serialReceiveQtyImp: 0,
      inboundSerialReceiptList: '',
      toSerialOldList: '',
      reasonValue: '',
      reasonValue1: '',
      skuInfo: {
        detailLineNum: '',
        goodsName: '',
        uomName: '',
      },
      toJavaData: [],
      showQty: '',
      isReturn: '',
      inputData: {
        sourceBillCode: '',
        billCode: '',
        receiptNumber: '',
        cidCode: '',
        skuCode: '',
        batchCode: '',
        receiveQty: '',
        cidCode1: '',
        reason1: '',
      },
      urlData:{},
      customData: {}, // 个性化配置信息列表
      hideControl: {}, // 个性化配置显示隐藏控制
      sourceParseField: {}, // 界面中所有的来源解析字段
      cidDataArray: [],
      inboundSerialReceiptList1: [],
      scrollY: -1, // y 滚动距离
      page: 0,
      size: 10,
      scrollOption: {
        pullDownRefresh: {
          threshold: 60, // 触发 pullingDown 的距离
          stop: 40, // pullingDown 正在刷新 hold 时的距离
          txt: this.$t('message.refreshSuccess'),
        },
        pullUpLoad: {
          txt: {
            // more: '上拉加载',
            more: this.$t('message.pullOnLoading'),
            // noMore: '暂无更多数据',
            noMore: this.$t('message.noMoreData'),
          },
        },
      },
    };
  },
  created() {
    const { customObj, hideControl } = this.handleCustomData(storage.get('customData'));
    this.customData = customObj;
    this.hideControl = hideControl;
    // console.log('收货调整customData', Object.assign({}, this.customData));
  },
  mounted() {
    this.inputFieldList = [
      {type: 'lov', required: false, ref: 0, bind: 'inputData.sourceBillCode', field: 'sourceBillCode'},
      {type: 'lov', required: this.active === 0, ref: 10, bind: 'inputData.billCode', field: 'billCode'},
      {type: 'lov', required: false, ref: 20, bind: 'inputData.receiptNumber', field: 'receiptNumber'},
      {type: 'lov', required: false, ref: 30, bind: 'inputData.cidCode', field: 'cidCode'},
      {type: 'lov', required: this.active === 0, ref: 40, bind: 'inputData.skuCode', field: 'skuCode'},
      {type: 'lov', required: this.active === 0, ref: 50, bind: 'inputData.batchCode', field: 'batchCode'},
      {type: 'input', required: this.active === 0, ref: 60, bind: 'inputData.receiveQty', field: 'receiveQty'},
      {type: 'lov', required: this.active === 0, ref: 70, bind: 'inputData.reason', field: 'reason'},
      {type: 'lov', required: this.active === 1, ref: 100, bind: 'inputData.cidCode1', field: 'cidCode1'},
      {type: 'lov', required: this.active === 1, ref: 110, bind: 'inputData.reason1', field: 'reason1'},
    ];
    this.urlData = this.handleData('RECEIPT_ADJUST_CONFIRM');
    this.skipNext(0);
  },
  watch: {
    'inputData.sourceBillCode': function(val) {
      if (!val) {
        this.inputData.billCode='';
        this.billId='';
        // 接收号
        this.inputData.receiptNumber='';
        // 托盘
        this.inputData.cidCode='';
        this.cidId='';
        // 物料
        this.inputData.skuCode='';
        this.skuInfo.detailLineNum='';
        this.detailId='';
        this.skuInfo.goodsName='';
        this.skuInfo.uomName='';
        this.serialType = '';
        this.goodsId = '';
        this.isbatch = '';
        // 批次
        this.inputData.batchCode='';
        this.batchId ='';
        // 调整数量
        this.inputData.receiveQty='';
        this.serialReceiveQtyImp='';
        this.showQty='';
        this.toJavaData = '';
      }
    },
    'inputData.billCode': function(val) {
      if (!val) {
        // 接收号
        this.inputData.receiptNumber='';
        // 托盘
        this.inputData.cidCode='';
        this.cidId='';
        // 物料
        this.inputData.skuCode='';
        this.skuInfo.detailLineNum='';
        this.detailId='';
        this.skuInfo.goodsName='';
        this.skuInfo.uomName='';
        this.serialType = '';
        this.goodsId = '';
        this.isbatch = '';
        // 批次
        this.inputData.batchCode='';
        this.batchId ='';
        // 调整数量
        this.inputData.receiveQty='';
        this.serialReceiveQtyImp='';
        this.showQty='';
        this.toJavaData = '';
      }
    },
    'inputData.receiptNumber': function(val) {
      if (!val) {
        // 托盘
        this.inputData.cidCode='';
        this.cidId='';
        // 物料
        this.inputData.skuCode='';
        this.skuInfo.detailLineNum='';
        this.detailId='';
        this.skuInfo.goodsName='';
        this.skuInfo.uomName='';
        this.serialType = '';
        this.goodsId = '';
        this.isbatch = '';
        // 批次
        this.inputData.batchCode='';
        this.batchId ='';
        // 调整数量
        this.inputData.receiveQty='';
        this.serialReceiveQtyImp='';
        this.showQty='';
        this.toJavaData = '';
      }
    },
    'inputData.cidCode': function(val) {
      if (!val) {
       // 物料
        this.inputData.skuCode='';
        this.skuInfo.detailLineNum='';
        this.detailId='';
        this.skuInfo.goodsName='';
        this.skuInfo.uomName='';
        this.serialType = '';
        this.goodsId = '';
        this.isbatch = '';
        // 批次
        this.inputData.batchCode='';
        this.batchId ='';
        // 调整数量
        this.inputData.receiveQty='';
        this.serialReceiveQtyImp='';
        this.showQty='';
        this.toJavaData = '';
      }
    },
    'inputData.skuCode': function(val) {
      if (!val) {
        // 批次
        this.inputData.batchCode='';
        this.batchId ='';
        // 调整数量
        this.inputData.receiveQty='';
        this.serialReceiveQtyImp='';
        this.showQty='';
        this.toJavaData = '';
      }
    },
    'inputData.batchCode': function(val) {
      if (!val&&this.isbatch===1) {
      // 调整数量
      this.inputData.receiveQty='';
      this.serialReceiveQtyImp='';
      this.showQty='';
      this.toJavaData = '';
      }
    }
  },
  components: {
    [Cell.name]: Cell,
    [Row.name]: Row,
    [Col.name]: Col,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Search.name]: Search,
    [Scroll.name]: Scroll,
  },
  methods: {
    changeTab(){
      if(this.active){
        this.$nextTick(() => {
          this.skipNext(100);
        });
      } else {
        this.$nextTick(() => {
          this.skipNext(0);
        });
      }
    },
    // lov选择框，选择数据
    handleChoose(type, obj) {
      switch (type) {
        case 'sourceBillCode':
          if(obj.inboundReceiptRecordList.length ===1){
            // 入库单
            this.inputData.billCode=obj.inboundReceiptRecordList[0].billCode;
            this.billId=obj.inboundReceiptRecordList[0].billId;
            // 接收号
            this.inputData.receiptNumber=obj.inboundReceiptRecordList[0].receiptNumber;
            // 托盘
            this.inputData.cidCode=obj.inboundReceiptRecordList[0].cidCode;
            this.cidId=obj.inboundReceiptRecordList[0].cidId;
            // 物料
            this.inputData.skuCode=obj.inboundReceiptRecordList[0].sku;
            this.skuInfo.detailLineNum=obj.inboundReceiptRecordList[0].detailLineNum;
            this.detailId=obj.inboundReceiptRecordList[0].detailId;
            this.skuInfo.goodsName=obj.inboundReceiptRecordList[0].goodsName;
            this.skuInfo.uomName=obj.inboundReceiptRecordList[0].uomName;
            this.serialType = obj.inboundReceiptRecordList[0].serialType;
            this.goodsId = obj.inboundReceiptRecordList[0].goodsId;
            this.isbatch = obj.inboundReceiptRecordList[0].isbatch;
            // 批次
            this.inputData.batchCode=obj.inboundReceiptRecordList[0].batchCode;
            this.batchId =obj.inboundReceiptRecordList[0].batchId;
            // 调整数量
            this.inputData.receiveQty=obj.inboundReceiptRecordList[0].receiveQty;
            this.serialReceiveQtyImp=obj.inboundReceiptRecordList[0].receiveQty;
            this.showQty=obj.inboundReceiptRecordList[0].receiveQty;
            this.toJavaData = obj.inboundReceiptRecordList;
            this.$nextTick(() => {
              this.skipNext(60);
            });
            //
          }else {
            this.inputData.receiveQty=obj.receiveQty;
            this.serialReceiveQtyImp=obj.receiveQty;
            this.showQty=obj.receiveQty;
          }
          // this.sourceBillId = obj.sourceBillId;
          break;
        case 'billCode':
          if(obj.inboundReceiptRecordList.length ===1){
            // 入库单
            this.billId=obj.inboundReceiptRecordList[0].billId;
            // 接收号
            this.inputData.receiptNumber=obj.inboundReceiptRecordList[0].receiptNumber;
            // 托盘
            this.inputData.cidCode=obj.inboundReceiptRecordList[0].cidCode;
            this.cidId=obj.inboundReceiptRecordList[0].cidId;
            // 物料
            this.inputData.skuCode=obj.inboundReceiptRecordList[0].sku;
            this.serialType = obj.inboundReceiptRecordList[0].serialType;
            this.goodsId = obj.inboundReceiptRecordList[0].goodsId;
            this.isbatch = obj.inboundReceiptRecordList[0].isbatch;
            this.skuInfo.detailLineNum=obj.inboundReceiptRecordList[0].detailLineNum;
            this.detailId=obj.inboundReceiptRecordList[0].detailId;
            this.skuInfo.goodsName=obj.inboundReceiptRecordList[0].goodsName;
            this.skuInfo.uomName=obj.inboundReceiptRecordList[0].uomName;
            // 批次
            this.inputData.batchCode=obj.inboundReceiptRecordList[0].batchCode;
            this.batchId =obj.inboundReceiptRecordList[0].batchId;
            // 调整数量
            this.inputData.receiveQty=obj.inboundReceiptRecordList[0].receiveQty;
            this.serialReceiveQtyImp=obj.inboundReceiptRecordList[0].receiveQty;
            this.showQty=obj.inboundReceiptRecordList[0].receiveQty;
            this.toJavaData = obj.inboundReceiptRecordList;
            this.$nextTick(() => {
              this.skipNext(60);
            });
            //
          }else {
            this.billId = obj.billId;
            this.inputData.receiveQty=obj.receiveQty;
            this.serialReceiveQtyImp=obj.receiveQty;
            this.showQty=obj.receiveQty;
          }
          break;
        case 'receiptNumber':
          if(obj.inboundReceiptRecordList.length ===1){
            // 托盘
            this.inputData.cidCode=obj.inboundReceiptRecordList[0].cidCode;
            this.cidId=obj.inboundReceiptRecordList[0].cidId;
            // 物料
            this.inputData.skuCode=obj.inboundReceiptRecordList[0].sku;
            this.serialType = obj.inboundReceiptRecordList[0].serialType;
            this.goodsId = obj.inboundReceiptRecordList[0].goodsId;
            this.isbatch = obj.inboundReceiptRecordList[0].isbatch;
            this.skuInfo.detailLineNum=obj.inboundReceiptRecordList[0].detailLineNum;
            this.detailId=obj.inboundReceiptRecordList[0].detailId;
            this.skuInfo.goodsName=obj.inboundReceiptRecordList[0].goodsName;
            this.skuInfo.uomName=obj.inboundReceiptRecordList[0].uomName;
            // 批次
            this.inputData.batchCode=obj.inboundReceiptRecordList[0].batchCode;
            this.batchId =obj.inboundReceiptRecordList[0].batchId;
            // 调整数量
            this.inputData.receiveQty=obj.inboundReceiptRecordList[0].receiveQty;
            this.serialReceiveQtyImp=obj.inboundReceiptRecordList[0].receiveQty;
            this.showQty=obj.inboundReceiptRecordList[0].receiveQty;
            this.toJavaData = obj.inboundReceiptRecordList;
            this.$nextTick(() => {
              this.skipNext(60);
            });
            //
          }else {
            this.inputData.receiveQty=obj.receiveQty;
            this.serialReceiveQtyImp=obj.receiveQty;
            this.showQty=obj.receiveQty;
          }
          break;
        case 'receiveCidCode':
          if(obj.inboundReceiptRecordList.length ===1){
            // 托盘
            this.inputData.cidCode=obj.inboundReceiptRecordList[0].cidCode;
            this.cidId=obj.inboundReceiptRecordList[0].cidId;
            // 物料
            this.inputData.skuCode=obj.inboundReceiptRecordList[0].sku;
            this.serialType = obj.inboundReceiptRecordList[0].serialType;
            this.goodsId = obj.inboundReceiptRecordList[0].goodsId;
            this.isbatch = obj.inboundReceiptRecordList[0].isbatch;
            this.skuInfo.detailLineNum=obj.inboundReceiptRecordList[0].detailLineNum;
            this.detailId=obj.inboundReceiptRecordList[0].detailId;
            this.skuInfo.goodsName=obj.inboundReceiptRecordList[0].goodsName;
            this.skuInfo.uomName=obj.inboundReceiptRecordList[0].uomName;
            // 批次
            this.inputData.batchCode=obj.inboundReceiptRecordList[0].batchCode;
            this.batchId =obj.inboundReceiptRecordList[0].batchId;
            // 调整数量
            this.inputData.receiveQty=obj.inboundReceiptRecordList[0].receiveQty;
            this.serialReceiveQtyImp=obj.inboundReceiptRecordList[0].receiveQty;
            this.showQty=obj.inboundReceiptRecordList[0].receiveQty;
            this.toJavaData = obj.inboundReceiptRecordList;
            this.$nextTick(() => {
              this.skipNext(60);
            });
            //
          }else {
            this.inputData.receiveQty=obj.receiveQty;
            this.serialReceiveQtyImp=obj.receiveQty;
            this.showQty=obj.receiveQty;
            this.cidId = obj.cidId;
          }
          break;
        case 'skuCode':
          if(obj.inboundReceiptRecordList.length ===1){
            // 物料
            this.serialType = obj.inboundReceiptRecordList[0].serialType;
            this.goodsId = obj.inboundReceiptRecordList[0].goodsId;
            this.isbatch = obj.inboundReceiptRecordList[0].isbatch;
            this.skuInfo.detailLineNum=obj.inboundReceiptRecordList[0].detailLineNum;
            this.detailId=obj.inboundReceiptRecordList[0].detailId;
            this.skuInfo.goodsName=obj.inboundReceiptRecordList[0].goodsName;
            this.skuInfo.uomName=obj.inboundReceiptRecordList[0].uomName;
            // 批次
            this.inputData.batchCode=obj.inboundReceiptRecordList[0].batchCode;
            this.batchId =obj.inboundReceiptRecordList[0].batchId;
            // 调整数量
            this.inputData.receiveQty=obj.inboundReceiptRecordList[0].receiveQty;
            this.serialReceiveQtyImp=obj.inboundReceiptRecordList[0].receiveQty;
            this.showQty=obj.inboundReceiptRecordList[0].receiveQty;
            this.toJavaData = obj.inboundReceiptRecordList;
            this.$nextTick(() => {
              this.skipNext(60);
            });
            //
          }else {
            this.skuInfo.detailLineNum=obj.detailLineNum;
            this.detailId=obj.detailId;
            this.skuInfo.goodsName=obj.goodsName;
            this.skuInfo.uomName=obj.uomName;
            this.serialType = obj.serialType;
            this.goodsId = obj.goodsId;
            this.isbatch = obj.isbatch;
            this.inputData.receiveQty = obj.receiveQty;
            this.serialReceiveQtyImp = obj.receiveQty;
            this.showQty = obj.receiveQty;
            this.toJavaData = obj.inboundReceiptRecordList;
          }
          break;
        case 'batchCode':
          if(obj.inboundReceiptRecordList.length ===1){
            this.batchId =obj.inboundReceiptRecordList[0].batchId;
            this.inputData.receiveQty=obj.inboundReceiptRecordList[0].receiveQty;
            this.serialReceiveQtyImp=obj.inboundReceiptRecordList[0].receiveQty;
            this.showQty=obj.inboundReceiptRecordList[0].receiveQty;
            this.toJavaData = obj.inboundReceiptRecordList;
            this.$nextTick(() => {
              this.skipNext(60);
            });
            //
          }else {
          this.batchId = obj.batchId;
          this.inputData.receiveQty = obj.receiveQty;
          this.serialReceiveQtyImp = obj.receiveQty;
          this.showQty = obj.receiveQty;
          this.toJavaData = obj.inboundReceiptRecordList;
          }
          break;
        case 'reason':
          this.reasonValue = obj.value;
          break;
        case 'reason1':
          this.reasonValue1 = obj.value;
          break;
        case 'receiveCidCode1':
          this.cidId1 = obj.cidId;
          break;
        default:
          return null;
      }
    },
    /**
     *@functionName: saveSourceField
     *@description: 保存来源解析字段并赋值Enter
     *@params1: fieldObj[Object] 来源解析字段对象,
     fieldCode[String] 来源解析字段
     parseValue[String] 二维码解析值
     subFields[Array]] 关联子字段
     */
    saveSourceField(fieldObj) {
      this.saveSourceParseField(this, fieldObj);
    },
    // 字段聚焦时赋值操作
    async valuedByFoucs(options={}) {
      const { custom ={} , currentValue } = options;
      await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
    },

    // 物料回车事件
    skuEnter(){
      if(this.isbatch === 1){
        this.$nextTick(() => {
          this.skipNext(50)
        });
      }else {
        this.$nextTick(() => {
          this.skipNext(60)
        });
      }
    },
    async goToSerialDetail(){
      let serialUrl ='app-inbound-receive-adjust/serial-check';
      const data ={
        detailId: this.detailId,
        billId: this.billId,
      };
      if(this.showQty === this.inputData.receiveQty && !this.isReturn){
        this.$hint.showLoading();
        const res = await handleQuerySerial(this.toJavaData);
        this.inboundSerialReceiptList = res;
        this.toSerialOldList = res;
        this.$hint.hideLoading();
        // this.serialReceiveQty = this.receiveQty;
      }
      const param = {
        name: 'serialComponent',
        params: {
          serialUrl,
          data,
          pendingQty: this.showQty,
          receiveQty: this.inputData.receiveQty,
          serialType: this.serialType,
          task: this.inboundSerialReceiptList || [],
          toSerialOldList: this.toSerialOldList || [],
          definedInboundSerialReceiptList: this.definedInboundSerialReceiptList,
          callback: function(serialNum) {
            const item = {
              serialNumber: serialNum,
              qualityStatusName: '0-合格',
              serialQty: 1,
            };
            this.tableData.push(item);
          }
        },
      };
      this.$router.pushPage(param);
    },
    checkImpQty(){
      if(this.inputData.receiveQty-this.serialReceiveQtyImp!==0){
        this.$hint.showFailed(this.$i18n.t('message.checkImpQty',{label: `${this.inputData.receiveQty}`,labelTwo:
            `${this.serialReceiveQtyImp}`}),  true);
        this.skipNext(60);
      }else {
        this.skipNext(70)
      }
    },
    // 收货调整数据提交
    async handleButtonSubmit(flag) {
      try {
        this.$hint.showLoading();
        if(flag === 'tab1'){
          if(this.isbatch===1 && !this.inputData.batchCode){
            this.$hint.showFailed(this.$i18n.t('message.checkRequired'));
            return
          }
          const params={
            definedInboundSerialReceiptList: this.inboundSerialReceiptList || [],
            inboundReceiptRecordList: this.toJavaData,
            sourceBillCode: this.inputData.sourceBillCode,
            billCode: this.inputData.billCode,
            sku: this.inputData.sku,
            detailId: this.detailId,
            cidCode: this.inputData.cidCode,
            cidId: this.cidId,
            receiptNumber: this.inputData.receiptNumber,
            batchCode: this.inputData.batchCode,
            receiveQty: this.inputData.receiveQty,
            reason: this.reasonValue,
            serialType: this.serialType,
          };
          const res = await handleSaveData(params);
          if(res){
            this.inputData.skuCode='';
            this.skuInfo.detailLineNum='';
            this.skuInfo.goodsName='';
            this.skuInfo.uomName='';
            this.serialType = '';
            this.goodsId = '';
            this.isbatch = '';
            // 批次
            this.inputData.batchCode='';
            this.batchId ='';
            // 调整数量
            this.inputData.receiveQty='';
            this.showQty='';
            this.toJavaData = '';
            this.inputData.reason = '';
            this.reasonValue = '';
            await this.clearSourceFields(this, true);
            this.$hint.showSuccess();
            this.skipNext(30)
          }
        } else {
          if(!this.inputData.cidCode1 || !this.inputData.reason1){
            this.$hint.showFailed(this.$i18n.t('message.checkRequired'));
            return
          }
          const params={
            cidCode: this.inputData.cidCode1,
            cidId: this.cidId1,
            receiveQty: 0,
            detailId: 0,
            reason: this.reasonValue1,
            serialType: "UNCTRL",
          };
          const res = await cancelReceiptData(params);
          if(res){
            this.inputData.reason1 = '';
            this.inputData.cidCode1 = '';
            this.cidDataArray = [];
            this.skipNext(100);
            this.$hint.showSuccess(this.$i18n.t('message.operationSuccess'));
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.$hint.hideLoading();
      }
    },


    // tab2的方法
    /**
      * 前往序列号明细界面
      */
      async goToSerialDetail1(item){
        let data = {
          billId: this.billId,
        };
        let serialUrl ='app-inbound/serial-check';
        const param = {
          name: 'serialComponent',
          params: {
            data,
            serialUrl,
            receiveQty: item.receiveQty,
            detailIdTmpList: true,
            task: item.inboundSerialReceiptList,
            callback: function(serialNum) {
              const item = {
                serialNumber: serialNum,
                qualityStatusName: '0-合格',
                qualityStatus: '0-ACCEPTED',
                serialQty: 1,
              };

              this.tableData.push(item);
              if(this.definedInboundSerialReceiptList.includes(serialNum)) {
                this.definedInboundSerialReceiptList.splice(this.definedInboundSerialReceiptList.findIndex((serial) => serial === serialNum), 1)
              }
            }
          },

        };
        this.$router.pushPage(param);
      },

      async handleSkipCid1(){
        const cidData = {
          params: {
            cidId: this.cidId1,
          }
        };
        const res = await getCidDetail(cidData);
        if(res && res.content && res.content.length > 0){
          this.cidDataArray = res.content;
          this.skipNext(110);
        } else {
          this.$hint.showFailed(this.$i18n.t('message.checkRequired'), true);
        }
      },

      scroll(pos) {
        this.scrollY = pos.y
      },

      scrollToTop () {
        this.$refs.scroll.scrollTo(0, 0);
      },

      // 加载刷新数据
      async loadRefresh () {
        const cidData = {
          params: {
            cidId: this.cidId1,
          }
        };
        const res = await getCidDetail(cidData);
        if(res && res.content && res.content.length > 0){
          this.cidDataArray = res.content;
        } else {
          this.$hint.showFailed(this.$i18n.t('message.checkRequired'), true);
        }
        this.page = 1;
      },

      // 加载更多数据
      async loadMore () {
        const page = this.page + 1;
        const cidData = {
          params: {
            cidId: this.cidId1,
            page,
            size: this.size,
          }
        };
        const res = await getCidDetail(cidData);
        if(res && res.content && res.content.length > 0){
          this.cidDataArray.push(...res.content);
        }
        // 判断是否已达最后一页
        if (this.cidDataArray.length < this.size) {
          // 如果没有新数据，不用强制更新滚轮，注意下面的 forceUpdate 方法需要包裹在 nextTick 中
          this.$nextTick(() => {
            this.$refs.scroll.forceUpdate();
          })
        } else {
          this.page++;
        }
      },

  },
  activated() {
    const inboundSerialReceiptList = JSON.parse(sessionStorage.getItem('inboundSerialReceiptList'));
    if(inboundSerialReceiptList){
      this.isReturn = true;
    }
    if(inboundSerialReceiptList&&inboundSerialReceiptList.length>0){
      this.skipNext(60)
    }
    this.inboundSerialReceiptList = inboundSerialReceiptList;
    this.serialReceiveQtyImp = inboundSerialReceiptList && inboundSerialReceiptList.length || 0;
    sessionStorage.removeItem('inboundSerialReceiptList');
  },

  beforeRouteLeave(to, from, next) {
    if (to.name === 'serialComponent') {
      this.$store.commit('pushKeepAlives', 'ReceiptAdjust');
    } else{
      this.$store.commit('deleteKeepAlives', 'ReceiptAdjust');
    }
    this.$nextTick(() => {
      next();
    });
  },
};
</script>

<style lang="stylus" scoped>
 @import '~@/style/var.styl'
  .hips-col-class{
    margin-top 16px;
  }
  .load-message-card {
    background: #fff;
    margin-bottom: 5px;
    border-radius: 1.6vw;
    margin-top: 4.267vw;
    width: 97%;
    padding: 2.133vw 0px;
    color: #000;
    font-size: 3.467vw;
    box-shadow: 0 0 2.133vw #d9d9d9;
  }
  .serial-button-style {
    margin-left: 20px;
    background-color: var(--primaryColor);
    color: #FFFFFF;
    font-size: 14px;
    height: 36px;
    line-height: 36px;
  }
  .qtyBox {
    margin-top 1vw
    border 1px solid #91D5FF
    border-radius 3px
    background-color: #BAE7FF
    font-size: 12px
    height: 26px
    line-height: 26px
    padding-left: 7px
    color: var(--primaryColor)
  }

  .ips-container{
    // height: 600px;
    padding-top 0!important;
      .scroll-list{
        background: white;
        text-align: center;
      }
      .scroll-item{
        line-height: 44px;
        height: 44px;
      }
    .ips-card__content {
      height: 400px;
      margin-top: 1.32vw;
      max-height: 264vw !important;
    }
    .ips-card__content .hips-scroll {
      max-height: 264vw !important;
    }
    .ips-card__content .list-wrapper .scroll-item .content-item--3 {
      word-wrap: break-word !important;
    }
    .menu-row{
      display flex
      margin 12px 32px 12px 12px;
      .menu-title{
        width:80px;
        height:16px;
        font-size:12px;
        /*font-family:PingFangSC-Regular,PingFang SC;*/
        font-weight:200;
        color:#8C8C8C;
        line-height:16px;
      }
      .menu-value{
        width:261px;
        height:16px;
        font-size:12px;
        /*font-family:PingFangSC-Regular,PingFang SC;*/
        font-weight:initial;
        color:#000000;
        line-height:16px;
        overflow: hidden;      /*溢出隐藏*/
        white-space: nowrap;	/*规定文本不进行换行*/
        text-overflow: ellipsis;	/*当对象内文本溢出时显示省略标记（...）*/
      }
    }
    .card_countLocation{
      margin-top: 2.367vw;
      padding: 0.4vw 2.7vw;
      border: 1px solid #d9d9d9;
      margin 10px 0;
      height: 170px;
      border-radius: 1.6vw;
      color: #000;
      box-shadow: 0 0 2.133vw #d9d9d9;
      font-size: 14px;
      .row{
          display flex
          padding  7px 0
          .row-50{
              width 50%
              display flex
              .title{
                  width:56px;
                  height:16px;
                  font-size:14px;
                  font-family:PingFangSC-Regular,PingFang SC;
                  font-weight:200;
                  color:#595959;
                  line-height:18px;
              }
              .value{
                  width:110px;
                  height:18px;
                  font-size:14px;
                  font-family:PingFangSC-Medium,PingFang SC;
                  font-weight:initial;
                  color:#000000;
                  line-height:18px;
                  overflow: hidden;      /*溢出隐藏*/
                  white-space: nowrap;	/*规定文本不进行换行*/
                  text-overflow: ellipsis;	/*当对象内文本溢出时显示省略标记（...）*/
              }
              .label{
                  width:40px;
                  height:18px;
                  font-size:14px;
                  font-family:PingFangSC-Medium,PingFang SC;
                  font-weight:initial;
                  color:#000000;
                  line-height:18px;
              }
              .label-number{
                  width:40px;
                  height:18px;
                  font-size:14px;
                  font-family:PingFangSC-Medium,PingFang SC;
                  font-weight:initial;
                  color:#9254DE;
                  line-height:18px;
              }
          }
          .title{
              width:56px;
              height:16px;
              font-size:14px;
              font-family:PingFangSC-Regular,PingFang SC;
              font-weight:200;
              color:#595959;
              line-height:18px;
          }
          .value{
              width:260px;
              height:18px;
              font-size:14px;
              font-family:PingFangSC-Medium,PingFang SC;
              font-weight:initial;
              color:#000000;
              line-height:18px;
              overflow: hidden;      /*溢出隐藏*/
              white-space: nowrap;	/*规定文本不进行换行*/
              text-overflow: ellipsis;	/*当对象内文本溢出时显示省略标记（...）*/
          }
        }
      }
    }

  .hips-tab__panel {
    padding: 4vw 0 !important;
  }

  .hips-scroll__pullup .before-trigger {
    padding: 61.267vw 0;
    min-height: 5.867vw;
  }
</style>
