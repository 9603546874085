<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.warehouseStock')" :is-index="false" />
    </div>
    <div v-if="skuWarehouseData.length > 0">
      <div class="header-row">
        <div class="header-title">
          {{ (batchData[0] && batchData[0].batchCode) || '--' }}
        </div>
      </div>
    </div>
    <div class="ips-container">
      <ips-no-data v-if="skuWarehouseData.length<=0" />
      <!--库位-物料卡片-->
      <div v-if="skuWarehouseData.length > 0">
        <div
          class="card_countLocation"
          v-for="(item,index) in skuWarehouseData"
          :key="index"
          @click="toBatchWharea(item)"
        >
          <div class="row">
            <div class="title">
              {{ $t('label.warehouse') }}:
            </div>
            <div class="value">
              {{ item.warehouseName }}
            </div>
          </div>
          <div class="row">
            <div class="row-50">
              <div class="title">
                {{ $t('label.sku') }}:
              </div>
              <div class="value">
                {{ item.sku }}
              </div>
            </div>
            <div class="row-50">
              <div class="title">
                {{ $t('label.goodsName') }}:
              </div>
              <div class="value">
                {{ item.goodsName }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="title">
              {{ $t('label.batch') }}:
            </div>
            <div class="value">
              {{ item.batchCode || '--' }}
            </div>
          </div>
          <div class="row">
            <div class="row-50">
              <div class="title">
                {{ $t('label.availableQty') }}:
              </div>
              <div class="label-number">
                {{ item.qty }}
              </div>
            </div>
            <div class="row-50">
              <div class="title">
                {{ $t('label.currentUseQty') }}:
              </div>
              <div class="label-number">
                {{ item.validQty }}
              </div>
            </div>
          </div>
          <div class="row-detail">
            <div class="title">
              <a class="detail">
                {{ $t('label.detail') }}
              </a>
            </div>
            <hips-icon class="ips-icon" name="arrow" size="30" />
          </div>
        </div>
      </div>
    </div>
  </hips-view>
</template>

<script>
  import { queryBatch } from '../api';
  import { Row, Col, Icon } from '@hips/vue-ui';
  export default {
    name: 'BatchWarehouse',
    components:{
      [Row.name]: Row,
      [Col.name]: Col,
      [Icon.name]: Icon,
    },
    data() {
      return {
        batchData: [], // params参数
        skuWarehouseData: '',
      };
    },
    methods: {
      // 获取params传来的参
      getParamsData(){
        const { item } = this.$route.params;
        this.batchData = item;
        let data = {
          params: {
            batchCode: item[0] && item[0].batchCode,
          }
        };
        queryBatch(data).then((res)=>{
          this.skuWarehouseData = res;
        })
          .catch((e) => {
            console.log('错误：'+ e )
          })
          .finally(()=>{
            this.$hint.hideLoading();
          });
      },
      //跳转至批次-库区页面
      toBatchWharea(item){
        this.batchData = {
          ...item,
        };
        let param = {
          name: 'BatchWharea',
          params: {
            item: this.batchData,
          },
        };
        this.$router.pushPage(param);
      },

    },
    mounted() {
      this.getParamsData();
    },
    beforeRouteLeave(to, from, next) {
      if (to.name === 'BatchWharea') {
        this.$store.commit('pushKeepAlives', 'BatchWarehouse');
      } else {
        this.$store.commit('deleteKeepAlives', 'BatchWarehouse');
      }
     this.$nextTick(() => {
        next();
      })
    },
  };
</script>

<style lang="stylus" scoped>
 @import '~@/style/var.styl'
    .header-row{
      padding-top 5px
      display flex;
      margin-bottom 12px;
      .header-title{
        display flex;
        align-items: center;
        height:28px;
        background:#E6F7FF;
        color var(--primaryColor);
        border-radius:0px 32px 32px 0px;
        padding 0 12px 0 8px;
        border-left:4px solid var(--primaryColor);
      }
  }
    .ips-container{
        padding-top 0!important;
        .card_countLocation{
            margin 10px 0;
            padding  0 12px
            height:160px;
            border-radius: 1.6vw;
            color: #000;
            box-shadow: 0 0 2.133vw #d9d9d9;
            border: none;
            font-size: 14px;
            .row{
                display flex
                padding  7px 0
                .row-50{
                    width 50%
                    display flex
                    .title{
                        width:56px;
                        height:16px;
                        font-size:14px;
                        font-family:PingFangSC-Regular,PingFang SC;
                        font-weight:200;
                        color:#595959;
                        line-height:18px;
                    }
                    .value{
                        width:110px;
                        height:18px;
                        font-size:14px;
                        font-family:PingFangSC-Medium,PingFang SC;
                        font-weight:initial;
                        color:#000000;
                        line-height:18px;
                        overflow: hidden;      /*溢出隐藏*/
                        white-space: nowrap;	/*规定文本不进行换行*/
                        text-overflow: ellipsis;	/*当对象内文本溢出时显示省略标记（...）*/
                    }
                    .label{
                        width:40px;
                        height:18px;
                        font-size:14px;
                        font-family:PingFangSC-Medium,PingFang SC;
                        font-weight:initial;
                        color:#000000;
                        line-height:18px;
                    }
                    .label-number{
                        width:40px;
                        height:18px;
                        font-size:14px;
                        font-family:PingFangSC-Medium,PingFang SC;
                        font-weight:initial;
                        color:#9254DE;
                        line-height:18px;
                    }
                }
                .title{
                    width:56px;
                    height:16px;
                    font-size:14px;
                    font-family:PingFangSC-Regular,PingFang SC;
                    font-weight:200;
                    color:#595959;
                    line-height:18px;
                }
                .value{
                    width:260px;
                    height:18px;
                    font-size:14px;
                    font-family:PingFangSC-Medium,PingFang SC;
                    font-weight:initial;
                    color:#000000;
                    line-height:18px;
                    overflow: hidden;      /*溢出隐藏*/
                    white-space: nowrap;	/*规定文本不进行换行*/
                    text-overflow: ellipsis;	/*当对象内文本溢出时显示省略标记（...）*/
                }
            }
            .row-detail{
                display flex
                justify-content space-between
                padding  7px 0
                border-top: 1px solid #d9d9d9;
                .detail{
                    width:28px;
                    height:16px;
                    font-size:14px;
                    font-family:PingFangSC-Regular,PingFang SC;
                    font-weight:200;
                    color:var(--primaryColor);
                    line-height:16px;
                }
                .ips-icon{
                  color #bfbfbf
                }
            }
        }
    }
</style>
