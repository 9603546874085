var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: {
            title: _vm.$t("title.batchSerialDetail"),
            "is-index": false,
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container" },
      _vm._l(_vm.lineData, function (item, index) {
        return _c(
          "div",
          { key: index, staticStyle: { marginBottom: "30px" } },
          [
            _c("ips-value-cell", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.customData["sku"] ? _vm.hideControl["sku"] : true,
                  expression: "customData['sku'] ? hideControl['sku'] : true",
                },
              ],
              attrs: { title: _vm.$t("label.sku"), value: item.sku },
            }),
            _c("ips-value-cell", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.customData["goodsName"]
                    ? _vm.hideControl["goodsName"]
                    : true,
                  expression:
                    "customData['goodsName'] ? hideControl['goodsName'] : true",
                },
              ],
              attrs: {
                title: _vm.$t("label.goodsName"),
                value: item.goodsName,
              },
            }),
            _c("ips-value-cell", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.customData["batchCode"]
                    ? _vm.hideControl["batchCode"]
                    : true,
                  expression:
                    "customData['batchCode'] ? hideControl['batchCode'] : true",
                },
              ],
              attrs: { title: _vm.$t("label.batch"), value: item.batchCode },
            }),
            _c("ips-value-cell", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.customData["ownerName"]
                    ? _vm.hideControl["ownerName"]
                    : true,
                  expression:
                    "customData['ownerName'] ? hideControl['ownerName'] : true",
                },
              ],
              attrs: {
                title: _vm.$t("label.ownerCode"),
                value: item.ownerName,
              },
            }),
            _c("ips-value-cell", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.customData["vendorCode"]
                    ? _vm.hideControl["vendorCode"]
                    : true,
                  expression:
                    "customData['vendorCode'] ? hideControl['vendorCode'] : true",
                },
              ],
              attrs: {
                title: _vm.$t("label.vendorCode"),
                value: item.vendorCode,
              },
            }),
            _c("ips-value-cell", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.customData["vendorBatch"]
                    ? _vm.hideControl["vendorBatch"]
                    : true,
                  expression:
                    "customData['vendorBatch'] ? hideControl['vendorBatch'] : true",
                },
              ],
              attrs: {
                title: _vm.$t("label.vendorNumber"),
                value: item.vendorBatch,
              },
            }),
            _c("ips-value-cell", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.customData["batchStatusMeaning"]
                    ? _vm.hideControl["batchStatusMeaning"]
                    : true,
                  expression:
                    "customData['batchStatusMeaning'] ? hideControl['batchStatusMeaning'] : true",
                },
              ],
              attrs: {
                title: _vm.$t("label.battcStatus"),
                value: item.batchStatusMeaning,
              },
            }),
            _c("ips-value-cell", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.customData["produceDate"]
                    ? _vm.hideControl["produceDate"]
                    : true,
                  expression:
                    "customData['produceDate'] ? hideControl['produceDate'] : true",
                },
              ],
              attrs: {
                title: _vm.$t("label.produceDate"),
                value: item.produceDate,
              },
            }),
            _c("ips-value-cell", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.customData["expireDate"]
                    ? _vm.hideControl["expireDate"]
                    : true,
                  expression:
                    "customData['expireDate'] ? hideControl['expireDate'] : true",
                },
              ],
              attrs: {
                title: _vm.$t("label.expireDate"),
                value: item.expireDate,
              },
            }),
            _c("ips-value-cell", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.customData["nextCheckDate"]
                    ? _vm.hideControl["nextCheckDate"]
                    : true,
                  expression:
                    "customData['nextCheckDate'] ? hideControl['nextCheckDate'] : true",
                },
              ],
              attrs: {
                title: _vm.$t("label.nextCheckDate"),
                value: item.nextCheckDate,
              },
            }),
            _c("ips-value-cell", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.customData["qty"] ? _vm.hideControl["qty"] : true,
                  expression: "customData['qty'] ? hideControl['qty'] : true",
                },
              ],
              attrs: { title: _vm.$t("label.availableQty"), value: item.qty },
            }),
            _c("ips-value-cell", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.customData["validQty"]
                    ? _vm.hideControl["validQty"]
                    : true,
                  expression:
                    "customData['validQty'] ? hideControl['validQty'] : true",
                },
              ],
              attrs: { title: _vm.$t("label.useQty"), value: item.validQty },
            }),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }