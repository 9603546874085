var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [_c("ips-header", { attrs: { title: _vm.$t("title.multiLpnPack") } })],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _c(
          "hips-tabs",
          {
            model: {
              value: _vm.active,
              callback: function ($$v) {
                _vm.active = $$v
              },
              expression: "active",
            },
          },
          [
            _c(
              "hips-tab",
              {
                staticClass: "tabTitleClass",
                class: [{ "active-tab": _vm.active === 0 }],
                attrs: { title: _vm.$t("label.multiPack") },
              },
              [
                _c("ips-lov", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.customData["parentCidCode"]
                        ? _vm.hideControl["parentCidCode"]
                        : true,
                      expression:
                        "customData['parentCidCode'] ? hideControl['parentCidCode']: true",
                    },
                  ],
                  ref: "ref0",
                  attrs: {
                    label: _vm.$t("label.parentCid"),
                    title: _vm.$t("label.parentCid"),
                    params: "cidCode",
                    url: _vm.wmdmBaseUrl + "containers/parent-cid-lov",
                    custom: _vm.customData["parentCidCode"],
                    "source-parse-field": _vm.sourceParseField,
                    required: _vm.active === 0,
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleCommonClick(0)
                    },
                    clickIcon: function ($event) {
                      return _vm.handleCommonClick(0, "lov")
                    },
                    enter: function ($event) {
                      return _vm.skipNext(10)
                    },
                    down: function ($event) {
                      return _vm.skipNext(10)
                    },
                    choose: function ($event) {
                      return _vm.handleChoose("parentCidCode", $event)
                    },
                    setSourceField: function ($event) {
                      return _vm.saveSourceField($event)
                    },
                    valuedByFoucs: function ($event) {
                      return _vm.valuedByFoucs($event)
                    },
                  },
                  model: {
                    value: _vm.inputData.parentCidCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputData, "parentCidCode", $$v)
                    },
                    expression: "inputData.parentCidCode",
                  },
                }),
                _c("ips-lov", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.customData["childCidCode"]
                        ? _vm.hideControl["childCidCode"]
                        : true,
                      expression:
                        "customData['childCidCode'] ? hideControl['childCidCode']: true",
                    },
                  ],
                  ref: "ref10",
                  attrs: {
                    label: _vm.$t("label.childCid"),
                    title: _vm.$t("label.childCid"),
                    params: "cidCode",
                    url: _vm.wmdmBaseUrl + "containers/child-cid-lov",
                    "url-params": {
                      cidStatus: _vm.parentCidStatus,
                      parentCidId: _vm.parentCidId,
                    },
                    custom: _vm.customData["childCidCode"],
                    "source-parse-field": _vm.sourceParseField,
                    required: _vm.active === 0,
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleCommonClick(10)
                    },
                    clickIcon: function ($event) {
                      return _vm.handleCommonClick(10, "lov")
                    },
                    enter: function ($event) {
                      return _vm.skipNext("submitRef")
                    },
                    down: function ($event) {
                      return _vm.skipNext("submitRef")
                    },
                    choose: function ($event) {
                      return _vm.handleChoose("childCidCode", $event)
                    },
                    setSourceField: function ($event) {
                      return _vm.saveSourceField($event)
                    },
                    valuedByFoucs: function ($event) {
                      return _vm.valuedByFoucs($event)
                    },
                  },
                  model: {
                    value: _vm.inputData.childCidCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputData, "childCidCode", $$v)
                    },
                    expression: "inputData.childCidCode",
                  },
                }),
                _c(
                  "hips-button",
                  {
                    ref: "submitRef",
                    staticClass: "pack-button-submit-class",
                    class: [
                      {
                        "disable-btn":
                          !_vm.inputData.parentCidCode ||
                          !_vm.inputData.childCidCode ||
                          _vm.isSubmitFlag,
                      },
                    ],
                    attrs: {
                      size: "large",
                      disabled:
                        !_vm.inputData.parentCidCode ||
                        !_vm.inputData.childCidCode ||
                        _vm.isSubmitFlag,
                    },
                    on: { click: _vm.handleCommonSubmit },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("button.buttonPack")) +
                        "\n        "
                    ),
                  ]
                ),
                _vm.childCidObj && _vm.childCidObj.length > 0
                  ? _c(
                      "div",
                      { staticStyle: { "margin-top": "20px" } },
                      [
                        _c(
                          "hips-row",
                          [
                            _c(
                              "hips-col",
                              { staticClass: "child-cid-card-title" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("label.childCid")) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._l(_vm.childCidObj, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "child-cid-message-card",
                            },
                            [
                              _c(
                                "hips-row",
                                [
                                  _c(
                                    "hips-col",
                                    { staticStyle: { "margin-left": "12px" } },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(item.cidCode) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "hips-tab",
              {
                staticClass: "tabTitleClass",
                class: [{ "active-tab": _vm.active !== 0 }],
                attrs: { title: _vm.$t("label.multiPackCancel") },
              },
              [
                _c("ips-lov", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.customData["parentCidCodeTab"]
                        ? _vm.hideControl["parentCidCodeTab"]
                        : true,
                      expression:
                        "customData['parentCidCodeTab'] ? hideControl['parentCidCodeTab']: true",
                    },
                  ],
                  ref: "ref20",
                  attrs: {
                    label: _vm.$t("label.parentCid"),
                    title: _vm.$t("label.parentCid"),
                    params: "cidCode",
                    url: _vm.wmdmBaseUrl + "containers/unpack-parent-cid-lov",
                    custom: _vm.customData["parentCidCodeTab"],
                    "source-parse-field": _vm.sourceParseField,
                    required: _vm.active !== 0,
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleCommonClick(20)
                    },
                    clickIcon: function ($event) {
                      return _vm.handleCommonClick(20, "lov")
                    },
                    enter: function ($event) {
                      return _vm.skipNext(30)
                    },
                    down: function ($event) {
                      return _vm.skipNext(30)
                    },
                    choose: function ($event) {
                      return _vm.handleChoose("parentCidCodeTab", $event)
                    },
                    setSourceField: function ($event) {
                      return _vm.saveSourceField($event)
                    },
                    valuedByFoucs: function ($event) {
                      return _vm.valuedByFoucs($event)
                    },
                  },
                  model: {
                    value: _vm.inputData.parentCidCodeTab,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputData, "parentCidCodeTab", $$v)
                    },
                    expression: "inputData.parentCidCodeTab",
                  },
                }),
                _c("hips-row", [
                  _c(
                    "div",
                    { staticStyle: { "margin-top": "16px" } },
                    [
                      _c(
                        "hips-col",
                        { attrs: { span: "16" } },
                        [
                          _c("ips-lov", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.customData["childCidCodeTab"]
                                  ? _vm.hideControl["childCidCodeTab"]
                                  : true,
                                expression:
                                  "customData['childCidCodeTab'] ? hideControl['childCidCodeTab']: true",
                              },
                            ],
                            ref: "ref30",
                            attrs: {
                              label: _vm.$t("label.childCid"),
                              title: _vm.$t("label.childCid"),
                              params: "cidCode",
                              url:
                                _vm.wmdmBaseUrl +
                                "containers/unpack-child-cid-lov",
                              "url-params": {
                                cidStatus: _vm.parentCidStatusTab,
                                parentCidId: _vm.parentCidIdTab,
                              },
                              custom: _vm.customData["childCidCodeTab"],
                              "source-parse-field": _vm.sourceParseField,
                              required: _vm.active !== 0 && !_vm.isAllChange,
                              disabled: _vm.isAllChange,
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.handleCommonClick(30)
                              },
                              clickIcon: function ($event) {
                                return _vm.handleCommonClick(30, "lov")
                              },
                              enter: function ($event) {
                                return _vm.skipNext("allCancellation")
                              },
                              down: function ($event) {
                                return _vm.skipNext("allCancellation")
                              },
                              choose: function ($event) {
                                return _vm.handleChoose(
                                  "childCidCodeTab",
                                  $event
                                )
                              },
                              setSourceField: function ($event) {
                                return _vm.saveSourceField($event)
                              },
                              valuedByFoucs: function ($event) {
                                return _vm.valuedByFoucs($event)
                              },
                            },
                            model: {
                              value: _vm.inputData.childCidCodeTab,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputData, "childCidCodeTab", $$v)
                              },
                              expression: "inputData.childCidCodeTab",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "hips-col",
                        { attrs: { span: "8" } },
                        [
                          _c("hips-checkbox", {
                            staticClass: "checkbox-class",
                            attrs: { label: _vm.$t("button.allCheck") },
                            model: {
                              value: _vm.isAllChange,
                              callback: function ($$v) {
                                _vm.isAllChange = $$v
                              },
                              expression: "isAllChange",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "hips-row",
                  [
                    _c(
                      "hips-col",
                      { attrs: { span: "10" } },
                      [
                        _c(
                          "hips-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.customData["cancellation"]
                                  ? _vm.hideControl["cancellation"]
                                  : true,
                                expression:
                                  "customData['cancellation'] ? hideControl['cancellation']: true",
                              },
                            ],
                            ref: "cancellation",
                            staticClass: "cancel-button-style-right",
                            class: [
                              {
                                "disable-btn-line":
                                  !_vm.inputData.parentCidCodeTab ||
                                  (!_vm.inputData.childCidCodeTab &&
                                    !_vm.isAllChange) ||
                                  (_vm.isAllChange &&
                                    _vm.childCidObjTab.length === 0),
                              },
                            ],
                            attrs: {
                              disabled:
                                !_vm.inputData.parentCidCodeTab ||
                                (!_vm.inputData.childCidCodeTab &&
                                  !_vm.isAllChange) ||
                                (_vm.isAllChange &&
                                  _vm.childCidObjTab.length === 0),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleCommonSubmit("cancellation")
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("button.cancellation")) +
                                "\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "hips-col",
                      { attrs: { span: "10" } },
                      [
                        _c(
                          "hips-button",
                          {
                            ref: "allCancellation",
                            staticClass: "cancel-button-style",
                            class: [
                              {
                                "disable-btn":
                                  !_vm.inputData.parentCidCodeTab ||
                                  (!_vm.inputData.childCidCodeTab &&
                                    !_vm.isAllChange) ||
                                  (_vm.isAllChange &&
                                    _vm.childCidObjTab.length === 0),
                              },
                            ],
                            attrs: {
                              disabled:
                                !_vm.inputData.parentCidCodeTab ||
                                (!_vm.inputData.childCidCodeTab &&
                                  !_vm.isAllChange) ||
                                (_vm.isAllChange &&
                                  _vm.childCidObjTab.length === 0),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleCommonSubmit("allCancellation")
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("button.allCancellation")) +
                                "\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.childCidObjTab && _vm.childCidObjTab.length > 0
                  ? _c(
                      "div",
                      { staticStyle: { "margin-top": "20px" } },
                      [
                        _c(
                          "hips-row",
                          [
                            _c(
                              "hips-col",
                              { staticClass: "child-cid-card-title" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("label.childCid")) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._l(_vm.childCidObjTab, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "child-cid-message-card",
                              class: [
                                {
                                  "choose-card":
                                    item.cidCode === _vm.chooseObj.cidCode ||
                                    _vm.isAllChange,
                                },
                              ],
                            },
                            [
                              _c(
                                "hips-row",
                                [
                                  _c(
                                    "hips-col",
                                    { staticStyle: { "margin-left": "12px" } },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(item.cidCode) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }