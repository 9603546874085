<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.outboundDelivery')" />
    </div>
    <div class="ips-container">
      <hips-radio-group v-if="deliveryMode" horizontal v-model="deliveryMode">
        <hips-radio key="ORDER" option="ORDER" position="right">
          出库单交接
        </hips-radio>
        <hips-radio key="WAVE" option="WAVE" position="right">
          波次单交接
        </hips-radio>
      </hips-radio-group>
      <!--包裹单号-->
      <ips-input
        v-model="inputData.packCode"
        :label="$t('label.packageNo')"
        ref="ref0"
        :custom="customData['packCode']"
        :source-parse-field="sourceParseField"
        @enter="checkPackCodeBlur"
        @down="checkPackCodeBlur"
        @click.stop="handleCommonClick(0)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 承运商 -->
      <ips-value-cell
        :title="$t('label.carrier')"
        image-flag
        :value="orderData.carrierName"
      />
      <!-- 波次单号 -->
      <ips-value-cell
        v-if="deliveryMode === 'WAVE'"
        :title="$t('label.waveNum')"
        image-flag
        :value="orderData.waveBillCode"
      />
      <!-- 出库单号 -->
      <ips-value-cell
        v-if="deliveryMode === 'ORDER'"
        :title="$t('label.outboundOrderR')"
        image-flag
        :value="orderData.billCode"
      />
      <!-- 已录箱数 -->
      <ips-value-cell
        title="已录箱数 "
        image-flag
        :value="orderData.scannedCount"
      />
      
      <!-- 已交接数 -->
      <ips-value-cell
        title="已交接数"
        image-flag
        :value="orderData.deliveredCount"
      />
      <!-- 未交接数 -->
      <ips-value-cell
        title="未交接数"
        image-flag
        :value="orderData.unDeliveredCount"
      />

      <!-- 未复核数 -->
      <ips-value-cell
        title="未复核数"
        image-flag
        :value="orderData.unReviewedCount"
      />
      <div class="ips-footer">
        <!--提交-->
        <hips-button
          size="large"
          ref="submitRef"
          @click.stop="handleCommonSubmit"
        >
          {{ $t('button.confirm') }}
        </hips-button>
      </div>
    </div>
  </hips-view>
</template>

<script>
import { storage } from '@/utils';
import { packConfirm, checkPackCode } from '../api/index';
export default {
  name: 'OutboundDelivery',
  data() {
    return {
      deliveryMode: '',
      // 数量展示对象
      orderData: {},
      // 界面上的输入框数据
      inputData: {
        // 包裹
        packCode: ''
      },
      menuId: storage.get('menuId'),
      urlData: {},
      customData: {}, // 个性化配置信息列表
      hideControl: {}, // 非必输字段的显隐控制
      sourceParseField: {}, // 界面中所有的来源解析字段
    };
  },
  watch: {
    deliveryMode: {
      handler(nVal, oVal) {
        if (nVal && nVal !== oVal) {
          storage.set('_delivery_mode_', nVal)
          this.resetState()
          this.skipNext(0)
        }
      },
    }
  },
  async created() {
    this.$hint.hideLoading();
    const { hideControl, customObj } = this.handleCustomData(storage.get('customData') || []);
    this.customData = customObj;
    this.hideControl = hideControl;
  },
  mounted() {
    const cacheDeliveryMode = storage.get('_delivery_mode_')

    console.log('cacheDeliveryMode', cacheDeliveryMode)
    if (cacheDeliveryMode) {
      this.deliveryMode = cacheDeliveryMode
    } else {
      this.deliveryMode = 'ORDER';
      storage.set('_delivery_mode_', this.deliveryMode);
    }
  },
  methods: {
    // 保存来源解析字段
    async saveSourceField(fieldObj) {
      this.saveSourceParseField(this, fieldObj);
    },
    // 字段聚焦时赋值操作
    async valuedByFoucs(options = {}) {
      const { custom = {}, currentValue } = options;
      await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
    },
    // 重置转态
    resetState() {
      this.inputData.packCode = ''
      this.orderData = {}
    },
    // 提交
    async handleButtonSubmit() {
      try {
        this.$hint.showLoading();
        const res = await packConfirm(this.orderData)
        if (!(res && res.failed)) {
          this.resetState()
          this.skipNext(0)
        }
        this.$hint.hideLoading();
      } catch (error) {
        this.$hint.hideLoading();
      }
    },

    checkPackCodeBlur() {
      // eslint-disable-next-line no-unused-vars
      const { packCode, ...rest } = this.orderData
      const validateParams = {
        ...rest,
        deliveryMode: this.deliveryMode,
        packCode: this.inputData.packCode
      }

      checkPackCode(validateParams).then((res) => {
        if (!(res && res.failed)) {
          this.orderData = res
          if (res.scannedCount === res.unDeliveredCount) {
            this.skipNext('submitRef')
          } else {
            this.inputData.packCode = ''
          }
        }
      }).catch((err) => {
        this.inputData.packCode = ''
        console.log(err)
      })
    }
  },
};
</script>

<style lang="stylus" scoped>
@import '~@/style/var.styl';

.ips-container >>> .hips-cell__title {
  flex: 2 !important;
}
</style>
