import Demo from '../views/index'

let route = [
  {
    path: '/demo',
    name: 'Demo',
    component: Demo,
  },
]

export default route
