<template>
  <div
    class="ips-input-number ips-input-common"
  >
    <ips-input
      ref="input"
      v-bind="$attrs"
      :value="displayValue"
      :disabled="disabled"
      :readonly="readonly"
      :required="required"
      :max="max"
      :min="min"
      :name="name"
      :label="label"
      :autofocus="autofocus"
      @blur="handleBlur"
      @focus="handleFocus"
      @input="handleInput"
      @change="handleInputChange"
      @down="handleDown"
      @enter="handleEnter"
      @click="handleClick"
    />
  </div>
</template>
<script>
    import IpsInput from './ipsField';
    export default {
        name: 'IpsInputNumber',
        components: {
            IpsInput
        },
        props: {
            max: {
                type: Number,
                default: Infinity
            },
            min: {
                type: Number,
                default: -Infinity
            },
            value: {
                type: [String, Number],
                default: null
            },
            disabled: Boolean,
            required: Boolean,
            isControl: Boolean,
            readonly: Boolean,
            autofocus: {
                type: Boolean,
                default: false
            },
            name: {
                type: String,
                default: null
            },
            label: {
                type: String,
                default: null
            },
            enableSerial: {
                type: String,
                default: null
            },
            thousandsFormatter: { // 是否千分位格式化
                type: Boolean,
                default: false
            },
            // eslint-disable-next-line vue/require-default-prop
            precision: {
                type: Number,
                validator(val) {
                    return val >= 0 && val === parseInt(val, 10);
                }
            },
            notAutoFill: { // 数组框为空时是否不自动填充0
                type: Boolean,
                default: false,
            },
            // 是否已经被个性化过
            overed: {
                type: Boolean,
                default: false,
            },
            // 个性化配置信息
            custom: {
              type: Object,
              default: () => {},
            },
            type: {
              type: String,
              default: 'number',
            },
            // 所有的来源解析字段
            sourceParseField: {
              type: Object,
              default: () => {},
            }
        },
        watch: {
            value: {
                immediate: true,
                handler(value) {
                    let newVal = (value === undefined || value === null || value === '') ? value : Number(value);
                    if (!this.$common.isEmpty(newVal)) {
                        if (isNaN(newVal)) {
                            return;
                        }
                        if (!this.$common.isEmpty(this.digital) || !this.$common.isEmpty(this.localPrecision)) {
                            newVal = newVal === 0 ? 0 : newVal === '' ? '' : this.toPrecision(newVal);
                        }
                    }
                    if (newVal >= this.max) newVal = this.max;
                    if (newVal <= this.min) newVal = this.min;
                    this.currentValue = newVal;
                    this.userInput = null;
                    this.$emit('input', newVal);
                }
            },
            displayValue: {
                immediate: true,
                handler(value) {
                    let newVal = (value === undefined || value === null || value === '') ? value : Number(value);
                    if (!this.$common.isEmpty(newVal)) {
                        if (isNaN(newVal)) {
                            return;
                        }
                        if (!this.$common.isEmpty(this.digital) || !this.$common.isEmpty(this.localPrecision)) {
                            newVal = newVal === 0 ? 0 : newVal === '' ? '' : this.toPrecision(newVal);
                        }
                    }
                    if (newVal >= this.max) newVal = this.max;
                    if (newVal <= this.min) newVal = this.min;
                    this.currentValue = newVal;
                    this.userInput = null;
                    this.$emit('input', value)
                },
            },
            precision: {
                immediate: true,
                handler(value) {
                    this.localPrecision = value
                }
            }
        },
        computed: {
            displayValue() {
                // if(this.userInput === null || this.userInput === '' || this.userInput === undefined) return undefined;
                if (this.userInput !== null) {
                    return this.userInput;
                }
                let currentValue = this.currentValue;
                // if (typeof currentValue === 'number' && currentValue.toString().includes('.')) {
                //     if (this.localPrecision !== undefined) {
                //         currentValue = currentValue.toFixed(this.localPrecision);
                //     }
                // }
                if (this.thousandsFormatter && !this.focused) {
                    currentValue = !this.$common.isEmpty(currentValue) ? this.formatMoney(currentValue, false, true) : undefined
                }
                return currentValue;
            }
        },
        data() {
            return {
                currentValue: '',
                userInput: null,
                focused: false,
                digital: 0,
                localPrecision: 0
            };
        },
        methods: {
            async focus() {
                const input = this.$refs.input;
                // const { fieldCode } = this.custom || {};
                // console.log('光标到', fieldCode);
      console.log('触发Number focus');
                
                input.focus();
                if(this.currentValue && this.isControl){
                    return;
                }
                await this.$emit('valuedByFoucs', { custom: this.custom, currentValue: this.currentValue });
            },
            blur() {
      console.log('触发Number blur');

                this.$refs.input.blur();
            },
            toPrecision(num) {
                let precision = this.digital;
                const result = precision === 0 || this.$common.isEmpty(precision) ? num : parseFloat(Math.round(num * Math.pow(10, precision)) / Math.pow(10, precision));
                return result;
            },
            handleBlur(event) {
              const site = [this.SERIAL_INV_CTRL, this.SERIAL_IN_CTRL, this.SERIAL_OUT_CTRL, this.SERIAL_SEMI_INV_CTRL];
              this.focused = false;
              // event.currentTarget.value = Number(event.currentTarget.value).toFixed(this.digital);
                let currentValue = Number(event.currentTarget.value || '')
                if (currentValue.toString().includes('.')) {
                    if (this.localPrecision !== undefined) {
                        currentValue = currentValue.toFixed(this.localPrecision);
                        currentValue = currentValue.replaceAll(/0+?$/g, '');
                        currentValue = currentValue.replaceAll(/[.]$/g, '');
                        event.currentTarget.value = currentValue
                    }
                }
              if(!this.notAutoFill) {
                event.currentTarget.value = Math.round(event.currentTarget.value*Math.pow(10,this.digital))/Math.pow(10,this.digital);
                this.value = event.currentTarget.value;
              }
              if(site.includes(this.enableSerial)){
                this.value = Math.round(this.value)
              }
              this.$emit('blur', event);
            },
            handleEnter(event) {
              const { resolveSourceFlag = false, fieldCode, resolveDelimiter, valueSourceIndex, subFields } = this.custom || {};
              if(resolveSourceFlag && this.currentValue && this.currentValue.toString().includes(resolveDelimiter)) { // 是来源解析字段
                const parseValue = this.currentValue.split(resolveDelimiter);
                let value = parseValue[Number(valueSourceIndex)-1];
                value = value ? Number(value) : value;
                // const parsedValue = this.thousandsFormatter ? this.formatMoney(value) : Number(value);
                this.currentValue = value;
                this.$emit('input', value);
                // this.handleInputChange(parsedValue);
                const fieldObj = { fieldCode, parseValue, subFields, value };
                this.$emit('setSourceField', fieldObj);
              }
              this.$emit('enter', event)
            },
            handleDown(event) {
                this.$emit('down', event)
            },
            handleFocus(event) {
                this.focused = true;
                // this.focus();
                if (this.thousandsFormatter) {
                    this.currentValue = !this.$common.isEmpty(event.target.value) ? Number(this.$common.refMoneyFormat(event.target.value)) : undefined;
                }
                this.$emit('focus', event);
            },
            handleClick(event) {
                this.$emit('click', event);
            },
            handleInput(value) {
                this.userInput = value;
            },
            setCurrentValue(newVal) {
                const oldVal = this.currentValue;
                if (this.localPrecision !== undefined) {
                    newVal = this.toPrecision(newVal);
                }
                if (newVal >= this.max) newVal = this.max;
                if (newVal <= this.min) newVal = this.min;
                if (oldVal === newVal) return;
                this.userInput = null;
                // if (this.thousandsFormatter) {
                    // this.currentValue = !this.$common.isEmpty(newVal) ? this.formatMoney(newVal) : undefined;
                // } else {
                    // this.currentValue = newVal;
                // }
                this.currentValue = newVal;
                this.$emit('change', newVal, oldVal);
                this.$emit('input', newVal);
            },
            formatMoney(val) {
                return this.$common.moneyFormat(val, this.digital || this.permission, true, false)
            },
            handleInputChange(value) {
                let newVal;
                newVal = value;
                this.setCurrentValue(newVal);
                // if(value !== '' && value !== undefined && value !== null) {
                    // if (value && this.thousandsFormatter) {
                        // newVal = Number(this.$common.refMoneyFormat(value))
                    // } else {
                        // newVal = Number(value);
                    // }
                // }            
                // if (!isNaN(newVal) || value === '') {
                    // this.setCurrentValue(newVal);
                // }
                this.userInput = null;
            },
            // 获取有效数字精度
            async getDigital(){
                try {
                   const url = `${this.wmdmBaseUrl}wms-common/query-digital-accuracy`;
                    const res = await this.$http.get(url);
                    this.localPrecision= res;
                    this.digital = res; 
                } catch (error) {
                    //
                }
              
            },
        },
        beforeMount(){
          this.getDigital();
        },
    }
</script>
