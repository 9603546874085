<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.inventoryTransfer')" />
    </div>
    <div class="ips-container">
      <!-- 来源托盘 -->
      <ips-lov
        :label="$t('label.startOfTray')"
        :title="$t('label.startOfTray')"
        v-model="fromCidCode"
        v-show="customData['fromCidCode'] ? hideControl['fromCidCode']: true"
        required
        params="cidCode"
        :url="`${winvBaseUrl}app-stock-trans/query-whole-trans-container`"
        :pre-parse-url="urlData['fromCidCode']"
        :custom="customData['fromCidCode']"
        :source-parse-field="sourceParseField"
        ref="ref0"
        @click.stop="handleCommonClick(0)"
        @clickIcon="handleCommonClick(0, 'lov')"
        @enter="skipNext(20)"
        @down="skipNext(20)"
        @choose="handleChoose('fromCidCode', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 起始库区 -->
      <ips-value-cell
        :title="$t('label.sourceOfWharea')"
        v-show="customData['whareaCode'] ? hideControl['whareaCode']: true"
        :value="cidInfo.whareaCode"
      />
      <!-- 起始库位 -->
      <ips-value-cell
        :title="$t('label.startOfLocation')"
        v-show="customData['locationCode'] ? hideControl['locationCode']: true"
        :value="cidInfo.locationCode"
      />
      <!-- 现有量 -->
      <ips-value-cell
        :title="$t('label.availableQty')"
        v-show="customData['qty'] ? hideControl['qty']: true"
        :value="qtyInfo.qty"
      />
      <!-- 可用量 -->
      <ips-value-cell
        :title="$t('label.useQty')"
        v-show="customData['validQty'] ? hideControl['validQty']: true"
        :value="qtyInfo.validQty"
      />
      <!-- 目标托盘 -->
      <ips-lov
        :label="$t('label.targetTray')"
        :title="$t('label.targetTray')"
        required
        v-model="toCidCode"
        v-show="customData['toCidCode'] ? hideControl['toCidCode']: true"
        params="cidCode"
        :disabled="hasFromOutermostCidCode"
        :url="`${winvBaseUrl}app-stock-trans/query-wholetrans-to-container`"
        :url-params="toCidCodeParam"
        :pre-parse-url="urlData['toCidCode']"
        :custom="customData['toCidCode']"
        :source-parse-field="sourceParseField"
        ref="ref10"
        @click.stop="handleCommonClick(10)"
        @clickIcon="handleCommonClick(10, 'lov')"
        @enter="handleToCidEnter"
        @down="handleToCidEnter"
        @choose="handleChoose('toCidCode', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 目标库位 -->
      <ips-lov
        :label="$t('label.targetLocation')"
        :title="$t('label.targetLocation')"
        required
        v-model="toLocationCode"
        v-show="customData['toLocationCode'] ? hideControl['toLocationCode']: true"
        params="locationCode"
        :url="`${wmdmBaseUrl}wms-locations/lov`"
        :url-params="toLocationParam"
        :pre-parse-url="urlData['toLocationCode']"
        :custom="customData['toLocationCode']"
        :source-parse-field="sourceParseField"
        :disabled="isToCidCode"
        ref="ref20"
        @click.stop="handleCommonClick(20)"
        @clickIcon="handleCommonClick(20, 'lov')"
        @enter="skipNext('submitRef')"
        @down="skipNext('submitRef')"
        @choose="handleChoose('toLocationCode', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
    </div>
    <div class="ips-footer">
      <hips-button
        size="large"
        v-show="customData['theDetail'] ? hideControl['theDetail']: true"
        @click="goDetail"
      >
        <svg-icon slot="icon" icon-class="detail" />
        {{ $t('button.theDetail') }}
      </hips-button>
      <hips-button size="large" ref="submitRef" @click.stop="handleCommonSubmit">
        <svg-icon slot="icon" icon-class="success" />
        {{ $t('button.confirm') }}
      </hips-button>
    </div>
  </hips-view>
</template>

<script>
import {queryStockQtyAll, wholeCidStockTransfer} from '../api';
import { storage } from '@/utils';
export default {
  name: 'FullPalletTransfer',
  data() {
    return {
      fromCidId: '', // 起始托盘ID
      fromCidCode: '', // 起始托盘
      hasFromOutermostCidCode: false,//有起始托盘最外层托盘
      toLocationId: '', // 目标库位ID
      toLocationCode: '', // 目标库位
      toCidId: '', // 目标托盘ID
      toCidCode: '', // 目标托盘
      warehouseId: '',
      cidInfo: {}, // 起始托盘明细
      qtyInfo: '', // 数量详细
      toLocationParam: {}, // 目标库位参数
      toCidCodeParam: {}, // 目标托盘参数
      urlData:{},
      customData: {}, // 个性化配置信息列表
      hideControl: {}, // 个性化配置显示隐藏控制
      sourceParseField: {}, // 界面中所有的来源解析字段
      isToCidCode: false,
    };
  },
  methods: {
    /**
     *@functionName: saveSourceField
     *@description: 保存来源解析字段并赋值Enter
     *@params1: fieldObj[Object] 来源解析字段对象,
     */
    saveSourceField(fieldObj) {
      this.saveSourceParseField(this, fieldObj);
    },
    // 字段聚焦时赋值操作
    async valuedByFoucs(options={}) {
      const { custom ={} , currentValue } = options;
      await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
    },
    /**
     * lov选中事件
     * @param {String} type - 定位lov
     * @param {object} obj - 选中行的数据
     */
    handleChoose(type, obj) {
      const crossWhareaId = obj.crossWhareaTransferFlag === 0 ? obj.whareaId : undefined;
      switch (type) {
        case 'fromCidCode':
          this.fromCidId = obj.cidId;
          this.fromCidCode = obj.cidCode;
          this.warehouseId = obj.warehouseId;
          this.cidInfo = obj;
          this.queryQty(obj);
          this.toCidId = obj.cidId;
          this.toCidCode = obj.cidCode;
          this.toLocationParam = {
              warehouseId: obj.warehouseId,
              whareaId: crossWhareaId,
          };
          this.toCidCodeParam = {
              warehouseId: obj.warehouseId,
              whareaId: crossWhareaId,
          };
          if(obj.outermostCidCode!==undefined){
            this.hasFromOutermostCidCode=true;
          }
          break;
        case 'toCidCode':
            this.toCidId = obj.cidId;
            this.toCidCode = obj.cidCode;
            if(this.fromCidCode !== this.toCidCode && obj.locationCode !== undefined){
              this.toLocationId = obj.locationId;
              this.toLocationCode = obj.locationCode;
              this.isToCidCode = true;
            }
            break;
        case 'toLocationCode':
          this.toLocationId = obj.locationId;
          this.toLocationCode = obj.locationCode;
          break;
        default:
          return null;
      }
    },
    /**
     * 现有量、可用量查询
     * @param obj 查询参数
     * @returns {Promise<void>}
     */
    async queryQty(obj) {
      try{
        this.$hint.showLoading();
        // let url = `${this.winvBaseUrl}stock-trans/query-stock-qty-all`;
        let param = {
          params: {
            cidId: obj.cidId,
            cidCode: obj.cidCode,
            locationId: obj.locationId,
            locationCode: obj.locationCode,
            whareaId: obj.whareaId,
          },
        };
        // this.qtyInfo = await this.$http.get(url, param);
        this.qtyInfo = await queryStockQtyAll(param);
      } catch(e) {
        console.log('错误：' + e);
      } finally {
        this.$hint.hideLoading();
      }
    },
    /**
     * 目标托盘enter事件
     */
    handleToCidEnter(){
      if(this.toLocationId === ''){
        this.skipNext(20);
      }else {
        this.skipNext('submitRef');
      }
    },
    /**
     * 整库转移
     * @returns {Promise<void>}
     */
    async handleButtonSubmit() {
      try{
        this.$hint.showLoading();
        this.handleSubmitStatus();
        // let url = `${this.winvBaseUrl}stock-trans/whole-cid-stock-transfer`;
        let param = {
          fromCidId: this.fromCidId,
          fromCidCode: this.fromCidCode,
          toLocationId: this.toLocationId,
          toLocationCode: this.toLocationCode,
          toCidId: this.toCidId,
          toCidCode: this.toCidCode,
          warehouseId: this.warehouseId,
        };
        // await this.$http.post(url, param);
        await wholeCidStockTransfer(param);
        await this.clearSourceFields(this, true);
        this.$hint.showSuccess();
        this.dataInit();
        this.$refs['ref0'].focus();
      } catch(e) {
        console.log('错误：' + e);
      } finally {
        this.$hint.hideLoading();
        this.handleSubmitStatus();
      }
    },
    dataInit() {
      this.fromCidId = '';
      this.fromCidCode = '';
      this.toLocationId = '';
      this.toLocationCode = '';
      this.toCidId = '';
      this.toCidCode = '';
      this.cidInfo = {};
      this.qtyInfo = {};
    },
    /**
     * go明细界面
     */
    goDetail() {
      if (!this.fromCidCode) {
        this.$hint.showFailed(this.$i18n.t('message.startCidNotNull'), true);
        return;
      }
      let param = {
        name: 'FullPalletTransferDetail',
        params: {
          cidCode: this.fromCidCode,
          cidId: this.fromCidId,
        },
      };
      this.$router.pushPage(param);
    },
  },
  created() {
    const { customObj, hideControl } = this.handleCustomData(storage.get('customData'));
    this.customData = customObj;
    this.hideControl = hideControl;
    // console.log('整托转移customData', Object.assign({}, this.customData));
  },
  mounted() {
    this.inputFieldList = [
      { type: 'lov', required: true, ref: 0, bind: 'fromCidCode', field: 'fromCidCode' },
      { type: 'text', bind: 'whareaCode', field: 'whareaCode' },
      { type: 'text', bind: 'locationCode', field: 'locationCode' },
      { type: 'text', bind: 'qty', field: 'qty' },
      { type: 'text', bind: 'validQty', field: 'validQty' },
      { type: 'lov', required: false, ref: 10, bind: 'toCidCode', field: 'toCidCode' },
      { type: 'lov', required: true, ref: 20, bind: 'toLocationCode', field: 'toLocationCode' },
    ];
    this.urlData = this.handleData('FULL_PALLET_TRANSFER_CONFIRM');
    this.$refs['ref0'].focus();
  },
  beforeRouteLeave(to, from, next) {
      if (to.name === 'FullPalletTransferDetail') {
        this.$store.commit('pushKeepAlives', 'FullPalletTransfer');
      } else {
        this.$store.commit('deleteKeepAlives', 'FullPalletTransfer');
      }
      this.$nextTick(() => {
        next();
      });
    },
  watch:{
    'toCidCode': function(val, oldval) {
      if(val === ''){
        this.isToCidCode = false;
        console.log(oldval);
      }
    },
  }
};
</script>

<style lang="stylus" scoped></style>
