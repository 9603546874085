import request from '@/request';
import mixin  from '@/mixin/mixin';


// 单据编号校验
export function checkBillCode(data) {
  let url = `${mixin.data().winvBaseUrl}app-direct-pick/direct-outbound-or-pick`;
  return request.get(url, data);
}

// 创建托盘
export function Containers(data) {
  let url = `${mixin.data().wmdmBaseUrl}containers`;
  return request.post(url, data)
}

// 目标托盘失焦事件 
export function checkContainer(data) {
  let url = `${mixin.data().winvBaseUrl}app-direct-pick/check-to-container`;
  return request.post(url, data);
}

// 查询数量
export function queryProcessQty(data) {
  let url = `${mixin.data().winvBaseUrl}app-direct-pick/query-process-qty`;
  return request.get(url, data);
}

// 来源托盘跳转
export function directOutboundOrCid(data) {
  let url = `${mixin.data().winvBaseUrl}app-direct-pick/direct-outbound-or-cid`;
  return request.get(url, data);
}

// 直接出库数据提交
export function directPick(data) {
  let url = `${mixin.data().winvBaseUrl}app-direct-pick/direct-pick`;
  return request.post(url, data)
}

// 校验托盘是否为父托盘
export function queryFormCidCodeCheck(data) {
  let url = `${mixin.data().winvBaseUrl}app-direct-pick/check-parent-cid`;
  return request.get(url, data);
}

// 查询父托盘序列号
export function getSerialNumberList(data) {
  let url = `${mixin.data().winvBaseUrl}app-direct-pick/query-parent-cid-all-serial`;
  return request.get(url, data);
}

// 查询直接入库推荐规则
export function getRuleSuggest(data) {
  let url = `${mixin.data().winvBaseUrl}app-direct-pick/rule-suggest`;
  return request.post(url, data);
}

