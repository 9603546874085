import axios from 'axios';
import { toast, storage, isEmpty, bridge } from '@/utils';
import hint from '@/utils/hint.js';
// import { getToken } from '@/request';
import $router from '@/router';
import Qs from 'qs';
import globalConfig from '../custom.config'; // 引入配置文件

axios.defaults.timeout = process.env.VUE_APP_TIMEOUT;

const noAuthAxios = axios.create({
  baseURL: globalConfig.baseUrl || process.env.VUE_APP_NO_AUTH_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const pendingRequest = new Map();
// 生成唯一的key
function generateReqKey(config) {
  const { method, url, params, data } = config;
  return [method, url, Qs.stringify(params), Qs.stringify(data)].join('&');
}
// 添加pending状态下的接口
function addPendingRequest(config) {
  const requestKey = generateReqKey(config);
  config.cancelToken =
    config.cancelToken ||
    new axios.CancelToken((cancel) => {
      if (!pendingRequest.has(requestKey)) {
        pendingRequest.set(requestKey, cancel);
      }
    });
}
// 移除pending状态下的接口
function removePendingRequest(config) {
  const requestKey = generateReqKey(config);
  if (pendingRequest.has(requestKey)) {
    const cancel = pendingRequest.get(requestKey);
    cancel(requestKey);
    pendingRequest.delete(requestKey);
  }
}

const authedAxios = axios.create();
authedAxios.defaults.baseURL = globalConfig.baseUrl || process.env.VUE_APP_BASE_URL;
// 添加请求拦截器
authedAxios.interceptors.request.use((request) => {
  // 检查是否存在重复请求，若存在则取消已发的请求
  removePendingRequest(request);
  // 把当前请求添加到pendingRequest对象中
  addPendingRequest(request);
  // let res = checkSession();
  // 添加自定义请求头 - TODO
  const extraHeader = {};
  Object.keys(extraHeader).forEach((item) => {
    request.headers[item] = extraHeader[item];
  });
  let token = storage.get('user-token');
  request.headers['Authorization'] = token;
  // if (res.code === '-1' || res.code === '0') {
  //   // 没有token或者token失效，需要重新获取token
  //   console.log('未获取到token,先获取token');
  //   return noAuthHttp.login().then(() => {
  //     let token = storage.get('user-token');
  //     request.headers['Authorization'] = token;
  //     return request;
  //   });
  // }
  // let token = storage.get('user-token');
  // request.headers['Authorization'] = token;
  // console.log('token' + token);
  return request;
});
// 添加响应拦截器
authedAxios.interceptors.response.use(
  (response) => {
    // 从pendingRequest对象中移除请求
    removePendingRequest(response.config);
    // 只将请求结果的data字段返回
    let result = response.data;
    // console.log(`接口返回结果:`);
    // console.log(result);
    if (result && result.failed) {
      hint.showFailed(result.message, true);
      hint.hideLoading();
      return Promise.reject(result);
    }
    return Promise.resolve(result);
  },
  (err) => {
    // 从pendingRequest对象中移除请求
    removePendingRequest(err.config || {});
    // 发生网络错误后会走到这里
    if (err) {
      // console.log(err);
      if (err.response) {
        switch (err.response.status) {
          // token失效 重新获取
          case 401:
            if (storage.get('user-token')) {
              storage.remove('user-token');
            }
            // if (process.env.VUE_APP_BUILD === 'dev') {
            //   // 如果是开发环境自动登录
            //   console.log('接口401没有权限');
            //   getToken(loginData).then((res) => {
            //     res.access_token && storage.set('user-token', res.access_token);
            //     window.location.reload();
            //   });
            // } else {
            //   console.log('接口401没有权限');
            //   $router.push('/login');
            //   bridge.logout();
            // }
            console.log('接口401没有权限');
            $router.push('/login');
            bridge.logout();
            break;
          case 302:
            if (storage.get('user-token')) {
              storage.remove('user-token');
            }
            break;
          default:
            toast.show(err.message);
        }
      }
      hint.hideLoading();
      return Promise.reject(err);
    }
  }
);

noAuthAxios.interceptors.request.use((request) => {
  // 添加自定义请求头 - TODO
  const extraHeader = {};
  Object.keys(extraHeader).forEach((item) => {
    request.headers[item] = extraHeader[item];
  });
  request.url = noAuthAxios.defaults.baseURL + request.url;
  console.log(request);
  return request;
});

// 添加响应拦截器
noAuthAxios.interceptors.response.use(
  (response) => {
    // 只将请求结果的data字段返回
    let result = response.data;
    if (
      result.success ||
      result.token_type === 'bearer' ||
      (response.config.url && response.config.url.indexOf('logout') !== -1 && response.status === 200)
    ) {
      console.log('成功');
      return Promise.resolve(result);
    }
    toast.show(result.message);
    return Promise.reject(result);
  },
  (err) => {
    // 发生网络错误后会走到这里
    if (err) {
      if (err.response && err.response.data && err.response.data.message) {
        toast.show(err.response.data.message || err.message);
      }
      return Promise.reject(err);
    }
  }
);

/**
 * 查询当前session有效
 * resolve 0 没有登录,-1 登录失效,1 登录有效
 * */
const checkSession = () => {
  let token = storage.get('user-token');
  if (isEmpty(token)) {
    return {
      code: '0',
    };
  }
  let tokenExpireTimeRemaining = new Date() - new Date(token.requestTime);
  if (tokenExpireTimeRemaining > 23 * 60 * 60 * 1000) {
    // token已使用时间大于23个小时后，准备开始重新获取token
    return {
      code: '-1',
    };
  }
  return {
    code: '1',
  };
};

export { noAuthAxios, authedAxios, checkSession };
