export default {
  home: '首页',
  application: '应用',
  statistics: '统计',
  mine: '我的',
  title: {
    labelPrint: '标签打印',
    expressBackTypeName: '快递公司',
    isShowPrintTag: '是否打印签收标签',
    workManagement: '工作管理',
    analyse: '复核',
    allreceive: '整单接收',
    warehouseManagement: '仓储管理系统',
    expressReceipts: '快递签收',
    switchTenants: '切换租户',
    switchLanguage: '切换语言',
    outboundShipment: '出库发运',
    isShowEn: '是否显示英文',
    accept: '按单收货',
    acceptConfirm: '收货明细确认', // 按单收货确认
    acceptDetail: ' 入库单收货详情', // 单据收货明细
    associatedSite: '关联播种位',
    batchInventory: '批次库存',
    batchQuery: '批次查询',
    batchConversion: '批次转换',
    batchDecomposition: '批次分解',
    batchSerialDetail: '批次查询展示',
    serialNumber: '序列号明细',
    bulkStockTransfer: '库存转移',
    cancelPallet: '撤销码垛',
    pallet: '码垛',
    cidDetail: '托盘详情',
    count: '盘点',
    countDetail: '盘点明细',
    directInBound: '直接入库',
    quickInBound: '快速入库',
    directOut: '直接拣货',
    outboundDelivery: '出库交接',
    revokeDelivery: '撤销交接',
    inventoryCheck: '库存查询',
    interceptTally: '拦截理货',
    subinventoryTransfer: '子库存转移',
    inSundry: '杂项入库',
    outSundry: '杂项出库',
    warehouseStock: '仓库库存',
    whareaStock: '库区库存',
    locationStock: '库位库存',
    detailStock: '明细库存',
    inventoryTransfer: '整托库存转移',
    importedDetail: '已录明细',
    ipsBatch: '创建批次',
    ipsSerial: '序列号录入',
    locationInventory: '库位库存',
    palletsTask: '托盘上架任务',
    rackingTasks: '上架明细',
    ReceivingAdjustment: '收货调整',
    searchTray: '托盘查询',
    shelvesLoad: '上架入库',
    skuCode: '物料编码',
    taskQuery: '任务查询',
    trayDetail: '托盘明细',
    taskDetail: '任务明细',
    trayDetailShow: '托盘明细展示',
    trayInfoShow: '托盘信息展示',
    unloading: '卸货',
    taskExecution: '任务执行',
    singleProcessing: '加工单处理',
    secondarySorting: '二次分拣',
    waveNum: '波次单号',
    wavePickingCart: '拣货小车',
    nodata: '暂无数据',
    directlyShelves: '直接上架',
    receiveDetail: '接收详情',
    shelvesDetail: '上架详情',
    ipsNewSerial: '序列号明细',
    detail: '详情',
    acceptCidDetail: '托盘明细',
    loading: '装入',
    receiveAdjustment: '收货调整',
    multiLpnPack: '多层码垛/撤销',
    code: '编码',
    name: '名称',
    batchMarge: '批次合并',
    cancelPick: '撤销挑库',
    warehouseCode: '仓库编码',
    warehouseName: '仓库名称',
    batchList: '批次明细',
    skuPropertyUpdate: '物料属性更新',
    roundsTransform: '物料转换',
    serialChange: '序列号转换',
  },
  label: {
    locationTran: '整库位转换',
    transQty: '转换数量',
    toSku: '目标物料',
    sourceBatchs: '来源批次',
    sourceSku: '来源物料',
    fromWhareaName: '库区名称',
    fromWhareaCode: '库区编码',
    accumulateQty: '累加盘点',
    packageQty: '包装数量',
    casesNum: '箱数',
    tailBoxNum: '尾箱数量',
    thisInventoryQty: '本次盘点数量',
    haveInventoryQty: '已盘点数量',
    finalCountQty: '最终盘点数量',
    printer: '打印机',
    printMould: '打印模板',
    operation: '操作',
    snapshotQty: '快照数量',
    province: '退货省份',
    city: '退货城市',
    productCode: '商品条码',
    jobType: '作业类型',
    pieceQty: '退回件数',
    expressQty: '数量',
    planTheNumberOf: '计划数量',
    estimatedDeliveryDate: '预计配送日期',
    lotOrBoxCode: '批次/箱码',
    serialNumberOrBoxCode: '序列号/箱码',
    boxCode: '箱码',
    nameOfTheMaterial: '物料名称',
    remark: '备注',
    unpickedQuantity: '未拣货数量',
    totalNumberOfMaterialsCollected: '已拣物料总数',
    waveOrderTotalNumberOfMaterials: '波次单物料总数',
    numberOfMaterialTypes: '物料种类数',
    totalNumberOfOrders: '订单总数',
    pickingReservoir: '拣货库区',
    batchCodeNew: '批次编码',
    scanningValue: '扫描值',
    noQuantityChecked: '未复核数',
    quantityChecked: '已复核数',
    orderNumberNew: '订单数量',
    packagingMaterials: '包材',
    courierNumber: '箱号/快递单号',
    expressBackCode: '快递单号',
    expressBackTypeName: '快递公司',
    checkTheDimensionOrderNumber: '复核维度单号',
    packageNo: '包裹号',
    documentsNote: '单据备注',
    totalNumber: '总数量',
    totalScans: '总扫描数量',
    scannedQuantity: '已扫描数量',
    barCode: '条码',
    batchconfirmation: '批次确认',
    processedCcapacity: '已处理量',
    roleName: '角色',
    tenantName: '租户',
    userName: '用户',
    password: '密码',
    quantityToBeTreated: '待处理量',
    processedCapacity: '已处理量',
    totalFreightVolume: '总发运量',
    availableQty: '现有量',
    enteredQty: '已录量',
    batch: '批次',
    batchControl: '批次控制',
    batchSerial: '批次',
    battcStatus: '批次状态',
    billCode: '入库单号',
    billId: '单号',
    billType: '单据类型',
    belongOrg: '所属组织',
    belongWarehouse: '所属仓库',
    belongWharea: '所属库区',
    areaType: '库区类型',
    locationType: '库位类型',
    canOutQty: '可出库量',
    confirmBatchCode: '确认批次',
    confirmDischarge: '卸货确认',
    confirmLocation: '确认库位',
    confirmNum: '确认数量',
    confirmSku: '确认物料',
    confirmWharea: '确认库区',
    conformTray: '确认托盘',
    confirmParentTray: '确认父托盘',
    countLocation: '库位',
    countLocationCode: '库位编码',
    countPrecisionMeaning: '盘点精度',
    countType: '盘点方式',
    countQty: '盘点数量',
    countTypeMeaning: '盘点类型',
    customer: '客户',
    deliveryCode: '单据编号',
    dischargeNum: '本次卸货数量',
    detail: '明细',
    demandQty: '需求量',
    unfold: '展开',
    exceptionReason: '例外原因',
    sundryReason: '原因',
    expireDate: '有效日期',
    fixReason: '修正原因',
    instructions: '可输入下列分类编码值依次搜索：',
    goodsName: '品名',
    goodsStatus: '物料状态',
    category: '物料类别',
    GRN: '入库单',
    billInfo: '入库单-行号',
    inQty: '已入库数量',
    inventoryCode: '盘点单号',
    inventoryQty: '入库数量',
    lineNum: '行号',
    detailLineNum: '行号',
    receiveWarehouse: '收货仓库',
    location: '收货库位',
    locationName: '库位名称',
    locationUseQty: '库位可用量',
    identification: '库位锁',
    nextCheckDate: '下次质检日期',
    outQty: '出库数量',
    pendingInQty: '待入库数量',
    pickQty: '拣货数量',
    pickLocation: '拣货库位',
    pigeonhole: '分拣台',
    planQty: '需求数量',
    unreceiveQty: '未收数量',
    planSumQty: '需求总量',
    plantQty: '托盘数量',
    produceDate: '生产日期',
    putAwayTray: '上架托盘',
    putAwayParentTray: '上架父托盘',
    qtyPlan: '拣货数量',
    qtyWillPlan: '待拣货数量',
    quantity: '数量',
    queryConditions: '查询条件',
    queryType: '查询类型',
    receiveNum: '收货数量',
    receivedQty: '已收数量',
    receiveQty: '已收数量',
    receiveSumQty: '已收货总量',
    receivingTray: '收货托盘',
    replaceReason: '替换原因',
    resourceNum: '来源单号',
    revisionNumber: '修正数量',
    serialNumber: '序列号',
    serialControl: '序列号控制',
    sourceSerialNumber: '来源序列号',
    targetSerialNumber: '目标序列号',
    shelvesNum: '上架数量',
    sku: '物料',
    skuCode: '物料编码',
    sourceLocation: '来源库位',
    sourceTray: '来源托盘',
    sourceWharea: '来源库区',
    sourceOfWharea: '起始库区',
    sourceBatch: '原始批次',
    splitUnloading: '拆分卸货',
    split: '拆分',
    startOfLocation: '起始库位',
    startOfTray: '起始托盘',
    startOfParentTray: '起始父托盘',
    status: '状态',
    storageWarehouse: '入库仓库',
    storageLocation: '入库库位',
    storageTray: '入库托盘',
    stockQuery: '搜索条件',
    suggestedBatch: '建议批次',
    suggestedWhareaCode: '建议库区',
    suggestedLocation: '建议库位',
    suggestedCidCode: '建议托盘',
    targetBatch: '目标批次',
    targetLocation: '目标库位',
    targetTray: '目标托盘',
    targetParentTray: '目标父托盘',
    transferFrom: '转移从',
    transferTo: '转移至',
    targetWharea: '目标库区',
    taskCode: '任务单号',
    taskNum: '任务数量',
    taskNumber: '任务编号',
    theScanQty: '本次扫描数量',
    tips: '提示',
    toCidCode: '转运托盘',
    toBeEnterQty: '待录量',
    totalTaskNum: '任务总数量',
    transferNumber: '转移数量',
    tray: '托盘',
    parentTray: '父托盘',
    trayName: '托盘名称',
    trayStatus: '托盘状态',
    trayAvailability: '托盘可用量',
    trayCurrentQty: '托盘现有量',
    trayState: '托盘状态',
    unit: '单位',
    unReceiveQty: '待收数量',
    unReceiveSumQty: '待收货总量',
    useQty: '可用量',
    carrier: '承运商',
    vendor: '供应商',
    vendorCode: '供应商代码',
    vendorNumber: '供应商批号',
    warehouse: '仓库',
    whareaCode: '库区',
    whareaName: '库区名称',
    workStation: '工号',
    batchCode: '批次',
    note: '备注',
    org: '组织',
    orgName: '组织名称',
    ownerName: '货主名称',
    ownerCode: '货主',
    qualityStatus: '质量状态',
    supplierCode: '供应商编码',
    totalNum: '总数',
    fromWarehouse: '来源仓库',
    toWarehouse: '目标仓库',
    processingNum: '加工单号',
    waveNum: '波次单号',
    wavePickingCart: '拣货小车',
    itemCode: '物料编码',
    outboundOrder: '出库单号-行号',
    outboundOrderR: '出库单号',
    sowLocation: '播种位',
    pickNum: '执行数量',
    waitSortNum: '待分拣数量',
    processQtyUpdate: '执行数量修改',
    linenumSku: '行号/物料',
    processingType: '加工类型',
    assemblyArea: '装配库区',
    assemblyLocation: '装配库位',
    parentSku: '父级物料',
    currentUseQty: '可用量',
    toBeProcessed: '待加工',
    processingQty: '加工数量',
    childSku: '子级物料',
    fittingsNum: '配件数量',
    shelvesCid: '托盘',
    shelvesParentCid: '父托盘',
    shelvesGoods: '物料',
    shelvesBillNum: '来源单号',
    shelvesInboundNum: '入库单号',
    shelvesReceiveNum: '接收号',
    shelvesWarehouse: '仓库',
    shelvesReceiveQty: '接收数量',
    shelvesVendor: '供应商',
    shelvesCustomer: '客户',
    shelvesBatch: '批次',
    shelvesQuantity: '数量',
    shelvesLineNum: '行号',
    shelvesUom: '单位',
    shelvesPendingQty: '待处理数量',
    inboundNum: '入库数量',
    inboundCid: '入库托盘',
    recommandLoca: '推荐库位',
    receiveCid: '接收托盘',
    shouldEntry: '应录入量',
    alreadyEntry: '已录入量',
    stayEntry: '待录入量',
    receiptNumber: '接收单号',
    uomName: '单位',
    uom: '单位',
    orderLine: '行号',
    locationCode: '库位',
    orderNumber: '单号',
    amount: '数量',
    defaultWarehouse: '默认仓库',
    chooseWarehouse: '选择仓库',
    cidMsg: '托盘信息',
    waitLoading: '待装入',
    alreadyLoading: '已装入',
    loadingMessage: '装入信息',
    reason: '调整原因',
    receiveAdjustQty: '调整后数量',
    reserveQty: '保留量',
    oldReceiveQty: '原接收数量',
    multiPack: '码垛',
    multiPackCancel: '撤销码垛',
    parentCid: '父级托盘',
    childCid: '子级托盘',
    sourceMessage: '来源信息',
    executionMessage: '执行信息',
    sourceTypeName: '类型',
    warehouseName: '仓库',
    cidCode: '托盘',
    validQty: '可用数量',
    qty: '库存数量',
    parentCidCode: '父托盘',
    inboundParentCid: '入库父托盘',
    adjust: '调整',
    cancelReceipt: '一键取消收货',
    measurementStandard: '测量标准',
    qtyInBox: '箱内数量',
    weight: '重量',
    length: '长度',
    width: '宽度',
    height: '高度',
    weightBox: '重量(箱)',
    lengthBox: '长度(箱)',
    widthBox: '宽度(箱)',
    heightBox: '高度(箱)',
    weightUnit: '单位',
    lengthUnit: '单位',
    widthUnit: '单位',
    heightUnit: '单位',
  },
  button: {
    configuration: '配置',
    more: '更多',
    haveToDealWith: '已处理',
    taskToBeProcessed: '待处理',
    changeTheLocation: '更换库位',
    confirmThePicking: '确认拣货',
    checkTheDetails: '查看明细',
    closeBtn: '关闭',
    inCasesOf: '换箱',
    skuItemNo: '物料编号',
    shipConfirm: '发运确认',
    detailedShipping: '明细发运',
    batchshipment: '批量发运',
    login: '登录',
    logout: '退出登录',
    back: '返回',
    batches: '分托',
    batchInventory: '批次库存',
    cancel: '取消',
    save: '保存',
    cidDetail: '托盘详情',
    confirm: '确认',
    confirmRevoke: '撤销挑库',
    conversion: '转换',
    decomposition: '分解',
    conversionConfirm: '转换确认',
    decompositionConfirm: '分解确认',
    edit: '编辑',
    delete: '删除',
    detail: '详情',
    next: '下一个',
    importedDetail: '已录明细',
    entireTray: '整托',
    locationInventory: '库位库存',
    overTask: '完结任务',
    pickAndUnload: '挑库并卸货',
    pickConfirm: '挑库确认',
    search: '查询',
    query: '搜索',
    serialNumEntry: '序列号录入',
    serialDetail: '序列号明细',
    skipTask: '跳过任务',
    submit: '提交',
    submitUnloading: '提交卸货',
    switchTask: '切换任务',
    taskDetail: '任务明细',
    theDetail: '明细',
    confirmSNDetail: '确认序列号明细',
    sortConfirm: '分拣确认',
    associatedConfirm: '关联确认',
    processComplete: '加工完成',
    receiveDetail: '接收详情',
    serialNumber: '序列号明细',
    prePackCidDetail: '预打包托盘明细',
    serialEntry: '录入',
    selectTrayDetail: '选择托盘转移明细',
    clear: '清空',
    allCheck: '全选',
    locationCode: '库位',
    seriesDetail: '序列号明细',
    loadingCidCode: '装入托盘',
    buttonPack: '码垛',
    cancellation: '散货撤销',
    allCancellation: '整托撤销',
    allBreakUp: '一键打散',
    marge: '合并',
    margeConfirm: '合并确认',
    backTop: '回到顶部',
    cancelReceipt: '一键取消收货',
  },
  message: {
    analyseItemEave: '按波次单号复核',
    analyseItemOrder: '按订单号复核',
    analyseItemTracking: '按快递单号复核',
    scanTheDetail: '扫描明细',
    recommendedNumber: '建议数量',
    recommendedSeedingPosition: '建议播种位',
    checkBatchCode: '该批次不存在，是否创建批次',
    checkBatchSame: '请确认建议批次和确认批次一致',
    checkRequired: '有必输字段未填！',
    checkSkuSame: '请确认物料和确认物料一致',
    checkTask: '所选任务的目标库位不唯一且该托盘不支持库位替换，请检查',
    checkTray: '该托盘不存在,是否创建托盘?',
    checkTrayNotAll: '托盘存在保留，不允许整托转移',
    chooseOneAtLeast: '至少需要选择一条任务',
    cidNotNull: '上架托盘不能为空',
    countBillCheck: '盘点单据不存在或单据状态不符，请复核',
    countGoodsCheck: '该库位物料已经盘点，是否复盘',
    countLocationCkeck: '库位不在当前单据仓库内，请复核',
    countPlantsCkeck: '该托盘已经存在于其它库存，请复核',
    createPlantSuccess: '创建托盘成功',
    enterAtLeastOne: '请输入至少一项查询条件',
    findNull: '查不到该{label}',
    findNullNormal: '查询不到数据，请检查后重新输入',
    findNullNormalWithTask: '该托盘不存在直接物料接收记录，不允许进行分托处理',
    inputSuccess: '输入成功，请继续输入',
    lastDataTips: '已经是最后一条数据',
    limitDischargeQty: '本次卸货数量不可超过任务总数量',
    notFoundTask: '未找到可处理任务',
    operationSuccess: '操作成功',
    operationFailed: '操作失败',
    outBillCheck: '单据不存在或者单据状态不为审核',
    qtyInPlant: '现有量仅存放在托盘上，请输入托盘',
    qtyMustBeGreaterThanZero: '数量必须大于等于0',
    allQtyMustBeGreaterThanZero: '汇总转移数量必须大于0',
    qtyMustBeSmallerThanValidQty: '转移数量不能大于可用量',
    qtyMustBeSmallerThanValidFromQty: '已录数量加上当前录入数量大于来源数量',
    FromQtyHaveBeenEntered: '来源数量已经全部录入，不需点击下一个，可直接进行分解确认。',
    FromQtyNotEqualEntered: '汇总已录数量与来源数量不相等，不能进行分解确认',
    qtyOverrunReEnterSerial: '转移数量不能大于可用量,请重新录入序列号',
    transferQtyMustEqualValidQty: '序列号数量与转移数量不相等',
    transferQtyNotZero: '汇总操作数量不能为0',
    reEnter: '与任务不符，请重新输入',
    serialAllSuccess: '序列号全部输入成功',
    serialRepeat: '序列号重复，请重新输入',
    skipTaskSuccess: '跳过任务成功',
    startCidNotNull: '起始托盘不能为空',
    taskSubmitSuccess: '任务完成！',
    toAndFromLocationNotSame: '来源库位和目标库位不能相同',
    transferSuccess: '转移成功',
    unknownError: '发生未知错误，请联系管理员',
    unloadingSuccess: '卸货成功',
    sortingSuccess: '分拣成功',
    wareSuccess: '入库成功',
    pullOnLoading: '上拉加载',
    noMoreData: '暂无更多数据',
    refreshSuccess: '刷新成功',
    noData: '暂无数据',
    mustChooseSerial: '请选择序列号',
    batchCreatedSuccess: '批次创建成功',
    dataDuplication: '序列号重复，请重新输入',
    mustEnter: '必输',
    queryCodeNotNull: '查询数据不能为空',
    selectReplaceReason: '请选择替换原因',
    notAllowDirectPick: '此单据参数不允许直接拣货',
    waveOrderNull: '该波次单号下查询不到物料',
    waveOrderNumCheck: '执行数量不可以大于待分拣数量',
    waveOrderMsgChoose: '请选择单据后再进行分拣',
    waveOrderNumCheckMin: '执行数量必须大于0',
    quantityMin: '数量必须大于0',
    workQtyWarnOne: '加工数量必须为大于0的数字',
    workQtyWarnTwo: '加工数量需小于等于剩余加工数量',
    lackMaterial: '物料{label}在装配库位可用量不足，无法进行此操作',
    checkSerialQty: '应录数量和已录数量不相等，确认离开么',
    repeatSerial: '该序列号已存在',
    deleteSerial: '该序列号已存在，将执行删除操作，确认执行么',
    allDeleteSerial: '确定要清空当前录入的序列号么',
    checkImpQty: '当前应录数量{label}与已录数量{labelTwo}不一致，请修改',
    receivedNumberHaved: '接收号已存在接收记录，是否继续在该接收号下添加接收记录',
    unSerialMsg: '入库数量不可以大于待处理数量',
    unBatchSerialMsg: '入库数量不可以大于该批次的待处理数量 ',
    showCheckQty: '应录数量已经等于已录数量，请执行删除操作后在进行录入',
    checkCidSerialNum: '接收托盘未完全入库，不允许使用接收托盘进行直接上架',
    checkInboudReceiveQty: '入库数量不能大于需求数量',
    checkDefinedInboundSerialReceiptList: '剩余接收数量等于预指定序列号数量,请优先输入预指定序列号',
    checkAlreadyHasAcceptRecord: '该接收号已存在接收记录，是否继续在该接收号下添加接收记录',
    autoCreatingReceiptNumber: '自动生成接收号中...',
    receiptNumberSuccess: '接收号生成成功',
    confirmDeletion: '是否确认删除？',
    checkAlreadyHasAcceptRecordInTray: '该托盘在其他接收号下存在接收记录，是否允许同一托盘混接收号接收',
    checkBackPath: '当前页面存在未保存的数据，如果返回将不会自动保存，是否确定返回？',
    countQtyCheck: '盘点数量不可等于0',
    checkParentCid: '此托盘已经存在于当前父托盘上，不能再次码垛',
    sortOnlyOne: '序列号控制物料的分拣数量仅能为1',
    checkSerialBatch: '当前序列号在其他来源批次中已录入，不可重复录入',
    limitScanQty: '拆分数量必需大于0',
    noAutoEnter: '必输字段不允许自动跳过',
    loading: '加载中...',
    qtyNeedLessAvailable: '输入数量应小于等于可用量',
    checkWarehouseIdNotSame: '所选库位不属于单据行指定仓库，请重新选择',
    checkQtyScanWithSerialNumber: '录入序列号明细汇总数量需等于扫描数量',
    thisIsParentTran: '输入托盘为父托盘，请注意将执行全部出库！',
    showCheckReceiveQty: '序列号录入数量小于扫描数量，点击确认时自动替换外层数量',
    restOfTask: '任务剩余数量',
    autoCancelStart: '将自动取消',
    restAutoCancel: '任务剩余数量将自动取消',
    requiredField: '必输字段',
    retrievalValueField: '默认值获取失败',
    checkAppConfig: '请检查App个性化配置',
    // checkParentWithCidCode: '父托盘所属库位XX与入库托盘所属库位XX不一致',
    checkParentWithCidCodeStr1: '父托盘所属库位',
    checkParentWithCidCodeStr2: '与入库托盘所属库位',
    checkParentWithCidCodeStr3: '不一致',
    scanBatchIsNotEqualBatch: '输入批次与单据指定批次不一致',
    outQtyNeedLessThanValidQty: '出库数量必须小于等于库存可用量',
    judgeCidReceiveNumber: '托盘存在其他接收行，不能进入整托处理',
    cidNoData: '托盘没有数据',
    parameterInformationIncomplete: '参数信息不完整',
  },
  exception: {
    noData: '无数据',
  },
};
