<template>
  <div class="card_box">
    <div class="card_title" v-if="Object.keys(titleInfo).length > 0">
      <span class="title_span" v-for="(val, index) in titleInfo" :key="index">
        {{ val }}
      </span>
    </div>
    <div class="card_content">
      <div class="content" v-for="(field, index) in reactField" :key="index">
        <span class="card_content_span">{{ reactFieldNameKeys.includes(field) ? $t(`label.${reactFieldName[field]}`) : $t(`label.${field}`) }}:</span>
        <span v-if="linkField.includes(field)" class="card_content_span_value value_link" @click="linkFieldEvent(item)">{{ item[field] || '无' }}</span>
        <span v-else class="card_content_span_value">{{ item[field] === 0 ? 0 : item[field] ? item[field] : $t('message.noData') }}</span>
      </div>
      <slot />
    </div>
    <div class="card_footer" v-if="Object.keys(footerInfo).length > 0">
      <span class="title_span" v-for="(val, index) in footerInfo" :key="index">
        {{ val }}
      </span>
    </div>
  </div>
</template>

<script>
  export default {
    name: "IpsPageCard",
    props: {
      titleInfo: {
        type: Object || undefined || null,
        // eslint-disable-next-line vue/require-valid-default-prop
        default: {},
      },
      footerInfo: {
        type: Object || undefined || null,
        // eslint-disable-next-line vue/require-valid-default-prop
        default: {},
      },
      item: {
        type: Object,
        // eslint-disable-next-line vue/require-valid-default-prop
        default: {},
      },
      reactField: { // 需要渲染对字段集
        type: Array,
        // eslint-disable-next-line vue/require-valid-default-prop
        default: () => [],
      },
      reactFieldName: { // 字段名称集(取值字段和字段名称特殊对应)
        type: Array,
        // eslint-disable-next-line vue/require-valid-default-prop
        default: () => [],
      },
      linkField: { // 需要特殊处理对字段集(添加链接功能)
        type: Array,
        // eslint-disable-next-line vue/require-valid-default-prop
        default: () => [],
      },
      linkFieldEvent: { // 链接事件
        type: Function,
        // eslint-disable-next-line vue/require-valid-default-prop
        default: function() {},
      },
    },
    data() {
      return {
        reactFieldNameKeys: [],
      }
    },
    mounted() {
      this.reactFieldNameKeys = Object.keys(this.reactFieldName) || [];
    },
  };
</script>

<style lang="stylus">
    .card_box {
        border-radius: 1.6vw;
        margin-top: 2.367vw;
        padding: 0.4vw 2.7vw;
        color: #000;
        box-shadow: 0 0 2.133vw #d9d9d9;
        border: 1px solid #d9d9d9;
        font-size: 14px;
    }

    .card_title {
        margin-top: 10px;
    }
    .card_footer {
        
        padding-left: 2.667vw;
        .title_span {
          display: inline-block;
          margin: 10px 0px;
        }
    }

    .title_span {
        margin-right: 10px;
    }

    .content {
        width: 100%;
        display: inline-block;
        color: #8C8C8C;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        line-height: 22px;
        margin-top: 10px !important;
    }

    .card_content_span {
      min-width: 18.6vw;
      display: inline-block;
    }

    .card_content_span_value {
        color: #262626;
        margin-left: 1vw;
        -font-weight: bold;
    }
</style>
