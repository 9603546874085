
<template>
  <div class="text-a">
    <ips-header title="测试b" :is-index="false" />
    <input type="text" placeholder="输入框1" v-model="a">
    <input type="text" placeholder="输入框2" v-model="input.b">
    <input type="text" placeholder="输入框3" v-model="input.c">
    <input type="text" placeholder="输入框4" v-model="input.d">
    <button @click="go">
      前往c页面
    </button>
  </div>
</template>

<script>
export default {
  name: "Textb",
  data() {
    return {
      input: {
      },
      a: '111',
    };
  },
  methods: {
    go() {
      const param = {
        name: "text-c"
      };
      this.$store.commit("setKeepAlives", ["textb"])
      this.$router.pushPage(param);
    }
  },
  async beforeRouteLeave(to, from, next) {
    console.log(this.a)
    next()
  }

};
</script>

<style lang="stylus" scoped>
</style>