<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.count')" :is-index="true" />
    </div>
    <div class="ips-container">
      <!--盘点单号-->
      <ips-lov
        :label="$t('label.inventoryCode')"
        :title="$t('label.inventoryCode')"
        required
        v-model="inputData.billCode"
        v-show="customData['billCode'] ? hideControl['billCode']: true"
        params="billCode"
        display="billCode"
        :url="`${winvBaseUrl}count-orders?countStatusList=PROCESSING&countStatusList=APPROVED`"
        :pre-parse-url="urlData['billCode']"
        :custom="customData['billCode']"
        :source-parse-field="sourceParseField"
        ref="ref0"
        @click.stop="handleCommonClick(0)"
        @enter="skipNext('submitRef')"
        @down="skipNext('submitRef')"
        @choose="handleChoose($event)"
        @clickIcon="handleCommonClick(0, 'lov')"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <div class="out_box">
        <!-- 仓库 -->
        <ips-value-cell
          :title="$t('label.warehouse')"
          image-flag
          :value="orderData.warehouseName"
        />
        <!-- 货主 -->
        <ips-value-cell
          :title="$t('label.ownerCode')"
          image-flag
          :value="orderData.ownerName"
        />
        <!--盘点类型-->
        <ips-value-cell
          :title="$t('label.countTypeMeaning')"
          image-flag
          :value="orderData.countTypeMeaning"
        />
        <!--盘点精度-->
        <ips-value-cell
          :title="$t('label.countPrecisionMeaning')"
          image-flag
          :value="orderData.countPrecisionMeaning"
        />
        <!-- 盘点方式 -->
        <ips-value-cell
          :title="$t('label.countType')"
          image-flag
          :value="orderData.countModeMeaning"
        />
        <!--状态-->
        <ips-value-cell
          :title="$t('label.status')"
          image-flag
          :value="orderData.countStatusMeaning"
        />
      </div>
      <div class="ips-footer">
        <hips-button size="large" @click.stop="goDetail" ref="submitRef">
          <svg-icon slot="icon" icon-class="success" />
          {{ $t('button.confirm') }}
        </hips-button>
      </div>
    </div>
  </hips-view>
</template>

<script>
import { Cell } from '@hips/vue-ui';
import { storage } from '@/utils';
export default {
  name: 'StockCount',
  data() {
    return {
      inputData: {
        billCode: '',
      },
      orderData: {}, //盘点单号数据
      urlData: {},
      customData: {}, // 个性化配置信息列表
      hideControl: {}, // 个性化配置显示隐藏控制
      sourceParseField: {}, // 界面中所有的来源解析字段
    };
  },

  components: {
    [Cell.name]: Cell,
  },

  methods: {
    /**
     * 选择盘点单号
     * @param {object} item - 选中的数据
     */
    handleChoose(item) {
      this.orderData = item;
    },
    /**
     *@functionName: saveSourceField
     *@description: 保存来源解析字段并赋值Enter
     *@params1: fieldObj[Object] 来源解析字段对象,
     fieldCode[String] 来源解析字段
     parseValue[String] 二维码解析值
     subFields[Array]] 关联子字段
     */
    saveSourceField(fieldObj) {
      this.saveSourceParseField(this, fieldObj);
    },
    /**
     * 字段聚焦时赋值操作
     */
    async valuedByFoucs(options={}) {
      const { custom ={} , currentValue } = options;
      await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
    },
    /**
     * 前往盘点明细界面
     */
    goDetail() {
      let param = {
        name: 'countConfirm',
        params: {
          billCode: this.inputData.billCode,
          fatherProps: this.orderData,
          sourceParseField: {...this.sourceParseField},
          customData: this.customData,
          hideControl: this.hideControl,
        },
      };
      const { countStatus } = this.orderData;
      if (countStatus === 'APPROVED' || countStatus === 'PROCESSING') {
        this.$router.pushPage(param);
      } else {
        this.$hint.showFailed(this.$i18n.t('message.countBillCheck'));
      }
    },
  },
  created() {
    const { customObj, hideControl } = this.handleCustomData(storage.get('customData'));
    this.customData = customObj;
    this.hideControl = hideControl;
    // console.log('盘点customData', Object.assign({}, this.customData));
  },
  async mounted() {
    this.inputFieldList= [
      { type: 'lov', required: true, ref: 0, bind: 'inputData.billCode', field: 'billCode' },
    ];
    this.urlData = this.handleData('STOCK_COUNT_QUERY');
    this.skipNext(0);
  },
};
</script>

<style lang="stylus" scoped>
 @import '~@/style/var.styl'
.out_box {
  border-radius: 1.6vw;
  margin-top: 4.267vw;
  width: 100%;
  padding: 2.7vw 0px;
  color: #000;
  font-size: 3.467vw;
  box-shadow: 0 0 2.133vw #d9d9d9;
}

</style>
