var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "hips-view",
    [
      _c("ips-header", {
        attrs: { title: _vm.$t("title.ipsSerial"), "is-index": false },
        on: { beforeBack: _vm.saveVerify },
      }),
      _c(
        "div",
        { staticClass: "ips-container" },
        [
          _c(
            "hips-row",
            [
              _c(
                "hips-col",
                { attrs: { span: "18" } },
                [
                  _c("ips-field", {
                    ref: "ref0",
                    attrs: {
                      "is-only": "",
                      "is-index": false,
                      label: _vm.$t("label.serialNumber"),
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.handleCommonClick(0)
                      },
                      enter: _vm.checkSerialNum,
                      down: _vm.checkSerialNum,
                    },
                    model: {
                      value: _vm.serialNum,
                      callback: function ($$v) {
                        _vm.serialNum = $$v
                      },
                      expression: "serialNum",
                    },
                  }),
                ],
                1
              ),
              _c(
                "hips-col",
                { attrs: { span: "6" } },
                [
                  _c(
                    "hips-button",
                    {
                      ref: "inputRef",
                      staticClass: "input-button",
                      attrs: { size: "large" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.checkSerialNum.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.serialEntry")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "center_area" }, [
            _c("div", { staticClass: "entryQty-box" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("label.alreadyEntry")) +
                  ":  " +
                  _vm._s(_vm.totalSelectedNum) +
                  "\n      "
              ),
            ]),
          ]),
          _c("div", { staticClass: "ips-table" }, [
            _c(
              "table",
              [
                _c(
                  "tr",
                  _vm._l(_vm.tableTitle, function (item, index) {
                    return _c(
                      "th",
                      {
                        key: index,
                        staticClass: "ips-table-thead",
                        attrs: { scope: "col" },
                      },
                      [
                        _vm._v(
                          "\n            " + _vm._s(item.name) + "\n          "
                        ),
                      ]
                    )
                  }),
                  0
                ),
                _vm._l(_vm.tableData, function (item, index) {
                  return _c("tr", { key: index }, [
                    _c("td", [_vm._v(_vm._s(item.serialNumber))]),
                    _c("td", [_vm._v(_vm._s(item.qualityStatusName))]),
                    _c("td", [_vm._v(_vm._s(item.serialQty || item.qty))]),
                  ])
                }),
              ],
              2
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "ips-footer",
          staticStyle: { position: "fixed", bottom: "0px" },
        },
        [
          _c(
            "hips-button",
            {
              ref: "checkRef",
              staticClass: "ips-new-serial-button-clear-style",
              attrs: { size: "large" },
              on: { click: _vm.clearAll },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("button.clear")) + "\n    ")]
          ),
          _c(
            "hips-button",
            {
              ref: "submitRef",
              staticClass: "ips-new-serial-button-confirm-style",
              attrs: { size: "large" },
              on: { click: _vm.handleButtonSubmit },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("button.confirm")) + "\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }