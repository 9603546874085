<template>
  <div :class="['page_box', {'page_box_task_comfirm': pageBoxTaskComfirmClass}]">
    <div class="page_head_title">
      <span class="title_span" v-for="(val, index) in titleInfo" :key="index">
        {{ val }}
      </span>
    </div>
    <div class="page_head_content">
      <div v-if="reactField.length > 0">
        <div class="content" v-for="(key, index) in reactField" :key="index">
          {{ $t(`label.${key}`) }}: <span class="content_span_value">{{ contentInfo[key] }}</span>
        </div>
      </div>
      <div v-if="reactField.length === 0">
        <div class="content" v-for="(val, key, index) in contentInfo" :key="index">
          {{ $t(`label.${key}`) }}: <span class="content_span_value">{{ val }}</span>
        </div>
      </div>
      <div class="content message-content" slot="right" v-if="footerMessage">
        {{ footerMessage }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "IpsPageHead",
    props: {
      titleInfo: {
        type: Object,
        // eslint-disable-next-line vue/require-valid-default-prop
        default: () => {},
      },
      // 内容字段
      contentInfo: {
        type: Object,
        // eslint-disable-next-line vue/require-valid-default-prop
        default: () => {},
      },
      // 定制渲染部分字段
      reactField: {
        type: Array,
        // eslint-disable-next-line vue/require-valid-default-prop
        default: [],
      },
      // 底部纯显示信息
      // eslint-disable-next-line vue/require-default-prop
      footerMessage: null,
      // 样式类名
      pageBoxTaskComfirmClass: {
        type: String,
        // eslint-disable-next-line vue/require-valid-default-prop
        default: '',
      },
    },
    methods: {},
    created() {
      console.log('reactFields', this.reactField, 'contentInfo', this.contentInfo);
    },
  };
</script>

<style lang="stylus">
    .page_box {
        /* padding: 0 6px; */
        /* position: fixed;
        top: 13.333vw; */
        /* width: 100%; */
        font-size: 12px;
        /* z-index: 999; */
        margin: 0 2.667vw;
    }

    .page_head_title {
        /* margin-top: 15px; */
    }

    .title_span {
        margin-right: 0px;
    }

    .page_head_content {
      /* padding-bottom: 16px; */
    }

    .content {
        width: 100%;
        display: inline-block;
        margin-top: 8px;
        color: #8C8C8C;
        margin-left: 10px;
    }
    .message-content {
      width: 92%;
    }
    .content_span_value {
        color: #262626
    }
    .page_box_task_comfirm {
      margin: 0 2.667vw;
      background-color: #fff;
      border-bottom: 1px solid #eaeaea;
    }
</style>
