import request from '@/request';
import mixin from '@/mixin/mixin';

// 快递单号
export function checkExpressCode(data) {
  let url = `${mixin.data().winvBaseUrl}app-express-receipts/checkExpressCode`;
  return request.get(url, data);
}

// 商品条码
export function checkSkuCode(data) {
  let url = `${mixin.data().winvBaseUrl}app-express-receipts/checkSkuCode`;
  return request.get(url, data);
}

// 快递公司
export function getExpressType(data) {
  let url = `${mixin.data().winvBaseUrl}app-express-receipts/express-type-lov`;
  return request.get(url, data);
}

// 查询地区
export function getRegion(data) {
  let url = `${mixin.data().winvBaseUrl}app-express-receipts/get-region-lov`;
  return request.get(url, data);
}

// 保存提交
export function saveExpressReceipts(data) {
  let url = `${mixin.data().winvBaseUrl}app-express-receipts/saveExpressReceipts`;
  return request.post(url, data);
}
