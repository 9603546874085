var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ipsPicture" },
    [
      _c(
        "hips-row",
        [
          _vm._l(_vm.imageList, function (item, index) {
            return _c(
              "hips-col",
              { key: index, staticClass: "position-r", attrs: { span: 6 } },
              [
                _c("img", {
                  staticClass: "img-item",
                  attrs: { src: item.src, alt: "No Picture" },
                  on: {
                    click: function ($event) {
                      return _vm.previewImg(index)
                    },
                  },
                }),
                _c("img", {
                  staticClass: "img-del",
                  attrs: { src: require("@assets/del.png"), alt: "No Picture" },
                  on: {
                    click: function ($event) {
                      return _vm.deleteImg(index)
                    },
                  },
                }),
              ]
            )
          }),
          _c("hips-col", { attrs: { span: 6 } }, [
            _c("img", {
              staticClass: "img-item",
              attrs: { src: require("@assets/add.png"), alt: "No Picture" },
              on: { click: _vm.choiceMethods },
            }),
          ]),
        ],
        2
      ),
      _c("hips-actionsheet", {
        attrs: { buttons: _vm.buttons },
        model: {
          value: _vm.showAction,
          callback: function ($$v) {
            _vm.showAction = $$v
          },
          expression: "showAction",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }