// import Login from '../views/login'
import StockTransfer from '../views/stock-transfer'

let route = [
  {
    path: '/stock-transfer',
    name: 'StockTransfer',
    component: StockTransfer,
  },
]

export default route