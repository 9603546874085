var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ips-lov ips-input-common" },
    [
      _c(
        "ips-input",
        _vm._b(
          {
            ref: "input",
            attrs: {
              value: _vm.currentValue,
              disabled: _vm.disabled,
              readonly: _vm.readonly,
              required: _vm.required,
              "is-error": _vm.getErrorFlag,
              "error-msg": _vm.getErrorMsg,
              lov: "",
            },
            on: {
              blur: _vm.handleBlur,
              focus: _vm.handleFocus,
              click: _vm.handleClick,
              input: _vm.handleInput,
              change: _vm.handleInputChange,
              enter: _vm.handleEnter,
              down: _vm.handleDown,
              tab: _vm.handleTab,
            },
          },
          "ips-input",
          _vm.$attrs,
          false
        ),
        [
          _c(
            "div",
            {
              attrs: { slot: "append" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleIconClick.apply(null, arguments)
                },
              },
              slot: "append",
            },
            [_c("hips-icon", { attrs: { name: "search", size: "18px" } })],
            1
          ),
        ]
      ),
      _c(
        "hips-popup",
        {
          staticClass: "ips-popup",
          attrs: {
            position: "bottom",
            "overlay-on-click-close": _vm.overlayOnClickClose,
            "z-index": _vm.zIndex,
            "lock-scroll": _vm.lockScroll,
            "safe-area": _vm.safeArea,
          },
          on: { "click-overlay": _vm.handleClickOverlay },
          model: {
            value: _vm.showPopup,
            callback: function ($$v) {
              _vm.showPopup = $$v
            },
            expression: "showPopup",
          },
        },
        [
          _c(
            "div",
            { staticClass: "ips-popup__title" },
            _vm._l(_vm.getColumns.initList, function (item, index) {
              return _c("div", { key: index, staticClass: "title-item" }, [
                _vm._v("\n        " + _vm._s(item.title) + "\n      "),
              ])
            }),
            0
          ),
          _c(
            "div",
            {
              ref: "ips-content",
              staticClass: "ips-popup__content",
              attrs: { tabindex: "-1" },
            },
            [
              _c(
                "hips-scroll",
                {
                  ref: "scroll",
                  attrs: {
                    "scroll-events": ["scroll"],
                    data: [_vm.popupDataList],
                    "scroll-options": _vm.scrollOption,
                  },
                  on: {
                    scroll: _vm.scroll,
                    "pulling-down": _vm.loadRefresh,
                    "pulling-up": _vm.loadMore,
                  },
                },
                [
                  _c(
                    "ul",
                    { staticClass: "list-wrapper" },
                    [
                      _vm._l(_vm.popupDataList, function (item, index) {
                        return [
                          _c(
                            "li",
                            {
                              key: index,
                              ref: "scrollItem",
                              refInFor: true,
                              class: [
                                "scroll-item",
                                { active: index === _vm.activeListIndex },
                              ],
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.chooseOrder(item)
                                },
                              },
                            },
                            _vm._l(
                              _vm.getColumns.initList,
                              function (col, colIndex) {
                                return _c(
                                  "div",
                                  {
                                    key: colIndex,
                                    class:
                                      "content-item--" +
                                      [].concat(_vm.getColumns.initList).length,
                                  },
                                  [
                                    col.title === "详情"
                                      ? _c(
                                          "a",
                                          {
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.handleViewMoreInfo(
                                                  item,
                                                  col
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                !item._show ? "更多" : "收起"
                                              )
                                            ),
                                          ]
                                        )
                                      : [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(item[col.dataIndex]) +
                                              "\n                "
                                          ),
                                        ],
                                  ],
                                  2
                                )
                              }
                            ),
                            0
                          ),
                          _c(
                            "transition",
                            { key: index, attrs: { name: "fade" } },
                            [
                              item._show
                                ? _c(
                                    "div",
                                    { staticClass: "scroll-item__extra" },
                                    _vm._l(
                                      _vm.getColumns.restList,
                                      function (rest, rIndex) {
                                        return _c("ips-value-cell", {
                                          key: rIndex,
                                          attrs: {
                                            title: rest.title,
                                            value: item[rest.dataIndex],
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "hips-popup",
        {
          staticClass: "pre-check-popup",
          attrs: {
            "overlay-on-click-close": _vm.overlayOnClickClose,
            "lock-scroll": _vm.lockScroll,
            "safe-area": _vm.safeArea,
          },
          on: {
            "click-overlay": function () {
              return _vm.handleClickOverlay("pre-parse")
            },
          },
          model: {
            value: _vm.showPreChekPop,
            callback: function ($$v) {
              _vm.showPreChekPop = $$v
            },
            expression: "showPreChekPop",
          },
        },
        [
          _c(
            "div",
            { staticClass: "ips-popup__title" },
            _vm._l([].concat(_vm.preParseTitle), function (item, index) {
              return _c("div", { key: index, staticClass: "title-item" }, [
                _vm._v("\n        " + _vm._s(item) + "\n      "),
              ])
            }),
            0
          ),
          _c(
            "div",
            {
              ref: "ips-parse-content",
              staticClass: "ips-popup__content",
              attrs: { tabindex: "-1" },
            },
            [
              _c(
                "hips-scroll",
                {
                  ref: "parseScroll",
                  attrs: {
                    "scroll-events": ["scroll"],
                    data: [_vm.preChekPopList],
                  },
                  on: { scroll: _vm.scroll },
                },
                [
                  _c(
                    "ul",
                    { staticClass: "list-wrapper" },
                    _vm._l(_vm.preChekPopList, function (item, index) {
                      return _c(
                        "li",
                        {
                          key: index,
                          ref: "scrollItem",
                          refInFor: true,
                          class: [
                            "scroll-item",
                            { active: index === _vm.activeListIndex },
                          ],
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.chooseParseOrder(item)
                            },
                          },
                        },
                        _vm._l(
                          [].concat(_vm.preParams),
                          function (i, childIndex) {
                            return _c(
                              "div",
                              {
                                key: childIndex,
                                class:
                                  "content-item--" +
                                  [].concat(_vm.preParams).length,
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item[i]) +
                                    "\n            "
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      )
                    }),
                    0
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }