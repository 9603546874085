<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.cidDetail') + `-${loadCidCode}`" :is-index="false" />
    </div>
    <div class="ips-container">
      <div class="ips-table">
        <table>
          <tr style="background-color: #eee">
            <th v-for="(item, index) in tableTitle" :key="index" scope="col">
              {{ item.name }}
            </th>
          </tr>
          <tr v-for="(item, index) in cidDate" :key="index">
            <td>{{ item.billCode }}</td>
            <td v-if="item.isBatch || item.enableSerial"><a style="color: blue" @click="goGoodsDetail(item.sku,
                                                                                                      item.goodsId, item.billCode, item.billId, item.enableSerial, item.isBatch, item.isInvCtrl)"
            >{{ item.sku }}</a></td>
            <td v-if="!item.isBatch && !item.enableSerial">
              {{ item.sku }}
            </td>
            <td>{{ item.uomCode }}</td>
            <td>{{ item.qtyScan }}</td>
          </tr>
        </table>
      </div>
    </div>
    <!--<div class="ips-footer">-->
    <!--&lt;!&ndash;提交卸货&ndash;&gt;-->
    <!--<hips-button size="large" ref="submitRef">-->
    <!--<svg-icon slot="icon" icon-class="success" />-->
    <!--{{ $t('button.submitUnloading') }}-->
    <!--</hips-button>-->
    <!--</div>-->
  </hips-view> 
</template>

<script>
import { queryCidDetail } from '../api'
export default {
  name: 'CidDetail',
  data () {
    return {
      cidDate: [],
      loadCidId: '',
      loadCidCode: '',
      sourceType: '',
    }
  },
  methods: {
    // 获取托盘详情
    async getCidDetail(loadCidId, loadCidCode){
      const site = [this.SERIAL_INV_CTRL, this.SERIAL_OUT_CTRL, this.SERIAL_SEMI_INV_CTRL];
      const isInv = [this.SERIAL_INV_CTRL];
      try{
        this.$hint.showLoading();
        let param = {
          params: {
            loadCidId,
            loadCidCode,
          }
        };
      this.cidDate = await queryCidDetail(param);
        this.cidDate.forEach((item) => {
          item.isBatch = item.isbatch === 1 ? true : false;
          item.enableSerial = site.includes(item.serialType);
          item.isInvCtrl = isInv.includes(item.serialType);
      });
      } finally {
        this.$hint.hideLoading();
      }
    },
    // 前往物料明细
    goGoodsDetail(sku, goodsId, billCode, billId, enableSerial, isBatch, isInvCtrl){
      let param = {
        name: 'GoodsDetail',
        params: {
          sku,
          goodsId,
          billId,
          billCode,
          enableSerial,
          isBatch,
          isInvCtrl,
          loadCidCode: this.loadCidCode,
          sourceType: this.sourceType,
          loadCidId: this.loadCidId,
        },
      };
      this.$router.pushPage(param);
    }
  },
  created() {
    const {loadCidId, loadCidCode, sourceType} = this.$route.params;
    this.loadCidId = loadCidId;
    this.loadCidCode = loadCidCode;
    this.sourceType = sourceType;
    this.getCidDetail(loadCidId, loadCidCode);
    this.tableTitle = [
      {
        // 单据编号
        name: this.$t('label.deliveryCode')
      },
      {
        // 物料代码
        name: this.$t('label.skuCode')
      },
      {
        // 单位
        name: this.$t('label.unit')
      },
      {
        // 数量
        name: this.$t('label.quantity')
      }
    ];
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'GoodsDetail') {
      this.$store.commit('pushKeepAlives', 'CidDetail');
    } else if(to.name === 'Unpicking') {
      this.$store.commit('deleteKeepAlives', 'CidDetail');
    }
    this.$nextTick(() => {
      next();
    });
  },
}
</script>

<style lang="stylus" scoped>
 @import '~@/style/var.styl'
.ips-table {
  width 100%
  overflow-x auto
  table {
    width 100%
  }
  th, td {
    padding:8px 10px
    border 1px solid #ccc
    text-align center
    white-space nowrap
  }
}
.ips-child-title{
  width 100%
  height 10%
}

</style>





