import roundsTransform from '../views/index'


let route = [{
    path: '/roundsTransform',
    name: 'roundsTransform',
    component: roundsTransform,
  },
]

export default route