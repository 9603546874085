<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <!-- 批次转换 -->
      <ips-header :title="$t('title.batchConversion')" :is-index="true" />
    </div>
    <div class="ips-container">
      <!-- 原始批次 -->
      <ips-lov
        :label="$t('label.sourceBatch')"
        :title="$t('label.batchCode')"
        v-model="fromBatchCode"
        v-show="customData['fromBatchCode'] ? hideControl['fromBatchCode'] : true"
        required
        params="batchCode"
        display="batchCode"
        :url="`${wmdmBaseUrl}batchs/batch-code-lov`"
        :url-params="{ isbatchModify: 1, enabledFlag: 1 }"
        :custom="customData['fromBatchCode']"
        :source-parse-field="sourceParseField"
        ref="ref0"
        @enter="skipNext(10)"
        @down="skipNext(10)"
        @click.stop="handleCommonClick(0)"
        @clickIcon="handleCommonClick(0, 'lov')"
        @choose="handleChoose('fromBatchCode', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!--物料-->
      <ips-lov
        :label="$t('label.sku')"
        :title="$t('label.sku')"
        v-model="fromSku"
        v-show="customData['fromSku'] ? hideControl['fromSku'] : true"
        required
        params="sku"
        :url="`${wmdmBaseUrl}batchs/batch-modify-lov`"
        :url-params="{ batchCode: fromBatchCode, isbatchModify: 1 }"
        :custom="customData['fromSku']"
        :source-parse-field="sourceParseField"
        ref="ref10"
        @click.stop="handleCommonClick(10)"
        @clickIcon="handleCommonClick(10, 'lov')"
        @choose="handleChoose('fromSku', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 来源托盘 -->
      <ips-lov
        :label="$t('label.sourceTray')"
        :title="$t('label.sourceTray')"
        v-model="fromCidCode"
        v-show="customData['fromCidCode'] ? hideControl['fromCidCode'] : true"
        params="cidCode"
        :url="`${winvBaseUrl}app-batch-modify/original-cid-lov`"
        :url-params="fromCidParam"
        :custom="customData['fromCidCode']"
        :source-parse-field="sourceParseField"
        ref="ref20"
        @click.stop="handleCommonClick(20)"
        @clickIcon="handleCommonClick(20, 'lov')"
        @enter="skipNext(30)"
        @down="skipNext(30)"
        @choose="handleChoose('fromCidCode', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 来源库位 -->
      <ips-lov
        :label="$t('label.sourceLocation')"
        :title="$t('label.sourceLocation')"
        v-model="fromLocationCode"
        v-show="customData['fromLocationCode'] ? hideControl['fromLocationCode'] : true"
        required
        params="locationCode"
        :url="`${winvBaseUrl}app-batch-modify/original-location-lov`"
        :url-params="fromLocParam"
        :custom="customData['fromLocationCode']"
        :source-parse-field="sourceParseField"
        ref="ref30"
        @click.stop="handleCommonClick(30)"
        @clickIcon="handleCommonClick(30, 'lov')"
        @enter="handleLocEnter"
        @down="handleLocEnter"
        @choose="handleChoose('fromLocationCode', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 来源库区 -->
      <ips-lov
        :label="$t('label.sourceWharea')"
        :title="$t('label.sourceWharea')"
        v-model="fromWhareaCode"
        v-show="customData['fromWhareaCode'] ? hideControl['fromWhareaCode'] : true"
        required
        disabled
        :custom="customData['fromWhareaCode']"
        :source-parse-field="sourceParseField"
        ref="ref40"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />

      <!-- 转移数量 -->
      <ips-input-number
        :label="$t('label.amount')"
        v-model="fromTransferQty"
        v-show="!enableSerial ? (customData['fromTransferQty'] ? hideControl['fromTransferQty'] : !enableSerial) : false"
        required
        :custom="customData['fromTransferQty']"
        :source-parse-field="sourceParseField"
        ref="ref50"
        not-auto-fill
        :min="null"
        :more-than-zero="true"
        :enable-serial="serialType"
        @blur="checkNumBlur"
        @enter="skipNext('submitRef')"
        @down="skipNext('submitRef')"
        @tab="skipNext('submitRef')"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <hips-row v-if="enableSerial">
        <div class="hip-row">
          <hips-col span="21">
            <ips-input-number
              :label="$t('label.amount')"
              v-model="fromTransferQty"
              v-show="customData['fromTransferQty'] ? hideControl['fromTransferQty'] : true"
              required
              disabled
              :enable-serial="serialType"
              :custom="customData['fromTransferQty']"
              :source-parse-field="sourceParseField"
              ref="ref50"
              not-auto-fill
              :min="null"
              :more-than-zero="true"
              @enter="skipNext('refSerail')"
              @down="skipNext('refSerail')"
              @tab="skipNext('refSerail')"
              @setSourceField="saveSourceField($event)"
              @valuedByFoucs="valuedByFoucs($event)"
            />
          </hips-col>
          <hips-col span="8">
            <hips-button
              size="large"
              @click.stop="goToSerialDetail"
              @keyup.enter.native="handleCommonSubmit"
              @keypress.native.prevent
              ref="refSerail"
              class="button-detail"
            >
              {{ $t('button.serialNumber') }}
            </hips-button>
          </hips-col>
        </div>
      </hips-row>

      <div class="count_block_box">
        <!-- 现有量 -->
        <ips-number-count
          class="count-availableQty"
          :count="availableQty"
          :required="!enableSerial"
          :disabled="enableSerial"
          text-field="availableQty"
        />
        <!-- 可用量 -->
        <ips-number-count class="count-useQty" :count="useQty" text-field="useQty" />
        <!-- 保留量 -->
        <ips-number-count class="count-reserveQty" :count="reserveQty" text-field="reserveQty" />
      </div>
    </div>
    <div class="ips-footer">
      <hips-button size="large" @click.stop="handleCommonSubmit" ref="submitRef">
        <svg-icon slot="icon" icon-class="success" />{{ $t('button.conversion') }}
      </hips-button>
    </div>
  </hips-view>
</template>

<script>
import { Col, Row } from '@hips/vue-ui';
import { storage } from '@/utils';
import { checkBatch } from '../api';
export default {
  name: 'BatchModify',
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
  },
  data() {
    return {
      fromSerialFlag: false, // 从序列号组件跳转过来
      enableSerial: '',
      serialType: '',
      fromBatchId: '',
      fromBatchCode: '',
      goodsId: '',
      batchInfo: {}, // 批次数据
      fromCidId: '',
      fromCidCode: '',
      fromSku: '',
      fromLocationId: '',
      fromLocationCode: '',
      fromWhareaId: '',
      fromWhareaCode: '',
      warehouseId: '', // 仓库id
      fromCidParam: {}, // 来源托盘参数
      fromLocParam: {}, // 来源托盘参数
      fromTransferQty: '', // 转换数量
      availableQty: '', // 现有量
      useQty: '', // 可用量
      reserveQty: '', // 保留量
      serialNumberList: [], // 序列号数据
      // urlData:{}
      customData: {}, // 个性化配置信息列表
      hideControl: {}, // 个性化配置显示隐藏控制
      sourceParseField: {}, // 界面中所有的来源解析字段
    };
  },
  methods: {
    /**
     *@functionName: saveSourceField
     *@description: 保存来源解析字段并赋值Enter
     *@params1: fieldObj[Object] 来源解析字段对象,
     */
    saveSourceField(fieldObj) {
      this.saveSourceParseField(this, fieldObj);
    },
    // 字段聚焦时赋值操作
    async valuedByFoucs(options = {}) {
      const { custom = {}, currentValue } = options;
      await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
    },
    /**
     * handleChoose lov选中触发
     * @param type
     * @param obj 选中对象
     */
    handleChoose(type, obj) {
      const site = [this.SERIAL_INV_CTRL, this.SERIAL_SEMI_INV_CTRL];
      switch (type) {
        case 'fromBatchCode':
          this.fromBatchCode = obj.batchCode;
          break;
        case 'fromSku':
          // 先清空
          this.fromBatchId = '';
          this.serialType = '';
          this.fromSku = '';
          this.goodsId = '';
          this.fromCidId = '';
          this.fromCidCode = '';
          this.fromLocationId = '';
          this.fromLocationCode = '';
          this.fromWhareaId = '';
          this.fromWhareaCode = '';
          this.warehouseId = '';
          this.fromCidParam = {};
          this.fromLocParam = {};
          this.serialNumberList = [];
          this.$storage.set('serialNumberList', []);
          this.fromTransferQty = '';
          this.availableQty = ''; // 现有量
          this.useQty = ''; // 可用量
          this.reserveQty = ''; // 保留量
          // 再赋值
          this.batchInfo = obj;
          // this.fromBatchCode = obj.batchCode;
          this.fromBatchId = obj.id;
          this.goodsId = obj.goodsId;
          this.fromSku = obj.sku;
          this.serialType = obj.serialType;
          this.enableSerial = site.includes(obj.serialType);
          this.fromCidParam = {
            batchId: obj.id,
            goodsId: obj.goodsId,
          };
          this.fromLocParam = {
            batchId: this.fromBatchId,
            goodsId: this.goodsId,
          };
          this.handleBatchBlur();
          break;
        case 'fromCidCode':
          this.fromCidId = obj.cidId;
          this.fromCidCode = obj.cidCode;
          this.fromLocationId = obj.locationId;
          this.fromLocationCode = obj.locationCode;
          this.fromWhareaId = obj.whareaId;
          this.fromWhareaCode = obj.whareaCode;
          this.warehouseId = obj.warehouseId;
          this.availableQty = obj.qty; // 现有量
          this.useQty = obj.validQty; // 可用量
          this.reserveQty = obj.reserveQty; // 保留量
          this.fromLocParam = {
            batchId: this.fromBatchId,
            goodsId: this.goodsId,
            cidId: obj.cidId,
          };
          break;
        case 'fromLocationCode':
          this.fromLocationId = obj.locationId;
          this.fromLocationCode = obj.locationCode;
          this.fromWhareaId = obj.whareaId;
          this.fromWhareaCode = obj.whareaCode;
          this.warehouseId = obj.warehouseId;
          this.availableQty = obj.qty; // 现有量
          this.useQty = obj.validQty; // 可用量
          this.reserveQty = obj.reserveQty; // 保留量
          break;
        default:
          return null;
      }
    },
    /**
     * 批次lov失焦
     */
    async handleBatchBlur() {
      try {
        let data = {
          batchId: this.batchInfo.id,
          goodsId: this.batchInfo.goodsId,
        };
        const res = await checkBatch(data);
        if (res && res.failed) {
          this.skipNext(10);
        } else if (!res) {
          this.skipNext(20);
        } else {
          if (res.cidCode) {
            this.fromCidId = res.cidId;
            this.fromCidCode = res.cidCode;
          }
          this.fromLocationId = res.locationId;
          this.fromLocationCode = res.locationCode;
          this.fromWhareaId = res.whareaId;
          this.fromWhareaCode = res.whareaCode;
          this.warehouseId = res.warehouseId;
          this.availableQty = res.qty; // 现有量
          this.useQty = res.validQty; // 可用量
          this.reserveQty = res.reserveQty; // 保留量
          if (this.enableSerial) {
            this.skipNext('refSerail');
          } else {
            this.skipNext(50);
          }
        }
      } catch (e) {
        this.skipNext(10);
        console.log('错误：' + e);
      }
    },

    /**
     * 库位输入框失焦
     */
    handleLocEnter() {
      if (this.enableSerial) {
        this.skipNext('refSerail');
      } else {
        this.skipNext(50);
      }
    },
    /**
     * 数字输入框失焦
     */
    checkNumBlur() {
      if (this.fromTransferQty < 0) {
        this.$hint.showFailed(this.$i18n.t('message.qtyMustBeGreaterThanZero'), true);
        this.skipNext(50);
      }
      if (this.fromTransferQty > this.useQty) {
        // this.$hint.showFailed('转移数量不能大于可用量');
        this.$hint.showFailed(this.$i18n.t('message.qtyMustBeSmallerThanValidQty'), true);
        this.skipNext(50);
      }
    },
    /**
     * 序列号录入
     */
    async goToSerialDetail() {
      if (!this.fromBatchId || !this.fromLocationId) {
        this.$hint.showFailed(this.$i18n.t('message.checkRequired'), true);
        return;
      }
      let data = {
        serialType: this.serialType,
        batchId: this.fromBatchId,
        goodsId: this.goodsId,
        cidId: this.fromCidId,
        locationId: this.fromLocationId,
        warehouseId: this.warehouseId,
      };
      const serialUrl = 'app-batch-modify/check-serial';
      const param = {
        name: 'batchSerialInput',
        params: {
          data,
          serialUrl,
          useQty: this.useQty,
          serialNumberList: this.serialNumberList || [],
        },
      };
      this.fromSerialFlag = true;
      this.$router.pushPage(param);
    },

    async handleButtonSubmit() {
      if (this.fromTransferQty < 0) {
        this.$hint.showFailed(this.$i18n.t('message.qtyMustBeGreaterThanZero'), true);
        this.skipNext(50);
        return;
      }
      if (this.fromTransferQty > this.useQty) {
        // this.$hint.showFailed('转移数量不能大于可用量');
        this.$hint.showFailed(this.$i18n.t('message.qtyMustBeSmallerThanValidQty'), true);
        this.skipNext(50);
        return;
      }
      let param = {
        name: 'BatchModifyConfirm',
        params: {
          source: 'batchConversion',
          fromData: {
            enableSerial: this.enableSerial,
            batchInfo: this.batchInfo,
            goodsId: this.goodsId,
            fromSku: this.fromSku,
            sku: this.batchInfo.sku,
            ownerName: this.batchInfo.ownerName,
            ownerId: this.batchInfo.ownerId,
            orgName: this.batchInfo.orgName,
            orgId: this.batchInfo.orgId,
            serialType: this.serialType,
            fromBatchId: this.fromBatchId,
            fromBatchCode: this.fromBatchCode,
            fromCidId: this.fromCidId,
            fromCidCode: this.fromCidCode,
            fromLocationId: this.fromLocationId,
            fromLocationCode: this.fromLocationCode,
            fromWhareaId: this.fromWhareaId,
            fromWhareaCode: this.fromWhareaCode,
            fromTransferQty: this.fromTransferQty,
            availableQty: this.availableQty,
            useQty: this.useQty,
            reserveQty: this.reserveQty,
            serialNumberList: this.serialNumberList,
          },
          sourceParseField: { ...this.sourceParseField },
          customData: this.customData,
          hideControl: this.hideControl,
        },
      };
      this.$router.pushPage(param);
    },
  },
  created() {
    const { customObj, hideControl } = this.handleCustomData(storage.get('customData'));
    this.customData = customObj;
    this.hideControl = hideControl;
    // console.log('批次转换customData', Object.assign({}, this.customData));
  },
  mounted() {
    this.fromSerialFlag = false;
    this.inputFieldList = [
      { type: 'lov', required: true, ref: 0, bind: 'fromBatchCode', field: 'fromBatchCode' },
      { type: 'lov', required: true, ref: 10, bind: 'fromSku', field: 'fromSku' },
      { type: 'lov', required: false, ref: 20, bind: 'fromCidCode', field: 'fromCidCode' },
      { type: 'lov', required: true, ref: 30, bind: 'fromLocationCode', field: 'fromLocationCode' },
      { type: 'lov', required: true, ref: 40, bind: 'fromWhareaCode', field: 'fromWhareaCode' },
      { type: 'number', required: true, ref: 50, bind: 'fromTransferQty', field: 'fromTransferQty' },
    ];
    this.skipNext(0);
  },
  activated() {
    const { deleteData } = this.$route.params;
    if (this.fromSerialFlag) {
      this.fromSerialFlag = false;
      this.serialNumberList = this.$storage.get('serialNumberList') || [];
      this.fromTransferQty = this.serialNumberList.length;
      this.skipNext('submitRef');
    }
    if (deleteData) {
      this.enableSerial = '';
      this.fromBatchId = '';
      this.fromSku = '';
      this.fromBatchCode = '';
      this.goodsId = '';
      this.serialType = '';
      this.fromCidId = '';
      this.fromCidCode = '';
      this.fromLocationId = '';
      this.fromLocationCode = '';
      this.fromWhareaId = '';
      this.fromWhareaCode = '';
      this.warehouseId = '';
      this.fromCidParam = {};
      this.fromLocParam = {};
      this.serialNumberList = [];
      this.$storage.set('serialNumberList', []);
      this.fromTransferQty = '';
      this.availableQty = ''; // 现有量
      this.useQty = ''; // 可用量
      this.reserveQty = ''; // 保留量
      this.skipNext(0);
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'BatchModifyConfirm' || to.name === 'batchSerialInput') {
      this.$store.commit('pushKeepAlives', 'BatchModify');
    } else {
      this.$store.commit('deleteKeepAlives', 'BatchModify');
    }
    setTimeout(() => {
      next();
    }, 0);
  },
  // 从二级界面返回主界面, 清空来源解析字段
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const _this = vm;
      if (from.name === 'BatchModifyConfirm') {
        _this.clearSourceFields(_this);
      }
    });
  },
  watch: {
    fromBatchCode: function (val, oldval) {
      if (oldval !== '' && val === '') {
        this.fromBatchId = '';
        this.serialType = '';
        this.goodsId = '';
        this.fromCidId = '';
        this.fromCidCode = '';
        this.fromLocationId = '';
        this.fromLocationCode = '';
        this.fromWhareaId = '';
        this.fromWhareaCode = '';
        this.warehouseId = '';
        this.fromCidParam = {};
        this.fromLocParam = {};
        this.serialNumberList = [];
        this.$storage.set('serialNumberList', []);
        this.fromTransferQty = '';
        this.availableQty = ''; // 现有量
        this.useQty = ''; // 可用量
        this.reserveQty = ''; // 保留量
      }
    },
    fromCidCode: function (val, oldval) {
      if (oldval !== '' && val !== oldval) {
        this.fromCidId = '';
        this.fromLocationId = '';
        this.fromLocationCode = '';
        this.fromWhareaId = '';
        this.fromWhareaCode = '';
        this.warehouseId = '';
        this.fromLocParam = {
          batchId: this.fromBatchId,
          goodsId: this.goodsId,
        };
        this.serialNumberList = [];
        this.$storage.set('serialNumberList', []);
        this.fromTransferQty = '';
        this.availableQty = ''; // 现有量
        this.useQty = ''; // 可用量
        this.reserveQty = ''; // 保留量
      }
    },
    fromLocationCode: function (val, oldval) {
      if (oldval !== '' && val === '') {
        this.fromLocationId = '';
        this.fromWhareaId = '';
        this.fromWhareaCode = '';
        this.warehouseId = '';
        this.serialNumberList = [];
        this.$storage.set('serialNumberList', []);
        this.fromTransferQty = '';
        this.availableQty = ''; // 现有量
        this.useQty = ''; // 可用量
        this.reserveQty = ''; // 保留量
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
 @import '~@/style/var.styl'
.count_block_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
}
.count-availableQty{
  background-color: #91D5FF
  border-color: var(--primaryColor)
  color: var(--primaryColor)
}
.count-useQty{
  background-color: #B7EB8F
  border-color: #52C41A
  color: #52C41A
}
.count-reserveQty{
  background-color: #FFD382
  border-color: #FF8F07
  color: #FF8F07
}
.hip-row{
  display flex
  padding  12px 0 0 0
  .button-detail{
    margin-left: 15px;
    margin-right: 5px;
    padding 0 5px;
    background-color: var(--primaryColor);
    color: #FFFFFF;
    border: none;
    font-size: 14px;
    height: 36px;
    line-height: 36px;
  }
}
</style>
