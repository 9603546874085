<template>
  <div>
    <!-- 父托盘 -->
    <ips-field
      v-model="parentCidCode"
      params="parentCidCode"
      display="parentCidCode"
      :pre-parse-url="urlData['parentCidCode']"
      :label="$t('label.parentTray')"
      ref="ref40"
      :custom="customData['parentCidCode']"
      :source-parse-field="sourceParseField"
      v-show="
        !parentCidCodeShow
          ? false
          : containerManageFlag
            ? customData['parentCidCode']
              ? hideControl['parentCidCode']
              : containerManageFlag
            : false
      "
      @setSourceField="saveSourceField($event)"
      @valuedByFoucs="valuedByFoucs($event)"
      @blur="handleBlur('parentCidCode')"
      @enter="isPrePackage ? skipNext('submitRef') : skipNext(100)"
      @down="isPrePackage ? skipNext('submitRef') : skipNext(100)"
      @click.stop="handleCommonClick(5)"
    />
    <!-- 物料 -->
    <div style="margin-top: 16px">
      <ips-lov
        :label="$t('label.sku')"
        v-model="sku"
        v-show="isPrepackParentCid ? false : cidDrag === false ? (customData['sku'] ? hideControl['sku'] : cidDrag === false) : false"
        :disabled="isPackageSku"
        :required="isPrepackParentCid ? false : true"
        :title="[$t('label.sku'), $t('label.lineNum')]"
        :params="['sku', 'lineNum']"
        :url="`${winvBaseUrl}app-quick-inbound/query-inbound-info`"
        :url-params="{ billId, warehouseId }"
        :pre-parse-url="urlData['sku']"
        display="sku"
        :custom="customData['sku']"
        :source-parse-field="sourceParseField"
        ref="ref50"
        @click.stop="handleCommonClick(50)"
        @clickIcon="handleCommonClick(50, 'lov')"
        @enter="handleSkuEnter"
        @down="handleSkuEnter"
        @choose="handleChoose('sku', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
    </div>

    <div v-if="sku">
      <ips-page-card
        :react-field="['detailLineNum', 'uomName']"
        :item="skuInfo"
      >
        <ips-message-tip :message="skuInfo.goodsName" />
      </ips-page-card>
    </div>

    <!-- 批次 -->
    <ips-field
      :disabled="isPackage"
      v-model="batchCode"
      v-show="isBatch ? (customData['batchCode'] ? hideControl['batchCode'] : isBatch) : false"
      required
      :label="$t('label.batch')"
      params="batchCode"
      display="batchCode"
      :pre-parse-url="urlData['batchCode']"
      :custom="customData['batchCode']"
      :source-parse-field="sourceParseField"
      :enable-serial="serialType"
      ref="ref60"
      @click.stop="handleCommonClick(60)"
      @enter="handleBatchEnter"
      @down="handleBatchEnter"
      @setSourceField="saveSourceField($event)"
      @valuedByFoucs="valuedByFoucs($event)"
    />
    <!-- 序列号 -->
    <hips-row>
      <div
        style="margin-top: 16px"
        v-if="serialType === 'INV_CTRL' || serialType === 'IN_CTRL' || serialType === 'SEMI_INV_CTRL' || isPrepackParentCid"
      >
        <hips-col span="14">
          <!-- 入库数量 -->
          <ips-input-number
            v-model="receiveQty"
            v-show="customData['receiveQty'] ? hideControl['receiveQty'] : true"
            :disabled="isPackage || isPrePackage"
            :required="true"
            :label="$t('label.inventoryQty')"
            :custom="customData['receiveQty']"
            :source-parse-field="sourceParseField"
            :enable-serial="serialType"
            ref="ref80"
            :min="null"
            :precision="0"
            @enter="skipNext(90)"
            @down="skipNext(90)"
            @click.stop="handleCommonClick(80)"
            not-auto-fill
            @setSourceField="saveSourceField($event)"
            @valuedByFoucs="valuedByFoucs($event)"
          />
        </hips-col>
        <hips-col
          span="10"
          style="text-align: right"
        >
          <!-- 序列号明细 -->
          <hips-button
            size="large"
            v-show="
              customData['serialDetail']
                ? hideControl['serialDetail']
                : serialType === 'INV_CTRL' || serialType === 'IN_CTRL' || serialType === 'SEMI_INV_CTRL'
            "
            @click.stop="goToSerialDetail"
            ref="refSerail"
            class="serial-button-detail"
          >
            {{ `${$t('button.seriesDetail')}(${serialReceiveQtyImp})` }}
          </hips-button>
          <!-- 预打包托盘明细 -->
          <hips-button
            size="large"
            @click.stop="goToPrePackCidDetail"
            ref="refCid"
            v-show="customData['showPrePackCidDetail'] ? hideControl['showPrePackCidDetail'] : isPrepackParentCid"
            style="
                margin-left: 10px;
                background-color: var(--primaryColor);
                color: #ffffff;
                font-size: 14px;
                height: 36px;
                line-height: 36px;
              "
          >
            {{ $t('button.prePackCidDetail') }}
          </hips-button>
        </hips-col>
      </div>
      <div
        style="margin-top: 16px"
        v-if="serialType !== 'INV_CTRL' && serialType !== 'IN_CTRL' && serialType !== 'SEMI_INV_CTRL' && !isPrepackParentCid"
      >
        <hips-col span="24">
          <!-- 入库数量 -->
          <ips-input-number
            v-model="receiveQty"
            v-show="isPrepackParentCid ? false : customData['receiveQty'] ? hideControl['receiveQty'] : true"
            :required="!enableSerial"
            :disabled="enableSerial || isPackage || isPrePackage"
            :label="$t('label.inventoryQty')"
            :custom="customData['receiveQty']"
            :source-parse-field="sourceParseField"
            ref="ref80"
            :min="null"
            :more-than-zero="true"
            @enter="skipNext(90)"
            @down="skipNext(90)"
            @click.stop="handleCommonClick(80)"
            not-auto-fill
            @setSourceField="saveSourceField($event)"
            @valuedByFoucs="valuedByFoucs($event)"
          />
        </hips-col>
      </div>
    </hips-row>

    <div
      v-if="sku"
      class="count_block_box"
    >
      <!-- 需求数量 -->
      <ips-number-count
        :count="skuInfo.planQty"
        :required="!enableSerial"
        :disabled="enableSerial"
        text-field="planQty"
        background-color="#E6F7FF"
        border-color="#91D5FF"
        font-color="var(--primaryColor)"
      />
      <!-- 已入库数量 -->
      <ips-number-count
        :count="skuInfo.inQty"
        text-field="inQty"
        background-color="#F6FFED"
        border-color="#B7EB8F"
        font-color="#52C41A"
      />
      <!-- 待入库数量 -->
      <ips-number-count
        :count="skuInfo.pendingInQty"
        text-field="pendingInQty"
        background-color="#FFF4E6"
        border-color="#FFD382"
        font-color="#FF8F07"
      />
    </div>
    <!-- 入库库位 -->
    <ips-lov
      :label="$t('label.storageLocation')"
      required
      v-model="locationCode"
      v-show="customData['locationCode'] ? hideControl['locationCode'] : true"
      :title="[$t('label.storageLocation'), $t('label.whareaCode'), $t('label.whareaName')]"
      :params="['locationCode', 'whareaCode', 'whareaName']"
      display="locationCode"
      :url="`${wmdmBaseUrl}wms-locations/app`"
      :url-params="{ warehouseId, locationType: 'T_STOCK', whareaIdsString, whareaId: whareaIdsString ? whareaIdSku : null }"
      :pre-parse-url="urlData['locationCode']"
      :custom="customData['locationCode']"
      :source-parse-field="sourceParseField"
      ref="ref90"
      @click.stop="handleCommonClick(90)"
      @clickIcon="handleCommonClick(90, 'lov')"
      @enter="handleLocationEnter"
      @down="handleLocationEnter"
      @choose="handleChoose('locationCode', $event)"
      @setSourceField="saveSourceField($event)"
      @valuedByFoucs="valuedByFoucs($event)"
    />
    <!-- 入库托盘 -->
    <hips-row>
      <div
        style="margin-top: 16px"
        v-show="
          containerManageFlag === 1 && cidDrag === true
            ? customData['batch']
              ? hideControl['batch']
              : containerManageFlag === 1 && cidDrag === true
            : false
        "
      >
        <hips-col span="16">
          <ips-field
            v-model="cidCode"
            v-show="customData['cidCode'] ? hideControl['cidCode'] : true"
            :label="$t('label.storageTray')"
            params="cidCode"
            display="cidCode"
            :pre-parse-url="urlData['cidCode']"
            :custom="customData['cidCode']"
            :source-parse-field="sourceParseField"
            ref="ref100"
            @blur="handleCidBlur"
            @enter="skipNext('submitRef')"
            @click.stop="handleCommonClick(100)"
            @setSourceField="saveSourceField($event)"
            @valuedByFoucs="valuedByFoucs($event)"
          />
        </hips-col>
        <hips-col span="8">
          <hips-button
            size="large"
            @click.stop="goToCidDetail"
            ref="refSerail"
            style="
                margin-left: 16px;
                background-color: var(--primaryColor);
                color: #ffffff;
                font-size: 14px;
                height: 36px;
                line-height: 36px;
              "
          >
            {{ $t('button.cidDetail') }}
          </hips-button>
        </hips-col>
      </div>
      <div
        style="margin-top: 16px"
        v-show="isPrepackParentCid ? false : containerManageFlag === 1 && (cidCode === '' || cidDrag === false)"
      >
        <hips-col span="24">
          <ips-field
            v-model="cidCode"
            v-show="
              containerManageFlag === 1 && (cidCode === '' || cidDrag === false)
                ? customData['batch']
                  ? hideControl['batch']
                  : containerManageFlag === 1 && (cidCode === '' || cidDrag === false)
                : false
            "
            :label="$t('label.storageTray')"
            :required="parentCidCode ? true : !cidCodeRequired"
            params="cidCode"
            display="cidCode"
            :pre-parse-url="urlData['cidCode']"
            :custom="customData['cidCode']"
            :source-parse-field="sourceParseField"
            ref="ref100"
            @blur="handleCidBlur($event)"
            @enter="skipNext(30)"
            @down="skipNext(30)"
            @click.stop="handleCommonClick(100)"
            @setSourceField="saveSourceField($event)"
            @valuedByFoucs="valuedByFoucs($event)"
          />
        </hips-col>
      </div>
    </hips-row>
    <div class="ips-footer">
      <hips-button
        size="large"
        @click.stop="handleCommonSubmit"
        ref="submitRef"
      >
        <svg-icon
          slot="icon"
          icon-class="success"
        />{{ $t('button.confirm') }}
      </hips-button>
    </div>
  </div>
</template>

<script>
import { Col, Row } from '@hips/vue-ui';
import { storage } from '@/utils';
import {
  checkCidCode,
  checkSku,
  createTray,
  batchCheckAgain,
  checkSerialNum,
  checkBatchCode,
  queryReceiptInfo,
  checkParentCidCode,
  queryPrePackCidDetail,
  queryPageFunction,
  checkCidBlur,
  checkParentCidAndCid,
} from '../api';
export default {
  name: 'QuickInboundDetail',
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
  },
  data() {
    return {
      locationCode: '', //入库库位
      cidCode: '', //入库托盘
      parentCidCode: '', // 父托盘
      sku: '', // 物料
      skuBatchCode: '', // 物料带出批次
      batchCode: '', //批次
      receiveQty: null, // 入库数量
      batchControl: '', //是否允许自动创建批次
      isPrePackage: false, // 判断是否是预打包
      serialType: '',
      prepackagedData: [],
      inboundSerialReceiptList: [],
      serialReceiveQtyImp: 0,
      detailId: '',
      isPackage: false,
      isPackageSku: false,
      whareaIdSku: '',
      containerManageFlag: 0, // 是否启用托盘扣控制
      parentCidCodeShow: 0, // 页面功能参数控制是否启用父托盘
      cidCodeRequired: '', // 页面功能参数控制托盘是否必输
      skuInfo: {
        lineNum: '',
        detailLineNum: '',
        goodsName: '',
        uomName: '',
        planQty: null,
        inQty: null,
        pendingInQty: null,
      }, // 物料明细
      goodsInfo: {},
      menuId: storage.get('menuId'),
      isBatch: false, //是否启用批次控制
      enableSerial: false, //是否启用序列号管理
      cidDrag: false, //托盘是否混托
      urlData: {},
    //   customData: {}, // 个性化配置信息列表
    //   hideControl: {}, // 个性化配置显示隐藏控制
    //   sourceParseField: {}, // 界面中所有的来源解析字段
      inboundReceiptRecordPackList: [], // 预打包托盘明细
      isPrepackParentCid: false,
    };
  },
  props: {
        billId: { type: Number, default: null},
        billTypeId: { type: Number, default: null},
        billCode: { type: String, default: ''},
        warehouseId: { type: String, default: null},
        warehouseCode: { type: String, default: ''},
        receiptNumber: { type: String, default: ''},
        whareaIdsString: { type: String, default: ''},
        sourceParseField: { type: Object, default: () => {}},
        customData: { type: Object, default: () => {}},
        hideControl: { type: Object, default: () => {}},
        warehouseIdStr: { type: String, default: () => {}},
  },
  methods: {
    /**
       *@functionName: saveSourceField
       *@description: 保存来源解析字段并赋值Enter
       *@params1: fieldObj[Object] 来源解析字段对象,
       fieldCode[String] 来源解析字段
       parseValue[String] 二维码解析值
       subFields[Array]] 关联子字段
       */
    saveSourceField(fieldObj) {
      this.saveSourceParseField(this, fieldObj);
    },
    /**
     * 字段聚焦时赋值操作
     */
    async valuedByFoucs(options = {}) {
      const { custom = {}, currentValue } = options;
      await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
    },
    /**
     * handleCidBlur 入库托盘失焦触发
     */
    async handleCidBlur() {
      const onCancel = () => {
        this.cidCode = '';
        this.skipNext(100);
      };
      const onConfirm = () => {
        const data = {
          cidCode: this.cidCode,
          cidName: this.cidCode,
        };
        createTray(data)
          .then((res) => {
            if (res) {
              this.cidId = res.id;
              this.skipNext(30);
            }
          })
          .catch(() => {
            this.skipNext(100);
          });
      };
      if (this.cidCode) {
        this.$hint.showLoading();
        if (this.containerManageFlag) {
          let getParentCiddata = {
            params: {
              cidCode: this.cidCode,
              billId: this.billId,
              billCode: this.billCode,
              locationId: this.inLocationId,
              locationCode: this.locationCode,
              billTypeId: this.billTypeId,
            },
          };
          if (!this.parentCidCode) {
            const result = await checkCidBlur(getParentCiddata);
            if (result && result.parentCidId) {
              this.parentCidCode = result.parentCidCode;
              this.parentCidId = result.parentCidId;
            }
          }
        }
        if (this.parentCidCode) {
          let checkParentCidData = {
            billId: this.billId,
            locationId: this.inLocationId,
            locationCode: this.locationCode,
            cidCode: this.cidCode,
            parentCidCode: this.parentCidCode,
            checkPermissions: true,
            billCode: this.billCode,
            fromPDAFlag: true,
          };
          const res = this.checkCidAndParentCid(checkParentCidData, 'cid');
          if (!res) {
            return false;
          }
        }
        const data = {
          params: {
            cidCode: this.cidCode, //当前填写的入库托盘
            billId: this.billId,
            billTypeId: this.billTypeId,
            warehouseId: this.warehouseId,
            inLocationId: this.inLocationId,
          },
        };
        try {
          checkCidCode(data).then((res) => {
            const checkPackData = {
              params: {
                cidCode: this.cidCode, //当前填写的入库托盘
                billId: this.billId,
                status: 'PREPACKAGED',
              },
            };
            if (res === 0) {
              queryReceiptInfo(checkPackData)
                .then((result) => {
                  if (result && result.length > 1) {
                    this.cidDrag = true;
                    this.prepackagedData = result;
                    const packQtyAll = result.reduce(function (total, item) {
                      return total + Number(item.packQty);
                    }, 0);
                    this.receiveQty = packQtyAll;
                    this.isPackageSku = false;
                    this.isPackage = true;
                    this.$hint.hideLoading();
                  } else if (result && result.length === 1) {
                    this.prepackagedData = result;
                    this.cidDrag = false;
                    this.isPackage = true;
                    this.sku = result[0].sku;
                    this.goodsId = result[0].goodsId;
                    this.isPackageSku = true;
                    this.receiveQty = result[0].packQty;
                    this.isBatch = result[0].isbatch;
                    this.batchCode = result[0].batchCode;
                    this.serialType = result[0].serialType;
                    this.skuInfo.lineNum = result[0].lineNum;
                    this.skuInfo.detailLineNum = this.isPackage ? result[0].detailLineNum : result[0].lineNum;
                    this.skuInfo.goodsName = result[0].goodsName;
                    this.skuInfo.uomName = result[0].uomName;
                    this.skuInfo.planQty = result[0].planQty;
                    this.skuInfo.inQty = result[0].inQty;
                    this.skuInfo.pendingInQty = result[0].pendingInQty;
                    this.detailId = result[0].detailId;
                    this.serialReceiveQtyImp = result[0].inboundSerialReceiptList && result[0].inboundSerialReceiptList.length;
                    this.goodsInfo = result[0];
                    this.handleBatchBlur();
                    this.$hint.hideLoading();
                  } else {
                    this.$hint.showAlert(this.$i18n.t('message.checkTray'), onConfirm, onCancel);
                    this.isPackage = false;
                    this.isPackageSku = false;
                    this.$hint.hideLoading();
                  }
                })
                .finally(() => {
                  if (this.isPackageSku || this.cidDrag) {
                    this.skipNext('submitRef');
                  }
                });
              //  该托盘存在
            } else if (res === 1) {
              this.$hint.hideLoading();
              //  该托盘不存在,是否创建托盘
            } else if (res !== 1 && res !== 0) {
              // 判断托盘是否是预打包
              queryReceiptInfo(checkPackData)
                .then((result) => {
                  if (result && result.length > 1) {
                    this.cidDrag = true;
                    this.prepackagedData = result;
                    // this.$hint.hideLoading();
                    this.isPackage = true;
                    this.isPackageSku = false;
                    const packQtyAll = result.reduce(function (total, item) {
                      return total + Number(item.packQty);
                    }, 0);
                    this.receiveQty = packQtyAll;
                    this.$hint.hideLoading();
                  } else if (result && result.length === 1) {
                    this.prepackagedData = result;
                    this.cidDrag = false;
                    this.isPackage = true;
                    this.sku = result[0].sku;
                    this.goodsId = result[0].goodsId;
                    this.isPackageSku = true;
                    this.receiveQty = result[0].packQty;
                    this.isBatch = result[0].isbatch;
                    this.batchCode = result[0].batchCode;
                    this.serialType = result[0].serialType;
                    this.detailId = result[0].detailId;
                    this.skuInfo.lineNum = result[0].lineNum;
                    this.skuInfo.detailLineNum = this.isPackage ? result[0].detailLineNum : result[0].lineNum;
                    this.skuInfo.goodsName = result[0].goodsName;
                    this.skuInfo.uomName = result[0].uomName;
                    this.skuInfo.planQty = result[0].planQty;
                    this.skuInfo.inQty = result[0].inQty;
                    this.skuInfo.pendingInQty = result[0].pendingInQty;
                    this.serialReceiveQtyImp = result[0].inboundSerialReceiptList && result[0].inboundSerialReceiptList.length;
                    this.goodsInfo = result[0];
                    this.handleBatchBlur();
                    this.$hint.hideLoading();
                  } else {
                    this.isPackage = false;
                    this.isPackageSku = false;
                    this.$hint.showFailed(res);
                    this.$hint.hideLoading();
                    this.skipNext(100);
                  }
                })
                .finally(() => {
                  if (this.isPackageSku || this.cidDrag) {
                    this.skipNext('submitRef');
                  }
                });
            }
          });
        } catch (error) {
          this.skipNext(100);
        }
      }
    },
    skipNextSku(index) {
        this.skipNext(index)
    },
    /**
     * 取消创建批次
     **/
    cancelCreateBatch() {
      this.$nextTick(() => {
        this.skipNext(60);
      });
    },

    // 校验入库数量值
    inboudNumCheck() {
      if (this.receiveQty > this.skuInfo.planQty) {
        this.$hint.showFailed(this.$i18n.t('message.checkInboudReceiveQty'));
        this.skipNext(80);
      }
    },
    /**
     * handleBatchBlur 批次失焦触发
     */
    async handleBatchBlur() {
      try {
        if (this.parentCidCode) {
          let checkParentCidData = {
            billId: this.billId,
            locationId: this.inLocationId,
            locationCode: this.locationCode,
            cidCode: this.parentCidCode,
            checkPermissions: true,
            billCode: this.billCode,
            fromPDAFlag: true,
            sku: this.sku,
            goodsId: this.goodsId,
            batchCode: this.batchCode,
            billTypeId: this.billTypeId,
          };
          await checkParentCidCode(checkParentCidData);
        }
        if (this.cidCode) {
          const data = {
            params: {
              cidCode: this.cidCode, //当前填写的入库托盘
              billId: this.billId,
              billTypeId: this.billTypeId,
              warehouseId: this.warehouseId,
              inLocationId: this.inLocationId,
              sku: this.sku,
              goodsId: this.goodsId,
              batchCode: this.batchCode,
            },
          };
          const res = await checkCidCode(data);
          if (res !== 0 && res !== 1) {
            this.$hint.showFailed(res);
            this.skipNext(60);
          }
        }
        const onConfirm = () => {
          const urlParam = {
            name: 'createBatch',
            params: {
              skuInfo: this.goodsInfo,
              batchCode: this.batchCode,
            },
          };
          this.$router.pushPage(urlParam);
        };
        const onCancel = () => {
          this.$nextTick(() => {
            this.skipNext(60);
          });
        };
        if (this.batchCode) {
          try {
            this.$hint.showLoading();
            const data = {
              params: {
                batchCode: this.batchCode,
                goodsId: this.goodsId,
                // cidCode: this.cidCode,
                // billTypeId: this.billTypeId,
              },
            };
            const res = await checkBatchCode(data);
            if (res && !res.batchId && this.batchControl === '0') {
              // 该批次不存在，是否创建批次？
              this.$hint.showAlert(this.$i18n.t('message.checkBatchCode'), onConfirm, onCancel);
            } else if (this.cidCode) {
              this.batchId = res.batchId;
              const data = {
                params: {
                  batchId: this.batchId,
                  batchCode: this.batchCode,
                  goodsId: this.goodsId,
                  cidCode: this.cidCode,
                  billTypeId: this.billTypeId,
                },
              };
              batchCheckAgain(data).catch(() => {
                this.$nextTick(() => {
                  this.skipNext(60);
                });
              });
                this.$hint.hideLoading();
            } else if (res.batchId) {
              this.batchId = res.batchId;
              if (this.isPackage === true) {
                this.skipNext('submitRef');
              } else {
                // this.$nextTick(() => {
                this.skipNext(80);
                // })
              }
                this.$hint.hideLoading();
            }
          } catch {
            this.$nextTick(() => {
              this.skipNext(60);
            });
            this.$hint.hideLoading();
          } finally {
            this.$hint.hideLoading();
          }
        }
      } catch (error) {
        this.skipNext(60);
        this.$hint.hideLoading();
      }
    },

    /**
     * 失焦触发
     * @param flag dom类型标志
     */
    async handleBlur(flag) {
      try {
        if (flag === 'parentCidCode' && this.parentCidCode) {
          this.$hint.showLoading();
          let checkParentCidData = {
            billId: this.billId,
            locationId: this.inLocationId,
            locationCode: this.locationCode,
            cidCode: this.parentCidCode,
            checkPermissions: true,
            billCode: this.billCode,
            fromPDAFlag: true,
            sku: this.sku,
            batchCode: this.batchCode,
            billTypeId: this.billTypeId,
          };
          const res = await checkParentCidCode(checkParentCidData);
          if (res && res.failed && res.message) {
            this.$hint.showFailed(res.message, true);
            this.skipNext(40);
            return;
          } else if (res && res.isPrepackParentCid) {
            this.isPrepackParentCid = true;
            this.isPrePackage = true;
            this.receiveQty = res.receivedQty;
            this.prePackReceivedQty = res.receivedQty;
            let getCidDetailData = {
              params: {
                status: 'PREPACKAGED',
                parentCidCode: this.parentCidCode,
                billId: this.billId,
              },
            };
            const dataList = await queryPrePackCidDetail(getCidDetailData);
            if (dataList && dataList.content.length > 0) {
              this.inboundReceiptRecordPackList = dataList.content;
            }
            this.$nextTick(() => {
              this.skipNext('submitRef');
            });
          } else {
            this.isPrepackParentCid = false;
            this.isPrePackage = false;
            this.inboundReceiptRecordPackList = [];
          }
          if (this.cidCode) {
            let checkParentCidData = {
              billId: this.billId,
              locationId: this.inLocationId,
              locationCode: this.locationCode,
              parentCidCode: this.parentCidCode,
              cidCode: this.cidCode,
              checkPermissions: true,
              billCode: this.billCode,
              fromPDAFlag: true,
            };
            const res = this.checkCidAndParentCid(checkParentCidData, 'parentCid');
            if (!res) {
              return false;
            }
          }
        }
      } catch (e) {
        if (flag === 'parentCidCode') {
          this.skipNext(40);
        }
      } finally {
        this.$hint.hideLoading();
      }
    },

    async checkCidAndParentCid(data, flag) {
      try {
        await checkParentCidAndCid(data);
        return true;
      } catch (error) {
        if (flag === 'cid') {
          this.skipNext(100);
        } else {
          this.skipNext(40);
        }
      }
    },

    /**
     * 前往托盘明细界面
     */
    goToCidDetail() {
      let param = {
        name: 'quickInboundCidDetail',
        params: {
          prepackagedData: this.prepackagedData,
          cidCode: this.cidCode,
          receiveQty: this.receiveQty,
          billId: this.billId,
        },
      };
      this.$router.pushPage(param);
    },

    /**
     * handleChoose lov选中触发
     * @param type
     * @param obj 选中对象
     */
    async handleChoose(type, obj) {
      // const site = ['INV_CTRL', 'IN_CTRL', 'SEMI_INV_CTRL'];
      const site = [this.SERIAL_INV_CTRL, this.SERIAL_IN_CTRL, this.SERIAL_SEMI_INV_CTRL];
      switch (type) {
        case 'sku':
          this.skuInfo = obj;
          this.goodsInfo = obj;
          this.skuInfo.detailLineNum = this.isPackage ? obj.detailLineNum : obj.lineNum;
          this.skuInfo.lineNum = obj.lineNum;
          this.skuInfo.goodsName = obj.goodsName;
          this.skuInfo.uomName = obj.uomName;
          this.skuInfo.planQty = obj.planQty;
          this.skuInfo.inQty = obj.inQty;
          this.skuInfo.pendingInQty = obj.pendingInQty;
          this.goodsId = obj.goodsId;
          this.serialType = obj.serialType;
          this.detailId = obj.detailId;
          this.enableSerial = site.includes(obj.serialType);
          this.isBatch = obj.isbatch;
          if (this.isBatch) {
            this.batchCode = obj.batchCode;
            this.skuBatchCode = obj.batchCode;
          }
          this.whareaIdSku = obj.whareaId;
          // 比较仓库ID是否与库位的一致，若不一致，以物料的为准
          if (this.warehouseId !== obj.warehouseId) {
            // console.log('不一致');
            this.$hint.showFailed(this.$i18n.t('message.checkWarehouseIdNotSame'));
            this.locationCode = '';
            this.warehouseId = obj.warehouseId;
            // this.skipNext(0);
            return;
          }
          if (this.whareaIdsString && this.whareaIdSku && this.whareaId && this.whareaIdSku !== this.whareaId) {
            this.inLocationId = '';
            this.whareaId = '';
            this.whareaCode = '';
            this.containerManageFlag = '';
            this.locationCode = '';
          }
          try {
            if (this.parentCidCode) {
              let checkParentCidData = {
                billId: this.billId,
                locationId: this.inLocationId,
                locationCode: this.locationCode,
                cidCode: this.parentCidCode,
                checkPermissions: true,
                billCode: this.billCode,
                fromPDAFlag: true,
                sku: this.sku,
                goodsId: this.goodsId,
                billTypeId: this.billTypeId,
              };
              await checkParentCidCode(checkParentCidData);
            }
            if (this.cidCode) {
              const data = {
                params: {
                  cidCode: this.cidCode, //当前填写的入库托盘
                  billId: this.billId,
                  billTypeId: this.billTypeId,
                  sku: this.sku,
                  goodsId: this.goodsId,
                  warehouseId: this.warehouseId,
                  inLocationId: this.inLocationId,
                },
              };
              const res = await checkCidCode(data);
              if (res !== 0 && res !== 1) {
                this.$hint.showFailed(res);
                this.skipNext(30);
              }
            }
          } catch (error) {
            this.skipNext(30);
          }
          break;
        case 'locationCode':
          this.inLocationId = obj.id;
          this.whareaId = obj.whareaId;
          this.whareaCode = obj.whareaCode;
          this.containerManageFlag = obj.containerManageFlag;
          this.warehouseId = obj.warehouseId;
          // console.log('仓库1', this.warehouseId);
          break;
        default:
          return null;
      }
      if (this.sku && this.cidCode) {
        const data = {
          params: {
            goodsId: this.goodsId,
            cidCode: this.cidCode,
            billTypeId: this.billTypeId,
          },
        };
        checkSku(data).catch(() => {
          this.skipNext(30);
        });
      }
    },

    /**
     * handleLocationEnter 库位enter事件
     */
    handleLocationEnter() {
      if (this.containerManageFlag === 1 && this.parentCidCodeShow) {
        this.$nextTick(() => {
          this.skipNext(40);
        });
      } else if (this.containerManageFlag === 1) {
        this.$nextTick(() => {
          this.skipNext(100);
        });
      } else {
        this.$nextTick(() => {
          this.skipNext('submitRef');
        });
      }
    },

    /**
     * handleSkuEnter 物料enter事件
     */
    handleSkuEnter() {
    //   if (this.batchCode === '') {
    //     this.$nextTick(() => {
    //       this.skipNext(60);
    //     });
    //   } else {
    //     if (this.whareaIdsString && this.whareaIdSku && !this.locationCode) {
    //       this.$nextTick(() => {
    //         this.skipNext(90);
    //       });
    //     } else {
          if (this.isBatch) {
            this.$nextTick(() => {
              this.skipNext(60);
            });
          } else {
            this.$nextTick(() => {
              this.skipNext(80);
            });
          }
    //     }
    //   }
    },

    /**
     * handleBatchEnter 批次enter事件
     */
    handleBatchEnter() {
      this.handleBatchBlur();
      if (this.isPackage === true) {
        this.skipNext('submitRef');
      } else {
        // this.$nextTick(() => {
        this.skipNext(80);
        // })
      }
    },

    async handleButtonSubmit() {
      if (this.batchControl === '0' && this.isBatch === 1 && !this.batchId && !this.cidDrag) {
        this.handleBatchBlur();
        return;
      }
      const data = {
        pageId: this.menuId,
        billId: this.billId,
        billCode: this.billCode,
        inWhareaId: this.whareaId,
        inWhareaCode: this.whareaCode,
        cidCode: this.cidCode,
        cidId: this.cidId,
        cidName: this.cidCode,
        inLocationId: this.inLocationId,
        inLocationCode: this.locationCode,
        parentCidCode: this.parentCidCode,
        detailId: this.detailId,
        goodsId: this.goodsId,
        receiptNumber: this.receiptNumber,
        detailLineNum: this.skuInfo.lineNum,
        batchId: this.isBatch ? this.batchId : '',
        batchCode: this.isBatch ? this.batchCode : '',
        inQty: this.receiveQty,
        id: this.enableSerial ? this.id : '',
        inboundSerialReceiptList: this.inboundSerialReceiptList,
        warehouseId: this.warehouseId,
        warehouseCode: this.warehouseCode,
      };
      if (this.isPrepackParentCid) {
        data.prePackagedFlag = true;
      }
      this.$hint.showLoading();
      this.handleSubmitStatus();
      checkSerialNum(data)
        .then(() => {
        //   if (this.isPrepackParentCid && res && res.quickInboundResultData && res.quickInboundResultData.parentCidCode) {
        //     this.$hint.showSuccess(this.$i18n.t('message.wareSuccess'));
        //     this.parentCidCode = '';
        //     this.skipNext(90);
        //   } else {
        //     const resGoods = res.returnTypeInteger;
        //     if (res) {
        //       this.skuInfo.inQty = this.skuInfo.inQty + this.receiveQty;
        //       this.skuInfo.pendingInQty = this.skuInfo.pendingInQty - this.receiveQty;
        //       if (resGoods === 0) {
        //         const param = {
        //           name: 'quickInbound',
        //         };
        //         this.detailId = '';
        //         sessionStorage.setItem('formSerial', JSON.stringify('1'));
        //         this.$router.pushPage(param);
        //       } else if (this.isPackage) {
        //         this.inLocationId = '';
        //         this.whareaId = '';
        //         this.whareaCode = '';
        //         this.containerManageFlag = '';
        //         this.prepackagedData = [];
        //         this.goodsId = '';
        //         this.sku = '';
        //         this.receiveQty = '';
        //         this.cidId = '';
        //         this.cidCode = '';
        //         this.isPackage = false;
        //         this.isPackageSku = false;
        //         this.detailId = '';
        //         this.inboundReceiptRecordPackList = [];
        //         this.clearSourceFields(this, true);
        //         this.skipNext(90);
        //       } else {
        //         if (this.skuInfo.planQty === this.skuInfo.inQty) {
        //           if (resGoods === 0) {
        //             const param = {
        //               name: 'quickInbound',
        //             };
        //             sessionStorage.setItem('formSerial', JSON.stringify('1'));
        //             this.$hint.showSuccess(this.$i18n.t('message.wareSuccess'));
        //             this.detailId = '';
        //             this.clearSourceFields(this, true);
        //             this.$router.pushPage(param);
        //           } else {
        //             this.init();
        //           }
        //         } else {
        //           if (resGoods === 0) {
        //             const param = {
        //               name: 'quickInbound',
        //             };
        //             this.detailId = '';
        //             sessionStorage.setItem('formSerial', JSON.stringify('1'));
        //             this.$hint.showSuccess(this.$i18n.t('message.wareSuccess'));
        //             this.clearSourceFields(this, true);
        //             this.$router.pushPage(param);
        //           } else {
        //             this.init();
        //           }
        //         }
        //       }
        //       this.serialReceiveQtyImp = 0;
        //       this.inboundSerialReceiptList = [];
        //     }
        //   }
          this.$hint.hideLoading();
          this.$router.go(0);
          this.handleSubmitStatus();
        })
        .catch((e) => {
          this.$hint.hideLoading();
          this.handleSubmitStatus();
          console.log(e);
        });
    },

    /**
     * 前往托盘明细界面
     */
    goToPrePackCidDetail() {
      let param = {
        name: 'quickReceiveParentCidDetail',
        params: {
          billId: this.billId,
          parentCidCode: this.parentCidCode,
          status: 'PREPACKAGED',
        },
      };
      this.$router.pushPage(param);
    },

    async goToSerialDetail() {
      if (this.receiveQty > 0) {
        let data = {
          cidCode: this.cidCode,
          billId: this.billId,
          billCode: this.billCode,
          locationId: this.inLocationId,
          locationCode: this.locationCode,
          sku: this.sku,
          goodsId: this.goodsId,
          batchCode: this.batchCode,
          serialType: this.serialType,
          detailId: this.detailId,
          id: this.receiptId,
        };
        let serialUrl = 'app-inbound/serial-check';
        // const _this = this;
        const param = {
          name: 'serialComponent',
          params: {
            serialUrl,
            data,
            task:
              this.inboundSerialReceiptList && this.inboundSerialReceiptList.length > 0
                ? this.inboundSerialReceiptList
                : this.prepackagedData[0]
                  ? this.prepackagedData[0].inboundSerialReceiptList
                  : [],
            detailId: this.detailId,
            pendingQty: this.pendingQty,
            receiveQty: this.receiveQty,
            serialType: this.serialType,
            detailIdTmpList: this.prepackagedData.length === 1,

            callback: function (serialNum) {
              const item = {
                serialNumber: serialNum,
                qualityStatusName: '0-合格',
                qualityStatus: '0-ACCEPTED',
                serialQty: 1,
              };
              this.tableData.push(item);
            },
          },
        };
        this.$router.pushPage(param);
      }
    },

    /**
     * 获取页面功能参数-父托盘是否显示
     */
    async getParentCidPageFunction() {
      let data = {
        params: {
          functionCode: 'PDA_IN_DIRECT_INBOUND',
          deviceCode: 'PDA',
          paramCode: 'PARENT_CID_ENABLE',
        },
      };
      this.parentCidCodeShow = await queryPageFunction(data);
    },

    /**
     * 获取页面功能参数-托盘是否必输
     */
    async getCidPageFunction() {
      let data = {
        params: {
          functionCode: 'PDA_IN_DIRECT_INBOUND',
          deviceCode: 'PDA',
          paramCode: 'LPN_IS_NULL',
        },
      };
      this.cidCodeRequired = await queryPageFunction(data);
    },

    init() {
      this.cidCode = '';
      this.sku = '';
      this.skuBatchCode = '';
      this.batchCode = '';
      this.receiveQty = '';
      this.batchControl = '';
      this.isPrePackage = false;
      this.serialType = '';
      this.prepackagedData = [];
      this.inboundSerialReceiptList = [];
      this.serialReceiveQtyImp = 0;
      this.isPackage = false;
      this.isPackageSku = false;
      this.whareaIdsString = '';
      this.warehouseIdStr = '';
      this.whareaIdSku = '';
      this.containerManageFlag = 0;
      this.receiptNumber = '';
      this.skuInfo = {
        lineNum: '',
        detailLineNum: '',
        goodsName: '',
        uomName: '',
        planQty: null,
        inQty: null,
        pendingInQty: null,
      };
      this.isBatch = false;
      this.enableSerial = false;
      this.cidDrag = false;
      this.urlData = {};
      this.customData = {};
      this.hideControl = {};
      this.sourceParseField = {};
      this.inboundReceiptRecordPackList = {};
      this.skipNext(90);
    },
    handleCancel() {
      this.$router.backPage();
    },
  },
  created() {
  },
  activated() {
    if (this.isPackage) {
      this.$nextTick(() => {
        this.skipNext('submitRef');
      });
    } else {
      this.$nextTick(() => {
        this.skipNext(80);
      });
    }
    const inboundSerialReceiptList = JSON.parse(sessionStorage.getItem('inboundSerialReceiptList'));
    this.inboundSerialReceiptList = inboundSerialReceiptList;
    this.serialReceiveQtyImp = inboundSerialReceiptList && inboundSerialReceiptList.length;
    sessionStorage.removeItem('inboundSerialReceiptList');
  },
  async mounted() {
    this.inputFieldList = [
      { type: 'lov', required: true, ref: 90, bind: 'locationCode', field: 'locationCode' },
      { type: 'input', required: !this.parentCidCodeShow, ref: 40, bind: 'parentCidCode', field: 'parentCidCode' },
      { type: 'input', required: this.isPrepackParentCid ? false : !this.parentCidCodeShow, ref: 100, bind: 'cidCode', field: 'cidCode' },
      { type: 'lov', required: !this.isPrepackParentCid, ref: 30, bind: 'sku', field: 'sku' },
      { type: 'input', required: true, ref: 60, bind: 'batchCode', field: 'batchCode', isHide: !this.isBatch },
      // { type: 'input', required: false, ref: 'refSerail', bind: 'serialNumber', isHide: !this.enableSerial },
      {
        type: 'input',
        required:
          (this.serialType !== 'INV_CTRL' && this.serialType !== 'IN_CTRL' && this.serialType !== 'SEMI_INV_CTRL' && !this.enableSerial) ||
          this.serialType === 'INV_CTRL' ||
          this.serialType === 'IN_CTRL' ||
          this.serialType === 'SEMI_INV_CTRL',
        ref: 80,
        bind: 'receiveQty',
        field: 'receiveQty',
      },
      // { type: 'input', required: false, ref: 50, bind: 'cidCode', field: 'cidCode' },
    ];
    this.urlData = this.handleData('DIRECT_INB_CONFIRM');
    this.getParentCidPageFunction();
    this.getCidPageFunction();
    this.skipNext(90);
  },
  watch: {
    parentCidCode: function (val, oldval) {
      if (oldval !== '' && val === '') {
        this.isPrepackParentCid = false;
        this.isPrePackage = false;
        this.sku = '';
        this.goodsId = '';
        this.batchId = '';
        this.batchCode = '';
        this.isbatch = '';
        this.cidCode = '';
        this.cidId = '';
        if (this.prePackReceivedQty) {
          this.prePackReceivedQty = '';
          this.receiveQty = '';
        }
      }
    },
    locationCode: function (val) {
      if (!val) {
        this.inLocationId = undefined;
        this.whareaId = undefined;
        this.whareaCode = undefined;
        this.containerManageFlag = undefined;
      }
    },
    cidCode: function () {
      this.isPackage = false;
      this.isPackageSku = false;
      this.cidDrag = false;
      this.prepackagedData = [];
      this.sku = '';
      this.goodsId = '';
      this.receiveQty = '';
      this.isBatch = '';
      this.serialType = '';
      this.skuInfo.lineNum = '';
      this.skuInfo.goodsName = '';
      this.skuInfo.uomName = '';
      this.skuInfo.planQty = '';
      this.skuInfo.inQty = '';
      this.skuInfo.pendingInQty = '';
      this.goodsInfo = '';
    },
    sku: function (val) {
      if (!val) {
        this.goodsId = '';
        this.isBatch = false;
        this.serialType = '';
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name === 'createBatch') {
        if (vm.$storage.get('batchId')) {
          this.batchId = vm.$storage.get('batchId');
          vm.handleBatchEnter();
          vm.disabledFlag = true;
          vm.$storage.remove('batchId');
        } else {
          vm.cancelCreateBatch();
        }
      } else if (from.name === 'ReceiveParentCidDetail') {
        const _this = vm;
        _this.skipNext('submitRef');
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    if (
      to.name === 'serialComponent' ||
      to.name === 'InboundCidDetail' ||
      to.name === 'createBatch' ||
      to.name === 'ReceiveParentCidDetail'
    ) {
      this.$store.commit('pushKeepAlives', 'DerectInboundReceive');
    } else {
      this.$store.commit('deleteKeepAlives', 'DerectInboundReceive');
    }
    setTimeout(() => {
      next();
    }, 0);
  },
};
</script>

<style lang="stylus" scoped>
@import '~@/style/var.styl';

.count_block_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
}

.out_box {
  border-radius: 1.6vw;
  margin-top: 4.267vw;
  margin-bottom: 4.267vw;
  width: 100%;
  padding: 2.133vw 0px;
  color: #000;
  font-size: 3.467vw;
  box-shadow: 0 0 2.133vw #d9d9d9;
}

.serial-button-detail {
  background-color: var(--primaryColor) !important;
  color: #FFFFFF !important;
  font-size: 14px;
  height: 36px !important;
  line-height: 36px !important;
}
</style>
