<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.serialChange')" :is-index="true" />
    </div>
    <div class="ips-container">
      <!-- 目标批次 -->
      <ips-input
        :label="$t('label.sourceSerialNumber')"
        v-model="inputData.sourceSerialNumber"
        :custom="customData['sourceSerialNumber']"
        :source-parse-field="sourceParseField"
        required
        :pre-parse-url="urlData['sourceSerialNumber']"
        ref="ref0"
        @click.stop="handleCommonClick(0)"
        @enter="validateSourceSerial"
        @down="validateSourceSerial"
        not-auto-fill
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <div class="out_box">
        <!-- 物料编码 -->
        <ips-value-cell
          :title="$t('label.sku')"
          image-flag
          :value="serialData.sku"
        />
        <!-- 物料名 -->
        <ips-value-cell
          :title="$t('label.goodsName')"
          image-flag
          :value="serialData.goodsName"
        />
        <!-- 批次 -->
        <ips-value-cell
          v-if="serialData.batchCode"
          :title="$t('label.batchCode')"
          image-flag
          :value="serialData.batchCode"
        />
        <!-- 货主 -->
        <ips-value-cell
          :title="$t('label.ownerCode')"
          image-flag
          :value="getOwnerCodeStr"
        />
        <!-- 仓库 -->
        <ips-value-cell
          :title="$t('label.warehouse')"
          image-flag
          :value="serialData.warehouseName"
        />
        <!-- 库区 -->
        <ips-value-cell
          :title="$t('label.whareaCode')"
          image-flag
          :value="serialData.whareaCode"
        />
        <!-- 库位 -->
        <ips-value-cell
          :title="$t('label.locationCode')"
          image-flag
          :value="serialData.locationCode"
        />
      </div>
      <ips-input
        :label="$t('label.targetSerialNumber')"
        v-model="inputData.targetSerialNumber"
        :custom="customData['targetSerialNumber']"
        :source-parse-field="sourceParseField"
        required
        :disabled="!serialData.sourceSerialId"
        :pre-parse-url="urlData['targetSerialNumber']"
        ref="ref10"
        @enter="validateTargetSerial"
        @down="validateTargetSerial"
        not-auto-fill
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <div class="ips-footer">
        <hips-button size="large" :loading="submitLoading" @click.stop="onHanldeSubmitData" ref="submitRef">
          <svg-icon slot="icon" icon-class="success" />
          {{ $t('button.confirm') }}
        </hips-button>
      </div>
    </div>
  </hips-view>
</template>

<script>
import { Cell } from '@hips/vue-ui';
import { storage } from '@/utils';
import { validateSourceSerial, validateTargetSerial, confirmSerialChange } from '../api'
export default {
  name: 'StockCount',
  data() {
    return {
      inputData: {},
      submitLoading: false,
      serialData: {}, //序列号数据
      targetSerial: undefined, //目标序列号数据
      urlData: {},
      customData: {}, // 个性化配置信息列表
      hideControl: {}, // 个性化配置显示隐藏控制
      sourceParseField: {}, // 界面中所有的来源解析字段
    };
  },

  components: {
    [Cell.name]: Cell,
  },

  computed: {
    getOwnerCodeStr(ctx) {
      return ctx.serialData.ownerCode ? ( ctx.serialData.ownerCode + '-' + ctx.serialData.ownerName) : undefined
    }
  },

  methods: {
    /**
     * 选择盘点单号
     * @param {object} item - 选中的数据
     */
    handleChoose(item) {
      this.serialData = item;
    },
    async validateSourceSerial() {
      if (!this.inputData.sourceSerialNumber) {
        return
      }
      const res = await validateSourceSerial({
        sourceSerialNumber: this.inputData.sourceSerialNumber
      })
      if (res && !res.field) {
        this.serialData = res
        this.$nextTick(() => {
          this.skipNext(10)
        })
        
      }
      
    },

    async validateTargetSerial() {
      const { sourceSerialNumber } = this.serialData;
      const targetSerialNumber = this.inputData.targetSerialNumber;
      if (!sourceSerialNumber || !targetSerialNumber) {
        return;
      }
      const res = await validateTargetSerial({
        ...this.serialData,
        targetSerialNumber
      })

      if (res && !res.field) {
        this.targetSerial = res
        this.skipNext('submitRef')
      }
    },
    /**
     *@functionName: saveSourceField
     *@description: 保存来源解析字段并赋值Enter
     *@params1: fieldObj[Object] 来源解析字段对象,
     fieldCode[String] 来源解析字段
     parseValue[String] 二维码解析值
     subFields[Array]] 关联子字段
     */
    saveSourceField(fieldObj) {
      this.saveSourceParseField(this, fieldObj);
    },
    /**
     * 字段聚焦时赋值操作
     */
    async valuedByFoucs(options={}) {
      const { custom ={} , currentValue } = options;
      await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
    },
    /**
     * 前往盘点明细界面
     */
    async onHanldeSubmitData() {
      if (!this.targetSerial) {
        return;
      }
      this.submitLoading = true
      try {
        const res = await confirmSerialChange(this.targetSerial);
        if (!res.field) {
          this.$hint.showSuccess();
          this.serialData = {}
          this.inputData = {}
          this.targetSerial = undefined
          this.skipNext(0);
        }
        this.submitLoading = false
      } catch (error) {
        this.submitLoading = false
      }
      
      // const { countStatus } = this.serialData;
      // if (countStatus === 'APPROVED' || countStatus === 'PROCESSING') {
      //   this.$router.pushPage(param);
      // } else {
      //   this.$hint.showFailed(this.$i18n.t('message.countBillCheck'));
      // }
    },
  },
  created() {
    const { customObj, hideControl } = this.handleCustomData(storage.get('customData'));
    this.customData = customObj;
    this.hideControl = hideControl;
  },
  async mounted() {
    this.skipNext(0);
  },
};
</script>

<style lang="stylus" scoped>
 @import '~@/style/var.styl'
.out_box {
  border-radius: 1.6vw;
  margin-top: 4.267vw;
  width: 100%;
  padding: 2.7vw 0px;
  color: #000;
  font-size: 3.467vw;
  box-shadow: 0 0 2.133vw #d9d9d9;
}

</style>
