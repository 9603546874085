<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header
        :title="$t('title.rackingTasks')"
        :is-index="false"
      />
    </div>
    <div class="ips-container">
      <!-- 上架托盘 -->
      <ips-value-cell
        :title="$t('label.putAwayTray')"
        :value="cidCode"
        v-show="customData['cidCode'] ? hideControl['cidCode']: true"
      />
      <!-- 任务数量 -->
      <ips-value-cell
        :title="$t('label.taskNum')"
        :value="qtyPlan"
        v-show="customData['qtyPlan'] ? hideControl['qtyPlan']: true"
      />
      <!-- 确认托盘 -->
      <ips-field
        :label="$t('label.conformTray')"
        v-model="toCidCodeByEntire"
        v-show="customData['toCidCodeByEntire'] ? hideControl['toCidCodeByEntire']: true"
        disabled
        params="toCidCode"
        display="toCidCode"
        :pre-parse-url="urlData['toCidCodeByEntire']"
        :custom="customData['toCidCodeByEntire']"
        :source-parse-field="sourceParseField"
        ref="ref0"
        @click.stop="handleCommonClick(0)"
        @enter="handleCodeSkip"
        @down="handleCodeSkip"
        @blur="handleBlur"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 建议库位 -->
      <ips-value-cell
        :title="$t('label.suggestedLocation')"
        :value="sugLocationCode"
        v-show="customData['sugLocationCode'] ? hideControl['sugLocationCode']: true"
      />
      <!-- 确认库位 -->
      <ips-lov
        :label="$t('label.confirmLocation')"
        :title="$t('label.confirmLocation')"
        required
        :disabled="disabledFlag"
        v-model="formData.toLocationCodeByEntire"
        v-show="customData['toLocationCodeByEntire'] ? hideControl['toLocationCodeByEntire']: true"
        params="locationCode"
        :url="`${wmdmBaseUrl}wms-locations/lov`"
        :url-params="{warehouseId, 'locationType': 'T_STOCK'}"
        :pre-parse-url="urlData['toLocationCodeByEntire']"
        :custom="customData['toLocationCodeByEntire']"
        :source-parse-field="sourceParseField"
        ref="ref10"
        @click.stop="handleCommonClick(10)"
        @clickIcon="handleCommonClick(10, 'lov')"
        @enter="skipNext('submitRef')"
        @down="skipNext('submitRef')"
        @choose="chooseConfirmLocation"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
    </div>
    <div class="ips-footer">
      <hips-button
        size="large"
        ref="submitRef"
        @click.stop="handleCommonSubmit"
      >
        <svg-icon
          slot="icon"
          icon-class="success"
        />
        {{ $t('button.confirm') }}
      </hips-button>
      <hips-button
        v-show="customData['taskDetail'] ? hideControl['taskDetail']: true"
        size="large"
        @click="goDetail"
      >
        <svg-icon
          slot="icon"
          icon-class="detail"
        />
        {{ $t('button.taskDetail') }}
      </hips-button>
    </div>
  </hips-view>
</template>

<script>
import { confirmDetail, confirmCidDetail, checkCidCode } from '../api';
export default {
  name: 'EntireTray',
  data() {
    return {
      cidCode: '',    //上架托盘
      toCidCodeByEntire: '',  //确认托盘
      sugLocationCode: "", //建议库位
      qtyPlan: null, //任务数量
      disabledFlag: false,
      warehouseId: '', // 库位
      formData: {
        toLocationCodeByEntire: '', // 确认库位
      },
      urlData:{},
      customData: {}, // 个性化配置信息列表
      hideControl: {}, // 个性化配置显示隐藏控制
      sourceParseField: {}, // 界面中所有的来源解析字段
    };
  },
  methods: {
    /**
     *@functionName: saveSourceField
     *@description: 保存来源解析字段并赋值Enter
     *@params1: fieldObj[Object] 来源解析字段对象,
     fieldCode[String] 来源解析字段
     parseValue[String] 二维码解析值
     subFields[Array]] 关联子字段
     */
    saveSourceField(fieldObj) {
      this.saveSourceParseField(this, fieldObj);
    },
    // 字段聚焦时赋值操作
    async valuedByFoucs(options={}) {
      const { custom ={} , currentValue } = options;
      await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
    },
    /**
     * 确认托盘失焦事件
    */
    handleBlur() {
      if (this.toCidCodeByEntire) {
        this.$hint.showLoading();
        const data = {
          cidCode: this.toCidCodeByEntire, //当前填写的确认托盘
          fromCidCode: this.cidCode, //上架托盘
          locationId: this.formData.locationId,
          locationCode: this.formData.toLocationCodeByEntire, //确认库位
          warehouseId: this.formData.warehouseId,
        };
        checkCidCode(data).then((res)=>{
          if(res.locationCode && res.locationId) {
            this.formData.toLocationCodeByEntire = res.locationCode;
            this.formData.locationId = res.locationId;
            this.formData.toLocationId = res.locationId;
            this.disabledFlag = true;
            this.skipNext('submitRef');
          }else {
            this.disabledFlag = false;
            this.skipNext(10);
          }
        })
        .catch(() => {
          this.skipNext(0);
        })
        .finally(()=>{
          this.$hint.hideLoading();
        });
      }
    },

    /**
     * 确认托盘Enter事件
    */
    handleCodeSkip() {
      if(this.disabledFlag) {
        this.skipNext('submitRef');
      }else {
        this.skipNext(10);
      }
    },

    /**
     * 确认库位选中事件
     * @param {Object} item
    */
    chooseConfirmLocation(item) {
      this.formData.locationId = item.locationId;
      this.formData.toLocationId = item.locationId;
      this.formData.locationCode = item.locationCode;
    },

    /**
     * 查看明细
    */
    goDetail() {
      if (!this.cidCode) {
        this.$hint.showFailed(this.$i18n.t('message.cidNotNull'), true);
        return;
      }
      let param = {
        name: 'TrayDetail',
        params: {
          cidCode: this.cidCode,
          status: 'INITIAL',
        },
      };
      this.$router.pushPage(param);
    },

    async handleButtonSubmit() {
      try {
        if (!this.formData.toLocationCodeByEntire) {
        this.$hint.showFailed(this.$i18n.t('message.checkRequired'), true);
        return;
        }
        this.$hint.showLoading();
        const data = {
          params: {
            cidCode: this.cidCode,
          }
        };
        await confirmCidDetail(data);
        let param = {
          ...this.formData,
          toLocationCode: this.formData.toLocationCodeByEntire,
          toCidId: this.toCidId,
          toCidCode: this.toCidCodeByEntire,
          cidCode: this.cidCode,
        };
        confirmDetail(param).then((res)=>{
          if (res && !res.failed) {
            this.clearSourceFields(this, true);
            this.$hint.showSuccess();
            this.$router.backPage();
          }
        }).finally(()=>{
          this.$hint.hideLoading();
        })
      } catch (error) {
        this.$hint.hideLoading();
      }
    },

    async getDataFromSession() {
      const { sourceParseField, customData, hideControl } = this.$route.params;
      this.sourceParseField = sourceParseField;
      this.customData = customData;
      this.hideControl = hideControl;
      let sessionData = sessionStorage.getItem('_putawayLoad');
      if (sessionData) {
        const data = JSON.parse(sessionData);
        const transformData = data && data[0] && {
          ...data[0],
          toLocationCodeByEntire: data[0] ? data[0].toLocationCode : '',
        }
        this.formData = transformData;
        // this.formData.toLocationCodeByEntire = data[0] ? data[0].toLocationCode : '';
        this.sugLocationCode = data[0] ? data[0].toLocationCode : '';
        this.formData.toLocationCodeByEntire = this.sugLocationCode;
        let num = 0;
        data.map((item)=>{
          num += item.qtyPlan;
        });
        this.qtyPlan = num;
        this.disabledFlag = false;
        sessionStorage.removeItem('_putawayLoad');
      }
    },
  },

  async mounted(){
    this.inputFieldList= [
      { type: 'text', bind: 'cidCode', field: 'cidCode' },
      { type: 'text', bind: 'qtyPlan', field: 'qtyPlan' },
      {type: 'input', required: false, ref: 0, bind: 'toCidCodeByEntire', field: 'toCidCodeByEntire'},
      { type: 'text', bind: 'sugLocationCode', field: 'sugLocationCode' },
      {type: 'lov', required: true, ref: 10 , bind: 'formData.toLocationCodeByEntire', field: 'toLocationCodeByEntire'},
    ];
    this.urlData = this.handleData('FULL_PALLET_CONFIRM');
    this.skipNext(10);
  },
  async activated() {
    await this.getDataFromSession();
    const { cidCode } = this.$route.params;
    this.warehouseId = this.$route.params.warehouseId;
    // 从明细返回时，params里没有值
    if(cidCode) {
      this.cidCode = this.toCidCodeByEntire = cidCode;
    }
    this.skipNext(10);
  }
};
</script>

<style lang="stylus" scoped></style>

