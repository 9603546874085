var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: { title: _vm.$t("title.batchList"), "is-index": false },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _vm.stockList.length <= 0 ? _c("ips-no-data") : _vm._e(),
        _vm.stockList.length > 0
          ? _c(
              "div",
              _vm._l(_vm.stockList, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "card_countLocation" },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "row-50" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("label.batch")) +
                              ":\n            "
                          ),
                        ]),
                        _c("div", { staticClass: "value" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.batchCode) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "row-50" }, [
                        _c("div", { staticClass: "title2" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("label.availableQty")) +
                              ":\n            "
                          ),
                        ]),
                        _c("div", { staticClass: "label" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.qty) +
                              "\n            "
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }