import request from '@/request';
import mixin from '@/mixin/mixin';

// 校验托盘是否存在于其他库位
export function appIsContainer(data) {
  const url = `${mixin.data().winvBaseUrl}app-inv/app-is-container`;
  return request.get(url, data);
}

// 盘点数据提交
export function countConfirm(data) {
  const url = `${mixin.data().winvBaseUrl}app-inv/count-confirm`;
  return request.post(url, data);
}

// 盘点确认
export function appRepeatCount(data) {
  const url = `${mixin.data().winvBaseUrl}app-inv/app-repeat-count`;
  return request.get(url, data);
}

// 最终盘点
export function appCount(data) {
  let url = `${mixin.data().winvBaseUrl}app-inv/app-count`;
  return request.post(url, data);
}

// 获取订单行数据
export function queryOrderLines(data) {
  let url = `${mixin.data().winvBaseUrl}app-inv/queryOrderLines`;
  return request.get(url, data);
}

// 获取快照数据
export function querySnapshotQty(data) {
  let url = `${mixin.data().winvBaseUrl}app-inv/queryOrderLine`;
  return request.get(url, data);
}
