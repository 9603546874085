import request from '@/request';
import mixin from '@/mixin/mixin';

// 复核维度单
export function autoMatchCode(data) {
  let url = `${mixin.data().winvBaseUrl}app-review/auto-match-code`;
  return request.get(url, data);
}
// 校验快递单号
export function validateExpressCode(data) {
  let url = `${mixin.data().winvBaseUrl}app-review/validate-express-code`;
  return request.post(url, data);
}

// 校验物料
export function validateSku(data) {
  let url = `${mixin.data().winvBaseUrl}app-review/validate-sku`;
  return request.post(url, data);
}

// 校验条码
export function validateCode(data) {
  let url = `${mixin.data().winvBaseUrl}app-review/validate-code`;
  return request.post(url, data);
}

// 校验数量
export function validateQty(data) {
  let url = `${mixin.data().winvBaseUrl}app-review/validate-qty`;
  return request.post(url, data);
}

// 换箱
export function exchangePack(data) {
  let url = `${mixin.data().winvBaseUrl}app-review/exchange-pack`;
  return request.post(url, data);
}

// 确认
export function confirm(data) {
  let url = `${mixin.data().winvBaseUrl}app-review/confirm`;
  return request.post(url, data);
}

// 校验出库单号
export function validateBillCode(data) {
  let url = `${mixin.data().winvBaseUrl}app-review/validate-bill-code`;
  return request.post(url, data);
}

// 校验波次号
export function validateWaveBillCode(data) {
  let url = `${mixin.data().winvBaseUrl}app-review/validate-wave-billCode`;
  return request.post(url, data);
}
