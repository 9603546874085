import request from '@/request';
import mixin  from '@/mixin/mixin';


// 来源单号失去焦点校验
export function queryGoods(data) {
  const url = `${mixin.data().winvBaseUrl}app-outbound-sorting/query-goods`;
  return request.get(url, data);
}

// 查询初始化数值调用init接口
export function initPage(data) {
  const url = `${mixin.data().winvBaseUrl}app-outbound-sorting/init-page`;
  return request.get(url, data);
}

// 获取数据
export function queryProcessRecords(data) {
  let url = `${mixin.data().winvBaseUrl}app-outbound-sorting/query-process-records`;
  return request.post(url, data);
}

export function sortConfirm(data) {
  let url = `${mixin.data().winvBaseUrl}app-outbound-sorting/sort`;
  return request.post(url, data);
}

// 清空数据
export function clearTask(data) {
  let url = `${mixin.data().winvBaseUrl}app-outbound-sorting/clear-task`;
  return request.post(url, data);
}

