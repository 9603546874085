import Unpicking from '../views/index'
import GoodsDetail from '../views/goodsDetail.vue'
import CidDetail from '../views/cidDetail.vue'
import SerialDetail from '../views/serialDetail.vue'

let route = [
  {
    path: '/unpicking',
    name: 'unpicking',
    component:Unpicking,
  },
  {
    path: '/goodsDetail',
    name: 'GoodsDetail',
    component:GoodsDetail,
  },
  {
    path: '/cidDetail',
    name: 'CidDetail',
    component:CidDetail,
  },
  {
    path: '/serialDetail',
    name: 'SerialDetail',
    component:SerialDetail,
  },
]

export default route
