var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [_c("ips-header", { attrs: { title: _vm.$t("title.loading") } })],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _c(
          "hips-tabs",
          {
            model: {
              value: _vm.active,
              callback: function ($$v) {
                _vm.active = $$v
              },
              expression: "active",
            },
          },
          [
            _c(
              "hips-tab",
              {
                class: [{ "active-tab": _vm.active === 0 }],
                attrs: { title: _vm.$t("label.waitLoading") },
              },
              [
                _c("ips-field", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.customData["cidCode"]
                        ? _vm.hideControl["cidCode"]
                        : true,
                      expression:
                        "customData['cidCode'] ? hideControl['cidCode']: true",
                    },
                  ],
                  ref: "ref0",
                  attrs: {
                    label: _vm.$t("label.cidMsg"),
                    params: "cidCode",
                    display: "cidCode",
                    "pre-parse-url": _vm.urlData["cidCode"],
                    custom: _vm.customData["cidCode"],
                    "source-parse-field": _vm.sourceParseField,
                    required: "",
                  },
                  on: {
                    enter: function ($event) {
                      return _vm.skipNext("submitRef")
                    },
                    down: function ($event) {
                      return _vm.skipNext("submitRef")
                    },
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleCommonClick(0)
                    },
                    blur: _vm.checkCidCodeReturn,
                    setSourceField: function ($event) {
                      return _vm.saveSourceField($event)
                    },
                    valuedByFoucs: function ($event) {
                      return _vm.valuedByFoucs($event)
                    },
                  },
                  model: {
                    value: _vm.inputData.cidCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputData, "cidCode", $$v)
                    },
                    expression: "inputData.cidCode",
                  },
                }),
                _c(
                  "hips-button",
                  {
                    ref: "submitRef",
                    class: [
                      "load-cid-button-class",
                      { "disable-btn": !_vm.inputData.cidCode },
                    ],
                    attrs: { size: "large", disabled: !_vm.inputData.cidCode },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.handleCommonSubmit.apply(null, arguments)
                      },
                      enter: _vm.handleCommonSubmit,
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("button.loadingCidCode")) +
                        "\n        "
                    ),
                  ]
                ),
                _vm.loadReturnData && _vm.loadReturnData.length > 0
                  ? _c(
                      "div",
                      { staticStyle: { "margin-top": "16px" } },
                      [
                        _c("span", { staticClass: "bos-title-style" }, [
                          _vm._v(_vm._s(_vm.$t("label.loadingMessage"))),
                        ]),
                        _vm._l(_vm.loadReturnData, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "load-message-card" },
                            [
                              _c("ips-value-cell", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.parentCidCode,
                                    expression: "item.parentCidCode",
                                  },
                                ],
                                attrs: {
                                  title: _vm.$t("label.shelvesParentCid"),
                                  value: item.parentCidCode,
                                },
                              }),
                              _c("ips-value-cell", {
                                attrs: {
                                  title: _vm.$t("label.shelvesCid"),
                                  value: item.cidCode,
                                },
                              }),
                              _c("ips-value-cell", {
                                staticClass: "ips-value-cell",
                                attrs: {
                                  title: _vm.$t("label.warehouse"),
                                  value: item.warehouseCode,
                                },
                              }),
                              _c("ips-value-cell", {
                                attrs: {
                                  title: _vm.$t("label.targetWharea"),
                                  value: item.toWhareaCode,
                                },
                              }),
                              _c("ips-value-cell", {
                                attrs: {
                                  title: _vm.$t("label.targetLocation"),
                                  value: item.toLocationCode,
                                },
                              }),
                              _c("ips-value-cell", {
                                attrs: {
                                  title: _vm.$t("label.targetTray"),
                                  value: item.toCidCOde,
                                },
                              }),
                            ],
                            1
                          )
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "hips-tab",
              {
                class: [{ "active-tab": _vm.active !== 0 }],
                attrs: {
                  title:
                    "" +
                    (_vm.$t("label.alreadyLoading") +
                      "(" +
                      _vm.allCidListLength +
                      ")"),
                },
              },
              [
                _c(
                  "hips-row",
                  [
                    _c(
                      "hips-col",
                      { attrs: { span: "19" } },
                      [
                        _c("ips-field", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.customData["inputCidCode"]
                                ? _vm.hideControl["inputCidCode"]
                                : true,
                              expression:
                                "customData['inputCidCode'] ? hideControl['inputCidCode']: true",
                            },
                          ],
                          ref: "ref10",
                          attrs: {
                            label: _vm.$t("label.cidMsg"),
                            params: "inputCidCode",
                            display: "inputCidCode",
                            "pre-parse-url": _vm.urlData["inputCidCode"],
                            custom: _vm.customData["inputCidCode"],
                            "source-parse-field": _vm.sourceParseField,
                          },
                          on: {
                            enter: function ($event) {
                              return _vm.skipNext("searchRef")
                            },
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.handleCommonClick(10)
                            },
                            blur: _vm.checkCidCodeReturn,
                            setSourceField: function ($event) {
                              return _vm.saveSourceField($event)
                            },
                            valuedByFoucs: function ($event) {
                              return _vm.valuedByFoucs($event)
                            },
                          },
                          model: {
                            value: _vm.inputCidCode,
                            callback: function ($$v) {
                              _vm.inputCidCode = $$v
                            },
                            expression: "inputCidCode",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "hips-col",
                      { staticClass: "ips-row-left", attrs: { span: "4" } },
                      [
                        _c(
                          "hips-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.customData["query"]
                                  ? _vm.hideControl["query"]
                                  : true,
                                expression:
                                  "customData['query'] ? hideControl['query']: true",
                              },
                            ],
                            ref: "searchRef",
                            staticClass: "search-cid-button-class",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.handleQueryAllCidCode.apply(
                                  null,
                                  arguments
                                )
                              },
                              enter: _vm.handleQueryAllCidCode,
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.handleQueryAllCidCode.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("button.query")) +
                                "\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._l(_vm.allCidList, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "load-message-card" },
                    [
                      _c("ips-value-cell", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: item.parentCidCode,
                            expression: "item.parentCidCode",
                          },
                        ],
                        attrs: {
                          title: _vm.$t("label.shelvesParentCid"),
                          value: item.parentCidCode,
                        },
                      }),
                      _c("ips-value-cell", {
                        attrs: {
                          title: _vm.$t("label.shelvesCid"),
                          value: item.cidCode,
                        },
                      }),
                      _c("ips-value-cell", {
                        staticClass: "ips-value-cell",
                        attrs: {
                          title: _vm.$t("label.warehouse"),
                          value: item.warehouseCode,
                        },
                      }),
                      _c("ips-value-cell", {
                        attrs: {
                          title: _vm.$t("label.targetWharea"),
                          value: item.toWhareaCode,
                        },
                      }),
                      _c("ips-value-cell", {
                        attrs: {
                          title: _vm.$t("label.targetLocation"),
                          value: item.toLocationCode,
                        },
                      }),
                      _c("ips-value-cell", {
                        attrs: {
                          title: _vm.$t("label.targetTray"),
                          value: item.toCidCOde,
                        },
                      }),
                    ],
                    1
                  )
                }),
              ],
              2
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }