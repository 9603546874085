<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.acceptCidDetail')" :is-index="false" />
    </div>
    <div class="ips-container">
      <ips-page-head :title-info="{cidCode}" :content-info="{'amount': receiveQty}" />

      <!-- <div class="scroll_box"> -->
      <hips-scroll>
        <ips-page-card
          v-for="(item, index) in lineData"
          :key="index"
          :react-field="['sku', 'amount', 'uomName', 'batchCode']"
          :link-field="['batchCode']"
          :link-field-event="() => goToCreateBatch(index, item)"
          :item="{...item, unReceiveQty: item.planQty - item.receiveQty, amount: item.packQty}"
        >
          <div class="content-line">
            <span>{{ $t('label.orderNumber') }}:</span> <span class="card_content_span_value">{{ item.billCode }}</span> 
          </div>
          <ips-message-tip :message="item.goodsName" />
        </ips-page-card>
      </hips-scroll>
    </div>
    <!-- </div> -->
  </hips-view>
</template>

<script>
export default {
  name: 'ReceiveCidDetail',
  data() {
    return {
      lineData: [],
      receiveQty: 0,
      cidCode: this.$i18n.t('message.noData'),
    };
  },

  methods: {
    /**
    * 数据初始化
    */
    getDataFromParams() {
      const { prepackagedData, receiveQty, cidCode } = this.$route.params;

      this.lineData = prepackagedData;
      this.receiveQty = receiveQty;
      this.cidCode = cidCode;
      // this.allowSkuItem = prepackagedData.map((item) => item.batchId);
    },
    //返回
    goBack() {
      this.$router.backPage();
    },

    /**
     * 前往批次创建界面
     */
    goToCreateBatch(index, sku) {
      // 只允许没有批次id的才能跳转
      if(!sku.batchId && sku.batchCode) {
        const urlParam = {
          name: 'createBatch',
          params: {
            skuInfo: {
              ...sku,
            },
            batchCode: sku.batchCode,
            formCode: 'cid',
          },
        };
        this.$router.pushPage(urlParam);
        this.cacheSkuIndex = index;
      }
    },
  },
  mounted() {
    this.getDataFromParams();
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'createBatch') {
      this.$store.commit('pushKeepAlives', 'ReceiveCidDetail');
    } else {
      this.$store.commit('deleteKeepAlives', 'ReceiveCidDetail');
    }
    this.$nextTick(() => {
      next();
    });
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name === 'createBatch') {
        const batchId = vm.$storage.get('cid-batchId');
        if(batchId && vm.cacheSkuIndex) {
          vm.lineData = vm.lineData.map((line, i) => {
            if(i === vm.cacheSkuIndex) {
              return {...line, batchId}
            }
            return line
          })
        }
        vm.cacheSkuIndex = null;
        vm.$storage.remove('cid-batchId');
      }
    });
  },
};
</script>

<style lang="stylus" scoped>
 @import '~@/style/var.styl'
  .acceptance-detail-item {
    border-top: 2px solid #e8e8e8
  }
  .scroll_box {
    position: absolute;
    margin-top: 21vw;
    height calc(100% - 109px);
    width: 96%
    padding-bottom: 10px
  }

  .card_box {
    margin 10px 5px 16px 5px !important 
  }
  
  .content-line {
    width: 100%;
    display: inline-block;
    margin-top: 18px;
    color: #8C8C8C;
  }
  .card_content_span_value {
    color: #262626
    margin-left: 5px;
  }
</style>
