import request from '@/request';
import mixin from '@/mixin/mixin'; // 引入vue实例
import { queryStringify } from './../../utils/common'

// 获取语言列表
export function getLovCodeList(data) {
  let url= `${mixin.data().hpfmBaseUrl}lovs/data?lovCode=HPFM.LANGUAGE`
  console.log(url);
  return request.get(url, data);
}

// 给用户设置语言
export function setLovCode(data) {
  let parmasUrl = queryStringify(data)
  let url= `iam/hzero/v1/users/default-language?${parmasUrl}`
  return request.put(url, data);
}


// 获取可选租户
export function getSelfTenants(data) {
  let url= `iam/hzero/v1/users/self-tenants`
  return request.get(url, data);
}

// 给用户设置租户
export function setTenantId(data) {
  let parmasUrl = queryStringify(data)
  let url= `iam/v1/users/tenant-id?${parmasUrl}`
  return request.put(url, data);
}
