var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "hips-view",
    { attrs: { "header-fixed": true } },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("ips-header", {
            attrs: { title: _vm.$t("title.unloading"), "is-index": false },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "ips-container" }, [
        _c("div", { staticClass: "ips-table" }, [
          _c(
            "table",
            [
              _c(
                "tr",
                { staticStyle: { "background-color": "#eee" } },
                _vm._l(_vm.tableTitle, function (item, index) {
                  return _c("th", { key: index, attrs: { scope: "col" } }, [
                    _vm._v(
                      "\n            " + _vm._s(item.name) + "\n          "
                    ),
                  ])
                }),
                0
              ),
              _vm._l(_vm.showTaskList, function (item, index) {
                return _c("tr", { key: index }, [
                  _c("td", [_vm._v(_vm._s(item.ownerCode))]),
                  _c("td", [_vm._v(_vm._s(item.sku))]),
                  _c("td", [_vm._v(_vm._s(item.batchCode))]),
                  _c("td", [_vm._v(_vm._s(item.qtyScan))]),
                  _c("td", [_vm._v(_vm._s(item.uomCode))]),
                ])
              }),
            ],
            2
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "ips-footer" },
        [
          _c(
            "hips-button",
            {
              attrs: { size: "large" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.openPopup.apply(null, arguments)
                },
              },
            },
            [
              _c("svg-icon", {
                attrs: { slot: "icon", "icon-class": "success" },
                slot: "icon",
              }),
              _vm._v(
                "\n      " + _vm._s(_vm.$t("label.confirmDischarge")) + "\n    "
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "hips-popup",
        {
          attrs: { "z-index": 1 },
          model: {
            value: _vm.showConfirm,
            callback: function ($$v) {
              _vm.showConfirm = $$v
            },
            expression: "showConfirm",
          },
        },
        [
          _c(
            "div",
            { staticClass: "unloadingConfirm" },
            [
              _c("h1", [_vm._v(_vm._s(_vm.$t("label.confirmDischarge")))]),
              _vm.allowLocationReplace === "1"
                ? _c(
                    "div",
                    { staticStyle: { "margin-bottom": "12px" } },
                    [
                      _c("ips-lov", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.allowLocationReplace === "1"
                                ? _vm.customData["toLocationCodeByConfirm"]
                                  ? _vm.hideControl["toLocationCodeByConfirm"]
                                  : _vm.allowLocationReplace === "1"
                                : false,
                            expression:
                              "allowLocationReplace==='1' ? (customData['toLocationCodeByConfirm'] ? hideControl['toLocationCodeByConfirm'] : allowLocationReplace==='1') : false",
                          },
                        ],
                        ref: "ref0",
                        attrs: {
                          label: _vm.$t("label.targetLocation"),
                          title: _vm.$t("label.targetLocation"),
                          required: "",
                          params: "toLocationCode",
                          url:
                            _vm.winvBaseUrl +
                            "app-outbound-unload/query-to-locations",
                          "url-params": {
                            loadCidCode: _vm.loadCidCode,
                            loadCidId: _vm.loadCidId,
                          },
                          "pre-parse-url":
                            _vm.urlData["toLocationCodeByConfirm"],
                          custom: _vm.customData["toLocationCodeByConfirm"],
                          "source-parse-field": _vm.sourceParseField,
                          "z-index": "9997",
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.handleCommonClick(0)
                          },
                          clickIcon: function ($event) {
                            return _vm.handleCommonClick(0, "lov")
                          },
                          choose: function ($event) {
                            return _vm.handleChoose("location", $event)
                          },
                          enter: _vm.handleLocationEnter,
                          setSourceField: function ($event) {
                            return _vm.saveSourceField($event)
                          },
                          valuedByFoucs: function ($event) {
                            return _vm.valuedByFoucs($event)
                          },
                        },
                        model: {
                          value: _vm.toLocationCodeByConfirm,
                          callback: function ($$v) {
                            _vm.toLocationCodeByConfirm = $$v
                          },
                          expression: "toLocationCodeByConfirm",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.allowLocationReplace === "0"
                ? _c(
                    "hips-row",
                    { staticStyle: { "margin-bottom": "12px" } },
                    [
                      _c("hips-col", { attrs: { span: "7" } }, [
                        _c(
                          "span",
                          { staticClass: "unloading-card-coloumns-left" },
                          [_vm._v(_vm._s(_vm.$t("label.targetLocation")))]
                        ),
                      ]),
                      _c("hips-col", { attrs: { span: "17" } }, [
                        _c("span", { staticClass: "popup-column-right" }, [
                          _vm._v(_vm._s(_vm.toLocationCodeByConfirm)),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "hips-row",
                { staticStyle: { "margin-bottom": "12px" } },
                [
                  _c("hips-col", { attrs: { span: "7" } }, [
                    _c(
                      "span",
                      { staticClass: "unloading-card-coloumns-left" },
                      [_vm._v(_vm._s(_vm.$t("label.locationName")))]
                    ),
                  ]),
                  _c("hips-col", { attrs: { span: "17" } }, [
                    _c("span", { staticClass: "popup-column-right" }, [
                      _vm._v(_vm._s(_vm.locationInfo.toLocationName)),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "hips-row",
                { staticStyle: { "margin-bottom": "12px" } },
                [
                  _c("hips-col", { attrs: { span: "7" } }, [
                    _c(
                      "span",
                      { staticClass: "unloading-card-coloumns-left" },
                      [_vm._v(_vm._s(_vm.$t("label.targetWharea")))]
                    ),
                  ]),
                  _c("hips-col", { attrs: { span: "17" } }, [
                    _c("span", { staticClass: "popup-column-right" }, [
                      _vm._v(_vm._s(_vm.locationInfo.toWhareaCode)),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "hips-row",
                [
                  _c("hips-col", { attrs: { span: "7" } }, [
                    _c(
                      "span",
                      { staticClass: "unloading-card-coloumns-left" },
                      [_vm._v(_vm._s(_vm.$t("label.whareaName")))]
                    ),
                  ]),
                  _c("hips-col", { attrs: { span: "17" } }, [
                    _c("span", { staticClass: "popup-column-right" }, [
                      _vm._v(_vm._s(_vm.locationInfo.toWhareaName)),
                    ]),
                  ]),
                ],
                1
              ),
              _c("ips-lov", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.customData["toCidCodeByConfirm"]
                      ? _vm.hideControl["toCidCodeByConfirm"]
                      : true,
                    expression:
                      "customData['toCidCodeByConfirm'] ? hideControl['toCidCodeByConfirm']: true",
                  },
                ],
                ref: "ref10",
                attrs: {
                  label: _vm.$t("label.targetTray"),
                  title: _vm.$t("label.targetTray"),
                  params: "toCidCode",
                  url:
                    _vm.winvBaseUrl + "app-outbound-unload/query-to-containers",
                  "url-params": {
                    toLocationCode: _vm.toLocationCodeByConfirm,
                    toLocationId: _vm.toLocationId,
                    allProcessFlag: _vm.allProcessFlag,
                    loadCidId: _vm.loadCidId,
                    loadCidCode: _vm.loadCidCode,
                  },
                  "pre-parse-url": _vm.urlData["toCidCodeByConfirm"],
                  custom: _vm.customData["toCidCodeByConfirm"],
                  "source-parse-field": _vm.sourceParseField,
                  "z-index": "9999",
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleCommonClick(10)
                  },
                  clickIcon: function ($event) {
                    return _vm.handleCommonClick(10, "lov")
                  },
                  choose: function ($event) {
                    return _vm.handleChoose("cid", $event)
                  },
                  blur: _vm.toCidBlur,
                  enter: function ($event) {
                    _vm.isReplace
                      ? _vm.skipNext(20)
                      : _vm.skipNext("popupConfirmRef")
                  },
                  setSourceField: function ($event) {
                    return _vm.saveSourceField($event)
                  },
                  valuedByFoucs: function ($event) {
                    return _vm.valuedByFoucs($event)
                  },
                },
                model: {
                  value: _vm.toCidCodeByConfirm,
                  callback: function ($$v) {
                    _vm.toCidCodeByConfirm = $$v
                  },
                  expression: "toCidCodeByConfirm",
                },
              }),
              _c("ips-lov", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isReplace
                      ? _vm.customData["ReasonCodeByConfirm"]
                        ? _vm.hideControl["ReasonCodeByConfirm"]
                        : _vm.isReplace
                      : false,
                    expression:
                      "isReplace ? (customData['ReasonCodeByConfirm'] ? hideControl['ReasonCodeByConfirm'] : isReplace) : false",
                  },
                ],
                ref: "ref20",
                attrs: {
                  label: _vm.$t("label.replaceReason"),
                  title: _vm.$t("label.replaceReason"),
                  params: "meaning",
                  "is-page-content": false,
                  required: _vm.needReason === "1",
                  url: _vm.wmdmBaseUrl + "wms-common/lov-data-query",
                  "url-params": { lovCode: "WINV.OUTBOUND_LOAD_REASON" },
                  "pre-parse-url": _vm.urlData["ReasonCodeByConfirm"],
                  custom: _vm.customData["ReasonCodeByConfirm"],
                  "source-parse-field": _vm.sourceParseField,
                  "z-index": "9999",
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleCommonClick(20)
                  },
                  clickIcon: function ($event) {
                    return _vm.handleCommonClick(20, "lov")
                  },
                  choose: function ($event) {
                    return _vm.handleChoose("replace", $event)
                  },
                  enter: function ($event) {
                    return _vm.skipNext("popupConfirmRef")
                  },
                  setSourceField: function ($event) {
                    return _vm.saveSourceField($event)
                  },
                  valuedByFoucs: function ($event) {
                    return _vm.valuedByFoucs($event)
                  },
                },
                model: {
                  value: _vm.ReasonCodeByConfirm,
                  callback: function ($$v) {
                    _vm.ReasonCodeByConfirm = $$v
                  },
                  expression: "ReasonCodeByConfirm",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "ips-popup-footer" },
            [
              _c("hips-button", { on: { click: _vm.cancel } }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("button.cancel")) + "\n      "
                ),
              ]),
              _c(
                "hips-button",
                {
                  ref: "popupConfirmRef",
                  staticStyle: { color: "#108EE9" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleButtonSubmit.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("button.confirm")) + "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }