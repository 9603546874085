<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.unloading')" :is-index="false" />
    </div>
    <div class="ips-container">
      <div class="ips-table">
        <!--卸货table-->
        <table>
          <tr style="background-color: #eee">
            <th v-for="(item, index) in tableTitle" :key="index" scope="col">
              {{ item.name }}
            </th>
          </tr>
          <tr v-for="(item, index) in showTaskList" :key="index">
            <td>{{ item.ownerCode }}</td>
            <td>{{ item.sku }}</td>
            <td>{{ item.batchCode }}</td>
            <td>{{ item.qtyScan }}</td>
            <td>{{ item.uomCode }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="ips-footer">
      <hips-button
        size="large"
        @click.stop="openPopup"
      >
        <svg-icon slot="icon" icon-class="success" />
        {{ $t('label.confirmDischarge') }}
      </hips-button>
    </div>
    <!--卸货确认弹窗-->
    <hips-popup v-model="showConfirm" :z-index="1">
      <div class="unloadingConfirm">
        <h1>{{ $t('label.confirmDischarge') }}</h1>
        <!--目标库位-->
        <div v-if="allowLocationReplace==='1'" style="margin-bottom: 12px">
          <ips-lov
            :label="$t('label.targetLocation')"
            :title="$t('label.targetLocation')"
            required
            v-model="toLocationCodeByConfirm"
            v-show="allowLocationReplace==='1' ? (customData['toLocationCodeByConfirm'] ? hideControl['toLocationCodeByConfirm'] : allowLocationReplace==='1') : false"
            params="toLocationCode"
            :url="`${winvBaseUrl}app-outbound-unload/query-to-locations`"
            :url-params="{ loadCidCode, loadCidId}"
            :pre-parse-url="urlData['toLocationCodeByConfirm']"
            :custom="customData['toLocationCodeByConfirm']"
            :source-parse-field="sourceParseField"
            ref="ref0"
            @click.stop="handleCommonClick(0)"
            @clickIcon="handleCommonClick(0, 'lov')"
            @choose="handleChoose('location', $event)"
            @enter="handleLocationEnter"
            @setSourceField="saveSourceField($event)"
            @valuedByFoucs="valuedByFoucs($event)"
            z-index="9997"
          />
        </div>
        <!-- 目标库位 -->
        <hips-row v-if="allowLocationReplace==='0'" style="margin-bottom: 12px">
          <hips-col span="7">
            <span class="unloading-card-coloumns-left">{{ $t('label.targetLocation') }}</span>
          </hips-col>
          <hips-col span="17">
            <span class="popup-column-right">{{ toLocationCodeByConfirm }}</span>
          </hips-col>
        </hips-row>
        <!-- 库位名称 -->
        <hips-row style="margin-bottom: 12px">
          <hips-col span="7">
            <span class="unloading-card-coloumns-left">{{ $t('label.locationName') }}</span>
          </hips-col>
          <hips-col span="17">
            <span class="popup-column-right">{{ locationInfo.toLocationName }}</span>
          </hips-col>
        </hips-row>
        <!-- 目标库区 -->
        <hips-row style="margin-bottom: 12px">
          <hips-col span="7">
            <span class="unloading-card-coloumns-left">{{ $t('label.targetWharea') }}</span>
          </hips-col>
          <hips-col span="17">
            <span class="popup-column-right">{{ locationInfo.toWhareaCode }}</span>
          </hips-col>
        </hips-row>
        <!-- 库区名称 -->
        <hips-row>
          <hips-col span="7">
            <span class="unloading-card-coloumns-left">{{ $t('label.whareaName') }}</span>
          </hips-col>
          <hips-col span="17">
            <span class="popup-column-right">{{ locationInfo.toWhareaName }}</span>
          </hips-col>
        </hips-row>
        <!-- 目标托盘 -->
        <ips-lov
          :label="$t('label.targetTray')"
          :title="$t('label.targetTray')"
          v-model="toCidCodeByConfirm"
          v-show="customData['toCidCodeByConfirm'] ? hideControl['toCidCodeByConfirm']: true"
          params="toCidCode"
          :url="`${winvBaseUrl}app-outbound-unload/query-to-containers`"
          :url-params="{toLocationCode: toLocationCodeByConfirm, toLocationId, allProcessFlag, loadCidId, loadCidCode}"
          :pre-parse-url="urlData['toCidCodeByConfirm']"
          :custom="customData['toCidCodeByConfirm']"
          :source-parse-field="sourceParseField"
          ref="ref10"
          @click.stop="handleCommonClick(10)"
          @clickIcon="handleCommonClick(10, 'lov')"
          @choose="handleChoose('cid', $event)"
          @blur="toCidBlur"
          @enter="isReplace ? skipNext(20) : skipNext('popupConfirmRef')"
          @setSourceField="saveSourceField($event)"
          @valuedByFoucs="valuedByFoucs($event)"
          z-index="9999"
        />
        <!--替换原因-->
        <ips-lov
          :label="$t('label.replaceReason')"
          :title="$t('label.replaceReason')"
          v-model="ReasonCodeByConfirm"
          v-show="isReplace ? (customData['ReasonCodeByConfirm'] ? hideControl['ReasonCodeByConfirm'] : isReplace) : false"
          params="meaning"
          :is-page-content="false"
          :required="needReason === '1'"
          :url="`${wmdmBaseUrl}wms-common/lov-data-query`"
          :url-params="{lovCode:'WINV.OUTBOUND_LOAD_REASON'}"
          :pre-parse-url="urlData['ReasonCodeByConfirm']"
          :custom="customData['ReasonCodeByConfirm']"
          :source-parse-field="sourceParseField"
          ref="ref20"
          @click.stop="handleCommonClick(20)"
          @clickIcon="handleCommonClick(20, 'lov')"
          @choose="handleChoose('replace', $event)"
          @enter="skipNext('popupConfirmRef')"
          @setSourceField="saveSourceField($event)"
          @valuedByFoucs="valuedByFoucs($event)"
          z-index="9999"
        />
      </div>
      <div class="ips-popup-footer">
        <hips-button
          @click="cancel"
        >
          {{ $t('button.cancel') }}
        </hips-button>
        <hips-button
          style="color: #108EE9"
          ref="popupConfirmRef"
          @click.stop="handleButtonSubmit"
        >
          {{ $t('button.confirm') }}
        </hips-button>
      </div>
    </hips-popup>
  </hips-view>

</template>

<script>
import { queryUnloadTask, confirmUnloading, cancelUnloading } from '../api'
export default {
  name: 'UnloadingConfirm',
  data() {
    return {
      showTaskList: [],
      taskList: [],
      tableTitle: [],
      showConfirm: true,
      toLocationCodeByConfirm: '',
      loadCidCode: '',
      loadCidId: '',
      allProcessFlag: 1,
      locationInfo: {
        toLocationName: '',
        toWhareaCode: '',
        toWhareaName: '',
      },
      toCidCodeByConfirm: '',
      ReasonCodeByConfirm: '',
      isReplace: false,
      needReason: '1', // 原因是否必输 默认为1 必输
      allowLocationReplace: '',
      toLocationId: '',
      showTargetTray: true,
      urlData:{},
      customData: {}, // 个性化配置信息列表
      hideControl: {}, // 个性化配置显示隐藏控制
      sourceParseField: {}, // 界面中所有的来源解析字段
    };
  },
  methods: {
    /**
     *@functionName: saveSourceField
     *@description: 保存来源解析字段并赋值Enter
     *@params1: fieldObj[Object] 来源解析字段对象,
     */
    saveSourceField(fieldObj) {
      this.saveSourceParseField(this, fieldObj);
    },
    // 字段聚焦时赋值操作
    async valuedByFoucs(options={}) {
      const { custom ={} , currentValue } = options;
      await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
    },
    /**
     * 目标库位enter事件
    **/
    handleLocationEnter() {
      this.$nextTick(()=>{
        if(this.showTargetTray) {
          this.skipNext(10);
        }else{
          if(this.isReplace) {
            this.skipNext(20);
          }else {
            this.skipNext(10);
          }
        }
      })
    },

    /**
     *  目标托盘失焦事件
     */
    toCidBlur() {
      // code清空的同时清除id
      if(!this.toCidCodeByConfirm) {
        this.toCidId = null;
      }
    },

    /**
     * 弹窗确认卸货事件
     */
    async handleButtonSubmit() {
      try {
        const { taskList, toCidCodeByConfirm, toCidId, unloadReplaceReason, ReasonCodeByConfirm, loadCidCode, loadCidId, toLocationCodeByConfirm, toLocationId, isReplace, needReason} = this;
        if (isReplace && needReason === '1') {
          const validateLov = this.$refs['ref20'] && this.$refs['ref20'].validateLov
          if (!ReasonCodeByConfirm || !validateLov || (validateLov && !unloadReplaceReason)) { // 防止其选择一个之后 又重新输入 再没有选择的情况
            this.$hint.showFailed(this.$i18n.t('message.selectReplaceReason'), true)
            return
          }
        }
        this.$hint.showLoading();
        this.handleSubmitStatus();
        // const url = `${this.winvBaseUrl}app-outbound-unload/unload`;
        const data = {
          taskList,
          toCidCode: toCidCodeByConfirm,
          toCidId,
          loadCidCode,
          loadCidId,
          toLocationCode: toLocationCodeByConfirm,
          unloadReplaceReason,
          toLocationId,
        };
        // await this.$http.post(url, param);
        await confirmUnloading(data);
        await this.clearSourceFields(this, true);
        this.$hint.showSuccess(this.$i18n.t('message.unloadingSuccess'));
        // const queryUrl = `${this.winvBaseUrl}app-outbound-unload/query-unload-tasks`;
        let params = {
          loadCidCode,
          loadCidId,
        };
        // const list = await this.$http.get(queryUrl, { params });
        const list = await queryUnloadTask({ params })
        if (!list || list.length === 0) {
          this.$store.commit('deleteKeepAlives', 'UnloadingDetail');
          this.$router.backPage(false, 2);
        } else {
          sessionStorage.setItem('refresh', 'true');
          this.$store.commit('deleteKeepAlives', 'IpsSerial');
          this.$router.backPage(true, 1);
        }
      } catch(e) {
        console.log('错误：' + e);
      } finally {
        this.$hint.hideLoading();
        this.handleSubmitStatus();
      }
    },
    /**
     * lov选中事件
     * @param {String} type - 定位lov
     * @param {object} obj - 选中行的数据
     */
    handleChoose(type, obj) {
      switch (type) {
        case 'location':
          this.locationInfo = obj;
          this.toLocationId = obj.toLocationId;
          // this.showTargetTray = obj.containerManageFlag !== 0 && obj.containerManageFlag !== null && obj.containerManageFlag !== '';
          this.locationReplace();
          break;
        case 'replace':
          this.unloadReplaceReason = obj.value;
          break;
        case 'cid':
          this.toCidId = obj.toCidId;
          break;
        default:
          return null;
      }
    },
    /**
     * 弹窗取消按钮事件
     */
    cancel() {
      this.showConfirm = false;
      this.toLocationCodeByConfirm = this.defaultLocation;
    },
    /**
     * 获取上个页面的传参
     */
    getDataFromParam() {
      const {
        showTaskList,
        taskList = [],
        allTaskList = [],
        loadCidCode,
        loadCidId,
        defaultLocation,
        locationInfo,
        needReason,
        allowLocationReplace,
        qtyModified,
        sourceParseField,
        customData,
        hideControl,
      } = this.$route.params;
      this.sourceParseField = sourceParseField;
      this.customData = customData;
      this.hideControl = hideControl;
      this.showTaskList = showTaskList;
      this.taskList = taskList;
      this.loadCidCode = loadCidCode;
      this.loadCidId = loadCidId;
      this.defaultLocation = defaultLocation;
      this.toLocationCodeByConfirm = defaultLocation;
      this.toCidId = loadCidId;
      this.toLocationId = locationInfo.toLocationId;
      this.needReason = needReason;
      this.locationInfo = locationInfo;
      this.allowLocationReplace = allowLocationReplace;
      this.allProcessFlag = (taskList.length === allTaskList.length && !qtyModified) ? 1 : 0;
      if (this.allowLocationReplace === '1') {
        this.$nextTick(() => {
          this.skipNext(0)
        })
      } else {
        this.skipNext(10)
      }
    },
    /**
     * 卸货确认事件
     */
    openPopup () {
      this.showConfirm = true;
      if (this.allowLocationReplace === '1') {
        this.$nextTick(() => {
          this.skipNext(0);
        })
      }
    },
    locationReplace() {
      const { defaultLocation, toLocationCodeByConfirm } = this;
      if (defaultLocation !== toLocationCodeByConfirm) {
        this.isReplace = true;
      }
    },
  },
  async mounted() {
    this.urlData = this.handleData('DROP_CONFIRM');
    await this.getDataFromParam();
    this.inputFieldList = [
        { type: 'lov', required: false, ref: 0, bind: 'toLocationCodeByConfirm', field: 'toLocationCodeByConfirm', isHide: this.allowLocationReplace !== '1' },
        { type: 'lov', required: false, ref: 10, bind: 'toCidCodeByConfirm', field: 'toCidCodeByConfirm', isHide: !this.showTargetTray },
        { type: 'lov', required: this.needReason === '1', ref: 20, bind: 'ReasonCodeByConfirm', field: 'ReasonCodeByConfirm', isHide: !this.isReplace },
    ];
    this.tableTitle = [
      {
        name: this.$t('label.ownerCode'),  // 货主
      },
      {
        name: this.$t('label.skuCode'),  // 物料编码
      },
      {
        name: this.$t('label.batch'), // 批次
      },
      {
        name: this.$t('label.quantity'), // 数量
      },
      {
        name: this.$t('label.unit'), // 单位
      },
    ];
  },
  async beforeRouteLeave(to, from, next) {
    try {
      this.$hint.showLoading();
      // const url = `${this.winvBaseUrl}app-outbound-unload/clear-task`
      // await this.$http.post(url, {});
      await cancelUnloading({})
    } catch (e) {
      console.log('错误：' + e);
    } finally {
      this.$hint.hideLoading();
    }
    this.$nextTick(() => {
      next();
    });
  }
}
</script>

<style lang="stylus" scoped>
 @import '~@/style/var.styl'
.ips-table {
  width 100%
  overflow-x auto
  table {
    width 100%
  }
  th, td {
    padding:5px 2px
    border 1px solid #ccc
    text-align center
    white-space nowrap
  }
}
.unloadingConfirm {
  width 70vw
  padding 0 10px
  h1 {
    font-size 18px
    text-align center
    margin 20px 0
  }
}

.popup-column-right {
  display inline-block;
  width: 80%;
  font-size:14px;
  font-weight:500;
  color:rgba(0,0,0,0.85);
  line-height:16px;
  margin-left 5px;
}

</style>

