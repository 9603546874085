<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.associatedSite')" />
    </div>
    <div class="ips-container">
      <!-- 分拣台 -->
      <ips-lov
        :label="$t('label.pigeonhole')"
        :title="$t('label.pigeonhole')"
        v-model="inputData.pigeonhole"
        v-show="customData['pigeonhole'] ? hideControl['pigeonhole']: true"
        params="sourceBillCode"
        display="sourceBillCode"
        :url="`${winvBaseUrl}app-inbound/query-source-inbound-order`"
        :pre-parse-url="urlData['pigeonhole']"
        :custom="customData['pigeonhole']"
        :source-parse-field="sourceParseField"
        ref="ref0"
        is-control
        @click.stop="handleCommonClick(0)"
        @clickIcon="handleCommonClick(0, 'lov')"
        @enter="skipNext(10)"
        @down="skipNext(10)"
        @choose="handleChoose('pigeonhole', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 波次单号 -->
      <ips-lov
        :label="$t('label.waveNum')"
        :title="$t('label.waveNum')"
        required
        v-model="inputData.billCode"
        v-show="customData['billCode'] ? hideControl['billCode']: true"
        params="billCode"
        display="billCode"
        :url="`${winvBaseUrl}app-sorting-locations/query-wave`"
        :pre-parse-url="urlData['billCode']"
        :custom="customData['billCode']"
        :source-parse-field="sourceParseField"
        ref="ref10"
        @click.stop="handleCommonClick(10)"
        @clickIcon="handleCommonClick(10, 'lov')"
        @enter="skipNext('submitRef')"
        @down="skipNext('submitRef')"
        @choose="handleChoose('billCode', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
    </div>
    <div class="ips-footer">
      <hips-button
        size="large"
        @click.stop="handleCommonSubmit"
        @keyup.enter.native="handleCommonSubmit"
        @keypress.native.prevent
        ref="submitRef"
      >
        <svg-icon slot="icon" icon-class="success" />
        {{ $t('button.confirm') }}
      </hips-button>
    </div>
  </hips-view>
</template>

<script>
  import {queryDefaultInfo} from "../api";
  import { Cell } from "@hips/vue-ui";
  import { storage } from '@/utils';
  export default {
    name: "AssociatedSite",
    data() {
      return {
        inputData: {
          pigeonhole: "",
          billId: "",
          billCode: "",
        },
        sourceBillId: "",
        urlData:{},
        customData: {}, // 个性化配置信息列表
        hideControl: {}, // 个性化配置显示隐藏控制
        sourceParseField: {}, // 界面中所有的来源解析字段
      };
    },
    components: {
      [Cell.name]: Cell
    },
    methods: {
      /**
       *@functionName: saveSourceField
       *@description: 保存来源解析字段并赋值Enter
       *@params1: fieldObj[Object] 来源解析字段对象,
       fieldCode[String] 来源解析字段
       parseValue[String] 二维码解析值
       subFields[Array]] 关联子字段
       */
      saveSourceField(fieldObj) {
        this.saveSourceParseField(this, fieldObj);
      },
      // 字段聚焦时赋值操作
      async valuedByFoucs(options={}) {
        const { custom ={} , currentValue } = options;
        await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
      },
      /**
       * lov选择框，选择数据
       * @param type lov
       * @param obj 选中对象
       */
      handleChoose(type, obj) {
        switch (type) {
          case 'pigeonhole':
            this.sourceBillId = obj.sourceBillId;
            break;
          case 'billCode':
            this.inputData.billCode = obj.billCode;
            this.inputData.billId = obj.billId;
            break;
          default:
            return null;
        }
      },

      /**
       * 提交
       */
      async handleButtonSubmit() {
        // const pageCode = this.$storage.get('pageCode');
        if (!this.inputData.billCode) {
          this.$hint.showFailed(this.$i18n.t('message.checkRequired'));
          return;
        }
        try {
          // const pageCode = 'WINV_OUT_SORT';
          // const url = `${this.winvBaseUrl}app-sorting-locations/query-default-info`;
          const data = {
            params: {
              billId: this.inputData.billId,
              billCode: this.inputData.billCode,
              // pageCode: pageCode,
            }
          };
          this.$hint.showLoading();
          this.handleSubmitStatus();
          // const res = await this.$http.get(url, data);
          const res = await queryDefaultInfo(data);
          if (res.failed) {
            this.$hint.showFailed(res.message);
            return
          }
          const param = {
            name: "AssociatedSiteDetail",
            params: {
              billId: this.inputData.billId,
              billCode: this.inputData.billCode,
              data: res,
              sourceParseField: {...this.sourceParseField},
              customData: this.customData,
              hideControl: this.hideControl,
            }
          };
          this.$router.pushPage(param);
        } catch(e) {
          console.log('错误：' + e);
        } finally{
          this.$hint.hideLoading();
          this.handleSubmitStatus();
        }
      }
    },
    beforeRouteLeave(to, from, next) {
      if (to.name === 'AssociatedSiteDetail') {
        this.$store.commit('pushKeepAlives', 'AssociatedSite');
      } else {
        this.$store.commit('deleteKeepAlives', 'AssociatedSite');
      }
      this.$nextTick(() => {
        next();
      });
    },
    created() {
      const { customObj, hideControl } = this.handleCustomData(storage.get('customData'));
      this.customData = customObj;
      this.hideControl = hideControl;
      // console.log('关联播种位customData', Object.assign({}, this.customData));
    },
    mounted() {
      this.inputFieldList= [
        {type: 'lov', required: false, ref: 0, bind: 'inputData.pigeonhole', field: 'pigeonhole' },
        {type: 'lov', required: true, ref: 10 , bind: 'inputData.billCode', field: 'billCode' },
      ];
      this.urlData = this.handleData('SORT_LOCATION_MAP_QUERY');
      this.skipNext(0);
    }
  }
</script>

<style lang="stylus" scoped>
 @import '~@/style/var.styl'
</style>