var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: { title: _vm.$t("title.trayDetail"), "is-index": false },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _c("ips-value-cell", {
          attrs: { title: _vm.$t("label.startOfTray"), value: _vm.cidCode },
        }),
        _c(
          "hips-scroll",
          _vm._l(_vm.goodsList, function (item, index) {
            return _c(
              "ips-page-card",
              {
                key: index,
                attrs: {
                  "react-field": [
                    "sku",
                    "cidCode",
                    "batchCode",
                    "uomName",
                    "qty",
                    "validQty",
                  ],
                  item: Object.assign({}, item),
                },
              },
              [_c("ips-message-tip", { attrs: { message: item.goodsName } })],
              1
            )
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }