<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.singleProcessing')" :is-index="true" />
    </div>
    <div class="ips-container">
      <!-- 加工单号 -->
      <ips-lov
        :label="$t('label.processingNum')"
        :title="$t('label.processingNum')"
        v-model="billCode"
        v-show="customData['billCode'] ? hideControl['billCode']: true"
        required
        params="billCode"
        :url="`${winvBaseUrl}work-orders?statusList=PROCESSING&statusList=APPROVED`"
        :pre-parse-url="urlData['billCode']"
        :custom="customData['billCode']"
        :source-parse-field="sourceParseField"
        ref="ref0"
        @enter="skipNext(10)"
        @down="skipNext(10)"
        @choose="handleChoose('billCode', $event)"
        @click.stop="handleCommonClick(0)"
        @clickIcon="handleCommonClick(0, 'lov')"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 行号/物料 -->
      <ips-lov
        :label="$t('label.linenumSku')"
        :title="[$t('label.lineNum'), $t('label.sku')]"
        v-model="sku"
        v-show="customData['sku'] ? hideControl['sku']: true"
        required
        display="sku"
        :url="`${winvBaseUrl}app-work-order/pda`"
        :url-params="{billId, workType}"
        :pre-parse-url="urlData['sku']"
        :params="['lineNum', 'sku']"
        :custom="customData['sku']"
        :source-parse-field="sourceParseField"
        ref="ref10"
        @enter="skipNext('submitRef')"
        @down="skipNext('submitRef')"
        @choose="handleChoose('sku', $event)"
        @click.stop="handleCommonClick(10)"
        @clickIcon="handleCommonClick(10, 'lov')"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
    </div>
    <div class="ips-footer">
      <hips-button
        size="large"
        ref="submitRef"
        @keyup.enter.native="handleCommonSubmit"
        @keypress.native.prevent
        @click.stop="handleCommonSubmit"
      >
        <svg-icon
          slot="icon"
          icon-class="success"
        />
        {{ $t('button.confirm') }}
      </hips-button>
    </div>
  </hips-view>
</template>

<script>
import { storage } from '@/utils';
export default {
    name:'WorkOrderExecution',
    data() {
        return {
          billCode: '',
          sku: '',
          billId: '',
          workType: '',
          urlData:{},
          customData: {}, // 个性化配置信息列表
          hideControl: {}, // 个性化配置显示隐藏控制
          sourceParseField: {}, // 界面中所有的来源解析字段
        }
    },
  created() {
    const { customObj, hideControl } = this.handleCustomData(storage.get('customData'));
    this.customData = customObj;
    this.hideControl = hideControl;
    // console.log('加工单执行customData', Object.assign({}, this.customData));
  },
    mounted() {
      this.inputFieldList= [
        { type: 'lov', required: true, ref: 0, bind: 'billCode', field: 'billCode' },
        { type: 'lov', required: true, ref: 10, bind: 'sku', field: 'sku' },
      ];
      this.urlData = this.handleData('WORK_ORDER_EXECUTION_QUERY');
      this.skipNext(0);
    },
    activated() {
      this.skipNext(0);
      this.sku = '';
    },
    methods: {
      /**
       *@functionName: saveSourceField
       *@description: 保存来源解析字段并赋值Enter
       *@params1: fieldObj[Object] 来源解析字段对象,
       */
      saveSourceField(fieldObj) {
        this.saveSourceParseField(this, fieldObj);
      },
      // 字段聚焦时赋值操作
      async valuedByFoucs(options={}) {
        const { custom ={} , currentValue } = options;
        await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
      },
      handleChoose(type, obj) {
        switch(type) {
          case 'billCode':
            this.billId = obj.billId;
            this.billInfo = obj;
            this.workType = obj.workType;
            break;
          case 'sku':
            this.skuInfo = obj;
            break;
          default:
            return null;
        }
      },

      /**
      * handleButtonSubmit 确认
      */
      handleButtonSubmit() {
        const routeParams = {
          name: 'WorkOrderExecutionDetail',
          params: {
            detailData: {
              ...this.billInfo,
              ...this.skuInfo,
            },
            sourceParseField: {...this.sourceParseField},
            customData: this.customData,
            hideControl: this.hideControl,
          }
        };
        this.$store.commit("pushKeepAlives", "WorkOrderExecution");
        this.$store.commit("pushKeepAlives", "WorkOrderExecutionDetail");
        this.$router.pushPage(routeParams);
      }
    },

    beforeRouteLeave(to, from, next) {
      if (to.name !== 'WorkOrderExecutionDetail') {
        this.$store.commit('deleteKeepAlives', 'WorkOrderExecution');
      }
      this.$nextTick(() => {
        next();
      });
    }
}

</script>
<style lang="stylus" scoped>
</style>