var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: {
            title: _vm.$t("title.cancelPallet"),
            trigger: _vm.$t("button.detail"),
            "on-go-detail": _vm.goDetail,
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["cidCode"]
                ? _vm.hideControl["cidCode"]
                : true,
              expression:
                "customData['cidCode'] ? hideControl['cidCode']: true",
            },
          ],
          ref: "ref0",
          attrs: {
            label: _vm.$t("label.startOfTray"),
            title: _vm.$t("label.startOfTray"),
            required: "",
            params: "cidCode",
            url: _vm.winvBaseUrl + "stock-trans/out-cid-lov",
            "pre-parse-url": _vm.urlData["cidCode"],
            custom: _vm.customData["cidCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(0)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(0, "lov")
            },
            enter: function ($event) {
              return _vm.skipNext("submitRef")
            },
            down: function ($event) {
              return _vm.skipNext("submitRef")
            },
            choose: function ($event) {
              return _vm.handleChoose($event)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.cidCode,
            callback: function ($$v) {
              _vm.cidCode = $$v
            },
            expression: "cidCode",
          },
        }),
        _c("ips-value-cell", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["whareaCode"]
                ? _vm.hideControl["whareaCode"]
                : true,
              expression:
                "customData['whareaCode'] ? hideControl['whareaCode']: true",
            },
          ],
          attrs: {
            title: _vm.$t("label.whareaCode"),
            value: _vm.cidInfo.whareaCode,
          },
        }),
        _c("ips-value-cell", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["locationCode"]
                ? _vm.hideControl["locationCode"]
                : true,
              expression:
                "customData['locationCode'] ? hideControl['locationCode']: true",
            },
          ],
          attrs: {
            title: _vm.$t("label.countLocation"),
            value: _vm.cidInfo.locationCode,
          },
        }),
        _c("ips-value-cell", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["qty"] ? _vm.hideControl["qty"] : true,
              expression: "customData['qty'] ? hideControl['qty']: true",
            },
          ],
          attrs: {
            title: _vm.$t("label.availableQty"),
            value: _vm.qtyInfo.qty,
          },
        }),
        _c("ips-value-cell", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["validQty"]
                ? _vm.hideControl["validQty"]
                : true,
              expression:
                "customData['validQty'] ? hideControl['validQty']: true",
            },
          ],
          attrs: { title: _vm.$t("label.useQty"), value: _vm.qtyInfo.validQty },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-footer" },
      [
        _c(
          "hips-button",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.customData["detail"]
                  ? _vm.hideControl["detail"]
                  : true,
                expression:
                  "customData['detail'] ? hideControl['detail']: true",
              },
            ],
            ref: "nextRef",
            attrs: { size: "large" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonSubmit("N")
              },
            },
            nativeOn: {
              keyup: [
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.handleCommonSubmit("N")
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "right", 39, $event.key, [
                      "Right",
                      "ArrowRight",
                    ])
                  ) {
                    return null
                  }
                  if ("button" in $event && $event.button !== 2) {
                    return null
                  }
                  return _vm.handleRight.apply(null, arguments)
                },
              ],
              keypress: function ($event) {
                $event.preventDefault()
              },
            },
          },
          [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("button.allCancellation")) + "\n    "
            ),
          ]
        ),
        _c(
          "hips-button",
          {
            ref: "submitRef",
            attrs: { size: "large" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonSubmit("Y")
              },
            },
            nativeOn: {
              keyup: [
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.handleCommonSubmit("Y")
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "left", 37, $event.key, [
                      "Left",
                      "ArrowLeft",
                    ])
                  ) {
                    return null
                  }
                  if ("button" in $event && $event.button !== 0) {
                    return null
                  }
                  return _vm.handleLeft.apply(null, arguments)
                },
              ],
              keypress: function ($event) {
                $event.preventDefault()
              },
            },
          },
          [_vm._v("\n      " + _vm._s(_vm.$t("button.allBreakUp")) + "\n    ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }