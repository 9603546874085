<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.batchQuery')" :is-index="true" />
    </div>
    <div class="ips-container">
      <!--序列号/批次-->
      <ips-field
        v-model="batchCode"
        required
        :label="$t('label.batchSerial')"
        params="batchCode"
        display="batchCode"
        :pre-parse-url="urlData['batchCode']"
        ref="ref0"
        :custom="customData['batchCode']"
        :source-parse-field="sourceParseField"
        v-show="customData['batchCode'] ? hideControl['batchCode'] : true"
        @enter="skipNext(10)"
        @down="skipNext(10)"
        @click.stop="handleCommonClick(0)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!--物料-->
      <ips-lov
        :label="$t('label.sku')"
        v-model="sku"
        :title="[$t('label.sku'), $t('label.goodsName')]"
        :params="['sku', 'goodsName']"
        display="sku"
        :url="`${wmdmBaseUrl}batchs`"
        :url-params="{ 'batchCodeTmp': batchCode }"
        :pre-parse-url="urlData['sku']"
        ref="ref10"
        :custom="customData['sku']"
        :source-parse-field="sourceParseField"
        v-show="customData['sku'] ? hideControl['sku'] : true"
        @click.stop="handleCommonClick(10)"
        @enter="skipNext('submitRef')"
        @down="skipNext('submitRef')"
        @choose="handleChoose('sku', $event)"
        @clickIcon="handleCommonClick(10, 'lov')"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!--品名-->
      <ips-value-cell
        :title="$t('label.goodsName')"
        :value="goodsName"
        v-show="customData['goodsName'] ? hideControl['goodsName'] : true"
      />
      <!--单位-->
      <ips-value-cell
        :title="$t('label.unit')"
        :value="uomName"
        v-show="customData['uomName'] ? hideControl['uomName'] : true"
      />
    </div>
    <div class="ips-footer">
      <hips-button
        size="large"
        @click.stop="goDetail"
        ref="submitRef"
      >
        <svg-icon
          slot="icon"
          icon-class="success"
        />{{ $t('button.confirm') }}
      </hips-button>
    </div>
  </hips-view>
</template>

<script>
import { Cell } from "@hips/vue-ui";
import { storage } from '@/utils';
export default {
  name: 'BatchQuery',
  data() {
    return {
      sku: '',
      batchCode: '',
      goodsName: '',
      goodsId: '',
      uomCode: '',
      uomId: '',
      uomName: '',
      fatherProps: {},
      inputFieldList:
      [
        {type: 'input', required: true, ref: 0 , bind: 'batchCode', field:'batchCode'},
        {type: 'lov', required: false, ref: 10 , bind: 'sku', field:'sku'},
      ],
      urlData:{},
      customData: {}, // 个性化配置信息列表
      hideControl: {}, // 非必输字段的显隐控制
      sourceParseField: {}, // 界面中所有的来源解析字段
    };
  },
  components: {
    [Cell.name]: Cell
  },

  mounted() {
    this.urlData = this.handleData('SERIAL_BATCH_QUERY');
    this.skipNext(0);

  },
    created() {
    const { hideControl, customObj } = this.handleCustomData(storage.get('customData'));
    this.customData = customObj;
    this.hideControl = hideControl;
  },

  methods: {
    /**
     * lov选中事件
     * @param {String} type - 定位lov
     * @param {object} obj - 选中行的数据
     */
    handleChoose(type, obj) {
      switch (type) {
        case 'sku':
          this.chooseSku(obj);
          break;
        default:
          return null;
      }
    },
    /**
     * 选择物料
     * @param item 选中物料数据
     */
    chooseSku(item) {
      this.fatherProps = item;
      this.goodsName = item.goodsName;
      this.uomCode = item.uomCode;
      this.uomId = item.uomId;
      this.uomName = item.uomName;
      this.goodsId = item.goodsId;
    },
    /**
     * 前往批次查询明细界面
     */
    async goDetail() {
      if(!this.batchCode){
        this.$hint.showFailed(this.$i18n.t('message.checkRequired'), true)
        return
      }
      let param = {
        name: 'BatchQueryDetail',
        params: {
          batchCode: this.batchCode,
          fatherProps: this.fatherProps,
          customData: this.customData,
          hideControl: this.hideControl,
        }
      };
      this.$router.pushPage(param);
      // 清除当前界面的二维码解析字段
      await this.clearSourceFields(this, true);
    },

    // 保存来源解析字段
    async saveSourceField(fieldObj) {
      this.saveSourceParseField(this, fieldObj);
    },

    // 字段聚焦时赋值操作
    async valuedByFoucs(options={}) {
      const { custom ={} , currentValue } = options;
      await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
    },
  },
}

</script>

<style lang="stylus" scoped>
 @import '~@/style/var.styl'
</style>
