
<template>
  <div class="text-a">
    <ips-header title="测试c" />
    <input type="text" placeholder="输入框1" v-model="input.a">
    <input type="text" placeholder="输入框2" v-model="input.b">
    <input type="text" placeholder="输入框3" v-model="input.c">
    <input type="text" placeholder="输入框4" v-model="input.d">
  </div>
</template>

<script>
export default {
  name: "Textc",
  data() {
    return {
      input: {}
    };
  }
};
</script>

<style lang="stylus" scoped>
</style>