var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-page" }, [
    _c("div", { staticClass: "login-header-bg" }, [
      _c("img", {
        staticStyle: { "padding-bottom": "20px" },
        attrs: { src: _vm.logo, alt: "" },
      }),
      _c("div", { staticClass: "big-title" }, [
        _vm._v("\n      WMS仓储管理系统\n    "),
      ]),
      _c("div", { staticClass: "small-title" }, [
        _vm._v("\n      仓库作业的精细化管理\n    "),
      ]),
    ]),
    _c("form", { attrs: { onsubmit: "return false;" } }, [
      _c("div", { staticClass: "from-box" }, [
        _c("div", { staticClass: "input-item" }, [
          _vm._m(0),
          _c("div", { staticClass: "input-box" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.username,
                  expression: "username",
                },
              ],
              ref: "ref10",
              attrs: { placeholder: "请输入账号", type: "text" },
              domProps: { value: _vm.username },
              on: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.skipPassword.apply(null, arguments)
                },
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.username = $event.target.value
                },
              },
            }),
          ]),
        ]),
        _c("div", { staticClass: "input-item" }, [
          _vm._m(1),
          _c("div", { staticClass: "input-box" }, [
            (_vm.showPassword ? "text" : "password") === "checkbox"
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.password,
                      expression: "password",
                    },
                  ],
                  ref: "ref20",
                  attrs: { placeholder: "请输入密码", type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.password)
                      ? _vm._i(_vm.password, null) > -1
                      : _vm.password,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.password,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.password = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.password = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.password = $$c
                      }
                    },
                  },
                })
              : (_vm.showPassword ? "text" : "password") === "radio"
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.password,
                      expression: "password",
                    },
                  ],
                  ref: "ref20",
                  attrs: { placeholder: "请输入密码", type: "radio" },
                  domProps: { checked: _vm._q(_vm.password, null) },
                  on: {
                    change: function ($event) {
                      _vm.password = null
                    },
                  },
                })
              : _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.password,
                      expression: "password",
                    },
                  ],
                  ref: "ref20",
                  attrs: {
                    placeholder: "请输入密码",
                    type: _vm.showPassword ? "text" : "password",
                  },
                  domProps: { value: _vm.password },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.password = $event.target.value
                    },
                  },
                }),
          ]),
          _c(
            "div",
            {
              staticClass: "show-password-box",
              on: {
                click: function ($event) {
                  _vm.showPassword = !_vm.showPassword
                },
              },
            },
            [
              !_vm.showPassword
                ? _c("img", {
                    attrs: {
                      src: require("./../resources/hide-icon.png"),
                      alt: "",
                    },
                  })
                : _c("img", {
                    attrs: {
                      src: require("./../resources/show-icon.png"),
                      alt: "",
                    },
                  }),
            ]
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "cache-password" },
        [
          _c("hips-checkbox", {
            attrs: { label: "记住密码" },
            model: {
              value: _vm.isCachePass,
              callback: function ($$v) {
                _vm.isCachePass = $$v
              },
              expression: "isCachePass",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "submit-box" }, [
        _c(
          "button",
          {
            staticClass: "submit-btn",
            attrs: { type: "submit" },
            on: { click: _vm.loginFun },
          },
          [_vm._v("\n        登 录\n      ")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-label" }, [
      _c("img", {
        attrs: { src: require("./../resources/phone-icon.png"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-label" }, [
      _c("img", {
        attrs: { src: require("./../resources/password-icon.png"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }