import StockInquiry from '../views/stock-inquiry'
import StockDetail from '../views/stock-detail'


let route = [{
    path: '/stock-inquiry',
    name: 'StockInquiry',
    component: StockInquiry,
  },
  {
    path:'/stock-detail',
    name:'StockDetail',
    component: StockDetail
  }
]

export default route