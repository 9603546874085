var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "ips-container",
        staticStyle: {
          "padding-left": "0",
          "padding-right": "0",
          "padding-bottom": "0",
        },
      },
      [
        (_vm.detailIdTmpList ? !_vm.detailIdTmpList : true)
          ? _c(
              "hips-row",
              [
                _c(
                  "hips-col",
                  { attrs: { span: "14" } },
                  [
                    _c("ips-field", {
                      ref: "ref0",
                      attrs: {
                        "is-only": "",
                        "is-index": false,
                        label: _vm.$t("label.serialNumber"),
                      },
                      on: {
                        enter: _vm.checkSerialNum,
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.handleCommonClick(0)
                        },
                      },
                      model: {
                        value: _vm.serialNum,
                        callback: function ($$v) {
                          _vm.serialNum = $$v
                        },
                        expression: "serialNum",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "hips-col",
                  {
                    staticStyle: { "text-align": "right" },
                    attrs: { span: "8" },
                  },
                  [
                    _c(
                      "hips-button",
                      {
                        ref: "refSerail",
                        staticClass: "ips-new-serial-button-style",
                        attrs: { size: "large" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            _vm.showTable = true
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          查看明细(" +
                            _vm._s(_vm.tableData.length) +
                            ")\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.batchSerialNum,
                expression: "batchSerialNum",
              },
            ],
            staticClass: "center_area",
          },
          [
            _c("div", { staticClass: "entryQty-box" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("label.alreadyEntry")) +
                  ":  " +
                  _vm._s(_vm.totalSelectedNum) +
                  "\n      "
              ),
            ]),
          ]
        ),
        _c(
          "hips-popup",
          {
            attrs: { position: "bottom" },
            model: {
              value: _vm.showTable,
              callback: function ($$v) {
                _vm.showTable = $$v
              },
              expression: "showTable",
            },
          },
          [
            _c("div", { staticClass: "ips-table" }, [
              _c(
                "table",
                [
                  _c(
                    "tr",
                    { staticStyle: { "background-color": "#eee" } },
                    _vm._l(_vm.tableTitle, function (item, index) {
                      return _c(
                        "th",
                        {
                          key: index,
                          staticClass: "ips-table-thead",
                          attrs: { scope: "col" },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.name) +
                              "\n            "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._l(_vm.tableData, function (item, index) {
                    return _c(
                      "tr",
                      { key: index, ref: "tr" + index, refInFor: true },
                      [
                        _c("td", [_vm._v(_vm._s(item.serialNumber))]),
                        _c("td", { staticStyle: { position: "relative" } }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.qualityStatusName) +
                              "\n              "
                          ),
                        ]),
                        _c("td", [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.serialQty || item.qty) +
                              "\n            "
                          ),
                        ]),
                        _c("td", [
                          _c(
                            "div",
                            {
                              staticClass: "delLineIcon",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.deleteTableData(item)
                                },
                              },
                            },
                            [
                              _c("svg-icon", {
                                attrs: { slot: "icon", "icon-class": "delete" },
                                slot: "icon",
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    )
                  }),
                ],
                2
              ),
            ]),
          ]
        ),
      ],
      1
    ),
    false
      ? _c(
          "div",
          {
            staticClass: "ips-footer",
            staticStyle: { position: "fixed", bottom: "0px" },
          },
          [
            !_vm.detailIdTmpList
              ? _c(
                  "hips-button",
                  {
                    ref: "checkRef",
                    staticClass: "ips-new-serial-button-clear-style",
                    attrs: { size: "large" },
                    on: { click: _vm.selectAll },
                  },
                  [
                    _vm._v(
                      "\n      " + _vm._s(_vm.$t("button.clear")) + "\n    "
                    ),
                  ]
                )
              : _vm._e(),
            _c(
              "hips-button",
              {
                ref: "submitRef",
                staticClass: "ips-new-serial-button-confirm-style",
                attrs: { size: "large" },
                on: { click: _vm.handleButtonSubmit },
              },
              [_vm._v("\n      " + _vm._s(_vm.$t("button.confirm")) + "\n    ")]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }