var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { "background-color": "#f6f7fa" } }, [
    _c("div", { ref: "header-box-dom", staticClass: "header-box" }, [
      _c("div", { ref: "logo-box-dom", staticClass: "logo-box" }, [
        _c("img", {
          staticClass: "img-box",
          attrs: { src: _vm.logo, alt: "" },
        }),
        _c("div", { staticClass: "right" }, [
          _vm._v(
            "\n        WMS" +
              _vm._s(_vm.$t("title.warehouseManagement")) +
              "\n      "
          ),
        ]),
      ]),
      _c(
        "div",
        { ref: "search-box-dom", staticClass: "search-box" },
        [
          _c("hips-search", {
            attrs: { placeholder: "请输入搜索条件", "show-clear-button": "" },
            on: { change: _vm.handleChange },
            model: {
              value: _vm.input,
              callback: function ($$v) {
                _vm.input = $$v
              },
              expression: "input",
            },
          }),
        ],
        1
      ),
    ]),
    _c(
      "div",
      {
        staticClass: "scroll-box",
        style:
          "top:" + _vm.scrollboxTop + "Px;height:" + _vm.scrollHeight + "Px;",
      },
      [
        _c(
          "hips-scroll",
          {
            ref: "scroll",
            attrs: {
              "scroll-options": _vm.scrollOption,
              data: [_vm.functionCategorys],
            },
            on: { "pulling-down": _vm.loadRefresh },
          },
          [
            !_vm.isShowSeacher
              ? _c("div", { staticClass: "applications" }, [
                  false
                    ? _c("div", { staticClass: "func-categrory-wrap" }, [
                        _c(
                          "div",
                          { staticClass: "func-categrory" },
                          [
                            _c("div", { staticClass: "title-wrap" }, [
                              _c(
                                "div",
                                { staticClass: "title-box g-flex-between" },
                                [
                                  _c("div", { staticClass: "g-flex" }, [
                                    _c("div", { staticClass: "icon-box" }),
                                    _vm.language === "zh_CN"
                                      ? _c("div", { staticClass: "zh-box" }, [
                                          _vm._v(
                                            "\n                    常用模块\n                  "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.language === "en_US"
                                      ? _c("div", { staticClass: "en-box" }, [
                                          _vm._v(
                                            "\n                    菜单名字\n                  "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "setting-box g-flex" },
                                    [
                                      _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "4px",
                                        },
                                        attrs: {
                                          src: require("./resources/setting.png"),
                                          alt: "",
                                        },
                                      }),
                                      _c("div", [_vm._v("管理")]),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "hips-row",
                              _vm._l(
                                _vm.commonlyUsedfunctionMenus,
                                function (item, index) {
                                  return _c(
                                    "hips-col",
                                    {
                                      key: index,
                                      staticClass: "module",
                                      attrs: { span: "6" },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.enterPage(item)
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "img-box",
                                        attrs: {
                                          src: item.menuIcon,
                                          alt: "",
                                          srcset: "",
                                        },
                                      }),
                                      _c("div", { staticClass: "menu-name" }, [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.language === "zh_CN"
                                                ? item.menuName
                                                : item.menuCode
                                            ) +
                                            "\n                "
                                        ),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "func-categrory-wrap" },
                    _vm._l(_vm.functionCategorys, function (category) {
                      return _c(
                        "div",
                        {
                          key: category.categoryCode,
                          staticClass: "func-categrory",
                        },
                        [
                          _c("div", { staticClass: "title-wrap" }, [
                            _c("div", { staticClass: "title-box g-flex" }, [
                              _c("div", { staticClass: "icon-box" }),
                              _vm.language === "zh_CN"
                                ? _c("div", { staticClass: "zh-box" }, [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(category.categoryName) +
                                        "\n                "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.language === "en_US"
                                ? _c("div", { staticClass: "en-box" }, [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(category.categoryCode) +
                                        "\n                "
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                          _c(
                            "hips-row",
                            _vm._l(
                              category.functionMenus,
                              function (item, index) {
                                return _c(
                                  "hips-col",
                                  {
                                    key: index,
                                    staticClass: "module",
                                    attrs: { span: "6" },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.enterPage(item)
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "img-box",
                                      attrs: {
                                        src: item.menuIcon,
                                        alt: "",
                                        srcset: "",
                                      },
                                    }),
                                    _c("div", { staticClass: "menu-name" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.language === "zh_CN"
                                              ? item.menuName
                                              : item.menuCode
                                          ) +
                                          "\n                "
                                      ),
                                    ]),
                                  ]
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ])
              : _c("div", { staticClass: "applications" }, [
                  _c("div", { staticClass: "func-categrory-wrap" }, [
                    _vm.filterMenus.length > 0
                      ? _c(
                          "div",
                          { staticClass: "seacher-list" },
                          _vm._l(_vm.filterMenus, function (item) {
                            return _c(
                              "div",
                              {
                                key: item.menuName,
                                staticClass: "item g-flex-between",
                                on: {
                                  click: function ($event) {
                                    return _vm.enterPage(item)
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "left-image g-flex-center" },
                                  [
                                    _c("img", {
                                      staticClass: "img-box",
                                      attrs: {
                                        "data-v-665e6f6c": "",
                                        src: item.menuIcon,
                                        alt: "",
                                        srcset: "",
                                      },
                                    }),
                                    _c("div", { staticClass: "menu-text" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(item.menuName) +
                                          "\n                "
                                      ),
                                    ]),
                                  ]
                                ),
                                _c("div", { staticClass: "right-arrow" }, [
                                  _c("img", {
                                    attrs: {
                                      src: require("./resources/arrow-right.png"),
                                      alt: "",
                                    },
                                  }),
                                ]),
                              ]
                            )
                          }),
                          0
                        )
                      : _c("div", { staticClass: "no-data-box" }, [
                          _c("img", {
                            attrs: {
                              src: require("./resources/no-data.png"),
                              alt: "",
                            },
                          }),
                        ]),
                  ]),
                ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }