var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "message_box" }, [
    _c(
      "div",
      {
        class: [
          "message_text_normal",
          {
            message_text_expand: _vm.expand,
          },
        ],
      },
      [_vm._v("\n    " + _vm._s(_vm.message) + "\n  ")]
    ),
    _c(
      "div",
      { staticClass: "control_tool", on: { click: _vm.changeExpandStatus } },
      [
        _c("span", [_vm._v(_vm._s(this.$i18n.t("label.unfold")))]),
        _c("div", { staticClass: "crl_switch" }, [
          _c("span", {
            class: [
              "switch_triangle",
              {
                switch_triangle_expand: _vm.expand,
              },
            ],
          }),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }