var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [_c("ips-header", { attrs: { title: _vm.$t("title.revokeDelivery") } })],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _c("ips-input", {
          ref: "ref0",
          attrs: {
            label: _vm.$t("label.packageNo"),
            custom: _vm.customData["packCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            enter: _vm.checkPackCodeBlur,
            down: _vm.checkPackCodeBlur,
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(0)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.inputData.packCode,
            callback: function ($$v) {
              _vm.$set(_vm.inputData, "packCode", $$v)
            },
            expression: "inputData.packCode",
          },
        }),
        _c(
          "div",
          { staticClass: "ips-footer" },
          [
            _c(
              "hips-button",
              {
                ref: "submitRef",
                attrs: { size: "large" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleCommonSubmit.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n        撤销交接\n      ")]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }