var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: { title: _vm.$t("title.serialNumber"), "is-index": false },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "ips-container" }, [
      _c("div", { staticClass: "ips-table" }, [
        _c(
          "table",
          [
            _c(
              "tr",
              { staticStyle: { "background-color": "#eee" } },
              _vm._l(_vm.tableTitle, function (item, index) {
                return _c("th", { key: index, attrs: { scope: "col" } }, [
                  _vm._v("\n            " + _vm._s(item.name) + "\n          "),
                ])
              }),
              0
            ),
            _vm._l(_vm.serialDate, function (item, index) {
              return _c("tr", { key: index }, [
                _c("td", [_vm._v(_vm._s(item.serialNumber))]),
                _c("td", [_vm._v(_vm._s(item.qualityStatusName))]),
              ])
            }),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }