import request from '@/request';
import mixin  from '@/mixin/mixin';

// 确认  查询任务
export function queryProcessTask(data) {
  const url = `${mixin.data().winvBaseUrl}app-outbound-load/fetch-process-task`;
  return request.get(url, data);
}

// 校验扫描数量
export function checkScanQty(data) {
  const url = `${mixin.data().winvBaseUrl}app-outbound-load/check-qty`;
  return request.post(url, data);
}

// 跳过任务
export function skipTask(data) {
  const url = `${mixin.data().winvBaseUrl}app-outbound-load/skip-task`;
  return request.post(url, data);
}

// 清除任务
export function clearTask(data) {
  const url = `${mixin.data().winvBaseUrl}app-outbound-load/clear-task`;
  return request.post(url, data);
}

// 获取menuId
export function getAppId(param) {
  const url = `${mixin.data().winvBaseUrl}wms-common/query-hips-menu`;
  return request.get(url, param);
}

// 校验确认托盘
export function checkCidCode(data) {
  const url = `${mixin.data().winvBaseUrl}app-outbound-load/check-from-container`;
  return request.post(url, data);
}

// 校验转运托盘
export function checkLoadCidCode(data) {
  const url = `${mixin.data().winvBaseUrl}app-outbound-load/check-load-container`;
  return request.post(url, data);
}

// 校验确认托盘
export function checkQtyNumber(data) {
  const url = `${mixin.data().winvBaseUrl}app-outbound-load/query-qty`;
  return request.post(url, data);
}

// 校验批次
export function checkBatchCode(data) {
  const url = `${mixin.data().winvBaseUrl}app-outbound-load/check-batch`;
  return request.post(url, data);
}

// // 查询父托盘序列号
// export function getSerialNumberList(data) {
//   let url = `${mixin.data().winvBaseUrl}app-outbound-load/query-parent-cid-all-serial`;
//   return request.get(url, data);
// }


