import WorkOrderExecution from '../views/work-order-execution'
import WorkOrderExecutionDetail from  '../views/work-order-execution-detail'

let route = [
  {
    path: '/work-order-execution',
    name: 'WorkOrderExecution',
    component: WorkOrderExecution,
  },
  {
    path: '/work-order-execution-detail',
    name: 'WorkOrderExecutionDetail',
    component: WorkOrderExecutionDetail,
  }
]

export default route
