import Vue from 'vue';
import FastClick from 'fastclick';
import HipsRequest from './plugin-vue/request';
import App from './App.vue';
import store from './store';
import router from './router';
import http from '@/request';
import { storage, bridge } from './utils/index';
import * as common from '@utils/common';
import mixin from '@/mixin/mixin';
import hint from './utils/hint';
import './icons'; // icon
import '@/utils/register'; // 组件全局注册
import { speechTTS } from '@utils/tts';
// 引入i18n
import i18n from './i18n/vue-i18n';
import I18nProvider from './i18n/provider';
import globalConfig from './custom.config'; // 引入配置文件

// 增加低版本安卓适配
import '@babel/polyfill';
import Es6Promise from 'es6-promise';
Es6Promise.polyfill();

import 'x-photoswipe/dist/photoswipe.css';
import 'x-photoswipe/dist/default-skin/default-skin.css';
import './style/reset.css';
import './style/normalize.css';

import {
  View,
  NavBar,
  Toast,
  Indicator,
  Dialog,
  Input,
  Button,
  Popup,
  Cell,
  Notify,
  Actionsheet,
  Previewer,
  Row,
  Col,
  Icon,
  Circle,
  Uploader,
  Scroll,
  Checkbox,
  CheckboxGroup,
  SwitchCell,
} from '@hips/vue-ui';

if (process.env.VUE_APP_MOCK === 'yes') {
  require('../mocks');
}

if (process.env.VUE_APP_BUILD === 'test') {
  const VConsole = require('vconsole');
  new VConsole(); // eslint-disableline-
}
if (process.env.VUE_APP_BUILD === 'dev') {
  const VConsole = require('vconsole');
  new VConsole(); // eslint-disable-line
}

Vue.use(Toast)
    .use(Indicator)
    .use(Dialog)
    .use(View)
    .use(NavBar)
    .use(Input)
    .use(Button)
    .use(Popup)
    .use(Cell)
    .use(Notify)
    .use(Actionsheet)
    .use(Previewer)
    .use(Row)
    .use(Col)
    .use(Icon)
    .use(Circle)
    .use(Uploader)
    .use(Scroll)
    .use(Checkbox)
    .use(SwitchCell)
    .use(CheckboxGroup);
Vue.prototype.$storage = storage;
Vue.prototype.$http = http;
Vue.prototype.$hint = hint;
Vue.prototype.$bridge = bridge;
Vue.prototype.$common = common;

// TTS语音播报
if (globalConfig.ENABLE_SPEECH_SYNTHESIS === 1) {
  speechTTS.bootstrap();
}
// 给notify方法打一个补丁
function patchNotify(notify) {
  return function ({ message, type, duration }) {
    // eslint-disable-next-line no-constant-condition
    if (speechTTS.getTTSBootFlag()) {
      speechTTS.speak({
        text: message,
      });
    } else if (globalConfig.ENABLE_SPEECH_SYNTHESIS === 1 && window.speechSynthesis) {
      let speechInstance = new SpeechSynthesisUtterance(message);
      for (const key in globalConfig.SPEAK_CONFIG) {
        if (key === 'lang') {
          speechInstance[key] = storage.get('language') || globalConfig.SPEAK_CONFIG[key];
        } else {
          speechInstance[key] = globalConfig.SPEAK_CONFIG[key];
        }
      }
      window.speechSynthesis.speak(speechInstance);
    }

    notify.call(this, { message, type, duration });
  };
}

Vue.prototype.$hips.notify = patchNotify(Vue.prototype.$hips.notify);

Vue.config.productionTip = false;

Vue.mixin(mixin); // 混入全局

Vue.use(HipsRequest, {
  httpTimeout: process.env.VUE_APP_HTTP_TIMEOUT,
  httpBaseUrl: globalConfig.baseUrl || process.env.VUE_APP_HTTP_BASE_URL,
  httpNoauthBaseUrl: globalConfig.baseUrl || process.env.VUE_APP_HTTP_NOAUTH_BASE_URL,
  httpHeader: globalConfig.baseUrl || process.env.VUE_APP_HTTP_HEADER,
});

router.beforeEach(async (to, from, next) => {
  i18n.locale = storage.get('language') === 'zh_CN' ? 'zh' : 'en';
  // 登录拦截
  if (to.path === '/login') {
    next();
  } else {
    let token = storage.get('user-token');
    if (token === 'null' || token === '') {
      next('/login');
    } else {
      next();
    }
  }
});
// 可以引入i18n，通过设置this.$i18n.locale = 'en' / 'zh'去修改多语言
const vue = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(I18nProvider, { props: { locale: i18n.locale } }, [h(App)]),
}).$mount('#app');

const str = navigator.userAgent.toLowerCase();
const ver = str.match(/cpu iphone os (.*?) like mac os/);

if (!ver) {
  // 非IOS系统
  // 引入fastclick 做相关处理
  FastClick.attach(document.body);
} else {
  if (parseInt(ver[1]) < 11) {
    // 引入fastclick 做相关处理
    FastClick.attach(document.body);
  }
}

export default vue;
