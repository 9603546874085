import request from '@/request';
import mixin  from '@/mixin/mixin';

// 托盘校验
export function checkCidCode(data) {
  const url = `${mixin.data().winvBaseUrl}app-miscellaneous-in/cid-verification`;
  return request.get(url, data);
}

// 托盘物料混托
export function checkGoodsMixed(data) {
  const url = `${mixin.data().winvBaseUrl}app-miscellaneous-in/goods-verification`;
  return request.get(url, data);
}

// 批次校验
export function checkBatchCode(data) {
  const url = `${mixin.data().winvBaseUrl}app-miscellaneous-in/batch-verification`;
  return request.get(url, data);
}

// 获取物料数量
export function checkGoodsQty(data) {
  const url = `${mixin.data().winvBaseUrl}stocks/qty-query-for-transfer`;
  return request.get(url, data);
}

// 杂项入库提交
export function inSunderySubmit(data) {
  let url = `${mixin.data().winvBaseUrl}app-miscellaneous-in`;
  return request.post(url, data);
}

// 查询页面参数
export function getparentCidEnabled(data) {
  let url = `${mixin.data().winvBaseUrl}page-function-paramss/info`;
  return request.get(url, data);
}
