<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header
        :title="$t('title.interceptTally')"
        :is-index="true"
        @beforeBack="goBack()"
      />
    </div>
    <div class="ips-container">
      
      <!-- 快递单号 -->
      <ips-field
        v-model="inputData.expressNo"
        required
        :label="$t('label.expressBackCode')"
        ref="ref0"
        params="expressNo"
        display="expressNo"
        :pre-parse-url="urlData['expressNo']"
        :custom="customData['expressNo']"
        @blur="validatePack()"
        @click="handleCommonClick(0)"
        @enter="skipNext('submitRef')"
        @down="skipNext('submitRef')"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 单据编号 -->
      <ips-field
        v-model="inputData.billCode"
        disabled
        :label="$t('label.deliveryCode')"
        ref="ref10"
        params="billCode"
        display="billCode"
        :pre-parse-url="urlData['billCode']"
        :custom="customData['billCode']"
      />


      <div
        class="ips-table"
        style="overflow-y: scroll"
      >
        <table>
          <tr style="background-color: #eee">
            <th
              v-for="(item, index) in tableColumns"
              :key="index"
              scope="col"
            >
              {{ item.title }}
            </th>
          </tr>
          <tr
            v-for="(item, index) in interceptTallyDetails"
            :key="item.id"
            :ref="`tr${index}`"
          >
            <td v-for="col in tableColumns" :key="col.dataIndex">
              {{ item[col.dataIndex] }}
            </td>
          </tr>
        </table>
      </div>
      
    </div>
    <div
      class="ips-footer"
    >
      <hips-button
        size="large"
        @click.stop="handleCommonSubmit"
        @keyup.enter.native="handleCommonSubmit"
        @keypress.native.prevent
        ref="submitRef"
        :class="[
          'ips-btn-normal',
          {
            'ips-btn-no-value': !inputData.billCode,
          },
        ]"
      >
        <svg-icon
          slot="icon"
          icon-class="success"
        />
        {{ $t('button.submit') }}
      </hips-button>
    </div>
  </hips-view>
</template>

<script>
import { Cell } from '@hips/vue-ui';
import { storage } from '@/utils';
import { validatePack, interceptConfirm } from '../api';
export default {
  name: 'InterceptTally',
  components: {
    [Cell.name]: Cell,
  },
  data() {
    return {
      expressData: {},
      interceptTallyDetails: [],
      inputData: {
        expressNo: '',
        billCode: '',
        receiptNumber: '',
        warehouseCode: '',
      },
      menuId: storage.get('menuId'),
      urlData: {},
      customData: {}, // 个性化配置信息列表
      hideControl: {}, // 个性化配置显示隐藏控制
      sourceParseField: {}, // 界面中所有的来源解析字段
    };
  },
  computed: {
    tableColumns(ctx) {
      return [
        {
          dataIndex: 'sku',
          title: ctx.$t('title.skuCode')
        },
        {
          dataIndex: 'goodsName',
          title: ctx.$t('label.nameOfTheMaterial')
        },
        {
          dataIndex: 'locationCode',
          title: ctx.$t('label.countLocationCode')
        },
        {
          dataIndex: 'qty',
          title: ctx.$t('label.amount')
        },
      ]
    }
  },
  methods: {
    goBack() {
      this.$router.push('/applications')
    },
    
    /**
     *@functionName: saveSourceField
     *@description: 保存来源解析字段并赋值Enter
     *@params1: fieldObj[Object] 来源解析字段对象,
     fieldCode[String] 来源解析字段
     parseValue[String] 二维码解析值
     subFields[Array]] 关联子字段
     */
    saveSourceField(fieldObj) {
      this.saveSourceParseField(this, fieldObj);
    },
    // 字段聚焦时赋值操作
    async valuedByFoucs(options = {}) {
      const { custom = {}, currentValue } = options;
      await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
    },

    

    // 检验快递单号 SF1366920313204
    async validatePack() {
      const inputVal = this.inputData.expressNo
      if (!inputVal) {
        return
      }
      try {
        const res = await validatePack({
          expressNo: inputVal,
        });

        this.expressData = res;
        if (res) {
          this.inputData.billCode = res.billCode
          this.interceptTallyDetails = res.interceptTallyDetailDTOS || []
          // if (!res.expressBackType) {
          //   this.skipNext(20)
          // } else {
          //   this.expressData.expressBackType = res.expressBackType
          //   this.expressData.expressBackTypeName = res.expressBackTypeName
          //   this.skipNext(30)
          // }
        }
      } catch {
        // 
      }
    },
    
    /**
     * 提交
     */
    async handleButtonSubmit() {
      if (!this.inputData.expressNo) {
        this.$hint.showFailed(this.$i18n.t('message.checkRequired'));
        return;
      }
      // eslint-disable-next-line no-unused-vars
      const { interceptTallyDetailDTOS, ...data } = this.expressData || {}
      try {
        this.$hint.showLoading();
        await interceptConfirm(data);
        this.$hint.hideLoading();
        this.inputData = {}
        this.expressData = []
        this.interceptTallyDetails = []
        this.skipNext(0)
      } catch (error) {
        this.$hint.hideLoading();
      }
    }
    
  },
  created() {
    const { customObj, hideControl } = this.handleCustomData(storage.get('customData'));
    this.customData = customObj;
    this.hideControl = hideControl;
    // console.log('库存转移customData', Object.assign({}, this.customData));
  },
  async mounted() {
    // this.inputFieldList = [
    //   { type: 'lov', required: false, ref: 0, bind: 'inputData.resourceNum', field: 'resourceNum' },
    //   { type: 'lov', required: true, ref: 10, bind: 'inputData.enterNum', field: 'enterNum' },
    //   { type: 'input', required: false, ref: 20, bind: 'inputData.receiptNumber', field: 'receiptNumber' },
    //   { type: 'lov', required: true, ref: 30, bind: 'inputData.warehouseCode', field: 'warehouseCode' },
    // ];
    // this.urlData = this.handleData('DIRECT_INB_QUERY');
    this.skipNext(0);
  },
  activated() {
    //
  },
};
</script>

<style lang="stylus" scoped>
@import '~@/style/var.styl';

.out_box {
  border-radius: 1.6vw;
  margin-top: 4.267vw;
  width: 100%;
  padding: 2.133vw 0px;
  color: #000;
  font-size: 3.467vw;
  box-shadow: 0 0 2.133vw #d9d9d9;
}

.ips-btn-normal {
  background-color: var(--primaryColor) !important;
  color: #ffffff !important;
  font-size: 17px !important;
}

.ips-btn-no-value {
  background-color: #D9D9D9 !important;
}

.ips-table {
  margin-top: 16px;
  width: 100%;

  .ips-table-thead {
    width: 180px;
    height: 26px;
    background: rgba(255, 255, 255, 1);
    border-radius: 12px 0px 0px 0px;
    line-height: 26px;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    padding-left: 12px;
  }

  table {
    width: 100%;

    th, td {
      padding: 5px 2px;
      border: 1px solid #D9D9D9;
      text-align: center;
      white-space: nowrap;
      font-size: 14px;
      color: rgba(89, 89, 89, 1);

      &:first-child {
        width: 40px;
      }
    }

    .back-green {
      background-color: #cbdeb8;
    }
  }
}
</style>
