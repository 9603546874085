// import Login from '../views/login'
import DirectlyShelves from '../views/directly-shelves'
import ReceiveDetail from '../views/receive-detail'
import ShelvesDetailSku from '../views/shelves-detail-sku'
import ShelvesDetailSingle from '../views/shelves-detail-single'
import ShelvesDetailMultiple from '../views/shelves-detial-multiple'

let route = [
  {
    path: '/directly-shelves',
    name: 'directlyShelves',
    component: DirectlyShelves,
  },
  {
    path: '/receive-detail',
    name: 'receiveDetail',
    component: ReceiveDetail,
  },
  {
    path: '/shelves-detail-sku',
    name: 'shelvesDetailSku',
    component: ShelvesDetailSku,
  },
  {
    path: '/shelves-detail-single',
    name: 'shelvesDetailSingle',
    component: ShelvesDetailSingle,
  },
  {
    path: '/shelves-detail-multiple',
    name: 'shelvesDetailMultiple',
    component: ShelvesDetailMultiple,
  },
];

export default route
