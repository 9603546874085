<template>
  <div class="home">
    <keep-alive>
      <router-view />
    </keep-alive>
    <div class="tab-bar-box g-flex-between">
      <div @click="handleTabClick(item, index)" v-for="(item, index) in tabs" :key="item.name" class="tab-item">
        <div>
          <img class="img-box" :src="index === active ? item.activeIcon : item.normalIcon" alt="" srcset="">
        </div>
        <div class="tab-text" :class="index === active ? 'active' : ''">
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  created() {
    // 根据router判断当前激活
    const { fullPath } = this.$route;
    if (fullPath === '/mine') {
      this.active = 1;
    } else {
      this.active = 0;
    }
  },
  data() {
    return {
      active: 0,
      tabs: [
        {
          name: '首页',
          path: '/applications',
          activeIcon: require('../assets/icons/new-home-active.png'),
          normalIcon: require('../assets/icons/new-home-normal.png'),
        },
        {
          name: '我的',
          path: '/mine',
          activeIcon: require('../assets/icons/new-person-active.png'),
          normalIcon: require('../assets/icons/new-person-normal.png'),
        },
      ],
    };
  },
  methods: {
    handleTabClick(item, index) {
      this.active = index;
      if (this.$route.fullPath !== item.path) {
        this.$router.replace(item.path);
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
 @import '~@/style/var.styl'
.home{
    height: 100vh;
    overflow:scroll;
    box-sizing: border-box;
    .tab-bar-box:after {
        content: '';
        display: inline-block;
        width: 1px;
        height: 16px;
        background: #EFEFEF;
        position: absolute;
        left: 50%;
    }
    .tab-bar-box{
        position: fixed;
        z-index: 999;
        bottom:0px;
        width: 100%;
        height: 9vh;
        background: #FFFFFF;
        box-shadow: 0px 2px 10px 0px rgba(227,227,227,0.6);
        .tab-item{
            flex: 1;
            text-align: center;
            .img-box {
                width: 24px;
            }
            .tab-text{
                font-size: 11px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                line-height: 11px;
                &.active{
                    color: #222222;
                }
            }
        }
    }

}
</style>
