var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: { title: _vm.$t("title.rackingTasks"), "is-index": false },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _c("ips-value-cell", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["cidCode"]
                ? _vm.hideControl["cidCode"]
                : true,
              expression:
                "customData['cidCode'] ? hideControl['cidCode']: true",
            },
          ],
          attrs: { title: _vm.$t("label.putAwayTray"), value: _vm.cidCode },
        }),
        _c("ips-value-cell", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["qtyPlan"]
                ? _vm.hideControl["qtyPlan"]
                : true,
              expression:
                "customData['qtyPlan'] ? hideControl['qtyPlan']: true",
            },
          ],
          attrs: { title: _vm.$t("label.taskNum"), value: _vm.qtyPlan },
        }),
        _c("ips-field", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["toCidCodeByEntire"]
                ? _vm.hideControl["toCidCodeByEntire"]
                : true,
              expression:
                "customData['toCidCodeByEntire'] ? hideControl['toCidCodeByEntire']: true",
            },
          ],
          ref: "ref0",
          attrs: {
            label: _vm.$t("label.conformTray"),
            disabled: "",
            params: "toCidCode",
            display: "toCidCode",
            "pre-parse-url": _vm.urlData["toCidCodeByEntire"],
            custom: _vm.customData["toCidCodeByEntire"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(0)
            },
            enter: _vm.handleCodeSkip,
            down: _vm.handleCodeSkip,
            blur: _vm.handleBlur,
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.toCidCodeByEntire,
            callback: function ($$v) {
              _vm.toCidCodeByEntire = $$v
            },
            expression: "toCidCodeByEntire",
          },
        }),
        _c("ips-value-cell", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["sugLocationCode"]
                ? _vm.hideControl["sugLocationCode"]
                : true,
              expression:
                "customData['sugLocationCode'] ? hideControl['sugLocationCode']: true",
            },
          ],
          attrs: {
            title: _vm.$t("label.suggestedLocation"),
            value: _vm.sugLocationCode,
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["toLocationCodeByEntire"]
                ? _vm.hideControl["toLocationCodeByEntire"]
                : true,
              expression:
                "customData['toLocationCodeByEntire'] ? hideControl['toLocationCodeByEntire']: true",
            },
          ],
          ref: "ref10",
          attrs: {
            label: _vm.$t("label.confirmLocation"),
            title: _vm.$t("label.confirmLocation"),
            required: "",
            disabled: _vm.disabledFlag,
            params: "locationCode",
            url: _vm.wmdmBaseUrl + "wms-locations/lov",
            "url-params": {
              warehouseId: _vm.warehouseId,
              locationType: "T_STOCK",
            },
            "pre-parse-url": _vm.urlData["toLocationCodeByEntire"],
            custom: _vm.customData["toLocationCodeByEntire"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(10)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(10, "lov")
            },
            enter: function ($event) {
              return _vm.skipNext("submitRef")
            },
            down: function ($event) {
              return _vm.skipNext("submitRef")
            },
            choose: _vm.chooseConfirmLocation,
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.formData.toLocationCodeByEntire,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "toLocationCodeByEntire", $$v)
            },
            expression: "formData.toLocationCodeByEntire",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-footer" },
      [
        _c(
          "hips-button",
          {
            ref: "submitRef",
            attrs: { size: "large" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonSubmit.apply(null, arguments)
              },
            },
          },
          [
            _c("svg-icon", {
              attrs: { slot: "icon", "icon-class": "success" },
              slot: "icon",
            }),
            _vm._v("\n      " + _vm._s(_vm.$t("button.confirm")) + "\n    "),
          ],
          1
        ),
        _c(
          "hips-button",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.customData["taskDetail"]
                  ? _vm.hideControl["taskDetail"]
                  : true,
                expression:
                  "customData['taskDetail'] ? hideControl['taskDetail']: true",
              },
            ],
            attrs: { size: "large" },
            on: { click: _vm.goDetail },
          },
          [
            _c("svg-icon", {
              attrs: { slot: "icon", "icon-class": "detail" },
              slot: "icon",
            }),
            _vm._v("\n      " + _vm._s(_vm.$t("button.taskDetail")) + "\n    "),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }