import request from '@/request';
import mixin  from '@/mixin/mixin';

export function params(data) {
    let url = `${mixin.data().winvBaseUrl}print-records/params?datasetId=${data}`;
    return request.get(url)
}

export function printsubmit(data) {
    let url = `${mixin.data().winvBaseUrl}print-records`;
    return request.post(url, data)
}
