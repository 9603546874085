import request from '@/request';
import mixin  from '@/mixin/mixin';

// 获取物料数量
export function checkGoodsQty(data) {
  const url = `${mixin.data().winvBaseUrl}app-miscellaneous-out/qty-query`;
  return request.get(url, data);
}

// 杂项出库提交
export function outSunderySubmit(data) {
  let url = `${mixin.data().winvBaseUrl}app-miscellaneous-out/process`;
  return request.post(url, data);
}

// 杂项出库获取托盘明细
export function getCidDetailData(data) {
  const url = `${mixin.data().winvBaseUrl}app-miscellaneous-out/query-stock`;
  return request.get(url, data);
} 

// 查询类型
export function getStockTransferType(data) {
  const url = `${mixin.data().winvBaseUrl}new-stock-trans/query-page-param`;
  return request.get(url, data);
}