var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["receiveDetail"]
                ? _vm.hideControl["receiveDetail"]
                : true,
              expression:
                "customData['receiveDetail'] ? hideControl['receiveDetail'] : true",
            },
          ],
          attrs: {
            title: _vm.$t("title.directlyShelves"),
            "is-index": true,
            trigger:
              _vm.inputData.parentCidCode ||
              _vm.inputData.cidCode ||
              _vm.inputData.sku
                ? _vm.$t("button.receiveDetail")
                : null,
            "on-go-detail": _vm.goToReceiveDetail,
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.requireParentCidCode
                ? false
                : _vm.customData["parentCidCode"]
                ? _vm.hideControl["parentCidCode"]
                : true,
              expression:
                "!requireParentCidCode ? false : customData['parentCidCode'] ? hideControl['parentCidCode'] : true",
            },
          ],
          ref: "ref0",
          attrs: {
            label: _vm.$t("label.parentCidCode"),
            params: "parentCidCode",
            title: _vm.$t("label.parentCidCode"),
            url: _vm.winvBaseUrl + "app-direct-putaway/query-parent-container",
            "pre-parse-url": _vm.urlData["parentCidCode"],
            custom: _vm.customData["parentCidCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            enter: function ($event) {
              return _vm.skipNext(5)
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(0)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(0, "lov")
            },
            choose: function ($event) {
              return _vm.handleChoose("parentCidCode", $event)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.inputData.parentCidCode,
            callback: function ($$v) {
              _vm.$set(_vm.inputData, "parentCidCode", $$v)
            },
            expression: "inputData.parentCidCode",
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["cidCode"]
                ? _vm.hideControl["cidCode"]
                : true,
              expression:
                "customData['cidCode'] ? hideControl['cidCode'] : true",
            },
          ],
          ref: "ref5",
          attrs: {
            label: _vm.$t("label.shelvesCid"),
            params: "cidCode",
            title: _vm.$t("label.shelvesCid"),
            url: _vm.winvBaseUrl + "app-direct-putaway/query-container",
            "url-params": { parentCidId: _vm.parentCidId },
            "pre-parse-url": _vm.urlData["cidCode"],
            custom: _vm.customData["cidCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            enter: function ($event) {
              return _vm.skipNext(10)
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(5)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(5, "lov")
            },
            choose: function ($event) {
              return _vm.handleChoose("shelvesCid", $event)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.inputData.cidCode,
            callback: function ($$v) {
              _vm.$set(_vm.inputData, "cidCode", $$v)
            },
            expression: "inputData.cidCode",
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["sku"] ? _vm.hideControl["sku"] : true,
              expression: "customData['sku'] ? hideControl['sku'] : true",
            },
          ],
          ref: "ref10",
          attrs: {
            label: _vm.$t("label.shelvesGoods"),
            required: !(_vm.inputData.parentCidCode || _vm.inputData.cidCode),
            title: _vm.$t("label.shelvesGoods"),
            params: "sku",
            url: _vm.winvBaseUrl + "app-direct-putaway/query-goods",
            "url-params": {
              parentCidId: _vm.parentCidId,
              cidId: _vm.cidId,
              orgId: _vm.orgId,
              cidNull:
                _vm.inputData.cidCode || _vm.inputData.parentCidCode ? 0 : 1,
            },
            "pre-parse-url": _vm.urlData["sku"],
            custom: _vm.customData["sku"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            enter: function ($event) {
              return _vm.skipNext(20)
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(10)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(10, "lov")
            },
            choose: function ($event) {
              return _vm.handleChoose("shelvesGoods", $event)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.inputData.sku,
            callback: function ($$v) {
              _vm.$set(_vm.inputData, "sku", $$v)
            },
            expression: "inputData.sku",
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["sourceBillNumber"]
                ? _vm.hideControl["sourceBillNumber"]
                : true,
              expression:
                "customData['sourceBillNumber'] ? hideControl['sourceBillNumber'] : true",
            },
          ],
          ref: "ref20",
          attrs: {
            label: _vm.$t("label.shelvesBillNum"),
            title: _vm.$t("label.shelvesBillNum"),
            params: "sourceBillCode",
            url: _vm.winvBaseUrl + "app-direct-putaway/query-source-bill-code",
            "url-params": {
              parentCidId: _vm.parentCidId,
              cidId: _vm.cidId,
              orgId: _vm.orgId,
              goodsId: _vm.goodsId,
              cidNull:
                _vm.inputData.cidCode || _vm.inputData.parentCidCode ? 0 : 1,
            },
            "pre-parse-url": _vm.urlData["sourceBillCode"],
            custom: _vm.customData["sourceBillNumber"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            enter: function ($event) {
              return _vm.skipNext(30)
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(20)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(20, "lov")
            },
            choose: function ($event) {
              return _vm.handleChoose("shelvesBillNum", $event)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.inputData.sourceBillNumber,
            callback: function ($$v) {
              _vm.$set(_vm.inputData, "sourceBillNumber", $$v)
            },
            expression: "inputData.sourceBillNumber",
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["inboundNum"]
                ? _vm.hideControl["inboundNum"]
                : true,
              expression:
                "customData['inboundNum'] ? hideControl['inboundNum'] : true",
            },
          ],
          ref: "ref30",
          attrs: {
            label: _vm.$t("label.shelvesInboundNum"),
            title: _vm.$t("label.shelvesInboundNum"),
            params: "billCode",
            url: _vm.winvBaseUrl + "app-direct-putaway/query-bill-code",
            "url-params": {
              parentCidId: _vm.parentCidId,
              cidId: _vm.cidId,
              orgId: _vm.orgId,
              goodsId: _vm.goodsId,
              sourceBillId: _vm.sourceBillId,
              cidNull:
                _vm.inputData.cidCode || _vm.inputData.parentCidCode ? 0 : 1,
            },
            "pre-parse-url": _vm.urlData["billCode"],
            required: !(_vm.inputData.parentCidCode || _vm.inputData.cidCode),
            custom: _vm.customData["inboundNum"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            enter: _vm.inboundNumCheck,
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(30)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(30, "lov")
            },
            choose: function ($event) {
              return _vm.handleChoose("shelvesInboundNum", $event)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.inputData.inboundNum,
            callback: function ($$v) {
              _vm.$set(_vm.inputData, "inboundNum", $$v)
            },
            expression: "inputData.inboundNum",
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["receiveNum"]
                ? _vm.hideControl["receiveNum"]
                : true,
              expression:
                "customData['receiveNum'] ? hideControl['receiveNum'] : true",
            },
          ],
          ref: "ref40",
          attrs: {
            label: _vm.$t("label.shelvesReceiveNum"),
            title: _vm.$t("label.shelvesReceiveNum"),
            params: "receiptNumber",
            url: _vm.winvBaseUrl + "app-direct-putaway/query-receipt-number",
            "url-params": {
              parentCidId: _vm.parentCidId,
              cidId: _vm.cidId,
              orgId: _vm.orgId,
              goodsId: _vm.goodsId,
              sourceBillId: _vm.sourceBillId,
              billId: _vm.billId,
              cidNull:
                _vm.inputData.cidCode || _vm.inputData.parentCidCode ? 0 : 1,
            },
            "pre-parse-url": _vm.urlData["receiptNumber"],
            custom: _vm.customData["receiveNum"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            enter: function ($event) {
              return _vm.skipNext("submitRef")
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(40)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(40, "lov")
            },
            choose: function ($event) {
              return _vm.handleChoose("shelvesReceiveNum", $event)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.inputData.receiveNum,
            callback: function ($$v) {
              _vm.$set(_vm.inputData, "receiveNum", $$v)
            },
            expression: "inputData.receiveNum",
          },
        }),
        _c(
          "div",
          { staticClass: "out_box" },
          [
            _c("ips-value-cell", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.customData["shelvesWarehouse"]
                    ? _vm.hideControl["shelvesWarehouse"]
                    : true,
                  expression:
                    "customData['shelvesWarehouse'] ? hideControl['shelvesWarehouse'] : true",
                },
              ],
              staticClass: "ips-value-cell",
              attrs: {
                title: _vm.$t("label.shelvesWarehouse"),
                value: _vm.shelvesWarehouse,
              },
            }),
            _c("ips-value-cell", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.customData["shelvesOwner"]
                    ? _vm.hideControl["shelvesOwner"]
                    : true,
                  expression:
                    "customData['shelvesOwner'] ? hideControl['shelvesOwner'] : true",
                },
              ],
              attrs: {
                title: _vm.$t("label.ownerCode"),
                value: _vm.shelvesOwner,
              },
            }),
            _c("ips-value-cell", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.customData["shelvesReceiveQty"]
                    ? _vm.hideControl["shelvesReceiveQty"]
                    : true,
                  expression:
                    "customData['shelvesReceiveQty'] ? hideControl['shelvesReceiveQty'] : true",
                },
              ],
              attrs: {
                title: _vm.$t("label.shelvesReceiveQty"),
                value: _vm.shelvesReceiveQty,
              },
            }),
            _c("ips-value-cell", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.customData["shelvesVendor"]
                    ? _vm.hideControl["shelvesVendor"]
                    : true,
                  expression:
                    "customData['shelvesVendor'] ? hideControl['shelvesVendor'] : true",
                },
              ],
              attrs: {
                title: _vm.$t("label.shelvesVendor"),
                value: _vm.shelvesVendor,
              },
            }),
            _c("ips-value-cell", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.customData["shelvesCustomer"]
                    ? _vm.hideControl["shelvesCustomer"]
                    : true,
                  expression:
                    "customData['shelvesCustomer'] ? hideControl['shelvesCustomer'] : true",
                },
              ],
              attrs: {
                title: _vm.$t("label.shelvesCustomer"),
                value: _vm.shelvesCustomer,
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-footer" },
      [
        _c(
          "hips-button",
          {
            ref: "submitRef",
            attrs: { size: "large" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.goToShelvesDetail.apply(null, arguments)
              },
            },
          },
          [
            _c("svg-icon", {
              attrs: { slot: "icon", "icon-class": "success" },
              slot: "icon",
            }),
            _vm._v("\n      " + _vm._s(_vm.$t("button.submit")) + "\n    "),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }