import request from '@/request';
import mixin  from '@/mixin/mixin';


// 查询所有序列号
export function querySerialList(data) {
  const url = `${mixin.data().winvBaseUrl}app-stock-trans/query-trans-serial`;
  return request.get(url, data);
}

// 计算现有量可用量
export function queryStockQty(data) {
  const url = `${mixin.data().winvBaseUrl}app-stock-trans/query-stock-qty`;
  return request.get(url, data);
}

// 库存转移
export function stockTrans(data) {
  let url = `${mixin.data().winvBaseUrl}app-stock-trans`;
  return request.post(url, data);
}

// 目标父托盘校验
export function toParentCidCheck(data) {
  let url = `${mixin.data().winvBaseUrl}app-stock-trans/check-parent-cid`;
  return request.post(url, data);
}

// 查询页面参数
export function getparentCidEnabled(data) {
  let url = `${mixin.data().winvBaseUrl}page-function-paramss/info`;
  return request.get(url, data);
}
