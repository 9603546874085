<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header
        :is-index="false"
        title="边拣边分"
      />
    </div>
    <div class="ips-container">
      <ul class="piking-cart-box">
        <li v-for="item in taskPickingList" :key="item.code" class="piking-cart-box-item">
          <div class="piking-cart-box-item__inner" :class="{ 'num-active': item.value > 0 }">
            <p>{{ item.code }}</p>
            <div>{{ item.value }}</div>
          </div>
        </li>
      </ul>
      <div class="ips-footer">
        <hips-button size="large" ref="submitRef" @click.stop="handleCommonSubmit">
          {{ $t('button.confirm') }}
        </hips-button>
      </div>
    </div>
  </hips-view>
</template>

<script>
export default {
  name: 'PickingBoundSort',
  data() {
    const { params } = this.$route
    console.log('params.taskPickingList', params.taskPickingList);
    return {
      taskPickingList:  Array.isArray(params.taskPickingList) ? params.taskPickingList : [],
    }
  },
  mounted() {
    this.skipNext('submitRef')
  },
  methods: {
    handleButtonSubmit() {
      const param = {
        name: 'taskExecutionWave',
        params: {
          action: 'submit',
        },
      };
      this.$router.pushPage(param);
    },
  }
}
</script>

<style lang="stylus" scoped>
.piking-cart-box {
  display: flex;
  flex-wrap: wrap;
  // margin: 0 -2px;
  &__submitBtn {
    background-color: #f5a623;
  }
  &-item {
    width: 25%;
    height: 20vw;
    padding: 1px;
    box-sizing: border-box;
    &__inner {
      color: #999;
      display: flex;
      flex-direction: column;
      justify-content:center;
      align-items: center;
      background-color: #efefef;
      border-radius: 4px;
      height: 100%;
      font-size: 14px;
      &> div {
        margin-top: 8px;
        font-size: 12px;
      }
      &.num-active {
        font-weight: 550;
        color: #fff;
        background-color: #86f15a;
      }
    }
  }
}
</style>