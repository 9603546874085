var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [_c("ips-header", { attrs: { title: _vm.$t("title.inventoryCheck") } })],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["sku"] ? _vm.hideControl["sku"] : true,
              expression: "customData['sku'] ? hideControl['sku'] : true",
            },
          ],
          ref: "ref0",
          attrs: {
            label: _vm.$t("label.sku"),
            title: _vm.$t("label.sku"),
            params: "sku",
            url: _vm.wmdmBaseUrl + "goods",
            custom: _vm.customData["sku"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(0)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(0, "lov")
            },
            enter: function ($event) {
              return _vm.skipNext(10)
            },
            choose: _vm.handleChoose,
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.inputData.sku,
            callback: function ($$v) {
              _vm.$set(_vm.inputData, "sku", $$v)
            },
            expression: "inputData.sku",
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["batchCode"]
                ? _vm.hideControl["batchCode"]
                : true,
              expression:
                "customData['batchCode'] ? hideControl['batchCode'] : true",
            },
          ],
          ref: "ref10",
          attrs: {
            label: _vm.$t("label.batch"),
            title: _vm.$t("label.batch"),
            params: "batchCode",
            url: _vm.wmdmBaseUrl + "batchs",
            "url-params": { goodsId: _vm.inputData.goodsId },
            "pre-parse-url": _vm.urlData["batchCode"],
            custom: _vm.customData["batchCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(10)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(10, "lov")
            },
            enter: function ($event) {
              return _vm.skipNext(20)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.inputData.batchCode,
            callback: function ($$v) {
              _vm.$set(_vm.inputData, "batchCode", $$v)
            },
            expression: "inputData.batchCode",
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["whareaCode"]
                ? _vm.hideControl["whareaCode"]
                : true,
              expression:
                "customData['whareaCode'] ? hideControl['whareaCode'] : true",
            },
          ],
          ref: "ref20",
          attrs: {
            label: _vm.$t("label.whareaCode"),
            title: _vm.$t("label.whareaCode"),
            params: "whareaCode",
            url: _vm.wmdmBaseUrl + "whareas",
            "pre-parse-url": _vm.urlData["whareaCode"],
            custom: _vm.customData["whareaCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(20)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(20, "lov")
            },
            enter: function ($event) {
              return _vm.skipNext(30)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.inputData.whareaCode,
            callback: function ($$v) {
              _vm.$set(_vm.inputData, "whareaCode", $$v)
            },
            expression: "inputData.whareaCode",
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["locationCode"]
                ? _vm.hideControl["locationCode"]
                : true,
              expression:
                "customData['locationCode'] ? hideControl['locationCode'] : true",
            },
          ],
          ref: "ref30",
          attrs: {
            label: _vm.$t("label.countLocation"),
            title: _vm.$t("label.countLocation"),
            params: "locationCode",
            url: _vm.wmdmBaseUrl + "wms-locations",
            "pre-parse-url": _vm.urlData["locationCode"],
            custom: _vm.customData["locationCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(30)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(30, "lov")
            },
            enter: function ($event) {
              return _vm.skipNext("submitRef")
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.inputData.locationCode,
            callback: function ($$v) {
              _vm.$set(_vm.inputData, "locationCode", $$v)
            },
            expression: "inputData.locationCode",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-footer" },
      [
        _c(
          "hips-button",
          {
            ref: "submitRef",
            attrs: { size: "large" },
            on: {
              click: function ($event) {
                return _vm.handleCommonSubmit("location")
              },
            },
          },
          [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("button.locationInventory")) + "\n    "
            ),
          ]
        ),
        _c(
          "hips-button",
          {
            attrs: { size: "large" },
            on: {
              click: function ($event) {
                return _vm.handleCommonSubmit("batch")
              },
            },
          },
          [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("button.batchInventory")) + "\n    "
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }