import Vue from 'vue';
import { isFunction } from 'lodash';
import {storage} from './index'
import i18n from '../i18n/vue-i18n';

let hint = {
  outboundStatus: 'OUT',  // 出库单对应单据类型分类简码
  waveStatus: 'WAVE',   // 波次单对应单据类型分类简码
  transferStatus: 'TRANSFER',// 调拨单对应单据类型分类简码
  showLoading: (text) => {
    Vue.prototype.$hips.indicator.show({
      text: text ? text : i18n.t('message.loading'),
    });
  },
  hideLoading: () => {
    Vue.prototype.$hips.indicator.hide();
  },
  showSuccess: (text = '操作成功') => {
    Vue.prototype.$hips.notify({
      message: text,
      type: 'success',
      duration: 2000,
    });
  },
  // autoClose 是否自动关闭
  showFailed: (text = '操作失败', autoClose = false) => {
    Vue.prototype.$hips.notify({
      message: text,
      type: 'warning',
      duration: autoClose ? storage.get('closeTime') : 2000,
      showClose: true,
    });
  },
  showAlert: (text, onConfirm, onCancel) => {
    // document.addEventListener('mousedown', (e) => {
    //   const flag = e.target.dataset && e.target.dataset.blur;
    //   console.log('监听事件mousedown')
    //   if (e.target.tagName !== 'INPUT' && (e.target.tagName !== 'BUTTON' || flag)) {
    //     e.preventDefault();
    //   }
    // });
    Vue.prototype.$hips.dialog.confirm({
      title: '提示',
      content: text,
      okText: '确定',
      cancelText: '取消',
      onOk() {
        // 阻止输入框失焦事件
        let btn = document.querySelector('.hips-dialog__footer-ok');
        btn.onmousedown = function(event) {
          event.preventDefault()
        };
        // on OK
        onConfirm();
      },
      onCancel() {
        // 阻止输入框失焦事件
        let btn = document.querySelector('.hips-dialog__footer-cancel');
        btn.onmousedown = function(event) {
          event.preventDefault()
        };
        // on cancel
        if (onCancel && isFunction(onCancel)) onCancel();
      },
    });
  },
};

export default hint;
