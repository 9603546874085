var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "no-data" }, [
    _c("div", { staticClass: "no-data-content" }, [
      _c("img", { attrs: { src: _vm.getIcon(), alt: "No Data" } }),
    ]),
    _c("div", { staticClass: "no-data-text" }, [
      _vm._v("\n    " + _vm._s(_vm.$t("title.nodata")) + "\n  "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }