var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [_c("ips-header", { attrs: { title: _vm.$t("title.inSundry") } })],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _c("ips-field", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["cidCode"]
                ? _vm.hideControl["cidCode"]
                : true,
              expression:
                "customData['cidCode'] ? hideControl['cidCode'] : true",
            },
          ],
          ref: "ref0",
          attrs: {
            params: "cidCode",
            display: "cidCode",
            "pre-parse-url": _vm.urlData["cidCode"],
            label: _vm.$t("label.tray"),
            custom: _vm.customData["cidCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
            blur: function ($event) {
              return _vm.containerBlur()
            },
            enter: function ($event) {
              return _vm.containerBlur()
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(0)
            },
          },
          model: {
            value: _vm.cidCode,
            callback: function ($$v) {
              _vm.cidCode = $$v
            },
            expression: "cidCode",
          },
        }),
        _c("ips-field", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.parentCidEnabled.paramValue === "1" ? true : false,
              expression: "parentCidEnabled.paramValue === '1' ? true : false",
            },
          ],
          ref: "ref5",
          attrs: {
            params: "parentCidCode",
            display: "parentCidCode",
            "pre-parse-url": _vm.urlData["parentCidCode"],
            label: _vm.$t("label.parentTray"),
            custom: _vm.customData["parentCidCode"],
            "source-parse-field": _vm.sourceParseField,
            disabled: _vm.cidEditFlag,
          },
          on: {
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
            blur: function ($event) {
              return _vm.parentContainerBlur()
            },
            enter: function ($event) {
              return _vm.parentContainerEnter()
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(0)
            },
          },
          model: {
            value: _vm.parentCidCode,
            callback: function ($$v) {
              _vm.parentCidCode = $$v
            },
            expression: "parentCidCode",
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["locationCode"]
                ? _vm.hideControl["locationCode"]
                : true,
              expression:
                "customData['locationCode'] ? hideControl['locationCode'] : true",
            },
          ],
          ref: "ref10",
          attrs: {
            label: _vm.$t("label.countLocation"),
            title: [
              _vm.$t("label.countLocation"),
              _vm.$t("label.locationName"),
              _vm.$t("label.whareaName"),
            ],
            params: ["locationCode", "locationName", "whareaName"],
            disabled: _vm.locationEditFlag,
            required: "",
            display: "locationCode",
            "url-params": {
              locationIdList: _vm.locationId,
              locationCode: _vm.locationCode,
              cidCode: _vm.cidCode,
              enabledFlag: 1,
            },
            url: _vm.wmdmBaseUrl + "wms-locations",
            "pre-parse-url": _vm.urlData["locationCode"],
            custom: _vm.customData["locationCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            enter: function ($event) {
              return _vm.skipNext(20)
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(10)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(10, "lov")
            },
            choose: function ($event) {
              return _vm.handleChoose("locationCode", $event)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.locationCode,
            callback: function ($$v) {
              _vm.locationCode = $$v
            },
            expression: "locationCode",
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["sku"] ? _vm.hideControl["sku"] : true,
              expression: "customData['sku'] ? hideControl['sku'] : true",
            },
          ],
          ref: "ref20",
          attrs: {
            label: _vm.$t("label.sku"),
            required: "",
            title: [
              _vm.$t("label.sku"),
              _vm.$t("label.goodsName"),
              _vm.$t("label.ownerName"),
            ],
            params: ["sku", "goodsName", "ownerName"],
            display: "sku",
            url: _vm.wmdmBaseUrl + "goods/goods-lov",
            "url-params": { ownerId: _vm.ownerId },
            "pre-parse-url": _vm.urlData["sku"],
            custom: _vm.customData["sku"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            choose: function ($event) {
              return _vm.handleChoose("sku", $event)
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(20)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(20, "lov")
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.sku,
            callback: function ($$v) {
              _vm.sku = $$v
            },
            expression: "sku",
          },
        }),
        _c("ips-field", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isbatch
                ? _vm.customData["batchCode"]
                  ? _vm.hideControl["batchCode"]
                  : _vm.isbatch
                : false,
              expression:
                "isbatch ? (customData['batchCode'] ? hideControl['batchCode'] : isbatch) : false",
            },
          ],
          ref: "ref30",
          attrs: {
            label: _vm.$t("label.batch"),
            title: _vm.$t("label.batch"),
            required: "",
            params: "batchCode",
            display: "batchCode",
            "pre-parse-url": _vm.urlData["batchCode"],
            custom: _vm.customData["batchCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(30)
            },
            enter: _vm.handleEnter,
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.batchCode,
            callback: function ($$v) {
              _vm.batchCode = $$v
            },
            expression: "batchCode",
          },
        }),
        _vm.goodsId
          ? _c(
              "div",
              [
                _c("ips-page-card", {
                  attrs: {
                    "react-field": ["ownerName", "uomName"],
                    item: _vm.goodsLov,
                    "footer-info": { goodsName: _vm.goodsLov.goodsName },
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c("hips-row", [
          ["INV_CTRL", "IN_CTRL", "SEMI_INV_CTRL"].includes(_vm.serialType)
            ? _c(
                "div",
                { staticStyle: { "margin-top": "16px" } },
                [
                  _c(
                    "hips-col",
                    { attrs: { span: "14" } },
                    [
                      _c("ips-input-number", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.customData["transferQty"]
                              ? _vm.hideControl["transferQty"]
                              : true,
                            expression:
                              "customData['transferQty'] ? hideControl['transferQty'] : true",
                          },
                        ],
                        ref: "ref40",
                        attrs: {
                          required: "",
                          label: _vm.$t("label.quantity"),
                          "thousands-formatter": "",
                          "not-auto-fill": "",
                          "integral-mode": true,
                          custom: _vm.customData["transferQty"],
                          "source-parse-field": _vm.sourceParseField,
                          "enable-serial": _vm.serialType,
                          "more-than-zero": true,
                        },
                        on: {
                          setSourceField: function ($event) {
                            return _vm.saveSourceField($event)
                          },
                          valuedByFoucs: function ($event) {
                            return _vm.valuedByFoucs($event)
                          },
                          enter: function ($event) {
                            _vm.showSeriesDetail
                              ? _vm.skipNext("refSerail")
                              : _vm.skipNext(60)
                          },
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.handleCommonClick(50)
                          },
                        },
                        model: {
                          value: _vm.transferQty,
                          callback: function ($$v) {
                            _vm.transferQty = $$v
                          },
                          expression: "transferQty",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "hips-col",
                    { attrs: { span: "10" } },
                    [
                      _c(
                        "hips-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showSeriesDetail
                                ? _vm.customData["showSeriesDetail"]
                                  ? _vm.hideControl["showSeriesDetail"]
                                  : _vm.showSeriesDetail
                                : false,
                              expression:
                                "showSeriesDetail ? (customData['showSeriesDetail'] ? hideControl['showSeriesDetail'] : showSeriesDetail) : false",
                            },
                          ],
                          ref: "refSerail",
                          staticStyle: {
                            width: "93%",
                            "margin-left": "10px",
                            color: "#FFFFFF",
                            height: "36px",
                            "line-height": "36px",
                          },
                          attrs: {
                            type: "primary",
                            size: "large",
                            disabled: !_vm.transferQty,
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.goToSerialDetail.apply(null, arguments)
                            },
                            enter: function ($event) {
                              return _vm.skipNext(60)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.serialNumberList.length > 0
                                  ? _vm.$t("button.seriesDetail") +
                                      "(" +
                                      _vm.serialNumberList.length +
                                      ")"
                                  : _vm.$t("button.seriesDetail")
                              ) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
        _c("hips-row", [
          !["INV_CTRL", "IN_CTRL", "SEMI_INV_CTRL"].includes(_vm.serialType)
            ? _c(
                "div",
                { staticStyle: { "margin-top": "16px" } },
                [
                  _c(
                    "hips-col",
                    { attrs: { span: "24" } },
                    [
                      _c("ips-number", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.customData["transferQty"]
                              ? _vm.hideControl["transferQty"]
                              : true,
                            expression:
                              "customData['transferQty'] ? hideControl['transferQty'] : true",
                          },
                        ],
                        ref: "ref40",
                        attrs: {
                          required: "",
                          label: _vm.$t("label.quantity"),
                          "thousands-formatter": "",
                          "integral-mode": "UNCTRL" !== _vm.serialType,
                          "enable-serial": _vm.serialType,
                          custom: _vm.customData["transferQty"],
                          "source-parse-field": _vm.sourceParseField,
                          "not-auto-fill": "",
                          min: null,
                          precision: _vm.showSeriesDetail ? 0 : undefined,
                        },
                        on: {
                          enter: function ($event) {
                            return _vm.skipNext(60)
                          },
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.handleCommonClick(50)
                          },
                          setSourceField: function ($event) {
                            return _vm.saveSourceField($event)
                          },
                          valuedByFoucs: function ($event) {
                            return _vm.valuedByFoucs($event)
                          },
                        },
                        model: {
                          value: _vm.transferQty,
                          callback: function ($$v) {
                            _vm.transferQty = $$v
                          },
                          expression: "transferQty",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
        _c(
          "div",
          { staticClass: "card_num_subtrans" },
          [
            _c("hips-row", [
              _c(
                "div",
                { staticClass: "row-num" },
                [
                  _c(
                    "hips-col",
                    {
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        height: "100%",
                      },
                      attrs: { span: "12" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "title",
                          staticStyle: { display: "inline-block" },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("label.availableQty")) +
                              ":\n            "
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "value",
                          staticStyle: { display: "inline-block" },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.qty) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "hips-col",
                    {
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        height: "100%",
                      },
                      attrs: { span: "12" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "title",
                          staticStyle: { display: "inline-block" },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("label.currentUseQty")) +
                              ":\n            "
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "value",
                          staticStyle: { display: "inline-block" },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.validQty) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["reason"]
                ? _vm.hideControl["reason"]
                : true,
              expression: "customData['reason'] ? hideControl['reason'] : true",
            },
          ],
          ref: "ref60",
          attrs: {
            label: _vm.$t("label.sundryReason"),
            title: _vm.$t("label.sundryReason"),
            params: ["meaning"],
            url: _vm.wmdmBaseUrl + "wms-common/lov-data-query",
            "url-params": { lovCode: "WINV.INV_MOVE_MISC_REASON" },
            "pre-parse-url": _vm.urlData["reason"],
            "is-page-content": false,
            custom: _vm.customData["reason"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(60)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(60, "lov")
            },
            enter: function ($event) {
              return _vm.skipNext("submitRef")
            },
            choose: function ($event) {
              return _vm.handleChoose("reason", $event)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.reason,
            callback: function ($$v) {
              _vm.reason = $$v
            },
            expression: "reason",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-footer" },
      [
        _c(
          "hips-button",
          {
            ref: "submitRef",
            attrs: { size: "large" },
            on: { click: _vm.handleButtonSubmit },
          },
          [
            _c("svg-icon", {
              attrs: { slot: "icon", "icon-class": "success" },
              slot: "icon",
            }),
            _vm._v("\n      " + _vm._s(_vm.$t("button.confirm")) + "\n    "),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }