<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header
        :title="$t('title.accept')"
        :trigger="orderData.billTypeName ? $t('title.detail') : ''"
        :on-go-detail="goDetail"
      />
    </div>
    <div class="ips-container">
      <!-- 来源单号 -->
      <!-- <ips-lov
        v-model="inputData.resourceNum"
        :label="$t('label.resourceNum')"
        :title="$t('label.resourceNum')"
        params="sourceBillCode"
        display="sourceBillCode"
        :url="`${winvBaseUrl}app-inbound-receive/query-source-inbound-order`"
        :pre-parse-url="urlData['sourceBillCode']"
        ref="ref0"
        is-control
        :custom="customData['resourceNum']"
        :source-parse-field="sourceParseField"
        v-show="customData['resourceNum'] ? hideControl['resourceNum'] : true"
        @click.stop="handleCommonClick(0)"
        @clickIcon="handleCommonClick(0, 'lov')"
        @enter="skipNext(10)"
        @down="skipNext(10)"
        @choose="handleChoose('resourceNum', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      /> -->
      <!-- 入库单号 -->
      <ips-lov
        :label="$t('label.billCode')"
        required
        v-model="inputData.enterNum"
        :title="$t('label.billCode')"
        params="billCode"
        display="billCode"
        :url="`${winvBaseUrl}app-inbound-receive/query-inbound-order`"
        :url-params="{ 'sourceBillId': sourceBillId, pageId: menuId }"
        :pre-parse-url="urlData['billCode']"
        ref="ref10"
        :custom="customData['enterNum']"
        :source-parse-field="sourceParseField"
        v-show="customData['enterNum'] ? hideControl['enterNum'] : true"
        @click.stop="handleCommonClick(10)"
        @clickIcon="handleCommonClick(10, 'lov')"
        @enter="skipNext(20)"
        @down="skipNext(20)"
        @choose="handleChoose('enterNum', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 接收单号 -->
      <ips-field
        v-model="receiptNumber"
        :label="$t('label.receiptNumber')"
        ref="ref20"
        params="receiptNumber"
        display="receiptNumber"
        :pre-parse-url="urlData['receiptNumber']"
        :custom="customData['receiptNumber']"
        :source-parse-field="sourceParseField"
        v-show="customData['receiptNumber'] ? hideControl['receiptNumber']: true"
        @blur="receiptNumBlur()"
        @click="handleCommonClick(20)"
        @enter="skipNext(30)"
        @down="skipNext(30)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 收货仓库 -->
      <ips-lov
        :label="$t('label.receiveWarehouse')"
        required
        v-model="inputData.warehouseCode"
        :title="[$t('title.warehouseCode'), $t('title.warehouseName')]"
        display="warehouseCode"
        :params="['warehouseCode', 'warehouseName']"
        :url="`${wmdmBaseUrl}warehouses`"
        :url-params="{ warehouseIdStr }"
        :pre-parse-url="urlData['warehouseCode']"
        ref="ref30"
        :custom="customData['warehouseCode']"
        :source-parse-field="sourceParseField"
        v-show="customData['warehouseCode'] ? hideControl['warehouseCode'] : true"
        @click.stop="handleCommonClick(30)"
        @clickIcon="handleCommonClick(30, 'lov')"
        @enter="skipNext('submitRef')"
        @down="skipNext('submitRef')"
        @choose="handleChoose('warehouseCode', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />

      <div
        class="out_box"
        v-if="orderData.billTypeName"
      >
        <!-- 单据类型 -->
        <ips-value-cell
          :title="$t('label.billType')"
          image-flag
          :value="orderData.billTypeName"
        />
        <!-- 供应商 -->
        <ips-value-cell
          :title="$t('label.vendor')"
          image-flag
          :value="orderData.vendorName || '无'"
        />
        <!-- 客户 -->
        <ips-value-cell
          :title="$t('label.customer')"
          image-flag
          :value="orderData.customerName || '无'"
        />
      </div>
    </div>
    <div class="ips-footer">
      <hips-button
        size="large"
        @click.stop="handleCommonSubmit"
        @keyup.enter.native="handleCommonSubmit"
        @keypress.native.prevent
        ref="submitRef"
        :class="[
          'ips-btn-normal',
          {
            'ips-btn-no-value': !orderData.billTypeName,
          }]"
      >
        <svg-icon
          slot="icon"
          icon-class="success"
        />
        {{ $t('button.submit') }}
      </hips-button>
    </div>
  </hips-view>
</template>

<script>
import { Cell } from "@hips/vue-ui";
import { storage } from '@/utils';
import { fromBillBlur, checkReceiptNum, autoCreateReceiptNum, queryWhareaStr } from "../api";

export default {
  name: 'Receive',
  data() {
    return {
      inputData: {
        resourceNum: "",
        enterNum: "",
        warehouseCode: "",
      },
      batchControl: '',
      inputFieldList: [
        { type: 'lov', required: false, ref: 0, bind: 'inputData.resourceNum', field: 'resourceNum' },
        { type: 'lov', required: true, ref: 10, bind: 'inputData.enterNum', field: 'enterNum' },
        { type: 'field', required: false, ref: 20, bind: 'receiptNumber', field: 'receiptNumber' },
        { type: 'lov', required: true, ref: 30, bind: 'inputData.warehouseCode', field: 'warehouseCode' },
      ],
      orderData: {}, //入库单号数据
      sourceBillId: "",
      whareaIdsString: "",
      warehouseIdStr: '',
      receiptNumber: '', // 接收单号
      menuId: storage.get('menuId'),
      urlData: {},
      customData: {}, // 个性化配置信息列表
      hideControl: {}, // 非必输字段的显隐控制
      sourceParseField: {}, // 界面中所有的来源解析字段
    };
  },
  components: {
    [Cell.name]: Cell
  },
  methods: {
    /**
    * lov选择框，选择数据
    * @param type lov
    * @param obj 选中对象
    */
    handleChoose(type, obj) {
      switch (type) {
        case 'resourceNum':
          this.sourceBillId = obj.sourceBillId;
          this.handleBlur();
          break;
        case 'enterNum':
          this.orderData = obj;
          this.batchControl = obj.batchControl;
          this.billdBlur();
          break;
        case 'warehouseCode':
          this.warehouseId = obj.warehouseId;
          break;
        default:
          return null;
      }
    },
    // 保存来源解析字段
    async saveSourceField(fieldObj) {
      this.saveSourceParseField(this, fieldObj);
    },
    // 字段聚焦时赋值操作
    async valuedByFoucs(options = {}) {
      const { custom = {}, currentValue } = options;
      await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
    },
    async billdBlur() {
      let data = {
        params: {
          billId: this.orderData.billId,
          billTypeId: this.orderData.billTypeId,
          billType: this.orderData.billType,
        }
      };
      const res = await queryWhareaStr(data);
      if (res) {
        this.whareaIdsString = res.whareaIdsString;
        this.warehouseIdStr = res.warehouseIdStr;
        const warehouseIdArr = this.warehouseIdStr.split(',');
        if (warehouseIdArr.length === 1) {
          this.inputData.warehouseCode = res.warehouseCodeStr;
          this.warehouseId = warehouseIdArr[0];
          this.skipNext('submitRef');
        }
      }
    },
    async handleBlur() {
      await this.sourceBillBlur();
    },

    /**
    * 来源单号失去焦点校验
    */
    async sourceBillBlur() {
      if (this.inputData.resourceNum) {
        this.$hint.showLoading();
        try {
          // const url = `${this.winvBaseUrl}inbound-orders`;
          let data = {
            params: {
              sourceBillCode: this.inputData.resourceNum
            }
          };
          // const res = await this.$http.get(url, data);
          const res = await fromBillBlur(data);
          if (res && res.content) {
            if (res.content.length === 1) {
              this.inputData.sourceBillCode = res.content[0].sourceBillCode;
              if (this.status === 'stop') this.inputData.enterNum = res.content[0].billCode;
              this.orderData = res.content[0];
            }
            this.$refs["ref0"].validateLov = true;
            this.skipNext(10);
          } else {
            this.$hint.showFailed(this.$i18n.t('message.findNull', { label: '来源单号' }));
            this.inputData.resourceNum = '';
            this.$refs["ref0"].validateLov = false
          }
        } finally {
          this.$hint.hideLoading();
        }
      }
    },

    /**
    * 接收单号失去焦点校验
    */
    async receiptNumBlur() {
      const input = this.$refs.ref20;
      if (this.receiptNumber || input.value) {
        this.$hint.showLoading();
        try {
          let data = {
            params: {
              receiptNumber: this.receiptNumber
            }
          };
          // const res = await this.$http.get(url, data);
          const res = await checkReceiptNum(data);
          if (!res) {
            this.$hint.showAlert(
              `${this.receiptNumber} ${this.$i18n.t('message.checkAlreadyHasAcceptRecord')}}`,
              () => {
                this.skipNext(30);
              },
              () => {
                this.receiptNumber = '';
                this.skipNext(20);
              }
            );
          }
        } finally {
          this.$hint.hideLoading();
        }
      } else {
        // 自动生成接收号
        this.$hint.showLoading(this.$i18n.t('message.autoCreatingReceiptNumber'));
        this.receiptNumber = await autoCreateReceiptNum();
        this.$hint.hideLoading(this.$i18n.t('message.receiptNumberSuccess'));
        if (this.inputData.warehouseCode === '') {
          this.skipNext(30);
        } else {
          this.skipNext('submitRef');
        }
      }
    },

    /**
    * 提交
    */
    handleButtonSubmit() {
      if (!this.inputData.enterNum) {
        this.$hint.showFailed(this.$i18n.t('message.checkRequired'));
        return;
      }
      const param = {
        name: "ReceiveConfirm",
        params: {
          batchControl: this.batchControl,
          billId: this.orderData.billId,
          billCode: this.orderData.billCode,
          warehouseCode: this.orderData.warehouseCode,
          receiptNumber: this.receiptNumber,
          warehouseId: this.warehouseId,
          whareaIdsString: this.whareaIdsString,
          warehouseIdStr: this.warehouseIdStr,
          sourceParseField: { ...this.sourceParseField },
          customData: this.customData,
          hideControl: this.hideControl,
        }
      };
      this.$store.commit("pushKeepAlives", "ReceiveConfirm");
      this.$router.pushPage(param);
    },
    /**
     * 前往明细
     */
    goDetail() {
      let param = {
        name: 'ReceiveDetail',
        params: {
          billId: this.orderData.billId,
          billCode: this.orderData.billCode,
        },
      };
      this.$router.pushPage(param);
    },
  },
  async created() {
    const { hideControl, customObj } = this.handleCustomData(storage.get('customData'));
    this.customData = customObj;
    this.hideControl = hideControl;
    // console.log('按单收货customData', Object.assign({}, this.customData));
  },
  mounted() {
    this.urlData = this.handleData('RECEIVE_QUERY');
    this.skipNext(10);
  },
  // 从二级界面返回主界面, 清空来源解析字段
  // TODO: 暂时按业务要求不在做清空操作
  // beforeRouteEnter(to, from, next) {
  // next((vm) => {
  // const _this = vm;
  // if(from.name === 'ReceiveConfirm') {
  // _this.clearSourceFields(_this);
  // }
  // });
  // },
};
</script>

<style lang="stylus" scoped>
@import '~@/style/var.styl';

.out_box {
  border-radius: 1.6vw;
  margin-top: 4.267vw;
  width: 100%;
  padding: 2.7vw 0px;
  color: #000;
  font-size: 3.467vw;
  box-shadow: 0 0 2.133vw #d9d9d9;
}

.ips-btn-normal {
  border-radius: 8px !important;
  margin: 12px 10px 12px 10px;
  background-color: var(--primaryColor) !important;
  color: #ffffff !important;
  font-size: 17px !important;
}

.ips-btn-no-value {
  border-radius: 8px !important;
  margin: 12px 10px 12px 10px;
  background-color: #D9D9D9 !important;
}
</style>
