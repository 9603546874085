import SerialChange from '../view/index'

let route = [
  {
    path: '/serial-change',
    name: 'SerialChange',
    component: SerialChange,
  },
]

export default route
