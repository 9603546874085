import request from '@/request';
import mixin  from '@/mixin/mixin';

// 查询数量
export function queryQtyInfo(param) {
  const url = `${mixin.data().winvBaseUrl}app-stock-trans/query-stock-qty-all`;
  return request.get(url, param);
}

// 确认提交
export function confirmUnstack(data) {
  const url = `${mixin.data().winvBaseUrl}app-inv/unstacking`;
  return request.post(url, data);
}

// 查询托盘详情
export function queryCidDetail(param) {
  const url = `${mixin.data().winvBaseUrl}stock-trans/query-stock`;
  return request.get(url, param);
}





