var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "hips-view",
    [
      _c("ips-header", {
        attrs: { title: _vm.$t("title.ipsBatch"), "is-index": false },
      }),
      _c(
        "div",
        { staticClass: "ips-container" },
        [
          _vm._l(_vm.cellList, function (item, index) {
            return _c("ips-value-cell", {
              key: index,
              attrs: { title: item.title, value: _vm.skuInfo[item.value] },
            })
          }),
          _c("ips-lov", {
            ref: "ref30",
            staticStyle: { "margin-top": "16px" },
            attrs: {
              label: _vm.$t("label.supplierCode"),
              title: [_vm.$t("label.vendor"), _vm.$t("label.supplierCode")],
              params: ["vendorName", "vendorCode"],
              display: "vendorCode",
              url: _vm.wmdmBaseUrl + "/vendors/lov",
            },
            on: {
              enter: function ($event) {
                return _vm.skipNext(0)
              },
              clickIcon: function ($event) {
                return _vm.handleCommonClick(30, "lov")
              },
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(30)
              },
              choose: function ($event) {
                return _vm.handleChoose("vendorCode", $event)
              },
            },
            model: {
              value: _vm.batch.vendorCode,
              callback: function ($$v) {
                _vm.$set(_vm.batch, "vendorCode", $$v)
              },
              expression: "batch.vendorCode",
            },
          }),
          _c("ips-field", {
            ref: "ref0",
            attrs: { label: _vm.$t("label.vendorNumber") },
            on: {
              enter: function ($event) {
                return _vm.skipNext(10)
              },
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(0)
              },
            },
            model: {
              value: _vm.batch.vendorBatch,
              callback: function ($$v) {
                _vm.$set(_vm.batch, "vendorBatch", $$v)
              },
              expression: "batch.vendorBatch",
            },
          }),
          _c("ips-lov", {
            ref: "ref10",
            attrs: {
              label: _vm.$t("label.battcStatus"),
              title: _vm.$t("label.battcStatus"),
              params: ["meaning"],
              "is-page-content": false,
              url:
                _vm.wmdmBaseUrl +
                "wms-common/lov-data-query?lovCode=WMDM.BATCH_STATUS",
            },
            on: {
              enter: function ($event) {
                return _vm.skipNext(20)
              },
              clickIcon: function ($event) {
                return _vm.handleCommonClick(10, "lov")
              },
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(10)
              },
              choose: function ($event) {
                return _vm.handleChoose("batchStatusMeaning", $event)
              },
            },
            model: {
              value: _vm.batch.batchStatusMeaning,
              callback: function ($$v) {
                _vm.$set(_vm.batch, "batchStatusMeaning", $$v)
              },
              expression: "batch.batchStatusMeaning",
            },
          }),
          _c("ips-field", {
            ref: "ref20",
            attrs: { label: _vm.$t("label.note") },
            on: {
              enter: function ($event) {
                return _vm.skipNext("submitRef")
              },
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(20)
              },
            },
            model: {
              value: _vm.batch.remark,
              callback: function ($$v) {
                _vm.$set(_vm.batch, "remark", $$v)
              },
              expression: "batch.remark",
            },
          }),
          _c(
            "div",
            { staticClass: "ips-footer" },
            [
              _c(
                "hips-button",
                {
                  ref: "submitRef",
                  staticClass: "btn",
                  attrs: { size: "large" },
                  on: { click: _vm.handleCommonSubmit },
                },
                [
                  _c("svg-icon", {
                    attrs: { slot: "icon", "icon-class": "success" },
                    slot: "icon",
                  }),
                  _vm._v(_vm._s(_vm.$t("button.confirm")) + "\n      "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }