import request from '@/request';
import mixin from '@/mixin/mixin';

// 批量发运 
export function batchShipment(data) {
  const url = `${mixin.data().winvBaseUrl}app-outbound-ship/whole-ship`;
  return request.post(url, data);
}

// 获取明细
export function fetchOneShippableDetail(data) {
  const url = `${mixin.data().winvBaseUrl}app-outbound-ship/fetch-one-shippable-detail`;
  return request.get(url, data);
}


// 明细发运 
export function batchDetailShipment(data) {
  const url = `${mixin.data().winvBaseUrl}app-outbound-ship/detail-ship`;
  return request.post(url, data);
}

// 跳过任务  
export function skipCurrentShippableDetail(data) {
  const url = `${mixin.data().winvBaseUrl}app-outbound-ship/skip-current-shippable-detail`;
  return request.post(url, data);
}
