var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: { title: _vm.$t("title.ReceivingAdjustment") },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _c("ips-lov", {
          ref: "ref0",
          attrs: {
            label: _vm.$t("label.receivingTray"),
            required: "",
            title: _vm.$t("label.receivingTray"),
            params: "cidCode",
            url: _vm.winvBaseUrl + "app-inbound-receive-adjust/cid-lov",
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(0)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(0, "lov")
            },
            choose: function ($event) {
              return _vm.handleChoose("cidCode", $event)
            },
          },
          model: {
            value: _vm.cidCode,
            callback: function ($$v) {
              _vm.cidCode = $$v
            },
            expression: "cidCode",
          },
        }),
        _c("ips-lov", {
          ref: "ref10",
          attrs: {
            label: _vm.$t("label.sku"),
            required: "",
            title: [_vm.$t("label.sku"), _vm.$t("label.billInfo")],
            params: ["sku", "billInfo"],
            url: _vm.winvBaseUrl + "app-inbound-receive-adjust/item-lov",
            "url-params": _vm.skuParam,
            display: "sku",
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(10)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(10, "lov")
            },
            enter: _vm.handleSkuEnter,
            down: _vm.handleSkuEnter,
            choose: function ($event) {
              return _vm.handleChoose("sku", $event)
            },
          },
          model: {
            value: _vm.sku,
            callback: function ($$v) {
              _vm.sku = $$v
            },
            expression: "sku",
          },
        }),
        _c("ips-value-cell", {
          attrs: {
            title: _vm.$t("label.goodsName"),
            value: _vm.skuInfo.goodsName,
          },
        }),
        _c("ips-value-cell", {
          attrs: { title: _vm.$t("label.unit"), value: _vm.skuInfo.uomName },
        }),
        _c("ips-value-cell", {
          attrs: {
            title: _vm.$t("label.billInfo"),
            value: _vm.skuInfo.billInfo,
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isBatch,
              expression: "isBatch",
            },
          ],
          ref: "ref20",
          attrs: {
            label: _vm.$t("label.batch"),
            required: "",
            title: _vm.$t("label.batch"),
            params: "batchCode",
            url: _vm.winvBaseUrl + "app-inbound-receive-adjust/batch-lov",
            "url-params": _vm.batchParam,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(20)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(20, "lov")
            },
            enter: _vm.handleBatchEnter,
            down: _vm.handleBatchEnter,
            choose: function ($event) {
              return _vm.handleChoose("batchCode", $event)
            },
          },
          model: {
            value: _vm.batchCode,
            callback: function ($$v) {
              _vm.batchCode = $$v
            },
            expression: "batchCode",
          },
        }),
        _c("ips-value-cell", {
          attrs: {
            title: _vm.$t("label.receiveNum"),
            value: _vm.skuInfo.receiveQty,
          },
        }),
        _vm.enableSerial
          ? _c(
              "div",
              { staticClass: "input-button" },
              [
                _c(
                  "hips-button",
                  {
                    ref: "inputRef",
                    style: Object.assign({}, _vm.inputBtnStyle),
                    on: { click: _vm.goToSerial },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("button.serialNumEntry")) +
                        "\n      "
                    ),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
        _c("ips-input-number", {
          ref: "ref40",
          attrs: {
            required: "",
            disabled: _vm.qtyDisabled,
            min: null,
            "more-than-zero": true,
            label: _vm.$t("label.revisionNumber"),
            "enable-serial": _vm.serialType,
          },
          on: {
            enter: function ($event) {
              return _vm.skipNext(50)
            },
            down: function ($event) {
              return _vm.skipNext(50)
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(40)
            },
            blur: _vm.checkQtyBlur,
          },
          model: {
            value: _vm.changeQty,
            callback: function ($$v) {
              _vm.changeQty = $$v
            },
            expression: "changeQty",
          },
        }),
        _c("ips-field", {
          ref: "ref50",
          attrs: { required: "", label: _vm.$t("label.fixReason") },
          on: {
            enter: function ($event) {
              return _vm.skipNext("submitRef")
            },
            down: function ($event) {
              return _vm.skipNext("submitRef")
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(50)
            },
          },
          model: {
            value: _vm.remark,
            callback: function ($$v) {
              _vm.remark = $$v
            },
            expression: "remark",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-footer" },
      [
        _c(
          "hips-button",
          {
            ref: "submitRef",
            attrs: { size: "large" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonSubmit.apply(null, arguments)
              },
            },
          },
          [
            _c("svg-icon", {
              attrs: { slot: "icon", "icon-class": "success" },
              slot: "icon",
            }),
            _vm._v("\n      " + _vm._s(_vm.$t("button.submit")) + "\n    "),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }