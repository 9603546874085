var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: {
            title: _vm.$t("title.waveNum") + "" + _vm.waveBillCode,
            "is-index": false,
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _c(
          "div",
          { staticClass: "ips-outbound-style" },
          [
            _c("ips-lov", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.customData["outboundOrder"]
                    ? _vm.hideControl["outboundOrder"]
                    : true,
                  expression:
                    "customData['outboundOrder'] ? hideControl['outboundOrder']: true",
                },
              ],
              ref: "ref0",
              attrs: {
                label: _vm.$t("label.queryType"),
                title: _vm.$t("label.queryType"),
                params: "meaning",
                url: _vm.wmdmBaseUrl + "wms-common/lov-data-query",
                "url-params": { lovCode: "WINV.SORTING_QUERY" },
                "is-page-content": false,
                custom: _vm.customData["outboundOrder"],
                "source-parse-field": _vm.sourceParseField,
                "is-control": "",
                readonly: true,
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleCommonClick(0)
                },
                clickIcon: function ($event) {
                  return _vm.handleCommonClick(0, "lov")
                },
                enter: function ($event) {
                  return _vm.skipNext(10)
                },
                down: function ($event) {
                  return _vm.skipNext(10)
                },
                choose: function ($event) {
                  return _vm.handleChoose("outboundOrder", $event)
                },
                setSourceField: function ($event) {
                  return _vm.saveSourceField($event)
                },
                valuedByFoucs: function ($event) {
                  return _vm.valuedByFoucs($event)
                },
              },
              model: {
                value: _vm.billData.outboundOrder,
                callback: function ($$v) {
                  _vm.$set(_vm.billData, "outboundOrder", $$v)
                },
                expression: "billData.outboundOrder",
              },
            }),
          ],
          1
        ),
        _c(
          "hips-row",
          [
            _c(
              "hips-col",
              { attrs: { span: "19" } },
              [
                _c("ips-field", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.customData["querySearch"]
                        ? _vm.hideControl["querySearch"]
                        : true,
                      expression:
                        "customData['querySearch'] ? hideControl['querySearch']: true",
                    },
                  ],
                  ref: "ref10",
                  attrs: {
                    label: _vm.$t("label.queryConditions"),
                    custom: _vm.customData["querySearch"],
                    "source-parse-field": _vm.sourceParseField,
                  },
                  on: {
                    enter: function ($event) {
                      return _vm.skipNext(20)
                    },
                    setSourceField: function ($event) {
                      return _vm.saveSourceField($event)
                    },
                    valuedByFoucs: function ($event) {
                      return _vm.valuedByFoucs($event)
                    },
                  },
                  model: {
                    value: _vm.billData.querySearch,
                    callback: function ($$v) {
                      _vm.$set(_vm.billData, "querySearch", $$v)
                    },
                    expression: "billData.querySearch",
                  },
                }),
              ],
              1
            ),
            _c(
              "hips-col",
              { staticClass: "ips-row-left", attrs: { span: "4" } },
              [
                _c(
                  "hips-button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.customData["search"]
                          ? _vm.hideControl["search"]
                          : true,
                        expression:
                          "customData['search'] ? hideControl['search']: true",
                      },
                    ],
                    ref: "ref20",
                    attrs: { type: "warning" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.handleButtonSubmit.apply(null, arguments)
                      },
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleButtonSubmit.apply(null, arguments)
                      },
                      keypress: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("button.search")) +
                        "\n        "
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "ips-table" },
          [
            _c(
              "table",
              [
                _c(
                  "tr",
                  { staticStyle: { "background-color": "#eee" } },
                  _vm._l(_vm.tableTitle, function (item, index) {
                    return _c("th", { key: index, attrs: { scope: "col" } }, [
                      _vm._v(
                        "\n            " + _vm._s(item.name) + "\n          "
                      ),
                    ])
                  }),
                  0
                ),
                _vm._l(_vm.tableData, function (item, index) {
                  return _c(
                    "tr",
                    {
                      key: index,
                      ref: "tr" + index,
                      refInFor: true,
                      class: item.selectedFlag ? "back-green" : "",
                      on: {
                        click: function ($event) {
                          return _vm.handleSelect(item, index)
                        },
                      },
                    },
                    [
                      _c("td", [_vm._v(_vm._s(item.sortingLocation))]),
                      _c("td", [_vm._v(_vm._s(item.sku))]),
                      _c(
                        "td",
                        {
                          ref: "num" + index,
                          refInFor: true,
                          staticClass: "font-skyblue",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.modifyNum(index)
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(item.processQty) + "/" + _vm._s(item.planQty)
                          ),
                        ]
                      ),
                      _c("td", [_vm._v(_vm._s(item.orderLine))]),
                    ]
                  )
                }),
              ],
              2
            ),
            _c(
              "hips-popup",
              {
                model: {
                  value: _vm.modifyFlag,
                  callback: function ($$v) {
                    _vm.modifyFlag = $$v
                  },
                  expression: "modifyFlag",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "modifyModel" },
                  [
                    _c("h1", [
                      _vm._v(_vm._s(_vm.$t("label.processQtyUpdate"))),
                    ]),
                    _c("ips-input-number", {
                      ref: "modifyRef",
                      attrs: {
                        label: _vm.$t("label.dischargeNum"),
                        min: null,
                        disabled: _vm.serialNumber,
                        "more-than-zero": true,
                      },
                      on: { enter: _vm.modifyConfirm, down: _vm.modifyConfirm },
                      model: {
                        value: _vm.processQty,
                        callback: function ($$v) {
                          _vm.processQty = $$v
                        },
                        expression: "processQty",
                      },
                    }),
                    _c(
                      "hips-button",
                      {
                        staticStyle: { width: "50%", "margin-top": "10px" },
                        attrs: { type: "warning" },
                        on: { click: _vm.modifyConfirm },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("button.confirm")) +
                            "\n          "
                        ),
                      ]
                    ),
                    _c(
                      "hips-button",
                      {
                        staticStyle: { width: "50%", "margin-top": "10px" },
                        attrs: { type: "warn" },
                        on: { click: _vm.modifyCancel },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("button.cancel")) + " ")]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-footer" },
      [
        _c(
          "hips-button",
          {
            ref: "submitRef",
            attrs: { size: "large" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleSortSubmit.apply(null, arguments)
              },
            },
          },
          [
            _c("svg-icon", {
              attrs: { slot: "icon", "icon-class": "success" },
              slot: "icon",
            }),
            _vm._v(
              "\n      " + _vm._s(_vm.$t("button.sortConfirm")) + "\n    "
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }