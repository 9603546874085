var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: {
            title: _vm.$t("title.acceptConfirm"),
            "is-index": false,
            trigger: _vm.$t("title.detail"),
            "on-go-detail": _vm.goDetail,
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["locationCode"]
                ? _vm.hideControl["locationCode"]
                : true,
              expression:
                "customData['locationCode'] ? hideControl['locationCode'] : true",
            },
          ],
          ref: "ref0",
          attrs: {
            label: _vm.$t("label.location"),
            required: "",
            title: [
              _vm.$t("label.locationCode"),
              _vm.$t("label.whareaCode"),
              _vm.$t("label.whareaName"),
            ],
            display: "locationCode",
            params: ["locationCode", "whareaCode", "whareaName"],
            url: _vm.wmdmBaseUrl + "wms-locations/app",
            "url-params": {
              warehouseId: _vm.warehouseId,
              locationType: "T_RCV",
              whareaIdsString: _vm.whareaIdsString,
              whareaId: _vm.whareaIdsString ? _vm.whareaIdSku : null,
              locationCode: _vm.inputData.locationCode,
            },
            "pre-parse-url": _vm.urlData["locationCode"],
            custom: _vm.customData["locationCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(0)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(0, "lov")
            },
            enter: _vm.handleLocationEnter,
            down: _vm.handleLocationEnter,
            choose: function ($event) {
              return _vm.chooseLocation($event)
            },
          },
          model: {
            value: _vm.inputData.locationCode,
            callback: function ($$v) {
              _vm.$set(_vm.inputData, "locationCode", $$v)
            },
            expression: "inputData.locationCode",
          },
        }),
        _c("ips-field", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.parentCidCodeShow
                ? false
                : _vm.containerManageFlag
                ? _vm.customData["parentCidCode"]
                  ? _vm.hideControl["parentCidCode"]
                  : _vm.containerManageFlag
                : false,
              expression:
                "!parentCidCodeShow ? false : containerManageFlag ? (customData['parentCidCode'] ? hideControl['parentCidCode'] : containerManageFlag) : false",
            },
          ],
          ref: "ref5",
          attrs: {
            params: "parentCidCode",
            display: "parentCidCode",
            "pre-parse-url": _vm.urlData["parentCidCode"],
            label: _vm.$t("label.parentTray"),
            custom: _vm.customData["parentCidCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
            blur: function ($event) {
              return _vm.handleBlur("parentCidCode")
            },
            enter: function ($event) {
              _vm.isPrePackage ? _vm.skipNext("submitRef") : _vm.skipNext(10)
            },
            down: function ($event) {
              _vm.isPrePackage ? _vm.skipNext("submitRef") : _vm.skipNext(10)
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(5)
            },
          },
          model: {
            value: _vm.inputData.parentCidCode,
            callback: function ($$v) {
              _vm.$set(_vm.inputData, "parentCidCode", $$v)
            },
            expression: "inputData.parentCidCode",
          },
        }),
        _c("ips-field", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isPrepackParentCid
                ? false
                : _vm.containerManageFlag
                ? _vm.customData["cidCode"]
                  ? _vm.hideControl["cidCode"]
                  : _vm.containerManageFlag
                : false,
              expression:
                "isPrepackParentCid ? false : containerManageFlag ? (customData['cidCode'] ? hideControl['cidCode'] : containerManageFlag) : false",
            },
          ],
          ref: "ref10",
          attrs: {
            params: "cidCode",
            display: "cidCode",
            "pre-parse-url": _vm.urlData["cidCode"],
            required: _vm.inputData.parentCidCode ? true : !_vm.cidCodeRequired,
            label: _vm.$t("label.tray"),
            custom: _vm.customData["cidCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
            blur: function ($event) {
              return _vm.handleBlur("cidCode")
            },
            enter: function ($event) {
              _vm.issku
                ? _vm.skipNext(20)
                : _vm.isbatch
                ? _vm.skipNext(30)
                : _vm.skipNext(50)
            },
            down: function ($event) {
              _vm.issku
                ? _vm.skipNext(20)
                : _vm.isbatch
                ? _vm.skipNext(30)
                : _vm.skipNext(50)
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(10)
            },
          },
          model: {
            value: _vm.inputData.cidCode,
            callback: function ($$v) {
              _vm.$set(_vm.inputData, "cidCode", $$v)
            },
            expression: "inputData.cidCode",
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isPrepackParentCid
                ? false
                : _vm.issku
                ? _vm.customData["sku"]
                  ? _vm.hideControl["sku"]
                  : _vm.issku
                : false,
              expression:
                "isPrepackParentCid ? false : issku ? (customData['sku'] ? hideControl['sku'] : issku) : false",
            },
          ],
          ref: "ref20",
          attrs: {
            label: _vm.$t("label.sku"),
            required: _vm.issku,
            title: [
              _vm.$t("label.lineNum"),
              _vm.$t("label.sku"),
              _vm.$t("label.goodsName"),
            ],
            params: ["lineNum", "sku", "goodsName"],
            display: "sku",
            url: _vm.winvBaseUrl + "app-inbound/query-inbound-lines-info",
            "pre-parse-title": [_vm.$t("label.sku"), _vm.$t("label.goodsName")],
            "url-params": {
              billCode: _vm.billCode,
              cidCode: _vm.inputData.cidCode,
              warehouseId: _vm.warehouseId,
              billId: _vm.billId,
            },
            "pre-parse-url": _vm.urlData["sku"],
            disabled: _vm.isPrePackage,
            custom: _vm.customData["sku"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(20)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(20, "lov")
            },
            choose: function ($event) {
              return _vm.chooseSku($event)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.inputData.sku,
            callback: function ($$v) {
              _vm.$set(_vm.inputData, "sku", $$v)
            },
            expression: "inputData.sku",
          },
        }),
        _vm.inputData.sku
          ? _c(
              "div",
              [
                _c(
                  "ips-page-card",
                  {
                    attrs: {
                      "react-field": ["uom", "orderLine"],
                      item: _vm.inputData,
                    },
                  },
                  [
                    _c("ips-message-tip", {
                      attrs: { message: _vm.inputData.goods },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _c("ips-field", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isbatch
                ? _vm.customData["batchCode"]
                  ? _vm.hideControl["batchCode"]
                  : _vm.isbatch
                : false,
              expression:
                "isbatch ? (customData['batchCode'] ? hideControl['batchCode'] : isbatch) : false",
            },
          ],
          ref: "ref30",
          attrs: {
            required: "",
            label: _vm.$t("label.batch"),
            params: "batchCode",
            display: "batchCode",
            "pre-parse-url": _vm.urlData["batchCode"],
            disabled: _vm.batchDisabled,
            custom: _vm.customData["batchCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
            blur: function ($event) {
              return _vm.handleBlur("batch")
            },
            enter: function ($event) {
              return _vm.handleBatchEnter()
            },
            down: function ($event) {
              return _vm.handleBatchEnter()
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(30)
            },
          },
          model: {
            value: _vm.inputData.batchCode,
            callback: function ($$v) {
              _vm.$set(_vm.inputData, "batchCode", $$v)
            },
            expression: "inputData.batchCode",
          },
        }),
        _c(
          "hips-row",
          [
            (_vm.prepackagedData &&
              ["INV_CTRL", "IN_CTRL", "SEMI_INV_CTRL"].includes(
                _vm.serialType
              )) ||
            _vm.showCidDetail ||
            _vm.showSeriesDetail ||
            _vm.isPrepackParentCid
              ? _c(
                  "div",
                  { staticStyle: { "margin-top": "16px" } },
                  [
                    _c(
                      "hips-col",
                      {
                        staticStyle: { "margin-bottom": "16px" },
                        attrs: { span: "14" },
                      },
                      [
                        _c("ips-input-number", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.customData["receiveQty"]
                                ? _vm.hideControl["receiveQty"]
                                : true,
                              expression:
                                "customData['receiveQty'] ? hideControl['receiveQty'] : true",
                            },
                          ],
                          ref: "ref50",
                          attrs: {
                            required: "",
                            label: _vm.$t("label.receiveNum"),
                            "thousands-formatter": "",
                            "not-auto-fill": "",
                            custom: _vm.customData["receiveQty"],
                            "source-parse-field": _vm.sourceParseField,
                            "enable-serial": _vm.serialType,
                            disabled: _vm.readOnly || _vm.isPrePackage,
                            "more-than-zero": "",
                          },
                          on: {
                            setSourceField: function ($event) {
                              return _vm.saveSourceField($event)
                            },
                            valuedByFoucs: function ($event) {
                              return _vm.valuedByFoucs($event)
                            },
                            enter: function ($event) {
                              _vm.showSeriesDetail
                                ? _vm.skipSerail()
                                : _vm.skipNext("submitRef")
                            },
                            down: function ($event) {
                              _vm.showSeriesDetail
                                ? _vm.skipSerail()
                                : _vm.skipNext("submitRef")
                            },
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.handleCommonClick(50)
                            },
                          },
                          model: {
                            value: _vm.inputData.receiveQty,
                            callback: function ($$v) {
                              _vm.$set(_vm.inputData, "receiveQty", $$v)
                            },
                            expression: "inputData.receiveQty",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "hips-col",
                      { attrs: { span: "10" } },
                      [
                        _c(
                          "hips-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.customData["showCidDetail"]
                                  ? _vm.hideControl["showCidDetail"]
                                  : _vm.showCidDetail,
                                expression:
                                  "customData['showCidDetail'] ? hideControl['showCidDetail'] : showCidDetail",
                              },
                            ],
                            ref: "refCid",
                            staticStyle: {
                              "margin-left": "10px",
                              "background-color": "var(--primaryColor)",
                              color: "#FFFFFF",
                              height: "36px",
                              "line-height": "36px",
                            },
                            attrs: { size: "large" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.goToCidDetail.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("button.cidDetail")) +
                                "\n          "
                            ),
                          ]
                        ),
                        _c(
                          "hips-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.customData["showPrePackCidDetail"]
                                  ? _vm.hideControl["showPrePackCidDetail"]
                                  : _vm.isPrepackParentCid,
                                expression:
                                  "customData['showPrePackCidDetail'] ? hideControl['showPrePackCidDetail'] : isPrepackParentCid",
                              },
                            ],
                            ref: "refCid",
                            staticStyle: {
                              "margin-left": "10px",
                              "background-color": "var(--primaryColor)",
                              color: "#FFFFFF",
                              height: "36px",
                              "line-height": "36px",
                            },
                            attrs: { size: "large" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.goToPrePackCidDetail.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("button.prePackCidDetail")) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c("serialComp", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showSeriesDetail
                    ? _vm.customData["showSeriesDetail"]
                      ? _vm.hideControl["showSeriesDetail"]
                      : _vm.showSeriesDetail
                    : false,
                  expression:
                    "showSeriesDetail ? (customData['showSeriesDetail'] ? hideControl['showSeriesDetail'] : showSeriesDetail) : false",
                },
              ],
              ref: "refSerail",
              attrs: { params: _vm.serialCompParams },
              on: {
                inboundSerialReceiptList: _vm.changeInboundSerialReceiptList,
              },
              model: {
                value: _vm.inputData.receiveQty,
                callback: function ($$v) {
                  _vm.$set(_vm.inputData, "receiveQty", $$v)
                },
                expression: "inputData.receiveQty",
              },
            }),
          ],
          1
        ),
        _c("hips-row", [
          !["INV_CTRL", "IN_CTRL", "SEMI_INV_CTRL"].includes(_vm.serialType) &&
          !_vm.showSeriesDetail &&
          !_vm.showCidDetail &&
          !_vm.isPrepackParentCid
            ? _c(
                "div",
                { staticStyle: { "margin-top": "16px" } },
                [
                  _c(
                    "hips-col",
                    { attrs: { span: "24" } },
                    [
                      _c("ips-input-number", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isPrepackParentCid
                              ? false
                              : _vm.customData["receiveQty"]
                              ? _vm.hideControl["receiveQty"]
                              : true,
                            expression:
                              "isPrepackParentCid ? false : customData['receiveQty'] ? hideControl['receiveQty'] : true",
                          },
                        ],
                        ref: "ref50",
                        attrs: {
                          required: "",
                          label: _vm.$t("label.receiveNum"),
                          "thousands-formatter": "",
                          "enable-serial": _vm.serialType,
                          disabled: _vm.readOnly || _vm.isPrePackage,
                          custom: _vm.customData["receiveQty"],
                          "source-parse-field": _vm.sourceParseField,
                          "not-auto-fill": "",
                          "more-than-zero": "",
                        },
                        on: {
                          enter: function ($event) {
                            return _vm.skipNext("submitRef")
                          },
                          down: function ($event) {
                            return _vm.skipNext("submitRef")
                          },
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.handleCommonClick(50)
                          },
                          setSourceField: function ($event) {
                            return _vm.saveSourceField($event)
                          },
                          valuedByFoucs: function ($event) {
                            return _vm.valuedByFoucs($event)
                          },
                        },
                        model: {
                          value: _vm.inputData.receiveQty,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputData, "receiveQty", $$v)
                          },
                          expression: "inputData.receiveQty",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
        _vm.inputData.sku
          ? _c(
              "div",
              { staticClass: "count_block_box" },
              [
                _c("ips-number-count", {
                  attrs: {
                    count: _vm.inputData.planQty,
                    "text-field": "planQty",
                    "background-color": "#E6F7FF",
                    "border-color": "#91D5FF",
                    "font-color": "var(--primaryColor)",
                  },
                }),
                _c("ips-number-count", {
                  attrs: {
                    count: _vm.inputData.receivedQty,
                    "text-field": "receivedQty",
                    "background-color": "#F6FFED",
                    "border-color": "#B7EB8F",
                    "font-color": "#52C41A",
                  },
                }),
                _c("ips-number-count", {
                  attrs: {
                    count: _vm.inputData.unreceiveQty,
                    "text-field": "unReceiveQty",
                    "background-color": "#FFF4E6",
                    "border-color": "#FFD382",
                    "font-color": "#FF8F07",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-footer" },
      [
        _c(
          "hips-button",
          {
            ref: "submitRef",
            staticClass: "ips-btn-normal",
            attrs: { type: "warning", size: "large" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonSubmit.apply(null, arguments)
              },
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleCommonSubmit.apply(null, arguments)
              },
              keypress: function ($event) {
                $event.preventDefault()
              },
            },
          },
          [
            _c("svg-icon", {
              attrs: { slot: "icon", "icon-class": "success" },
              slot: "icon",
            }),
            _vm._v("\n      " + _vm._s(_vm.$t("button.confirm")) + "\n    "),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }