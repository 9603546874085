<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header
        :title="$t('title.directInBound')"
        :trigger="orderData.billTypeName ? $t('title.detail') : ''"
        :on-go-detail="goDetail"
        :is-index="true"
        @beforeBack="goBack()"
      />
    </div>
    <div class="ips-container">
      <!-- 来源单号 -->
      <ips-lov
        v-show="false"
        :label="$t('label.resourceNum')"
        :title="$t('label.resourceNum')"
        v-model="inputData.resourceNum"
        v-if="customData['resourceNum'] ? hideControl['resourceNum'] : true"
        params="sourceBillCode"
        display="sourceBillCode"
        :url="`${winvBaseUrl}app-quick-inbound/query-source-inbound-order`"
        :pre-parse-url="urlData['resourceNum']"
        :custom="customData['resourceNum']"
        :source-parse-field="sourceParseField"
        ref="ref0"
        is-control
        @click.stop="handleCommonClick(0)"
        @clickIcon="handleCommonClick(0, 'lov')"
        @enter="skipNext(10)"
        @down="skipNext(10)"
        @choose="handleChoose('resourceNum', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 入库单号 -->
      <ips-lov
        :label="$t('label.billCode')"
        :title="$t('label.billCode')"
        required
        v-model="inputData.enterNum"
        v-show="customData['enterNum'] ? hideControl['enterNum'] : true"
        params="billCode"
        display="billCode"
        :url="`${winvBaseUrl}app-quick-inbound/query-inbound-order`"
        :url-params="{ sourceBillId: sourceBillId, pageId: menuId }"
        :pre-parse-url="urlData['enterNum']"
        :custom="customData['enterNum']"
        :source-parse-field="sourceParseField"
        ref="ref10"
        @click.stop="handleCommonClick(10)"
        @clickIcon="handleCommonClick(10, 'lov')"
        @enter="skipNext(20)"
        @down="skipNext(20)"
        @choose="handleChoose('enterNum', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 接收单号 -->
      <!-- v-show="customData['receiptNumber'] ? hideControl['receiptNumber'] : true" -->
      <ips-field
        v-show="false"
        :label="$t('label.receiptNumber')"
        v-model="inputData.receiptNumber"
        params="receiptNumber"
        display="receiptNumber"
        :pre-parse-url="urlData['receiptNumber']"
        :custom="customData['receiptNumber']"
        :source-parse-field="sourceParseField"
        ref="ref20"
        @blur="handleReceiptNumberCheck"
        @click.stop="handleCommonClick(20)"
        @enter="skipNext(30)"
        @down="skipNext(30)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 入库仓库 -->
      <!-- v-show="customData['warehouseCode'] ? hideControl['warehouseCode'] : true" -->
      <ips-lov
        v-show="false"
        :label="$t('label.storageWarehouse')"
        required
        v-model="inputData.warehouseCode"
        :title="[$t('title.warehouseCode'), $t('title.warehouseName')]"
        display="warehouseCode"
        :params="['warehouseCode', 'warehouseName']"
        :url="`${wmdmBaseUrl}warehouses`"
        :url-params="{ warehouseIdStr }"
        :pre-parse-url="urlData['warehouseCode']"
        ref="ref30"
        :custom="customData['warehouseCode']"
        :source-parse-field="sourceParseField"
        @click.stop="handleCommonClick(30)"
        @clickIcon="handleCommonClick(30, 'lov')"
        @enter="skipNext('submitRef')"
        @down="skipNext('submitRef')"
        @choose="handleChoose('warehouseCode', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />

      <inboundReceive
        v-if="showReceive"
        :receive-param="receiveParam"
      />

      <div
        class="out_box"
        v-if="false"
      >
        <!-- v-if="orderData.billTypeName" -->
        <!-- 单据类型 -->
        <ips-value-cell
          :title="$t('label.billType')"
          image-flag
          :value="orderData.billTypeName || '无'"
        />
        <!-- 供应商 -->
        <ips-value-cell
          :title="$t('label.vendor')"
          image-flag
          :value="orderData.vendorName || '无'"
        />
        <!-- 客户 -->
        <ips-value-cell
          :title="$t('label.customer')"
          image-flag
          :value="orderData.customerCode || '无'"
        />
        <!-- 仓库 -->
        <ips-value-cell
          :title="$t('label.warehouse')"
          image-flag
          :value="orderData.warehouseName || '无'"
        />
        <!-- 货主 -->
        <ips-value-cell
          :title="$t('label.ownerCode')"
          image-flag
          :value="orderData.ownerName || '无'"
        />
      </div>
    </div>
    <div
      v-if="false"
      class="ips-footer"
    >
      <hips-button
        size="large"
        @click.stop="handleCommonSubmit"
        @keyup.enter.native="handleCommonSubmit"
        @keypress.native.prevent
        ref="submitRef"
        :class="[
          'ips-btn-normal',
          {
            'ips-btn-no-value': !orderData.billTypeName,
          },
        ]"
      >
        <svg-icon
          slot="icon"
          icon-class="success"
        />
        {{ $t('button.submit') }}
      </hips-button>
    </div>
  </hips-view>
</template>

<script>
import { Cell } from '@hips/vue-ui';
import { storage } from '@/utils';
import { fromBillBlur, checkReceiptNumber, autoCreateReceiptNum, queryWhareaStr } from '../api';
import inboundReceive from './inbound-receive-comp.vue';
export default {
  name: 'DerectInbound',
  data() {
    return {
      receiveParam: {},
      showReceive: false,
      inputData: {
        resourceNum: '',
        enterNum: '',
        receiptNumber: '',
        warehouseCode: '',
      },
      orderData: {}, //入库单号数据
      batchControl: '',
      cidControl: '',
      sourceBillId: '',
      whareaIdsString: '',
      menuId: storage.get('menuId'),
      urlData: {},
      customData: {}, // 个性化配置信息列表
      hideControl: {}, // 个性化配置显示隐藏控制
      sourceParseField: {}, // 界面中所有的来源解析字段
    };
  },
  components: {
    [Cell.name]: Cell,
    inboundReceive
  },
  methods: {
    goBack() {
      this.$router.push('/applications')
    },
    /**
     * lov选择框，选择数据
     * @param type lov
     * @param obj 选中对象
     */
    handleChoose(type, obj) {
      switch (type) {
        case 'resourceNum':
          this.sourceBillId = obj.sourceBillId;
          this.handleBlur();
          break;
        case 'enterNum':
          this.orderData = obj;
          this.batchControl = obj.batchControl;
          this.cidControl = obj.cidControl;
          this.billdBlur();
          break;
        case 'warehouseCode':
          this.warehouseId = obj.warehouseId;
          break;
        default:
          return null;
      }
    },
    /**
     *@functionName: saveSourceField
     *@description: 保存来源解析字段并赋值Enter
     *@params1: fieldObj[Object] 来源解析字段对象,
     fieldCode[String] 来源解析字段
     parseValue[String] 二维码解析值
     subFields[Array]] 关联子字段
     */
    saveSourceField(fieldObj) {
      this.saveSourceParseField(this, fieldObj);
    },
    // 字段聚焦时赋值操作
    async valuedByFoucs(options = {}) {
      const { custom = {}, currentValue } = options;
      await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
    },

    handleBlur() {
      this.sourceBillBlur();
    },

    async billdBlur() {
      let data = {
        params: {
          billId: this.orderData.billId,
          billTypeId: this.orderData.billTypeId,
          billType: this.orderData.billType,
        },
      };
      const res = await queryWhareaStr(data);
      if (res) {
        const { whareaIdsString, warehouseIdStr } = res;
        this.whareaIdsString = whareaIdsString;
        this.warehouseIdStr = warehouseIdStr;
        const warehouseIdArr = this.warehouseIdStr.split(',');
        if (warehouseIdArr.length === 1) {
          this.warehouseId = warehouseIdArr[0];
          this.inputData.warehouseCode = res.warehouseCodeStr;
          this.skipNext('submitRef');
        }

        this.$nextTick(() => {
          this.handleButtonSubmit()
        }, 50)
      }
    },
    /**
     * 校验接收号
     */
    async handleReceiptNumberCheck() {
      if (this.inputData.receiptNumber) {
        this.$hint.showLoading();
        const onConfirm = () => {
          this.$nextTick(() => {
            this.skipNext(30);
          });
        };
        const onCancel = () => {
          this.$nextTick(() => {
            this.skipNext(20);
          });
        };
        try {
          let data = {
            params: {
              receiptNumber: this.inputData.receiptNumber,
            },
          };
          const res = await checkReceiptNumber(data);
          if (!res) {
            this.$hint.showAlert(this.$i18n.t('message.receivedNumberHaved'), onConfirm, onCancel);
          }
        } finally {
          this.$hint.hideLoading();
        }
      } else {
        // 自动生成接收号
        this.$hint.showLoading('自动生成接收号中...');
        this.inputData.receiptNumber = await autoCreateReceiptNum();
        this.$hint.hideLoading('接收号生成成功');
        if (this.inputData.warehouseCode === '') {
          this.skipNext(30);
        } else {
          this.skipNext('submitRef');
        }
      }
    },

    /**
     * 来源单号失去焦点校验
     */
    async sourceBillBlur() {
      if (this.inputData.resourceNum) {
        this.$hint.showLoading();
        try {
          let data = {
            params: {
              sourceBillCode: this.inputData.resourceNum,
            },
          };
          const res = await fromBillBlur(data);
          if (res && res.content) {
            if (res.content.length === 1) {
              this.inputData.sourceBillCode = res.content[0].sourceBillCode;
              this.inputData.enterNum = res.content[0].billCode;
              this.orderData = res.content[0];
            }
            this.$refs['ref0'].validateLov = true;
            this.skipNext(10);
          } else {
            this.$hint.showFailed(this.$i18n.t('message.findNull', { label: '来源单号' }));
            this.inputData.resourceNum = '';
            this.$refs['ref0'].validateLov = false;
          }
        } finally {
          this.$hint.hideLoading();
        }
      }
    },

    /**
     * 提交
     */
    handleButtonSubmit() {
      if (!this.inputData.enterNum) {
        this.$hint.showFailed(this.$i18n.t('message.checkRequired'));
        return;
      }
      const param = {
        name: 'DerectInboundReceive',
        params: {
          billId: this.orderData.billId,
          batchControl: this.batchControl,
          billCode: this.orderData.billCode,
          billTypeId: this.orderData.billTypeId,
          whareaId: this.orderData.whareaId,
          whareaCode: this.orderData.whareaCode,
          receiptNumber: this.inputData.receiptNumber,
          whareaIdsString: this.whareaIdsString,
          warehouseId: this.warehouseId,
          warehouseCode: this.inputData.warehouseCode,
          warehouseIdStr: this.warehouseIdStr,
          sourceParseField: {
            ...this.sourceParseField
          },
          customData: this.customData,
          hideControl: this.hideControl,
        },
      };
      this.$store.commit('pushKeepAlives', 'DerectInbound');
      this.receiveParam = param;
      setTimeout(() => {
        this.showReceive = true;
      }, 300)
      // this.$router.pushPage(param);
    },

    /**
     * 前往明细
     */
    goDetail() {
      let param = {
        name: 'InboundDetail',
        params: {
          billId: this.orderData.billId,
          billCode: this.orderData.billCode,
          billTypeId: this.orderData.billTypeId,
        },
      };
      this.$router.pushPage(param);
    },
  },
  created() {
    const { customObj, hideControl } = this.handleCustomData(storage.get('customData'));
    this.customData = customObj;
    this.hideControl = hideControl;
    // console.log('库存转移customData', Object.assign({}, this.customData));
  },
  async mounted() {
    this.inputFieldList = [
      { type: 'lov', required: false, ref: 0, bind: 'inputData.resourceNum', field: 'resourceNum' },
      { type: 'lov', required: true, ref: 10, bind: 'inputData.enterNum', field: 'enterNum' },
      { type: 'input', required: false, ref: 20, bind: 'inputData.receiptNumber', field: 'receiptNumber' },
      { type: 'lov', required: true, ref: 30, bind: 'inputData.warehouseCode', field: 'warehouseCode' },
    ];
    this.urlData = this.handleData('DIRECT_INB_QUERY');
    this.skipNext(10);
  },
  activated() {
    const formSerial = JSON.parse(sessionStorage.getItem('formSerial'));
    if (formSerial === '1') {
      this.inputData.resourceNum = undefined;
      this.inputData.enterNum = undefined;
      this.inputData.receiptNumber = undefined;
      this.sourceBillId = undefined;
      this.billId = undefined;
      this.inputData.enterNum = undefined;
      this.inputData.receiptNum = undefined;
      this.orderData = {};
      this.batchControl = undefined;
      this.cidControl = undefined;
      this.whareaIdsString = undefined;
      this.skipNext(10);
    }
    sessionStorage.removeItem('formSerial');
  },
  watch: {
    'inputData.resourceNum': function (val) {
      if (!val) {
        this.sourceBillId = undefined;
        this.billId = undefined;
        this.inputData.enterNum = undefined;
        this.inputData.receiptNum = undefined;
        this.orderData = {};
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    console.log(to.name)
    if (to.name === 'InboundDetail' || to.name === 'InboundCidDetail' || to.name === 'createBatch') {
      this.$store.commit('pushKeepAlives', 'DerectInbound');
    } else {
      this.$store.commit('deleteKeepAlives', 'DerectInbound');
    }
    this.$nextTick(() => {
      next();
    });
  },
};
</script>

<style lang="stylus" scoped>
@import '~@/style/var.styl';

.out_box {
  border-radius: 1.6vw;
  margin-top: 4.267vw;
  width: 100%;
  padding: 2.133vw 0px;
  color: #000;
  font-size: 3.467vw;
  box-shadow: 0 0 2.133vw #d9d9d9;
}

.ips-btn-normal {
  background-color: var(--primaryColor) !important;
  color: #ffffff !important;
  font-size: 17px !important;
}

.ips-btn-no-value {
  background-color: #D9D9D9 !important;
}
</style>
