import Mock from 'mockjs'

import { createMock } from './utils'

// import User from './modules/user'
import Custom from './modules/custom'


Mock.setup({
    timeout: 2000
})

createMock(Mock, Custom);

export default Mock