import ipsBatch from "../views/ipsBatch";
import ipsSerial from "../views/ipsSerial";
import ipsNewSerial from "../views/ipsNewSerial";
import BatchInputSerial from "../views/batchSerialInput";

let routes = [
  {
    path: '/createBatch',
    name: 'createBatch',
    component: ipsBatch
  },
  {
    path: '/inputSerial',
    name: 'inputSerial',
    component: ipsSerial,
    meta: {keepAlive: true},
  },
  {
    path: '/serialComponent',
    name: 'serialComponent',
    component: ipsNewSerial,
    meta: {keepAlive: true},
  },
  {
    path: '/batchSerialInput',
    name: 'batchSerialInput',
    component: BatchInputSerial,
  },
];

export default routes
