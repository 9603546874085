var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ips-field", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.parentCidCodeShow
              ? false
              : _vm.containerManageFlag
              ? _vm.customData["parentCidCode"]
                ? _vm.hideControl["parentCidCode"]
                : _vm.containerManageFlag
              : false,
            expression:
              "\n      !parentCidCodeShow\n        ? false\n        : containerManageFlag\n          ? customData['parentCidCode']\n            ? hideControl['parentCidCode']\n            : containerManageFlag\n          : false\n    ",
          },
        ],
        ref: "ref40",
        attrs: {
          params: "parentCidCode",
          display: "parentCidCode",
          "pre-parse-url": _vm.urlData["parentCidCode"],
          label: _vm.$t("label.parentTray"),
          custom: _vm.customData["parentCidCode"],
          "source-parse-field": _vm.sourceParseField,
        },
        on: {
          setSourceField: function ($event) {
            return _vm.saveSourceField($event)
          },
          valuedByFoucs: function ($event) {
            return _vm.valuedByFoucs($event)
          },
          blur: function ($event) {
            return _vm.handleBlur("parentCidCode")
          },
          enter: function ($event) {
            _vm.isPrePackage ? _vm.skipNext("submitRef") : _vm.skipNext(100)
          },
          down: function ($event) {
            _vm.isPrePackage ? _vm.skipNext("submitRef") : _vm.skipNext(100)
          },
          click: function ($event) {
            $event.stopPropagation()
            return _vm.handleCommonClick(5)
          },
        },
        model: {
          value: _vm.parentCidCode,
          callback: function ($$v) {
            _vm.parentCidCode = $$v
          },
          expression: "parentCidCode",
        },
      }),
      _c(
        "div",
        { staticStyle: { "margin-top": "16px" } },
        [
          _c("ips-lov", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isPrepackParentCid
                  ? false
                  : _vm.cidDrag === false
                  ? _vm.customData["sku"]
                    ? _vm.hideControl["sku"]
                    : _vm.cidDrag === false
                  : false,
                expression:
                  "isPrepackParentCid ? false : cidDrag === false ? (customData['sku'] ? hideControl['sku'] : cidDrag === false) : false",
              },
            ],
            ref: "ref50",
            attrs: {
              label: _vm.$t("label.sku"),
              disabled: _vm.isPackageSku,
              required: _vm.isPrepackParentCid ? false : true,
              title: [_vm.$t("label.sku"), _vm.$t("label.lineNum")],
              params: ["sku", "lineNum"],
              url: _vm.winvBaseUrl + "app-quick-inbound/query-inbound-info",
              "url-params": {
                billId: _vm.billId,
                warehouseId: _vm.warehouseId,
              },
              "pre-parse-url": _vm.urlData["sku"],
              display: "sku",
              custom: _vm.customData["sku"],
              "source-parse-field": _vm.sourceParseField,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(50)
              },
              clickIcon: function ($event) {
                return _vm.handleCommonClick(50, "lov")
              },
              enter: _vm.handleSkuEnter,
              down: _vm.handleSkuEnter,
              choose: function ($event) {
                return _vm.handleChoose("sku", $event)
              },
              setSourceField: function ($event) {
                return _vm.saveSourceField($event)
              },
              valuedByFoucs: function ($event) {
                return _vm.valuedByFoucs($event)
              },
            },
            model: {
              value: _vm.sku,
              callback: function ($$v) {
                _vm.sku = $$v
              },
              expression: "sku",
            },
          }),
        ],
        1
      ),
      _vm.sku
        ? _c(
            "div",
            [
              _c(
                "ips-page-card",
                {
                  attrs: {
                    "react-field": ["detailLineNum", "uomName"],
                    item: _vm.skuInfo,
                  },
                },
                [
                  _c("ips-message-tip", {
                    attrs: { message: _vm.skuInfo.goodsName },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("ips-field", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isBatch
              ? _vm.customData["batchCode"]
                ? _vm.hideControl["batchCode"]
                : _vm.isBatch
              : false,
            expression:
              "isBatch ? (customData['batchCode'] ? hideControl['batchCode'] : isBatch) : false",
          },
        ],
        ref: "ref60",
        attrs: {
          disabled: _vm.isPackage,
          required: "",
          label: _vm.$t("label.batch"),
          params: "batchCode",
          display: "batchCode",
          "pre-parse-url": _vm.urlData["batchCode"],
          custom: _vm.customData["batchCode"],
          "source-parse-field": _vm.sourceParseField,
          "enable-serial": _vm.serialType,
        },
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.handleCommonClick(60)
          },
          enter: _vm.handleBatchEnter,
          down: _vm.handleBatchEnter,
          setSourceField: function ($event) {
            return _vm.saveSourceField($event)
          },
          valuedByFoucs: function ($event) {
            return _vm.valuedByFoucs($event)
          },
        },
        model: {
          value: _vm.batchCode,
          callback: function ($$v) {
            _vm.batchCode = $$v
          },
          expression: "batchCode",
        },
      }),
      _c("hips-row", [
        _vm.serialType === "INV_CTRL" ||
        _vm.serialType === "IN_CTRL" ||
        _vm.serialType === "SEMI_INV_CTRL" ||
        _vm.isPrepackParentCid
          ? _c(
              "div",
              { staticStyle: { "margin-top": "16px" } },
              [
                _c(
                  "hips-col",
                  { attrs: { span: "14" } },
                  [
                    _c("ips-input-number", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.customData["receiveQty"]
                            ? _vm.hideControl["receiveQty"]
                            : true,
                          expression:
                            "customData['receiveQty'] ? hideControl['receiveQty'] : true",
                        },
                      ],
                      ref: "ref80",
                      attrs: {
                        disabled: _vm.isPackage || _vm.isPrePackage,
                        required: true,
                        label: _vm.$t("label.inventoryQty"),
                        custom: _vm.customData["receiveQty"],
                        "source-parse-field": _vm.sourceParseField,
                        "enable-serial": _vm.serialType,
                        min: null,
                        precision: 0,
                        "not-auto-fill": "",
                      },
                      on: {
                        enter: function ($event) {
                          return _vm.skipNext(90)
                        },
                        down: function ($event) {
                          return _vm.skipNext(90)
                        },
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.handleCommonClick(80)
                        },
                        setSourceField: function ($event) {
                          return _vm.saveSourceField($event)
                        },
                        valuedByFoucs: function ($event) {
                          return _vm.valuedByFoucs($event)
                        },
                      },
                      model: {
                        value: _vm.receiveQty,
                        callback: function ($$v) {
                          _vm.receiveQty = $$v
                        },
                        expression: "receiveQty",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "hips-col",
                  {
                    staticStyle: { "text-align": "right" },
                    attrs: { span: "10" },
                  },
                  [
                    _c(
                      "hips-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.customData["serialDetail"]
                              ? _vm.hideControl["serialDetail"]
                              : _vm.serialType === "INV_CTRL" ||
                                _vm.serialType === "IN_CTRL" ||
                                _vm.serialType === "SEMI_INV_CTRL",
                            expression:
                              "\n            customData['serialDetail']\n              ? hideControl['serialDetail']\n              : serialType === 'INV_CTRL' || serialType === 'IN_CTRL' || serialType === 'SEMI_INV_CTRL'\n          ",
                          },
                        ],
                        ref: "refSerail",
                        staticClass: "serial-button-detail",
                        attrs: { size: "large" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.goToSerialDetail.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t("button.seriesDetail") +
                                "(" +
                                _vm.serialReceiveQtyImp +
                                ")"
                            ) +
                            "\n        "
                        ),
                      ]
                    ),
                    _c(
                      "hips-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.customData["showPrePackCidDetail"]
                              ? _vm.hideControl["showPrePackCidDetail"]
                              : _vm.isPrepackParentCid,
                            expression:
                              "customData['showPrePackCidDetail'] ? hideControl['showPrePackCidDetail'] : isPrepackParentCid",
                          },
                        ],
                        ref: "refCid",
                        staticStyle: {
                          "margin-left": "10px",
                          "background-color": "var(--primaryColor)",
                          color: "#ffffff",
                          "font-size": "14px",
                          height: "36px",
                          "line-height": "36px",
                        },
                        attrs: { size: "large" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.goToPrePackCidDetail.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("button.prePackCidDetail")) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.serialType !== "INV_CTRL" &&
        _vm.serialType !== "IN_CTRL" &&
        _vm.serialType !== "SEMI_INV_CTRL" &&
        !_vm.isPrepackParentCid
          ? _c(
              "div",
              { staticStyle: { "margin-top": "16px" } },
              [
                _c(
                  "hips-col",
                  { attrs: { span: "24" } },
                  [
                    _c("ips-input-number", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isPrepackParentCid
                            ? false
                            : _vm.customData["receiveQty"]
                            ? _vm.hideControl["receiveQty"]
                            : true,
                          expression:
                            "isPrepackParentCid ? false : customData['receiveQty'] ? hideControl['receiveQty'] : true",
                        },
                      ],
                      ref: "ref80",
                      attrs: {
                        required: !_vm.enableSerial,
                        disabled:
                          _vm.enableSerial || _vm.isPackage || _vm.isPrePackage,
                        label: _vm.$t("label.inventoryQty"),
                        custom: _vm.customData["receiveQty"],
                        "source-parse-field": _vm.sourceParseField,
                        min: null,
                        "more-than-zero": true,
                        "not-auto-fill": "",
                      },
                      on: {
                        enter: function ($event) {
                          return _vm.skipNext(90)
                        },
                        down: function ($event) {
                          return _vm.skipNext(90)
                        },
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.handleCommonClick(80)
                        },
                        setSourceField: function ($event) {
                          return _vm.saveSourceField($event)
                        },
                        valuedByFoucs: function ($event) {
                          return _vm.valuedByFoucs($event)
                        },
                      },
                      model: {
                        value: _vm.receiveQty,
                        callback: function ($$v) {
                          _vm.receiveQty = $$v
                        },
                        expression: "receiveQty",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm.sku
        ? _c(
            "div",
            { staticClass: "count_block_box" },
            [
              _c("ips-number-count", {
                attrs: {
                  count: _vm.skuInfo.planQty,
                  required: !_vm.enableSerial,
                  disabled: _vm.enableSerial,
                  "text-field": "planQty",
                  "background-color": "#E6F7FF",
                  "border-color": "#91D5FF",
                  "font-color": "var(--primaryColor)",
                },
              }),
              _c("ips-number-count", {
                attrs: {
                  count: _vm.skuInfo.inQty,
                  "text-field": "inQty",
                  "background-color": "#F6FFED",
                  "border-color": "#B7EB8F",
                  "font-color": "#52C41A",
                },
              }),
              _c("ips-number-count", {
                attrs: {
                  count: _vm.skuInfo.pendingInQty,
                  "text-field": "pendingInQty",
                  "background-color": "#FFF4E6",
                  "border-color": "#FFD382",
                  "font-color": "#FF8F07",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("ips-lov", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.customData["locationCode"]
              ? _vm.hideControl["locationCode"]
              : true,
            expression:
              "customData['locationCode'] ? hideControl['locationCode'] : true",
          },
        ],
        ref: "ref90",
        attrs: {
          label: _vm.$t("label.storageLocation"),
          required: "",
          title: [
            _vm.$t("label.storageLocation"),
            _vm.$t("label.whareaCode"),
            _vm.$t("label.whareaName"),
          ],
          params: ["locationCode", "whareaCode", "whareaName"],
          display: "locationCode",
          url: _vm.wmdmBaseUrl + "wms-locations/app",
          "url-params": {
            warehouseId: _vm.warehouseId,
            locationType: "T_STOCK",
            whareaIdsString: _vm.whareaIdsString,
            whareaId: _vm.whareaIdsString ? _vm.whareaIdSku : null,
          },
          "pre-parse-url": _vm.urlData["locationCode"],
          custom: _vm.customData["locationCode"],
          "source-parse-field": _vm.sourceParseField,
        },
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.handleCommonClick(90)
          },
          clickIcon: function ($event) {
            return _vm.handleCommonClick(90, "lov")
          },
          enter: _vm.handleLocationEnter,
          down: _vm.handleLocationEnter,
          choose: function ($event) {
            return _vm.handleChoose("locationCode", $event)
          },
          setSourceField: function ($event) {
            return _vm.saveSourceField($event)
          },
          valuedByFoucs: function ($event) {
            return _vm.valuedByFoucs($event)
          },
        },
        model: {
          value: _vm.locationCode,
          callback: function ($$v) {
            _vm.locationCode = $$v
          },
          expression: "locationCode",
        },
      }),
      _c("hips-row", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.containerManageFlag === 1 && _vm.cidDrag === true
                    ? _vm.customData["batch"]
                      ? _vm.hideControl["batch"]
                      : _vm.containerManageFlag === 1 && _vm.cidDrag === true
                    : false,
                expression:
                  "\n        containerManageFlag === 1 && cidDrag === true\n          ? customData['batch']\n            ? hideControl['batch']\n            : containerManageFlag === 1 && cidDrag === true\n          : false\n      ",
              },
            ],
            staticStyle: { "margin-top": "16px" },
          },
          [
            _c(
              "hips-col",
              { attrs: { span: "16" } },
              [
                _c("ips-field", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.customData["cidCode"]
                        ? _vm.hideControl["cidCode"]
                        : true,
                      expression:
                        "customData['cidCode'] ? hideControl['cidCode'] : true",
                    },
                  ],
                  ref: "ref100",
                  attrs: {
                    label: _vm.$t("label.storageTray"),
                    params: "cidCode",
                    display: "cidCode",
                    "pre-parse-url": _vm.urlData["cidCode"],
                    custom: _vm.customData["cidCode"],
                    "source-parse-field": _vm.sourceParseField,
                  },
                  on: {
                    blur: _vm.handleCidBlur,
                    enter: function ($event) {
                      return _vm.skipNext("submitRef")
                    },
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleCommonClick(100)
                    },
                    setSourceField: function ($event) {
                      return _vm.saveSourceField($event)
                    },
                    valuedByFoucs: function ($event) {
                      return _vm.valuedByFoucs($event)
                    },
                  },
                  model: {
                    value: _vm.cidCode,
                    callback: function ($$v) {
                      _vm.cidCode = $$v
                    },
                    expression: "cidCode",
                  },
                }),
              ],
              1
            ),
            _c(
              "hips-col",
              { attrs: { span: "8" } },
              [
                _c(
                  "hips-button",
                  {
                    ref: "refSerail",
                    staticStyle: {
                      "margin-left": "16px",
                      "background-color": "var(--primaryColor)",
                      color: "#ffffff",
                      "font-size": "14px",
                      height: "36px",
                      "line-height": "36px",
                    },
                    attrs: { size: "large" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.goToCidDetail.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("button.cidDetail")) +
                        "\n        "
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isPrepackParentCid
                  ? false
                  : _vm.containerManageFlag === 1 &&
                    (_vm.cidCode === "" || _vm.cidDrag === false),
                expression:
                  "isPrepackParentCid ? false : containerManageFlag === 1 && (cidCode === '' || cidDrag === false)",
              },
            ],
            staticStyle: { "margin-top": "16px" },
          },
          [
            _c(
              "hips-col",
              { attrs: { span: "24" } },
              [
                _c("ips-field", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.containerManageFlag === 1 &&
                        (_vm.cidCode === "" || _vm.cidDrag === false)
                          ? _vm.customData["batch"]
                            ? _vm.hideControl["batch"]
                            : _vm.containerManageFlag === 1 &&
                              (_vm.cidCode === "" || _vm.cidDrag === false)
                          : false,
                      expression:
                        "\n            containerManageFlag === 1 && (cidCode === '' || cidDrag === false)\n              ? customData['batch']\n                ? hideControl['batch']\n                : containerManageFlag === 1 && (cidCode === '' || cidDrag === false)\n              : false\n          ",
                    },
                  ],
                  ref: "ref100",
                  attrs: {
                    label: _vm.$t("label.storageTray"),
                    required: _vm.parentCidCode ? true : !_vm.cidCodeRequired,
                    params: "cidCode",
                    display: "cidCode",
                    "pre-parse-url": _vm.urlData["cidCode"],
                    custom: _vm.customData["cidCode"],
                    "source-parse-field": _vm.sourceParseField,
                  },
                  on: {
                    blur: function ($event) {
                      return _vm.handleCidBlur($event)
                    },
                    enter: function ($event) {
                      return _vm.skipNext(30)
                    },
                    down: function ($event) {
                      return _vm.skipNext(30)
                    },
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleCommonClick(100)
                    },
                    setSourceField: function ($event) {
                      return _vm.saveSourceField($event)
                    },
                    valuedByFoucs: function ($event) {
                      return _vm.valuedByFoucs($event)
                    },
                  },
                  model: {
                    value: _vm.cidCode,
                    callback: function ($$v) {
                      _vm.cidCode = $$v
                    },
                    expression: "cidCode",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "ips-footer" },
        [
          _c(
            "hips-button",
            {
              ref: "submitRef",
              attrs: { size: "large" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleCommonSubmit.apply(null, arguments)
                },
              },
            },
            [
              _c("svg-icon", {
                attrs: { slot: "icon", "icon-class": "success" },
                slot: "icon",
              }),
              _vm._v(_vm._s(_vm.$t("button.confirm")) + "\n    "),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }