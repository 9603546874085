var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: { title: _vm.$t("title.acceptDetail"), "is-index": false },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _c("ips-page-head", {
          attrs: {
            "title-info": {
              billCode: _vm.billCode,
              billTypeName: _vm.billTypeName,
            },
            "content-info": _vm.billData,
          },
        }),
        _c(
          "hips-scroll",
          _vm._l(_vm.lineData, function (item, index) {
            return _c(
              "ips-page-card",
              {
                key: index,
                attrs: {
                  "title-info": { sku: item.sku },
                  "react-field": [
                    "lineNum",
                    "uomName",
                    "planQty",
                    "receiveQty",
                    "unReceiveQty",
                  ],
                  item: Object.assign({}, item, {
                    unReceiveQty: item.planQty - item.receiveQty,
                  }),
                },
              },
              [_c("ips-message-tip", { attrs: { message: item.goodsName } })],
              1
            )
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }