import { noAuthAxios, authedAxios } from './configs';
import { storage } from '@/utils';
import { isEmpty } from '@/utils/common';
import uuidv4 from 'uuid/v4'

// 需要授权信息的http请求
const http = {
  get(url, data) {
    // data && data.params && (data.params.pageCode = process.env.VUE_APP_CODE || storage.get('pageCode'));
    data && data.params && (data.params.pageId = isEmpty(storage.get('menuId')) ? null : storage.get('menuId'));
    return new Promise((resolve, reject) => {
      authedAxios
        .get(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  put(url, data) {
    // data && data.params && (data.params.pageCode = process.env.VUE_APP_CODE || storage.get('pageCode'));
    data && data.params && (data.params.pageId = isEmpty(storage.get('menuId')) ? null : storage.get('menuId'));
    return new Promise((resolve, reject) => {
      authedAxios
        .put(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  post(url, data) {
    // data.pageCode = process.env.VUE_APP_CODE || storage.get('pageCode');
    data.pageId = isEmpty(storage.get('menuId')) ? null : storage.get('menuId');
    return new Promise((resolve, reject) => {
      authedAxios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  postFormData(url, formData) {
    let config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };
    return authedAxios.post(url, formData, config);
  },
};

// 无需授权的http请求
const noAuthHttp = {
  get(url, data) {
    return new Promise((resolve, reject) => {
      noAuthAxios
        .get(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  post(url, data) {
    return new Promise((resolve, reject) => {
      noAuthAxios
        .post(url, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  login(url) {
    // TODO
    return new Promise((resolve) => {
      noAuthAxios
        .post(url, {})
        .then((res) => {
          console.log('res = ', res);
          storage.set('user-token', res.access_token);
          resolve(res);
        })
        .catch((err) => {
          // console.log('错误')
          console.log(err);
          // reject({
          //   msg: '网络链接失败，请稍后再试',
          // })
        });
    });
  },
};

export const getToken = (data) => {
  const { grantType, username, password, clientId, clientSecret } = data;
  const sourceType = 'app';
  let deviceId = storage.get('deviceId');
  if (!deviceId) {
    deviceId = uuidv4();
    storage.set('deviceId', deviceId);
  }

  return noAuthAxios({
    // url: `/oauth/oauth/token?grant_type=${grantType}&client_secret=${clientSecret}&client_id=${clientId}&username=${username}&password=${password}&source_type=${sourceType}&device_id=${deviceId}`,
    url: '/oauth/oauth/token',
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    transformRequest: [
      (oldData) => {
        let newStr = ''
        for (let item in oldData){
          newStr += encodeURIComponent(item) + '=' + encodeURIComponent(oldData[item]) + '&'
        }
        newStr = newStr.slice(0, -1)
        return newStr
      }
    ],
    data: {
      'grant_type': grantType,
      'client_secret': clientSecret,
      'client_id': clientId,
      'source_type': sourceType,
      'device_id': deviceId,
      username,
      password,
    },
  })
};

export const appLogout = (data) => {
  const { accessToken } = data;
  return new Promise((resolve, reject) => {
    noAuthAxios
      .get(`/oauth/logout?access_token=${accessToken}`)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export { noAuthHttp };

export default http;
