<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="cidTransferTitle" :is-index="false" @beforeBack="handleGoBack" />
      <!--<ips-header :title="$t('title.subinventoryTransfer')" :is-index="false" @beforeBack="handleGoBack" />-->
    </div>
    <div class="header-row">
      <div class="header-title">
        {{ cidData.cidCode }}
      </div>
      <div :class="{'header-0-status':cidData.cidStatus==='EMPTY', 'header-1-status':cidData.cidStatus==='RECEIVING', 'header-2-status':cidData.cidStatus==='IN_STOCK', 'header-3-status':cidData.cidStatus==='PICKED', 'header-4-status':cidData.cidStatus==='LOADED'}">
        {{ cidData.cidStatusName }}
      </div>
    </div>
    <div class="ips-container">
      <div class="card_batch_subtrans">
        <div class="row">
          <div class="title">
            {{ $t('label.transferFrom') }}
          </div>
          <svg-icon slot="icon" icon-class="arrow" />
        </div>
        <!-- 起始托盘 -->
        <ips-lov
          :label="$t('label.startOfTray')"
          v-model="fromCidCodeByCid"
          v-show="customData['fromCidCodeByCid'] ? hideControl['fromCidCodeByCid']: true"
          disabled
          :title="$t('label.startOfTray')"
          params="cidCode"
          :url="`${winvBaseUrl}new-stock-trans/query-container-from`"
          :pre-parse-url="urlData['fromCidCodeByCid']"
          :custom="customData['fromCidCodeByCid']"
          :source-parse-field="sourceParseField"
          ref="ref0"
          @click.stop="handleCommonClick(0)"
          @clickIcon="handleCommonClick(0, 'lov')"
          @choose="handleChoose('fromCidCodeByCid', $event)"
          @setSourceField="saveSourceField($event)"
          @valuedByFoucs="valuedByFoucs($event)"
        />
        <!-- 起始父托盘 -->
        <ips-field
          :label="$t('label.startOfParentTray')"
          :title="$t('label.startOfParentTray')"
          v-show="parentCidEnabled.paramValue==1? fromParentCidCodeByCid:false"
          v-model="fromParentCidCodeByCid"
          disabled
          params="fromParentCidCodeByCid"
          :url="`${winvBaseUrl}new-stock-trans/query-container-from`"
          :custom="customData['fromParentCidCodeByCid']"
          :source-parse-field="sourceParseField"
        />
        <!-- 起始库位 -->
        <ips-lov
          :label="$t('label.startOfLocation')"
          :title="$t('label.startOfLocation')"
          v-model="fromLocationCodeByCid"
          v-show="customData['fromLocationCodeByCid'] ? hideControl['fromLocationCodeByCid']: true"
          disabled
          required
          params="locationCode"
          :url="`${winvBaseUrl}new-stock-trans/query-location-from`"
          :pre-parse-url="urlData['fromLocationCodeByCid']"
          :custom="customData['fromLocationCodeByCid']"
          :source-parse-field="sourceParseField"
          ref="ref10"
          @click.stop="handleCommonClick(10)"
          @clickIcon="handleCommonClick(10, 'lov')"
          @choose="handleChoose('fromLocationCodeByCid', $event)"
          @setSourceField="saveSourceField($event)"
          @valuedByFoucs="valuedByFoucs($event)"
        />
      </div>
      <div class="card_countLocation">
        <div class="row">
          <svg-icon slot="icon" icon-class="arrow" />
          <div class="title">
            {{ $t('label.transferTo') }}
          </div>
        </div>
        <!-- 目标托盘 -->
        <ips-lov
          :label="$t('label.targetTray')"
          :title="$t('label.targetTray')"
          v-show="customData['toCidCodeByCid'] ? hideControl['toCidCodeByCid']: true"
          v-model="toCidCodeByCid"
          params="cidCode"
          :disabled="transferType=== 'UNPACK'"
          :url="`${winvBaseUrl}new-stock-trans/query-container-to`"
          :url-params="toCidParam"
          :pre-parse-url="urlData['toCidCodeByCid']"
          :custom="customData['toCidCodeByCid']"
          :source-parse-field="sourceParseField"
          ref="ref20"
          @click.stop="handleCommonClick(20)"
          @clickIcon="handleCommonClick(20, 'lov')"
          @enter="handleToCidEnter"
          @down="handleToCidEnter"
          @tab="handleToCidTab"
          @choose="handleChoose('toCidCodeByCid', $event)"
          @setSourceField="saveSourceField($event)"
          @valuedByFoucs="valuedByFoucs($event)"
        />
        <!-- 目标父托盘 -->
        <ips-lov
          :label="$t('label.targetParentTray')"
          :title="$t('label.targetParentTray')"
          v-show="parentCidEnabled.paramValue==1?true:false"
          v-model="toParentCidCodeByCid"
          params="cidCode"
          :disabled="isHasParentCid"
          :url="`${winvBaseUrl}new-stock-trans/query-trans-to-parent-container`"
          :url-params="toCidParam"
          :pre-parse-url="urlData['toParentCidCodeByCid']"
          :custom="customData['toParentCidCodeByCid']"
          :source-parse-field="sourceParseField"
          ref="ref25"
          @click.stop="handleCommonClick(25)"
          @clickIcon="handleCommonClick(25, 'lov')"
          @enter="handleToParentCidEnter"
          @down="handleToParentCidEnter"
          @tab="handleToParentCidTab"
          @choose="handleChoose('toParentCidCodeByCid', $event)"
          @setSourceField="saveSourceField($event)"
          @valuedByFoucs="valuedByFoucs($event)"
        />
        <!-- 目标库位 -->
        <ips-lov
          :label="$t('label.targetLocation')"
          :title="$t('label.targetLocation')"
          v-model="toLocationCodeByCid"
          v-show="customData['toLocationCodeByCid'] ? hideControl['toLocationCodeByCid']: true"
          required
          :disabled="isToCidCode"
          params="locationCode"
          :url="`${winvBaseUrl}new-stock-trans/query-location-to`"
          :url-params="toLocationParam"
          :pre-parse-url="urlData['toLocationCodeByCid']"
          :custom="customData['toLocationCodeByCid']"
          :source-parse-field="sourceParseField"
          ref="ref30"
          @click.stop="handleCommonClick(30)"
          @clickIcon="handleCommonClick(30, 'lov')"
          @enter="skipNext('submitRef')"
          @down="skipNext('submitRef')"
          @tab="skipNext('submitRef')"
          @choose="handleChoose('toLocationCodeByCid', $event)"
          @setSourceField="saveSourceField($event)"
          @valuedByFoucs="valuedByFoucs($event)"
        />
      </div>
      <div class="card_num_subtrans">
        <!-- 转移数量 -->
        <hips-row>
          <div class="hips-row">
            <hips-col span="16">
              <ips-input-number
                v-model="transferQtyByCid"
                v-show="customData['transferQtyByCid'] ? hideControl['transferQtyByCid']: true"
                disabled
                required
                not-auto-fill
                :min="null"
                :more-than-zero="true"
                :label="$t('label.transferNumber')"
                :custom="customData['transferQtyByCid']"
                :source-parse-field="sourceParseField"
                ref="ref40"
                @enter="skipNext('submitRef')"
                @down="skipNext('submitRef')"
                @tab="skipNext('submitRef')"
                @click.stop="handleCommonClick(40)"
                @setSourceField="saveSourceField($event)"
                @valuedByFoucs="valuedByFoucs($event)"
              />
            </hips-col>
            <hips-col span="8">
              <hips-button size="large"
                           @click.stop="goToCidTransferDetail"
                           ref="refSerail"
                           class="button-detail"
              >
                {{ $t('button.selectTrayDetail') }}
              </hips-button>
            </hips-col>
          </div>
        </hips-row>
        <!--现有量、可用量-->
        <hips-row>
          <div class="row-num">
            <hips-col span="12" style="display: flex;align-items: center;height: 100%;">
              <div class="title" style="display: inline-block">
                {{ $t('label.availableQty') }}:
              </div>
              <div class="value" style="display: inline-block;">
                {{ qty }}
              </div>
            </hips-col>
            <hips-col span="12" style="display: flex;align-items: center;height: 100%;">
              <div class="title" style="display: inline-block">
                {{ $t('label.currentUseQty') }}:
              </div>
              <div class="value" style="display: inline-block;">
                {{ validQty }}
              </div>
            </hips-col>
          </div>

        </hips-row>
      </div>
    </div>
    <div class="ips-footer">
      <hips-button
        size="large"
        @click.stop="handleCommonSubmit"
        @enter="handleCommonSubmit"
        ref="submitRef"
        :class="['ips-btn-normal']"
      >
        <svg-icon slot="icon" icon-class="success" />
        {{ $t('button.confirm') }}
      </hips-button>
    </div>
  </hips-view>
</template>

<script>
  import { getCidData, getCidDetail, cidStockTrans, toParentCidCheck, getparentCidEnabled } from '../api';
  import { Row, Col } from '@hips/vue-ui';
  import { storage } from '@/utils';
  export default {
    name: 'CidTransfer',
    components:{
      [Row.name]: Row,
      [Col.name]: Col,
    },
    data() {
      return {
        cidTransferTitle: '', // 标题
        cidData: '', // params参数
        transferType: '', // 功能参数
        cidDetailData: '', //托盘下
        fromCidIdByCid: '', // 起始托盘ID
        fromCidCodeByCid: '', // 起始托盘
        fromParentCidIdByCid: '', // 起始托盘ID
        fromParentCidCodeByCid: '', // 起始托盘
        fromLocationIdByCid: '', // 起始库位
        fromLocationCodeByCid: '', // 起始库位
        toCidIdByCid: '', // 目标托盘ID
        toCidCodeByCid: '', // 目标托盘
        toParentCidIdByCid: '', // 目标托盘ID
        toParentCidCodeByCid: '', // 目标托盘
        toLocationIdByCid: '', // 目标库位
        toLocationCodeByCid: '', // 目标库位
        warehouseId: '', // 仓库id
        whareaId: '', // 库区id
        transferQtyByCid: '', // 转移数量
        qty: '',
        validQty: '',
        toCidParam: {}, // 目标托盘参数
        toLocationParam: {},
        urlData: {},
        customData: {}, // 个性化配置信息列表
        hideControl: {}, // 个性化配置显示隐藏控制
        sourceParseField: {}, // 界面中所有的来源解析字段
        isHasParentCid: true, //目标父托盘字段是否可以编辑
        isToCidCode: false, //目标库位是否可以编辑
        parentCidEnabled:{}, //页面参数是否显示父托盘字段
        LocationIsParentLocation: false,//目标库位是父托盘带出数据

        crossWhareaId: undefined //允许跨库区库存转移 1：允许 0： 不允许
      };
    },
    methods: {
      /**
       * 获取params传参
       */
      async getParamsData(){
        const { item, transferType, sourceParseField, customData, hideControl } = this.$route.params;
        this.cidData = item;
        this.transferType = transferType;
        this.sourceParseField = sourceParseField;
        this.customData = customData;
        this.hideControl = hideControl;
        this.whareaId = item?.whareaId
        if(this.transferType === 'FULL_PALLET'){
          this.cidTransferTitle = this.$t('title.inventoryTransfer');
        }else if(this.transferType === 'UNPACK'){
          this.cidTransferTitle = this.$t('title.cancelPallet');
        }else {
          this.cidTransferTitle = this.$t('title.subinventoryTransfer');
        }
        try {
          this.$hint.showLoading();
          const data = {
            params: {
              cidId: this.cidData.id,
              cidCode: this.cidData.cidCode,
            }
          };
          const res = await getCidData(data);
          this.fromCidIdByCid = res[0].cidId;
          this.fromCidCodeByCid = res[0].cidCode;
          this.fromParentCidIdByCid = res[0].parentCidId;
          this.fromParentCidCodeByCid = res[0].parentCidCode;
          this.fromLocationIdByCid = res[0].locationId;
          this.fromLocationCodeByCid = res[0].locationCode;
          this.qty = res[0].qty;
          this.validQty = res[0].validQty;
          this.transferQtyByCid = res[0].validQty;
          this.warehouseId = res[0].warehouseId;
          
          this.crossWhareaId = res[0]?.crossWhareaTransferFlag === 0 ? this.whareaId : undefined;

          this.toCidParam = {
            warehouseId: this.warehouseId,
            whareaId: this.crossWhareaId,
          };
          this.toLocationParam = {
            warehouseId: this.warehouseId,
            whareaId: this.crossWhareaId,
          };
          if(this.transferType === 'FULL_PALLET'){
            this.toCidIdByCid = this.fromCidIdByCid;
            this.toCidCodeByCid = this.fromCidCodeByCid;
            this.skipNext(30);
          }
          if(this.transferType === 'UNPACK'){
            this.toLocationIdByCid = this.fromLocationIdByCid;
            this.toLocationCodeByCid = this.fromLocationCodeByCid;
            this.skipNext(20);
          }
          await this.getCidDetailData();
        }finally {
          this.$hint.hideLoading();
        }
      },
      /**
       * 目标托盘enter事件
       */
      handleToCidEnter(){
        if(this.isHasParentCid){
          if(this.parentCidEnabled.paramValue!=='1'){
            this.skipNext(30);
          }else{
            this.skipNext(30);
          }
        }else{
          if(this.parentCidEnabled.paramValue!=='1'){
            this.skipNext(30);
          }else{
            this.skipNext(25);
          }
        }
      },
      handleToCidTab(){
        if(this.isHasParentCid){
          if(this.parentCidEnabled.paramValue!=='1'){
            this.skipNext(40);
          }else{
            this.skipNext(30);
          }
        }else{
          if(this.parentCidEnabled.paramValue!=='1'){
            this.skipNext(30);
          }else{
            this.skipNext(25);
          }
        }
      },
      /**
       * 目标父托盘enter事件
       */
      handleToParentCidEnter(){
        if (this.toLocationIdByCid) {
          this.skipNext('submitRef');
        } else {
          this.skipNext(30);
        }
      },
      handleToParentCidTab(){
        if (this.toLocationIdByCid) {
          this.skipNext('submitRef');
        } else {
          this.skipNext(30);
        }
      },
      /**
       *@functionName: saveSourceField
       *@description: 保存来源解析字段并赋值Enter
       *@params1: fieldObj[Object] 来源解析字段对象,
       fieldCode[String] 来源解析字段
       parseValue[String] 二维码解析值
       subFields[Array]] 关联子字段
       */
      saveSourceField(fieldObj) {
        this.saveSourceParseField(this, fieldObj);
      },
      /**
       * 字段聚焦时赋值操作
       */
      async valuedByFoucs(options={}) {
        const { custom ={} , currentValue } = options;
        await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
      },
      async getCidDetailData(){
        try {
          this.$hint.showLoading();
          const data = {
            params: {
              cidId: this.cidData.id,
              cidCode: this.cidData.cidCode,
            }
          };
          const res = await getCidDetail(data);
          this.cidDetailData = res;
          if(res){
            this.$hint.hideLoading();
          }
        }finally {
          // this.$hint.hideLoading();
        }
      },
      async parentCidEnabledShow(){
        try {
          this.$hint.showLoading();
          const data = {
            params: {
              pageId: storage.get('menuId'),
              device: "PDA",
              functionCode:"PDA_INV_STOCK_TRANSFER",
              paramCode:"PARENT_CID_ENABLE",
            }
          };
          const res = await getparentCidEnabled(data);
          this.parentCidEnabled = res;
        }finally {
          this.$hint.hideLoading();
        }
      },
      /**
       * lov选中事件
       * @param {String} type - 定位lov
       * @param {object} obj - 选中行的数据
       */
      handleChoose(type, obj) {
        switch (type) {
          case 'toCidCodeByCid':
            this.toCidIdByCid = obj.id;
            this.toCidCodeByCid = obj.cidCode;
            this.isToCidCode = false;
            if(obj.cidStatus === 'IN_STOCK' && this.fromCidCodeByCid !== obj.cidCode){
              this.toLocationParam = {
                cidId: obj.id,
                cidStatus: obj.cidStatus,
                warehouseId: this.warehouseId,
                whareaId: this.crossWhareaId
              };
            }else {
              this.toLocationParam = {
                cidStatus: obj.cidStatus,
                warehouseId: this.warehouseId,
                whareaId: this.crossWhareaId
              };
            }
            if(obj.locationId && this.fromCidCodeByCid !== obj.cidCode){
              this.toLocationIdByCid = obj.locationId;
              this.toLocationCodeByCid = obj.locationCode;
            }
            this.toParentCidIdByCid = obj.parentCidId;
            this.toParentCidCodeByCid = obj.parentCidCode;
            if (this.toCidIdByCid !== undefined) {
              this.isHasParentCid = false;
            }
            break;
          case 'toParentCidCodeByCid':
            this.toParentCidIdByCid = obj.cidId;
            this.toParentCidCodeByCid = obj.cidCode;
            if(!this.toLocationCodeByCid&&obj.locationId && this.toCidCodeByCid !== ''&& this.fromParentCidCodeByCid !== obj.cidCode){
              this.toLocationIdByCid = obj.locationId;
              this.toLocationCodeByCid = obj.locationCode;
              this.LocationIsParentLocation = true;
            }
            this.chekckToParentCidCode();
            break;
          case 'toLocationCodeByCid':
            this.toLocationIdByCid = obj.locationId;
            this.toLocationCodeByCid = obj.locationCode;
            break;
          default:
            return null;
        }
      },
      /**
       * 校验目标父托盘字段
       */
      async chekckToParentCidCode() {
        try {
          const data = {
            locationId: this.toLocationIdByCid,
            locationCode: this.toLocationCodeByCid,
            cidId: this.toCidIdByCid,
            cidCode: this.toCidCodeByCid,
            parentCidId: this.toParentCidIdByCid,
            parentCidCode: this.toParentCidCodeByCid,
          };
          const res = await toParentCidCheck(data);
          if (res && res.failed) {
            this.skipNext(25);
          } else {
            if (res.locationCode && !(this.fromParentCidCodeByCid === res.parentCidCode&&this.fromCidCodeByCid === res.cidCode)) {
              this.toLocationIdByCid = res.locationId;
              this.toLocationCodeByCid = res.locationCode;
            }
          }
        } catch (e) {
          this.skipNext(25);
        }
      },
      monitorCid(){
        if(!this.fromCidCodeByCid){
          this.fromParentCidCodeByCid = ''
        }
        if(!this.toCidCodeByCid){
          this.toParentCidCodeByCid = '';
          this.toLocationCodeByCid = '';
        }
        if(!this.toParentCidCodeByCid && this.LocationIsParentLocation){
          this.toLocationCodeByCid = '';
        }
      },
      /**
       * go托盘转移详情
       * */
      goToCidTransferDetail(){
        const param = {
          name: 'CidTransferDetail',
          params: {
            item: this.cidData,
            cidDetailData: this.cidDetailData,
            transferType: this.transferType,
          },
        };
        this.$router.pushPage(param);
      },
      /**
       * 子库存转移
       */
      async handleButtonSubmit() {
        if(this.transferType === 'FULL_PALLET' && this.qty !== this.validQty){
          this.$hint.showFailed(this.$i18n.t('message.checkTrayNotAll'), true);
          return;
        }
        try {
          this.$hint.showLoading();
          this.handleSubmitStatus();
          let param = {
            fromCidId: this.fromCidIdByCid,
            fromCidCode: this.fromCidCodeByCid,
            fromLocationId:  this.fromLocationIdByCid,
            fromLocationCode: this.fromLocationCodeByCid,
            batchId: this.batchId,
            batchCode: this.batchCode,
            toLocationId: this.toLocationIdByCid,
            toLocationCode: this.toLocationCodeByCid,
            toCidId: this.toCidIdByCid,
            toCidCode: this.toCidCodeByCid,
            toParentCidId: this.toParentCidIdByCid,
            toParentCidCode: this.toParentCidCodeByCid,
            stockProcessDtos: this.cidDetailData,
            transferQty: this.transferQtyByCid,
            qty: this.transferQtyByCid,
          };
          await cidStockTrans(param);
          this.dataInit();
          await this.clearSourceFields(this, true);
          this.$hint.showSuccess();
        }finally {
          this.$hint.hideLoading();
          this.handleSubmitStatus();
        }
      },
      dataInit(){
        const param = {
          name: 'SubinventoryQuery',
        };
        this.$router.pushPage(param);
      },
      handleGoBack(){
        //const param = {
        //  name: 'SubinventoryQuery',
        //};
        //this.$router.pushPage(param);
        //return ({isToCidQuery: true})
        this.$router.backPage();
      },

    },
    async mounted() {
      await this.getParamsData();
      this.urlData = this.handleData('STOCK_PALLET_CONFIRM');
      this.inputFieldList = [
        { type: 'lov', required: false, ref: 0, bind: 'fromCidCodeByCid', field: 'fromCidCodeByCid' },
        { type: 'lov', required: true, ref: 10, bind: 'fromLocationCodeByCid', field: 'fromLocationCodeByCid' },
        { type: 'lov', required: false, ref: 20, bind: 'toCidCodeByCid', field: 'toCidCodeByCid' },
        { type: 'lov', required: false, ref: 25, bind: 'toParentCidCodeByCid', field: 'toParentCidCodeByCid' },
        { type: 'lov', required: true, ref: 30, bind: 'toLocationCodeByCid', field: 'toLocationCodeByCid' },
        { type: 'number', required: true, ref: 40, bind: 'transferQtyByCid', field: 'transferQtyByCid' },
      ];
      this.parentCidEnabledShow();
      this.skipNext(20);
    },
    activated() {
      if(this.$route.params.route === 'CidTransferDetail'){
        this.cidDetailData = this.$route.params.cidDetailData;
        this.transferQtyByCid = this.$route.params.transferNumber;
      }
    },
    beforeRouteLeave(to, from, next) {
      if (to.name === 'CidTransferDetail') {
        this.$store.commit('pushKeepAlives', 'CidTransfer');
      } else {
        this.$store.commit('deleteKeepAlives', 'CidTransfer');
      }
      setTimeout(() => {
        next();
      })
    },
    watch:{
      'toCidCodeByCid': function(val, oldval) {
        if(oldval !== '' && val === ''){
          this.toCidIdByCid = '';
          this.isHasParentCid = true;
          this.toLocationParam = {
            warehouseId: this.warehouseId,
            whareaId: this.crossWhareaId
          };
          this.toParentCidCodeByCid = null;
          this.toLocationCodeByCid = null;
        }
        if(!this.toCidCodeByCid){
          this.toParentCidCodeByCid = null;
          this.toLocationCodeByCid = null;
          this.toLocationIdByCid = null;
          this.isToCidCode = false;
        }
      },
      'fromCidCodeByCid': function(val, oldval) {
        if(oldval !== '' && val === ''){
          this.fromParentCidCodeByCid = null;
          this.fromParentCidIdByCid = null;
        }
        if(oldval !== '' && val === ''){
          this.toParentCidIdByCid = null;
        }
      },
      'toParentCidCodeByCid': function(val, oldval) {
        if(oldval !== '' && val === ''&& this.LocationIsParentLocation){
          this.toLocationCodeByCid = null;
          this.toLocationIdByCid = null;
        }
        if(oldval !== '' && val === ''){
          this.toParentCidIdByCid = null;
        }
      }
    }
  };
</script>

<style lang="stylus" scoped>
 @import '~@/style/var.styl'
  .header-row{
    padding-top 5px
    display flex;
    margin-bottom 12px;
    .header-title{
      display flex;
      align-items: center;
      height:28px;
      background:#E6F7FF;
      color var(--primaryColor);
      border-radius:0px 32px 32px 0px;
      padding 0 12px 0 8px;
      border-left:4px solid var(--primaryColor);
    }
    .header-0-status{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left 12px;
      padding 0 12px;
      height:28px;
      font-size 14px;
      background:#D9D9D9;
      color #8C8C8C;
      border-radius:12px;
    }
    .header-1-status{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left 12px;
      padding 0 12px;
      height:28px;
      font-size 14px;
      background:#FFE4AB;
      color #FF8F07;
      border-radius:12px;
    }
    .header-2-status{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left 12px;
      padding 0 12px;
      height:28px;
      font-size 14px;
      background:#E6F7FF;
      color var(--primaryColor);
      border-radius:12px;
    }
    .header-3-status{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left 12px;
      padding 0 12px;
      height:28px;
      font-size 14px;
      background:#D6FFD7;
      color #19A633;
      border-radius:12px;
    }
    .header-4-status{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left 12px;
      padding 0 12px;
      height:28px;
      font-size 14px;
      background:#EFDBFF;
      color #722ED1;
      border-radius:12px;
    }
  }
  .ips-container{
    padding-top 0!important;
    .menu-row{
      display flex
      margin 12px 32px 12px 12px;
      .menu-title{
        width:40px;
        height:16px;
        font-size:12px;
        /*font-family:PingFangSC-Regular,PingFang SC;*/
        font-weight:200;
        color:#8C8C8C;
        line-height:16px;
      }
      .menu-value{
        width:261px;
        height:16px;
        font-size:12px;
        /*font-family:PingFangSC-Regular,PingFang SC;*/
        font-weight:initial;
        color:#000000;
        line-height:16px;
        overflow: hidden;      /*溢出隐藏*/
        white-space: nowrap;	/*规定文本不进行换行*/
        text-overflow: ellipsis;	/*当对象内文本溢出时显示省略标记（...）*/
      }
    }
    .card_batch_subtrans{
      margin 10px 0;
      padding  12px 12px
      height:flex;
      border-radius: 1.6vw;
      color: #000;
      box-shadow: 0 0 2.133vw #d9d9d9;
      border: none;
      font-size: 14px;
      background-color #FFFFFF;
      .row{
        display flex
        padding 0 0 0 0
        .title{
          height:16px;
          font-size:14px;
          font-family:PingFangSC-Regular,PingFang SC;
          font-weight:200;
          color:var(--primaryColor);
          line-height:18px;
        }
      }
    }
    .card_countLocation{
      margin 10px 0;
      padding  12px 12px
      height:flex;
      border-radius: 1.6vw;
      color: #000;
      box-shadow: 0 0 2.133vw #d9d9d9;
      border: none;
      font-size: 14px;
      background-color #FFFFFF;
      .row{
        display flex
        padding 0 0 0 0
        .title{
          height:16px;
          font-size:14px;
          font-family:PingFangSC-Regular,PingFang SC;
          font-weight:200;
          color:var(--primaryColor);
          line-height:18px;
        }
      }
    }
    .card_num_subtrans{
      margin 10px 0;
      padding  0 12px
      height:100px;
      border-radius: 1.6vw;
      color: #000;
      box-shadow: 0 0 2.133vw #d9d9d9;
      border: none;
      font-size: 14px;
      background-color #FFFFFF;
      .row{
        display flex
        /*padding 0 0 7px 0*/
        .title{
          height:16px;
          font-size:14px;
          font-family:PingFangSC-Regular,PingFang SC;
          font-weight:200;
          color:var(--primaryColor);
          line-height:18px;
        }
      }
        .hips-row{
            display flex
            /*padding-left: 5px;*/
            padding-top: 7px;
            .button-detail{
                margin-left: 5px;
                margin-right: 5px;
                padding 0 5px;
                background-color: var(--primaryColor);
                color: #FFFFFF;
                border: none;
                font-size: 14px;
                height: 36px;
                line-height: 36px;
            }
        }
      .row-num{
        display inline-block;
        align-items center;
        margin-top: 3px;
        height:25px;
        background:#E6F7FF;
        border-radius:6px;
        width 100%;
        border:2px solid #91D5FF;
        .row-50{
          width 50%
          /*display flex*/
          .title{
            width:56px;
            height:16px;
            padding-left: 5px;
            font-size:14px;
            font-family:PingFangSC-Regular,PingFang SC;
            font-weight:200;
            color:#595959;
            line-height:18px;
          }
          .value{
            width:110px;
            height:18px;
            font-size:14px;
            font-family:PingFangSC-Medium,PingFang SC;
            font-weight:initial;
            color:#000000;
            line-height:18px;
            overflow: hidden;      /*溢出隐藏*/
            white-space: nowrap;	/*规定文本不进行换行*/
            text-overflow: ellipsis;	/*当对象内文本溢出时显示省略标记（...）*/
          }
          .label{
            width:40px;
            height:18px;
            font-size:14px;
            font-family:PingFangSC-Medium,PingFang SC;
            font-weight:initial;
            color:#000000;
            line-height:18px;
          }
          .label-number{
            width:40px;
            height:18px;
            font-size:14px;
            font-family:PingFangSC-Medium,PingFang SC;
            font-weight:initial;
            color:#9254DE;
            line-height:18px;
          }
        }
        .title{
          width:100px;
          height:16px;
          padding-left: 5px;
          font-size:14px;
          font-family:PingFangSC-Regular,PingFang SC;
          font-weight:200;
          color:#595959;
          line-height:18px;
        }
        .value{
          height:18px;
          font-size:14px;
          font-family:PingFangSC-Medium,PingFang SC;
          font-weight:initial;
          color:#000000;
          line-height:18px;
          overflow: hidden;      /*溢出隐藏*/
          white-space: nowrap;	/*规定文本不进行换行*/
          text-overflow: ellipsis;	/*当对象内文本溢出时显示省略标记（...）*/
        }
      }
      .theDetail{
        color: var(--primaryColor);
        border: none;
        font-size: 14px;
        height: 18px;
        line-height: 18px;
      }
    }
  }
  .ips-btn-normal {
    border-radius: 8px !important;
    margin: 12px 10px 12px 10px;
    background-color var(--primaryColor) !important;
    color #ffffff !important
    font-size 17px !important
  }
</style>
