var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "hips-view",
    { attrs: { "header-fixed": true, "header-height": "48" } },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [_c("ips-header", { attrs: { title: "整托拣货" } })],
        1
      ),
      _c(
        "div",
        { staticClass: "ips-container" },
        [
          _c("ips-field", {
            ref: "ref0",
            attrs: { label: "测试", autofocus: "" },
            on: {
              enter: function ($event) {
                return _vm.skipNext(10)
              },
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(0)
              },
            },
            model: {
              value: _vm.formData.billCode,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "billCode", $$v)
              },
              expression: "formData.billCode",
            },
          }),
          _c("ips-field", {
            ref: "ref10",
            attrs: { label: "测111试" },
            on: {
              enter: function ($event) {
                return _vm.skipNext(20)
              },
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(10)
              },
            },
            model: {
              value: _vm.formData.cidCode,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "cidCode", $$v)
              },
              expression: "formData.cidCode",
            },
          }),
          _c("ips-value-cell", {
            attrs: {
              title: _vm.$t("label.billType"),
              "image-flag": "",
              value: _vm.formData.billTypeName,
            },
          }),
          _c("ips-value-cell", {
            attrs: {
              title: _vm.$t("label.vendor"),
              "image-flag": "",
              value: _vm.formData.vendorName,
            },
          }),
          _c("ips-value-cell", {
            attrs: {
              title: _vm.$t("label.customer"),
              "image-flag": "",
              value: _vm.formData.customerCode,
            },
          }),
          _c("ips-field", {
            ref: "ref20",
            attrs: { label: "测222试" },
            on: {
              enter: function ($event) {
                return _vm.skipNext(30)
              },
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(20)
              },
            },
            model: {
              value: _vm.formData.aaa,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "aaa", $$v)
              },
              expression: "formData.aaa",
            },
          }),
          _c("ips-field", {
            ref: "ref30",
            attrs: { label: "测333试" },
            on: {
              enter: function ($event) {
                return _vm.skipNext(40)
              },
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(30)
              },
            },
            model: {
              value: _vm.formData.bbb,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "bbb", $$v)
              },
              expression: "formData.bbb",
            },
          }),
          _c("ips-field", {
            ref: "ref40",
            attrs: { label: "测444试" },
            on: {
              enter: function ($event) {
                return _vm.skipNext(50)
              },
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(40)
              },
            },
            model: {
              value: _vm.formData.ccc,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "ccc", $$v)
              },
              expression: "formData.ccc",
            },
          }),
          _c("ips-field", {
            ref: "ref50",
            attrs: { label: "测555试" },
            on: {
              enter: function ($event) {
                return _vm.skipNext(60)
              },
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(50)
              },
            },
            model: {
              value: _vm.formData.ddd,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "ddd", $$v)
              },
              expression: "formData.ddd",
            },
          }),
          _c("ips-field", {
            ref: "ref60",
            attrs: { label: "测666试" },
            on: {
              enter: function ($event) {
                return _vm.skipNext(70)
              },
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(60)
              },
            },
            model: {
              value: _vm.formData.eee,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "eee", $$v)
              },
              expression: "formData.eee",
            },
          }),
          _c("ips-field", {
            ref: "ref70",
            attrs: { label: "测777试" },
            on: {
              enter: function ($event) {
                return _vm.skipNext(80)
              },
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(70)
              },
            },
            model: {
              value: _vm.formData.ggg,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "ggg", $$v)
              },
              expression: "formData.ggg",
            },
          }),
          _c("ips-field", {
            ref: "ref80",
            attrs: { label: "测888试" },
            on: {
              enter: function ($event) {
                return _vm.skipNext("submitRef")
              },
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(80)
              },
            },
            model: {
              value: _vm.formData.ttt,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "ttt", $$v)
              },
              expression: "formData.ttt",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "ips-footer" },
        [
          _c(
            "hips-button",
            {
              ref: "submitRef",
              attrs: { size: "large" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleCommonSubmit.apply(null, arguments)
                },
              },
            },
            [
              _c("svg-icon", {
                attrs: { slot: "icon", "icon-class": "success" },
                slot: "icon",
              }),
              _vm._v("\n      提交" + _vm._s(_vm.error) + "\n    "),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }