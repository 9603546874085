import FullPalletTransfer from '../views/full-pallet-transfer'
import FullPalletTransferDetail from  '../views/full-pallet-transfer-detail'


let route = [
  {
    path: '/full-pallet-transfer',
    name: 'FullPalletTransfer',
    component: FullPalletTransfer,
  },
  {
    path:'/full-pallet-transfer-detail',
    name:'FullPalletTransferDetail',
    component: FullPalletTransferDetail,
  },
]

export default route