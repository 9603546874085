import request from '@/request';
import mixin  from '@/mixin/mixin';  // 引入vue实例

// 校验批次可用量
export function checkBatch(data) {
  let url = `${mixin.data().winvBaseUrl}app-batch-modify/check-batch-availability`;
  return request.post(url, data);
}

// 目标批次校验
export function checkToBatch(data) {
  let url = `${mixin.data().wmdmBaseUrl}batchs/check-batch`;
  return request.get(url, data);
}

// 校验批次是否存在
export function checkBatchCode(data) {
  let url = `${mixin.data().wmdmBaseUrl}batchs/check`;
  return request.get(url, data);
}

// 目标托盘校验
export function checkToCid(data) {
  let url = `${mixin.data().winvBaseUrl}app-batch-modify/check-to-cid`;
  return request.get(url, data);
}

// 目标托盘校验
export function getValidQty(data) {
  let url = `${mixin.data().winvBaseUrl}app-batch-modify/get-valid-qty`;
  return request.get(url, data);
}

// 批次分解
export function batchSplit(data) {
  let url = `${mixin.data().winvBaseUrl}app-batch-modify/batch-split`;
  return request.post(url, data);
}
