<template>
  <hips-view :header-fixed="true">
    <!-- 头部信息 -->
    <div slot="header">
      <ips-header :title="$t('title.outboundShipment')" />
    </div>
    <div class="ips-container">
      <!-- 波次单号 -->
      <!-- <ips-lov
        :label="$t('label.waveNum')"
        :title="$t('label.waveNum')"
        v-model="inputData.waveBillCode"
        v-show="customData['waveBillCode'] ? hideControl['waveBillCode'] : true"
        params="waveBillCode"
        display="waveBillCode"
        :url="`${winvBaseUrl}app-outbound-ship/query-shippable-wave`"
        :pre-parse-url="urlData['waveBillCode']"
        :custom="customData['waveBillCode']"
        :source-parse-field="sourceParseField"
        ref="ref10"
        @click.stop="handleCommonClick(10)"
        @clickIcon="handleCommonClick(10, 'lov')"
        @enter="skipNext(20)"
        @down="skipNext(20)"
        @choose="handleChoose('waveBillCode', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      /> -->
      <!-- 出库单号 -->
      <ips-lov
        v-model="inputData.billCode"
        :label="$t('label.outboundOrderR')"
        :title="$t('label.outboundOrderR')"
        params="billCode"
        display="billCode"
        :url="`${winvBaseUrl}app-outbound-ship/query-shippable-order`"
        :pre-parse-url="urlData['billCode']"
        ref="ref20"
        is-only
        @enter="skipNext('submitRef')"
        @down="skipNext('submitRef')"
        @click.stop="handleCommonClick(20)"
        @clickIcon="handleCommonClick(20, 'lov')"
        @choose="handleChoose('billCode', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <div
        class="out_box"
        v-if="orderData.waveBillCode || orderData.billCode"
      >
        <!-- 波次单号 -->
        <ips-value-cell
          v-if="orderData.waveBillCode"
          :title="'波次单号'"
          image-flag
          :value="orderData.waveBillCode"
        />
        <!-- 出库单号 -->
        <ips-value-cell
          v-if="orderData.billCode"
          :title="$t('label.outboundOrderR')"
          image-flag
          :value="orderData.billCode"
        />
        <!-- 总发运量 -->
        <ips-value-cell
          :title="$t('label.totalFreightVolume')"
          image-flag
          :value="orderData.billPickQty"
        />
        <!-- 已处理量 -->
        <ips-value-cell
          :title="$t('label.processedCcapacity')"
          image-flag
          :value="orderData.billShipQty"
        />
      </div>
    </div>
    <div class="ips-footer">
      <!--撤销挑库-->
      <hips-button
        size="large"
        type="warning"
        ref="submitRef"
        class="ips-btn-normal"
        @click.stop="handleCommonSubmit"
        @keyup.enter.native="handleCommonSubmit"
        @keypress.native.prevent
      >
        {{ $t('button.batchshipment') }}
      </hips-button>
      <!--托盘详情-->
      <hips-button
        v-if="!orderData.forbidSelectTaskFlag"
        size="large"
        type="warning"
        class="ips-btn-normal"
        @click="goDetail"
      >
        {{ $t('button.detailedShipping') }}
      </hips-button>
    </div>
  </hips-view>
</template>

<script>
import { batchShipment } from '../api';
export default {
  name: 'Shipment',
  data() {
    return {
      orderData: {},
      inputData: {
        waveBillId: '',
        waveBillCode: '',
        billId: '',
        billCode: '',
      },
      urlData: {},
      customData: {}, // 个性化配置信息列表
      sourceParseField: {}, // 界面中所有的来源解析字段
    };
  },
  created() {
    this.$nextTick(() => {
      this.skipNext(20);
    });
  },
  methods: {
    // 跳转到发运明细页面
    goDetail() {
      if (this.inputData.waveBillId || this.inputData.billId) {
        let param = {
          name: 'ShipmentDetail',
          query: this.inputData,
        };
        this.$router.pushPage(param);
      }
    },
    // 处理选择搜集数据
    handleChoose(type, obj) {
      this.orderData = obj;
      switch (type) {
        case 'billCode':
          this.inputData.billId = obj.billId;
          this.inputData.billCode = obj.billCode;
          break;
        case 'waveBillCode':
          this.inputData.waveBillId = obj.waveBillId;
          this.inputData.waveBillCode = obj.waveBillCode;
          break;
        default:
          return null;
      }
    },
    // 点击批量发运
    async handleButtonSubmit() {
      // 只要有一个就可以批量发运
      if (this.inputData.waveBillId || this.inputData.billId) {
        try {
          this.$hint.showLoading();
          this.handleSubmitStatus();
          let res = await batchShipment(this.inputData);
          console.log(res);
          if (res) {
            const orderData = this.orderData;
            this.$hint.showSuccess();
            Object.keys(this.inputData).forEach((key) => {
              this.inputData[key] = '';
            });
            orderData.waveBillCode = '';
            orderData.billCode = '';
          }
        } catch (e) {
          console.log('错误: ' + e);
        } finally {
          this.$hint.hideLoading();
          this.handleSubmitStatus();
        }
      } else {
        this.$hips.toast({
          message: '批次单号或者是出库单号必须选择一项',
        });
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
 @import '~@/style/var.styl'
</style>