<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <!-- 批次分解 -->
      <ips-header :title="$t('title.batchMarge')" :is-index="false" @beforeBack="goToBatchMarge" />
    </div>
    <div class="ips-container">
      <!-- 目标批次 -->
      <ips-lov
        :label="$t('label.targetBatch')"
        :title="$t('label.targetBatch')"
        v-model="toBatchCode"
        v-show="customData['toBatchCode'] ? hideControl['toBatchCode']: true"
        required
        params="batchCode"
        :url="`${wmdmBaseUrl}batchs/to-batch-modify-lov`"
        :url-params="{ 'isbatchMerge': 1, 'enabledFlag': 1, 'goodsId': skuInfo.goodsId }"
        :custom="customData['toBatchCode']"
        :source-parse-field="sourceParseField"
        ref="ref0"
        @enter="skipNext(10)"
        @down="skipNext(10)"
        @click.stop="handleCommonClick(0)"
        @clickIcon="handleCommonClick(0, 'lov')"
        @choose="handleChoose('toBatchCode', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!--物料-->
      <ips-lov
        :label="$t('label.sku')"
        :title="$t('label.sku')"
        v-model="toSku"
        v-show="customData['toSku'] ? hideControl['toSku']: true"
        required
        disabled
        params="sku"
        :custom="customData['toSku']"
        :source-parse-field="sourceParseField"
        ref="ref10"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 目标托盘 -->
      <ips-lov
        :label="$t('label.targetTray')"
        :title="$t('label.targetTray')"
        v-model="toCidCode"
        v-show="customData['toCidCode'] ? hideControl['toCidCode']: true"
        params="cidCode"
        :url="`${wmdmBaseUrl}containers/to-cid-lov`"
        :custom="customData['toCidCode']"
        :source-parse-field="sourceParseField"
        ref="ref20"
        @enter="skipNext(30)"
        @down="skipNext(30)"
        @click.stop="handleCommonClick(20)"
        @clickIcon="handleCommonClick(20, 'lov')"
        @choose="handleChoose('toCidCode', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 目标库位 -->
      <ips-lov
        :label="$t('label.targetLocation')"
        :title="$t('label.targetLocation')"
        v-model="toLocationCode"
        v-show="customData['toLocationCode'] ? hideControl['toLocationCode']: true"
        required
        :disabled="cidStatus === 'IN_STOCK'"
        params="locationCode"
        :url="`${wmdmBaseUrl}wms-locations`"
        :custom="customData['toLocationCode']"
        :source-parse-field="sourceParseField"
        ref="ref30"
        @click.stop="handleCommonClick(30)"
        @clickIcon="handleCommonClick(30, 'lov')"
        @choose="handleChoose('toLocationCode', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 目标库区 -->
      <ips-lov
        :label="$t('label.targetWharea')"
        :title="$t('label.targetWharea')"
        v-model="toWhareaCode"
        v-show="customData['toWhareaCode'] ? hideControl['toWhareaCode']: true"
        required
        disabled
        params="whareaCode"
        :custom="customData['toWhareaCode']"
        :source-parse-field="sourceParseField"
        ref="ref40"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />

      <!-- 合并数量 -->
      <ips-input-number
        :label="$t('label.amount')"
        v-model="decompositionQty"
        v-show="!enableSerial ? (customData['decompositionQty'] ? hideControl['decompositionQty'] : !enableSerial) : false"
        disabled
        required
        :custom="customData['decompositionQty']"
        :source-parse-field="sourceParseField"
        ref="ref50"
        not-auto-fill
        :min="null"
        :more-than-zero="true"
        @enter="skipNext('submitRef')"
        @down="skipNext('submitRef')"
        @tab="skipNext('submitRef')"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <hips-row v-if="enableSerial">
        <div class="hip-row">
          <hips-col span="21">
            <ips-input-number
              :label="$t('label.amount')"
              v-model="decompositionQty"
              required
              disabled
              :enable-serial="skuInfo.serialType"
              :custom="customData['decompositionQty']"
              :source-parse-field="sourceParseField"
              ref="ref50"
              not-auto-fill
              :min="null"
              :more-than-zero="true"
              @enter="skipNext('refSerail')"
              @down="skipNext('refSerail')"
              @tab="skipNext('refSerail')"
              @setSourceField="saveSourceField($event)"
              @valuedByFoucs="valuedByFoucs($event)"
            />
          </hips-col>
          <hips-col span="8">
            <hips-button
              size="large"
              @click.stop="goToSerialDetail"
              @enter="goToSerialDetail"
              ref="refSerail"
              class="button-detail"
            >
              {{ $t('button.serialNumber') }}
            </hips-button>
          </hips-col>
        </div>
      </hips-row>

      <div class="count_block_box">
        <!-- 现有量 -->
        <ips-number-count
          class="count-availableQty"
          :count="availableQty"
          text-field="availableQty"
        />
        <!-- 可用量 -->
        <ips-number-count
          class="count-useQty"
          :count="useQty"
          text-field="useQty"
        />
        <!-- 保留量 -->
        <ips-number-count
          class="count-reserveQty"
          :count="reserveQty"
          text-field="reserveQty"
        />
      </div>
    </div>
    <div class="ips-footer">
      <hips-button
        class="importedDetail"
        size="large"
        v-show="customData['importedDetail'] ? hideControl['importedDetail']: true"
        style="background-color:#f5a623"
        @click.stop="handleImportedDetail"
        @keyup.enter.native="handleImportedDetail"
        @keypress.native.prevent
      >{{ $t('button.importedDetail') }}
      </hips-button>
      <hips-button
        size="large"
        @click.stop="handleCommonSubmit"
        @keyup.enter.native="handleCommonSubmit"
        @keypress.native.prevent
        style="background-color:var(--primaryColor)"
        ref="submitRef"
      >
        <svg-icon
          slot="icon"
          icon-class="success"
        />{{ $t('button.margeConfirm') }}
      </hips-button>
    </div>
  </hips-view>
</template>

<script>
  import {Col, Row} from '@hips/vue-ui';
  import { checkToBatch, checkToCid, getValidQty, batchMarge, checkBatchCode } from '../api';
  export default {
    name: 'BatchMargeConfirm',
    components: {
      [Col.name]: Col,
      [Row.name]: Row,
    },
    data() {
      return {
        fromSerialFlag: false, // 从序列号组件跳转过来
        enableSerial: '',
        toBatchId: '',
        toBatchCode: '',
        toSku: '',
        goodsId: '',
        toGoodsId: '',
        fromData: '',
        toCidId: '',
        skuInfo: '', // 物料信息存储
        toCidCode: '',
        cidStatus: '',
        toLocationId: '',
        toLocationCode: '',
        toWhareaId: '',
        toWhareaCode: '',
        warehouseId: '', // 仓库id
        toCidParam: {}, // 来源托盘参数
        toLocParam: {}, // 来源托盘参数
        decompositionQty: '', // 分解数量
        availableQty: '', // 现有量
        useQty: '', // 可用量
        reserveQty: '', // 保留量
        serialNumberList: [], // 序列号数据
        serialStockProcessDtoList: [], // 序列号数据
        margeData: [], // 合并数据
        isGotoDetail: false, // 合并数据
        customData: {}, // 个性化配置信息列表
        hideControl: {}, // 个性化配置显示隐藏控制
        sourceParseField: {}, // 界面中所有的来源解析字段
      };
    },
    methods: {
      // 获取上页数据
      async getDataFromParam() {
        const { goodsId, sku, margeData, skuInfo, enableSerial, enteredQty, sourceParseField, customData, hideControl } = this.$route.params;
        this.goodsId = goodsId;
        this.skuInfo = skuInfo;
        this.enableSerial = enableSerial;
        this.toSku = sku;
        this.margeData = margeData;
        if(enableSerial){
          margeData.forEach((item)=>{
            this.serialStockProcessDtoList.push(...item.serialStockProcessDtoList)
          })
        }
        this.decompositionQty = enableSerial?this.serialStockProcessDtoList.length:enteredQty;
        this.sourceParseField = sourceParseField;
        this.customData = customData;
        this.hideControl = hideControl;
      },
      /**
       *@functionName: saveSourceField
       *@description: 保存来源解析字段并赋值Enter
       *@params1: fieldObj[Object] 来源解析字段对象,
       */
      saveSourceField(fieldObj) {
        this.saveSourceParseField(this, fieldObj);
      },
      // 字段聚焦时赋值操作
      async valuedByFoucs(options={}) {
        const { custom ={} , currentValue } = options;
        await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
      },
      /**
       * handleChoose lov选中触发
       * @param type
       * @param obj 选中对象
       */
      handleChoose(type, obj) {
        switch (type) {
          case 'toBatchCode':
            this.toBatchId = obj.id;
            this.toBatchCode = obj.batchCode;
            this.handleBatchBlur(obj.batchCode, obj.id);
            break;
          case 'toCidCode':
            this.toCidId = obj.cidId;
            this.toCidCode = obj.cidCode;
            this.cidStatus = obj.cidStatus;
            if(obj.cidStatus === 'IN_STOCK'){
              this.handleCidBlur();
            }else {
              this.skipNext(30);
            }
            break;
          case 'toLocationCode':
            this.toLocationId = obj.locationId;
            this.toLocationCode = obj.locationCode;
            this.toWhareaId = obj.whareaId;
            this.toWhareaCode = obj.whareaCode;
            this.warehouseId = obj.warehouseId;
            this.handleLocBlur();
            break;
          default:
            return null;
        }
      },

      /**
       * 批次lov选中事件
       */
      async handleBatch(batchId){
        try {
          let data = {
            params:{
              batchId: batchId
            }
          };
          const res = await checkToBatch(data);
          if(res) {
            this.skipNext(20);
          }else {
            this.skipNext(0);
          }
        }catch(e) {
          console.log('错误：' + e);
        }
      },
      /**
       * handleBatchBlur 批次失焦触发
       */
      async handleBatchBlur(batchCode, batchId) {
        const onConfirm = () => {
          const urlParam = {
            name: 'createBatch',
            params: {
              source: 'BatchMargeConfirm',
              skuInfo: {
                sku: this.skuInfo.sku,
                goodsId: this.skuInfo.goodsId,
                ownerName: this.skuInfo.ownerName,
                ownerId: this.skuInfo.ownerId,
                orgName: this.skuInfo.orgName,
                orgId: this.skuInfo.orgId,
              },
              batchCode: batchCode,
            },
          };
          this.$router.pushPage(urlParam);
        };
        const onCancel = () => {
          this.$nextTick(() => {
            this.skipNext(0);
          })
        };
        if (batchCode) {
          try {
            this.$hint.showLoading();
            this.handleSubmitStatus();
            const data = {
              params: {
                batchCode: batchCode,
                goodsId: this.skuInfo.goodsId,
              },
            };
            const res = await checkBatchCode(data);
            if (res && !res.batchId) {
              // 该批次不存在，是否创建批次？
              this.$hint.showAlert(this.$i18n.t('message.checkBatchCode'), onConfirm, onCancel);
            } else {
              this.handleBatch(batchId);
            }
          } catch {
            this.$nextTick(() => {
              this.skipNext(0);
            })
          } finally {
            this.$hint.hideLoading();
            this.handleSubmitStatus();
          }
        }
      },
      /**
       * 托盘lov失焦
       */
      async handleCidBlur(){
        try {
          let data = {
            params:{
              cidId: this.toCidId
            }
          };
          const res = await checkToCid(data);
          if(res) {
            this.toLocationId = res.locationId;
            this.toLocationCode = res.locationCode;
            this.toWhareaId = res.whareaId;
            this.toWhareaCode = res.whareaCode;
            this.warehouseId = res.warehouseId;
            this.availableQty = res.qty; // 现有量
            this.useQty = res.validQty; // 可用量
            this.reserveQty = res.reserveQty; // 保留量
            this.handleLocBlur();
            this.skipNext('submitRef');
          }else {
            this.skipNext(20);
          }
        }catch(e) {
          console.log('错误：' + e);
        }
      },
      /**
       * 库位lov失焦
       */
      async handleLocBlur(){
        try {
          let data = {
            params:{
              batchId: this.toBatchId,
              cidId: this.toCidId,
              locationId: this.toLocationId
            }
          };
          const res = await getValidQty(data);
          if(res) {
            this.availableQty = res.qty; // 现有量
            this.useQty = res.validQty; // 可用量
            this.reserveQty = res.reserveQty; // 保留量
            this.skipNext('submitRef');
          }else {
            this.skipNext(30);
          }
        }catch(e) {
          console.log('错误：' + e);
        }
      },

      /**
       * 序列号录入
       */
      async goToSerialDetail(){
        // if(!this.toBatchId || !this.toLocationId){
        //   this.$hint.showFailed(this.$i18n.t('message.checkRequired'), true);
        //   return
        // }
        let usedStockSerialList = [];
        this.margeData.forEach((item)=>{
          usedStockSerialList = usedStockSerialList.concat(item.serialStockProcessDtoList);
        });
        let data = {
          originalStockSerialList: this.fromData.fromSerialNumberList || [],
          usedStockSerialList: usedStockSerialList,
        };
        const serialUrl = 'app-batch-modify/batch-split-check-serial';
        const param = {
          name: 'batchSerialInput',
          params: {
            data,
            serialUrl,
            serialNumberList: usedStockSerialList || [],
            isDisable: false,
          },
        };
        this.$router.pushPage(param);
      },

      // 跳转详情界面
      handleImportedDetail(){
        this.isGotoDetail = true;
        let param = {
          name: 'ImportedDetail',
          params: {
            enteredQty: this.decompositionQty,
            margeData: this.margeData,
            source: 'batchMargeConfirm',
          },
        };
        this.$storage.set('isToDetail', true);
        this.$router.pushPage(param);
      },
      // 分解
      async handleButtonSubmit() {
        if(this.margeData.length>0){
          this.$hint.showLoading();
          this.handleSubmitStatus();
          if(!this.toBatchId || !this.toLocationId){
            this.$hint.showFailed(this.$i18n.t('message.checkRequired'), true);
            return
          }
          this.$storage.set('isToDetail', false);
          let decData = {
            goodsId: this.goodsId,
            toSku: this.toSku,
            toBatchId: this.toBatchId,
            serialType: this.skuInfo.serialType,
            toBatchCode: this.toBatchCode,
            toCidId: this.toCidId,
            toCidCode: this.toCidCode,
            toWhareaId: this.toWhareaId,
            toWhareaCode: this.toWhareaCode,
            toLocationId: this.toLocationId,
            toLocationCode: this.toLocationCode,
            qty: this.decompositionQty,
            serialStockProcessDtoList: this.serialStockProcessDtoList,
          };
          const lastObj={
            ...decData,
            appBatchModifyDTOList: this.margeData
          };
          await batchMarge(lastObj);
          await this.clearSourceFields(this, true);
          this.$hint.hideLoading();
          this.handleSubmitStatus();
          this.$hint.showSuccess();
          let param = {
            name: 'BatchMarge',
            params: {
              deleteData: true,
            },
          };
          this.$router.pushPage(param);
        } else {
          this.handleNext();
        }
      },
    //  返回按钮
     async goToBatchMarge(){
       this.$router.backPage();
      //  let param = {
      //    name: 'BatchMarge',
      //    params: {
      //      source: 'batchMargeConfirm',
      //      margeData: this.margeData,
      //      data: this.margeData[this.margeData.length-1],
      //      serialData: this.margeData[this.margeData.length-1].serialStockProcessDtoList,
      //      decompositionQty: this.decompositionQty-this.margeData[this.margeData.length-1].qty
      //    },
      //  };
      //  this.$router.pushPage(param);
     },
    },
    async mounted() {
      await this.getDataFromParam();
      this.inputFieldList = [
        { type: 'lov', required: true, ref: 0, bind: 'toBatchCode', field: 'toBatchCode' },
        { type: 'lov', required: true, ref: 10, bind: 'toSku', field: 'toSku' },
        { type: 'lov', required: false, ref: 20, bind: 'toCidCode', field: 'toCidCode' },
        { type: 'lov', required: true, ref: 30, bind: 'toLocationCode', field: 'toLocationCode' },
        { type: 'lov', required: true, ref: 40, bind: 'toWhareaCode', field: 'toWhareaCode' },
        { type: 'number', required: true, ref: 50, bind: 'decompositionQty', field: 'decompositionQty' },
      ];
      // this.urlData = this.handleData('DIRECT_INB_CONFIRM');
      this.skipNext(0);
    },
    activated(){
      const {source, batchId, enableSerial, margeData} = this.$route.params;
      if(enableSerial){
        let newSerialList = [];
        margeData.forEach((item)=>{
          newSerialList.push(...item.serialStockProcessDtoList)
        });
        this.serialStockProcessDtoList = newSerialList;
      }
      // console.log('this.serialStockProcessDtoList',this.serialStockProcessDtoList )
      // this.goodsId = goodsId;
      // this.toSku = sku;
      // this.margeData = margeData;
      if(this.isGotoDetail){
        this.isGotoDetail = false;
        this.margeData = this.$storage.get('margeData') || [];
        this.decompositionQty = this.$storage.get('enteredQty') || 0;
      }
      if(source === 'createBatch'){
        this.toBatchId = batchId;
        this.skipNext(20);
      }else if(source === 'batchMarge'){
        this.skipNext(0);
      } else {
        this.skipNext('submitRef');
      }
    },
    beforeRouteLeave(to, from, next) {
      if (to.name === 'batchSerialInput' || to.name === 'createBatch' || to.name === 'ImportedDetail') {
        this.$store.commit('pushKeepAlives', 'BatchMargeConfirm');
      } else {
        this.$store.commit('deleteKeepAlives', 'BatchMargeConfirm');
      }
      setTimeout(() => {
        next();
      },0)
    },
    watch:{
      'toBatchCode': function(val, oldval) {
        if(oldval !== '' && val === ''){
          this.toBatchId = '';
          this.toCidId = '';
          this.toCidCode = '';
          this.toLocationId = '';
          this.toLocationCode = '';
          this.toWhareaId = '';
          this.toWhareaCode = '';
          this.warehouseId = '';
          this.availableQty = ''; // 现有量
          this.useQty = ''; // 可用量
          this.cidStatus = '';
          this.reserveQty = ''; // 保留量
        }
      },
      'toCidCode': function(val, oldval) {
        if(oldval !== '' && val !== oldval){
          this.toCidId = '';
          this.toLocationId = '';
          this.toLocationCode = '';
          this.toWhareaId = '';
          this.toWhareaCode = '';
          this.warehouseId = '';
          this.cidStatus=''
          this.availableQty = ''; // 现有量
          this.useQty = ''; // 可用量
          this.reserveQty = ''; // 保留量
        }
      },
      'toLocationCode': function(val, oldval) {
        if(oldval !== '' && val === ''){
          this.toLocationId = '';
          this.toWhareaId = '';
          this.toWhareaCode = '';
          this.warehouseId = '';
          this.availableQty = ''; // 现有量
          this.useQty = ''; // 可用量
          this.reserveQty = ''; // 保留量
        }
      },
    }

  };
</script>

<style lang="stylus" scoped>
 @import '~@/style/var.styl'
    .count_block_box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 16px 0;
    }
    .count-availableQty{
        background-color: #91D5FF
        border-color: var(--primaryColor)
        color: var(--primaryColor)
    }
    .count-useQty{
        background-color: #B7EB8F
        border-color: #52C41A
        color: #52C41A
    }
    .count-reserveQty{
        background-color: #FFD382
        border-color: #FF8F07
        color: #FF8F07
    }
    .hip-row{
      display flex
      padding  12px 0 0 0
      .button-detail{
        margin-left: 15px;
        margin-right: 5px;
        padding 0 5px;
        background-color: var(--primaryColor);
        color: #FFFFFF;
        border: none;
        font-size: 14px;
        height: 36px;
        line-height: 36px;
      }
    }
  .footer-button{
    display: flex;
    margin-top: 30px;
    justify-content: space-around;
    .next{
      background-color var(--primaryColor)
      color white
      width 120px
    }
    .importedDetail{
      color white
      width 120px
    }
  }
</style>
