var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: { title: _vm.cidTransferTitle, "is-index": false },
          on: { beforeBack: _vm.handleGoBack },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "header-row" }, [
      _c("div", { staticClass: "header-title" }, [
        _vm._v("\n      " + _vm._s(_vm.cidData.cidCode) + "\n    "),
      ]),
      _c(
        "div",
        {
          class: {
            "header-0-status": _vm.cidData.cidStatus === "EMPTY",
            "header-1-status": _vm.cidData.cidStatus === "RECEIVING",
            "header-2-status": _vm.cidData.cidStatus === "IN_STOCK",
            "header-3-status": _vm.cidData.cidStatus === "PICKED",
            "header-4-status": _vm.cidData.cidStatus === "LOADED",
          },
        },
        [_vm._v("\n      " + _vm._s(_vm.cidData.cidStatusName) + "\n    ")]
      ),
    ]),
    _c("div", { staticClass: "ips-container" }, [
      _c(
        "div",
        { staticClass: "card_batch_subtrans" },
        [
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("div", { staticClass: "title" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("label.transferFrom")) +
                    "\n        "
                ),
              ]),
              _c("svg-icon", {
                attrs: { slot: "icon", "icon-class": "arrow" },
                slot: "icon",
              }),
            ],
            1
          ),
          _c("ips-lov", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.customData["fromCidCodeByCid"]
                  ? _vm.hideControl["fromCidCodeByCid"]
                  : true,
                expression:
                  "customData['fromCidCodeByCid'] ? hideControl['fromCidCodeByCid']: true",
              },
            ],
            ref: "ref0",
            attrs: {
              label: _vm.$t("label.startOfTray"),
              disabled: "",
              title: _vm.$t("label.startOfTray"),
              params: "cidCode",
              url: _vm.winvBaseUrl + "new-stock-trans/query-container-from",
              "pre-parse-url": _vm.urlData["fromCidCodeByCid"],
              custom: _vm.customData["fromCidCodeByCid"],
              "source-parse-field": _vm.sourceParseField,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(0)
              },
              clickIcon: function ($event) {
                return _vm.handleCommonClick(0, "lov")
              },
              choose: function ($event) {
                return _vm.handleChoose("fromCidCodeByCid", $event)
              },
              setSourceField: function ($event) {
                return _vm.saveSourceField($event)
              },
              valuedByFoucs: function ($event) {
                return _vm.valuedByFoucs($event)
              },
            },
            model: {
              value: _vm.fromCidCodeByCid,
              callback: function ($$v) {
                _vm.fromCidCodeByCid = $$v
              },
              expression: "fromCidCodeByCid",
            },
          }),
          _c("ips-field", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.parentCidEnabled.paramValue == 1
                    ? _vm.fromParentCidCodeByCid
                    : false,
                expression:
                  "parentCidEnabled.paramValue==1? fromParentCidCodeByCid:false",
              },
            ],
            attrs: {
              label: _vm.$t("label.startOfParentTray"),
              title: _vm.$t("label.startOfParentTray"),
              disabled: "",
              params: "fromParentCidCodeByCid",
              url: _vm.winvBaseUrl + "new-stock-trans/query-container-from",
              custom: _vm.customData["fromParentCidCodeByCid"],
              "source-parse-field": _vm.sourceParseField,
            },
            model: {
              value: _vm.fromParentCidCodeByCid,
              callback: function ($$v) {
                _vm.fromParentCidCodeByCid = $$v
              },
              expression: "fromParentCidCodeByCid",
            },
          }),
          _c("ips-lov", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.customData["fromLocationCodeByCid"]
                  ? _vm.hideControl["fromLocationCodeByCid"]
                  : true,
                expression:
                  "customData['fromLocationCodeByCid'] ? hideControl['fromLocationCodeByCid']: true",
              },
            ],
            ref: "ref10",
            attrs: {
              label: _vm.$t("label.startOfLocation"),
              title: _vm.$t("label.startOfLocation"),
              disabled: "",
              required: "",
              params: "locationCode",
              url: _vm.winvBaseUrl + "new-stock-trans/query-location-from",
              "pre-parse-url": _vm.urlData["fromLocationCodeByCid"],
              custom: _vm.customData["fromLocationCodeByCid"],
              "source-parse-field": _vm.sourceParseField,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(10)
              },
              clickIcon: function ($event) {
                return _vm.handleCommonClick(10, "lov")
              },
              choose: function ($event) {
                return _vm.handleChoose("fromLocationCodeByCid", $event)
              },
              setSourceField: function ($event) {
                return _vm.saveSourceField($event)
              },
              valuedByFoucs: function ($event) {
                return _vm.valuedByFoucs($event)
              },
            },
            model: {
              value: _vm.fromLocationCodeByCid,
              callback: function ($$v) {
                _vm.fromLocationCodeByCid = $$v
              },
              expression: "fromLocationCodeByCid",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "card_countLocation" },
        [
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("svg-icon", {
                attrs: { slot: "icon", "icon-class": "arrow" },
                slot: "icon",
              }),
              _c("div", { staticClass: "title" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("label.transferTo")) +
                    "\n        "
                ),
              ]),
            ],
            1
          ),
          _c("ips-lov", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.customData["toCidCodeByCid"]
                  ? _vm.hideControl["toCidCodeByCid"]
                  : true,
                expression:
                  "customData['toCidCodeByCid'] ? hideControl['toCidCodeByCid']: true",
              },
            ],
            ref: "ref20",
            attrs: {
              label: _vm.$t("label.targetTray"),
              title: _vm.$t("label.targetTray"),
              params: "cidCode",
              disabled: _vm.transferType === "UNPACK",
              url: _vm.winvBaseUrl + "new-stock-trans/query-container-to",
              "url-params": _vm.toCidParam,
              "pre-parse-url": _vm.urlData["toCidCodeByCid"],
              custom: _vm.customData["toCidCodeByCid"],
              "source-parse-field": _vm.sourceParseField,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(20)
              },
              clickIcon: function ($event) {
                return _vm.handleCommonClick(20, "lov")
              },
              enter: _vm.handleToCidEnter,
              down: _vm.handleToCidEnter,
              tab: _vm.handleToCidTab,
              choose: function ($event) {
                return _vm.handleChoose("toCidCodeByCid", $event)
              },
              setSourceField: function ($event) {
                return _vm.saveSourceField($event)
              },
              valuedByFoucs: function ($event) {
                return _vm.valuedByFoucs($event)
              },
            },
            model: {
              value: _vm.toCidCodeByCid,
              callback: function ($$v) {
                _vm.toCidCodeByCid = $$v
              },
              expression: "toCidCodeByCid",
            },
          }),
          _c("ips-lov", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.parentCidEnabled.paramValue == 1 ? true : false,
                expression: "parentCidEnabled.paramValue==1?true:false",
              },
            ],
            ref: "ref25",
            attrs: {
              label: _vm.$t("label.targetParentTray"),
              title: _vm.$t("label.targetParentTray"),
              params: "cidCode",
              disabled: _vm.isHasParentCid,
              url:
                _vm.winvBaseUrl +
                "new-stock-trans/query-trans-to-parent-container",
              "url-params": _vm.toCidParam,
              "pre-parse-url": _vm.urlData["toParentCidCodeByCid"],
              custom: _vm.customData["toParentCidCodeByCid"],
              "source-parse-field": _vm.sourceParseField,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(25)
              },
              clickIcon: function ($event) {
                return _vm.handleCommonClick(25, "lov")
              },
              enter: _vm.handleToParentCidEnter,
              down: _vm.handleToParentCidEnter,
              tab: _vm.handleToParentCidTab,
              choose: function ($event) {
                return _vm.handleChoose("toParentCidCodeByCid", $event)
              },
              setSourceField: function ($event) {
                return _vm.saveSourceField($event)
              },
              valuedByFoucs: function ($event) {
                return _vm.valuedByFoucs($event)
              },
            },
            model: {
              value: _vm.toParentCidCodeByCid,
              callback: function ($$v) {
                _vm.toParentCidCodeByCid = $$v
              },
              expression: "toParentCidCodeByCid",
            },
          }),
          _c("ips-lov", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.customData["toLocationCodeByCid"]
                  ? _vm.hideControl["toLocationCodeByCid"]
                  : true,
                expression:
                  "customData['toLocationCodeByCid'] ? hideControl['toLocationCodeByCid']: true",
              },
            ],
            ref: "ref30",
            attrs: {
              label: _vm.$t("label.targetLocation"),
              title: _vm.$t("label.targetLocation"),
              required: "",
              disabled: _vm.isToCidCode,
              params: "locationCode",
              url: _vm.winvBaseUrl + "new-stock-trans/query-location-to",
              "url-params": _vm.toLocationParam,
              "pre-parse-url": _vm.urlData["toLocationCodeByCid"],
              custom: _vm.customData["toLocationCodeByCid"],
              "source-parse-field": _vm.sourceParseField,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(30)
              },
              clickIcon: function ($event) {
                return _vm.handleCommonClick(30, "lov")
              },
              enter: function ($event) {
                return _vm.skipNext("submitRef")
              },
              down: function ($event) {
                return _vm.skipNext("submitRef")
              },
              tab: function ($event) {
                return _vm.skipNext("submitRef")
              },
              choose: function ($event) {
                return _vm.handleChoose("toLocationCodeByCid", $event)
              },
              setSourceField: function ($event) {
                return _vm.saveSourceField($event)
              },
              valuedByFoucs: function ($event) {
                return _vm.valuedByFoucs($event)
              },
            },
            model: {
              value: _vm.toLocationCodeByCid,
              callback: function ($$v) {
                _vm.toLocationCodeByCid = $$v
              },
              expression: "toLocationCodeByCid",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "card_num_subtrans" },
        [
          _c("hips-row", [
            _c(
              "div",
              { staticClass: "hips-row" },
              [
                _c(
                  "hips-col",
                  { attrs: { span: "16" } },
                  [
                    _c("ips-input-number", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.customData["transferQtyByCid"]
                            ? _vm.hideControl["transferQtyByCid"]
                            : true,
                          expression:
                            "customData['transferQtyByCid'] ? hideControl['transferQtyByCid']: true",
                        },
                      ],
                      ref: "ref40",
                      attrs: {
                        disabled: "",
                        required: "",
                        "not-auto-fill": "",
                        min: null,
                        "more-than-zero": true,
                        label: _vm.$t("label.transferNumber"),
                        custom: _vm.customData["transferQtyByCid"],
                        "source-parse-field": _vm.sourceParseField,
                      },
                      on: {
                        enter: function ($event) {
                          return _vm.skipNext("submitRef")
                        },
                        down: function ($event) {
                          return _vm.skipNext("submitRef")
                        },
                        tab: function ($event) {
                          return _vm.skipNext("submitRef")
                        },
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.handleCommonClick(40)
                        },
                        setSourceField: function ($event) {
                          return _vm.saveSourceField($event)
                        },
                        valuedByFoucs: function ($event) {
                          return _vm.valuedByFoucs($event)
                        },
                      },
                      model: {
                        value: _vm.transferQtyByCid,
                        callback: function ($$v) {
                          _vm.transferQtyByCid = $$v
                        },
                        expression: "transferQtyByCid",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "hips-col",
                  { attrs: { span: "8" } },
                  [
                    _c(
                      "hips-button",
                      {
                        ref: "refSerail",
                        staticClass: "button-detail",
                        attrs: { size: "large" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.goToCidTransferDetail.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("button.selectTrayDetail")) +
                            "\n            "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("hips-row", [
            _c(
              "div",
              { staticClass: "row-num" },
              [
                _c(
                  "hips-col",
                  {
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      height: "100%",
                    },
                    attrs: { span: "12" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "title",
                        staticStyle: { display: "inline-block" },
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("label.availableQty")) +
                            ":\n            "
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "value",
                        staticStyle: { display: "inline-block" },
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.qty) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "hips-col",
                  {
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      height: "100%",
                    },
                    attrs: { span: "12" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "title",
                        staticStyle: { display: "inline-block" },
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("label.currentUseQty")) +
                            ":\n            "
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "value",
                        staticStyle: { display: "inline-block" },
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.validQty) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "ips-footer" },
      [
        _c(
          "hips-button",
          {
            ref: "submitRef",
            class: ["ips-btn-normal"],
            attrs: { size: "large" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonSubmit.apply(null, arguments)
              },
              enter: _vm.handleCommonSubmit,
            },
          },
          [
            _c("svg-icon", {
              attrs: { slot: "icon", "icon-class": "success" },
              slot: "icon",
            }),
            _vm._v("\n      " + _vm._s(_vm.$t("button.confirm")) + "\n    "),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }