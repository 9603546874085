var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.detailData
    ? _c("hips-view", { attrs: { "header-fixed": true } }, [
        _c(
          "div",
          { attrs: { slot: "header" }, slot: "header" },
          [
            _c("ips-header", {
              attrs: { title: _vm.$t("button.detailedShipping") },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "ips-container",
            staticStyle: { "padding-top": "2px" },
          },
          [
            _c(
              "div",
              {
                staticClass: "out_box",
                staticStyle: { padding: "0", "margin-top": "0" },
              },
              [
                _c("ips-value-cell", {
                  attrs: {
                    title: _vm.$t("label.outboundOrderR"),
                    "image-flag": "",
                    value: _vm.detailData.billCode || "无",
                  },
                }),
                _c("ips-value-cell", {
                  attrs: {
                    title: _vm.$t("label.skuCode"),
                    "image-flag": "",
                    value: _vm.detailData.sku || "无",
                  },
                }),
                _c("ips-value-cell", {
                  attrs: {
                    title: _vm.$t("label.ownerCode"),
                    "image-flag": "",
                    value: _vm.detailData.ownerName || "无",
                  },
                }),
                _c("ips-value-cell", {
                  attrs: {
                    title: _vm.$t("label.fromWarehouse"),
                    "image-flag": "",
                    value: _vm.detailData.ownerCode || "无",
                  },
                }),
                _c("ips-value-cell", {
                  attrs: {
                    title: _vm.$t("label.sourceWharea"),
                    "image-flag": "",
                    value: _vm.detailData.fromWhareaCode || "无",
                  },
                }),
                _c("ips-value-cell", {
                  attrs: {
                    title: _vm.$t("label.sourceLocation"),
                    "image-flag": "",
                    value: _vm.detailData.fromLocationCode || "无",
                  },
                }),
                _c("ips-value-cell", {
                  attrs: {
                    title: _vm.$t("label.batchCode"),
                    "image-flag": "",
                    value: _vm.detailData.batchCode || "无",
                  },
                }),
                _vm.detailData.serialType === "INV_CTRL" ||
                _vm.detailData.serialType === "OUT_CTRL" ||
                _vm.detailData.serialType === "SEMI_INV_CTRL"
                  ? _c(
                      "hips-button",
                      {
                        ref: "refSerail",
                        staticClass:
                          "serial-button-detail serial-button-detail-pos",
                        attrs: { size: "large" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.goToSerialDetail.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("button.seriesDetail")) +
                            "\n      "
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c("ips-lov", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.detailData.containerManageFlag === 1,
                  expression: "detailData.containerManageFlag === 1",
                },
              ],
              ref: "ref0",
              attrs: {
                label: _vm.$t("label.sourceTray"),
                title: _vm.$t("label.sourceTray"),
                params: "fromCidCode",
                url: _vm.winvBaseUrl + "app-outbound-ship/query-shippable-lpn",
                "url-params": _vm.fromCidParam,
                "source-parse-field": _vm.sourceParseField,
              },
              on: {
                enter: function ($event) {
                  return _vm.skipNext(10)
                },
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleCommonClick(0)
                },
                clickIcon: function ($event) {
                  return _vm.handleCommonClick(0, "lov")
                },
                choose: function ($event) {
                  return _vm.handleChoose("fromCidCode", $event)
                },
                setSourceField: function ($event) {
                  return _vm.saveSourceField($event)
                },
                valuedByFoucs: function ($event) {
                  return _vm.valuedByFoucs($event)
                },
              },
              model: {
                value: _vm.fromCidCode,
                callback: function ($$v) {
                  _vm.fromCidCode = $$v
                },
                expression: "fromCidCode",
              },
            }),
            _c("ips-input", {
              ref: "ref10",
              attrs: {
                label: _vm.$t("label.confirmSku"),
                type: "text",
                "label-position": "left",
              },
              on: {
                enter: function ($event) {
                  return _vm.skipNext(20)
                },
              },
              model: {
                value: _vm.confirmSku,
                callback: function ($$v) {
                  _vm.confirmSku = $$v
                },
                expression: "confirmSku",
              },
            }),
            _c("ips-input", {
              ref: "ref20",
              attrs: {
                label: _vm.$t("label.batchconfirmation"),
                type: "text",
                "label-position": "left",
              },
              on: {
                enter: function ($event) {
                  return _vm.skipNext(30)
                },
              },
              model: {
                value: _vm.confirmBatchCode,
                callback: function ($$v) {
                  _vm.confirmBatchCode = $$v
                },
                expression: "confirmBatchCode",
              },
            }),
            _c(
              "div",
              { staticStyle: { "margin-top": "16px" } },
              [
                _c(
                  "hips-row",
                  [
                    _c(
                      "hips-col",
                      { attrs: { span: "14" } },
                      [
                        _c("ips-input-number", {
                          ref: "ref30",
                          attrs: {
                            label: _vm.$t("label.quantityToBeTreated"),
                            required: "",
                            "not-auto-fill": "",
                            min: null,
                            "more-than-zero": true,
                          },
                          on: {
                            blur: _vm.checkNumBlur,
                            enter: function ($event) {
                              return _vm.skipNext("submitRef")
                            },
                            down: function ($event) {
                              return _vm.skipNext("submitRef")
                            },
                            tab: function ($event) {
                              return _vm.skipNext("submitRef")
                            },
                            setSourceField: function ($event) {
                              return _vm.saveSourceField($event)
                            },
                            valuedByFoucs: function ($event) {
                              return _vm.valuedByFoucs($event)
                            },
                          },
                          model: {
                            value: _vm.planQty,
                            callback: function ($$v) {
                              _vm.planQty = $$v
                            },
                            expression: "planQty",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "hips-col",
                      {
                        staticStyle: { "text-align": "right" },
                        attrs: { span: "10" },
                      },
                      [
                        _vm.detailData.serialType === "INV_CTRL" ||
                        _vm.detailData.serialType === "OUT_CTRL" ||
                        _vm.detailData.serialType === "SEMI_INV_CTRL"
                          ? _c(
                              "hips-button",
                              {
                                ref: "refSerail",
                                staticClass: "serial-button-detail",
                                attrs: { size: "large" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.goToSerialDetail2.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("button.seriesDetail")) +
                                    "(" +
                                    _vm._s(
                                      _vm.inboundSerialReceiptList.length
                                    ) +
                                    ")\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "count_block_box" },
              [
                _c("ips-number-count", {
                  staticClass: "count-totalFreightVolume",
                  attrs: {
                    count: _vm.totalFreightVolume,
                    "text-field": "totalFreightVolume",
                  },
                }),
                _c("ips-number-count", {
                  staticClass: "count-processedCapacity",
                  attrs: {
                    count: _vm.processedCapacity,
                    "text-field": "processedCapacity",
                  },
                }),
                _c("ips-number-count", {
                  staticClass: "count-quantityToBeTreated",
                  attrs: {
                    count: _vm.quantityToBeTreated,
                    "text-field": "quantityToBeTreated",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "ips-footer" },
          [
            _vm.detailData.existNextFlag === 1
              ? _c(
                  "hips-button",
                  {
                    attrs: { size: "large", type: "warning" },
                    on: { click: _vm.skipCurrentShippableDetailFun },
                  },
                  [
                    _vm._v(
                      "\n      " + _vm._s(_vm.$t("button.skipTask")) + "\n    "
                    ),
                  ]
                )
              : _vm._e(),
            _c(
              "hips-button",
              {
                ref: "submitRef",
                staticClass: "ips-btn-normal",
                attrs: { size: "large", type: "warning" },
                on: { click: _vm.handleCommonSubmit },
              },
              [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("button.shipConfirm")) + "\n    "
                ),
              ]
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }