var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "hips-view",
    [
      _c("div", { staticClass: "mine-container" }, [
        _c("div", { staticClass: "header-box g-flex" }, [
          _c("div", { staticClass: "left-img" }, [
            _c("img", {
              attrs: {
                src: require("./resources/default-header.png"),
                alt: "",
              },
            }),
          ]),
          _c("div", { staticClass: "right-info" }, [
            _c("div", { staticClass: "top-role" }, [
              _vm._v("\n          " + _vm._s(_vm.realName) + "\n        "),
            ]),
            _c("div", { staticClass: "tag-box g-flex" }, [
              _c("div", { staticClass: "tag-item" }, [
                _vm._v(
                  "\n            角色 " + _vm._s(_vm.roleName) + "\n          "
                ),
              ]),
              _c("div", { staticClass: "tag-item" }, [
                _vm._v(
                  "\n            租户 " +
                    _vm._s(_vm.tenantName) +
                    "\n          "
                ),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "top-box-warp" }, [
          _c("div", { staticClass: "top-box" }, [
            _c("div", { staticClass: "item", on: { click: _vm.selectLag } }, [
              _c("div", { staticClass: "icon" }, [
                _c("img", {
                  attrs: {
                    src: require("./resources/new-switch-yuyan.png"),
                    alt: "",
                    srcset: "",
                  },
                }),
              ]),
              _c("div", { staticClass: "text" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("title.switchLanguage")) +
                    "\n          "
                ),
              ]),
              _c("div", { staticClass: "arrow-right" }, [
                _c("img", {
                  attrs: {
                    src: require("./resources/arrow-right.png"),
                    alt: "",
                  },
                }),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "item", on: { click: _vm.selectTenant } },
              [
                _c("div", { staticClass: "icon" }, [
                  _c("img", {
                    attrs: {
                      src: require("./resources/new-switch-zuhu.png"),
                      alt: "",
                      srcset: "",
                    },
                  }),
                ]),
                _c("div", { staticClass: "text" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("title.switchTenants")) +
                      "\n          "
                  ),
                ]),
                _c("div", { staticClass: "arrow-right" }, [
                  _c("img", {
                    attrs: {
                      src: require("./resources/arrow-right.png"),
                      alt: "",
                    },
                  }),
                ]),
              ]
            ),
          ]),
        ]),
        _c("div", { staticClass: "logo" }, [
          _c("img", {
            attrs: {
              src: require("./resources/logo.png"),
              alt: "",
              srcset: "",
            },
          }),
        ]),
        _c("div", { staticClass: "submit-box" }, [
          _c("div", { staticClass: "submit-btn", on: { click: _vm.logout } }, [
            _vm._v("\n        " + _vm._s(_vm.$t("button.logout")) + "\n      "),
          ]),
        ]),
      ]),
      _c(
        "hips-popup",
        {
          attrs: { position: "bottom" },
          model: {
            value: _vm.showSwitchLanPopup,
            callback: function ($$v) {
              _vm.showSwitchLanPopup = $$v
            },
            expression: "showSwitchLanPopup",
          },
        },
        [
          _c("hips-picker", {
            attrs: {
              data: _vm.langsColumns,
              "value-key": "name",
              title: _vm.$t("title.switchLanguage"),
            },
            on: {
              confirm: function ($event) {
                return _vm.handleConfirm($event, "lan")
              },
            },
          }),
        ],
        1
      ),
      _c(
        "hips-popup",
        {
          attrs: { position: "bottom" },
          model: {
            value: _vm.showSwitchTenantPopup,
            callback: function ($$v) {
              _vm.showSwitchTenantPopup = $$v
            },
            expression: "showSwitchTenantPopup",
          },
        },
        [
          _c("hips-picker", {
            attrs: {
              data: _vm.tenantsColumns,
              "value-key": "tenantName",
              title: _vm.$t("title.switchTenants"),
            },
            on: {
              confirm: function ($event) {
                return _vm.handleConfirm($event, "tenant")
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }