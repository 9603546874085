import Load from '../views/load'

let route = [
  {
    path: '/load',
    name: 'Load',
    component: Load,
  },
]

export default route
