var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "hips-view",
    { attrs: { "header-fixed": true } },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("ips-header", {
            attrs: { title: _vm.$t("title.subinventoryTransfer") },
          }),
        ],
        1
      ),
      _c(
        "hips-row",
        { staticClass: "ips-search" },
        [
          _c(
            "hips-col",
            { attrs: { span: "20" } },
            [
              _c("ips-field", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.customData["queryCode"]
                      ? _vm.hideControl["queryCode"]
                      : true,
                    expression:
                      "customData['queryCode'] ? hideControl['queryCode']: true",
                  },
                ],
                ref: "ref0",
                attrs: {
                  label: _vm.$t("label.stockQuery"),
                  required: "",
                  custom: _vm.customData["queryCode"],
                  "source-parse-field": _vm.sourceParseField,
                },
                on: {
                  enter: _vm.handleButtonQuery,
                  setSourceField: function ($event) {
                    return _vm.saveSourceField($event)
                  },
                  valuedByFoucs: function ($event) {
                    return _vm.valuedByFoucs($event)
                  },
                },
                model: {
                  value: _vm.queryCode,
                  callback: function ($$v) {
                    _vm.queryCode = $$v
                  },
                  expression: "queryCode",
                },
              }),
            ],
            1
          ),
          _c(
            "hips-col",
            { staticClass: "ips-row-left", attrs: { span: "4" } },
            [
              _c(
                "hips-button",
                {
                  ref: "submitRef",
                  attrs: { type: "warning" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleButtonQuery.apply(null, arguments)
                    },
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.handleButtonQuery.apply(null, arguments)
                    },
                    keypress: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("button.query")) + "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "ips-container" }, [
        _vm.stockData.length === 0 && _vm.transferType === "ALL"
          ? _c(
              "div",
              [
                _c("span", { staticClass: "instructions" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("label.instructions")) +
                      "\n      "
                  ),
                ]),
                _c(
                  "hips-row",
                  { staticClass: "ips-row" },
                  [
                    _c(
                      "hips-col",
                      { staticClass: "ips-col", attrs: { span: "7" } },
                      [
                        _c(
                          "a",
                          {
                            on: {
                              enter: function ($event) {
                                return _vm.toQueryDetail("GOODS_TAG")
                              },
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.toQueryDetail("GOODS_TAG")
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("label.sku")))]
                        ),
                      ]
                    ),
                    _c(
                      "hips-col",
                      { staticClass: "ips-col", attrs: { span: "1" } },
                      [_vm._v("\n          |\n        ")]
                    ),
                    _c(
                      "hips-col",
                      { staticClass: "ips-col", attrs: { span: "7" } },
                      [
                        _c(
                          "a",
                          {
                            on: {
                              enter: function ($event) {
                                return _vm.toQueryDetail("SERIAL_NUMBER_TAG")
                              },
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.toQueryDetail("SERIAL_NUMBER_TAG")
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("label.serialNumber")))]
                        ),
                      ]
                    ),
                    _c(
                      "hips-col",
                      { staticClass: "ips-col", attrs: { span: "1" } },
                      [_vm._v("\n          |\n        ")]
                    ),
                    _c(
                      "hips-col",
                      { staticClass: "ips-col", attrs: { span: "7" } },
                      [
                        _c(
                          "a",
                          {
                            on: {
                              enter: function ($event) {
                                return _vm.toQueryDetail("CONTAINER_TAG")
                              },
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.toQueryDetail("CONTAINER_TAG")
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("label.tray")))]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.objTag === "GOODS_TAG" && _vm.stockData.length >= 1
          ? _c(
              "div",
              _vm._l(_vm.stockData, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    ref: "ref" + (index * 10 + 10),
                    refInFor: true,
                    staticClass: "card_countLocation",
                    on: {
                      click: function ($event) {
                        return _vm.toSkuTransfer(item)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("label.org")) +
                            ":\n          "
                        ),
                      ]),
                      _c("div", { staticClass: "label" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(item.orgName) +
                            "\n          "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("label.ownerCode")) +
                            ":\n          "
                        ),
                      ]),
                      _c("div", { staticClass: "label" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(item.ownerName) +
                            "\n          "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("label.sku")) +
                            ":\n          "
                        ),
                      ]),
                      _c("div", { staticClass: "label" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(item.goodsName) +
                            "\n          "
                        ),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
        _vm.objTag === "SERIAL_NUMBER_TAG" && _vm.stockData.length >= 1
          ? _c(
              "div",
              _vm._l(_vm.stockData, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    ref: "ref" + (index * 10 + 10),
                    refInFor: true,
                    staticClass: "card_wharea",
                    on: {
                      click: function ($event) {
                        return _vm.toSerialDetail(item)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("label.org")) +
                            ":\n          "
                        ),
                      ]),
                      _c("div", { staticClass: "label" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(item.orgName) +
                            "\n          "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("label.serialNumber")) +
                            ":\n          "
                        ),
                      ]),
                      _c("div", { staticClass: "label" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(item.serialNumber) +
                            "\n          "
                        ),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }