<template>
  <hips-view>
    <div slot="header">
      <ips-header
        :is-index="false"
        :render-data="billDetail"
        page-type="taskExecution"
        @beforeBack="beforeBack"
        :trigger="$t('button.skipTask')"
        :on-go-detail="handleSkipSubmit"
      />
    </div>

    <ips-page-head
      :title-info="{ billCode, billTypeName }"
      :content-info="billDetail"
      :react-field="['sku', 'sourceTypeName', 'warehouseName', 'whareaCode', 'qtyPlan', 'uomName']"
      :footer-message="billDetail['goodsName']"
      page-box-task-comfirm-class="task-execution-page-header"
    />

    <!-- <div style="margin-top: 10px;"> -->
    <!-- <div class="card-title" style="margin-left: 10px;"> -->
    <!-- 来源信息 -->
    <!-- {{ $t('label.sourceMessage') }} -->
    <!-- </div> -->
    <!-- <div class="card-type"> -->
    <!-- <ips-page-card -->
    <!-- :key="index" -->
    <!-- :react-filed="['', 'locationCode', 'batchCode', 'cidCode']" -->
    <!-- :item="billDetail" -->
    <!-- /> -->
    <!-- </div> -->
    <!-- </div> -->
    <div class="ips-container" style="padding-bottom: 10px; height: calc(100vh - 55vw); padding-top: 0.27vw">
      <!-- <div class="card-title"> -->
      <!-- 执行信息 -->
      <!-- {{ $t('label.executionMessage') }} -->
      <!-- </div> -->
      <div style="box-shadow: 0 0 2.133vw #d9d9d9">
        <div class="container-warp-box" style="height: calc(100vh - 75vw); overflow-y: scroll">
          <!-- <ips-value-cell :title="$t('label.sku')" :value="billDetail.sku" v-show="customData['sku'] ? hideControl['sku'] : true" /> -->
          <!-- 确认物料 -->
          <ips-field
            v-model="scanSku"
            required
            :label="$t('label.confirmSku')"
            params="scanSku"
            display="scanSku"
            :pre-parse-url="urlData['scanSku']"
            ref="ref0"
            :custom="customData['scanSku']"
            :source-parse-field="sourceParseField"
            v-show="customData['scanSku'] ? hideControl['scanSku'] : true"
            @setSourceField="saveSourceField($event)"
            @valuedByFoucs="valuedByFoucs($event)"
            @click.stop="handleCommonClick(0)"
            @enter="() => handleSkuEnter(0)"
            @blur="() => handleSkuBlur(0)"
          />
          <!--品名-->
          <!-- <ips-value-cell :title="$t('label.goodsName')" :value="billDetail.goodsName" v-show="customData['goodsName'] ? hideControl['goodsName'] : true" /> -->
          <!-- 单位 -->
          <!-- <ips-value-cell :title="$t('label.unit')" :value="billDetail.uomCode" v-show="customData['uomCode'] ? hideControl['uomCode'] : true" /> -->
          <!--来源库区-->
          <!-- <ips-value-cell :title="$t('label.sourceWharea')" :value="billDetail.whareaCode" v-show="customData['whareaCode'] ? hideControl['whareaCode'] : true" /> -->
          <!-- 确认库区 -->
          <ips-field
            v-model="scanWhareaCode"
            required
            :label="$t('label.confirmWharea')"
            params="scanWhareaCode"
            display="scanWhareaCode"
            :pre-parse-url="urlData['scanWhareaCode']"
            ref="ref10"
            :custom="customData['scanWhareaCode']"
            :source-parse-field="sourceParseField"
            v-show="customData['scanWhareaCode'] ? hideControl['scanWhareaCode'] : true"
            @setSourceField="saveSourceField($event)"
            @valuedByFoucs="valuedByFoucs($event)"
            @click.stop="handleCommonClick(10)"
            @enter="handleWhareaEnter(10)"
            @blur="handleWhareaBlur(10)"
          />
          <!-- 确认库位 -->
          <ips-lov
            v-model="billDetail.scanLocationCode"
            :label="$t('label.confirmLocation')"
            required
            :title="$t('label.confirmLocation')"
            params="scanLocationCode"
            :url="`${winvBaseUrl}app-outbound-load/query-from-locations`"
            :url-params="billDetail"
            :pre-parse-url="urlData['scanLocationCode']"
            ref="ref20"
            :custom="customData['scanLocationCode']"
            :source-parse-field="sourceParseField"
            v-show="customData['scanLocationCode'] ? hideControl['scanLocationCode'] : true"
            @setSourceField="saveSourceField($event)"
            @valuedByFoucs="valuedByFoucs($event)"
            @click.stop="handleCommonClick(20)"
            @clickIcon="handleCommonClick(20, 'lov')"
            @choose="handleChoose('location', $event)"
            @enter="handleLocationEnter()"
            @down="handleLocationEnter()"
            @change="handleChange"
          />
          <!--建议库位-->
          <ips-value-cell
            :special-background="true"
            self-icon="location"
            :title="$t('label.suggestedLocation')"
            :value="billDetail.locationCode"
            v-show="customData['locationCode'] ? hideControl['locationCode'] : display['locationCode']"
          />
          <!-- 确认托盘 -->
          <ips-lov
            v-model="billDetail.scanCidCode"
            :label="$t('label.conformTray')"
            :title="$t('label.conformTray')"
            params="scanCidCode"
            :url="`${winvBaseUrl}app-outbound-load/query-from-containers`"
            :url-params="billDetail"
            :pre-parse-url="urlData['scanCidCode']"
            ref="ref30"
            :custom="customData['scanCidCode']"
            :source-parse-field="sourceParseField"
            v-show="customData['scanCidCode'] ? hideControl['scanCidCode'] : containerManageFlag"
            @setSourceField="saveSourceField($event)"
            @valuedByFoucs="valuedByFoucs($event)"
            @click.stop="handleCommonClick(30)"
            @clickIcon="handleCommonClick(30, 'lov')"
            @choose="handleChoose('cid', $event)"
            @enter="handleCidEnter"
            @down="handleCidEnter"
            @blur="handleCheckCid"
            @change="handleCidChange"
          />
          <!--建议托盘-->
          <ips-value-cell
            self-icon="cid"
            :special-background="true"
            :title="$t('label.suggestedCidCode')"
            :value="billDetail.cidCode"
            v-show="customData['cidCode'] ? hideControl['cidCode'] : containerManageFlag && display['cidCode']"
          />
          <!-- 确认批次 -->
          <ips-lov
            v-model="billDetail.scanBatchCode"
            :label="$t('label.confirmBatchCode')"
            :title="$t('label.confirmBatchCode')"
            required
            params="scanBatchCode"
            :url="`${winvBaseUrl}app-outbound-load/query-batchs`"
            :url-params="billDetail"
            :pre-parse-url="urlData['scanBatchCode']"
            :disabled="disabledBatch"
            ref="ref40"
            :custom="customData['scanBatchCode']"
            :source-parse-field="sourceParseField"
            v-show="
              isBatch
                ? customData['scanBatchCode']
                  ? hideControl['scanBatchCode']
                  : isBatch && display['batchCode']
                : display['batchCode']
            "
            @setSourceField="saveSourceField($event)"
            @valuedByFoucs="valuedByFoucs($event)"
            @click.stop="handleCommonClick(40)"
            @clickIcon="handleCommonClick(40, 'lov')"
            @choose="handleChoose('batch', $event)"
            @enter="handleBatchSkip"
            @down="handleBatchSkip"
            @change="handleChange"
          />
          <!--建议批次-->
          <ips-value-cell
            self-icon="batch"
            :special-background="true"
            :title="$t('label.suggestedBatch')"
            :value="billDetail.batchCode"
            v-show="
              isBatch
                ? customData['batchCode']
                  ? hideControl['batchCode']
                  : isBatch && display['suggestBatchCode']
                : display['suggestBatchCode']
            "
          />

          <!-- 序列号录入 -->
          <hips-row>
            <div style="margin-top: 16px" v-if="enableSerial">
              <hips-col span="14">
                <ips-input-number
                  v-model="billDetail.qtyScan"
                  required
                  not-auto-fill
                  :label="$t('label.theScanQty')"
                  ref="ref50"
                  :custom="customData['qtyScan']"
                  :source-parse-field="sourceParseField"
                  v-show="customData['qtyScan'] ? hideControl['qtyScan'] : true"
                  @setSourceField="saveSourceField($event)"
                  @valuedByFoucs="valuedByFoucs($event)"
                  @enter="handleQtyScan"
                  @down="handleQtyScan"
                  @click.stop="handleCommonClick(50)"
                  @change="changeQtyScan"
                  @focus="focusQtyNumber"
                />
              </hips-col>
              <hips-col span="10">
                <!-- 序列号明细 -->
                <hips-button
                  @click="goToSerial"
                  ref="inputRef"
                  v-show="customData['serialNumEntry'] ? hideControl['serialNumEntry'] : enableSerial"
                  :style="{
                    ...inputBtnStyle,
                    width: '98%',
                    'margin-left': '3px',
                    'text-align': 'center',
                    overflow: 'hidden',
                    'text-overflow': 'ellipsis',
                  }"
                >
                  {{
                    serialNumberDtoList && serialNumberDtoList.length > 0
                      ? `${$t('button.serialDetail')}(${serialNumberDtoList.length})`
                      : $t('button.seriesDetail')
                  }}
                </hips-button>
              </hips-col>
            </div>
          </hips-row>
          <!-- 需求数量 -->
          <!-- <ips-value-cell :title="$t('label.planQty')" :value="billDetail.qtyPlan" v-show="customData['qtyPlan'] ? hideControl['qtyPlan'] : true" /> -->
          <!-- 本次扫描数量 -->
          <div style="margin-top: 18px" v-if="!enableSerial">
            <ips-input-number
              v-model="billDetail.qtyScan"
              required
              not-auto-fill
              :disabled="showSerialBtn || isParentCidDisabled"
              :label="$t('label.theScanQty')"
              ref="ref50"
              :custom="customData['qtyScan']"
              :source-parse-field="sourceParseField"
              v-show="customData['qtyScan'] ? hideControl['qtyScan'] : true"
              @setSourceField="saveSourceField($event)"
              @valuedByFoucs="valuedByFoucs($event)"
              @enter="handleQtyScan"
              @down="handleQtyScan"
              @change="handleChange"
              @click.stop="handleCommonClick(50)"
              @focus="focusQtyNumber"
            />
          </div>
          <div class="qty-card" v-if="showQtyDialog">
            <div class="text">
              {{ $t('label.qty') }}
              <span>{{ qty }}</span>
            </div>
            <div class="text">
              {{ $t('label.validQty') }}
              <span>{{ validQty }}</span>
            </div>
          </div>
          <!-- 转运托盘 -->
          <ips-lov
            v-model="billDetail.loadCidCode"
            :label="$t('label.toCidCode')"
            required
            :title="$t('label.toCidCode')"
            params="loadCidCode"
            :url="`${winvBaseUrl}app-outbound-load/query-load-containers`"
            :url-params="billDetail"
            :pre-parse-url="urlData['loadCidCode']"
            ref="ref60"
            allow-no-exist
            :custom="customData['loadCidCode']"
            :source-parse-field="sourceParseField"
            v-show="customData['loadCidCode'] ? hideControl['loadCidCode'] : display['toCidCode']"
            @setSourceField="saveSourceField($event)"
            @valuedByFoucs="valuedByFoucs($event)"
            @click.stop="handleCommonClick(60)"
            @clickIcon="handleCommonClick(60, 'lov')"
            @choose="handleChoose('loadCid', $event)"
            @enter="handleLoadEnter"
            @down="handleLoadEnter"
            @change="handleChange"
          />
          <!-- 替换原因 -->
          <ips-lov
            v-model="billDetail.replaceReasonMeaning"
            :label="$t('label.replaceReason')"
            :required="rpReasonRequired"
            :title="$t('label.replaceReason')"
            params="meaning"
            :url="`${wmdmBaseUrl}wms-common/lov-data-query`"
            :url-params="{ lovCode: 'WINV.OUTBOUND_LOAD_REASON', value: billDetail.replaceReason }"
            :pre-parse-url="urlData['replaceReasonMeaning']"
            :is-page-content="false"
            ref="ref70"
            :custom="customData['replaceReasonMeaning']"
            :source-parse-field="sourceParseField"
            v-show="
              customData['replaceReasonMeaning'] // 判断个性化是否启用 // 启用时判断个性化是否隐藏，隐藏直接不显示，不隐藏直接按页面参数设置判断
                ? hideControl['replaceReasonMeaning']
                  ? rpReasonRequired
                    ? replaceFlag
                      ? true
                      : false
                    : false
                  : false // 个性化不启用时直接按页面参数设置判断
                : rpReasonRequired
                  ? replaceFlag
                    ? true
                    : false
                  : false
            "
            @setSourceField="saveSourceField($event)"
            @valuedByFoucs="valuedByFoucs($event)"
            @click.stop="handleCommonClick(70)"
            @clickIcon="handleCommonClick(70, 'lov')"
            @enter="handleReplaceEnter"
            @down="handleReplaceEnter"
            @choose="handleChoose('replace', $event)"
          />
          <!-- 例外原因 -->
          <ips-lov
            v-model="billDetail.exceptionReasonMeaning"
            :label="$t('label.exceptionReason')"
            :title="$t('label.exceptionReason')"
            :required="ecReasonRequired"
            params="meaning"
            :url="`${wmdmBaseUrl}wms-common/lov-data-query`"
            :url-params="{ lovCode: 'WINV.OUTBOUND_EXCEPTION_REASON', value: billDetail.exceptionReason }"
            :pre-parse-url="urlData['exceptionReasonMeaning']"
            :is-page-content="false"
            ref="ref80"
            :custom="customData['replaceReasonMeaning']"
            :source-parse-field="sourceParseField"
            v-show="
              customData['exceptionReasonMeaning'] // 判断个性化是否启用 // 启用时判断个性化是否隐藏，隐藏直接不显示，不隐藏直接按页面参数设置判断
                ? hideControl['exceptionReasonMeaning']
                  ? ecReasonRequired
                    ? exceptionFlag
                      ? true
                      : false
                    : false
                  : false // 个性化不启用时直接按页面参数设置判断
                : ecReasonRequired
                  ? exceptionFlag
                    ? true
                    : false
                  : false
            "
            @click.stop="handleCommonClick(80)"
            @setSourceField="saveSourceField($event)"
            @valuedByFoucs="valuedByFoucs($event)"
            @clickIcon="handleCommonClick(80, 'lov')"
            @enter="skipNext('submitRef')"
            @down="skipNext('submitRef')"
            @choose="handleChoose('exception', $event)"
          />
        </div>
      </div>
    </div>
    <div class="ips-container" style="padding: 1px">
      <div class="ips-footer-more" style="position: absolute; bottom: 5px">
        <!--挑库并卸货-->
        <hips-button
          size="middle"
          @click.stop="handleCommonSubmit('loadAndUnload')"
          v-show="customData['pickAndUnload'] ? hideControl['pickAndUnload'] : true"
        >
          {{ $t('button.pickAndUnload') }}
        </hips-button>
        <!--完结任务-->
        <hips-button
          size="middle"
          @click.stop="handleCommonSubmit('complete')"
          v-show="customData['overTask'] ? hideControl['overTask'] : true"
          :class="[{ 'useless-button': uselessButton }]"
          :disabled="uselessButton"
        >
          {{ $t('button.overTask') }}
        </hips-button>
        <!--挑库确认-->
        <hips-button
          size="middle"
          @click.stop="handleCommonSubmit('load')"
          @keyup.enter.native="handleCommonSubmit('load')"
          @keypress.native.prevent
          ref="submitRef"
        >
          <svg-icon slot="icon" icon-class="success" />
          {{ $t('button.pickConfirm') }}
        </hips-button>
        <!--跳过任务-->
        <!-- <hips-button
          data-blur="true"
          size="middle"
          v-show="customData['skipTask'] ? hideControl['skipTask'] : true"
          @click.stop="handleSkipSubmit"
          style="color: #999; border-color: #999; flex-grow: 1"
        >
          {{ $t('button.skipTask') }}
        </hips-button> -->
      </div>
    </div>
  </hips-view>
</template>

<script>
import { checkScanQty, skipTask, clearTask, getAppId, checkCidCode, checkQtyNumber, checkLoadCidCode, checkBatchCode } from '../api';

export default {
  name: 'ExecutionConfirm',
  data() {
    return {
      billDetail: {
        startTaskNumber: 1, // 默认任务数量
        whareaCode: '',
        scanLocationCode: '',
        scanCidCode: '',
        scanBatchCode: '',
        loadCidCode: '',
        qtyScan: '',
        replaceReasonMeaning: '',
        exceptionReasonMeaning: '',
        replaceReason: '',
        exceptionReason: '',
      },
      skipTo70: false,
      skipTo80: false,
      disabledBatch: false,
      parentCidFlag: '',
      childContainerList: [],
      scanSku: '',
      scanWhareaCode: '',
      isBatch: false,
      replaceFlag: false,
      exceptionFlag: false,
      qtyPlan: -1,
      isParentCidDisabled: false, // 父托盘禁用扫描数量
      rpReasonRequired: false, //替换原因是否必输
      ecReasonRequired: false, //例外原因是否必输
      enableSerial: false,
      showSerialBtn: false,
      serialNumberDtoList: [], // 序列号明细界面传过来的选中行
      urlData: {},
      customData: {}, // 个性化配置信息列表
      hideControl: {}, // 非必输字段的显隐控制
      sourceParseField: {}, // 界面中所有的来源解析字段
      showQtyDialog: false, // 展示数据可用量框
      qtyChanged: false,
      containerManageFlag: false, // 是否启用托盘管理
      display: {
        // 建议字段显示控制
        locationCode: false, // 来源、建议库位
        cidCode: false, // 建议托盘
        batchCode: false, //批次
        suggestBatchCode: false, // 建议批次
        replaceReason: false, // 替换原因
        exceptionReason: false, // 例外原因
        toCidCode: false, // 转运托盘
      },
      uselessButton: false, // 按钮不可操作控制
    };
  },
  methods: {
    // 保存来源解析字段
    async saveSourceField(fieldObj) {
      this.saveSourceParseField(this, fieldObj);
    },
    // 字段聚焦时赋值操作
    async valuedByFoucs(options = {}) {
      const { custom = {}, currentValue } = options;
      await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
    },
    // 序列号录入界面跳转
    goToSerial() {
      if (this.serialNumberDtoList && this.serialNumberDtoList.length > 0) {
        this.goToSerialDetail();
      } else {
        const SERIALCHECKURL = 'app-outbound-load/check-serial-sernumber'; // 序列号录入、校验
        const SERIALSUBMITURL = 'app-outbound-load/confirm-serial-sernumber'; // 序列号提交
        // const _this = this;
        const param = {
          name: 'SerialExecution',
          params: {
            SERIALCHECKURL,
            SERIALSUBMITURL,
            billDetail: { ...this.billDetail },
            receiveQty: this.billDetail.qtyScan,
            showInputBox: true, // 是否展示输入框
            // 待确认参数
            serialNumberDtoList: this.serialNumberDtoList,
            detailIdTmpList: this.billDetail.taskId,
          },
        };
        this.$router.pushPage(param);
      }
    },

    /**
     * 前往序列号明细界面
     */
    async goToSerialDetail() {
      let data = {
        billId: this.billId,
      };
      let serialUrl = 'app-inbound/serial-check';
      const param = {
        name: 'serialComponent',
        params: {
          data,
          serialUrl,
          receiveQty: this.billDetail.qtyScan,
          detailIdTmpList: true,
          task: this.serialNumberDtoList,
          callback: function (serialNum) {
            const item = {
              serialNumber: serialNum,
              qualityStatusName: '0-合格',
              qualityStatus: '0-ACCEPTED',
              serialQty: 1,
            };

            this.tableData.push(item);
          },
        },
      };
      this.$router.pushPage(param);
    },

    // 界面初始化获取上个页面的参数
    getDataFromParam() {
      // const site = [this.SERIAL_INV_CTRL, this.SERIAL_OUT_CTRL, this.SERIAL_SEMI_INV_CTRL];
      const { billDetail, sourceParseField, customData, hideControl, goodsId } = this.$route.params;
      if (billDetail) {
        this.enableSerial = this.isShowSerialButton(billDetail);
        this.billDetail = billDetail;
        this.billDetail.startTaskNumber = 1; // 默认任务数量
        this.isBatch = billDetail.isbatch;
        if (!this.$common.isEmpty(this.billDetail.locationCode)) this.display.locationCode = true;
        this.rpReasonRequired = billDetail.needReplaceReason === '1';
        this.ecReasonRequired = billDetail.needReason === '1';
        const { sourceType, serialType, warehouseId, toWarehouseId } = billDetail;
        if (sourceType === 'TRANSFER' && serialType === 'OUT_CTRL') {
          this.showSerialBtn = false;
        } else if (sourceType === 'TRANSFER' && serialType === 'SEMI_INV_CTRL' && warehouseId === toWarehouseId) {
          this.showSerialBtn = false;
        } else if (serialType === 'INV_CTRL' || serialType === 'OUT_CTRL' || serialType === 'SEMI_INV_CTRL') {
          this.showSerialBtn = true;
        }
      }
      this.sourceParseField = sourceParseField;
      this.customData = customData;
      this.hideControl = hideControl;
      this.goodsId = goodsId;
    },

    isShowSerialButton(billDetail) {
      const { sourceType, serialType, warehouseId, toWarehouseId } = billDetail;
      if (sourceType === 'TRANSFER' && serialType === 'SEMI_INV_CTRL' && warehouseId === toWarehouseId) {
        return false;
      } else if (sourceType === 'TRANSFER' && serialType === 'OUT_CTRL') {
        return false;
      } else if (serialType === 'OUT_CTRL' || serialType === 'SEMI_INV_CTRL' || serialType === 'INV_CTRL') {
        return true;
      }
      return false;
    },

    // 物料loventer调用验证
    handleSkuEnter(ref) {
      if (this.scanSku) {
        const refObj = this.$refs[`ref${ref}`];
        if (this.scanSku !== this.billDetail.sku) {
          refObj.isError = true;
          refObj.validateClass = 'has-error';
          refObj.errorMsg = this.$i18n.t('message.reEnter');
        } else {
          refObj.isError = false;
          refObj.validateClass = false;
          refObj.errorMsg = '';
          this.skipNext(10);
        }
      }
    },

    async beforeBack() {
        await clearTask({});
        this.$router.backPage();
    },
    // 物料lov失焦调用验证
    async handleSkuBlur(ref) {
      let breakOut = false;
      if (this.scanSku) {
        // console.log("开始触发input失焦函数******");
        const refObj = this.$refs[`ref${ref}`];
        if (this.scanSku !== this.billDetail.sku) {
          refObj.isError = true;
          refObj.validateClass = 'has-error';
          refObj.errorMsg = this.$i18n.t('message.reEnter');
          breakOut = true;
        } else {
          refObj.isError = false;
          refObj.validateClass = false;
          refObj.errorMsg = '';
          // this.skipNext(10);
        }
      }
      return breakOut;
    },

    // 确认库位lov失焦与enter调用验证
    handleWhareaEnter(ref) {
      if (this.scanWhareaCode) {
        const refObj = this.$refs[`ref${ref}`];
        if (this.scanWhareaCode !== this.billDetail.whareaCode) {
          refObj.isError = true;
          refObj.validateClass = 'has-error';
          refObj.errorMsg = this.$i18n.t('message.reEnter');
        } else {
          refObj.isError = false;
          refObj.validateClass = false;
          refObj.errorMsg = '';
          this.skipNext(20);
        }
      }
    },

    // 确认库区lov失焦与Blur调用验证
    handleWhareaBlur(ref) {
      let breakOut = false;
      if (this.scanWhareaCode) {
        const refObj = this.$refs[`ref${ref}`];
        if (this.scanWhareaCode !== this.billDetail.whareaCode) {
          refObj.isError = true;
          refObj.validateClass = 'has-error';
          refObj.errorMsg = this.$i18n.t('message.reEnter');
          breakOut = true;
        } else {
          refObj.isError = false;
          refObj.validateClass = false;
          refObj.errorMsg = '';
          // this.skipNext(20);
        }
      }
      return breakOut;
    },

    handleCheckCid() {
      const { locationCode, batchCode, scanBatchCode, cidCode, scanLocationCode, cidId, scanCidCode } = this.billDetail;
      if (!scanCidCode && cidId) {
        if (
          scanLocationCode !== locationCode ||
          (batchCode && scanBatchCode && batchCode !== scanBatchCode) ||
          (!scanCidCode && cidCode !== !scanCidCode)
        ) {
          this.replaceFlag = true;
        } else {
          this.replaceFlag = false;
          this.billDetail.replaceReasonMeaning = '';
          this.billDetail.replaceReason = '';
        }
      } else if (!scanCidCode && !cidId) {
        if (scanLocationCode !== locationCode || (batchCode && scanBatchCode && batchCode !== scanBatchCode)) {
          this.replaceFlag = true;
        } else {
          this.replaceFlag = false;
          this.billDetail.replaceReasonMeaning = '';
          this.billDetail.replaceReason = '';
        }
      }
    },

    // lov选择事件
    handleChoose(type, obj) {
      // const { locationCode, cidCode, batchCode } = this.billDetail;
      switch (type) {
        case 'location':
          this.containerManageFlag = obj.containerManageFlag;
          this.billDetail.scanLocationId = obj.scanLocationId;
          // this.checkReplace(locationCode, obj.scanLocationCode, 'location');
          break;
        case 'cid':
          this.billDetail.scanCidId = obj.scanCidId;
          this.qtyPlan = obj.qtyPlan;
          this.parentCidFlag = obj.parentCidFlag;
          // this.checkReplace(cidCode, obj.scanCidCode, 'cid');
          break;
        case 'batch':
          this.billDetail.scanBatchId = obj.scanBatchId;
          // this.checkReplace(batchCode, obj.scanBatchCode, 'batch');
          break;
        case 'loadCid':
          if (obj !== 'noExist') {
            this.billDetail.loadCidId = obj.loadCidId;
          } else {
            this.billDetail.loadCidId = null;
          }
          this.checkCid();
          break;
        case 'replace':
          this.billDetail.replaceReason = obj.value;
          break;
        case 'exception':
          this.billDetail.exceptionReason = obj.value;
          break;
        default:
          return null;
      }
    },
    // 扫描数量失焦
    // async checkNumber() {
    //   try{
    //     if(this.billDetail.qtyScan){
    //       let data = {
    //         ...this.billDetail,
    //         parentCidFlag: this.parentCidFlag,
    //         childContainerList: this.childContainerList,
    //       };
    //       // 优先校验输入数量是否满足可用量
    //       const { qtyScan } = this.billDetail;
    //       if(qtyScan && (qtyScan > this.validQty)) {
    //         this.$hint.showFailed(this.$i18n.t('message.qtyNeedLessAvailable'), true);
    //         this.skipNext(50);
    //         this.$hint.hideLoading();
    //         return;
    //       }
    //       this.$hint.showLoading();
    //       const res = await checkScanQty(data);
    //       this.checkException();
    //       if(res){
    //         this.billDetail.loadCidCode = this.billDetail.scanCidCode;
    //         this.billDetail.loadCidId = this.billDetail.scanCidId;
    //       }
    //     }

    //     // if (this.qtyPlan > -1 && this.billDetail.qtyScan === this.qtyPlan) {
    //     //   this.billDetail.loadCidCode = this.billDetail.scanCidCode;
    //     //   this.billDetail.loadCidId = this.billDetail.scanCidId;
    //     // }
    //   } catch(e) {
    //     this.skipNext(50);
    //   } finally {
    //     this.$hint.hideLoading();
    //   }
    // },

    // 批次enter或来源托盘飞批次控制enter
    async handleBatchSkip() {
      if (this.billDetail.scanBatchCode) {
        const {
          goodsId,
          locationId,
          batchCode,
          batchId,
          cidId,
          scanBatchId,
          scanBatchCode,
          scanLocationId,
          scanLocationCode,
          scanCidId,
          scanCidCode,
        } = this.billDetail;
        const data = {
          goodsId,
          locationId,
          batchId,
          batchCode,
          cidId,
          scanBatchId,
          scanBatchCode,
          scanLocationId,
          scanLocationCode,
          scanCidId,
          scanCidCode,
        };
        const res = await checkBatchCode(data);
        if (res && res.failed && res.message) {
          this.$hint.showFailed(res.message, true);
          return;
        }
      }
      this.$nextTick(() => {
        this.skipNext(50);
      });
    },

    // 跳过任务
    async handleSkipSubmit() {
      try {
        this.$hint.showLoading();
        const res = await skipTask(this.billDetail);
        this.$hint.showSuccess(this.$i18n.t('message.skipTaskSuccess'));
        if (res && res.billId) {
          const { refreshFlag } = res;
          this.reHandle(res, refreshFlag);
        } else {
          this.$router.backPage();
        }
      } catch (e) {
        console.log('错误: ' + e);
      } finally {
        this.$hint.hideLoading();
      }
    },

    /**
     * 校验原因字段
     */
    checkReasonPass() {
      let replacePass = true;
      let exceptionPass = true;
      if (this.replaceFlag && this.rpReasonRequired) {
        replacePass = this.billDetail.replaceReasonMeaning;
      }

      if (this.exceptionFlag && this.ecReasonRequired) {
        exceptionPass = this.billDetail.exceptionReasonMeaning;
      }
      return replacePass && exceptionPass;
    },

    async handleButtonSubmit(type) {
      const handleSubmitConfirm = async () => {
        try {
          const { loadCidCode, loadCidId } = this.billDetail;
          this.$hint.showLoading();
          this.handleSubmitStatus();
          let url = '';
          const data = {
            ...this.billDetail,
            serialNumberDtoList: this.serialNumberDtoList,
          };
          switch (type) {
            case 'load':
              url = `${this.winvBaseUrl}app-outbound-load/load`;
              break;
            case 'loadAndUnload':
              url = `${this.winvBaseUrl}app-outbound-load/load-and-unload`;
              break;
            case 'complete':
              url = `${this.winvBaseUrl}app-outbound-load/complete-task`;
              break;
            case 'skip':
              url = `${this.winvBaseUrl}app-outbound-load/skip-task`;
              break;
            default:
              return null;
          }
          const res = await this.$http.post(url, data);

          this.$hint.showSuccess();
          if (type === 'loadAndUnload') {
            await this.getAppID();
            const appArgs = {
              loadCidCode,
              loadCidId,
            };
            let param = {
              name: 'unloading',
              query: appArgs,
            };
            this.$router.pushPage(param);
            // this.clearSourceFields();
            // this.$bridge.closeWebView();
            // await this.$bridge.goApplication(this.appId, appArgs);
          } else if (res && res.billId) {
            const { refreshFlag } = res;
            // 确认提交之后数据初始化
            this.showQtyDialog = false;
            this.qty = null;
            this.validQty = null;
            this.isBatch = res.isbatch;
            this.reHandle(res, refreshFlag);
            this.serialNumberDtoList = [];
          } else {
            // this.clearSourceFields();
            this.$router.backPage();
          }
        } finally {
          this.$hint.hideLoading();
          this.handleSubmitStatus();
        }
      };
      const handleSubmitCancel = () => {
        this.skipNext(50);
        this.handleSubmitStatus();
        return;
      };

      if (this.checkReasonPass()) {
        if (type === 'complete') {
          const { qtyPlan, qtyScan } = this.billDetail;
          const restTaskNum = qtyPlan - qtyScan;
          await this.$hint.showAlert(
            `${this.$i18n.t('message.restOfTask')}(${restTaskNum})${this.$i18n.t('message.autoCancelStart')}`,
            handleSubmitConfirm,
            handleSubmitCancel
          );
        } else {
          handleSubmitConfirm();
        }
      } else {
        this.$hint.showFailed(this.$t('message.checkRequired'), true);
      }
    },

    async getAppID() {
      const param = {
        params: {
          menuName: '卸货',
        },
      };
      const res = await getAppId(param);
      this.appId = res && res[0].id;
    },

    // 确认托盘enter事件
    async handleCidEnter() {
      // 确认托盘存在
      try {
        if (this.billDetail.scanCidId) {
          this.$hint.showLoading();
          const data = await checkCidCode({ ...this.billDetail, parentCidFlag: this.parentCidFlag, qtyScan: null });
          // const { content } = data;
          // 校验单行并且允许带出批次、数量
          if (this.parentCidFlag === 1) {
            this.isParentCidDisabled = true;
            this.billDetail.loadCidCode = this.billDetail.scanCidCode;
            this.billDetail.loadCidId = this.billDetail.scanCidId;
            const { qtyScan, qtyPlan } = this.billDetail;
            if (Number(qtyScan) > Number(qtyPlan)) this.uselessButton = true;
          } else {
            this.isParentCidDisabled = false;
          }
          if (data && data.id) {
            this.childContainerList = data.childContainerList;
          }
          if (data && data.scanBatchId && this.parentCidFlag) {
            this.billDetail.scanBatchId = data.scanBatchId;
            this.billDetail.scanBatchCode = data.scanBatchCode;
            this.disabledBatch = true;
          } else {
            this.billDetail.scanBatchId = data.scanBatchId;
            this.billDetail.scanBatchCode = data.scanBatchCode;
            this.disabledBatch = false;
          }
          if (data && data.autoFlag) {
            const { batchCode, batchId, qtyScan, stockSerialList } = data;
            // this.isBatch = true;
            if (this.isBatch) this.display.batchCode = true;
            if (!this.$common.isEmpty(this.billDetail.batchCode)) this.display.suggestBatchCode = true;
            this.billDetail.scanBatchCode = batchCode;
            this.billDetail.scanBatchId = batchId;
            this.billDetail.qtyScan = qtyScan;
            // 判断是否序列号控制物料
            if (this.enableSerial) {
              this.serialNumberDtoList = stockSerialList ? stockSerialList : [];
            }
            if (!this.isParentCidDisabled) {
              this.handleBatchSkip();
              return;
            }
          }
          if (this.isBatch && !this.disabledBatch) {
            this.display.batchCode = true;
            if (!this.$common.isEmpty(this.billDetail.batchCode)) this.display.suggestBatchCode = true;
            this.$nextTick(() => {
              this.skipNext(40);
            });
          } else {
            this.handleBatchSkip();
          }

          if (this.parentCidFlag && !this.enableSerial) {
            await this.focusQtyNumber();
            await this.handleQtyScan();
          }
        }
        if (this.isBatch && !this.disabledBatch) {
          this.display.batchCode = true;
          if (!this.$common.isEmpty(this.billDetail.batchCode)) this.display.suggestBatchCode = true;
          this.$nextTick(() => {
            this.skipNext(40);
          });
        } else {
          this.handleBatchSkip();
        }
        if (this.billDetail.scanCidCode && this.parentCidFlag) {
          this.checkException();
        }
      } catch {
        this.skipNext(30);
      } finally {
        this.$hint.hideLoading();
      }
    },
    dataInit(refreshFlag) {
      this.skipTo70 = false;
      this.skipTo80 = false;
      this.disabledBatch = false;
      this.isParentCidDisabled = false;
      this.billDetail.scanCidCode = '';
      this.billDetail.scanBatchCode = '';
      this.billDetail.scanBatchId = '';
      this.billDetail.qtyScan = '';
      this.billDetail.loadCidCode = '';
      this.billDetail.replaceReasonMeaning = '';
      this.billDetail.exceptionReasonMeaning = '';
      this.replaceFlag = false;
      this.exceptionFlag = false;
      this.serialNumberDtoList = [];
      this.display = {
        locationCode: !this.$common.isEmpty(this.billDetail.locationCode),
        cidCode: false,
        batchCode: this.isBatch,
        suggestBatchCode: false,
        replaceReason: false,
        exceptionReason: false,
        toCidCode: false,
      };
      const { scanLocationCode, scanLocationId, taskNumbers } = this.previousBillDetail;

      if (refreshFlag) {
        this.scanSku = '';
        this.containerManageFlag = false;
        this.display.batchCode = false;
        this.scanWhareaCode = '';
        this.billDetail.scanLocationCode = '';
        this.billDetail.taskNumbers = taskNumbers ? Number(taskNumbers) - 1 : '';
        this.$nextTick(() => {
          this.skipNext(0);
        });
      } else {
        this.billDetail.scanLocationCode = scanLocationCode;
        this.billDetail.scanLocationId = scanLocationId;
        this.billDetail.taskNumbers = taskNumbers;

        // 部分执行之后, 光标定位判断是否启用托盘管理、是否批次控制
        if (this.containerManageFlag) {
          this.skipNext(30);
        } else if (this.isBatch) {
          this.skipNext(40);
        } else {
          this.skipNext(50);
        }
      }
    },
    async reHandle(data, refreshFlag) {
      this.previousBillDetail = this.billDetail;
      this.billDetail = data;
      this.enableSerial = this.isShowSerialButton(this.billDetail);
      await this.clearSourceFields(this, true);
      // this.msg =  `<p style="line-height:25px;font-weight:bold">${ this.billDetail.billCode }</p>
      // <p style="line-height:25px;font-weight:bold">${ this.billDetail.taskCode }</p>`
      this.dataInit(refreshFlag);
    },

    // 转运托盘选中后调用
    async checkCid() {
      try {
        this.$hint.showLoading();
        const res = await checkLoadCidCode(this.billDetail);
        if (res && res.type === 'warn') {
          this.$hint.showAlert(
            res.desc,
            () => {},
            () => this.skipNext(60)
          );
        }
      } catch (e) {
        this.skipNext(60);
      } finally {
        this.$hint.hideLoading();
      }
    },
    checkReplace(def, current) {
      // 判断如果来源托盘不存在, 则无需替换原因
      // console.log(type, 'replaceFlag', this.$common.isEmpty(def) ? false : def !== current);
      this.replaceFlag = this.$common.isEmpty(def) ? false : def !== current;
    },
    checkException() {
      const { qtyScan, qtyPlan } = this.billDetail;
      this.exceptionFlag = qtyScan < qtyPlan;
    },
    // 转运托盘enter事件
    handleLoadEnter() {
      this.skipTo70 = this.customData['replaceReasonMeaning'] // 判断个性化是否启用
        ? // 启用时判断个性化是否隐藏，隐藏直接不显示，不隐藏直接按页面参数设置判断
          this.hideControl['replaceReasonMeaning']
          ? this.rpReasonRequired
            ? this.replaceFlag
              ? true
              : false
            : false
          : false
        : // 个性化不启用时直接按页面参数设置判断
        this.rpReasonRequired
        ? this.replaceFlag
          ? true
          : false
        : false;

      this.skipTo80 = this.customData['exceptionReasonMeaning'] // 判断个性化是否启用
        ? // 启用时判断个性化是否隐藏，隐藏直接不显示，不隐藏直接按页面参数设置判断
          this.hideControl['exceptionReasonMeaning']
          ? this.ecReasonRequired
            ? this.exceptionFlag
              ? true
              : false
            : false
          : false
        : // 个性化不启用时直接按页面参数设置判断
        this.ecReasonRequired
        ? this.exceptionFlag
          ? true
          : false
        : false;
      console.log(this.skipTo70);
      if (this.skipTo70) {
        this.display.replaceReason = true;
        this.$nextTick(() => {
          this.skipNext(70);
        });
      } else if (this.skipTo80) {
        this.display.exceptionReason = true;
        this.$nextTick(() => {
          this.skipNext(80);
        });
      } else {
        this.skipNext('submitRef');
      }
    },
    // 替换原因enter事件
    handleReplaceEnter() {
      if (this.skipTo80) {
        this.display.exceptionReason = true;
        this.$nextTick(() => {
          this.skipNext(80);
        });
      } else {
        this.skipNext('submitRef');
      }
    },
    // 确认托盘change事件
    handleCidChange() {
      this.clearReason();
      this.qtyPlan = -1;
      this.billDetail.scanCidId = '';
    },
    // change事件，只要有更改，就直接清空原因
    handleChange() {
      this.clearReason();
    },
    // 本次扫描数量聚焦时校验可用量
    async focusQtyNumber() {
      try {
        this.showQtyDialog = false;
        this.uselessButton = false;
        // qtyScan扫描数量手动传参置空
        const data = await checkQtyNumber({ ...this.billDetail, qtyScan: null });
        // const { content } = data;
        // 校验单行并且允许带出批次、数量
        if (data) {
          this.showQtyDialog = true;
          const { validQty, qty } = data;
          // this.isBatch = true;
          this.validQty = validQty;
          this.qty = qty;
          // this.$nextTick(() => {
          //   this.validQty = validQty;
          //   this.qty= qty;
          // });
        }
      } catch (err) {
        console.log(err);
      }
    },
    // 序列号状态下, 更改扫描数量
    changeQtyScan() {
      const { qtyScan } = this.billDetail;
      this.qtyChanged = true;
      if (Number(qtyScan) < this.serialNumberDtoList.length) this.serialNumberDtoList = [];
      // this.skipNext('inputRef');
      this.clearReason();
    },
    // 确认库位处理Enter
    handleLocationEnter() {
      if (this.containerManageFlag) {
        if (!this.$common.isEmpty(this.billDetail.cidCode)) this.display.cidCode = true;
        this.skipNext(30);
      } else if (this.isBatch) {
        this.display.batchCode = true;
        if (!this.$common.isEmpty(this.billDetail.batchCode)) this.display.suggestBatchCode = true;
        this.skipNext(40);
      } else {
        this.skipNext(50);
      }
    },
    // 扫描数量entter处理
    async handleQtyScan() {
      if (this.billDetail.qtyScan <= 0) {
        this.$hint.showFailed(this.$i18n.t('message.quantityMin'), true);
      } else {
        try {
          if (this.billDetail.qtyScan) {
            let data = {
              ...this.billDetail,
              parentCidFlag: this.parentCidFlag,
              childContainerList: this.childContainerList,
            };
            // 优先校验输入数量是否满足可用量
            const { qtyScan } = this.billDetail;
            if (qtyScan && qtyScan > this.validQty) {
              this.$hint.showFailed(this.$i18n.t('message.qtyNeedLessAvailable'), true);
              this.skipNext(50);
              this.$hint.hideLoading();
              return;
            }
            this.$hint.showLoading();
            const res = await checkScanQty(data);
            this.checkException();
            if (res) {
              this.billDetail.loadCidCode = this.billDetail.scanCidCode;
              this.billDetail.loadCidId = this.billDetail.scanCidId;
            }
          }
        } catch (e) {
          this.skipNext(50);
        } finally {
          this.$hint.hideLoading();
        }
        const { qtyScan, qtyPlan } = this.billDetail;
        // 序列号模式需要判断录入数量关系
        if (this.enableSerial && this.serialNumberDtoList && Number(qtyScan) !== this.serialNumberDtoList.length) {
          if (!this.serialNumberDtoList.length === 0) this.$hint.showFailed(this.$i18n.t('message.checkQtyScanWithSerialNumber'), true);
          this.goToSerial();
          return;
        }
        this.display.toCidCode = true;
        if (Number(qtyScan) > Number(qtyPlan)) this.uselessButton = true;
        this.$nextTick(() => {
          this.skipNext(60);
        });
      }
    },
  },
  watch: {
    'billDetail.isbatch': function (val) {
      this.isBatch = val === 1;
    },
    replaceFlag: function (val) {
      if (!val) {
        this.billDetail.replaceReasonMeaning = '';
        this.billDetail.replaceReason = '';
      }
    },
    exceptionFlag: function (val) {
      if (!val) {
        this.billDetail.exceptionReasonMeaning = '';
        this.billDetail.exceptionReason = '';
      }
    },
    'billDetail.scanCidCode': function (val, oldval) {
      if (val !== oldval) {
        this.billDetail.loadCidCode = '';
        this.billDetail.loadCidId = '';
        this.disabledBatch = false;
        this.billDetail.scanBatchId = '';
        this.billDetail.scanBatchCode = '';
        this.billDetail.qtyScan = '';
        this.serialNumberDtoList = [];
        this.isParentCidDisabled = false;
        this.showQtyDialog = false;
      }
      const { locationCode, batchCode, scanBatchCode, cidCode, scanLocationCode, cidId } = this.billDetail;
      if (val && cidId) {
        if (scanLocationCode !== locationCode || (batchCode && scanBatchCode && batchCode !== scanBatchCode) || (val && cidCode !== val)) {
          this.replaceFlag = true;
        } else {
          this.replaceFlag = false;
        }
      } else if (val && !cidId) {
        if (scanLocationCode !== locationCode || (batchCode && scanBatchCode && batchCode !== scanBatchCode)) {
          this.replaceFlag = true;
        } else {
          this.replaceFlag = false;
        }
      }
    },
    'billDetail.scanLocationCode': function (val) {
      const { locationCode, batchCode, scanBatchCode, cidCode, scanCidCode, cidId } = this.billDetail;
      if (val && cidId) {
        if (val !== locationCode || (batchCode && scanBatchCode && batchCode !== scanBatchCode) || cidCode !== scanCidCode) {
          this.replaceFlag = true;
        } else {
          this.replaceFlag = false;
        }
      } else if (val && !cidId) {
        if (val !== locationCode || (batchCode && scanBatchCode && batchCode !== scanBatchCode)) {
          this.replaceFlag = true;
        } else {
          this.replaceFlag = false;
        }
      }
    },
    'billDetail.scanBatchCode': function (val) {
      const { locationCode, batchCode, scanLocationCode, cidCode, scanCidCode, cidId } = this.billDetail;
      if (val && cidId) {
        if (scanLocationCode !== locationCode || (batchCode && val && batchCode !== val) || cidCode !== scanCidCode) {
          this.replaceFlag = true;
        } else {
          this.replaceFlag = false;
        }
      } else if (val && !cidId) {
        if (scanLocationCode !== locationCode || (batchCode && val && batchCode !== val)) {
          this.replaceFlag = true;
        } else {
          this.replaceFlag = false;
        }
      }
    },
  },
  created() {
    this.getDataFromParam();
    // this.msg =  `<p style="line-height:25px;font-weight:bold">${ this.billDetail.billCode }</p>
    //   <p style="line-height:25px;font-weight:bold">${ this.billDetail.taskCode }</p>`
  },
  async mounted() {
    this.inputFieldList = [
      { type: 'text', required: false, bind: 'billDetail.sku', field: 'sku' },
      { type: 'text', required: false, bind: 'billDetail.goodsName', field: 'goodsName' },
      { type: 'text', required: false, bind: 'billDetail.uomCode', field: 'uomCode' },
      { type: 'text', required: false, bind: 'billDetail.whareaCode', field: 'whareaCode' },
      { type: 'text', required: false, bind: 'billDetail.locationCode', field: 'locationCode' },
      { type: 'text', required: false, bind: 'billDetail.cidCode', field: 'cidCode' },
      { type: 'text', required: false, bind: 'billDetail.batchCode', field: 'batchCode' },
      { type: 'text', required: false, bind: 'billDetail.qtyPlan', field: 'qtyPlan' },
      { type: 'input', required: true, ref: 0, bind: 'scanSku', field: 'scanSku' },
      { type: 'input', required: true, ref: 10, bind: 'scanWhareaCode', field: 'scanWhareaCode' },
      { type: 'lov', required: true, ref: 20, bind: 'billDetail.scanLocationCode', field: 'scanLocationCode' },
      { type: 'lov', required: false, ref: 30, bind: 'billDetail.scanCidCode', field: 'scanCidCode' },
      { type: 'lov', required: true, ref: 40, bind: 'billDetail.scanBatchCode', isHide: !this.isBatch, field: 'scanBatchCode' },
      { type: 'number', required: true, ref: 50, bind: 'billDetail.qtyScan', field: 'qtyScan' },
      { type: 'lov', required: true, ref: 60, bind: 'billDetail.loadCidCode', field: 'loadCidCode' },
      {
        type: 'lov',
        required: this.rpReasonRequired,
        ref: 70,
        bind: 'billDetail.replaceReasonMeaning',
        isHide: !this.replaceFlag,
        field: 'replaceReasonMeaning',
      },
      {
        type: 'lov',
        required: this.ecReasonRequired,
        ref: 80,
        bind: 'billDetail.exceptionReasonMeaning',
        isHide: !this.exceptionFlag,
        field: 'exceptionReasonMeaning',
      },
    ];
    this.urlData = this.handleData('TASK_EXECUTION_CONFIRM');
    this.skipNext(0);
  },
  activated() {
    const totalSerialNum = JSON.parse(sessionStorage.getItem('totalSerialNum'));
    const serialNumberDtoList = JSON.parse(sessionStorage.getItem('serialNumberDtoList'));
    if (totalSerialNum) {
      this.billDetail.qtyScan = totalSerialNum;
      this.exceptionFlag = this.billDetail.qtyScan < this.billDetail.qtyPlan;
      this.serialNumberDtoList = serialNumberDtoList;
      sessionStorage.removeItem('totalSerialNum');
      sessionStorage.removeItem('serialNumberDtoList');
    }
  },

  async beforeRouteLeave(to, from, next) {
    if (to.name === 'TaskExecution') {
      try {
        this.$hint.showLoading();
        const data = {};
        await clearTask(data);
        this.$store.commit('deleteKeepAlives', 'ExecutionConfirm');
      } finally {
        this.$hint.hideLoading();
      }
    } else if (to.name === 'SerialExecuton') {
      this.$store.commit('pushKeepAlives', 'IpsNewSerial');
    } else if (to.name === 'serialComponent') {
      this.$store.commit('pushKeepAlives', 'ExecutionConfirm');
    }
    this.$nextTick(() => {
      next();
    });
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const _this = vm;
      if (from.name === 'SerialExecution') {
        // 从序列号界面返回, 默认定位在数量字段
        _this.skipNext(50);
      }
    });
  },
};
</script>

<style lang="stylus" scoped>
 @import '~@/style/var.styl'
.input-button {
  margin 8px 0
  .hips-button--primary {
    height 35px
    line-height 30px
  }
}

.card-type {
  margin-left: 10px;
  margin-right: 10px;
}

.card-warp-box {
  border-radius: 1.6vw;
  padding: 3.5vw .4vw 2.7vw;
  -webkit-box-shadow: 0 0 2.133vw #d9d9d9;
  box-shadow: 0 0 0.133vw #d9d9d9;
  padding-top: 15px;
}

.container-warp-box {
  margin-top: 10px;
  border-radius: 1.6vw;
  padding: 3.4vw 2.7vw !important;
  margin-top: 10px;
}

.ips-footer-more {
  width: 100%;
  min-height: 40.56px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  .hips-button {
    padding: 0 10px 0px 10px;
    border: 1px solid var(--primaryColor);
    padding: 0 4vw 2.667vw;
    color: var(--primaryColor);
  }
  .hips-button:focus {
    background-color: var(--primaryColor);
    color: #fff;
  }
}

.qty-card {
  display: flex;
  justify-content: space-between;
  background-color: #E7F7FF;
  padding: 6px 10px;
  color: #70C3FD;
  border-color: #B0E7FF;
  margin-top: 5px;
  .text {
    width: 50%;
  }
}

.card-title {
  color: #8c8c8c;
}
</style>
