import request from '@/request';
import mixin  from '@/mixin/mixin';


function transformLocales(messages) {
  if (!messages) {
    return messages
  }
  const msgKeys = Object.keys(messages) || []
  return msgKeys.reduce((totalize, key) => {
    const msgVal = messages[key]
    if (msgVal) {
      const k = key.replace(/^pwms.subapplication./, '')
      totalize[k] = msgVal
    }
    return totalize
  }, {})
}

export function queryApplicationLocales(lang = 'zh_CN') {
  let url = `${mixin.data().hpfmBaseUrl}prompt/${lang}`;
  return request.get(url, {
    params: {
      promptKey: 'pwms.subapplication'
    }
  }).then((res) => {
    // 错误处理
    if (res && res.failed) {
      return {}
    }
    return transformLocales(res)
  })
}