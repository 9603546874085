<template>
  <div style="margin:18px 0">
    <hips-row>
      <hips-col span="14">
        <ips-field
          v-model="serialNum"
          v-show="showInputBox"
          is-only
          :is-index="false"
          :label="$t('label.serialNumber')"
          ref="ref58"
          @click.stop="handleCommonClick(58)"
          @enter="checkSerialNum"
          @blur="checkSerialNum"
        />
      </hips-col>
      <hips-col span="8">
        <hips-button
          size="large"
          @click.stop="showTable = true"
          class="ips-new-serial-button-style"
        >
          查看明细({{ tableData.length }})
        </hips-button>
      </hips-col>
    </hips-row>
    <hips-popup
      v-model="showTable"
      position="bottom"
    >
      <div class="ips-table">
        <table>
          <tr style="background-color: #eee">
            <th
              class="ips-table-thead"
              v-for="(item, index) in tableTitle"
              :key="index"
              scope="col"
            >
              {{ item.name }}
            </th>
          </tr>
          <tr
            v-for="(item, index) in tableData"
            :key="index"
            :ref="`tr${index}`"
            :class="[item.selectedFlag ? 'back-green' : '']"
            @click="handleSelect(item, index)"
          >
            <td>{{ item.serialNumber }}</td>
            <td style="position:relative">
              {{ item.qualityStatusName }}
              <div
                v-if="item.handInputFlag"
                @click.stop="deleteTableData(index)"
                class="delIcon"
              >
                <svg-icon
                  slot="icon"
                  icon-class="delete"
                />
              </div>
            </td>
          </tr>
        </table>
      </div>
    </hips-popup>
    <div
      class="ips-footer"
      v-if="false"
    >
      <hips-button
        size="large"
        ref="submitRef"
        @click="handleButtonSubmit"
      >
        <svg-icon
          slot="icon"
          icon-class="success"
        />
        {{ $t('button.confirmSNDetail') }}
      </hips-button>
    </div>
  </div>
</template>

<script>
import { Switch } from '@hips/vue-ui';
export default {
  name: 'IpsSerial',
  components: {
    [Switch.name]: Switch,
  },
  props: {
    params: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      showTable: false,
      totalSelectedNum: 0,
      showInputBox: true,
      serialNum: '',
      isSelectedAll: false, //是否全部选中
      classValue: '',
      tableData: [],
      tableTitle: [],
      currentIndex: '',
      inputFieldList: [{ type: 'input', required: false, ref: 58, bind: 'serialNum' }],
    };
  },
  computed: {
    task() {
      return this.params.task
    }
  },

  mounted() {
    this.tableTitle = [
      {
        name: this.$t('label.serialNumber'), // 序列号
      },
      {
        name: this.$t('label.qualityStatus'), // 质量状态
      },
    ];
    // 默认展示输入框
    const { task, currentIndex, showInputBox = true } = this.params;
    // this.task = task;
    this.showInputBox = showInputBox;
    // fromUnLoading || fromPutaway
    // 不展示输入框的情况
    if (!showInputBox) {
      this.currentIndex = currentIndex;
      // 卸货过来时 tableData = task.outboundSerialRecordList
      this.tableData = task.outboundSerialRecordList || task;
      for (let item of this.tableData) {
        item.selectedFlag = true;
      }
      this.totalSelectedNum = this.tableData.length;
      this.isSelectedAll = this.totalSelectedNum !== 0; // 默认全选
    } else {
      this.getSerialList();
    }
    this.skipNext(58);
  },

  activated() {
    //与卸货页面交互时，当前页面会被缓存 需要以下逻辑重新渲染数据
    const { task, fromUnLoading, currentIndex } = this.params;
    const indexNotSame = currentIndex !== this.currentIndex;
    if (fromUnLoading && indexNotSame) {
      this.currentIndex = currentIndex;
      this.tableData = task.outboundSerialRecordList;
      for (let item of this.tableData) {
        item.selectedFlag = true;
      }
      this.totalSelectedNum = this.tableData.length;
      this.isSelectedAll = this.totalSelectedNum !== 0; // 卸货过来默认全选
    }
    this.skipNext(58);
  },

  methods: {
    /**
     * 查询默认序列号
    */
    async getSerialList() {
      try {
        this.$hint.showLoading();
        const { serialNumberDtoList } = this.params;
        const data = {
          ...this.task,
          serialNumberDtoList,
        };
        const url = `${this.winvBaseUrl}app-outbound-load/query-init-serial`;
        const res = await this.$http.post(url, data);
        res.map((item) => {
          if (item.isChecked) {
            item.selectedFlag = true;
            this.totalSelectedNum++;
          }
        })
        const index = res.length && res.findIndex((item) => {
          return item.selectedFlag !== true;
        });
        this.isSelectedAll = index === -1;
        this.tableData = res;
      } finally {
        this.$hint.hideLoading();
      }
    },

    /**
     * 录入序列号
    */
    async checkSerialNum() {
      if (this.serialNum) {
        const index = this.tableData.findIndex((item) => {
          return item.serialNumber === this.serialNum;
        });
        if (index > -1) {
          this.$hint.showFailed(this.$t('message.dataDuplication'), true);
          this.skipNext(58);
        } else {
          try {
            this.$hint.showLoading();
            const url = `${this.winvBaseUrl}app-outbound-load/check-serial-sernumber`;
            const data = {
              ...this.task,
              serialNumberDtoList: [{ serialNumber: this.serialNum }]
            };
            const res = await this.$http.post(url, data);
            this.tableData.unshift({ ...res[0], selectedFlag: true, handInputFlag: true });
            this.serialNum = '';
            this.totalSelectedNum++;
            const index = this.tableData.findIndex((item) => {
              return item.selectedFlag !== true;
            });
            this.isSelectedAll = index === -1;
            this.$emit('serialNumberList', this.tableData)
          } catch {
            this.skipNext(58);
          } finally {
            this.$hint.hideLoading();
          }
        }
      } else {
        if (this.tableData && this.tableData.length > 0) {
          this.$emit('skipNextField')
        }
      }
    },

    /**
     * 删除行
    */
    deleteTableData(index) {
      console.log(index);
      this.tableData.splice(index, 1);
      this.totalSelectedNum--;
      if (!this.totalSelectedNum) {
        this.isSelectedAll = false;
      }
    },

    /**
     * 全选
    */
    selectAll() {
      for (const index in this.tableData) {
        this.$refs[`tr${index}`][0].className = this.isSelectedAll ? 'back-green' : '';
        this.tableData[index].selectedFlag = this.isSelectedAll;
      }
      this.totalSelectedNum = this.isSelectedAll ? this.tableData.length : 0;
    },

    // 单选或取消操作
    handleSelect(item, index) {
      item.selectedFlag = !item.selectedFlag;
      this.$refs[`tr${index}`][0].className = item.selectedFlag ? 'back-green' : '';
      this.totalSelectedNum = item.selectedFlag ? this.totalSelectedNum + 1 : this.totalSelectedNum - 1;
      const indexFlag = this.tableData.findIndex((item) => {
        return item.selectedFlag !== true;
      });
      this.isSelectedAll = indexFlag === -1;
    },

    // 确认序列号明细
    async handleButtonSubmit() {
      let serialNumberDtoList = [];
      this.tableData.map((item) => {
        if (item.selectedFlag) {
          serialNumberDtoList.push(item);
        }
      });
      if (serialNumberDtoList.length) {
        // fromUnLoading, fromPutaway, fromReceiveAdjust
        // 不展示输入框的情况 ，纯展示，不输入
        if (!this.showInputBox) {
          sessionStorage.setItem('totalSerialNum', JSON.stringify(this.totalSelectedNum));
          sessionStorage.setItem('serialNumberDtoList', JSON.stringify(serialNumberDtoList));
          this.$store.commit('pushKeepAlives', 'IpsSerial'); // 返回卸货页面时 缓存当前页面
          this.$router.backPage();
        } else {
          try {
            this.$hint.showLoading();
            const { pageName } = this.$route.params;
            let url;
            if (pageName && pageName === 'dierctOutbound') {
              url = `${this.winvBaseUrl}app-direct-pick/confirm-serial-sernumber`;
            } else {
              url = `${this.winvBaseUrl}app-outbound-load/check-serial-sernumber`;
            }
            const data = {
              ...this.task,
              serialNumberDtoList,
            };
            const res = await this.$http.post(url, data);
            sessionStorage.setItem('totalSerialNum', JSON.stringify(this.totalSelectedNum));
            sessionStorage.setItem('serialNumberDtoList', JSON.stringify(res));
            this.$router.backPage();
          } finally {
            this.$hint.hideLoading();
            this.handleSubmitStatus();
          }
        }
      } else {
        this.$hint.showFailed(this.$t('message.mustChooseSerial'), true);
      }
    },
  },

};
</script>

<style lang="stylus" scoped>
@import '~@/style/var.styl';

.center_area {
  line-height: 36px;
  margin: 0 10px 5px;
  overflow: hidden;

  .hips-switch {
    float: right;
  }
}

.ips-table {
  width: 100%;

  table {
    width: 100%;

    th, td {
      padding: 5px 2px;
      border: 1px solid #D9D9D9;
      text-align: center;
      white-space: nowrap;
      font-size: 14px;
      color: rgba(89, 89, 89, 1);
      line-height: 16px;
    }

    .ips-table-thead {
      width: 180px;
      height: 26px;
      background: rgba(255, 255, 255, 1);
      border-radius: 12px 0px 0px 0px;
      line-height: 26px;
      font-weight: bold;
      font-size: 14px;
      padding-left: 12px;
    }

    .back-green {
      background-color: #cbdeb8;
    }
  }
}

.delIcon {
  position: absolute;
  z-index: 999;
  right: 10px;
  top: 5px;
}

.ips-new-serial-button-style {
  margin-left: 10px;
  background-color: var(--primaryColor);
  color: #FFFFFF;
  font-size: 14px;
  height: 36px;
  line-height: 36px;
}
</style>
