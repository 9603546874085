import Unloading from '../views/index'
import UnloadingDetail from '../views/detail.vue'
import UnloadingConfirm from '../views/confirm.vue'

let route = [
  {
    path: '/unloading',
    name: 'unloading',
    component: Unloading,
  },
  {
    path: '/unloadingDetail',
    name: 'unloadingDetail',
    component: UnloadingDetail,
  },
  {
    path: '/unloadingConfirm',
    name: 'unloadingConfirm',
    component: UnloadingConfirm,
  }
]

export default route
