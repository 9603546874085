<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header
        :title="$t('title.subinventoryTransfer')"
        :is-index="false"
      />
    </div>
    <!-- 库区、库位、物料、批次、序列号、托盘 -->
    <hips-row class="ips-search">
      <hips-col span="20">
        <ips-field
          :label="stockQueryField"
          required
          v-show="customData['queryCode'] ? hideControl['queryCode']: true"
          v-model="queryCode"
          :params="dynamicUrlParam"
          :display="dynamicUrlParam"
          :pre-parse-url="dynamicUrl"
          :pre-parse-title="preParseTitle"
          :pre-params="preParams"
          :custom="customData['queryCode']"
          :source-parse-field="sourceParseField"
          ref="ref0"
          @enter="handleButtonQuery"
          @setSourceField="saveSourceField($event)"
          @valuedByFoucs="valuedByFoucs($event)"
        />
      </hips-col>
      <hips-col
        span="4"
        class="ips-row-left"
      >
        <hips-button
          type="warning"
          @click.stop="handleButtonQuery"
          @keyup.enter.native="handleButtonQuery"
          @keypress.native.prevent
          ref="submitRef"
        >
          {{ $t('button.query') }}
        </hips-button>
      </hips-col>
    </hips-row>
    <div class="ips-container">
      <!--库区卡片-->
      <div v-if="queryTag === 'WHAREA_TAG' && stockData.length>=1">
        <div
          class="card_wharea"
          v-for="(item,index) in stockData"
          :key="index"
          :ref="`ref${index*10+10}`"
          @click="toWhareaSkuDetail(item)"
        >
          <div class="row">
            <div class="title">
              {{ $t('label.warehouse') }}:
            </div>
            <div class="label">
              {{ item.warehouseName }}
            </div>
          </div>
          <div class="row">
            <div class="title">
              {{ $t('label.whareaCode') }}:
            </div>
            <div class="label">
              {{ item.whareaCode }}
            </div>
          </div>
        </div>
      </div>
      <!--库位卡片-->
      <div v-if="queryTag === 'LOCATION_TAG' && stockData.length>=1">
        <div
          class="card_countLocation"
          v-for="(item,index) in stockData"
          :key="index"
          :ref="`ref${index*10+10}`"
          @click="toLocationSkuDetail(item)"
        >
          <div class="row">
            <div class="title">
              {{ $t('label.warehouse') }}:
            </div>
            <div class="label">
              {{ item.warehouseName }}
            </div>
          </div>
          <div class="row">
            <div class="title">
              {{ $t('label.whareaCode') }}:
            </div>
            <div class="label">
              {{ item.whareaCode }}
            </div>
          </div>
          <div class="row">
            <div class="title">
              {{ $t('label.countLocation') }}:
            </div>
            <div class="label">
              {{ item.locationCode }}
            </div>
          </div>
        </div>

      </div>
      <!--物料卡片-->
      <div v-if="queryTag === 'GOODS_TAG' && stockData.length>=1">
        <div
          class="card_countLocation"
          v-for="(item,index) in stockData"
          :key="index"
          :ref="`ref${index*10+10}`"
          @click="toSkuTransfer(item)"
        >
          <div class="row">
            <div class="title">
              {{ $t('label.org') }}:
            </div>
            <div class="label">
              {{ item.orgName }}
            </div>
          </div>
          <div class="row">
            <div class="title">
              {{ $t('label.ownerCode') }}:
            </div>
            <div class="label">
              {{ item.ownerName }}
            </div>
          </div>
          <div class="row">
            <div class="title">
              {{ $t('label.sku') }}:
            </div>
            <div class="label">
              {{ item.goodsName }}
            </div>
          </div>
        </div>

      </div>
      <!--序列号卡片-->
      <div v-if="queryTag === 'SERIAL_NUMBER_TAG' && stockData.length>=1">
        <div
          class="card_wharea"
          v-for="(item,index) in stockData"
          :key="index"
          :ref="`ref${index*10+10}`"
          @click="toSerialDetail(item)"
        >
          <div class="row">
            <div class="title">
              {{ $t('label.org') }}:
            </div>
            <div class="label">
              {{ item.orgName }}
            </div>
          </div>
          <div class="row">
            <div class="title">
              {{ $t('label.serialNumber') }}:
            </div>
            <div class="label">
              {{ item.serialNumber }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </hips-view>
</template>

<script>
import { getSubinventory } from '../api';
import { Row, Col } from '@hips/vue-ui';
import { storage } from '@/utils';
export default {
  name: 'SubinventoryQueryDetail',
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
  },
  data() {
    return {
      queryCode: '', // 库区、库位、物料、批次、序列号、托盘
      queryTag: '',
      dynamicUrl: '',
      dynamicUrlParam: '',
      stockData: '', // 查询出的数据
      stockQueryField: '', // 默认数据
      routeParam: '', // 跳转参数
      urlData: {},
      customData: {}, // 个性化配置信息列表
      hideControl: {}, // 个性化配置显示隐藏控制
      sourceParseField: {}, // 界面中所有的来源解析字段
      preParseTitle: undefined,
      preParams: undefined,
    };
  },
  methods: {
    // params传参
    async getParamsData() {
      const { flag } = this.$route.params;
      this.queryTag = flag;
      switch (flag) {
        case 'GOODS_TAG':
          this.stockQueryField = this.$t('label.sku');
          this.urlData = await this.handleData('STOCK_TRANSFER_GOODS_QUERY');
          this.dynamicUrl = this.urlData['sku'];
          this.dynamicUrlParam = 'sku';
          this.preParseTitle = [this.$t('label.sku'), this.$t('title.name'), this.$t('label.ownerCode')]
          this.preParams = ['mainValue', 'mainName', 'ownerCode']
          break;
        case 'SERIAL_NUMBER_TAG':
          this.stockQueryField = this.$t('label.serialNumber');
          this.urlData = this.handleData('STOCK_TRANSFER_SERIAL_QUERY');
          break;
        case 'CONTAINER_TAG':
          this.stockQueryField = this.$t('label.tray');
          this.urlData = this.handleData('STOCK_TRANSFER_PALLET_QUERY');
          break;
        default:
          return null;
      }
    },

    // 查询
    async handleButtonQuery() {
      let _this = this;
      if (!_this.queryCode) {
        _this.$hint.showFailed(_this.$i18n.t('message.queryCodeNotNull'), true);
        return;
      }
      const data = {
        params: {
          queryTag: _this.queryTag,
          queryCode: _this.queryCode,
        }
      };
      _this.$hint.showLoading();
      getSubinventory(data, this.urlData.queryCode).then((res) => {
        console.log('res&&&&&&&&&&&&&&&&&123', res.objTag);
        _this.routePage(res.objTag, res);
      }).finally(() => {
        this.$hint.hideLoading();
      })
    },
    /**
     *@functionName: saveSourceField
     *@description: 保存来源解析字段并赋值Enter
     *@params1: fieldObj[Object] 来源解析字段对象,
     fieldCode[String] 来源解析字段
     parseValue[String] 二维码解析值
     subFields[Array]] 关联子字段
     */
    saveSourceField(fieldObj) {
      this.saveSourceParseField(this, fieldObj);
    },
    /**
     * 字段聚焦时赋值操作
     */
    async valuedByFoucs(options = {}) {
      const { custom = {}, currentValue } = options;
      await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
    },
    // 跳转
    routePage(flag, res) {
      let _this = this;
      switch (flag) {
        case 'GOODS_TAG':
          _this.stockData = res.contentDTO.goodsList;
          if (_this.stockData.length === 1) {
            _this.toSkuTransfer(_this.stockData[0]);
          }
          break;
        case 'SERIAL_NUMBER_TAG':
          _this.stockData = res.contentDTO.serialNumberList;
          if (_this.stockData.length === 1) {
            _this.toSerialDetail(_this.stockData[0]);
          }
          break;
        case 'CONTAINER_TAG':
          _this.stockData = res.contentDTO.containerList;
          if (_this.stockData.length === 1) {
            _this.toContainerDetail(_this.stockData[0]);
          }
          break;
        default:
          return null;
      }
      if (_this.stockData.length === 1) {
        console.log("跳转")
      }
    },
    //跳转至物料-仓库页面
    toSkuTransfer(item) {
      let param = {
        name: 'SkuTransfer',
        params: {
          item: item,
          sourceParseField: { ...this.sourceParseField },
          customData: this.customData,
          hideControl: this.hideControl,
        },
      };
      this.$router.pushPage(param);
    },
    //跳转至序列号-明细页面
    toSerialDetail(item) {
      let param = {
        name: 'SerialTransfer',
        params: {
          item: item,
          sourceParseField: { ...this.sourceParseField },
          customData: this.customData,
          hideControl: this.hideControl,
        },
      };
      this.$router.pushPage(param);
    },
    //跳转至托盘-明细页面
    toContainerDetail(item) {
      let param = {
        name: 'CidTransfer',
        params: {
          item: item,
          sourceParseField: { ...this.sourceParseField },
          customData: this.customData,
          hideControl: this.hideControl,
        },
      };
      this.$router.pushPage(param);
    },
  },
  created() {
    const { customObj, hideControl } = this.handleCustomData(storage.get('customData'));
    this.customData = customObj;
    this.hideControl = hideControl;
    // console.log('子库存转移customData', Object.assign({}, this.customData));
  },
  async mounted() {
    this.inputFieldList = [
      { type: 'input', required: true, ref: 0, bind: 'queryCode', field: 'queryCode' },
    ];
    this.getParamsData();
    this.skipNext(0);
  },

  activated() {
    this.skipNext(0);
  },

  deactivated() {
    this.queryCode = '';
    this.stockData = '';
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'SubinventoryQuery') {
      this.$store.commit('pushKeepAlives', 'SubinventoryQueryDetail');
    } else {
      this.$store.commit('deleteKeepAlives', 'SubinventoryQueryDetail');
    }
    next();
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      console.log(from)
      if (from.name === 'SkuTransfer') {
        vm.$route.params.flag = 'GOODS_TAG'
      }
    });
  },

};
</script>

<style lang="stylus" scoped>
@import '~@/style/var.styl';

.ips-search {
  display: flex;
  padding: 4.27vw 2vw 0 2vw;

  .ips-row-left {
    float: right;
    text-align: right;
  }
}

.ips-container {
  padding: 0 0 16vw !important;

  .ips-row {
    width: 66%;
    margin: 24px 50px;

    .ips-col {
      text-align: center;
      font-size: 14px;

      a {
        color: var(--primaryColor);
      }
    }
  }

  .card_wharea {
    margin: 10px 0;
    height: 76px;
    color: #000;
    box-shadow: 0 0 2.133vw #d9d9d9;
    border: none;
    font-size: 14px;

    .row {
      display: flex;
      padding: 14px 12px 0 12px;

      .title {
        width: 63px;
        height: 18px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 200;
        color: #8C8C8C;
        line-height: 18px;
      }

      .label {
        width: 289px;
        height: 18px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: initial;
        color: #000000;
        line-height: 18px;
      }
    }
  }

  .card_countLocation {
    margin: 10px 0;
    height: 114px;
    color: #000;
    box-shadow: 0 0 2.133vw #d9d9d9;
    border: none;
    font-size: 14px;

    .row {
      display: flex;
      padding: 14px 12px 0 12px;

      .title {
        width: 42px;
        height: 18px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 200;
        color: #8C8C8C;
        line-height: 18px;
      }

      .label {
        width: 289px;
        height: 18px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: initial;
        color: #000000;
        line-height: 18px;
      }
    }
  }
}
</style>
