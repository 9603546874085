<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <!-- 直接捡货 -->
      <ips-header
        :title="$t('title.directOut')"
        :trigger="isBatchListShow ? $t('title.batchList') : ''"
        :on-go-detail="goDetail"
      />
    </div>
    <div class="ips-container">
      <!-- 单据编号 -->
      <ips-lov
        :label="$t('label.deliveryCode')"
        required
        params="billCode"
        v-model="billCode"
        :title="$t('label.deliveryCode')"
        :url="`${winvBaseUrl}app-direct-pick/query-bills`"
        :pre-parse-url="urlData['billCode']"
        ref="ref0"
        v-show="customData['billCode'] ? hideControl['billCode'] : true"
        :custom="customData['billCode']"
        :source-parse-field="sourceParseField"
        @setSourceField="saveSourceField($event)"
        @click.stop="handleCommonClick(0)"
        @choose="chooseBillCode($event)"
        @clickIcon="handleCommonClick(0, 'lov')"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 物料 -->
      <ips-lov
        :label="$t('label.sku')"
        v-model="sku"
        required
        :title="[$t('label.lineNum') ,$t('label.sku'), $t('label.goodsName')]"
        :params="[ 'lineNum', 'sku','goodsName']"
        :max-col-num="3"
        display="sku"
        :url="skuUrl"
        :url-params="{ billId, 'ownerId': billOwnerId }"
        :pre-parse-url="urlData['sku']"
        ref="ref10"
        :custom="customData['sku']"
        :source-parse-field="sourceParseField"
        @setSourceField="saveSourceField($event)"
        v-show="customData['sku'] ? hideControl['sku'] : true"
        @enter="handleSkip('ref10')"
        @down="handleSkip('ref10')"
        @choose="chooseSku($event)"
        @click.stop="handleCommonClick(10)"
        @clickIcon="handleCommonClick(10, 'lov')"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 品名 -->
      <ips-value-cell
        :title="$t('label.goodsName')"
        :value="goodsName"
        v-show="customData['goodsName'] ? hideControl['goodsName'] : true"
      />
      <!-- 单位 -->
      <ips-value-cell
        :title="$t('label.unit')"
        :value="uomName"
        v-show="customData['uomName'] ? hideControl['uomName'] : true"
      />
      <!-- 来源仓库 -->
      <ips-value-cell
        v-show="customData['fromWarehouseShow'] ? hideControl['fromWarehouseShow'] : fromWarehouseShow"
        :title="$t('label.fromWarehouse')"
        :value="fromWarehouseName"
      />
      <!-- 来源库区 -->
      <ips-lov
        :label="$t('label.sourceWharea')"
        required
        :disabled="fromWhareaCodeDisabled"
        v-model="fromWhareaCode"
        :title="$t('label.sourceWharea')"
        params="whareaCode"
        :url="`${winvBaseUrl}app-stock-trans/query-trans-wharea`"
        :url-params="{ 'warehouseId': fromWarehouseId, goodsId, ownerId }"
        :pre-parse-url="urlData['fromWhareaCode']"
        ref="ref30"
        :custom="customData['fromWhareaCode']"
        :source-parse-field="sourceParseField"
        @setSourceField="saveSourceField($event)"
        v-show="customData['fromWhareaCode'] ? hideControl['fromWhareaCode'] : true"
        @click.stop="handleCommonClick(30)"
        @enter="skipNext(40)"
        @down="skipNext(40)"
        @choose="handleChoose('fromWhareaCode', $event)"
        @clickIcon="handleCommonClick(30, 'lov')"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!--建议来源库区-->
      <ips-value-cell
        self-icon="batch"
        :special-background="true"
        :title="$t('label.suggestedWhareaCode')"
        :value="scanFromWhareaCode"
        v-show="scanFromWhareaId ? true : false"
      />
      <!-- 来源库位 -->
      <ips-lov
        :label="$t('label.sourceLocation')"
        required
        v-model="fromLocationCode"
        :title="$t('label.sourceLocation')"
        params="locationCode"
        :url="`${winvBaseUrl}app-stock-trans/query-trans-location`"
        :url-params="{ goodsId, 'whareaId': fromWhareaId, ownerId, 'warehouseId': fromWarehouseId}"
        :pre-parse-url="urlData['fromLocationCode']"
        ref="ref40"
        :custom="customData['fromLocationCode']"
        :source-parse-field="sourceParseField"
        @setSourceField="saveSourceField($event)"
        v-show="customData['fromLocationCode'] ? hideControl['fromLocationCode'] : true"
        @click.stop="handleCommonClick(40)"
        @enter="handleSkip('ref40')"
        @down="handleSkip('ref40')"
        @choose="handleChoose('fromLocationCode', $event)"
        @clickIcon="handleCommonClick(40, 'lov')"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <ips-value-cell
        self-icon="batch"
        :special-background="true"
        :title="$t('label.suggestedLocation')"
        :value="scanFromLocationCode"
        v-show="scanFromLocationId ? true : false"
      />
      <!-- 来源托盘 -->
      <ips-lov
        v-if="containerManageFlag"
        ref="ref50"
        v-model="fromCidCode"
        params="cidCode"
        :label="$t('label.sourceTray')"
        :title="$t('label.sourceTray')"
        :url="`${winvBaseUrl}app-direct-pick/outbound-query-trans-container`"
        :url-params="{'locationId': fromLocationId, goodsId, 'cidCode': fromCidCode}"
        :pre-parse-url="urlData['fromCidCode']"
        :custom="customData['fromCidCode']"
        :source-parse-field="sourceParseField"
        @setSourceField="saveSourceField($event)"
        v-show="customData['fromCidCode'] ? hideControl['fromCidCode']: true"
        @click.stop="handleCommonClick(50)"
        @enter="cidSkipNext"
        @down="cidSkipNext"
        @choose="handleChoose('fromCidCode', $event)"
        @clickIcon="handleCommonClick(50, 'lov')"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 批次 -->
      <ips-lov
        :label="$t('label.batch')"
        :title="$t('label.batch')"
        :required="!!isbatch"
        params="batchCode"
        v-model="batch"
        display="batchCode"
        :disabled="batchDisabled"
        :url="`${winvBaseUrl}app-direct-pick/outbound-query-trans-batch`"
        :url-params="{ orgId, 'warehouseId': fromWarehouseId, ownerId, goodsId, 'locationId': fromLocationId, 'cidId': fromCidId}"
        :pre-parse-url="urlData['batchCode']"
        ref="ref55"
        :custom="customData['batch']"
        :source-parse-field="sourceParseField"
        v-show=" isBatchListShow ? !isBatchListShow : (customData['batch'] ? hideControl['batch'] : isbatch ? isbatch : false) "
        @setSourceField="saveSourceField($event)"
        @click.stop="handleCommonClick(55)"
        @clickIcon="handleCommonClick(55, 'lov')"
        @enter="handleSkip('ref55')"
        @down="handleSkip('ref55')"
        @choose="handleChoose('batch', $event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!--建议批次-->
      <ips-value-cell
        self-icon="batch"
        :special-background="true"
        :title="$t('label.suggestedBatch')"
        :value="scanBatchCode"
        v-show="!!scanBatchId"
      />
      <!-- 可出库数量 -->
      <ips-value-cell
        :title="$t('label.canOutQty')"
        :value="plusQty"
        v-show="customData['plusQty'] ? hideControl['plusQty']: true"
      />
      <!-- 出库数量 -->
      <ips-input-number
        v-model="planQty"
        required
        :disabled="isTransfer || isParentCidDisabled"
        :label="$t('label.outQty')"
        ref="ref57"
        :custom="customData['planQty']"
        :source-parse-field="sourceParseField"
        @setSourceField="saveSourceField($event)"
        v-show="customData['planQty'] ? hideControl['planQty']: true"
        :enable-serial="serialType"
        @enter="handleSkip('ref57')"
        @down="handleSkip('ref57')"
        @click.stop="handleCommonClick(57)"
        @blur="checkQtyBlur"
        @valuedByFoucs="valuedByFoucs($event)"
        @focus="handlePlanQtyFocus"
        :min="null"
        :more-than-zero="true"
        not-auto-fill
      />
      <div
        class="qty-card"
        v-if="showQtyDialog"
      >
        <div class="text">
          {{ $t('label.qty') }}
          <span>{{ qty }}</span>
        </div>
        <div class="text">
          {{ $t('label.validQty') }}
          <span>{{ validQty }}</span>
        </div>
      </div>
      <!-- 序列号录入 -->
      <div
        class="input-button"
        v-if="isTransfer"
      >
        <!-- <hips-button
          @click="goToSerial"
          ref="inputRef"
          v-if="isTransfer"
          :style="{...inputBtnStyle}"
        >
          {{ $t('button.serialNumEntry') }}
        </hips-button> -->

        <ipsSerialComp
          ref="inputRef"
          :params="serialCompParams"
          @serialNumberList="changeSerialNumberList"
          @skipNextField="skipNext(60)"
        />
      </div>

      <!-- 目标仓库 -->
      <ips-value-cell
        :title="$t('label.toWarehouse')"
        :value="toWarehouseName"
        v-show="customData['toWarehouseName'] ? hideControl['toWarehouseName'] : toWarehouseShow"
      />
      <!-- 目标库区 -->
      <ips-lov
        :label="$t('label.targetWharea')"
        :required="showTarget"
        :disabled="toWhareaCodeDisabled"
        v-model="toWhareaCode"
        :title="$t('label.targetWharea')"
        params="whareaCode"
        :url="`${wmdmBaseUrl}whareas/accessible-list`"
        :url-params="{ 'warehouseId': toWarehouseId }"
        :pre-parse-url="urlData['toWhareaCode']"
        ref="ref60"
        :custom="customData['toWhareaCode']"
        :source-parse-field="sourceParseField"
        @setSourceField="saveSourceField($event)"
        v-show="customData['toWhareaCode'] ? hideControl['toWhareaCode']: showTarget"
        @click.stop="handleCommonClick(60)"
        @enter="skipNext(70)"
        @down="skipNext(70)"
        @choose="handleChoose('toWhareaCode', $event)"
        @clickIcon="handleCommonClick(60, 'lov')"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 目标库位 -->
      <ips-lov
        :label="$t('label.targetLocation')"
        :required="showTarget"
        v-model="toLocationCode"
        :title="$t('label.targetLocation')"
        params="locationCode"
        :url="`${wmdmBaseUrl}wms-locations/app`"
        :url-params="{ 'whareaId': toWhareaId, 'warehouseId': toWarehouseId }"
        :pre-parse-url="urlData['toLocationCode']"
        ref="ref70"
        :custom="customData['toLocationCode']"
        :source-parse-field="sourceParseField"
        @setSourceField="saveSourceField($event)"
        v-show="customData['toLocationCode'] ? hideControl['toLocationCode']: showTarget"
        @click.stop="handleCommonClick(70)"
        @enter="toLocationSkipNext"
        @down="toLocationSkipNext"
        @choose="chooseToLocation($event)"
        @clickIcon="handleCommonClick(70, 'lov')"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 目标托盘 -->
      <ips-lov
        :label="$t('label.targetTray')"
        :title="$t('label.targetTray')"
        v-model="toCidCode"
        ref="ref80"
        params="cidCode"
        :url="`${winvBaseUrl}app-direct-pick/query-to-cid`"
        :url-params="{fromCidId, fromCidCode, sourceBillType, toLocationId, isNew, parentCidFlag, toCidCode, toWarehouseId }"
        :pre-parse-url="urlData['toCidCode']"
        v-show="targetPlantShow && targetControlShow && showTarget ? customData['toCidCode'] ? hideControl['toCidCode']: true : targetPlantShow && targetControlShow && showTarget"
        :custom="customData['toCidCode']"
        :source-parse-field="sourceParseField"
        allow-no-exist
        @setSourceField="saveSourceField($event)"
        @click.stop="handleCommonClick(80)"
        @clickIcon="handleCommonClick(80, 'lov')"
        @choose="handleChoose('toCidCode', $event)"
        @enter="skipNext('submitRef')"
        @down="skipNext('submitRef')"
        @valuedByFoucs="valuedByFoucs($event)"
      />
    </div>
    <div class="ips-footer">
      <hips-button
        size="large"
        ref="submitRef"
        @click.stop="handleCommonSubmit"
      >
        <svg-icon
          slot="icon"
          icon-class="success"
        />{{ $t('button.confirm') }}
      </hips-button>
    </div>
  </hips-view>
</template>

<script>
import { checkBillCode, Containers, checkContainer, queryProcessQty, directOutboundOrCid, directPick, queryFormCidCodeCheck, getSerialNumberList, getRuleSuggest } from '../api';
import { storage } from '@/utils';
import ipsSerialComp from '@/public/views/ipsSerialComp.vue'
const isDirectGo = 'shipStatusList=INITIAL&shipStatusList=PARTLY_COMPLETED';
const isNotDirectGo = 'pickStatusList=INITIAL&pickStatusList=PARTLY_COMPLETED';
export default {
  name: "DirectOutbound",
  components: {
    ipsSerialComp
  },
  data() {
    return {
      billCode: '',
      sku: '',
      orgId: '',
      goodsId: '',
      batchCode: '',
      batch: '',
      fromWhareaCode: '',
      fromLocationCode: '',
      fromCidCode: '',
      toWhareaCode: '',
      toLocationCode: '',
      toCidCode: '',
      planQty: 0,
      isNew: 1,
      plusQty: '',
      uomCode: '',
      uomName: '',
      uomId: '',
      goodsName: '',
      billId: '',
      whareaId: '',
      warehouseId: '',
      fromWhareaId: '',
      fromWarehouseId: '',
      fromWarehouseName: '',
      fromLocationId: '',
      fromCidId: '',
      toCidId: '',
      toWarehouseId: '',
      toWarehouseName: '',
      ownerId: '',
      billOwnerId: '',
      toWhareaId: '',
      toLocationId: '',
      batchId: '',
      billTypeId: '',
      skuUrl: '',
      serialType: '',
      qtyShipTolerance: '',
      parentCidFlag: 0,
      onHandQty: '', // 现有量
      qty: '',
      validQty: '',
      scanBatchCode: '',
      scanBatchId: '',

      scanFromWhareaCode: '',
      scanFromWhareaId: '',

      scanFromLocationCode: '',
      scanFromLocationId: '',

      batchDisabled: false, // 父托盘仅有一个批次，禁用批次
      isbatch: false, //批次是否必输
      enableSerial: false, //是否启用序列号管理
      showTarget: true, //目标库区库位是否展示
      alsoCreateplant: false, //是否已经创建托盘
      directGo: false, //是否直接发运
      whetherRuleSuggest: false, //是否直接拣货增加规则推荐
      targetPlantShow: true, //单据编号控制目标托盘是否展示
      targetControlShow: true, //目标库位控制目标托盘是否展示
      clearToLocation: false, //判断目标库位是否跟来源库位相同
      containerManageFlag: 1, // 是否开始托盘管理, 默认是
      serialNumberDtoList: [],
      inboundSerialReceiptList: [],
      fromWarehouseShow: false,
      toWarehouseShow: false,
      isTransfer: false, //是否是调拨单
      isParentCidDisabled: false, // 是否是父托盘
      toWhareaCodeDisabled: false,
      fromWhareaCodeDisabled: false,
      showQtyDialog: false, // 展示数据可用量框
      // qtyCheckSuccess: false, // 出库数量校验是否成功
      isBatchListShow: false, // 批次列表按钮是否展示
      sourceBillType: '',
      urlData: {},
      customData: {}, // 个性化配置信息列表
      sourceParseField: {}, // 界面中所有的来源解析字段
      hideControl: {}, // 非必输字段的显隐控制
      stockList: [],
      menuId: storage.get('menuId'),
      serialCompParams: {},
    };
  },
  watch: {
    fromCidCode: function (val, oldval) {
      if (!val) {
        this.fromCidId = null;
        this.parentCidFlag = 0;
      }
      if (oldval !== '' && val === '') {
        this.batch = '';
        this.batchId = '';
        this.isBatchListShow = false;
        this.stockList = [];
        this.planQty = '';
        this.showQtyDialog = false;
        this.serialNumberDtoList = [];
        this.inboundSerialReceiptList = [];
        this.toCidCode = '';
        this.toCidId = '';
        this.isParentCidDisabled = false;
      }
      if (oldval !== val) {
        this.showQtyDialog = false;
      }
    },
    fromLocationCode: function (val, oldval) {
      if (oldval !== '' && val === '') {
        this.batch = '';
        this.batchId = '';
        this.isBatchListShow = false;
        this.stockList = [];
        this.planQty = '';
        this.showQtyDialog = false;
        this.serialNumberDtoList = [];
        this.inboundSerialReceiptList = [];
        this.toCidCode = '';
        this.toCidId = '';
        this.fromCidCode = '';
        this.fromCidId = '';
      }
      if (oldval !== val) {
        this.showQtyDialog = false;
      }
    },
    planQty: function (val) {
      if (val && val === this.onHandQty) {
        this.toCidCode = this.fromCidCode;
        this.toCidId = this.fromCidId;
      } else {
        this.toCidCode = '';
        this.toCidId = '';
      }
    },
    serialNumberDtoList: function (val) {
      if (this.isTransfer) {
        this.planQty = val?.length
      }
    }
  },
  async mounted() {
    this.inputFieldList = [
      { type: 'text', bind: 'goodsName', field: 'goodsName' },
      { type: 'text', bind: 'uomName', field: 'uomName' },
      { type: 'text', bind: 'fromWarehouseName', field: 'fromWarehouseName' },
      { type: 'text', bind: 'toWarehouseName', field: 'toWarehouseName' },
      { type: 'text', bind: 'plusQty', field: 'plusQty' },
      { type: 'lov', required: true, ref: 0, bind: 'billCode', field: 'billCode', isControl: true },
      { type: 'lov', required: true, ref: 10, bind: 'sku', field: 'sku' },
      { type: 'lov', required: true, ref: 30, bind: 'fromWhareaCode', field: 'fromWhareaCode' },
      { type: 'lov', required: true, ref: 40, bind: 'fromLocationCode', field: 'fromLocationCode' },
      { type: 'lov', required: false, ref: 50, bind: 'fromCidCode', field: 'fromCidCode' },
      { type: 'lov', required: !this.isbatch, ref: 55, bind: 'batch', isHide: !this.isbatch, field: 'batch' },
      { type: 'number', required: true, ref: 57, bind: 'planQty', field: 'planQty' },
      { type: 'lov', required: !this.showTarget, ref: 60, bind: 'toWhareaCode', isHide: !this.showTarget, field: 'toWhareaCode' },
      { type: 'lov', required: !this.showTarget, ref: 70, bind: 'toLocationCode', isHide: !this.showTarget, field: 'toLocationCode' },
      { type: 'lov', required: false, ref: 80, bind: 'toCidCode', isHide: !this.targetPlantShow && !this.targetControlShow, field: 'toCidCode' },
    ];
    this.urlData = this.handleData('DIRECT_OUB_CONFIRM');
    this.skipNext(0);
  },

  methods: {
    // 字段聚焦时赋值操作
    async valuedByFoucs(options = {}) {
      const { custom = {}, currentValue } = options;
      await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
    },
    // 保存来源解析字段
    async saveSourceField(fieldObj) {
      this.saveSourceParseField(this, fieldObj);
    },

    changeSerialNumberList(list) {
      this.serialNumberDtoList = list;
      this.planQty = this.serialNumberDtoList.length;
    },

    goToSerial() {
      if (this.inboundSerialReceiptList && this.inboundSerialReceiptList.length > 0) {
        console.log('序列号详情界面')
        this.goToSerialDetail();
      } else {
        const param = {
          name: 'inputSerial',
          params: {
            formOutBound: true,
            serialNumberDtoList: this.serialNumberDtoList,
            showInputBox: true,
            pageName: 'dierctOutbound',
            task: {
              billId: this.billId,
              billCode: this.billCode,
              detailId: this.detailId,
              sourceType: this.sourceBillType,
              serialType: this.serialType,
              goodsId: this.goodsId,
              sku: this.sku,
              qtyPlan: this.qtyPlan,
              scanBatchId: this.isbatch ? this.batchId : '',
              scanBatchCode: this.isbatch ? this.batch : '',
              whareaCode: this.fromWhareaCode,
              whareaId: this.fromWhareaId,
              ownerCode: this.ownerCode,
              ownerId: this.ownerId,
              warehouseCode: this.fromWarehouseCode,
              warehouseId: this.fromWarehouseId,
              orgCode: this.orgCode,
              orgId: this.orgId,
              billTypeId: this.billTypeId,
              billType: this.billType,
              scanCidId: this.serialType === 'INV_CTRL' ? this.fromCidId : null, // 来源托盘id
            },
          },
        };
        console.log(param)
        this.serialCompParams = param.params
        // this.$router.pushPage(param);
      }
    },

    /**
     * 前往序列号明细界面
     */
    async goToSerialDetail() {
      let data = {
        billId: this.billId,
      };
      let serialUrl = 'app-inbound/serial-check';
      // const _this = this;
      const param = {
        name: 'serialComponent',
        params: {
          data,
          serialUrl,
          receiveQty: this.planQty,
          detailIdTmpList: true,
          task: this.inboundSerialReceiptList,
          callback: function (serialNum) {
            const item = {
              serialNumber: serialNum,
              qualityStatusName: '0-合格',
              qualityStatus: '0-ACCEPTED',
              serialQty: 1,
            };
            this.tableData.push(item);
          }
        },

      };
      this.$router.pushPage(param);
    },

    // lov选择框，选择数据
    async handleChoose(type, obj) {
      switch (type) {
        case 'batch':
          this.batchId = obj.batchId;
          this.batch = obj.batchCode;
          break;
        case 'fromWhareaCode':
          this.fromWhareaCode = obj.whareaCode;
          this.fromWhareaId = obj.whareaId;
          break;
        case 'fromLocationCode':
          this.fromLocationCode = obj.locationCode;
          this.fromLocationId = obj.locationId;
          this.containerManageFlag = obj.containerManageFlag;
          if (this.containerManageFlag === 0) {
            this.fromCidCode = '';
            this.fromCidId = '';
          }
          break;
        case 'fromCidCode':
          this.fromCidCode = obj.cidCode;
          this.fromCidId = obj.cidId;
          this.scanBatchId = ''
          break;
        case 'toWhareaCode':
          this.toWhareaId = obj.whareaId;
          this.toWhareaCode = obj.whareaCode;
          break;
        case 'toCidCode':
          if (obj !== 'noExist') {
            this.toCidId = obj.cidId;
            this.toCidCode = obj.cidCode;
          } else {
            this.toCidId = null;
            this.toCidCode = null;
          }
          this.handlePlantsBlur();
          break;
        default:
          return null;
      }
    },

    // 光标定位到数量，获取可用量
    async handlePlanQtyFocus() {
      if (!this.parentCidFlag) {
        const { goodsId, sku, fromLocationId, fromLocationCode, fromCidId, fromCidCode, batch, batchId, fromWarehouseId, fromWarehouseCode, fromWhareaCode, fromWhareaId,
          uomCode, ownerCode, uomId, orgCode, ownerId, orgId } = this;
        const data = {
          params: {
            goodsId,
            sku,
            batchCode: batch,
            batchId,
            locationId: fromLocationId,
            locationCode: fromLocationCode,
            warehouseId: fromWarehouseId,
            warehouseCode: fromWarehouseCode,
            whareaCode: fromWhareaCode,
            whareaId: fromWhareaId,
            cidId: fromCidId,
            cidCode: fromCidCode,
            uomCode,
            ownerCode,
            ownerId,
            uomId,
            orgCode,
            orgId
          }
        };
        const res = await queryProcessQty(data);
        if (res && (res.qty || res.qty === 0)) {
          this.qty = res.qty;
          this.validQty = res.validQty;
          this.showQtyDialog = true; // 展示数据可用量框
        } else {
          this.showQtyDialog = false;
        }
      }
    },

    /**
    * 校验托盘是否为父托盘
    */
    async formCidCodeCheck() {
      try {
        const data = {
          params: {
            fromCidId: this.fromCidId,
            id: this.detailId,
            sourceBillType: this.sourceBillType,
            isbatch: this.isbatch ? 1 : 0,
            goodsId: this.goodsId,
            serialType: this.serialType,
            fromWarehouseId: this.fromWarehouseId,
            toWarehouseId: this.toWarehouseId,
          }
        };
        if (this.fromCidCode) {
          const res = await queryFormCidCodeCheck(data);
          if (res && res.onHandQty) {
            this.onHandQty = res.onHandQty;
          }
          if (res && res.parentCidFlag === 1) {
            this.$hint.showSuccess(this.$i18n.t('message.thisIsParentTran'));
            this.planQty = res.onHandQty ? res.onHandQty + '' : '';
            this.parentCidFlag = res.parentCidFlag;
            this.isParentCidDisabled = true;
            // this.skipNext(60);
          } else if (res && res.parentCidFlag === 0) {
            this.planQty = '';
            this.parentCidFlag = res.parentCidFlag;
            this.isParentCidDisabled = false;
          }
          if (res && res.stockList && res.stockList.length > 1) {
            this.stockList = res.stockList;
            this.isBatchListShow = true;
            this.batchDisabled = false;
            this.showQtyDialog = false;
            this.batch = '';
            this.batchId = '';
            if (this.isbatch && !this.isBatchListShow) {
              this.$nextTick(() => {
                this.skipNext(55)
              });
            } else if (this.isParentCidDisabled || this.isbatch) {
              this.skipNext(60);
            } else {
              this.skipNext(57);
            }
          } else if (res && res.stockList && res.stockList.length === 1) {
            this.stockList = res.stockList;
            this.batch = res.stockList[0].batchCode;
            this.batchId = res.stockList[0].batchId;
            this.isBatchListShow = false;
            this.batchDisabled = true;
            if (this.parentCidFlag) {
              if (this.isTransfer || this.isParentCidDisabled && this.toWhareaCodeDisabled && !this.showTarget) {
                this.skipNext('submitRef');
              } else if (this.isTransfer || this.isParentCidDisabled && this.toWhareaCodeDisabled) {
                this.skipNext(70);
              } else if (this.isTransfer || this.isParentCidDisabled) {
                this.skipNext(60);
              }
            }
          } else {
            this.stockList = [];
            this.isBatchListShow = false;
            this.batchDisabled = false;
            this.batch = '';
            this.batchId = '';
            if (!this.parentCidFlag && this.isbatch && !this.isBatchListShow) {
              this.$nextTick(() => {
                this.skipNext(55);
              });
            }
          }
          const serialNumberData = {
            params: {
              fromLocationId: this.fromLocationId,
              goodsId: this.goodsId,
              serialType: this.serialType,
              fromCidId: this.fromCidId,
              parentCidFlag: this.parentCidFlag,
            }
          };
          const result = await getSerialNumberList(serialNumberData);
          if (result && result.length > 0) {
            this.inboundSerialReceiptList = result;
          }
        }
        return true;
      } catch (error) {
        this.isParentCidDisabled = false;
        this.skipNext(50);
        return false;
      }
    },

    /**
    * createPlants 创建托盘
    */
    async createPlants() {
      try {
        this.$hint.showLoading();
        // const url = `${this.wmdmBaseUrl}containers`;
        let data = {
          warehouseId: this.warehouseId,
          cidCode: this.toCidCode,
          cidStatus: 'EMPTY',
          cidName: this.toCidCode,
        };
        // const res = await this.$http.post(url, data);
        const res = await Containers(data);
        if (!res.failed) {
          this.alsoCreateplant = true;
          this.$hint.showSuccess(this.$i18n.t('message.createPlantSuccess'));
        }
      } finally {
        this.$hint.hideLoading();
      }
    },

    /**
    * 物料选中调用
    * @param skuItem 选中对象
    */
    async chooseSku(skuItem) {
      let item = { ...skuItem }
      if (this.whetherRuleSuggest) {
        getRuleSuggest(item).then((res) => {
          if (res && res.failed === true) {
            this.showFailed(res.message, true);
          } else {
            this.scanFromLocationCode = res?.locationCode;
            this.scanFromLocationId = res?.locationId;
            this.scanFromWhareaCode = res?.whareaCode;
            this.scanFromWhareaId = res?.whareaId;
            this.scanBatchId = res?.batchId;
            this.scanBatchCode = res?.batchCode;
            item = Object.assign({}, skuItem, {
              batchId: res?.batchId,
              batchCode: res?.batchCode,
            });
          }
        })
      } else {
        this.scanFromLocationCode = item?.locationCode
        this.scanFromLocationId = item?.locationId
        this.scanFromWhareaCode = item?.whareaCode
        this.scanFromWhareaId = item?.whareaId
      }

      // const site = ['INV_CTRL', 'OUT_CTRL', 'SEMI_INV_CTRL'];
      const site = [this.SERIAL_INV_CTRL, this.SERIAL_OUT_CTRL, this.SERIAL_SEMI_INV_CTRL];
      this.enableSerial = site.includes(item.serialType);
      // this.isTransfer = item.serialType==='OUT_CTRL' && this.fromWarehouseShow;
      if (this.enableSerial) {
        if (item.serialType === 'OUT_CTRL' && this.sourceBillType !== 'OUT') {
          this.isTransfer = false;
        } else if (item.serialType === this.SERIAL_SEMI_INV_CTRL && (this.toWarehouseId === this.fromWarehouseId) && (this.sourceBillType === 'TRANSFER' || this.sourceBillType === 'WAVE')) {
          this.isTransfer = false;
        } else {
          this.isTransfer = true;
        }
      }

      const { goodsName, uomCode, uomId, uomName, goodsId,
        plusQty, whareaId, orgId, orgCode, ownerName, ownerCode, ownerId,
        id, serialType, planQty, lineNum, qtyShipTolerance,
        fromWarehouseName, toWarehouseName, sourceBillType } = item;
      this.goodsName = goodsName;
      this.whareaId = whareaId;
      this.uomCode = uomCode;
      this.uomId = uomId;
      this.orgId = orgId;
      this.orgCode = orgCode;
      this.uomName = uomName;
      this.goodsId = goodsId;
      this.plusQty = plusQty;
      this.ownerName = ownerName;
      this.ownerCode = ownerCode;
      this.ownerId = ownerId;
      this.detailId = id;
      this.serialType = serialType;
      this.qtyPlan = planQty;
      this.detailLineNum = lineNum;
      this.qtyShipTolerance = qtyShipTolerance;

      // 由物料带出来源仓库和目标仓库
      this.fromWarehouseName = fromWarehouseName;
      this.toWarehouseName = toWarehouseName;
      this.fromWarehouseShow = sourceBillType === 'TRANSFER'; // 为调拨单时显示来源仓库
      this.toWarehouseShow = sourceBillType === 'TRANSFER';
      // 物料行精细控制仓库id
      this.fromWarehouseId = item.fromWarehouseId;
      this.fromWarehouseCode = item.fromWarehouseCode;
      this.toWarehouseId = item.toWarehouseId;
      this.toWarehouseCode = item.toWarehouseCode;
      //以下是物料能带出目标库区 来源库区的情况
      this.fromWhareaCode = item.fromWhareaCode;
      this.fromWhareaId = item.fromWhareaId;
      this.fromWhareaCodeDisabled = item.fromWhareaCode ? true : false;
      this.toWhareaCode = item.toWhareaCode;
      this.toWhareaId = item.toWhareaId;
      this.toWhareaCodeDisabled = item.toWhareaCode ? true : false;
      if (item.isbatch === 1) {
        this.isbatch = true;
      } else {
        this.isbatch = false;
        this.batch = '';
        this.batchId = '';
        this.isBatchListShow = false;
        this.stockList = [];
      }
    },

    /**
    * 目标库位选中调用
    * @param item 选中对象
    */
    chooseToLocation(item) {
      this.toOrgCode = item.orgCode;
      this.toOrgId = item.orgId;
      this.toWarehouseCode = item.warehouseCode;
      this.toWarehouseId = item.warehouseId;
      if (item.locationId === this.fromLocationId) {
        this.$hint.showFailed(this.$i18n.t('message.toAndFromLocationNotSame'), true);
        this.clearToLocation = true;
        return
      }
      this.clearToLocation = false;
      this.toLocationCode = item.locationCode;
      this.toLocationId = item.locationId;

      // 当单据编号控制目标托盘未隐藏时，走以下判断
      if (this.targetPlantShow) {
        this.targetControlShow = item.containerManageFlag === 1 ? true : false;
      }
      if (!this.targetControlShow) {
        this.toCidCode = '';
        this.toCidId = '';
      }
    },

    /**
     * handlePlantsBlur 目标托盘失焦事件
    */
    async handlePlantsBlur() {
      // 当输入的托盘是不存在时（不存在所以没有id）
      try {
        if (this.toCidCode && !this.toCidId) {
          let data = {
            pageId: this.menuId
          };
          await checkContainer(data);
          // if(!res.failed && !this.alsoCreateplant){
          //   this.createPlants();
          // }else {
          //   this.skipNext(80);
          // }
        }
        if (!this.toCidCode) {
          this.toCidId = '';
        }
      } catch {
        this.skipNext(80);
      }
    },

    /**
    * 出库数量失焦事件
    */
    async checkQtyBlur() {
      const { planQty, validQty } = this;
      if (planQty && planQty <= 0) {
        this.$hint.showFailed(this.$i18n.t('message.qtyMustBeGreaterThanZero'), true);
        this.skipNext(57);
      } else if (planQty && (validQty === 0 ? true : validQty ? validQty : false) && planQty > validQty) {
        this.$hint.showFailed(this.$i18n.t('message.outQtyNeedLessThanValidQty'), true);
        this.skipNext(57);
      }
    },

    /**
    * chooseBillCode 单据编号选中调用 校验单号是否允许拣货，出库
    * @param {Object} obj 选中对象
    */
    async chooseBillCode(obj) {
      if (this.billCode) {
        this.sourceBillType = obj.sourceBillType;
        this.fromOrgId = obj.fromOrgId;
        this.fromOrgCode = obj.fromOrgCode;
        this.billId = obj.billId;
        this.billTypeId = obj.billTypeId;
        this.billType = obj.billType;
        this.billOwnerId = obj.ownerId;
        // this.fromWarehouseName = obj.fromWarehouseName;
        // this.toWarehouseName = obj.toWarehouseName;
        // this.fromWarehouseShow = obj.sourceBillType === 'TRANSFER'; // 为调拨单时显示来源仓库
        // this.toWarehouseShow = obj.sourceBillType === 'TRANSFER';
        //为调拨单时toWarehouseId != fromWarehouseId
        //this.toWarehouseId = obj.toWarehouseId;
        // this.fromWarehouseId = obj.fromWarehouseId;
        // 出库单与波次单的情况下fromWarehouseId=toWarehouseId，可随意选取一个作为warehouseId
        // this.warehouseId = obj.fromWarehouseId;
        // 单据编号校验
        this.receiptNumBlur(obj);
      }
    },

    async receiptNumBlur(obj) {
      this.$hint.showLoading();
      const data = {
        params: {
          billTypeId: this.billTypeId,
        },
      };
      const res = await checkBillCode(data);
      try {
        switch (obj.sourceBillType) {
          //出库单
          case this.$hint.outboundStatus:
            if (res.billTypeNoAllocationBoolean) {
              this.$hint.showFailed(this.$i18n.t('message.notAllowDirectPick'), true);
              setTimeout(() => this.skipNext(0), 30);
              return
            }
            this.showTarget = res.outBoundLabel === 3 ? false : true;
            this.targetPlantShow = (res.outBoundLabel === 3) ? false : true;
            this.directGo = res.outBoundLabel === 3 ? true : false;
            this.whetherRuleSuggest = !!res.whetherRuleSuggest
            this.skuUrl = `${this.winvBaseUrl}bill-process/outbound?${this.directGo ? isDirectGo : isNotDirectGo}`;
            setTimeout(() => this.skipNext(10), 30);
            break;
          //调拨单
          case this.$hint.transferStatus:
            if (res.billTypeIsDirectTransferBoolean) {
              this.$hint.showFailed(this.$i18n.t('message.notAllowDirectPick'), true);
              setTimeout(() => this.skipNext(0), 30);
              return
            }
            this.skuUrl = `${this.winvBaseUrl}bill-process/transfer`;
            setTimeout(() => this.skipNext(10), 30);
            break;
          //波次单
          case this.$hint.waveStatus:
            if (res.billTypeNoAllocationBoolean) {
              this.$hint.showFailed(this.$i18n.t('message.notAllowDirectPick'), true);
              setTimeout(() => this.skipNext(0), 30);
              return
            }
            this.skuUrl = `${this.winvBaseUrl}bill-process/wave`;
            setTimeout(() => this.skipNext(10), 30);
            break;
          default:
            return null;
        }
      } catch (err) {
        this.skipNext(0);
      } finally {
        this.$hint.hideLoading();
      }
    },

    // 光标跳转问题
    handleSkip(type) {
      switch (type) {
        case 'ref10':
          if (!this.fromWhareaCodeDisabled) {
            this.$nextTick(() => {
              this.skipNext(30);
            })
          } else {
            this.skipNext(40);
          }
          break;
        case 'ref40':
          if (this.containerManageFlag) {
            if (this.isTransfer) {
              this.goToSerial()
              this.$refs.inputRef.$refs['ref58'].focus()
            } else {
              this.skipNext(50);
            }
          } else if (this.isbatch) {
            this.skipNext(55);
          } else if (!(this.isTransfer || this.isParentCidDisabled)) {
            this.skipNext(57);
          } else if (!this.showTarget) {
            this.skipNext('submitRef');
          } else if (!this.toWhareaCodeDisabled) {
            this.skipNext(60);
          } else {
            this.skipNext(70);
          }
          break;
        case 'ref55':
          // if (this.scanBatchId && this.batch && (this.scanBatchCode !== this.batch || this.batchId !== this.scanBatchId)) {
          //   this.$hint.showFailed(this.$i18n.t('message.scanBatchIsNotEqualBatch'), true);
          //   this.skipNext(55);
          // } else 

          if (!(this.isTransfer || this.isParentCidDisabled)) {
            this.skipNext(57);
          } else if (!this.showTarget) {
            this.skipNext('submitRef');
          } else if (!this.toWhareaCodeDisabled) {
            this.skipNext(60);
          } else {
            this.skipNext(70);
          }
          break;
        case 'ref57':
          if (this.enableSerial) {
            this.skipNext('inputRef');
          } else {
            if (this.showTarget) {
              if (!this.toWhareaCodeDisabled) {
                this.$nextTick(() => {
                  this.skipNext(60);
                });
              } else {
                this.$nextTick(() => {
                  this.skipNext(70);
                });
              }
            } else {
              this.toLocationSkipNext();
            }
            // this.skipNext(60);
          }
          break;
        case 'ref80':
          this.skipNext('submitRef');
          break;
        default:
          return null;
      }
    },
    /**
     * XSCK202207060003
     * B2C007
     * B2C007205
     */

    /**
    * cidSkipNext 来源托盘跳转
    */
    async cidSkipNext() {
      const res = await this.formCidCodeCheck();
      if (res) {
        try {
          // this.$hint.showLoading();
          const { orgId, ownerId, fromWarehouseId, goodsId, fromWhareaId, fromLocationId, fromCidCode } = this;
          let data = {
            params: {
              orgId,
              ownerId,
              warehouseId: fromWarehouseId,
              goodsId,
              whareaId: fromWhareaId,
              locationId: fromLocationId,
            }
          };
          if (!fromCidCode) {
            const res = await directOutboundOrCid(data);
            if (res) {
              this.$hint.showFailed(this.$i18n.t('message.qtyInPlant'), true);
              this.skipNext(50);
              return;
            }
          }
          if (this.isbatch && !this.isBatchListShow) {
            this.$nextTick(() => {
              this.skipNext(55)
            });
          } else if (!(this.isTransfer || this.isParentCidDisabled)) {
            this.skipNext(57);
          } else if (!this.showTarget) {
            this.skipNext('submitRef');
          } else if (!this.toWhareaCodeDisabled) {
            this.goToSerial()
            this.$refs.inputRef.$refs['ref58'].focus()
          } else {
            this.skipNext(70);
          }
        } catch (error) {
          this.skipNext(50);
        } finally {
          // this.$hint.hideLoading();
        }
      }
    },

    /**
    * 目标库位enter事件
    */
    toLocationSkipNext() {
      if (this.clearToLocation) {
        this.skipNext(70);
      } else if (this.targetPlantShow && this.targetControlShow) {
        this.$nextTick(() => {
          this.skipNext(80);
        });
      } else {
        this.handleSkip('ref80');
      }
    },

    // 直接出库数据提交
    async handleButtonSubmit() {
      let data = {
        goodsId: this.goodsId,
        uomId: this.uomId,
        batchId: this.batchId,
        fromWhareaId: this.fromWhareaId,
        fromLocationId: this.fromLocationId,
        fromCidId: this.fromCidId,
        billTypeId: this.billTypeId,
        billType: this.billType,
        billCode: this.billCode,
        sku: this.sku,
        uomCode: this.uomCode,
        fromWhareaCode: this.fromWhareaCode,
        fromLocationCode: this.fromLocationCode,
        fromCidCode: this.fromCidCode,
        // planQty: this.planQty,
        planQty: this.qtyPlan,
        billId: this.billId,
        sourceBillType: this.sourceBillType,
        processQty: this.planQty,
        fromOrgId: this.fromOrgId,
        fromOrgCode: this.fromOrgCode,
        fromWarehouseCode: this.fromWarehouseCode,
        fromWarehouseId: this.fromWarehouseId,
        toOrgCode: this.toOrgCode,
        toOrgId: this.toOrgId,
        toWarehouseCode: this.toWarehouseCode,
        toWarehouseId: this.toWarehouseId,
        ownerId: this.ownerId,
        ownerCode: this.ownerCode,
        batchCode: this.batch,
        id: this.detailId,
        detailId: this.detailId,
        detailLineNum: this.detailLineNum,
        outboundSerialRecordList: this.serialNumberDtoList.length > 0 ? this.serialNumberDtoList : this.inboundSerialReceiptList,
        stockList: this.stockList ? this.stockList : null,
        serialType: this.serialType,
        qtyShipTolerance: this.qtyShipTolerance,
      };
      if (!this.showTarget) {
        data.toWhareaId = this.fromWhareaId;
        data.toWhareaCode = this.fromWhareaCode;
        data.toLocationId = this.fromLocationId;
        data.toLocationCode = this.fromLocationCode;
        data.toCidId = this.fromCidId;
        data.toCidCode = this.fromCidCode;
      } else {
        data.toWhareaId = this.toWhareaId;
        data.toWhareaCode = this.toWhareaCode;
        data.toLocationId = this.toLocationId;
        data.toLocationCode = this.toLocationCode;
        data.toCidId = this.toCidId;
        data.toCidCode = this.toCidCode;
      }
      const { enableSerial, fromCidId, toCidId } = this;
      // 出库数量必输且不是disabled状态、目标托盘=来源托盘时（这种情况下要校验数量），直接点击提交按钮情况时，控制数量失焦时校验操作必须走在提交操作前
      if (!enableSerial && fromCidId && toCidId && fromCidId === toCidId) {
        // if(qtyCheckSuccess) {
        try {
          this.$hint.showLoading();
          this.handleSubmitStatus();
          const res = await directPick(data);
          if (res.failed) {
            this.$hint.showFailed(res.message);
          } else {
            this.$hint.showSuccess(this.$i18n.t('message.operationSuccess'));
            this.reAccept();
          }
        } finally {
          this.$hint.hideLoading();
          this.handleSubmitStatus();
        }
        // }
      } else {
        // 不需要交验的情况可直接提交
        try {
          this.$hint.showLoading();
          this.handleSubmitStatus();
          const res = await directPick(data);
          if (res.failed) {
            this.$hint.showFailed(res.message);
          } else {
            this.$hint.showSuccess(this.$i18n.t('message.operationSuccess'));
            this.reAccept();
          }
        } finally {
          this.$hint.hideLoading();
          this.handleSubmitStatus();
        }
      }
    },

    async reAccept() {
      this.init();
      // 清除当前界面的二维码解析字段
      await this.clearSourceFields(this, true);
      this.skipNext(0);
    },

    // 数据初始化
    init() {
      this.billCode = '';
      this.batch = '';
      this.sku = '';
      this.goodsId = '';
      this.batchCode = '';
      this.fromWhareaCode = '';
      this.fromLocationCode = '';
      this.containerManageFlag = 1;
      this.fromCidCode = '';
      this.toWhareaCode = '';
      this.toLocationCode = '';
      this.toCidCode = '';
      this.toCidId = '';
      this.planQty = 0;
      this.onHandQty = '';
      this.plusQty = '';
      this.uomCode = '';
      this.uomId = '';
      this.isbatch = false;
      this.isTransfer = false;
      this.scanBatchId = '';
      this.uomName = '';
      this.goodsName = '';
      this.qtyShipTolerance = '';
      this.alsoCreateplant = false;
      this.isParentCidDisabled = false;
      this.showQtyDialog = false;
      this.batchDisabled = false;
      this.showTarget = true; //目标库区库位是否展示
      this.alsoCreateplant = false; //是否已经创建托盘
      this.directGo = false;//是否直接发运
      this.targetPlantShow = true;
      this.targetControlShow = true;
      this.serialNumberDtoList = [];
      this.inboundSerialReceiptList = [];
    },

    goDetail() {
      let param = {
        name: 'DirectOutboundToBatchDetail',
        params: {
          stockList: this.stockList,
        },
      };
      this.$router.pushPage(param);
    },
  },
  activated() {
    const totalSerialNum = JSON.parse(sessionStorage.getItem('totalSerialNum'));
    const serialNumberDtoList = JSON.parse(sessionStorage.getItem('serialNumberDtoList'));
    if (totalSerialNum) {
      this.planQty = totalSerialNum;
      this.serialNumberDtoList = serialNumberDtoList;
      this.skipNext('submitRef');
      sessionStorage.removeItem('totalSerialNum');
      sessionStorage.removeItem('serialNumberDtoList');
    }
  },

  created() {
    const { hideControl, customObj } = this.handleCustomData(storage.get('customData'));
    this.customData = customObj;
    this.hideControl = hideControl;
    // console.log('按单收货customData', Object.assign({}, this.customData));
  },

  beforeRouteLeave(to, from, next) {
    console.log(to.name)
    if (to.name === 'inputSerial' || to.name === 'BatchDetail' || to.name === 'serialComponent') {
      this.$store.commit('pushKeepAlives', 'DirectOutbound');
    } else {
      this.$store.commit('deleteKeepAlives', 'DirectOutbound');
    }
    if (from.name === 'BatchDetail' && this.showTarget) {
      this.skipNext(60);
    } else if (from.name === 'BatchDetail' && !this.showTarget) {
      this.skipNext('submitRef');
    }
    next();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const _this = vm;
      if (from.name === 'BatchDetail' && _this.showTarget) {
        _this.skipNext(60);
      } else if (from.name === 'BatchDetail' && !_this.showTarget) {
        _this.skipNext('submitRef');
      }
    });
  },
};
</script>

<style lang="stylus" scoped>
@import '~@/style/var.styl';

.input-button {
  margin: 8px 0;

  .hips-button--primary {
    height: 35px;
    line-height: 30px;
  }
}

.qty-card {
  display: flex;
  justify-content: space-between;
  background-color: #E7F7FF;
  padding: 6px 10px;
  color: #70C3FD;
  border-color: #B0E7FF;
  margin-top: 5px;

  .text {
    width: 50%;
  }
}
</style>
