var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "hips-view",
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("ips-header", {
            attrs: {
              "is-index": false,
              "render-data": _vm.billDetail,
              "page-type": "taskExecution",
              trigger: _vm.$t("button.skipTask"),
              "on-go-detail": _vm.handleSkipSubmit,
            },
            on: { beforeBack: _vm.beforeBack },
          }),
        ],
        1
      ),
      _c("ips-page-head", {
        attrs: {
          "title-info": {
            billCode: _vm.billCode,
            billTypeName: _vm.billTypeName,
          },
          "content-info": _vm.billDetail,
          "react-field": [
            "sku",
            "sourceTypeName",
            "warehouseName",
            "whareaCode",
            "qtyPlan",
            "uomName",
          ],
          "footer-message": _vm.billDetail["goodsName"],
          "page-box-task-comfirm-class": "task-execution-page-header",
        },
      }),
      _c(
        "div",
        {
          staticClass: "ips-container",
          staticStyle: {
            "padding-bottom": "10px",
            height: "calc(100vh - 55vw)",
            "padding-top": "0.27vw",
          },
        },
        [
          _c("div", { staticStyle: { "box-shadow": "0 0 2.133vw #d9d9d9" } }, [
            _c(
              "div",
              {
                staticClass: "container-warp-box",
                staticStyle: {
                  height: "calc(100vh - 75vw)",
                  "overflow-y": "scroll",
                },
              },
              [
                _c("ips-field", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.customData["scanSku"]
                        ? _vm.hideControl["scanSku"]
                        : true,
                      expression:
                        "customData['scanSku'] ? hideControl['scanSku'] : true",
                    },
                  ],
                  ref: "ref0",
                  attrs: {
                    required: "",
                    label: _vm.$t("label.confirmSku"),
                    params: "scanSku",
                    display: "scanSku",
                    "pre-parse-url": _vm.urlData["scanSku"],
                    custom: _vm.customData["scanSku"],
                    "source-parse-field": _vm.sourceParseField,
                  },
                  on: {
                    setSourceField: function ($event) {
                      return _vm.saveSourceField($event)
                    },
                    valuedByFoucs: function ($event) {
                      return _vm.valuedByFoucs($event)
                    },
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleCommonClick(0)
                    },
                    enter: function () {
                      return _vm.handleSkuEnter(0)
                    },
                    blur: function () {
                      return _vm.handleSkuBlur(0)
                    },
                  },
                  model: {
                    value: _vm.scanSku,
                    callback: function ($$v) {
                      _vm.scanSku = $$v
                    },
                    expression: "scanSku",
                  },
                }),
                _c("ips-field", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.customData["scanWhareaCode"]
                        ? _vm.hideControl["scanWhareaCode"]
                        : true,
                      expression:
                        "customData['scanWhareaCode'] ? hideControl['scanWhareaCode'] : true",
                    },
                  ],
                  ref: "ref10",
                  attrs: {
                    required: "",
                    label: _vm.$t("label.confirmWharea"),
                    params: "scanWhareaCode",
                    display: "scanWhareaCode",
                    "pre-parse-url": _vm.urlData["scanWhareaCode"],
                    custom: _vm.customData["scanWhareaCode"],
                    "source-parse-field": _vm.sourceParseField,
                  },
                  on: {
                    setSourceField: function ($event) {
                      return _vm.saveSourceField($event)
                    },
                    valuedByFoucs: function ($event) {
                      return _vm.valuedByFoucs($event)
                    },
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleCommonClick(10)
                    },
                    enter: function ($event) {
                      return _vm.handleWhareaEnter(10)
                    },
                    blur: function ($event) {
                      return _vm.handleWhareaBlur(10)
                    },
                  },
                  model: {
                    value: _vm.scanWhareaCode,
                    callback: function ($$v) {
                      _vm.scanWhareaCode = $$v
                    },
                    expression: "scanWhareaCode",
                  },
                }),
                _c("ips-lov", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.customData["scanLocationCode"]
                        ? _vm.hideControl["scanLocationCode"]
                        : true,
                      expression:
                        "customData['scanLocationCode'] ? hideControl['scanLocationCode'] : true",
                    },
                  ],
                  ref: "ref20",
                  attrs: {
                    label: _vm.$t("label.confirmLocation"),
                    required: "",
                    title: _vm.$t("label.confirmLocation"),
                    params: "scanLocationCode",
                    url:
                      _vm.winvBaseUrl +
                      "app-outbound-load/query-from-locations",
                    "url-params": _vm.billDetail,
                    "pre-parse-url": _vm.urlData["scanLocationCode"],
                    custom: _vm.customData["scanLocationCode"],
                    "source-parse-field": _vm.sourceParseField,
                  },
                  on: {
                    setSourceField: function ($event) {
                      return _vm.saveSourceField($event)
                    },
                    valuedByFoucs: function ($event) {
                      return _vm.valuedByFoucs($event)
                    },
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleCommonClick(20)
                    },
                    clickIcon: function ($event) {
                      return _vm.handleCommonClick(20, "lov")
                    },
                    choose: function ($event) {
                      return _vm.handleChoose("location", $event)
                    },
                    enter: function ($event) {
                      return _vm.handleLocationEnter()
                    },
                    down: function ($event) {
                      return _vm.handleLocationEnter()
                    },
                    change: _vm.handleChange,
                  },
                  model: {
                    value: _vm.billDetail.scanLocationCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.billDetail, "scanLocationCode", $$v)
                    },
                    expression: "billDetail.scanLocationCode",
                  },
                }),
                _c("ips-value-cell", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.customData["locationCode"]
                        ? _vm.hideControl["locationCode"]
                        : _vm.display["locationCode"],
                      expression:
                        "customData['locationCode'] ? hideControl['locationCode'] : display['locationCode']",
                    },
                  ],
                  attrs: {
                    "special-background": true,
                    "self-icon": "location",
                    title: _vm.$t("label.suggestedLocation"),
                    value: _vm.billDetail.locationCode,
                  },
                }),
                _c("ips-lov", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.customData["scanCidCode"]
                        ? _vm.hideControl["scanCidCode"]
                        : _vm.containerManageFlag,
                      expression:
                        "customData['scanCidCode'] ? hideControl['scanCidCode'] : containerManageFlag",
                    },
                  ],
                  ref: "ref30",
                  attrs: {
                    label: _vm.$t("label.conformTray"),
                    title: _vm.$t("label.conformTray"),
                    params: "scanCidCode",
                    url:
                      _vm.winvBaseUrl +
                      "app-outbound-load/query-from-containers",
                    "url-params": _vm.billDetail,
                    "pre-parse-url": _vm.urlData["scanCidCode"],
                    custom: _vm.customData["scanCidCode"],
                    "source-parse-field": _vm.sourceParseField,
                  },
                  on: {
                    setSourceField: function ($event) {
                      return _vm.saveSourceField($event)
                    },
                    valuedByFoucs: function ($event) {
                      return _vm.valuedByFoucs($event)
                    },
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleCommonClick(30)
                    },
                    clickIcon: function ($event) {
                      return _vm.handleCommonClick(30, "lov")
                    },
                    choose: function ($event) {
                      return _vm.handleChoose("cid", $event)
                    },
                    enter: _vm.handleCidEnter,
                    down: _vm.handleCidEnter,
                    blur: _vm.handleCheckCid,
                    change: _vm.handleCidChange,
                  },
                  model: {
                    value: _vm.billDetail.scanCidCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.billDetail, "scanCidCode", $$v)
                    },
                    expression: "billDetail.scanCidCode",
                  },
                }),
                _c("ips-value-cell", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.customData["cidCode"]
                        ? _vm.hideControl["cidCode"]
                        : _vm.containerManageFlag && _vm.display["cidCode"],
                      expression:
                        "customData['cidCode'] ? hideControl['cidCode'] : containerManageFlag && display['cidCode']",
                    },
                  ],
                  attrs: {
                    "self-icon": "cid",
                    "special-background": true,
                    title: _vm.$t("label.suggestedCidCode"),
                    value: _vm.billDetail.cidCode,
                  },
                }),
                _c("ips-lov", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isBatch
                        ? _vm.customData["scanBatchCode"]
                          ? _vm.hideControl["scanBatchCode"]
                          : _vm.isBatch && _vm.display["batchCode"]
                        : _vm.display["batchCode"],
                      expression:
                        "\n            isBatch\n              ? customData['scanBatchCode']\n                ? hideControl['scanBatchCode']\n                : isBatch && display['batchCode']\n              : display['batchCode']\n          ",
                    },
                  ],
                  ref: "ref40",
                  attrs: {
                    label: _vm.$t("label.confirmBatchCode"),
                    title: _vm.$t("label.confirmBatchCode"),
                    required: "",
                    params: "scanBatchCode",
                    url: _vm.winvBaseUrl + "app-outbound-load/query-batchs",
                    "url-params": _vm.billDetail,
                    "pre-parse-url": _vm.urlData["scanBatchCode"],
                    disabled: _vm.disabledBatch,
                    custom: _vm.customData["scanBatchCode"],
                    "source-parse-field": _vm.sourceParseField,
                  },
                  on: {
                    setSourceField: function ($event) {
                      return _vm.saveSourceField($event)
                    },
                    valuedByFoucs: function ($event) {
                      return _vm.valuedByFoucs($event)
                    },
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleCommonClick(40)
                    },
                    clickIcon: function ($event) {
                      return _vm.handleCommonClick(40, "lov")
                    },
                    choose: function ($event) {
                      return _vm.handleChoose("batch", $event)
                    },
                    enter: _vm.handleBatchSkip,
                    down: _vm.handleBatchSkip,
                    change: _vm.handleChange,
                  },
                  model: {
                    value: _vm.billDetail.scanBatchCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.billDetail, "scanBatchCode", $$v)
                    },
                    expression: "billDetail.scanBatchCode",
                  },
                }),
                _c("ips-value-cell", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isBatch
                        ? _vm.customData["batchCode"]
                          ? _vm.hideControl["batchCode"]
                          : _vm.isBatch && _vm.display["suggestBatchCode"]
                        : _vm.display["suggestBatchCode"],
                      expression:
                        "\n            isBatch\n              ? customData['batchCode']\n                ? hideControl['batchCode']\n                : isBatch && display['suggestBatchCode']\n              : display['suggestBatchCode']\n          ",
                    },
                  ],
                  attrs: {
                    "self-icon": "batch",
                    "special-background": true,
                    title: _vm.$t("label.suggestedBatch"),
                    value: _vm.billDetail.batchCode,
                  },
                }),
                _c("hips-row", [
                  _vm.enableSerial
                    ? _c(
                        "div",
                        { staticStyle: { "margin-top": "16px" } },
                        [
                          _c(
                            "hips-col",
                            { attrs: { span: "14" } },
                            [
                              _c("ips-input-number", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.customData["qtyScan"]
                                      ? _vm.hideControl["qtyScan"]
                                      : true,
                                    expression:
                                      "customData['qtyScan'] ? hideControl['qtyScan'] : true",
                                  },
                                ],
                                ref: "ref50",
                                attrs: {
                                  required: "",
                                  "not-auto-fill": "",
                                  label: _vm.$t("label.theScanQty"),
                                  custom: _vm.customData["qtyScan"],
                                  "source-parse-field": _vm.sourceParseField,
                                },
                                on: {
                                  setSourceField: function ($event) {
                                    return _vm.saveSourceField($event)
                                  },
                                  valuedByFoucs: function ($event) {
                                    return _vm.valuedByFoucs($event)
                                  },
                                  enter: _vm.handleQtyScan,
                                  down: _vm.handleQtyScan,
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.handleCommonClick(50)
                                  },
                                  change: _vm.changeQtyScan,
                                  focus: _vm.focusQtyNumber,
                                },
                                model: {
                                  value: _vm.billDetail.qtyScan,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.billDetail, "qtyScan", $$v)
                                  },
                                  expression: "billDetail.qtyScan",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "hips-col",
                            { attrs: { span: "10" } },
                            [
                              _c(
                                "hips-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.customData["serialNumEntry"]
                                        ? _vm.hideControl["serialNumEntry"]
                                        : _vm.enableSerial,
                                      expression:
                                        "customData['serialNumEntry'] ? hideControl['serialNumEntry'] : enableSerial",
                                    },
                                  ],
                                  ref: "inputRef",
                                  style: Object.assign({}, _vm.inputBtnStyle, {
                                    width: "98%",
                                    "margin-left": "3px",
                                    "text-align": "center",
                                    overflow: "hidden",
                                    "text-overflow": "ellipsis",
                                  }),
                                  on: { click: _vm.goToSerial },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.serialNumberDtoList &&
                                          _vm.serialNumberDtoList.length > 0
                                          ? _vm.$t("button.serialDetail") +
                                              "(" +
                                              _vm.serialNumberDtoList.length +
                                              ")"
                                          : _vm.$t("button.seriesDetail")
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                !_vm.enableSerial
                  ? _c(
                      "div",
                      { staticStyle: { "margin-top": "18px" } },
                      [
                        _c("ips-input-number", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.customData["qtyScan"]
                                ? _vm.hideControl["qtyScan"]
                                : true,
                              expression:
                                "customData['qtyScan'] ? hideControl['qtyScan'] : true",
                            },
                          ],
                          ref: "ref50",
                          attrs: {
                            required: "",
                            "not-auto-fill": "",
                            disabled:
                              _vm.showSerialBtn || _vm.isParentCidDisabled,
                            label: _vm.$t("label.theScanQty"),
                            custom: _vm.customData["qtyScan"],
                            "source-parse-field": _vm.sourceParseField,
                          },
                          on: {
                            setSourceField: function ($event) {
                              return _vm.saveSourceField($event)
                            },
                            valuedByFoucs: function ($event) {
                              return _vm.valuedByFoucs($event)
                            },
                            enter: _vm.handleQtyScan,
                            down: _vm.handleQtyScan,
                            change: _vm.handleChange,
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.handleCommonClick(50)
                            },
                            focus: _vm.focusQtyNumber,
                          },
                          model: {
                            value: _vm.billDetail.qtyScan,
                            callback: function ($$v) {
                              _vm.$set(_vm.billDetail, "qtyScan", $$v)
                            },
                            expression: "billDetail.qtyScan",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.showQtyDialog
                  ? _c("div", { staticClass: "qty-card" }, [
                      _c("div", { staticClass: "text" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("label.qty")) +
                            "\n            "
                        ),
                        _c("span", [_vm._v(_vm._s(_vm.qty))]),
                      ]),
                      _c("div", { staticClass: "text" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("label.validQty")) +
                            "\n            "
                        ),
                        _c("span", [_vm._v(_vm._s(_vm.validQty))]),
                      ]),
                    ])
                  : _vm._e(),
                _c("ips-lov", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.customData["loadCidCode"]
                        ? _vm.hideControl["loadCidCode"]
                        : _vm.display["toCidCode"],
                      expression:
                        "customData['loadCidCode'] ? hideControl['loadCidCode'] : display['toCidCode']",
                    },
                  ],
                  ref: "ref60",
                  attrs: {
                    label: _vm.$t("label.toCidCode"),
                    required: "",
                    title: _vm.$t("label.toCidCode"),
                    params: "loadCidCode",
                    url:
                      _vm.winvBaseUrl +
                      "app-outbound-load/query-load-containers",
                    "url-params": _vm.billDetail,
                    "pre-parse-url": _vm.urlData["loadCidCode"],
                    "allow-no-exist": "",
                    custom: _vm.customData["loadCidCode"],
                    "source-parse-field": _vm.sourceParseField,
                  },
                  on: {
                    setSourceField: function ($event) {
                      return _vm.saveSourceField($event)
                    },
                    valuedByFoucs: function ($event) {
                      return _vm.valuedByFoucs($event)
                    },
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleCommonClick(60)
                    },
                    clickIcon: function ($event) {
                      return _vm.handleCommonClick(60, "lov")
                    },
                    choose: function ($event) {
                      return _vm.handleChoose("loadCid", $event)
                    },
                    enter: _vm.handleLoadEnter,
                    down: _vm.handleLoadEnter,
                    change: _vm.handleChange,
                  },
                  model: {
                    value: _vm.billDetail.loadCidCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.billDetail, "loadCidCode", $$v)
                    },
                    expression: "billDetail.loadCidCode",
                  },
                }),
                _c("ips-lov", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.customData["replaceReasonMeaning"] // 判断个性化是否启用 // 启用时判断个性化是否隐藏，隐藏直接不显示，不隐藏直接按页面参数设置判断
                        ? _vm.hideControl["replaceReasonMeaning"]
                          ? _vm.rpReasonRequired
                            ? _vm.replaceFlag
                              ? true
                              : false
                            : false
                          : false // 个性化不启用时直接按页面参数设置判断
                        : _vm.rpReasonRequired
                        ? _vm.replaceFlag
                          ? true
                          : false
                        : false,
                      expression:
                        "\n            customData['replaceReasonMeaning'] // 判断个性化是否启用 // 启用时判断个性化是否隐藏，隐藏直接不显示，不隐藏直接按页面参数设置判断\n              ? hideControl['replaceReasonMeaning']\n                ? rpReasonRequired\n                  ? replaceFlag\n                    ? true\n                    : false\n                  : false\n                : false // 个性化不启用时直接按页面参数设置判断\n              : rpReasonRequired\n                ? replaceFlag\n                  ? true\n                  : false\n                : false\n          ",
                    },
                  ],
                  ref: "ref70",
                  attrs: {
                    label: _vm.$t("label.replaceReason"),
                    required: _vm.rpReasonRequired,
                    title: _vm.$t("label.replaceReason"),
                    params: "meaning",
                    url: _vm.wmdmBaseUrl + "wms-common/lov-data-query",
                    "url-params": {
                      lovCode: "WINV.OUTBOUND_LOAD_REASON",
                      value: _vm.billDetail.replaceReason,
                    },
                    "pre-parse-url": _vm.urlData["replaceReasonMeaning"],
                    "is-page-content": false,
                    custom: _vm.customData["replaceReasonMeaning"],
                    "source-parse-field": _vm.sourceParseField,
                  },
                  on: {
                    setSourceField: function ($event) {
                      return _vm.saveSourceField($event)
                    },
                    valuedByFoucs: function ($event) {
                      return _vm.valuedByFoucs($event)
                    },
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleCommonClick(70)
                    },
                    clickIcon: function ($event) {
                      return _vm.handleCommonClick(70, "lov")
                    },
                    enter: _vm.handleReplaceEnter,
                    down: _vm.handleReplaceEnter,
                    choose: function ($event) {
                      return _vm.handleChoose("replace", $event)
                    },
                  },
                  model: {
                    value: _vm.billDetail.replaceReasonMeaning,
                    callback: function ($$v) {
                      _vm.$set(_vm.billDetail, "replaceReasonMeaning", $$v)
                    },
                    expression: "billDetail.replaceReasonMeaning",
                  },
                }),
                _c("ips-lov", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.customData["exceptionReasonMeaning"] // 判断个性化是否启用 // 启用时判断个性化是否隐藏，隐藏直接不显示，不隐藏直接按页面参数设置判断
                        ? _vm.hideControl["exceptionReasonMeaning"]
                          ? _vm.ecReasonRequired
                            ? _vm.exceptionFlag
                              ? true
                              : false
                            : false
                          : false // 个性化不启用时直接按页面参数设置判断
                        : _vm.ecReasonRequired
                        ? _vm.exceptionFlag
                          ? true
                          : false
                        : false,
                      expression:
                        "\n            customData['exceptionReasonMeaning'] // 判断个性化是否启用 // 启用时判断个性化是否隐藏，隐藏直接不显示，不隐藏直接按页面参数设置判断\n              ? hideControl['exceptionReasonMeaning']\n                ? ecReasonRequired\n                  ? exceptionFlag\n                    ? true\n                    : false\n                  : false\n                : false // 个性化不启用时直接按页面参数设置判断\n              : ecReasonRequired\n                ? exceptionFlag\n                  ? true\n                  : false\n                : false\n          ",
                    },
                  ],
                  ref: "ref80",
                  attrs: {
                    label: _vm.$t("label.exceptionReason"),
                    title: _vm.$t("label.exceptionReason"),
                    required: _vm.ecReasonRequired,
                    params: "meaning",
                    url: _vm.wmdmBaseUrl + "wms-common/lov-data-query",
                    "url-params": {
                      lovCode: "WINV.OUTBOUND_EXCEPTION_REASON",
                      value: _vm.billDetail.exceptionReason,
                    },
                    "pre-parse-url": _vm.urlData["exceptionReasonMeaning"],
                    "is-page-content": false,
                    custom: _vm.customData["replaceReasonMeaning"],
                    "source-parse-field": _vm.sourceParseField,
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleCommonClick(80)
                    },
                    setSourceField: function ($event) {
                      return _vm.saveSourceField($event)
                    },
                    valuedByFoucs: function ($event) {
                      return _vm.valuedByFoucs($event)
                    },
                    clickIcon: function ($event) {
                      return _vm.handleCommonClick(80, "lov")
                    },
                    enter: function ($event) {
                      return _vm.skipNext("submitRef")
                    },
                    down: function ($event) {
                      return _vm.skipNext("submitRef")
                    },
                    choose: function ($event) {
                      return _vm.handleChoose("exception", $event)
                    },
                  },
                  model: {
                    value: _vm.billDetail.exceptionReasonMeaning,
                    callback: function ($$v) {
                      _vm.$set(_vm.billDetail, "exceptionReasonMeaning", $$v)
                    },
                    expression: "billDetail.exceptionReasonMeaning",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "ips-container", staticStyle: { padding: "1px" } },
        [
          _c(
            "div",
            {
              staticClass: "ips-footer-more",
              staticStyle: { position: "absolute", bottom: "5px" },
            },
            [
              _c(
                "hips-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.customData["pickAndUnload"]
                        ? _vm.hideControl["pickAndUnload"]
                        : true,
                      expression:
                        "customData['pickAndUnload'] ? hideControl['pickAndUnload'] : true",
                    },
                  ],
                  attrs: { size: "middle" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleCommonSubmit("loadAndUnload")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("button.pickAndUnload")) +
                      "\n      "
                  ),
                ]
              ),
              _c(
                "hips-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.customData["overTask"]
                        ? _vm.hideControl["overTask"]
                        : true,
                      expression:
                        "customData['overTask'] ? hideControl['overTask'] : true",
                    },
                  ],
                  class: [{ "useless-button": _vm.uselessButton }],
                  attrs: { size: "middle", disabled: _vm.uselessButton },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleCommonSubmit("complete")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("button.overTask")) +
                      "\n      "
                  ),
                ]
              ),
              _c(
                "hips-button",
                {
                  ref: "submitRef",
                  attrs: { size: "middle" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleCommonSubmit("load")
                    },
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.handleCommonSubmit("load")
                    },
                    keypress: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c("svg-icon", {
                    attrs: { slot: "icon", "icon-class": "success" },
                    slot: "icon",
                  }),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("button.pickConfirm")) +
                      "\n      "
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }