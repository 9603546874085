<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.loading')" />
    </div>
    <div class="ips-container">
      <hips-tabs v-model="active">
        <hips-tab :title="$t('label.waitLoading')" :class="[{'active-tab': active===0}]">
          <!--z装入托盘输入框-->
          <ips-field
            :label="$t('label.cidMsg')"
            v-model="inputData.cidCode"
            v-show="customData['cidCode'] ? hideControl['cidCode']: true"
            params="cidCode"
            display="cidCode"
            :pre-parse-url="urlData['cidCode']"
            :custom="customData['cidCode']"
            :source-parse-field="sourceParseField"
            ref="ref0"
            required
            @enter="skipNext('submitRef')"
            @down="skipNext('submitRef')"
            @click.stop="handleCommonClick(0)"
            @blur="checkCidCodeReturn"
            @setSourceField="saveSourceField($event)"
            @valuedByFoucs="valuedByFoucs($event)"
          />
          <hips-button
            size="large"
            :disabled="!inputData.cidCode"
            @click.stop="handleCommonSubmit"
            @enter="handleCommonSubmit"
            ref="submitRef"
            :class="['load-cid-button-class', {'disable-btn': !inputData.cidCode}]"
          >
            {{ $t('button.loadingCidCode') }}
          </hips-button>
          <div style="margin-top: 16px" v-if="loadReturnData && loadReturnData.length > 0">
            <span class="bos-title-style">{{ $t('label.loadingMessage') }}</span>
            <div class="load-message-card" v-for="(item, index) in loadReturnData" :key="index">
              <!-- 父装入托盘 -->
              <ips-value-cell
                v-show="item.parentCidCode"
                :title="$t('label.shelvesParentCid')"
                :value="item.parentCidCode"
              />
              <!-- 托盘 -->
              <ips-value-cell
                :title="$t('label.shelvesCid')"
                :value="item.cidCode"
              />
              <!-- 仓库 -->
              <ips-value-cell
                class="ips-value-cell"
                :title="$t('label.warehouse')"
                :value="item.warehouseCode"
              />
              <!-- 目标库区 -->
              <ips-value-cell
                :title="$t('label.targetWharea')"
                :value="item.toWhareaCode"
              />
              <!-- 目标库位-->
              <ips-value-cell
                :title="$t('label.targetLocation')"
                :value="item.toLocationCode"
              />
              <!-- 目标托盘 -->
              <ips-value-cell
                :title="$t('label.targetTray')"
                :value="item.toCidCOde"
              />
            </div>
          </div>
        </hips-tab>
        <hips-tab :title="`${$t('label.alreadyLoading')+'('+(allCidListLength)+')'}`"
                  :class="[{'active-tab': active!==0}]"
        >
          <hips-row>
            <hips-col span="19">
              <ips-field
                :label="$t('label.cidMsg')"
                v-model="inputCidCode"
                v-show="customData['inputCidCode'] ? hideControl['inputCidCode']: true"
                params="inputCidCode"
                display="inputCidCode"
                :pre-parse-url="urlData['inputCidCode']"
                :custom="customData['inputCidCode']"
                :source-parse-field="sourceParseField"
                ref="ref10"
                @enter="skipNext('searchRef')"
                @click.stop="handleCommonClick(10)"
                @blur="checkCidCodeReturn"
                @setSourceField="saveSourceField($event)"
                @valuedByFoucs="valuedByFoucs($event)"
              />
            </hips-col>
            <hips-col span="4" class="ips-row-left">
              <hips-button
                v-show="customData['query'] ? hideControl['query']: true"
                ref="searchRef"
                class="search-cid-button-class"
                @click.stop="handleQueryAllCidCode"
                @enter="handleQueryAllCidCode"
                @keyup.enter.native="handleQueryAllCidCode"
              >
                {{ $t('button.query') }}
              </hips-button>
            </hips-col>
          </hips-row>
          <div class="load-message-card" v-for="(item, index) in allCidList" :key="index">
            <!-- 父装入托盘 -->
            <ips-value-cell 
              v-show="item.parentCidCode"
              :title="$t('label.shelvesParentCid')"
              :value="item.parentCidCode"
            />
            <!-- 托盘 -->
            <ips-value-cell
              :title="$t('label.shelvesCid')"
              :value="item.cidCode"
            />
            <!-- 仓库 -->
            <ips-value-cell
              class="ips-value-cell"
              :title="$t('label.warehouse')"
              :value="item.warehouseCode"
            />
            <!-- 目标库区 -->
            <ips-value-cell
              :title="$t('label.targetWharea')"
              :value="item.toWhareaCode"
            />
            <!-- 目标库位-->
            <ips-value-cell
              :title="$t('label.targetLocation')"
              :value="item.toLocationCode"
            />
            <!-- 目标托盘 -->
            <ips-value-cell
              :title="$t('label.targetTray')"
              :value="item.toCidCOde"
            />
          </div>
        </hips-tab>

      </hips-tabs>

    </div>
  </hips-view>
</template>
<script>
  import { Cell, Row, Col, Tabs, Tab, Search  } from "@hips/vue-ui";
  import { checkContainer, handleLoading, queryCidData, queryAllList, getAppId } from '../api';
  import { storage } from '@/utils';
  export default {
    name: 'Load',
    data(){
      return{
        active: 0,
        appId: '',
        warehouseName: '',
        cidCode: '',
        cidId: '',
        inputCidCode: '',
        whareaCode: '',
        locationCode: '',
        isSubmit: false,
        invLoad: '',
        toCidCOde: '',
        allCidList: [],  //已装入数据
        allCidListLength: '',  //已装入数据长度
        loadReturnData: [], //装入返回数组
        inputData: {
         cidCode: '',
        },
        urlData:{},
        customData: {}, // 个性化配置信息列表
        hideControl: {}, // 个性化配置显示隐藏控制
        sourceParseField: {}, // 界面中所有的来源解析字段
      }
    },
    components: {
      [Cell.name]: Cell,
      [Row.name]: Row,
      [Col.name]: Col,
      [Tabs.name]: Tabs,
      [Tab.name]: Tab,
      [Search.name]: Search,
    },
    methods: {
      /**
       *@functionName: saveSourceField
       *@description: 保存来源解析字段并赋值Enter
       *@params1: fieldObj[Object] 来源解析字段对象,
       */
      saveSourceField(fieldObj) {
        this.saveSourceParseField(this, fieldObj);
      },
      // 字段聚焦时赋值操作
      async valuedByFoucs(options={}) {
        const { custom ={} , currentValue } = options;
        await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
      },
      //  托盘校验
      async checkCidCodeReturn(){
        if(this.inputData.cidCode){
          this.$hint.showLoading();
          const data = {
            params: {
              cidCode: this.inputData.cidCode,
            }
          };
          try {
            const res = await checkContainer(data);
            this.loadReturnData = [];
            if(res && res.cidId){
              this.cidId = res.cidId;
              const dataIdData = {
                params: {
                  cidId: res.cidId,
                }
              };
              const resIdData = await queryCidData(dataIdData);
              this.invLoad = resIdData;
              this.$hint.hideLoading();
              this.isSubmit = true;
            }
          }catch (e) {
            this.skipNext(0);
            this.isSubmit = false;
            console.log(e)
          }finally {
            this.$hint.hideLoading();
          }
        }
      },
      // 装入按钮 当invLoad为0时 可以执行装入不然跳到卸货
      async handleButtonSubmit() {
      if(this.isSubmit === true){
        if(this.invLoad === 0){
          this.$hint.showLoading();
          this.handleSubmitStatus();
          const params = {
            cidId: this.cidId,
          };
          const res = await handleLoading(params);
          this.loadReturnData=res;
          this.queryLoaded();
          await this.clearSourceFields(this, true);
          this.inputData.cidCode = '';
          this.$hint.hideLoading();
          this.handleSubmitStatus();
        }else if(this.invLoad === 1){
          this.$hint.showLoading();
          this.handleSubmitStatus();
          const params = {
            cidId: this.cidId,
          };
          const resLoad = await handleLoading(params);
          this.$hint.hideLoading();
          if(resLoad){
            const param = {
              params: {
                menuName: '卸货',
              }
            };
            const res = await getAppId(param);
            this.appId = res && res[0].id;
            const appArgs =  {
              loadCidCode: this.inputData.cidCode,
              loadCidId: this.cidId,
            };
            this.$bridge.closeWebView();
            await this.$bridge.goApplication(this.appId, appArgs);
          }else {
            this.$hint.hideLoading();
            this.handleSubmitStatus();
          }
        }
      }
      },
    //  查询已装入数据
      async queryLoaded (){
        this.$hint.showLoading();
        const res = await queryAllList();
        this.allCidList = res;
        this.allCidListLength = res && res.length;
        this.$hint.hideLoading();
      },
    //  查询已装入cid数据
      async handleQueryAllCidCode(){
        this.$hint.showLoading();
        const data={
          params : {
            cidCode: this.inputCidCode,
            cidId: this.cidId,
          }
        };
        const res = await queryAllList(data);
        this.allCidList = res;
        this.$hint.hideLoading();
      },
    },
    beforeRouteLeave(to, from, next) {
      if (to.name === 'Unloading') {
        this.$store.commit('pushKeepAlives', 'Load');
      } else {
        this.$store.commit('deleteKeepAlives', 'Load');
      }
      next();
    },
    created() {
      const { customObj, hideControl } = this.handleCustomData(storage.get('customData'));
      this.customData = customObj;
      this.hideControl = hideControl;
      // console.log('装入customData', Object.assign({}, this.customData));
    },
    mounted() {
      this.inputFieldList= [
        { type: 'input', required: true, ref: 0, bind: 'inputData.cidCode', field: 'cidCode' },
        { type: 'input', required: false, ref: 10, bind: 'inputCidCode', field: 'inputCidCode' },
      ];
      this.queryLoaded();
      this.urlData = this.handleData('INV_LOAD_CONFIRM');
      this.skipNext(0)
    }
  }
</script>
<style lang="stylus" scoped>
 @import '~@/style/var.styl'
  .ips-container {
    padding: 0 !important;
    .ips-row-left{
      float right
      text-align right
    }
  }
    .load-cid-button-class {
        width: 100%;
        margin-top: 32px;
        background-color: var(--primaryColor) !important;
        color: #FFFFFF!important;
        font-size:17px;
        height: 45px;
        line-height: 36px;
        border-radius: 8px;
    }
    .search-cid-button-class {
        width: 100%;
        background-color: var(--primaryColor) !important;
        color: #FFFFFF!important;
        font-size 14px;
        height 36px;
        line-height 36px
    }
    .bos-title-style{
        width:48px;
        height:16px;
        font-size:12px;
        font-weight:400;
        color:rgba(140,140,140,1);
        line-height:16px;
    }
    .load-message-card {
        background: #fff;
        margin: 4.267vw 0 5px 0;
        /*margin-bottom: 5px;*/
        border-radius: 1.6vw;
        /*margin-top: 4.267vw;*/
        width: 100%;
        padding: 2.133vw 0px;
        color: #000;
        font-size: 3.467vw;
        box-shadow: 0 0 2.133vw #d9d9d9;
    }
    .disable-btn {
        background-color: #BFBFBF!important;
    }
    .active-tab {
        font-size: 15px!important;
        font-weight: bold!important;
        color: var(--primaryColor) !important;
    }
  .hips-tab__panel {
    padding: 4vw 0 !important;
  }
</style>
