import request from '@/request';
import mixin from '@/mixin/mixin';

export function checkSerialInbound(data) {
  const url = `${mixin.data().winvBaseUrl}inbound-receipt-records/check-serial-inbound`;
  return request.post(url, data);
}

// 动态获取功能模块
export function getFunctionCategorys(data) {
  const url = `${mixin.data().winvBaseUrl}function-categorys/app-permission-list`;
  return request.get(url, data);
}

// 获取我的配置
export function getUsersSelf(data) {
  return request.get('/iam/hzero/v1/users/self', data);
}

// 获取登录界面 logo
export function getLogo(data) {
  return request.get('/oauth/public/fetch-app-logo', data);
}
// 获取首页界面 logo
export function getHomeLogo(data) {
  const url = `${mixin.data().hpfmBaseUrl}config/value`;
  return request.get(url, data);
}
