var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: { title: _vm.$t("title.inventoryTransfer") },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["fromCidCode"]
                ? _vm.hideControl["fromCidCode"]
                : true,
              expression:
                "customData['fromCidCode'] ? hideControl['fromCidCode']: true",
            },
          ],
          ref: "ref0",
          attrs: {
            label: _vm.$t("label.startOfTray"),
            title: _vm.$t("label.startOfTray"),
            required: "",
            params: "cidCode",
            url:
              _vm.winvBaseUrl + "app-stock-trans/query-whole-trans-container",
            "pre-parse-url": _vm.urlData["fromCidCode"],
            custom: _vm.customData["fromCidCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(0)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(0, "lov")
            },
            enter: function ($event) {
              return _vm.skipNext(20)
            },
            down: function ($event) {
              return _vm.skipNext(20)
            },
            choose: function ($event) {
              return _vm.handleChoose("fromCidCode", $event)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.fromCidCode,
            callback: function ($$v) {
              _vm.fromCidCode = $$v
            },
            expression: "fromCidCode",
          },
        }),
        _c("ips-value-cell", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["whareaCode"]
                ? _vm.hideControl["whareaCode"]
                : true,
              expression:
                "customData['whareaCode'] ? hideControl['whareaCode']: true",
            },
          ],
          attrs: {
            title: _vm.$t("label.sourceOfWharea"),
            value: _vm.cidInfo.whareaCode,
          },
        }),
        _c("ips-value-cell", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["locationCode"]
                ? _vm.hideControl["locationCode"]
                : true,
              expression:
                "customData['locationCode'] ? hideControl['locationCode']: true",
            },
          ],
          attrs: {
            title: _vm.$t("label.startOfLocation"),
            value: _vm.cidInfo.locationCode,
          },
        }),
        _c("ips-value-cell", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["qty"] ? _vm.hideControl["qty"] : true,
              expression: "customData['qty'] ? hideControl['qty']: true",
            },
          ],
          attrs: {
            title: _vm.$t("label.availableQty"),
            value: _vm.qtyInfo.qty,
          },
        }),
        _c("ips-value-cell", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["validQty"]
                ? _vm.hideControl["validQty"]
                : true,
              expression:
                "customData['validQty'] ? hideControl['validQty']: true",
            },
          ],
          attrs: { title: _vm.$t("label.useQty"), value: _vm.qtyInfo.validQty },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["toCidCode"]
                ? _vm.hideControl["toCidCode"]
                : true,
              expression:
                "customData['toCidCode'] ? hideControl['toCidCode']: true",
            },
          ],
          ref: "ref10",
          attrs: {
            label: _vm.$t("label.targetTray"),
            title: _vm.$t("label.targetTray"),
            required: "",
            params: "cidCode",
            disabled: _vm.hasFromOutermostCidCode,
            url:
              _vm.winvBaseUrl + "app-stock-trans/query-wholetrans-to-container",
            "url-params": _vm.toCidCodeParam,
            "pre-parse-url": _vm.urlData["toCidCode"],
            custom: _vm.customData["toCidCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(10)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(10, "lov")
            },
            enter: _vm.handleToCidEnter,
            down: _vm.handleToCidEnter,
            choose: function ($event) {
              return _vm.handleChoose("toCidCode", $event)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.toCidCode,
            callback: function ($$v) {
              _vm.toCidCode = $$v
            },
            expression: "toCidCode",
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["toLocationCode"]
                ? _vm.hideControl["toLocationCode"]
                : true,
              expression:
                "customData['toLocationCode'] ? hideControl['toLocationCode']: true",
            },
          ],
          ref: "ref20",
          attrs: {
            label: _vm.$t("label.targetLocation"),
            title: _vm.$t("label.targetLocation"),
            required: "",
            params: "locationCode",
            url: _vm.wmdmBaseUrl + "wms-locations/lov",
            "url-params": _vm.toLocationParam,
            "pre-parse-url": _vm.urlData["toLocationCode"],
            custom: _vm.customData["toLocationCode"],
            "source-parse-field": _vm.sourceParseField,
            disabled: _vm.isToCidCode,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(20)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(20, "lov")
            },
            enter: function ($event) {
              return _vm.skipNext("submitRef")
            },
            down: function ($event) {
              return _vm.skipNext("submitRef")
            },
            choose: function ($event) {
              return _vm.handleChoose("toLocationCode", $event)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.toLocationCode,
            callback: function ($$v) {
              _vm.toLocationCode = $$v
            },
            expression: "toLocationCode",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-footer" },
      [
        _c(
          "hips-button",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.customData["theDetail"]
                  ? _vm.hideControl["theDetail"]
                  : true,
                expression:
                  "customData['theDetail'] ? hideControl['theDetail']: true",
              },
            ],
            attrs: { size: "large" },
            on: { click: _vm.goDetail },
          },
          [
            _c("svg-icon", {
              attrs: { slot: "icon", "icon-class": "detail" },
              slot: "icon",
            }),
            _vm._v("\n      " + _vm._s(_vm.$t("button.theDetail")) + "\n    "),
          ],
          1
        ),
        _c(
          "hips-button",
          {
            ref: "submitRef",
            attrs: { size: "large" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonSubmit.apply(null, arguments)
              },
            },
          },
          [
            _c("svg-icon", {
              attrs: { slot: "icon", "icon-class": "success" },
              slot: "icon",
            }),
            _vm._v("\n      " + _vm._s(_vm.$t("button.confirm")) + "\n    "),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }