<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <!-- 托盘明细 -->
      <ips-header
        :title="$t('title.taskDetail')"
        :is-index="false"
      />
    </div>
    <div class="ips-container">
      <hips-scroll>
        <ips-page-card
          v-for="(item, index) in taskList"
          :key="index"
          :react-field="['sku', 'cidCode', 'batchCode', 'qtyPlan']"
          :react-field-name="{
            // 取值字段和字段名称特殊对应
            'qtyPlan': 'quantity', 
          }"
          :item="{...item}"
          :title-info="{ 'billCode': item.billCode }"
        />
      </hips-scroll>
      <!-- <div class="ips-table">
        <table>
          <tr style="background-color: #eee">
            <th v-for="(item, index) in tableTitle" :key="index" scope="col">
              {{ item.name }}
            </th>
          </tr>
          <tr 
            v-for="(item, index) in taskList" 
            :key="index"
            :ref="`tr${index}`"      
          >
            <td>{{ item.billCode }}</td>
            <td>{{ item.sku }}</td>
            <td>{{ item.batchCode }}</td>
            <td>{{ item.qtyPlan }}</td>
            <td>{{ item.qtyPlan }}</td>
          </tr>
        </table>
      </div> -->
    </div>
  </hips-view>
</template>

<script>
import { queryTrayDetail } from '../api';
export default {
  name: 'TrayDetail',
  data() {
    return {
      taskList: [],
      tableTitle: [],
    };
  },
  methods: {
    /**
     * 查询托盘明细数据
    */
    getTableData() {
      const { cidCode, status } = this.$route.params;
      const data = {
        params: {
          cidCode,
          status,
        }
      };
      this.$hint.showLoading();
      queryTrayDetail(data).then((res)=>{
        this.taskList = res
      }).finally(()=>{
        this.$hint.hideLoading();
      })
    },
  },
  mounted() {
    this.tableTitle = [
      {
        name: this.$t('label.billId'), //单号
      },
      {
        name: this.$t('label.sku'), // 物料
      },
      {
        name: this.$t('label.batch'), // 批次
      },
      {
        name: this.$t('label.quantity'), // 数量
      }
    ];
    this.getTableData();
  },
};
</script>

<style lang="stylus" scoped>
 @import '~@/style/var.styl'
.ips-table {
  width: 100%
  overflow-x: auto
  table {
    width: 100%
  }
  th, td {
    padding: 5px 2px
    border: 1px solid #ccc
    text-align: center
    white-space: nowrap
  }
}
</style>

