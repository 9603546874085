<template>
  <hips-view>
    <ips-header class="ips-header-height" :title="$t('title.serialNumber')" />
    <div class="ips-container">
      {{ $t('button.skuItemNo') }}：{{ $route.query.sku }}
      <div class="ips-table">
        <table>
          <tr style="background-color: #eee;">
            <th class="ips-table-thead" v-for="(item, index) in tableTitle" :key="index" scope="col">
              {{ item.name }}
            </th>
          </tr>
          <tr v-for="(item, index) in tableData" :key="index" :ref="`tr${index}`">
            <td>{{ index + 1 }}</td>
            <td>{{ item.serialNumber }}</td>
          </tr>
        </table>
      </div>
    </div>
  </hips-view>
</template>

<script>
export default {
  name: 'SerialNumberDetails',
  data() {
    return {
      tableTitle: [
        {
          name: this.$t('label.batch'),
        },
        {
          name: this.$t('label.serialNumber'),
        },
      ],
      tableData: [],
    };
  },
  mounted() {
    this.tableData = this.$storage.get('serialNumberDetailsData');
  },
};
</script>

<style lang="stylus" scoped>
 @import '~@/style/var.styl'
.ips-table {
    margin-top 16px;
    width: 100%;
    .ips-table-thead{
        width:180px;
        height:26px;
        background:rgba(255,255,255,1);
        border-radius:12px 0px 0px 0px;
        line-height 26px;
        text-align center;
        font-weight bold;
        font-size 14px;
        padding-left 12px;
    }

    table {
        width: 100%;
        th, td {
            padding: 5px 2px;
            border: 1px solid #D9D9D9;
            text-align: center;
            white-space: nowrap;
            font-size 14px;
            color:rgba(89,89,89,1);
            &:first-child {
              width:40px;
            }
        }

        .back-green {
            background-color: #cbdeb8;
        }
    }
}
</style>
