<template>
  <div :class="['valueCell', {'cell_special_background': specialBackground}]">
    <img :src="imgSrc" alt="" v-if="selfIcon">
    <hips-cell :title="title" :value="value" />
  </div>
</template>

<script>
import { Cell } from "@hips/vue-ui";

export default {
  name: "ValueCell",
  components: {
    [Cell.name]: Cell
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    // eslint-disable-next-line vue/require-default-prop
    value: {
      type: [String, Number],
      default: '无',
    },
    // 自定义图标
    selfIcon: {
      type: String,
      default: '',
    },
    // 自定义背景样式
    specialBackground: {
      type: Boolean,
      default: false,
    }
  },
  created () {
    this.imgSrc = this.selfIcon ? require(`../assets/${this.selfIcon}.png`) : null;
    // console.log('imgSrc', this.imgSrc)
  },
};
</script>

<style lang="stylus">
@import '~@/style/var.styl'
.valueCell {
  position: relative
  top: 5px;

  img{ 
    width: 25px;
    height: 25px;
    position: absolute;
    left: 0;
    top: 3px;
    z-index :999
    background-color: none
  }

  .hips-cell {
    padding: 1vw 0 
    background-color: #fff;
  }

  .hips-cell__title {
    color: #8c8c8c;
    margin-left: 0.5em;

    span {
      position: relative
    }
  }

  .hips-cell__value {
    flex: 4;
    text-align: left;
    color: #8c8c8c;
  }
}

.cell_special_background {
    background-color: var(--primaryColor) !important;
    .hips-cell  {
      background-color: #e6f7ff;
    }
    .hips-thinline {
      background-color: #e6f7ff !important;

      .hips-cell__title {
        margin-left: 2.5em !important;
        color: var(--primaryColor) !important;
      }
      
      .hips-cell__value {
        color: var(--primaryColor) !important;
      }
    }
    
  }


</style>
