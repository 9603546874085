<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.skuPropertyUpdate')" :is-index="true" />
    </div>
    <div class="ips-container">
      <!--测量标准-->
      <ips-lov
        :label="$t('label.measurementStandard')"
        :title="$t('label.measurementStandard')"
        v-model="measurementStandard"
        required
        ref="ref0"
        params="meaning"
        display="meaning"
        :is-page-content="false"
        :url="`${wmdmBaseUrl}goods/measurement-type-lov`"
        @enter="skipNext(1)"
        @down="skipNext(1)"
        @choose="handleChoose('measurementStandard', $event)"
        @click.stop="handleCommonClick(0)"
        @clickIcon="handleCommonClick(0, 'lov')"
      />
      <!--物料-->
      <ips-lov
        :label="$t('label.sku')"
        :title="[$t('label.skuCode'), $t('label.goodsName')]"
        v-model="sku"
        ref="ref1"
        required
        :params="['sku', 'goodsName']"
        display="sku"
        :url="`${wmdmBaseUrl}goods`"
        :url-params="{skuName: sku}"
        @enter="handleSkuEnter"
        @blur="handleCommonBlur(1)"
        @choose="handleChoose('sku', $event)"
        @click.stop="handleCommonClick(1)"
        @clickIcon="handleCommonClick(1, 'lov')"
      />
      <!-- 品名 -->
      <ips-value-cell
        class="goods-name"
        :title="$t('label.goodsName')"
        image-flag
        :value="skuData.goodsName"
      />
      <!-- 箱内数量 -->
      <ips-input-number
        class="qtyInbox"
        :label="$t('label.qtyInBox')"
        v-show="measurementType==='BOX'"
        v-model="boxQty"
        :required="measurementType==='BOX'"
        :min="null"
        :more-than-zero="true"
        :precision="0"
        ref="ref2"
        not-auto-fill
        @enter="skipNext(3)"
        @down="skipNext(3)"
        @blur="handleCommonBlur(2)"
      />
      <hips-row>
        <hips-col span="16">
          <ips-input-number
            v-model="weight"
            :label="measurementType==='BOX' ? $t('label.weightBox') : $t('label.weight')"
            ref="ref3"
            :min="null"
            :more-than-zero="true"
            not-auto-fill
            @enter="skipNext(4)"
            @down="skipNext(4)"
            @click.stop="handleCommonClick(3)"
          />
          <ips-input-number
            v-model="length"
            :label="measurementType==='BOX' ? $t('label.lengthBox') : $t('label.length')"
            ref="ref4"
            :min="null"
            :more-than-zero="true"
            not-auto-fill
            @enter="skipNext(5)"
            @down="skipNext(5)"
            @click.stop="handleCommonClick(4)"
          />
          <ips-input-number
            v-model="width"
            :label="measurementType==='BOX' ? $t('label.widthBox') : $t('label.width')"
            ref="ref5"
            :min="null"
            :more-than-zero="true"
            not-auto-fill
            @enter="skipNext(6)"
            @down="skipNext(6)"
            @click.stop="handleCommonClick(5)"
          />
          <ips-input-number
            v-model="height"
            :label="measurementType==='BOX' ? $t('label.heightBox') : $t('label.height')"
            ref="ref6"
            :min="null"
            :more-than-zero="true"
            not-auto-fill
            @enter="skipNext('submitRef')"
            @down="skipNext('submitRef')"
            @click.stop="handleCommonClick(6)"
          />
        </hips-col>
        <hips-col span="8">
          <ips-field
            v-model="measurementData.weightUnit"
            :label="$t('label.weightUnit')"
            disabled
          />
          <ips-field
            v-model="measurementData.lengthUnit"
            :label="$t('label.lengthUnit')"
            disabled
          />
          <ips-field
            v-model="measurementData.widthUnit"
            :label="$t('label.widthUnit')"
            disabled
          />
          <ips-field
            v-model="measurementData.heightUnit"
            :label="$t('label.heightUnit')"
            disabled
          />
        </hips-col>
      </hips-row>
      <div class="out_box">
        <hips-row>
          <div style="margin: 10px 15px;font-size: 20px;">
            单物料属性
          </div>
          <hips-col span="12">
            <!-- 重量 -->
            <hips-cell
              :title="$t('label.weight')"
              style="color: #8C8C8C;"
              image-flag
              :value="skuData.grossWeight? skuData.grossWeight+'kg': skuData.grossWeight"
            />
            <!-- 宽度 -->
            <hips-cell
              :title="$t('label.width')"
              style="color: #8C8C8C;"
              image-flag
              :value="skuData.width? skuData.width+'cm': skuData.width"
            />
            <!-- 体积 -->
            <!-- <hips-cell
              title="体积:"
              style="color: #8C8C8C;"
              image-flag
              :value="(skuData.length & skuData.length & skuData.width) ? (Number(skuData.length * skuData.length * skuData.width)/1000000000)+'m³': null"
            /> -->
          </hips-col>
          <hips-col span="12">
            <!-- 长度 -->
            <hips-cell
              :title="$t('label.length')"
              style="color: #8C8C8C;"
              image-flag
              :value="skuData.length? skuData.length+'cm': skuData.length"
            />
            <!-- 高度 -->
            <hips-cell
              :title="$t('label.height')"
              style="color: #8C8C8C;"
              image-flag
              :value="skuData.height? skuData.height+'cm': skuData.height"
            />
            <!-- 最后更新人 -->
            <!-- <hips-cell
              title="最后更新人:"
              style="color: #8C8C8C;"
              image-flag
              :value="skuData.lastUpdatedName"
            /> -->
          </hips-col>
          <!-- 体积 -->
          <hips-cell
            title="体积"
            style="color: #8C8C8C;"
            image-flag
            :value="skuData.volume? skuData.volume+'m³': skuData.volume"
          />
          <hips-cell
            title="最后更新人"
            style="color: #8C8C8C;"
            image-flag
            :value="skuData.lastUpdatedName"
          />
        </hips-row>
        
      </div>
      <div class="ips-footer">
        <hips-button
          size="large"
          ref="submitRef"
          @click="handleCommonSubmit"
        >
          <svg-icon slot="icon" icon-class="success" />
          {{ $t('button.confirm') }}
        </hips-button>
      </div>
    </div>
  </hips-view>
</template>

<script>
  import { Cell, Row, Col } from '@hips/vue-ui';
  import { handleSaveData } from '../api';
  // import { storage } from '@/utils';
  export default {
    name: 'SkuPropertyUpdate',
    data() {
      return {
        measurementStandard: '',
        measurementType:'',
        measurementData: {
          weightUnit: null,
          lengthUnit: null,
          widthUnit: null,
          heightUnit: null,
        },
        sku: '',
        boxQty: null,
        weight: null,
        length: null,
        width: null,
        height: null,
        skuData:{
          goodsName: '',
          grossWeight: null,
          length: null,
          width: null,
          height: null,
          volume: null,
        },
      };
    },

    mounted() {
      this.inputFieldList = [
        {type: 'lov', required: true, ref: 0, bind: 'measurementStandard' },
        {type: 'lov', required: true, ref: 1, bind: 'sku' },
        {type: 'number', required: this.measurementType==='BOX', ref: 2, bind: 'boxQty' },
        {type: 'number', required: false, ref: 3, bind: 'weight' },
        {type: 'number', required: false, ref: 4, bind: 'length' },
        {type: 'number', required: false, ref: 5, bind: 'width' },
        {type: 'number', required: false, ref: 6, bind: 'height' },
      ];
      this.skipNext(0);
    },

    components: {
      [Cell.name]: Cell,
      [Row.name]: Row,
      [Col.name]: Col,
    },

    watch: {
      'sku':function(val){
        if(!val){
          this.boxQty = null;
          this.weight = null;
          this.length = null;
          this.width = null;
          this.height = null;
          this.skuData={};
          this.skipNext(1)
        }
      },
      'measurementStandard':function(val, oldval){
        if(val !== oldval){
          this.sku = '';
          this.skuData = {};
          this.boxQty = null;
          this.weight = null;
          this.length = null;
          this.width = null;
          this.height = null;
        }
        if(!val){
          this.measurementType = '',
          this.measurementData = {};
          this.skipNext(0)
        }
      }
    },

    methods: {
      handleChoose(type, obj) {
        switch (type) {
          case 'measurementStandard':
            this.measurementType = obj.value;
            this.measurementData.weightUnit = obj.description,
            this.measurementData.lengthUnit = obj.tag,
            this.measurementData.widthUnit = obj.tag,
            this.measurementData.heightUnit = obj.tag,
            this.skipNext(1);
            break;
          case 'sku':
            this.skuData = obj;
            if(this.measurementType === 'BOX'){
              this.skipNext(2);
            }else {
              this.skipNext(3)
            }
            break;
          default:
            return null;
        }
        console.log(this.measurementData);
      },

      handleSkuEnter(){
        if(this.sku){
          if(this.measurementType === 'BOX'){
            this.skipNext(2);
          }else {
            this.skipNext(3)
          }
        } 
      },

      // 物料属性更新数据提交
      async handleButtonSubmit() {
        if(!this.sku){
          this.$hint.showFailed(this.$i18n.t('message.checkRequired'));
          this.skipNext(1);
          return;
        }
        if(this.measurementType === 'BOX' && this.boxQty < 1){
          this.$hint.showFailed(this.$i18n.t('message.quantityMin'));
          this.boxQty = null;
          this.skipNext(2);
          return;
        }
        const params={
          measurementType: this.measurementType,
          sku: this.sku, 
          goodsId: this.skuData.goodsId,
          boxQty: this.boxQty,
          weight: this.weight,
          length: this.length,
          width: this.width,
          height: this.height,
          weightUnit: this.measurementData.weightUnit,
          capacityUnit: this.measurementData.lengthUnit,
        };
        const res = await handleSaveData(params);
        if(res){
          this.sku = '';       
          this.skuData={};
          this.boxQty = null,
          this.weight = null,
          this.length = null,
          this.width = null,
          this.height = null,
          this.$hint.showSuccess(this.$i18n.t('message.operationSuccess'));
          this.skipNext(1);
        }
      },
    }
  }
</script>

<style lang="stylus" scoped>
.qtyInbox {
  margin-top: 0
  margin-bottom: 4.267vw
}
.out_box {
  border-radius: 1.6vw;
  margin-top: 2.267vw;
  width: 100%;
  padding: 2vw 0px;
  color: #000;
  box-shadow: 0 0 2.133vw #d9d9d9;
}

.goods-name{
  padding:10px 0
}
.valueCell{
  position: relative;
top:0;
}


</style>
