var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: { title: _vm.$t("title.whareaStock"), "is-index": false },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "header-row" }, [
      _c("div", { staticClass: "header-title" }, [
        _vm._v(
          "\n      " +
            _vm._s(
              (_vm.batchWhareaData[0] && _vm.batchWhareaData[0].batchCode) ||
                "--"
            ) +
            "\n    "
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _vm.batchWhareaData.length <= 0 ? _c("ips-no-data") : _vm._e(),
        _vm.batchWhareaData.length > 0
          ? _c(
              "div",
              _vm._l(_vm.batchWhareaData, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "card_countLocation",
                    on: {
                      click: function ($event) {
                        return _vm.toBatchWarehouse(item)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("label.warehouse")) +
                            ":\n          "
                        ),
                      ]),
                      _c("div", { staticClass: "value" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.batchData.warehouseName) +
                            "\n          "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("label.whareaCode")) +
                            ":\n          "
                        ),
                      ]),
                      _c("div", { staticClass: "value" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(item.whareaCode) +
                            "\n          "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "row-50" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("label.sku")) +
                              ":\n            "
                          ),
                        ]),
                        _c("div", { staticClass: "value" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.sku) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "row-50" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("label.goodsName")) +
                              ":\n            "
                          ),
                        ]),
                        _c("div", { staticClass: "value" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.goodsName) +
                              "\n            "
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("label.batch")) +
                            ":\n          "
                        ),
                      ]),
                      _c("div", { staticClass: "value" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.batchData.batchCode || "--") +
                            "\n          "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "row-50" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("label.availableQty")) +
                              ":\n            "
                          ),
                        ]),
                        _c("div", { staticClass: "label-number" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.qty) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "row-50" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("label.currentUseQty")) +
                              ":\n            "
                          ),
                        ]),
                        _c("div", { staticClass: "label-number" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.validQty) +
                              "\n            "
                          ),
                        ]),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "row-detail" },
                      [
                        _c("div", { staticClass: "title" }, [
                          _c("a", { staticClass: "detail" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("label.detail")) +
                                "\n            "
                            ),
                          ]),
                        ]),
                        _c("hips-icon", {
                          staticClass: "ips-icon",
                          attrs: { name: "arrow", size: "30" },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }