<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.shelvesDetail')" :is-index="false" />
    </div>
    <div class="ips-container">
      <div style="padding: 0 12px; font-size: 14px">
        <span style="color: #595959">{{ $t('label.receiveCid') }} :</span>
        <span style="font-weight: bold; color: #262626">
          {{ receiveCidCode }}
        </span>
      </div>
      <div class="shelves-detail-multiple-card">
        <div style="font-size: 14px; font-weight: bold">
          {{ $t('label.skuCode') }}
        </div>
        <hr style="margin: 10px 0px; border: 0.5px solid #d8d8d8">
        <span class="shelves-detail-multiple-card-sku-style" v-for="(item, index) in detailData" :key="index">
          {{ item.sku }}
        </span>
      </div>
      <hips-row style="margin-top: 16px" v-if="serialType !== 'UNCTRL' && serialType !== 'OUT_CTRL'">
        <hips-col span="14">
          <ips-input-number
            :disabled="true"
            v-model="receiveQty"
            :label="$t('label.inboundNum')"
            required
            :custom="customData['receiveQty']"
            :source-parse-field="sourceParseField"
            :enable-serial="serialType"
            v-show="customData['receiveQty'] ? hideControl['receiveQty'] : true"
            ref="ref10"
            :min="null"
            :precision="0"
            @enter="skipNext(20)"
            @setSourceField="saveSourceField($event)"
            @valuedByFoucs="valuedByFoucs($event)"
          />
        </hips-col>
        <hips-col span="10">
          <hips-button
            size="large"
            @click.stop="goToSerialDetail"
            ref="refSerail"
            class="serial-button-detail"
            v-show="customData['serialNumber'] ? hideControl['serialNumber'] : true"
          >
            {{ $t('button.serialNumber') }}({{ serialQtyNum }})
          </hips-button>
        </hips-col>
      </hips-row>
      <div style="margin-top: 16px" v-if="serialType === 'UNCTRL' || serialType === 'OUT_CTRL'">
        <ips-input-number
          :disabled="true"
          v-model="receiveQty"
          :label="$t('label.inboundNum')"
          required
          ref="ref50"
          :custom="customData['receiveQty']"
          :source-parse-field="sourceParseField"
          v-show="customData['receiveQty'] ? hideControl['receiveQty'] : true"
          :min="null"
          :enable-serial="serialType"
          :precision="0"
          @enter="checkImpQtyUnSerial"
          @down="checkImpQtyUnSerial"
          @blur="checkImpQtyUnSerial"
          @setSourceField="saveSourceField($event)"
          @valuedByFoucs="valuedByFoucs($event)"
        />
      </div>
      <div style="margin-top: 16px">
        <!--入库库位-->
        <ips-lov
          :label="$t('label.storageLocation')"
          v-model="inputData.locationCode"
          required
          :title="$t('label.storageLocation')"
          params="locationCode"
          :url="`${wmdmBaseUrl}wms-locations`"
          :url-params="{ warehouseId: warehouseId, whareaId: whareaId }"
          ref="ref20"
          :custom="customData['locationCode']"
          :source-parse-field="sourceParseField"
          v-show="customData['locationCode'] ? hideControl['locationCode'] : true"
          @enter="checkToRouter"
          @down="checkToRouter"
          @click.stop="handleCommonClick(20)"
          @clickIcon="handleCommonClick(20, 'lov')"
          @choose="handleChoose('storageLocation', $event)"
          @setSourceField="saveSourceField($event)"
          @valuedByFoucs="valuedByFoucs($event)"
        />
      </div>
      <!-- 推荐库位 -->
      <div class="recommend-loaction">
        <span>{{ $t('label.recommandLoca') }} :</span>
        <span>{{ defaultLocation }}</span>
      </div>
      <div style="margin-top: 16px" v-if="inputData.containerManageFlag === 1">
        <!--入库托盘-->
        <ips-field
          v-if="inputData.containerManageFlag === 1"
          :disabled="inputData.containerManageFlag === 1"
          v-model="inputData.inboundCidCode"
          :label="$t('label.inboundCid')"
          ref="ref30"
          :custom="customData['inboundCidCode']"
          :source-parse-field="sourceParseField"
          v-show="customData['inboundCidCode'] ? hideControl['inboundCidCode'] : true"
          @enter="skipNext('submitRef')"
          @down="skipNext('submitRef')"
          @click.stop="handleCommonClick(submitRef)"
          @blur="checkInboundCidBlur"
          @setSourceField="saveSourceField($event)"
          @valuedByFoucs="valuedByFoucs($event)"
        />
      </div>
    </div>
    <div class="ips-footer">
      <hips-button size="large" @click.stop="submitShelves" ref="submitRef">
        <svg-icon slot="icon" icon-class="success" />
        {{ $t('button.submit') }}
      </hips-button>
    </div>
  </hips-view>
</template>
<script>
import { Cell, Row, Col } from '@hips/vue-ui';
import {
  queryCidData,
  querySummaryData,
  queryDefaultLocation,
  checkInboundCid,
  handleSubmit,
  checkContainerPackObject,
} from '../api/index';

export default {
  name: 'ShelvesDetailMultiple',
  data() {
    return {
      cidId: '',
      cidCode: '',
      orgId: '',
      orgCode: '',
      billTypeId: '',
      billType: '',
      billId: '',
      billCode: '',
      sourceBillId: '',
      serialType: '',
      recordSize: '',
      warehouseId: '',

      goodsId: '',
      pendingQty: '',
      detailData: [],
      receiveCidCode: '',
      defaultLocation: '',
      firstPageData: '',
      receiptNumber: '',
      whareaIdSku: '',
      whareaId: '',
      goodsObj: '',
      detailIdObj: [],
      serialQtyNum: '',
      inputData: {
        batchCode: '',
        locationCode: '',
        locationId: '',
        whareaId: '',
        whareaCode: '',
        inboundCidCode: '',
        inboundCidId: '',
        containerManageFlag: '',
      },
      customData: {}, // 个性化配置信息列表
      hideControl: {}, // 非必输字段的显隐控制
      sourceParseField: {}, // 界面中所有的来源解析字段
    };
  },
  components: {
    [Cell.name]: Cell,
    [Row.name]: Row,
    [Col.name]: Col,
  },
  methods: {
    // 保存来源解析字段
    async saveSourceField(fieldObj) {
      this.saveSourceParseField(this, fieldObj);
    },
    // 字段聚焦时赋值操作
    async valuedByFoucs(options = {}) {
      const { custom = {}, currentValue } = options;
      await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
    },

    handleChoose(type, obj) {
      const { parentCidCode, parentCidId, cidCode, cidId } = this.detailData[0];
      switch (type) {
        case 'batch':
          this.batchId = obj.batchId;
          break;
        case 'storageLocation':
          this.inputData.locationId = obj.locationId;
          this.inputData.locationCode = obj.locationCode;
          this.inputData.whareaCode = obj.whareaCode;
          this.inputData.whareaId = obj.whareaId;
          this.inputData.containerManageFlag = obj.containerManageFlag;
          this.inputData.inboundCidCode = obj.containerManageFlag ? parentCidCode || cidCode : '';
          this.inputData.inboundCidId = obj.containerManageFlag ? parentCidId || cidId : '';
          break;
      }
    },
    async getDetailData() {
      // this.$hint.showLoading();
      let data = {
        params: {
          parentCidId: this.parentCidId,
          cidId: this.cidId,
          sourceBillId: this.sourceBillId,
          billId: this.billId,
          receiptNumber: this.receiptNumber,
          billTypeId: this.billTypeId,
          billType: this.billType,
        },
      };
      // const res =await queryCidData(data);
      const res = await querySummaryData(data);
      this.detailData = res.content;
      this.receiveCidCode = res.content[0].parentCidCode || res.content[0].cidCode;
      // this.receiveQty = res.content[0].pendingQty;
      const resDetail = await queryCidData(data);
      this.whareaId = resDetail.content[0].assignWhareaId;
      this.goodsObj = resDetail.content;
      let num = 0;
      let numQty = 0;
      resDetail.content.forEach((v) => {
        this.detailIdObj.push(v.detailId);
        num += Number(v.receiveQty);
        if (v.serialType !== 'UNCTRL' && v.serialType !== 'OUT_CTRL') {
          numQty += v.receiveQty;
        }
      });
      this.receiveQty = num;
      this.serialQtyNum = numQty;
      const resLocation = await queryDefaultLocation(resDetail.content);
      // const resLocation =await queryDefaultLocation(resDetail.content[0]);
      this.defaultLocation = resLocation.locationCode;
      this.recordSize = resDetail.content.length;
      this.orgCode = res.content[0].orgCode;
      this.orgId = res.content[0].orgId;
      this.cidId = res.content[0].cidId;
      this.cidCode = res.content[0].cidCode;
      this.billCode = res.content[0].billCode;
      // this.$hint.hideLoading();

      let dataObj = {
        cidId: this.cidId,
        goodsId: this.goodsId,
        sourceBillId: this.sourceBillId,
        billId: this.billId,
        batchId: this.batchId,
        serialType: this.serialType,
        detailIdTmpList: this.detailIdObj,
        // serialNumber: this.serialNumber,
      };
      // const res =  await querySerialDetail(data);
      const url = `${this.winvBaseUrl}app-direct-putaway/query-serial`;
      const resObj = await this.$http.post(url, dataObj);
      this.inboundSerialReceiptList = resObj;
    },

    async checkInboundCidBlur() {
      if (this.inputData.inboundCidCode) {
        let data = {
          params: {
            parentCidId: this.parentCidId,
            cidId: this.cidId,
            cidCode: this.cidCode,
            billId: this.billId,
            billCode: this.billCode,
            locationCode: this.inputData.locationCode,
            locationId: this.inputData.locationId,
            inboundCidCode: this.inputData.inboundCidCode,
            recordSize: this.recordSize,
            orgCode: this.orgCode,
            orgId: this.orgId,
            billTypeId: this.billTypeId,
            billType: this.billType,
          },
        };
        try {
          this.$hint.showLoading();
          // const url = `${this.winvBaseUrl}app-inbound-receive-adjust/receive-reduce`;
          const res = await checkInboundCid(data);
          this.inputData.inboundCidCode = res.cidCode;
          this.inputData.inboundCidId = res.cidId;
          return true;
        } catch (e) {
          this.$nextTick(() => {
            this.skipNext(30);
          });
          console.log('错误：' + e);
        } finally {
          this.$hint.hideLoading();
        }
      }
    },
    checkToRouter() {
      this.skipNext('submitRef');
      // if(this.inputData.containerManageFlag===1){
      //   this.$nextTick(()=>{
      //     this.skipNext(30);
      //   })
      // }else {
      //   this.skipNext('submitRef')
      // }
    },
    goToDirectly() {
      const param = {
        name: 'directlyShelves',
      };
      sessionStorage.setItem('formSerial', JSON.stringify('1'));
      this.$router.pushPage(param);
    },
    async goToSerialDetail() {
      let data = {
        cidId: this.cidId,
        goodsId: this.goodsId,
        sourceBillId: this.sourceBillId,
        billId: this.billId,
        batchId: this.batchId,
        serialType: this.serialType,
        detailIdTmpList: this.detailIdObj,
        // serialNumber: this.serialNumber,
      };
      // const res =  await querySerialDetail(data);
      const url = `${this.winvBaseUrl}app-direct-putaway/query-serial`;
      const res = await this.$http.post(url, data);
      this.inboundSerialReceiptList = res;
      const param = {
        name: 'serialComponent',
        params: {
          data,
          pendingQty: this.pendingQty,
          serialQtyNum: this.serialQtyNum,
          receiveQty: this.receiveQty,
          serialType: this.serialType,
          detailIdTmpList: this.detailIdObj,
          task: res || [],
          serialStatus: 'new',
        },
      };
      this.$router.pushPage(param);
    },
    checkImpQtyUnSerial() {
      if (this.pendingQty - this.receiveQty < 0) {
        this.$hint.showFailed(this.$i18n.t('message.unSerialMsg'));
        this.$nextTick(() => {
          this.skipNext(50);
        });
        return;
      }
      this.skipNext(20);
    },
    async submitShelves() {
      if (!this.inputData.locationCode) {
        this.$hint.showFailed(this.$i18n.t('message.checkRequired'), true);
        return;
      }
      if (this.inputData.inboundCidCode) {
        const checkInCid = await this.checkInboundCidBlur();
        if (!checkInCid) return;
      }
      console.log('cidCode:', this.receiveCidCode);
      // await checkContainerPackObject({cidCode: this.receiveCidCode});
      try {
        this.$hint.showLoading();
        const checkData = {
          params: { cidCode: this.receiveCidCode },
        };
        await checkContainerPackObject(checkData);

        const params = {
          ...this.firstPageData,
          inboundQty: this.receiveQty,
          inLocationId: this.inputData.locationId,
          inLocationCode: this.inputData.locationCode,
          inWhareaId: this.inputData.whareaId,
          inWhareaCode: this.inputData.whareaCode,
          batchId: this.batchId,
          batchCode: this.inputData.batchCode,
          // inCidCode: this.inputData.inboundCidCode,
          // inCidId: this.inputData.inboundCid,
          inboundSerialReceiptList: [],
        };
        const obj = [];
        this.goodsObj.forEach((v) => {
          // obj.push({ ...params,...v,})
          obj.push({
            ...params,
            parentCidId: null,
            parentCideCode: null,
            ...v,
            inCidCode: this.inputData.containerManageFlag === 1 ? v.cidCode : null,
            inCidId: this.inputData.containerManageFlag === 1 ? v.cidId : null,
            inParentCidCode: this.inputData.containerManageFlag === 1 ? v.parentCidCode : null,
            inParentCidId: this.inputData.containerManageFlag === 1 ? v.parentCidId : null,
          });
        });
        const inboundReceiptRecordList = [];
        inboundReceiptRecordList.push(...obj);
        const inboundSerialReceiptsMutiplyGoods = this.inboundSerialReceiptList;
        let data = {
          inboundReceiptRecordList,
          inboundSerialReceiptsMutiplyGoods,
        };
        await handleSubmit(data);
        await this.goToDirectly();
      } catch (e) {
        console.log(e);
      } finally {
        // 清除当前界面的二维码解析字段
        await this.clearSourceFields(this, true);
        this.$hint.hideLoading();
      }
    },
  },

  mounted() {
    this.inputFieldList = [
      { type: 'input', required: true, ref: 10, bind: 'pendingQty', field: 'pendingQty' },
      { type: 'lov', required: true, ref: 20, bind: 'inputData.locationCode', field: 'locationCode' },
      { type: 'input', required: false, ref: 30, bind: 'inputData.inboundCidCode', field: 'inboundCidCode' },
    ];
    const { sourceParseField, customData, hideControl } = this.$route.params;
    this.sourceParseField = sourceParseField;
    this.customData = customData;
    this.hideControl = hideControl;
    if (this.serialType === 'UNCTRL' || this.serialType === 'OUT_CTRL') {
      this.$nextTick(() => {
        this.skipNext(50);
      });
    }
    this.skipNext(20);
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'serialComponent') {
      this.$store.commit('pushKeepAlives', 'ShelvesDetailMultiple');
    } else {
      this.$store.commit('deleteKeepAlives', 'ShelvesDetailMultiple');
    }
    next();
  },
  activated() {
    this.whareaId = '';
    const inboundSerialReceiptList = JSON.parse(sessionStorage.getItem('inboundSerialReceiptList'));
    this.inboundSerialReceiptList = inboundSerialReceiptList;
    sessionStorage.removeItem('inboundSerialReceiptList');
  },
  created() {
    const {
      parentCidId,
      cidId,
      goodsId,
      sourceBillId,
      billId,
      billTypeId,
      billType,
      serialType,
      firstPageData,
      warehouseId,
      receiptNumber,
      whareaIdSku,
    } = this.$route.params;
    this.parentCidId = parentCidId;
    this.cidId = cidId;
    this.goodsId = goodsId;
    this.sourceBillId = sourceBillId;
    this.billId = billId;
    this.billTypeId = billTypeId;
    this.billType = billType;
    this.serialType = serialType;
    this.warehouseId = warehouseId;
    this.firstPageData = firstPageData;
    this.receiptNumber = receiptNumber;
    this.whareaIdSku = whareaIdSku;
    this.getDetailData();
  },
};
</script>
<style lang="stylus" scoped>
  @import '~@/style/var.styl'
 .shelves-detail-multiple-card {
   border-radius: 1.6vw;
   color: #000;
   font-size: 14px;
   box-shadow: 0 0 2.133vw #d9d9d9;
   margin-top: 16px;
   padding: 14px 12px;
   box-sizing: border-box;
  }
 .shelves-detail-multiple-card-sku-style {
     display: inline-block;
     background-color: #E3F5FF;
     margin-right: 16px;
     color: var(--primaryColor);
     border:1px solid #E6F7FF;
     border-radius: 22px;
     font-size: 14px;
     height: 16px;
     line-height: 16px;
     padding:7px 14px;
 }
.serial-button-detail{
  margin-left: 10px;
  background-color: var(--primaryColor)!important;
  color: #FFFFFF!important;
  font-size:14px;
  height: 36px!important;
  line-height: 36px!important;
}
.recommend-loaction {
  border: 1px solid #91D5FF
  line-height: 30px
  border-radius: 3px
  background-color: #BAE7FF
  font-size: 12px
  color: var(--primaryColor)
  padding: 0 10px
  margin-top 4px
  span {
    &:first-child {
      margin-right: 10px
    }
  }
}
</style>
