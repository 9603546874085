// import Login from '../views/login'
import Receive from '../views/receive';
import ReceiveConfirm from '../views/receive-confirm';
import ReceiveDetail from '../views/receive-detail';
import ReceiveCidDetail from '../views/receive-cid-detail';
import ReceiveParentCidDetail from '../views/receive-parent-cid-detail';

let route = [
  {
    path: '/receive',
    name: 'Receive',
    component: Receive,
  },
  {
    path: '/receive-confirm',
    name: 'ReceiveConfirm',
    component: ReceiveConfirm,
  },
  {
    path: '/receive-detail',
    name: 'ReceiveDetail',
    component: ReceiveDetail,
  },
  {
    path: '/receive-cid-detail',
    name: 'ReceiveCidDetail',
    component: ReceiveCidDetail,
  },
  {
    path: '/receive-parent-cid-detail',
    name: 'ReceiveParentCidDetail',
    component: ReceiveParentCidDetail,
  },
];

export default route;
