<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.unloading')" @beforeBack="clearStorage" />
    </div>
    <div class="ips-container">
      <!--页面只有一个lov 所以绑定isOnly-->
      <!-- 转运托盘 -->
      <ips-lov
        :label="$t('label.toCidCode')"
        :title="$t('label.toCidCode')"
        required
        params="loadCidCode"
        v-model="loadCidCode"
        v-show="customData['loadCidCode'] ? hideControl['loadCidCode'] : true"
        :url="`${winvBaseUrl}app-outbound-unload/query-unload-containers`"
        :pre-parse-url="urlData['loadCidCode']"
        :custom="customData['loadCidCode']"
        :source-parse-field="sourceParseField"
        ref="ref0"
        is-only
        @choose="handleChoose"
        @enter="skipNext('submitRef')"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
    </div>
    <div class="ips-footer">
      <hips-button
        size="large"
        ref="submitRef"
        @click.stop="handleCommonSubmit"
        @keyup.enter.native="handleCommonSubmit"
        @keypress.native.prevent
      >
        <svg-icon slot="icon" icon-class="success" />{{ $t('button.confirm') }}
      </hips-button>
    </div>
  </hips-view>
</template>

<script>
import { queryUnloadTask, queryLoadCidId, cancelUnloading } from '../api';
import { storage } from '@/utils';
export default {
  name: 'Unloading',
  data() {
    return {
      loadCidCode: '',
      urlData: {},
      customData: {}, // 个性化配置信息列表
      hideControl: {}, // 个性化配置显示隐藏控制
      sourceParseField: {}, // 界面中所有的来源解析字段
    };
  },
  methods: {
    /**
     *@functionName: saveSourceField
     *@description: 保存来源解析字段并赋值Enter
     *@params1: fieldObj[Object] 来源解析字段对象,
     fieldCode[String] 来源解析字段
     parseValue[String] 二维码解析值
     subFields[Array]] 关联子字段
     */
    saveSourceField(fieldObj) {
      this.saveSourceParseField(this, fieldObj);
    },
    // 字段聚焦时赋值操作
    async valuedByFoucs(options = {}) {
      const { custom = {}, currentValue } = options;
      await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
    },
    // lov选择事件
    handleChoose(obj) {
      this.loadCidId = obj.loadCidId;
    },
    handleButtonSubmit() {
      this.handleGo();
    },
    // 跳转详情界面
    async handleGo() {
      try {
        const { loadCidCode, loadCidId } = this;
        this.$hint.showLoading();
        this.handleSubmitStatus();
        let params = {
          loadCidCode,
          loadCidId,
        };
        const taskList = await queryUnloadTask({ params });
        if (taskList && taskList.length === 0) {
          this.$hint.showFailed(this.$i18n.t('message.notFoundTask'), true);
          return;
        }
        for (const index in taskList) {
          taskList[index].selected = true;
        }
        const routerParam = {
          name: 'unloadingDetail',
          params: {
            loadCidId,
            loadCidCode,
            taskList,
            sourceParseField: { ...this.sourceParseField },
            customData: this.customData,
            hideControl: this.hideControl,
            index: true,
          },
        };
        this.$router.push(routerParam);
      } finally {
        this.$hint.hideLoading();
        this.handleSubmitStatus();
      }
    },

    async clearStorage() {
      await this.$storage.remove('argsExtra');
      return null;
    },

    async getLoadCidId(loadCidCode) {
      try {
        const param = {
          params: {
            loadCidCode,
          },
        };
        const res = await queryLoadCidId(param);
        this.loadCidId = res.content[0].loadCidId;
        console.log(res.content[0].loadCidId);
      } catch {
        this.$refs['ref0'].focus();
      }
    },
  },
  created() {
    const { customObj, hideControl } = this.handleCustomData(storage.get('customData'));
    this.customData = customObj;
    this.hideControl = hideControl;
    // console.log('卸货customData', Object.assign({}, this.customData));
  },
  async mounted() {
    await cancelUnloading({});
    this.urlData = this.handleData('DROP_TASK_QUERY');
    this.inputFieldList = [{ type: 'lov', required: true, ref: 0, bind: 'loadCidCode', field: 'loadCidCode' }];
    this.skipNext(0);
    const argsExtra = this.$route.query || this.$storage.get('argsExtra');
    if (argsExtra) {
      const { loadCidCode, loadCidId = null } = argsExtra;
      if (loadCidCode && !loadCidId) {
        await this.getLoadCidId(loadCidCode);
      } else {
        this.loadCidId = loadCidId;
      }
      this.loadCidCode = loadCidCode;
      if (this.loadCidCode && this.loadCidId) {
        this.$storage.set('argsExtra', {});
        await this.handleGo();
      }
    }
  },
};
</script>

<style lang="stylus" scoped></style>
