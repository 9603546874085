import { render, staticRenderFns } from "./inbound-cid-detail.vue?vue&type=template&id=f85c2a84&scoped=true&"
import script from "./inbound-cid-detail.vue?vue&type=script&lang=js&"
export * from "./inbound-cid-detail.vue?vue&type=script&lang=js&"
import style0 from "./inbound-cid-detail.vue?vue&type=style&index=0&id=f85c2a84&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f85c2a84",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/app/wms-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f85c2a84')) {
      api.createRecord('f85c2a84', component.options)
    } else {
      api.reload('f85c2a84', component.options)
    }
    module.hot.accept("./inbound-cid-detail.vue?vue&type=template&id=f85c2a84&scoped=true&", function () {
      api.rerender('f85c2a84', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/direct-inbound/views/inbound-cid-detail.vue"
export default component.exports