import request from '@/request';
import mixin  from '@/mixin/mixin';


// 查询
export function getSubinventory(data, queryURL) {
  let url;
  if(queryURL){
    url = queryURL;
  }else {
    url = `${mixin.data().wmdmBaseUrl}mdm-info/query-mdm-code`;
  }
  return request.get(url, data);
}

// 查询类型
export function getStockTransferType(data) {
  const url = `${mixin.data().winvBaseUrl}new-stock-trans/query-page-param`;
  return request.get(url, data);
}

// 物料数量查询
export function getSkuNumber(data) {
  const url = `${mixin.data().winvBaseUrl}new-stock-trans/query-goods-number`;
  return request.get(url, data);
}

// 子库存转移-物料
export function skuStockTrans(data) {
  let url = `${mixin.data().winvBaseUrl}new-stock-trans/goods-stock-transfer`;
  return request.post(url, data);
}

// 序列号查询
export function getSerialData(data) {
  const url = `${mixin.data().winvBaseUrl}new-stock-trans/query-serial-from`;
  return request.get(url, data);
}

// 序列号查询可用库存量
export function getLocNumber(data) {
  const url = `${mixin.data().winvBaseUrl}new-stock-trans/query-location-validQty`;
  return request.get(url, data);
}

// 子库存转移-序列号
export function serialStockTrans(data) {
  let url = `${mixin.data().winvBaseUrl}new-stock-trans/serial-stock-transfer`;
  return request.post(url, data);
}

// 托盘查询
export function getCidData(data) {
  const url = `${mixin.data().winvBaseUrl}new-stock-trans/query-stock-container`;
  return request.get(url, data);
}

// 托盘明细查询
export function getCidDetail(data) {
  const url = `${mixin.data().winvBaseUrl}new-stock-trans/query-container-detail`;
  return request.get(url, data);
}

// 子库存转移-托盘
export function cidStockTrans(data) {
  let url = `${mixin.data().winvBaseUrl}new-stock-trans/container-stock-transfer`;
  return request.post(url, data);
}

// 目标父托盘校验
export function toParentCidCheck(data) {
  let url = `${mixin.data().winvBaseUrl}app-stock-trans/check-parent-cid`;
  return request.post(url, data);
}

// 查询页面参数
export function getparentCidEnabled(data) {
  let url = `${mixin.data().winvBaseUrl}page-function-paramss/info`;
  return request.get(url, data);
}