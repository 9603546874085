<template>
  <div class="ips-input ips-input-common">
    <div :class="validateClass">
      <div
        :class="[
          'ips-input-wrapper',
          'ips-input-has-label',
          'ips-input-has-border',
          {
            'ips-input-has-value': nativeInputValue,
            'ips-input-focused': focused,
            'ips-has-append': $slots.append,
            'is-disabled': disabled,
            'disabled-background': disBackground,
            'is-required': required,
            'back-required': backRequired,
            'nolabel-required': nolabelRequired
          }]"
      >
        <input
          class="ips-input__inner"
          v-bind="$attrs"
          :type="type"
          :disabled="disabled"
          :disBackground="disBackground"
          :readonly="readonly"
          ref="input"
          @input="handleInput"
          @focus="handleFocus"
          @click="handleClick"
          @blur="handleBlur"
          @change="handleChange"
          @keyup.enter="handleEnter"
          @keydown.down="handleDown"
          @keydown.tab="handleTab"
        >
        <div class="ips-input-label-wrapper" v-if="label">
          <div class="ips-input-label">
            {{ label }}
          </div>
        </div>
        <div class="ips-input-group__append" v-if="$slots.append">
          <slot name="append" />
        </div>
        <div class="ips-error-suffix" v-show="validateClass === 'has-error' || validateClass === 'has-nodata'">
          <svg-icon icon-class="fail" />
          <div class="ips-toolTip">
            <div class="ips-toolTip__message">
              {{ errorMsg || $t('message.mustEnter') }}
            </div>
            <div class="popper__arrow" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'IpsInput',
    componentName: 'IpsInput',
    inheritAttrs: false,
    data() {
      return {
        focused: false,
        validateClass: 'has-normal'
      };
    },
    props: {
      // eslint-disable-next-line vue/require-default-prop
      value: [String, Number],
      disabled: Boolean,
      disBackground: Boolean,
      required: Boolean,
      backRequired: Boolean,
      nolabelRequired: Boolean,
      readonly: Boolean,
      lov: Boolean,
      type: {
        type: String,
        default: 'text'
      },
      label: {
        type: String,
        default: '',
      },
      isError: {
        type: Boolean,
        default: false
      },
      errorMsg: {
        type: String,
        // default: '必输'
        default: function (){
          return this.$t('message.mustEnter');
        }
      },
    },
    computed: {
      nativeInputValue() {
        return this.value === null || this.value === undefined ? '' : String(this.value);
      },
    },
    watch: {
      // native input value is set explicitly
      // do not use v-model / :value in template
      nativeInputValue(val) {
        if (this.required && val) {
          this.validateClass = 'has-success'
        }
        this.setNativeInputValue();
      },
      isError(val) {
        if(val && this.required) {
          this.validateClass = 'has-error'
        }else if(val && !this.required){ // 非必输无数据情况
          this.validateClass = 'has-nodata'
        }else {
          this.validateClass = 'has-normal'
        }
      }
    },
    mounted() {
      this.setNativeInputValue();
    },
    methods: {
      async focus() {
        console.log('触发input focus');

        const input = this.$refs.input;
        input.focus();
      },
      blur() {
        console.log('触发input blur');

        this.$refs.input.blur();
      },
      handleBlur(event) {
        this.focused = false;
        this.$emit('blur', event);
      },
      handleEnter(event) {
        let value = event.target.value.trim();
        // todo设置正则校验输入值，是否含有不规则字符
        if ( !this.lov && this.required && value === '' || value === undefined || value === null) {
          this.validateClass = 'has-error'
        }
        if ((!this.lov && this.validateClass === 'has-error' || this.isError) && this.validateClass !== 'has-nodata') {
          this.focus();
          return;
        }
        this.$emit('enter', event);
      },
      handleDown(event) {
        let value = event.target.value.trim();
        if ( !this.lov && this.required && value === '' || value === undefined || value === null) {
          this.validateClass = 'has-error'
        }
        if ((!this.lov && this.validateClass === 'has-error' || this.isError)) {
          this.focus();
          return;
        }
        this.$emit('down', event);
      },
      handleTab(){
        let value = event.target.value.trim();
        if ( !this.lov && this.required && value === '' || value === undefined || value === null) {
          this.validateClass = 'has-error'
        }
        if ((!this.lov && this.validateClass === 'has-error' || this.isError) && this.validateClass !== 'has-nodata') {
          this.focus();
          return;
        }
        this.$emit('tab', event)
      },
      setNativeInputValue() {
        const input = this.$refs.input;
        if (!input) return;
        if (input.value === this.nativeInputValue) return;
        input.value = this.nativeInputValue;
      },
      handleFocus(event) {
        this.focused = true;
        // this.focus();
        this.$emit('focus', event);
      },
      handleClick(event) {
        this.$emit('click', event);
      },
      handleInput(event) {
        // not update v-model when composing
        if (event.target.composing) {
          return;
        }
        if (event.target.value === this.nativeInputValue) return;
        this.$emit('input', event.target.value.trim());
        // ensure native input value is controlled
        this.$nextTick(() => {
          if (this.required && !event.target.value) {
            this.validateClass = 'has-error'
          }
          // this.setNativeInputValue();
        });
      },
      handleChange(event) {
        this.$emit('change', event.target.value.trim());
      },
    },
  };
</script>
