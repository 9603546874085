var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: {
            title: _vm.$t("title.directOut"),
            trigger: _vm.isBatchListShow ? _vm.$t("title.batchList") : "",
            "on-go-detail": _vm.goDetail,
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["billCode"]
                ? _vm.hideControl["billCode"]
                : true,
              expression:
                "customData['billCode'] ? hideControl['billCode'] : true",
            },
          ],
          ref: "ref0",
          attrs: {
            label: _vm.$t("label.deliveryCode"),
            required: "",
            params: "billCode",
            title: _vm.$t("label.deliveryCode"),
            url: _vm.winvBaseUrl + "app-direct-pick/query-bills",
            "pre-parse-url": _vm.urlData["billCode"],
            custom: _vm.customData["billCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(0)
            },
            choose: function ($event) {
              return _vm.chooseBillCode($event)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(0, "lov")
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.billCode,
            callback: function ($$v) {
              _vm.billCode = $$v
            },
            expression: "billCode",
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["sku"] ? _vm.hideControl["sku"] : true,
              expression: "customData['sku'] ? hideControl['sku'] : true",
            },
          ],
          ref: "ref10",
          attrs: {
            label: _vm.$t("label.sku"),
            required: "",
            title: [
              _vm.$t("label.lineNum"),
              _vm.$t("label.sku"),
              _vm.$t("label.goodsName"),
            ],
            params: ["lineNum", "sku", "goodsName"],
            "max-col-num": 3,
            display: "sku",
            url: _vm.skuUrl,
            "url-params": { billId: _vm.billId, ownerId: _vm.billOwnerId },
            "pre-parse-url": _vm.urlData["sku"],
            custom: _vm.customData["sku"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            enter: function ($event) {
              return _vm.handleSkip("ref10")
            },
            down: function ($event) {
              return _vm.handleSkip("ref10")
            },
            choose: function ($event) {
              return _vm.chooseSku($event)
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(10)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(10, "lov")
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.sku,
            callback: function ($$v) {
              _vm.sku = $$v
            },
            expression: "sku",
          },
        }),
        _c("ips-value-cell", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["goodsName"]
                ? _vm.hideControl["goodsName"]
                : true,
              expression:
                "customData['goodsName'] ? hideControl['goodsName'] : true",
            },
          ],
          attrs: { title: _vm.$t("label.goodsName"), value: _vm.goodsName },
        }),
        _c("ips-value-cell", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["uomName"]
                ? _vm.hideControl["uomName"]
                : true,
              expression:
                "customData['uomName'] ? hideControl['uomName'] : true",
            },
          ],
          attrs: { title: _vm.$t("label.unit"), value: _vm.uomName },
        }),
        _c("ips-value-cell", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["fromWarehouseShow"]
                ? _vm.hideControl["fromWarehouseShow"]
                : _vm.fromWarehouseShow,
              expression:
                "customData['fromWarehouseShow'] ? hideControl['fromWarehouseShow'] : fromWarehouseShow",
            },
          ],
          attrs: {
            title: _vm.$t("label.fromWarehouse"),
            value: _vm.fromWarehouseName,
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["fromWhareaCode"]
                ? _vm.hideControl["fromWhareaCode"]
                : true,
              expression:
                "customData['fromWhareaCode'] ? hideControl['fromWhareaCode'] : true",
            },
          ],
          ref: "ref30",
          attrs: {
            label: _vm.$t("label.sourceWharea"),
            required: "",
            disabled: _vm.fromWhareaCodeDisabled,
            title: _vm.$t("label.sourceWharea"),
            params: "whareaCode",
            url: _vm.winvBaseUrl + "app-stock-trans/query-trans-wharea",
            "url-params": {
              warehouseId: _vm.fromWarehouseId,
              goodsId: _vm.goodsId,
              ownerId: _vm.ownerId,
            },
            "pre-parse-url": _vm.urlData["fromWhareaCode"],
            custom: _vm.customData["fromWhareaCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(30)
            },
            enter: function ($event) {
              return _vm.skipNext(40)
            },
            down: function ($event) {
              return _vm.skipNext(40)
            },
            choose: function ($event) {
              return _vm.handleChoose("fromWhareaCode", $event)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(30, "lov")
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.fromWhareaCode,
            callback: function ($$v) {
              _vm.fromWhareaCode = $$v
            },
            expression: "fromWhareaCode",
          },
        }),
        _c("ips-value-cell", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.scanFromWhareaId ? true : false,
              expression: "scanFromWhareaId ? true : false",
            },
          ],
          attrs: {
            "self-icon": "batch",
            "special-background": true,
            title: _vm.$t("label.suggestedWhareaCode"),
            value: _vm.scanFromWhareaCode,
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["fromLocationCode"]
                ? _vm.hideControl["fromLocationCode"]
                : true,
              expression:
                "customData['fromLocationCode'] ? hideControl['fromLocationCode'] : true",
            },
          ],
          ref: "ref40",
          attrs: {
            label: _vm.$t("label.sourceLocation"),
            required: "",
            title: _vm.$t("label.sourceLocation"),
            params: "locationCode",
            url: _vm.winvBaseUrl + "app-stock-trans/query-trans-location",
            "url-params": {
              goodsId: _vm.goodsId,
              whareaId: _vm.fromWhareaId,
              ownerId: _vm.ownerId,
              warehouseId: _vm.fromWarehouseId,
            },
            "pre-parse-url": _vm.urlData["fromLocationCode"],
            custom: _vm.customData["fromLocationCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(40)
            },
            enter: function ($event) {
              return _vm.handleSkip("ref40")
            },
            down: function ($event) {
              return _vm.handleSkip("ref40")
            },
            choose: function ($event) {
              return _vm.handleChoose("fromLocationCode", $event)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(40, "lov")
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.fromLocationCode,
            callback: function ($$v) {
              _vm.fromLocationCode = $$v
            },
            expression: "fromLocationCode",
          },
        }),
        _c("ips-value-cell", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.scanFromLocationId ? true : false,
              expression: "scanFromLocationId ? true : false",
            },
          ],
          attrs: {
            "self-icon": "batch",
            "special-background": true,
            title: _vm.$t("label.suggestedLocation"),
            value: _vm.scanFromLocationCode,
          },
        }),
        _vm.containerManageFlag
          ? _c("ips-lov", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.customData["fromCidCode"]
                    ? _vm.hideControl["fromCidCode"]
                    : true,
                  expression:
                    "customData['fromCidCode'] ? hideControl['fromCidCode']: true",
                },
              ],
              ref: "ref50",
              attrs: {
                params: "cidCode",
                label: _vm.$t("label.sourceTray"),
                title: _vm.$t("label.sourceTray"),
                url:
                  _vm.winvBaseUrl +
                  "app-direct-pick/outbound-query-trans-container",
                "url-params": {
                  locationId: _vm.fromLocationId,
                  goodsId: _vm.goodsId,
                  cidCode: _vm.fromCidCode,
                },
                "pre-parse-url": _vm.urlData["fromCidCode"],
                custom: _vm.customData["fromCidCode"],
                "source-parse-field": _vm.sourceParseField,
              },
              on: {
                setSourceField: function ($event) {
                  return _vm.saveSourceField($event)
                },
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleCommonClick(50)
                },
                enter: _vm.cidSkipNext,
                down: _vm.cidSkipNext,
                choose: function ($event) {
                  return _vm.handleChoose("fromCidCode", $event)
                },
                clickIcon: function ($event) {
                  return _vm.handleCommonClick(50, "lov")
                },
                valuedByFoucs: function ($event) {
                  return _vm.valuedByFoucs($event)
                },
              },
              model: {
                value: _vm.fromCidCode,
                callback: function ($$v) {
                  _vm.fromCidCode = $$v
                },
                expression: "fromCidCode",
              },
            })
          : _vm._e(),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isBatchListShow
                ? !_vm.isBatchListShow
                : _vm.customData["batch"]
                ? _vm.hideControl["batch"]
                : _vm.isbatch
                ? _vm.isbatch
                : false,
              expression:
                " isBatchListShow ? !isBatchListShow : (customData['batch'] ? hideControl['batch'] : isbatch ? isbatch : false) ",
            },
          ],
          ref: "ref55",
          attrs: {
            label: _vm.$t("label.batch"),
            title: _vm.$t("label.batch"),
            required: !!_vm.isbatch,
            params: "batchCode",
            display: "batchCode",
            disabled: _vm.batchDisabled,
            url: _vm.winvBaseUrl + "app-direct-pick/outbound-query-trans-batch",
            "url-params": {
              orgId: _vm.orgId,
              warehouseId: _vm.fromWarehouseId,
              ownerId: _vm.ownerId,
              goodsId: _vm.goodsId,
              locationId: _vm.fromLocationId,
              cidId: _vm.fromCidId,
            },
            "pre-parse-url": _vm.urlData["batchCode"],
            custom: _vm.customData["batch"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(55)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(55, "lov")
            },
            enter: function ($event) {
              return _vm.handleSkip("ref55")
            },
            down: function ($event) {
              return _vm.handleSkip("ref55")
            },
            choose: function ($event) {
              return _vm.handleChoose("batch", $event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.batch,
            callback: function ($$v) {
              _vm.batch = $$v
            },
            expression: "batch",
          },
        }),
        _c("ips-value-cell", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !!_vm.scanBatchId,
              expression: "!!scanBatchId",
            },
          ],
          attrs: {
            "self-icon": "batch",
            "special-background": true,
            title: _vm.$t("label.suggestedBatch"),
            value: _vm.scanBatchCode,
          },
        }),
        _c("ips-value-cell", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["plusQty"]
                ? _vm.hideControl["plusQty"]
                : true,
              expression:
                "customData['plusQty'] ? hideControl['plusQty']: true",
            },
          ],
          attrs: { title: _vm.$t("label.canOutQty"), value: _vm.plusQty },
        }),
        _c("ips-input-number", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["planQty"]
                ? _vm.hideControl["planQty"]
                : true,
              expression:
                "customData['planQty'] ? hideControl['planQty']: true",
            },
          ],
          ref: "ref57",
          attrs: {
            required: "",
            disabled: _vm.isTransfer || _vm.isParentCidDisabled,
            label: _vm.$t("label.outQty"),
            custom: _vm.customData["planQty"],
            "source-parse-field": _vm.sourceParseField,
            "enable-serial": _vm.serialType,
            min: null,
            "more-than-zero": true,
            "not-auto-fill": "",
          },
          on: {
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            enter: function ($event) {
              return _vm.handleSkip("ref57")
            },
            down: function ($event) {
              return _vm.handleSkip("ref57")
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(57)
            },
            blur: _vm.checkQtyBlur,
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
            focus: _vm.handlePlanQtyFocus,
          },
          model: {
            value: _vm.planQty,
            callback: function ($$v) {
              _vm.planQty = $$v
            },
            expression: "planQty",
          },
        }),
        _vm.showQtyDialog
          ? _c("div", { staticClass: "qty-card" }, [
              _c("div", { staticClass: "text" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("label.qty")) + "\n        "
                ),
                _c("span", [_vm._v(_vm._s(_vm.qty))]),
              ]),
              _c("div", { staticClass: "text" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("label.validQty")) + "\n        "
                ),
                _c("span", [_vm._v(_vm._s(_vm.validQty))]),
              ]),
            ])
          : _vm._e(),
        _vm.isTransfer
          ? _c(
              "div",
              { staticClass: "input-button" },
              [
                _c("ipsSerialComp", {
                  ref: "inputRef",
                  attrs: { params: _vm.serialCompParams },
                  on: {
                    serialNumberList: _vm.changeSerialNumberList,
                    skipNextField: function ($event) {
                      return _vm.skipNext(60)
                    },
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c("ips-value-cell", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["toWarehouseName"]
                ? _vm.hideControl["toWarehouseName"]
                : _vm.toWarehouseShow,
              expression:
                "customData['toWarehouseName'] ? hideControl['toWarehouseName'] : toWarehouseShow",
            },
          ],
          attrs: {
            title: _vm.$t("label.toWarehouse"),
            value: _vm.toWarehouseName,
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["toWhareaCode"]
                ? _vm.hideControl["toWhareaCode"]
                : _vm.showTarget,
              expression:
                "customData['toWhareaCode'] ? hideControl['toWhareaCode']: showTarget",
            },
          ],
          ref: "ref60",
          attrs: {
            label: _vm.$t("label.targetWharea"),
            required: _vm.showTarget,
            disabled: _vm.toWhareaCodeDisabled,
            title: _vm.$t("label.targetWharea"),
            params: "whareaCode",
            url: _vm.wmdmBaseUrl + "whareas/accessible-list",
            "url-params": { warehouseId: _vm.toWarehouseId },
            "pre-parse-url": _vm.urlData["toWhareaCode"],
            custom: _vm.customData["toWhareaCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(60)
            },
            enter: function ($event) {
              return _vm.skipNext(70)
            },
            down: function ($event) {
              return _vm.skipNext(70)
            },
            choose: function ($event) {
              return _vm.handleChoose("toWhareaCode", $event)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(60, "lov")
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.toWhareaCode,
            callback: function ($$v) {
              _vm.toWhareaCode = $$v
            },
            expression: "toWhareaCode",
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["toLocationCode"]
                ? _vm.hideControl["toLocationCode"]
                : _vm.showTarget,
              expression:
                "customData['toLocationCode'] ? hideControl['toLocationCode']: showTarget",
            },
          ],
          ref: "ref70",
          attrs: {
            label: _vm.$t("label.targetLocation"),
            required: _vm.showTarget,
            title: _vm.$t("label.targetLocation"),
            params: "locationCode",
            url: _vm.wmdmBaseUrl + "wms-locations/app",
            "url-params": {
              whareaId: _vm.toWhareaId,
              warehouseId: _vm.toWarehouseId,
            },
            "pre-parse-url": _vm.urlData["toLocationCode"],
            custom: _vm.customData["toLocationCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(70)
            },
            enter: _vm.toLocationSkipNext,
            down: _vm.toLocationSkipNext,
            choose: function ($event) {
              return _vm.chooseToLocation($event)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(70, "lov")
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.toLocationCode,
            callback: function ($$v) {
              _vm.toLocationCode = $$v
            },
            expression: "toLocationCode",
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.targetPlantShow && _vm.targetControlShow && _vm.showTarget
                  ? _vm.customData["toCidCode"]
                    ? _vm.hideControl["toCidCode"]
                    : true
                  : _vm.targetPlantShow &&
                    _vm.targetControlShow &&
                    _vm.showTarget,
              expression:
                "targetPlantShow && targetControlShow && showTarget ? customData['toCidCode'] ? hideControl['toCidCode']: true : targetPlantShow && targetControlShow && showTarget",
            },
          ],
          ref: "ref80",
          attrs: {
            label: _vm.$t("label.targetTray"),
            title: _vm.$t("label.targetTray"),
            params: "cidCode",
            url: _vm.winvBaseUrl + "app-direct-pick/query-to-cid",
            "url-params": {
              fromCidId: _vm.fromCidId,
              fromCidCode: _vm.fromCidCode,
              sourceBillType: _vm.sourceBillType,
              toLocationId: _vm.toLocationId,
              isNew: _vm.isNew,
              parentCidFlag: _vm.parentCidFlag,
              toCidCode: _vm.toCidCode,
              toWarehouseId: _vm.toWarehouseId,
            },
            "pre-parse-url": _vm.urlData["toCidCode"],
            custom: _vm.customData["toCidCode"],
            "source-parse-field": _vm.sourceParseField,
            "allow-no-exist": "",
          },
          on: {
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(80)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(80, "lov")
            },
            choose: function ($event) {
              return _vm.handleChoose("toCidCode", $event)
            },
            enter: function ($event) {
              return _vm.skipNext("submitRef")
            },
            down: function ($event) {
              return _vm.skipNext("submitRef")
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.toCidCode,
            callback: function ($$v) {
              _vm.toCidCode = $$v
            },
            expression: "toCidCode",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-footer" },
      [
        _c(
          "hips-button",
          {
            ref: "submitRef",
            attrs: { size: "large" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonSubmit.apply(null, arguments)
              },
            },
          },
          [
            _c("svg-icon", {
              attrs: { slot: "icon", "icon-class": "success" },
              slot: "icon",
            }),
            _vm._v(_vm._s(_vm.$t("button.confirm")) + "\n    "),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }