<template>
  <div className="text-a">
    <ips-header title="测试a"/>
    <input type="text" placeholder="输入框1" v-model="input.a">
    <input type="text" placeholder="输入框2" v-model="input.b">
    <input type="text" placeholder="输入框3" v-model="input.c">
    <input type="text" placeholder="输入框4" v-model="input.d">
    <input type="text" placeholder="请输入">
    <button @click="go">
      前往b页面
    </button>
    <button @click="click('success')">
      显示成功消息
    </button>
    <br>
    <button @click="click('failed')">
      显示失败消息
    </button>
    <br>
    <button @click="click('alert')">
      显示alert框
    </button>
    <br>
    <button @click="click('64')">
      图片上传
    </button>
    <br>
    <button @click="click('goApp')">
      前往其他子应用
    </button>
    <br><br><br><br><br>
    <ips-picture @changeImg="changeImg"/>
    <ips-date-picker/>
    <input type="text" placeholder="请输入测试数据">
  </div>
</template>
h
<script>
export default {
  name: 'Texta',
  data() {
    return {
      input: {},
      show: false,
      url: '',
      imageList: [],
      scrollOptions: {
        pullDownRefresh: {
          threshold: 60, // 触发 pullingDown 的距离
          stop: 40, // pullingDown 正在刷新 hold 时的距离
          txt: 'loading',
        },
        pullUpLoad: {
          txt: {
            more: 'loading More',
            noMore: 'no More',
          },
        },
      },
    };
  },
  watch: {
    show: (newv) => {
      console.log('父组件' + newv);
    },
  },
  methods: {
    choose() {
      console.log('choose');
    },
    go() {
      this.$store.commit('setKeepAlives', ['textb']);
      const param = {
        name: 'text-b',
      };
      this.$router.pushPage(param);
    },
    async click(type) {
      // this.$hint.showSuccess();
      if (type === 'success') {
        this.$hint.showSuccess();
      } else if (type === 'failed') {
        this.$hint.showFailed();
      } else if (type === 'alert') {
        this.show = !this.show;
      } else if (type === '64') {
        await this.$common.imgUpload(this.imageList);
        this.imageList = [];
      } else if (type === 'goApp') {
        await this.$bridge.goApplication()
      }
    },
    changeImg(list) {
      console.log('图片更改');
      this.imageList = list;
    },
    syncFunction() {
      return new Promise((resolve) => {
        setTimeout(() => console.log('sync'), 2000)
        resolve();
      })
    },
    async fun1() {
      await this.syncFunction();
    }
  },
  async mounted() {
    await this.syncFunction()
    console.log(1)
  },
};
</script>

<style lang="stylus" scoped>
.hips-notify {
  top: 13.333vw;
}
</style>
