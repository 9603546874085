var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: {
            title:
              _vm.transferType === "PACK"
                ? _vm.$t("title.pallet")
                : _vm.$t("title.subinventoryTransfer"),
            "is-index": false,
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "header-row" }, [
      _c("div", { staticClass: "header-title" }, [
        _vm._v("\n      " + _vm._s(_vm.skuData.sku) + "\n    "),
      ]),
    ]),
    _c("div", { staticClass: "ips-container" }, [
      _c("div", { staticClass: "menu-row" }, [
        _c("div", { staticClass: "menu-title" }, [
          _vm._v(
            "\n        " + _vm._s(_vm.$t("label.goodsName")) + "：\n      "
          ),
        ]),
        _c("div", { staticClass: "menu-value" }, [
          _vm._v("\n        " + _vm._s(_vm.skuData.goodsName) + "\n      "),
        ]),
      ]),
      _c("div", { staticClass: "menu-row" }, [
        _c("div", { staticClass: "menu-title" }, [
          _vm._v("\n        " + _vm._s(_vm.$t("label.unit")) + "：\n      "),
        ]),
        _c("div", { staticClass: "menu-value" }, [
          _vm._v("\n        " + _vm._s(_vm.skuData.uomName) + "\n      "),
        ]),
      ]),
      _c(
        "div",
        { class: _vm.isbatch ? "card_batch_subtrans" : "card_subtrans" },
        [
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("div", { staticClass: "title" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("label.transferFrom")) +
                    "\n        "
                ),
              ]),
              _c("svg-icon", {
                attrs: { slot: "icon", "icon-class": "arrow" },
                slot: "icon",
              }),
            ],
            1
          ),
          _c("ips-lov", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.customData["fromCidCodeBySku"]
                  ? _vm.hideControl["fromCidCodeBySku"]
                  : true,
                expression:
                  "customData['fromCidCodeBySku'] ? hideControl['fromCidCodeBySku']: true",
              },
            ],
            ref: "ref0",
            attrs: {
              label: _vm.$t("label.startOfTray"),
              title: _vm.$t("label.startOfTray"),
              disabled: _vm.transferType === "PACK",
              params: "cidCode",
              url: _vm.winvBaseUrl + "new-stock-trans/query-container-from",
              "url-params": _vm.cidParam,
              "pre-parse-url": _vm.urlData["fromCidCodeBySku"],
              custom: _vm.customData["fromCidCodeBySku"],
              "source-parse-field": _vm.sourceParseField,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(0)
              },
              clickIcon: function ($event) {
                return _vm.handleCommonClick(0, "lov")
              },
              enter: _vm.handleCidEnter,
              down: _vm.handleCidEnter,
              tab: _vm.handleCidTab,
              choose: function ($event) {
                return _vm.handleChoose("fromCidCodeBySku", $event)
              },
              setSourceField: function ($event) {
                return _vm.saveSourceField($event)
              },
              valuedByFoucs: function ($event) {
                return _vm.valuedByFoucs($event)
              },
            },
            model: {
              value: _vm.fromCidCodeBySku,
              callback: function ($$v) {
                _vm.fromCidCodeBySku = $$v
              },
              expression: "fromCidCodeBySku",
            },
          }),
          _c("ips-field", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.parentCidEnabled.paramValue == 1
                    ? _vm.fromParentCidCodeBySku
                    : false,
                expression:
                  "parentCidEnabled.paramValue==1? fromParentCidCodeBySku:false",
              },
            ],
            attrs: {
              label: _vm.$t("label.startOfParentTray"),
              title: _vm.$t("label.startOfParentTray"),
              disabled: "true",
              params: "fromParentCidCodeBySku",
              url: _vm.winvBaseUrl + "new-stock-trans/query-container-from",
              custom: _vm.customData["fromParentCidCodeBySku"],
              "source-parse-field": _vm.sourceParseField,
            },
            model: {
              value: _vm.fromParentCidCodeBySku,
              callback: function ($$v) {
                _vm.fromParentCidCodeBySku = $$v
              },
              expression: "fromParentCidCodeBySku",
            },
          }),
          _c("ips-lov", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.customData["fromLocationCodeBySku"]
                  ? _vm.hideControl["fromLocationCodeBySku"]
                  : true,
                expression:
                  "customData['fromLocationCodeBySku'] ? hideControl['fromLocationCodeBySku']: true",
              },
            ],
            ref: "ref10",
            attrs: {
              label: _vm.$t("label.startOfLocation"),
              title: _vm.$t("label.startOfLocation"),
              required: "",
              params: "locationCode",
              url: _vm.winvBaseUrl + "new-stock-trans/query-location-serial",
              "url-params": _vm.locationParam,
              "pre-parse-url": _vm.urlData["fromLocationCodeBySku"],
              custom: _vm.customData["fromLocationCodeBySku"],
              "source-parse-field": _vm.sourceParseField,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(10)
              },
              clickIcon: function ($event) {
                return _vm.handleCommonClick(10, "lov")
              },
              enter: _vm.handleLocEnter,
              down: _vm.handleLocEnter,
              tab: _vm.handleLocTab,
              choose: function ($event) {
                return _vm.handleChoose("fromLocationCodeBySku", $event)
              },
              setSourceField: function ($event) {
                return _vm.saveSourceField($event)
              },
              valuedByFoucs: function ($event) {
                return _vm.valuedByFoucs($event)
              },
            },
            model: {
              value: _vm.fromLocationCodeBySku,
              callback: function ($$v) {
                _vm.fromLocationCodeBySku = $$v
              },
              expression: "fromLocationCodeBySku",
            },
          }),
          _c("ips-lov", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isbatch
                  ? _vm.customData["batchCodeBySku"]
                    ? _vm.hideControl["batchCodeBySku"]
                    : _vm.isbatch
                  : false,
                expression:
                  "isbatch ? (customData['batchCodeBySku'] ? hideControl['batchCodeBySku'] : isbatch) : false",
              },
            ],
            ref: "ref20",
            attrs: {
              label: _vm.$t("label.batch"),
              required: _vm.isbatch,
              title: _vm.$t("label.batch"),
              params: "batchCode",
              url: _vm.winvBaseUrl + "new-stock-trans/query-batch-from",
              "url-params": _vm.batchParam,
              "pre-parse-url": _vm.urlData["batchCodeBySku"],
              custom: _vm.customData["batchCodeBySku"],
              "source-parse-field": _vm.sourceParseField,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(20)
              },
              clickIcon: function ($event) {
                return _vm.handleCommonClick(20, "lov")
              },
              enter: _vm.handleBatchEnter,
              tab: _vm.handleBatchTab,
              choose: function ($event) {
                return _vm.handleChoose("batch", $event)
              },
              setSourceField: function ($event) {
                return _vm.saveSourceField($event)
              },
              valuedByFoucs: function ($event) {
                return _vm.valuedByFoucs($event)
              },
            },
            model: {
              value: _vm.batchCodeBySku,
              callback: function ($$v) {
                _vm.batchCodeBySku = $$v
              },
              expression: "batchCodeBySku",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "card_countLocation" },
        [
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("svg-icon", {
                attrs: { slot: "icon", "icon-class": "arrow" },
                slot: "icon",
              }),
              _c("div", { staticClass: "title" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("label.transferTo")) +
                    "\n        "
                ),
              ]),
            ],
            1
          ),
          _c("ips-lov", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.customData["toCidCodeBySku"]
                  ? _vm.hideControl["toCidCodeBySku"]
                  : true,
                expression:
                  "customData['toCidCodeBySku'] ? hideControl['toCidCodeBySku'] : true",
              },
            ],
            ref: "ref30",
            attrs: {
              label: _vm.$t("label.targetTray"),
              title: _vm.$t("label.targetTray"),
              required: _vm.transferType === "PACK",
              params: "cidCode",
              url: _vm.winvBaseUrl + "new-stock-trans/query-container-to",
              "url-params": _vm.toCidParam,
              "pre-parse-url": _vm.urlData["toCidCodeBySku"],
              custom: _vm.customData["batchCodeBySku"],
              "source-parse-field": _vm.sourceParseField,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(30)
              },
              clickIcon: function ($event) {
                return _vm.handleCommonClick(30, "lov")
              },
              enter: _vm.handleToCidEnter,
              down: _vm.handleToCidEnter,
              tab: _vm.handleToCidTab,
              choose: function ($event) {
                return _vm.handleChoose("toCidCodeBySku", $event)
              },
              setSourceField: function ($event) {
                return _vm.saveSourceField($event)
              },
              valuedByFoucs: function ($event) {
                return _vm.valuedByFoucs($event)
              },
            },
            model: {
              value: _vm.toCidCodeBySku,
              callback: function ($$v) {
                _vm.toCidCodeBySku = $$v
              },
              expression: "toCidCodeBySku",
            },
          }),
          _c("ips-lov", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.parentCidEnabled.paramValue == 1 ? true : false,
                expression: "parentCidEnabled.paramValue==1? true:false",
              },
            ],
            ref: "ref35",
            attrs: {
              label: _vm.$t("label.targetParentTray"),
              title: _vm.$t("label.targetParentTray"),
              disabled: _vm.isHasParentCid,
              params: "cidCode",
              url:
                _vm.winvBaseUrl +
                "new-stock-trans/query-trans-to-parent-container",
              "url-params": _vm.toCidParam,
              "pre-parse-url": _vm.urlData["toParentCidCodeBySku"],
              custom: _vm.customData["batchCodeBySku"],
              "source-parse-field": _vm.sourceParseField,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(35)
              },
              clickIcon: function ($event) {
                return _vm.handleCommonClick(35, "lov")
              },
              enter: _vm.handleToParentCidEnter,
              down: _vm.handleToParentCidEnter,
              tab: _vm.handleToParentCidTab,
              choose: function ($event) {
                return _vm.handleChoose("toParentCidCodeBySku", $event)
              },
              setSourceField: function ($event) {
                return _vm.saveSourceField($event)
              },
              valuedByFoucs: function ($event) {
                return _vm.valuedByFoucs($event)
              },
            },
            model: {
              value: _vm.toParentCidCodeBySku,
              callback: function ($$v) {
                _vm.toParentCidCodeBySku = $$v
              },
              expression: "toParentCidCodeBySku",
            },
          }),
          _c("ips-lov", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.customData["toLocationCodeBySku"]
                  ? _vm.hideControl["toLocationCodeBySku"]
                  : true,
                expression:
                  "customData['toLocationCodeBySku'] ? hideControl['toLocationCodeBySku']: true",
              },
            ],
            ref: "ref40",
            attrs: {
              label: _vm.$t("label.targetLocation"),
              title: _vm.$t("label.targetLocation"),
              required: "",
              params: "locationCode",
              url: _vm.winvBaseUrl + "new-stock-trans/query-location-to",
              "url-params": _vm.toLocationParam,
              "pre-parse-url": _vm.urlData["toLocationCodeBySku"],
              custom: _vm.customData["toLocationCodeBySku"],
              "source-parse-field": _vm.sourceParseField,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(40)
              },
              clickIcon: function ($event) {
                return _vm.handleCommonClick(40, "lov")
              },
              enter: function ($event) {
                return _vm.skipNext(50)
              },
              down: function ($event) {
                return _vm.skipNext(50)
              },
              tab: function ($event) {
                return _vm.skipNext(50)
              },
              choose: function ($event) {
                return _vm.handleChoose("toLocationCodeBySku", $event)
              },
              setSourceField: function ($event) {
                return _vm.saveSourceField($event)
              },
              valuedByFoucs: function ($event) {
                return _vm.valuedByFoucs($event)
              },
            },
            model: {
              value: _vm.toLocationCodeBySku,
              callback: function ($$v) {
                _vm.toLocationCodeBySku = $$v
              },
              expression: "toLocationCodeBySku",
            },
          }),
          _c("ips-input-number", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.enableSerial
                  ? _vm.customData["transferQtyBySku"]
                    ? _vm.hideControl["transferQtyBySku"]
                    : !_vm.enableSerial
                  : false,
                expression:
                  "!enableSerial ? (customData['transferQtyBySku'] ? hideControl['transferQtyBySku'] : !enableSerial) : false",
              },
            ],
            ref: "ref50",
            attrs: {
              required: "",
              "not-auto-fill": "",
              label: _vm.$t("label.transferNumber"),
              custom: _vm.customData["transferQtyBySku"],
              "source-parse-field": _vm.sourceParseField,
              min: null,
              "more-than-zero": true,
            },
            on: {
              enter: function ($event) {
                return _vm.skipNext("submitRef")
              },
              down: function ($event) {
                return _vm.skipNext("submitRef")
              },
              tab: function ($event) {
                return _vm.skipNext("submitRef")
              },
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(50)
              },
              blur: _vm.checkQtyBlur,
              setSourceField: function ($event) {
                return _vm.saveSourceField($event)
              },
              valuedByFoucs: function ($event) {
                return _vm.valuedByFoucs($event)
              },
            },
            model: {
              value: _vm.transferQtyBySku,
              callback: function ($$v) {
                _vm.transferQtyBySku = $$v
              },
              expression: "transferQtyBySku",
            },
          }),
          _vm.enableSerial
            ? _c(
                "hips-row",
                [
                  _c(
                    "div",
                    { staticClass: "hip-row" },
                    [
                      _c(
                        "hips-col",
                        { attrs: { span: "24" } },
                        [
                          _c("ips-input-number", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.customData["transferQtyBySku"]
                                  ? _vm.hideControl["transferQtyBySku"]
                                  : true,
                                expression:
                                  "customData['transferQtyBySku'] ? hideControl['transferQtyBySku']: true",
                              },
                            ],
                            ref: "ref50",
                            attrs: {
                              required: "",
                              label: _vm.$t("label.transferNumber"),
                              custom: _vm.customData["transferQtyBySku"],
                              "source-parse-field": _vm.sourceParseField,
                              min: null,
                              "more-than-zero": true,
                            },
                            on: {
                              enter: _vm.skipSerail,
                              down: _vm.skipSerail,
                              tab: _vm.skipSerail,
                              blur: _vm.checkQtyBlur,
                              setSourceField: function ($event) {
                                return _vm.saveSourceField($event)
                              },
                              valuedByFoucs: function ($event) {
                                return _vm.valuedByFoucs($event)
                              },
                            },
                            model: {
                              value: _vm.transferQtyBySku,
                              callback: function ($$v) {
                                _vm.transferQtyBySku = $$v
                              },
                              expression: "transferQtyBySku",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("serialComp", {
                    ref: "refSerail",
                    attrs: { params: _vm.serialCompParams },
                    on: {
                      inboundSerialReceiptList:
                        _vm.changeInboundSerialReceiptList,
                    },
                    model: {
                      value: _vm.serialReceiveQty,
                      callback: function ($$v) {
                        _vm.serialReceiveQty = $$v
                      },
                      expression: "serialReceiveQty",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("div", { staticClass: "row-num" }, [
            _c("div", { staticClass: "row-50" }, [
              _c("div", { staticClass: "title" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("label.availableQty")) +
                    ":\n          "
                ),
              ]),
              _c("div", { staticClass: "value" }, [
                _vm._v("\n            " + _vm._s(_vm.qty) + "\n          "),
              ]),
            ]),
            _c("div", { staticClass: "row-50" }, [
              _c("div", { staticClass: "title" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("label.currentUseQty")) +
                    ":\n          "
                ),
              ]),
              _c("div", { staticClass: "value" }, [
                _vm._v(
                  "\n            " + _vm._s(_vm.validQty) + "\n          "
                ),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "ips-footer" },
      [
        _c(
          "hips-button",
          {
            ref: "submitRef",
            class: ["ips-btn-normal"],
            attrs: { size: "large" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonSubmit.apply(null, arguments)
              },
              enter: _vm.handleCommonSubmit,
            },
          },
          [
            _c("svg-icon", {
              attrs: { slot: "icon", "icon-class": "success" },
              slot: "icon",
            }),
            _vm._v("\n      " + _vm._s(_vm.$t("button.confirm")) + "\n    "),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }