// import Login from '../views/login'
import Unpack from '../views/unpack'
import UnpackDetail from '../views/unpack-detail'

let route = [
  {
    path: '/unpack',
    name: 'Unpack',
    component: Unpack,
  },
  {
    path: '/unpack-detail',
    name: 'UnpackDetail',
    component: UnpackDetail,
  },
]

export default route