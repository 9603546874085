<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.analyse')" />
    </div>
    <div class="ips-container">
      <!-- 快递单号 -->
      <ips-field
        v-if="matchType === 'PACK'"
        v-model="courierNumber"
        :label="$t('label.courierNumber')"
        ref="ref0"
        required
        v-show="customData['courierNumber'] ? hideControl['courierNumber'] : true"
        :custom="customData['courierNumber']"
        :source-parse-field="sourceParseField"
        @setSourceField="saveSourceField($event)"
        @click.stop="handleCommonClick(0)"
        @down="handleCourierNumber"
        @enter="handleCourierNumber"
      />
      <!-- 出库单号   @blur="handleBlur('outboundOrderR')"-->
      <ips-lov
        :label="$t('label.outboundOrderR')"
        :title="$t('label.outboundOrderR')"
        v-if="matchType === 'BILL'"
        v-model="outboundOrderR"
        params="billCode"
        display="billCode"
        :url="`${winvBaseUrl}app-review/query-bill-orders`"
        ref="ref0"
        is-control
        required
        v-show="customData['outboundOrderR'] ? hideControl['outboundOrderR'] : true"
        :custom="customData['outboundOrderR']"
        :source-parse-field="sourceParseField"
        @setSourceField="saveSourceField($event)"
        @click.stop="handleCommonClick(0)"
        @clickIcon="handleCommonClick(0, 'lov')"
        @enter="skipNext(10)"
        @down="skipNext(10)"
        @choose="handleChooseOutboundOrderR($event)"
      />
      <!-- 波次单号 -->
      <ips-lov
        :label="$t('label.waveNum')"
        :title="$t('label.waveNum')"
        v-if="matchType === 'WAVE'"
        v-model="waveNum"
        params="waveBillCode"
        display="waveBillCode"
        :url="`${winvBaseUrl}app-review/query-wave-orders`"
        ref="ref0"
        is-control
        required
        v-show="customData['waveBillCode'] ? hideControl['waveBillCode'] : true"
        :custom="customData['waveBillCode']"
        :source-parse-field="sourceParseField"
        @setSourceField="saveSourceField($event)"
        @click.stop="handleCommonClick(0)"
        @clickIcon="handleCommonClick(0, 'lov')"
        @enter="skipNext(10)"
        @down="skipNext(10)"
        @choose="handleChooseWaveNum($event)"
      />

      <!-- 物料 -->
      <ips-field
        v-if="matchType === 'WAVE'"
        v-model="scanSku"
        :label="$t('label.sku')"
        ref="ref10"
        required
        params="scanSku"
        display="scanSku"
        v-show="customData['scanSku'] ? hideControl['scanSku'] : true"
        :custom="customData['scanSku']"
        :pre-parse-url="urlData['scanSku']"
        :source-parse-field="sourceParseField"
        @setSourceField="saveSourceField($event)"
        @click.stop="handleCommonClick(10)"
        @down="handleScanSku"
        @enter="handleScanSku"
      />

      <!-- 物料 -->
      <ips-lov
        :label="$t('label.sku')"
        :title="[$t('label.sku'), $t('label.goodsName')]"
        v-if="matchType !== 'WAVE'"
        v-model="scanSku"
        :params="['sku', 'goodsName']"
        display="sku"
        :url="`${winvBaseUrl}cux-app-review/sku-lov`"
        :url-params="{ billId: resInfo.billId }"
        ref="ref10"
        is-control
        required
        v-show="customData['scanSku'] ? hideControl['scanSku'] : true"
        :custom="customData['scanSku']"
        :source-parse-field="sourceParseField"
        @setSourceField="saveSourceField($event)"
        @click.stop="handleCommonClick(10)"
        @clickIcon="handleCommonClick(10, 'lov')"
        @enter="handleScanSku"
        @down="handleScanSku"
        @choose="handleChooseSku($event)"
      />

      <!-- 批次、序列号-->
      <ips-field
        v-model="barCode"
        v-if="scanType === 'BATCH' || scanType === 'SERIAL'"
        :label="scanType === 'BATCH' ? $t('label.batch') : $t('label.serialNumber')"
        ref="ref20"
        required
        v-show="customData['barCode'] ? hideControl['barCode'] : true"
        :custom="customData['barCode']"
        :source-parse-field="sourceParseField"
        @setSourceField="saveSourceField($event)"
        @click.stop="handleCommonClick(20)"
        @down="handleBarCode"
        @enter="handleBarCode"
      />
      <!-- 数量 -->
      <ips-input-number
        v-model="quantity"
        required
        :label="$t('label.quantity')"
        :disabled="scanType === 'SERIAL'"
        ref="ref30"
        v-show="customData['quantity'] ? hideControl['quantity'] : true"
        :custom="customData['quantity']"
        :source-parse-field="sourceParseField"
        @setSourceField="saveSourceField($event)"
        @enter="handleQuantity"
        @down="handleQuantity"
        :min="null"
        @click.stop="handleCommonClick(30)"
        :more-than-zero="true"
        not-auto-fill
      />
      <!-- 包材 -->
      <ips-field
        v-if="matchType !== 'WAVE'"
        v-model="packagingMaterials"
        :label="$t('label.packagingMaterials')"
        ref="ref40"
        v-show="customData['packagingMaterials'] ? hideControl['packagingMaterials'] : true"
        :custom="customData['packagingMaterials']"
        :source-parse-field="sourceParseField"
        @setSourceField="saveSourceField($event)"
        @click.stop="handleCommonClick(40)"
        @down="handlePackagingMaterials"
        @enter="handlePackagingMaterials"
      />

      <div style="display: flex">
        <hips-button size="large" class="ips-btn-normal" @click="showSwitchTenantFun">
          {{ $t('message.scanTheDetail') }}
        </hips-button>
      </div>
      <!-- 订单数量 -->
      <ips-value-cell :title="$t('label.orderNumberNew')" :value="resInfo.billQty || 0" />
      <!-- 已复核数量-->
      <ips-value-cell :title="$t('label.quantityChecked')" :value="resInfo.billReviewedQty || 0" />
      <!-- 未复核数量 -->
      <ips-value-cell :title="$t('label.noQuantityChecked')" :value="resInfo.billNotReviewedQty || 0" />
      <div class="ips-footer" style="bottom: 60px">
        <!--换箱-->
        <hips-button v-if="matchType !== 'WAVE'" size="large" ref="endBoxChangeBtn" @click="endBoxChange">
          {{ $t('button.inCasesOf') }}
        </hips-button>
        <!--查看明细-->
        <hips-button size="large" ref="goDetailAnalyseBtn" @click="goDetailAnalyse">
          {{ $t('button.checkTheDetails') }}
        </hips-button>
      </div>
      <div class="ips-footer">
        <!--提交-->
        <hips-button size="large" ref="submitRef" @click="handleSub">
          {{ $t('button.submit') }}
        </hips-button>
      </div>
    </div>

    <hips-popup v-model="showSwitchTenantPopup" position="bottom">
      <div class="popup-title">
        <div class="left" @click="cleanPage">
          {{ $t('button.clear') }}
        </div>
        <div class="center">
          {{ $t('message.scanTheDetail') }}
        </div>
        <div class="right" @click="showSwitchTenantPopup = false">
          {{ $t('button.closeBtn') }}
        </div>
      </div>
      <div class="ips-table" style="overflow-y: scroll">
        <table>
          <tr style="background-color: #eee">
            <th v-for="(item, index) in tableTitle" :key="index" scope="col">
              {{ item.name }}
            </th>
          </tr>
          <tr v-for="(item, index) in taskList" :key="index" :ref="`tr${index}`">
            <td>{{ item.sku }}</td>
            <td>{{ item.code }}</td>
            <td>{{ item.serialNumber }}</td>
            <td>{{ item.batchCode }}</td>
            <td>{{ item.qty }}</td>
          </tr>
        </table>
      </div>
    </hips-popup>
  </hips-view>
</template>

<script>
import { storage } from '@/utils';
import {
  validateExpressCode,
  validateSku,
  validateCode,
  validateQty,
  exchangePack,
  confirm,
  validateBillCode,
  validateWaveBillCode,
} from '../api';
export default {
  name: 'PackAnalyse',
  data() {
    return {
      // 波次单号
      waveNum: '',
      // 出库单号
      outboundOrderR: '',
      // 匹配类型
      matchType: '',
      // 快递单号
      courierNumber: '',
      // 物料
      scanSku: '',
      // 条码
      barCode: '',
      // 扫描类型
      scanType: '',
      // 数量
      quantity: '',
      // 包材
      packagingMaterials: '',
      // 接口返回的对象
      resInfo: {},
      // 是否显示明细
      showSwitchTenantPopup: false,
      // 从出库单号获取的未复核数量
      billNotReviewedQty: '',
      //  表单数据
      tableTitle: [],
      taskList: [],
      customData: {}, // 个性化配置信息列表
      hideControl: {}, // 非必输字段的显隐控制
      sourceParseField: {}, // 界面中所有的来源解析字段
      urlData: {},
    };
  },
  mounted() {
    const { hideControl, customObj } = this.handleCustomData(storage.get('customData') || []);
    this.customData = customObj;
    this.hideControl = hideControl;
    // 物料编码 扫描值 序列号 批次 数量
    this.tableTitle = [
      {
        name: this.$t('label.skuCode'),
      },
      {
        name: this.$t('label.scanningValue'),
      },
      {
        name: this.$t('label.serialNumber'),
      },
      {
        name: this.$t('label.batchCode'),
      },
      {
        name: this.$t('label.shelvesQuantity'),
      },
    ];
    if (this.$route.query && this.$route.query.matchType) {
      this.matchType = this.$route.query.matchType;
      if (this.matchType === 'PACK') {
        this.inputFieldList = [
          { type: 'input', required: true, ref: 0, bind: 'courierNumber', field: 'courierNumber' },
          { type: 'lov', required: true, ref: 10, bind: 'scanSku', field: 'scanSku' },
          { type: 'input', required: true, ref: 20, bind: 'barCode', field: 'barCode' },
          { type: 'input', required: false, ref: 30, bind: 'packagingMaterials', field: 'packagingMaterials' },
        ];
        this.courierNumber = this.$route.query.matchCode;
        this.courierNumber && this.handleCourierNumber();
      } else if (this.matchType === 'BILL') {
        this.inputFieldList = [
          { type: 'lov', required: true, ref: 0, bind: 'outboundOrderR', field: 'outboundOrderR' },
          { type: 'lov', required: true, ref: 10, bind: 'scanSku', field: 'scanSku' },
          { type: 'input', required: true, ref: 20, bind: 'barCode', field: 'barCode' },
          { type: 'input', required: false, ref: 30, bind: 'packagingMaterials', field: 'packagingMaterials' },
        ];
        this.outboundOrderR = this.$route.query.matchCode;
        this.outboundOrderR &&
          this.handleChooseOutboundOrderR({
            billId: this.$route.query.billId,
            billCode: this.outboundOrderR,
          });
      } else if (this.matchType === 'WAVE') {
        this.inputFieldList = [
          { type: 'lov', required: true, ref: 0, bind: 'waveNum', field: 'waveNum' },
          { type: 'input', required: true, ref: 10, bind: 'scanSku', field: 'scanSku' },
          { type: 'input', required: true, ref: 20, bind: 'barCode', field: 'barCode' },
        ];
        this.waveNum = this.$route.query.matchCode;
        this.waveNum &&
          this.handleChooseWaveNum({
            waveBillCode: this.waveNum,
            waveBillId: this.$route.query.waveBillId,
          });
      }
    }
    this.urlData = this.handleData('REVIEW_CONFIRM');
  },
  methods: {
    // 处理选择波次单号
    handleChooseWaveNum(obj) {
      let waveBillCode = obj.waveBillCode;
      let waveBillId = obj.waveBillId;
      validateWaveBillCode({
        waveBillId,
        waveBillCode,
      }).then((res) => {
        if (res) {
          this.resInfo = res;
          this.skipNext(10);
        }
      });
    },

    // 处理选择物料
    handleChooseSku() {
      this.handleScanSku()
    },

    // 处理选择出库单单号
    handleChooseOutboundOrderR(obj) {
      let billCode = obj.billCode;
      let billId = obj.billId;
      validateBillCode({
        billId,
        billCode,
      }).then((res) => {
        if (res) {
          this.resInfo = res;
          this.billNotReviewedQty = res.billNotReviewedQty;
          this.skipNext(10);
        }
      });
    },
    // 显示扫描明细
    showSwitchTenantFun() {
      if (this.taskList.length) {
        this.showSwitchTenantPopup = true;
      } else {
        this.$hint.showFailed('暂无扫描数据');
      }
    },
    // 换箱
    async endBoxChange() {
      this.skipNext('endBoxChangeBtn');
      if (this.taskList.length) {
        const param = {
          ...this.resInfo,
          qty: this.scanType === 'SERIAL' ? 1 : this.quantity,
          code: this.barCode,
          sku: this.scanSku,
          waveBillCode: this.waveNum,
          expressNo: this.courierNumber,
          billCode: this.outboundOrderR,
          packMaterial: this.packagingMaterials,
        };
        const res = await exchangePack(param);
        console.log(res);
        this.resInfo = res;
        if (this.matchType === 'PACK') {
          this.courierNumber = res.packCode;
        } else if (this.matchType === 'BILL') {
          this.outboundOrderR = res.billCode;
        }
        this.scanSku = '';
        this.barCode = '';
        this.quantity = '';
        this.packagingMaterials = '';
        this.skipNext(10);
      } else {
        this.$hint.showFailed('暂无扫描数据');
      }
    },
    cleanPage() {},
    // 去查看明细
    goDetailAnalyse() {
      this.skipNext('goDetailAnalyseBtn');
      if (this.resInfo.reviewedList && this.resInfo.reviewedList.length > 0) {
        let param = {
          name: 'DetailAnalyse',
        };
        sessionStorage.setItem('reviewedList', JSON.stringify(this.resInfo.reviewedList));
        this.$router.pushPage(param);
      } else {
        this.$hint.showFailed('暂无明细数据');
      }
    },
    // 提交
    async handleSub() {
      if (this.taskList.length) {
        const param = {
          ...this.resInfo,
          qty: this.scanType === 'SERIAL' ? 1 : this.quantity,
          code: this.barCode,
          sku: this.scanSku,
          waveBillCode: this.waveNum,
          expressNo: this.courierNumber,
          billCode: this.outboundOrderR,
          packMaterial: this.packagingMaterials,
        };
        const res = await confirm(param);
        console.log(res);
        this.waveNum = '';
        this.scanSku = '';
        this.barCode = '';
        this.quantity = '';
        this.packagingMaterials = '';
        if (this.matchType !== 'WAVE' && Number(this.quantity) < Number(this.billNotReviewedQty)) {
          if (this.matchType === 'PACK') {
            this.handleCourierNumber()
            this.skipNext(10);
          } else {
            this.$refs.ref0.handleIconClick()
          }
        } else {
          this.courierNumber = '';
          this.outboundOrderR = '';
          this.resInfo = {};
          this.skipNext(0);
        }
      } else {
        this.$hint.showFailed('暂无扫描数据');
      }
    },
    // 处理单号
    handleCourierNumber() {
      validateExpressCode({
        expressNo: this.courierNumber,
      }).then((res) => {
        if (res) {
          this.resInfo = res;
          this.billNotReviewedQty = res.billNotReviewedQty;
          this.skipNext(10);
        }
      });
    },
    // 处理物料
    handleScanSku() {
      validateSku({
        ...this.resInfo,
        sku: this.scanSku,
        waveBillCode: this.waveNum,
        expressNo: this.courierNumber,
        billCode: this.outboundOrderR,
      }).then((res) => {
        if (res) {
          this.resInfo = res;
          this.scanType = res.scanType;
          if (this.scanType === 'BATCH' || this.scanType === 'SERIAL') {
            if (this.scanType === 'SERIAL') {
              this.quantity = 1;
            }
            setTimeout(() => {
              this.skipNext(20);
            }, 10);
          } else {
            this.skipNext(30);
          }
        }
      });
    },
    // 处理条码
    handleBarCode() {
      this.resInfo.qty = '';
      validateCode({
        ...this.resInfo,
        code: this.barCode,
        sku: this.scanSku,
        waveBillCode: this.waveNum,
        expressNo: this.courierNumber,
        billCode: this.outboundOrderR,
      }).then((res) => {
        if (res) {
          this.resInfo = res;
          if (this.scanType === 'SERIAL') {
            this.taskList = res.scannedList;
            let sum = res.scannedList.reduce((sum, item) => {
              return sum + item.qty;
            }, 0);
            console.log('扫描了：：', sum);
            this.quantity = sum;
            if (sum === res.billNotReviewedQty) {
              this.skipNext(40);
            } else {
              this.skipNext(20);
              this.barCode = '';
            }
          } else {
            this.skipNext(30);
          }
        }
      });
    },
    // 验证数量
    handleQuantity() {
      validateQty({
        ...this.resInfo,
        qty: this.scanType === 'SERIAL' ? 1 : this.quantity,
        code: this.barCode,
        sku: this.scanSku,
        waveBillCode: this.waveNum,
        expressNo: this.courierNumber,
        billCode: this.outboundOrderR,
      }).then((res) => {
        if (res) {
          this.resInfo = res;
          this.taskList = res.scannedList;
          let sum = res.scannedList.reduce((sum, item) => {
            return sum + item.qty;
          }, 0);
          if (sum === res.billNotReviewedQty) {
            if (this.matchType !== 'WAVE') {
              this.skipNext(40);
            } else {
              this.skipNext('submitRef');
            }
          } else {
            this.skipNext(20);
            this.barCode = '';
            this.quantity = '';
          }
        }
      });
    },
    // 处理包材
    handlePackagingMaterials() {
      this.skipNext('submitRef');
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'DetailAnalyse') {
      this.$store.commit('pushKeepAlives', 'PackAnalyse');
    } else {
      this.$store.commit('deleteKeepAlives', 'PackAnalyse');
    }
    setTimeout(() => {
      next();
    }, 0);
  },
};
</script>

<style lang="stylus" scoped>
@import '~@/style/var.styl';

.ips-btn-normal {
  flex: 1;
}

.popup-title {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 0 20px;

  .left, .right {
    font-size: 12px;
    color: #999;
  }
}

.ips-table {
  margin-top: 16px;
  width: 100%;

  .ips-table-thead {
    width: 180px;
    height: 26px;
    background: rgba(255, 255, 255, 1);
    border-radius: 12px 0px 0px 0px;
    line-height: 26px;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    padding-left: 12px;
  }

  table {
    width: 100%;

    th, td {
      padding: 5px 2px;
      border: 1px solid #D9D9D9;
      text-align: center;
      white-space: nowrap;
      font-size: 14px;
      color: rgba(89, 89, 89, 1);

      &:first-child {
        width: 40px;
      }
    }

    .back-green {
      background-color: #cbdeb8;
    }
  }
}
</style>
