<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.outSundry')" :is-index="false" />
    </div>
    <div class="header-row">
      <div class="header-title">
        {{ cidData.cidCode }}
      </div>
      <div :class="{'header-0-status':cidData.inStockStatus==='EMPTY', 'header-1-status':cidData.inStockStatus==='RECEIVING', 'header-2-status':cidData.inStockStatus==='IN_STOCK', 'header-3-status':cidData.inStockStatus==='PICKED', 'header-4-status':cidData.inStockStatus==='LOADED'}">
        {{ cidData.inStockStatusName }}
      </div>
    </div>
    <div class="ips-container">
      <!--托盘明细-物料卡片-->
      <ips-no-data v-if="cidDetailData.length<=0" />
      <div v-if="cidDetailData.length > 0">
        <div
          class="card_countLocation"
          v-for="(item,index) in cidDetailData"
          :key="index"
        >
          <div class="row">
            <div class="row-50">
              <div class="title">
                {{ $t('label.sku') }}:

                   
              </div>
              <div class="value">
                {{ item.sku }}
              </div>
            </div>
            <div class="row-50">
              <div class="title">
                {{ $t('label.unit') }}:
              </div>
              <div class="label">
                {{ item.uomName }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="row-50">
              <div class="title">
                {{ $t('label.batch') }}:
              </div>
              <div class="label">
                {{ item.batchCode || '--' }}
              </div>
            </div>
            <div class="row-50">
              <div class="title">
                {{ $t('label.tray') }}:
              </div>
              <div class="label">
                {{ item.cidCode || '--' }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="row-50">
              <div class="title">
                {{ $t('label.availableQty') }}:
              </div>
              <div class="label-number">
                {{ item.qty }}
              </div>
            </div>
            <div class="row-50">
              <div class="title">
                {{ $t('label.currentUseQty') }}:
              </div>
              <div class="label-number">
                {{ item.validQty }}
              </div>
            </div>

          </div>
          <div class="row">
            <div class="title">
              {{ $t('label.goodsName') }}:
            </div>
            <div class="value">
              {{ item.goodsName }}
            </div>
          </div>
          <div class="row">
            <ips-input-number
              v-if="!(item.serialType === SERIAL_INV_CTRL || item.serialType === SERIAL_OUT_CTRL || item.serialType === SERIAL_SEMI_INV_CTRL)"
              v-model="item.transferQty"
              :disabled="transferType=== 'FULL_PALLET'"
              required
              :label="$t('label.quantity')"
              style="width: 100%;"
              @enter="skipNext('submitRef')"
              @down="skipNext('submitRef')"
              @tab="skipNext('submitRef')"
              @blur="checkQtyBlur(item)"
            />
          </div>
          <hips-row v-if="item.serialType === SERIAL_INV_CTRL || item.serialType === SERIAL_OUT_CTRL || item.serialType === SERIAL_SEMI_INV_CTRL">
            <div class="hip-row">
              <hips-col span="16">
                <ips-input-number
                  v-model="item.transferQty"
                  :disabled="transferType=== 'FULL_PALLET'"
                  required
                  :label="$t('label.quantity')"
                  ref="ref0"
                  @enter="chooseSkipNext(item)"
                  @down="chooseSkipNext(item)"
                  @tab="chooseSkipNext(item)"
                  @blur="checkQtyBlur(item)"
                />
              </hips-col>
              <hips-col span="8">
                <hips-button size="large" @click.stop="goToSerialDetail(item)"
                             ref="refSerail"
                             class="button-detail"
                >
                  {{ $t('button.serialNumber') }}({{ item.serialStockProcessDtoList ? item.serialStockProcessDtoList.length : 0 }})
                </hips-button>
              </hips-col>
            </div>
          </hips-row>
        </div>
      </div>
    </div>
    <div class="ips-footer">
      <hips-button
        size="large"
        @click.stop="goBack()"
        @enter="goBack()"
        ref="submitRef"
        :class="['ips-btn-normal']"
      >
        <svg-icon slot="icon" icon-class="success" />
        {{ $t('button.confirm') }}
      </hips-button>
    </div>
  </hips-view>
</template>

<script>
  // import { getCidDetail } from '../api';
  import { Row, Col } from '@hips/vue-ui';
  import { cloneDeep } from 'lodash';
  export default {
    name: 'CidMiscellaneousOutDetail',
    components:{
      [Row.name]: Row,
      [Col.name]: Col,
    },
    data() {
      return {
        cidData: '', // params参数
        transferType: '',
        cidDetailData: '',
        // serialReceiveQtyImp: 0, // 序列号个数
        // serialStockProcessDtoList: [], // 序列号数据
      };
    },
    methods: {
      // 仅支持 数组形式 获取光标
      upgradeSkipNext(type, item) {
        // 获取item项在数组中对应的下标
        let serialData = this.cidDetailData.filter((item) => item.serialType === this.SERIAL_INV_CTRL || item.serialType === this.SERIAL_OUT_CTRL || item.serialType === this.SERIAL_SEMI_INV_CTRL);
        let activeIndex = serialData.findIndex( (nape) => nape.id === item.id);
        // 获得焦点
        if(type === 'refSerail') {
          this.$refs[type][activeIndex].$el.focus();
        }else {
          let ref = Number(type);
          this.$nextTick(() => {
            this.$refs[`ref${ref}`][activeIndex].focus();
          })
        }
      },
      // 根据条件限制光标的跳转
      chooseSkipNext(item) {
        const { transferQty } = item;
        if(transferQty <= 0) {
          this.$hint.showFailed(this.$i18n.t('message.quantityMin'), true);
        }else {
          if(transferQty > item.validQty){
          // this.$hint.showFailed('转移数量不能大于可用量');
            this.$hint.showFailed(this.$i18n.t('message.qtyMustBeSmallerThanValidQty'), true);
          }else if(transferQty === item.serialStockProcessDtoList.length) {
            this.skipNext('submitRef');
          }else {
            /**
           * 这里光标走向不通过混入的skipNext()方法控制，
           * 因为 这是vue的特性,自动把v-for里面的ref展开成数组的形式，哪怕你的ref名字是唯一的！
           * 这里自定义方法 upgradeSkipNext 来控制跳转
           */
          this.upgradeSkipNext('refSerail', item);
          }
        }
      },
      /**
       * 获取params传参
       */
      async getParamsData(){
        const { item, cidDetailData, transferType } = this.$route.params;
        this.cidData = item;
        this.cidDetailData = cloneDeep(cidDetailData);
        this.transferType = transferType;
      },

      /**
       * 转移数量blur事件
       */
      checkQtyBlur(item){
        const { transferQty } = item;
        if(transferQty < 0){
          this.$hint.showFailed(this.$i18n.t('message.qtyMustBeGreaterThanZero'), true);
        } else if(transferQty > item.validQty){
          // this.$hint.showFailed('转移数量不能大于可用量');
          this.$hint.showFailed(this.$i18n.t('message.qtyMustBeSmallerThanValidQty'), true);
        }
      },
      goBack(){
        try{
          let goBackFalg = true;
          let transferQtyAmount = 0;
          this.cidDetailData.forEach((item) => {
            transferQtyAmount = transferQtyAmount + item.transferQty;
            if((item.serialType === this.SERIAL_INV_CTRL || item.serialType === this.SERIAL_OUT_CTRL || item.serialType === this.SERIAL_SEMI_INV_CTRL) && item.transferQty !== (item.serialStockProcessDtoList ? item.serialStockProcessDtoList.length : 0)){
              goBackFalg = false;
            }
            if(item.transferQty > item.validQty){
              throw new Error();
            }
          });
          if(goBackFalg && transferQtyAmount){
            let transferNumber = 0;
            this.cidDetailData.forEach((item) => {
              transferNumber += item.transferQty;
            });
            const param = {
              name: 'miscellaneousOut',
              params: {
                cidDetailData: this.cidDetailData,
                transferNumber: transferNumber,
                route: 'cidMiscellaneousOutDetail',
              },
            };
            sessionStorage.setItem('isOutCidSubmit', true);
            this.$router.pushPage(param);
          } else if(!transferQtyAmount) {
            this.$hint.showFailed(this.$i18n.t('message.transferQtyNotZero'), true);
          } else {
            this.$hint.showFailed(this.$i18n.t('message.transferQtyMustEqualValidQty'), true);
          }
        } catch (e) {
          this.$hint.showFailed(this.$i18n.t('message.qtyMustBeSmallerThanValidQty'), true);
        }
        
      },
      /**
       * go序列号录入
       * */
      async goToSerialDetail(item){
        const { transferQty } = item;
        if(!transferQty || transferQty <= 0){
          this.$hint.showFailed(this.$i18n.t('message.qtyMustBeGreaterThanZero'), true);
          return;
        } else if(transferQty > item.validQty){
          // this.$hint.showFailed('转移数量不能大于可用量');
          this.$hint.showFailed(this.$i18n.t('message.qtyMustBeSmallerThanValidQty'), true);
        }else {
          item.selected = true;
          let data = {
            cidId: item.cidId,
            cidCode: item.cidCode,
            goodsId: item.goodsId,
            sku: item.sku,
            locationId: item.locationId,
            locationCode: item.locationCode,
            warehouseId: item.warehouseId,
            batchId: item.batchId,
            batchCode: item.batchCode,
            serialType: item.serialType,
          };
          let serialUrl ='app-miscellaneous-out/check-serial';
          const param = {
            name: 'serialComponent',
            params: {
              data,
              serialUrl,
              receiveQty: item.transferQty,
              serialType: this.cidData.serialType,
              serialNumberList: [...item.serialStockProcessDtoList] || [],
              callback: function(itemStatus, serialObj) {
                const item = {
                  serialId: serialObj.serialId,
                  serialNumber: serialObj.serialNumber,
                  serialQty: 1,
                };
                if(!serialObj.serialNumberStatusName) {
                  const serialDataShow = Object.assign({}, itemStatus, serialObj, item, {serialNumberStatusName: '合格'});
                  this.tableData.push(serialDataShow);
                } else {
                  const serialDataShow = Object.assign({}, itemStatus, serialObj, item);
                  this.tableData.push(serialDataShow);
                }
              }
            },
          };
          this.$router.pushPage(param);
        }

      },

    },
    mounted() {
      this.getParamsData();
    },
    activated() {
      const serialStockProcessDtoList = JSON.parse(sessionStorage.getItem('serialNumberList')) || []; 
      const cidDataCopy = [];
      let activeIndex = this.cidDetailData.findIndex((item) => item.selected === true);
      this.cidDetailData.forEach((item) => {
        if(item.selected){
          item.serialStockProcessDtoList = serialStockProcessDtoList;
          item.selected = false;
          item.transferQty = serialStockProcessDtoList.length;
          cidDataCopy.push(item);
        } else {
          cidDataCopy.push(item);
        }
      });
      this.upgradeSkipNext(0, this.cidDetailData[activeIndex]);
      this.cidDetailData = cidDataCopy;
      sessionStorage.removeItem('serialNumberList');
    },
    beforeRouteLeave(to, from, next) {
      if (to.name === 'serialComponent') {
        this.$store.commit('pushKeepAlives', 'CidMiscellaneousOutDetail');
      } else {
        this.$store.commit('deleteKeepAlives', 'CidMiscellaneousOutDetail');
      }
      next();
    },
  };
</script>

<style lang="stylus" scoped>
 @import '~@/style/var.styl'
    .header-row{
      padding-top 5px
      display flex;
      margin-bottom 12px;
      .header-title{
        display flex;
        align-items: center;
        height:28px;
        background:#E6F7FF;
        color var(--primaryColor);
        border-radius:0px 32px 32px 0px;
        padding 0 12px 0 8px;
        border-left:4px solid var(--primaryColor);
      }
      .header-0-status{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left 12px;
        padding 0 12px;
        height:28px;
        font-size 14px;
        background:#D9D9D9;
        color #8C8C8C;
        border-radius:12px;
      }
      .header-1-status{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left 12px;
        padding 0 12px;
        height:28px;
        font-size 14px;
        background:#FFE4AB;
        color #FF8F07;
        border-radius:12px;
      }
      .header-2-status{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left 12px;
        padding 0 12px;
        height:28px;
        font-size 14px;
        background:#E6F7FF;
        color var(--primaryColor);
        border-radius:12px;
      }
      .header-3-status{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left 12px;
        padding 0 12px;
        height:28px;
        font-size 14px;
        background:#D6FFD7;
        color #19A633;
        border-radius:12px;
      }
      .header-4-status{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left 12px;
        padding 0 12px;
        height:28px;
        font-size 14px;
        background:#EFDBFF;
        color #722ED1;
        border-radius:12px;
      }
    }
    .ips-container{
        padding-top 0!important;
        .menu-row{
            display flex
            margin 12px 32px 12px 12px;
            .menu-title{
                width:40px;
                height:16px;
                font-size:12px;
                /*font-family:PingFangSC-Regular,PingFang SC;*/
                font-weight:200;
                color:#8C8C8C;
                line-height:16px;
            }
            .menu-value{
                width:261px;
                height:16px;
                font-size:12px;
                /*font-family:PingFangSC-Regular,PingFang SC;*/
                font-weight:initial;
                color:#000000;
                line-height:16px;
                overflow: hidden;      /*溢出隐藏*/
                white-space: nowrap;	/*规定文本不进行换行*/
                text-overflow: ellipsis;	/*当对象内文本溢出时显示省略标记（...）*/
            }
        }
        .card_countLocation{
          margin 10px 0;
          padding  0 12px
          height:170px;
          border-radius: 1.6vw;
          color: #000;
          box-shadow: 0 0 2.133vw #d9d9d9;
          border: none;
          font-size: 14px;
          background-color #FFFFFF;
        .row{
          display flex
          padding  10px 12px 0 0
          .row-50{
            width 50%
            display flex
            .title{
              width:56px;
              height:16px;
              font-size:14px;
              font-family:PingFangSC-Regular,PingFang SC;
              font-weight:200;
              color:#595959;
              line-height:18px;
            }
            .value{
              width:110px;
              height:18px;
              font-size:14px;
              font-family:PingFangSC-Medium,PingFang SC;
              font-weight:initial;
              color:#000000;
              line-height:18px;
              overflow: hidden;      /*溢出隐藏*/
              white-space: nowrap;	/*规定文本不进行换行*/
              text-overflow: ellipsis;	/*当对象内文本溢出时显示省略标记（...）*/
            }
            .label{
              width:110px;
              height:18px;
              font-size:14px;
              font-family:PingFangSC-Medium,PingFang SC;
              font-weight:initial;
              color:#000000;
              line-height:18px;
              overflow: hidden;      /*溢出隐藏*/
              white-space: nowrap;	/*规定文本不进行换行*/
              text-overflow: ellipsis;	/*当对象内文本溢出时显示省略标记（...）*/
            }
            .label-number{
              width:40px;
              height:18px;
              font-size:14px;
              font-family:PingFangSC-Medium,PingFang SC;
              font-weight:initial;
              color:#9254DE;
              line-height:18px;
            }
          }
          .title{
            width:56px;
            height:16px;
            font-size:14px;
            font-family:PingFangSC-Regular,PingFang SC;
            font-weight:200;
            color:#595959;
            line-height:18px;
          }
          .value{
            height:18px;
            font-size:14px;
            font-family:PingFangSC-Medium,PingFang SC;
            font-weight:initial;
            color:#000000;
            line-height:18px;
            overflow: hidden;      /*溢出隐藏*/
            white-space: nowrap;	/*规定文本不进行换行*/
            text-overflow: ellipsis;	/*当对象内文本溢出时显示省略标记（...）*/
          }
        }
          .hip-row{
            display flex
            padding: 0 10px 10px 10px;
            .button-detail{
              margin-left: 5px;
              margin-right: 5px;
              padding 0 5px;
              background-color: var(--primaryColor);
              color: #FFFFFF;
              border: none;
              font-size: 14px;
              height: 36px;
              line-height: 36px;
            }
          }
      }
    }
    .ips-btn-normal {
      border-radius: 8px !important;
      margin: 12px 10px 12px 10px;
      background-color var(--primaryColor) !important;
      color #ffffff !important
      font-size 17px !important
    }
</style>
