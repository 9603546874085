<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.batchList')" :is-index="false" />
    </div>
    <div class="ips-container">
      <!--托盘明细-物料卡片-->
      <ips-no-data v-if="stockList.length<=0" />
      <div v-if="stockList.length > 0">
        <div
          class="card_countLocation"
          v-for="(item,index) in stockList"
          :key="index"
        >
          <div class="row">
            <div class="row-50">
              <div class="title">
                {{ $t('label.batch') }}:
              </div>
              <div class="value">
                {{ item.batchCode }}
              </div>
            </div>
            <div class="row-50">
              <div class="title2">
                {{ $t('label.availableQty') }}:
              </div>
              <div class="label">
                {{ item.qty }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </hips-view>
</template>

<script>
  // import {} from "../api";
  export default {
    name: 'BatchDetail',
    data() {
      return {
        stockList: [],
      };
    },

    methods: {
      /**
       * 获取数据
       */
      // async getBillData() {
      //   try {
      //     this.$hint.showLoading();
      //     // const res = await checkQty(this.billId);
      //     const resData = res.content[0];
      //     const { planQty, receiveQty, billTypeName } = resData;
      //     this.billTypeName = billTypeName;
      //     this.billData.planQty = planQty;
      //     this.billData.receivedQty = receiveQty;
      //     this.billData.unreceiveQty = planQty - receiveQty;
      //     await this.getLineNum();
      //   } catch (e) {
      //     console.log(e);
      //   }finally {
      //     this.$hint.hideLoading();
      //   }
      // },

      // async getLineNum() {
      //   try {
      //     // let url = `${this.winvBaseUrl}inbound-order-lines`;
      //     let data = {
      //       params: {
      //         billId: this.billId,
      //       },
      //     };
      //     // const res = await this.$http.get(url, data);
      //     // const res = await inboundOrderLines(data);
      //     this.lineData = res.content;
      //     // console.log('====lr===', this.lineData);
      //   } catch (e) {
      //     console.log(e);
      //   }
      // },
      //返回
      goBack() {
        this.$router.backPage();
      },
    },
    mounted() {
      const {stockList} = this.$route.params;
      this.stockList = stockList;
    },
  };
</script>

<style lang="stylus" scoped>
 @import '~@/style/var.styl'
  .acceptance-detail-item {
    border-top: 2px solid #e8e8e8
  }
  .scroll_box {
    position: absolute;
    margin-top 108.97px
    height calc(100% - 109px);
    width: 96%
    padding-bottom: 10px
  }

  .card_box {
    margin 10px 5px 16px 5px !important 
  }
  .ips-container{
        padding-top 0!important;
        .menu-row{
            display flex
            margin 12px 32px 12px 12px;
            .menu-title{
                width:40px;
                height:16px;
                font-size:12px;
                /*font-family:PingFangSC-Regular,PingFang SC;*/
                font-weight:200;
                color:#8C8C8C;
                line-height:16px;
            }
            .menu-value{
                width:261px;
                height:16px;
                font-size:12px;
                /*font-family:PingFangSC-Regular,PingFang SC;*/
                font-weight:initial;
                color:#000000;
                line-height:16px;
                overflow: hidden;      /*溢出隐藏*/
                white-space: nowrap;	/*规定文本不进行换行*/
                text-overflow: ellipsis;	/*当对象内文本溢出时显示省略标记（...）*/
            }
        }
        .card_countLocation{
          margin 10px 0;
          padding  0 12px
          height: 50px;
          border-radius: 1.6vw;
          color: #000;
          box-shadow: 0 0 2.133vw #d9d9d9;
          border: none;
          font-size: 14px;
          background-color #FFFFFF;
        .row{
          display flex
          padding  10px 12px 0 0
          .row-50{
            width 60%
            display flex
            .title{
              width:56px;
              height:16px;
              font-size:14px;
              font-family:PingFangSC-Regular,PingFang SC;
              font-weight:200;
              color:#595959;
              line-height:18px;
            }
            .title2{
              width:56px;
              height:16px;
              font-size:14px;
              margin-left: 30px;
              font-family:PingFangSC-Regular,PingFang SC;
              font-weight:200;
              color:#595959;
              line-height:18px;
            }
            .value{
              width:110px;
              height:18px;
              font-size:14px;
              font-family:PingFangSC-Medium,PingFang SC;
              font-weight:initial;
              color:#000000;
              line-height:18px;
              // overflow: hidden;      /*溢出隐藏*/
              // white-space: nowrap;	/*规定文本不进行换行*/
              // text-overflow: ellipsis;	/*当对象内文本溢出时显示省略标记（...）*/
            }
            .label{
              width:110px;
              height:18px;
              font-size:14px;
              font-family:PingFangSC-Medium,PingFang SC;
              font-weight:initial;
              color:#000000;
              line-height:18px;
              overflow: hidden;      /*溢出隐藏*/
              white-space: nowrap;	/*规定文本不进行换行*/
              text-overflow: ellipsis;	/*当对象内文本溢出时显示省略标记（...）*/
            }
            .label-number{
              width:40px;
              height:18px;
              font-size:14px;
              font-family:PingFangSC-Medium,PingFang SC;
              font-weight:initial;
              color:#9254DE;
              line-height:18px;
            }
          }
          .title{
            width:56px;
            height:16px;
            font-size:14px;
            font-family:PingFangSC-Regular,PingFang SC;
            font-weight:200;
            color:#595959;
            line-height:18px;
          }
          .value{
            height:18px;
            font-size:14px;
            font-family:PingFangSC-Medium,PingFang SC;
            font-weight:initial;
            color:#000000;
            line-height:18px;
            // overflow: hidden;      /*溢出隐藏*/
            // white-space: nowrap;	/*规定文本不进行换行*/
            // text-overflow: ellipsis;	/*当对象内文本溢出时显示省略标记（...）*/
          }
        }
          .hip-row{
            display flex
            padding: 0 10px 10px 10px;
            .button-detail{
              margin-left: 5px;
              margin-right: 5px;
              padding 0 5px;
              background-color: var(--primaryColor);
              color: #FFFFFF;
              border: none;
              font-size: 14px;
              height: 36px;
              line-height: 36px;
            }
          }
      }
    }
</style>
