var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: { title: _vm.$t("title.skuPropertyUpdate"), "is-index": true },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _c("ips-lov", {
          ref: "ref0",
          attrs: {
            label: _vm.$t("label.measurementStandard"),
            title: _vm.$t("label.measurementStandard"),
            required: "",
            params: "meaning",
            display: "meaning",
            "is-page-content": false,
            url: _vm.wmdmBaseUrl + "goods/measurement-type-lov",
          },
          on: {
            enter: function ($event) {
              return _vm.skipNext(1)
            },
            down: function ($event) {
              return _vm.skipNext(1)
            },
            choose: function ($event) {
              return _vm.handleChoose("measurementStandard", $event)
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(0)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(0, "lov")
            },
          },
          model: {
            value: _vm.measurementStandard,
            callback: function ($$v) {
              _vm.measurementStandard = $$v
            },
            expression: "measurementStandard",
          },
        }),
        _c("ips-lov", {
          ref: "ref1",
          attrs: {
            label: _vm.$t("label.sku"),
            title: [_vm.$t("label.skuCode"), _vm.$t("label.goodsName")],
            required: "",
            params: ["sku", "goodsName"],
            display: "sku",
            url: _vm.wmdmBaseUrl + "goods",
            "url-params": { skuName: _vm.sku },
          },
          on: {
            enter: _vm.handleSkuEnter,
            blur: function ($event) {
              return _vm.handleCommonBlur(1)
            },
            choose: function ($event) {
              return _vm.handleChoose("sku", $event)
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(1)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(1, "lov")
            },
          },
          model: {
            value: _vm.sku,
            callback: function ($$v) {
              _vm.sku = $$v
            },
            expression: "sku",
          },
        }),
        _c("ips-value-cell", {
          staticClass: "goods-name",
          attrs: {
            title: _vm.$t("label.goodsName"),
            "image-flag": "",
            value: _vm.skuData.goodsName,
          },
        }),
        _c("ips-input-number", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.measurementType === "BOX",
              expression: "measurementType==='BOX'",
            },
          ],
          ref: "ref2",
          staticClass: "qtyInbox",
          attrs: {
            label: _vm.$t("label.qtyInBox"),
            required: _vm.measurementType === "BOX",
            min: null,
            "more-than-zero": true,
            precision: 0,
            "not-auto-fill": "",
          },
          on: {
            enter: function ($event) {
              return _vm.skipNext(3)
            },
            down: function ($event) {
              return _vm.skipNext(3)
            },
            blur: function ($event) {
              return _vm.handleCommonBlur(2)
            },
          },
          model: {
            value: _vm.boxQty,
            callback: function ($$v) {
              _vm.boxQty = $$v
            },
            expression: "boxQty",
          },
        }),
        _c(
          "hips-row",
          [
            _c(
              "hips-col",
              { attrs: { span: "16" } },
              [
                _c("ips-input-number", {
                  ref: "ref3",
                  attrs: {
                    label:
                      _vm.measurementType === "BOX"
                        ? _vm.$t("label.weightBox")
                        : _vm.$t("label.weight"),
                    min: null,
                    "more-than-zero": true,
                    "not-auto-fill": "",
                  },
                  on: {
                    enter: function ($event) {
                      return _vm.skipNext(4)
                    },
                    down: function ($event) {
                      return _vm.skipNext(4)
                    },
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleCommonClick(3)
                    },
                  },
                  model: {
                    value: _vm.weight,
                    callback: function ($$v) {
                      _vm.weight = $$v
                    },
                    expression: "weight",
                  },
                }),
                _c("ips-input-number", {
                  ref: "ref4",
                  attrs: {
                    label:
                      _vm.measurementType === "BOX"
                        ? _vm.$t("label.lengthBox")
                        : _vm.$t("label.length"),
                    min: null,
                    "more-than-zero": true,
                    "not-auto-fill": "",
                  },
                  on: {
                    enter: function ($event) {
                      return _vm.skipNext(5)
                    },
                    down: function ($event) {
                      return _vm.skipNext(5)
                    },
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleCommonClick(4)
                    },
                  },
                  model: {
                    value: _vm.length,
                    callback: function ($$v) {
                      _vm.length = $$v
                    },
                    expression: "length",
                  },
                }),
                _c("ips-input-number", {
                  ref: "ref5",
                  attrs: {
                    label:
                      _vm.measurementType === "BOX"
                        ? _vm.$t("label.widthBox")
                        : _vm.$t("label.width"),
                    min: null,
                    "more-than-zero": true,
                    "not-auto-fill": "",
                  },
                  on: {
                    enter: function ($event) {
                      return _vm.skipNext(6)
                    },
                    down: function ($event) {
                      return _vm.skipNext(6)
                    },
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleCommonClick(5)
                    },
                  },
                  model: {
                    value: _vm.width,
                    callback: function ($$v) {
                      _vm.width = $$v
                    },
                    expression: "width",
                  },
                }),
                _c("ips-input-number", {
                  ref: "ref6",
                  attrs: {
                    label:
                      _vm.measurementType === "BOX"
                        ? _vm.$t("label.heightBox")
                        : _vm.$t("label.height"),
                    min: null,
                    "more-than-zero": true,
                    "not-auto-fill": "",
                  },
                  on: {
                    enter: function ($event) {
                      return _vm.skipNext("submitRef")
                    },
                    down: function ($event) {
                      return _vm.skipNext("submitRef")
                    },
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleCommonClick(6)
                    },
                  },
                  model: {
                    value: _vm.height,
                    callback: function ($$v) {
                      _vm.height = $$v
                    },
                    expression: "height",
                  },
                }),
              ],
              1
            ),
            _c(
              "hips-col",
              { attrs: { span: "8" } },
              [
                _c("ips-field", {
                  attrs: { label: _vm.$t("label.weightUnit"), disabled: "" },
                  model: {
                    value: _vm.measurementData.weightUnit,
                    callback: function ($$v) {
                      _vm.$set(_vm.measurementData, "weightUnit", $$v)
                    },
                    expression: "measurementData.weightUnit",
                  },
                }),
                _c("ips-field", {
                  attrs: { label: _vm.$t("label.lengthUnit"), disabled: "" },
                  model: {
                    value: _vm.measurementData.lengthUnit,
                    callback: function ($$v) {
                      _vm.$set(_vm.measurementData, "lengthUnit", $$v)
                    },
                    expression: "measurementData.lengthUnit",
                  },
                }),
                _c("ips-field", {
                  attrs: { label: _vm.$t("label.widthUnit"), disabled: "" },
                  model: {
                    value: _vm.measurementData.widthUnit,
                    callback: function ($$v) {
                      _vm.$set(_vm.measurementData, "widthUnit", $$v)
                    },
                    expression: "measurementData.widthUnit",
                  },
                }),
                _c("ips-field", {
                  attrs: { label: _vm.$t("label.heightUnit"), disabled: "" },
                  model: {
                    value: _vm.measurementData.heightUnit,
                    callback: function ($$v) {
                      _vm.$set(_vm.measurementData, "heightUnit", $$v)
                    },
                    expression: "measurementData.heightUnit",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "out_box" },
          [
            _c(
              "hips-row",
              [
                _c(
                  "div",
                  { staticStyle: { margin: "10px 15px", "font-size": "20px" } },
                  [_vm._v("\n          单物料属性\n        ")]
                ),
                _c(
                  "hips-col",
                  { attrs: { span: "12" } },
                  [
                    _c("hips-cell", {
                      staticStyle: { color: "#8C8C8C" },
                      attrs: {
                        title: _vm.$t("label.weight"),
                        "image-flag": "",
                        value: _vm.skuData.grossWeight
                          ? _vm.skuData.grossWeight + "kg"
                          : _vm.skuData.grossWeight,
                      },
                    }),
                    _c("hips-cell", {
                      staticStyle: { color: "#8C8C8C" },
                      attrs: {
                        title: _vm.$t("label.width"),
                        "image-flag": "",
                        value: _vm.skuData.width
                          ? _vm.skuData.width + "cm"
                          : _vm.skuData.width,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "hips-col",
                  { attrs: { span: "12" } },
                  [
                    _c("hips-cell", {
                      staticStyle: { color: "#8C8C8C" },
                      attrs: {
                        title: _vm.$t("label.length"),
                        "image-flag": "",
                        value: _vm.skuData.length
                          ? _vm.skuData.length + "cm"
                          : _vm.skuData.length,
                      },
                    }),
                    _c("hips-cell", {
                      staticStyle: { color: "#8C8C8C" },
                      attrs: {
                        title: _vm.$t("label.height"),
                        "image-flag": "",
                        value: _vm.skuData.height
                          ? _vm.skuData.height + "cm"
                          : _vm.skuData.height,
                      },
                    }),
                  ],
                  1
                ),
                _c("hips-cell", {
                  staticStyle: { color: "#8C8C8C" },
                  attrs: {
                    title: "体积",
                    "image-flag": "",
                    value: _vm.skuData.volume
                      ? _vm.skuData.volume + "m³"
                      : _vm.skuData.volume,
                  },
                }),
                _c("hips-cell", {
                  staticStyle: { color: "#8C8C8C" },
                  attrs: {
                    title: "最后更新人",
                    "image-flag": "",
                    value: _vm.skuData.lastUpdatedName,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "ips-footer" },
          [
            _c(
              "hips-button",
              {
                ref: "submitRef",
                attrs: { size: "large" },
                on: { click: _vm.handleCommonSubmit },
              },
              [
                _c("svg-icon", {
                  attrs: { slot: "icon", "icon-class": "success" },
                  slot: "icon",
                }),
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("button.confirm")) + "\n      "
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }