<script>
import { storage } from '@/utils';
import { queryApplicationLocales } from '../vue-i18n/api';
import i18n from '../vue-i18n';
const localeKeyMaps = {
  zh: 'zh_CN',
  en: 'en_US',
};
export default {
  props: {
    locale: {
      type: String,
      default: 'zh',
    },
  },
  data() {
    return {
      isReady: false,
    };
  },
  beforeMount() {
    const lang = localeKeyMaps[this.locale];
    const accessToken = storage.get('user-token')
    if (this.locale && lang && accessToken) {
      queryApplicationLocales(lang)
        .then((res) => {
          i18n.mergeLocaleMessage(this.locale, res);
          this.isReady = true;
        })
        .catch(() => {
          this.isReady = true;
        });
    } else {
      this.isReady = true;
    }
  },
  render() {
    return this.isReady ? this.$slots.default : null;
  },
};
</script>