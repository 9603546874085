import WorkManagement from '../views/work-management'

let route = [
  {
    path: '/work-management',
    name: 'WorkManagement',
    component: WorkManagement,
  },
]

export default route