var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: { title: _vm.$t("title.rackingTasks"), "is-index": false },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _c("ips-value-cell", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["cidCode"]
                ? _vm.hideControl["cidCode"]
                : true,
              expression:
                "customData['cidCode'] ? hideControl['cidCode'] : true",
            },
          ],
          attrs: {
            title: _vm.$t("label.putAwayTray"),
            value: _vm.formData.cidCode,
          },
        }),
        _c("ips-value-cell", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.requireParentCidCode
                ? false
                : _vm.customData["parentCidCode"]
                ? _vm.hideControl["parentCidCode"]
                : _vm.formData.parentCidCode,
              expression:
                "!requireParentCidCode ? false : customData['parentCidCode'] ? hideControl['parentCidCode'] : formData.parentCidCode",
            },
          ],
          attrs: {
            title: _vm.$t("label.putAwayParentTray"),
            value: _vm.formData.parentCidCode,
          },
        }),
        _c("ips-value-cell", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["skuShow"]
                ? _vm.hideControl["skuShow"]
                : true,
              expression:
                "customData['skuShow'] ? hideControl['skuShow'] : true",
            },
          ],
          attrs: { title: _vm.$t("label.sku"), value: _vm.formData.skuShow },
        }),
        _c("ips-value-cell", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["goodsName"]
                ? _vm.hideControl["goodsName"]
                : true,
              expression:
                "customData['goodsName'] ? hideControl['goodsName'] : true",
            },
          ],
          attrs: {
            title: _vm.$t("label.goodsName"),
            value: _vm.formData.goodsName,
          },
        }),
        _c("ips-value-cell", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["uomName"]
                ? _vm.hideControl["uomName"]
                : true,
              expression:
                "customData['uomName'] ? hideControl['uomName'] : true",
            },
          ],
          attrs: { title: _vm.$t("label.unit"), value: _vm.formData.uomName },
        }),
        _c("ips-field", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["confirmSku"]
                ? _vm.hideControl["confirmSku"]
                : true,
              expression:
                "customData['confirmSku'] ? hideControl['confirmSku'] : true",
            },
          ],
          ref: "ref0",
          attrs: {
            label: _vm.$t("label.confirmSku"),
            required: "",
            params: "confirmSku",
            display: "confirmSku",
            "pre-parse-url": _vm.urlData["confirmSku"],
            custom: _vm.customData["confirmSku"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            blur: function () {
              return _vm.handleSkipBlur("ref0", "sku", _vm.formData.confirmSku)
            },
            enter: function () {
              return _vm.handleSkip("ref0")
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(0)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.formData.confirmSku,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "confirmSku", $$v)
            },
            expression: "formData.confirmSku",
          },
        }),
        _c("ips-value-cell", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isbatch
                ? _vm.customData["batchCodeShow"]
                  ? _vm.hideControl["batchCodeShow"]
                  : _vm.isbatch
                : false,
              expression:
                "isbatch ? (customData['batchCodeShow'] ? hideControl['batchCodeShow'] : isbatch) : false",
            },
          ],
          attrs: {
            title: _vm.$t("label.suggestedBatch"),
            value: _vm.formData.batchCodeShow,
          },
        }),
        _vm.isbatch
          ? _c("ips-field", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.customData["confirmBatchCode"]
                    ? _vm.hideControl["confirmBatchCode"]
                    : true,
                  expression:
                    "customData['confirmBatchCode'] ? hideControl['confirmBatchCode'] : true",
                },
              ],
              ref: "ref10",
              attrs: {
                label: _vm.$t("label.confirmBatchCode"),
                required: "",
                params: "confirmBatchCode",
                display: "confirmBatchCode",
                "pre-parse-url": _vm.urlData["confirmBatchCode"],
                custom: _vm.customData["confirmBatchCode"],
                "source-parse-field": _vm.sourceParseField,
              },
              on: {
                blur: function () {
                  return _vm.handleSkipBlur(
                    "ref10",
                    "batchCode",
                    _vm.formData.confirmBatchCode
                  )
                },
                enter: function () {
                  return _vm.handleSkip("ref10")
                },
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleCommonClick(10)
                },
                setSourceField: function ($event) {
                  return _vm.saveSourceField($event)
                },
                valuedByFoucs: function ($event) {
                  return _vm.valuedByFoucs($event)
                },
              },
              model: {
                value: _vm.formData.confirmBatchCode,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "confirmBatchCode", $$v)
                },
                expression: "formData.confirmBatchCode",
              },
            })
          : _vm._e(),
        _c("ips-field", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["confirmBatchCode"]
                ? _vm.hideControl["confirmBatchCode"]
                : true,
              expression:
                "customData['confirmBatchCode'] ? hideControl['confirmBatchCode'] : true",
            },
          ],
          ref: "ref20",
          attrs: {
            label: _vm.$t("label.conformTray"),
            params: "toCidCode",
            display: "toCidCode",
            "pre-parse-url": _vm.urlData["toCidCodeByBatches"],
            custom: _vm.customData["toCidCodeByBatches"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            blur: _vm.handleCodeBlur,
            enter: _vm.handleCodeSkip,
            down: _vm.handleCodeSkip,
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(20)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.formData.toCidCodeByBatches,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "toCidCodeByBatches", $$v)
            },
            expression: "formData.toCidCodeByBatches",
          },
        }),
        _c("ips-field", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.requireParentCidCode
                ? false
                : _vm.customData["toParentCidCode"]
                ? _vm.hideControl["toParentCidCode"]
                : true,
              expression:
                "!requireParentCidCode ? false : customData['toParentCidCode'] ? hideControl['toParentCidCode'] : true",
            },
          ],
          ref: "ref25",
          attrs: {
            label: _vm.$t("label.confirmParentTray"),
            params: "toParentCidCode",
            "pre-parse-url": _vm.urlData["toParentCidCode"],
            custom: _vm.customData["toParentCidCode"],
            "source-parse-field": _vm.sourceParseField,
            disabled: !_vm.formData.toCidCodeByBatches || _vm.disableParentCid,
          },
          on: {
            blur: _vm.handleParentCodeBlur,
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(25)
            },
            enter: function ($event) {
              return _vm.handleSkip("ref25")
            },
            down: function ($event) {
              return _vm.handleSkip("ref25")
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.formData.toParentCidCode,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "toParentCidCode", $$v)
            },
            expression: "formData.toParentCidCode",
          },
        }),
        _c("ips-value-cell", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["toLocationCodeShow"]
                ? _vm.hideControl["toLocationCodeShow"]
                : true,
              expression:
                "customData['toLocationCodeShow'] ? hideControl['toLocationCodeShow'] : true",
            },
          ],
          attrs: {
            title: _vm.$t("label.suggestedLocation"),
            value: _vm.formData.toLocationCodeShow,
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["toLocationCodeByBatches"]
                ? _vm.hideControl["toLocationCodeByBatches"]
                : true,
              expression:
                "customData['toLocationCodeByBatches'] ? hideControl['toLocationCodeByBatches'] : true",
            },
          ],
          ref: "ref30",
          attrs: {
            label: _vm.$t("label.confirmLocation"),
            title: _vm.$t("label.confirmLocation"),
            required: "",
            disabled: _vm.disabledFlag,
            params: "locationCode",
            url: _vm.wmdmBaseUrl + "wms-locations/lov",
            "url-params": {
              warehouseId: _vm.warehouseId,
              locationType: "T_STOCK",
            },
            "pre-parse-url": _vm.urlData["toLocationCodeByBatches"],
            custom: _vm.customData["toLocationCodeByBatches"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(30)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(30, "lov")
            },
            enter: function ($event) {
              return _vm.handleSkip("ref30")
            },
            down: function ($event) {
              return _vm.handleSkip("ref30")
            },
            choose: _vm.chooseConfirmLocation,
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.toLocationCodeByBatches,
            callback: function ($$v) {
              _vm.toLocationCodeByBatches = $$v
            },
            expression: "toLocationCodeByBatches",
          },
        }),
        _c("ips-value-cell", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["qtyPlan"]
                ? _vm.hideControl["qtyPlan"]
                : true,
              expression:
                "customData['qtyPlan'] ? hideControl['qtyPlan'] : true",
            },
          ],
          attrs: {
            title: _vm.$t("label.taskNum"),
            value: _vm.formData.qtyPlan,
          },
        }),
        _vm.showSerialFlag
          ? _c(
              "div",
              { staticClass: "input-button" },
              [
                _c(
                  "hips-button",
                  {
                    ref: "inputRef",
                    style: Object.assign({}, _vm.inputBtnStyle),
                    on: { click: _vm.goToSerial },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("button.serialNumEntry")) +
                        "\n      "
                    ),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
        _c("ips-input-number", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["qtyScan"]
                ? _vm.hideControl["qtyScan"]
                : true,
              expression:
                "customData['qtyScan'] ? hideControl['qtyScan'] : true",
            },
          ],
          ref: "ref40",
          attrs: {
            label: _vm.$t("label.confirmNum"),
            min: null,
            "more-than-zero": true,
            "not-auto-fill": "",
            required: "",
            custom: _vm.customData["qtyScan"],
            "source-parse-field": _vm.sourceParseField,
            max: _vm.formData.qtyPlan,
            disabled: _vm.enableSerialFlag || _vm.disQtyScan,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(40)
            },
            enter: function ($event) {
              return _vm.skipNext("submitRef")
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.formData.qtyScan,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "qtyScan", $$v)
            },
            expression: "formData.qtyScan",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-footer" },
      [
        _c(
          "hips-button",
          {
            ref: "submitRef",
            attrs: { size: "large" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonSubmit.apply(null, arguments)
              },
            },
          },
          [
            _c("svg-icon", {
              attrs: { slot: "icon", "icon-class": "success" },
              slot: "icon",
            }),
            _vm._v("\n      " + _vm._s(_vm.$t("button.confirm")) + "\n    "),
          ],
          1
        ),
        _c(
          "hips-button",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.customData["switchTask"]
                  ? _vm.hideControl["switchTask"]
                  : true,
                expression:
                  "customData['switchTask'] ? hideControl['switchTask'] : true",
              },
            ],
            attrs: { size: "large" },
            on: { click: _vm.switchTask },
          },
          [_vm._v("\n      " + _vm._s(_vm.$t("button.switchTask")) + "\n    ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }