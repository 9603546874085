var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [_c("ips-header", { attrs: { title: _vm.$t("title.taskQuery") } })],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["billCode"]
                ? _vm.hideControl["billCode"]
                : true,
              expression:
                "customData['billCode'] ? hideControl['billCode'] : true",
            },
          ],
          ref: "ref0",
          attrs: {
            title: _vm.$t("label.deliveryCode"),
            label: _vm.$t("label.deliveryCode"),
            required: "",
            params: "billCode",
            url: _vm.winvBaseUrl + "app-outbound-load/query-load-orders",
            "url-params": _vm.billParam,
            "pre-parse-url": _vm.urlData["billCode"],
            custom: _vm.customData["billCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(0)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(0, "lov")
            },
            enter: function ($event) {
              return _vm.skipNext(10)
            },
            down: function ($event) {
              return _vm.skipNext(10)
            },
            choose: function ($event) {
              return _vm.handleChoose("billCode", $event)
            },
          },
          model: {
            value: _vm.billCode,
            callback: function ($$v) {
              _vm.billCode = $$v
            },
            expression: "billCode",
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["whareaCode"]
                ? _vm.hideControl["whareaCode"]
                : true,
              expression:
                "customData['whareaCode'] ? hideControl['whareaCode'] : true",
            },
          ],
          ref: "ref10",
          attrs: {
            title: _vm.$t("label.sourceWharea"),
            label: _vm.$t("label.sourceWharea"),
            params: "whareaCode",
            url: _vm.winvBaseUrl + "app-outbound-load/query-task-whareas",
            "url-params": _vm.whareaParam,
            "pre-parse-url": _vm.urlData["whareaCode"],
            custom: _vm.customData["whareaCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(10)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(10, "lov")
            },
            enter: function ($event) {
              return _vm.skipNext(20)
            },
            down: function ($event) {
              return _vm.skipNext(20)
            },
            choose: function ($event) {
              return _vm.handleChoose("whareaCode", $event)
            },
          },
          model: {
            value: _vm.whareaCode,
            callback: function ($$v) {
              _vm.whareaCode = $$v
            },
            expression: "whareaCode",
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["sku"] ? _vm.hideControl["sku"] : true,
              expression: "customData['sku'] ? hideControl['sku'] : true",
            },
          ],
          ref: "ref20",
          attrs: {
            title: _vm.$t("label.sku"),
            label: _vm.$t("label.sku"),
            params: "sku",
            url: _vm.winvBaseUrl + "app-outbound-load/query-goods",
            "url-params": _vm.skuParam,
            "pre-parse-url": _vm.urlData["sku"],
            custom: _vm.customData["sku"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(20)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(20, "lov")
            },
            enter: function ($event) {
              return _vm.skipNext(30)
            },
            down: function ($event) {
              return _vm.skipNext(30)
            },
            choose: function ($event) {
              return _vm.handleChoose("sku", $event)
            },
          },
          model: {
            value: _vm.sku,
            callback: function ($$v) {
              _vm.sku = $$v
            },
            expression: "sku",
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["taskCode"]
                ? _vm.hideControl["taskCode"]
                : true,
              expression:
                "customData['taskCode'] ? hideControl['taskCode'] : true",
            },
          ],
          ref: "ref30",
          attrs: {
            title: _vm.$t("label.taskNumber"),
            label: _vm.$t("label.taskNumber"),
            params: "taskCode",
            url: _vm.winvBaseUrl + "app-outbound-load/query-tasks",
            "url-params": _vm.taskParam,
            "pre-parse-url": _vm.urlData["taskCode"],
            custom: _vm.customData["taskCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(30)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(30, "lov")
            },
            enter: function ($event) {
              return _vm.skipNext("submitRef")
            },
            down: function ($event) {
              return _vm.skipNext("submitRef")
            },
            choose: function ($event) {
              return _vm.handleChoose("taskCode", $event)
            },
          },
          model: {
            value: _vm.taskCode,
            callback: function ($$v) {
              _vm.taskCode = $$v
            },
            expression: "taskCode",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-footer" },
      [
        _c(
          "hips-button",
          {
            ref: "submitRef",
            attrs: { size: "large" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonSubmit.apply(null, arguments)
              },
            },
          },
          [
            _c("svg-icon", {
              attrs: { slot: "icon", "icon-class": "success" },
              slot: "icon",
            }),
            _vm._v("\n      " + _vm._s(_vm.$t("button.confirm")) + "\n    "),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }