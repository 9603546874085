import pickingByOrder from '../views/picking-by-order';
import taskExecutionWave from '../views/task-execution-wave';
let route = [
  {
    path: '/picking-by-order',
    name: 'pickingByOrder',
    component: pickingByOrder,
  },
  {
    path: '/task-execution-wave2',
    name: 'taskExecutionWave2',
    component: taskExecutionWave,
  },
];

export default route;
