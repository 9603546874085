import { render, staticRenderFns } from "./subinventory-query-detail.vue?vue&type=template&id=10923eb2&scoped=true&"
import script from "./subinventory-query-detail.vue?vue&type=script&lang=js&"
export * from "./subinventory-query-detail.vue?vue&type=script&lang=js&"
import style0 from "./subinventory-query-detail.vue?vue&type=style&index=0&id=10923eb2&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10923eb2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/app/wms-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('10923eb2')) {
      api.createRecord('10923eb2', component.options)
    } else {
      api.reload('10923eb2', component.options)
    }
    module.hot.accept("./subinventory-query-detail.vue?vue&type=template&id=10923eb2&scoped=true&", function () {
      api.rerender('10923eb2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/sub-stock-transfer/views/subinventory-query-detail.vue"
export default component.exports