var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: ["valueCell", { cell_special_background: _vm.specialBackground }],
    },
    [
      _vm.selfIcon
        ? _c("img", { attrs: { src: _vm.imgSrc, alt: "" } })
        : _vm._e(),
      _c("hips-cell", { attrs: { title: _vm.title, value: _vm.value } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }