var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "hips-view",
    { attrs: { "header-fixed": true } },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("ips-header", {
            attrs: {
              "is-index": false,
              title: _vm.waveOrderItem.waveBillCode,
              trigger: _vm.$t("button.skipTask"),
              "on-go-detail": _vm.handleSkipSubmit,
            },
            on: { beforeBack: _vm.beforeBack },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "ips-container" },
        [
          _c("ips-value-cell", {
            attrs: {
              title: _vm.$t("label.sku"),
              value: _vm.taskInfo.sku + " | " + _vm.taskInfo.goodsName || 0,
            },
          }),
          _c("ips-value-cell", {
            attrs: {
              title: _vm.$t("label.remark"),
              value: _vm.taskInfo.remark || "无",
            },
          }),
          _c("ips-value-cell", {
            attrs: {
              title: _vm.$t("label.whareaCode"),
              value: _vm.taskInfo.whareaCode || "无",
            },
          }),
          _c("ips-value-cell", {
            attrs: {
              title: _vm.$t("label.countLocation"),
              value: _vm.taskInfo.locationCode || "无",
            },
          }),
          _c("ips-lov", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.customData["locationCode"]
                  ? _vm.hideControl["locationCode"]
                  : true,
                expression:
                  "customData['locationCode'] ? hideControl['locationCode'] : true",
              },
            ],
            ref: "ref10",
            attrs: {
              label: _vm.$t("label.pickLocation"),
              title: _vm.$t("label.pickLocation"),
              required: "",
              params: "locationCode",
              "url-params": {
                whareaId: _vm.taskInfo.whareaId,
                goodsId: _vm.taskInfo.goodsId,
              },
              url: _vm.wmdmBaseUrl + "app-wave-picking/location-lov",
              "pre-parse-url": _vm.urlData["locationCode"],
              custom: _vm.customData["locationCode"],
              "source-parse-field": _vm.sourceParseField,
              disabled: _vm.fieldLocationCodeDisabled,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(10)
              },
              clickIcon: function ($event) {
                return _vm.handleCommonClick(10, "lov")
              },
              choose: function ($event) {
                return _vm.handleChooseLocationCode($event)
              },
              setSourceField: function ($event) {
                return _vm.saveSourceField($event)
              },
              valuedByFoucs: function ($event) {
                return _vm.valuedByFoucs($event)
              },
            },
            model: {
              value: _vm.inputData.locationCode,
              callback: function ($$v) {
                _vm.$set(_vm.inputData, "locationCode", $$v)
              },
              expression: "inputData.locationCode",
            },
          }),
          _c("ips-field", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.customData["sku"] ? _vm.hideControl["sku"] : true,
                expression: "customData['sku'] ? hideControl['sku'] : true",
              },
            ],
            ref: "ref20",
            attrs: {
              label: _vm.$t("label.sku"),
              required: "",
              params: "sku",
              display: "sku",
              "pre-parse-params": {
                orgId: _vm.waveOrderItem.orgId,
                ownerId: _vm.waveOrderItem.ownerId,
              },
              "pre-parse-url": _vm.urlData["sku"],
              custom: _vm.customData["sku"],
              "source-parse-field": _vm.sourceParseField,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(20)
              },
              down: _vm.handleScanSku,
              enter: _vm.handleScanSku,
              blur: _vm.handleScanSku,
              setSourceField: function ($event) {
                return _vm.saveSourceField($event)
              },
              valuedByFoucs: function ($event) {
                return _vm.valuedByFoucs($event)
              },
            },
            model: {
              value: _vm.inputData.sku,
              callback: function ($$v) {
                _vm.$set(_vm.inputData, "sku", $$v)
              },
              expression: "inputData.sku",
            },
          }),
          _vm.fieldCodeIsShow
            ? _c("ips-field", {
                ref: "ref30",
                attrs: {
                  label: _vm.fieldCodeLabel,
                  params: "code",
                  display: "code",
                  "pre-parse-url": _vm.urlData["code"],
                  custom: _vm.customData["code"],
                  required: _vm.fieldCodeRequired,
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleCommonClick(30)
                  },
                  down: _vm.handleBarCode,
                  enter: _vm.handleBarCode,
                  blur: _vm.handleBarCode,
                },
                model: {
                  value: _vm.inputData.code,
                  callback: function ($$v) {
                    _vm.$set(_vm.inputData, "code", $$v)
                  },
                  expression: "inputData.code",
                },
              })
            : _vm._e(),
          _c("ips-input-number", {
            ref: "ref40",
            attrs: {
              required: "",
              label: _vm.$t("label.quantity"),
              disabled: _vm.fieldQtyDisabled,
              "pre-parse-url": _vm.urlData["qty"],
              custom: _vm.customData["qty"],
              min: null,
              "more-than-zero": true,
              "not-auto-fill": "",
            },
            on: {
              enter: _vm.handleQuantity,
              down: _vm.handleQuantity,
              blur: _vm.handleQuantity,
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonClick(40)
              },
            },
            model: {
              value: _vm.inputData.qty,
              callback: function ($$v) {
                _vm.$set(_vm.inputData, "qty", $$v)
              },
              expression: "inputData.qty",
            },
          }),
          _c(
            "div",
            { staticClass: "footer-btns" },
            [
              _c(
                "hips-button",
                {
                  ref: "submitRef",
                  attrs: { size: "large", loading: _vm.submitFunLoading },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.submitFun.apply(null, arguments)
                    },
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "right", 39, $event.key, [
                          "Right",
                          "ArrowRight",
                        ])
                      ) {
                        return null
                      }
                      if ("button" in $event && $event.button !== 2) {
                        return null
                      }
                      return _vm.skipNext("nextRef")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("button.confirmThePicking")) +
                      "\n      "
                  ),
                ]
              ),
              _c(
                "hips-button",
                {
                  ref: "nextRef",
                  staticClass: "change-location",
                  attrs: { size: "large" },
                  on: { click: _vm.changeTheLocation },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "left", 37, $event.key, [
                          "Left",
                          "ArrowLeft",
                        ])
                      ) {
                        return null
                      }
                      if ("button" in $event && $event.button !== 0) {
                        return null
                      }
                      return _vm.skipNext("submitRef")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("button.changeTheLocation")) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "light-blue-box" }, [
            _c("div", { staticClass: "line" }, [
              _c("div", { staticClass: "left" }, [
                _vm._v(
                  "\n          需求数量：" +
                    _vm._s(_vm.taskInfo.qtyPlan) +
                    "\n        "
                ),
              ]),
              _c("div", { staticClass: "right" }, [
                _vm._v(
                  "\n          还需出库数量：" +
                    _vm._s(_vm.taskInfo.qtyPlan - _vm.totalQty) +
                    "\n        "
                ),
              ]),
            ]),
            _c("div", { staticClass: "line" }, [
              _c("div", { staticClass: "left" }, [
                _vm._v(
                  "\n          现有量：" + _vm._s(_vm.stockQty) + "\n        "
                ),
              ]),
              _c("div", { staticClass: "right" }, [
                _vm._v(
                  "\n          扫描总数：" + _vm._s(_vm.totalQty) + "\n        "
                ),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticStyle: { display: "flex" } },
            [
              _c(
                "hips-button",
                {
                  staticClass: "ips-btn-normal",
                  attrs: { size: "large", loading: _vm.quantitySubmitLoading },
                  on: { click: _vm.showSwitchTenantFun },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("message.scanTheDetail")) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "hips-popup",
        {
          attrs: { position: "bottom" },
          on: { "click-overlay": _vm.onTenantPopupClose },
          model: {
            value: _vm.showSwitchTenantPopup,
            callback: function ($$v) {
              _vm.showSwitchTenantPopup = $$v
            },
            expression: "showSwitchTenantPopup",
          },
        },
        [
          _c("div", { staticClass: "popup-title" }, [
            _c("div", { staticClass: "left" }),
            _c("div", { staticClass: "center" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("message.scanTheDetail")) +
                  "\n      "
              ),
            ]),
            _c(
              "div",
              { staticClass: "right", on: { click: _vm.onTenantPopupClose } },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("button.closeBtn")) + "\n      "
                ),
              ]
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "ips-table",
              staticStyle: { "overflow-y": "scroll" },
            },
            [
              _c(
                "table",
                [
                  _c(
                    "tr",
                    { staticStyle: { "background-color": "#eee" } },
                    _vm._l(_vm.tableTitle, function (item, index) {
                      return _c("th", { key: index, attrs: { scope: "col" } }, [
                        _vm._v(
                          "\n            " + _vm._s(item.name) + "\n          "
                        ),
                      ])
                    }),
                    0
                  ),
                  _vm._l(_vm.taskList, function (item, index) {
                    return _c(
                      "tr",
                      { key: index, ref: "tr" + index, refInFor: true },
                      [
                        _c("td", [_vm._v(_vm._s(item.sku))]),
                        _c("td", [_vm._v(_vm._s(item.serialNumber))]),
                        _c("td", [_vm._v(_vm._s(item.batchCode))]),
                        _c("td", [_vm._v(_vm._s(item.qty))]),
                        _c("td", [
                          _c(
                            "div",
                            {
                              staticClass: "delLineIcon",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.deleteTableData(item)
                                },
                              },
                            },
                            [
                              _c("svg-icon", {
                                attrs: { slot: "icon", "icon-class": "delete" },
                                slot: "icon",
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    )
                  }),
                ],
                2
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }