import request from '@/request';
import mixin from '@/mixin/mixin';

// 校验波次单号
export function validateWaveBillCode(data) {
  let url = `${mixin.data().winvBaseUrl}app-sorting-seeding/validate-wave-billCode`;
  return request.post(url, data);
}
// 校验物料
export function validateSku(data) {
  let url = `${mixin.data().winvBaseUrl}app-sorting-seeding/validate-sku`;
  return request.post(url, data);
}

// 校验条码
export function validateCode(data) {
  let url = `${mixin.data().winvBaseUrl}app-sorting-seeding/validate-code`;
  return request.post(url, data);
}

// 校验数量
export function validateQty(data) {
  let url = `${mixin.data().winvBaseUrl}app-sorting-seeding/validate-qty`;
  return request.post(url, data);
}

// 校验数量
export function sortingSeedingConfirm(data) {
  let url = `${mixin.data().winvBaseUrl}app-sorting-seeding/confirm`;
  return request.post(url, data);
}

// 校验数量
export function sortingSeedingExchangePack(data) {
    let url = `${mixin.data().winvBaseUrl}app-sorting-seeding/exchange-pack`;
    return request.post(url, data);
  }


  
