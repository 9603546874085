import Shipment from '../views/shipment'
import ShipmentDetail from '../views/shipment-detail.vue'
import serialNumberDetails from '../views/serial-number-details.vue'


let route = [
  {
    path: '/shipment',
    name: 'Shipment',
    component: Shipment,
  },
  {
    path: '/shipment-detail',
    name: 'ShipmentDetail',
    component: ShipmentDetail,
  },
  {
    path: '/serial-number-details',
    name: 'serialNumberDetails',
    component: serialNumberDetails,
  },
]

export default route