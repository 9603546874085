var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [_c("ips-header", { attrs: { title: _vm.$t("title.associatedSite") } })],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["pigeonhole"]
                ? _vm.hideControl["pigeonhole"]
                : true,
              expression:
                "customData['pigeonhole'] ? hideControl['pigeonhole']: true",
            },
          ],
          ref: "ref0",
          attrs: {
            label: _vm.$t("label.pigeonhole"),
            title: _vm.$t("label.pigeonhole"),
            params: "sourceBillCode",
            display: "sourceBillCode",
            url: _vm.winvBaseUrl + "app-inbound/query-source-inbound-order",
            "pre-parse-url": _vm.urlData["pigeonhole"],
            custom: _vm.customData["pigeonhole"],
            "source-parse-field": _vm.sourceParseField,
            "is-control": "",
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(0)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(0, "lov")
            },
            enter: function ($event) {
              return _vm.skipNext(10)
            },
            down: function ($event) {
              return _vm.skipNext(10)
            },
            choose: function ($event) {
              return _vm.handleChoose("pigeonhole", $event)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.inputData.pigeonhole,
            callback: function ($$v) {
              _vm.$set(_vm.inputData, "pigeonhole", $$v)
            },
            expression: "inputData.pigeonhole",
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["billCode"]
                ? _vm.hideControl["billCode"]
                : true,
              expression:
                "customData['billCode'] ? hideControl['billCode']: true",
            },
          ],
          ref: "ref10",
          attrs: {
            label: _vm.$t("label.waveNum"),
            title: _vm.$t("label.waveNum"),
            required: "",
            params: "billCode",
            display: "billCode",
            url: _vm.winvBaseUrl + "app-sorting-locations/query-wave",
            "pre-parse-url": _vm.urlData["billCode"],
            custom: _vm.customData["billCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(10)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(10, "lov")
            },
            enter: function ($event) {
              return _vm.skipNext("submitRef")
            },
            down: function ($event) {
              return _vm.skipNext("submitRef")
            },
            choose: function ($event) {
              return _vm.handleChoose("billCode", $event)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.inputData.billCode,
            callback: function ($$v) {
              _vm.$set(_vm.inputData, "billCode", $$v)
            },
            expression: "inputData.billCode",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-footer" },
      [
        _c(
          "hips-button",
          {
            ref: "submitRef",
            attrs: { size: "large" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonSubmit.apply(null, arguments)
              },
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleCommonSubmit.apply(null, arguments)
              },
              keypress: function ($event) {
                $event.preventDefault()
              },
            },
          },
          [
            _c("svg-icon", {
              attrs: { slot: "icon", "icon-class": "success" },
              slot: "icon",
            }),
            _vm._v("\n      " + _vm._s(_vm.$t("button.confirm")) + "\n    "),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }