var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ips-date-picker" },
    [
      _c(
        "ips-field",
        {
          attrs: { label: _vm.label, readonly: true },
          on: { focus: _vm.openPicker, click: _vm.handleClick },
          model: {
            value: _vm.dateValue,
            callback: function ($$v) {
              _vm.dateValue = $$v
            },
            expression: "dateValue",
          },
        },
        [
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.dateValue,
                  expression: "dateValue",
                },
              ],
              attrs: { slot: "append" },
              on: { click: _vm.clear },
              slot: "append",
            },
            [_c("svg-icon", { attrs: { "icon-class": "clear" } })],
            1
          ),
        ]
      ),
      _c(
        "hips-popup",
        {
          attrs: { position: "bottom" },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c("hips-datetime-picker", {
            attrs: { format: _vm.format, title: "选择时间" },
            on: { confirm: _vm.confirm, cancel: _vm.cancel },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }