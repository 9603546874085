/**
 * 创建发送消息体
 * @param {object} message - 发送消息体
 * @param {string} [message.cmd] - 消息体编码
 * @param {string} [message.from] - 消息体来源编码
 * @param {object} [message.value] - 消息体发送载体参数
 * @returns 
 */
function defineMessageAction(message) {
  return {
    data: {
      cmd: message.cmd,
      from: message.from || 'pda',
      value: message.value || {},
    }
  }
}


class SpeechTTS {
  constructor(options) {
    this.pitch = options.defaultPitch
    this.speed = options.defaultSpeed
    this.hasBootFlag = false
    this._uniAppPostMessage = window.uni && window.uni.postMessage
  }

  /**
   * 设置TTS语调
   * @param {number} pitch 值范围：0-100
   */
  setPitch(pitch) {
    this.pitch = pitch
  }

  /**
   * 设置TTS语速
   * @param {number} speed 值范围：0-100
   */
  setSpeed(speed) {
    this.speed = speed
  }

  setTTSBootFlag(value) {
    this.hasBootFlag = value
  }

  getTTSBootFlag() {
    return this.hasBootFlag
  }

  /**
   * SpeechTTS 初始化
   */
  // eslint-disable-next-line no-unused-vars
  init() {
    if (this._uniAppPostMessage) {
      this._uniAppPostMessage(
        defineMessageAction({
          cmd: 'tts_init',
          value: {
            callback: 'onTtsInitCallback'
          }
        })
      )
    } else {
      throw Error(`UniAppJSBridge Not Ready`)
    }
  }

  /**
   * SpeechTTS 文字转语音播放
   * @param {object} params - 文本内容
   * @param {string} [params.text] - 文本
   * @param {number} [params.pitch] - 语调
   * @param {number} [params.speed] - 语速
   * @param {function} [params.callback] - 播放结果回调函数
   */
   speak(params = {}) {
    if (!params.text) {
      return
    }

    if (this._uniAppPostMessage) {
      this._uniAppPostMessage(
        defineMessageAction({
          cmd: 'tts_speak',
          value: {
            pitch: this.pitch,
            speed: this.speed,
            ...params,
            callback: 'onTtsSpeakCallback'
          }
        })
      )
    } else {
      throw Error(`UniAppJSBridge Not Ready`)
    }
  }

  // 启动函数
  bootstrap() {
    const self = this
    
    document.addEventListener("UniAppJSBridgeReady", function() {
      window.uni.getEnv(function(res) {
        if (res.h5 !== true) {
          self.init()
        }
      });
    })

    /**
     * 语音初始化返回结果回调函数
     * @param {*} data 
     */
    window.onTtsInitCallback = function (isBoot) {
      self.setTTSBootFlag(isBoot)
    };
  }



}




export const speechTTS = new SpeechTTS({
  defaultPitch: 75,
  defaultSpeed: 60
})
