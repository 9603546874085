<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.inSundry')" />
    </div>
    <div class="ips-container">
      <!-- 托盘 -->
      <ips-field
        v-model="cidCode"
        params="cidCode"
        display="cidCode"
        :pre-parse-url="urlData['cidCode']"
        :label="$t('label.tray')"
        ref="ref0"
        :custom="customData['cidCode']"
        :source-parse-field="sourceParseField"
        v-show="customData['cidCode'] ? hideControl['cidCode'] : true"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
        @blur="containerBlur()"
        @enter="containerBlur()"
        @click.stop="handleCommonClick(0)"
      />
      <!-- 父托盘 -->
      <ips-field
        v-model="parentCidCode"
        params="parentCidCode"
        display="parentCidCode"
        :pre-parse-url="urlData['parentCidCode']"
        :label="$t('label.parentTray')"
        ref="ref5"
        :custom="customData['parentCidCode']"
        :source-parse-field="sourceParseField"
        :disabled="cidEditFlag"
        v-show="parentCidEnabled.paramValue === '1' ? true : false"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
        @blur="parentContainerBlur()"
        @enter="parentContainerEnter()"
        @click.stop="handleCommonClick(0)"
      />
      <!-- 库位 -->
      <ips-lov
        :label="$t('label.countLocation')"
        :title="[$t('label.countLocation'), $t('label.locationName'), $t('label.whareaName')]"
        :params="['locationCode', 'locationName', 'whareaName']"
        :disabled="locationEditFlag"
        v-model="locationCode"
        v-show="customData['locationCode'] ? hideControl['locationCode'] : true"
        required
        display="locationCode"
        :url-params="{ locationIdList: locationId, locationCode, cidCode, enabledFlag: 1 }"
        :url="`${wmdmBaseUrl}wms-locations`"
        :pre-parse-url="urlData['locationCode']"
        :custom="customData['locationCode']"
        :source-parse-field="sourceParseField"
        ref="ref10"
        @enter="skipNext(20)"
        @click.stop="handleCommonClick(10)"
        @clickIcon="handleCommonClick(10, 'lov')"
        @choose="handleChoose('locationCode', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 物料 -->
      <ips-lov
        :label="$t('label.sku')"
        v-model="sku"
        required
        :title="[$t('label.sku'), $t('label.goodsName'), $t('label.ownerName')]"
        :params="['sku', 'goodsName', 'ownerName']"
        display="sku"
        :url="`${wmdmBaseUrl}goods/goods-lov`"
        :url-params="{ ownerId }"
        :pre-parse-url="urlData['sku']"
        ref="ref20"
        :custom="customData['sku']"
        :source-parse-field="sourceParseField"
        v-show="customData['sku'] ? hideControl['sku'] : true"
        @setSourceField="saveSourceField($event)"
        @choose="handleChoose('sku', $event)"
        @click.stop="handleCommonClick(20)"
        @clickIcon="handleCommonClick(20, 'lov')"
        @valuedByFoucs="valuedByFoucs($event)"
      />

      <!-- 批次 -->
      <ips-field
        :label="$t('label.batch')"
        :title="$t('label.batch')"
        required
        params="batchCode"
        v-model="batchCode"
        display="batchCode"
        :pre-parse-url="urlData['batchCode']"
        ref="ref30"
        :custom="customData['batchCode']"
        :source-parse-field="sourceParseField"
        v-show="isbatch ? (customData['batchCode'] ? hideControl['batchCode'] : isbatch) : false"
        @setSourceField="saveSourceField($event)"
        @click.stop="handleCommonClick(30)"
        @enter="handleEnter"
        @valuedByFoucs="valuedByFoucs($event)"
      />

      <!-- 品名单位 -->
      <div v-if="goodsId">
        <ips-page-card :react-field="['ownerName', 'uomName']" :item="goodsLov" :footer-info="{ goodsName: goodsLov.goodsName }" />
      </div>

      <hips-row>
        <div style="margin-top: 16px;" v-if="['INV_CTRL', 'IN_CTRL', 'SEMI_INV_CTRL'].includes(serialType)">
          <hips-col span="14">
            <ips-input-number
              v-model="transferQty"
              required
              :label="$t('label.quantity')"
              ref="ref40"
              thousands-formatter
              not-auto-fill
              :integral-mode="true"
              :custom="customData['transferQty']"
              :source-parse-field="sourceParseField"
              :enable-serial="serialType"
              v-show="customData['transferQty'] ? hideControl['transferQty'] : true"
              @setSourceField="saveSourceField($event)"
              @valuedByFoucs="valuedByFoucs($event)"
              @enter="showSeriesDetail ? skipNext('refSerail') : skipNext(60)"
              @click.stop="handleCommonClick(50)"
              :more-than-zero="true"
            />
          </hips-col>
          <hips-col span="10">
            <!-- 序列号明细 -->
            <hips-button
              type="primary"
              size="large"
              :disabled="!transferQty"
              v-show="showSeriesDetail ? (customData['showSeriesDetail'] ? hideControl['showSeriesDetail'] : showSeriesDetail) : false"
              @click.stop="goToSerialDetail"
              @enter="skipNext(60)"
              ref="refSerail"
              style="width: 93%; margin-left: 10px; color: #FFFFFF; font-size:
                          14px; height: 36px;line-height: 36px"
            >
              {{ serialNumberList.length > 0 ? `${$t('button.seriesDetail')}(${serialNumberList.length})` : $t('button.seriesDetail') }}
            </hips-button>
          </hips-col>
        </div>
      </hips-row>
      <hips-row>
        <div style="margin-top: 16px;" v-if="!['INV_CTRL', 'IN_CTRL', 'SEMI_INV_CTRL'].includes(serialType)">
          <hips-col span="24">
            <ips-number
              v-model="transferQty"
              required
              :label="$t('label.quantity')"
              ref="ref40"
              thousands-formatter
              :integral-mode="'UNCTRL' !== serialType"
              @enter="skipNext(60)"
              @click.stop="handleCommonClick(50)"
              :enable-serial="serialType"
              :custom="customData['transferQty']"
              :source-parse-field="sourceParseField"
              v-show="customData['transferQty'] ? hideControl['transferQty'] : true"
              @setSourceField="saveSourceField($event)"
              @valuedByFoucs="valuedByFoucs($event)"
              not-auto-fill
              :min="null"
              :precision="showSeriesDetail ? 0 : undefined"
            />
          </hips-col>
        </div>
      </hips-row>
      <!--现有量、可用量-->
      <div class="card_num_subtrans">
        <hips-row>
          <div class="row-num">
            <hips-col span="12" style="display: flex; align-items: center; height: 100%;">
              <div class="title" style="display: inline-block;">
                {{ $t('label.availableQty') }}:
              </div>
              <div class="value" style="display: inline-block;">
                {{ qty }}
              </div>
            </hips-col>
            <hips-col span="12" style="display: flex; align-items: center; height: 100%;">
              <div class="title" style="display: inline-block;">
                {{ $t('label.currentUseQty') }}:
              </div>
              <div class="value" style="display: inline-block;">
                {{ validQty }}
              </div>
            </hips-col>
          </div>
        </hips-row>
      </div>
      <!--调整原因-->
      <ips-lov
        :label="$t('label.sundryReason')"
        :title="$t('label.sundryReason')"
        v-model="reason"
        v-show="customData['reason'] ? hideControl['reason'] : true"
        :params="['meaning']"
        :url="`${wmdmBaseUrl}wms-common/lov-data-query`"
        :url-params="{ lovCode: 'WINV.INV_MOVE_MISC_REASON' }"
        :pre-parse-url="urlData['reason']"
        :is-page-content="false"
        :custom="customData['reason']"
        :source-parse-field="sourceParseField"
        ref="ref60"
        @click.stop="handleCommonClick(60)"
        @clickIcon="handleCommonClick(60, 'lov')"
        @enter="skipNext('submitRef')"
        @choose="handleChoose('reason', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
    </div>
    <div class="ips-footer">
      <hips-button size="large" ref="submitRef" @click="handleButtonSubmit">
        <svg-icon slot="icon" icon-class="success" />
        {{ $t('button.confirm') }}
      </hips-button>
    </div>
  </hips-view>
</template>

<script>
import { checkCidCode, checkGoodsQty, inSunderySubmit, checkBatchCode, checkGoodsMixed, getparentCidEnabled } from '../api';
import { Row, Col } from '@hips/vue-ui';
import { storage } from '@/utils';
import { pick } from 'lodash';
export default {
  name: 'MiscellaneousIn',
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
  },
  data() {
    return {
      cidCode: '',
      parentCidCode: '',
      cidEditFlag: true,
      locationEditFlag: false,
      isClearLocation: false,
      locationId: '',
      locationCode: '',
      locationType: '',
      warehouseId: '',
      warehouseCode: '',
      whareaId: '',
      whareaCode: '',
      orgId: '',
      orgCode: '',
      orgName: '',
      sku: '',
      goodsId: '',
      goodsLov: null,
      isbatch: '',
      ownerId: '',
      ownerCode: '',
      ownerName: '',
      serialType: '',
      serialCtrl: false, // 是否序列号控制
      uomId: '',
      uomCode: '',
      uomName: '',
      batchCode: '',
      qty: '',
      validQty: '',
      transferQty: '',
      reason: '',
      reasonValue: '',
      receiptId: '',
      urlData: {},
      receiveQty: '',
      showSeriesDetail: false, // 显示序列号明细按钮
      customData: {}, // 个性化配置信息列表
      hideControl: {}, // 个性化配置显示隐藏控制
      sourceParseField: {}, // 界面中所有的来源解析字段
      inboundSerialReceiptList: [], // 序列号明细list
      serialNumberList: [],
      parentCidEnabled: {}, //页面参数是否显示父托盘字段
    };
  },
  methods: {
    async handleEnter() {
      await this.handleBlur('batchCode');
      if(!this.notCreate) {
          this.skipNext(40);
      }
      this.notCreate = false;
    },
    // 个性化配置
    // 保存来源解析字段
    async saveSourceField(fieldObj) {
      this.saveSourceParseField(this, fieldObj);
    },
    // 字段聚焦时赋值操作
    async valuedByFoucs(options = {}) {
      const { custom = {}, currentValue } = options;
      await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
    },

    handleChoose(type, obj) {
      switch (type) {
        case 'locationCode':
          this.locationId = obj.locationId;
          this.warehouseId = obj.warehouseId;
          this.warehouseCode = obj.warehouseCode;
          this.locationType = obj.locationType;
          this.whareaId = obj.whareaId;
          this.whareaCode = obj.whareaCode;
          this.orgId = obj.orgId;
          this.orgCode = obj.orgCode;
          this.orgName = obj.orgName;
          break;
        case 'sku':
          this.goodsLov = obj;
          this.goodsId = obj.goodsId;
          this.ownerId = obj.ownerId;
          this.ownerCode = obj.ownerCode;
          this.ownerName = obj.ownerName;
          this.isbatch = obj.isbatch;
          this.serialType = obj.serialType;
          (this.uomId = obj.uomId),
            (this.uomCode = obj.uomCode),
            (this.uomName = obj.uomName),
            (this.receiptId = obj.receiptId),
            (this.showSeriesDetail = ['INV_CTRL', 'IN_CTRL', 'SEMI_INV_CTRL'].includes(obj.serialType)),
            this.skuCheckAndGetQty();
          break;
        case 'reason':
          this.reasonValue = obj.value;
          this.reason = obj.meaning;
          break;
        default:
          return null;
      }
    },

    async skuCheckAndGetQty() {
      try {
        const data = {
          params: {
            goodsId: this.goodsId,
            sku: this.sku,
            orgId: this.orgId,
            orgCode: this.orgCode,
            ownerId: this.ownerId,
            ownerCode: this.ownerCode,
            locationId: this.locationId,
            locationCode: this.locationCode,
          },
        };
        const res = await checkGoodsMixed(data);
        if (res && res.failed) {
          this.$hint.showFailed(res);
          this.skipNext(20);
        } else {
          this.getGoodsQty();
          if (this.isbatch === 1) {
            this.skipNext(30);
          } else {
            this.skipNext(40);
          }
        }
      } catch {
        this.skipNext(20);
      }
    },

    async getGoodsQty() {
      let data;
      if (this.isbatch === 0) {
        data = {
          params: {
            cidCode: this.cidCode,
            goodsId: this.goodsId,
            locationId: this.locationId,
            ownerId: this.ownerId,
            warehouseId: this.warehouseId,
            whareaId: this.whareaId,
          },
        };
        const res = await checkGoodsQty(data);
        if (res && res.qty) {
          this.qty = res.qty;
          this.validQty = res.validQty;
        } else {
          this.qty = 0;
          this.validQty = 0;
        }
      } else if (this.batchCode) {
        data = {
          params: {
            cidCode: this.cidCode,
            goodsId: this.goodsId,
            locationId: this.locationId,
            ownerId: this.ownerId,
            warehouseId: this.warehouseId,
            whareaId: this.whareaId,
            batchCode: this.batchCode,
          },
        };
        const res = await checkGoodsQty(data);
        if (res && res.qty) {
          this.qty = res.qty;
          this.validQty = res.validQty;
        } else {
          this.qty = 0;
          this.validQty = 0;
        }
      }
    },

    async containerBlur() {
      if (!this.cidCode) {
        this.skipNext(10);
      } else {
        try {
          const data = {
            params: {
              cidCode: this.cidCode,
            },
          };
          const res = await checkCidCode(data);
          if (res && res.cidId && res.inStockStatus !== 'EMPTY') {
            this.parentCidCode = res.parentCidCode;
            this.parentCidId = res.parentCidId;
            this.locationCode = res.locationCode;
            this.locationId = res.locationId;
            this.orgCode = res.orgCode;
            this.orgId = res.orgId;
            this.whareaCode = res.whareaCode;
            this.whareaId = res.whareaId;
            this.warehouseCode = res.warehouseCode;
            this.warehouseId = res.warehouseId;
            this.locationEditFlag = true;
            if (res.parentCidCode) {
              this.cidEditFlag = true;
              this.skipNext(20);
            } else {
              this.cidEditFlag = false;
              if (this.parentCidEnabled.paramValue === '1') {
                this.$nextTick(() => {
                  this.skipNext(5);
                });
              } else if (this.locationCode) {
                this.skipNext(20);
              } else {
                this.skipNext(10);
              }
            }
          } else {
            this.cidEditFlag = false;
            if (this.parentCidEnabled.paramValue === '1') {
              this.$nextTick(() => {
                this.skipNext(5);
              });
            } else if (this.locationCode) {
              this.skipNext(20);
            } else {
              this.skipNext(10);
            }
          }
        } catch (e) {
          this.skipNext(0);
        }
      }
    },

    parentContainerEnter() {
      if (this.locationEditFlag) {
        this.skipNext(20);
      } else {
        this.skipNext(10);
      }
    },

    async parentContainerBlur() {
      if (this.parentCidCode) {
        try {
          const data = {
            params: {
              parentCidCode: this.parentCidCode,
              cidCode: this.cidCode,
            },
          };
          const res = await checkCidCode(data);
          if (res && res.cidId && res.inStockStatus && res.inStockStatus !== 'EMPTY' && !this.locationId) {
            this.locationCode = res.locationCode;
            this.locationId = res.locationId;
            this.orgCode = res.orgCode;
            this.orgId = res.orgId;
            this.whareaCode = res.whareaCode;
            this.whareaId = res.whareaId;
            this.warehouseCode = res.warehouseCode;
            this.warehouseId = res.warehouseId;
            this.locationEditFlag = true;
            this.isClearLocation = true;
            this.skipNext(20);
          } else if (res && res.cidId && res.inStockStatus && res.inStockStatus !== 'EMPTY' && this.locationId) {
            this.$nextTick(() => {
              this.skipNext(10);
            });
          } else {
            if (this.locationEditFlag) {
              this.skipNext(20);
            } else {
              this.skipNext(10);
            }
          }
        } catch (e) {
          this.skipNext(10);
        }
      }
    },

    async handleBlur(flag) {
      const onConfirm = () => {
        const urlParam = {
          name: 'createBatch',
          params: {
            source: 'MiscellaneousIn',
            skuInfo: {
              goodsId: this.goodsId,
              sku: this.sku,
              orgId: this.orgId,
              orgCode: this.orgCode,
              ownerId: this.ownerId,
              ownerCode: this.ownerCode,
            },
            batchCode: this.batchCode,
          },
        };
        this.$router.pushPage(urlParam);
      };
      const onCancel = () => {
        this.$nextTick(() => {
          this.skipNext(30);
        })
      };
      if (flag === 'batchCode' && this.batchCode) {
        try {
          const data = {
            params: {
              isbatch: this.isbatch,
              goodsId: this.goodsId,
              batchCode: this.batchCode,
              sku: this.sku,
              orgId: this.orgId,
              orgCode: this.orgCode,
              ownerId: this.ownerId,
              ownerCode: this.ownerCode,
              locationCode: this.locationCode,
              locationId: this.locationId,
            },
          };
          const res = await checkBatchCode(data);
          if (res && res.id) {
            this.batchId = res.batchId;
            this.getGoodsQty();
          }else if(res && !res.batchId) {
            this.$common.createBatchMethod.call(this, {createBatchControl: this.createBatchControl, onConfirm, onCancel});
          }
        } catch {
          this.skipNext(30);
        }
      }
    },
    /**
     * 前往序列号明细界面
     */
    async goToSerialDetail() {
      let data = {
        cidCode: this.cidCode,
        locationId: this.locationId,
        locationCode: this.locationCode,
        sku: this.sku,
        goodsId: this.goodsId,
        batchCode: this.batchCode,
        serialType: this.serialType,
      };
      let serialUrl = 'app-miscellaneous-in/serial-check';
      const param = {
        name: 'serialComponent',
        params: {
          // data,
          serialUrl,
          urlParam: {
            ...data,
          },
          receiveQty: this.transferQty,
          serialNumberList: this.serialNumberList, // 序列号信息
          // numberKey: 'stringValue', // 自定义序列号列表中的‘序列号数量’键，默认'serialNumber'
          // task: this.inboundSerialReceiptList,
          // callback: function (serialNum, serialId) {
          //   const item = {
          //     serialId,
          //     serialNumber: serialNum,
          //     serialNumberStatusName: '合格',
          //     serialNumberStatus: 'QUALIFIED',
          //     serialQty: 1,
          //   };
          //   this.tableData.push(item);
          // },
        },
      };
      this.$router.pushPage(param);
    },

    async parentCidEnabledShow() {
      try {
        this.$hint.showLoading();
        const data = {
          params: {
            pageId: storage.get('menuId'),
            device: 'PDA',
            functionCode: 'PDA_MISCELLANEOUS_IN',
            paramCode: 'PARENT_CID_ENABLE',
          },
        };
        const res = await getparentCidEnabled(data);
        this.parentCidEnabled = res;
      } finally {
        this.$hint.hideLoading();
      }
    },
    /**
     * 获取页面功能参数
     */
    async getPageFunctionParameters() {
      const { BATCH_CREATE_CONTROL } = await this.fetchPageFunctionConfig({
        functionCode: 'PDA_MISCELLANEOUS_IN',
        deviceCode: 'PDA',
      });
      this.createBatchControl = BATCH_CREATE_CONTROL;
    },

    // 杂项入库提交
    async handleButtonSubmit() {
      try {
        this.$hint.showLoading();
        this.handleSubmitStatus();

        // 获取页面功能参数
        const { MOVE_CREATE_CONTAINER_CONTROL = 0, BATCH_CREATE_CONTROL = 0 } = await this.fetchPageFunctionConfig({
          pageId: storage.get('menuId'),
          pageCode: 'MISCELLANEOUS_IN',
          deviceCode: 'PDA',
          functionCode: 'PDA_MISCELLANEOUS_IN',
        });

        // 配置序列号列表
        let serialStockProcessDtoList = [];
        this.serialNumberList.forEach((item) => {
          serialStockProcessDtoList.push(pick(item, ['serialNumber', 'batchId']));
        });

        const paramIn = {
          cidCode: this.cidCode,
          parentCidCode: this.parentCidCode,
          locationId: this.locationId,
          locationCode: this.locationCode,
          goodsId: this.goodsId,
          sku: this.sku,
          batchCode: this.batchCode,
          transferQty: this.transferQty,
          qty: this.qty,
          validQty: this.validQty,
          reason: this.reasonValue,
          loadCreateLpnControl: Number(MOVE_CREATE_CONTAINER_CONTROL),
          loadCreateBatchControl: BATCH_CREATE_CONTROL,
          serialStockProcessDtoList,
        };

        const postData = {
          paramIn,
          macroCode: 'WMS_APP_MISCELLANEOUS_IN',
        };

        const res = await inSunderySubmit(postData);
        if (res && res.success) {
          // 操作成功
          this.$hint.showSuccess(this.$i18n.t('message.operationSuccess'));
          this.init();
          // 清除当前界面的二维码解析字段
          await this.clearSourceFields(this, true);
        }
      } finally {
        this.$hint.hideLoading();
        this.handleSubmitStatus();
      }
    },

    init() {
      this.cidCode = '';
      this.parentCidCode = '';
      this.locationId = '';
      this.locationCode = '';
      this.locationType = '';
      this.warehouseId = '';
      this.warehouseCode = '';
      this.whareaId = '';
      this.whareaCode = '';
      this.orgId = '';
      this.orgCode = '';
      this.orgName = '';
      this.sku = '';
      this.goodsId = '';
      this.goodsLov = null;
      this.isbatch = '';
      this.ownerId = '';
      this.ownerCode = '';
      this.serialType = '';
      this.uomId = '';
      this.uomCode = '';
      this.uomName = '';
      this.batchId = '';
      this.batchCode = '';
      this.qty = '';
      this.validQty = '';
      this.transferQty = '';
      this.reason = '';
      this.reasonValue = '';
      this.receiptId = '';
      this.urlData = {};
      this.receiveQty = '';
      this.showSeriesDetail = false;
      this.customData = {};
      this.hideControl = {};
      this.sourceParseField = {};
      this.inboundSerialReceiptList = [];
      this.serialNumberList = [];
      this.skipNext(0);
    },
  },
  async mounted() {
    this.inputFieldList = [
      { type: 'input', required: false, ref: 0, bind: 'cidCode', field: 'cidCode' },
      { type: 'input', required: false, ref: 5, bind: 'parentCidCode', field: 'parentCidCode' },
      { type: 'lov', required: true, ref: 10, bind: 'locationCode', field: 'locationCode' },
      { type: 'lov', required: true, ref: 20, bind: 'sku', field: 'sku' },
      { type: 'lov', required: true, ref: 30, bind: 'batchCode', field: 'batchCode' },
      { type: 'number', required: true, ref: 40, bind: 'transferQty', field: 'transferQty' },
      { type: 'lov', required: false, ref: 60, bind: 'reason', field: 'reason' },
    ];
    this.parentCidEnabledShow();
    this.getPageFunctionParameters();
    this.skipNext(0);
  },
  async created() {
    const { hideControl, customObj } = this.handleCustomData(storage.get('customData'));
    this.customData = customObj;
    this.hideControl = hideControl;
    // console.log('按单收货customData', Object.assign({}, this.customData));
  },
  activated() {
    // const inboundSerialReceiptList = JSON.parse(sessionStorage.getItem('inboundSerialReceiptList'));
    // this.inboundSerialReceiptList = inboundSerialReceiptList || [];
    // sessionStorage.removeItem('inboundSerialReceiptList');
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'serialComponent') {
      this.$store.commit('pushKeepAlives', 'MiscellaneousIn');
    }
    if (to.name === 'createBatch') {
      this.$store.commit('pushKeepAlives', 'MiscellaneousIn');
    }
    next();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const _this = vm;
      if (from.name === 'serialComponent') {
        const serialNumberList = JSON.parse(sessionStorage.getItem('serialNumberList')) || [];
        _this.serialNumberList = serialNumberList;
        // 重新覆值操作数量
        _this.transferQty = serialNumberList.length;
        sessionStorage.removeItem('serialNumberList');
        setTimeout(() => {
          _this.skipNext(60);
        }, 300);
      }
      if(from.name === 'createBatch') {
        _this.$nextTick(() => {
          _this.skipNext(40);
        })
      }
    });
  },
  watch: {
    cidCode: function (val, oldval) {
      if (oldval !== val) {
        this.locationId = '';
        this.locationCode = '';
        this.locationEditFlag = false;
        this.parentCidCode = '';
        this.parentCidId = '';
        this.cidEditFlag = true;
      }
    },
    parentCidCode: function (val, oldval) {
      if (oldval !== val && this.isClearLocation) {
        this.locationId = '';
        this.locationCode = '';
        this.locationEditFlag = false;
        this.isClearLocation = false;
      }
    },
    locationCode: function(val, oldval) {
      if(oldval && val === '') {
        this.locationId = '';
      }
    },
    serialType: function (val) {
      if (val && ['INV_CTRL', 'IN_CTRL', 'SEMI_INV_CTRL'].includes(val)) {
        this.serialCtrl = true;
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
 @import '~@/style/var.styl'
.ips-container{
  // padding-top 0!important;
  .card_num_subtrans{
    margin 10px 0;
    padding  0 12px
    height: 50px;
    border-radius: 1.6vw;
    color: #000;
    box-shadow: 0 0 2.133vw #d9d9d9;
    border: none;
    font-size: 14px;
    background-color #FFFFFF;
    .row{
      display flex
      /*padding 0 0 7px 0*/
      .title{
        height:16px;
        font-size:14px;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:200;
        color:var(--primaryColor);
        line-height:18px;
      }
    }
      .hips-row{
          display flex
          /*padding-left: 5px;*/
          padding-top: 7px;
          .button-detail{
              margin-left: 5px;
              margin-right: 5px;
              padding 0 5px;
              background-color: var(--primaryColor);
              color: #FFFFFF;
              border: none;
              font-size: 14px;
              height: 36px;
              line-height: 36px;
          }
      }
    .row-num{
      display inline-block;
      align-items center;
      margin-top: 3px;
      height:25px;
      background:#E6F7FF;
      border-radius:6px;
      width 100%;
      border:2px solid #91D5FF;
      .row-50{
        width 50%
        /*display flex*/
      }
      .title{
        width:100px;
        height:16px;
        padding-left: 5px;
        font-size:14px;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:200;
        color:#595959;
        line-height:18px;
      }
      .value{
        height:18px;
        font-size:14px;
        font-family:PingFangSC-Medium,PingFang SC;
        font-weight:initial;
        color:#000000;
        line-height:18px;
        overflow: hidden;      /*溢出隐藏*/
        white-space: nowrap;	/*规定文本不进行换行*/
        text-overflow: ellipsis;	/*当对象内文本溢出时显示省略标记（...）*/
      }
    }
  }
}
.ips-btn-normal {
  border-radius: 8px !important;
  margin: 12px 10px 12px 10px;
  background-color var(--primaryColor) !important;
  color #ffffff !important
  font-size 17px !important
}
</style>
