<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.trayDetail')" :is-index="false" />
    </div>
    <div class="ips-container">
      <!--起始托盘-->
      <ips-value-cell :title="$t('label.startOfTray')" :value="cidCode" />
      <hips-scroll>
        <ips-page-card
          v-for="(item, index) in goodsList"
          :key="index"
          :react-field="['sku', 'cidCode', 'batchCode', 'uomName', 'qty', 'validQty']"
          :item="{...item}"
        >
          <ips-message-tip :message="item.goodsName" />
        </ips-page-card>
      </hips-scroll>
    </div>
  </hips-view>
</template>

<script>
import { queryCidDetail } from '../api'
export default {
  name: 'UnpackDetail',
  data() {
    return {
      cidCode: '', //起始托盘
      cidId: '',
      goodsList: [], //物料数据
    };
  },
  methods: {
    // 获取上个页面传过来的数据
    getDataFromParams() {
      const { cidCode, cidId } = this.$route.params;
      this.cidCode = cidCode;
      this.cidId = cidId;
    },
    // 查询托盘明细
    async getDetail() {
      try {
        this.$hint.showLoading();
        let param = {
          params: {
            cidId: this.cidId,
          },
        };
        const res = await queryCidDetail(param);
        if(res && res.content && res.content.length > 0){
          this.goodsList = res.content;
          console.log('goodsList', this.goodsList);
        }
      } catch(e) {
        console.log('错误: ' + e);
      } finally {
        this.$hint.hideLoading();
      }
    },
  },
  mounted() {
    this.getDataFromParams();
    this.getDetail();
  },
};
</script>

<style lang="stylus" scoped></style>
