<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <!-- 分拣执行 -->
      <ips-header :title="$t('title.waveNum') + `${waveBillCode}`" :is-index="false" />
    </div>
    <div class="ips-container">
      <!-- 查询类型 -->
      <div class="ips-outbound-style">
        <ips-lov
          :label="$t('label.queryType')"
          :title="$t('label.queryType')"
          v-model="billData.outboundOrder"
          v-show="customData['outboundOrder'] ? hideControl['outboundOrder']: true"
          params="meaning"
          :url="`${wmdmBaseUrl}wms-common/lov-data-query`"
          :url-params="{lovCode:'WINV.SORTING_QUERY'}"
          :is-page-content="false"
          :custom="customData['outboundOrder']"
          :source-parse-field="sourceParseField"
          ref="ref0"
          is-control
          :readonly="true"
          @click.stop="handleCommonClick(0)"
          @clickIcon="handleCommonClick(0, 'lov')"
          @enter="skipNext(10)"
          @down="skipNext(10)"
          @choose="handleChoose('outboundOrder', $event)"
          @setSourceField="saveSourceField($event)"
          @valuedByFoucs="valuedByFoucs($event)"
        />
      </div>
      <hips-row>
        <hips-col span="19">
          <ips-field
            :label="$t('label.queryConditions')"
            v-model="billData.querySearch"
            v-show="customData['querySearch'] ? hideControl['querySearch']: true"
            :custom="customData['querySearch']"
            :source-parse-field="sourceParseField"
            ref="ref10"
            @enter="skipNext(20)"
            @setSourceField="saveSourceField($event)"
            @valuedByFoucs="valuedByFoucs($event)"
          />
        </hips-col>
        <hips-col span="4" class="ips-row-left">
          <hips-button
            type="warning"
            @click.stop="handleButtonSubmit"
            @keyup.enter.native="handleButtonSubmit"
            @keypress.native.prevent
            v-show="customData['search'] ? hideControl['search']: true"
            ref="ref20"
          >
            {{ $t('button.search') }}
          </hips-button>
        </hips-col>
      </hips-row>
      <div class="ips-table">
        <table>
          <tr style="background-color: #eee">
            <th v-for="(item, index) in tableTitle" :key="index" scope="col">
              {{ item.name }}
            </th>
          </tr>
          <tr
            v-for="(item, index) in tableData"
            :key="index"
            :ref="`tr${index}`"
            :class="item.selectedFlag ? 'back-green' : ''"
            @click="handleSelect(item, index)"
          >
            <td>{{ item.sortingLocation }}</td>
            <td>{{ item.sku }}</td>
            <td
              class="font-skyblue"
              @click.stop="modifyNum(index)"
              :ref="`num${index}`"
            >{{ item.processQty }}/{{ item.planQty }}</td>
            <td>{{ item.orderLine }}</td>
          </tr>
        </table>
        <hips-popup v-model="modifyFlag">
          <div class="modifyModel">
            <!-- 执行数量修改 -->
            <h1>{{ $t('label.processQtyUpdate') }}</h1>
            <!-- 执行数量修改 -->
            <ips-input-number
              v-model="processQty"
              :label="$t('label.dischargeNum')"
              ref="modifyRef"
              :min="null"
              :disabled="serialNumber"
              :more-than-zero="true"
              @enter="modifyConfirm"
              @down="modifyConfirm"
            />
            <hips-button
              type="warning"
              style="width:50%; margin-top:10px"
              @click="modifyConfirm"
            >
              {{ $t('button.confirm') }}
            </hips-button>
            <hips-button
              type="warn"
              style="width:50%; margin-top:10px"
              @click="modifyCancel"
            > {{ $t('button.cancel') }} </hips-button>
          </div>
        </hips-popup>
      </div>
    </div>
    <div class="ips-footer">
      <hips-button
        size="large"
        ref="submitRef"
        @click.stop="handleSortSubmit"
      >
        <svg-icon slot="icon" icon-class="success" />
        {{ $t('button.sortConfirm') }}
      </hips-button>
    </div>
  </hips-view>
</template>

<script>
  import { queryProcessRecords, sortConfirm, clearTask } from '../api';
  import { Row, Col, Search  } from '@hips/vue-ui';
  export default {
  name: 'WaveOrderDetail',
  components:{
    [Row.name]: Row,
    [Col.name]: Col,
    [Search.name]: Search,
  },
  data() {
    return {
      waveBillId: '', //入库单id
      waveBillCode: '', //入库单号
      goodsId: '',
      sku: '',
      sumProcessQty: '',
      modifyFlag: false,
      processQty: '',
      planQty: '',
      modifyIndex: '',
      serialNumber: '',
      serialId: '',
      billData: {
        waveBillId: this.waveBillId,
        planQty: '',
        receiveQty: '',
        outboundOrder: this.$t('label.outboundOrderR'),
        querySearch: '',
      },
      selectValue: '', //筛选前选择的数值
      lineData: [],
      selectData: [],
      tableTitle: [],
      tableData: [],
      copyTableData: [],
      sortDtoList: [],
      customData: {}, // 个性化配置信息列表
      hideControl: {}, // 个性化配置显示隐藏控制
      sourceParseField: {}, // 界面中所有的来源解析字段
    };
  },

  methods: {
    // 获取上页数据
    async getParamsData(){
      const {waveBillId, waveBillCode, goodsId, sku, sumProcessQty, waveBillTypeId, serialNumber, serialId, sourceParseField, customData, hideControl, uomId, uomCode} = this.$route.params;
      this.waveBillId = waveBillId;
      this.waveBillTypeId = waveBillTypeId;
      this.waveBillCode = waveBillCode;
      this.waveBillCode = waveBillCode;
      this.goodsId = goodsId;
      this.serialNumber = serialNumber;
      this.sku = sku;
      this.sumProcessQty = sumProcessQty;
      this.serialId = serialId;
      this.sourceParseField = sourceParseField;
      this.customData = customData;
      this.hideControl = hideControl;
      this.uomId = uomId;
      this.uomCode = uomCode;
    },
    /**
    * 获取数据
    */
    async getBillData() {
      try {
        this.$hint.showLoading();
        // const url = `${this.winvBaseUrl}app-outbound-sorting/query-process-records`;
        const data = {
          waveBillId: this.waveBillId,
          waveBillTypeId: this.waveBillTypeId,
          waveBillCode: this.waveBillCode,
          goodsId: this.goodsId,
          sku: this.sku,
          serialNumber: this.serialNumber,
          serialId: this.serialId,
          sumProcessQty: this.sumProcessQty,
        };
        // const res = await this.$http.post(url, data);
        const res = await queryProcessRecords(data);
        res.map((item)=>{
          if(item.isChecked===1) {
            item.selectedFlag = true;
          }
        });
        this.tableData = res;
        this.copyTableData = res;
      } catch (e) {
        console.log(e);
      }finally {
        this.$hint.hideLoading();
      }
    },

    handleChoose(type, obj) {
      switch (type) {
        case 'outboundOrder':
          this.selectValue = obj.value;
          break;
        default:
          return null;
      }
    },

    // 单选或取消操作
    handleSelect(item, index) {
      item.selectedFlag = !item.selectedFlag;
      this.$refs[`tr${index}`][0].className = item.selectedFlag ? 'back-green' : '';
      this.totalSelectedNum = item.selectedFlag ? this.totalSelectedNum + 1 : this.totalSelectedNum - 1;
    },
    // 编辑执行数量弹出框
    modifyNum(index) {
      this.modifyFlag = true;
      const { processQty, planQty } = this.tableData[index];
      this.processQty = processQty;
      this.planQty = planQty;
      this.modifyIndex = index;
    },
    // 弹出框确认
    modifyConfirm() {
      const index = this.modifyIndex;
      if (this.planQty < this.processQty) {
        this.$hint.showFailed(this.$i18n.t('message.waveOrderNumCheck'), true);
        return;
      }
      if(this.processQty>0){
        if(this.processQty===this.tableData[index].processQty){
          this.$refs[`num${index}`][0].className = 'font-skyblue';
        }else {
          this.$refs[`num${index}`][0].className = 'font-red';
        }
        this.tableData[index].processQty = this.processQty;
        this.modifyFlag = false;
      }else {
        this.$hint.showFailed(this.$i18n.t('message.waveOrderNumCheckMin'), true);
      }

    },
    modifyCancel() {
      this.modifyFlag = false;
    },
    queryDataSet(params, data, lovParams){
      if(params === lovParams){
        if(data){
          const newData = [];
            this.copyTableData.forEach((v, index)=>{
            if(v[lovParams].indexOf(data)!==-1){
              newData.push(this.copyTableData[index])
            }
          });
          this.tableData = newData;
        }else {
          this.tableData = this.copyTableData;
        }
      }
    },
    // 查询
    async handleButtonSubmit() {
      const _this = this;
    if(this.billData.outboundOrder === this.$t('label.outboundOrderR')){
      this.selectValue = 'billCode'
    }
     if(this.selectValue){
      this.$hint.showLoading();
      this.handleSubmitStatus();
      if(this.selectValue === 'billCode'){
        this.queryDataSet(this.selectValue,this.billData.querySearch,'billCode');
      }else if(this.selectValue === 'sortingLocation'){
        this.queryDataSet(this.selectValue,this.billData.querySearch,'sortingLocation');
      }else if(this.selectValue === 'sku'){
        this.queryDataSet(this.selectValue,this.billData.querySearch,'sku');
      }
      setTimeout(function() {
        _this.$hint.hideLoading()
        this.handleSubmitStatus();
      },200);
    }else {
      this.tableData = this.copyTableData;
    }

    },
    async onConfirm(){
      try{
        // const urlSort = `${this.winvBaseUrl}app-outbound-sorting/sort`;
        if(this.serialNumber){
          if(this.sortDtoList.length ===1){
            const data = {
              waveBillTypeId : this.waveBillTypeId,
              sumProcessQty:this.sumProcessQty,
              orderSortingLocationsList:this.sortDtoList,
            };
            this.$hint.showLoading();
            // const resSort = await this.$http.post(urlSort, data);
            const resSort = await sortConfirm(data);
            if(this.$common.isEmpty(resSort)){
              this.$hint.hideLoading();
              await this.clearSourceFields(this, true);
              this.$hint.showSuccess(this.$i18n.t('message.unloadingSuccess'));
              this.goBack();
            }
          }else {
            this.$hint.showFailed(this.$i18n.t('message.sortOnlyOne'))
          }
        }else {
          const data = {
            waveBillTypeId : this.waveBillTypeId,
            sumProcessQty:this.sumProcessQty,
            orderSortingLocationsList:this.sortDtoList,
            serialId: this.serialId,
          };
          this.$hint.showLoading();
          // const resSort = await this.$http.post(urlSort, data);
          const resSort = await sortConfirm(data);
          if(this.$common.isEmpty(resSort)){
            this.$hint.hideLoading();
            this.$hint.showSuccess(this.$i18n.t('message.unloadingSuccess'));
            this.goBack();
          }
        }

      }catch(e){
       console.log(3)
      } finally{
        this.$hint.hideLoading();
      }
    },
    // 提交
    async handleSortSubmit() {
     const onCancel = () => {
       this.$nextTick(() => {
         this.skipNext(10);
       })
     };
     let sortDtoList = [];
     this.tableData.map((item) => {
       if (item.selectedFlag) {
         item.serialId = this.serialId;
         item.uomId = this.uomId;
         item.uomCode = this.uomCode;
         sortDtoList.push(item);
       }
     });
     // console.log(sortDtoList)
     this.sortDtoList = sortDtoList;
     const urlCherk = `${this.winvBaseUrl}app-outbound-sorting/check-process-qty`;
     const urlSort = `${this.winvBaseUrl}app-outbound-sorting/sort`;
     const data = {
       waveBillTypeId : this.waveBillTypeId,
       sumProcessQty:this.sumProcessQty,
       orderSortingLocationsList:sortDtoList,
     };
     if(sortDtoList && sortDtoList.length > 0){
       this.$hint.showLoading();
         const res = await this.$http.post(urlCherk, data);
         if(this.$common.isEmpty(res)){
           if(this.serialNumber){
             if(this.sortDtoList.length ===1){
               const resSort = await this.$http.post(urlSort, data);
               // this.$hint.hideLoading();
               if(this.$common.isEmpty(resSort)){
                 this.goBack()
               }
             }else {
               this.$hint.showFailed(this.$i18n.t('message.sortOnlyOne'))
             }
           }else {
             const resSort = await this.$http.post(urlSort, data);
             // this.$hint.hideLoading();
             if(this.$common.isEmpty(resSort)){
               this.goBack()
             }
           }
         }else {
           this.$hint.showAlert(res&&res.desc, this.onConfirm, onCancel)
         }
       this.$hint.hideLoading();
     }else {
       this.$hint.showFailed(this.$i18n.t('message.waveOrderMsgChoose'), true);
    }


    },
    //返回
    async goBack() {
      sessionStorage.setItem('formSortSerial', JSON.stringify('1'));
      this.$router.backPage();
    },
  },
  beforeRouteLeave(to, from, next) {
      if (to.name === 'Sort') {
        // const url = `${this.winvBaseUrl}app-outbound-sorting/clear-task`;
        const data ={};
        // this.$http.post(url, data);
        clearTask(data);
      }
      this.$nextTick(() => {
        next();
      });
    // this.goBack()
    },
  async mounted() {
    await this.getParamsData();
    this.inputFieldList= [
      { type: 'input', required: false, ref: 10, bind: 'billData.querySearch', field: 'querySearch' },
    ];
    this.skipNext(10);
    this.selectData = [
      {meaning: this.$t('label.sowLocation'),value: 'sowLocation'},
      {meaning: this.$t('label.itemCode'),value: 'itemCode'},
      {meaning: this.$t('label.outboundOrder'),value: 'outboundOrder'},
      {meaning: this.$t('label.pickNum'),value: 'pickNum'},
      {meaning: this.$t('label.waitSortNum'),value: 'waitSortNum'},
    ];
    this.tableTitle = [
      {
        name: this.$t('label.sowLocation'), // 播种位
      },
      {
        name: this.$t('label.itemCode'), // 物料编码
      },
      {
        name: this.$t('label.quantity'), // 出库单号
      },
      {
        name: this.$t('label.outboundOrder'), // 出库单号
      },
      // {
      //   name: this.$t('label.pickNum'), // 执行数量
      // },
      // {
      //   name: this.$t('label.waitSortNum'), // c待分拣数量
      // },
    ];
    this.getBillData();
  },
};
</script>

<style lang="stylus" scoped>
 @import '~@/style/var.styl'
  .acceptance-detail-item {
    border-top 2px solid #e8e8e8
  }
  .ips-outbound-style{
      margin-bottom 0.6rem
  }
  .ips-row-left{
      float right
  }
  .font-skyblue {
      color var(--primaryColor)
  }
  .ips-table {
      width 100%
      overflow-x auto
      margin 0.5rem 0.1rem
      table {
          width 100%
          th, td {
              padding 5px 2px
              border 1px solid #ccc
              text-align center
              white-space nowrap
          }

          .back-green {
              background-color #cbdeb8
          }
      }
  }
  .modifyModel {
      width 70vw
      padding 0 10px 10px 10px
      h1 {
          font-size 18px
          text-align center
      }
  }
  .font-red {
      color red
  }
</style>
