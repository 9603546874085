import BatchModify from '../views/batch-modify'
import BatchModifyConfirm from '../views/batch-modify-confirm'

let route = [
  {
    path: '/batch-modify',
    name: 'BatchModify',
    component: BatchModify,
  },
  {
    path: '/batch-modify-confirm',
    name: 'BatchModifyConfirm',
    component: BatchModifyConfirm,
  },
];

export default route