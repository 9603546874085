<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.subinventoryTransfer')" :is-index="false" />
    </div>
    <div class="header-row">
      <div class="header-title">
        {{ serialData.serialNumber }}
      </div>
      <div :class="{'header-0-status':serialData.status==='0-DEFINED', 'header-1-status':serialData.status==='1-INSTOCK', 'header-2-status':serialData.status==='2-SHIPPED'}">
        {{ serialData.statusName }}
      </div>
    </div>
    <div class="ips-container">
      <div class="menu-row">
        <div class="menu-title">
          {{ $t('label.sku') }}：
        </div>
        <div class="menu-value">
          {{ serialData.sku }}
        </div>
      </div>
      <div class="menu-row">
        <div class="menu-title">
          {{ $t('label.unit') }}：
        </div>
        <div class="menu-value">
          {{ serialData.uomName }}
        </div>
      </div>
      <div class="menu-row">
        <div class="menu-title">
          {{ $t('label.goodsName') }}：
        </div>
        <div class="menu-value">
          {{ serialData.goodsName }}
        </div>
      </div>
      <div :class="isbatch?'card_batch_subtrans':'card_nobatch_subtrans'">
        <div class="row">
          <div class="title">
            {{ $t('label.transferFrom') }}
          </div>
          <svg-icon slot="icon" icon-class="arrow" />
        </div>
        <!-- 起始托盘 -->
        <ips-lov
          :label="$t('label.startOfTray')"
          :title="$t('label.startOfTray')"
          v-show="customData['fromCidCodeBySerial'] ? hideControl['fromCidCodeBySerial']: true"
          v-model="fromCidCodeBySerial"
          :disabled="enableSerial"
          params="cidCode"
          :url="`${winvBaseUrl}new-stock-trans/query-container-serial-lov`"
          :url-params="cidParam"
          :pre-parse-url="urlData['fromCidCodeBySerial']"
          :custom="customData['fromCidCodeBySerial']"
          :source-parse-field="sourceParseField"
          ref="ref0"
          @click.stop="handleCommonClick(0)"
          @clickIcon="handleCommonClick(0, 'lov')"
          @enter="skipNext(10)"
          @down="skipNext(10)"
          @tab="skipNext(10)"
          @choose="handleChoose('fromCidCodeBySerial', $event)"
          @setSourceField="saveSourceField($event)"
          @valuedByFoucs="valuedByFoucs($event)"
        />
        <!-- 起始父托盘 -->
        <ips-field
          :label="$t('label.startOfParentTray')"
          :title="$t('label.startOfParentTray')"
          v-show="parentCidEnabled.paramValue==1? fromParentCidCodeBySerial:false"
          v-model="fromParentCidCodeBySerial"
          :params="fromParentCidCodeBySerial"
          disabled
          display="parentCidCode"
          ref="ref5"
          :custom="customData['fromParentCidCodeBySerial']"
          :source-parse-field="sourceParseField"
        />
        <!-- 起始库位 -->
        <ips-lov
          :label="$t('label.startOfLocation')"
          :title="$t('label.startOfLocation')"
          v-model="fromLocationCodeBySerial"
          v-show="customData['fromLocationCodeBySerial'] ? hideControl['fromLocationCodeBySerial']: true"
          :disabled="enableSerial"
          required
          params="locationCode"
          :url="`${winvBaseUrl}new-stock-trans/query-location-serial`"
          :url-params="locationParam"
          :pre-parse-url="urlData['fromLocationCodeBySerial']"
          :custom="customData['fromLocationCodeBySerial']"
          :source-parse-field="sourceParseField"
          ref="ref10"
          @click.stop="handleCommonClick(10)"
          @clickIcon="handleCommonClick(10, 'lov')"
          @enter="skipNext(30)"
          @down="skipNext(30)"
          @tab="skipNext(30)"
          @choose="handleChoose('fromLocationCodeBySerial', $event)"
          @setSourceField="saveSourceField($event)"
          @valuedByFoucs="valuedByFoucs($event)"
        />
        <!-- 起始批次 -->
        <ips-lov
          :label="$t('label.batch')"
          v-model="batchCodeBySerial"
          v-show="isbatch ? (customData['batchCodeBySerial'] ? hideControl['batchCodeBySerial'] : isbatch) : false"
          disabled
          required
          :title="$t('label.batch')"
          params="batchCode"
          :url="`${winvBaseUrl}new-stock-trans/query-batch-from`"
          :pre-parse-url="urlData['batchCodeBySerial']"
          :custom="customData['batchCodeBySerial']"
          :source-parse-field="sourceParseField"
          ref="ref20"
          @click.stop="handleCommonClick(20)"
          @clickIcon="handleCommonClick(20, 'lov')"
          @choose="handleChoose('batch', $event)"
          @setSourceField="saveSourceField($event)"
          @valuedByFoucs="valuedByFoucs($event)"
        />
      </div>
      <div class="card_countLocation">
        <div class="row">
          <svg-icon slot="icon" icon-class="arrow" />
          <div class="title">
            {{ $t('label.transferTo') }}
          </div>
        </div>
        <!-- 目标托盘 -->
        <ips-lov
          :label="$t('label.targetTray')"
          :title="$t('label.targetTray')"
          v-show="customData['toCidCodeBySerial'] ? hideControl['toCidCodeBySerial']: true"
          v-model="toCidCodeBySerial"
          params="cidCode"
          :url="`${winvBaseUrl}new-stock-trans/query-container-to`"
          :url-params="toCidParam"
          :pre-parse-url="urlData['toCidCodeBySerial']"
          :custom="customData['toCidCodeBySerial']"
          :source-parse-field="sourceParseField"
          ref="ref30"
          @click.stop="handleCommonClick(30)"
          @clickIcon="handleCommonClick(30, 'lov')"
          @enter="handleToCidEnter"
          @down="handleToCidEnter"
          @tab="handleToCidTab"
          @choose="handleChoose('toCidCodeBySerial', $event)"
          @setSourceField="saveSourceField($event)"
          @valuedByFoucs="valuedByFoucs($event)"
        />
        <!-- 目标父托盘 -->
        <ips-lov
          :label="$t('label.targetParentTray')"
          :title="$t('label.targetParentTray')"
          v-model="toParentCidCodeBySerial"
          v-show="parentCidEnabled.paramValue==1?true:false"
          :required="transferType === 'PACK'"
          :disabled="isHasParentCid"
          params="cidCode"
          :url="`${winvBaseUrl}new-stock-trans/query-trans-to-parent-container`"
          :url-params="toCidParam"
          :pre-parse-url="urlData['toParentCidCodeBySerial']"
          :custom="customData['batchCodeBySerial']"
          :source-parse-field="sourceParseField"
          ref="ref35"
          @click.stop="handleCommonClick(35)"
          @clickIcon="handleCommonClick(35, 'lov')"
          @enter="handleToParentCidEnter"
          @down="handleToParentCidEnter"
          @tab="handleToParentCidTab"
          @choose="handleChoose('toParentCidCodeBySerial', $event)"
          @setSourceField="saveSourceField($event)"
          @valuedByFoucs="valuedByFoucs($event)"
        />
        <!-- 目标库位 -->
        <ips-lov
          :label="$t('label.targetLocation')"
          :title="$t('label.targetLocation')"
          v-model="toLocationCodeBySerial"
          v-show="customData['toLocationCodeBySerial'] ? hideControl['toLocationCodeBySerial']: true"
          required
          params="locationCode"
          :disabled="isToCidCode"
          :url="`${winvBaseUrl}new-stock-trans/query-location-to`"
          :url-params="toLocationParam"
          :pre-parse-url="urlData['toLocationCodeBySerial']"
          :custom="customData['toLocationCodeBySerial']"
          :source-parse-field="sourceParseField"
          ref="ref40"
          @click.stop="handleCommonClick(40)"
          @clickIcon="handleCommonClick(40, 'lov')"
          @enter="skipNext('submitRef')"
          @down="skipNext('submitRef')"
          @tab="skipNext('submitRef')"
          @choose="handleChoose('toLocationCodeBySerial', $event)"
          @setSourceField="saveSourceField($event)"
          @valuedByFoucs="valuedByFoucs($event)"
        />
      </div>
      <div :class="enableSerial?'card_num_subtrans_serial':'card_num_subtrans'">
        <div class="row">
          <div class="title">
            {{ $t('label.transferNumber') }}
          </div>
        </div>
        <!-- 转移数量 -->
        <ips-input-number
          v-model="transferQtyBySerial"
          v-show="customData['transferQtyBySerial'] ? hideControl['transferQtyBySerial']: true"
          disabled
          required
          not-auto-fill
          :more-than-zero="true"
          :label="$t('label.transferNumber')"
          :custom="customData['transferQtyBySerial']"
          :source-parse-field="sourceParseField"
          ref="ref50"
          @enter="skipNext('submitRef')"
          @down="skipNext('submitRef')"
          @tab="skipNext('submitRef')"
          @click.stop="handleCommonClick(60)"
          @setSourceField="saveSourceField($event)"
          @valuedByFoucs="valuedByFoucs($event)"
        />
        <!-- 库位可用量 -->
        <div
          class="row-num"
          v-if="!enableSerial"
        >
          <div class="title">
            {{ $t('label.locationUseQty') }}:
          </div>
          <div class="value">
            {{ locationValidQty }}
          </div>
        </div>
        <!--现有量、可用量-->
        <div class="row-num">
          <div class="row-50">
            <div class="title">
              {{ $t('label.availableQty') }}:
            </div>
            <div class="value">
              {{ qty }}
            </div>
          </div>
          <div class="row-50">
            <div class="title">
              {{ $t('label.currentUseQty') }}:
            </div>
            <div class="value">
              {{ validQty }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ips-footer">
      <hips-button
        size="large"
        @click.stop="handleCommonSubmit"
        @enter="handleCommonSubmit"
        ref="submitRef"
        :class="['ips-btn-normal']"
      >
        <svg-icon slot="icon" icon-class="success" />
        {{ $t('button.confirm') }}
      </hips-button>
    </div>
  </hips-view>
</template>

<script>
  import { getSerialData, getLocNumber, serialStockTrans, toParentCidCheck, getparentCidEnabled } from '../api';
  import { Row, Col } from '@hips/vue-ui';
  import { storage } from '@/utils';
  export default {
    name: 'SerialTransfer',
    components:{
      [Row.name]: Row,
      [Col.name]: Col,
    },
    data() {
      return {
        serialData: '', // params参数
        isbatch: '',
        enableSerial: '',
        fromCidIdBySerial: '', // 起始托盘ID
        fromCidCodeBySerial: '', // 起始托盘
        fromParentCidIdBySerial: '', // 起始托盘ID
        fromParentCidCodeBySerial: '', // 起始托盘
        fromLocationIdBySerial: '', // 起始库位
        fromLocationCodeBySerial: '', // 起始库位
        batchCodeBySerial: '', // 批次
        batchIdBySerial: '', //批次id
        toCidIdBySerial: '', // 目标托盘ID
        toCidCodeBySerial: '', // 目标托盘
        toParentCidIdBySerial: '', // 目标托盘ID
        toParentCidCodeBySerial: '', // 目标托盘
        toLocationIdBySerial: '', // 目标库位
        toLocationCodeBySerial: '', // 目标库位
        locationValidQty: '', // 库位可用量
        warehouseId: '', // 仓库id
        transferQtyBySerial: '', // 转移数量
        qty: '',
        validQty: '',
        cidParam: {}, // 托盘lov参数
        locationParam: {}, // 库位lov参数
        toCidParam: {}, // 目标托盘参数
        toLocationParam: {}, // 目标库位参数
        urlData: {},
        customData: {}, // 个性化配置信息列表
        hideControl: {}, // 个性化配置显示隐藏控制
        sourceParseField: {}, // 界面中所有的来源解析字段
        isToCidCode: false, // 托盘带出来的库位不允许编辑
        isHasParentCid: true, //目标父托盘字段是否可以编辑
        parentCidEnabled:{}, //页面参数是否显示父托盘字段
        LocationIsParentLocation: false,//目标库位是父托盘带出数据

        crossWhareaId: undefined //允许跨库区库存转移来源库区id
      };
    },
    methods: {
      /**
       * 获取params传参
       */
      async getParamsData(){
        const { item, sourceParseField, customData, hideControl } = this.$route.params;
        this.serialData = item;
        this.sourceParseField = sourceParseField;
        this.customData = customData;
        this.hideControl = hideControl;
        this.isbatch = item.isbatch === 1 ? true : false,
        this.enableSerial = item.serialType === this.SERIAL_INV_CTRL;
        if(this.enableSerial){
          try {
            this.$hint.showLoading();
            const data = {
              params: {
                serialId: this.serialData.id,
                goodsId: this.serialData.goodsId,
              }
            };
            const res = await getSerialData(data);
            this.fromCidIdBySerial = res[0].cidId;
            this.fromCidCodeBySerial = res[0].cidCode;
            this.fromParentCidIdBySerial = res[0].parentCidId;
            this.fromParentCidCodeBySerial = res[0].parentCidCode;
            this.fromLocationIdBySerial = res[0].locationId;
            this.fromLocationCodeBySerial = res[0].locationCode;
            this.batchCodeBySerial = res[0].batchCode;
            this.batchIdBySerial = res[0].batchId;
            this.warehouseId = res[0].warehouseId;
            this.qty = res[0].qty;
            this.validQty = res[0].validQty;

            this.crossWhareaId = res[0]?.crossWhareaTransferFlag === 0 ? res[0]?.whareaId : undefined;
      
            this.toCidParam = {
              warehouseId: this.warehouseId,
              whareaId: this.crossWhareaId,
            };
            this.toLocationParam = {
              warehouseId: this.warehouseId,
              whareaId: this.crossWhareaId,
            };
            this.skipNext(30);
          }finally {
            this.$hint.hideLoading();
          }
        }else {
          try {
            this.$hint.showLoading();
            const data = {
              params: {
                serialId: this.serialData.id,
                goodsId: this.serialData.goodsId,
              }
            };
            const res = await getSerialData(data);
            this.batchCodeBySerial = res[0].batchCode;
            this.batchIdBySerial = res[0].batchId;
            this.warehouseId = res[0].warehouseId;
            this.qty = res[0].qty;
            this.validQty = res[0].validQty;
            this.toCidParam = {
              warehouseId: this.warehouseId,
              whareaId: this.crossWhareaId
            };
            this.toLocationParam = {
              warehouseId: this.warehouseId,
              whareaId: this.crossWhareaId
            };
            this.skipNext(0);
          }finally {
            this.$hint.hideLoading();
          }
        }
        this.transferQtyBySerial = this.validQty;
        this.cidParam = {
          serialId: this.serialData.id,
          goodsId: this.serialData.goodsId,
          batchId: this.batchIdBySerial,
          warehouseId: this.warehouseId,
        };
        this.locationParam = {
          cidId: '',
          serialId: this.serialData.id,
          goodsId: this.serialData.goodsId,
          batchId: this.batchIdBySerial,
          warehouseId: this.warehouseId,
        };
      },
      /**
       *@functionName: saveSourceField
       *@description: 保存来源解析字段并赋值Enter
       *@params1: fieldObj[Object] 来源解析字段对象,
       fieldCode[String] 来源解析字段
       parseValue[String] 二维码解析值
       subFields[Array]] 关联子字段
       */
      saveSourceField(fieldObj) {
        this.saveSourceParseField(this, fieldObj);
      },
      /**
       * 目标托盘enter事件
       */
      handleToCidEnter(){
        if(this.isHasParentCid){
          if(this.parentCidEnabled.paramValue!=='1'){
            this.skipNext(50);
          }else{
            this.skipNext(40);
          }
        }else{
          if(this.parentCidEnabled.paramValue!=='1'){
            this.skipNext(40);
          }else{
            this.skipNext(35);
          }
        }
      },
      handleToCidTab(){
        if(this.isHasParentCid){
          if(this.parentCidEnabled.paramValue!=='1'){
            this.skipNext(40);
          }else{
            this.skipNext(40);
          }
        }else{
          if(this.parentCidEnabled.paramValue!=='1'){
            this.skipNext(40);
          }else{
            this.skipNext(35);
          }
        }
      },
      /**
       * 目标父托盘enter事件
       */
      handleToParentCidEnter(){
        if (this.toLocationIdBySerial) {
          this.skipNext('submitRef');
        } else {
          this.skipNext(40);
        }
      },
      handleToParentCidTab(){
        if (this.toLocationIdBySerial) {
          this.skipNext('submitRef');
        } else {
          this.skipNext(40);
        }
      },
      monitorCid(){
        if(!this.fromCidCodeBySerial){
          this.fromParentCidCodeBySerial = '';
        }
        if(!this.toCidCodeBySerial){
          this.toCidIdBySerial = null;
          this.toParentCidCodeBySerial = '';
          this.toParentCidIdBySerial = null;
          this.toLocationCodeBySerial = '';
        }
        if(!this.toParentCidCodeBySerial && this.LocationIsParentLocation){
          this.toLocationCodeBySerial = '';
        }
      },
      /**
       * 字段聚焦时赋值操作
       */
      async valuedByFoucs(options={}) {
        const { custom ={} , currentValue } = options;
        await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
      },
      /**
       * lov选中事件
       * @param {String} type - 定位lov
       * @param {object} obj - 选中行的数据
       */
      handleChoose(type, obj) {
        switch (type) {
          case 'fromCidCodeBySerial':
            this.fromCidIdBySerial = obj.cidId;
            this.fromCidCodeBySerial = obj.cidCode;
            this.fromLocationIdBySerial = obj.locationId;
            this.fromLocationCodeBySerial = obj.locationCode;
            this.locationParam = {
              cidId: obj.cidId,
              serialId: this.serialData.id,
              goodsId: this.serialData.goodsId,
              batchId: this.batchIdBySerial,
              warehouseId: this.warehouseId,
            };
            if(obj.locationCode){
              this.getLocationUseQty();
            }
            break;
          case 'fromLocationCodeBySerial':
            this.fromLocationIdBySerial = obj.locationId;
            this.fromLocationCodeBySerial = obj.locationCode;
            this.warehouseId = obj.warehouseId;
            this.toCidParam = {
              warehouseId: this.warehouseId,
              whareaId: this.crossWhareaId
            };
            this.toLocationParam = {
              warehouseId: this.warehouseId,
              whareaId: this.crossWhareaId
            };
            this.getLocationUseQty();
            break;
          case 'toCidCodeBySerial':
            this.toCidIdBySerial = obj.cidId;
            this.toCidCodeBySerial = obj.cidCode;
            this.isToCidCode = false;
            if(obj.cidStatus === 'IN_STOCK' && this.fromCidCodeBySerial !== obj.cidCode){
              this.toLocationParam = {
                // cidId: obj.cidId,
                // cidStatus: obj.cidStatus,
                warehouseId: this.warehouseId,
                whareaId: this.crossWhareaId
              };
            }else {
              this.toLocationParam = {
                cidStatus: obj.cidStatus,
                warehouseId: this.warehouseId,
                whareaId: this.crossWhareaId
              };
            }
            if(obj.locationId && this.fromCidCodeBySerial !== obj.cidCode){
              this.toLocationIdBySerial = obj.locationId;
              this.toLocationCodeBySerial = obj.locationCode;
            }
            this.toParentCidIdBySerial = obj.parentCidId;
            this.toParentCidCodeBySerial = obj.parentCidCode;
            if (this.toCidIdBySerial !== undefined) {
              this.isHasParentCid = false;
            }
            break;
          case 'toParentCidCodeBySerial':
            this.toParentCidIdBySerial = obj.cidId;
            this.toParentCidCodeBySerial = obj.cidCode;
            if(!this.toLocationCodeBySerial&&obj.locationId && this.toCidCodeBySerial !== ''&& this.fromParentCidCodeBySerial !== obj.cidCode){
              this.toLocationIdBySerial = obj.locationId;
              this.toLocationCodeBySerial = obj.locationCode;
              this.LocationIsParentLocation = true;
            }
            this.chekckToParentCidCode();
            break;
          case 'toLocationCodeBySerial':
            this.toLocationIdBySerial = obj.locationId;
            this.toLocationCodeBySerial = obj.locationCode;
            break;
          default:
            return null;
        }
      },
       /**
       * 校验目标父托盘字段
       */
      async chekckToParentCidCode() {
        try {
          const data = {
            locationId: this.toLocationIdBySerial,
            locationCode: this.toLocationCodeBySerial,
            cidId: this.toCidIdBySerial,
            cidCode: this.toCidCodeBySerial,
            parentCidId: this.toParentCidIdBySerial,
            parentCidCode: this.toParentCidCodeBySerial,
          };
          const res = await toParentCidCheck(data);
          if (res && res.failed) {
            this.skipNext(35);
          } else {
            if (res.locationCode&& !(this.fromParentCidCodeBySerial === res.parentCidCode&&this.fromCidCodeBySerial === res.cidCode)) {
              this.toLocationIdBySerial = res.locationId;
              this.toLocationCodeBySerial = res.locationCode;
            }
          }
        } catch (e) {
          this.skipNext(35);
        }
      },
      async parentCidEnabledShow(){
        try {
          this.$hint.showLoading();
          const data = {
            params: {
              pageId: storage.get('menuId'),
              device: "PDA",
              functionCode:"PDA_INV_STOCK_TRANSFER",
              paramCode:"PARENT_CID_ENABLE",
            }
          };
          const res = await getparentCidEnabled(data);
          this.parentCidEnabled = res;
        }finally {
          this.$hint.hideLoading();
        }
      },

      async getLocationUseQty(){
        try {
          this.$hint.showLoading();
          const data = {
            params: {
              serialId: this.serialData.id,
              goodsId: this.serialData.goodsId,
              locationId: this.fromLocationIdBySerial,
              cidId: this.fromCidIdBySerial,
              batchId: this.batchIdBySerial,
            }
          };
          const res = await getLocNumber(data);
          this.locationValidQty = res[0].locationValidQty? res[0].locationValidQty : 0;
        }finally {
          this.$hint.hideLoading();
        }
      },
      /**
       * 子库存转移
       */
      async handleButtonSubmit() {
        try {
          this.$hint.showLoading();
          this.handleSubmitStatus();
          let param = {
            sku: this.serialData.sku,
            goodsId: this.serialData.goodsId,
            serialId: this.serialData.id,
            serialNumber: this.serialData.serialNumber,
            fromCidId: this.fromCidIdBySerial,
            fromCidCode: this.fromCidCodeBySerial,
            fromLocationId:  this.fromLocationIdBySerial,
            fromLocationCode: this.fromLocationCodeBySerial,
            batchId: this.batchIdBySerial,
            batchCode: this.batchCodeBySerial,
            toLocationId: this.toLocationIdBySerial,
            toLocationCode: this.toLocationCodeBySerial,
            toCidId: this.toCidIdBySerial,
            toCidCode: this.toCidCodeBySerial,
            toParentCidId: this.toParentCidIdBySerial,
            toParentCidCode: this.toParentCidCodeBySerial,
            transferQty: this.transferQtyBySerial,
            qty: this.transferQtyBySerial,
          };
          await serialStockTrans(param);
          this.$hint.showSuccess();
          this.dataInit();
          await this.clearSourceFields(this, true);
          this.goToSubinventoryquery();
        }finally {
          this.$hint.hideLoading();
          this.handleSubmitStatus();
        }
      },
      goToSubinventoryquery(){
        let param = {
          name: 'SubinventoryQuery',
        };
        this.$router.pushPage(param);
      },

      dataInit() {
        this.fromCidIdBySerial= ''; // 起始托盘ID
        this.fromCidCodeBySerial= ''; // 起始托盘
        this.fromLocationIdBySerial= ''; // 起始库位
        this.fromLocationCodeBySerial= ''; // 起始库位
        this.batchCodeBySerial= ''; // 批次
        this.batchIdBySerial= ''; //批次id
        this.toCidIdBySerial= ''; // 目标托盘ID
        this.toCidCodeBySerial= ''; // 目标托盘
        this.toLocationIdBySerial= ''; // 目标库位
        this.toLocationCodeBySerial= ''; // 目标库位
        this.transferQtyBySerial= ''; // 转移数量
        this.qty= '';
        this.validQty= '';
        this.locationValidQty = '';
        this.cidParam = {};
        this.locationParam = {};
        this.toLocationParam= {}; // 目标库位参数
        this.getParamsData();
      },

    },
    async mounted() {
      this.urlData = this.handleData('STOCK_SERIAL_CONFIRM');
      await this.getParamsData();
      this.inputFieldList = [
        { type: 'lov', required: false, ref: 0, bind: 'fromCidCodeBySerial', field: 'fromCidCodeBySerial' },
        { type: 'lov', required: true, ref: 10, bind: 'fromLocationCodeBySerial', field: 'fromLocationCodeBySerial' },
        { type: 'lov', required: true, ref: 20, bind: 'batchCodeBySerial', field: 'batchCodeBySerial' },
        { type: 'lov', required: false, ref: 30, bind: 'toCidCodeBySerial', field: 'toCidCodeBySerial' },
        { type: 'lov', required: false, ref: 35, bind: 'toParentCidCodeBySerial', field: 'toParentCidCodeBySerial' },
        { type: 'lov', required: true, ref: 40, bind: 'toLocationCodeBySerial', field: 'toLocationCodeBySerial' },
        { type: 'number', required: true, ref: 50, bind: 'transferQtyBySerial', field: 'transferQtyBySerial' },
      ];
      this.parentCidEnabledShow();
      if(this.enableSerial){
        this.skipNext(30);
      }else {
        this.skipNext(0);
      }
    },
    watch:{
      'fromCidCodeBySerial': function(val, oldval) {
        if(oldval !== '' && val === ''){
          this.fromCidIdBySerial = '';
          this.fromLocationIdBySerial = '';
          this.fromLocationCodeBySerial = '';
          this.locationParam = {
            cidId: '',
            serialId: this.serialData.id,
            goodsId: this.serialData.goodsId,
            batchId: this.batchIdBySerial,
            warehouseId: this.warehouseId,
          };
        }
        if(!this.fromCidCodeBySerial){
          this.fromParentCidCodeBySerial = '';
        }
      },
      'toCidCodeBySerial': function(val, oldval) {
        if(oldval !== '' && val === ''){
          this.toLocationParam = {
            warehouseId: this.warehouseId,
            whareaId: this.crossWhareaId
          };
          this.isHasParentCid = true;
        }
        if(!this.toCidCodeBySerial){
          this.toCidIdBySerial = null;
          this.toParentCidCodeBySerial = '';
          this.toParentCidIdBySerial = null;
          this.toLocationCodeBySerial = null;
          this.toLocationIdBySerial = null;
        }
      },
      'toParentCidCodeBySerial': function(val, oldval) {
        if(oldval !== '' && val === ''&& this.LocationIsParentLocation){
          this.toLocationCodeBySerial = null;
          this.toLocationIdBySerial = null;
        }
        if(oldval !== '' && val === ''){
          this.toParentCidIdBySerial = null;
        }
      },
    }
  };
</script>

<style lang="stylus" scoped>
 @import '~@/style/var.styl'
  .header-row{
    padding-top 5px
    display flex;
    margin-bottom 12px;
    .header-title{
      display flex;
      align-items: center;
      height:28px;
      background:#E6F7FF;
      color var(--primaryColor);
      border-radius:0px 32px 32px 0px;
      padding 0 12px 0 8px;
      border-left:4px solid var(--primaryColor);
    }
    .header-0-status{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left 12px;
      padding 0 12px;
      height:28px;
      font-size 14px;
      background:#E6F7FF;
      color var(--primaryColor);
      border-radius:12px;
    }
    .header-1-status{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left 12px;
      padding 0 12px;
      height:28px;
      font-size 14px;
      background:#FFE4AB;
      color #FF8F07;
      border-radius:12px;
    }
    .header-2-status{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left 12px;
      padding 0 12px;
      height:28px;
      font-size 14px;
      background:#D6FFD7;
      color #19A633;
      border-radius:12px;
    }
  }
    .ips-container{
        padding-top 0!important;
        .menu-row{
            display flex
            margin 12px 32px 12px 12px;
            .menu-title{
                width:40px;
                height:16px;
                font-size:12px;
                /*font-family:PingFangSC-Regular,PingFang SC;*/
                font-weight:200;
                color:#8C8C8C;
                line-height:16px;
            }
            .menu-value{
                width:261px;
                height:16px;
                font-size:12px;
                /*font-family:PingFangSC-Regular,PingFang SC;*/
                font-weight:initial;
                color:#000000;
                line-height:16px;
            }
        }
        .card_batch_subtrans{
            margin 10px 0;
            padding  12px 12px
            height:flex;
            border-radius: 1.6vw;
            color: #000;
            box-shadow: 0 0 2.133vw #d9d9d9;
            border: none;
            font-size: 14px;
            background-color #FFFFFF;
            .row{
                display flex
                padding  0 0 0 0
                .title{
                    height:16px;
                    font-size:13px;
                    font-family:PingFangSC-Regular,PingFang SC;
                    font-weight:initial;
                    color:var(--primaryColor);
                    line-height:18px;
                }
            }
        }
        .card_nobatch_subtrans{
            margin 10px 0;
            padding  12px 12px
            height:flex;
            border-radius: 1.6vw;
            color: #000;
            box-shadow: 0 0 2.133vw #d9d9d9;
            border: none;
            font-size: 14px;
            background-color #FFFFFF;
          .row{
            display flex
            padding  0 0 0 0
            .title{
              height:16px;
              font-size:13px;
              font-family:PingFangSC-Regular,PingFang SC;
              font-weight:initial;
              color:var(--primaryColor);
              line-height:18px;
            }
          }
        }
        .card_countLocation{
            margin 10px 0;
            padding  12px 12px
            height:flex;
            border-radius: 1.6vw;
            color: #000;
            box-shadow: 0 0 2.133vw #d9d9d9;
            border: none;
            font-size: 14px;
            background-color #FFFFFF;
            .row{
                display flex
                padding  0 0 0 0
                .title{
                    height:16px;
                    font-size:13px;
                    font-family:PingFangSC-Regular,PingFang SC;
                    font-weight:initial;
                    color:var(--primaryColor);
                    line-height:18px;
                }
            }
        }
        .card_num_subtrans_serial{
          margin 10px 0;
          padding  0 12px
          height:125px;
          border-radius: 1.6vw;
          color: #000;
          box-shadow: 0 0 2.133vw #d9d9d9;
          border: none;
          font-size: 14px;
          background-color #FFFFFF;
          .row{
            display flex
            padding  12px 0 0 0
            .title{
              height:16px;
              font-size:13px;
              font-family:PingFangSC-Regular,PingFang SC;
              font-weight:initial;
              color:var(--primaryColor);
              line-height:18px;
            }
          }
          
        }
        .card_num_subtrans{
          margin 10px 0;
          padding  0 12px
          height:155px;
          border-radius: 1.6vw;
          color: #000;
          box-shadow: 0 0 2.133vw #d9d9d9;
          border: none;
          font-size: 14px;
          background-color #FFFFFF;
          .row{
            display flex
            padding  12px 0 0 0
            .title{
              height:16px;
              font-size:13px;
              font-family:PingFangSC-Regular,PingFang SC;
              font-weight:initial;
              color:var(--primaryColor);
              line-height:18px;
            }
          }
        }
        .row-num{
            display flex
            align-items center
            margin-top: 5px;
            height:25px;
            background:#E6F7FF;
            border-radius:6px;
            border:2px solid #91D5FF;
            .row-50{
              width 50%
              display flex
              .title{
                width:100px;
                height:16px;
                padding-left: 5px;
                font-size:14px;
                font-family:PingFangSC-Regular,PingFang SC;
                font-weight:200;
                color:#595959;
                line-height:18px;
              }
              .value{
                width:60px;
                height:18px;
                font-size:14px;
                font-family:PingFangSC-Medium,PingFang SC;
                font-weight:initial;
                color:#000000;
                line-height:18px;
                overflow: hidden;      /*溢出隐藏*/
                white-space: nowrap;	/*规定文本不进行换行*/
                text-overflow: ellipsis;	/*当对象内文本溢出时显示省略标记（...）*/
              }
            }
            .title{
              width:100px;
              height:16px;
              padding-left: 5px;
              font-size:14px;
              font-family:PingFangSC-Regular,PingFang SC;
              font-weight:200;
              color:#595959;
              line-height:18px;
            }
            .value{
              height:18px;
              font-size:14px;
              font-family:PingFangSC-Medium,PingFang SC;
              font-weight:initial;
              color:#000000;
              line-height:18px;
              overflow: hidden;      /*溢出隐藏*/
              white-space: nowrap;	/*规定文本不进行换行*/
              text-overflow: ellipsis;	/*当对象内文本溢出时显示省略标记（...）*/
            }
          }
    }
  .ips-btn-normal {
    border-radius: 8px !important;
    margin: 12px 10px 12px 10px;
    background-color var(--primaryColor) !important;
    color #ffffff !important
    font-size 17px !important
  }
</style>
