var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: { title: _vm.$t("title.bulkStockTransfer") },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["fromCidCode"]
                ? _vm.hideControl["fromCidCode"]
                : true,
              expression:
                "customData['fromCidCode'] ? hideControl['fromCidCode'] : true",
            },
          ],
          ref: "ref0",
          attrs: {
            label: _vm.$t("label.startOfTray"),
            title: [
              _vm.$t("label.startOfTray"),
              _vm.$t("label.sku"),
              _vm.$t("label.batch"),
            ],
            params: ["cidCode", "sku", "batchCode"],
            url: _vm.winvBaseUrl + "app-stock-trans/query-trans-container",
            "pre-parse-url": _vm.urlData["fromCidCode"],
            display: "cidCode",
            custom: _vm.customData["fromCidCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(0)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(0, "lov")
            },
            enter: _vm.handleCidEnter,
            down: _vm.handleCidEnter,
            tab: _vm.handleCidTab,
            choose: function ($event) {
              return _vm.handleChoose("fromCidCode", $event)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.fromCidCode,
            callback: function ($$v) {
              _vm.fromCidCode = $$v
            },
            expression: "fromCidCode",
          },
        }),
        _c("ips-field", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.parentCidEnabled.paramValue == 1 ? true : false,
              expression: "parentCidEnabled.paramValue==1? true:false",
            },
          ],
          ref: "ref5",
          attrs: {
            label: _vm.$t("label.startOfParentTray"),
            title: _vm.$t("label.startOfParentTray"),
            params: _vm.fromParentCidCode,
            disabled: "",
            display: "parentCidCode",
            custom: _vm.customData["fromParentCidCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          model: {
            value: _vm.fromParentCidCode,
            callback: function ($$v) {
              _vm.fromParentCidCode = $$v
            },
            expression: "fromParentCidCode",
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["fromLocationCode"]
                ? _vm.hideControl["fromLocationCode"]
                : true,
              expression:
                "customData['fromLocationCode'] ? hideControl['fromLocationCode'] : true",
            },
          ],
          ref: "ref10",
          attrs: {
            label: _vm.$t("label.startOfLocation"),
            title: _vm.$t("label.startOfLocation"),
            required: "",
            params: "locationCode",
            url: _vm.winvBaseUrl + "app-stock-trans/query-trans-location",
            "url-params": _vm.locationParam,
            "pre-parse-url": _vm.urlData["fromLocationCode"],
            custom: _vm.customData["fromLocationCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(10)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(10, "lov")
            },
            enter: function ($event) {
              return _vm.skipNext(20)
            },
            down: function ($event) {
              return _vm.skipNext(20)
            },
            tab: function ($event) {
              return _vm.skipNext(20)
            },
            choose: function ($event) {
              return _vm.handleChoose("fromLocationCode", $event)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.fromLocationCode,
            callback: function ($$v) {
              _vm.fromLocationCode = $$v
            },
            expression: "fromLocationCode",
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["sku"] ? _vm.hideControl["sku"] : true,
              expression: "customData['sku'] ? hideControl['sku'] : true",
            },
          ],
          ref: "ref20",
          attrs: {
            label: _vm.$t("label.sku"),
            title: [_vm.$t("label.sku"), _vm.$t("label.goodsName")],
            required: "",
            display: "sku",
            params: ["sku", "goodsName"],
            url: _vm.winvBaseUrl + "app-stock-trans/query-trans-sku",
            "url-params": _vm.skuParam,
            "pre-parse-url": _vm.urlData["sku"],
            "pre-parse-title": [
              _vm.$t("label.sku"),
              _vm.$t("title.name"),
              _vm.$t("label.ownerCode"),
            ],
            "pre-params": ["mainValue", "mainName", "ownerCode"],
            custom: _vm.customData["sku"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(20)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(20, "lov")
            },
            enter: _vm.handleSkuEnter,
            down: _vm.handleSkuEnter,
            tab: _vm.handleSkuTab,
            choose: function ($event) {
              return _vm.handleChoose("sku", $event)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.sku,
            callback: function ($$v) {
              _vm.sku = $$v
            },
            expression: "sku",
          },
        }),
        _c("ips-value-cell", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["goodsName"]
                ? _vm.hideControl["goodsName"]
                : true,
              expression:
                "customData['goodsName'] ? hideControl['goodsName'] : true",
            },
          ],
          attrs: {
            title: _vm.$t("label.goodsName"),
            value: _vm.skuInfo.goodsName,
          },
        }),
        _c("ips-value-cell", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["uomCode"]
                ? _vm.hideControl["uomCode"]
                : true,
              expression:
                "customData['uomCode'] ? hideControl['uomCode'] : true",
            },
          ],
          attrs: { title: _vm.$t("label.unit"), value: _vm.skuInfo.uomCode },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isbatch
                ? _vm.customData["batchCode"]
                  ? _vm.hideControl["batchCode"]
                  : _vm.isbatch
                : false,
              expression:
                "isbatch ? (customData['batchCode'] ? hideControl['batchCode'] : isbatch) : false",
            },
          ],
          ref: "ref30",
          attrs: {
            label: _vm.$t("label.batch"),
            required: _vm.isbatch,
            title: _vm.$t("label.batch"),
            params: "batchCode",
            url: _vm.winvBaseUrl + "app-stock-trans/query-trans-batch",
            "url-params": _vm.batchParam,
            "pre-parse-url": _vm.urlData["batchCode"],
            custom: _vm.customData["batchCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(30)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(30, "lov")
            },
            enter: function ($event) {
              return _vm.skipNext(40)
            },
            down: function ($event) {
              return _vm.skipNext(40)
            },
            tab: function ($event) {
              return _vm.skipNext(40)
            },
            choose: function ($event) {
              return _vm.handleChoose("batch", $event)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.batchCode,
            callback: function ($$v) {
              _vm.batchCode = $$v
            },
            expression: "batchCode",
          },
        }),
        _c("ips-value-cell", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["qty"] ? _vm.hideControl["qty"] : true,
              expression: "customData['qty'] ? hideControl['qty'] : true",
            },
          ],
          attrs: {
            title: _vm.$t("label.availableQty"),
            value: _vm.qtyInfo.qty,
          },
        }),
        _c("ips-value-cell", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["validQty"]
                ? _vm.hideControl["validQty"]
                : true,
              expression:
                "customData['validQty'] ? hideControl['validQty'] : true",
            },
          ],
          attrs: { title: _vm.$t("label.useQty"), value: _vm.qtyInfo.validQty },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["toCidCode"]
                ? _vm.hideControl["toCidCode"]
                : true,
              expression:
                "customData['toCidCode'] ? hideControl['toCidCode'] : true",
            },
          ],
          ref: "ref40",
          attrs: {
            label: _vm.$t("label.targetTray"),
            title: _vm.$t("label.targetTray"),
            params: "cidCode",
            url: _vm.winvBaseUrl + "app-stock-trans/query-trans-to-container",
            "url-params": _vm.toCidCodeParam,
            "pre-parse-url": _vm.urlData["toCidCode"],
            custom: _vm.customData["toCidCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(40)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(40, "lov")
            },
            enter: _vm.handleToCidEnter,
            down: _vm.handleToCidEnter,
            tab: _vm.handleToCidTab,
            choose: function ($event) {
              return _vm.handleChoose("toCidCode", $event)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.toCidCode,
            callback: function ($$v) {
              _vm.toCidCode = $$v
            },
            expression: "toCidCode",
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.parentCidEnabled.paramValue == 1 ? true : false,
              expression: "parentCidEnabled.paramValue==1? true:false",
            },
          ],
          ref: "ref45",
          attrs: {
            label: _vm.$t("label.targetParentTray"),
            title: _vm.$t("label.targetParentTray"),
            params: "cidCode",
            disabled: _vm.isHasParentCid,
            url:
              _vm.winvBaseUrl +
              "app-stock-trans/query-trans-to-parent-container",
            "url-params": _vm.toCidCodeParam,
            "pre-parse-url": _vm.urlData["toParentCidCode"],
            custom: _vm.customData["toParentCidCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(45)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(45, "lov")
            },
            enter: _vm.handleToParentCidEnter,
            down: _vm.handleToParentCidEnter,
            tab: _vm.handleToParentCidTab,
            choose: function ($event) {
              return _vm.handleChoose("toParentCidCode", $event)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.toParentCidCode,
            callback: function ($$v) {
              _vm.toParentCidCode = $$v
            },
            expression: "toParentCidCode",
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["toLocationCode"]
                ? _vm.hideControl["toLocationCode"]
                : true,
              expression:
                "customData['toLocationCode'] ? hideControl['toLocationCode'] : true",
            },
          ],
          ref: "ref50",
          attrs: {
            title: _vm.$t("label.targetLocation"),
            label: _vm.$t("label.targetLocation"),
            required: "",
            params: "locationCode",
            url: _vm.wmdmBaseUrl + "wms-locations/lov",
            "url-params": _vm.toLocationParam,
            "pre-parse-url": _vm.urlData["toLocationCode"],
            disabled: _vm.isToCidCode,
            custom: _vm.customData["toLocationCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(50)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(50, "lov")
            },
            enter: _vm.handleLocationEnter,
            down: _vm.handleLocationEnter,
            tab: _vm.handleLocationTab,
            choose: function ($event) {
              return _vm.handleChoose("toLocationCode", $event)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.toLocationCode,
            callback: function ($$v) {
              _vm.toLocationCode = $$v
            },
            expression: "toLocationCode",
          },
        }),
        _vm.enableSerial
          ? _c(
              "div",
              { staticClass: "input-button" },
              [
                _c(
                  "hips-button",
                  {
                    ref: "inputRef",
                    style: Object.assign({}, _vm.inputBtnStyle),
                    on: { click: _vm.goToSerial },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("button.serialNumEntry")) +
                        "\n      "
                    ),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
        _c("ips-input-number", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["transferQty"]
                ? _vm.hideControl["transferQty"]
                : true,
              expression:
                "customData['transferQty'] ? hideControl['transferQty'] : true",
            },
          ],
          ref: "ref60",
          attrs: {
            required: "",
            disabled: _vm.qtyScanDisabled,
            "enable-serial": _vm.skuInfo.serialType,
            label: _vm.$t("label.transferNumber"),
            custom: _vm.customData["transferQty"],
            "source-parse-field": _vm.sourceParseField,
            min: null,
            "more-than-zero": true,
            "not-auto-fill": "",
          },
          on: {
            enter: function ($event) {
              return _vm.skipNext("submitRef")
            },
            down: function ($event) {
              return _vm.skipNext("submitRef")
            },
            tab: function ($event) {
              return _vm.skipNext("submitRef")
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(60)
            },
            blur: _vm.checkQtyBlur,
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.transferQty,
            callback: function ($$v) {
              _vm.transferQty = $$v
            },
            expression: "transferQty",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-footer" },
      [
        _c(
          "hips-button",
          {
            ref: "submitRef",
            attrs: { size: "large" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonSubmit.apply(null, arguments)
              },
            },
          },
          [
            _c("svg-icon", {
              attrs: { slot: "icon", "icon-class": "success" },
              slot: "icon",
            }),
            _vm._v("\n      " + _vm._s(_vm.$t("button.confirm")) + "\n    "),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }