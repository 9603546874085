<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.cancelPick')" />
    </div>
    <div class="ips-container">
      <!--页面只有一个lov 所以绑定isOnly-->
      <!-- 转运托盘 -->
      <ips-lov
        :label="$t('label.toCidCode')"
        :title="$t('label.toCidCode')"
        v-model="loadCidCode"
        v-show="customData['loadCidCode'] ? hideControl['loadCidCode']: true"
        required
        params="loadCidCode"
        :url="`${winvBaseUrl}app-outbound-load-cancel/query-load-cancel-containers`"
        :pre-parse-url="urlData['loadCidCode']"
        :custom="customData['loadCidCode']"
        :source-parse-field="sourceParseField"
        ref="ref0"
        @click.stop="handleCommonClick(0)"
        @enter="skipNext('submitRef')"
        @down="skipNext('submitRef')"
        @clickIcon="handleCommonClick(0, 'lov')"
        @choose="handleChoose('cid', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
    </div>
    <div class="ips-footer">
      <!--撤销挑库-->
      <hips-button
        size="large"
        ref="submitRef"
        @click="handleCommonSubmit"
      >
        <svg-icon slot="icon" icon-class="success" />
        {{ $t('button.confirmRevoke') }}
      </hips-button>
      <!--托盘详情-->
      <hips-button
        size="large"
        ref="cidDetail"
        v-show="customData['cidDetail'] ? hideControl['cidDetail']: true"
        @click="goDetail"
      >
        <svg-icon slot="icon" icon-class="detail" />
        {{ $t('button.cidDetail') }}
      </hips-button>
    </div>
  </hips-view> 
</template>

<script>
import { loadCancel } from '../api'
import { storage } from '@/utils';
export default {
  name: 'Unpicking',
  data () {
    return {
      loadCidId: '',
      loadCidCode: '',
      sourceType: '',
      urlData:{},
      customData: {}, // 个性化配置信息列表
      hideControl: {}, // 个性化配置显示隐藏控制
      sourceParseField: {}, // 界面中所有的来源解析字段
    }
  },
  methods: {
    /**
     *@functionName: saveSourceField
     *@description: 保存来源解析字段并赋值Enter
     *@params1: fieldObj[Object] 来源解析字段对象,
     fieldCode[String] 来源解析字段
     parseValue[String] 二维码解析值
     subFields[Array]] 关联子字段
     */
    saveSourceField(fieldObj) {
      this.saveSourceParseField(this, fieldObj);
    },
    // 字段聚焦时赋值操作
    async valuedByFoucs(options={}) {
      const { custom ={} , currentValue } = options;
      await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
    },
    //前往托盘明细
    goDetail() {
      let param = {
        name: 'CidDetail',
        params: {
          loadCidId: this.loadCidId,
          loadCidCode: this.loadCidCode,
          sourceType: this.sourceType,
        },
      }; 
      this.$router.pushPage(param);
    },
    // 转运托盘lov选择事件
    handleChoose(type, obj) {
      switch (type) {
        case 'cid':
          this.loadCidId = obj.loadCidId;
          this.sourceType = obj.sourceType;
          break;
        default:
          return null;
      }
    },
    // 撤销挑库
    async handleButtonSubmit() {
      try {
        this.$hint.showLoading();
        this.handleSubmitStatus();
        const { loadCidCode, loadCidId } = this;
        let data = {
          loadCidCode,
          loadCidId,
        };
        await loadCancel(data);
        await this.clearSourceFields(this, true);
        this.$hint.showSuccess(this.$i18n.t('message.operationSuccess'));
        this.loadCidId = '';
        this.loadCidCode = '';
        this.$refs['ref0'].focus();
      } catch (e) {
          console.log('错误: ' + e);
      } finally {
        this.$hint.hideLoading();
        this.handleSubmitStatus();
      }
    }
  },
  created() {
    const { customObj, hideControl } = this.handleCustomData(storage.get('customData'));
    this.customData = customObj;
    this.hideControl = hideControl;
    // console.log('卸货customData', Object.assign({}, this.customData));
  },
  mounted() {
    this.inputFieldList = [
      { type: 'lov', required: true, ref: 0, bind: 'loadCidCode', field: 'loadCidCode' },
    ];
    this.urlData = this.handleData('UNLOAD_TASK_QUERY');
    this.$refs['ref0'].focus();
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'CidDetail') {
      this.$store.commit('pushKeepAlives', 'Unpicking');
    } else {
      this.$store.commit('deleteKeepAlives', 'Unpicking');
    }
    this.$nextTick(() => {
      next();
    });
  },
}
</script>

<style lang="stylus" scoped>
 @import '~@/style/var.styl'
.ips-table {
  width 100%
  overflow-x auto
  table {
    width 100%
  }
  th, td {
    padding:5px 2px
    border 1px solid #ccc
    text-align center
    white-space nowrap
  }
}

</style>





