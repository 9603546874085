import receivingAdjustment from '../views/receivingAdjustment'


let route = [{
    path: '/receivingAdjustment',
    name: 'receivingAdjustment',
    component: receivingAdjustment,
  },
]

export default route