<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.taskQuery')" />
    </div>
    <div class="ips-container">
      <!-- 单据编号 -->
      <ips-lov
        v-model="billCode"
        :title="$t('label.deliveryCode')"
        :label="$t('label.deliveryCode')"
        required
        params="billCode"
        :url="`${winvBaseUrl}app-outbound-load/query-load-orders`"
        :url-params="billParam"
        :pre-parse-url="urlData['billCode']"
        ref="ref0"
        :custom="customData['billCode']"
        :source-parse-field="sourceParseField"
        v-show="customData['billCode'] ? hideControl['billCode'] : true"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
        @click.stop="handleCommonClick(0)"
        @clickIcon="handleCommonClick(0, 'lov')"
        @enter="skipNext(10)"
        @down="skipNext(10)"
        @choose="handleChoose('billCode', $event)"
      />
      <!-- 来源库区 -->
      <ips-lov
        v-model="whareaCode"
        :title="$t('label.sourceWharea')"
        :label="$t('label.sourceWharea')"
        params="whareaCode"
        :url="`${winvBaseUrl}app-outbound-load/query-task-whareas`"
        :url-params="whareaParam"
        :pre-parse-url="urlData['whareaCode']"
        ref="ref10"
        :custom="customData['whareaCode']"
        :source-parse-field="sourceParseField"
        v-show="customData['whareaCode'] ? hideControl['whareaCode'] : true"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
        @click.stop="handleCommonClick(10)"
        @clickIcon="handleCommonClick(10, 'lov')"
        @enter="skipNext(20)"
        @down="skipNext(20)"
        @choose="handleChoose('whareaCode', $event)"
      />
      <!--物料-->
      <ips-lov
        v-model="sku"
        :title="$t('label.sku')"
        :label="$t('label.sku')"
        params="sku"
        :url="`${winvBaseUrl}app-outbound-load/query-goods`"
        :url-params="skuParam"
        :pre-parse-url="urlData['sku']"
        ref="ref20"
        :custom="customData['sku']"
        :source-parse-field="sourceParseField"
        v-show="customData['sku'] ? hideControl['sku'] : true"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
        @click.stop="handleCommonClick(20)"
        @clickIcon="handleCommonClick(20, 'lov')"
        @enter="skipNext(30)"
        @down="skipNext(30)"
        @choose="handleChoose('sku', $event)"
      />
      <!-- 任务编号 -->
      <ips-lov
        v-model="taskCode"
        :title="$t('label.taskNumber')"
        :label="$t('label.taskNumber')"
        params="taskCode"
        :url="`${winvBaseUrl}app-outbound-load/query-tasks`"
        :url-params="taskParam"
        :pre-parse-url="urlData['taskCode']"
        ref="ref30"
        :custom="customData['taskCode']"
        :source-parse-field="sourceParseField"
        v-show="customData['taskCode'] ? hideControl['taskCode'] : true"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
        @click.stop="handleCommonClick(30)"
        @clickIcon="handleCommonClick(30, 'lov')"
        @enter="skipNext('submitRef')"
        @down="skipNext('submitRef')"
        @choose="handleChoose('taskCode', $event)"
      />
    </div>
    <div class="ips-footer">
      <!--确认按钮-->
      <hips-button size="large" @click.stop="handleCommonSubmit" ref="submitRef">
        <svg-icon slot="icon" icon-class="success" />
        {{ $t('button.confirm') }}
      </hips-button>
    </div>
  </hips-view>
</template>

<script>
import { queryProcessTask, clearTask } from '../api';
import { storage } from '@/utils';

export default {
  name: 'TaskExecution',
  data() {
    return {
      billId: '',
      billCode: '', // 入库单号
      billTypeId: '',
      billType: '',
      whareaCode: '', // 来源库区
      sku: '', // 物料
      taskCode: '', // 任务编号
      billParam: {},
      whareaParam: {},
      skuParam: {},
      taskParam: {},
      urlData: {},
      customData: {}, // 个性化配置信息列表
      hideControl: {}, // 非必输字段的显隐控制
      sourceParseField: {}, // 界面中所有的来源解析字段
    };
  },
  methods: {
    // 保存来源解析字段
    async saveSourceField(fieldObj) {
      this.saveSourceParseField(this, fieldObj);
    },
    // 字段聚焦时赋值操作
    async valuedByFoucs(options = {}) {
      const { custom = {}, currentValue } = options;
      await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
    },
    // lov选择方法
    handleChoose(type, obj) {
      switch (type) {
        case 'billCode':
          this.billId = obj.billId;
          this.billTypeId = obj.billTypeId;
          this.billType = obj.billType;
          break;
        case 'whareaCode':
          this.whareaId = obj.whareaId;
          break;
        case 'sku':
          this.goodsId = obj.goodsId;
          break;
        case 'taskCode':
          this.id = obj.id;
          break;
        default:
          return null;
      }
      this.getLovParam();
    },

    //确认
    async handleButtonSubmit() {
      try {
        const { whareaCode, whareaId, sku, goodsId, taskCode, id, billCode, billId, billTypeId, billType } = this;
        this.$hint.showLoading();
        this.handleSubmitStatus();
        let param = {
          params: {
            billCode,
            billId,
            billTypeId,
            billType,
            whareaCode,
            whareaId,
            sku,
            goodsId,
            taskCode,
            id,
          },
        };
        const billDetail = await queryProcessTask(param);
        const routerParam = {
          name: 'ExecutionConfirm',
          params: {
            billDetail,
            sourceParseField: { ...this.sourceParseField },
            customData: this.customData,
            hideControl: this.hideControl,
          },
        };
        this.$router.pushPage(routerParam);
      } finally {
        this.$hint.hideLoading();
        this.handleSubmitStatus();
      }
    },
    // lov请求参数
    getLovParam() {
      const { whareaCode, whareaId, sku, goodsId, taskCode, id, billCode, billId } = this;
      this.billParam = {
        whareaCode,
        whareaId,
        sku,
        goodsId,
        taskCode,
        id,
      };
      this.whareaParam = {
        billCode,
        billId,
        sku,
        goodsId,
        taskCode,
        id,
      };
      this.skuParam = {
        billCode,
        billId,
        whareaCode,
        whareaId,
        taskCode,
        id,
      };
      this.taskParam = {
        billCode,
        billId,
        whareaCode,
        whareaId,
        sku,
        goodsId,
      };
    },
  },
  mounted() {
    if (this.$route.params.billCode) {
      this.billCode = this.$route.params.billCode;
    }
    this.inputFieldList = [
      { type: 'lov', required: true, ref: 0, bind: 'billCode', field: 'billCode' },
      { type: 'lov', required: false, ref: 10, bind: 'whareaCode', field: 'whareaCode' },
      { type: 'lov', required: false, ref: 20, bind: 'sku', field: 'sku' },
      { type: 'lov', required: false, ref: 30, bind: 'taskCode', field: 'taskCode' },
    ];
    this.urlData = this.handleData('TASK_EXECUTION_QUERY');
    this.skipNext(0);
  },
  async created() {
    const { hideControl, customObj } = this.handleCustomData(storage.get('customData'));
    this.customData = customObj;
    this.hideControl = hideControl;
    // console.log('任务执行customData', Object.assign({}, this.customData));
    // 默认清除当前操作人任务
    await clearTask({});
  },
  async createdactivated() {
    this.skipNext(0);
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'ExecutionConfirm') {
      this.$store.commit('pushKeepAlives', 'ExecutionConfirm');
    } else {
      this.$store.commit('deleteKeepAlives', 'ExecutionConfirm');
    }
    this.$nextTick(() => {
      next();
    });
  },
};
</script>

<style lang="stylus" scoped></style>
