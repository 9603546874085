<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :is-index="false" :render-data="{title: $t('title.associatedSite'), billCode }" />
    </div>
    <div class="ips-container">
      <!-- 查询类型 -->
      <div class="ips-outbound-style">
        <ips-lov
          :label="$t('label.queryType')"
          :title="$t('label.queryType')"
          v-model="billData.outboundOrder"
          v-show="customData['outboundOrder'] ? hideControl['outboundOrder']: true"
          params="meaning"
          :url="`${wmdmBaseUrl}wms-common/lov-data-query`"
          :url-params="{lovCode:'WINV.SORTING_LOCATION_MAPPING_QUERY'}"
          :pre-parse-url="urlData['outboundOrder']"
          :is-page-content="false"
          :custom="customData['outboundOrder']"
          :source-parse-field="sourceParseField"
          ref="ref0"
          is-control
          :readonly="true"
          @click.stop="handleCommonClick(0)"
          @clickIcon="handleCommonClick(0, 'lov')"
          @enter="skipNext(10)"
          @down="skipNext(10)"
          @choose="handleChoose('outboundOrder', $event)"
          @setSourceField="saveSourceField($event)"
          @valuedByFoucs="valuedByFoucs($event)"
        />
      </div>
      <hips-row>
        <hips-col span="19">
          <ips-field
            :label="$t('label.queryConditions')"
            v-model="billData.querySearch"
            v-show="customData['querySearch'] ? hideControl['querySearch']: true"
            params="querySearch"
            display="querySearch"
            :pre-parse-url="urlData['querySearch']"
            :custom="customData['querySearch']"
            :source-parse-field="sourceParseField"
            ref="ref10"
            @enter="skipNext('selectRef')"
            @down="skipNext('selectRef')"
            @setSourceField="saveSourceField($event)"
            @valuedByFoucs="valuedByFoucs($event)"
          />
        </hips-col>
        <hips-col span="4" class="ips-row-left">
          <hips-button
            type="warning"
            @click.stop="handleButtonSubmit"
            @keyup.enter.native="handleButtonSubmit"
            @keyup.down.native="handleButtonSubmit"
            @keypress.native.prevent
            v-show="customData['search'] ? hideControl['search']: true"
            ref="selectRef"
          >
            {{ $t('button.search') }}
          </hips-button>
        </hips-col>
      </hips-row>
      <div class="ips-table">
        <table>
          <tr style="background-color: #eee">
            <th v-for="(item, index) in tableTitle" :key="index" scope="col">
              {{ item.name }}
            </th>
          </tr>
          <tr
            v-for="(item, index) in tableData"
            :key="index"
            :ref="`tr${index}`"
          >
            <td>
              <ips-field
                required
                back-required
                v-model="item.sortingLocation"
                :ref="`ref${20*index+20}`"
                :disabled="!item.isEdit"
                :dis-background="!item.isEdit"
                @enter="handleTableEnter(index)"
                @down="handleTableEnter(index)"
              />
            </td>
            <td>
              <ips-lov
                required
                back-required
                v-model="item.billCode"
                :ref="`ref${20*index+30}`"
                :url="`${winvBaseUrl}app-sorting-locations/query-outbound-order`"
                :url-params="{billId: billId}"
                :pre-parse-url="urlData['billCode']"
                params="billCode"
                :disabled="!item.isEdit"
                :dis-background="!item.isEdit"
                :readonly="!item.isEdit"
                @enter="handleTableLovEnter(index)"
                @down="handleTableLovEnter(index)"
                @choose="chooseChildBill($event, item, index)"
                @blur="handleCommonClick(20*index+30, 'lov')"
                @click.stop="handleCommonClick(20*index+30)"
                @clickIcon="handleCommonClick(20*index+30, 'lov')"
              />
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="ips-footer">
      <hips-button
        size="large"
        ref="submitRef"
        @click.stop="handleAssociatedSubmit"
      >
        <svg-icon slot="icon" icon-class="success" />
        {{ $t('button.associatedConfirm') }}
      </hips-button>
    </div>
  </hips-view>
</template>
<script>
  import {submitConfirm} from "../api";
  import { Row, Col, Search  } from '@hips/vue-ui';
  export default {
    name: "AssociatedSiteDetail",
    components:{
      [Row.name]: Row,
      [Col.name]: Col,
      [Search.name]: Search,
    },
    data() {
      return {
        billId: '', //入库单id
        billCode: '', //入库单号',
        billData: {
          outboundOrder: this.$t('label.sowLocation'),
          querySearch: '',
        },
        selectValue: '', //筛选前选择的数值
        tableTitle: [],
        tableData: [],
        copyTableData: [],
        urlData:{},
        customData: {}, // 个性化配置信息列表
        hideControl: {}, // 个性化配置显示隐藏控制
        sourceParseField: {}, // 界面中所有的来源解析字段
      };
    },
    methods: {
      /**
       *@functionName: saveSourceField
       *@description: 保存来源解析字段并赋值Enter
       *@params1: fieldObj[Object] 来源解析字段对象,
       fieldCode[String] 来源解析字段
       parseValue[String] 二维码解析值
       subFields[Array]] 关联子字段
       */
      saveSourceField(fieldObj) {
        this.saveSourceParseField(this, fieldObj);
      },
      // 字段聚焦时赋值操作
      async valuedByFoucs(options={}) {
        const { custom ={} , currentValue } = options;
        await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
      },
      // 获取上个页面传参
      async getDataFromParam() {
        this.fromIndex = false;
        const { billCode, billId, data, sourceParseField, customData, hideControl } = this.$route.params;
        this.billCode = billCode;
        this.billId = billId;
        this.tableData = data;
        this.copyTableData = data;
        this.sourceParseField = sourceParseField;
        this.customData = customData;
        this.hideControl = hideControl;
      },
      handleChoose(type, obj) {
        switch (type) {
          case 'outboundOrder':
            this.selectValue = obj.value;
            this.skipNext(10);
            break;
          default:
            return null;
        }
      },

      // /**
      //  * 查询按钮enter时间
      //  * */
      // handleQueryEnter(){
      //
      // },

      /**
       * table里的input enter事件判断
       */
      handleTableEnter(index) {
        for(let i=index; i<this.tableData.length; i++) {
          let toIndex = 20*index+30;
          if(!(this.$refs['ref' + toIndex][0].disabled || this.$refs['ref' + toIndex].disabled)) {
            this.skipNext(toIndex);
            return
          }
            toIndex += 10;
            if(!(this.$refs['ref' + toIndex][0].disabled || this.$refs['ref' + toIndex].disabled)) {
              this.skipNext(toIndex);
              return
            }

        }
        this.skipNext('submitRef');
      },

      /**
       * table里的lov enter事件判断
       */
      handleTableLovEnter(index) {
        for(let i=index+1; i<this.tableData.length; i++) {
          const toIndex = 20*index+40;
          if(!(this.$refs['ref' + toIndex][0].disabled || this.$refs['ref' + toIndex].disabled)) {
            this.skipNext(toIndex);
            return
          }
        }
        this.skipNext('submitRef');
      },

      /**
       * 子级出库单号选中事件
       * @param {Object} obj 选中对象
       * @param {Object} item table当前行
       */
      async chooseChildBill(obj, item) {
        item.billId = obj.billId;
        item.billCode = obj.billCode;
      },

      // 查询
      async handleButtonSubmit() {
        const _this = this;
        if(this.billData.outboundOrder === this.$t('label.sowLocation')){
          this.selectValue = 'sortingLocation'
        }
        if(this.selectValue){
          this.$hint.showLoading();
          this.handleSubmitStatus();
          if(this.selectValue === 'billCode'){
            this.queryDataSet(this.selectValue,this.billData.querySearch,'billCode');
          }else if(this.selectValue === 'sortingLocation'){
            this.queryDataSet(this.selectValue,this.billData.querySearch,'sortingLocation');
          }
          setTimeout(function() {
            _this.$hint.hideLoading();
            this.handleSubmitStatus();
            if(_this.$refs['ref20'][0].disabled || _this.$refs['ref20'].disabled){
              _this.handleTableEnter(0);
            }else {
              _this.skipNext(20);
            }

          },200);
        } else {
          this.tableData = this.copyTableData;
        }
      },

      queryDataSet(params, data, lovParams){
        if(params === lovParams){
          if(data){
            const newData = [];
            this.copyTableData.forEach((item, index)=>{
              if(item[lovParams].indexOf(data)!==-1){
                newData.push(this.copyTableData[index])
              }
            });
            this.tableData = newData;
          } else {
            this.tableData = this.copyTableData;
          }
        }
      },

      /**
       * 提交
       */
      async handleAssociatedSubmit() {
        let submitFlag = true;
        this.tableData.forEach((item)=>{
          if(!item.billId){
            submitFlag = false;
            return;
          }
        });
        if(!submitFlag){
          this.$hint.showFailed(this.$i18n.t('message.checkRequired'), true);
          return;
        }
        try {
          // const url = `${this.winvBaseUrl}app-sorting-locations/confirm`;
          const params = {
            billId: this.billId,
            billCode: this.billCode,
            orderSortingLocationsList: this.tableData,
          };
          this.$hint.showLoading();
          // const res = await this.$http.post(url, params);
          const res = await submitConfirm(params);
          if (res.failed) {
            this.$hint.showFailed(res.message);
            return
          }
          await this.clearSourceFields(this, true);
          this.$hint.showSuccess(this.$i18n.t('message.operationSuccess'));
          this.$router.backPage();

        } catch(e) {
          console.log('错误：' + e);
        } finally{
          this.$hint.hideLoading();
        }
      }

    },
    async mounted() {
      await this.getDataFromParam();
      this.inputFieldList= [
        { type: 'input', required: false, ref: 10, bind: 'billData.querySearch', field: 'querySearch' },
      ];
      this.urlData = this.handleData('SORT_LOCATION_MAP_CONFIRM');
      this.skipNext(10);
      this.tableTitle = [
        {
          name: this.$t('label.sowLocation'), // 播种位
        },
        {
          name: this.$t('label.outboundOrderR'), // 出库单号
        },
      ];
      // this.msg =  `<p style="line-height:25px">${this.$t('title.associatedSite')}</p>
      // <p style="line-height:25px">${ this.billCode }</p>`
    },
  }
</script>

<style lang="stylus" scoped>
 @import '~@/style/var.styl'
  .ips-table {
    width 100%
    overflow-x auto
    margin-top 0.6rem
    table {
      width 100%
      margin-bottom 2rem
    }
    th, td {
      padding 5px 2px
      border 1px solid #ccc
      text-align center
      white-space nowrap
    }
  }
  .ips-outbound-style{
    margin-bottom 0.6rem
  }
  .ips-row-left{
    float right
  }
</style>