import miscellaneousOut from '../views/miscellaneousOut'
import cidMiscellaneousOutDetail from '../views/cidMiscellaneousOutDetail'

let route = [{
    path: '/miscellaneous-out',
    name: 'miscellaneousOut',
    component: miscellaneousOut,
  },
  {
    path: '/cidMiscellaneousOutDetail',
    name: 'cidMiscellaneousOutDetail',
    component: cidMiscellaneousOutDetail,
  },
];

export default route