<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header
        :title="$t('title.cancelPallet')"
        :trigger="$t('button.detail')"
        :on-go-detail="goDetail"
      />
    </div>
    <div class="ips-container">
      <!-- 起始托盘 -->
      <ips-lov
        :label="$t('label.startOfTray')"
        :title="$t('label.startOfTray')"
        v-model="cidCode"
        v-show="customData['cidCode'] ? hideControl['cidCode']: true"
        required
        params="cidCode"
        :url="`${winvBaseUrl}stock-trans/out-cid-lov`"
        :pre-parse-url="urlData['cidCode']"
        :custom="customData['cidCode']"
        :source-parse-field="sourceParseField"
        ref="ref0"
        @click.stop="handleCommonClick(0)"
        @clickIcon="handleCommonClick(0, 'lov')"
        @enter="skipNext('submitRef')"
        @down="skipNext('submitRef')"
        @choose="handleChoose($event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!--库区-->
      <ips-value-cell
        :title="$t('label.whareaCode')"
        v-show="customData['whareaCode'] ? hideControl['whareaCode']: true"
        :value="cidInfo.whareaCode"
      />
      <!--库位-->
      <ips-value-cell
        :title="$t('label.countLocation')"
        v-show="customData['locationCode'] ? hideControl['locationCode']: true"
        :value="cidInfo.locationCode"
      />
      <!--现有量-->
      <ips-value-cell
        :title="$t('label.availableQty')"
        v-show="customData['qty'] ? hideControl['qty']: true"
        :value="qtyInfo.qty"
      />
      <!--可用量-->
      <ips-value-cell
        :title="$t('label.useQty')"
        v-show="customData['validQty'] ? hideControl['validQty']: true"
        :value="qtyInfo.validQty"
      />
    </div>
    <div class="ips-footer">
      <!--整托撤销-->
      <hips-button
        v-show="customData['detail'] ? hideControl['detail']: true"
        size="large"
        ref="nextRef"
        @keyup.enter.native="handleCommonSubmit('N')"
        @keyup.right.native="handleRight"
        @keypress.native.prevent
        @click.stop="handleCommonSubmit('N')" 
      >
        {{ $t('button.allCancellation') }}
      </hips-button>
      <!--一键打散-->
      <hips-button
        size="large"
        ref="submitRef"
        @keyup.enter.native="handleCommonSubmit('Y')"
        @keyup.left.native="handleLeft"
        @keypress.native.prevent
        @click.stop="handleCommonSubmit('Y')"
      >
        {{ $t('button.allBreakUp') }}
      </hips-button>
    </div>
  </hips-view>
</template>

<script>
import { queryQtyInfo, confirmUnstack } from '../api'
import { storage } from '@/utils';
export default {
  name: 'Unpack',
  data() {
    return {
      cidCode: '', // 起始托盘
      cidInfo: {}, // 托盘详情
      qtyInfo: '', // 数量详细
      urlData:{},
      customData: {}, // 个性化配置信息列表
      hideControl: {}, // 个性化配置显示隐藏控制
      sourceParseField: {}, // 界面中所有的来源解析字段
    };
  },
  methods: {
    /**
     *@functionName: saveSourceField
     *@description: 保存来源解析字段并赋值Enter
     *@params1: fieldObj[Object] 来源解析字段对象,
     */
    saveSourceField(fieldObj) {
      this.saveSourceParseField(this, fieldObj);
    },
    // 字段聚焦时赋值操作
    async valuedByFoucs(options={}) {
      const { custom ={} , currentValue } = options;
      await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
    },
    // 起始托盘lov选择事件
    handleChoose(obj) {
      this.cidInfo = obj;
      this.queryQty(obj);
    },

    handleLeft(){
      this.skipNext('nextRef');
    },

    handleRight(){
      this.skipNext('submitRef');
    },

    // 数量获取
    async queryQty(obj) {
      try {
        this.$hint.showLoading();
        let param = {
          params: {
            cidId: obj.cidId,
            locationId: obj.locationId,
            whareaId: obj.whareaId,
          },
        };
        this.qtyInfo = await queryQtyInfo(param);
      } catch(e) {
        console.log('错误: ' + e);
      } finally {
        this.$hint.hideLoading();
      }
    },
    // 跳转至详情界面
    goDetail() {
      if (!this.cidCode) {
        this.$hint.showFailed(this.$i18n.t('message.startCidNotNull'), true);
        return;
      }
      let param = {
        name: 'UnpackDetail',
        params: {
          cidCode: this.cidCode,
          cidId: this.cidInfo.cidId,
        },
      };
      this.$router.pushPage(param);
    },

    // 提交事件
    async handleButtonSubmit(flag) {
      try{
        this.$hint.showLoading();
        this.handleSubmitStatus();
        const data = {
          fromCidCode: this.cidCode,
          fromCidId: this.cidInfo.cidId,
          isToGoodsStockFlag: flag,

        };
        await confirmUnstack(data);
        await this.clearSourceFields(this, true);
        this.$hint.showSuccess(this.$i18n.t('message.operationSuccess'));
        this.dataInit();
        this.$refs['ref0'].focus();
      } catch (e) {
        console.log('错误: ' + e);
      } finally{
        this.$hint.hideLoading();
        this.handleSubmitStatus();
      }
    },
    dataInit() {
      this.cidCode = '';
      this.cidInfo = {};
      this.qtyInfo = {};
    }
  },
  created() {
    const { customObj, hideControl } = this.handleCustomData(storage.get('customData'));
    this.customData = customObj;
    this.hideControl = hideControl;
    // console.log('撤销码垛customData', Object.assign({}, this.customData));
  },
  mounted() {
    this.inputFieldList= [
      { type: 'text', bind: 'whareaCode', field: 'whareaCode' },
      { type: 'text', bind: 'locationCode', field: 'locationCode' },
      { type: 'text', bind: 'qty', field: 'qty' },
      { type: 'text', bind: 'validQty', field: 'validQty' },
      { type: 'lov', required: true, ref: 0, bind: 'cidCode', field: 'cidCode' },
      ];
    this.urlData = this.handleData('UNPACK_CONFIRM');
    this.skipNext(0);
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'applications') {
      this.$store.commit('deleteKeepAlives', []);
    } else if (to.name === 'UnpackDetail') {
      this.$store.commit('pushKeepAlives', 'Unpack');
    }
    this.$nextTick(() => {
      next();
    });
  },
};
</script>

<style lang="stylus" scoped></style>
