var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [_c("ips-header", { attrs: { title: _vm.$t("title.expressReceipts") } })],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _c("ips-field", {
          ref: "ref10",
          attrs: {
            required: "",
            label: _vm.$t("label.expressBackCode"),
            params: "expressBackCode",
            display: "expressBackCode",
            "pre-parse-url": _vm.urlData["expressBackCode"],
            custom: _vm.customData["expressBackCode"],
          },
          on: {
            blur: function ($event) {
              return _vm.checkExpressCode()
            },
            click: function ($event) {
              return _vm.handleCommonClick(10)
            },
            enter: function ($event) {
              return _vm.skipNext(20)
            },
            down: function ($event) {
              return _vm.skipNext(20)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.expressData.expressBackCode,
            callback: function ($$v) {
              _vm.$set(_vm.expressData, "expressBackCode", $$v)
            },
            expression: "expressData.expressBackCode",
          },
        }),
        _c("ips-lov", {
          ref: "ref20",
          attrs: {
            label: _vm.$t("label.expressBackTypeName"),
            required: "",
            title: _vm.$t("title.expressBackTypeName"),
            display: "expressBackTypeName",
            params: ["expressBackTypeName"],
            url: _vm.winvBaseUrl + "app-express-receipts/express-type-lov",
            "pre-parse-url": _vm.urlData["expressBackTypeName"],
            custom: _vm.customData["expressBackTypeName"],
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(20)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(20, "lov")
            },
            enter: function ($event) {
              return _vm.skipNext(30)
            },
            down: function ($event) {
              return _vm.skipNext(30)
            },
            choose: function ($event) {
              return _vm.handleChoose("expressBackType", $event)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.expressData.expressBackTypeName,
            callback: function ($$v) {
              _vm.$set(_vm.expressData, "expressBackTypeName", $$v)
            },
            expression: "expressData.expressBackTypeName",
          },
        }),
        _c("ips-input-number", {
          ref: "ref30",
          attrs: {
            label: _vm.$t("label.pieceQty"),
            required: "",
            min: null,
            "more-than-zero": true,
            precision: _vm.precision,
            "not-auto-fill": "",
            params: "pieceQty",
            display: "pieceQty",
            "pre-parse-url": _vm.urlData["pieceQty"],
            custom: _vm.customData["pieceQty"],
          },
          on: {
            click: function ($event) {
              return _vm.handleCommonClick(20)
            },
            enter: function ($event) {
              return _vm.skipNext(40)
            },
            down: function ($event) {
              return _vm.skipNext(40)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.expressData.pieceQty,
            callback: function ($$v) {
              _vm.$set(_vm.expressData, "pieceQty", $$v)
            },
            expression: "expressData.pieceQty",
          },
        }),
        _c("ips-lov", {
          ref: "ref40",
          attrs: {
            label: _vm.$t("label.province"),
            title: [_vm.$t("label.province")],
            display: "province",
            params: ["regionName"],
            url: _vm.winvBaseUrl + "app-express-receipts/get-region-lov",
            "pre-parse-url": _vm.urlData["province"],
            custom: _vm.customData["province"],
          },
          on: {
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(40)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(40, "lov")
            },
            enter: function ($event) {
              return _vm.skipNext(50)
            },
            down: function ($event) {
              return _vm.skipNext(50)
            },
            choose: function ($event) {
              return _vm.chooseProvince("province", $event)
            },
          },
          model: {
            value: _vm.expressData.province,
            callback: function ($$v) {
              _vm.$set(_vm.expressData, "province", $$v)
            },
            expression: "expressData.province",
          },
        }),
        _c("ips-lov", {
          ref: "ref50",
          attrs: {
            label: _vm.$t("label.city"),
            title: [_vm.$t("label.city")],
            display: "city",
            params: ["regionName"],
            url: _vm.winvBaseUrl + "app-express-receipts/get-region-lov",
            "url-params": { parentRegionId: _vm.expressData.regionId },
            "pre-parse-url": _vm.urlData["city"],
            custom: _vm.customData["city"],
          },
          on: {
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(50)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(50, "lov")
            },
            enter: function ($event) {
              return _vm.skipNext(60)
            },
            down: function ($event) {
              return _vm.skipNext(60)
            },
            choose: function ($event) {
              return _vm.chooseProvince("city", $event)
            },
          },
          model: {
            value: _vm.expressData.city,
            callback: function ($$v) {
              _vm.$set(_vm.expressData, "city", $$v)
            },
            expression: "expressData.city",
          },
        }),
        _c("ips-field", {
          ref: "ref60",
          attrs: {
            label: _vm.$t("label.productCode"),
            params: "sku",
            display: "sku",
            "pre-parse-url": _vm.urlData["sku"],
            custom: _vm.customData["sku"],
          },
          on: {
            blur: function ($event) {
              return _vm.checkSkuCode()
            },
            enter: function ($event) {
              return _vm.checkSkuCode()
            },
            click: function ($event) {
              return _vm.handleCommonClick(60)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.expressData.sku,
            callback: function ($$v) {
              _vm.$set(_vm.expressData, "sku", $$v)
            },
            expression: "expressData.sku",
          },
        }),
        _c("ips-input-number", {
          ref: "ref70",
          attrs: {
            label: _vm.$t("label.expressQty"),
            params: "qty",
            display: "qty",
            type: "number",
            "pre-parse-url": _vm.urlData["qty"],
            custom: _vm.customData["qty"],
          },
          on: {
            click: function ($event) {
              return _vm.handleCommonClick(70)
            },
            enter: function ($event) {
              return _vm.skipNext("submitRef")
            },
            down: function ($event) {
              return _vm.skipNext("submitRef")
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.expressData.qty,
            callback: function ($$v) {
              _vm.$set(_vm.expressData, "qty", $$v)
            },
            expression: "expressData.qty",
          },
        }),
        _c("hips-switch-cell", {
          attrs: { title: _vm.$t("title.isShowPrintTag") },
          on: { change: _vm.switchTag },
          model: {
            value: _vm.expressData.printFlag,
            callback: function ($$v) {
              _vm.$set(_vm.expressData, "printFlag", $$v)
            },
            expression: "expressData.printFlag",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-footer" },
      [
        _c(
          "hips-button",
          {
            ref: "submitRef",
            attrs: { size: "large" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonSubmit.apply(null, arguments)
              },
            },
          },
          [
            _c("svg-icon", {
              attrs: { slot: "icon", "icon-class": "success" },
              slot: "icon",
            }),
            _vm._v("\n      " + _vm._s(_vm.$t("button.submit")) + "\n    "),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }