import SubinventoryQuery from '../views/subinventory-query'
import SubinventoryQueryDetail from '../views/subinventory-query-detail'
import SkuTransfer from '../views/sku-transfer'
import SerialTransfer from '../views/serial-transfer'
import CidTransfer from '../views/cid-transfer'
import CidTransferDetail from '../views/cid-transfer-detail'


let route = [{
    path: '/sub-stock-transfer',
    name: 'SubinventoryQuery',
    component: SubinventoryQuery,
  },
  {
    path: '/subinventory-query-detail',
    name: 'SubinventoryQueryDetail',
    component: SubinventoryQueryDetail,
  },
  {
    path: '/sku-transfer',
    name: 'SkuTransfer',
    component: SkuTransfer,
  },
  {
    path: '/serial-transfer',
    name: 'SerialTransfer',
    component: SerialTransfer,
  },
  {
    path: '/cid-transfer',
    name: 'CidTransfer',
    component: CidTransfer,
  },
  {
    path: '/cid-transfer-detail',
    name: 'CidTransferDetail',
    component: CidTransferDetail,
  },
];

export default route