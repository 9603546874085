var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: { title: _vm.$t("title.count"), "is-index": true },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["billCode"]
                ? _vm.hideControl["billCode"]
                : true,
              expression:
                "customData['billCode'] ? hideControl['billCode']: true",
            },
          ],
          ref: "ref0",
          attrs: {
            label: _vm.$t("label.inventoryCode"),
            title: _vm.$t("label.inventoryCode"),
            required: "",
            params: "billCode",
            display: "billCode",
            url:
              _vm.winvBaseUrl +
              "count-orders?countStatusList=PROCESSING&countStatusList=APPROVED",
            "pre-parse-url": _vm.urlData["billCode"],
            custom: _vm.customData["billCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(0)
            },
            enter: function ($event) {
              return _vm.skipNext("submitRef")
            },
            down: function ($event) {
              return _vm.skipNext("submitRef")
            },
            choose: function ($event) {
              return _vm.handleChoose($event)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(0, "lov")
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.inputData.billCode,
            callback: function ($$v) {
              _vm.$set(_vm.inputData, "billCode", $$v)
            },
            expression: "inputData.billCode",
          },
        }),
        _c(
          "div",
          { staticClass: "out_box" },
          [
            _c("ips-value-cell", {
              attrs: {
                title: _vm.$t("label.warehouse"),
                "image-flag": "",
                value: _vm.orderData.warehouseName,
              },
            }),
            _c("ips-value-cell", {
              attrs: {
                title: _vm.$t("label.ownerCode"),
                "image-flag": "",
                value: _vm.orderData.ownerName,
              },
            }),
            _c("ips-value-cell", {
              attrs: {
                title: _vm.$t("label.countTypeMeaning"),
                "image-flag": "",
                value: _vm.orderData.countTypeMeaning,
              },
            }),
            _c("ips-value-cell", {
              attrs: {
                title: _vm.$t("label.countPrecisionMeaning"),
                "image-flag": "",
                value: _vm.orderData.countPrecisionMeaning,
              },
            }),
            _c("ips-value-cell", {
              attrs: {
                title: _vm.$t("label.countType"),
                "image-flag": "",
                value: _vm.orderData.countModeMeaning,
              },
            }),
            _c("ips-value-cell", {
              attrs: {
                title: _vm.$t("label.status"),
                "image-flag": "",
                value: _vm.orderData.countStatusMeaning,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "ips-footer" },
          [
            _c(
              "hips-button",
              {
                ref: "submitRef",
                attrs: { size: "large" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.goDetail.apply(null, arguments)
                  },
                },
              },
              [
                _c("svg-icon", {
                  attrs: { slot: "icon", "icon-class": "success" },
                  slot: "icon",
                }),
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("button.confirm")) + "\n      "
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }