<template>
  <div style="background-color: #f6f7fa">
    <div ref="header-box-dom" class="header-box">
      <div ref="logo-box-dom" class="logo-box">
        <img class="img-box" :src="logo" alt="">
        <div class="right">
          WMS{{ $t('title.warehouseManagement') }}
        </div>
      </div>
      <div ref="search-box-dom" class="search-box">
        <hips-search v-model="input" @change="handleChange" placeholder="请输入搜索条件" show-clear-button />
      </div>
    </div>
    <div :style="'top:' + scrollboxTop + 'Px;height:' + scrollHeight + 'Px;'" class="scroll-box">
      <hips-scroll ref="scroll" :scroll-options="scrollOption" :data="[functionCategorys]" @pulling-down="loadRefresh">
        <div v-if="!isShowSeacher" class="applications">
          <div v-if="false" class="func-categrory-wrap">
            <div class="func-categrory">
              <div class="title-wrap">
                <div class="title-box g-flex-between">
                  <div class="g-flex">
                    <div class="icon-box" />
                    <div v-if="language === 'zh_CN'" class="zh-box">
                      常用模块
                    </div>
                    <div v-if="language === 'en_US'" class="en-box">
                      菜单名字
                    </div>
                  </div>
                  <div class="setting-box g-flex">
                    <img style="width: 20px; margin-right: 4px" src="./resources/setting.png" alt="">
                    <div>管理</div>
                  </div>
                </div>
              </div>
              <hips-row>
                <hips-col
                  v-for="(item, index) in commonlyUsedfunctionMenus"
                  :key="index"
                  class="module"
                  span="6"
                  @click.native="enterPage(item)"
                >
                  <img class="img-box" :src="item.menuIcon" alt="" srcset="">
                  <div class="menu-name">
                    {{ language === 'zh_CN' ? item.menuName : item.menuCode }}
                  </div>
                </hips-col>
              </hips-row>
            </div>
          </div>
          <div class="func-categrory-wrap">
            <div v-for="category in functionCategorys" :key="category.categoryCode" class="func-categrory">
              <div class="title-wrap">
                <div class="title-box g-flex">
                  <div class="icon-box" />
                  <div v-if="language === 'zh_CN'" class="zh-box">
                    {{ category.categoryName }}
                  </div>
                  <div v-if="language === 'en_US'" class="en-box">
                    {{ category.categoryCode }}
                  </div>
                </div>
              </div>
              <hips-row>
                <hips-col
                  v-for="(item, index) in category.functionMenus"
                  :key="index"
                  class="module"
                  span="6"
                  @click.native="enterPage(item)"
                >
                  <img class="img-box" :src="item.menuIcon" alt="" srcset="">
                  <div class="menu-name">
                    {{ language === 'zh_CN' ? item.menuName : item.menuCode }}
                  </div>
                </hips-col>
              </hips-row>
            </div>
          </div>
        </div>
        <div v-else class="applications">
          <div class="func-categrory-wrap">
            <div v-if="filterMenus.length > 0" class="seacher-list">
              <div @click="enterPage(item)" v-for="item in filterMenus" :key="item.menuName" class="item g-flex-between">
                <div class="left-image g-flex-center">
                  <img data-v-665e6f6c="" :src="item.menuIcon" alt="" srcset="" class="img-box">
                  <div class="menu-text">
                    {{ item.menuName }}
                  </div>
                </div>
                <div class="right-arrow">
                  <img src="./resources/arrow-right.png" alt="">
                </div>
              </div>
            </div>
            <div class="no-data-box" v-else>
              <img src="./resources/no-data.png" alt="">
            </div>
          </div>
        </div>
      </hips-scroll>
    </div>
  </div>
</template>

<script>
import { Col, Row, Search } from '@hips/vue-ui';
import { getFunctionCategorys, getHomeLogo } from '../public/api';
import http from '@/request';
const moduleColorsMap = {
  1: '#69C0FF',
  2: '#9353DE',
  3: '#56F0E5',
  4: '#FFBF5A',
};

export default {
  name: 'Applications',
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [Search.name]: Search,
  },
  data() {
    return {
      filterMenus:[],
      isShowSeacher: false,
      scrollHeight: '',
      scrollboxTop: -53,
      // 常用模块
      commonlyUsedfunctionMenus: [
        {
          _token: '68xIsAqCL9Hqiyv/4xJ+5IlVDvHLtuIoG5HbRiVzP1rzGBRmakH//gljRQguVbx20AxYyDVkauUZ/kClquGqfg==',
          id: 1,
          menuCode: 'RECEIVE',
          menuName: '标准接收',
          menuIcon: 'https://wmsdev-gateway.pooksh.com:9000/wms-app/icon/0/DEFAULT/788e70d16d6849c78954d6a6eb82c321@标准接收@2x.png',
          menuUrl: 'receive',
          categoryId: 1,
          menuSeq: 1,
          tenantId: 0,
        },
        {
          _token: '68xIsAqCL9Hqiyv/4xJ+5IlVDvHLtuIoG5HbRiVzP1rzGBRmakH//gljRQguVbx2e+QMJp8s2WLAt3/bVOoLEw==',
          id: 2,
          menuCode: 'DIRECT_INBOUND',
          menuName: '直接入库',
          menuIcon: 'https://wmsdev-gateway.pooksh.com:9000/wms-app/icon/0/DEFAULT/6e7034f5a1524170a350afb48ac6d4b8@直接入库@2x.png',
          menuUrl: 'inbound',
          categoryId: 1,
          menuSeq: 2,
          tenantId: 0,
        },
        {
          _token: '68xIsAqCL9Hqiyv/4xJ+5IlVDvHLtuIoG5HbRiVzP1rzGBRmakH//gljRQguVbx2HMehFaYBux2qRr5Ysx9jgQ==',
          id: 3,
          menuCode: 'PUT_AWAY',
          menuName: '上架入库',
          menuIcon: 'https://wmsdev-gateway.pooksh.com:9000/wms-app/icon/0/DEFAULT/6a8f58be0018448985932c2d9d68447d@上架入库@2x.png',
          menuUrl: 'put-away',
          categoryId: 1,
          menuSeq: 3,
          tenantId: 0,
        },
        {
          _token: '68xIsAqCL9Hqiyv/4xJ+5IlVDvHLtuIoG5HbRiVzP1rzGBRmakH//gljRQguVbx2wTfAav1jbm5gu2zUSYhNaQ==',
          id: 4,
          menuCode: 'DIRECTLY_SHELVES',
          menuName: '一步上架',
          menuIcon: 'https://wmsdev-gateway.pooksh.com:9000/wms-app/icon/0/DEFAULT/7379c86c82364bdea4457911b76c9f78@一步上架@2x.png',
          menuUrl: 'directly-shelves',
          categoryId: 1,
          menuSeq: 4,
          tenantId: 0,
        },
      ],
      input: '',
      scrollOption: {
        pullDownRefresh: {
          threshold: 60, // 触发 pullingDown 的距离
          stop: 40, // pullingDown 正在刷新 hold 时的距离
          txt: this.$i18n.t('message.refreshSuccess'),
        },
      },
      moduleColorsMap,
      language: 'zh_CN',
      functionCategorys: [], // 功能模块
      logo: require('./resources/logo.png'),
    };
  },
  mounted() {
    this.calculateHeight();
    window.addEventListener('resize', this.calculateHeight);
  },
  created() {
    this._initPage();
  },
  methods: {
    handleChange(val) {
      val = val.trim(); // 空格处理一下
      if (val) {
        let filterMenus = [];
        this.$storage.get('functionCategorys').map((item) => {
          item.functionMenus = item.functionMenus.filter((_item) => {
            return _item.menuName.indexOf(val) > -1;
          });
          if (item.functionMenus.length > 0) {
            filterMenus.push(...item.functionMenus);
          }
        });
        this.isShowSeacher = true;
        console.log(filterMenus)
        this.filterMenus = filterMenus
      } else {
        this.isShowSeacher = false;
        this.functionCategorys = this.$storage.get('functionCategorys');
      }
    },
    calculateHeight() {
      // 计算偏移量
      let style = window.getComputedStyle(this.$refs['header-box-dom'], null);
      const winHeight = window.screen.height;
      const headerHeight = this.$refs['header-box-dom'].clientHeight;
      const headerTopHeight = parseInt(style.getPropertyValue('padding-top'));
      const loginBoxHeight = this.$refs['logo-box-dom'].clientHeight;
      const seacherBoxHeight = this.$refs['search-box-dom'].clientHeight;
      // 计算top
      let top = headerHeight - headerTopHeight - loginBoxHeight - seacherBoxHeight - 13;
      this.scrollboxTop = -top;
      // 计算滚动容器的高度
      let tabBarBoxDom = document.querySelector('.tab-bar-box');
      let tabBarBoxDomHeight = tabBarBoxDom.clientHeight;
      let scrollHeight = winHeight - tabBarBoxDomHeight - headerHeight + top;
      this.scrollHeight = scrollHeight;
    },
    // 初始化页面
    _initPage() {
      this.language = this.$storage.get('language');
      // 获取菜单列表
      if (this.$storage.get('functionCategorys')) {
        this.functionCategorys = this.$storage.get('functionCategorys');
      } else {
        getFunctionCategorys({}).then((res) => {
          this.functionCategorys = res;
          this.$storage.set('functionCategorys', res);
        });
      }

      if (!this.$storage.get('homeLogo')) {
        this.getHomeLogo();
      } else {
        this.logo = this.$storage.get('homeLogo');
      }
    },
    // 刷新菜单
    loadRefresh() {
      this.$storage.remove('homeLogo');
      this.$storage.remove('functionCategorys');
      this._initPage();
    },
    // 获取首页logo
    async getHomeLogo() {
      const data = {
        params: {
          tenantId: this.$storage.get('tenantId'),
          configCode: 'APP_LOGO',
        },
      };
      getHomeLogo(data).then((res) => {
        if (res) {
          this.$storage.set('homeLogo', res);
          this.logo = res;
        }
      });
    },

    // 点击进入子模块
    async enterPage({ menuUrl, id }) {
      if (menuUrl.indexOf('http') !== -1 && menuUrl.indexOf('#') !== -1) {
        menuUrl = menuUrl.split('#')[1];
      }
      this.$storage.set('menuId', id);
      await this.getCustomConfig();
      await this.getInterfaceInfo();
      this.$router.pushPage(menuUrl);
    },
    // 获取urlData
    getInterfaceInfo: async function () {
      try {
        const winvBaseUrl = `${process.env.VUE_APP_WINV_URL}v1/${this.$storage.get('tenantId')}/`;
        const url = `${winvBaseUrl}personal/query-page-app-id`;
        const menuId = this.$storage.get('menuId');
        if (menuId === undefined || menuId === '' || menuId === 'undefined') {
          console.log('不执行');
        } else {
          const data = {
            params: {
              appId: menuId,
            },
          };
          const res = await http.get(url, data);
          this.$storage.set('urlData', res.pages);
        }
      } catch (e) {
        console.log('getInterfaceInfo exc error', e.message);
      }
    },
    // 获取个性化配置信息
    getCustomConfig: async function () {
      try {
        // const winvBaseUrl = `${process.env.VUE_APP_WINV_URL}v1/${storage.get('tenantId')}/`;
        // const url = `${winvBaseUrl}personal/query-page-app-id`;
        const menuId = this.$storage.get('menuId');
        if (menuId === undefined || menuId === '' || menuId === 'undefined') {
          console.log('未获取到子应用ID, 获取子应用个性化配置信息失效');
          return;
        }
        this.$storage.remove('customData');
        const data = {
          params: {
            subApplicationId: menuId,
            pdaEnabledFlag: 1, // PDA端默认指查询启用状态配置
          },
        };
        const url = `${process.env.VUE_APP_WINV_URL}v1/${this.$storage.get('tenantId')}/app-field-assignments/query-all`; // /custom/config
        const customConfigData = await http.get(url, data);
        const { content: resData } = customConfigData;
        console.log('获取子应用配置信息成功', resData);
        this.$storage.set('customData', resData);
      } catch (e) {
        console.log('getCustomConfig exc error', e.message);
      }
    },
  },
};
</script>

<style scoped lang="stylus">
@import '../style/var.styl';

.scroll-box{
    height: 73vh;
    z-index: 300;
    top: -58Px;
    position: relative;
    border-radius: 7px;
    overflow: hidden;
    background:white;
    margin: 0 10px;
}
.header-box {
    padding: 10px;
    box-sizing: border-box;
    // background-color:$app-primari-color;
    position: relative;
    height: 26vh;
    overflow: hidden;
    width: 100%;
    z-index: 99;

    :after {
        /* 这个伪类的作用就是一个圆弧的背景色 */
        width: 160%;
        height: 150px;
        position: absolute;
        /*设置水平居中*/
        left: -30%;
        /* 之所以left:20%,是因为width:140%，若width:160%，那么left:30%才能水平居中 */
        top: 0;
        z-index: -1;
        /*层叠顺序，最底层显示*/
        content: '';
        border-radius: 0 0 50% 50%;
        /*分别对应 左上 右上 右下 左下 可以修改成其它颜色*/
        background-color: $app-primari-color;
    }

    .logo-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height:40Px;
        .img-box {
            height: 100%;
            img {
                height: 100%;
            }
        }

        .right {
            font-size: 18Px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #222222;
            line-height: 18px;
        }
    }

    .search-box {
        padding-top: 10px;

        .hips-search {
            height: 30px;
            border-radius: 18px;
            border: 2px solid white;
            overflow: hidden;

            :deep .hips-search__input {
                background: rgba(247, 247, 247, 0.6);
                padding-left: 5px;
                .hips-icon-search:before{
                    content: "\F079";
                    color:#222;
                    vertical-align: -2px;
                }
                /* WebKit browsers */
                input::-webkit-input-placeholder {
                    color: #999;
                }

                /* Mozilla Firefox 4 to 18 */
                input:-moz-placeholder {
                    color: #999;
                }

                /* Mozilla Firefox 19+ */
                input::-moz-placeholder {
                    color: #999;
                }

                /* Internet Explorer 10+ */
                input:-ms-input-placeholder {
                    color: #999;
                }
            }
        }
    }
}
.applications {
    padding-bottom: 0px;
    height: 100%;
    .func-categrory-wrap {
        position: relative;
        z-index: 300;
        border-radius: 7px;
        background-color:rgb(246, 247, 250);

        .no-data-box{
            padding-top: 50px;
            text-align: center;
            img{
                 width:206px;
            }
        }
       
        .seacher-list{
            height: 100%;
            background:white;
            
            .item{
               height: 60px;
               padding: 0 10px;
               background: white;
               border-bottom: 1px solid #ddd;
               .left-image{
                    img{
                        width:40px;
                    }
                    .menu-text{
                        margin-left: 5px;
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        color: #222222;
                    }
                }
                .right-arrow{
                    img{
                        width: 12px;
                    }  
                }
            }
        }
        .func-categrory {
            background-color: white;
            border-radius: 7px;
            padding: 15px 10px;
            margin-bottom: 10px;
            .module {
                text-align: center;
            }

            .title-wrap {
                .title-box {
                    .setting-box{
                        font-size: 13px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        color: #999999;
                        vertical-align: middle;
                        align-items: center;
                    }
                    .icon-box {
                        width: 4Px;
                        background: #FFBE37;
                        border-radius: 2Px;
                        display: inline-block;
                        margin-right: 5px;
                    }
                    .zh-box,.en-box {
                        font-size: 13px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #222222;
                        line-height: 13px;
                    }
                }
            }
            .hips-row{
                padding-top: 5px;
                .module {
                    padding-top:10px;
                }
                .menu-name {
                    font-size: 12px;
                    line-height: 20px;
                    color: #666;
                }
                img {
                    width: 36px;
                }
            }
        }
    }
}
</style>
