import request from '@/request';
import mixin  from '@/mixin/mixin';

// 包裹单号校验
export function checkPackCode(data) {
  let url = `${mixin.data().winvBaseUrl}app-outbound-delivery/validate-pack`;
  return request.post(url, data);
}

// 确认交接
export function packConfirm(data) {
  let url = `${mixin.data().wmdmBaseUrl}app-outbound-delivery/confirm`;
  return request.post(url, data)
}
