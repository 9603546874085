var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: { title: _vm.$t("title.taskDetail"), "is-index": false },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _c(
          "hips-scroll",
          _vm._l(_vm.taskList, function (item, index) {
            return _c("ips-page-card", {
              key: index,
              attrs: {
                "react-field": ["sku", "cidCode", "batchCode", "qtyPlan"],
                "react-field-name": {
                  // 取值字段和字段名称特殊对应
                  qtyPlan: "quantity",
                },
                item: Object.assign({}, item),
                "title-info": { billCode: item.billCode },
              },
            })
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }