<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header
        :title="billDetail.billCode"
        :trigger="$t('button.skipTask')"
        :on-go-detail="handleSkipSubmit"
      />
    </div>
    <div class="ips-container">
      <!-- 物料编码 -->
      <!-- <ips-value-cell
        :title="$t('label.skuCode')"
        :value="taskInfo.sku || 0"
      /> -->
      <!-- 物料名称-->
      <!-- <ips-value-cell
        :title="$t('label.nameOfTheMaterial')"
        :value="taskInfo.goodsName || 0"
      /> -->
      <!-- 物料 -->
      <ips-value-cell
        :title="$t('label.sku')"
        :value="`${taskInfo.sku} | ${taskInfo.goodsName }`|| 0"
      />
      <!-- 备注 -->
      <ips-value-cell
        :title="$t('label.remark')"
        :value="taskInfo.remark || '无'"
      />
      <!-- 库区 -->
      <ips-value-cell
        :title="$t('label.whareaCode')"
        :value="taskInfo.whareaCode || '无'"
      />
      <!-- 库位 -->
      <ips-value-cell
        :title="$t('label.countLocation')"
        :value="taskInfo.locationCode || '无'"
      />
      <!-- 拣货库位 -->
      <ips-lov
        :label="$t('label.pickLocation')"
        :title="$t('label.pickLocation')"
        v-model="inputData.locationCode"
        v-show="customData['locationCode'] ? hideControl['locationCode'] : true"
        required
        params="locationCode"
        :url-params="{ whareaId: taskInfo.whareaId, goodsId: taskInfo.goodsId }"
        :url="`${wmdmBaseUrl}app-wave-picking/location-lov`"
        :pre-parse-url="urlData['locationCode']"
        :custom="customData['locationCode']"
        :source-parse-field="sourceParseField"
        :disabled="fieldLocationCodeDisabled"
        ref="ref10"
        @click.stop="handleCommonClick(10)"
        @clickIcon="handleCommonClick(10, 'lov')"
        @choose="handleChooseLocationCode($event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 物料 XSCK202208170007-->
      <ips-field
        v-model="inputData.sku"
        :label="$t('label.sku')"
        ref="ref20"
        required
        params="sku"
        display="sku"
        @click.stop="handleCommonClick(20)"
        @down="handleScanSku"
        @enter="handleScanSku"
        @blur="handleScanSku"
        v-show="customData['sku'] ? hideControl['sku'] : true"
        :pre-parse-url="urlData['sku']"
        :pre-parse-params="{orgId: billDetail.orgId, ownerId: billDetail.ownerId}"
        :custom="customData['sku']"
        :source-parse-field="sourceParseField"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 批次、序列号-->
      <ips-field
        v-model="inputData.code"
        v-if="fieldCodeIsShow"
        :label="fieldCodeLabel"
        ref="ref30"
        params="code"
        display="code"
        :pre-parse-url="urlData['code']"
        :custom="customData['code']"
        :required="fieldCodeRequired"
        @click.stop="handleCommonClick(30)"
        @down="handleBarCode"
        @enter="handleBarCode"
        @blur="handleBarCode"
      />
      <!-- 数量 -->
      <ips-input-number
        v-model="inputData.qty"
        required
        :label="$t('label.quantity')"
        :disabled="fieldQtyDisabled"
        :pre-parse-url="urlData['qty']"
        :custom="customData['qty']"
        ref="ref40"
        @enter="handleQuantity"
        @down="handleQuantity"
        @blur="handleQuantity"
        :min="null"
        @click.stop="handleCommonClick(40)"
        :more-than-zero="true"
        not-auto-fill
      />
      <div class="footer-btns">
        <!--确认拣货-->
        <hips-button
          size="large"
          ref="submitRef"
          @keyup.right.native="skipNext('nextRef')"
          @click="submitFun"
        >
          {{ $t('button.confirmThePicking') }}
        </hips-button>
        <!--更换库位-->
        <hips-button
          size="large"
          ref="nextRef"
          class="change-location"
          @keyup.left.native="skipNext('submitRef')"
          @click="changeTheLocation"
        >
          {{ $t('button.changeTheLocation') }}
        </hips-button>
      </div>

      <div class="light-blue-box">
        <div class="line">
          <div class="left">
            需求数量：{{ taskInfo.qtyPlan }}
          </div>
          <div class="right">
            还需出库数量：{{ taskInfo.qtyPlan - totalQty }}
          </div>
        </div>
        <div class="line">
          <div class="left">
            现有量：{{ stockQty }}
          </div>
          <div class="right">
            扫描总数：{{ totalQty }}
          </div>
        </div>
      </div>
      <div style="display: flex">
        <hips-button
          size="large"
          class="ips-btn-normal"
          @click="showSwitchTenantFun"
        >
          {{ $t('message.scanTheDetail') }}
        </hips-button>
      </div>

    </div>
    <hips-popup
      v-model="showSwitchTenantPopup"
      position="bottom"
    >
      <div class="popup-title">
        <div class="left">
          <!-- {{ $t('button.clear') }} -->
        </div>
        <div class="center">
          {{ $t('message.scanTheDetail') }}
        </div>
        <div
          class="right"
          @click="showSwitchTenantPopup = false"
        >
          {{ $t('button.closeBtn') }}
        </div>
      </div>
      <div
        class="ips-table"
        style="overflow-y: scroll"
      >
        <table>
          <tr style="background-color: #eee">
            <th
              v-for="(item, index) in tableTitle"
              :key="index"
              scope="col"
            >
              {{ item.name }}
            </th>
          </tr>
          <tr
            v-for="(item, index) in taskList"
            :key="index"
            :ref="`tr${index}`"
          >
            <td>{{ item.sku }}</td>
            <td>{{ item.serialNumber }}</td>
            <td>{{ item.batchCode }}</td>
            <td>{{ item.qty }}</td>
          </tr>
        </table>
      </div>
    </hips-popup>
  </hips-view>
</template>

<script>
import { storage } from '@/utils';
import {
  fetchProcessTask,
  clearTask,
  validateSku,
  validateCode,
  queryScanDetail,
  querySummaryQty,
  validateQty,
  skipTask,
  clearCurrentScan,
  loadAndUnload,
} from '../api/index';
export default {
  data() {
    return {
      fieldLocationCodeDisabled: true,
      tableTitle: [],
      taskList: [],
      showSwitchTenantPopup: false,
      // 现有量
      stockQty: 0,
      // 数量是否可以使用
      fieldQtyDisabled: false,
      // 扫描总数
      totalQty: 0,
      // 条码字段显示标签
      fieldCodeLabel: '',
      // 条码字段知否显示
      fieldCodeIsShow: false,
      // 条码字段是否必输
      fieldCodeRequired: true,
      // 任务信息
      taskInfo: {},
      // 上个页面缓存
      billDetail: {},
      // 请求路由信息
      queryInfo: {},
      // 界面上的输入框数据
      inputData: {
        // 拣货库区
        locationCode: '',
        // 物料
        sku: '',
        // 数量
        qty: '',
      },
      inputFieldList: [
        { type: 'lov', required: true, ref: 10, bind: 'inputData.locationCode', field: 'locationCode' },
        { type: 'input', required: true, ref: 20, bind: 'inputData.sku', field: 'sku' },
        { type: 'input', required: true, ref: 30, bind: 'inputData.code', field: 'code' },
        { type: 'input', required: true, ref: 40, bind: 'inputData.qty', field: 'qty' },
      ],
      menuId: storage.get('menuId'),
      urlData: {},
      customData: {}, // 个性化配置信息列表
      hideControl: {}, // 非必输字段的显隐控制
      sourceParseField: {}, // 界面中所有的来源解析字段
    };
  },
  async created() {
    this.quantityPromise = undefined;
    this.scanSkuPromise = undefined;
    this.barCodePromise = undefined;
    const { hideControl, customObj } = this.handleCustomData(storage.get('customData') || []);
    this.customData = customObj || {};
    this.hideControl = hideControl || {};
    // console.log('按单收货customData', Object.assign({}, this.customData));
  },
  async mounted() {
    this.tableTitle = [
      {
        name: this.$t('label.skuCode'),
      },
      {
        name: this.$t('label.serialNumber'),
      },
      {
        name: this.$t('label.batchCode'),
      },
      {
        name: this.$t('label.shelvesQuantity'),
      },
    ];
    this.billDetail = storage.get('billDetail');
    // 需要先清空数据
    await clearTask({});
    this.queryInfo = this.$route.query;

    let data = {
      params: {
        sourceType: this.billDetail.sourceType,
        ...this.queryInfo,
      },
    };
    let res = await fetchProcessTask(data);
    this.taskInfo = res;
    this.containerManageFlag = res.containerManageFlag;
    if (res.locationCode) {
      this.inputData.locationCode = res.locationCode;
      this.skipNext(20);
    }
    let res2 = await querySummaryQty({
      params: {
        sourceType: this.billDetail.sourceType,
        scanLocationId: res.locationId,
        goodsId: res.goodsId,
      },
    });
    this.stockQty = res2.stockQty;
    this.urlData = this.handleData('ORDER_PICK_CONFIRM');
    this.$hint.hideLoading();
    // this.skipNext(10);
  },
  methods: {
    // 保存来源解析字段
    async saveSourceField(fieldObj) {
      this.saveSourceParseField(this, fieldObj);
    },
    // 字段聚焦时赋值操作
    async valuedByFoucs(options = {}) {
      const { custom = {}, currentValue } = options;
      await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
    },
    // 跳过任务
    async handleSkipSubmit() {
      try {
        this.$hint.showLoading();
        // 需要先清空数据
        this.inputData.locationCode = '';
        this.inputData.sku = '';
        this.inputData.qty = '';
        // console.log(this.billDetail);
        // console.log(this.taskInfo);
        const res = await skipTask({
          sourceType: this.billDetail.sourceType,
          tenantId: this.billDetail.tenantId,
          orgId: this.billDetail.orgId,
          ownerId: this.billDetail.ownerId,
          billId: this.billDetail.billId,
          billCode: this.billDetail.billCode,
          allowExecuteQty: this.taskInfo.allowExecuteQty,
          whareaId: this.taskInfo.whareaId,
          locationId: this.taskInfo.locationId,
          locationCode: this.taskInfo.locationCode,
          qtyPlan: this.taskInfo.qtyPlan,
          scanLocationId: this.locationId || this.taskInfo.locationId,
          scanLocationCode: this.locationCode || this.taskInfo.locationCode,
          goodsId: this.taskInfo.goodsId,
          totalQty: this.totalQty,
          containerManageFlag: this.containerManageFlag,
          isbatch: this.isbatch,
          serialType: this.serialType,
          code: this.inputData.code,
        });
        this.$hint.showSuccess(this.$i18n.t('message.skipTaskSuccess'));
        this.taskInfo = res;
        if (res && res.sku) {
          if (res.locationCode) {
            this.inputData.locationCode = res.locationCode;
            this.skipNext(20);
          }
          let res2 = await querySummaryQty({
            params: {
              sourceType: this.billDetail.sourceType,
              scanLocationId: res.locationId,
              goodsId: res.goodsId,
            },
          });
          this.stockQty = res2.stockQty;
        } else {
          setTimeout(() => {
            // 跳到上一个页面
            this.$router.backPage();
          }, 2000);
        }
      } catch (e) {
        console.log('错误: ' + e);
      } finally {
        this.$hint.hideLoading();
      }
    },
    // 查看扫描明细
    showSwitchTenantFun() {
      queryScanDetail({}).then((res) => {
        if (res.length > 0) {
          this.showSwitchTenantPopup = true;
          this.taskList = res;
        } else {
          console.log('no scan data');
        }
      });
    },
    // 处理数量
    handleQuantity() {
      if (!this.inputData?.qty) {
        return
      }

      if (!this.quantityPromise) {
        this.quantityPromise = validateQty({
          sourceType: this.billDetail.sourceType,
          tenantId: this.billDetail.tenantId,
          billId: this.billDetail.billId,
          orgId: this.billDetail.orgId,
          ownerId: this.billDetail.ownerId,
          allowExecuteQty: this.taskInfo.allowExecuteQty,
          whareaId: this.taskInfo.whareaId,
          locationId: this.taskInfo.locationId,
          locationCode: this.taskInfo.locationCode,
          scanLocationId: this.locationId || this.taskInfo.locationId,
          scanLocationCode: this.locationCode || this.taskInfo.locationCode,
          containerManageFlag: this.containerManageFlag,
          goodsId: this.taskInfo.goodsId,
          isbatch: this.isbatch,
          qtyPlan: this.taskInfo.qtyPlan,
          serialType: this.serialType,
          code: this.inputData.code,
          totalQty: this.totalQty,
          batchId: this.batchId,
          qty: this.inputData.qty,
        }).then((res) => {
          this.totalQty = res.totalQty;
          this.inputData.qty = '';
          this.inputData.code = '';
          if (res.totalQty === this.taskInfo.qtyPlan) {
            this.skipNext('submitRef');
          } else {
            this.skipNext('30');
          }
        }).finally(() => {
          this.$nextTick(() => {
            this.quantityPromise = undefined
          }, 20)
        });
      }
    },
    // 条码
    handleBarCode() {
      if (!this.barCodePromise) {
        if (!this.inputData?.code) {
          if (!this.fieldCodeRequired && this.containerManageFlag === 1 && this.isbatch ===0 && this.serialType !== 'INV_CTRL' && this.serialType !== 'OUT_CTRL' && this.serialType !== 'SEMI_INV_CTRL') {
            this.skipNext('40');
          }
          return
        }
        this.barCodePromise = validateCode({
          sourceType: this.billDetail.sourceType,
          tenantId: this.billDetail.tenantId,
          orgId: this.billDetail.orgId,
          ownerId: this.billDetail.ownerId,
          billId: this.billDetail.billId,
          billCode: this.billDetail.billCode,
          allowExecuteQty: this.taskInfo.allowExecuteQty,
          whareaId: this.taskInfo.whareaId,
          locationId: this.taskInfo.locationId,
          locationCode: this.taskInfo.locationCode,
          qtyPlan: this.taskInfo.qtyPlan,
          scanLocationId: this.locationId || this.taskInfo.locationId,
          scanLocationCode: this.locationCode || this.taskInfo.locationCode,
          goodsId: this.taskInfo.goodsId,
          sku: this.taskInfo.sku,
          totalQty: this.totalQty,
          containerManageFlag: this.containerManageFlag,
          isbatch: this.isbatch,
          serialType: this.serialType,
          code: this.inputData.code,
          warehouseId: this.taskInfo.warehouseId,
          toWarehouseId: this.taskInfo.toWarehouseId,
        })
        .then((res) => {
          this.batchId = res.batchId;
          this.totalQty = res.totalQty;
          // 如果是批次
          if (res.scanType === 'BATCH') {
            this.skipNext('40');
          } else if (res.scanType === 'SERIAL' || res.scanType === 'LPN') {
            this.fieldQtyDisabled = true;
            this.inputData.code = '';
            if (res.qty || res.qty === 0) {
              this.inputData.qty = res.qty;
            }
            if (res.totalQty === this.taskInfo.qtyPlan) {
              this.skipNext('submitRef');
            }
          } else {
            this.skipNext('40');
          }
        })
        .catch((err) => {
          console.log(err);
        }).finally(() => {
          this.$nextTick(() => {
            this.barCodePromise = undefined
          }, 20)
        });
      }
    },
    // 更换库位
    async changeTheLocation() {
      try {
        this.$hint.showLoading();
        await clearCurrentScan({});
        this.inputData.locationCode = '';
        this.inputData.sku = '';
        this.inputData.code = '';
        this.inputData.qty = '';
        this.fieldLocationCodeDisabled = false;
        setTimeout(() => {
          this.skipNext(10);
        }, 0);
      } catch (e) {
        console.log('错误: ' + e);
      } finally {
        this.$hint.hideLoading();
      }
    },

    // 确认拣货
    async submitFun() {
      const res = await loadAndUnload({
        sourceType: this.billDetail.sourceType,
        tenantId: this.billDetail.tenantId,
        orgId: this.billDetail.orgId,
        ownerId: this.billDetail.ownerId,
        billId: this.billDetail.billId,
        billCode: this.billDetail.billCode,
        allowExecuteQty: this.taskInfo.allowExecuteQty,
        whareaId: this.taskInfo.whareaId,
        locationId: this.taskInfo.locationId,
        locationCode: this.taskInfo.locationCode,
        qtyPlan: this.taskInfo.qtyPlan,
        scanLocationId: this.locationId || this.taskInfo.locationId,
        scanLocationCode: this.locationCode || this.taskInfo.locationCode,
        goodsId: this.taskInfo.goodsId,
        totalQty: this.totalQty,
        containerManageFlag: this.containerManageFlag,
        isbatch: this.isbatch,
        serialType: this.serialType,
        code: this.inputData.code,
        warehouseId: this.taskInfo.warehouseId,
        toWarehouseId: this.taskInfo.toWarehouseId,
      });
      if (res && res.sku) {
        this.$hint.showSuccess(this.$i18n.t('message.operationSuccess'));
        this.inputData.locationCode = '';
        this.taskInfo = res;
        if (res.locationCode) {
          this.inputData.locationCode = res.locationCode;
          this.skipNext(20);
        }
        let res2 = await querySummaryQty({
          params: {
            scanLocationId: res.locationId,
            goodsId: res.goodsId,
          },
        });
        this.stockQty = res2.stockQty;
        this.totalQty = 0;
        this.inputData.sku = '';
        this.inputData.code = '';
        this.inputData.qty = '';
      } else {
        this.$hint.showSuccess(this.$i18n.t('message.operationSuccess'));
        setTimeout(() => {
          // 跳到上一个页面
          this.$router.backPage();
        }, 2000);
      }
    },
    // 库位选择
    handleChooseLocationCode(obj) {
      this.locationId = obj.locationId;
      this.containerManageFlag = obj.containerManageFlag;
      this.skipNext('20');
    },
    // 处理扫描的物料
    handleScanSku() {
      if (!this.inputData?.sku) {
        return
      }

      if (!this.scanSkuPromise) {
        // 检验是否和上面展示的物料一致
        if (this.inputData.sku !== this.taskInfo.sku) {
          this.$hint.showFailed(this.$i18n.t('message.checkSkuSame'), true);
          return;
        }
        this.scanSkuPromise = validateSku({
          sourceType: this.billDetail.sourceType,
          tenantId: this.billDetail.tenantId,
          orgId: this.billDetail.orgId,
          ownerId: this.billDetail.ownerId,
          sku: this.inputData.sku,
        }).then((res) => {
          this.serialType = res.serialType;
          this.isbatch = res.isbatch;
          this.fieldCodeRequired = true;
          if ((this.billDetail.sourceType === 'OUT' && (res.serialType === 'INV_CTRL' || res.serialType === 'OUT_CTRL' || res.serialType === 'SEMI_INV_CTRL')) || (this.billDetail.sourceType === 'TRANSFER' && (res.serialType === 'INV_CTRL' || (res.serialType === 'SEMI_INV_CTRL' && this.taskInfo.warehouseId !== this.taskInfo.toWarehouseId)))) {
            // 序列号
            this.fieldCodeIsShow = true;
            this.fieldQtyDisabled = true;
            if (this.containerManageFlag === 1) {
              this.inputData.qty = 1;
              this.fieldCodeLabel = this.$t('label.serialNumberOrBoxCode');
            } else {
              this.fieldCodeLabel = this.$t('label.serialNumber');
              this.inputData.qty = 1;
            }
            this.$nextTick(() => {
                this.skipNext(30);
              });
          } else if (res.isbatch === 1) {
            // 批次
            this.fieldCodeIsShow = true;
            this.fieldQtyDisabled = false;
            if (this.containerManageFlag === 1) {
              this.fieldCodeLabel = this.$t('label.lotOrBoxCode');
            } else {
              this.fieldCodeLabel = this.$t('label.batch');
            }
            this.$nextTick(() => {
                this.skipNext(30);
              });
          } else {
            // 不是批次也不是序列号
            this.fieldQtyDisabled = false;
            if (this.containerManageFlag === 1) {
              this.fieldCodeIsShow = true;
              this.fieldCodeRequired = false;
              this.fieldCodeLabel = this.$t('label.boxCode');
              this.$nextTick(() => {
                this.skipNext(30);
              });
            } else {
              this.fieldCodeLabel = this.$t('label.barCode');
              this.$nextTick(() => {
                this.skipNext(40);
              });
            }
          }
        }).finally(() => {
          this.$nextTick(() => {
            this.scanSkuPromise = undefined
          }, 20)
        });
      }

      
    },
  },
};
</script>
<style lang="stylus" scoped>
@import '~@/style/var.styl';

.ips-btn-normal {
  flex: 1 !important;
  margin: 10px 0 !important;
}

.light-blue-box {
  padding: 10px;
  border-radius: 5px;
  background: #57B1ED;
  color: white;

  .line {
    display: flex;
    height: 25px;
    line-height: 25px;

    .left, .right {
      flex: 1;
    }
  }
}

.popup-title {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 0 20px;

  .left, .right {
    font-size: 12px;
    color: #999;
  }
}

.ips-table {
  margin-top: 16px;
  width: 100%;

  .ips-table-thead {
    width: 180px;
    height: 26px;
    background: rgba(255, 255, 255, 1);
    border-radius: 12px 0px 0px 0px;
    line-height: 26px;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    padding-left: 12px;
  }

  table {
    width: 100%;

    th, td {
      padding: 5px 2px;
      border: 1px solid #D9D9D9;
      text-align: center;
      white-space: nowrap;
      font-size: 14px;
      color: rgba(89, 89, 89, 1);

      &:first-child {
        width: 40px;
      }
    }

    .back-green {
      background-color: #cbdeb8;
    }
  }
}

.footer-btns {
  padding-top: 10px;
  background-color: $background-color-white;
  display: flex;
  width: 100%;

  button {
    flex: 1;
    border: none;
    border-radius: 2.133vw;
    margin: 0 2.667vw 3.2vw;
    font-size: 4.533vw;
    color: #FFF;
    background-color: #d9d9d9;
  }

  button:focus {
    background-color: var(--primaryColor) !important;
  }

  .change-location {
    background-color: #57B1ED !important;

    &:focus {
      background-color: #57B1ED !important;
    }
  }

  .svg-icon {
    margin-right: 5px;
    font-size: $fontsize-large;
  }
}
</style>
