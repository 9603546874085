import DirectOutbound from '../views/direct-outbound.vue'
import BatchDetail from '../views/batch-detail'


let route = [
  {
    path: '/direct-outbound',
    name: 'DirectOutbound',
    component: DirectOutbound,
  },
  {
    path: '/batch-detail',
    name: 'DirectOutboundToBatchDetail',
    component: BatchDetail,
  },
]

export default route