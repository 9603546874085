<template>
  <div>统计</div>
</template>

<script>
export default {
  name: 'Statistics',
};
</script>

<style lang="stylus" scoped>
</style>