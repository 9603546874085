<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <!-- 扫描明细 -->
      <ips-header :title="$t('message.scanTheDetail')" :is-index="false" />
    </div>
    <div class="ips-container">
      <hips-scroll>
        <ips-page-card
          v-for="(item, index) in taskList"
          :key="index"
          :react-field="['sku', 'batchCode', 'serialNumber', 'qty']"
          :react-field-name="{
            // 取值字段和字段名称特殊对应
            sku: 'skuCode',
            qty: 'amount',
          }"
          :item="{ ...item }"
          :title-info="{ sku: item.sku }"
        />
      </hips-scroll>
    </div>
  </hips-view>
</template>

<script>
export default {
  name: 'DetailAnalyse',
  data() {
    return {
      taskList: [],
    };
  },
  mounted() {
    this.taskList = JSON.parse(sessionStorage.getItem('reviewedList'));
  },
};
</script>

<style lang="stylus" scoped></style>
