<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.secondarySorting')" />
    </div>
    <div class="ips-container">
      <!-- 波次单号 -->
      <ips-lov
        :label="$t('label.waveNum')"
        :title="$t('label.waveNum')"
        v-model="inputData.waveNum"
        v-show="customData['waveNum'] ? hideControl['waveNum']: true"
        params="waveBillCode"
        display="waveBillCode"
        :url="`${winvBaseUrl}app-outbound-sorting/query-wave-orders`"
        :pre-parse-url="urlData['waveNum']"
        :custom="customData['waveNum']"
        :source-parse-field="sourceParseField"
        ref="ref0"
        is-control
        required
        @click.stop="handleCommonClick(0)"
        @clickIcon="handleCommonClick(0, 'lov')"
        @enter="skipNext(10)"
        @down="skipNext(10)"
        @choose="handleChoose('waveNum', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 序列号 -->
      <ips-lov
        :label="$t('label.serialNumber')"
        :title="$t('label.serialNumber')"
        v-model="inputData.serialNumber"
        v-show="inputData.showSerialFlag ? (customData['serialNumber'] ? hideControl['serialNumber'] : inputData.showSerialFlag) : false"
        :required="isSerial"
        params="serialNumber"
        :url="`${winvBaseUrl}app-outbound-sorting/query-serials`"
        :url-params="{ 'waveBillId': waveBillId, }"
        :pre-parse-url="urlData['serialNumber']"
        :custom="customData['serialNumber']"
        :source-parse-field="sourceParseField"
        ref="ref10"
        @click.stop="handleCommonClick(10)"
        @clickIcon="handleCommonClick(10, 'lov')"
        @enter="serialBlur"
        @down="serialBlur"
        @blur="serialBlur"
        @choose="handleChoose('serialNumber', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 物料编码 -->
      <ips-lov
        :label="$t('label.itemCode')"
        :title="$t('label.itemCode')"
        required
        v-model="inputData.itemCode"
        v-show="customData['itemCode'] ? hideControl['itemCode']: true"
        :disabled="isDisabled"
        params="sku"
        display="sku"
        :url="`${winvBaseUrl}app-outbound-sorting/query-goods`"
        :url-params="{ 'waveBillId': waveBillId, 'waveBillTypeId': waveBillTypeId }"
        :pre-parse-url="urlData['itemCode']"
        :custom="customData['itemCode']"
        :source-parse-field="sourceParseField"
        ref="ref20"
        @click.stop="handleCommonClick(20)"
        @clickIcon="handleCommonClick(20, 'lov')"
        @enter="handleGoodsBlur"
        @down="handleGoodsBlur"
        @choose="handleChoose('itemCode', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 数量 -->
      <ips-input-number
        :label="$t('label.quantity')"
        v-model="inputData.quantity"
        v-show="customData['quantity'] ? hideControl['quantity']: true"
        :disabled="isDisabled"
        required
        :min="null"
        :more-than-zero="true"
        :enable-serial="serialType"
        @blur="handleNumberBlur"
        :custom="customData['quantity']"
        :source-parse-field="sourceParseField"
        ref="ref30"
        @enter="inputData.isbatch===0?(inputData.showSerialFlag?skipNext(10):skipNext('submitRef')):skipNext(40)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 货主 -->
      <ips-value-cell
        :title="$t('label.ownerCode')"
        v-show="customData['owner'] ? hideControl['owner']: true"
        image-flag
        :value="orderData.ownerName"
      />
      <!--批次-->
      <ips-lov
        :label="$t('label.batch')"
        :title="$t('label.batch')"
        v-model="inputData.batchCode"
        v-show="inputData.isbatch ? (customData['batchCode'] ? hideControl['batchCode'] : inputData.isbatch) : false"
        :disabled="isDisabled"
        params="batchCode"
        :url="`${winvBaseUrl}app-outbound-sorting/query-batchs`"
        :url-params="{goodsId: inputData.goodsId}"
        :pre-parse-url="urlData['batchCode']"
        :custom="customData['batchCode']"
        :source-parse-field="sourceParseField"
        ref="ref40"
        @click.stop="handleCommonClick(40)"
        @clickIcon="handleCommonClick(40, 'lov')"
        @enter="skipNext('submitRef')"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
    </div>
    <div class="ips-footer">
      <hips-button
        size="large"
        @click.stop="handleCommonSubmit"
        @keyup.enter.native="handleCommonSubmit"
        @keypress.native.prevent
        ref="submitRef"
      >
        <svg-icon slot="icon" icon-class="success" />
        {{ $t('button.submit') }}
      </hips-button>
    </div>
  </hips-view>
</template>

<script>
import { queryGoods, initPage } from '../api';
import { Cell } from "@hips/vue-ui";
import { storage } from '@/utils';
export default {
  name: 'Sort',
  data() {
    return {
      inputData: {
        waveNum: "",
        itemCode: "",
        quantity: null,
        serialId: '',
        batchCode: '',
        serialNumber: '',   //序列号
        isbatch: 0,
        showSerialFlag: true,
        quantityCopy: '',
      },
      serialType: '',
      isSerial: false,
      orderData: {}, //物料数据
      waveBillId: "",
      waveBillTypeId: "",
      isDisabled: false,
      urlData:{},
      customData: {}, // 个性化配置信息列表
      hideControl: {}, // 个性化配置显示隐藏控制
      sourceParseField: {}, // 界面中所有的来源解析字段
    };
  },
  components: {
    [Cell.name]: Cell
  },
  watch:{
    'inputData.waveNum': function(val) {
      if (!val) {
        this.inputData.serialNumber = '';
        this.orderData = '';
        this.serialId='';
        this.isSerial= false;
        this.inputData.itemCode = '';
        this.inputData.goodsId = '';
        this.isDisabled = false;
        this.inputData.isbatch=0;
        this.inputData.batchCode = '';
        this.inputData.quantity = '';
        this.quantityCopy = '';
      }
    },
    'inputData.serialNumber': function(val) {
      if (!val) {
        this.orderData = '';
        this.serialId='';
        this.isSerial= false;
        this.inputData.itemCode = '';
        this.inputData.goodsId = '';
        this.isDisabled = false;
        this.inputData.isbatch=0;
        this.inputData.batchCode = '';
        this.inputData.quantity = this.quantityCopy;
      }
    },
  },
  methods: {
    /**
     *@functionName: saveSourceField
     *@description: 保存来源解析字段并赋值Enter
     *@params1: fieldObj[Object] 来源解析字段对象,
     fieldCode[String] 来源解析字段
     parseValue[String] 二维码解析值
     subFields[Array]] 关联子字段
     */
    saveSourceField(fieldObj) {
      this.saveSourceParseField(this, fieldObj);
    },
    // 字段聚焦时赋值操作
    async valuedByFoucs(options={}) {
      const { custom ={} , currentValue } = options;
      await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
    },
    /**
    * lov选择框，选择数据
    * @param type lov
    * @param obj 选中对象
    */
    handleChoose(type, obj) {
      const site = [this.SERIAL_INV_CTRL, this.SERIAL_SEMI_INV_CTRL, this.SERIAL_OUT_CTRL];
      switch (type) {
        case 'waveNum':
          this.waveBillId = obj.waveBillId;
          this.waveBillTypeId = obj.waveBillTypeId;
          this.handleBlur();
          break;
        case 'serialNumber':
          this.orderData = obj;
          this.serialId = obj.serialId;
          this.inputData.itemCode = obj.sku;
          this.inputData.goodsId = obj.goodsId;
          this.inputData.quantity = 1;
          this.isDisabled = true;
          if(obj.batchCode){
            this.inputData.isbatch=1;
            this.inputData.batchCode=obj.batchCode;
          }
          this.$nextTick(()=>{
            this.skipNext('submitRef');
          });
          break;
        case 'itemCode':
          this.orderData = obj;
          this.inputData.goodsId = obj.goodsId;
          this.inputData.uomId = obj.uomId;
          this.inputData.uomCode = obj.uomCode;
          this.serialType = obj.serialType;
          if(obj.isbatch===1){
            this.inputData.isbatch=1
          }
          if(site.includes(obj.serialType)) {
            this.isSerial= true;
            this.inputData.showSerialFlag = true;
          }
          break;
        default:
          return null;
      }
    },

    handleBlur() {
      this.sourceBillBlur();
    },

    handleGoodsBlur(){
      if(this.isSerial){
        this.skipNext(10)
      }else {
        this.skipNext(30)
      }
    },
    serialBlur(){
      if(this.isSerial && !this.inputData.serialNumber){
        this.skipNext(10)
      }else {
        if(this.inputData.serialNumber){
          this.skipNext('submitRef')
        }else {
          this.skipNext(20)
        }
      }
    },
    handleNumberBlur(){
      if(this.inputData.quantity===0){
        this.$hint.showFailed(this.$i18n.t('message.quantityMin'), true);
        this.$refs['ref30'].focus()
      }
    },

    /**
    * 来源单号失去焦点校验
    */
    async sourceBillBlur() {
      if (this.inputData.waveNum) {
        this.$hint.showLoading();
        try {
          // const url = `${this.winvBaseUrl}app-outbound-sorting/query-goods`;
          let data = {
            params: {
              waveBillId: this.waveBillId,
              waveBillTypeId: this.waveBillTypeId
            }
          };
          this.queryInitNum();
          // const res = await this.$http.get(url, data);
          const res = await queryGoods(data);
          if (res && res.content && res.content.length > 0) {
            this.inputData.waveBillId = res.content[0].waveBillId;
            this.inputData.waveBillTypeId = res.content[0].waveBillTypeId;
            // this.inputData.itemCode = res.content[0].sku;
            this.orderData = res.content[0];
            this.$refs["ref0"].validateLov = true;
            this.skipNext(10);
          } else {
            this.$hint.showFailed(this.$i18n.t('message.waveOrderNull', {label: '波次单号'}));
            this.inputData.waveNum = '';
            this.$refs["ref0"].validateLov = false
          }
        }finally {
          this.$hint.hideLoading();
        }
      }
    },

    /**
    * 提交
    */
    handleButtonSubmit() {
      if (!this.inputData.itemCode || (this.isSerial && !this.inputData.serialNumber)) {
        this.$hint.showFailed(this.$i18n.t('message.checkRequired'));
        return;
      }
      const param = {
        name: "WaveOrderDetail",
        params: {
          waveBillId: this.waveBillId,
          waveBillTypeId: this.waveBillTypeId,
          waveBillCode: this.inputData.waveNum,
          goodsId: this.orderData.goodsId,
          sku: this.orderData.sku,
          sumProcessQty: this.inputData.quantity,
          serialNumber: this.inputData.serialNumber,
          serialId: this.serialId,
          sourceParseField: {...this.sourceParseField},
          customData: this.customData,
          hideControl: this.hideControl,
          uomId: this.inputData.uomId,
          uomCode: this.inputData.uomCode,
        }
      };
      this.$store.commit("pushKeepAlives", "waveOrderDetail");
      this.$router.pushPage(param);
    },

  //  查询初始化数值调用init接口
   async queryInitNum(){
      // const urlInit = `${this.winvBaseUrl}app-outbound-sorting/init-page`;
      const dataGet = {
        params: {waveBillTypeId: this.waveBillTypeId}
      };
    // const res = await this.$http.get(urlInit, dataGet );
     const res = await initPage(dataGet );
        if(res.sumProcessQty && !this.inputData.serialNumber){
          this.inputData.quantity=res.sumProcessQty;
          this.inputData.quantityCopy=res.sumProcessQty;
        }
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'WaveOrderDetail') {
      this.$store.commit('pushKeepAlives', 'Sort');
    } else {
      this.$store.commit('deleteKeepAlives', 'Sort');
    }
    next();
  },
  activated(){
    const formSortSerial = JSON.parse(sessionStorage.getItem('formSortSerial'));
    if(formSortSerial === '1'){
      this.skipNext(0);
      this.inputData.serialNumber = '';
    }
    sessionStorage.removeItem('formSortSerial');
  },
  created() {
    const { customObj, hideControl } = this.handleCustomData(storage.get('customData'));
    this.customData = customObj;
    this.hideControl = hideControl;
    // console.log('二次分拣customData', Object.assign({}, this.customData));
  },
  mounted() {
    this.inputFieldList= [
      { type: 'lov', required: true, ref: 0, bind: 'inputData.waveNum', field: 'waveNum' },
      { type: 'lov', required: false, ref: 10, bind: 'inputData.serialNumber', field: 'serialNumber', isHide: !this.serialType },
      { type: 'lov', required: true, ref: 20 , bind: 'inputData.itemCode', field: 'itemCode' },
      { type: 'number', required: true, ref: 30 , bind: 'inputData.quantity', field: 'quantity' },
      { type: 'text', bind: 'owner', field: 'owner' },
      { type: 'lov', required: false, ref: 40, bind: 'inputData.batchCode', field: 'batchCode', isHide: !this.isbatch },
    ];
    this.urlData = this.handleData('SORT_QUERY');
    this.skipNext(0);
  }
};
</script>

<style lang="stylus" scoped>
 @import '~@/style/var.styl'
</style>
