import request from '@/request';
import mixin from '@/mixin/mixin';

export function getQty(data) {
  let url = `${mixin.data().winvBaseUrl}pda-goods-change/from-info`;
  return request.get(url, data);
}

export function submit(data) {
  let url = `${mixin.data().winvBaseUrl}pda-goods-change/change`;
  return request.post(url, data);
}