import request from '@/request';
import mixin  from '@/mixin/mixin';


// 快递单号
export function validatePack(data) {
  let url = `${mixin.data().winvBaseUrl}app-intercept-tally/validate-pack`;
  return request.post(url, data);
}

// 快递单号
export function interceptConfirm(data) {
  let url = `${mixin.data().winvBaseUrl}app-intercept-tally/confirm`;
  return request.post(url, data);
}
