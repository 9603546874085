var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { margin: "18px 0" } },
    [
      _c(
        "hips-row",
        [
          _c(
            "hips-col",
            { attrs: { span: "14" } },
            [
              _c("ips-field", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showInputBox,
                    expression: "showInputBox",
                  },
                ],
                ref: "ref58",
                attrs: {
                  "is-only": "",
                  "is-index": false,
                  label: _vm.$t("label.serialNumber"),
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleCommonClick(58)
                  },
                  enter: _vm.checkSerialNum,
                  blur: _vm.checkSerialNum,
                },
                model: {
                  value: _vm.serialNum,
                  callback: function ($$v) {
                    _vm.serialNum = $$v
                  },
                  expression: "serialNum",
                },
              }),
            ],
            1
          ),
          _c(
            "hips-col",
            { attrs: { span: "8" } },
            [
              _c(
                "hips-button",
                {
                  staticClass: "ips-new-serial-button-style",
                  attrs: { size: "large" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      _vm.showTable = true
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        查看明细(" +
                      _vm._s(_vm.tableData.length) +
                      ")\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "hips-popup",
        {
          attrs: { position: "bottom" },
          model: {
            value: _vm.showTable,
            callback: function ($$v) {
              _vm.showTable = $$v
            },
            expression: "showTable",
          },
        },
        [
          _c("div", { staticClass: "ips-table" }, [
            _c(
              "table",
              [
                _c(
                  "tr",
                  { staticStyle: { "background-color": "#eee" } },
                  _vm._l(_vm.tableTitle, function (item, index) {
                    return _c(
                      "th",
                      {
                        key: index,
                        staticClass: "ips-table-thead",
                        attrs: { scope: "col" },
                      },
                      [
                        _vm._v(
                          "\n            " + _vm._s(item.name) + "\n          "
                        ),
                      ]
                    )
                  }),
                  0
                ),
                _vm._l(_vm.tableData, function (item, index) {
                  return _c(
                    "tr",
                    {
                      key: index,
                      ref: "tr" + index,
                      refInFor: true,
                      class: [item.selectedFlag ? "back-green" : ""],
                      on: {
                        click: function ($event) {
                          return _vm.handleSelect(item, index)
                        },
                      },
                    },
                    [
                      _c("td", [_vm._v(_vm._s(item.serialNumber))]),
                      _c("td", { staticStyle: { position: "relative" } }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(item.qualityStatusName) +
                            "\n            "
                        ),
                        item.handInputFlag
                          ? _c(
                              "div",
                              {
                                staticClass: "delIcon",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.deleteTableData(index)
                                  },
                                },
                              },
                              [
                                _c("svg-icon", {
                                  attrs: {
                                    slot: "icon",
                                    "icon-class": "delete",
                                  },
                                  slot: "icon",
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]
                  )
                }),
              ],
              2
            ),
          ]),
        ]
      ),
      false
        ? _c(
            "div",
            { staticClass: "ips-footer" },
            [
              _c(
                "hips-button",
                {
                  ref: "submitRef",
                  attrs: { size: "large" },
                  on: { click: _vm.handleButtonSubmit },
                },
                [
                  _c("svg-icon", {
                    attrs: { slot: "icon", "icon-class": "success" },
                    slot: "icon",
                  }),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("button.confirmSNDetail")) +
                      "\n    "
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }