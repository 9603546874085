<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <!-- 直接出库 -->
      <ips-header
        :title="$t('title.multiLpnPack')"
      />
    </div>
    <div class="ips-container">
      <hips-tabs v-model="active">
        <hips-tab :title="$t('label.multiPack')" class="tabTitleClass" :class="[{'active-tab': active===0}]">
          <ips-lov
            :label="$t('label.parentCid')"
            :title="$t('label.parentCid')"
            v-model="inputData.parentCidCode"
            v-show="customData['parentCidCode'] ? hideControl['parentCidCode']: true"
            params="cidCode"
            :url="`${wmdmBaseUrl}containers/parent-cid-lov`"
            :custom="customData['parentCidCode']"
            :source-parse-field="sourceParseField"
            ref="ref0"
            :required="active===0"
            @click.stop="handleCommonClick(0)"
            @clickIcon="handleCommonClick(0, 'lov')"
            @enter="skipNext(10)"
            @down="skipNext(10)"
            @choose="handleChoose('parentCidCode', $event)"
            @setSourceField="saveSourceField($event)"
            @valuedByFoucs="valuedByFoucs($event)"
          />
          <ips-lov
            :label="$t('label.childCid')"
            :title="$t('label.childCid')"
            v-model="inputData.childCidCode"
            v-show="customData['childCidCode'] ? hideControl['childCidCode']: true"
            params="cidCode"
            :url="`${wmdmBaseUrl}containers/child-cid-lov`"
            :url-params="{ 'cidStatus': parentCidStatus, 'parentCidId': parentCidId }"
            :custom="customData['childCidCode']"
            :source-parse-field="sourceParseField"
            ref="ref10"
            :required="active===0"
            @click.stop="handleCommonClick(10)"
            @clickIcon="handleCommonClick(10, 'lov')"
            @enter="skipNext('submitRef')"
            @down="skipNext('submitRef')"
            @choose="handleChoose('childCidCode', $event)"
            @setSourceField="saveSourceField($event)"
            @valuedByFoucs="valuedByFoucs($event)"
          />
          <hips-button
            size="large"
            ref="submitRef"
            @click="handleCommonSubmit"
            class="pack-button-submit-class"
            :class="[{'disable-btn': !inputData.parentCidCode || !inputData.childCidCode || isSubmitFlag}]"
            :disabled="!inputData.parentCidCode || !inputData.childCidCode || isSubmitFlag"
          >
            {{ $t('button.buttonPack') }}
          </hips-button>
          <div style="margin-top: 20px" v-if="childCidObj && childCidObj.length>0">
            <hips-row>
              <hips-col class="child-cid-card-title">
                {{ $t('label.childCid') }}
              </hips-col>
            </hips-row>
            <div v-for="(item, index) in childCidObj" :key="index" class="child-cid-message-card">
              <hips-row>
                <hips-col style="margin-left: 12px">
                  {{ item.cidCode }}
                </hips-col>
              </hips-row>
            </div>
          </div>
        </hips-tab>
        <hips-tab :title="$t('label.multiPackCancel')" class="tabTitleClass" :class="[{'active-tab': active!==0}]">
          <ips-lov
            :label="$t('label.parentCid')"
            :title="$t('label.parentCid')"
            v-model="inputData.parentCidCodeTab"
            v-show="customData['parentCidCodeTab'] ? hideControl['parentCidCodeTab']: true"
            params="cidCode"
            :url="`${wmdmBaseUrl}containers/unpack-parent-cid-lov`"
            :custom="customData['parentCidCodeTab']"
            :source-parse-field="sourceParseField"
            ref="ref20"
            :required="active!==0"
            @click.stop="handleCommonClick(20)"
            @clickIcon="handleCommonClick(20, 'lov')"
            @enter="skipNext(30)"
            @down="skipNext(30)"
            @choose="handleChoose('parentCidCodeTab', $event)"
            @setSourceField="saveSourceField($event)"
            @valuedByFoucs="valuedByFoucs($event)"
          />
          <hips-row>
            <div style="margin-top: 16px">
              <hips-col span="16">
                <ips-lov
                  :label="$t('label.childCid')"
                  :title="$t('label.childCid')"
                  v-model="inputData.childCidCodeTab"
                  v-show="customData['childCidCodeTab'] ? hideControl['childCidCodeTab']: true"
                  params="cidCode"
                  :url="`${wmdmBaseUrl}containers/unpack-child-cid-lov`"
                  :url-params="{ 'cidStatus': parentCidStatusTab, 'parentCidId': parentCidIdTab }"
                  :custom="customData['childCidCodeTab']"
                  :source-parse-field="sourceParseField"
                  ref="ref30"
                  :required="active!==0 && !isAllChange"
                  :disabled="isAllChange"
                  @click.stop="handleCommonClick(30)"
                  @clickIcon="handleCommonClick(30, 'lov')"
                  @enter="skipNext('allCancellation')"
                  @down="skipNext('allCancellation')"
                  @choose="handleChoose('childCidCodeTab', $event)"
                  @setSourceField="saveSourceField($event)"
                  @valuedByFoucs="valuedByFoucs($event)"
                />
              </hips-col>
              <hips-col span="8">
                <hips-checkbox
                  class="checkbox-class"
                  v-model="isAllChange"
                  :label="$t('button.allCheck')"
                />
              </hips-col>
            </div>
          </hips-row>
          <hips-row>
            <hips-col span="10">
              <hips-button
                ref="cancellation"
                v-show="customData['cancellation'] ? hideControl['cancellation']: true"
                @click="handleCommonSubmit('cancellation')"
                class="cancel-button-style-right"
                :class="[{'disable-btn-line': !inputData.parentCidCodeTab || (!inputData.childCidCodeTab && !isAllChange) ||
                  (isAllChange && childCidObjTab.length===0)}]"
                :disabled="!inputData.parentCidCodeTab || (!inputData.childCidCodeTab && !isAllChange) ||
                  (isAllChange && childCidObjTab.length===0)"
              >
                {{ $t('button.cancellation') }}
              </hips-button>
            </hips-col>
            <hips-col span="10">
              <hips-button
                ref="allCancellation"
                @click="handleCommonSubmit('allCancellation')"
                class="cancel-button-style"
                :class="[{'disable-btn': !inputData.parentCidCodeTab || (!inputData.childCidCodeTab && !isAllChange) ||
                  (isAllChange && childCidObjTab.length===0)}]"
                :disabled="!inputData.parentCidCodeTab || (!inputData.childCidCodeTab && !isAllChange) ||
                  (isAllChange && childCidObjTab.length===0)"
              >
                {{ $t('button.allCancellation') }}
              </hips-button>
            </hips-col>
          </hips-row>
          <div style="margin-top: 20px" v-if="childCidObjTab && childCidObjTab.length>0">
            <hips-row>
              <hips-col class="child-cid-card-title">
                {{ $t('label.childCid') }}
              </hips-col>
            </hips-row>
            <div v-for="(item, index) in childCidObjTab" :key="index" class="child-cid-message-card" :class="[{'choose-card': item.cidCode ===chooseObj.cidCode || isAllChange}]">
              <hips-row>
                <hips-col style="margin-left: 12px">
                  {{ item.cidCode }}
                </hips-col>
              </hips-row>
            </div>
          </div>
        </hips-tab>
      </hips-tabs>
    </div>
  </hips-view>
</template>

<script>
  import { Cell, Row, Col, Tabs, Tab, } from "@hips/vue-ui";
  import { storage } from '@/utils';
  import { multiLpnPack, queryChildCid, unpackToCid, unpackToGoods, checkChildCid } from '../api';
  export default {
  name: "MultiLpnPackUnpack",
  data() {
    return {
      active: 0,
      inputData:{
        parentCidCode: '',
        childCidCode: '',
        parentCidCodeTab: '',
        childCidCodeTab: '',
      },
      parentCidId:'',
      parentCidStatus:'',
      parentCidIdCheck:'',
      cidId: '',
      isSubmitFlag: false,
      childCidObj: [],
      parentCidIdTab:'',
      parentCidStatusTab:'',
      cidIdTab: '',
      childCidObjTab: [],
      chooseObj: '',
      isAllChange: false,
      customData: {}, // 个性化配置信息列表
      hideControl: {}, // 个性化配置显示隐藏控制
      sourceParseField: {}, // 界面中所有的来源解析字段
    };
  },
    created() {
      const { customObj, hideControl } = this.handleCustomData(storage.get('customData'));
      this.customData = customObj;
      this.hideControl = hideControl;
      // console.log('多层码垛/撤销customData', Object.assign({}, this.customData));
    },
    mounted() {
      this.inputFieldList = [
        {type: 'lov', required: false, ref: 0, bind: 'inputData.parentCidCode', field: 'parentCidCode' },
        {type: 'lov', required: false, ref: 10, bind: 'inputData.childCidCode', field: 'childCidCode' },
        {type: 'lov', required: false, ref: 20, bind: 'inputData.parentCidCodeTab', field: 'parentCidCodeTab' },
        {type: 'lov', required: false, ref: 30, bind: 'inputData.childCidCodeTab', field: 'childCidCodeTab' },
      ];
      this.skipNext(0);
    },
  watch: {
    'inputData.parentCidCode': function(val) {
      if (!val) {
        this.inputData.childCidCode='';
        this.cidId= '';
        this.parentCidId='';
        this.parentCidStatus='';
        this.childCidObj= [];
      }
    },
    'inputData.parentCidCodeTab': function(val) {
      if (!val) {
        this.inputData.childCidCodeTab='';
        this.cidIdTab= '';
        this.parentCidIdTab='';
        this.parentCidStatusTab='';
        this.chooseObj='';
        this.childCidObjTab= [];
      }
    },
    'active':function(val) {
      if(val===0){
        this.$nextTick(() => {
          this.skipNext(0);
        });
      }else {
        this.$nextTick(() => {
          this.skipNext(20);
        });
      }
    }
  },
  components: {
    [Cell.name]: Cell,
    [Row.name]: Row,
    [Col.name]: Col,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
  },
  methods: {
    /**
     *@functionName: saveSourceField
     *@description: 保存来源解析字段并赋值Enter
     *@params1: fieldObj[Object] 来源解析字段对象,
     */
    saveSourceField(fieldObj) {
      this.saveSourceParseField(this, fieldObj);
    },
    // 字段聚焦时赋值操作
    async valuedByFoucs(options={}) {
      const { custom ={} , currentValue } = options;
      await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
    },
    // lov选择框，选择数据
    handleChoose(type, obj) {
      switch (type) {
        case 'parentCidCode':
          this.parentCidId = obj.cidId;
          this.parentCidStatus = obj.cidStatus;
          this.handleQueryCidData();
          break;
        case 'childCidCode':
          this.cidId = obj.id;
          this.parentCidIdCheck = obj.parentCidId;
          this.checkCidCode();
          break;
        case 'parentCidCodeTab':
          this.parentCidIdTab = obj.cidId;
          this.parentCidStatusTab = obj.cidStatus;
          this.handleQueryCidData();
          break;
        case 'childCidCodeTab':
          this.cidIdTab = obj.cidId;
          this.chooseObj = obj;
          break;
        default:
          return null;
      }
    },
    // 查询父托盘下子托盘数据
    async handleQueryCidData(){
      let data={
        params:{
          cidId: this.active===0?this.parentCidId:this.parentCidIdTab,
        }
      };
     const res = await queryChildCid(data);
     if(this.active === 0){
       this.childCidObj = res;
     }else {
       this.childCidObjTab = res;
     }
    },
    async checkCidCode(){
      let params ={
        cidStatus: this.parentCidStatus,
        parentCidId: this.parentCidId,
        cidId: this.cidId,
      };
      try {
        this.$hint.showLoading()
        const res =await checkChildCid(params);
        if(res){
          if(this.parentCidIdCheck === this.parentCidId){
            this.$hint.showFailed(this.$i18n.t('message.checkParentCid'), true);
            this.isSubmitFlag = true;
            return
          }
          this.isSubmitFlag = false;
        }
      }catch (e) {
        this.isSubmitFlag = true;
        this.$hint.hideLoading()
      }finally {
        this.$hint.hideLoading()
      }


    },
    // 数据提交
    async handleButtonSubmit(type) {
      if(!this.isSubmitFlag){
        if(this.active===0){
          try {
            this.$hint.showLoading();
            this.handleSubmitStatus();
            await multiLpnPack({
              parentCidId: this.parentCidId,
              parentCidCode: this.inputData.parentCidCode,
              cidId: this.cidId,
              cidCode: this.inputData.childCidCode,
            });
            await this.clearSourceFields(this, true);
            this.$hint.showSuccess();
            this.$hint.hideLoading();
            this.handleSubmitStatus();
            this.handleQueryCidData();
            this.inputData.childCidCode='';
            this.cidId= '';
          }catch (e) {
            this.$hint.hideLoading();
            this.handleSubmitStatus();
            console.log(e)
          }
        }else {
          if(type==='cancellation'){
            await unpackToGoods({
              parentCidId: this.parentCidIdTab,
              parentCidCode: this.inputData.parentCidCodeTab,
              cidId: this.cidIdTab,
              cidCode: this.inputData.childCidCodeTab,
              containerList:this.isAllChange?this.childCidObjTab:[],
            });
            if(this.isAllChange){
              this.inputData.parentCidCodeTab='';
              this.parentCidIdTab = '';
              this.parentCidStatusTab = '';
              this.inputData.childCidCodeTab='';
              this.cidIdTab= '';
              this.chooseObj= '';
              this.childCidObjTab= [];
            }else{
              this.inputData.childCidCodeTab='';
              this.cidIdTab= '';
              this.chooseObj= '';
              this.handleQueryCidData();
            }
            await this.clearSourceFields(this, true);
            this.$hint.showSuccess();
          }else {
            await unpackToCid({
              parentCidId: this.parentCidIdTab,
              parentCidCode: this.inputData.parentCidCodeTab,
              cidId: this.cidIdTab,
              cidCode: this.inputData.childCidCodeTab,
              containerList:this.isAllChange?this.childCidObjTab:[],
            });
            if(this.isAllChange){
              this.inputData.parentCidCodeTab='';
              this.parentCidIdTab = '';
              this.parentCidStatusTab = '';
              this.inputData.childCidCodeTab='';
              this.cidIdTab= '';
              this.chooseObj= '';
              this.childCidObjTab= [];
            }else{
              this.inputData.childCidCodeTab='';
              this.cidIdTab= '';
              this.chooseObj= '';
              this.handleQueryCidData();
            }
            await this.clearSourceFields(this, true);
            this.$hint.showSuccess();
          }
        }

      }
    },
    // 校验必输
    // checkRequired(type){
    //   if(this.active===0){
    //     if(type === 'parentCidCode' && !this.inputData.parentCidCode){
    //       this.$nextTick(() => {
    //         this.skipNext(0);
    //       });
    //     }
    //   }else {
    //     if(type === 'parentCidCodeTab' && !this.inputData.parentCidCodeTab){
    //       this.$nextTick(() => {
    //         this.skipNext(20);
    //       });
    //     }
    //   }

    // },
  },
  activated() {
  },
};
</script>

<style lang="stylus" scoped>
  @import '~@/style/var.styl'
  .checkbox-class{
    text-align right
  }
  .tabTitleClass{
    font-size: 14px!important;
  }
  .active-tab {
    font-size: 16px!important;
    font-weight: bold!important;
    color: var(--primaryColor) !important;
  }
  .pack-button-submit-class {
    width: 100%;
    margin-top: 32px;
    background-color: var(--primaryColor) ;
    color: #FFFFFF;
    font-size: 18px;
    height: 44px;
    line-height: 36px;
  }
  .disable-btn {
    background-color: #BFBFBF!important;
  }
  .disable-btn-line {
    color: #BFBFBF!important;
    border:1px solid #BFBFBF!important;
  }
  .disable-btn-two {
    background-color: #BFBFBF!important;
  }
  .child-cid-card-title{
    width:56px;
    height:18px;
    font-size:14px;
    font-weight:400;
    color:rgba(191,191,191,1);
    line-height:18px;
  }
  .child-cid-message-card{
    background: #fff;
    margin-bottom: 5px;
    border-radius: 1.6vw;
    margin-top: 8px;
    width: 99%;
    padding: 2.133vw 0px;
    color: #000;
    font-size: 3.467vw;
    box-shadow: 0 0 2.133vw #d9d9d9;
    border 1px solid transparent;

  }
  .cancel-button-style{
    margin-top 20px;
    width:160px;
    height:44px;
    border-radius:4px;
    font-size:18px;
    color:  rgba(64,169,255,1);
    margin-left 30px;
    border:1px solid rgba(64,169,255,1);
  }
  .cancel-button-style-right{
    margin-top 20px;
    width:160px;
    height:44px;
    font-size:18px;
    border-radius:4px;
    background-color: var(--primaryColor);
    color: #FFFFFF;
  }
  .choose-card{
      background-color : #F2FBFF;
      border 1px solid #40A9FF;
  }
</style>
