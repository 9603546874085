var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: { title: _vm.$t("title.outSundry"), "is-index": false },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "header-row" }, [
      _c("div", { staticClass: "header-title" }, [
        _vm._v("\n      " + _vm._s(_vm.cidData.cidCode) + "\n    "),
      ]),
      _c(
        "div",
        {
          class: {
            "header-0-status": _vm.cidData.inStockStatus === "EMPTY",
            "header-1-status": _vm.cidData.inStockStatus === "RECEIVING",
            "header-2-status": _vm.cidData.inStockStatus === "IN_STOCK",
            "header-3-status": _vm.cidData.inStockStatus === "PICKED",
            "header-4-status": _vm.cidData.inStockStatus === "LOADED",
          },
        },
        [_vm._v("\n      " + _vm._s(_vm.cidData.inStockStatusName) + "\n    ")]
      ),
    ]),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _vm.cidDetailData.length <= 0 ? _c("ips-no-data") : _vm._e(),
        _vm.cidDetailData.length > 0
          ? _c(
              "div",
              _vm._l(_vm.cidDetailData, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "card_countLocation" },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "row-50" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("label.sku")) +
                              ":\n\n                 \n            "
                          ),
                        ]),
                        _c("div", { staticClass: "value" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.sku) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "row-50" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("label.unit")) +
                              ":\n            "
                          ),
                        ]),
                        _c("div", { staticClass: "label" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.uomName) +
                              "\n            "
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "row-50" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("label.batch")) +
                              ":\n            "
                          ),
                        ]),
                        _c("div", { staticClass: "label" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.batchCode || "--") +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "row-50" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("label.tray")) +
                              ":\n            "
                          ),
                        ]),
                        _c("div", { staticClass: "label" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.cidCode || "--") +
                              "\n            "
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "row-50" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("label.availableQty")) +
                              ":\n            "
                          ),
                        ]),
                        _c("div", { staticClass: "label-number" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.qty) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "row-50" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("label.currentUseQty")) +
                              ":\n            "
                          ),
                        ]),
                        _c("div", { staticClass: "label-number" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.validQty) +
                              "\n            "
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("label.goodsName")) +
                            ":\n          "
                        ),
                      ]),
                      _c("div", { staticClass: "value" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(item.goodsName) +
                            "\n          "
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "row" },
                      [
                        !(
                          item.serialType === _vm.SERIAL_INV_CTRL ||
                          item.serialType === _vm.SERIAL_OUT_CTRL ||
                          item.serialType === _vm.SERIAL_SEMI_INV_CTRL
                        )
                          ? _c("ips-input-number", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                disabled: _vm.transferType === "FULL_PALLET",
                                required: "",
                                label: _vm.$t("label.quantity"),
                              },
                              on: {
                                enter: function ($event) {
                                  return _vm.skipNext("submitRef")
                                },
                                down: function ($event) {
                                  return _vm.skipNext("submitRef")
                                },
                                tab: function ($event) {
                                  return _vm.skipNext("submitRef")
                                },
                                blur: function ($event) {
                                  return _vm.checkQtyBlur(item)
                                },
                              },
                              model: {
                                value: item.transferQty,
                                callback: function ($$v) {
                                  _vm.$set(item, "transferQty", $$v)
                                },
                                expression: "item.transferQty",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    item.serialType === _vm.SERIAL_INV_CTRL ||
                    item.serialType === _vm.SERIAL_OUT_CTRL ||
                    item.serialType === _vm.SERIAL_SEMI_INV_CTRL
                      ? _c("hips-row", [
                          _c(
                            "div",
                            { staticClass: "hip-row" },
                            [
                              _c(
                                "hips-col",
                                { attrs: { span: "16" } },
                                [
                                  _c("ips-input-number", {
                                    ref: "ref0",
                                    refInFor: true,
                                    attrs: {
                                      disabled:
                                        _vm.transferType === "FULL_PALLET",
                                      required: "",
                                      label: _vm.$t("label.quantity"),
                                    },
                                    on: {
                                      enter: function ($event) {
                                        return _vm.chooseSkipNext(item)
                                      },
                                      down: function ($event) {
                                        return _vm.chooseSkipNext(item)
                                      },
                                      tab: function ($event) {
                                        return _vm.chooseSkipNext(item)
                                      },
                                      blur: function ($event) {
                                        return _vm.checkQtyBlur(item)
                                      },
                                    },
                                    model: {
                                      value: item.transferQty,
                                      callback: function ($$v) {
                                        _vm.$set(item, "transferQty", $$v)
                                      },
                                      expression: "item.transferQty",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "hips-col",
                                { attrs: { span: "8" } },
                                [
                                  _c(
                                    "hips-button",
                                    {
                                      ref: "refSerail",
                                      refInFor: true,
                                      staticClass: "button-detail",
                                      attrs: { size: "large" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.goToSerialDetail(item)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t("button.serialNumber")
                                          ) +
                                          "(" +
                                          _vm._s(
                                            item.serialStockProcessDtoList
                                              ? item.serialStockProcessDtoList
                                                  .length
                                              : 0
                                          ) +
                                          ")\n              "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-footer" },
      [
        _c(
          "hips-button",
          {
            ref: "submitRef",
            class: ["ips-btn-normal"],
            attrs: { size: "large" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.goBack()
              },
              enter: function ($event) {
                return _vm.goBack()
              },
            },
          },
          [
            _c("svg-icon", {
              attrs: { slot: "icon", "icon-class": "success" },
              slot: "icon",
            }),
            _vm._v("\n      " + _vm._s(_vm.$t("button.confirm")) + "\n    "),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }