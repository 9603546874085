<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header
        :title="$t('title.acceptConfirm')"
        :is-index="false"
        :trigger="$t('title.detail')"
        :on-go-detail="goDetail"
      />
    </div>
    <div class="ips-container">
      <!-- 收货库位 -->
      <ips-lov
        :label="$t('label.location')"
        required
        v-model="inputData.locationCode"
        :title="[$t('label.locationCode'), $t('label.whareaCode'), $t('label.whareaName')]"
        display="locationCode"
        :params="['locationCode', 'whareaCode', 'whareaName']"
        :url="`${wmdmBaseUrl}wms-locations/app`"
        :url-params="{ warehouseId, locationType: 'T_RCV', whareaIdsString,
                       'whareaId':whareaIdsString?whareaIdSku: null,
                       locationCode: inputData.locationCode }"
        :pre-parse-url="urlData['locationCode']"
        ref="ref0"
        :custom="customData['locationCode']"
        :source-parse-field="sourceParseField"
        v-show="customData['locationCode'] ? hideControl['locationCode'] : true"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
        @click.stop="handleCommonClick(0)"
        @clickIcon="handleCommonClick(0, 'lov')"
        @enter="handleLocationEnter"
        @down="handleLocationEnter"
        @choose="chooseLocation($event)"
      />
      <!-- 父托盘 -->
      <ips-field
        v-model="inputData.parentCidCode"
        params="parentCidCode"
        display="parentCidCode"
        :pre-parse-url="urlData['parentCidCode']"
        :label="$t('label.parentTray')"
        ref="ref5"
        :custom="customData['parentCidCode']"
        :source-parse-field="sourceParseField"
        v-show="!parentCidCodeShow ? false : containerManageFlag ? (customData['parentCidCode'] ? hideControl['parentCidCode'] : containerManageFlag) : false"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
        @blur="handleBlur('parentCidCode')"
        @enter="isPrePackage ? skipNext('submitRef'): skipNext(10)"
        @down="isPrePackage ? skipNext('submitRef'): skipNext(10)"
        @click.stop="handleCommonClick(5)"
      />
      <!-- 托盘 -->
      <ips-field
        v-model="inputData.cidCode"
        params="cidCode"
        display="cidCode"
        :pre-parse-url="urlData['cidCode']"
        :required=" inputData.parentCidCode ? true : !cidCodeRequired"
        :label="$t('label.tray')"
        ref="ref10"
        :custom="customData['cidCode']"
        :source-parse-field="sourceParseField"
        v-show="isPrepackParentCid ? false : containerManageFlag ? (customData['cidCode'] ? hideControl['cidCode'] : containerManageFlag) : false"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
        @blur="handleBlur('cidCode')"
        @enter="issku ? skipNext(20) : (isbatch ? skipNext(30): skipNext(50))"
        @down="issku ? skipNext(20) : (isbatch ? skipNext(30): skipNext(50))"
        @click.stop="handleCommonClick(10)"
      />
      <!-- 物料 -->
      <ips-lov
        :label="$t('label.sku')"
        :required="issku"
        v-model="inputData.sku"
        :title="[$t('label.lineNum'), $t('label.sku'), $t('label.goodsName')]"
        :params="['lineNum', 'sku', 'goodsName']"
        display="sku"
        :url="`${winvBaseUrl}app-inbound/query-inbound-lines-info`"
        :pre-parse-title="[$t('label.sku'), $t('label.goodsName')]"
        :url-params="{ billCode, 'cidCode': inputData.cidCode, warehouseId, billId }"
        :pre-parse-url="urlData['sku']"
        ref="ref20"
        :disabled="isPrePackage"
        :custom="customData['sku']"
        :source-parse-field="sourceParseField"
        v-show="isPrepackParentCid ? false : issku ? (customData['sku'] ? hideControl['sku'] : issku) : false"
        @click.stop="handleCommonClick(20)"
        @clickIcon="handleCommonClick(20, 'lov')"
        @choose="chooseSku($event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <div v-if="inputData.sku">
        <ips-page-card
          :react-field="['uom', 'orderLine']"
          :item="inputData"
        >
          <ips-message-tip :message="inputData.goods" />
        </ips-page-card>
      </div>

      <!-- 批次 -->
      <ips-field
        v-model="inputData.batchCode"
        required
        :label="$t('label.batch')"
        params="batchCode"
        display="batchCode"
        :pre-parse-url="urlData['batchCode']"
        ref="ref30"
        :disabled="batchDisabled"
        :custom="customData['batchCode']"
        :source-parse-field="sourceParseField"
        v-show="isbatch ? (customData['batchCode'] ? hideControl['batchCode'] : isbatch) : false"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
        @blur="handleBlur('batch')"
        @enter="handleBatchEnter()"
        @down="handleBatchEnter()"
        @click.stop="handleCommonClick(30)"
      />
      <!-- 序列号 -->
      <!-- <ips-field
        v-model="inputData.serialNumber"
        required
        :disabled="isPrePackage"
        :label="$t('label.serialNumber')"
        ref="ref40"
        @blur="handleSerialBlur"
        @enter="skipNext('submitRef')"
        v-show="isserial"
        @click.stop="handleCommonClick(40)"
      /> -->
      <!-- 收货数量 -->

      <hips-row>
        <div
          style="margin-top: 16px"
          v-if="(prepackagedData && (['INV_CTRL', 'IN_CTRL', 'SEMI_INV_CTRL'].includes(serialType)) || showCidDetail || showSeriesDetail) || isPrepackParentCid"
        >
          <hips-col
            span="14"
            style="margin-bottom: 16px"
          >
            <ips-input-number
              v-model="inputData.receiveQty"
              required
              :label="$t('label.receiveNum')"
              ref="ref50"
              thousands-formatter
              not-auto-fill
              :custom="customData['receiveQty']"
              :source-parse-field="sourceParseField"
              :enable-serial="serialType"
              v-show="customData['receiveQty'] ? hideControl['receiveQty'] : true"
              @setSourceField="saveSourceField($event)"
              @valuedByFoucs="valuedByFoucs($event)"
              @enter="showSeriesDetail ? skipSerail() : skipNext('submitRef')"
              @down="showSeriesDetail ? skipSerail() : skipNext('submitRef')"
              @click.stop="handleCommonClick(50)"
              :disabled="readOnly || isPrePackage"
              more-than-zero
            />
          </hips-col>
          <hips-col span="10">
            <!-- 托盘明细 -->
            <hips-button
              size="large"
              @click.stop="goToCidDetail"
              ref="refCid"
              v-show="customData['showCidDetail'] ? hideControl['showCidDetail'] : showCidDetail"
              style="margin-left: 10px; background-color: var(--primaryColor); color: #FFFFFF; font-size:
                          14px; height: 36px;line-height: 36px;"
            >
              {{ $t('button.cidDetail') }}
            </hips-button>
            <!-- 预打包托盘明细 -->
            <hips-button
              size="large"
              @click.stop="goToPrePackCidDetail"
              ref="refCid"
              v-show="customData['showPrePackCidDetail'] ? hideControl['showPrePackCidDetail'] : isPrepackParentCid"
              style="margin-left: 10px; background-color: var(--primaryColor); color: #FFFFFF; font-size:
                          14px; height: 36px;line-height: 36px;"
            >
              {{ $t('button.prePackCidDetail') }}
            </hips-button>
            <!-- 序列号明细 -->
            <!-- <hips-button
              type="warning"
              :disabled="!inputData.receiveQty"
              size="large"
              v-show="showSeriesDetail ? (customData['showSeriesDetail'] ? hideControl['showSeriesDetail'] : showSeriesDetail) : false"
              @click.stop="goToSerialDetail"
              :class="[{'disable-btn': !inputData.receiveQty}]"
              @enter="handleSeriesBtn()"
              ref="refSerail"
              style="margin-left: 10px; color: #FFFFFF; font-size:
                          14px; height: 36px;line-height: 36px;"
            >
              {{ inboundSerialReceiptList.length > 0 ? (`${$t('button.seriesDetail')}(${inboundSerialReceiptList.length})`) : ($t('button.seriesDetail')) }}
            </hips-button> -->
          </hips-col>
        </div>

        <!-- 序列号明细 -->
        <serialComp
          v-show="showSeriesDetail ? (customData['showSeriesDetail'] ? hideControl['showSeriesDetail'] : showSeriesDetail) : false"
          ref="refSerail"
          v-model="inputData.receiveQty"
          :params="serialCompParams"
          @inboundSerialReceiptList="changeInboundSerialReceiptList"
        />
      </hips-row>

      <hips-row>
        <div
          style="margin-top: 16px"
          v-if="!['INV_CTRL', 'IN_CTRL', 'SEMI_INV_CTRL'].includes(serialType) && !showSeriesDetail && !showCidDetail && !isPrepackParentCid"
        >
          <hips-col span="24">
            <ips-input-number
              v-model="inputData.receiveQty"
              required
              :label="$t('label.receiveNum')"
              ref="ref50"
              thousands-formatter
              @enter="skipNext('submitRef')"
              @down="skipNext('submitRef')"
              @click.stop="handleCommonClick(50)"
              :enable-serial="serialType"
              :disabled="readOnly || isPrePackage"
              :custom="customData['receiveQty']"
              :source-parse-field="sourceParseField"
              v-show="isPrepackParentCid ? false : customData['receiveQty'] ? hideControl['receiveQty'] : true"
              @setSourceField="saveSourceField($event)"
              @valuedByFoucs="valuedByFoucs($event)"
              not-auto-fill
              more-than-zero
            />
          </hips-col>
        </div>
      </hips-row>
      <div
        v-if="inputData.sku"
        class="count_block_box"
      >
        <!-- 需求数量 -->
        <ips-number-count
          :count="inputData.planQty"
          text-field="planQty"
          background-color="#E6F7FF"
          border-color="#91D5FF"
          font-color="var(--primaryColor)"
        />
        <!-- 已收货数量 -->
        <ips-number-count
          :count="inputData.receivedQty"
          text-field="receivedQty"
          background-color="#F6FFED"
          border-color="#B7EB8F"
          font-color="#52C41A"
        />
        <!-- 待收货数量 -->
        <ips-number-count
          :count="inputData.unreceiveQty"
          text-field="unReceiveQty"
          background-color="#FFF4E6"
          border-color="#FFD382"
          font-color="#FF8F07"
        />
      </div>

    </div>
    <div class="ips-footer">
      <hips-button
        type="warning"
        size="large"
        ref="submitRef"
        class="ips-btn-normal"
        @keyup.enter.native="handleCommonSubmit"
        @keypress.native.prevent
        @click.stop="handleCommonSubmit"
      >
        <svg-icon
          slot="icon"
          icon-class="success"
        />
        {{ $t('button.confirm') }}
      </hips-button>
    </div>
  </hips-view>
</template>

<script>
// const SERIALTYPELIST = ['INV_CTRL', 'IN_CTRL', 'SEMI_INV_CTRL'];
import {
  checkCidBlur,
  checkCid,
  checkQty,
  serialReceive,
  queryReceiptInfo,
  checkBatchs,
  submitReceive,
  queryInboundLines,
  queryPageFunction,
  checkCidCode,
  checkCidNull,
  // successCheckItem,
  checkParentCidCode,
  checkParentCidAndCid,
  queryPrePackCidDetail,
} from "../api";
import serialComp from '@/public/views/ipsNewSerialComp'

export default {
  name: 'ReceiveConfirm',
  components: {
    serialComp
  },
  data() {
    return {
      inputData: {
        goods: '',
        uom: '',
        locationCode: '',
        sku: '',
        orderLine: '',
        batchCode: '',
        cidCode: '',
        parentCidCode: '',
        receiveQty: undefined,
        planQty: '',
        plantQty: '', //托盘数量
        receivedQty: '',
        unreceiveQty: '',
        goodsId: '',
        ownerId: '',
        serialNumber: '',
      }, //输入框数据
      parentCidId: '',
      billId: '', //入库单号
      billCode: '',
      isbatch: false, //批次是否必输 隐藏
      isserial: false,
      issku: true, // 物料是否必输、隐藏
      showCidDetail: false, // 显示托盘明细按钮
      showSeriesDetail: false, // 显示序列号明细按钮
      needWarehouseId: false,
      warehouseId: '',
      // warehouseCode: '',
      whareaIdsString: '',
      warehouseIdStr: '',
      whareaIdSku: '',
      detailId: '',
      receiptId: '',
      prePackReceivedQty: '', // 预打包接口返回数量
      serialType: '', //是否启用序列号控制
      readOnly: false, //输入数量是否可以更改
      isPrePackage: false, // 是否为预打包
      isPrepackParentCid: false, // 判断父托盘是否是预打包
      skuInfo: {},
      locatioInfo: {},
      disabledFlag: true, // 批次是否禁用元素之一
      // batchFlag: false, // 是否通过了批次验证
      pageFunction: {}, // 页面功能参数配置信息
      containerManageFlag: 1, // 是否开始托盘管理, 默认是
      parentCidCodeShow: 0, // 页面功能参数控制是否显示父托盘
      cidCodeRequired: '', // 页面功能参数控制托盘是否必输
      batchControl: '', //是否允许自动创建批次
      prepackagedData: [],
      inboundSerialReceiptList: [], // 序列号明细list
      definedInboundSerialReceiptList: [], // 预定义序列号
      inboundReceiptRecordPackList: [], // 预打包托盘明细
      urlData: {},
      customData: {}, // 个性化配置信息列表
      hideControl: {}, // 非必输字段的显隐控制
      sourceParseField: {}, // 界面中所有的来源解析字段
      serialCompParams: {},

    };
  },

  computed: {
    // 批次是否禁用
    batchDisabled: function () {
      return this.isPrePackage && this.disabledFlag;
    }
  },

  methods: {
    skipSerail() {
      if (this.inputData.receiveQty > 0) {
        this.goToSerialDetail()
        this.$nextTick(() => {
          this.$refs.refSerail.$refs['ref0'].focus()
        })
      }
    },

    changeInboundSerialReceiptList(list) {
      this.inboundSerialReceiptList = list
    },

    /** 
    * 前往明细
    */
    goDetail() {
      let param = {
        name: 'ReceiveDetail',
        params: {
          billId: this.billId,
          billCode: this.billCode,
        },
      };
      this.$router.pushPage(param);
    },


    /**
    * 物料校验通过后往下走
    */
    handleSkipNext() {
      if (this.whareaIdsString && this.whareaIdSku && !this.inputData.locationCode) {
        this.skipNext(0)
      } else {
        if (this.isbatch) {
          this.$nextTick(() => {
            this.skipNext(30);
          });
          return;
        } else if (!this.isbatch && this.isserial) {
          this.$nextTick(() => {
            this.skipNext(50);
          });
          return;
        }
        this.$nextTick(() => {
          this.skipNext(50);
        });
      }
    },

    handleLocationEnter() {
      if (this.containerManageFlag) {
        if (this.parentCidCodeShow) {
          this.skipNext(5);
        } else {
          this.skipNext(10);
        }
      } else {
        this.skipNext(20);
      }
    },

    /**
    * 数据初始化
    */
    getDataFromParams() {
      const { billId, billCode, warehouseCode, receiptNumber, whareaIdsString, warehouseId, warehouseIdStr, sourceParseField, customData, hideControl, batchControl } = this.$route.params;
      if (billId) {
        this.billId = billId;
        this.billCode = billCode;
        // this.warehouseId = warehouseId;
        this.warehouseCode = warehouseCode;
        this.receiptNumber = receiptNumber;
        this.whareaIdsString = whareaIdsString;
        this.warehouseId = warehouseId;
        this.warehouseIdStr = warehouseIdStr;
        this.batchControl = batchControl;
      }
      this.sourceParseField = sourceParseField;
      this.customData = customData;
      this.hideControl = hideControl;
    },
    // 保存来源解析字段
    async saveSourceField(fieldObj) {
      this.saveSourceParseField(this, fieldObj);
    },
    // 字段聚焦时赋值操作
    async valuedByFoucs(options = {}) {
      const { custom = {}, currentValue } = options;
      await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
    },
    handleSeriesBtn() {
      if (this.prepackagedData.length > 0) {
        this.skipNext('refSerail')
      } else {
        this.skipNext('submitRef')
      }
    },

    handleReceiveQtyEnter() {
      this.showSeriesDetail ? this.skipNext('refSerail') : this.skipNext('submitRef')
    },

    /**
    * 序列号失焦触发
    */
    async handleSerialBlur() {
      if (this.inputData.serialNumber) {
        // const SerialUrl = `${this.winvBaseUrl}app-inbound/serial-receive`;
        let SerialData = {
          cidCode: this.inputData.cidCode,
          billId: this.billId,
          billCode: this.billCode,
          locationId: this.locationId,
          locationCode: this.inputData.locationCode,
          sku: this.inputData.sku,
          goodsId: this.inputData.goodsId,
          batchCode: this.inputData.batchCode,
          serialType: this.serialType,
          serialNumber: this.inputData.serialNumber,
          detailId: this.detailId,
          id: this.receiptId,
        };
        if (this.receiptId) {
          SerialData.id = this.receiptId;
        }
        this.$hint.showLoading();
        try {
          // const res = await this.$http.post(SerialUrl, SerialData);
          const res = await serialReceive(SerialData);
          this.$hint.hideLoading();
          if (res && !res.failed) {
            const { planQty, receivedQty, receiveQty } = this.inputData;
            this.receiptId = res.id;
            // console.log(planQty === receivedQty + parseInt(receiveQty), planQty, receivedQty)
            if (planQty === receivedQty + parseInt(receiveQty)) {
              this.$hint.showSuccess(this.$i18n.t('message.operationSuccess'));
              const res = await checkQty(this.billId);
              if (res && res.content && res.content.length > 0) {
                const { planQty, receiveQty } = res.content[0]
                if (planQty <= receiveQty) {
                  this.$router.backPage();
                } else {
                  this.dataInit();
                  this.$nextTick(() => {
                    this.skipNext(10);
                  })
                }
              }
            } else {
              this.$hint.showSuccess(this.$i18n.t('message.operationSuccess'));
              this.inputData.serialNumber = '';
              this.inputData.receivedQty++; // 已收货加一
              this.inputData.unreceiveQty--; // 待收货减一
              this.$nextTick(() => {
                this.skipNext(40);
              })
            }
          }
        } catch (e) {
          this.inputData.serialNumber = ''
          this.skipNext(40);
        } finally {
          this.$hint.hideLoading();
        }
      }
    },

    /**
     * 确认创建批次
    **/
    confirmCreateBatch() {
      const { batchCode, ownerName, ownerId, ownerCode, orgId, goodsId, orgName, orgCode, sku } = this.inputData;
      const urlParam = {
        name: 'createBatch',
        params: {
          skuInfo: {
            sku,
            ownerName,
            ownerId,
            ownerCode,
            orgName,
            orgId,
            orgCode,
            goodsId,
            ...this.skuInfo,
          },
          batchCode: batchCode,
        },
      };
      this.$router.pushPage(urlParam);
    },

    /**
     * 取消创建批次
    **/
    cancelCreateBatch() {
      this.disabledFlag = false;
      this.$nextTick(() => {
        this.skipNext(30);
      })
    },

    async checkCidAndParentCid(data) {
      const res = await checkParentCidAndCid(data);
      if (res && res.failed && res.message) {
        this.$hint.showFailed(res.message, true);
        return false;
      }
      return true;
    },

    /**
    * 失焦触发
    * @param flag dom类型标志
    */
    async handleBlur(flag) {
      try {
        // const url = `${this.winvBaseUrl}inbound-receipt-records/check-cid`;
        // const cidUrl = `${this.winvBaseUrl}app-inbound/query-receipt-info`;
        this.cidData = {
          params: {
            cidCode: this.inputData.cidCode,
            billId: this.billId,
            status: 'PREPACKAGED',
          },
        };
        this.receiptId = '';
        let data = {
          cidCode: this.inputData.cidCode,
          billId: this.billId,
          billCode: this.billCode,
          locationId: this.locationId,
          locationCode: this.inputData.locationCode
        };
        const cidDateParams = this.cidData.params;
        if (flag === 'parentCidCode' && this.inputData.parentCidCode) {
          this.$hint.showLoading();
          let checkParentCidData = {
            billId: this.billId,
            billCode: this.billCode,
            sku: this.inputData.sku,
            locationId: this.locationId,
            locationCode: this.inputData.locationCode,
            cidCode: this.inputData.parentCidCode,
            checkPermissions: true,
            batchCode: this.inputData.batchCode,
            fromPDAFlag: true,
          };
          const res = await checkParentCidCode(checkParentCidData);
          if (res && res.failed && res.message) {
            this.$hint.showFailed(res.message, true);
            return;
          } else if (res && res.isPrepackParentCid) {
            this.isPrepackParentCid = true;
            this.isPrePackage = true;
            this.inputData.receiveQty = res.receivedQty;
            this.prePackReceivedQty = res.receivedQty;
            let getCidDetailData = {
              params: {
                status: 'PREPACKAGED',
                parentCidCode: this.inputData.parentCidCode,
                billId: this.billId,
              },
            };
            const dataList = await queryPrePackCidDetail(getCidDetailData);
            if (dataList && dataList.content.length > 0) {
              this.inboundReceiptRecordPackList = dataList.content;
            }
            this.$nextTick(() => {
              this.skipNext('submitRef');
            });
          } else {
            this.isPrepackParentCid = false;
            this.isPrePackage = false;
            this.inboundReceiptRecordPackList = [];
          }
          if (this.inputData.cidCode) {
            let checkParentCidData = {
              billId: this.billId,
              locationId: this.locationId,
              locationCode: this.inputData.locationCode,
              cidCode: this.inputData.parentCidCode,
              checkPermissions: true,
              billCode: this.billCode,
              fromPDAFlag: true,
            };
            this.checkCidAndParentCid(checkParentCidData);
          }
        } else if (flag === 'cidCode') {
          if (this.inputData.cidCode) {
            this.$hint.showLoading();
            if (this.containerManageFlag && !this.inputData.parentCidCode) {
              const result = await checkCidBlur(data);
              if (result && result.parentCidId) {
                this.inputData.parentCidCode = result.parentCidCode;
                this.parentCidId = result.parentCidId;
              }
            }
            if (this.inputData.parentCidCode) {
              let checkParentCidData = {
                billId: this.billId,
                locationId: this.locationId,
                locationCode: this.inputData.locationCode,
                cidCode: this.inputData.cidCode,
                parentCidCode: this.inputData.parentCidCode,
                checkPermissions: true,
                billCode: this.billCode,
                fromPDAFlag: true,
              };
              const checkCidAndParentCidRes = await this.checkCidAndParentCid(checkParentCidData);
              if (!checkCidAndParentCidRes) {
                return;
              }
            }
            const res = await queryReceiptInfo(this.cidData);
            if (res) {
              // 有返回数据 代表这是预打包
              this.prepackagedData = res;

              // 非混托
              if (res.length === 1) {

                const resData = res[0];
                const { sku, goodsName, detailLineNum, uomName, detailId, packQty,
                  receivedQty, planQty, receiveQty, isbatch, batchCode,
                  ownerName, ownerId, orgName, orgId, goodsId, serialType, ownerCode, orgCode, inboundSerialReceiptList } = resData;
                this.isPrePackage = true;
                this.inputData.sku = sku;
                this.inputData.goods = goodsName;
                this.inputData.orderLine = detailLineNum;
                this.inputData.uom = uomName;
                this.detailId = detailId;
                // this.inputData.plantQty = packQty;
                // 预打包类型时 接收数量取packQty
                this.inputData.receiveQty = packQty || receiveQty;
                this.inputData.receivedQty = receivedQty;
                this.inputData.planQty = planQty;
                this.inputData.unreceiveQty = planQty - receivedQty;
                this.isserial = ['INV_CTRL', 'IN_CTRL', 'SEMI_INV_CTRL'].includes(serialType); // 隐藏序列号
                this.serialType = serialType,
                  this.isbatch = isbatch === 1;
                this.inputData.batchCode = batchCode;
                this.inputData.ownerName = ownerName;
                this.inputData.ownerId = ownerId;
                this.inputData.ownerCode = ownerCode;
                this.inputData.orgName = orgName;
                this.inputData.orgId = orgId;
                this.inputData.orgCode = orgCode;
                this.inputData.goodsId = goodsId;
                // 显示序列号明细按钮
                this.issku = true;
                this.showCidDetail = false;
                this.showSeriesDetail = ['INV_CTRL', 'IN_CTRL', 'SEMI_INV_CTRL'].includes(serialType);
                this.inboundSerialReceiptList = res.length === 1 ? inboundSerialReceiptList : [];
                // console.log('this.inputData', this.inputData);
                const batchFlag = await this.checkBatch('prePackage');
                if (isbatch === 1) {
                  if (batchFlag || this.batchControl) {
                    this.skipNext('submitRef');
                  } else {
                    this.skipNext(30); // 转至批次字段
                  }
                } else {
                  this.skipNext('submitRef');
                }
              } else {

                // 重置数据
                this.isPrePackage = false; // 禁止编辑收货数量
                this.inputData.sku = null;
                this.inputData.goods = null;
                this.inputData.orderLine = null;
                this.inputData.uom = null;
                this.detailId = null;
                this.inputData.receiveQty = null;
                this.inputData.receivedQty = null;
                this.inputData.planQty = null;
                this.inputData.unreceiveQty = null;
                this.isserial = false; // 隐藏序列号
                this.serialType = null,
                  this.isbatch = false;
                this.inputData.batchCode = null;
                this.inputData.ownerName = null;
                this.inputData.ownerId = null;
                this.inputData.ownerCode = null;
                this.inputData.orgName = null;
                this.inputData.orgId = null;
                this.inputData.orgCode = null;
                this.inputData.goodsId = null;
                this.showSeriesDetail = false;
                this.inboundSerialReceiptList = [];

                // 混托
                this.issku = false;
                this.showCidDetail = true;
                this.isPrePackage = true; // 禁止编辑收货数量


                const packQtyAll = res.reduce(function (total, item) {
                  return total + Number(item.packQty);
                }, 0);
                this.inputData.receiveQty = packQtyAll;
                this.skipNext('submitRef');
              }

            } else if (res === null || res === "") {
              // 重置数据
              this.isPrePackage = false; // 禁止编辑收货数量
              this.inputData.sku = null;
              this.inputData.goods = null;
              this.inputData.orderLine = null;
              this.inputData.uom = null;
              this.detailId = null;
              this.inputData.receiveQty = null;
              this.inputData.receivedQty = null;
              this.inputData.planQty = null;
              this.inputData.unreceiveQty = null;
              this.isserial = false; // 隐藏序列号
              this.serialType = null,
                this.isbatch = false;
              this.inputData.batchCode = null;
              this.inputData.ownerName = null;
              this.inputData.ownerId = null;
              this.inputData.ownerCode = null;
              this.inputData.orgName = null;
              this.inputData.orgId = null;
              this.inputData.orgCode = null;
              this.inputData.goodsId = null;
              this.showSeriesDetail = false;
              this.inboundSerialReceiptList = [];
              this.inboundReceiptRecordPackList = [];
              // 托盘不存在预打包数据
              this.inputData.plantQty = 0;
              this.showSeriesDetail = false;
              // 检验托盘与接收号是否是1v1对应关系
              const cidCodeFlag = await checkCidCode({ params: { cidCode: this.inputData.cidCode, receiptNumber: this.receiptNumber } });
              if (!cidCodeFlag) {
                // console.log('cidCodeFlag', cidCodeFlag);
                this.$hint.showAlert(
                  this.$t('message.checkAlreadyHasAcceptRecordInTray'),
                  () => {
                    this.$refs.ref20.focus();
                  },
                  () => {
                    this.inputData.cidCode = '';
                    this.$refs.ref10.focus();
                  }
                );
              }

              // 检验托盘是否为空托盘
              await checkCidNull({
                params: {
                  warehouseId: this.warehouseId,
                  warehouseCode: this.warehouseCode,
                  billCode: this.billCode,
                  cidCode: this.inputData.cidCode,
                }
              });
              this.skipNext(20);
            }
          }
        } else if (flag === 'batch' && this.inputData.batchCode) {
          this.$hint.showLoading();
          data.sku = this.inputData.sku;
          data.goodsId = this.inputData.goodsId;
          data.batchCode = this.inputData.batchCode;
          cidDateParams.status = "RECEIVED";
          cidDateParams.batchCode = this.inputData.batchCode;
          cidDateParams.sku = this.inputData.sku;
          this.batchId = '';
          if (this.inputData.parentCidCode) {
            let checkParentCidData = {
              billId: this.billId,
              billCode: this.billCode,
              sku: this.inputData.sku,
              goodsId: this.inputData.goodsId,
              locationId: this.locationId,
              locationCode: this.inputData.locationCode,
              cidCode: this.inputData.parentCidCode,
              checkPermissions: true,
              batchCode: this.inputData.batchCode,
              fromPDAFlag: true,
            };
            await checkParentCidCode(checkParentCidData);
          }
          await checkCid(data);
          const batchFlag = await this.checkBatch();
          if (!batchFlag) {
            return;
          }
          data.batchId = this.batchId;
          cidDateParams.batchId = this.batchId;
          // this.containerManageFlag && await checkCid(data); // TODO: 是否根据containerManageFlag控制调用
          const res = await queryReceiptInfo(this.cidData);
          if (res.id) {
            this.receiptId = res.id;
          }
          // this.handleBatchEnter();
        }
      } catch (e) {
        if (flag === 'cidCode') {
          this.skipNext(10);
        } else if (flag === 'parentCidCode') {
          this.skipNext(5);
        } else {
          this.skipNext(30);
        }
      } finally {
        this.$hint.hideLoading();
      }
    },


    /**
    * 收货库位选择
    * @param {Object} obj 选中对象
    */
    chooseLocation(obj) {
      const { locationId, whareaId, whareaCode, containerManageFlag, warehouseId } = obj;
      this.warehouseId = warehouseId;
      this.locationId = locationId;
      this.whareaId = whareaId;
      this.whareaCode = whareaCode;
      this.warehouseId = warehouseId;

      // 重新选择库位时（物料非空），才传warehouseId
      // console.log('物料', this.inputData.sku);
      // if(this.inputData.sku !== '') {
      //   this.warehouseId = warehouseId;
      // }

      // 不启用托盘管理,隐藏‘托盘管理’字段, 且自动跳至物料字段
      this.containerManageFlag = containerManageFlag;
      // if(!containerManageFlag) {
      //   this.$nextTick(() => {
      //     // this.$refs.ref20.focus();
      //   });
      // }
    },

    /**
    * 选择物料
    * @param item 选中对象
    */
    async chooseSku(item) {
      this.cidData = {
        params: {
          cidCode: this.inputData.cidCode,
          billId: this.billId,
          status: 'PREPACKAGED',
        },
      };
      this.whareaIdSku = item.whareaId;
      if (this.whareaIdsString && this.whareaIdSku && this.whareaId && (this.whareaIdSku !== this.whareaId)) {
        this.locationId = '';
        this.whareaId = '';
        this.whareaCode = '';
        this.containerManageFlag = '';
        this.inputData.locationCode = '';
      }
      const obj = {
        cidCode: this.inputData.cidCode,
        billId: this.billId,
        billCode: this.billCode,
        locationId: this.locationId,
        locationCode: this.inputData.locationCode,
        sku: item.sku,
        goodsId: item.goodsId,
      };
      // console.log('当前批次', this.inputData.batchCode);

      if (item.warehouseId !== this.warehouseId) {
        this.warehouseId = item.warehouseId;
        this.$hint.showFailed(this.$i18n.t('message.checkWarehouseIdNotSame'));
        this.inputData.locationCode = '';
        this.skipNext(0);
        return;
      }

      try {
        this.$hint.showLoading();
        this.setDataBySku(item);
        const checkRes = await checkCid(obj);
        if (this.inputData.parentCidCode) {
          let checkParentCidData = {
            billId: this.billId,
            billCode: this.billCode,
            sku: this.inputData.sku,
            goodsId: this.inputData.goodsId,
            locationId: this.locationId,
            locationCode: this.inputData.locationCode,
            cidCode: this.inputData.parentCidCode,
            checkPermissions: true,
            batchCode: this.inputData.batchCode,
            fromPDAFlag: true,
          };
          await checkParentCidCode(checkParentCidData);
        }
        // const cidUrl = `${this.winvBaseUrl}app-inbound/query-receipt-info`;
        if (checkRes && !checkRes.failed) {
          // console.log('重新赋值之后 当前批次', this.inputData.batchCode)
          const cidDateParams = this.cidData.params;
          cidDateParams.sku = this.inputData.sku;
          cidDateParams.goodsId = this.inputData.goodsId;
          this.showSeriesDetail = ['INV_CTRL', 'IN_CTRL', 'SEMI_INV_CTRL'].includes(item.serialType);
          if (!this.isbatch && this.inputData.cidCode) {
            // const res = await this.$http.get(cidUrl, this.cidData);
            queryReceiptInfo(this.cidData).then((res) => {
              this.receiptId = res.id || '';
            });
          }
          cidDateParams.status = "RECEIVED";
          this.handleSkipNext();
        } else {
          this.inputData.sku = '';
        }
        // this.handleSkipNext();
      } catch (e) {
        this.inputData.sku = '';
        this.skipNext(20);
      } finally {
        this.$hint.hideLoading();
      }
    },

    setDataBySku(item) {
      const site = [this.SERIAL_INV_CTRL, this.SERIAL_IN_CTRL, this.SERIAL_SEMI_INV_CTRL];
      this.skuInfo = item;
      this.inputData.sku = item.sku;
      this.inputData.goods = item.goodsName;
      this.inputData.uom = item.uomName;
      this.inputData.orderLine = item.lineNum;
      if (item.batchCode) this.inputData.batchCode = item.batchCode;
      this.batchId = item.batchId || '';
      this.inputData.planQty = item.planQty;
      this.inputData.receivedQty = item.receiveQty;
      this.inputData.unreceiveQty = item.planQty - item.receiveQty;
      this.definedInboundSerialReceiptList = item.definedInboundSerialReceiptList || []; // 预指定序列号
      this.detailId = item.id;
      this.inputData.goodsId = item.goodsId;
      this.inputData.ownerId = item.ownerId;
      this.serialType = item.serialType;
      this.inputData.serialNumber = '';
      if (item.isbatch === 1) {
        if (site.includes(item.serialType)) {
          this.isbatch = true;
          // this.inputData.receiveQty = 1;
          this.isserial = true;
          this.readOnly = false;
        } else {
          this.isbatch = true;
          this.isserial = false;
          this.readOnly = false;
        }
      } else {
        this.inputData.batchCode = '';
        if (site.includes(item.serialType)) {
          this.isbatch = false;
          // this.inputData.receiveQty = 1;
          this.isserial = true;
          this.readOnly = false;
        } else {
          this.isbatch = false;
          this.inputData.receiveQty = undefined;
          this.isserial = false;
          this.readOnly = false;
        }
      }
    },

    /**
    * checkBatch 校验批次是否存在
    * @params {String} type 区分哪次校验批次
    */
    async checkBatch(type) {
      const { batchCode, goodsId, ownerId } = this.inputData;
      const _this = this;
      function onCancel() {
        _this.inputData.batchCode = '';
        _this.$nextTick(() => {
          _this.skipNext(30);
        })
      }
      try {
        if (batchCode) {
          // const url = `${this.wmdmBaseUrl}batchs/check`;
          let params = {
            batchCode: batchCode,
            goodsId: goodsId,
            ownerId: ownerId,
          };
          // const res = await this.$http.get(url, { params });
          const res = await checkBatchs({ params });
          if (!res.id) {
            if (type === 'prePackage') {
              // 预打包校验
              if (this.batchControl === '0') {
                this.$hint.showAlert(this.$i18n.t('message.checkBatchCode'), this.confirmCreateBatch, this.cancelCreateBatch);
              }
            } else {
              if (this.batchControl === '0') {
                this.$hint.showAlert(this.$i18n.t('message.checkBatchCode'), this.confirmCreateBatch, onCancel);
              }
            }
            return false;
          }
          this.batchId = res.id;
          return true;
        } else if (!this.isbatch) {
          return true;
        }
      } catch {
        this.skipNext(30);
        // this.$hint.showFailed(error.message,true);
      }
    },

    /**
    * handleBatchEnter 批次enter事件
    */
    async handleBatchEnter() {
      const site = [this.SERIAL_INV_CTRL, this.SERIAL_IN_CTRL, this.SERIAL_SEMI_INV_CTRL];
      if (site.includes(this.serialType)) {
        await this.handleBlur('batch');
        this.skipNext(50);
      } else {
        if (this.readOnly || this.isPrePackage) {
          this.skipNext('submit')
        } else {
          await this.handleBlur('batch');
          this.skipNext(50);
        }
      }
    },

    /**
    * 确认
    */
    async handleButtonSubmit() {
      try {
        this.$hint.showLoading();
        this.handleSubmitStatus();
        // const url = `${this.winvBaseUrl}app-inbound/receive`;
        let data = {};
        if (this.isPrepackParentCid) {
          data = {
            receiveQty: this.inputData.receiveQty,
            locationCode: this.inputData.locationCode,
            locationId: this.locationId,
            whareaId: this.whareaId,
            whareaCode: this.whareaCode,
            warehouseId: this.warehouseId,
            warehouseCode: this.warehouseCode,
            billId: this.billId,
            detailId: this.detailId,
            prePackagedFlag: true,
            detailLineNum: this.inputData.lineNum,
            parentCidCode: this.inputData.parentCidCode,
            // inboundReceiptRecordPackList: this.inboundReceiptRecordPackList,
          };
        } else if (this.prepackagedData.length > 0) {
          data = {
            ...this.prepackagedData[0],
            receiveQty: this.inputData.receiveQty,
            locationCode: this.inputData.locationCode,
            locationId: this.locationId,
            whareaId: this.whareaId,
            whareaCode: this.whareaCode,
            warehouseId: this.warehouseId,
            warehouseCode: this.warehouseCode,
            batchId: this.isbatch ? this.batchId : null,
            inboundSerialReceiptList: this.inboundSerialReceiptList,
            receiptNumber: this.receiptNumber,
            billId: this.billId,
            detailId: this.detailId,
            batchCode: this.inputData.batchCode,
            cidCode: this.inputData.cidCode,
            detailLineNum: this.inputData.lineNum,
            parentCidCode: this.inputData.parentCidCode,
          }
        } else {
          data = {
            cidCode: this.inputData.cidCode,
            billId: this.billId,
            billCode: this.billCode,
            locationId: this.locationId,
            locationCode: this.inputData.locationCode,
            sku: this.inputData.sku,
            goodsId: this.inputData.goodsId,
            batchCode: this.inputData.batchCode,
            batchId: this.isbatch ? this.batchId : null,
            detailId: this.detailId,
            receiveQty: this.inputData.receiveQty,
            id: this.receiptId,
            whareaId: this.whareaId,
            whareaCode: this.whareaCode,
            warehouseId: this.warehouseId,
            warehouseCode: this.warehouseCode,
            inboundSerialReceiptList: this.inboundSerialReceiptList,
            receiptNumber: this.receiptNumber,
            detailLineNum: this.inputData.lineNum,
            parentCidCode: this.inputData.parentCidCode,
          };
        }
        const res = await submitReceive(data);
        this.isPrePackage = false;
        if (res && res.inboundOrderListReturn) {
          this.inboundSerialReceiptList = [];
          this.definedInboundSerialReceiptList = [];
          this.$refs['refSerail'].tableData = [];
          await this.reAccept(this.isPrepackParentCid, res);
        }
      } finally {
        this.$hint.hideLoading();
        this.handleSubmitStatus();
      }
    },

    /**
    * 成功后重新收货
    */
    async reAccept(isPrepackParentCid, subRes) {
      const { planQty, receiveQty, receivedQty } = this.inputData;
      // 操作成功 的物料回调 判断需要清空的参数
      // let goodsData={
      //   params:{
      //     billCode: this.billCode,
      //     cidCode: this.inputData.cidCode,
      //     currentDetailId: this.detailId,
      //   }
      // };

      let resGoods;
      if (!isPrepackParentCid) {
        resGoods = subRes.returnTypeInteger;
      }
      if (isPrepackParentCid) {
        this.inputData.parentCidCode = '';
        this.inputData.receiveQty = '';
        this.isPrepackParentCid = false;
        this.isPrePackage = false;
        this.inboundReceiptRecordPackList = [];
      } else if ((planQty === receivedQty + parseInt(receiveQty)) || (receivedQty + parseInt(receiveQty) - planQty <= 10)) {
        const res = subRes.inboundOrderListReturn;
        if (res && res.length > 0) {
          if (resGoods === 0) {
            this.$router.backPage();
          } else {
            this.inputData = {
              goods: '',
              uom: '',
              sku: '',
              orderLine: '',
              batchId: '',
              batchCode: '',
              cidCode: '',
              receiveQty: undefined,
              planQty: '',
              plantQty: '', //托盘数量
              receivedQty: '',
              unreceiveQty: '',
              goodsId: '',
              ownerId: '',
              serialNumber: '',
              parentCidCode: this.inputData.parentCidCode,
              locationCode: this.inputData.locationCode,
            } //输入框数据
            this.isbatch = false; //批次是否必输 隐藏
            this.isserial = false;
            this.detailId = '';
            this.receiptId = '';
            this.serialType = ''; //是否启用序列号控制
            this.readOnly = false; //输入数量是否可以更改
            this.isPrePackage = false;
            this.prepackagedData = '';
            this.batchId = undefined;
            this.inboundReceiptRecordPackList = [];
          }
          // else if(this.prepackagedData.length>0 && (resGoods===1 ||resGoods===2)){
          //   this.inputData.sku = undefined;
          //   this.inputData.isbatch = 0;
          //   this.inputData.receiveQty = undefined;
          //   this.inputData.cidCode = undefined;
          //   this.prepackagedData = [];
          //   this.$nextTick(() => {
          //     this.skipNext(10);
          //   });
          // }else if(resGoods === 1){
          //   this.inputData.receiveQty = undefined;
          //   const dataList = (await this.getGoodsData()).content;
          //   this.setDataBySku(dataList[0]);
          //   this.$nextTick(() => {
          //     this.skipNext(this.isbatch?30:50);
          //   });
          // }else if(resGoods === 2){
          //   this.inputData.sku = undefined;
          //   this.inputData.isbatch = 0;
          //   this.inputData.receiveQty = undefined;
          //   this.$nextTick(() => {
          //     this.skipNext(20);
          //   })
          // }
        }
        this.$hint.showSuccess(this.$i18n.t('message.operationSuccess'));
      } else {
        this.$hint.showSuccess(this.$i18n.t('message.operationSuccess'));
        this.inputData.batchCode = '';
        this.batchId = '';
        this.inputData.receiveQty = undefined;
        this.$nextTick(() => {
          this.skipNext(this.containerManageFlag ? 10 : 20);
        });
        const dataList = (await this.getGoodsData()).content;
        await this.setDataBySku(dataList[0]);
      }
      // 清除当前界面的二维码解析字段
      await this.clearSourceFields(this, true);
      this.skipNext(0);
    },

    /**
    * 获取物料
    */
    async getGoodsData() {
      this.$hint.showLoading();
      // const url = `${this.winvBaseUrl}app-inbound/query-inbound-lines-info`;
      let data = {
        params: {
          billCode: this.billCode,
          sku: this.inputData.sku,
        },
      };
      // const dataList = await this.$http.get(url, data);
      const dataList = await queryInboundLines(data);
      this.$hint.hideLoading();
      return dataList;
    },


    /**
    * 获取页面功能参数-父托盘是否显示
    */
    async getParentCidPageFunction() {
      let data = {
        params: {
          functionCode: 'PDA_IN_RCV',
          deviceCode: 'PDA',
          paramCode: 'PARENT_CID_ENABLE',
        },
      };
      this.parentCidCodeShow = await queryPageFunction(data);
    },

    /**
    * 获取页面功能参数-托盘是否必输
    */
    async getCidPageFunction() {
      let data = {
        params: {
          functionCode: 'PDA_IN_RCV',
          deviceCode: 'PDA',
          paramCode: 'LPN_IS_NULL',
        },
      };
      this.cidCodeRequired = await queryPageFunction(data);
    },


    /**
    * 获取页面功能参数-是否启用批次创建
    */
    async getAutoCreateBatch() {
      let data = {
        params: {
          functionCode: 'PDA_IN_RCV',
          deviceCode: 'PDA',
          paramCode: 'AUTO_CREATE_BATCH',
        },
      };

      this.autoCreateBatch = await queryPageFunction(data);
    },

    /**
     * 前往托盘明细界面
     */
    goToCidDetail() {
      let param = {
        name: 'ReceiveCidDetail',
        params: {
          prepackagedData: this.prepackagedData,
          cidCode: this.inputData.cidCode,
          receiveQty: this.inputData.receiveQty,
        },
      };
      this.$router.pushPage(param);
    },

    /**
     * 前往托盘明细界面
     */
    goToPrePackCidDetail() {
      let param = {
        name: 'ReceiveParentCidDetail',
        params: {
          billId: this.billId,
          parentCidCode: this.inputData.parentCidCode,
          status: 'PREPACKAGED',
        },
      };
      this.$router.pushPage(param);
    },

    /**
     * 前往序列号明细界面
     */
    async goToSerialDetail() {
      let data = {
        cidCode: this.inputData.cidCode,
        billId: this.billId,
        billCode: this.billCode,
        locationId: this.locationId,
        locationCode: this.inputData.locationCode,
        sku: this.inputData.sku,
        goodsId: this.inputData.goodsId,
        batchCode: this.inputData.batchCode,
        serialType: this.serialType,
        serialNumber: this.inputData.serialNumber,
        detailId: this.detailId,
        id: this.receiptId,
      };
      let serialUrl = 'app-inbound/serial-check';
      const _this = this;
      const param = {
        name: 'serialComponent',
        params: {
          data,
          serialUrl,
          // pendingQty: this.pendingQty,
          receiveQty: this.inputData.receiveQty,
          // serialType: this.serialType,
          task: this.inboundSerialReceiptList,
          detailIdTmpList: this.prepackagedData.length === 1,
          definedInboundSerialReceiptList: this.definedInboundSerialReceiptList,
          detailId: this.detailId,
          callback: function (serialNum) {
            const item = {
              serialNumber: serialNum,
              qualityStatusName: '0-合格',
              qualityStatus: '0-ACCEPTED',
              serialQty: 1,
            };
            const restNumber = _this.definedInboundSerialReceiptList.length;


            if (_this.inputData.unreceiveQty <= restNumber) {
              _this.$hint.showFailed(_this.$i18n.t('message.checkDefinedInboundSerialReceiptList'));
              return
            }
            this.tableData.push(item);

            if (_this.definedInboundSerialReceiptList.includes(serialNum)) {
              _this.definedInboundSerialReceiptList.splice(_this.definedInboundSerialReceiptList.findIndex((serial) => serial === serialNum), 1)
            }
          }
        },

      };
      // this.$router.pushPage(param);
      this.serialCompParams = param.params
    },

    /**
    * 初始化数据
    */
    dataInit() {
      this.inputData = {
        goods: '',
        uom: '',
        locationCode: '',
        sku: '',
        orderLine: '',
        batchId: '',
        batchCode: '',
        cidCode: '',
        receiveQty: undefined,
        planQty: '',
        plantQty: '', //托盘数量
        receivedQty: '',
        unreceiveQty: '',
        goodsId: '',
        ownerId: '',
        serialNumber: '',
        parentCidCode: '',
      } //输入框数据
      this.isbatch = false; //批次是否必输 隐藏
      this.isserial = false;
      this.detailId = '';
      this.receiptId = '';
      this.serialType = ''; //是否启用序列号控制
      this.readOnly = false; //输入数量是否可以更改
      this.isPrePackage = false;
      this.prepackagedData = '';
      this.batchId = undefined;
      this.inboundReceiptRecordPackList = [];
    },
  },
  created() {
    this.getDataFromParams();
    this.receiptId = '';
  },
  activated() {
    const batchId = this.$storage.get('batchId');
    if (!this.$common.isEmpty(batchId)) {
      this.batchId = batchId;
    }
    const inboundSerialReceiptList = JSON.parse(sessionStorage.getItem('inboundSerialReceiptList'));
    this.inboundSerialReceiptList = inboundSerialReceiptList || [];
    sessionStorage.removeItem('inboundSerialReceiptList');
  },
  async mounted() {
    this.inputFieldList = [
      { type: 'lov', required: true, ref: 0, bind: 'inputData.locationCode', field: 'locationCode' },
      { type: 'input', required: !this.parentCidCodeShow, ref: 5, bind: 'inputData.parentCidCode', field: 'parentCidCode' },
      { type: 'input', required: !this.parentCidCodeShow, ref: 10, bind: 'inputData.cidCode', field: 'cidCode' },
      { type: 'lov', required: this.issku, ref: 20, bind: 'inputData.sku', field: 'sku' },
      {
        type: 'input',
        required: true,
        ref: 30,
        bind: 'inputData.batchCode',
        isHide: !this.isbatch,
        field: 'batchCode',
      },
      {
        type: 'input',
        required: true,
        ref: 40,
        bind: 'inputData.serialNumber',
        isHide: !this.isserial,
        field: 'serialNumber',
      },
      { type: 'number', required: true, ref: 50, bind: 'inputData.receiveQty', field: 'receiveQty' },
    ];
    this.urlData = this.handleData('RECEIVE_CONFIRM');
    await this.getParentCidPageFunction();
    await this.getCidPageFunction();
    // this.getAutoCreateBatch();
    this.skipNext(0);
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name === 'createBatch') {
        if (vm.$storage.get('batchId')) {
          vm.batchId = vm.$storage.get('batchId');
          // vm.skipNext(50);
          vm.handleBatchEnter();
          vm.disabledFlag = true;
          vm.$storage.remove('batchId');
        } else {
          this.cancelCreateBatch();
        }
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'Receive') {
      this.$store.commit('deleteKeepAlives', 'ReceiveConfirm');
    }
    if (to.name === 'serialComponent') {
      this.$store.commit('pushKeepAlives', 'ipsNewSerial');
    }
    this.$nextTick(() => {
      next();
    });
  },
  watch: {
    'inputData.parentCidCode': function (val, oldval) {
      if (oldval !== '' && val === '') {
        this.isPrepackParentCid = false;
        this.isPrePackage = false;
        if (this.prePackReceivedQty) {
          this.prePackReceivedQty = '';
          this.inputData.receiveQty = '';
        }
      }
    }
  },
};
</script>

<style lang="stylus" scoped>
@import '~@/style/var.styl';

.count_block_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
}

.ips-btn-normal {
  border-radius: 8px !important;
  margin: 12px 10px 12px 10px;
  background-color: var(--primaryColor) !important;
  color: #ffffff !important;
  font-size: 17px !important;
}

.disable-btn {
  background-color: #BFBFBF;
}
</style>
