// import Login from '../views/login'
import ContanierSearch from '../views/contanier-search'
import ContanierData from '../views/contanier-data'
import ContanierDetail from '../views/contanier-detail'

let route = [
  {
    path: '/contanier-search',
    name: 'ContanierSearch',
    component: ContanierSearch,
  },
  {
    path:'/contanier-data',
    name:'ContanierData',
    component:ContanierData,
  },
  {
    path:'/contanier-detail',
    name:'ContanierDetail',
    component:ContanierDetail,
  },
]

export default route