<template>
  <hips-view :header-fixed="true">
    <!-- router-key="sorting-seeding"  -->
    <div slot="header">
      <ips-header :title="$t('title.secondarySorting') + '-播种式'" @onChecked="onChecked" />
    </div>
    <!-- 正常扫描 -->
    <div v-if="normalScanning" class="ips-container">
      <!-- 波次单号 -->
      <ips-lov
        :label="$t('label.waveNum')"
        :title="$t('label.waveNum')"
        v-model="waveNum"
        params="waveBillCode"
        display="waveBillCode"
        :url="`${winvBaseUrl}app-sorting-seeding/query-wave-orders`"
        ref="ref0"
        is-control
        required
        :pre-parse-url="urlData['waveNum']"
        :custom="customData['waveNum']"
        :source-parse-field="sourceParseField"
        v-show="customData['waveNum'] ? hideControl['waveNum'] : true"
        @click.stop="handleCommonClick(0)"
        @clickIcon="handleCommonClick(0, 'lov')"
        @enter="skipNext(10)"
        @down="skipNext(10)"
        @choose="handleChoose('waveNum', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 物料  -->
      <ips-field
        v-model="scanSku"
        :label="$t('label.sku')"
        ref="ref10"
        required
        :pre-parse-url="urlData['scanSku']"
        :custom="customData['scanSku']"
        :source-parse-field="sourceParseField"
        @click.stop="handleCommonClick(10)"
        @down="() => handleBlur('scanSku', scanSku)"
        @enter="() => handleBlur('scanSku', scanSku)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 条码  :label="$t('label.barCode')"   @blur="() => handleBlur('barCode', barCode)" -->
      <ips-field
        v-show="scanType"
        v-model="barCode"
        :label="scanType === 'BATCH' ? $t('label.batchCodeNew') : $t('label.serialNumber')"
        ref="ref20"
        required
        :pre-parse-url="urlData['barCode']"
        :custom="customData['barCode']"
        :source-parse-field="sourceParseField"
        @click.stop="handleCommonClick(20)"
        @down="() => handleBlur('barCode', barCode)"
        @enter="() => handleBlur('barCode', barCode)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />

      <!-- 建议播种位置 -->
      <div class="sow-location">
        {{ $t('message.recommendedSeedingPosition') }}
        <div class="num-box">
          {{ orderInformation.sortingPosition }}
        </div>
      </div>
      <!-- 建议数量 -->
      <div class="recommended-number">
        <div class="left">
          {{ $t('message.recommendedNumber') }}
        </div>
        <div class="right">
          {{ orderInformation.suggestQty }}
        </div>
      </div>
      <!-- 出库数量 -->
      <ips-input-number
        style="margin: 0"
        v-model="quantity"
        required
        :disabled="!currentNumCanEdit"
        :label="$t('label.quantity')"
        ref="ref30"
        @click.stop="handleCommonClick(30)"
        @enter="checkQty"
        @down="checkQty"
        :pre-parse-url="urlData['quantity']"
        :custom="customData['quantity']"
        :source-parse-field="sourceParseField"
        :min="null"
        :more-than-zero="true"
        not-auto-fill
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <div style="display: flex">
        <hips-button size="large" ref="submitRef" class="ips-btn-normal" @click="showSwitchTenantFun">
          {{ $t('message.scanTheDetail') }}
        </hips-button>
      </div>
      <!--出库单号-->
      <ips-value-cell :title="$t('label.outboundOrderR')" :value="orderInformation.billCode" />
      <!-- 单据备注-->
      <ips-value-cell :title="$t('label.documentsNote')" :value="orderInformation.remark || '无'" />
      <!--包裹号-->
      <ips-value-cell :title="$t('label.packageNo')" :value="orderInformation.packCode" />
      <div class="count_block_box">
        <!-- 已经扫描 -->
        <ips-number-count class="count-scannedQuantity" :count="orderInformation.scanQty || 0" text-field="scannedQuantity" />
        <!-- 总扫描数量 -->
        <ips-number-count class="count-totalScans" :count="orderInformation.sortedQty || 0" text-field="totalScans" />
        <!-- 总数量 -->
        <ips-number-count class="count-totalNumber" :count="orderInformation.planQty || 0" text-field="totalNumber" />
      </div>
    </div>
    <!-- 反扫描 -->
    <div class="ips-container" v-else>
      <!-- 反扫描波次单号 -->
      <ips-lov
        :label="$t('label.waveNum')"
        :title="$t('label.waveNum')"
        v-model="waveNum"
        params="waveBillCode"
        display="waveBillCode"
        disabled
        :url="`${winvBaseUrl}app-sorting-seeding/query-wave-orders`"
        ref="ref0"
        is-control
        required
        @click.stop="handleCommonClick(0)"
        @clickIcon="handleCommonClick(0, 'lov')"
        @enter="skipNext(10)"
        @down="skipNext(10)"
        @choose="handleChoose('waveNum', $event)"
      />
      <!-- 反扫描物料 -->
      <ips-field
        v-model="scanSku"
        :label="$t('label.sku')"
        ref="ref10"
        required
        @down="() => handleBlur('scanSku', scanSku)"
        @enter="() => handleBlur('scanSku', scanSku)"
      />
      <!-- 反扫条码 -->
      <ips-field
        v-model="barCode"
        :label="$t('label.barCode')"
        ref="ref20"
        required
        @down="() => handleBlur('barCode', barCode)"
        @enter="() => handleBlur('barCode', barCode)"
      />
      <div v-if="notNormalScanningItem.sortingPosition" class="sow-location">
        播种位
        <div class="num-box">
          {{ notNormalScanningItem.sortingPosition }}
        </div>
      </div>
      <!-- 反扫出库数量 -->
      <ips-input-number
        v-model="quantity"
        required
        :label="$t('label.quantity')"
        ref="ref30"
        @enter="checkQty"
        @down="checkQty"
        :min="null"
        :more-than-zero="true"
        not-auto-fill
      />
    </div>

    <div class="ips-footer">
      <!--分拣确认-->
      <hips-button size="large" class="ips-btn-normal" @click="handleSub">
        {{ $t('button.sortConfirm') }}
      </hips-button>
      <!--换箱-->
      <hips-button size="large" class="ips-btn-normal" @click="endBoxChange">
        {{ $t('button.inCasesOf') }}
      </hips-button>
    </div>

    <hips-popup v-model="showSwitchTenantPopup" position="bottom">
      <div class="popup-title">
        <div class="left" @click="cleanPage">
          {{ $t('button.clear') }}
        </div>
        <div class="center">
          {{ $t('message.scanTheDetail') }}
        </div>
        <div class="right" @click="deleteIsSelectItem">
          {{ $t('button.delete') }}
        </div>
      </div>
      <div v-if="orderInformation.waveSortingScanDTOS" class="ips-table" style="overflow-y: scroll; padding-bottom: 30px">
        <table>
          <tr style="background-color: #eee">
            <th v-for="(item, index) in tableTitle" :key="index" scope="col">
              {{ item.name }}
            </th>
          </tr>
          <tr
            @click="selectItem(index)"
            v-for="(item, index) in orderInformation.waveSortingScanDTOS"
            :key="index"
            :ref="`tr${index}`"
            :style="item.isSelect ? 'background: #ccc;' : ''"
          >
            <td>{{ item.sortingPosition }}</td>
            <td>{{ item.sku }}</td>
            <td>{{ item.serialNumber }}</td>
            <td>{{ item.batchCode }}</td>
            <td>{{ item.qty }}</td>
            <td>{{ item.packCode }}</td>
          </tr>
        </table>
      </div>
    </hips-popup>
  </hips-view>
</template>
<script>
import { storage } from '@/utils';
import { validateWaveBillCode, validateSku, validateCode, validateQty, sortingSeedingConfirm, sortingSeedingExchangePack } from '../api';
export default {
  name: 'SortingSeeding',
  data() {
    return {
      // 批次ID
      waveBillId: '',
      // 批次单号
      waveNum: '',
      // 分拣总信息
      orderInformation: {},
      // 物料编码
      scanSku: '',
      // 条码
      barCode: '',
      // 扫描类型
      scanType: '',
      // 数量
      quantity: '',
      // 是否逐个扫描
      //   isScanOneByOne: false,
      // 当前数量是否可以编辑
      currentNumCanEdit: true,
      // 条码是可以编辑
      currentBarCodeCanEdit: true,
      //  表单数据
      tableTitle: [],
      // 显示扫明明细
      showSwitchTenantPopup: false,
      normalScanning: true,
      // 反扫数据
      notNormalScanningItem: {},
      menuId: storage.get('menuId'),
      urlData: {},
      customData: {}, // 个性化配置信息列表
      hideControl: {}, // 非必输字段的显隐控制
      sourceParseField: {}, // 界面中所有的来源解析字段
    };
  },
  mounted() {
    // 测试数据

    // 序列号
    // 波次单 WAVE202204010001
    // 物料 B2C004
    // 系列号 B2C004-0003

    // 波次单 WAVE202204140002
    // 物料 testsku040101
    // 批次 PC04110011

    this.urlData = this.handleData('RECEIVE_QUERY');
    this.skipNext(0);
    this.inputFieldList = [
      { type: 'lov', required: true, ref: 0, bind: 'waveNum', field: 'waveNum' },
      { type: 'input', required: true, ref: 10, bind: 'scanSku', field: 'scanSku' },
      { type: 'input', required: true, ref: 20, bind: 'barCode', field: 'barCode' },
      { type: 'input', required: false, ref: 30, bind: 'quantity', field: 'quantity' },
    ];
    this.tableTitle = [
      {
        name: this.$t('label.sowLocation'),
      },
      {
        name: this.$t('label.skuCode'),
      },
      {
        name: this.$t('label.serialNumber'),
      },
      {
        name: this.$t('label.batchSerial'),
      },
      {
        name: this.$t('label.scannedQuantity'),
      },
      {
        name: this.$t('label.packageNo'),
      },
    ];
  },
  computed: {},
  async created() {
    const { hideControl, customObj } = this.handleCustomData(storage.get('customData'));
    this.customData = customObj;
    this.hideControl = hideControl;
    // console.log('按单收货customData', Object.assign({}, this.customData));
  },
  methods: {
    // 保存来源解析字段
    async saveSourceField(fieldObj) {
      this.saveSourceParseField(this, fieldObj);
    },
    // 字段聚焦时赋值操作
    async valuedByFoucs(options = {}) {
      const { custom = {}, currentValue } = options;
      await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
    },
    // 选择一项
    selectItem(index) {
      this.$set(this.orderInformation.waveSortingScanDTOS[index], 'isSelect', !this.orderInformation.waveSortingScanDTOS[index].isSelect);
    },
    // 删除所选中的项
    deleteIsSelectItem() {
      let newArray = [];
      let deleteObj = {};
      let deleteSum = 0;
      this.orderInformation.waveSortingScanDTOS.forEach((item) => {
        if (!item.isSelect) {
          newArray.push(item);
        } else {
          if (deleteObj[item.billCode]) {
            deleteObj[item.billCode]['deleteNum'] = deleteObj[item.billCode]['deleteNum'] + item.qty;
          } else {
            deleteObj[item.billCode] = {};
            deleteObj[item.billCode]['deleteNum'] = item.qty;
            deleteSum = deleteSum + item.qty;
          }
        }
      });
      this.orderInformation.waveSortingProgressDTOS.map((item) => {
        if (deleteObj[item.billCode]) {
          item.scanQty = item.scanQty - deleteObj[item.billCode]['deleteNum'];
        }
      });
      this.orderInformation.scanQty = this.orderInformation.scanQty - deleteSum;
      this.orderInformation.waveSortingScanDTOS = newArray;
      this.showSwitchTenantPopup = false;
      console.log('删除之后的数据：' + this.orderInformation);
    },
    // 清空缓存
    cleanPage() {
      this.waveNum = '';
      this.scanSku = '';
      this.barCode = '';
      this.quantity = '';
      this.orderInformation = {};
      this.showSwitchTenantPopup = false;
      this.skipNext(0);
    },
    //   监听事件
    onChecked(val) {
      this.normalScanning = val;
    },
    //   显示扫描明细
    async showSwitchTenantFun() {
      if (this.orderInformation.waveSortingScanDTOS && this.orderInformation.waveSortingScanDTOS.length) {
        this.showSwitchTenantPopup = true;
      } else {
        this.$hint.showFailed('暂无扫描数据');
      }
    },
    /**
     * 换箱
     */
    async endBoxChange() {
      if (this.orderInformation.waveSortingScanDTOS && this.orderInformation.waveSortingScanDTOS.length) {
        const param = {
          ...this.orderInformation,
        };
        const res = await sortingSeedingExchangePack(param);
        console.log(res);
        this.$hint.showSuccess();
        this.orderInformation = res;
      } else {
        this.$hint.showFailed('暂无扫描数据');
      }
    },
    /**
     * 确认按钮
     */
    async handleSub() {
      if (this.orderInformation.waveSortingScanDTOS && this.orderInformation.waveSortingScanDTOS.length) {
        const param = {
          ...this.orderInformation,
        };
        const res = await sortingSeedingConfirm(param);
        console.log(res);
        this.$hint.showSuccess(this.$i18n.t('message.sortingSuccess'));
        this.cleanPage();
      } else {
        this.$hint.showFailed('暂无扫描数据');
      }
    },
    /**
     * lov选择框，选择数据
     * @param type lov
     * @param obj 选中对象
     */
    handleChoose(type, obj) {
      switch (type) {
        case 'waveNum':
          this.waveBillId = obj.waveBillId;
          this.sourceBillBlur();
          break;
        default:
          return null;
      }
    },
    // 处理失去焦点事件
    async handleBlur(type) {
      switch (type) {
        case 'waveNum':
          this.handleWaveNumBlur();
          break;
        case 'scanSku':
          this.handleScanSkuBlur();
          break;
        case 'barCode':
          this.handleScanCodeBlur();
          break;
        default:
          return null;
      }
    },
    handleWaveNumBlur() {
      if (!this.waveNum) {
        // this.$hint.showFailed('请输入波次单号');
        this.skipNext(0);
        return;
      }
    },
    // 如果是扫描条码
    async handleScanCodeBlur() {
      if (!this.barCode) {
        // this.$hint.showFailed('请输入条码');
        this.skipNext(20);
        return;
      }
      // 首先判断是否是反扫
      if (!this.normalScanning) {
        if (this.checkoutFilde('batchCode')) {
          // 计算数据
          let filterItems = this.orderInformation.waveSortingScanDTOS.filter((item) => {
            return item.sku === this.scanSku && item.batchCode === this.barCode;
          });
          if (filterItems.length > 0) {
            this.notNormalScanningItem = filterItems[0];
            this.skipNext(30);
          } else {
            console.log('缓存中没有该字段');
          }
        } else {
          console.log('缓存中没有该字段');
        }
        return;
      }
      let params = {
        ...this.orderInformation,
        waveBillCode: this.waveNum,
        sku: this.scanSku,
        scanType: this.scanType,
        code: this.barCode,
      };
      console.log(params);
      //  如果是序列号
      if (this.scanType === 'SERIAL') {
        params.qty = 1;
      }
      try {
        const res = await validateCode(params);
        this.orderInformation = res;
        if (this.scanType === 'SERIAL') {
          this.quantity = 1;
          this.barCode = '';
          this.orderInformation = { ...this.orderInformation, qty: null, scanType: '', code: '' };
          setTimeout(() => {
            this.skipNext(20);
          }, 0);
        } else {
          this.skipNext(30);
        }
      } catch (error) {
        this.skipNext(20);
      }
    },
    // 检测字段是否在缓存中
    checkoutFilde(fieldName) {
      let comVal;
      if (fieldName === 'sku') {
        comVal = this.scanSku;
      } else if (fieldName === 'batchCode') {
        comVal = this.barCode;
      }
      if (this.orderInformation.waveSortingScanDTOS && this.orderInformation.waveSortingScanDTOS.length > 0) {
        for (let i = 0; i < this.orderInformation.waveSortingScanDTOS.length; i++) {
          if (this.orderInformation.waveSortingScanDTOS[i][fieldName] === comVal) {
            return true;
          }
        }
        return false;
      }
    },
    // 如果是扫描物料
    async handleScanSkuBlur() {
      if (!this.scanSku) {
        // this.$hint.showFailed('请输入物料编号');
        this.skipNext(10);
        return;
      }
      // 首先判断是否是反扫
      if (!this.normalScanning) {
        if (this.checkoutFilde('sku')) {
          this.skipNext(20);
        } else {
          console.log('缓存中没有该字段');
        }
        return;
      }
      let params = {
        ...this.orderInformation,
        waveBillCode: this.waveNum,
        sku: this.scanSku,
      };
      const res = await validateSku(params);
      this.orderInformation = res;
      if (res.serialType === 'INV_CTRL' || res.serialType === 'IN_CTRL' || res.serialType === 'SEMI_INV_CTRL') {
        // 序列号 数量不可编辑
        this.scanType = 'SERIAL';
        this.currentNumCanEdit = false;
        this.currentBarCodeCanEdit = true;
        setTimeout(() => {
          this.skipNext(20);
        }, 0);
      } else if (res.isbatch === 1) {
        //   批次 数量可以编辑
        this.currentNumCanEdit = true;
        this.currentBarCodeCanEdit = true;
        this.scanType = 'BATCH';
        setTimeout(() => {
          this.skipNext(20);
        }, 0);
      } else {
        this.quantity = 1;
        this.currentBarCodeCanEdit = false;
        this.currentNumCanEdit = true;
        setTimeout(() => {
          this.skipNext(30);
        }, 0);
      }
      console.log(this.scanType);
    },
    /**
     * 波次单号失去焦点校验
     */
    async sourceBillBlur() {
      if (this.waveNum) {
        this.$hint.showLoading();
        try {
          // 验证物料
          const result = await validateWaveBillCode({ waveBillId: this.waveBillId });
          this.orderInformation = result;
          if (!result || (result && result.failed)) {
            // 接口报错
            this.skipNext(0);
          } else {
            this.skipNext(10);
          }
        } catch (err) {
          this.skipNext(0);
        } finally {
          this.$hint.hideLoading();
        }
      }
    },
    // 检查数量
    async checkQty() {
      // 如果是反扫
      if (!this.normalScanning) {
        if (this.notNormalScanningItem.qty < this.quantity) {
          console.log('反扫数量');
          return;
        }
      }
      let params = {
        ...this.orderInformation,
        waveBillCode: this.waveNum,
        sku: this.scanSku,
        scanType: this.scanType,
        serialNumber:'',
        code: this.barCode,
        qty: this.quantity,
      };
      const result = await validateQty(params);
      this.orderInformation = result;
      // 光标定位到物料
      if (result) {
        // 清空数据
        this.scanSku = '';
        this.barCode = '';
        this.quantity = '0';
        this.orderInformation = { ...this.orderInformation, qty: null, scanType: '', code: '', sortingPosition: '', suggestQty: '' };
        this.skipNext(10);
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
 @import '~@/style/var.styl'
.sow-location {
  padding-top:20px;
  font-size:14px;
  .num-box{
    color:#666;
    margin-top:10px;
    border:1px solid #ddd;
    height:100px;
    border-radius: 10px;
    line-height: 100px;
    text-align: center;
    font-size: 80px;
  }
}

.recommended-number{
  font-size:14px;
  height: 50px;
  line-height: 50px;
  display: flex;
  justify-content: space-between;
}
.count_block_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0;
}

.count-scannedQuantity{
    background-color: #91D5FF
    border-color: var(--primaryColor)
    color: var(--primaryColor)
}
.count-totalScans{
    background-color: #B7EB8F
    border-color: #52C41A
    color: #52C41A
}

.count-totalNumber{
    background-color: #FFD382
    border-color: #FF8F07
    color: #FF8F07
}
.ips-btn-normal{
    flex:1;
}

.popup-title{
    display: flex;
    justify-content: space-between;
    padding:20px 20px 0 20px;
    .left,.right{
        font-size:12px;
        color:#999;
    }
}

.ips-table {
    margin-top 16px;
    width: 100%;
    .ips-table-thead{
        width:180px;
        height:26px;
        background:rgba(255,255,255,1);
        border-radius:12px 0px 0px 0px;
        line-height 26px;
        text-align center;
        font-weight bold;
        font-size 14px;
        padding-left 12px;
    }

    table {
        width: 100%;
        th, td {
            padding: 5px 2px;
            border: 1px solid #D9D9D9;
            text-align: center;
            white-space: nowrap;
            font-size 14px;
            color:rgba(89,89,89,1);
            &:first-child {
              width:40px;
            }
        }

        .back-green {
            background-color: #cbdeb8;
        }
    }
}
</style>
