var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "hips-nav-bar",
    {
      attrs: {
        slot: "header",
        "back-button": {
          showLeftArrow: true,
          backText: _vm.$t("button.back"),
        },
        title: _vm.title,
      },
      on: {
        "nav-bar-back-click": function ($event) {
          return _vm.back()
        },
      },
      slot: "header",
    },
    [
      _vm.trigger
        ? _c(
            "div",
            {
              attrs: { slot: "right" },
              on: { click: _vm.onGoDetail },
              slot: "right",
            },
            [_vm._v("\n    " + _vm._s(_vm.trigger) + "\n  ")]
          )
        : _vm._e(),
      _vm.routerKey === "sorting-seeding"
        ? _c(
            "div",
            { attrs: { slot: "right" }, slot: "right" },
            [
              _c("hips-switch", {
                attrs: {
                  value: _vm.checked,
                  width: "70",
                  "checked-text": "正扫",
                  "unchecked-text": "反扫",
                },
                on: { change: _vm.onInput },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.renderData && _vm.pageType === ""
        ? _c(
            "div",
            { staticClass: "tabs", attrs: { slot: "center" }, slot: "center" },
            [
              _c(
                "p",
                {
                  staticStyle: { "line-height": "25px", "font-weight": "bold" },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.renderData.title || _vm.renderData.billCode) +
                      "\n    "
                  ),
                ]
              ),
              _c(
                "p",
                {
                  staticStyle: { "line-height": "25px", "font-weight": "bold" },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.renderData.taskCode || _vm.renderData.billCode
                      ) +
                      "\n    "
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm.renderData && _vm.pageType === "taskExecution"
        ? _c(
            "div",
            {
              staticClass: "tabs",
              staticStyle: { "text-align": "left", float: "left" },
              attrs: { slot: "center" },
              slot: "center",
            },
            [
              _c(
                "p",
                {
                  staticStyle: { "line-height": "25px", "font-weight": "bold" },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.renderData.title || _vm.renderData.billCode) +
                      "\n    "
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    "line-height": "25px",
                    "font-weight": "bold",
                    "font-size": "12px",
                    float: "left",
                  },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.renderData.taskCode || _vm.renderData.billCode
                      ) +
                      "\n      "
                  ),
                  _c(
                    "p",
                    { staticStyle: { float: "right", "margin-left": "10px" } },
                    [_c("span", [_vm._v(_vm._s(_vm.renderData.taskNumbers))])]
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }