<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header
        :title="$t('title.locationStock')"
        :is-index="false"
      />
    </div>
    <div class="header-row">
      <div class="header-title">
        {{ whareaData.locationCode }}
      </div>
      <div :class="statusFlag === '启用' ? 'header-status' : 'header-no-status'">
        {{ statusFlag }}
      </div>
    </div>
    <div class="ips-container">
      <div class="menu-row">
        <div class="menu-title">
          {{ $t('label.belongOrg') }}：
        </div>
        <div class="menu-value">
          {{ whareaData.orgName }}
        </div>
      </div>
      <div class="menu-row">
        <div class="menu-title">
          {{ $t('label.belongWarehouse') }}：
        </div>
        <div class="menu-value">
          {{ whareaData.warehouseName }}
        </div>
      </div>
      <div class="menu-row">
        <div class="menu-title">
          {{ $t('label.belongWharea') }}：
        </div>
        <div class="menu-value">
          {{ whareaData.whareaName }}
        </div>
      </div>
      <div class="menu-row">
        <div class="menu-title">
          {{ $t('label.locationName') }}：
        </div>
        <div class="menu-value">
          {{ whareaData.locationName }}
        </div>
      </div>
      <div class="menu-row">
        <div class="menu-title">
          {{ $t('label.locationType') }}：
        </div>
        <div class="menu-value">
          {{ whareaData.locationTypeName }}
        </div>
      </div>
      <div class="menu-row">
        <div class="menu-title">
          {{ $t('label.identification') }}：
        </div>
        <div class="menu-value">
          {{ whareaData.identificationName }}
        </div>
      </div>
      <ips-no-data v-if="locationSkuData.length<=0" />
      <!--库位-物料卡片-->
      <div v-if="locationSkuData.length > 0">
        <div
          class="card_countLocation"
          v-for="(item,index) in locationSkuData"
          :key="index"
          @click="toWhareaLocation(item)"
        >
          <div class="row">
            <div class="title">
              {{ $t('label.countLocation') }}:
            </div>
            <div class="value">
              {{ item.locationCode }}
            </div>
          </div>
          <div class="row">
            <div class="row-50">
              <div class="title">
                {{ $t('label.sku') }}:
              </div>
              <div class="value">
                {{ item.sku }}
              </div>
            </div>
            <div class="row-50">
              <div class="title">
                {{ $t('label.goodsName') }}:
              </div>
              <div class="value">
                {{ item.goodsName }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="row-50">
              <div class="title">
                {{ $t('label.availableQty') }}:
              </div>
              <div class="label-number">
                {{ item.qty }}
              </div>
            </div>
            <div class="row-50">
              <div class="title">
                {{ $t('label.currentUseQty') }}:
              </div>
              <div class="label-number">
                {{ item.validQty }}
              </div>
            </div>

          </div>
          <div class="row-detail">
            <div class="title">
              <a class="detail">
                {{ $t('label.detail') }}
              </a>
            </div>
            <hips-icon
              class="ips-icon"
              name="arrow"
              size="30"
            />
          </div>
        </div>
      </div>
    </div>
  </hips-view>
</template>

<script>
import { queryLocationSku } from '../api';
import { Row, Col, Icon } from '@hips/vue-ui';
export default {
  name: 'LocationSku',
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
  },
  data() {
    return {
      whareaData: {}, // params参数
      locationSkuData: [], // 库位-物料数据
      statusFlag: '', // 状态
    };
  },
  methods: {
    // 获取params传来的参
    getParamsData() {
      const { item } = this.$route.params;
      this.whareaData = item;
      this.statusFlag = item.enabledFlag === 1 ? '启用' : '未启用';
      let data = {
        params: {
          locationId: item.id, // 库位id
          locationCode: item.locationCode, // 库位编码
        }
      };
      queryLocationSku(data).then((res) => {
        this.locationSkuData = res.contentDTO.goodsDTOList;
      })
        .catch((e) => {
          console.log('错误：' + e)
        })
        .finally(() => {
          this.$hint.hideLoading();
        });
    },

    //跳转至库位-详情页面
    toWhareaLocation(item) {
      const { enabledFlag, ...rest} = this.whareaData
      this.whareaData = {
        ...rest,
        ...item,
        enabledFlag
      };
      let param = {
        name: 'LocationDetail',
        params: {
          item: this.whareaData,
        },
      };
      this.$router.pushPage(param);
    },

  },
  mounted() {
    this.getParamsData();
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'LocationDetail') {
      this.$store.commit('pushKeepAlives', 'LocationSku');
    } else {
      this.$store.commit('deleteKeepAlives', 'LocationSku');
    }
    setTimeout(() => {
      next();
    }, 10)
  },
};
</script>

<style lang="stylus" scoped>
@import '~@/style/var.styl';

.header-row {
  padding-top: 5px;
  display: flex;
  margin-bottom: 12px;

  .header-title {
    display: flex;
    align-items: center;
    height: 28px;
    background: #E6F7FF;
    color: var(--primaryColor);
    border-radius: 0px 32px 32px 0px;
    padding: 0 12px 0 8px;
    border-left: 4px solid var(--primaryColor);
  }

  .header-status {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 12px;
    padding: 0 12px;
    height: 28px;
    font-size: 15px;
    background: #D6FFD7;
    color: #19A633;
    border-radius: 12px;
  }

  .header-no-status {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 12px;
    padding: 0 12px;
    height: 28px;
    font-size: 15px;
    background: #D9D9D9;
    color: #8C8C8C;
    border-radius: 12px;
  }
}

.ips-container {
  padding-top: 0 !important;

  .menu-row {
    display: flex;
    margin: 12px 32px 12px 12px;

    .menu-title {
      width: 70px;
      height: 16px;
      font-size: 12px;
      /* font-family:PingFangSC-Regular,PingFang SC; */
      font-weight: 200;
      color: #8C8C8C;
      line-height: 16px;
    }

    .menu-value {
      width: 261px;
      height: 16px;
      font-size: 12px;
      /* font-family:PingFangSC-Regular,PingFang SC; */
      font-weight: initial;
      color: #000000;
      line-height: 16px;
      overflow: hidden; /* 溢出隐藏 */
      white-space: nowrap; /* 规定文本不进行换行 */
      text-overflow: ellipsis; /* 当对象内文本溢出时显示省略标记（...） */
    }
  }

  .card_countLocation {
    margin: 10px 0;
    padding: 0 12px;
    height: 130px;
    border-radius: 1.6vw;
    color: #000;
    box-shadow: 0 0 2.133vw #d9d9d9;
    border: none;
    font-size: 14px;

    .row {
      display: flex;
      padding: 7px 0;

      .row-50 {
        width: 50%;
        display: flex;

        .title {
          width: 56px;
          height: 16px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 200;
          color: #595959;
          line-height: 18px;
        }

        .value {
          width: 110px;
          height: 18px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: initial;
          color: #000000;
          line-height: 18px;
          overflow: hidden; /* 溢出隐藏 */
          white-space: nowrap; /* 规定文本不进行换行 */
          text-overflow: ellipsis; /* 当对象内文本溢出时显示省略标记（...） */
        }

        .label-number {
          width: 40px;
          height: 18px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: initial;
          color: #9254DE;
          line-height: 18px;
        }
      }

      .title {
        width: 56px;
        height: 16px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 200;
        color: #595959;
        line-height: 18px;
      }

      .value {
        width: 260px;
        height: 18px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: initial;
        color: #000000;
        line-height: 18px;
        overflow: hidden; /* 溢出隐藏 */
        white-space: nowrap; /* 规定文本不进行换行 */
        text-overflow: ellipsis; /* 当对象内文本溢出时显示省略标记（...） */
      }
    }

    .row-detail {
      display: flex;
      justify-content: space-between;
      padding: 7px 0;
      border-top: 1px solid #d9d9d9;

      .detail {
        width: 28px;
        height: 16px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 200;
        color: var(--primaryColor);
        line-height: 16px;
      }

      .ips-icon {
        color: #bfbfbf;
      }
    }
  }
}
</style>
