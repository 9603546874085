import print from '../views/index.vue'


let route = [
  {
    path: '/print',
    name: 'print',
    component: print,
  },
]

export default route