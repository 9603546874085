<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.trayDetail')" :is-index="false" />
    </div>
    <div class="ips-container">
      <ips-value-cell :title="$t('label.startOfTray')" :value="cidCode" />
      <div class="detail-card" v-for="(item, index) in goodsList" :key="index">
        <!-- 托盘 -->
        <ips-value-cell :title="$t('label.cidCode')" :value="item.cidCode" />
        <!-- 物料名 -->
        <ips-value-cell :title="$t('label.sku')" :value="item.sku" />
        <!-- 品名 -->
        <ips-value-cell :title="$t('label.goodsName')" :value="item.goodsName" />
        <!-- 单位 -->
        <ips-value-cell :title="$t('label.unit')" :value="item.uomName" />
        <!-- 批次 -->
        <ips-value-cell :title="$t('label.batch')" :value="item.batchCode" />
        <!-- 可用量 -->
        <ips-value-cell :title="$t('label.useQty')" :value="item.validQty + ''" />
      </div>
    </div>
  </hips-view>
</template>

<script>
import {queryCidStockDetail} from '../api';
export default {
  name: 'FullPalletTransferDetail',
  data() {
    return {
      cidCode: '', //起始托盘
      goodsList: [], //物料数据
    };
  },
  methods: {
    /**
     * 获取上个页面的传参
     */
    getDataFromParams() {
      const { cidCode,cidId } = this.$route.params;
      this.cidCode = cidCode
      this.cidId = cidId
    },
    /**
     * 获取当前页面详情信息
     */
    async getDetail() {
      try{
        this.$hint.showLoading();
        // let url = `${this.winvBaseUrl}app-inv/query-cid-stock-detail`;
        let param = {
          params: {
            cidCode: this.cidCode,
            cidId: this.cidId,
          },
        };
        // const res = await this.$http.get(url, param);
        const res = await queryCidStockDetail(param);
        this.goodsList = res;
      } catch(e) {
        console.log('错误：' + e);
      } finally {
        this.$hint.hideLoading();
      }
    },
  },
  mounted() {
    this.getDataFromParams();
    this.getDetail();
  },
};
</script>

<style  lang="stylus" scoped>
  .detail-card {
        background: #fff;
        margin: 4.267vw 0 5px 0;
        /*margin-bottom: 5px;*/
        border-radius: 1.6vw;
        /*margin-top: 4.267vw;*/
        width: 100%;
        padding: 2.133vw 0px;
        color: #000;
        font-size: 3.467vw;
        box-shadow: 0 0 2.133vw #d9d9d9;
    }
</style>
