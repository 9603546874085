<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.revokeDelivery')" />
    </div>
    <div class="ips-container">
      <!--包裹单号-->
      <ips-input
        v-model="inputData.packCode"
        :label="$t('label.packageNo')"
        ref="ref0"
        :custom="customData['packCode']"
        :source-parse-field="sourceParseField"
        @enter="checkPackCodeBlur"
        @down="checkPackCodeBlur"
        @click.stop="handleCommonClick(0)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <div class="ips-footer">
        <!--提交-->
        <hips-button
          size="large"
          ref="submitRef"
          @click.stop="handleCommonSubmit"
        >
          撤销交接
        </hips-button>
      </div>
    </div>
  </hips-view>
</template>

<script>
import { storage } from '@/utils';
import { packConfirm, checkPackCode } from '../api/index';
export default {
  name: 'RevokeDelivery',
  data() {
    return {
      // 数量展示对象
      orderData: {},
      // 界面上的输入框数据
      inputData: {
        // 包裹
        packCode: ''
      },
      menuId: storage.get('menuId'),
      urlData: {},
      customData: {}, // 个性化配置信息列表
      hideControl: {}, // 非必输字段的显隐控制
      sourceParseField: {}, // 界面中所有的来源解析字段
    };
  },
  async created() {
    this.$hint.hideLoading();
    const { hideControl, customObj } = this.handleCustomData(storage.get('customData') || []);
    this.customData = customObj;
    this.hideControl = hideControl;
  },
  methods: {
    // 保存来源解析字段
    async saveSourceField(fieldObj) {
      this.saveSourceParseField(this, fieldObj);
    },
    // 字段聚焦时赋值操作
    async valuedByFoucs(options = {}) {
      const { custom = {}, currentValue } = options;
      await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
    },
    // 重置转态
    resetState() {
      this.inputData.packCode = ''
      this.orderData = {}
    },
    // 提交
    async handleButtonSubmit() {
      try {
        this.$hint.showLoading();
        const res = await packConfirm(this.orderData)
        if (!(res && res.failed)) {
          this.resetState()
          this.skipNext(0)
        }
        this.$hint.hideLoading();
      } catch (error) {
        this.$hint.hideLoading();
      }
    },

    checkPackCodeBlur() {
      checkPackCode({
        packCode: this.inputData.packCode
      }).then((res) => {
        if (!(res && res.failed)) {
          this.orderData = res
          this.skipNext('submitRef')
        }
      }).catch((err) => {
        this.inputData.packCode = ''
        console.log(err)
      })
    }
  },
};
</script>

<style lang="stylus" scoped>
@import '~@/style/var.styl';

.ips-container >>> .hips-cell__title {
  flex: 2 !important;
}
</style>
