var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: {
            title: _vm.$t("title.allreceive"),
            trigger: _vm.orderData.billTypeName ? _vm.$t("title.detail") : "",
            "on-go-detail": _vm.goDetail,
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["enterNum"]
                ? _vm.hideControl["enterNum"]
                : true,
              expression:
                "customData['enterNum'] ? hideControl['enterNum'] : true",
            },
          ],
          ref: "ref10",
          attrs: {
            label: _vm.$t("label.billCode"),
            required: "",
            title: _vm.$t("label.billCode"),
            params: "billCode",
            display: "billCode",
            url: _vm.winvBaseUrl + "app-inbound-receive/query-inbound-order",
            "url-params": { pageId: _vm.menuId },
            "pre-parse-url": _vm.urlData["billCode"],
            custom: _vm.customData["enterNum"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(10)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(10, "lov")
            },
            enter: function ($event) {
              return _vm.skipNext(20)
            },
            down: function ($event) {
              return _vm.skipNext(20)
            },
            choose: function ($event) {
              return _vm.handleChoose("enterNum", $event)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.inputData.enterNum,
            callback: function ($$v) {
              _vm.$set(_vm.inputData, "enterNum", $$v)
            },
            expression: "inputData.enterNum",
          },
        }),
        _c("ips-field", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["receiptNumber"]
                ? _vm.hideControl["receiptNumber"]
                : true,
              expression:
                "customData['receiptNumber'] ? hideControl['receiptNumber'] : true",
            },
          ],
          ref: "ref20",
          attrs: {
            label: _vm.$t("label.receiptNumber"),
            params: "receiptNumber",
            display: "receiptNumber",
            "pre-parse-url": _vm.urlData["receiptNumber"],
            custom: _vm.customData["receiptNumber"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            blur: function ($event) {
              return _vm.receiptNumBlur()
            },
            click: function ($event) {
              return _vm.handleCommonClick(20)
            },
            enter: function ($event) {
              return _vm.skipNext(30)
            },
            down: function ($event) {
              return _vm.skipNext(30)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.receiptNumber,
            callback: function ($$v) {
              _vm.receiptNumber = $$v
            },
            expression: "receiptNumber",
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["warehouseCode"]
                ? _vm.hideControl["warehouseCode"]
                : true,
              expression:
                "customData['warehouseCode'] ? hideControl['warehouseCode'] : true",
            },
          ],
          ref: "ref30",
          attrs: {
            label: _vm.$t("label.receiveWarehouse"),
            required: "",
            title: [
              _vm.$t("title.warehouseCode"),
              _vm.$t("title.warehouseName"),
            ],
            display: "warehouseCode",
            params: ["warehouseCode", "warehouseName"],
            url: _vm.wmdmBaseUrl + "warehouses",
            "url-params": { warehouseIdStr: _vm.warehouseIdStr },
            "pre-parse-url": _vm.urlData["warehouseCode"],
            custom: _vm.customData["warehouseCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(30)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(30, "lov")
            },
            enter: function ($event) {
              return _vm.skipNext(40)
            },
            down: function ($event) {
              return _vm.skipNext(40)
            },
            choose: function ($event) {
              return _vm.handleChoose("warehouseCode", $event)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.inputData.warehouseCode,
            callback: function ($$v) {
              _vm.$set(_vm.inputData, "warehouseCode", $$v)
            },
            expression: "inputData.warehouseCode",
          },
        }),
        _c("ips-lov", {
          ref: "ref40",
          attrs: {
            label: _vm.$t("label.location"),
            required: "",
            title: [
              _vm.$t("label.locationCode"),
              _vm.$t("label.whareaCode"),
              _vm.$t("label.whareaName"),
            ],
            display: "locationCode",
            params: ["locationCode", "whareaCode", "whareaName"],
            url: _vm.wmdmBaseUrl + "wms-locations/app",
            "url-params": {
              warehouseId: _vm.warehouseId,
              locationType: "T_RCV",
              whareaIdsString: _vm.whareaIdsString,
              whareaId: _vm.whareaIdsString ? _vm.whareaIdSku : null,
              locationCode: _vm.inputData.locationCode,
            },
            "pre-parse-url": _vm.urlData["locationCode"],
            custom: _vm.customData["locationCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(40)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(40, "lov")
            },
            enter: function ($event) {
              return _vm.skipNext("submitRef")
            },
            down: function ($event) {
              return _vm.skipNext("submitRef")
            },
            choose: function ($event) {
              return _vm.chooseLocation($event)
            },
          },
          model: {
            value: _vm.inputData.locationCode,
            callback: function ($$v) {
              _vm.$set(_vm.inputData, "locationCode", $$v)
            },
            expression: "inputData.locationCode",
          },
        }),
        _vm.orderData.billTypeName
          ? _c(
              "div",
              { staticClass: "out_box" },
              [
                _c("ips-value-cell", {
                  attrs: {
                    title: _vm.$t("label.billType"),
                    "image-flag": "",
                    value: _vm.orderData.billTypeName,
                  },
                }),
                _c("ips-value-cell", {
                  attrs: {
                    title: _vm.$t("label.vendor"),
                    "image-flag": "",
                    value: _vm.orderData.vendorName || "无",
                  },
                }),
                _c("ips-value-cell", {
                  attrs: {
                    title: _vm.$t("label.customer"),
                    "image-flag": "",
                    value: _vm.orderData.customerName || "无",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-footer" },
      [
        _c(
          "hips-button",
          {
            ref: "submitRef",
            class: [
              "ips-btn-normal",
              {
                "ips-btn-no-value": !_vm.orderData.billTypeName,
              },
            ],
            attrs: { size: "large" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonSubmit.apply(null, arguments)
              },
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleCommonSubmit.apply(null, arguments)
              },
              keypress: function ($event) {
                $event.preventDefault()
              },
            },
          },
          [
            _c("svg-icon", {
              attrs: { slot: "icon", "icon-class": "success" },
              slot: "icon",
            }),
            _vm._v("\n      " + _vm._s(_vm.$t("button.submit")) + "\n    "),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }