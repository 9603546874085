import AllReceive from '../views/all-receive'

let route = [
  {
    path: '/all-receive',
    name: 'AllReceive',
    component: AllReceive,
  },
]

export default route