<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header
        :title="$t('title.directlyShelves')"
        :is-index="true"
        :trigger="inputData.parentCidCode || inputData.cidCode || inputData.sku ? $t('button.receiveDetail') : null" 
        :on-go-detail="goToReceiveDetail"
        v-show="customData['receiveDetail'] ? hideControl['receiveDetail'] : true"
      />
    </div>
    <div class="ips-container">
      <!--父托盘-->
      <ips-lov
        :label="$t('label.parentCidCode')"
        v-model="inputData.parentCidCode" 
        params="parentCidCode"
        :title="$t('label.parentCidCode')"
        :url="`${winvBaseUrl}app-direct-putaway/query-parent-container`"
        :pre-parse-url="urlData['parentCidCode']"
        ref="ref0"
        :custom="customData['parentCidCode']"
        :source-parse-field="sourceParseField"
        v-show="!requireParentCidCode ? false : customData['parentCidCode'] ? hideControl['parentCidCode'] : true"
        @enter="skipNext(5)" 
        @click.stop="handleCommonClick(0)" 
        @clickIcon="handleCommonClick(0, 'lov')"
        @choose="handleChoose('parentCidCode', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!--托盘-->
      <ips-lov
        :label="$t('label.shelvesCid')"
        v-model="inputData.cidCode" 
        params="cidCode"
        :title="$t('label.shelvesCid')"
        :url="`${winvBaseUrl}app-direct-putaway/query-container`"
        :url-params="{ parentCidId }"
        :pre-parse-url="urlData['cidCode']"
        ref="ref5"
        :custom="customData['cidCode']"
        :source-parse-field="sourceParseField"
        v-show="customData['cidCode'] ? hideControl['cidCode'] : true"
        @enter="skipNext(10)" 
        @click.stop="handleCommonClick(5)" 
        @clickIcon="handleCommonClick(5, 'lov')"
        @choose="handleChoose('shelvesCid', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!--物料-->
      <ips-lov
        :label="$t('label.shelvesGoods')"
        v-model="inputData.sku"
        :required="!(inputData.parentCidCode || inputData.cidCode)"
        :title="$t('label.shelvesGoods')"
        params="sku"
        :url="`${winvBaseUrl}app-direct-putaway/query-goods`"
        :url-params="{ 
          parentCidId, 
          cidId: cidId,
          orgId: orgId,
          cidNull: inputData.cidCode || inputData.parentCidCode ? 0 : 1 
        }"
        :pre-parse-url="urlData['sku']"
        ref="ref10"
        :custom="customData['sku']"
        :source-parse-field="sourceParseField"
        v-show="customData['sku'] ? hideControl['sku'] : true"
        @enter="skipNext(20)"
        @click.stop="handleCommonClick(10)"
        @clickIcon="handleCommonClick(10, 'lov')"
        @choose="handleChoose('shelvesGoods', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!--来源单号-->
      <ips-lov
        :label="$t('label.shelvesBillNum')"
        v-model="inputData.sourceBillNumber"
        :title="$t('label.shelvesBillNum')"
        params="sourceBillCode"
        :url="`${winvBaseUrl}app-direct-putaway/query-source-bill-code`"
        :url-params="{ 
          parentCidId, 
          cidId: cidId, 
          orgId: orgId, 
          goodsId: goodsId, 
          cidNull: inputData.cidCode || inputData.parentCidCode ? 0 : 1 
        }"
        :pre-parse-url="urlData['sourceBillCode']"
        ref="ref20"
        :custom="customData['sourceBillNumber']"
        :source-parse-field="sourceParseField"
        v-show="customData['sourceBillNumber'] ? hideControl['sourceBillNumber'] : true"
        @enter="skipNext(30)"
        @click.stop="handleCommonClick(20)"
        @clickIcon="handleCommonClick(20, 'lov')"
        @choose="handleChoose('shelvesBillNum', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!--入库单号-->
      <ips-lov
        :label="$t('label.shelvesInboundNum')"
        v-model="inputData.inboundNum"
        :title="$t('label.shelvesInboundNum')"
        params="billCode"
        :url="`${winvBaseUrl}app-direct-putaway/query-bill-code`"
        :url-params="{ 
          parentCidId, 
          cidId: cidId, 
          orgId: orgId, 
          goodsId: goodsId, 
          sourceBillId: sourceBillId, 
          cidNull: inputData.cidCode || inputData.parentCidCode ? 0 : 1
        }"
        :pre-parse-url="urlData['billCode']"
        ref="ref30"
        :required="!(inputData.parentCidCode || inputData.cidCode)"
        :custom="customData['inboundNum']"
        :source-parse-field="sourceParseField"
        v-show="customData['inboundNum'] ? hideControl['inboundNum'] : true"
        @enter="inboundNumCheck"
        @click.stop="handleCommonClick(30)"
        @clickIcon="handleCommonClick(30, 'lov')"
        @choose="handleChoose('shelvesInboundNum', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />

      <!--接收号-->
      <ips-lov
        :label="$t('label.shelvesReceiveNum')"
        v-model="inputData.receiveNum"
        :title="$t('label.shelvesReceiveNum')"
        params="receiptNumber"
        :url="`${winvBaseUrl}app-direct-putaway/query-receipt-number`"
        :url-params="{
          parentCidId, 
          cidId: cidId,
          orgId: orgId,
          goodsId: goodsId,
          sourceBillId: sourceBillId,
          billId: billId,
          cidNull: inputData.cidCode || inputData.parentCidCode ? 0 : 1
        }"
        :pre-parse-url="urlData['receiptNumber']"
        ref="ref40"
        :custom="customData['receiveNum']"
        :source-parse-field="sourceParseField"
        v-show="customData['receiveNum'] ? hideControl['receiveNum'] : true"
        @enter="skipNext('submitRef')"
        @click.stop="handleCommonClick(40)"
        @clickIcon="handleCommonClick(40, 'lov')"
        @choose="handleChoose('shelvesReceiveNum', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <div class="out_box">
        <!-- 仓库 -->
        <ips-value-cell
          class="ips-value-cell"
          :title="$t('label.shelvesWarehouse')"
          :value="shelvesWarehouse"
          v-show="customData['shelvesWarehouse'] ? hideControl['shelvesWarehouse'] : true"
        />
        <!-- 货主 -->
        <ips-value-cell
          :title="$t('label.ownerCode')"
          :value="shelvesOwner"
          v-show="customData['shelvesOwner'] ? hideControl['shelvesOwner'] : true"
        />
        <!-- 接收数量 -->
        <ips-value-cell
          :title="$t('label.shelvesReceiveQty')"
          :value="shelvesReceiveQty"
          v-show="customData['shelvesReceiveQty'] ? hideControl['shelvesReceiveQty'] : true"
        />
        <!-- 供应商 -->
        <ips-value-cell
          :title="$t('label.shelvesVendor')"
          :value="shelvesVendor"
          v-show="customData['shelvesVendor'] ? hideControl['shelvesVendor'] : true"
        />
        <!-- 客户 -->
        <ips-value-cell
          :title="$t('label.shelvesCustomer')"
          :value="shelvesCustomer"
          v-show="customData['shelvesCustomer'] ? hideControl['shelvesCustomer'] : true"
        />
      </div>
    </div>
    <div class="ips-footer">
      <hips-button size="large" @click.stop="goToShelvesDetail" ref="submitRef">
        <svg-icon slot="icon" icon-class="success" />
        {{ $t('button.submit') }}
      </hips-button>
    </div>
  </hips-view>
</template>
<script>
import { Cell } from '@hips/vue-ui';
import { queryCidData, checkWholeContainer } from '../api/index';
import { storage } from '@/utils';

export default {
  name: 'DirectlyShelves',
  data() {
    return {
      inputData: {
        parentCidCode: '',
        cidCode: '',
        sku: '',
        sourceBillNumber: '',
        inboundNum: '',
        receiveNum: '',
      },
      orderData: {}, //入库单号数据
      shelvesWarehouse: '',
      shelvesOwner: '',
      shelvesReceiveQty: '',
      shelvesVendor: '',
      shelvesCustomer: '',
      shelvesWarehouseId: '',
      shelvesGoodsId: '',
      // whareaIdSku: '',
      parentCidId: '',
      cidId: '',
      receiveId: '',
      isOnlySingle: '',
      orgId: '',
      goodsId: '',
      sourceBillId: '',
      billId: '',
      billTypeId: '',
      billType: '',
      toPageStatus: '',
      pendingQty: '',
      isbatch: '',
      serialType: '',
      urlData: {},
      receiveRecord: { 
        parentCid: 0, // 父托盘接收记录基准数量
        cid: 0, // 子托盘接收记录基准数量
      },
      customData: {}, // 个性化配置信息列表
      hideControl: {}, // 非必输字段的显隐控制
      sourceParseField: {}, // 界面中所有的来源解析字段
      requireParentCidCode: 1, // 页面功能参数控制是否启用父托盘
    };
  },
  components: {
    [Cell.name]: Cell,
  },
  computed: {
    inputFieldLists() {
      const Required = !(this.inputData.parentCidCode || this.inputData.cidCode);
      return [
        { type: 'lov', required: false, ref: 0, bind: 'inputData.parentCidCode', field: 'parentCidCode' },
        { type: 'lov', required: false, ref: 5, bind: 'inputData.cidCode', field: 'cidCode' },
        { type: 'lov', required: Required, ref: 10, bind: 'inputData.sku', field: 'sku' },
        { type: 'lov', required: false, ref: 20, bind: 'inputData.sourceBillNumber', field: 'sourceBillNumber' },
        { type: 'lov', required: Required, ref: 30, bind: 'inputData.inboundNum', field: 'inboundNum' },
        { type: 'lov', required: false, ref: 40, bind: 'inputData.receiveNum', field: 'receiveNum' },
      ];
    },
  },
  created() {
    this.inputFieldList = [
      { type: 'lov', required: false, ref: 0, bind: 'inputData.parentCidCode', field: 'parentCidCode' },
      { type: 'lov', required: false, ref: 5, bind: 'inputData.cidCode', field: 'cidCode' },
      { type: 'lov', required: true, ref: 10, bind: 'inputData.sku', field: 'sku' },
      { type: 'lov', required: false, ref: 20, bind: 'inputData.sourceBillNumber', field: 'sourceBillNumber' },
      { type: 'lov', required: true, ref: 30, bind: 'inputData.inboundNum', field: 'inboundNum' },
      { type: 'lov', required: false, ref: 40, bind: 'inputData.receiveNum', field: 'receiveNum' },
    ];
    const { hideControl, customObj } = this.handleCustomData(storage.get('customData'));
    this.customData = customObj;
    this.hideControl = hideControl;
  },
  methods: {
    handleChoose(type, obj) {
      switch (type) {
        case 'parentCidCode': 
          this.parentCidId = obj.parentCidId;
          this.handleBlur('parentCidCode');
          break;
        case 'shelvesCid':
          this.cidId = obj.cidId;
          // 如果托盘存在父托盘则自动带出, 光标重定位
          if(obj.parentCidId && !this.inputData.parentCidCode) {
            this.inputData.parentCidCode = obj.parentCidCode;
            this.inputData.parentCidId = obj.parentCidId;
            if(this.requireParentCidCode) {
              setTimeout(() => this.skipNext(0));
              return;
            }
          }
          this.handleBlur('shelvesCid');
          break;
        case 'shelvesGoods':
          this.goodsId = obj.goodsId;
          this.pendingQty = obj.pendingQty;
          this.isbatch = obj.isbatch;
          this.serialType = obj.serialType;
          // this.whareaIdSku = obj.whareaId;
          this.handleBlur('shelvesGoods');
          break;
        case 'shelvesBillNum':
          this.sourceBillId = obj.id;
          this.billCode = obj.inboundCode;
          this.billId = obj.inboundId;
          this.handleBlur('shelvesBillNum');
          break;
        case 'shelvesInboundNum':
          this.billId = obj.billId;
          this.billTypeId = obj.billTypeId;
          this.billType = obj.billType;
          this.handleBlur('shelvesInboundNum');
          break;
        case 'shelvesReceiveNum':
          this.handleBlur('shelvesReceiveNum');
          break;
        default:
          return null;
      }
    },

    // 保存来源解析字段
    async saveSourceField(fieldObj) {
      this.saveSourceParseField(this, fieldObj);
    },

    // 字段聚焦时赋值操作
    async valuedByFoucs(options={}) {
      const { custom ={} , currentValue } = options;
      await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
    },

    inboundNumCheck() {
      const { cidCode, parentCidCode, inboundNum, sku } = this.inputData;
      if (cidCode || parentCidCode) {
        this.skipNext(40);
      } else if (!parentCidCode && !cidCode && !inboundNum && !sku) {
        this.skipNext(10);
      } else if (!parentCidCode && !cidCode && !inboundNum) {
        this.skipNext(30);
      } else if (!cidCode && !parentCidCode && this.inputData.inboundNum) {
        this.skipNext(40);
      }
    },
    goToSingle(resContent) {
      this.shelvesWarehouseId = resContent[0].warehouseId;
      // 展示字段
      this.shelvesWarehouse = resContent[0].warehouseName;
      this.shelvesOwner = resContent[0].ownerName;
      this.shelvesReceiveQty = resContent[0].receiveQty;
      this.shelvesVendor = resContent[0].vendorName;
      this.shelvesCustomer = resContent[0].customer;
      // lov赋值
      this.inputData.cidCode = resContent[0].cidCode;
      this.inputData.cidId = resContent[0].cidId;
      // this.cidId = resContent[0].cidId;
      this.inputData.sku = resContent[0].sku;
      this.goodsId = resContent[0].goodsId;
      this.inputData.sourceBillNumber = resContent[0].sourceBillCode;
      this.sourceBillId = resContent[0].sourceBillId;
      this.billId = resContent[0].billId;
      this.isbatch = resContent[0].isbatch;
      this.inputData.inboundNum = resContent[0].billCode;
      this.inputData.receiveNum = resContent[0].receiptNumber;
      // 查询所需id
      this.orgId = resContent[0].orgId;
      this.goodsId = resContent[0].goodsId;
      this.sourceBillId = resContent[0].sourceBillId;
      this.billId = resContent[0].billId;
      this.billTypeId = resContent[0].billTypeId;
      this.billType = resContent[0].billType;
      this.serialType = resContent[0].serialType;
      // 跳转到单条页面
      this.toPageStatus = 'single';
      this.skipNext('submitRef');
    },
    goToSku(resContent) {
      let num = 0;
      resContent.forEach((v) => {
        num = this.$common.numberAdd(num, v.receiveQty);
      });

      this.shelvesReceiveQty = num;
      this.shelvesWarehouse = resContent[0].warehouseName;
      this.shelvesOwner = resContent[0].ownerName;
      // this.shelvesReceiveQty = resContent[0].receiveQty;
      this.shelvesVendor = this.billId ? resContent[0].vendorName : '';
      this.shelvesCustomer = this.billId ? resContent[0].customer : '';
      // 跳转到只有物料页面
      this.toPageStatus = 'sku';
    },
    goToMultiple(resContent) {
      this.shelvesWarehouse = resContent[0].warehouseName;
      this.shelvesWarehouseId = resContent[0].warehouseId;
      let num = 0;
      let serialTypeBean = false;
      resContent.forEach((v) => {        
        num = this.$common.numberAdd(num, v.receiveQty);
        if (v.serialType !== 'UNCTRL' && v.serialType !== 'OUT_CTRL') {
          serialTypeBean = true;
          this.serialType = v.serialType;
        }
      });

      this.serialType = serialTypeBean ? this.serialType : 'UNCTRL';
      this.shelvesReceiveQty = num;
      this.serialType = resContent[0].serialType;
      this.billType = resContent[0].billType;
      this.billTypeId = resContent[0].billTypeId;
      this.shelvesVendor = this.billId ? resContent[0].vendorName : '';
      this.shelvesCustomer = this.billId ? resContent[0].customer : '';
      // 跳转到多条页面
      this.toPageStatus = 'multiple';
    },
    async handleBlur(type) {
      let data = {
        params: {
          parentCidId: this.parentCidId,
          cidId: this.cidId,
          cidNull: this.cidId || this.parentCidId ? 0 : 1,
          goodsId: this.goodsId,
          sourceBillId: this.sourceBillId,
          billId: this.billId,
          receiptNumber: this.inputData.receiveNum,
          onlyQueryParentCidFlag: this.inputData.parentCidCode && (this.cidId || this.goodsId || this.sourceBillId || this.billId || this.inputData.receiveNum) ? true : false,
        },
      };
      queryCidData(data).then((res) => {
        const resContent = res.content;
        this.resContent = resContent;
        if (type === 'parentCidCode') {
          this.receiveRecord.parentCid = resContent.length;
          if (res && res.content && res.content.length > 0) {
            if (resContent.length === 1) {
              this.isOnlySingle = true;
              this.goToSingle(resContent);
            } else {
              this.goToMultiple(resContent);
            }
            return;
          }
        }
        if (type === 'shelvesCid') {
          this.receiveRecord.cid = resContent.length;
          if (res && res.content && res.content.length > 0) {
            if (resContent.length === 1) {
              this.isOnlySingle = true;
              this.goToSingle(resContent);
            } else {
              this.goToMultiple(resContent);
            }
            return;
          }
        }

        if (type === 'shelvesGoods') {
            if (res && res.content.length > 0) {
              if (resContent.length === 1) {
                this.shelvesWarehouseId = resContent[0].warehouseId;
                // 展示字段
                this.shelvesWarehouse = resContent[0].warehouseName;
                this.shelvesOwner = resContent[0].ownerName;
                this.shelvesReceiveQty = resContent[0].receiveQty;
                this.shelvesVendor = resContent[0].vendorName;
                this.shelvesCustomer = resContent[0].customer;
                // lov赋值
                this.inputData.sourceBillNumber = resContent[0].sourceBillCode;
                this.sourceBillId = resContent[0].sourceBillId;
                this.billId = resContent[0].billId;
                this.inputData.inboundNum = resContent[0].billCode;
                this.inputData.receiveNum = resContent[0].receiptNumber;
                // 查询所需id
                this.orgId = resContent[0].orgId;
                this.goodsId = resContent[0].goodsId;
                this.sourceBillId = resContent[0].sourceBillId;
                this.billId = resContent[0].billId;
                this.billTypeId = resContent[0].billTypeId;
                this.billType = resContent[0].billType;
                this.serialType = resContent[0].serialType;
                if(!this.inputData.parentCidCode&& !this.inputData.cidCode) {
                  this.toPageStatus = 'sku';
                } else {
                  this.isOnlySingle = true;
                  this.goToSingle(resContent);
                }
                this.skipNext('submitRef');
              } else {
                this.goToSku(resContent);
              }
            }
          } else {
            this.shelvesWarehouse = resContent[0].warehouseName;
            this.shelvesOwner = this.goodsId ? resContent[0].ownerName : '';
            this.shelvesReceiveQty = resContent[0].receiveQty;
            this.shelvesVendor = this.billId ? resContent[0].vendorName : '';
            this.shelvesCustomer = this.billId ? resContent[0].customer : '';
            if (this.goodsId && resContent.length === 1) {
              this.shelvesWarehouseId = resContent[0].warehouseId;
              // 展示字段
              this.shelvesWarehouse = resContent[0].warehouseName;
              this.shelvesOwner = resContent[0].ownerName;
              this.shelvesReceiveQty = resContent[0].receiveQty;
              this.shelvesVendor = resContent[0].vendorName;
              this.shelvesCustomer = resContent[0].customer;
              // lov赋值
              this.inputData.sku = resContent[0].sku;
              this.goodsId = resContent[0].goodsId;
              this.inputData.sourceBillNumber = resContent[0].sourceBillCode;
              this.sourceBillId = resContent[0].sourceBillId;
              this.billId = resContent[0].billId;
              this.isbatch = resContent[0].isbatch;
              this.inputData.inboundNum = resContent[0].billCode;
              this.inputData.receiveNum = resContent[0].receiptNumber;
              // 查询所需id
              this.orgId = resContent[0].orgId;
              this.goodsId = resContent[0].goodsId;
              this.sourceBillId = resContent[0].sourceBillId;
              this.billId = resContent[0].billId;
              this.billTypeId = resContent[0].billTypeId;
              this.billType = resContent[0].billType;
              this.serialType = resContent[0].serialType;
              this.toPageStatus = 'sku';
              this.skipNext('submitRef');
            } else {
              this.goToSku(resContent);
            }
          }

        // if (this.cidId) {
        //   this.receiveRecord.cid = resContent.length;
        //   if (type === 'shelvesCid') {
        //     if (res && res.content && res.content.length > 0) {
        //       if (resContent.length === 1) {
        //         this.isOnlySingle = true;
        //         this.goToSingle(resContent);
        //       } else {
        //         this.goToMultiple(resContent);
        //       }
        //     }
        //   } else {
        //     this.shelvesWarehouse = resContent[0].warehouseName;
        //     this.shelvesOwner = this.goodsId ? resContent[0].ownerName : '';
        //     this.shelvesReceiveQty = resContent[0].receiveQty;
        //     this.shelvesVendor = this.billId ? resContent[0].vendorName : '';
        //     this.shelvesCustomer = this.billId ? resContent[0].customer : '';
        //     if (this.goodsId && resContent.length === 1) {
        //       this.isOnlySingle = false;
        //       this.goToSingle(resContent);
        //     } else {
        //       this.goToMultiple(resContent);
        //     }
        //   }
        // } else if (!this.parentCidId && !this.cidId && this.goodsId) {
          
        // }
      });
    },
    goToReceiveDetail() {
      if ((!this.inputData.parentCidCode &&!this.inputData.cidCode && !this.inputData.sku) || (!this.inputData.parentCidCode && !this.inputData.cidCode && !this.inputData.inboundNum)) {
        this.$hint.showFailed(this.$i18n.t('message.checkRequired'), true);
        return;
      }
      const param = {
        name: 'receiveDetail',
        params: {
          parentCidId: this.parentCidId,
          cidId: this.cidId,
          cidNull: this.cidId || this.parentCidId ? 0 : 1,
          goodsId: this.goodsId,
          sourceBillId: this.sourceBillId,
          billId: this.billId,
          receiptNumber: this.inputData.receiveNum,
          //个性化配置信息
          sourceParseField: this.sourceParseField,
          customData: this.customData,
          hideControl: this.hideControl,
        },
      };
      this.$store.commit('pushKeepAlives', 'receiveDetail');
      this.$router.pushPage(param);
    },
    async goToShelvesDetail() {
      // const { parentCidCode, cidCode } = this.inputData;
      if ((!this.inputData.parentCidCode && !this.inputData.cidCode && !this.inputData.sku) || (!this.inputData.parentCidCode  && !this.inputData.cidCode && !this.inputData.inboundNum)) {
        this.$hint.showFailed(this.$i18n.t('message.checkRequired'), true);
        return;
      }
      const wholeCheckData = {
        cidId: this.parentCidId || this.cidId,
        inboundReceiptRecordPutAwayList: [...this.resContent]
      };
      // 进入整托界面前校验
      if(this.toPageStatus === 'multiple') await checkWholeContainer(wholeCheckData);
      const firstPageData = {
        parentCidId: this.parentCidId,
        cidId: this.cidId || this.inputData.cidId,
        cidNull: this.cidId || this.parentCidId ? 0 : 1,
        cidCode: this.inputData.cidCode,
        goodsId: this.goodsId,
        sku: this.inputData.sku,
        sourceBillId: this.sourceBillId,
        sourceBillCode: this.inputData.sourceBillNumber,
        billId: this.billId,
        billCode: this.inputData.inboundNum,
        receiptNumber: this.inputData.receiveNum,
      };
      if (this.toPageStatus === 'sku') {
        const param = {
          name: 'shelvesDetailSku',
          params: {
            cidId: this.cidId,
            parentCidId: this.parentCidId,
            cidNull: this.cidId || this.parentCidId  ? 0 : 1,
            // whareaIdSku: this.whareaIdSku,
            goodsId: this.goodsId,
            sourceBillId: this.sourceBillId,
            billId: this.billId,
            pendingQty: this.pendingQty,
            isbatch: this.isbatch,
            serialType: this.serialType,
            orgId: this.orgId,
            billTypeId: this.billTypeId,
            billType: this.billType,
            receiptNumber: this.inputData.receiveNum,
            firstPageData: firstPageData,
            requireParentCidCode: this.requireParentCidCode, // 是否启用父托盘
            // 个性化配置信息
            sourceParseField: this.sourceParseField,
            customData: this.customData,
            hideControl: this.hideControl,
            skuFlag: true,
          },
        };
        this.$store.commit('pushKeepAlives', 'shelvesDetailSku');
        this.$router.pushPage(param);
      } else if (this.toPageStatus === 'single') {
        const param = {
          name: 'shelvesDetailSingle',
          params: {
            isOnlySingle: this.isOnlySingle,
            parentCidId: this.parentCidId,
            parentCidCode: this.inputData.parentCidCode,
            receiveRecord: this.receiveRecord,
            cidId: this.cidId || this.inputData.cidId,
            cidNull: this.cidId || this.parentCidId ? 0 : 1,
            // whareaIdSku: this.whareaIdSku,
            goodsId: this.goodsId,
            sourceBillId: this.sourceBillId,
            billId: this.billId,
            isbatch: this.isbatch,
            serialType: this.serialType,
            billTypeId: this.billTypeId,
            billType: this.billType,
            receiptNumber: this.inputData.receiveNum,
            firstPageData: firstPageData,
            requireParentCidCode: this.requireParentCidCode, // 是否启用父托盘
            // 个性化配置信息
            sourceParseField: this.sourceParseField,
            customData: this.customData,
            hideControl: this.hideControl,
          },
        };
        this.$store.commit('pushKeepAlives', 'shelvesDetailSingle');
        this.$router.pushPage(param);
      } else if (this.toPageStatus === 'multiple') {
        const basicReceiveLength = this.inputData.parentCidCode ? this.receiveRecord.parentCid : this.receiveRecord.cid;
        if(this.resContent.length !== basicReceiveLength) {
          this.$hint.showFailed(this.inputData.parentCidCode ? this.inputData.parentCidCode : this.inputData.cidCode `${this.$i18n.t('message.judgeCidReceiveNumber')}`, true);
        }
        const param = {
          name: 'shelvesDetailMultiple',
          params: {
            parentCidId: this.parentCidId,
            cidId: this.cidId || this.inputData.cidId,
            cidNull: this.cidId || this.parentCidId ? 0 : 1,
            // whareaIdSku: this.whareaIdSku,
            goodsId: this.goodsId,
            sourceBillId: this.sourceBillId,
            billId: this.billId,
            serialType: this.serialType,
            billTypeId: this.billTypeId,
            billType: this.billType,
            firstPageData: firstPageData,
            receiptNumber: this.inputData.receiveNum,
            warehouseId: this.shelvesWarehouseId,
            // 个性化配置信息
            sourceParseField: this.sourceParseField,
            customData: this.customData,
            hideControl: this.hideControl,
          },
        };
        this.$store.commit('pushKeepAlives', 'shelvesDetailMultiple');
        this.$router.pushPage(param);
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (
      to.name === 'receiveDetail' ||
      to.name === 'shelvesDetailSku' ||
      to.name === 'shelvesDetailSingle' ||
      to.name === 'shelvesDetailMultiple'
    ) {
      this.$store.commit('pushKeepAlives', 'DirectlyShelves');
    } else {
      this.$store.commit('deleteKeepAlives', 'DirectlyShelves');
    }
    this.$nextTick(() => {
      next();
    });
  },
  activated() {
    const formSerial = JSON.parse(sessionStorage.getItem('formSerial'));
    if (formSerial === '1') {
      this.shelvesWarehouseId = '';
      // 展示字段
      this.shelvesWarehouse = '';
      this.shelvesOwner = '';
      this.shelvesReceiveQty = '';
      this.shelvesVendor = '';
      this.shelvesCustomer = '';
      // lov赋值
      this.inputData.sku = '';
      this.inputData.parentCidCode = '';
      this.inputData.cidCode = '';
      this.goodsId = '';
      this.inputData.sourceBillNumber = '';
      this.sourceBillId = '';
      this.billId = '';
      this.inputData.inboundNum = '';
      this.inputData.receiveNum = '';
      // 查询所需id
      this.orgId = '';
      this.cidId = '';
      this.goodsId = '';
      this.sourceBillId = '';
      this.billId = '';
      this.billTypeId = '';
      this.billType = '';
      this.serialType = '';
      this.skipNext(0);
    }
    sessionStorage.removeItem('formSerial');
  },
  watch: {
    'inputData.parentCidCode': function (val) {
      if (!val) {
        this.parentCidId = undefined;
        this.cidId = null;
        this.inputData.cidId = null;
        this.inputData.parentCidId = null;
        this.shelvesWarehouseId = '';
        // 展示字段
        this.shelvesWarehouse = '';
        this.shelvesOwner = '';
        this.shelvesReceiveQty = '';
        this.shelvesVendor = '';
        this.shelvesCustomer = '';
        // lov赋值
        this.inputData.sku = '';
        this.goodsId = '';
        this.inputData.sourceBillNumber = '';
        this.sourceBillId = '';
        this.billId = '';
        this.inputData.inboundNum = '';
        this.inputData.receiveNum = '';
        this.inputData.cidCode = null;
        // 查询所需id
        this.orgId = '';
        this.goodsId = '';
        this.sourceBillId = '';
        this.billId = '';
        this.billTypeId = '';
        this.billType = '';
        this.serialType = '';
      }
    },
    'inputData.cidCode': function (val) {
      if (!val) {
        this.cidId = undefined;
        this.shelvesWarehouseId = '';
        // 展示字段
        this.shelvesWarehouse = '';
        this.shelvesOwner = '';
        this.shelvesReceiveQty = '';
        this.shelvesVendor = '';
        this.shelvesCustomer = '';
        // lov赋值
        this.inputData.sku = '';
        this.goodsId = '';
        this.inputData.sourceBillNumber = '';
        this.sourceBillId = '';
        this.billId = '';
        this.inputData.inboundNum = '';
        this.inputData.receiveNum = '';
        // 查询所需id
        this.orgId = '';
        this.goodsId = '';
        this.sourceBillId = '';
        this.billId = '';
        this.billTypeId = '';
        this.billType = '';
        this.serialType = '';
      }
    },
    'inputData.sku': function (val) {
      if (!val) {
        this.goodsId = undefined;
        this.shelvesOwner = '';
        this.shelvesWarehouseId = '';
        // 展示字段
        this.shelvesWarehouse = '';
        this.shelvesOwner = '';
        this.shelvesReceiveQty = '';
        this.shelvesVendor = '';
        this.shelvesCustomer = '';
        // lov赋值
        this.inputData.sku = '';
        this.goodsId = '';
        this.inputData.sourceBillNumber = '';
        this.sourceBillId = '';
        this.billId = '';
        this.inputData.inboundNum = '';
        this.inputData.receiveNum = '';
        // 查询所需id
        this.orgId = '';
        this.goodsId = '';
        this.sourceBillId = '';
        this.billId = '';
        this.billTypeId = '';
        this.billType = '';
        this.serialType = '';
        this.handleBlur('shelvesGoods');
      }
    },
    'inputData.sourceBillNumber': function (val) {
      if (!val) {
        this.sourceBillId = undefined;
      }
    },
    'inputData.inboundNum': function (val) {
      if (!val) {
        this.billId = undefined;
        this.shelvesVendor = '';
        this.shelvesCustomer = '';
      }
    },
    'inputData.receiveNum': function (val) {
      if (!val) {
        this.inputData.receiveNum = '';
        this.handleBlur('shelvesReceiveNum');
      }
    },
    'inputFieldLists': function (val) {
      this.inputFieldList = val;
    }
  },
  async mounted() {
    this.urlData = this.handleData('DIRECTLY_SHELVES_QUERY');
    // 获取页面功能参数
    const { PARENT_CID_ENABLE } = await this.fetchPageFunctionConfig({
      functionCode: 'DIRECTLY_SHELVES',
      deviceCode: 'PDA',
      paramCode: 'PARENT_CID_ENABLE', // 是否启用父托盘
    });
    this.requireParentCidCode = Number(PARENT_CID_ENABLE);
    this.requireParentCidCode ? this.skipNext(0) : this.skipNext(5);
  },
};
</script>
