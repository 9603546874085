<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.shelvesDetail')" :is-index="false" />
    </div>
    <div class="ips-container">
      <div class="receive-detail-sku-card" v-for="(item, index) in detailData" :key="index">
        <hips-row>
          <div class="receive-detail-sku-card-sku">
            {{ item.sku || '' }}
          </div>
        </hips-row>
        <hips-row>
          <div class="receive-detail-sku-card-second">
            <hips-col span="12">
              <span class="font-color">{{ $t('label.shelvesPendingQty') }}</span>：
              <span style="font-weight: bold">{{ pendingQty || '' }}</span>
            </hips-col>
            <hips-col span="12">
              <span class="font-color">{{ $t('label.shelvesUom') }}</span>：
              <span style="font-weight: bold">{{ item.uomName || '' }}</span>
            </hips-col>
          </div>
        </hips-row>
        <hips-row>
          <div class="receive-detail-sku-card-three">
            {{ item.goodsName || '' }}
          </div>
        </hips-row>
        <!--批次-->
      </div>
      <div style="margin-top: 16px" v-if="isbatch">
        <!-- 批次 -->
        <ips-lov
          :label="$t('label.batch')"
          :required="isbatch===1"
          v-model="inputData.batchCode"
          :title="$t('label.batch')"
          params="batchCode"
          :url="`${winvBaseUrl}app-direct-putaway/query-batch`"
          :url-params="{
            'cidId': cidId,
            'orgId': orgId,
            'goodsId': goodsId,
            'sourceBillId': sourceBillId,
            'billId': billId,
            'receiptNumber': receiptNumber,
            'cidNull': 1
          }"
          :pre-parse-url="urlData['batchCode']"
          ref="ref0"
          :custom="customData['batchCode']"
          :source-parse-field="sourceParseField"
          v-show="isbatch===1 ? (customData['batchCode'] ? hideControl['batchCode'] : isbatch===1) : false"
          @enter="batchToSerial"
          @click.stop="handleCommonClick(0)"
          @clickIcon="handleCommonClick(0, 'lov')"
          @choose="handleChoose('batch', $event)"
          @setSourceField="saveSourceField($event)"
          @valuedByFoucs="valuedByFoucs($event)"
        />
      </div>
      <hips-row v-if="serialType!=='UNCTRL'&& serialType!=='OUT_CTRL'" style="margin-top: 16px">
        <hips-col span="14">
          <ips-input-number
            v-model="receiveQty"
            :label="$t('label.inboundNum')"
            required
            ref="ref10"
            is-control
            :min="null"
            :precision="0"
            :enable-serial="serialType"
            :custom="customData['receiveQty']"
            :source-parse-field="sourceParseField"
            v-show="customData['receiveQty'] ? hideControl['receiveQty'] : true"
            @enter="checkImpQty"
            @blur="changeSerialQty"
            @setSourceField="saveSourceField($event)"
            @valuedByFoucs="valuedByFoucs($event)"
          />
        </hips-col>
        <hips-col span="10">
          <hips-button
            size="large"
            @click.stop="goToSerialDetail"
            ref="refSerail"
            v-show="customData['serialNumber'] ? hideControl['serialNumber'] : true"
            class="serial-button-detail"
          >
            {{ $t('button.serialNumber') }} ({{ serialReceiveQtyImp!==0?serialReceiveQtyImp:serialReceiveQty }})
          </hips-button>
        </hips-col>
      </hips-row>
      <div
        style="margin-top: 16px"
        v-if="serialType==='UNCTRL' || serialType==='OUT_CTRL'"
      >
        <ips-input-number
          v-model="receiveQty"
          :label="$t('label.inboundNum')"
          required
          is-control
          ref="ref50"
          :min="null"
          :more-than-zero="true"
          :custom="customData['receiveQty']"
          :enable-serial="serialType"
          :source-parse-field="sourceParseField"
          v-show="customData['receiveQty'] ? hideControl['receiveQty'] : true"
          @enter="checkImpQtyUnSerial"
          @down="checkImpQtyUnSerial"
          @blur="checkImpQtyUnSerial"
          not-auto-fill
          @setSourceField="saveSourceField($event)"
          @valuedByFoucs="valuedByFoucs($event)"
        />
      </div>
      <div style="margin-top: 16px">
        <!--入库库位-->
        <ips-lov
          :label="$t('label.storageLocation')"
          v-model="inputData.locationCode" :title="$t('label.storageLocation')"
          required
          params="locationCode"
          :url="`${wmdmBaseUrl}wms-locations`"
          :url-params="{ 'warehouseId': warehouseId, 'whareaId': whareaId }"
          :pre-parse-url="urlData['locationCode']"
          ref="ref20"
          :custom="customData['locationCode']"
          :source-parse-field="sourceParseField"
          v-show="customData['locationCode'] ? hideControl['locationCode'] : true"
          @enter="inboundLocationEnter"
          @click.stop="handleCommonClick(20)"
          @clickIcon="handleCommonClick(20, 'lov')"
          @choose="handleChoose('storageLocation', $event)"
          @setSourceField="saveSourceField($event)"
          @valuedByFoucs="valuedByFoucs($event)"
        />
      </div>
      <!-- 推荐库位 -->
      <div class="recommend-loaction">
        <span>{{ $t('label.recommandLoca') }} :</span>
        <span>{{ defaultLocation }}</span>
      </div>
      <div style="margin-top: 16px">
        <!--入库托盘-->
        <ips-field
          v-model="inputData.inboundCidCode"
          :label="$t('label.inboundCid')"
          params="inCidCode" display="inCidCode"
          :pre-parse-url="urlData['inCidCode']"
          ref="ref30" 
          :custom="customData['inboundCidCode']"
          :source-parse-field="sourceParseField"
          v-show="inputData.containerManageFlag===1 ? (customData['inboundCidCode'] ? hideControl['inboundCidCode'] : inputData.containerManageFlag===1) : false"
          @enter="inputData.inboundCidCode && requireParentCidCode ? skipNext(40) : skipNext('submitRef')"
          @down="inputData.inboundCidCode && requireParentCidCode? skipNext(40) : skipNext('submitRef')"
          @click.stop="handleCommonClick(50)"
          @blur="checkInboundCidBlur()"
          @setSourceField="saveSourceField($event)"
          @valuedByFoucs="valuedByFoucs($event)"
        />
      </div>
      <div style="margin-top: 16px" v-if="requireParentCidCode && inputData.containerManageFlag===1">
        <!--入库父托盘-->
        <ips-field
          v-model="inputData.inboundParentCidCode"
          :label="$t('label.inboundParentCid')"
          :disabled="!inputData.inboundCidCode || disableParentCidCode"
          ref="ref40"
          :custom="customData['inboundParentCidCode']"
          :source-parse-field="sourceParseField"
          v-show="!requireParentCidCode ? false : inputData.containerManageFlag===1 ? (customData['inboundParentCidCode'] ? hideControl['inboundParentCidCode'] : inputData.containerManageFlag===1) : false"
          @enter="skipNext('submitRef')"
          @down="skipNext('submitRef')"
          @click.stop="handleCommonClick(40)"
          @blur="checkInboundCidBlur('parent')"
          @setSourceField="saveSourceField($event)"
          @valuedByFoucs="valuedByFoucs($event)"
        />
      </div>
    </div>
    <div class="ips-footer">
      <hips-button size="large" @click.stop="submitShelvesCheck" ref="submitRef">
        <svg-icon slot="icon" icon-class="success" />
        {{ $t('button.submit') }}
      </hips-button>
    </div>
  </hips-view>
</template>
<script>
import { Cell, Row, Col } from '@hips/vue-ui';
import { queryCidData, querySummaryData, queryDefaultLocation, checkInboundCid, handleSubmit, queryGoodsToSubmit } from '../api/index';

export default {
  name: 'ShelvesDetailSku',
  data() {
    return {
      cidId: '',
      cidCode: '',
      orgId: '',
      orgCode: '',
      goodsId: '',
      sourceBillId: '',
      billId: '',
      billCode: '',
      pendingQty: '',
      batchPendingQty: '', // 批次可处理数量
      receiveQty: '',
      isbatch: '',
      serialType: '',
      warehouseId: '',
      billTypeId: '',
      billType: '',
      whareaId: '',
      recordSize: '',
      serialReceiveQty: 0,
      serialReceiveQtyImp: 0,
      defaultLocation: '',
      whareaIdSku: '',
      //提交需要数据
      batchId: '',
      firstPageData: '',
      receiptNumber: '',
      detailData: [],
      inboundSerialReceiptList: [],
      toSerialOldList: [],
      goodsObj: '',
      inputData: {
        batchCode: '',
        locationCode: '',
        locationId: '',
        inboundCidCode: '',
        inboundCidId: '',
        containerManageFlag: '',
      },
      requireParentCidCode: 1,
      disableParentCidCode: false, // 入库父托盘是否禁用
      urlData: {},
      customData: {}, // 个性化配置信息列表
      hideControl: {}, // 非必输字段的显隐控制
      sourceParseField: {}, // 界面中所有的来源解析字段
    };
  },
  components: {
    [Cell.name]: Cell,
    [Row.name]: Row,
    [Col.name]: Col,
  },
  methods: {
    async getDetailData() {
      this.$hint.showLoading();
      let data = {
        params: {
          cidNull: this.cidNull,
          cidId: this.cidId,
          parentCidId: this.parentCidId,
          goodsId: this.goodsId,
          sourceBillId: this.sourceBillId,
          billId: this.billId,
          receiptNumber: this.receiptNumber,
          billTypeId: this.billTypeId,
          billType: this.billType,
        },
      };
      const resDetail = await queryCidData(data);
      this.whareaId = resDetail.content[0].assignWhareaId;
      this.recordSize = resDetail.content.length;
      if (!resDetail.content[0]) {
        this.$hint.hideLoading();
      }
      this.orgCode = resDetail.content[0].orgCode;
      this.orgId = resDetail.content[0].orgId;
      // this.cidId = resDetail.content[0].cidId;
      this.cidCode = resDetail.content[0].cidCode;
      this.billId = resDetail.content[0].billId;
      this.billCode = resDetail.content[0].billCode;
      this.warehouseId = resDetail.content[0].warehouseId;

      const res = await querySummaryData(data);

      this.detailData.push(res.content[0]);
      this.receiveQty = res.content[0].pendingQty;
      this.pendingQty = res.content[0].pendingQty;
      this.batchPendingQty = res.content[0].pendingQty;
      this.serialReceiveQty = res.content[0].pendingQty;
      this.serialReceiveQtyImp = res.content[0].pendingQty;
      const resLocation = await queryDefaultLocation(resDetail.content);
      // const resLocation = await queryDefaultLocation(resDetail.content[0]);
      this.defaultLocation = resLocation.locationCode;
      this.queryGoodsObj();
      this.$hint.hideLoading();
    },
    handleChoose(type, obj) {
      switch (type) {
        case 'batch':
          this.batchId = obj.batchId;
          this.receiveQty = obj.receiveQty;
          this.batchPendingQty = obj.receiveQty;
          break;
        case 'storageLocation':
          this.inputData.locationId = obj.locationId;
          this.inputData.locationCode = obj.locationCode;
          this.inputData.whareaCode = obj.whareaCode;
          this.inputData.whareaId = obj.whareaId;
          this.inputData.containerManageFlag = obj.containerManageFlag;
          break;
      }
    },
    async queryGoodsObj() {
      let data = {
        params: {
          // cidId: this.cidId,
          goodsId: this.goodsId,
          orgId: this.orgId,
        },
      };
      const res = await queryGoodsToSubmit(data);
      this.goodsObj = res.content[0];
    },
    async goToSerialDetail() {
      if (this.receiveQty > 0) {
        let data = {
          cidNull: 1,
          // cidId: this.cidId,
          goodsId: this.goodsId,
          sourceBillId: this.sourceBillId,
          billId: this.billId,
          batchId: this.batchId,
          serialType: this.serialType,
          // serialNumber: this.serialNumber,
        };
        if (this.pendingQty === this.receiveQty) {
          const url = `${this.winvBaseUrl}app-direct-putaway/query-serial`;
          const res = await this.$http.post(url, data);
          this.inboundSerialReceiptList = res;
          this.toSerialOldList = res;
          this.serialReceiveQty = this.receiveQty;
        }
        let serialUrl = 'app-direct-putaway/query-serial';
        const param = {
          name: 'serialComponent',
          params: {
            data,
            serialUrl,
            pendingQty: this.pendingQty,
            receiveQty: this.receiveQty,
            serialType: this.serialType,
            task: this.inboundSerialReceiptList || [],
            toSerialOldList: this.toSerialOldList || [],
          },
        };
        this.$router.pushPage(param);
      }
    },
    batchToSerial() {
      if (this.serialType === 'UNCTRL' || this.serialType === 'OUT_CTRL') {
        this.$nextTick(() => {
          this.skipNext(50);
        });
      } else {
        this.$nextTick(() => {
          this.skipNext(10);
        });
      }
    },
    inboundLocationEnter() {
      if (this.inputData.containerManageFlag === 1) {
        this.$nextTick(() => {
          this.skipNext(30);
        });
      } else {
        this.$nextTick(() => {
          this.skipNext('submitRef');
        });
      }
    },
    goToDirectly() {
      const param = {
        name: 'directlyShelves',
      };
      sessionStorage.setItem('formSerial', JSON.stringify('1'));
      this.$router.pushPage(param);
    },
    async submitShelvesCheck() {
      if (this.pendingQty - this.receiveQty < 0) {
        this.$hint.showFailed(this.$i18n.t('message.unSerialMsg'));
        this.$nextTick(() => {
          this.skipNext(50);
        });
        return;
      }else if (this.batchPendingQty - this.receiveQty < 0) {
        this.$hint.showFailed(this.$i18n.t('message.unBatchSerialMsg') + this.batchPendingQty);
        this.$nextTick(() => {
          this.skipNext(50);
        });
        return;
      }
      if (!this.inputData.locationCode) {
        this.$hint.showFailed(this.$i18n.t('message.checkRequired'), true);
        return;
      }
      let data = {
        cidNull: 1,
        // cidId: this.cidId,
        goodsId: this.goodsId,
        sourceBillId: this.sourceBillId,
        billId: this.billId,
        batchId: this.batchId,
        serialType: this.serialType,
        // serialNumber: this.serialNumber,
      };
      if (this.pendingQty === this.receiveQty) {
        const url = `${this.winvBaseUrl}app-direct-putaway/query-serial`;
        const res = await this.$http.post(url, data);
        this.inboundSerialReceiptList = res;
        this.serialReceiveQty = this.receiveQty;
      }
      if (this.pendingQty - this.serialReceiveQtyImp === 0 && this.receiveQty - this.serialReceiveQtyImp === 0) {
        const res = await this.submitShelves();
        if (res && res.failed === true) {
          this.showFailed(res.message);
        } else if (res && res.length > 0) {
          this.$hint.showSuccess();
          this.goToDirectly();
        }
      } else {
        const res = await this.submitShelves();
        if (res && res.failed === true) {
          this.showFailed(res.message);
        } else if (res && res.length > 0) {
          if (this.serialType === 'UNCTRL' || this.serialType === 'OUT_CTRL') {
            this.pendingQty = this.pendingQty - this.receiveQty;
            if (this.pendingQty === 0 || this.pendingQty === null) {
              this.goToDirectly();
            }
          } else {
            this.pendingQty = this.pendingQty - this.serialReceiveQtyImp;
          }
          this.receiveQty = null;
          this.inputData.batchCode = '';
          this.batchId = '';
          this.$hint.showSuccess();
          if (this.isbatch === 1) {
            this.$nextTick(() => {
              this.skipNext(0);
            });
          } else {
            if (this.serialType === 'UNCTRL' || this.serialType === 'OUT_CTRL') {
              this.$nextTick(() => {
                this.skipNext(50);
              });
            } else {
              this.$nextTick(() => {
                this.skipNext(10);
              });
            }
          }
        }
      }
    },
    changeSerialQty() {
      if (this.serialReceiveQtyImp && this.serialReceiveQtyImp !== 0) {
        this.checkImpQty();
      }
      if (this.receiveQty !== this.pendingQty) {
        this.serialReceiveQty = 0;
      } else if (this.receiveQty === this.pendingQty) {
        this.serialReceiveQty = this.receiveQty;
        this.serialReceiveQtyImp = this.receiveQty;
      }
    },
    checkImpQtyUnSerial() {
      if (this.pendingQty - this.receiveQty < 0) {
        this.$hint.showFailed(this.$i18n.t('message.unSerialMsg'));
        this.$nextTick(() => {
          this.skipNext(50);
        });
        return;
      }else if (this.batchPendingQty - this.receiveQty < 0) {
        this.$hint.showFailed(this.$i18n.t('message.unBatchSerialMsg') + this.batchPendingQty);
        this.$nextTick(() => {
          this.skipNext(50);
        });
        return;
      }
      this.skipNext(20);
    },
    async submitShelves() {
      if (!this.inputData.locationCode) {
        this.$hint.showFailed(this.$i18n.t('message.checkRequired'), true);
        return;
      }
      try {
        // this.$hint.showLoading();
        const params = {
          ...this.goodsObj,
          ...this.firstPageData,
          inboundQty: this.receiveQty,
          inLocationId: this.inputData.locationId,
          inLocationCode: this.inputData.locationCode,
          inWhareaId: this.inputData.whareaId,
          inWhareaCode: this.inputData.whareaCode,
          batchId: this.batchId,
          batchCode: this.inputData.batchCode,
          inCidCode: this.inputData.inboundCidCode,
          inCidId: this.inputData.inboundCidId,
          cidNull: 1,
          inboundSerialReceiptList: this.inboundSerialReceiptList,
          inParentCidCode: this.inputData.inboundParentCidCode,
        };
        const inboundReceiptRecordList = [];
        inboundReceiptRecordList.push(params);
        const inboundSerialReceiptsMutiplyGoods = [];
        let data = {
          inboundReceiptRecordList,
          inboundSerialReceiptsMutiplyGoods,
        };
        const res = await handleSubmit(data);
        return res;
      } catch (e) {
        console.log(e);
      } finally {
        // 清除当前界面的二维码解析字段
        await this.clearSourceFields(this, true);
        // this.$hint.hideLoading();
      }
    },
    checkImpQty() {
      if (this.receiveQty - this.serialReceiveQtyImp !== 0) {
        this.$hint.showFailed(
          this.$i18n.t('message.checkImpQty', { label: `${this.receiveQty || 0}`, labelTwo: `${this.serialReceiveQtyImp}` }),
          true
        );
        this.skipNext(10);
        return;
      }
      this.skipNext(20);
    },
    async checkInboundCidBlur(type) {
      if (this.inputData.inboundCidCode === this.cidCode && this.cidCode !== '' && this.pendingQty !== this.receiveQty) {
        this.$hint.showFailed(this.$i18n.t('message.checkCidSerialNum'));
        this.$nextTick(() => {
          this.skipNext(30);
        });
        return;
      }
      if(type !== 'parent' && !this.inputData.inboundCidCode) return;
      if(type === 'parent' && !this.inputData.inboundParentCidCode) return;
      let data = {
        cidId: this.cidId,
        cidCode: this.cidCode,
        billId: this.billId,
        billCode: this.billCode,

        locationCode: this.inputData.locationCode,
        locationId: this.inputData.locationId,
        inboundCidCode: this.inputData.inboundCidCode,
        recordSize: this.recordSize,
        orgCode: this.orgCode,
        orgId: this.orgId,
        billTypeId: this.billTypeId,
        billType: this.billType,
      };
      let url = 'app-direct-putaway/query-inbound-container'; // 原子托盘校验接口
      // 父托盘校验接口
      if(type === 'parent' && this.inputData.inboundParentCidCode) {
        url = 'app-direct-putaway/check-inbound-parent-container'; 
        data = {
          ...data,
          parentCidCode: this.inputData.inboundParentCidCode,
          cidStatusNotReceiveFlag: this.checkInoundCidData.cidStatusNotReceiveFlag,
          cidId: null,
          cidCode: null,
        }
      }

      try {
        this.$hint.showLoading();
        // const url = `${this.winvBaseUrl}app-inbound-receive-adjust/receive-reduce`;
        const res = await checkInboundCid({ params: data }, url);
        // 入库父托盘比对入库库位、入库托盘的locationid
        if(type === 'parent' && this.inputData.locationId && this.checkInoundCidData.locationId && res.locationId) {
          if(res.locationId !== this.inputData.locationId && this.checkInoundCidData.locationId !== res.locationId) {
            this.$hint.showFailed(this.$i18n.t('message.checkParentWithCidCode'), true);
          }
        }
        if(type === 'parent') return;
        this.checkInoundCidData = res;
        this.inputData.inboundCidCode = res.cidCode;
        this.inputData.inboundCidId = res.cidId;
        if(res.parentCidId) {
          this.inputData.inboundParentCidCode = res.parentCidCode;
          this.inputData.inboundParentCidId = res.parentCidId;
          this.disableParentCidCode = true;
          this.skipNext('submitRef');
        }
      } catch (e) {
        this.$nextTick(() => {
          type === 'parent' ? this.skipNext(40) : this.skipNext(30);
        });
        console.log('错误：' + e);
      } finally {
        this.$hint.hideLoading();
      }
    },
    // 保存来源解析字段
    async saveSourceField(fieldObj) {
      this.saveSourceParseField(this, fieldObj);
    },
    // 字段聚焦时赋值操作
    async valuedByFoucs(options={}) {
      const { custom ={} , currentValue } = options;
      await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
    },
  },

  beforeRouteLeave(to, from, next) {
    if (to.name === 'serialComponent') {
      this.$store.commit('pushKeepAlives', 'ShelvesDetailSku');
    } else {
      this.$store.commit('deleteKeepAlives', 'ShelvesDetailSku');
    }
    this.$nextTick(() => {
      next();
    });
  },
  mounted() {
    this.inputFieldList = [
      { type: 'lov', required: this.isbatch===1, ref: 0, bind: 'inputData.batchCode', field: 'batchCode' },
      { type: 'input', required: true, ref: 10, bind: 'receiveQty', field: 'receiveQty' },
      { type: 'lov', required: true, ref: 20, bind: 'inputData.locationCode', field: 'locationCode' },
      { type: 'input', required: false, ref: 30, bind: 'inputData.inboundCidCode', field: 'inboundCidCode' },
      { type: 'input', required: false, ref: 40, bind: 'inputData.inboundParentCidCode',field:'parentCidCode' },
    ];
    if (this.isbatch === 1) {
      this.$nextTick(() => {
        this.skipNext(0);
      });
    } else {
      if (this.serialType === 'UNCTRL' || this.serialType === 'OUT_CTRL') {
        this.$nextTick(() => {
          this.skipNext(50);
        });
      } else {
        this.$nextTick(() => {
          this.skipNext(10);
        });
      }
    }
  },
  activated() {
    this.$nextTick(() => {
      this.skipNext(10);
    });
    this.whareaId = '';
    const inboundSerialReceiptList = JSON.parse(sessionStorage.getItem('inboundSerialReceiptList'));
    this.inboundSerialReceiptList = inboundSerialReceiptList;
    // const serialReceiveQty = JSON.parse(sessionStorage.getItem('serialReceiveQty'));
    this.serialReceiveQty = (inboundSerialReceiptList && inboundSerialReceiptList.length) || 0;
    this.serialReceiveQtyImp = (inboundSerialReceiptList && inboundSerialReceiptList.length) || 0;
    sessionStorage.removeItem('inboundSerialReceiptList');
    sessionStorage.removeItem('serialReceiveQty');
  },
  watch: {
    'inputData.inboundCidCode': function(val) {
      if (!val) {
        this.inputData.inboundParentCidCode = undefined;
      }
    },
  },
  created() {
    const {
      cidNull,
      cidId,
      parentCidId,
      goodsId,
      sourceBillId,
      billId,
      isbatch,
      serialType,
      billTypeId,
      billType,
      firstPageData,
      receiptNumber,
      whareaIdSku,
      requireParentCidCode,
    } = this.$route.params;
    // console.log('---this.$route.params-,', this.$route.params);
    this.requireParentCidCode = requireParentCidCode;
    this.cidNull = cidNull;
    this.cidId = cidId;
    this.parentCidId = parentCidId;
    this.goodsId = goodsId;
    this.sourceBillId = sourceBillId;
    this.billId = billId;
    this.isbatch = isbatch;
    this.serialType = serialType;
    this.billTypeId = billTypeId;
    this.billType = billType;
    this.firstPageData = firstPageData;
    this.whareaIdSku = whareaIdSku;
    this.receiptNumber = receiptNumber;
    this.getDetailData();
    this.urlData = this.handleData('DIRECTLY_SHELVES_CONFIRM');
    const { sourceParseField, customData, hideControl } = this.$route.params;
    this.sourceParseField = sourceParseField;
    this.customData = customData;
    this.hideControl = hideControl;
  },
};
</script>
<style lang="stylus" scoped>
 @import '~@/style/var.styl'
.receive-detail-sku-card {
  background: #fff;
  border-radius: 1.6vw;
  width: 100%;
  padding: 14px 12px;
  color: #000;
  font-size: 14px;
  box-shadow: 0 0 2.133vw #d9d9d9;
  box-sizing: border-box;

  .receive-detail-sku-card-sku {
    font-size: 14px;
    font-weight: bold;
    color: #262626;
    line-height: 20px;
    padding-bottom: 6px;
  }

  .receive-detail-sku-card-second {
    font-size: 14px;
    color: #262626;
    line-height: 20px;
    padding-bottom: 6px;
    overflow: hidden;
  }

  .receive-detail-sku-card-three {
    font-size: 14px;
    color: #595959;
    line-height: 20px;
  }

  .font-color {
    color: #595959;
  }
}

.disabled {
  background-color: red;
  /* background-color #f5f5f5 */
}

.serial-button-detail {
  margin-left: 10px;
  background-color: var(--primaryColor) !important;
  color: #FFFFFF !important;
  font-size: 14px;
  height: 36px !important;
  line-height: 36px !important;
}

.recommend-loaction {
  border: 1px solid #91D5FF;
  line-height: 30px;
  border-radius: 3px;
  background-color: #BAE7FF;
  font-size: 12px;
  color: var(--primaryColor);
  padding: 0 10px;
  margin-top: 4px;

  span {
    &:first-child {
      margin-right: 10px;
    }
  }
}
</style>
