<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.serialNumber')" :is-index="false" />
    </div>
    <div class="ips-container">
      <div class="ips-table">
        <table>
          <tr style="background-color: #eee">
            <th v-for="(item, index) in tableTitle" :key="index" scope="col">
              {{ item.name }}
            </th>
          </tr>
          <tr v-for="(item, index) in serialDate" :key="index">
            <td>{{ item.serialNumber }}</td>
            <td>{{ item.qualityStatusName }}</td>
          </tr>
        </table>
      </div>
    </div>
  </hips-view>
</template>

<script>
  export default {
    name: 'SerialDetail',
    data () {
      return {
        serialDate: [],
      }
    },
    created() {
      const {serialDate} = this.$route.params;
      this.serialDate = serialDate;
      this.tableTitle = [
        {
          name: this.$t('label.serialNumber'), // 序列号
        },
        {
          name: this.$t('label.qualityStatus'), // 质量状态
        },
      ];
    },
  }
</script>

<style lang="stylus" scoped>
 @import '~@/style/var.styl'
    .ips-table {
        width 100%
        overflow-x auto
        table {
            width 100%
        }
        th, td {
            padding:8px 10px
            border 1px solid #ccc
            text-align center
            white-space nowrap
        }
    }

</style>
