import inbound from '../views/quick-inbound.vue'
// import QuickInboundReceive from '../views/quick-inbound-receive.vue'
import InboundDetail from '../views/quick-inbound-detail.vue'
import InboundCidDetail from '../views/quick-inbound-cid-detail.vue'
import ReceiveParentCidDetail from '../views/quick-receive-parent-cid-detail.vue'

let route = [{
    path: '/quick-inbound',
    name: 'QuickInbound',
    component: inbound,
  },
//   {
//     path:'/quick-inbound-receive',
//     name:'quickInboundReceive',
//     component: QuickInboundReceive,
//   },
  {
    path:'/quick-inbound-detail',
    name:'QuickInboundDetail',
    component: InboundDetail,
  },
  {
    path:'/quick-inbound-cid-detail',
    name:'quickInboundCidDetail',
    component: InboundCidDetail,
  },
  {
    path:'/quick-receive-parent-cid-detail',
    name:'quickReceiveParentCidDetail',
    component:ReceiveParentCidDetail,
  },
]

export default route