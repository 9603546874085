<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header
        :title="$t('title.countDetail')"
        :is-index="false"
      />
      <div
        v-if="!showSerialBtn"
        class="hips-nav-bar__buttons hips-nav-bar__buttons--right"
      >
        <hips-button
          class="header-right"
          @click.stop="handleShowPopup"
        >
          {{ $t('button.configuration') }}
        </hips-button>
      </div>
    </div>
    <hips-popup
      v-model="showPopup"
      @click-overlay="onPopupClose"
    >
      <div class="popup">
        <hips-switch-cell
          @change="switchTag"
          v-model="accumulateQty"
          :title="$t('label.accumulateQty')"
        />
      </div>
    </hips-popup>
    <div class="ips-container">
      <!--库位-->
      <ips-lov
        :label="$t('label.countLocation')"
        :title="[$t('label.countLocationCode'), $t('label.locationName'), $t('label.whareaName')]"
        v-model="inputData.location"
        v-show="customData['location'] ? hideControl['location'] : true"
        required
        :params="['locationCode', 'locationName', 'whareaName']"
        display="locationCode"
        :url="`${wmdmBaseUrl}wms-locations/app`"
        :url-params="{ warehouseId }"
        :pre-parse-url="urlData['locationCode']"
        :custom="customData['location']"
        :source-parse-field="sourceParseField"
        ref="ref0"
        @click.stop="handleCommonClick(0)"
        @enter="skipJudge(10, hidePlant)"
        @down="skipJudge(10, hidePlant)"
        :error-msg="this.$i18n.t('message.countLocationCkeck')"
        @choose="handleChoose('location', $event)"
        @clickIcon="handleCommonClick(0, 'lov')"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!--托盘-->
      <ips-field
        :label="$t('label.tray')"
        v-model="inputData.plants"
        v-show="customData['plants'] ? hideControl['plants'] : true"
        params="plants"
        display="plants"
        :pre-parse-url="urlData['plants']"
        v-if="!hidePlant"
        :custom="customData['plants']"
        :source-parse-field="sourceParseField"
        ref="ref10"
        @enter="skipNext(20)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!--物料-->
      <ips-lov
        :label="$t('label.sku')"
        :title="[$t('label.sku'), $t('label.goodsName')]"
        required
        v-show="customData['sku'] ? hideControl['sku'] : true"
        v-model="inputData.sku"
        :params="['sku', 'goodsName']"
        display="sku"
        :url="`${wmdmBaseUrl}goods`"
        :url-params="{ orgId, ownerId, enabledFlag: 1, ...paramsObj }"
        :pre-parse-url="urlData['sku']"
        :custom="customData['sku']"
        :source-parse-field="sourceParseField"
        ref="ref20"
        @click.stop="handleCommonClick(20)"
        @enter="skipJudge(30, hideBatch)"
        @down="skipJudge(30, hideBatch)"
        @choose="handleChoose('sku', $event)"
        @clickIcon="handleCommonClick(20, 'lov')"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!--快照数量-->
      <hips-row v-if="showSerialBtn">
        <ips-value-cell
          :title="$t('label.snapshotQty')"
          :value="inputData.snapshotQty"
          v-show="customData['snapshotQty'] ? hideControl['snapshotQty'] : true"
        />
      </hips-row>
      <!--品名-->
      <ips-value-cell
        :title="$t('label.goodsName')"
        :value="inputData.goods"
        v-show="customData['goods'] ? hideControl['goods'] : true"
      />
      <!--单位-->
      <hips-row v-if="showSerialBtn">
        <ips-value-cell
          :title="$t('label.unit')"
          :value="inputData.uom"
          v-show="customData['uom'] ? hideControl['uom'] : true"
        />
      </hips-row>
      <!--批次-->
      <ips-field
        :label="$t('label.batch')"
        v-show="customData['batch'] ? hideControl['batch'] : true"
        v-model="inputData.batch"
        v-if="!hideBatch"
        required
        params="batchCode"
        display="batchCode"
        :pre-parse-url="urlData['batchCode']"
        :custom="customData['batch']"
        :source-parse-field="sourceParseField"
        ref="ref30"
        @click.stop="handleCommonClick(30)"
        @enter="handleBatchEnter()"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />

      <!-- 序列号 -->
      <serialInputComp
        v-if="showSerialBtn"
        v-model="inputData.qty"
        :params="serialCompParams"
        @serialNumberList="changeSerialNumberList"
      />
      <!-- 包装数量 -->
      <ips-input-number
        :label="$t('label.packageQty')"
        v-model="inputData.packageQty"
        v-show="!showSerialBtn ? (customData['packageQty'] ? hideControl['packageQty'] : true) : false"
        :min="null"
        required
        not-auto-fill
        :custom="customData['packageQty']"
        :source-parse-field="sourceParseField"
        @click.stop="handleCommonClick(50)"
        @enter="calInventoryQty(60)"
        @down="skipNext(60)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
        ref="ref50"
      />
      <!-- 箱数 -->
      <ips-input-number
        :label="$t('label.casesNum')"
        v-model="inputData.casesNum"
        v-show="!showSerialBtn ? (customData['casesNum'] ? hideControl['casesNum'] : true) : false"
        :min="null"
        :more-than-zero="true"
        not-auto-fill
        :custom="customData['casesNum']"
        :source-parse-field="sourceParseField"
        @click.stop="handleCommonClick(60)"
        @enter="calInventoryQty(70)"
        @down="skipNext(70)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
        ref="ref60"
      />
      <!-- 尾箱数量 -->
      <ips-input-number
        :label="$t('label.tailBoxNum')"
        v-model="inputData.tailBoxNum"
        v-show="!showSerialBtn ? (customData['tailBoxNum'] ? hideControl['tailBoxNum'] : true) : false"
        :min="null"
        :more-than-zero="true"
        not-auto-fill
        :custom="customData['tailBoxNum']"
        :source-parse-field="sourceParseField"
        @click.stop="handleCommonClick(70)"
        @enter="calInventoryQty(80)"
        @down="skipNext(80)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
        ref="ref70"
      />
      <!-- 本次盘点数量 -->
      <ips-input-number
        :label="$t('label.thisInventoryQty')"
        v-model="inputData.thisInventoryQty"
        v-show="!showSerialBtn ? (customData['thisInventoryQty'] ? hideControl['thisInventoryQty'] : true) : false"
        :min="null"
        required
        :more-than-zero="true"
        not-auto-fill
        :custom="customData['thisInventoryQty']"
        :source-parse-field="sourceParseField"
        @click.stop="handleCommonClick(80)"
        @enter="calFinalCountQty('submitRef')"
        @down="skipNext('submitRef')"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
        ref="ref80"
      />
      <!-- 已盘点数量 -->
      <ips-value-cell
        :title="$t('label.haveInventoryQty')"
        :value="inputData.haveInventoryQty"
        v-show="!showSerialBtn ? (customData['haveInventoryQty'] ? hideControl['haveInventoryQty'] : true) : false"
      />
      <!-- 最终盘点数量 -->
      <ips-value-cell
        :title="$t('label.finalCountQty')"
        :value="inputData.finalCountQty"
        v-show="!showSerialBtn ? (customData['finalCountQty'] ? hideControl['finalCountQty'] : true) : false"
      />
      <!-- 快照数量 -->
      <ips-value-cell
        :title="$t('label.snapshotQty')"
        :value="inputData.snapshotQty"
        v-show="!showSerialBtn ? (showSnapshot ? (customData['snapshotQty'] ? hideControl['snapshotQty'] : true) : false) : false"
      />
      <hips-row v-if="showSerialBtn">
        <div style="margin-top: 16px">
          <hips-col span="24">
            <ips-input-number
              v-model="inputData.qty"
              required
              :min="null"
              :label="$t('label.countQty')"
              :more-than-zero="true"
              :custom="customData['qty']"
              :source-parse-field="sourceParseField"
              :enable-serial="serialType"
              ref="ref40"
              :disabled="showSerialBtn"
              not-auto-fill
              @setSourceField="saveSourceField($event)"
              @valuedByFoucs="valuedByFoucs($event)"
            />
          </hips-col>
          <!-- <hips-col span="10">
            序列号录入
            <hips-button
              size="large"
              @click.stop="handleSeriesBtn"
              @enter="handleSeriesBtn"
              ref="inputRef"
              :style="{ ...inputBtnStyle }"
              class="input-btn"
            >
              {{ $t('button.serialNumEntry') }}
            </hips-button>
          </hips-col> -->
        </div>
      </hips-row>
      <div class="ips-footer">
        <hips-button
          size="large"
          @click.stop="handleButtonSubmit"
          ref="submitRef"
        >
          <svg-icon
            slot="icon"
            icon-class="success"
          />
          {{ $t('button.confirm') }}
        </hips-button>
      </div>
    </div>
  </hips-view>
</template>

<script>
import { appIsContainer, countConfirm, appRepeatCount, appCount, queryOrderLines, querySnapshotQty } from '../api';
import serialInputComp from './serialInput-comp.vue'

export default {
  name: 'CountConfirm',
  components: {
    serialInputComp
  },
  data() {
    return {
      inputData: {
        goods: '',
        uom: '',
        location: '',
        sku: '',
        batch: '',
        plants: '',
        qty: null,
        snapshotQty: '',
        packageQty: '',
        casesNum: 1,
        tailBoxNum: 0,
        thisInventoryQty: '',
        haveInventoryQty: null,
        finalCountQty: null,
      }, //输入框数据
      showSnapshot: false,
      showPopup: false,
      accumulateQty: true,
      billCode: '',
      goodsId: '',
      warehouseId: '',
      fatherProps: '',
      isbatch: false, // 批次是否必输
      locationCode: '',
      locationId: '',
      countPrecision: '',
      modalShow: false,
      readOnly: false, // 输入数量是否可以更改
      hidePlant: false, // 隐藏托盘
      hideBatch: false, // 隐藏批次
      urlData: {},
      paramsObj: {},
      ownerId: null,
      orgId: null,
      showSerialBtn: false, // 是否展示序列号录入按钮
      customData: {}, // 个性化配置信息列表
      hideControl: {}, // 个性化配置显示隐藏控制
      sourceParseField: {}, // 界面中所有的来源解析字段
      id: '',
      serialCompParams: {},
      serialNumberList: [],
    };
  },
  created() {
    this.getDataFromParams();
    const { countPrecision } = this;
    if (countPrecision === 'LOCATION') {
      this.hidePlant = true;
    } else if (countPrecision === 'SKU') {
      this.hidePlant = true;
      this.hideBatch = true;
    } else if (countPrecision === 'STOCK') {
      this.paramsObj = { excludeSerialType: 'SEMI_INV_CTRL' };
    } else if (countPrecision === 'SERIAL') {
      this.paramsObj = { serialType: 'SEMI_INV_CTRL' };
    }
  },
  async mounted() {
    this.inputFieldList = [
      { type: 'lov', required: true, ref: 0, bind: 'inputData.location', field: 'location' },
      { type: 'input', required: false, ref: 10, bind: 'inputData.plants', field: 'plants' },
      { type: 'lov', required: true, ref: 20, bind: 'inputData.sku', field: 'sku' },
      { type: 'text', bind: 'goods', field: 'goods' },
      { type: 'text', bind: 'uom', field: 'uom' },
      { type: 'input', required: true, ref: 30, bind: 'inputData.batch', field: 'batch' },
      { type: 'number', required: true, ref: 40, bind: 'inputData.qty', field: 'qty' },
      { type: 'number', required: true, ref: 50, bind: 'inputData.packageQty', field: 'packageQty' },
      { type: 'number', ref: 60, bind: 'inputData.casesNum', field: 'casesNum' },
      { type: 'number', ref: 70, bind: 'inputData.tailBoxNum', field: 'tailBoxNum' },
      { type: 'number', required: true, ref: 80, bind: 'inputData.thisInventoryQty', field: 'thisInventoryQty' },
    ];
    this.urlData = this.handleData('STOCK_COUNT_CONFIRM');
    if (this.fatherProps.countModeMeaning === '明盘') {
      // this.skipNext(20)
    } else {
      this.skipNext(0);
    }
  },
  activated() {
    this.serialNumberList = this.$storage.get('serialNumberList') || [];
    this.inputData.qty = this.serialNumberList.length;
    this.skipNext('submitRef');
  },
  methods: {
    changeSerialNumberList(list) {
      this.serialNumberList = list;
      this.inputData.qty = this.serialNumberList.length;
    },

    // 点击配置
    handleShowPopup() {
      this.showPopup = true;
      this.inputData = {
        goods: '',
        uom: '',
        location: '',
        sku: '',
        batch: '',
        plants: '',
        qty: null,
        snapshotQty: null,
        packageQty: null,
        casesNum: '1',
        tailBoxNum: '0',
        thisInventoryQty: null,
        haveInventoryQty: null,
        finalCountQty: null,
      }; // 输入框数据
    },
    // 关闭配置界面
    onPopupClose() {
      this.skipNext(0);
    },
    // 累加盘点
    switchTag(val) {
      console.log(val);
      this.accumulateQty = val;
      this.calFinalCountQty();
    },
    // 计算本次盘点数据
    calInventoryQty(ref) {
      const { packageQty, casesNum, tailBoxNum } = this.inputData;
      const thisInventoryQty = Number(packageQty) * Number(casesNum) + Number(tailBoxNum);
      this.inputData = { ...this.inputData, thisInventoryQty };
      this.skipNext(ref);
      this.calFinalCountQty();
    },
    // 计算最终盘点数量
    calFinalCountQty(ref) {
      const { thisInventoryQty, haveInventoryQty } = this.inputData;
      console.log(thisInventoryQty, haveInventoryQty);
      if (this.accumulateQty) {
        const finalCountQty = Number(thisInventoryQty) + Number(haveInventoryQty || 0);
        this.inputData = { ...this.inputData, finalCountQty };
      } else {
        const finalCountQty = thisInventoryQty || '0';
        this.inputData = { ...this.inputData, finalCountQty };
      }
      if (ref) {
        this.skipNext(ref);
      }
    },

    // 快照数据
    async querySnapshotQty() {
      const lineParams = {
        params: {
          billId: this.fatherProps.id,
          orgId: this.orgId,
          ownerId: this.ownerId,
          warehouseId: this.warehouseId,
          whareaId: this.whareaId,
          locationId: this.locationId,
          goodsId: this.goodsId,
        },
      };
      if (this.inputData.plants) {
        lineParams.params.cidId = this.inputData.plants;
      }
      if (this.inputData.batch) {
        lineParams.params.batchCode = this.inputData.batch;
      }
      const res = await querySnapshotQty(lineParams);
      if (res) {
        console.log(res);
        this.id = res.id;
        this.inputData.snapshotQty = res.snapshotQty;
        this.inputData.haveInventoryQty = res.countQty;
      }
      this.calFinalCountQty();
    },

    // 明盘带出库位、数量
    async queryOrderLines() {
      const lineParams = {
        params: {
          billId: this.fatherProps.id,
        },
      };
      const res = await queryOrderLines(lineParams);
      if (res.content && res.content.length > 0) {
        const { locationCode, sku, locationId, whareaId, whareaCode, goodsName, uomId, goodsId } = res.content[0];
        this.inputData.location = locationCode;
        this.locationCode = locationCode;
        this.locationId = locationId;
        this.whareaId = whareaId;
        this.whareaCode = whareaCode;
        this.inputData.sku = sku;
        this.inputData.goods = goodsName;
        this.inputData.uom = uomId;
        this.goodsId = goodsId;
        this.skipNext(20);
      }
      this.querySnapshotQty();
    },

    handleBatchEnter() {
      this.querySnapshotQty();
      if (!this.showSerialBtn) {
        this.skipNext(50);
      } else {
        this.skipNext(40);
      }
    },

    handleSeriesBtn() {
      if (!this.hideBatch && this.isbatch && !this.inputData.batch) {
        return;
      }
      const param = {
        name: 'stockInputSerial',
        params: {
          serialNumberList: this.$storage.get('serialNumberList') || [],
        },
      };
      this.serialCompParams = param.params;
      // this.$router.pushPage(param);
    },

    /**
     * 获取父页面传递的参数
     */
    getDataFromParams() {
      const { billCode, fatherProps, sourceParseField, customData, hideControl } = this.$route.params;
      console.log(this.$route.params);
      if (billCode) {
        this.billCode = billCode;
        this.fatherProps = fatherProps;
        this.warehouseId = fatherProps.warehouseId;
        this.orgId = fatherProps.orgId;
        this.ownerId = fatherProps.ownerId;
        this.countPrecision = fatherProps.countPrecision;
        this.countMode = fatherProps.countMode;
      }
      this.sourceParseField = sourceParseField;
      this.customData = customData;
      this.hideControl = hideControl;
      if (this.fatherProps.countModeMeaning === '明盘') {
        this.queryOrderLines();
        this.showSnapshot = true;
      } else {
        this.$nextTick(() => {
          this.$refs.ref0.focus();
        });
      }
    },
    /**
     *@functionName: saveSourceField
     *@description: 保存来源解析字段并赋值Enter
     *@params1: fieldObj[Object] 来源解析字段对象,
     fieldCode[String] 来源解析字段
     parseValue[String] 二维码解析值
     subFields[Array]] 关联子字段
     */
    saveSourceField(fieldObj) {
      this.saveSourceParseField(this, fieldObj);
    },
    /**
     * 字段聚焦时赋值操作
     */
    async valuedByFoucs(options = {}) {
      const { custom = {}, currentValue } = options;
      await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
    },
    /**
     * 光标跳转判断
     */
    skipJudge(ref, hideFlag) {
      if (hideFlag) {
        if (!this.showSerialBtn && ref === 30) {
          this.skipNext(ref + 20);
        } else {
          this.skipNext(ref + 10);
        }
      } else {
        this.skipNext(ref);
      }
    },

    /**
     * lov选择框，选择数据
     * @param {String} type - 定位lov
     * @param {object} obj - 选中行的数据
     */
    handleChoose(type, obj) {
      switch (type) {
        case 'location':
          this.locationCode = obj.locationCode;
          this.locationId = obj.locationId;
          this.whareaId = obj.whareaId;
          this.whareaCode = obj.whareaCode;
          if (['STOCK', 'SERIAL'].includes(this.countPrecision)) {
            this.hidePlant = obj.containerManageFlag === 1 ? false : true;
          }
          if (this.goodsId) {
            this.querySnapshotQty();
          }
          break;
        case 'sku':
          this.chooseSku(obj);
          this.querySnapshotQty();
          break;
        default:
          return null;
      }
    },
    /**
     * 选择物料
     */
    chooseSku(item) {
      const { countPrecision } = this.fatherProps;
      const { goodsName, uomCode, goodsId, serialType, ownerId, ownerCode, isbatch } = item;
      this.inputData.goods = goodsName;
      this.inputData.uom = uomCode;
      this.goodsId = goodsId;
      this.serialType = serialType;
      this.ownerId = ownerId;
      this.ownerCode = ownerCode;
      if (countPrecision === 'SKU') {
        this.isbatch = false;
      } else {
        // item.isbatch === 1 ? (this.isbatch = true) : (this.isbatch = false);
        this.isbatch = item.isbatch === 1;
      }
      if (['LOCATION', 'STOCK', 'SERIAL'].includes(countPrecision)) {
        this.hideBatch = isbatch === 0;
      }
      // this.inputData.batch = item.batchCode;
      if (item.serialType === '10') {
        this.inputData.qty = 1;
        this.readOnly = true;
      } else {
        this.inputData.qty = null;
        this.readOnly = false;
      }

      if ((countPrecision === 'STOCK' && serialType === 'INV_CTRL') || countPrecision === 'SERIAL') {
        this.showSerialBtn = true;
      } else {
        this.showSerialBtn = false;
      }

      this.handleSeriesBtn()
    },

    // 校验托盘是否存在于其他库位
    async handlePlantsBlur() {
      if (this.inputData.plants) {
        try {
          this.$hint.showLoading();
          let data = {
            params: {
              cidCode: this.inputData.plants,
              locationId: this.locationId,
              locationCode: this.locationCode,
            },
          };
          await appIsContainer(data);
        } catch (e) {
          console.log('错误：' + e);
        } finally {
          this.$hint.hideLoading();
        }
      }
    },

    // 盘点数据提交
    async handleButtonSubmit() {
      const {
        fatherProps: { countPrecision },
      } = this;
      // const { qty } = this.inputData;
      // if (!qty) {
      //   this.$hint.showFailed(this.$i18n.t('message.countQtyCheck'), true);
      //   return;
      // }
      if (this.countMode === 'VISIBIE_INVENTORY') {
        // 明盘情况下
        if (countPrecision === 'SERIAL') {
          this.onConfirm();
        } else {
          try {
            const data = {
              id: this.id,
              billId: this.fatherProps.id,
              locationCode: this.locationCode,
              locationId: this.locationId,
              cidCode: this.inputData.plants,
              goodsId: this.goodsId,
              sku: this.inputData.sku,
              batchCode: this.inputData.batch,
              countQty: this.serialType === 'INV_CTRL' ? this.inputData.qty : this.inputData.finalCountQty,
              serialType: this.serialType,
              countPrecision: this.fatherProps.countPrecision,
              serialNumberDtoList: this.serialNumberList,
            };
            this.$hint.showLoading();
            this.handleSubmitStatus();
            const res = await countConfirm(data);
            if (res === true) {
              if (['INV_CTRL', 'SEMI_INV_CTRL'].includes(this.serialType)) {
                this.onConfirm();
              } else {
                this.confirmPandian();
              }
            } else {
              if (['INV_CTRL', 'SEMI_INV_CTRL'].includes(this.serialType)) {
                this.$hint.showAlert(res, this.onConfirm, this.onCancel);
              } else {
                this.$hint.showAlert(res, this.confirmPandian, this.onCancel);
              }
            }
          } catch (e) {
            console.log('错误：' + e);
          } finally {
            this.$hint.hideLoading();
            this.handleSubmitStatus();
          }
        }
      } else {
        // 盲盘情况下
        if (countPrecision === 'SERIAL') {
          this.onConfirm();
        } else if (countPrecision === 'STOCK') {
          if (['INV_CTRL'].includes(this.serialType)) {
            this.onConfirm();
          } else {
            this.confirmPandian();
          }
        } else if (countPrecision === 'SKU' || countPrecision === 'LOCATION') {
          this.confirmPandian();
        }
      }
    },
    /**
     * 盘点确认是否复盘
     * @returns {Promise<void>}
     */
    async confirmPandian() {
      try {
        this.$hint.showLoading();
        let isRepeatData = {
          params: {
            billId: this.fatherProps.id,
            locationId: this.locationId,
            cidCode: this.inputData.plants,
            batchCode: this.inputData.batch,
            goodsId: this.goodsId,
          },
        };
        const result = await appRepeatCount(isRepeatData);
        if (result) {
          this.$hint.showAlert(this.$i18n.t('message.countGoodsCheck'), this.onConfirm, this.onCancel);
        } else {
          this.onConfirm();
        }
      } catch (e) {
        console.log('错误：' + e);
      } finally {
        this.$hint.hideLoading();
      }
    },

    onCancel() {
      this.skipNext(40);
    },

    // 最终事务处理
    async onConfirm() {
      try {
        this.$hint.showLoading();
        let data = {
          ownerId: this.ownerId,
          ownerCode: this.ownerCode,
          billId: this.fatherProps.id,
          locationCode: this.locationCode,
          locationId: this.locationId,
          cidCode: this.inputData.plants,
          goodsId: this.goodsId,
          sku: this.inputData.sku,
          batchCode: this.inputData.batch,
          id: this.id,
          countQty: this.serialType === 'INV_CTRL' ? this.inputData.qty : this.inputData.finalCountQty,
          countPrecision: this.fatherProps.countPrecision,
          serialType: this.serialType,
          whareaId: this.whareaId,
          whareaCode: this.whareaCode,
          warehouseId: this.warehouseId,
          serialNumberDtoList: this.serialNumberList,
        };
        await appCount(data);
        this.$hint.showSuccess();
        this.init();
        await this.clearSourceFields(this, true);
        this.$storage.remove('serialNumberList');
        this.skipNext(0);
      } catch {
        this.skipNext(40);
      } finally {
        this.$hint.hideLoading();
      }
    },

    // 数据初始化
    init() {
      this.inputData = {
        goods: '',
        uom: '',
        location: '',
        sku: '',
        batch: '',
        plants: '',
        qty: null,
        snapshotQty: null,
        packageQty: null,
        casesNum: '1',
        tailBoxNum: '0',
        thisInventoryQty: null,
        haveInventoryQty: null,
        finalCountQty: null,
      }; // 输入框数据
      this.serialNumberList = [];
      this.getDataFromParams();
    },
  },

  beforeRouteLeave(to, from, next) {
    if (to.name === 'stockInputSerial') {
      this.$store.commit('pushKeepAlives', 'CountConfirm');
    } else {
      this.$store.commit('deleteKeepAlives', 'CountConfirm');
      this.$storage.remove('serialNumberList');
    }
    this.$nextTick(() => {
      next();
    });
  },
};
</script>

<style lang="stylus" scoped>
@import '~@/style/var.styl';

.input-btn {
  margin-left: 10px;
  height: 36px;
  line-height: 36px;
}

.popup {
  width: 50 vw;
}

.header-right {
  padding: 0 10px;
  background-color: #eee;
  color: #88c0f0;
}

.ips-container >>> .hips-cell__title {
  flex: 2 !important;
}
</style>
