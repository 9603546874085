import request from '@/request';
import mixin from '@/mixin/mixin';

//确认
export function fetchProcessTask(data) {
  let url = `${mixin.data().winvBaseUrl}app-order-picking/fetch-process-task`;
  return request.get(url, data);
}

// 操作拦截
export function operatingNodeIntercept(data) {
  let url = `${mixin.data().winvBaseUrl}winv-outbound-interceptions/operating-node-intercept`;
  return request.post(url, data);
}


// 清除任务
export function clearTask(data) {
  let url = `${mixin.data().winvBaseUrl}app-order-picking/clear-task`;
  return request.post(url, data);
}

// 清空当前扫描任务
export function clearCurrentScan(data) {
  let url = `${mixin.data().winvBaseUrl}app-order-picking/clear-current-scan`;
  return request.post(url, data);
}

// 获取现有量
export function querySummaryQty(data) {
  let url = `${mixin.data().winvBaseUrl}app-order-picking/query-summary-qty`;
  return request.get(url, data);
}

// 验证物料
export function validateSku(data) {
  let url = `${mixin.data().winvBaseUrl}app-order-picking/validate-sku`;
  return request.post(url, data);
}

// 验证条码
export function validateCode(data) {
  let url = `${mixin.data().winvBaseUrl}app-order-picking/validate-code`;
  return request.post(url, data);
}

// 扫描明细
export function queryScanDetail(data) {
  let url = `${mixin.data().winvBaseUrl}app-order-picking/query-scan-detail`;
  return request.get(url, data);
}

// 获取现有量
export function validateQty(data) {
  let url = `${mixin.data().winvBaseUrl}app-order-picking/validate-qty`;
  return request.post(url, data);
}

// 跳过任务
export function skipTask(data) {
  let url = `${mixin.data().winvBaseUrl}app-order-picking/skip-task`;
  return request.post(url, data);
}

// 拣货确认
export function loadAndUnload(data) {
  let url = `${mixin.data().winvBaseUrl}app-order-picking/load-and-unload`;
  return request.post(url, data);
}
