<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.expressReceipts')" />
    </div>
    <div class="ips-container">
      <!-- 快递单号 -->
      <ips-field
        v-model="expressData.expressBackCode"
        required
        :label="$t('label.expressBackCode')"
        ref="ref10"
        params="expressBackCode"
        display="expressBackCode"
        :pre-parse-url="urlData['expressBackCode']"
        :custom="customData['expressBackCode']"
        @blur="checkExpressCode()"
        @click="handleCommonClick(10)"
        @enter="skipNext(20)"
        @down="skipNext(20)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />

      <!-- 快递公司 -->
      <ips-lov
        :label="$t('label.expressBackTypeName')"
        required
        v-model="expressData.expressBackTypeName"
        :title="$t('title.expressBackTypeName')"
        display="expressBackTypeName"
        :params="[ 'expressBackTypeName']"
        :url="`${winvBaseUrl}app-express-receipts/express-type-lov`"
        :pre-parse-url="urlData['expressBackTypeName']"
        :custom="customData['expressBackTypeName']"
        ref="ref20"
        @click.stop="handleCommonClick(20)"
        @clickIcon="handleCommonClick(20, 'lov')"
        @enter="skipNext(30)"
        @down="skipNext(30)"
        @choose="handleChoose('expressBackType', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />

      <!-- 退回件数 -->
      <ips-input-number
        v-model="expressData.pieceQty"
        :label="$t('label.pieceQty')"
        required
        ref="ref30"
        :min="null"
        :more-than-zero="true"
        :precision="precision"
        not-auto-fill
        params="pieceQty"
        display="pieceQty"
        :pre-parse-url="urlData['pieceQty']"
        :custom="customData['pieceQty']"
        @click="handleCommonClick(20)"
        @enter="skipNext(40)"
        @down="skipNext(40)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />

      <!-- 退货省份 -->
      <ips-lov
        :label="$t('label.province')"
        v-model="expressData.province"
        :title="[$t('label.province')]"
        ref="ref40"
        display="province"
        :params="['regionName']"
        :url="`${winvBaseUrl}app-express-receipts/get-region-lov`"
        :pre-parse-url="urlData['province']"
        :custom="customData['province']"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
        @click.stop="handleCommonClick(40)"
        @clickIcon="handleCommonClick(40, 'lov')"
        @enter="skipNext(50)"
        @down="skipNext(50)"
        @choose="chooseProvince('province',$event)"
      />

      <!-- 退货城市 -->
      <ips-lov
        :label="$t('label.city')"
        v-model="expressData.city"
        :title="[$t('label.city')]"
        ref="ref50"
        display="city"
        :params="['regionName']"
        :url="`${winvBaseUrl}app-express-receipts/get-region-lov`"
        :url-params="{ parentRegionId: expressData.regionId}"
        :pre-parse-url="urlData['city']"
        :custom="customData['city']"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
        @click.stop="handleCommonClick(50)"
        @clickIcon="handleCommonClick(50, 'lov')"
        @enter="skipNext(60)"
        @down="skipNext(60)"
        @choose="chooseProvince('city',$event)"
      />

      <!-- 商品条码         @enter="skipNext(70)"
        @down="skipNext(70)"-->
      <ips-field
        v-model="expressData.sku"
        :label="$t('label.productCode')"
        ref="ref60"
        params="sku"
        display="sku"
        :pre-parse-url="urlData['sku']"
        :custom="customData['sku']"
        @blur="checkSkuCode()"
        @enter="checkSkuCode()"
        @click="handleCommonClick(60)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />

      <!-- 数量 -->
      <ips-input-number
        v-model="expressData.qty"
        :label="$t('label.expressQty')"
        ref="ref70"
        params="qty"
        display="qty"
        type="number"
        :pre-parse-url="urlData['qty']"
        :custom="customData['qty']"
        @click="handleCommonClick(70)"
        @enter="skipNext('submitRef')"
        @down="skipNext('submitRef')"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />

      <hips-switch-cell
        @change="switchTag"
        v-model="expressData.printFlag"
        :title="$t('title.isShowPrintTag')"
      />
    </div>
    <div class="ips-footer">
      <hips-button
        size="large"
        @click.stop="handleCommonSubmit"
        ref="submitRef"
      >
        <svg-icon
          slot="icon"
          icon-class="success"
        />
        {{ $t('button.submit') }}
      </hips-button>
    </div>
  </hips-view>
</template>

<script>
import { Cell } from '@hips/vue-ui';
// 引用自己模块的api
import { checkExpressCode, checkSkuCode, saveExpressReceipts } from '../api';

export default {
  name: 'ExpressReceipts',
  data() {
    return {
      expressData: {
        printFlag: true,
        pieceQty: '1',
      },
      expressBackCode: '',
      precision: 0,
      confirmDisabled: false,
      urlData: {},
      customData: {},
    };
  },
  components: {
    [Cell.name]: Cell,
  },
  methods: {
    switchTag(val) {
      this.expressData.printFlag = val
    },

    // 检验商品条码
    async checkSkuCode() {
      const input = this.$refs.ref60;
      if (input.value) {
        try {
          let data = {
            params: {
              sku: input.value,
            },
          };
          const res = await checkSkuCode(data);
          console.log(res)
          if (res?.failed) {
            this.skipNext(60)
          } else {
            this.skipNext(70)
          }
        } catch {
          // 
        }
      } else {
        this.skipNext(70)
      }
    },

    // 检验快递单号 SF1366920313204
    async checkExpressCode() {
      const input = this.$refs.ref10;
      try {
        let data = {
          params: {
            expressBackCode: input.value,
          },
        };
        const res = await checkExpressCode(data);
        if (res) {
          if (!res.expressBackType) {
            this.skipNext(20)
          } else {
            this.expressData.expressBackType = res.expressBackType
            this.expressData.expressBackTypeName = res.expressBackTypeName
            this.skipNext(30)
          }
        }
      } catch {
        // 
      }
    },

    init() {
      this.expressData = Object.assign({}, {
        printFlag: true,
        pieceQty: '1',
      })
      this.skipNext(10)
    },

    // // 检验退回件数
    // checkPieceQty() {
    //   const input = this.$refs.ref30;
    //   if (input.value < 1) {
    //     this.$hint.showFailed(this.$i18n.t('请输入大于0的正整数'));
    //     this.$skipNext(30)
    //   } else {
    //     this.$skipNext(40)
    //   }
    // },

    /**
     * 省份、城市选择
     * @param {Object} obj 选中对象
     */
    chooseProvince(type, obj) {
      const { regionId, regionName } = obj;
      const expressData = this.expressData
      expressData.regionId = regionId
      expressData[type] = regionName
    },
    /**
     * lov选择框，选择数据
     * @param type lov
     * @param obj 选中对象
     */
    handleChoose(type, obj) {
      const expressData = this.expressData
      const { expressBackType, expressBackTypeName } = obj
      switch (type) {
        case 'expressBackType':
          expressData.expressBackType = expressBackType
          expressData.expressBackTypeName = expressBackTypeName
          break;
        default:
          return null;
      }
    },
    // 保存来源解析字段
    async saveSourceField(fieldObj) {
      this.saveSourceParseField(this, fieldObj);
    },
    // 字段聚焦时赋值操作
    async valuedByFoucs(options = {}) {
      const { custom = {}, currentValue } = options;
      await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
    },

    /**
     * 提交
     */
    async handleButtonSubmit() {
      if (!this.expressData.expressBackCode && !this.expressData.expressBackType) {
        this.$hint.showFailed(this.$i18n.t('message.checkRequired'));
        return;
      }

      if (!this.confirmDisabled) {
        this.confirmDisabled = true
        this.$hint.showLoading();
        this.handleSubmitStatus();
        const data = this.expressData;
        data.pieceQty = +data.pieceQty
        data.qty = +data.qty

        saveExpressReceipts(data).then((res) => {
          if (res && !res.failed) {
            this.$hint.showSuccess(this.$i18n.t('message.operationSuccess'));
            this.confirmDisabled = false;
            this.init()
          }
        }).catch(() => {
          this.confirmDisabled = false;
        }).finally(() => {
          this.$hint.hideLoading();
          this.handleSubmitStatus();
        });
      }
    },
  },

  mounted() {
    this.urlData = this.handleData('EXPRESS_RECEIPTS_QUERY');
    this.skipNext(10);
  },
};
</script>

<style lang="stylus" scoped>
@import '~@/style/var.styl';

.out_box {
  border-radius: 1.6vw;
  margin-top: 4.267vw;
  width: 100%;
  padding: 2.7vw 0px;
  color: #000;
  font-size: 3.467vw;
  box-shadow: 0 0 2.133vw #d9d9d9;
}

.ips-btn-normal {
  border-radius: 8px !important;
  margin: 12px 10px 12px 10px;
  background-color: var(--primaryColor) !important;
  color: #ffffff !important;
  font-size: 17px !important;
}

.ips-btn-no-value {
  border-radius: 8px !important;
  margin: 12px 10px 12px 10px;
  background-color: #D9D9D9 !important;
}
</style>
