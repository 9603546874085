var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("div", { staticClass: "top" }, [
        _c("div", { staticClass: "left" }, [
          _vm._v(
            "\n      " + _vm._s(_vm.$t("label.defaultWarehouse")) + "\n    "
          ),
        ]),
        _c(
          "div",
          { staticClass: "right", on: { click: _vm.openPopupModal } },
          [
            _c("svg-icon", {
              attrs: { slot: "icon", "icon-class": "add" },
              slot: "icon",
            }),
            _vm._v(
              "\n      " + _vm._s(_vm.$t("label.chooseWarehouse")) + "\n    "
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "content" }, [
        _vm.showImg
          ? _c("img", {
              attrs: {
                src: require("@/public/assets/pic-no-data.png"),
                alt: "'NO Data'",
              },
            })
          : _vm._e(),
        _vm.showContent
          ? _c(
              "ul",
              { staticClass: "warehouse_area" },
              _vm._l(_vm.selectedWarehouseList, function (item, index) {
                return _c("li", { key: index }, [
                  _c("span", [_vm._v(_vm._s(item.warehouseCode))]),
                  _c("span", [_vm._v(_vm._s(item.warehouseName))]),
                  _c(
                    "span",
                    {
                      ref: "icon-del",
                      refInFor: true,
                      staticClass: "icon-del",
                      on: {
                        click: function ($event) {
                          return _vm.handleDelete(item)
                        },
                      },
                    },
                    [
                      _c("svg-icon", {
                        attrs: { slot: "icon", "icon-class": "delete_sm" },
                        slot: "icon",
                      }),
                    ],
                    1
                  ),
                ])
              }),
              0
            )
          : _vm._e(),
      ]),
      _c(
        "hips-popup",
        {
          attrs: { position: "bottom", overlay: true },
          model: {
            value: _vm.showPopup,
            callback: function ($$v) {
              _vm.showPopup = $$v
            },
            expression: "showPopup",
          },
        },
        [
          _c("div", { staticClass: "popup_title" }, [
            _c(
              "span",
              { staticClass: "title_item", on: { click: _vm.handleCancel } },
              [_vm._v(_vm._s(_vm.$t("button.cancel")))]
            ),
            _c("div", { staticClass: "title_item" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("label.chooseWarehouse")) +
                  "\n      "
              ),
            ]),
            _c(
              "span",
              { staticClass: "title_item", on: { click: _vm.handleSave } },
              [_vm._v(_vm._s(_vm.$t("button.save")))]
            ),
          ]),
          _c(
            "div",
            { staticClass: "popup_content" },
            [
              _c(
                "hips-scroll",
                {
                  ref: "scroll",
                  attrs: {
                    "scroll-options": _vm.scrollOption,
                    data: [_vm.allWarehouseList],
                  },
                  on: {
                    "pulling-down": _vm.loadRefresh,
                    "pulling-up": _vm.loadMore,
                  },
                },
                [
                  _c(
                    "hips-checkbox-group",
                    {
                      attrs: { "checkbox-border": true },
                      model: {
                        value: _vm.selectedOptions,
                        callback: function ($$v) {
                          _vm.selectedOptions = $$v
                        },
                        expression: "selectedOptions",
                      },
                    },
                    _vm._l(_vm.allWarehouseList, function (item, index) {
                      return _c(
                        "hips-checkbox",
                        {
                          key: index,
                          attrs: {
                            option: item.warehouseId.toString(),
                            position: "right",
                          },
                        },
                        [
                          _c("div", { staticClass: "warehouse_item" }, [
                            _c("span", [_vm._v(_vm._s(item.warehouseCode))]),
                            _c("span", [_vm._v(_vm._s(item.warehouseName))]),
                          ]),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }