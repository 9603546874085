import TaskExecution from '../views/task-execution.vue'
import ExecutionConfirm from '../views/execution-confirm.vue'
import SerialExecution from '../views/serial-execution.vue'


let route = [{
    path: '/task-execution',
    name: 'TaskExecution',
    component: TaskExecution,
  }, {
    path: '/execution-confirm',
    name: 'ExecutionConfirm',
    component: ExecutionConfirm,
  }, {
    path: '/serial-execution',
    name: 'SerialExecution',
    component: SerialExecution,
  }
]

export default route