import textA from '../views/text-a'
import textB from '../views/text-b'
import textC from '../views/text-c'




let route = [{
    path: '/text-a',
    name: 'text-a',
    component: textA,
  },{
    path: '/text-b',
    name: 'text-b',
    component: textB,
  },{
    path: '/text-c',
    name: 'text-c',
    component: textC,
  },
]

export default route