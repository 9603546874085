<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.importedDetail')" :is-index="false" @beforeBack="goBack" />
    </div>
    <div class="header-row">
      <div class="header-title">
        {{ $t('label.enteredQty') }}: {{ enteredQty || '--' }}
      </div>
    </div>
    <div class="ips-container">
      <ips-no-data v-if="decompositionData.length <= 0" />
      <div v-if="decompositionData.length > 0">
        <div class="card_countLocation" v-for="(item, index) in decompositionData" :key="index">
          <div class="row">
            <div class="row-50">
              <div class="title">
                {{ $t('label.batchCode') }}:
              </div>
              <div class="value">
                {{ item.toBatchCode }}
              </div>
            </div>
            <div class="row-50">
              <div class="title">
                {{ $t('label.amount') }}:
              </div>
              <div class="label">
                {{ item.qty }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="row-50">
              <div class="title">
                {{ $t('label.sku') }}:
              </div>
              <div class="label">
                {{ item.toSku }}
              </div>
            </div>
            <div class="row-50">
              <div class="title">
                {{ $t('label.whareaCode') }}:
              </div>
              <div class="value">
                {{ item.toWhareaCode }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="row-50">
              <div class="title">
                {{ $t('label.locationCode') }}:
              </div>
              <div class="value">
                {{ item.toLocationCode }}
              </div>
            </div>
            <div class="row-50">
              <div class="title">
                {{ $t('label.tray') }}:
              </div>
              <div class="value">
                {{ item.toCidCode }}
              </div>
            </div>
          </div>
          <div class="row-detail">
            <a class="detail" @click.stop="handleCommonEdit(item)">
              {{ $t('button.edit') }}
            </a>
            <a class="delete" @click.stop="handleCommonDelete(item)">
              {{ $t('button.delete') }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </hips-view>
</template>

<script>
import { Row, Col, Icon } from '@hips/vue-ui';
export default {
  name: 'ImportedDetailSplit',
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
  },
  data() {
    return {
      editFlag: '', // 编辑界面进入
      editQty: '',
      enteredQty: '',
      decompositionData: '',
    };
  },
  methods: {
    // 获取params传来的参
    getParamsData() {
      const { decompositionData, enteredQty, editFlag, editQty } = this.$route.params;
      this.decompositionData = decompositionData;
      this.enteredQty = enteredQty;
      this.editFlag = editFlag;
      this.editQty = editQty;
    },
    // 删除
    handleCommonDelete(data) {
      const onConfirm = () => {
        this.decompositionData.forEach((item, index) => {
          if (item.uuid === data.uuid) {
            this.decompositionData.splice(index, 1);
            this.enteredQty = this.enteredQty - item.qty;
          }
        });
      };
      const onCancel = () => {};
      // 是否确认删除？
      this.$hint.showAlert(this.$i18n.t('message.confirmDeletion'), onConfirm, onCancel);
    },
    // 编辑
    handleCommonEdit(data) {
      this.enteredQty = this.enteredQty - data.qty;
      let param = {
        name: 'batchDecompositionConfirm',
        params: {
          source: 'importedDetail',
          editFlag: true,
          editData: data,
        },
      };
      this.$router.pushPage(param);
    },
    // 返回
    async goBack() {
      this.$router.backPage();
      // if(this.editFlag){
      //   this.enteredQty = this.enteredQty - this.editQty;
      // }
      // let param = {
      //   name: 'BatchSplitConfirm',
      //   params: {
      //     source: 'importedDetail',
      //   },
      // };
      // this.$router.pushPage(param);
    },
  },
  mounted() {
    this.getParamsData();
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'BatchSplitConfirm') {
      this.$storage.set('decompositionData', this.decompositionData);
      this.$storage.set('enteredQty', this.enteredQty);
    }
    this.$nextTick(() => {
      next();
    });
  },
};
</script>

<style lang="stylus" scoped>
@import '~@/style/var.styl'
 .header-row{
   padding-top 5px
   display flex;
   font-size: 18px;
   font-weight bold
   .header-title{
     display flex;
     align-items: center;
     height:28px;
     padding 0 12px;
   }
 }
   .ips-container{
       .card_countLocation{
           margin-top 10px
           padding  0 12px
           height:130px;
           border-radius: 1.6vw;
           color: #000;
           box-shadow: 0 0 2.133vw #d9d9d9;
           border: none;
           font-size: 14px;
           .row{
               display flex
               padding  7px 0
               .row-50{
                   width 50%
                   display flex
                   .title{
                       width:56px;
                       height:16px;
                       font-size:14px;
                       font-family:PingFangSC-Regular,PingFang SC;
                       font-weight:200;
                       color:#595959;
                       line-height:18px;
                   }
                   .value{
                       width:110px;
                       height:18px;
                       font-size:14px;
                       font-family:PingFangSC-Medium,PingFang SC;
                       font-weight:initial;
                       color:#000000;
                       line-height:18px;
                       overflow: hidden;      /*溢出隐藏*/
                       white-space: nowrap;	/*规定文本不进行换行*/
                       text-overflow: ellipsis;	/*当对象内文本溢出时显示省略标记（...）*/
                   }
                   .label{
                       width:40px;
                       height:18px;
                       font-size:14px;
                       font-family:PingFangSC-Medium,PingFang SC;
                       font-weight:initial;
                       color:#000000;
                       line-height:18px;
                   }
                   .label-number{
                       width:40px;
                       height:18px;
                       font-size:14px;
                       font-family:PingFangSC-Medium,PingFang SC;
                       font-weight:initial;
                       color:#9254DE;
                       line-height:18px;
                   }
               }
               .title{
                   width:56px;
                   height:16px;
                   font-size:14px;
                   font-family:PingFangSC-Regular,PingFang SC;
                   font-weight:200;
                   color:#595959;
                   line-height:18px;
               }
               .value{
                   width:260px;
                   height:18px;
                   font-size:14px;
                   font-family:PingFangSC-Medium,PingFang SC;
                   font-weight:initial;
                   color:#000000;
                   line-height:18px;
                   overflow: hidden;      /*溢出隐藏*/
                   white-space: nowrap;	/*规定文本不进行换行*/
                   text-overflow: ellipsis;	/*当对象内文本溢出时显示省略标记（...）*/
               }
           }
         .row-detail{
           display flex
           padding  7px 0
           border-top: 1px solid #d9d9d9;
           .detail{
             width:28px;
             height:16px;
             font-size:14px;
             font-family:PingFangSC-Regular,PingFang SC;
             font-weight:200;
             color:var(--primaryColor);
             line-height:16px;
           }
           .delete{
             margin-left 20px;
             width:28px;
             height:16px;
             font-size:14px;
             font-family:PingFangSC-Regular,PingFang SC;
             font-weight:200;
             color #d50000;
             line-height:16px;
           }
         }
       }
   }
</style>
