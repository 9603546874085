var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: { title: _vm.$t("title.importedDetail"), "is-index": false },
          on: { beforeBack: _vm.goBack },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "header-row" }, [
      _c("div", { staticClass: "header-title" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.$t("label.enteredQty")) +
            ": " +
            _vm._s(_vm.enteredQty || "--") +
            "\n    "
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _vm.margeData.length <= 0 ? _c("ips-no-data") : _vm._e(),
        _vm.margeData.length > 0
          ? _c(
              "div",
              _vm._l(_vm.margeData, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "card_countLocation" },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "row-50" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("label.batchCode")) +
                              ":\n            "
                          ),
                        ]),
                        _c("div", { staticClass: "value" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.fromBatchCode) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "row-50" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("label.amount")) +
                              ":\n            "
                          ),
                        ]),
                        _c("div", { staticClass: "label" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.qty) +
                              "\n            "
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "row-50" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("label.sku")) +
                              ":\n            "
                          ),
                        ]),
                        _c("div", { staticClass: "label" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.fromSku) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "row-50" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("label.whareaCode")) +
                              ":\n            "
                          ),
                        ]),
                        _c("div", { staticClass: "value" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.fromWhareaCode) +
                              "\n            "
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "row-50" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("label.locationCode")) +
                              ":\n            "
                          ),
                        ]),
                        _c("div", { staticClass: "value" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.fromLocationCode) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "row-50" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("label.tray")) +
                              ":\n            "
                          ),
                        ]),
                        _c("div", { staticClass: "value" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.fromCidCode) +
                              "\n            "
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "row-detail" }, [
                      _c(
                        "a",
                        {
                          staticClass: "detail",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.handleCommonEdit(item)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("button.edit")) +
                              "\n          "
                          ),
                        ]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "delete",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.handleCommonDelete(item)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("button.delete")) +
                              "\n          "
                          ),
                        ]
                      ),
                    ]),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }