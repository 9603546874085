var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [_c("ips-header", { attrs: { "is-index": false, title: "边拣边分" } })],
      1
    ),
    _c("div", { staticClass: "ips-container" }, [
      _c(
        "ul",
        { staticClass: "piking-cart-box" },
        _vm._l(_vm.taskPickingList, function (item) {
          return _c(
            "li",
            { key: item.code, staticClass: "piking-cart-box-item" },
            [
              _c(
                "div",
                {
                  staticClass: "piking-cart-box-item__inner",
                  class: { "num-active": item.value > 0 },
                },
                [
                  _c("p", [_vm._v(_vm._s(item.code))]),
                  _c("div", [_vm._v(_vm._s(item.value))]),
                ]
              ),
            ]
          )
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "ips-footer" },
        [
          _c(
            "hips-button",
            {
              ref: "submitRef",
              attrs: { size: "large" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleCommonSubmit.apply(null, arguments)
                },
              },
            },
            [
              _vm._v(
                "\n        " + _vm._s(_vm.$t("button.confirm")) + "\n      "
              ),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }