var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: { title: _vm.$t("title.batchMarge"), "is-index": true },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["fromBatchCode"]
                ? _vm.hideControl["fromBatchCode"]
                : true,
              expression:
                "customData['fromBatchCode'] ? hideControl['fromBatchCode']: true",
            },
          ],
          ref: "ref0",
          attrs: {
            label: _vm.$t("label.sourceBatch"),
            title: _vm.$t("label.batchCode"),
            required: "",
            params: "batchCode",
            url:
              _vm.margeData.length > 0
                ? _vm.wmdmBaseUrl + "batchs/batch-modify-lov"
                : _vm.wmdmBaseUrl + "batchs/batch-code-lov",
            "url-params": {
              isbatchMerge: 1,
              enabledFlag: 1,
              goodsId: _vm.disableGoodsId,
            },
            custom: _vm.customData["fromBatchCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            enter: function ($event) {
              _vm.margeData.length > 0 ? _vm.skipNext(20) : _vm.skipNext(10)
            },
            down: function ($event) {
              _vm.margeData.length > 0 ? _vm.skipNext(20) : _vm.skipNext(10)
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(0)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(0, "lov")
            },
            choose: function ($event) {
              return _vm.handleChoose("fromBatchCode", $event)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.fromBatchCode,
            callback: function ($$v) {
              _vm.fromBatchCode = $$v
            },
            expression: "fromBatchCode",
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["fromSku"]
                ? _vm.hideControl["fromSku"]
                : true,
              expression:
                "customData['fromSku'] ? hideControl['fromSku']: true",
            },
          ],
          ref: "ref10",
          attrs: {
            label: _vm.$t("label.sku"),
            title: _vm.$t("label.sku"),
            required: "",
            params: "sku",
            url: _vm.wmdmBaseUrl + "batchs/batch-modify-lov",
            "url-params": { batchCode: _vm.fromBatchCode, isbatchMerge: 1 },
            disabled: _vm.margeData.length > 0,
            custom: _vm.customData["fromSku"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(10)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(10, "lov")
            },
            choose: function ($event) {
              return _vm.handleChoose("fromSku", $event)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.fromSku,
            callback: function ($$v) {
              _vm.fromSku = $$v
            },
            expression: "fromSku",
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["fromCidCode"]
                ? _vm.hideControl["fromCidCode"]
                : true,
              expression:
                "customData['fromCidCode'] ? hideControl['fromCidCode']: true",
            },
          ],
          ref: "ref20",
          attrs: {
            label: _vm.$t("label.sourceTray"),
            title: _vm.$t("label.sourceTray"),
            params: "cidCode",
            url: _vm.winvBaseUrl + "app-batch-modify/original-cid-lov",
            "url-params": _vm.fromCidParam,
            custom: _vm.customData["fromCidCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(20)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(20, "lov")
            },
            enter: function ($event) {
              return _vm.skipNext(30)
            },
            down: function ($event) {
              return _vm.skipNext(30)
            },
            choose: function ($event) {
              return _vm.handleChoose("fromCidCode", $event)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.fromCidCode,
            callback: function ($$v) {
              _vm.fromCidCode = $$v
            },
            expression: "fromCidCode",
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["fromLocationCode"]
                ? _vm.hideControl["fromLocationCode"]
                : true,
              expression:
                "customData['fromLocationCode'] ? hideControl['fromLocationCode']: true",
            },
          ],
          ref: "ref30",
          attrs: {
            label: _vm.$t("label.sourceLocation"),
            title: _vm.$t("label.sourceLocation"),
            required: "",
            params: "locationCode",
            url: _vm.winvBaseUrl + "app-batch-modify/original-location-lov",
            "url-params": {
              batchId: _vm.fromBatchId,
              goodsId: _vm.goodsId,
              cidId: _vm.fromCidId,
            },
            custom: _vm.customData["fromLocationCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(30)
            },
            clickIcon: function ($event) {
              return _vm.handleCommonClick(30, "lov")
            },
            enter: _vm.handleLocEnter,
            down: _vm.handleLocEnter,
            choose: function ($event) {
              return _vm.handleChoose("fromLocationCode", $event)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.fromLocationCode,
            callback: function ($$v) {
              _vm.fromLocationCode = $$v
            },
            expression: "fromLocationCode",
          },
        }),
        _c("ips-lov", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["fromWhareaCode"]
                ? _vm.hideControl["fromWhareaCode"]
                : true,
              expression:
                "customData['fromWhareaCode'] ? hideControl['fromWhareaCode']: true",
            },
          ],
          ref: "ref40",
          attrs: {
            label: _vm.$t("label.sourceWharea"),
            title: _vm.$t("label.sourceWharea"),
            required: "",
            disabled: "",
            custom: _vm.customData["fromWhareaCode"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.fromWhareaCode,
            callback: function ($$v) {
              _vm.fromWhareaCode = $$v
            },
            expression: "fromWhareaCode",
          },
        }),
        _c("ips-input-number", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.enableSerial
                ? _vm.customData["transferQty"]
                  ? _vm.hideControl["transferQty"]
                  : !_vm.enableSerial
                : false,
              expression:
                "!enableSerial ? (customData['transferQty'] ? hideControl['transferQty'] : !enableSerial) : false",
            },
          ],
          ref: "ref50",
          attrs: {
            label: _vm.$t("label.amount"),
            required: "",
            custom: _vm.customData["transferQty"],
            "source-parse-field": _vm.sourceParseField,
            "not-auto-fill": "",
            min: null,
            "enable-serial": _vm.serialType,
            "more-than-zero": true,
          },
          on: {
            blur: _vm.checkNumBlur,
            enter: function ($event) {
              _vm.margeData.length > 0
                ? _vm.skipNext("nextRef")
                : _vm.skipNext("submitRef")
            },
            down: function ($event) {
              _vm.margeData.length > 0
                ? _vm.skipNext("nextRef")
                : _vm.skipNext("submitRef")
            },
            tab: function ($event) {
              _vm.margeData.length > 0
                ? _vm.skipNext("nextRef")
                : _vm.skipNext("submitRef")
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.transferQty,
            callback: function ($$v) {
              _vm.transferQty = $$v
            },
            expression: "transferQty",
          },
        }),
        _vm.enableSerial
          ? _c("hips-row", [
              _c(
                "div",
                { staticClass: "hip-row" },
                [
                  _c(
                    "hips-col",
                    { attrs: { span: "21" } },
                    [
                      _c("ips-input-number", {
                        ref: "ref50",
                        attrs: {
                          label: _vm.$t("label.amount"),
                          required: "",
                          disabled: "",
                          "enable-serial": _vm.serialType,
                          custom: _vm.customData["transferQty"],
                          "source-parse-field": _vm.sourceParseField,
                          "not-auto-fill": "",
                          min: null,
                          "more-than-zero": true,
                        },
                        on: {
                          enter: function ($event) {
                            return _vm.skipNext("refSerail")
                          },
                          down: function ($event) {
                            return _vm.skipNext("refSerail")
                          },
                          tab: function ($event) {
                            return _vm.skipNext("refSerail")
                          },
                          setSourceField: function ($event) {
                            return _vm.saveSourceField($event)
                          },
                          valuedByFoucs: function ($event) {
                            return _vm.valuedByFoucs($event)
                          },
                        },
                        model: {
                          value: _vm.transferQty,
                          callback: function ($$v) {
                            _vm.transferQty = $$v
                          },
                          expression: "transferQty",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "hips-col",
                    { attrs: { span: "8" } },
                    [
                      _c(
                        "hips-button",
                        {
                          ref: "refSerail",
                          staticClass: "button-detail",
                          attrs: { size: "large" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.goToSerialDetail.apply(null, arguments)
                            },
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.goToSerialDetail.apply(null, arguments)
                            },
                            keypress: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("button.serialNumber")) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "count_block_box" },
          [
            _c("ips-number-count", {
              staticClass: "count-availableQty",
              attrs: {
                count: _vm.availableQty,
                required: !_vm.enableSerial,
                disabled: _vm.enableSerial,
                "text-field": "availableQty",
              },
            }),
            _c("ips-number-count", {
              staticClass: "count-useQty",
              attrs: { count: _vm.useQty, "text-field": "useQty" },
            }),
            _c("ips-number-count", {
              staticClass: "count-enteredQty",
              attrs: { count: _vm.enteredQty, "text-field": "enteredQty" },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _vm.enteredQty > 0
      ? _c("div", { staticClass: "footer-button" }, [
          _c(
            "div",
            { staticClass: "footer-button-box" },
            [
              _c(
                "hips-button",
                {
                  ref: "nextRef",
                  staticClass: "next",
                  attrs: { size: "large", disabled: _vm.isDisabled },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleNext.apply(null, arguments)
                    },
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.handleNext.apply(null, arguments)
                    },
                    keypress: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("button.next")) + "\n      ")]
              ),
              _c(
                "hips-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.customData["importedDetail"]
                        ? _vm.hideControl["importedDetail"]
                        : true,
                      expression:
                        "customData['importedDetail'] ? hideControl['importedDetail']: true",
                    },
                  ],
                  staticClass: "importedDetail",
                  attrs: { size: "large" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleImportedDetail.apply(null, arguments)
                    },
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.handleImportedDetail.apply(null, arguments)
                    },
                    keypress: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("button.importedDetail")) + "\n      ")]
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "ips-footer" },
      [
        !_vm.enteredQty
          ? _c(
              "hips-button",
              {
                ref: "submitRef",
                attrs: { size: "large", disabled: _vm.isDisabled },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleNext.apply(null, arguments)
                  },
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleNext.apply(null, arguments)
                  },
                  keypress: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [_vm._v("\n      " + _vm._s(_vm.$t("button.next")) + "\n    ")]
            )
          : _vm._e(),
        _vm.enteredQty > 0
          ? _c(
              "hips-button",
              {
                ref: "submitRef",
                attrs: { size: "large", disabled: _vm.isDisabled },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.submit.apply(null, arguments)
                  },
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.submit.apply(null, arguments)
                  },
                  keypress: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [_vm._v("\n      " + _vm._s(_vm.$t("button.marge")) + "\n    ")]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }