<template>
  <hips-view>
    <ips-header :title="$t('title.ipsBatch')" :is-index="false" />
    <div class="ips-container">
      <ips-value-cell v-for="(item, index) in cellList" :key="index" :title="item.title" :value="skuInfo[item.value]" />
      <!-- 供应商编码 -->
      <ips-lov
        style="margin-top: 16px;"
        :label="$t('label.supplierCode')"
        ref="ref30"
        :title="[$t('label.vendor'), $t('label.supplierCode')]"
        :params="['vendorName', 'vendorCode']"
        v-model="batch.vendorCode"
        display="vendorCode"
        :url="`${wmdmBaseUrl}/vendors/lov`"
        @enter="skipNext(0)"
        @clickIcon="handleCommonClick(30, 'lov')"
        @click.stop="handleCommonClick(30)"
        @choose="handleChoose('vendorCode', $event)"
      />
      <!-- 供应商批号 -->
      <ips-field
        :label="$t('label.vendorNumber')"
        ref="ref0"
        v-model="batch.vendorBatch"
        @enter="skipNext(10)"
        @click.stop="handleCommonClick(0)"
      />
      <!-- 批次状态 -->
      <ips-lov
        :label="$t('label.battcStatus')"
        ref="ref10"
        :title="$t('label.battcStatus')"
        v-model="batch.batchStatusMeaning"
        :params="['meaning']"
        :is-page-content="false"
        :url="`${wmdmBaseUrl}wms-common/lov-data-query?lovCode=WMDM.BATCH_STATUS`"
        @enter="skipNext(20)"
        @clickIcon="handleCommonClick(10, 'lov')"
        @click.stop="handleCommonClick(10)"
        @choose="handleChoose('batchStatusMeaning', $event)"
      />
      <!-- 备注 -->
      <ips-field
        :label="$t('label.note')"
        ref="ref20"
        v-model="batch.remark"
        @enter="skipNext('submitRef')"
        @click.stop="handleCommonClick(20)"
      />
      <div class="ips-footer">
        <hips-button size="large" ref="submitRef" @click="handleCommonSubmit" class="btn">
          <svg-icon slot="icon" icon-class="success" />{{ $t('button.confirm') }}
        </hips-button>
      </div>
    </div>
  </hips-view>
</template>

<script>
export default {
  name: 'IpsBatch',
  data() {
    return {
      batch: {
        vendorBatch: '',
        vendorCode: '',
        vendorId: '',
        batchStatusMeaning: '',
        remark: '',
      },
      cellList: [],
      skuInfo: {},
      formCode: null,
    };
  },
  methods: {
    getDataFromParam() {
      let { batchCode, skuInfo = {}, formCode } = this.$route.params;
      this.skuInfo = {
        ...skuInfo,
        batchCode,
      };
      this.formCode = formCode;
    },
    async handleButtonSubmit() {
      let { source } = this.$route.params;
      try {
        this.skuInfo = {
          ...this.skuInfo,
          ...this.batch,
          // vendorCode: this.batch.vendorCode,
          // vendorId: this.batch.vendorId,
        };
        const url = `${this.wmdmBaseUrl}batchs`;
        let data = Object.assign({}, this.isbatch, this.skuInfo);
        data.id = null;
        data.remark = this.batch.remark;
        this.$hint.showLoading();
        this.handleSubmitStatus();
        this.$http.post(url, data).then((res) => {
          if (res) {
            this.$hint.showSuccess(this.$t('message.batchCreatedSuccess'));
            if (this.formCode && this.formCode === 'cid') {
              this.$storage.set('cid-batchId', res.batchId);
            } else {
              this.$storage.set('batchId', res.batchId);
            }
            this.$hint.hideLoading();
            if (source === 'BatchModifyConfirm' || source === 'BatchSplitConfirm') {
              const urlParam = {
                name: source,
                params: {
                  source: 'createBatch',
                  batchId: res.batchId,
                },
              };
              this.$router.pushPage(urlParam);
            } else if (source === 'BatchMargeConfirm') {
              const urlParam = {
                name: source,
                params: {
                  source: 'createBatch',
                  batchId: res.batchId,
                },
              };
              this.$router.pushPage(urlParam);
            } else {
              this.$router.backPage();
            }
          }
        });
      } finally {
        this.$hint.hideLoading();
        this.handleSubmitStatus();
      }
    },
    handleChoose(type, obj) {
      switch (type) {
        case 'vendorCode':
          this.batch.vendorId = obj.vendorId;
          break;
        case 'batchStatusMeaning':
          this.batch.batchStatusMeaning = obj.meaning;
          this.batch.batchStatus = obj.value;
          break;
        default:
          return null;
      }
    },
  },
  mounted() {
    console.log(this.batch);
    this.getDataFromParam();
    this.$refs['ref30'].focus();
    this.inputFieldList = [
      { type: 'input', required: false, ref: 0, bind: 'batch.vendorBatch' },
      { type: 'lov', required: false, ref: 10, bind: 'batch.batchStatusMeaning' },
      { type: 'input', required: false, ref: 20, bind: 'batch.remark' },
      { type: 'lov', required: false, ref: 30, bind: 'batch.vendorCode' },
    ];
    this.cellList = [
      {
        title: this.$t('label.batchCode'), // 批次编码
        value: 'batchCode',
      },
      {
        title: this.$t('label.orgName'), // 组织名称,
        value: 'orgName',
      },
      {
        title: this.$t('label.ownerName'), // 货主名称,
        value: 'ownerName',
      },
      {
        title: this.$t('label.skuCode'), // 物料代码
        value: 'sku',
      },
    ];
  },
};
</script>

<style lang="stylus" scoped>
 @import '~@/style/var.styl'
.btn {
  border-radius: 8px !important;
  background-color var(--primaryColor) !important;
  color #ffffff !important;
  font-size 17px !important;
  margin-right: 0.8rem;
  margin-left: 0.8rem;
  height: 100%;
  margin-bottom: 10px;
}
</style>
