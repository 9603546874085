import request from '@/request';
import mixin  from '@/mixin/mixin';

// 提交
export function submitConfirm(data) {
  let url = `${mixin.data().winvBaseUrl}app-sorting-locations/confirm`;
  return request.post(url, data)
}

// 获取波次单下默认关联信息
export function queryDefaultInfo(data) {
  let url = `${mixin.data().winvBaseUrl}app-sorting-locations/query-default-info`;
  return request.get(url, data);
}

