<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.batchSerialDetail')" :is-index="false" />
    </div>
    <div class="ips-container">
      <div
        v-for="(item, index) in lineData"
        :key="index"
        style="marginBottom: 30px"
      >
        <!--物料-->
        <ips-value-cell
          :title="$t('label.sku')"
          :value="item.sku"
          v-show="customData['sku'] ? hideControl['sku'] : true"
        />
        <!--品名-->
        <ips-value-cell
          :title="$t('label.goodsName')"
          :value="item.goodsName"
          v-show="customData['goodsName'] ? hideControl['goodsName'] : true"
        />
        <!--批次-->
        <ips-value-cell
          :title="$t('label.batch')"
          :value="item.batchCode"
          v-show="customData['batchCode'] ? hideControl['batchCode'] : true"
        />
        <!--货主-->
        <ips-value-cell
          :title="$t('label.ownerCode')"
          :value="item.ownerName"
          v-show="customData['ownerName'] ? hideControl['ownerName'] : true"
        />
        <!--供应商代码-->
        <ips-value-cell
          :title="$t('label.vendorCode')"
          :value="item.vendorCode"
          v-show="customData['vendorCode'] ? hideControl['vendorCode'] : true"
        />
        <!--供应商批号-->
        <ips-value-cell
          :title="$t('label.vendorNumber')"
          :value="item.vendorBatch"
          v-show="customData['vendorBatch'] ? hideControl['vendorBatch'] : true"
        />
        <!--批次状态-->
        <ips-value-cell
          :title="$t('label.battcStatus')"
          :value="item.batchStatusMeaning"
          v-show="customData['batchStatusMeaning'] ? hideControl['batchStatusMeaning'] : true"
        />
        <!--生产日期-->
        <ips-value-cell
          :title="$t('label.produceDate')"
          :value="item.produceDate"
          v-show="customData['produceDate'] ? hideControl['produceDate'] : true"
        />
        <!--有效日期-->
        <ips-value-cell
          :title="$t('label.expireDate')"
          :value="item.expireDate"
          v-show="customData['expireDate'] ? hideControl['expireDate'] : true"
        />
        <!--下次质检日期-->
        <ips-value-cell
          :title="$t('label.nextCheckDate')"
          :value="item.nextCheckDate"
          v-show="customData['nextCheckDate'] ? hideControl['nextCheckDate'] : true"
        />
        <!--现有量-->
        <ips-value-cell
          :title="$t('label.availableQty')"
          :value="item.qty"
          v-show="customData['qty'] ? hideControl['qty'] : true"
        />
        <!--可用量-->
        <ips-value-cell
          :title="$t('label.useQty')"
          :value="item.validQty"
          v-show="customData['validQty'] ? hideControl['validQty'] : true"
        />
      </div>
    </div>
  </hips-view>
</template>

<script>
import { queryBatchStock } from '../api';
export default {
  name: "BatchQueryDetail",
  data() {
    return {
      inputFieldList: [
        { type: 'text', bind: 'sku', field: 'sku' },
        { type: 'text', bind: 'goodsName', field: 'goodsName' },
        { type: 'text', bind: 'batchCode', field: 'batchCode' },
        { type: 'text', bind: 'ownerName', field: 'ownerName' },
        { type: 'text', bind: 'vendorCode', field: 'vendorCode' },
        { type: 'text', bind: 'vendorBatch', field: 'vendorBatch' },
        { type: 'text', bind: 'batchStatusMeaning', field: 'batchStatusMeaning' },
        { type: 'text', bind: 'produceDate', field: 'produceDate' },
        { type: 'text', bind: 'expireDate', field: 'expireDate' },
        { type: 'text', bind: 'nextCheckDate', field: 'nextCheckDate' },
        { type: 'text', bind: 'qty', field: 'qty' },
        { type: 'text', bind: 'validQty', field: 'validQty' },
      ],
      sku: '', //物料
      billCode: '',
      batchCode: '', //批次
      lineData: [],
      fatherProps: {},
      customData: {}, // 个性化配置信息列表
      hideControl: {}, // 个性化配置显示隐藏控制
    };
  },
  mounted() {
    this.getDataFromParams();
    this.getDetail();
  },

  methods: {
    /**
     * 获取上个页面的传参
     */
    getDataFromParams(){
      const {fatherProps, batchCode, customData, hideControl} = this.$route.params;
      this.customData = customData;
      this.hideControl = hideControl;
      if(batchCode){
        this.batchCode = batchCode;
        this.fatherProps = fatherProps;
      }
    },
    /**
     * 获取详情信息
     */
    async getDetail() {
      try {
        this.$hint.showLoading();
        // let url = `${this.winvBaseUrl}app-inv/query-batch-stock`;
        let param = {
          params: {
            batchCode: this.batchCode,
            sku: this.fatherProps.sku,
            goodsId: this.fatherProps.goodsId,
          }
        };
        // const res = await this.$http.get(url, param);
        const res = await queryBatchStock(param);
        this.lineData = res;
      } catch (e) {
        console.log(e);
      } finally {
        this.$hint.hideLoading();
      }
    },
    //返回
     goBack() {
      this.$router.backPage();
    }
  }
};
</script>

<style lang="stylus" scoped>
 @import '~@/style/var.styl'
</style>