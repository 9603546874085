import sortingSeeding from '../views/sorting-seeding'

let route = [
  {
    path: '/sorting-seeding',
    name: 'sortingSeeding',
    component: sortingSeeding,
  },
]

export default route