<template>
  <div class="number_count_box" :style="{'border': `1px solid ${borderColor}`, 'background': backgroundColor}">
    <div class="context">
      <div class="number_count" :style="{'color': fontColor}">
        {{ count }}
      </div>
      <div class="number_title" :style="{'color': fontColor}">
        {{ $t(`label.${textField}`) }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "IpsNumberCount",
    data() {
      return {
        expand: false,
      };
    },
    props: {
      textField: {
        type: String,
        default: '',
      },
      count: {
        type: [String, Number],
        default: '0',
      },
      backgroundColor: {
        type: String,
        default: ''
      },
      borderColor: {
        type: String,
        default: ''
      },
      fontColor: {
        type: String,
        default: ''
      }
    },
  };
</script>

<style lang="stylus">
    .number_count_box {
      height: 100%;
      width: 30%;
      border-radius: 8px;
      padding: 5vw 0;
      text-align: center;
      position: relative;
    }
    
    .number_count {
      font-weight: bold;
      font-size: 24px;
      z-index: 99;
    }

    .number_title {
      font-size: 14px;
      z-index: 99;
    }

</style>
