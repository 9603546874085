var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: { title: _vm.$t("title.serialChange"), "is-index": true },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _c("ips-input", {
          ref: "ref0",
          attrs: {
            label: _vm.$t("label.sourceSerialNumber"),
            custom: _vm.customData["sourceSerialNumber"],
            "source-parse-field": _vm.sourceParseField,
            required: "",
            "pre-parse-url": _vm.urlData["sourceSerialNumber"],
            "not-auto-fill": "",
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(0)
            },
            enter: _vm.validateSourceSerial,
            down: _vm.validateSourceSerial,
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.inputData.sourceSerialNumber,
            callback: function ($$v) {
              _vm.$set(_vm.inputData, "sourceSerialNumber", $$v)
            },
            expression: "inputData.sourceSerialNumber",
          },
        }),
        _c(
          "div",
          { staticClass: "out_box" },
          [
            _c("ips-value-cell", {
              attrs: {
                title: _vm.$t("label.sku"),
                "image-flag": "",
                value: _vm.serialData.sku,
              },
            }),
            _c("ips-value-cell", {
              attrs: {
                title: _vm.$t("label.goodsName"),
                "image-flag": "",
                value: _vm.serialData.goodsName,
              },
            }),
            _vm.serialData.batchCode
              ? _c("ips-value-cell", {
                  attrs: {
                    title: _vm.$t("label.batchCode"),
                    "image-flag": "",
                    value: _vm.serialData.batchCode,
                  },
                })
              : _vm._e(),
            _c("ips-value-cell", {
              attrs: {
                title: _vm.$t("label.ownerCode"),
                "image-flag": "",
                value: _vm.getOwnerCodeStr,
              },
            }),
            _c("ips-value-cell", {
              attrs: {
                title: _vm.$t("label.warehouse"),
                "image-flag": "",
                value: _vm.serialData.warehouseName,
              },
            }),
            _c("ips-value-cell", {
              attrs: {
                title: _vm.$t("label.whareaCode"),
                "image-flag": "",
                value: _vm.serialData.whareaCode,
              },
            }),
            _c("ips-value-cell", {
              attrs: {
                title: _vm.$t("label.locationCode"),
                "image-flag": "",
                value: _vm.serialData.locationCode,
              },
            }),
          ],
          1
        ),
        _c("ips-input", {
          ref: "ref10",
          attrs: {
            label: _vm.$t("label.targetSerialNumber"),
            custom: _vm.customData["targetSerialNumber"],
            "source-parse-field": _vm.sourceParseField,
            required: "",
            disabled: !_vm.serialData.sourceSerialId,
            "pre-parse-url": _vm.urlData["targetSerialNumber"],
            "not-auto-fill": "",
          },
          on: {
            enter: _vm.validateTargetSerial,
            down: _vm.validateTargetSerial,
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.inputData.targetSerialNumber,
            callback: function ($$v) {
              _vm.$set(_vm.inputData, "targetSerialNumber", $$v)
            },
            expression: "inputData.targetSerialNumber",
          },
        }),
        _c(
          "div",
          { staticClass: "ips-footer" },
          [
            _c(
              "hips-button",
              {
                ref: "submitRef",
                attrs: { size: "large", loading: _vm.submitLoading },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.onHanldeSubmitData.apply(null, arguments)
                  },
                },
              },
              [
                _c("svg-icon", {
                  attrs: { slot: "icon", "icon-class": "success" },
                  slot: "icon",
                }),
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("button.confirm")) + "\n      "
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }