import _ from 'lodash';
import bridge from './handBridge';
import router from '../router';
import http from '../request/index';
import hint from './hint';

//子应用主页返回
export function mainPageGoBack() {
  if (process.env.VUE_APP_TARGET === 'all') {
    router.backPage();
  } else {
    bridge.closeWebView();
  }
}

export function isEmpty(data) {
  return ['', undefined, null, 'undefined'].includes(data);
}

// 使用toFixed四舍五入 不兼容导致计算精度有误Bug, 使用此方法解决toFixed兼容问题
export function toFixedNum(num, s = 2) {
  if (this.isEmpty(num)) return '';
  if (num.toFixed(s) === '-' + (0).toFixed(s)) return (0).toFixed(s);

  const value = Number(num);
  // eslint-disable-next-line
  const times = Math.pow(10, s + 1);
  const des = parseInt(value * times, 0);
  const rest = des % 10;
  if (rest === 5) {
    return ((parseFloat(des) + 1) / times).toFixed(s);
  }
  return value.toFixed(s);
}

/**
 * 自动给金额加上千分位
 * @param {*} m 金额
 * @param {number} precision  保留几位小数
 * @param {boolean} needThousnds 是否需要千分位
 * @param {boolean} fix 是否需对参数进行处理
 */
export function moneyFormat(m, precision, needThousnds = true, fix = true) {
  // 空校验
  if (isEmpty(m)) {
    return '';
  }

  // 转为数字
  m = _.toNumber(m);

  // 转换异常处理
  if (!m && m !== 0) {
    return '';
  }

  // 保留两位小数并加上千分位
  let num;
  m = fix ? this.toFixedNum(m, precision || 0) : m.toString();
  if (precision) {
    // const isInt = m.indexOf('.') === -1;
    // const isSingleDecimal = /^\d*\.\d{1}$/.test(m);
    // if (isInt || isSingleDecimal) {
    // m = this.toFixedNum(_.toNumber(m), precision);
    // }
    const first = m.replace(/\B(?=(?:\d{3})+\b)/g, ',');
    console.log('first', first);
    num = first.replace(/\.$/, '');
  } else {
    num = m.toString().replace(/(\d)(?=(?:\d{3})+$)/g, `$1${needThousnds ? ',' : ''}`);
  }
  return num;
}
export function refMoneyFormat(a) {
  if (a === null || a === '' || a === 0) {
    return '';
  }
  return a.replace(/,/g, '');
}

/**
 * 附件上传
 * @param imgList 图片数组  bucketName 桶名
 * @return true
 */
export async function imgUpload(imgList, bucketName = 'wms-file') {
  hint.showLoading();
  const uuid = (await getUUid()).content;
  let uploadList = [];
  for (let img of imgList) {
    let form = toFormData(img.src);
    let url = `${process.env.VUE_APP_HFLE_URL}v1/1/files/attachment/multipart?organizationId=1&bucketName=${bucketName}&attachmentUUID=${uuid}`;
    uploadList.push(http.postFormData(url, form));
  }
  return Promise.all(uploadList).then(() => {
    hint.hideLoading();
    hint.showSuccess('图片上传成功');
  });
  //获取uuid
  function getUUid() {
    let param = {
      organizationId: '1',
    };
    return http.post(`${process.env.VUE_APP_HFLE_URL}v1/1/files/uuid`, param);
  }
  //base64转File类型
  function toFormData(imgData) {
    let arr = imgData.split(',');
    const type = imgData.match(/image(\S*);base64/)[1].slice(1);
    const fileName = new Date().getTime() + '.' + type;
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    console.log([u8arr]);
    let formData = new FormData();
    formData.append('file', new File([u8arr], fileName, { type: mime }));
    console.log(formData);
    return formData;
  }
}

const bailRE = /[^\w.$]/;
export function parsePath(path) {
  if (bailRE.test(path)) {
    return;
  }
  const segments = path.split('.');
  return function (obj) {
    for (let i = 0; i < segments.length; i++) {
      if (!obj) return;
      obj = obj[segments[i]];
    }
    return obj;
  };
}

/**
 * 将键值对形式参数格式化为查询参数字符串
 * @param {object} query 键值对形式参数
 * @param {boolean} isFilterEmpty 是否过滤空值
 * @returns {string} 返回带问号的url查询参数字符串
 */
export function queryStringify(query, isFilterEmpty = true) {
  const queryArr = [];
  Object.keys(query).forEach((key) => {
    const value = query[key];
    if (isFilterEmpty) {
      value && queryArr.push(`${key}=${value}`);
    } else {
      queryArr.push(`${key}=${value}`);
    }
  });
  return queryArr.join('&');
}

export function nextRandom(start, end) {
  return Math.random() * (end - start) + start;
}

// 精度数值计算控制
export function numberAdd(num1, num2) {
  const num1Digits = (num1.toString().split('.')[1] || '').length;
  const num2Digits = (num2.toString().split('.')[1] || '').length;
  const baseNum = Math.pow(10, Math.max(num1Digits, num2Digits));
  return parseFloat(((num1 * baseNum + num2 * baseNum) / baseNum).toPrecision(12));
}


/**
 * 过滤掉对象值为 undefined 和 空字符串 和 空数组 的属性
 * @param {Object} obj
 * @returns {Object} 过滤后的查询参数
 */
 export function filterNullValueObject(obj) {
  const result = {}
  if (obj && Object.keys(obj).length >= 1) {
    Object.keys(obj).forEach((key) => {
      if (key && obj[key] !== undefined && obj[key] !== '' && obj[key] !== null) {
        // 如果查询的条件不为空
        if (Array.isArray(obj[key]) && obj[key].length === 0) {
          return
        }
        result[key] = obj[key]
      }
    })
  }
  return result // 返回查询条件
}
