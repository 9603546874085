import request from '@/request';
import mixin  from '@/mixin/mixin';


// 收获调整包存
export function handleSaveData(data) {
  let url = `${mixin.data().winvBaseUrl}app-inbound-receive-adjust/receive-reduce`;
  return request.post(url, data);
}

// 序列号查询
export function handleQuerySerial(data) {
  let url = `${mixin.data().winvBaseUrl}app-inbound-receive-adjust/serial-lov`;
  return request.post(url, data);
}

// 获取行数据
export function getCidDetail(data) {
  let url = `${mixin.data().winvBaseUrl}app-inbound-receive-adjust/query-inbound-order-by-cid`;
  return request.get(url, data);
}

// 一键取消收货
export function cancelReceiptData(data) {
  let url = `${mixin.data().winvBaseUrl}app-inbound-receive-adjust/all-cancel-receive`;
  return request.post(url, data);
}
