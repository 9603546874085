var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: {
            "is-index": false,
            "render-data": {
              title: _vm.$t("title.associatedSite"),
              billCode: _vm.billCode,
            },
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _c(
          "div",
          { staticClass: "ips-outbound-style" },
          [
            _c("ips-lov", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.customData["outboundOrder"]
                    ? _vm.hideControl["outboundOrder"]
                    : true,
                  expression:
                    "customData['outboundOrder'] ? hideControl['outboundOrder']: true",
                },
              ],
              ref: "ref0",
              attrs: {
                label: _vm.$t("label.queryType"),
                title: _vm.$t("label.queryType"),
                params: "meaning",
                url: _vm.wmdmBaseUrl + "wms-common/lov-data-query",
                "url-params": {
                  lovCode: "WINV.SORTING_LOCATION_MAPPING_QUERY",
                },
                "pre-parse-url": _vm.urlData["outboundOrder"],
                "is-page-content": false,
                custom: _vm.customData["outboundOrder"],
                "source-parse-field": _vm.sourceParseField,
                "is-control": "",
                readonly: true,
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleCommonClick(0)
                },
                clickIcon: function ($event) {
                  return _vm.handleCommonClick(0, "lov")
                },
                enter: function ($event) {
                  return _vm.skipNext(10)
                },
                down: function ($event) {
                  return _vm.skipNext(10)
                },
                choose: function ($event) {
                  return _vm.handleChoose("outboundOrder", $event)
                },
                setSourceField: function ($event) {
                  return _vm.saveSourceField($event)
                },
                valuedByFoucs: function ($event) {
                  return _vm.valuedByFoucs($event)
                },
              },
              model: {
                value: _vm.billData.outboundOrder,
                callback: function ($$v) {
                  _vm.$set(_vm.billData, "outboundOrder", $$v)
                },
                expression: "billData.outboundOrder",
              },
            }),
          ],
          1
        ),
        _c(
          "hips-row",
          [
            _c(
              "hips-col",
              { attrs: { span: "19" } },
              [
                _c("ips-field", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.customData["querySearch"]
                        ? _vm.hideControl["querySearch"]
                        : true,
                      expression:
                        "customData['querySearch'] ? hideControl['querySearch']: true",
                    },
                  ],
                  ref: "ref10",
                  attrs: {
                    label: _vm.$t("label.queryConditions"),
                    params: "querySearch",
                    display: "querySearch",
                    "pre-parse-url": _vm.urlData["querySearch"],
                    custom: _vm.customData["querySearch"],
                    "source-parse-field": _vm.sourceParseField,
                  },
                  on: {
                    enter: function ($event) {
                      return _vm.skipNext("selectRef")
                    },
                    down: function ($event) {
                      return _vm.skipNext("selectRef")
                    },
                    setSourceField: function ($event) {
                      return _vm.saveSourceField($event)
                    },
                    valuedByFoucs: function ($event) {
                      return _vm.valuedByFoucs($event)
                    },
                  },
                  model: {
                    value: _vm.billData.querySearch,
                    callback: function ($$v) {
                      _vm.$set(_vm.billData, "querySearch", $$v)
                    },
                    expression: "billData.querySearch",
                  },
                }),
              ],
              1
            ),
            _c(
              "hips-col",
              { staticClass: "ips-row-left", attrs: { span: "4" } },
              [
                _c(
                  "hips-button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.customData["search"]
                          ? _vm.hideControl["search"]
                          : true,
                        expression:
                          "customData['search'] ? hideControl['search']: true",
                      },
                    ],
                    ref: "selectRef",
                    attrs: { type: "warning" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.handleButtonSubmit.apply(null, arguments)
                      },
                    },
                    nativeOn: {
                      keyup: [
                        function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.handleButtonSubmit.apply(null, arguments)
                        },
                        function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k($event.keyCode, "down", 40, $event.key, [
                              "Down",
                              "ArrowDown",
                            ])
                          ) {
                            return null
                          }
                          return _vm.handleButtonSubmit.apply(null, arguments)
                        },
                      ],
                      keypress: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("button.search")) +
                        "\n        "
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "ips-table" }, [
          _c(
            "table",
            [
              _c(
                "tr",
                { staticStyle: { "background-color": "#eee" } },
                _vm._l(_vm.tableTitle, function (item, index) {
                  return _c("th", { key: index, attrs: { scope: "col" } }, [
                    _vm._v(
                      "\n            " + _vm._s(item.name) + "\n          "
                    ),
                  ])
                }),
                0
              ),
              _vm._l(_vm.tableData, function (item, index) {
                return _c(
                  "tr",
                  { key: index, ref: "tr" + index, refInFor: true },
                  [
                    _c(
                      "td",
                      [
                        _c("ips-field", {
                          ref: "ref" + (20 * index + 20),
                          refInFor: true,
                          attrs: {
                            required: "",
                            "back-required": "",
                            disabled: !item.isEdit,
                            "dis-background": !item.isEdit,
                          },
                          on: {
                            enter: function ($event) {
                              return _vm.handleTableEnter(index)
                            },
                            down: function ($event) {
                              return _vm.handleTableEnter(index)
                            },
                          },
                          model: {
                            value: item.sortingLocation,
                            callback: function ($$v) {
                              _vm.$set(item, "sortingLocation", $$v)
                            },
                            expression: "item.sortingLocation",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "td",
                      [
                        _c("ips-lov", {
                          ref: "ref" + (20 * index + 30),
                          refInFor: true,
                          attrs: {
                            required: "",
                            "back-required": "",
                            url:
                              _vm.winvBaseUrl +
                              "app-sorting-locations/query-outbound-order",
                            "url-params": { billId: _vm.billId },
                            "pre-parse-url": _vm.urlData["billCode"],
                            params: "billCode",
                            disabled: !item.isEdit,
                            "dis-background": !item.isEdit,
                            readonly: !item.isEdit,
                          },
                          on: {
                            enter: function ($event) {
                              return _vm.handleTableLovEnter(index)
                            },
                            down: function ($event) {
                              return _vm.handleTableLovEnter(index)
                            },
                            choose: function ($event) {
                              return _vm.chooseChildBill($event, item, index)
                            },
                            blur: function ($event) {
                              return _vm.handleCommonClick(
                                20 * index + 30,
                                "lov"
                              )
                            },
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.handleCommonClick(20 * index + 30)
                            },
                            clickIcon: function ($event) {
                              return _vm.handleCommonClick(
                                20 * index + 30,
                                "lov"
                              )
                            },
                          },
                          model: {
                            value: item.billCode,
                            callback: function ($$v) {
                              _vm.$set(item, "billCode", $$v)
                            },
                            expression: "item.billCode",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              }),
            ],
            2
          ),
        ]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-footer" },
      [
        _c(
          "hips-button",
          {
            ref: "submitRef",
            attrs: { size: "large" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleAssociatedSubmit.apply(null, arguments)
              },
            },
          },
          [
            _c("svg-icon", {
              attrs: { slot: "icon", "icon-class": "success" },
              slot: "icon",
            }),
            _vm._v(
              "\n      " + _vm._s(_vm.$t("button.associatedConfirm")) + "\n    "
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }