import RevokeDelivery from '../views/revoke-delivery.vue'


let route = [
  {
    path: '/revoke-delivery',
    name: 'RevokeDelivery',
    component: RevokeDelivery,
  },
]

export default route