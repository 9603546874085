var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "hips-view",
    { attrs: { "header-fixed": true } },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [_c("ips-header", { attrs: { title: _vm.$t("title.analyse") } })],
        1
      ),
      _c(
        "hips-row",
        { staticClass: "ips-search" },
        [
          _c(
            "hips-col",
            { attrs: { span: "20" } },
            [
              _c("ips-field", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.customData["matchCode"]
                      ? _vm.hideControl["matchCode"]
                      : true,
                    expression:
                      "customData['matchCode'] ? hideControl['matchCode'] : true",
                  },
                ],
                ref: "ref0",
                attrs: {
                  label: _vm.$t("label.checkTheDimensionOrderNumber"),
                  custom: _vm.customData["matchCode"],
                  "source-parse-field": _vm.sourceParseField,
                  required: "",
                },
                on: {
                  setSourceField: function ($event) {
                    return _vm.saveSourceField($event)
                  },
                  enter: _vm.handleButtonQuery,
                },
                model: {
                  value: _vm.matchCode,
                  callback: function ($$v) {
                    _vm.matchCode = $$v
                  },
                  expression: "matchCode",
                },
              }),
            ],
            1
          ),
          _c(
            "hips-col",
            { staticClass: "ips-row-left", attrs: { span: "4" } },
            [
              _c(
                "hips-button",
                {
                  ref: "selectRef",
                  attrs: { type: "warning" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleButtonQuery.apply(null, arguments)
                    },
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.handleButtonQuery.apply(null, arguments)
                    },
                    keypress: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("button.query")) + "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "ips-container" },
        [
          _c("span", { staticClass: "instructions" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("label.instructions")) + "\n    "
            ),
          ]),
          _c("hips-row", { staticClass: "ips-row" }, [
            _c(
              "div",
              {
                staticClass: "link-item",
                on: {
                  enter: function ($event) {
                    return _vm.selectSearchType("PACK")
                  },
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.selectSearchType("PACK")
                  },
                },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("message.analyseItemTracking")) +
                    "\n      "
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "link-item",
                on: {
                  enter: function ($event) {
                    return _vm.selectSearchType("BILL")
                  },
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.selectSearchType("BILL")
                  },
                },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("message.analyseItemOrder")) +
                    "\n      "
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "link-item",
                on: {
                  enter: function ($event) {
                    return _vm.selectSearchType("WAVE")
                  },
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.selectSearchType("WAVE")
                  },
                },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("message.analyseItemEave")) +
                    "\n      "
                ),
              ]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }