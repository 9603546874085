import request from '@/request';
import mixin  from '@/mixin/mixin';

// 买多
export function multiLpnPack(data) {
  let url = `${mixin.data().winvBaseUrl}app-multi-lpn-pack/pack`;
  return request.post(url, data);
}

// 查询子托盘列表
export function queryChildCid(data) {
  let url = `${mixin.data().wmdmBaseUrl}containers/child-cid-list`;
  return request.get(url, data);
}


// 整托撤销
export function unpackToCid(data) {
  let url = `${mixin.data().winvBaseUrl}app-multi-lpn-pack/unpack-to-cid`;
  return request.post(url, data);
}
// 打散撤销
export function unpackToGoods(data) {
  let url = `${mixin.data().winvBaseUrl}app-multi-lpn-pack/unpack-to-goods`;
  return request.post(url, data);
}


// 打散撤销
export function checkChildCid(data) {
  let url = `${mixin.data().winvBaseUrl}app-multi-lpn-pack/check-child-cid`;
  return request.post(url, data);
}

