var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: { title: _vm.$t("title.singleProcessing"), "is-index": false },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _c("ips-value-cell", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["billCode"]
                ? _vm.hideControl["billCode"]
                : true,
              expression:
                "customData['billCode'] ? hideControl['billCode']: true",
            },
          ],
          attrs: {
            title: _vm.$t("label.processingNum"),
            value: _vm.detailData.billCode,
          },
        }),
        _c("ips-value-cell", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["workTypeName"]
                ? _vm.hideControl["workTypeName"]
                : true,
              expression:
                "customData['workTypeName'] ? hideControl['workTypeName']: true",
            },
          ],
          attrs: {
            title: _vm.$t("label.processingType"),
            value: _vm.detailData.workTypeName,
          },
        }),
        _c("ips-value-cell", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["ownerName"]
                ? _vm.hideControl["ownerName"]
                : true,
              expression:
                "customData['ownerName'] ? hideControl['ownerName']: true",
            },
          ],
          attrs: {
            title: _vm.$t("label.ownerCode"),
            value: _vm.detailData.ownerName,
          },
        }),
        _c("ips-value-cell", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["whareaName"]
                ? _vm.hideControl["whareaName"]
                : true,
              expression:
                "customData['whareaName'] ? hideControl['whareaName']: true",
            },
          ],
          attrs: {
            title: _vm.$t("label.assemblyArea"),
            value: _vm.detailData.whareaName,
          },
        }),
        _c("ips-value-cell", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["locationCode"]
                ? _vm.hideControl["locationCode"]
                : true,
              expression:
                "customData['locationCode'] ? hideControl['locationCode']: true",
            },
          ],
          attrs: {
            title: _vm.$t("label.assemblyLocation"),
            value: _vm.detailData.locationCode,
          },
        }),
        _c("ips-value-cell", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["sku"] ? _vm.hideControl["sku"] : true,
              expression: "customData['sku'] ? hideControl['sku']: true",
            },
          ],
          attrs: {
            title: _vm.$t("label.parentSku"),
            value: _vm.detailData.sku,
          },
        }),
        _c("ips-value-cell", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["goodsName"]
                ? _vm.hideControl["goodsName"]
                : true,
              expression:
                "customData['goodsName'] ? hideControl['goodsName']: true",
            },
          ],
          attrs: {
            title: _vm.$t("label.goodsName"),
            value: _vm.detailData.goodsName,
          },
        }),
        _c("ips-value-cell", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["fatherGoodsValidQty"]
                ? _vm.hideControl["fatherGoodsValidQty"]
                : true,
              expression:
                "customData['fatherGoodsValidQty'] ? hideControl['fatherGoodsValidQty']: true",
            },
          ],
          attrs: {
            title: _vm.$t("label.currentUseQty"),
            value: _vm.detailData.fatherGoodsValidQty,
          },
        }),
        _c("ips-value-cell", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["planQty"]
                ? _vm.hideControl["planQty"]
                : true,
              expression:
                "customData['planQty'] ? hideControl['planQty']: true",
            },
          ],
          attrs: {
            title: _vm.$t("label.toBeProcessed"),
            value: _vm.detailData.planQty - _vm.detailData.workQty,
          },
        }),
        _c("ips-field", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.customData["workQty"]
                ? _vm.hideControl["workQty"]
                : true,
              expression:
                "customData['workQty'] ? hideControl['workQty']: true",
            },
          ],
          ref: "ref0",
          attrs: {
            label: _vm.$t("label.processingQty"),
            required: "",
            custom: _vm.customData["workQty"],
            "source-parse-field": _vm.sourceParseField,
          },
          on: {
            enter: _vm.handleWorkQtyEnter,
            down: _vm.handleWorkQtyEnter,
            blur: _vm.checkMachiningQty,
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleCommonClick(0)
            },
            setSourceField: function ($event) {
              return _vm.saveSourceField($event)
            },
            valuedByFoucs: function ($event) {
              return _vm.valuedByFoucs($event)
            },
          },
          model: {
            value: _vm.workQty,
            callback: function ($$v) {
              _vm.workQty = $$v
            },
            expression: "workQty",
          },
        }),
        (
          _vm.detailData.isbatch && _vm.isAssemble
            ? _vm.customData["batchCode"]
              ? _vm.hideControl["batchCode"]
              : _vm.detailData.isbatch && _vm.isAssemble
            : false
        )
          ? _c("ips-field", {
              ref: "ref10",
              attrs: {
                label: _vm.$t("label.batch"),
                params: "batchCode",
                display: "batchCode",
                "pre-parse-url": _vm.urlData["batchCode"],
                required: "",
                custom: _vm.customData["batchCode"],
                "source-parse-field": _vm.sourceParseField,
              },
              on: {
                enter: function ($event) {
                  return _vm.handleTableEnter(-1)
                },
                down: function ($event) {
                  return _vm.handleTableEnter(-1)
                },
                blur: _vm.checkBatchCode,
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleCommonClick(10)
                },
                setSourceField: function ($event) {
                  return _vm.saveSourceField($event)
                },
                valuedByFoucs: function ($event) {
                  return _vm.valuedByFoucs($event)
                },
              },
              model: {
                value: _vm.detailData.batchCode,
                callback: function ($$v) {
                  _vm.$set(_vm.detailData, "batchCode", $$v)
                },
                expression: "detailData.batchCode",
              },
            })
          : _vm._e(),
        (
          _vm.detailData.isbatch && !_vm.isAssemble
            ? _vm.customData["batchCode"]
              ? _vm.hideControl["batchCode"]
              : _vm.detailData.isbatch && !_vm.isAssemble
            : false
        )
          ? _c("ips-lov", {
              ref: "ref10",
              attrs: {
                label: _vm.$t("label.batch"),
                title: _vm.$t("label.batch"),
                required: "",
                custom: _vm.customData["batchCode"],
                "source-parse-field": _vm.sourceParseField,
                url:
                  _vm.winvBaseUrl +
                  "app-direct-pick/outbound-query-trans-batch",
                "url-params": {
                  locationId: _vm.detailData.locationId,
                  ownerId: _vm.detailData.ownerId,
                  goodsId: _vm.detailData.goodsId,
                },
                "pre-parse-url": _vm.urlData["batchCode"],
                params: "batchCode",
              },
              on: {
                choose: function ($event) {
                  return _vm.chooseFatherBatch($event)
                },
                enter: function ($event) {
                  return _vm.handleTableEnter(-1)
                },
                down: function ($event) {
                  return _vm.handleTableEnter(-1)
                },
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleCommonClick(10)
                },
                clickIcon: function ($event) {
                  return _vm.handleCommonClick(10, "lov")
                },
                setSourceField: function ($event) {
                  return _vm.saveSourceField($event)
                },
                valuedByFoucs: function ($event) {
                  return _vm.valuedByFoucs($event)
                },
              },
              model: {
                value: _vm.detailData.batchCode,
                callback: function ($$v) {
                  _vm.$set(_vm.detailData, "batchCode", $$v)
                },
                expression: "detailData.batchCode",
              },
            })
          : _vm._e(),
        _c("div", { staticClass: "ips-table" }, [
          _c(
            "table",
            [
              _c(
                "tr",
                { staticStyle: { "background-color": "#eee" } },
                _vm._l(_vm.tableTitle, function (item, index) {
                  return _c("th", { key: index, attrs: { scope: "col" } }, [
                    _vm._v(
                      "\n            " + _vm._s(item.name) + "\n          "
                    ),
                  ])
                }),
                0
              ),
              _vm._l(_vm.tableData, function (item, index) {
                return _c(
                  "tr",
                  { key: index, ref: "tr" + index, refInFor: true },
                  [
                    _c("td", [_vm._v(_vm._s(item.sku))]),
                    _c("td", [_vm._v(_vm._s(item.qty))]),
                    _c("td", [_vm._v(_vm._s(item.validQty))]),
                    _c(
                      "td",
                      [
                        _vm.isAssemble
                          ? _c("ips-lov", {
                              ref: "ref" + (index * 10 + 20),
                              refInFor: true,
                              attrs: {
                                title: _vm.$t("label.batch"),
                                required: "",
                                "nolabel-required": "",
                                url:
                                  _vm.winvBaseUrl +
                                  "app-direct-pick/outbound-query-trans-batch",
                                "url-params": {
                                  locationId: _vm.detailData.locationId,
                                  ownerId: item.ownerId,
                                  goodsId: item.goodsId,
                                },
                                "pre-parse-url": _vm.urlData["tableBatchCode"],
                                params: "batchCode",
                                disabled: !item.isbatch,
                                "dis-background": !item.isbatch,
                              },
                              on: {
                                enter: function ($event) {
                                  return _vm.handleTableEnter(index)
                                },
                                down: function ($event) {
                                  return _vm.handleTableEnter(index)
                                },
                                choose: function ($event) {
                                  return _vm.chooseChildBatch(
                                    $event,
                                    item,
                                    index
                                  )
                                },
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.handleCommonClick(index * 10 + 20)
                                },
                                clickIcon: function ($event) {
                                  return _vm.handleCommonClick(
                                    index * 10 + 20,
                                    "lov"
                                  )
                                },
                              },
                              model: {
                                value: item.batchCode,
                                callback: function ($$v) {
                                  _vm.$set(item, "batchCode", $$v)
                                },
                                expression: "item.batchCode",
                              },
                            })
                          : _vm._e(),
                        !_vm.isAssemble
                          ? _c("ips-field", {
                              ref: "ref" + (index * 10 + 20),
                              refInFor: true,
                              attrs: {
                                required: "",
                                "nolabel-required": "",
                                params: "tableBatchCode",
                                display: "tableBatchCode",
                                "pre-parse-url": _vm.urlData["tableBatchCode"],
                                disabled: item.isbatch === 0,
                                "dis-background": item.isbatch === 0,
                              },
                              on: {
                                blur: function ($event) {
                                  return _vm.checkBatchCode($event, item, index)
                                },
                                enter: function ($event) {
                                  return _vm.handleTableEnter(index)
                                },
                                down: function ($event) {
                                  return _vm.handleTableEnter(index)
                                },
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.handleCommonClick(index * 10 + 20)
                                },
                              },
                              model: {
                                value: item.batchCode,
                                callback: function ($$v) {
                                  _vm.$set(item, "batchCode", $$v)
                                },
                                expression: "item.batchCode",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                )
              }),
            ],
            2
          ),
        ]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-footer" },
      [
        _c(
          "hips-button",
          {
            ref: "submitRef",
            attrs: { size: "large" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCommonSubmit.apply(null, arguments)
              },
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleCommonSubmit.apply(null, arguments)
              },
              keypress: function ($event) {
                $event.preventDefault()
              },
            },
          },
          [
            _c("svg-icon", {
              attrs: { slot: "icon", "icon-class": "success" },
              slot: "icon",
            }),
            _vm._v(
              "\n      " + _vm._s(_vm.$t("button.processComplete")) + "\n    "
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }