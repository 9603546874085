<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <!-- 标签打印 -->
      <ips-header :title="$t('title.labelPrint')" @beforeBack="clearStorage" />
    </div>
    <div class="ips-container">
      <!-- 打印机 -->
      <ips-lov
        :label="$t('label.printer')"
        :title="[$t('title.code'), $t('label.printer')]"
        required
        v-model="printer.printerName"
        v-show="customData['printerName'] ? hideControl['printerName'] : true"
        ref="ref0"
        :params="['value', 'meaning']"
        display="value"
        :custom="customData['printerName']"
        :url="`${winvBaseUrl}print-records/printer-lov`"
        :url-params="{ value: printer.printerCode }"
        :is-page-content="false"
        :source-parse-field="sourceParseField"
        @click.stop="handleCommonClick(0)"
        @clickIcon="handleCommonClick(0, 'lov')"
        @enter="skipNext(1)"
        @down="skipNext(1)"
        @choose="handleChoose('printer', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 打印模板 -->
      <ips-lov
        :label="$t('label.printMould')"
        :title="$t('label.printMould')"
        required
        params="name"
        v-model="printMould.mouldName"
        :url-params="{ value: 'print' }"
        v-show="customData['mouldName'] ? hideControl['mouldName'] : true"
        ref="ref1"
        :custom="customData['mouldName']"
        :url="`${winvBaseUrl}print-records/template-lov`"
        :is-page-content="false"
        :source-parse-field="sourceParseField"
        @click.stop="handleCommonClick(1)"
        @clickIcon="handleCommonClick(1, 'lov')"
        @choose="handleChoose('mould', $event)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <div v-for="(item, index) in printParam.slice(0, -1)" :key="index + 1">
        <ips-field
          :required="printParam[index].isRequired"
          style="margin-top: 15px"
          :label="param[index].text"
          v-model="printParam[index].text"
          v-if="printParam.length - index > 1"
          :ref="`ref${index + 2}`"
          @click.stop="handleCommonClick(index + 2)"
          @enter="skipNext(index + 3)"
          @down="skipNext(index + 3)"
        />
      </div>
      <div v-for="(item, index) in printParam.slice(-1)" :key="index">
        <ips-field
          :required="printParam[printParam.length - 1].isRequired"
          style="margin-top: 15px"
          :label="param[printParam.length - 1].text"
          v-model="printParam[printParam.length - 1].text"
          :ref="`ref${printParam.length + 1}`"
          @click.stop="handleCommonClick(printParam.length + 1)"
          @enter="skipNext('submitRef')"
          @down="skipNext('submitRef')"
        />
      </div>
    </div>

    <div class="ips-footer">
      <hips-button size="large" ref="submitRef" @click.stop="handleCommonSubmit">
        <svg-icon slot="icon" icon-class="success" />
        {{ $t('button.confirm') }}
      </hips-button>
    </div>
  </hips-view>
</template>

<script>
import { storage } from '@/utils';
import { params, printsubmit } from '../api/index';
export default {
  name: 'Print',
  data() {
    return {
      isLoading: false,
      printer: {
        printerCode: '',
        printerName: '',
      },
      printMould: {
        attribute1: '',
        mouldCode: '',
        mouldName: '',
        printType: '',
        datasetId: null,
      },
      mouldName: '',
      printParam: [],
      param: [],
      metadata: '',
      urlData: {},
      customData: {}, // 个性化配置信息列表
      hideControl: {}, // 个性化配置显示隐藏控制
      sourceParseField: {}, // 界面中所有的来源解析字段
    };
  },
  watch: {
    'printer.printerCode': function (val) {
      if (!val) {
        this.printer.printerCode = '';
        this.printer.printerName = '';
      }
    },
    // 'printMould.mouldCode': function(val) {
    //   console.log(this.printer)
    // },
    param: function () {
      if (this.param.length > 0) {
        this.$nextTick(() => {
          for (let key in this.$refs) {
            if (this.$refs[key].length > 0) {
              this.$refs[key] = this.$refs[key][0];
            }
          }
          console.log(this.$refs);
          this.skipNext(2);
        });
      } else {
        this.$hint.showFailed(this.$i18n.t('message.notFindParams'), true);
      }
    },
  },
  async mounted() {
    this.skipNext(0);
    this.inputFieldList = [
      { type: 'lov', required: true, ref: 0, bind: 'printMould.printer', field: 'printMould.printer' },
      { type: 'lov', required: true, ref: 1, bind: 'printMould.mouldName', field: 'printMould.mouldName' },
    ];
  },

  methods: {
    // lov选择框，选择数据
    async handleChoose(type, obj) {
      let res;
      switch (type) {
        case 'printer':
          this.printer.printerName = obj.meaning;
          this.printer.printerCode = obj.value;
          break;
        case 'mould':
          this.printParam = [];
          this.printMould.mouldName = obj.name;
          this.printMould.mouldCode = obj.code;
          this.printMould.printType = obj.printType;
          this.printMould.datasetId = obj.dataset_id;
          this.printMould.attribute1 = obj.attribute1;
          res = await params(this.printMould.datasetId);
          this.metadata = res.metadata;
          this.param = res.params;
          this.printParam = res.params.map((e) => {
            return { name: e.name, text: '', isRequired: e.isRequired };
          });
          break;
        default:
          return null;
      }
    },

    // 字段聚焦时赋值操作
    async valuedByFoucs(options = {}) {
      const { custom = {}, currentValue } = options;
      await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
    },

    async clearStorage() {
      await this.$storage.remove('argsExtra');
      return null;
    },

    // 数据提交
    async handleButtonSubmit() {
      let validate = false;
      for (let i = 0; i < this.printParam.length; i++) {
        if (this.printParam[i].isRequired === 1 && !this.$refs[`ref${i + 2}`][0].value) {
          validate = true;
          continue;
        }
      }
      if (validate) {
        this.$hint.showFailed('请完善必输字段', true);
        return;
      }
      console.log({ validate });
      if (!this.isLoading) {
        this.isLoading = true;
        const params = {
          printer: this.printer.printerCode,
          printTemplate: this.printMould.mouldCode,
          printType: this.printMould.printType,
          printParam: JSON.stringify(this.printParam),
          metadata: this.metadata,
          attribute1: this.printMould.attribute1,
          datasetId: this.printMould.datasetId,
        };
        try {
          const res = await printsubmit(params);
          if (!res.failed) {
            this.$hint.showSuccess(this.$i18n.t('message.operationSuccess'));
            this.reAccept();
          }
        } catch (e) {
          this.skipNext(2);
          this.isLoading = false;
        } finally {
          this.isLoading = false;
        }
      }
    },

    async reAccept() {
      // this.init();
      const res = await params(this.printMould.datasetId);
      this.metadata = res.metadata;
      this.param = res.params;
      this.printParam = res.params.map((e) => {
        return { name: e.name, text: '', isRequired: e.isRequired };
      });
      this.skipNext(2);
    },

    // 数据初始化
    // init(){
    //   this.printer = {
    //     printerCode: '',
    //     printerName: '',
    //   };
    //   this.printMould = {
    //     attribute1: '',
    //     mouldCode: '',
    //     mouldName: '',
    //     printType: '',
    //   };
    //   this.printParam = [];
    //   this.metadata = '';
    // },
  },

  activated() {},

  created() {
    const { customObj, hideControl } = this.handleCustomData(storage.get('customData'));
    this.customData = customObj;
    this.hideControl = hideControl;
    console.log('收货调整customData', Object.assign({}, this.customData));
  },
};
</script>

<style lang="stylus" scoped>
.input-button {
  margin 8px 0
  .hips-button--primary {
    height 35px
    line-height 30px
  }
}
</style>
