import request from '@/request';
import mixin  from '@/mixin/mixin';

// 查询加工单详情数据
export function queryTableData(data) {
  let url = `${mixin.data().winvBaseUrl}app-work-order/bom-lines`;
  return request.get(url, data);
}

// 查询可用量
export function validChange(data) {
  let url = `${mixin.data().winvBaseUrl}app-work-order/valid-change`;
  return request.get(url, data);
}

// 校验批次是否存在
export function batchCheck(data) {
  let url = `${mixin.data().winvBaseUrl}app-work-order/batch-check`;
  return request.get(url, data);
}

// 提交
export function Process(data) {
  let url = `${mixin.data().winvBaseUrl}app-work-order/process`;
  return request.post(url, data)
}