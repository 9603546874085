<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header :title="$t('title.receiveDetail')" :is-index="false" />
    </div>
    <div class="ips-container">
      <div class="receive-detail-card" v-for="(item, index) in detailData" :key="index">
        <hips-row>
          <div class="receive-detail-card-sku">
            {{ item.sku }}
          </div>
        </hips-row>
        <hips-row>
          <div class="receive-detail-card-second">
            <hips-col span="12">
              <span class="font-color">{{ $t('label.shelvesCid') }}</span>：
              <span style="font-weight:bold">
                {{ item.cidCode }}
              </span>
            </hips-col>
            <hips-col span="12">
              <span class="font-color">{{ $t('label.shelvesBatch') }}</span>：
              <span style="font-weight: bold">{{ item.batchCode }}</span>
            </hips-col>
          </div>
        </hips-row>
        <hips-row>
          <div class="receive-detail-card-second">
            <hips-col span="12">
              <span class="font-color">{{ $t('label.shelvesLineNum') }}</span>：
              <span style="font-weight:bold">
                {{ item.lineNum }}
              </span>
            </hips-col>

            <hips-col span="12">
              <span class="font-color">{{ $t('label.shelvesUom') }}</span>：
              <span style="font-weight: bold">{{ item.uomName }}</span>
            </hips-col>
          </div>
        </hips-row>
        <hips-row>
          <div class="receive-detail-card-second">
            <hips-col span="12">
              <span class="font-color">{{ $t('label.shelvesQuantity') }}</span>：
              <span style="font-weight: bold">{{ item.receiveQty }}</span>
            </hips-col>
          </div>
        </hips-row>
        <hips-row>
          <div class="receive-detail-card-three">
            {{ item.goodsName }}
          </div>
        </hips-row>
      </div>
    </div>
  </hips-view>
</template>
<script>
import { Cell, Row, Col } from '@hips/vue-ui';
import { queryCidData } from '../api/index';
export default {
  name: 'ReceiveDetail',
  data() {
    return {
      cidId: '',
      orgId: '',
      goodsId: '',
      sourceBillId: '',
      billId: '',
      receiptNumber: '',

      detailData: [],
    };
  },
  components: {
    [Cell.name]: Cell,
    [Row.name]: Row,
    [Col.name]: Col,
  },
  methods: {
    async getDetailData() {
      // this.$hint.showLoading();
      let data = {
        params: {
          parentCidId: this.parentCidId,
          cidId: this.cidId,
          cidNull: this.parentCidId || this.cidId ? 0 : 1,
          goodsId: this.goodsId,
          sourceBillId: this.sourceBillId,
          billId: this.billId,
          receiptNumber: this.receiptNumber,
        },
      };

      queryCidData(data).then((res) => {
        if (res) {
          this.detailData = res.content;
        }
      });
      // this.$hint.hideLoading();
    },
  },
  // beforeRouteLeave(to, from, next) {
  //   if (to.name === 'waveOrderDetail') {
  //     this.$store.commit('pushKeepAlives', 'SecondarySorting');
  //   } else {
  //     this.$store.commit('deleteKeepAlives', 'SecondarySorting');
  //   }
  //   next();
  // },
  mounted() {
    // this.skipNext(0);
    const { parentCidId, cidId, goodsId, sourceBillId, billId, receiptNumber } = this.$route.params;
    this.parentCidId = parentCidId;
    this.cidId = cidId;
    this.goodsId = goodsId;
    this.sourceBillId = sourceBillId;
    this.billId = billId;
    this.receiptNumber = receiptNumber;
    this.getDetailData();
  },
};
</script>
<style lang="stylus" scoped>
 @import '~@/style/var.styl'
.receive-detail-card {
  background: #fff;
  margin-bottom: 16px;
  border-radius: 1.6vw;
  width: 100%;
  padding: 14px 12px;
  color: #000;
  font-size: 14px;
  box-shadow: 0 0 2.133vw #d9d9d9;
  box-sizing: border-box;

  .receive-detail-card-sku {
    font-weight: bold;
    color: #262626;
  }

  .receive-detail-card-second {
    padding-top: 14px;
    color: #262626;
    overflow: hidden;
  }

  .receive-detail-card-three {
    padding-top: 14px;
    color: #595959;
  }

  .font-color {
    color: #595959;
  }
}
</style>
