import StockCount from '../view/index'
import CountConfirm from '../view/count-confirm'
import InputSerial from '../view/serialInput'

let route = [
  {
    path: '/stockCount',
    name: 'stockCount',
    component: StockCount,
  },

  {
    path: '/countConfirm',
    name: 'countConfirm',
    component: CountConfirm,
  },
  {
    path: '/input-serial',
    name:'stockInputSerial',
    component: InputSerial,
  }

]

export default route
