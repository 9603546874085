var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: { title: _vm.$t("title.shelvesDetail"), "is-index": false },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _vm._l(_vm.detailData, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticStyle: { padding: "0 12px", "font-size": "14px" },
            },
            [
              _c("span", { staticStyle: { color: "#595959" } }, [
                _vm._v(_vm._s(_vm.$t("label.receiveCid"))),
              ]),
              _vm._v("：\n      "),
              _c(
                "span",
                { staticStyle: { "font-weight": "bold", color: "#262626" } },
                [_vm._v(_vm._s(item.cidCode || ""))]
              ),
            ]
          )
        }),
        _vm._l(_vm.detailData, function (item, index) {
          return _c(
            "div",
            { key: index, staticClass: "receive-detail-sku-card" },
            [
              _c("hips-row", [
                _c("div", { staticClass: "receive-detail-sku-card-sku" }, [
                  _vm._v(
                    "\n          " + _vm._s(item.sku || "") + "\n        "
                  ),
                ]),
              ]),
              _c("hips-row", [
                _c(
                  "div",
                  { staticClass: "receive-detail-sku-card-second" },
                  [
                    _c("hips-col", { attrs: { span: "12" } }, [
                      _c("span", { staticClass: "font-color" }, [
                        _vm._v(_vm._s(_vm.$t("label.shelvesPendingQty"))),
                      ]),
                      _vm._v("：\n            "),
                      _c("span", { staticStyle: { "font-weight": "bold" } }, [
                        _vm._v(_vm._s(_vm.pendingQty)),
                      ]),
                    ]),
                    _c("hips-col", { attrs: { span: "12" } }, [
                      _c("span", { staticClass: "font-color" }, [
                        _vm._v(_vm._s(_vm.$t("label.shelvesUom"))),
                      ]),
                      _vm._v("：\n            "),
                      _c("span", { staticStyle: { "font-weight": "bold" } }, [
                        _vm._v(_vm._s(item.uomName)),
                      ]),
                    ]),
                  ],
                  1
                ),
              ]),
              _c("hips-row", [
                _c("div", { staticClass: "receive-detail-sku-card-three" }, [
                  _vm._v(
                    "\n          " + _vm._s(item.goodsName) + "\n        "
                  ),
                ]),
              ]),
            ],
            1
          )
        }),
        _vm.isbatch
          ? _c(
              "div",
              { staticStyle: { "margin-top": "16px" } },
              [
                _c("ips-lov", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.customData["batchCode"]
                        ? _vm.hideControl["batchCode"]
                        : true,
                      expression:
                        "customData['batchCode'] ? hideControl['batchCode'] : true",
                    },
                  ],
                  ref: "ref0",
                  attrs: {
                    label: _vm.$t("label.batch"),
                    required: _vm.isbatch === 1,
                    title: _vm.$t("label.batch"),
                    params: "batchCode",
                    url: _vm.winvBaseUrl + "app-direct-putaway/query-batch",
                    "url-params": {
                      cidId: _vm.cidId,
                      orgId: _vm.orgId,
                      goodsId: _vm.goodsId,
                      sourceBillId: _vm.sourceBillId,
                      billId: _vm.billId,
                      receiptNumber: _vm.receiptNumber,
                    },
                    custom: _vm.customData["batchCode"],
                    "source-parse-field": _vm.sourceParseField,
                  },
                  on: {
                    enter: _vm.batchToSerial,
                    down: _vm.batchToSerial,
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleCommonClick(0)
                    },
                    clickIcon: function ($event) {
                      return _vm.handleCommonClick(0, "lov")
                    },
                    choose: function ($event) {
                      return _vm.handleChoose("batch", $event)
                    },
                    setSourceField: function ($event) {
                      return _vm.saveSourceField($event)
                    },
                    valuedByFoucs: function ($event) {
                      return _vm.valuedByFoucs($event)
                    },
                  },
                  model: {
                    value: _vm.inputData.batchCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputData, "batchCode", $$v)
                    },
                    expression: "inputData.batchCode",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.serialType !== "UNCTRL" && _vm.serialType !== "OUT_CTRL"
          ? _c(
              "hips-row",
              { staticStyle: { "margin-top": "16px" } },
              [
                _c(
                  "hips-col",
                  { attrs: { span: "14" } },
                  [
                    _c("ips-input-number", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.customData["receiveQty"]
                            ? _vm.hideControl["receiveQty"]
                            : true,
                          expression:
                            "customData['receiveQty'] ? hideControl['receiveQty'] : true",
                        },
                      ],
                      ref: "ref10",
                      attrs: {
                        label: _vm.$t("label.inboundNum"),
                        required: "",
                        min: null,
                        precision: 0,
                        custom: _vm.customData["receiveQty"],
                        "source-parse-field": _vm.sourceParseField,
                      },
                      on: {
                        enter: _vm.checkImpQty,
                        blur: _vm.changeSerialQty,
                        setSourceField: function ($event) {
                          return _vm.saveSourceField($event)
                        },
                        valuedByFoucs: function ($event) {
                          return _vm.valuedByFoucs($event)
                        },
                      },
                      model: {
                        value: _vm.receiveQty,
                        callback: function ($$v) {
                          _vm.receiveQty = $$v
                        },
                        expression: "receiveQty",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "hips-col",
                  { attrs: { span: "10" } },
                  [
                    _c(
                      "hips-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.customData["serialNumber"]
                              ? _vm.hideControl["serialNumber"]
                              : true,
                            expression:
                              "customData['serialNumber'] ? hideControl['serialNumber'] : true",
                          },
                        ],
                        ref: "refSerail",
                        staticClass: "serial-button-detail",
                        attrs: { size: "large" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.goToSerialDetail.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("button.serialNumber")) +
                            " (" +
                            _vm._s(
                              _vm.serialReceiveQtyImp !== 0
                                ? _vm.serialReceiveQtyImp
                                : _vm.serialReceiveQty
                            ) +
                            ")\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.serialType === "UNCTRL" || _vm.serialType === "OUT_CTRL"
          ? _c(
              "div",
              { staticStyle: { "margin-top": "16px" } },
              [
                _c("ips-input-number", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.customData["receiveQty"]
                        ? _vm.hideControl["receiveQty"]
                        : true,
                      expression:
                        "customData['receiveQty'] ? hideControl['receiveQty'] : true",
                    },
                  ],
                  ref: "ref50",
                  attrs: {
                    label: _vm.$t("label.inboundNum"),
                    required: "",
                    min: null,
                    "more-than-zero": true,
                    custom: _vm.customData["receiveQty"],
                    "source-parse-field": _vm.sourceParseField,
                  },
                  on: {
                    enter: _vm.checkImpQtyUnSerial,
                    blur: _vm.checkImpQtyUnSerial,
                    setSourceField: function ($event) {
                      return _vm.saveSourceField($event)
                    },
                    valuedByFoucs: function ($event) {
                      return _vm.valuedByFoucs($event)
                    },
                  },
                  model: {
                    value: _vm.receiveQty,
                    callback: function ($$v) {
                      _vm.receiveQty = $$v
                    },
                    expression: "receiveQty",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticStyle: { "margin-top": "16px" } },
          [
            _c("ips-lov", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.customData["locationCode"]
                    ? _vm.hideControl["locationCode"]
                    : true,
                  expression:
                    "customData['locationCode'] ? hideControl['locationCode'] : true",
                },
              ],
              ref: "ref20",
              attrs: {
                label: _vm.$t("label.storageLocation"),
                title: _vm.$t("label.storageLocation"),
                params: "locationCode",
                url: _vm.wmdmBaseUrl + "wms-locations",
                "url-params": {
                  warehouseId: _vm.warehouseId,
                  whareaId: _vm.whareaId,
                },
                required: "",
                custom: _vm.customData["locationCode"],
                "source-parse-field": _vm.sourceParseField,
              },
              on: {
                enter: _vm.inboundLocationEnter,
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleCommonClick(20)
                },
                clickIcon: function ($event) {
                  return _vm.handleCommonClick(20, "lov")
                },
                choose: function ($event) {
                  return _vm.handleChoose("storageLocation", $event)
                },
                setSourceField: function ($event) {
                  return _vm.saveSourceField($event)
                },
                valuedByFoucs: function ($event) {
                  return _vm.valuedByFoucs($event)
                },
              },
              model: {
                value: _vm.inputData.locationCode,
                callback: function ($$v) {
                  _vm.$set(_vm.inputData, "locationCode", $$v)
                },
                expression: "inputData.locationCode",
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "recommend-loaction" }, [
          _c("span", [_vm._v(_vm._s(_vm.$t("label.recommandLoca")) + " :")]),
          _c("span", [_vm._v(_vm._s(_vm.defaultLocation))]),
        ]),
        _vm.inputData.containerManageFlag === 1
          ? _c(
              "div",
              { staticStyle: { "margin-top": "16px" } },
              [
                _c("ips-field", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.inputData.containerManageFlag === 1
                          ? _vm.customData["inboundCidCode"]
                            ? _vm.hideControl["inboundCidCode"]
                            : _vm.inputData.containerManageFlag === 1
                          : false,
                      expression:
                        "\n          inputData.containerManageFlag === 1\n            ? customData['inboundCidCode']\n              ? hideControl['inboundCidCode']\n              : inputData.containerManageFlag === 1\n            : false\n        ",
                    },
                  ],
                  ref: "ref30",
                  attrs: {
                    label: _vm.$t("label.inboundCid"),
                    custom: _vm.customData["inboundCidCode"],
                    "source-parse-field": _vm.sourceParseField,
                  },
                  on: {
                    enter: function ($event) {
                      _vm.inputData.inboundCidCode && _vm.requireParentCidCode
                        ? _vm.skipNext(40)
                        : _vm.skipNext("submitRef")
                    },
                    down: function ($event) {
                      _vm.inputData.inboundCidCode && _vm.requireParentCidCode
                        ? _vm.skipNext(40)
                        : _vm.skipNext("submitRef")
                    },
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleCommonClick(50)
                    },
                    blur: function ($event) {
                      return _vm.checkInboundCidBlur()
                    },
                    setSourceField: function ($event) {
                      return _vm.saveSourceField($event)
                    },
                    valuedByFoucs: function ($event) {
                      return _vm.valuedByFoucs($event)
                    },
                  },
                  model: {
                    value: _vm.inputData.inboundCidCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputData, "inboundCidCode", $$v)
                    },
                    expression: "inputData.inboundCidCode",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.requireParentCidCode && _vm.inputData.containerManageFlag === 1
          ? _c(
              "div",
              { staticStyle: { "margin-top": "16px" } },
              [
                _c("ips-input", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.requireParentCidCode
                        ? false
                        : _vm.inputData.containerManageFlag === 1
                        ? _vm.customData["inboundParentCidCode"]
                          ? _vm.hideControl["inboundParentCidCode"]
                          : _vm.inputData.containerManageFlag === 1
                        : false,
                      expression:
                        "\n          !requireParentCidCode\n            ? false\n            : inputData.containerManageFlag === 1\n              ? customData['inboundParentCidCode']\n                ? hideControl['inboundParentCidCode']\n                : inputData.containerManageFlag === 1\n              : false\n        ",
                    },
                  ],
                  ref: "ref40",
                  attrs: {
                    label: _vm.$t("label.inboundParentCid"),
                    disabled:
                      _vm.disableParentCidCode || !_vm.inputData.inboundCidCode,
                    custom: _vm.customData["inboundParentCidCode"],
                    "source-parse-field": _vm.sourceParseField,
                  },
                  on: {
                    enter: function ($event) {
                      return _vm.skipNext("submitRef")
                    },
                    down: function ($event) {
                      return _vm.skipNext("submitRef")
                    },
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleCommonClick(40)
                    },
                    blur: function ($event) {
                      return _vm.checkInboundCidBlur("parent")
                    },
                    setSourceField: function ($event) {
                      return _vm.saveSourceField($event)
                    },
                    valuedByFoucs: function ($event) {
                      return _vm.valuedByFoucs($event)
                    },
                  },
                  model: {
                    value: _vm.inputData.inboundParentCidCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputData, "inboundParentCidCode", $$v)
                    },
                    expression: "inputData.inboundParentCidCode",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "ips-footer" },
      [
        _c(
          "hips-button",
          {
            ref: "submitRef",
            attrs: { size: "large" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.submitShelvesCheck.apply(null, arguments)
              },
            },
          },
          [
            _c("svg-icon", {
              attrs: { slot: "icon", "icon-class": "success" },
              slot: "icon",
            }),
            _vm._v("\n      " + _vm._s(_vm.$t("button.submit")) + "\n    "),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }