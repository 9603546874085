var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: { title: _vm.$t("title.detailStock"), "is-index": false },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "header-row" }, [
      _c("div", { staticClass: "header-title" }, [
        _vm._v("\n      " + _vm._s(_vm.whareaData.locationCode) + "\n    "),
      ]),
      _c(
        "div",
        {
          class:
            _vm.statusFlag === "启用" ? "header-status" : "header-no-status",
        },
        [_vm._v("\n      " + _vm._s(_vm.statusFlag) + "\n    ")]
      ),
    ]),
    _c(
      "div",
      { staticClass: "ips-container" },
      [
        _c("div", { staticClass: "menu-row" }, [
          _c("div", { staticClass: "menu-title" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.$t("label.belongOrg")) + "：\n      "
            ),
          ]),
          _c("div", { staticClass: "menu-value" }, [
            _vm._v("\n        " + _vm._s(_vm.whareaData.orgName) + "\n      "),
          ]),
        ]),
        _c("div", { staticClass: "menu-row" }, [
          _c("div", { staticClass: "menu-title" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("label.belongWarehouse")) +
                "：\n      "
            ),
          ]),
          _c("div", { staticClass: "menu-value" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.whareaData.warehouseName) + "\n      "
            ),
          ]),
        ]),
        _c("div", { staticClass: "menu-row" }, [
          _c("div", { staticClass: "menu-title" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.$t("label.belongWharea")) + "：\n      "
            ),
          ]),
          _c("div", { staticClass: "menu-value" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.whareaData.whareaName) + "\n      "
            ),
          ]),
        ]),
        _c("div", { staticClass: "menu-row" }, [
          _c("div", { staticClass: "menu-title" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.$t("label.locationName")) + "：\n      "
            ),
          ]),
          _c("div", { staticClass: "menu-value" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.whareaData.locationName) + "\n      "
            ),
          ]),
        ]),
        _c("div", { staticClass: "menu-row" }, [
          _c("div", { staticClass: "menu-title" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.$t("label.locationType")) + "：\n      "
            ),
          ]),
          _c("div", { staticClass: "menu-value" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.whareaData.locationTypeName) +
                "\n      "
            ),
          ]),
        ]),
        _c("div", { staticClass: "menu-row" }, [
          _c("div", { staticClass: "menu-title" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("label.identification")) +
                "：\n      "
            ),
          ]),
          _c("div", { staticClass: "menu-value" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.whareaData.identificationName) +
                "\n      "
            ),
          ]),
        ]),
        _vm.whareaDetailData.length <= 0 ? _c("ips-no-data") : _vm._e(),
        _vm.whareaDetailData.length > 0
          ? _c(
              "div",
              _vm._l(_vm.whareaDetailData, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "card_countLocation" },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "row-50" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("label.whareaCode")) +
                              ":\n            "
                          ),
                        ]),
                        _c("div", { staticClass: "value" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.whareaData.whareaCode) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "row-50" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("label.countLocation")) +
                              ":\n            "
                          ),
                        ]),
                        _c("div", { staticClass: "label" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.whareaData.locationCode) +
                              "\n            "
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "row-50" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("label.sku")) +
                              ":\n            "
                          ),
                        ]),
                        _c("div", { staticClass: "value" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.sku) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "row-50" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("label.unit")) +
                              ":\n            "
                          ),
                        ]),
                        _c("div", { staticClass: "label" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.uomName) +
                              "\n            "
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "row-50" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("label.tray")) +
                              ":\n            "
                          ),
                        ]),
                        _c("div", { staticClass: "value" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.cidCode || "--") +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "row-50" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("label.batch")) +
                              ":\n            "
                          ),
                        ]),
                        _c("div", { staticClass: "label" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.batchCode || "--") +
                              "\n            "
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "row-50" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("label.availableQty")) +
                              ":\n            "
                          ),
                        ]),
                        _c("div", { staticClass: "label-number" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.qty) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "row-50" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("label.currentUseQty")) +
                              ":\n            "
                          ),
                        ]),
                        _c("div", { staticClass: "label-number" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.validQty) +
                              "\n            "
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("label.goodsName")) +
                            ":\n          "
                        ),
                      ]),
                      _c("div", { staticClass: "value" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(item.goodsName) +
                            "\n          "
                        ),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }