import request from '@/request';
import mixin  from '@/mixin/mixin';


// 入库托盘校验
export function checkCidCode(data) {
  let url = `${mixin.data().winvBaseUrl}app-quick-inbound/cid-verification`;
  return request.get(url, data);
}

// 入库托盘校验接受号下唯一
export function checkCidCodeRecipt(data) {
  let url = `${mixin.data().winvBaseUrl}app-inbound-receive/check-receipt-cid-code`;
  return request.get(url, data);
}

// 新建托盘
export function createTray(data) {
  let url = `${mixin.data().wmdmBaseUrl}containers`;
  return request.post(url, data);
}

// 物料校验
export function checkSku(data) {
  let url = `${mixin.data().winvBaseUrl}app-quick-inbound/sku-verification`;
  return request.get(url, data);
}

// 校验批次是否存在
export function checkBatchCode(data) {
  let url = `${mixin.data().wmdmBaseUrl}batchs/check`;
  return request.get(url, data);
}

// 校验批次
export function batchCheckAgain(data) {
  let url = `${mixin.data().winvBaseUrl}app-quick-inbound/batch-verification`;
  return request.get(url, data);
}

// 序列号新增校验和确认操作
export function checkSerialNum(data) {
  let url = `${mixin.data().winvBaseUrl}app-quick-inbound/serial-quick-inbound`;
  return request.post(url, data);
}


// 校验接收号
export function checkReceiptNumber(data) {
  let url = `${mixin.data().winvBaseUrl}app-inbound-receive/check-receipt-number`;
  return request.get(url, data);
}

// 自动生成接收号
export function autoCreateReceiptNum(data) {
  let url = `${mixin.data().winvBaseUrl}app-inbound-receive/get-receipt-number`;
  return request.get(url, data);
}

// 判断所有行收货完成
export function checkQty(id) {
    let url = `${mixin.data().winvBaseUrl}inbound-orders?id=${id}`;
    return request.get(url)
}

// 获取行数据
export function inboundOrderLines(data) {
  let url = `${mixin.data().winvBaseUrl}inbound-order-lines`;
  return request.get(url, data);
}

// 来源单号失去焦点校验
export function fromBillBlur(data) {
  let url = `${mixin.data().winvBaseUrl}inbound-orders`;
  return request.get(url, data)
}

// 检验托盘数据是否是欲打包+校验数据是否是混托
export function queryReceiptInfo(data) {
  let url = `${mixin.data().winvBaseUrl}app-inbound-receive/query-receipt-info`;
  return request.get(url, data);
}

// 录入序列号的校验接口
export function inputSerialChcek(data) {
  let url = `${mixin.data().winvBaseUrl}app-inbound/serial-check`;
  return request.get(url, data);
}

// 查询库区字符串
export function queryWhareaStr(data) {
  let url = `${mixin.data().winvBaseUrl}inbound-order-lines/find-inbound-wharea-ids`;
  return request.get(url, data);
}

// 入库成功物料回调
export function successItemCheck(data) {
  let url = `${mixin.data().winvBaseUrl}app-quick-inbound/query-return-type-fast-inbound`;
  return request.get(url, data);
}

// 校验父托盘是否为空托盘或者接收中
export function checkParentCidCode(data) {
    let url = `${mixin.data().winvBaseUrl}app-quick-inbound/check-parent-container`;
    return request.post(url, data);
}

// 查询预打包托盘详情
export function queryPrePackCidDetail(data) {
  let url = `${mixin.data().winvBaseUrl}inbound-receipt-records/query-prepackaged-receipt`
  return request.get(url, data);
}

// 获取页面功能参数
export function queryPageFunction(data) {
  let url = `${mixin.data().winvBaseUrl}wms-common/query-page-params`;
  return request.get(url, data);
}

export function checkCidBlur(data) {
    let url = `${mixin.data().winvBaseUrl}app-quick-inbound/default-parent-container`;
    return request.get(url, data)
}

// 校验父托盘和子托盘的关系
export function checkParentCidAndCid(data) {
    let url = `${mixin.data().winvBaseUrl}app-quick-inbound/check-outermost-container`;
    return request.post(url, data);
}