// import Login from '../views/login'
import Sort from '../views/sort'
import WaveOrderDetail from '../views/wave-order-detail'

let route = [
  {
    path: '/sort',
    name: 'Sort',
    component: Sort,
  },
  {
    path:'/wave-order-detail',
    name:'WaveOrderDetail',
    component:WaveOrderDetail,
  },
];

export default route
