import request from '@/request';
import mixin  from '@/mixin/mixin';

// 查询带出数据
export function queryCidData(data) {
  const url = `${mixin.data().winvBaseUrl}app-direct-putaway/query-detail`;

  return request.get(url, data);
}

// 查询汇总带出数据
export function querySummaryData(data) {
  const url = `${mixin.data().winvBaseUrl}app-direct-putaway/query-all-receipt`;

  return request.get(url, data);
}

// 默认库位
export function queryDefaultLocation(data) {
  const url = `${mixin.data().winvBaseUrl}app-direct-putaway/query-recommand-location`;

  return request.post(url, data);
}

// 校验入库托盘
export function checkInboundCid(data, defaultUrl = 'app-direct-putaway/query-inbound-container') {
  const url = `${mixin.data().winvBaseUrl}${defaultUrl}`;

  return request.get(url, data);
}

// 查询物料
export function queryGoodsToSubmit(data) {
  const url = `${mixin.data().winvBaseUrl}app-direct-putaway/query-goods`;

  return request.get(url, data);
}

// 保存提交
export function handleSubmit(data) {
  const url = `${mixin.data().winvBaseUrl}app-direct-putaway/process`;

  return request.post(url, data);
}

// 保存提交
export function querySerialDetail(data) {
  const url = `${mixin.data().winvBaseUrl}app-direct-putaway/query-serial`;

  return request.post(url, data);
}

// 带父托盘校验
export function checkWholeContainer(data) {
  const url = `${mixin.data().winvBaseUrl}app-direct-putaway/check-whole-container`;

  return request.post(url, data);
}

// 混拖 - 提交前校验
// 校验接收托盘及子托盘是否装载入库
export function checkContainerPackObject(data) {
  const url = `${mixin.data().winvBaseUrl}app-direct-putaway/check-container-pack-object`;

  return request.get(url, data);
}


