import request from '@/request';
import mixin  from '@/mixin/mixin';

// 托盘校验
export function checkContainer  (data) {
  // cidCode
  let url = `${mixin.data().winvBaseUrl}inv-load/check-container`;
  return request.get(url, data);
}

// 装入
export function handleLoading (data) {
  //cidId
  let url = `${mixin.data().winvBaseUrl}inv-load/load`;
  return request.post(url, data);
}


// 托盘返回对应状态 装入或装入卸货
export function queryCidData (data) {
  let url = `${mixin.data().winvBaseUrl}inv-load/query-params`;
  return request.get(url, data);
}


// 托盘返回对应状态 装入或装入卸货
export function queryAllList (data) {
  let url = `${mixin.data().winvBaseUrl}inv-load/load-task`;
  return request.get(url, data);
}


// 获取menuId
export function getAppId(param) {
  const url = `${mixin.data().winvBaseUrl}wms-common/query-hips-menu`;
  return request.get(url, param);
}
