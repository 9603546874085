<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <ips-header
        :title="$t('title.singleProcessing')"
        :is-index="false"
      />
    </div>
    <div class="ips-container">
      <!-- 加工单号 -->
      <ips-value-cell
        :title="$t('label.processingNum')"
        v-show="customData['billCode'] ? hideControl['billCode']: true"
        :value="detailData.billCode"
      />
      <!-- 加工类型 -->
      <ips-value-cell
        :title="$t('label.processingType')"
        v-show="customData['workTypeName'] ? hideControl['workTypeName']: true"
        :value="detailData.workTypeName"
      />
      <!-- 货主 -->
      <ips-value-cell
        :title="$t('label.ownerCode')"
        v-show="customData['ownerName'] ? hideControl['ownerName']: true"
        :value="detailData.ownerName"
      />
      <!-- 装配库区 -->
      <ips-value-cell
        :title="$t('label.assemblyArea')"
        v-show="customData['whareaName'] ? hideControl['whareaName']: true"
        :value="detailData.whareaName"
      />
      <!-- 装配库位 -->
      <ips-value-cell
        :title="$t('label.assemblyLocation')"
        v-show="customData['locationCode'] ? hideControl['locationCode']: true"
        :value="detailData.locationCode"
      />
      <!-- 父级物料 -->
      <ips-value-cell
        :title="$t('label.parentSku')"
        v-show="customData['sku'] ? hideControl['sku']: true"
        :value="detailData.sku"
      />
      <!-- 品名 -->
      <ips-value-cell
        :title="$t('label.goodsName')"
        v-show="customData['goodsName'] ? hideControl['goodsName']: true"
        :value="detailData.goodsName"
      />
      <!-- 现有可用量 -->
      <ips-value-cell
        :title="$t('label.currentUseQty')"
        v-show="customData['fatherGoodsValidQty'] ? hideControl['fatherGoodsValidQty']: true"
        :value="detailData.fatherGoodsValidQty"
      />
      <!-- 待加工 -->
      <ips-value-cell
        :title="$t('label.toBeProcessed')"
        v-show="customData['planQty'] ? hideControl['planQty']: true"
        :value="detailData.planQty-detailData.workQty"
      />
      <!-- 加工数量 -->
      <ips-field
        :label="$t('label.processingQty')"
        v-model="workQty"
        v-show="customData['workQty'] ? hideControl['workQty']: true"
        required
        :custom="customData['workQty']"
        :source-parse-field="sourceParseField"
        ref="ref0"
        @enter="handleWorkQtyEnter"
        @down="handleWorkQtyEnter"
        @blur="checkMachiningQty"
        @click.stop="handleCommonClick(0)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 批次 -->
      <ips-field
        :label="$t('label.batch')"
        v-model="detailData.batchCode"
        v-if="(detailData.isbatch && isAssemble) ? (customData['batchCode'] ? hideControl['batchCode'] : (detailData.isbatch && isAssemble)) : false"
        params="batchCode"
        display="batchCode"
        :pre-parse-url="urlData['batchCode']"
        required
        :custom="customData['batchCode']"
        :source-parse-field="sourceParseField"
        ref="ref10"
        @enter="handleTableEnter(-1)"
        @down="handleTableEnter(-1)"
        @blur="checkBatchCode"
        @click.stop="handleCommonClick(10)"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <!-- 批次 -->
      <ips-lov
        :label="$t('label.batch')"
        :title="$t('label.batch')"
        required
        v-model="detailData.batchCode"
        v-if="(detailData.isbatch && !isAssemble) ? (customData['batchCode'] ? hideControl['batchCode'] : (detailData.isbatch && !isAssemble)) : false"
        :custom="customData['batchCode']"
        :source-parse-field="sourceParseField"
        ref="ref10"
        :url="`${winvBaseUrl}app-direct-pick/outbound-query-trans-batch`"
        :url-params="{locationId: detailData.locationId, ownerId: detailData.ownerId, goodsId: detailData.goodsId }"
        :pre-parse-url="urlData['batchCode']"
        params="batchCode"
        @choose="chooseFatherBatch($event)"
        @enter="handleTableEnter(-1);"
        @down="handleTableEnter(-1);"
        @click.stop="handleCommonClick(10)"
        @clickIcon="handleCommonClick(10, 'lov')"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
      <div class="ips-table">
        <table>
          <tr style="background-color: #eee">
            <th
              v-for="(item, index) in tableTitle"
              :key="index"
              scope="col"
            >
              {{ item.name }}
            </th>
          </tr>
          <tr
            v-for="(item, index) in tableData"
            :key="index"
            :ref="`tr${index}`"
          >
            <td>{{ item.sku }}</td>
            <td>{{ item.qty }}</td>
            <td>{{ item.validQty }}</td>
            <td>
              <ips-lov
                :title="$t('label.batch')"
                required
                nolabel-required
                v-if="isAssemble"
                v-model="item.batchCode"
                :ref="`ref${index*10+20}`"
                :url="`${winvBaseUrl}app-direct-pick/outbound-query-trans-batch`"
                :url-params="{locationId: detailData.locationId, ownerId: item.ownerId, goodsId: item.goodsId}"
                :pre-parse-url="urlData['tableBatchCode']"
                params="batchCode"
                :disabled="!item.isbatch"
                :dis-background="!item.isbatch"
                @enter="handleTableEnter(index)"
                @down="handleTableEnter(index)"
                @choose="chooseChildBatch($event, item, index)"
                @click.stop="handleCommonClick(index*10+20)"
                @clickIcon="handleCommonClick(index*10+20, 'lov')"
              />
              <ips-field
                required
                nolabel-required
                v-if="!isAssemble"
                v-model="item.batchCode"
                params="tableBatchCode"
                display="tableBatchCode"
                :pre-parse-url="urlData['tableBatchCode']"
                :ref="`ref${index*10+20}`"
                :disabled="item.isbatch === 0"
                :dis-background="item.isbatch === 0"
                @blur="checkBatchCode($event, item, index)"
                @enter="handleTableEnter(index)"
                @down="handleTableEnter(index)"
                @click.stop="handleCommonClick(index*10+20)"
              />
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="ips-footer">
      <hips-button
        size="large"
        ref="submitRef"
        @keyup.enter.native="handleCommonSubmit"
        @keypress.native.prevent
        @click.stop="handleCommonSubmit"
      >
        <svg-icon
          slot="icon"
          icon-class="success"
        />
        {{ $t('button.processComplete') }}
      </hips-button>
    </div>
  </hips-view>
</template>

<script>
import { queryTableData, validChange, batchCheck, Process } from '../api'
export default {
  name: 'WorkOrderExecutionDetail',
  data() {
    return {
      isAssemble: true,  // 是否为组装 控制批次显示类型为lov还是input
      detailData: {
        isbatch: false,
        billCode: '',
        goodsId: '',
      },
      workQty: '',
      tableTitle: [],
      tableData: [],
      urlData: {},
      customData: {}, // 个性化配置信息列表
      hideControl: {}, // 个性化配置显示隐藏控制
      sourceParseField: {}, // 界面中所有的来源解析字段
    }
  },
  async mounted() {
    await this.getDetailData();
    this.urlData = this.handleData('WORK_ORDER_EXECUTION_CONFIRM');
    this.skipNext(0);
    this.tableTitle = [
      { name: this.$t('label.childSku') }, // 子级物料
      { name: this.$t('label.fittingsNum') }, // 配件数量
      { name: this.$t('label.currentUseQty') }, // 现有可用量
      { name: this.$t('label.batch') }, // 批次
    ];
  },
  methods: {
    /**
     *@functionName: saveSourceField
     *@description: 保存来源解析字段并赋值Enter
     *@params1: fieldObj[Object] 来源解析字段对象,
     */
    saveSourceField(fieldObj) {
      this.saveSourceParseField(this, fieldObj);
    },
    // 字段聚焦时赋值操作
    async valuedByFoucs(options = {}) {
      const { custom = {}, currentValue } = options;
      await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
    },
    checkPass() {
      let fatherBatchPass = true;
      let childBatchPass = true;
      const index = this.tableData.findIndex((item) => {
        return item.isbatch && !item.batchCode;
      });
      childBatchPass = index === -1;
      if (this.detailData.isbatch && !this.detailData.batchCode) {
        fatherBatchPass = false;
      }
      return childBatchPass && fatherBatchPass;
    },

    /**
    * 获取详情数据
    */
    getDetailData() {
      const { detailData, sourceParseField, customData, hideControl } = this.$route.params;
      this.detailData = detailData;
      // this.detailData.billCodeByDetail = detailData.billCode;
      // this.detailData.skuByDetail = detailData.sku;
      this.sourceParseField = sourceParseField;
      this.customData = customData;
      this.hideControl = hideControl;
      this.isAssemble = detailData && detailData.workType === 'ASSEMBLE'; // 加工单类型为组装
      // if(this.isAssemble) {
      this.inputFieldList = [
        { type: 'text', bind: 'billCode', field: 'billCode' },
        { type: 'text', bind: 'workTypeName', field: 'workTypeName' },
        { type: 'text', bind: 'ownerName', field: 'ownerName' },
        { type: 'text', bind: 'whareaName', field: 'whareaName' },
        { type: 'text', bind: 'locationCode', field: 'locationCode' },
        { type: 'text', bind: 'sku', field: 'sku' },
        { type: 'text', bind: 'goodsName', field: 'goodsName' },
        { type: 'text', bind: 'fatherGoodsValidQty', field: 'fatherGoodsValidQty' },
        { type: 'text', bind: 'planQty', field: 'planQty' },
        { type: 'input', required: true, ref: 0, bind: 'workQty', field: 'workQty' },
        { type: this.isAssemble ? 'input' : 'lov', required: true, ref: 10, bind: 'detailData.batchCode', field: 'batchCode', isHide: !this.detailData.isbatch },
      ];
      // }else {
      //   this.inputFieldList = [
      //     { type: 'input', required: true, ref: 0, bind: 'workQty' },
      //     { type: 'lov', required: true, ref: 10, bind: 'detailData.batchCode', isHide: !this.detailData.isbatch },
      //   ];
      // }
      // console.log(detailData);
    },

    /**
    * table里的enter事件判断
    */
    handleTableEnter(index) {
      for (let i = index + 1; i < this.tableData.length; i++) {
        const toIndex = i * 10 + 20;
        if (!(this.$refs['ref' + toIndex][0].disabled || this.$refs['ref' + toIndex].disabled)) {
          this.skipNext(toIndex);
          return
        }
      }
      this.skipNext('submitRef');
    },

    /**
    * 加工数量enter事件
    */
    handleWorkQtyEnter() {
      if (this.detailData.isbatch) {
        this.skipNext(10);
      } else {
        this.handleTableEnter(-1)
      }
    },

    /**
    * 父级批次选中事件
    * @param {Object} obj 选中对象
    */
    async chooseFatherBatch(obj) {
      this.detailData.batchId = obj.batchId;
      const { locationCode, locationId, goodsId, sku } = this.detailData;
      try {
        const data = {
          params: {
            locationCode,
            locationId,
            goodsId,
            sku,
            batchId: obj.batchId,
            batchCode: obj.batchCode,
          }
        };
        // const url = `${this.winvBaseUrl}app-work-order/valid-change`;
        // const res = await this.$http.get(url, data);
        const res = await validChange(data);
        this.detailData.fatherGoodsValidQty = res;
        // this.handleTableEnter(-1);
      } catch {
        this.skipNext(10);
      } finally {
        console.log('over')
      }
    },

    /**
    * 子级批次选中事件
    * @param {Object} obj 选中对象
    * @param {Object} item table当前行
    */
    async chooseChildBatch(obj, item, index) {
      console.log(obj)
      console.log(item)
      console.log(index)

      const { locationCode, locationId } = this.detailData;
      item.batchId = obj.batchId;
      try {
        const data = {
          params: {
            ...item,
            locationCode,
            locationId,
          }
        };
        // const url = `${this.winvBaseUrl}app-work-order/valid-change`
        // const res = await this.$http.get(url, data);
        const res = await validChange(data);
        item.validQty = res;
      } catch {
        this.skipNext(index * 10 + 20);
      } finally {
        console.log('over')
      }
    },

    /**
    * 加工数量校验
    */
    async checkMachiningQty() {
      const { goodsId, planQty, workQty, locationId, workType, orgId, orgCode } = this.detailData;
      if (this.workQty) {
        const reg = /^[0-9]*$/;    //匹配数字
        if (this.workQty <= 0 || !reg.test(this.workQty)) {
          this.$hint.showFailed(this.$t('message.workQtyWarnOne'), true);
          this.skipNext(0);
          return
        }
        if (this.workQty > planQty - workQty) {
          this.$hint.showFailed(this.$t('message.workQtyWarnTwo'), true);
          this.skipNext(0);
          return
        }
        try {
          this.$hint.showLoading();
          const data = {
            params: {
              fatherGoodsId: goodsId,
              planQty: this.workQty, // 当前加工数量
              workType,
              locationId,
              orgId,
              orgCode,
            }
          };
          const res = await queryTableData(data);
          this.tableData = res || [];
          this.$nextTick(() => {
            if (this.detailData.isbatch) {
              this.skipNext(10);
            } else {
              this.handleTableEnter(-1);
            }
          })
        } catch {
          this.skipNext(0);
        } finally {
          this.$hint.hideLoading();
        }
      }
    },

    /**
    * 批次失焦事件校验
    * @param {Object} event
    * @param {Object} item 子级table当前行
    * @param {String} index 子级table当前行index
    */
    async checkBatchCode(_, item, index) {
      // item存在代表是子级物料校验
      const _this = this;
      let breakOut = false;
      function onConfirm() {
        const { orgId, orgName } = _this.detailData;
        const urlParam = {
          name: 'createBatch',
          params: {
            skuInfo: {
              sku: item ? item.sku : _this.detailData.sku,
              ownerName: item ? item.ownerName : _this.detailData.ownerName,
              ownerId: item ? item.ownerId : _this.detailData.ownerId,
              orgName,
              orgId,
              goodsId: item ? item.goodsId : _this.detailData.goodsId,
            },
            batchCode: item ? item.batchCode : _this.detailData.batchCode,
          },
        };
        // 标志当前创建的批次是父级还是子级
        _this.createBatchFlag = {
          type: item ? 'child' : 'father',
          index,
        };
        _this.$router.pushPage(urlParam);
      }
      function onCancel() {
        if (item) {
          item.batchCode = '';
          _this.skipNext(index * 10 + 20);
        } else {
          _this.detailData.batchCode = '';
          _this.skipNext(10);
        }
      }
      const batchCode = item ? item.batchCode : this.detailData.batchCode;
      if (batchCode) {
        try {
          this.$hint.showLoading();
          // const url = `${this.winvBaseUrl}app-work-order/batch-check`;
          const data = {
            params: {
              batchCode: item ? item.batchCode : this.detailData.batchCode,
              goodsId: item ? item.goodsId : this.detailData.goodsId,
              ownerId: item ? item.ownerId : this.detailData.ownerId,
            }
          };
          // const res = await this.$http.get(url, data);
          const res = await batchCheck(data);
          if (res.batchCheckResult === 1) {
            breakOut = true;
            this.$hint.showAlert(this.$i18n.t('message.checkBatchCode'), onConfirm, onCancel);
            return breakOut;
          }
          if (item) {
            item.batchId = res.batchId;
            this.handleTableEnter(index);
          } else {
            this.batchId = res.batchId;
            this.handleTableEnter(-1);
          }

        } catch {
          breakOut = true;
          onCancel();
        } finally {
          this.$hint.hideLoading();
        }
      }
      return breakOut;
    },

    /**
    * handleButtonSubmit 确认
    */
    async handleButtonSubmit() {
      if (!this.checkPass()) {
        this.$hint.showFailed(this.$i18n.t('message.checkRequired'), true);
        return;
      }
      this.forbidSubmit = false;
      if (this.isAssemble) {
        let arr = [];
        this.tableData.map((item, index) => {
          if (item.validQty < item.qty) {
            arr.push(item.sku);
            this.$refs[`tr${index * 10}`][0].className = 'warn';
            this.forbidSubmit = true;
          }
        });
        if (this.forbidSubmit) {
          this.$hint.showFailed(this.$i18n.t('message.lackMaterial', { label: `${arr.join()}` }), true)
        }
      } else {
        const { fatherGoodsValidQty, sku } = this.detailData;
        if (fatherGoodsValidQty < this.workQty) {
          this.$hint.showFailed(this.$i18n.t('message.lackMaterial', { label: `${sku}` }), true);
          this.forbidSubmit = true;
        }
      }
      if (!this.forbidSubmit) {
        try {
          this.$hint.showLoading();
          this.handleSubmitStatus();
          const data = {
            ...this.detailData,
            billCode: this.detailData.billCode,
            workQty: this.workQty,
            batchId: this.batchId || this.detailData.batchId,
            bomLinesDtoList: this.tableData,
          };
          // const url = `${this.winvBaseUrl}app-work-order/process`;
          // const res = await this.$http.post(url, data);
          const res = await Process(data);
          if (!res) {
            this.$store.commit("deleteKeepAlives", "WorkOrderExecution");
          }
          await this.clearSourceFields(this, true);
          this.$hint.showSuccess(this.$i18n.t('message.operationSuccess'));
          this.$router.backPage();
        } finally {
          this.$hint.hideLoading();
          this.handleSubmitStatus();
        }
      }
    }
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name === 'createBatch') {
        const { type, index } = vm.createBatchFlag;
        if (type === 'child') {
          vm.tableData[index].batchId = vm.$storage.get('batchId');
          vm.skipNext(index * 10 + 20);
        } else {
          vm.batchId = vm.$storage.get('batchId');
          vm.skipNext(10);
        }
        vm.$storage.remove('batchId');
      }
    });
  },

  beforeRouteLeave(to, from, next) {
    if (to.name === 'WorkOrderExecution') {
      this.$store.commit('deleteKeepAlives', 'WorkOrderExecutionDetail');
    }
    next();
  },
}

</script>

<style lang="stylus" scoped>
@import '~@/style/var.styl';

.ips-table {
  width: 100%;
  overflow-x: auto;
  margin-top: 20px;

  table {
    width: 100%;
  }

  th, td {
    padding: 5px 2px;
    border: 1px solid #ccc;
    text-align: center;
    white-space: nowrap;
  }

  .warn {
    td {
      color: red;
    }
  }
}
</style>