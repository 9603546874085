<template>
  <hips-view :header-fixed="true">
    <div slot="header">
      <!-- 上架入库 -->
      <ips-header :title="$t('title.shelvesLoad')" :is-index="true" />
    </div>
    <div class="ips-container">
      <!-- 托盘 -->
      <ips-lov
        :label="$t('label.tray')"
        :title="$t('label.tray')"
        required
        v-model="cidCode"
        v-show="customData['cidCode'] ? hideControl['cidCode']: true"
        params="cidCode"
        :url="`${winvBaseUrl}app-inbound-putaway/cid-lov`"
        :pre-parse-url="urlData['cidCode']"
        :custom="customData['cidCode']"
        :source-parse-field="sourceParseField"
        ref="ref0"
        is-only
        @enter="skipNext('submitRef')"
        @down="skipNext('submitRef')"
        @choose="chooseCid"
        @click.stop="handleCommonClick(0)"
        @clickIcon="handleCommonClick(0, 'lov')"
        @setSourceField="saveSourceField($event)"
        @valuedByFoucs="valuedByFoucs($event)"
      />
    </div>
    <div class="ips-footer">
      <!-- 整托 -->
      <hips-button
        size="large"
        ref="submitRef"
        @click.stop="handleCommonSubmit('EntireTray')"
        @keyup.enter.native="handleCommonSubmit('EntireTray')"
        @keypress.native.prevent
      >
        <svg-icon
          slot="icon"
          icon-class="link"
        />
        {{ $t('button.entireTray') }}
      </hips-button>
      <!-- 分托 -->
      <hips-button
        size="large"
        v-show="customData['batches'] ? hideControl['batches']: true"
        @click.stop="handleCommonSubmit('Batches')"
        @keyup.enter.native="handleCommonSubmit('Batches')"
        @keypress.native.prevent
      >
        <svg-icon
          slot="icon"
          icon-class="link"
        />
        {{ $t('button.batches') }}
      </hips-button>
    </div>
  </hips-view>
</template>

<script>
import { dealWithCid, checkCidId } from '../api';
import { storage } from '@/utils';
export default {
  name: 'PutAway',
  data() {
    return {
      cidCode: '', // 托盘
      cidId: '',
      parentCidCode: '', // 父托盘
      parentCidId: '',
      isSpiltFlag: true, // 分托时为true
      urlData:{},
      customData: {}, // 个性化配置信息列表
      hideControl: {}, // 个性化配置显示隐藏控制
      sourceParseField: {}, // 界面中所有的来源解析字段
    };
  },
  methods: {
    handleButtonSubmit(type) {
      this.handleGo(type);
    },
    /**
     *@functionName: saveSourceField
     *@description: 保存来源解析字段并赋值Enter
     *@params1: fieldObj[Object] 来源解析字段对象,
     fieldCode[String] 来源解析字段
     parseValue[String] 二维码解析值
     subFields[Array]] 关联子字段
     */
    saveSourceField(fieldObj) {
      this.saveSourceParseField(this, fieldObj);
    },
    // 字段聚焦时赋值操作
    async valuedByFoucs(options={}) {
      const { custom ={} , currentValue } = options;
      await this.calculateFieldOnFocus(this, custom, this.sourceParseField, currentValue);
    },

    async handleGo(flag) {
      try {
        if (this.$common.isEmpty(this.cidCode)) {
          this.$hint.showFailed(this.$i18n.t('message.checkRequired'));
          return;
        }
        this.$hint.showLoading();
        // 整托的情况下，调用校验接口
        if(flag === 'EntireTray') {
          this.isSpiltFlag = false;
          const data = {
            params: {
              cidId: this.cidId,
            }
          };
          await checkCidId(data);
        }
        const cidData = {
          params: {
            cidId: this.cidId,
            cidCode: this.cidCode,
            isSpiltFlag: flag === 'EntireTray' ? false : true,
          }
        };
        dealWithCid(cidData).then((res) => {
          const length = res.length;
          if (res && length > 0) {
            this.parentCidCode = res[0].parentCidCode;
            this.parentCidId = res[0].parentCidId;
            this.handleRemoveSession();
            const param = {
              name: flag,
              params: {
                cidCode: this.cidCode,
                parentCidCode: this.parentCidCode,
                parentCidId: this.parentCidId,
                warehouseId: res[0].warehouseId,
                sourceParseField: {...this.sourceParseField},
                customData: this.customData,
                hideControl: this.hideControl,
              },
            };
            sessionStorage.setItem('_putawayLoad', JSON.stringify(res));
            this.$router.pushPage(param);
          } else if(length === 0) {
            this.$hint.showFailed(this.$i18n.t('message.findNullNormalWithTask'));
          } else {
            this.$hint.showFailed(this.$i18n.t('message.findNullNormal'));
          }
        }).finally(()=>{
          this.$hint.hideLoading();
        })
      } catch (error) {
        this.$hint.hideLoading();
      }
    },
    handleRemoveSession() {
      let sessionData = sessionStorage.getItem('_putawayLoad');
      if (sessionData) {
        sessionStorage.removeItem('_putawayLoad');
      }
    },
    /**
     * 托盘选中事件
     * @param {Object} item
    */
    chooseCid(item) {
      this.cidId = item.cidId;
    },
  },
  created() {
    const { customObj, hideControl } = this.handleCustomData(storage.get('customData'));
    this.customData = customObj;
    this.hideControl = hideControl;
    // console.log('上架入库customData', Object.assign({}, this.customData));
  },
  mounted() {
    this.inputFieldList = [{ type: 'lov', required: true, ref: 0, bind: 'cidCode', field: 'cidCode' }];
    this.urlData = this.handleData('PUT_AWAY_QUERY');
    this.skipNext(0);
  },
};
</script>

<style lang="stylus" scoped></style>

