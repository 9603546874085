var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "hips-view",
    [
      _c("ips-header", {
        staticClass: "ips-header-height",
        attrs: { title: _vm.$t("title.serialNumber") },
      }),
      _c("div", { staticClass: "ips-container" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.$t("button.skuItemNo")) +
            "：" +
            _vm._s(_vm.$route.query.sku) +
            "\n    "
        ),
        _c("div", { staticClass: "ips-table" }, [
          _c(
            "table",
            [
              _c(
                "tr",
                { staticStyle: { "background-color": "#eee" } },
                _vm._l(_vm.tableTitle, function (item, index) {
                  return _c(
                    "th",
                    {
                      key: index,
                      staticClass: "ips-table-thead",
                      attrs: { scope: "col" },
                    },
                    [
                      _vm._v(
                        "\n            " + _vm._s(item.name) + "\n          "
                      ),
                    ]
                  )
                }),
                0
              ),
              _vm._l(_vm.tableData, function (item, index) {
                return _c(
                  "tr",
                  { key: index, ref: "tr" + index, refInFor: true },
                  [
                    _c("td", [_vm._v(_vm._s(index + 1))]),
                    _c("td", [_vm._v(_vm._s(item.serialNumber))]),
                  ]
                )
              }),
            ],
            2
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }