var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("hips-view", { attrs: { "header-fixed": true } }, [
    _c(
      "div",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _c("ips-header", {
          attrs: { title: _vm.$t("title.trayDetailShow"), "is-index": false },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ips-container" },
      _vm._l(_vm.lineData, function (item, index) {
        return _c(
          "div",
          { key: index },
          [
            _c("ips-value-cell", {
              attrs: { title: _vm.$t("label.sku"), value: item.sku },
            }),
            _c("ips-value-cell", {
              attrs: {
                title: _vm.$t("label.goodsName"),
                value: item.goodsName,
              },
            }),
            _c("ips-value-cell", {
              attrs: { title: _vm.$t("label.unit"), value: item.uomName },
            }),
            _c("ips-value-cell", {
              attrs: { title: _vm.$t("label.batch"), value: item.batchCode },
            }),
            _c("ips-value-cell", {
              attrs: { title: _vm.$t("label.availableQty"), value: item.qty },
            }),
            _c("ips-value-cell", {
              attrs: { title: _vm.$t("label.useQty"), value: item.validQty },
            }),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }