import wavePicking from '../views/wave-picking';
import taskExecutionWave from '../views/task-execution-wave';
import withPageFunction from '@/hoc/withPageFunction'
let route = [
  {
    path: '/wave-picking',
    name: 'wavePicking',
    component: withPageFunction(wavePicking, {}),
  },
  {
    path: '/task-execution-wave',
    name: 'taskExecutionWave',
    component: taskExecutionWave,
  },
];

export default route;
